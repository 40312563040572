import { InMemoryCache, Reference, makeVar } from '@apollo/client';

export const userVar = makeVar<any>({
  accessToken: localStorage.token,
  id: localStorage.userId,
  headUri: localStorage.user ? JSON.parse(localStorage.user).headUri : '',
  name: localStorage.user ? JSON.parse(localStorage.user).name : '',
});

export const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        user: {
          read() {
            return userVar();
          },
        },
      },
    },
  },
});
