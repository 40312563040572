import {
  Alert,
  Button,
  Col,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Typography,
} from 'antd';
import Search from 'antd/lib/input/Search';
import { useEffect, useState } from 'react';
import {
  ProfitableQuery,
  ProfitablesDocument,
  SortOrder,
  useCreateOneStoreRatioHistoryItemMutation,
  useNewProfitableMutation,
  User,
  useStoreRatiosQuery,
  useUpdateOneProfitableMutation,
  useUsersLazyQuery,
} from '../../../graphql/types';
import { nationsOption } from './nationsSelectOption';

interface Props {
  isModalOpen: boolean;
  handleOk: () => void;
  handleCancel: () => void;
  initialValue?: ProfitableQuery['profitable'];
  forceUpdate: () => void;
}

const CreatorMutationModal = (props: Props) => {
  const { isModalOpen, handleOk, handleCancel, initialValue, forceUpdate } =
    props;
  const [form] = Form.useForm();
  const [user, setUser] = useState<User>();
  const [userID, setUserID] = useState<string>();
  const [isEditMode, setIsEditMode] = useState(false);
  const [isError, setIsError] = useState(false);
  const [fetchUser, { data: userData }] = useUsersLazyQuery();

  const { data: { storeRatios } = {} } = useStoreRatiosQuery({
    variables: {
      orderBy: {
        value: SortOrder.Desc,
      },
    },
  });

  const [createOneStoreRatioHistory] =
    useCreateOneStoreRatioHistoryItemMutation();

  const [createProfitable] = useNewProfitableMutation({
    refetchQueries: [
      {
        query: ProfitablesDocument,
      },
    ],
    onError(error) {
      setIsError(true);
    },
    onCompleted: (data) => {
      createOneStoreRatioHistory({
        variables: {
          data: {
            inSaleRatioHistory: {
              connect: {
                id: data.createOneProfitable.id,
              },
            },
            inSendRatioHistory: {
              connect: {
                id: data.createOneProfitable.id,
              },
            },
            ratio: {
              connect: {
                id: data.createOneProfitable.saleRatioId,
              },
            },
          },
        },
      });
      forceUpdate();
      handleCancel();
    },
  });
  const [updateProfitable] = useUpdateOneProfitableMutation({
    refetchQueries: [
      {
        query: ProfitablesDocument,
      },
    ],
    onCompleted: (data) => {
      createOneStoreRatioHistory({
        variables: {
          data: {
            inSaleRatioHistory: {
              connect: {
                id: data.updateOneProfitable?.id,
              },
            },
            inSendRatioHistory: {
              connect: {
                id: data.updateOneProfitable?.id,
              },
            },
            ratio: {
              connect: {
                id: data.updateOneProfitable?.saleRatioId,
              },
            },
          },
        },
      });
      forceUpdate();
      handleCancel();
    },
  });

  useEffect(() => {
    if (userID) {
      fetchUser({
        variables: {
          where: {
            idStr: {
              equals: userID,
            },
          },
        },
      });
    }
  }, [userID]);

  useEffect(() => {
    if (initialValue) {
      const formValues = {
        idStr: initialValue.id,
        profitTable: {
          bio: initialValue.bio,
          country: initialValue.user.country?.toUpperCase(),
          type: initialValue.type,
          class: initialValue.class,
          name: initialValue.user.name,
        },
        status: {
          text: initialValue.statusText,
        },
        id: initialValue!.id,
        saleRatio: {
          value: initialValue.saleRatio?.id,
        },
        sendRatio: {
          value: initialValue.sendRatio?.id,
        },
      };
      form.setFieldsValue(formValues);
      setIsEditMode(true);
    }
  }, [initialValue]);

  useEffect(() => {
    if (userData) {
      console.log('Hello userData', userData);
      const _user = userData.users[0] as User;
      if (_user) {
        setUser(_user);
        form.setFieldsValue({
          id: _user.id,
          idStr: _user.idStr,
          profitTable: {
            name: _user.name,
            bio: _user.bio,
          },
        });
      }
    }
  }, [userData, userID]);

  const onFinish = (values: any) => {
    const { profitTable, status, saleRatio, sendRatio } = values;
    if (isEditMode) {
      let updateData = form.getFieldsValue();
      updateProfitable({
        variables: {
          where: {
            id: updateData.idStr,
          },
          data: {
            type: { set: updateData.profitTable.type },
            class: { set: updateData.profitTable.class },
            statusText: { set: updateData.status.text },
            bio: { set: updateData.profitTable.bio },
            saleRatio: {
              connect: { id: updateData.saleRatio.value },
            },
            sendRatio: {
              connect: { id: updateData.sendRatio.value },
            },
          },
        },
      });
    } else {
      createProfitable({
        variables: {
          data: {
            type: profitTable.type,
            class: profitTable.class,
            statusText: status.text,
            bio: profitTable.bio,
            saleRatio: {
              connect: { id: saleRatio.value },
            },
            sendRatio: {
              connect: { id: sendRatio.value },
            },
            user: {
              connect: {
                id: user!.id,
              },
            },
          },
        },
      });
    }
  };

  const onFinishFailed = (errorInfo: any) => {};

  const onSearch = (idStr: string) => {
    console.log('Hello idStr', idStr);
    setUserID(idStr);
  };

  return (
    <Modal
      title={isEditMode ? '編輯創作者' : '新增創作者'}
      open={isModalOpen}
      onOk={handleOk}
      onCancel={() => {
        handleCancel();
        setUserID('');
        setIsError(false);
      }}
      footer={null}
      destroyOnClose={true}
    >
      <Form
        preserve={false}
        form={form}
        name="basic"
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 16 }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        initialValues={{
          profitTable: {
            country: 'tw',
            type: 'normalCreator',
            class: 'none',
          },
        }}
      >
        <Form.Item label="Postory ID" name="idStr">
          <Search
            disabled={isEditMode}
            placeholder="輸入使用者ID"
            onSearch={onSearch}
          />
        </Form.Item>

        <Form.Item label="創作者狀態短語" name={['status', 'text']}>
          <Input.TextArea />
        </Form.Item>

        <Form.Item label="關於創作者" name={['profitTable', 'bio']}>
          <Input.TextArea />
        </Form.Item>

        {/* <Form.Item label="用戶國籍" name={['profitTable', 'country']}>
          <Select labelInValue={isEditMode} options={nationsOption}></Select>
        </Form.Item> */}

        <Form.Item
          rules={[{ required: true, message: '欄位為必須' }]}
          label="銷售分潤比例"
          name={['saleRatio', 'value']}
        >
          <Select
            // labelInValue={isEditMode}
            options={storeRatios
              ?.filter(
                ({ description }) =>
                  description.includes('商店銷售') ||
                  description.includes('POSTORY')
              )
              .map((ratio) => ({ value: ratio.id, label: ratio.description }))}
          />
        </Form.Item>

        <Form.Item
          rules={[{ required: true, message: '欄位為必須' }]}
          label="寄送分潤比例"
          name={['sendRatio', 'value']}
        >
          <Select
            // labelInValue={isEditMode}
            options={storeRatios
              ?.filter(
                ({ description }) =>
                  description.includes('實體寄送') ||
                  description.includes('POSTORY')
              )
              .map((ratio) => ({ value: ratio.id, label: ratio.description }))}
          />
        </Form.Item>

        <Typography.Title level={5}>創作者</Typography.Title>

        <Form.Item label="姓名" name={['profitTable', 'name']}>
          <Input disabled />
        </Form.Item>

        {/* <Form.Item label="電話" name={['profitTable', 'phone']}>
          <Input disabled />
        </Form.Item>

        <Form.Item label="地址" name={['profitTable', 'address']}>
          <Input disabled />
        </Form.Item> */}

        <Form.Item label="創作者類型" name={['profitTable', 'type']}>
          <Select
            // labelInValue={isEditMode}
            options={[
              {
                value: 'normalCreator',
                label: '一般創作者',
              },
              {
                value: 'officialCorpCreator',
                label: '官方合作創作者',
              },
              {
                value: 'company',
                label: '商家',
              },
            ]}
          />
        </Form.Item>

        <Form.Item label="Class" name={['profitTable', 'class']}>
          <Select
            // labelInValue={isEditMode}
            options={[
              {
                value: 'none',
                label: '無',
              },
              {
                value: 'highQuality',
                label: '優質',
              },
            ]}
          />
        </Form.Item>
        {isError && (
          <Row className="mb-3">
            <Col span={24}>
              <Alert message="創作者重複。" type="error" />
            </Col>
          </Row>
        )}
        <Row justify="end" gutter={16}>
          <Col>
            <Button
              onClick={() => {
                handleCancel();
                setUserID('');
                setIsError(false);
              }}
            >
              取消
            </Button>
          </Col>
          <Col>
            <Button type="primary" htmlType="submit">
              送出
            </Button>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default CreatorMutationModal;
