import {
  Button,
  Col,
  DatePicker,
  Input,
  Modal,
  notification,
  PaginationProps,
  Row,
  Select,
  Table,
  Tag,
  Typography,
} from 'antd';
import { ColumnsType } from 'antd/lib/table/interface';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import { isEmpty } from 'lodash';
import { useEffect, useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import '../App.less';
import {
  Order,
  OrderDetail,
  OrderDetailsDocument,
  OrderStatus,
  OrderTag,
  Post,
  SortOrder,
  useUpdateManyOrderDetailMutation,
  useCreateManyOrderDetailHistoryMutation,
  OrderDetailHistoryCreateManyInput,
  useOrderDetailsQuery,
  useOrderDetailsLazyQuery,
  OrderDetailHistory,
} from '../graphql/types';
import { exportPdf } from '../util/util';
import { orderValidStartDateTime } from '../Order';
import { useQuery } from '@apollo/client';
dayjs.extend(isBetween);

const { RangePicker } = DatePicker;
const { Option } = Select;
const { Search, TextArea } = Input;

const STATUS: any = {
  waitToPrint: '待印製',
  printed: '已印製',
  waitToSend: '待配送',
  sent: '已配送',
  cancel: '已取消',
};

const STATUS_TAG_COLOR: any = {
  printed: 'yellow',
  waitToSend: 'orange',
  sent: 'green',
  waitToPrint: 'cyan',
  cancel: 'gray',
};

function handleChange(value: string) {
  console.log(`selected ${value}`);
}

export default (props: { tableData: OrderDetail[] }) => {
  const history = useHistory();
  const location = useLocation();
  const [currentPage, setCurrentPage] = useState(1);
  const [batchMemo, setBatchMemo] = useState<string | null>(null);
  const [search, setSearch] = useState<string | null>(null);
  const [searchTags, setSearchTags] = useState<string[]>([]);
  const [dateRange, setDateRange] = useState<any>(null);
  const [selectedOrders, setSelectedOrders] = useState<OrderDetail[]>([]);
  const { refetch } = useQuery(OrderDetailsDocument, {
    variables: {
      where: {
        createdAt: {
          gt: orderValidStartDateTime,
        },
      },
      orderBy: {
        createdAt: SortOrder.Desc,
      },
    },
  });

  const { data: { orderDetails } = {}, refetch: refetchOrder } =
    useOrderDetailsQuery({
      variables: {
        where: {
          createdAt: {
            gt: orderValidStartDateTime,
          },
        },
        orderBy: {
          createdAt: SortOrder.Desc,
        },
      },
    });

  const [fetchOrderDetails, { data: reFetchedOrderDetails, called, loading }] =
    useOrderDetailsLazyQuery({
      variables: {
        where: {
          createdAt: {
            gt: orderValidStartDateTime,
          },
        },
        orderBy: {
          createdAt: SortOrder.Desc,
        },
      },
    });

  const generateHistories = (
    inputValues: OrderDetail
  ): OrderDetailHistoryCreateManyInput => {
    let histories = inputValues.histories;

    const operation = {
      realname: inputValues.realname,
      phone: inputValues.phone,
      address: inputValues.address,
      country: inputValues.country,
      memo: inputValues.memo || batchMemo,
    };
    if (reFetchedOrderDetails?.orderDetails) {
      histories = (reFetchedOrderDetails.orderDetails.find(
        (orderDetail) => orderDetail.id === inputValues.id
      )?.histories || []) as OrderDetailHistory[];
    }
    return {
      status:
        (batchChangingOrders?.status as OrderStatus) || inputValues.status,
      operation: JSON.stringify(operation),
      operatorId: localStorage.userId,
      lastHistoryId: histories[histories.length - 1].id,
      detailId: inputValues.id,
    };
  };

  const [createManyOrderDetailHistory] =
    useCreateManyOrderDetailHistoryMutation({
      onCompleted: () => {
        fetchOrderDetails();
      },
    });

  const [updateManyOrderDetail] = useUpdateManyOrderDetailMutation({
    refetchQueries: [
      {
        query: OrderDetailsDocument,
        variables: {
          where: {
            createdAt: {
              gt: orderValidStartDateTime,
            },
          },
          orderBy: {
            createdAt: SortOrder.Desc,
          },
        },
      },
    ],
    onCompleted: () => {
      fetchOrderDetails();
    },
  });
  const [batchChangingOrders, setBatchChangingOrders] = useState<
    | {
        ids: string[];
        readableIds: string[];
        status: string;
      }
    | undefined
  >(undefined);
  const { tableData } = props;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isMemoModalOpen, setIsMemoModalOpen] = useState(false);

  const uploadInvoice = async (readableId: string) => {
    return await fetch(
      `${process.env.REACT_APP_RESTFUL_ENDPOINT}/createInvoiceByOrderId`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        body: JSON.stringify({
          orderReadableId: readableId,
        }),
      }
    )
      .then((res) => {
        console.log('Request complete! response:', res);
        refetch();
        return res.json();
      })
      .catch((err) => {
        notification.error({
          message: '補開發票失敗',
          description: `readableId: ${readableId}`,
        });
        console.log('Request failed', err);
      });
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const showMemoModal = () => {
    setIsMemoModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);

    updateManyOrderDetail({
      variables: {
        data: {
          status: {
            set: batchChangingOrders?.status as OrderStatus,
          },
        },
        where: {
          id: {
            in: batchChangingOrders?.ids,
          },
        },
      },
    });

    const histories = selectedOrders.map(generateHistories);
    console.log('Hello histories', histories);
    createManyOrderDetailHistory({
      variables: {
        data: histories as OrderDetailHistoryCreateManyInput[],
      },
    });
  };

  const handleMemoOk = () => {
    setIsMemoModalOpen(false);
    updateManyOrderDetail({
      variables: {
        data: {
          memo: {
            set: batchMemo,
          },
        },
        where: {
          id: {
            in: batchChangingOrders?.ids,
          },
        },
      },
    });

    const histories = selectedOrders.map(generateHistories);
    console.log('Hello histories', histories);
    createManyOrderDetailHistory({
      variables: {
        data: histories as OrderDetailHistoryCreateManyInput[],
      },
    });
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleMemoCancel = () => {
    setIsMemoModalOpen(false);
  };

  const removeTags = (tag: string) => {
    setSearchTags(searchTags.filter((searchTag) => searchTag !== tag));
  };
  const getSortedData = () => {
    if (search && dateRange && !isEmpty(searchTags)) {
      return tableData.filter(
        (data) =>
          data.id.includes(search) &&
          dayjs(data.createdAt).isBetween(dateRange[0], dateRange[1], 'day') &&
          !isEmpty(data.tags.filter((t) => searchTags.includes(t.tag)))
      );
    }
    if (dateRange) {
      return tableData.filter((data) =>
        dayjs(data.createdAt).isBetween(dateRange[0], dateRange[1], 'day')
      );
    }
    if (search) {
      return tableData.filter((data) =>
        data?.order?.readableId?.includes(search)
      );
    }

    if (!isEmpty(searchTags)) {
      return tableData.filter(
        (data) => !isEmpty(data.tags.filter((t) => searchTags.includes(t.tag)))
      );
    }

    return tableData;
  };

  // useEffect(() => {
  //   getSortedData();
  // }, [tableData]);

  useEffect(() => {
    if (location?.search) {
      const page = Number(new URLSearchParams(location.search).get('page'));
      if (page) setCurrentPage(page);
    }
  }, [location]);

  const columns: ColumnsType<OrderDetail> = [
    {
      title: '訂單編號',
      dataIndex: 'id',
      render: (id: string, record: OrderDetail) => (
        <Link
          to={`order/${id}/?page=${currentPage}${location.hash}`}
          style={{ textDecoration: 'underline' }}
        >
          {record.order.postPackage.totalCount > 1 ? (
            <>
              <div>{record.order.readableId}</div>
              <div>{record.id}</div>
            </>
          ) : (
            record.order.readableId || record.id
          )}
        </Link>
      ),
    },
    {
      title: '訂單日期',
      dataIndex: 'createdAt',
      render: (date: Date) => dayjs(date).format('YYYY/MM/DD'),
      sorter: (a, b) => (dayjs(a.createdAt).isBefore(b.createdAt) ? 1 : -1),
    },
    {
      title: '寄件日',
      dataIndex: 'sendDate',
      render: (date: Date) => dayjs(date).format('YYYY/MM/DD'),
      sorter: (a, b) => (dayjs(a.createdAt).isBefore(b.createdAt) ? 1 : -1),
    },
    {
      title: '狀態',
      dataIndex: 'status',
      render: (status: string) => {
        return <Tag color={STATUS_TAG_COLOR[status]}>{STATUS[status]}</Tag>;
      },
      sorter: (a, b) => a.status.localeCompare(b.status),
    },
    {
      title: '寄送張數',
      dataIndex: 'order',
      render: (order: Order) => {
        return order.postPackage.totalCount;
      },
      sorter: (a, b) =>
        a.order.postPackage.totalCount - b.order.postPackage.totalCount,
    },
    {
      title: '訂單金額',
      dataIndex: 'price',
      sorter: (a, b) => a.price - b.price,
    },
    {
      title: '收件地區',
      dataIndex: 'country',
    },
    {
      title: '寄件地區',
      dataIndex: 'post',
      render: (post: Post) => {
        return post.loc.country;
      },
    },
    {
      title: '標籤',
      dataIndex: 'tags',
      render: (tags: OrderTag[]) => {
        return tags?.map(({ tag }) => {
          return (
            <Tag
              color="cyan"
              style={{ cursor: 'pointer' }}
              key={tag}
              onClick={() =>
                setSearchTags((prev) => Array.from(new Set([...prev, tag])))
              }
            >
              {tag}
            </Tag>
          );
        });
      },
    },
    {
      title: '發票開立狀態',
      dataIndex: 'order',
      render: (order: Order) => {
        const isButtonShown =
          order.isPaid &&
          order.isInvoiced !== true &&
          order.invoiceParam !== null;
        if (isButtonShown) {
          return (
            <Button
              type="primary"
              onClick={() => uploadInvoice(order.readableId as string)}
            >
              補開發票
            </Button>
          );
        }
        return Number(order.price) > 0 && order.isInvoiced === false ? (
          <span style={{ color: 'red' }}>失敗</span>
        ) : (
          '成功'
        );
      },
      sorter: (a, b) => {
        return Number(a.order.isInvoiced) - Number(b.order.isInvoiced);
      },
    },
    {
      title: '付款狀態',
      dataIndex: 'order',
      render: (order: Order) => {
        return order.isPaid ? (
          '已付款'
        ) : (
          <span style={{ color: 'red' }}>未付款</span>
        );
      },
      sorter: (a, b) => {
        return Number(a.order.isPaid) - Number(b.order.isPaid);
      },
    },
    {
      title: 'Tappay交易識別碼',
      dataIndex: 'order',
      render: (order: Order) => {
        return order.isPaid ? (
          order.payment.map((payment) => {
            return <div key={payment.id}>{payment.tappay?.rec_trade_id}</div>;
          })
        ) : (
          <span style={{ color: 'red' }}>N/A</span>
        );
      },
      sorter: (a, b) => {
        return Number(a.order.isPaid) - Number(b.order.isPaid);
      },
    },
    {
      title: '發票號碼',
      dataIndex: 'order',
      render: (order: Order) => {
        return order.isInvoiced ? (
          order.invoice?.map((invoice) => {
            return <div key={invoice.id}>{invoice.number}</div>;
          })
        ) : (
          <span style={{ color: 'red' }}>N/A</span>
        );
      },
      sorter: (a, b) => {
        return Number(a.order.isPaid) - Number(b.order.isPaid);
      },
    },
  ];

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: OrderDetail[]) => {
      setSelectedOrders(selectedRows);
    },
  };

  const handleBatchChangeStatus = (value: string) => {
    showModal();
    setBatchChangingOrders({
      ids: selectedOrders.map((d) => d.id),
      readableIds: selectedOrders.map((d) => d.order.readableId!),
      status: value,
    });
  };

  const handleBatchDownloadPDF = () => {
    const dataToPrint = selectedOrders?.map((d) => ({
      id: d.order.readableId,
      post: d.post,
    }));

    dataToPrint?.forEach((data) => {
      exportPdf(data.id as string, data.post);
    });
  };

  const onPageChange: PaginationProps['onChange'] = (page) => {
    history.push({
      search: `page=${page}`,
      hash: location.hash,
    });
    setCurrentPage(page);
  };

  const onSearch = (value: string) => {
    setSearch(value || null);
  };

  return (
    <div>
      <Row align="middle">
        <Col span={5} className="text-start">
          <Search
            addonBefore="搜尋訂單號碼"
            onSearch={onSearch}
            allowClear
            enterButton
          />
        </Col>
        <Col span={7} className="text-start ps-3">
          訂單日期：
          <RangePicker
            onChange={(data) => {
              if (data !== null) {
                setDateRange([
                  data[0]!.startOf('day').toISOString(),
                  data[1]!.startOf('day').toISOString(),
                ]);
              } else {
                setDateRange(null);
              }
            }}
          />
        </Col>
        <Col span={3} className="text-start">
          <Button onClick={handleBatchDownloadPDF}>
            批次下載明信片 ({selectedOrders.length})
          </Button>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col span={4} className="text-start">
          批次更改狀態：
          <Select
            defaultValue={'請選擇狀態'}
            style={{ width: 120 }}
            onChange={handleBatchChangeStatus}
          >
            <Option value="waitToPrint">待印製</Option>
            <Option value="printed">已印製</Option>
            <Option value="waitToSend">待配送</Option>
            <Option value="sent">已配送</Option>
            <Option value="cancel">已取消</Option>
          </Select>
          <Button
            onClick={async () => {
              await fetchOrderDetails();
            }}
          >
            批次更改註解11
          </Button>
        </Col>
        <Col span={4} className="text-start">
          批次更改狀態：
          <Button onClick={showMemoModal}>批次更改註解</Button>
        </Col>
        <Col span={4} className="text-start">
          顯示：
          <Select
            defaultValue="100"
            style={{ width: 120 }}
            onChange={handleChange}
          >
            <Option value="100">100筆</Option>
          </Select>
        </Col>
      </Row>
      {!isEmpty(searchTags) && (
        <Row align="middle" className="mt-3">
          <Typography.Title className="m-0" level={5}>
            搜尋標籤：
          </Typography.Title>
          {searchTags.map((tag, index) => (
            <Tag key={tag + index} closable onClose={() => removeTags(tag)}>
              {tag}
            </Tag>
          ))}
          <Button danger onClick={() => setSearchTags([])}>
            全部清除
          </Button>
        </Row>
      )}
      <Table
        className="mt-3"
        rowSelection={{
          type: 'checkbox',
          ...rowSelection,
        }}
        dataSource={getSortedData()}
        rowKey={(record) => record.id}
        columns={columns}
        pagination={{
          current: currentPage,
          onChange: onPageChange,
          total: tableData?.length,
        }}
      />
      <Modal
        title="更改訂單狀態"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        更改以下訂單狀態為：
        <b>{STATUS[batchChangingOrders?.status!]}</b>
        <div className="mt-3">
          {batchChangingOrders?.readableIds.map((o) => {
            return (
              <div className="mb-2">
                <Tag color="error">{o}</Tag>
              </div>
            );
          })}
        </div>
      </Modal>

      <Modal
        title="批次增加註解"
        open={isMemoModalOpen}
        onOk={handleMemoOk}
        onCancel={handleMemoCancel}
        destroyOnClose
      >
        更改以下訂單註解為：
        <b>{STATUS[batchChangingOrders?.status!]}</b>
        <div className="mt-3">
          <div className="mb-2">
            <TextArea
              rows={4}
              placeholder="請輸入備註"
              onChange={(e) => setBatchMemo(e.target.value)}
            />
          </div>
        </div>
      </Modal>
    </div>
  );
};
