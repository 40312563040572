import '../App.less';
import './DownloadPostCardPDF.less';
import { Col, Image, Row } from 'antd';

export default (props: {
  headUri: string | undefined;
  rearUri: string | undefined;
  printHorizontal?: boolean;
  isVertical?: boolean;
}) => {
  return (
    <div id="containerToBePrinted">
      <Row
        justify="center"
        align="middle"
        style={{
          marginTop: '10%',
          minHeight: '90vh',
        }}
      >
        <Col style={{ maxWidth: '76.5%' }}>
          {props.isVertical || props.printHorizontal === false ? (
            <div id="vertical">
              <div className="headDiv">
                <Image
                  style={{ border: '5px solid white' }}
                  src={props.rearUri}
                  className="headImageBox"
                />
                <div className="headImage">
                  <Image
                    style={{ border: '5px solid white' }}
                    src={props.headUri}
                  />
                </div>
              </div>
              <div className="left-top-border border-box"></div>
              <div className="right-top-border border-box"></div>
              <div className="left-bottom-border border-box"></div>
              <div className="right-bottom-border border-box"></div>
            </div>
          ) : (
            <div id="horizontal">
              <Image
                style={{ border: '5px solid white' }}
                src={props.headUri}
              />
              <div className="left-top-border border-box"></div>
              <div className="right-top-border border-box"></div>
              <div className="left-bottom-border border-box"></div>
              <div className="right-bottom-border border-box"></div>
            </div>
          )}
        </Col>
      </Row>
      <div className="after" />
      <Row
        justify="center"
        align="middle"
        style={{
          marginTop: '10%',
          minHeight: '90vh',
        }}
      >
        <Col style={{ maxWidth: '76.5%' }}>
          <Image style={{ border: '5px solid white' }} src={props.rearUri} />
          <div className="left-top-border border-box"></div>
          <div className="right-top-border border-box"></div>
          <div className="left-bottom-border border-box"></div>
          <div className="right-bottom-border border-box"></div>
        </Col>
      </Row>
    </div>
  );
};
