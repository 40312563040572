import { Col, Row, Spin, Statistic } from 'antd';
import dayjs from 'dayjs';
import isToday from 'dayjs/plugin/isToday';
import { OrderDetailsQuery } from '../../graphql/types';
dayjs.extend(isToday);

export default function SendingStatusWidgets(props: {
  orderDetails: OrderDetailsQuery['orderDetails'] | undefined;
}) {
  const { orderDetails } = props;
  if (!orderDetails) return <Spin size="large" />;

  return (
    <Row gutter={25} className="mb-3">
      <Col>
        <Statistic
          title="今日新增寄件數"
          value={
            orderDetails.filter(
              (o) =>
                o.status === 'waitToPrint' &&
                dayjs(o.createdAt).isToday() &&
                !(Number(o.order.price) > 0 && o.order.isInvoiced === false)
            ).length
          }
        />
      </Col>
      <Col>
        <Statistic
          title="今日已寄件數"
          value={
            orderDetails.filter(
              (o) =>
                o.status === 'sent' &&
                dayjs(o.createdAt).isToday() &&
                !(Number(o.order.price) > 0 && o.order.isInvoiced === false)
            ).length
          }
        />
      </Col>
      <Col>
        <Statistic
          title="待寄送數量"
          value={
            orderDetails.filter(
              (o) =>
                o.status === 'printed' &&
                !(Number(o.order.price) > 0 && o.order.isInvoiced === false)
            ).length
          }
        />
      </Col>
      <Col>
        <Statistic
          title="待印製"
          value={
            orderDetails.filter(
              (o) =>
                o.status === 'waitToPrint' &&
                !(Number(o.order.price) > 0 && o.order.isInvoiced === false)
            ).length
          }
        />
      </Col>
    </Row>
  );
}
