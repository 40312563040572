import {
  Alert,
  Button,
  Col,
  Form,
  Input,
  Modal,
  RadioChangeEvent,
  Row,
} from 'antd';
import { useState, useEffect } from 'react';
import {
  BenefitMemosQuery,
  ProfitableQuery,
  useCreateOneBenefitTransferMutation,
  useUpdateOneStoreItemMutation,
} from '../../../graphql/types';
import dayjs from 'dayjs';
interface Props {
  isModalOpen: boolean;
  handleOk: () => void;
  handleCancel: () => void;
  initialValue?: ProfitableQuery['profitable'];
  forceUpdate: () => void;
  benefitMemos: BenefitMemosQuery['benefitMemos'][0][];
  curProfitableId: string;
}

const SaleMutationModal = (props: Props) => {
  const {
    benefitMemos,
    handleOk,
    handleCancel,
    isModalOpen,
    forceUpdate,
    curProfitableId,
  } = props;
  const [form] = Form.useForm();

  const [isSnError, setIsSnError] = useState(false);
  const [summary, setSummary] = useState(null);
  useEffect(() => {
    console.log('Hello benefitMemos', benefitMemos);
    if (benefitMemos) {
      // const summary = benefitMemos.reduce((acc, cur) => {}, []);
    }
  }, [benefitMemos]);

  const [updateOneStoreItem] = useUpdateOneStoreItemMutation({
    onCompleted(data) {
      forceUpdate();
      handleOk();
    },
  });

  const [createTransfer] = useCreateOneBenefitTransferMutation({
    onCompleted() {
      forceUpdate();
      handleOk();
    },
    onError(error) {
      console.log('Hello error', error);
      setIsSnError(true);
    },
  });

  const onFinish = async (values: any) => {
    console.log('Hello values', values);
    const { exchangeRate } = values;
    const benifitMemos = benefitMemos.map((memo) => ({
      id: memo.id,
    }));
    const totalAmount = benefitMemos.reduce((acc, cur) => {
      const subTotal = (cur.coin?.subTotal || 0) * exchangeRate;
      return acc + subTotal;
    }, 0);

    console.log('Hello benifitMemos', benifitMemos);
    console.log('Hello totalAmount', totalAmount);
    createTransfer({
      variables: {
        data: {
          benifitMemo: {
            connect: benifitMemos,
          },
          exchangeRateForCoin: Number(exchangeRate),
          profitable: {
            connect: {
              id: curProfitableId,
            },
          },
          totalAmount: totalAmount,
          transferDate: dayjs().toISOString(),
          transferMemo: values,
        },
      },
    });
  };

  const onTypeChange = (e: RadioChangeEvent) => {
    console.log('Hello e.target.value', e.target.value);
  };

  return (
    <Modal
      title={'新增匯款註解'}
      open={isModalOpen}
      // open={true}
      onOk={handleOk}
      onCancel={() => {
        handleCancel();
      }}
      footer={null}
      destroyOnClose={true}
    >
      <Form
        preserve={false}
        form={form}
        name="basic"
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 16 }}
        onFinish={onFinish}
        autoComplete="off"
        initialValues={{
          handler: 'Hank Zhou',
          transferFrom: '123456789',
          transferTo: '11123456789',
          amount: '1000',
          exchangeRate: '33',
          referenceNumber: '12789',
        }}
      >
        <Form.Item
          label="轉出帳戶"
          name={'transferFrom'}
          rules={[{ required: true, message: '欄位為必須' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="轉入帳戶"
          name={'transferTo'}
          rules={[{ required: true, message: '欄位為必須' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="匯率"
          name={'exchangeRate'}
          rules={[{ required: true, message: '欄位為必須' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="金額"
          name={'amount'}
          rules={[{ required: true, message: '欄位為必須' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="銀行轉帳流水號"
          name={'referenceNumber'}
          rules={[{ required: true, message: '欄位為必須' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="經辦人員"
          name={'handler'}
          rules={[{ required: true, message: '欄位為必須' }]}
        >
          <Input />
        </Form.Item>

        {isSnError && (
          <Row className="mb-3">
            <Col span={24}>
              <Alert
                message="商品編號重複。或者創作地點沒有填寫完整（經緯度不填這不行）"
                type="error"
              />
            </Col>
          </Row>
        )}
        <Row justify="end" gutter={16}>
          <Col>
            <Button
              onClick={() => {
                handleCancel();
              }}
            >
              取消
            </Button>
          </Col>
          <Col>
            <Button type="primary" htmlType="submit">
              送出
            </Button>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default SaleMutationModal;
