import Icon, { FileTextOutlined, LogoutOutlined } from '@ant-design/icons';
import { useQuery } from '@apollo/client';
import {
  Avatar,
  Button,
  Col,
  Dropdown,
  Layout,
  Menu,
  Row,
  Space,
  Spin,
} from 'antd';
import { Link, Route, Switch, useRouteMatch } from 'react-router-dom';
import './App.less';
import { userVar } from './cache';
import { GET_USER } from './graphql/user';
import './Layout.less';
import Order from './Order';
import OrderDetail from './OrderDetail';
import { ReactComponent as LogoSvg } from './static/logo.svg'; // path to your '*.svg' file.
import Activites from './pages/activites/ActivitesPage';
import { useState } from 'react';
import CreatorPage from './pages/profitable/Creator/CreatorPage';
import ProductPage from './pages/profitable/Product/ProductPage';
import CreatorDetail from './pages/profitable/Creator/CreatorDetail';
import ProductDetail from './pages/profitable/Product/ProductDetail';
import SalesPage from './pages/profitable/sales/SalesPage';
import SalesOrderPage from './pages/profitable/salesOrder/SalesOrderPage';

const { Header, Content, Footer, Sider } = Layout;
function SiderDemo({ children }: { children?: any }) {
  let { path, url } = useRouteMatch();
  const { data, loading, error } = useQuery(GET_USER);
  const [openKeys, setOpenKeys] = useState(['sub1']);
  if (loading) return <Spin size="large" />;
  if (error) return <p>ERROR: {error.message}</p>;

  const Logout = () => {
    delete localStorage.token;
    delete localStorage.user;
    userVar({});
  };

  const menu = (
    <Menu>
      <Menu.Item key="1">
        <Button type="text" block onClick={Logout}>
          <LogoutOutlined />
          登出
        </Button>
      </Menu.Item>
    </Menu>
  );

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Sider>
        <div style={{ marginTop: 20 }}>
          <Row justify="space-around" align="middle">
            <Col span={8}>
              <Icon component={() => <LogoSvg height="90px" />} />
            </Col>
          </Row>
          <div className="logo">
            <Space size="large"></Space>
          </div>
        </div>
        <Menu theme="dark" mode="inline">
          <Menu.Item key="1" icon={<FileTextOutlined />}>
            <Link to={`${url}order`}>訂單</Link>
          </Menu.Item>
          <Menu.Item key="2" icon={<FileTextOutlined />}>
            <Link to={`${url}activities`}>活動</Link>
          </Menu.Item>

          <Menu.SubMenu title="商店" icon={<FileTextOutlined />}>
            <Menu.Item key="creator" icon={<FileTextOutlined />}>
              <Link to={`${url}creator`}>創作者</Link>
            </Menu.Item>
            <Menu.Item key="product" icon={<FileTextOutlined />}>
              <Link to={`${url}product`}>商品</Link>
            </Menu.Item>
            <Menu.Item key="product-package" icon={<FileTextOutlined />}>
              <Link to={`${url}product-package`}>商品懶人包</Link>
            </Menu.Item>
            {/* <Menu.Item key="profitable" icon={<FileTextOutlined />}>
              <Link to={`${url}profitable`}>收益報表</Link>
            </Menu.Item> */}
          </Menu.SubMenu>

          <Menu.SubMenu title="分潤報表" icon={<FileTextOutlined />}>
            <Menu.Item key="product-sales" icon={<FileTextOutlined />}>
              <Link to={`${url}product-sales`}>線上商店</Link>
            </Menu.Item>
            <Menu.Item key="product-sales-order" icon={<FileTextOutlined />}>
              <Link to={`${url}product-sales-order`}>實體寄送</Link>
            </Menu.Item>
          </Menu.SubMenu>
        </Menu>
      </Sider>
      <Layout className="site-layout">
        <Header className="site-layout-background" style={{ padding: 0 }}>
          <Row justify="end">
            <Col span={4}>
              <Dropdown overlay={menu}>
                <a
                  className="ant-dropdown-link"
                  onClick={(e) => e.preventDefault()}
                >
                  <Avatar
                    style={{
                      backgroundColor: '#2c816e',
                      verticalAlign: 'middle',
                    }}
                    size="large"
                    src={data.user?.headUri}
                  >
                    {data.user?.name}
                  </Avatar>
                </a>
              </Dropdown>
            </Col>
          </Row>
        </Header>
        <Content style={{ padding: '0 16px' }}>
          <Switch>
            <Route path={`${path}order/:id`}>
              <OrderDetail />
            </Route>
            <Route path={`${path}order`}>
              <Order />
            </Route>
            <Route path={`${path}activities`}>
              <Activites />
            </Route>
            <Route path={`${path}profitable/:id`}>
              <CreatorDetail />
            </Route>
            <Route path={`${path}product/:id`}>
              <ProductDetail />
            </Route>
            <Route path={`${path}creator`}>
              <CreatorPage />
            </Route>
            <Route path={`${path}product`}>
              <ProductPage />
            </Route>
            <Route path={`${path}product-package`}>
              <ProductPage />
            </Route>
            <Route path={`${path}product-sales`}>
              <SalesPage />
            </Route>
            <Route path={`${path}product-sales-order`}>
              <SalesOrderPage />
            </Route>
          </Switch>
        </Content>
        <Footer style={{ textAlign: 'center' }}>Copyright ©2021 Postory</Footer>
      </Layout>
    </Layout>
  );
}

export default SiderDemo;
