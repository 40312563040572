import { Tabs } from 'antd';
import React from 'react';
import NormalActivity from './NormalActivity';
import NanHuiaActivity from './NanHuiaActivity';

export default function ActivitesPage() {
  return (
    <div
      className="site-layout-background"
      style={{ padding: 24, minHeight: 360 }}
    >
      <Tabs>
        <Tabs.TabPane tab="2022 一般活動" key="item-1">
          <NormalActivity />
        </Tabs.TabPane>
        <Tabs.TabPane tab="2022臺東南迴藝術季" key="item-2">
          <NanHuiaActivity />
        </Tabs.TabPane>
      </Tabs>
    </div>
  );
}
