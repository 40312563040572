import { Spin, Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import dayjs from 'dayjs';
import { isEmpty } from 'lodash';
import { Link } from 'react-router-dom';
import {
  StoreItemsQuery,
  BenefitMemosQuery,
  BenefitMemo,
} from '../../../graphql/types';

interface Props {
  items: BenefitMemosQuery['benefitMemos'] | undefined;
  rowSelection: {
    onChange: (
      newSelectedRowKeys: React.Key[],
      selectRows: BenefitMemosQuery['benefitMemos'][0][]
    ) => void;
  };
}

function ProductTable({ items, rowSelection }: Props) {
  if (isEmpty(items)) {
    return <Spin />;
  }
  const columns: ColumnsType<BenefitMemosQuery['benefitMemos'][0]> = [
    {
      title: '訂單編號',
      dataIndex: 'coin',
      key: 'coin.readableId',
      render: (coin, data) => {
        return coin.coin.readableId;
      },
    },
    {
      title: '銷售日期',
      dataIndex: 'coin',
      key: 'coin',
      render: (coin, data) => {
        return dayjs(coin.coin.createdAt).format('YYYY-MM-DD');
      },
    },
    {
      title: '銷售品名',
      dataIndex: 'coin',
      key: 'coin.storeItem.name',
      render: (coin, data) => {
        return coin.storeItem.name;
      },
    },
    {
      title: '購買者ID',
      dataIndex: 'coin',
      key: 'coin',
      render: (coin, data) => {
        return `${coin.coin.user.name} (${coin.coin.user.idStr})`;
      },
    },
    {
      title: '創作者ID',
      dataIndex: 'profitable',
      key: 'coin.storeItem.name.id',
      render: (profitable, data) => {
        return `${profitable.user.name} (${profitable.id})`;
      },
    },
    {
      title: '類型',
      dataIndex: 'coin',
      key: 'coin.storeItem.type',
      render: (coin, data) => {
        return coin.storeItem.type === 'postcard' ? '明信片' : '郵票';
      },
    },
    {
      title: '銷售金額(PO幣)',
      dataIndex: 'coin',
      key: 'coin.subTotal',
      render: (coin, data) => {
        return coin.subTotal;
      },
    },
    {
      title: '分潤比例',
      dataIndex: 'coin',
      key: 'coin.ratio',
      render: (coin, data) => {
        return coin.ratio;
      },
    },
    {
      title: '分潤金額(PO幣)',
      dataIndex: 'coin',
      key: 'coin.benefit',
      render: (coin, data) => {
        return coin.benefit;
      },
    },
    {
      title: '換算匯率',
      dataIndex: 'transfer',
      key: 'transfer.ratio',
      render: (transfer, data) => {
        return transfer ? transfer.exchangeRateForCoin : '(尚未匯款)';
      },
    },
    {
      title: '實際分潤金額(台幣)',
      dataIndex: 'coin',
      key: 'coin',
      render: (coin, data) => {
        console.log('Hello coincoincoin', coin);
        const extRate = data.transfer?.exchangeRateForCoin;
        if (extRate) {
          return (coin.benefit * extRate).toFixed(2);
        } else {
          return '尚未匯款';
        }
      },
    },
  ];

  return (
    <Table
      rowKey={(record) => record.id}
      rowSelection={{ ...rowSelection }}
      dataSource={items}
      columns={columns}
    />
  );
}

export default ProductTable;
