import {
  CampaignNanhui,
  SortOrder,
  useCampaignNanhuisQuery,
} from '../../graphql/types';
import { Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
dayjs.extend(utc);
dayjs.extend(timezone);
export default function NanHuiaActivity() {
  const { data: { campaignNanhuis } = {} } = useCampaignNanhuisQuery({
    variables: {
      orderBy: [
        {
          createdAt: SortOrder.Asc,
        },
      ],
    },
  });

  const columns: ColumnsType<CampaignNanhui> = [
    {
      title: '序號',
      dataIndex: 'index',
      key: 'index',
      render: (text, record, index) => index + 1,
    },
    {
      title: '姓名',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: '電話',
      dataIndex: 'phone',
      key: 'phone',
    },
    {
      title: '郵遞區號',
      dataIndex: 'postCode',
      key: 'postCode',
    },

    {
      title: '住址',
      dataIndex: 'address',
      key: 'address',
    },
    {
      title: '填表時間',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (createdAt) =>
        dayjs(createdAt).tz('Asia/Taipei').format('YYYY/MM/DD (ddd) HH:mm'),
    },
  ];
  return (
    <Table
      pagination={{ defaultPageSize: 100 }}
      dataSource={campaignNanhuis as CampaignNanhui[]}
      columns={columns}
    />
  );
}
