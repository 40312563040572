import { Spin } from 'antd';
import { isEmpty } from 'lodash';
import {
  Coin,
  CoinType,
  Profitable,
  SendingType,
  useCoinsQuery,
  useRelatedPracticalSendingsToProfitableQuery,
} from '../../../graphql/types';
import { CreatorList } from './CreatorList';

export type ProfitableWithCoins = Profitable & { coins: Coin[] };
interface Props {
  stateProFitItems: Profitable[];
}
function CreatorTable({ stateProFitItems }: Props) {
  const { data: coinsData } = useCoinsQuery({
    variables: {
      where: {
        type: {
          equals: CoinType.CartitemCheckout,
        },
      },
    },
  });

  const { data: postPackages } = useRelatedPracticalSendingsToProfitableQuery({
    variables: {
      where: {
        sendingType: {
          equals: SendingType.Practical,
        },
      },
    },
  });

  if (isEmpty(stateProFitItems) || !coinsData || !postPackages) {
    return <Spin />;
  }
  const profitablesWithCoins: Array<ProfitableWithCoins> = (
    stateProFitItems as Profitable[]
  ).map((profitable) => ({
    ...profitable,
    coins: (coinsData?.coins as Coin[]).filter((coin) =>
      coin.cart?.storeToCartItems.find(
        (s) => s.storeItem.profitable.userId === profitable.userId
      )
    ),
  }));
  return (
    <CreatorList tableData={profitablesWithCoins as ProfitableWithCoins[]} />
  );
}

export default CreatorTable;
