import { LeftOutlined } from '@ant-design/icons';
import {
  Button,
  Card,
  Col,
  Popconfirm,
  Row,
  Space,
  Spin,
  Table,
  Tag,
  Tooltip,
  Typography,
} from 'antd';
import { ColumnsType } from 'antd/lib/table/interface';
import dayjs from 'dayjs';
import React, { useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import {
  Coin,
  CoinType,
  Profitable,
  ProfitablesDocument,
  StoreItemToCartItem,
  useCoinsQuery,
  useProfitableQuery,
  useUpdateOneProfitableMutation,
} from '../../../graphql/types';
import CreatorMutationModal from './CreatorMutationModal';
import { nationsOption } from './nationsSelectOption';
import { ClassTypeMap, ProfitableTypeMap } from './CreatorList';
export type ProfitableWithCoins = Profitable & { coins: Coin[] };

const { Text } = Typography;

const CreatorDetail = () => {
  const history = useHistory();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [, updateState] = React.useState({});
  const forceUpdate = React.useCallback(() => updateState({}), []);
  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  let { id } = useParams<{ id?: string }>();

  const [updateProfitable] = useUpdateOneProfitableMutation({
    refetchQueries: [
      {
        query: ProfitablesDocument,
      },
    ],
    onCompleted() {
      forceUpdate();
      history.goBack();
    },
  });

  const { error, data: { profitable: curProFitItem } = {} } =
    useProfitableQuery({
      variables: {
        where: {
          id,
        },
      },
    });
  const { data: coinsData } = useCoinsQuery({
    variables: {
      where: {
        type: {
          equals: CoinType.CartitemCheckout,
        },
      },
    },
  });

  const coins = useMemo(
    () =>
      (coinsData?.coins as Coin[])?.filter((coin) =>
        coin.cart?.storeToCartItems.find(
          (s) => s.storeItem.profitable.userId === curProFitItem?.userId
        )
      ),
    [coinsData]
  );

  const coinColumns: ColumnsType<Coin> = [
    {
      title: '訂單編號',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: '時間',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (createdAt) => dayjs(createdAt).format('YYYY/MM/DD (ddd) HH:mm'),
    },
    {
      title: '品項/金額',
      dataIndex: 'cart',
      key: 'cart',
      render: (cart) => (
        <>
          {cart.storeToCartItems.map((item: StoreItemToCartItem) => (
            <Tag color="orange" className="mb-1">
              {item.storeItem.name} / {item.storeItem.price} PO幣
            </Tag>
          ))}
        </>
      ),
    },
    {
      title: '購買者ID',
      dataIndex: 'user',
      key: 'user',
      render: (user) => user.idStr,
    },
  ];

  const deleteProfitable = () => {
    updateProfitable({
      variables: {
        where: {
          id: curProFitItem?.id,
        },
        data: {
          valid: {
            set: false,
          },
        },
      },
    });
  };
  if (!curProFitItem || !coinsData) {
    return <Spin />;
  }

  return (
    <>
      <Row className="mb-3">
        <Col span={12}>
          <Button
            type="primary"
            icon={<LeftOutlined />}
            onClick={() => history.goBack()}
          >
            上一頁
          </Button>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Card bordered={false} style={{ background: '#e6e6e6', margin: 12 }}>
            <Row justify="space-between" className="mb-3">
              <Col>
                <Text strong style={{ color: '#2c816e' }}>
                  創作者
                </Text>
              </Col>
              <Col>
                <Space size={'small'}>
                  <Button type="primary" onClick={showModal}>
                    編輯
                  </Button>
                  <Popconfirm
                    title="刪除這個創作者？"
                    onConfirm={deleteProfitable}
                    okText="YES"
                    cancelText="NO"
                  >
                    <Button type="primary" danger>
                      刪除
                    </Button>
                  </Popconfirm>
                </Space>
              </Col>
            </Row>
            <Row className="mb-2">
              <Col span={12}>
                <Row>
                  <Col className="me-3" span={4}>
                    <Text strong>姓名</Text>
                  </Col>
                  <Col>
                    <Text>{curProFitItem.user.name}</Text>
                    <Popconfirm
                      icon={null}
                      title={
                        <div style={{ fontSize: '1.1rem' }}>
                          <p>
                            <b>profitableId</b>: {curProFitItem.id}
                          </p>
                          <p>
                            <b>userId</b>: {curProFitItem.userId}
                          </p>
                          <p>
                            <b>idStr</b>: {curProFitItem.user.idStr}
                          </p>
                        </div>
                      }
                      okText="明白了"
                      showCancel={false}
                    >
                      <Button className="ms-3">看！IDs</Button>
                    </Popconfirm>
                  </Col>
                </Row>
              </Col>
              <Col span={12}>
                <Row>
                  <Col className="me-3" span={4}>
                    <Text strong>國家</Text>
                  </Col>
                  <Col>
                    <Text>
                      {
                        nationsOption.find(
                          (n) =>
                            n.value ===
                            curProFitItem.user.country?.toUpperCase()
                        )?.label
                      }
                    </Text>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row className="mb-2">
              <Col span={12}>
                <Row>
                  <Col className="me-3" span={4}>
                    <Text strong>商店銷售分潤</Text>
                  </Col>
                  <Col>
                    <Text>
                      {curProFitItem.saleRatio?.value} (
                      {curProFitItem.saleRatio?.description})
                    </Text>
                  </Col>
                </Row>
              </Col>
              <Col span={12}>
                <Row>
                  <Col className="me-3" span={4}>
                    <Text strong>寄送銷售分潤</Text>
                  </Col>
                  <Col>
                    <Text>
                      {curProFitItem.sendRatio?.value} (
                      {curProFitItem.sendRatio?.description})
                    </Text>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row className="mb-2">
              <Col span={12}>
                <Row>
                  <Col className="me-3" span={4}>
                    <Text strong>創作者類型</Text>
                  </Col>
                  <Col>
                    <Text>{ProfitableTypeMap[curProFitItem.type]}</Text>
                  </Col>
                </Row>
              </Col>
              <Col span={12}>
                <Row>
                  <Col className="me-3" span={4}>
                    <Text strong>Class</Text>
                  </Col>
                  <Col>
                    <Text>{ClassTypeMap[curProFitItem.class]}</Text>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row className="mb-2">
              <Col span={12}>
                <Row>
                  <Col className="me-3" span={4}>
                    <Text strong>創作者狀態短語</Text>
                  </Col>
                  <Col>
                    <Text>{curProFitItem.statusText}</Text>
                  </Col>
                </Row>
              </Col>
              <Col span={12}>
                <Row>
                  <Col className="me-3" span={4}>
                    <Text strong>關於創作者</Text>
                  </Col>
                  <Col>
                    <Text>{curProFitItem.bio}</Text>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Card>
        </Col>

        <Col span={24}>
          <Card bordered={false} style={{ background: '#e6e6e6', margin: 12 }}>
            <Row>
              <Col>
                <Text strong style={{ color: '#2c816e' }}>
                  商店銷售
                </Text>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Table dataSource={coins} columns={coinColumns} />
              </Col>
            </Row>
          </Card>
        </Col>
        <Col span={12}>Order placeholder</Col>
      </Row>
      <CreatorMutationModal
        isModalOpen={isModalOpen}
        handleOk={handleOk}
        handleCancel={handleCancel}
        initialValue={curProFitItem}
        forceUpdate={forceUpdate}
      />
    </>
  );
};

export default CreatorDetail;
