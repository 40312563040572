import './App.less';
import { Tabs, Spin, Result, Button } from 'antd';
import OrdersTable from './components/OrdersTable';
import SendingStatusWidgets from './components/widget/SendingStatusWidgets';
import { OrderDetail, SortOrder, useOrderDetailsQuery } from './graphql/types';
import { useHistory, useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';

export const orderValidStartDateTime = '2022-07-01T00:00:00.001Z';

const { TabPane } = Tabs;

export default () => {
  const {
    data,
    loading,
    error,
    refetch: refetchOrder,
  } = useOrderDetailsQuery({
    variables: {
      where: {
        createdAt: {
          gt: orderValidStartDateTime,
        },
      },
      orderBy: {
        createdAt: SortOrder.Desc,
      },
    },
  });
  const history = useHistory();
  const location = useLocation();
  const [activeTab, setActiveTab] = useState('all');

  useEffect(() => {
    if (location.hash) {
      setActiveTab(location.hash.replace('#', ''));
    }
  }, [location]);
  if (loading || !data) return <Spin size="large" />;
  if (error) {
    return (
      <Result
        status="warning"
        title="讀取不到訂單資料."
        extra={
          <Button type="primary" key="console">
            Go Console
          </Button>
        }
      />
    );
  }

  return (
    <div>
      <div
        className="site-layout-background"
        style={{ padding: 24, minHeight: 360 }}
      >
        <SendingStatusWidgets orderDetails={data.orderDetails} />
        <Tabs
          defaultActiveKey="all"
          activeKey={activeTab}
          onChange={(type: string) => {
            history.push({
              pathname: history.location.pathname,
              hash: type,
            });
          }}
        >
          <TabPane tab="全部" key="all">
            <OrdersTable tableData={data.orderDetails as OrderDetail[]} />
          </TabPane>
          <TabPane tab="待印製" key="waitToPrint">
            <OrdersTable
              tableData={(data.orderDetails as OrderDetail[]).filter(
                ({ status, order }) =>
                  status === 'waitToPrint' &&
                  !(Number(order.price) > 0 && order.isInvoiced === false)
              )}
            />
          </TabPane>
          <TabPane tab="已印製" key="printed">
            <OrdersTable
              tableData={(data.orderDetails as OrderDetail[]).filter(
                ({ status, order }) =>
                  status === 'printed' &&
                  !(Number(order.price) > 0 && order.isInvoiced === false)
              )}
            />
          </TabPane>
          <TabPane tab="已配送" key="sent">
            <OrdersTable
              tableData={(data.orderDetails as OrderDetail[]).filter(
                ({ status, order }) =>
                  status === 'sent' &&
                  !(Number(order.price) > 0 && order.isInvoiced === false)
              )}
            />
          </TabPane>
          <TabPane tab="已接收" key="received">
            <OrdersTable
              tableData={(data.orderDetails as OrderDetail[]).filter(
                ({ status, order }) =>
                  status === 'received' &&
                  !(Number(order.price) > 0 && order.isInvoiced === false)
              )}
            />
          </TabPane>
          <TabPane tab="未完成" key="unfinsh">
            <OrdersTable
              tableData={(data.orderDetails as OrderDetail[]).filter(
                ({ order }) => {
                  return (
                    !order.isPaid ||
                    (Number(order.price) > 0 && order.isInvoiced === false)
                  );
                }
              )}
            />
          </TabPane>
          <TabPane tab="待修復發票訂單" key="needToBeFixed">
            <OrdersTable
              tableData={(data.orderDetails as OrderDetail[]).filter(
                ({ order }) => {
                  return (
                    order.isPaid &&
                    order.isInvoiced !== true &&
                    order.invoiceParam !== null
                  );
                }
              )}
            />
          </TabPane>
        </Tabs>
      </div>
    </div>
  );
};
