import { Button, Col, Row, Select, Checkbox } from 'antd';
import React, { useEffect, useState } from 'react';
import {
  Profitable,
  SortOrder,
  StoreItem,
  StoreItemsQuery,
  useProfitablesQuery,
  useStoreItemsQuery,
} from '../../../graphql/types';
import ProductMutationModal from './ProductMutationModal';
import ProductTable from './ProductTable';

const CreatorPage = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [stateProFitItems, setStateProFitItems] = useState<Profitable[]>([]);
  const [stateStoreItems, setStateStoreItems] = useState<
    StoreItemsQuery['storeItems']
  >([]);
  const [curType, setCurType] = useState('');
  const [curProId, setCurProId] = useState('');
  const forceUpdate = React.useCallback(async () => {
    const newFetchedData = await refetchStoreItems();
    if (newFetchedData) {
      setStateStoreItems(newFetchedData.data.storeItems as StoreItem[]);
    }
  }, []);
  const [isValid4Query, setIsValid4Query] = useState(true);

  useEffect(() => {
    setStateStoreItems(
      storeItems?.filter((item) => {
        if (curProId && curType) {
          return item.profitable.id === curProId && item.type === curType;
        }
        if (curProId) {
          return item.profitable.id === curProId;
        }
        if (curType) {
          return item.type === curType;
        }
        if (curType === null && curProId === null) {
          return true;
        }
      }) as unknown as StoreItemsQuery['storeItems']
    );
  }, [curType, curProId]);

  const { refetch } = useProfitablesQuery({
    variables: {
      where: {
        valid: {
          equals: true,
        },
      },
    },
    onCompleted(data) {
      setStateProFitItems(data.profitables as Profitable[]);
    },
  });

  const {
    data: { storeItems } = {},
    refetch: refetchStoreItems,
    loading: storeItemLoading,
  } = useStoreItemsQuery({
    variables: {
      where: {
        valid: {
          equals: isValid4Query,
        },
      },
      orderBy: {
        createdAt: SortOrder.Desc,
      },
    },
    onCompleted(data) {
      setStateStoreItems(data.storeItems);
    },
  });

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const setCreator = (id: string) => {
    setCurProId(id);
  };

  const setProdType = (type: string) => {
    setCurType(type);
  };

  const onChange_Valid = (e: any) => {
    setIsValid4Query(e.target.checked);
  };

  const toggleValid4Query = () => {
    setIsValid4Query(!isValid4Query);
  };

  useEffect(() => {
    forceUpdate();
  }, [isValid4Query]);

  return (
    <div
      className="site-layout-background"
      style={{ padding: 24, minHeight: 360 }}
    >
      <Row gutter={15}>
        <Col>
          <Button className="mb-3" onClick={showModal}>
            新增商品
          </Button>
        </Col>
        <Col>
          <Select
            defaultValue={'postcard'}
            style={{ width: 250 }}
            onChange={setProdType}
          >
            <Select.Option key={'typeDefault'} value={null}>
              全部商品
            </Select.Option>
            <Select.Option key={'postcard'} value={'postcard'}>
              明信片
            </Select.Option>
            <Select.Option key={'stamp'} value={'stamp'}>
              郵票
            </Select.Option>
          </Select>
        </Col>
        <Col>
          <Select
            style={{ width: 250 }}
            onChange={setCreator}
            placeholder="請選擇創作者"
          >
            <Select.Option key={'default'} value={null}>
              全部創作者
            </Select.Option>
            {stateProFitItems.map((item) => (
              <Select.Option key={item.id} value={item.id}>
                {item.user.name}
              </Select.Option>
            ))}
          </Select>
        </Col>
        <Col>
          <Checkbox
            checked={isValid4Query}
            onChange={onChange_Valid}
          ></Checkbox>
          <Button type="text" size="small" onClick={toggleValid4Query}>
            僅看有效的商品
          </Button>
        </Col>
      </Row>
      <ProductMutationModal
        isModalOpen={isModalOpen}
        handleOk={handleOk}
        handleCancel={handleCancel}
        forceUpdate={forceUpdate}
        proFitItems={stateProFitItems}
      />
      {!storeItemLoading && stateStoreItems?.length === 0 ? (
        '沒有相關商品'
      ) : (
        <ProductTable storeItems={stateStoreItems} />
      )}
    </div>
  );
};

export default CreatorPage;
