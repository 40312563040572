import { useState } from 'react';
import './App.less';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Login from './Login';
import Layout from './Layout';
import { gql, useQuery } from '@apollo/client';
import { Spin } from 'antd';
import { GET_USER } from './graphql/user';

function App() {
  const { data, loading, error } = useQuery(GET_USER);

  if (loading) return <Spin size="large" />;
  if (error) return <p>ERROR: {error.message}</p>;

  return (
    <BrowserRouter>
      <Switch>
        <Route path="/">
          {data.user?.accessToken ? <Layout /> : <Login />}
        </Route>
      </Switch>
    </BrowserRouter>
  );
}

export default App;
