import { Button } from 'antd';
import React, { useEffect, useState } from 'react';
import { Profitable, useProfitablesQuery } from '../../../graphql/types';
import CreatorMutationModal from './CreatorMutationModal';
import CreatorTable from './CreatorTable';

const CreatorPage = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [stateProFitItems, setStateProFitItems] = useState<Profitable[]>([]);
  const forceUpdate = React.useCallback(async () => {
    const newFetchedData = await refetch();
    setStateProFitItems(newFetchedData.data.profitables as Profitable[]);
  }, []);
  const { data, refetch } = useProfitablesQuery({
    variables: {
      where: {
        valid: {
          equals: true,
        },
      },
    },
    onCompleted(data) {
      setStateProFitItems(data.profitables as Profitable[]);
    },
  });
  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    forceUpdate();
  }, []);

  return (
    <div
      className="site-layout-background"
      style={{ padding: 24, minHeight: 360 }}
    >
      <Button className="mb-3" onClick={showModal}>
        新增創作者
      </Button>
      <CreatorMutationModal
        isModalOpen={isModalOpen}
        handleOk={handleOk}
        handleCancel={handleCancel}
        forceUpdate={forceUpdate}
      />
      <CreatorTable stateProFitItems={stateProFitItems} />
    </div>
  );
};

export default CreatorPage;
