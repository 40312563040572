import { Form, Input, Button, Checkbox, Col, Row, message } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import './App.less';
import './Login.less';
import PropTypes from 'prop-types';
import { useState } from 'react';
import Icon from '@ant-design/icons';
import { ReactComponent as LogoSvg } from './static/logo.svg'; // path to your '*.svg' file.
import { Footer } from 'antd/lib/layout/layout';
import { userVar } from './cache';
let base64 = require('base-64');

async function loginUser(credentials: {
  username: undefined;
  password: undefined;
}) {
  return fetch(`${process.env.REACT_APP_RESTFUL_ENDPOINT}/login`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization:
        'Basic ' +
        base64.encode(credentials.username + ':' + credentials.password),
    },
  }).then((data) => data.json());
}

const Login = () => {
  const [remember, setRemember] = useState(localStorage.remember);
  const [username, setUserName] = useState(localStorage.username);
  const [password, setPassword] = useState(localStorage.password);
  const [form] = Form.useForm();

  const onChange = (e: any) => {
    localStorage.remember = e.target.checked;
    if (localStorage.remember === 'false') {
      delete localStorage.username;
      delete localStorage.password;
    }
    setRemember(e.target.checked);
  };

  const onFinish = async ({ username, password }: any) => {
    try {
      const { data: user } = await loginUser({
        username,
        password,
      });
      localStorage.token = user.accessToken;
      localStorage.userId = user.id;
      localStorage.user = JSON.stringify(user);
      if (form.getFieldValue('remember')) {
        localStorage.remember = form.getFieldValue('remember');
        localStorage.username = username;
        localStorage.password = password;
      }
      userVar(user);
    } catch (err) {
      message.error('帳號或密碼錯誤');
    }
  };

  return (
    <Row justify="space-around" align="middle" style={{ minHeight: '100vh' }}>
      <Col span={6}>
        <Row justify="space-around" align="middle" style={{ marginBottom: 40 }}>
          <Col span={8}></Col>
          <Col span={8}>
            <Icon component={() => <LogoSvg width="120px" />} />
          </Col>
          <Col span={8}></Col>
        </Row>
        <Form
          form={form}
          name="normal_login"
          className="login-form"
          initialValues={{
            remember: remember === 'true',
            username,
            password,
          }}
          onFinish={onFinish}
        >
          <Form.Item
            name="username"
            rules={[{ required: true, message: '請輸入帳號' }]}
          >
            <Input
              prefix={<UserOutlined className="site-form-item-icon" />}
              placeholder="帳號: dev@postory.app"
            />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[{ required: true, message: '請輸入密碼' }]}
          >
            <Input
              prefix={<LockOutlined className="site-form-item-icon" />}
              type="password"
              placeholder="密碼: postory.app"
            />
          </Form.Item>
          <Form.Item>
            <Form.Item name="remember" valuePropName="checked" noStyle>
              <Checkbox onChange={onChange}>記住我</Checkbox>
            </Form.Item>

            {/* <a className="login-form-forgot" href="">
                            Forgot password
                        </a> */}
          </Form.Item>

          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className="login-form-button"
            >
              登入
            </Button>
            {/* Or <a href="">register now!</a> */}
          </Form.Item>
        </Form>
        <Footer style={{ textAlign: 'center' }}>Copyright ©2021 Postory</Footer>
      </Col>
    </Row>
  );
};

export default Login;
