import { Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { Link } from 'react-router-dom';
import { ProfitableWithCoins } from './CreatorDetail';

export const ProfitableTypeMap: Record<string, string> = {
  officialCorpCreator: '官方合作創作者',
  normalCreator: '一般創作者',
  company: '	POSTORY官方',
};

export const ClassTypeMap: Record<string, string> = {
  highQuality: '優質',
  none: '無',
};

interface Props {
  tableData: ProfitableWithCoins[];
}

export const CreatorList = ({ tableData }: Props) => {
  const columns: ColumnsType<ProfitableWithCoins> = [
    {
      title: '創作者名稱',
      dataIndex: 'user',
      key: 'user',
      render: (user, data) => (
        <Link to={`profitable/${data.id}`}>{user.name}</Link>
      ),
    },
    {
      title: '創作者狀態短語',
      dataIndex: 'statusText',
      key: 'statusText',
    },
    {
      title: '創作者類型',
      dataIndex: 'type',
      key: 'type',
      render: (type) => ProfitableTypeMap[type],
    },

    {
      title: '商品數',
      dataIndex: 'storeItems',
      key: 'storeItems',
      render: (storeItems) => storeItems.length,
    },
    {
      title: '銷售量',
      dataIndex: 'saleCount',
      key: 'saleCount',
    },
    {
      title: '追蹤人數',
      dataIndex: 'followedBy',
      key: 'followedBy',
      render: (followedBy) => followedBy.length,
    },
  ];

  return <Table dataSource={tableData} columns={columns} />;
};
