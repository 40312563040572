import { LeftOutlined } from '@ant-design/icons';
import {
  Button,
  Card,
  Col,
  Popconfirm,
  Row,
  Space,
  Spin,
  Typography,
} from 'antd';
import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import {
  Coin,
  Profitable,
  ProfitablesDocument,
  useStoreItemQuery,
  useUpdateOneProfitableMutation,
  useProfitablesQuery,
  useUpdateOneStoreItemMutation,
} from '../../../graphql/types';

import ProductMutationModal from './ProductMutationModal';
import { isEmpty } from 'lodash';

export type ProfitableWithCoins = Profitable & { coins: Coin[] };

const { Text } = Typography;
const ProductDetail = () => {
  const history = useHistory();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const forceUpdate = React.useCallback(() => refetchSotreItem(), []);
  const [stateProFitItems, setStateProFitItems] = useState<Profitable[]>([]);

  const { refetch } = useProfitablesQuery({
    variables: {
      where: {
        valid: {
          equals: true,
        },
      },
    },
    onCompleted(data) {
      setStateProFitItems(data.profitables as Profitable[]);
    },
  });

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  let { id } = useParams<{ id?: string }>();

  const [updateProfitable] = useUpdateOneProfitableMutation({
    refetchQueries: [
      {
        query: ProfitablesDocument,
      },
    ],
    onCompleted() {
      forceUpdate();
      history.goBack();
    },
  });

  const {
    error,
    data: { storeItem: curStoreItem } = {},
    refetch: refetchSotreItem,
  } = useStoreItemQuery({
    variables: {
      where: {
        id,
      },
    },
  });

  console.log('Hello curStoreItem', curStoreItem);
  const [updateOneStoreItem] = useUpdateOneStoreItemMutation({
    onCompleted(data) {
      forceUpdate();
      history.push('/product');
    },
  });
  const deleteProduct = () => {
    updateOneStoreItem({
      variables: {
        where: {
          id: curStoreItem?.id,
        },
        data: {
          valid: {
            set: false,
          },
        },
      },
    });
  };
  if (!curStoreItem) {
    return <Spin />;
  }

  return (
    <>
      <Row className="mb-3">
        <Col span={12}>
          <Button
            type="primary"
            icon={<LeftOutlined />}
            onClick={() => history.goBack()}
          >
            上一頁
          </Button>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Card bordered={false} style={{ background: '#e6e6e6', margin: 12 }}>
            <Row justify="space-between" className="mb-3">
              <Col>
                <Text strong style={{ color: '#2c816e' }}>
                  <p>
                    商品編號 (
                    {curStoreItem.itemCard?.sn || curStoreItem.itemStamp?.sn})
                  </p>
                  <p>商品ID ({curStoreItem.id})</p>
                </Text>
              </Col>
              <Col>
                <Space size={'small'}>
                  <Button type="primary" onClick={showModal}>
                    編輯
                  </Button>
                  <Popconfirm
                    title="刪除這個商品？"
                    onConfirm={deleteProduct}
                    okText="YES"
                    cancelText="NO"
                  >
                    <Button type="primary" danger>
                      刪除
                    </Button>
                  </Popconfirm>
                </Space>
              </Col>
            </Row>
            <Row className="mb-2">
              <Col span={12}>
                <Row>
                  <Col className="me-3" span={4}>
                    <Text strong>商品名稱</Text>
                  </Col>
                  <Col>
                    <Text>
                      {curStoreItem.itemCard?.name ||
                        curStoreItem.itemStamp?.name}
                    </Text>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row className="mb-2">
              <Col span={12}>
                <Row>
                  <Col className="me-3" span={4}>
                    <Text strong>商品類型</Text>
                  </Col>
                  <Col>
                    <Text>
                      {curStoreItem.type === 'postcard' ? '明信片' : '郵票'}
                    </Text>
                  </Col>
                </Row>
              </Col>
              <Col span={12}>
                <Row>
                  <Col className="me-3" span={4}>
                    <Text strong>商品金額</Text>
                  </Col>
                  <Col>
                    <Text>
                      {curStoreItem.itemCard?.price ||
                        curStoreItem.itemStamp?.price}
                    </Text>
                  </Col>
                </Row>
              </Col>
              {!isEmpty(curStoreItem.itemCard) && (
                <Col span={12}>
                  <Row>
                    <Col className="me-3" span={4}>
                      <Text strong>明信片水平</Text>
                    </Col>
                    <Col>
                      <Text>
                        {curStoreItem.itemCard?.orientation === 'Horizontal'
                          ? '橫式'
                          : '直式'}
                      </Text>
                    </Col>
                  </Row>
                </Col>
              )}
              <Col span={12}>
                <Row>
                  <Col className="me-3" span={4}>
                    <Text strong>是否為免費商品</Text>
                  </Col>
                  <Col>
                    <Text>
                      {curStoreItem.itemCard?.free ||
                      curStoreItem.itemStamp?.free
                        ? '是'
                        : '否'}
                    </Text>
                  </Col>
                </Row>
              </Col>
              <Col span={12}>
                <Row>
                  <Col className="me-3" span={4}>
                    <Text strong>創作者</Text>
                  </Col>
                  <Col>
                    <Text>
                      {curStoreItem.profitable.user.name}
                      id: {curStoreItem.profitable.id}
                    </Text>
                  </Col>
                </Row>
              </Col>
              <Col span={12}>
                <Row>
                  <Col className="me-3" span={4}>
                    <Text strong>商品詳細介紹</Text>
                  </Col>
                  <Col>
                    <Text>
                      {curStoreItem.itemCard?.description ||
                        curStoreItem.itemStamp?.description}
                    </Text>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row className="mt-5">
              <Col span={24}>
                <Row>
                  <Col className="me-3" span={4}>
                    <Text strong>商品預覽</Text>
                  </Col>
                  <Col>
                    {curStoreItem.itemCard ? (
                      <img width={600} src={curStoreItem.itemCard?.headUri} />
                    ) : (
                      <img width={600} src={curStoreItem.itemStamp?.picUri} />
                    )}
                  </Col>
                </Row>
              </Col>
            </Row>
          </Card>
        </Col>
        <ProductMutationModal
          isModalOpen={isModalOpen}
          handleOk={handleOk}
          handleCancel={handleCancel}
          forceUpdate={forceUpdate}
          proFitItems={stateProFitItems}
          curStoreItem={curStoreItem}
        />
      </Row>
    </>
  );
};

export default ProductDetail;
