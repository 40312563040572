import {
  Alert,
  Button,
  Col,
  Form,
  Input,
  Modal,
  Radio,
  RadioChangeEvent,
  Row,
  Select,
  Upload,
} from 'antd';
import { isEmpty } from 'lodash';
import { useEffect, useState } from 'react';
import {
  CardOrientation,
  Profitable,
  ProfitableQuery,
  StoreItemCreateInput,
  StoreItemType,
  useNewStoreItemCardMutation,
  StoreItemsQuery,
  useUpdateOneStoreItemMutation,
  StoreItemUpdateInput,
} from '../../../graphql/types';
import { nationsOption } from '../Creator/nationsSelectOption';

type PostCardSizes = [number, number, number, number];
type StampSizes = [number, number];
interface Props {
  isModalOpen: boolean;
  handleOk: () => void;
  handleCancel: () => void;
  initialValue?: ProfitableQuery['profitable'];
  forceUpdate: () => void;
  proFitItems: Profitable[];
  curStoreItem?: StoreItemsQuery['storeItems'][0];
}

const ProductMutationModal = (props: Props) => {
  const {
    proFitItems,
    handleOk,
    handleCancel,
    isModalOpen,
    forceUpdate,
    curStoreItem,
  } = props;
  const [form] = Form.useForm();

  const [productType, setProductType] = useState<string>('postcard');
  const [isEditMode, setIsEditMode] = useState(!isEmpty(curStoreItem));
  const [isWrongSize, setWrongSize] = useState(false);
  const [fileName, setFileName] = useState<string>();
  const [isSnError, setIsSnError] = useState(false);
  const getGeoInfo = (geoString: string): String[] => {
    return geoString
      .split(',')
      .map((v) => (Number(v) * 1000000).toString().split('.')[0]);
  };
  useEffect(() => {
    if (curStoreItem) {
      const { itemCard, itemStamp, type, profitable } = curStoreItem;
      const freeOption = itemCard ? itemCard.free : itemStamp?.free;
      const validOption = true;
      form.setFieldsValue({
        productType: type,
        free: freeOption,
        valid: validOption,
        sn: itemCard?.sn || itemStamp?.sn,
        profitableIDStr: profitable.user.idStr,
        price: itemCard?.price || itemStamp?.price,
        name: itemCard?.name || itemStamp?.name,
        description: itemCard?.description || itemStamp?.description,
        country:
          itemCard?.scenicSpot?.country || itemStamp?.scenicSpot?.country,
        latitude:
          itemCard?.scenicSpot?.latitude || itemStamp?.scenicSpot?.latitude,
        longitude:
          itemCard?.scenicSpot?.longitude || itemStamp?.scenicSpot?.longitude,
        locationName: itemCard?.scenicSpot?.name || itemStamp?.scenicSpot?.name,
        locationDescription:
          itemCard?.scenicSpot?.description ||
          itemStamp?.scenicSpot?.description,
      });
    }
  }, [curStoreItem]);

  const [updateOneStoreItem] = useUpdateOneStoreItemMutation({
    onCompleted(data) {
      forceUpdate();
      handleOk();
    },
    onError(error) {
      setIsSnError(true);
      console.log('createOneStoreItem: ', error);
    },
  });

  const uploadPicture = async (file: File, ownerId: string) => {
    const formData = new FormData();
    formData.append('attachment', file);
    formData.append('ownerId', ownerId);
    return await fetch(`${process.env.REACT_APP_RESTFUL_ENDPOINT}/attachment`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
      body: formData,
    })
      .then((res) => {
        return res.json();
      })
      .catch((err) => {});
  };

  const [createOneStoreItem] = useNewStoreItemCardMutation({
    onCompleted() {
      forceUpdate();
      handleOk();
    },
    onError(error) {
      setIsSnError(true);
      console.log('createOneStoreItem: ', error);
    },
  });

  const checkImgSize = async (
    file: File,
    sizes: PostCardSizes | StampSizes
  ) => {
    const imageUrl = URL.createObjectURL(file);
    const img = new Image();
    img.src = imageUrl;
    return new Promise((resolve) => {
      img.onload = async () => {
        if (
          (img.height === sizes[0] && img.width === sizes[1]) ||
          (img.height === sizes[2] && img.width === sizes[3])
        ) {
          setWrongSize(false);
          resolve(img.height === 1754 ? 'vertical' : 'horizontal');
        } else {
          setWrongSize(true);
          resolve(false);
        }
      };
    });
  };

  const onFinish = async (values: any) => {
    if (!isEditMode) {
      const { fileToUpload, profitableIDStr } = values;
      let headUri = '';
      const checkSizes: PostCardSizes | StampSizes =
        productType === 'postcard' ? [1240, 1754, 1754, 1240] : [346, 266];

      const orientation = await checkImgSize(fileToUpload.file, checkSizes);
      if (fileToUpload && !orientation) {
        form.setFields([
          {
            name: 'itemCard',
            errors: ['尺寸錯誤'],
          },
        ]);
      }

      const selectedProfitable = proFitItems.find(
        (item) => item.user.idStr === profitableIDStr
      );
      if (!orientation) {
        return;
      } else {
        const { recommandFileUri } = await uploadPicture(
          form.getFieldValue('fileToUpload').file,
          selectedProfitable?.userId!
        );
        headUri = recommandFileUri;
      }

      let {
        price,
        sn,
        free,
        valid,
        description,
        name,
        country,
        geoInfo,
        locationName,
        locationDescription,
      } = form.getFieldsValue();

      let post_body = {
        type:
          productType === 'postcard'
            ? StoreItemType.Postcard
            : StoreItemType.Stamp,
        readableId: sn,
        price: price,
        valid: valid,
        ...(productType === 'postcard'
          ? {
              itemCard: {
                create: {
                  sn: sn,
                  price: Number(price),
                  free: free,
                  headUri: headUri,
                  tailUri:
                    'https://api.postory.app/api/v1/attachment/rear-postcard',
                  orientation:
                    orientation === 'vertical'
                      ? CardOrientation.Vertical
                      : CardOrientation.Horizontal,
                  name: name,
                  description: description,
                  creator: {
                    connect: {
                      id: selectedProfitable?.userId,
                    },
                  },
                  ...(geoInfo && {
                    scenicSpot: {
                      connectOrCreate: {
                        where: {
                          latitude_longitude: {
                            latitude: Number(getGeoInfo(geoInfo)[0]),
                            longitude: Number(getGeoInfo(geoInfo)[1]),
                          },
                        },
                        create: {
                          country,
                          latitude: Number(getGeoInfo(geoInfo)[0]),
                          longitude: Number(getGeoInfo(geoInfo)[1]),
                          name: locationName,
                          description: locationDescription,
                        },
                      },
                    },
                  }),
                },
              },
            }
          : {
              itemStamp: {
                create: {
                  sn: sn,
                  price: Number(price),
                  free: free,
                  picUri: headUri,
                  name: name,
                  description: description,
                  creator: {
                    connect: {
                      id: selectedProfitable?.userId,
                    },
                  },
                  ...(geoInfo && {
                    scenicSpot: {
                      connectOrCreate: {
                        where: {
                          latitude_longitude: {
                            latitude: Number(getGeoInfo(geoInfo)[0]),
                            longitude: Number(getGeoInfo(geoInfo)[1]),
                          },
                        },
                        create: {
                          country,
                          latitude: Number(getGeoInfo(geoInfo)[0]),
                          longitude: Number(getGeoInfo(geoInfo)[1]),
                          name: locationName,
                          description: locationDescription,
                        },
                      },
                    },
                  }),
                },
              },
            }),
        profitable: {
          connect: {
            id: selectedProfitable?.id,
          },
        },
      };
      createOneStoreItem({
        variables: {
          data: post_body as StoreItemCreateInput,
        },
      });
    } else {
      let {
        price,
        sn,
        free,
        valid,
        description,
        name,
        country,
        geoInfo,
        locationName,
        locationDescription,
      } = form.getFieldsValue();

      let edit_body = {
        type: { set: curStoreItem?.type },
        readableId: { set: sn },
        price: { set: String(price) },
        valid: { set: valid },
        ...(curStoreItem?.type === 'postcard'
          ? {
              itemCard: {
                update: {
                  sn: { set: sn },
                  price: { set: Number(price) },
                  free: { set: free },
                  headUri: { set: curStoreItem?.itemCard?.headUri },
                  // tailUri: {set:'https://api.postory.app/api/v1/attachment/rear-postcard'},
                  orientation: { set: curStoreItem?.itemCard?.orientation },
                  name: { set: name },
                  description: { set: description },
                  ...(geoInfo && {
                    scenicSpot: {
                      connectOrCreate: {
                        where: {
                          latitude_longitude: {
                            latitude: Number(getGeoInfo(geoInfo)[0]),
                            longitude: Number(getGeoInfo(geoInfo)[1]),
                          },
                        },
                        create: {
                          country,
                          latitude: Number(getGeoInfo(geoInfo)[0]),
                          longitude: Number(getGeoInfo(geoInfo)[1]),
                          name: locationName,
                          description: locationDescription,
                        },
                      },
                      // upsert: {
                      //   create: {
                      //     country: country,
                      //     latitude: Number(getGeoInfo(geoInfo)[0]),
                      //     longitude: Number(getGeoInfo(geoInfo)[1]),
                      //     name: locationName,
                      //     description: locationDescription,
                      //   },
                      //   update: {
                      //     country: { set: country },
                      //     latitude: { set: Number(getGeoInfo(geoInfo)[0]) },
                      //     longitude: { set: Number(getGeoInfo(geoInfo)[1]) },
                      //     name: { set: locationName },
                      //     description: { set: locationDescription },
                      //   },
                      // },
                    },
                  }),
                },
              },
            }
          : {
              itemStamp: {
                update: {
                  sn: { set: sn },
                  price: { set: Number(price) },
                  free: { set: free },
                  picUri: { set: curStoreItem?.itemStamp?.picUri },
                  name: { set: name },
                  description: { set: description },
                  ...(geoInfo && {
                    scenicSpot: {
                      connectOrCreate: {
                        where: {
                          latitude_longitude: {
                            latitude: Number(getGeoInfo(geoInfo)[0]),
                            longitude: Number(getGeoInfo(geoInfo)[1]),
                          },
                        },
                        create: {
                          country,
                          latitude: Number(getGeoInfo(geoInfo)[0]),
                          longitude: Number(getGeoInfo(geoInfo)[1]),
                          name: locationName,
                          description: locationDescription,
                        },
                      },
                    },
                  }),
                },
              },
            }),
      };
      updateOneStoreItem({
        variables: {
          data: edit_body as StoreItemUpdateInput,
          where: {
            id: curStoreItem?.id,
          },
        },
      });
    }
  };

  const onFinishFailed = (errorInfo: any) => {};

  const onTypeChange = (e: RadioChangeEvent) => {
    setProductType(e.target.value);
  };

  return (
    <Modal
      title={isEditMode ? '編輯商品' : '新增商品'}
      open={isModalOpen}
      // open={true}
      onOk={handleOk}
      onCancel={() => {
        handleCancel();
      }}
      footer={null}
      destroyOnClose={true}
    >
      <Form
        preserve={false}
        form={form}
        name="basic"
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 16 }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        initialValues={{
          productType: 'postcard',
          ...(isEditMode ? {} : { country: 'tw' }),
          free: false,
          valid: true,
        }}
      >
        {!isEditMode && (
          <Form.Item
            label="上傳明信片"
            name={'fileToUpload'}
            rules={[{ required: true, message: '欄位為必須' }]}
          >
            <Upload
              showUploadList={false}
              maxCount={1}
              multiple={false}
              beforeUpload={(file: File) => {
                setFileName(file.name);
                return false;
              }}
            >
              <Button>Select File</Button>
              <p className="mt-1">{fileName}</p>
            </Upload>
          </Form.Item>
        )}
        <Form.Item
          label="商品種類"
          name={'productType'}
          rules={[{ required: true, message: '欄位為必須' }]}
        >
          <Radio.Group disabled={isEditMode} onChange={onTypeChange}>
            <Radio value={'postcard'}>明信片</Radio>
            <Radio value={'stamp'}>郵票</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item
          label="是否為Free商品"
          name={'free'}
          rules={[{ required: true, message: '欄位為必須' }]}
        >
          <Radio.Group>
            <Radio value={true}>是（Y）</Radio>
            <Radio value={false}>否（N）</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item
          label="是否為Valid商品"
          name={'valid'}
          rules={[{ required: true, message: '欄位為必須' }]}
        >
          <Radio.Group>
            <Radio value={true}>是（Y）</Radio>
            <Radio value={false}>否（N）</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item
          label="商品編號"
          name={'sn'}
          rules={[{ required: true, message: '欄位為必須' }]}
        >
          <Input disabled={isEditMode} />
        </Form.Item>
        <Form.Item
          label="創作者ID"
          name={'profitableIDStr'}
          rules={[{ required: true, message: '欄位為必須' }]}
        >
          <Select disabled={isEditMode}>
            {proFitItems.map((item) => {
              return (
                <Select.Option key={item.id} value={item.user.idStr}>
                  {item.user.name} ({item.user.idStr})
                </Select.Option>
              );
            })}
          </Select>
        </Form.Item>
        <Form.Item
          label="商品金額"
          name={'price'}
          rules={[{ required: true, message: '欄位為必須' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="商品名稱"
          name={'name'}
          rules={[{ required: true, message: '欄位為必須' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="商品詳細介紹"
          name={'description'}
          rules={[{ required: true, message: '欄位為必須' }]}
        >
          <Input.TextArea />
        </Form.Item>
        <Form.Item label="創作國家" name={'country'}>
          <Select options={nationsOption} />
        </Form.Item>
        <Form.Item label="地點緯度/經度" name={'geoInfo'}>
          <Input placeholder="43.913893415093575, -78.97149753672947" />
        </Form.Item>
        <Form.Item label="創作地點名字" name={'locationName'}>
          <Input />
        </Form.Item>
        <Form.Item label="創作地點敘述" name={'locationDescription'}>
          <Input.TextArea />
        </Form.Item>

        {isWrongSize && (
          <Row className="mb-3">
            <Col span={24}>
              {productType === 'postcard' ? (
                <Alert
                  message="明信片尺寸錯誤 - 1240x1754(300dpi)直式 / 1754x1240(300dpi)橫式"
                  type="error"
                />
              ) : (
                <Alert message="郵票尺寸錯誤 - 266x346(300dpi)" type="error" />
              )}
            </Col>
          </Row>
        )}
        {isSnError && (
          <Row className="mb-3">
            <Col span={24}>
              <Alert
                message="商品編號重複。或者創作地點沒有填寫完整（經緯度不填這不行）"
                type="error"
              />
            </Col>
          </Row>
        )}
        <Row justify="end" gutter={16}>
          <Col>
            <Button
              onClick={() => {
                handleCancel();
              }}
            >
              取消
            </Button>
          </Col>
          <Col>
            <Button type="primary" htmlType="submit">
              送出
            </Button>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default ProductMutationModal;
