import { Button, Input, Modal, Typography } from 'antd';
import dayjs from 'dayjs';
import { useRef, useState } from 'react';
import { CSVLink } from 'react-csv';
import { useReactToPrint } from 'react-to-print';
import {
  OrderBenefitsQuery,
  BenefitType,
  ProfitableQuery,
} from '../../../graphql/types';

const TableRow = ({ rowContent }: any) => {
  const row = rowContent;
  return (
    <tr className="text-align-right">
      {row.map((val: string, rowID: string) => (
        <td style={{ border: '1px solid black', paddingLeft: 5 }} key={rowID}>
          {val}
        </td>
      ))}
    </tr>
  );
};

const formatDollarNumber = (num: number) => {
  return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
};
interface Props {
  isModalOpen: boolean;
  handleOk: () => void;
  handleCancel: () => void;
  initialValue?: ProfitableQuery['profitable'];
  forceUpdate: () => void;
  benefitMemos: OrderBenefitsQuery['benefitMemos'][0][];
  monthRange: string[];
  curMemoType: BenefitType;
  unPaidMemos: number;
  curProfitable?: {
    idStr: string;
    profitableId: string;
    userId: string;
    userName: string;
  };
}

const SalesOrderReportModal = (props: Props) => {
  const {
    benefitMemos,
    handleOk,
    handleCancel,
    isModalOpen,
    monthRange,
    curProfitable,
    unPaidMemos,
  } = props;

  const componentRef = useRef(null);
  const handleDownloadPrint = useReactToPrint({
    content: () => componentRef.current,
  });

  var heading = [
    '訂單編號',
    '銷售日期',
    '銷售品名',
    '購買者ID',
    '類型',
    '銷售金額(台幣)',
    '分潤比例',
    '分潤金額(台幣)',
    '換算匯率',
    '實際分潤金額(台幣)',
  ];
  const tableBody = benefitMemos.map((memo) => {
    let row = [];
    const extRate = 1;
    console.log('Hello memo', memo);
    row.push(memo?.order?.order.readableId); // 訂單編號
    row.push(dayjs(memo?.order?.createdAt).format('YYYY-MM-DD')); // 銷售日期
    row.push(memo?.order?.storeItem.name); // 銷售品名
    row.push(
      `${memo?.order?.order.sender.name} (${memo?.order?.order.sender.idStr})`
    ); // 購買者ID
    row.push(memo?.order?.storeItem.type === 'postcard' ? '明信片' : '郵票'); // 類型
    row.push(memo?.order?.subTotal); // 銷售金額(台幣)
    row.push(memo?.order?.ratio); // 分潤比例
    row.push(
      memo?.order?.storeItem.type === 'stamp' ? 2 : memo?.order?.benefit
    ); // 分潤金額(台幣)
    row.push(extRate); // 換算匯率
    row.push(
      (
        extRate *
          (memo?.order?.storeItem.type === 'stamp'
            ? 2
            : memo?.order?.benefit!) || 0
      ).toFixed(2)
    ); // 實際分潤金額(台幣)

    return row;
  });

  return (
    <Modal
      title={'分潤報表'}
      open={isModalOpen}
      // open={true}
      onOk={handleOk}
      onCancel={() => {
        handleCancel();
      }}
      footer={null}
      destroyOnClose={true}
      width={'88%'}
    >
      <div ref={componentRef} className="m-3">
        <Typography.Title level={4} className="mb-5">
          {`POSTORY 商店 ${monthRange
            .map((month) => dayjs(month).format('YYYY-MM-DD'))
            .join(' 到 ')} 對帳單`}
        </Typography.Title>

        <Typography.Title level={4} className="mb-5">
          實體銷售
        </Typography.Title>

        <CSVLink
          filename={`${curProfitable?.userName}(${
            curProfitable?.idStr
          }) 實體銷售 ${monthRange
            .map((month) => dayjs(month).format('YYYY-MM-DD'))
            .join(' 到 ')} 對帳單`}
          headers={heading}
          data={tableBody}
        >
          <Button> CSV 下載</Button>
        </CSVLink>

        <table
          className="mt-5"
          style={{ width: '100%', border: '1px solid black' }}
        >
          <thead>
            <tr>
              {heading.map((head, headID) => (
                <th
                  style={{
                    border: '1px solid black',
                    textAlign: 'left',
                    paddingLeft: 5,
                  }}
                  key={headID}
                >
                  {head}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {tableBody.map((rowContent, rowID) => (
              <TableRow rowContent={rowContent} key={rowID} />
            ))}
          </tbody>
        </table>
        {tableBody && (
          <div className="d-flex flex-column">
            <div className="align-self-end justify-content-end">
              <div>
                合計:
                {formatDollarNumber(
                  tableBody.reduce((acc, cur) => acc + Number(cur?.[9]), 0)
                )}
              </div>
              <div>
                累積未付款:
                {formatDollarNumber(unPaidMemos)}
              </div>
            </div>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default SalesOrderReportModal;
