import { ArrowLeftOutlined, CloudDownloadOutlined } from '@ant-design/icons';
import {
  Button,
  Card,
  Col,
  DatePicker,
  Image,
  Input,
  message,
  Modal,
  Result,
  Row,
  Select,
  Spin,
  Table,
  Tag,
  Typography,
} from 'antd';
import dayjs from 'dayjs';
import { isEmpty } from 'lodash';
import moment from 'moment';
import { useEffect, useReducer, useRef, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import './App.less';
import DownloadPostCardPDF from './components/DownloadPostCardPDF';
import {
  OrderDetailsDocument,
  OrderStatus,
  useCreateOneOrderDetailHistoryMutation,
  useOrderDetailQuery,
  useOrderTagsQuery,
  useUpdateOneOrderDetailMutation,
} from './graphql/types';
import { orderValidStartDateTime } from './Order';
import './OrderDetail.less';
// @ts-ignore
import { exportPdf } from './util/util';

const { TextArea } = Input;
const { Option } = Select;
const { Text } = Typography;

const columns = [
  {
    title: '時間',
    dataIndex: 'createdAt',
  },
  {
    title: '內容說明',
    dataIndex: 'operation',
  },
  {
    title: '操作者',
    dataIndex: 'idStr',
  },
  {
    title: '原狀態',
    dataIndex: 'lastStatus',
    render: (tag: string) => (
      <Tag color={STATUS_TAG_COLOR[tag]}>{STATUS[tag]}</Tag>
    ),
  },
  {
    title: '新狀態',
    dataIndex: 'status',
    render: (tag: string) => (
      <Tag color={STATUS_TAG_COLOR[tag]}>{STATUS[tag]}</Tag>
    ),
  },
];

const STATUS: any = {
  waitToPrint: '待印製',
  printed: '已印製',
  waitToSend: '待配送',
  sent: '已配送',
  received: '已接收',
  arrived: '已接收',
  cancel: '已取消',
  error: '內部錯誤',
};

const STATUS_TAG_COLOR: any = {
  printed: 'yellow',
  waitToSend: 'orange',
  sent: 'green',
  waitToPrint: 'cyan',
  received: 'blue',
  arrived: 'blue',
  cancel: 'gray',
  error: 'red',
};

const COUNTRY: any = {
  tw: '台灣',
};

export default () => {
  const [cancelVisible, setCancelVisible] = useState(false);
  const [customTags, setCutsomTags] = useState<string[]>([]);
  const history = useHistory();
  const location = useLocation();
  const showCancelModal = () => {
    setInputValues({
      status,
      histories,
      ...inputValues.lastReceiver,
    });
    setCancelVisible(true);
  };
  const hideCancelModal = () => {
    if (isEmpty(inputValues.lastReceiver)) {
      setInputValues({
        status,
        histories,
      });
    } else {
      setInputValues({
        ...inputValues.lastReceiver,
      });
    }

    setCancelVisible(false);
  };
  const [receiverVisible, setReceiverVisible] = useState(false);
  const { data: { orderTags } = {} } = useOrderTagsQuery({
    variables: {},
  });
  const showReceiverModal = () => {
    setInputValues({
      realname,
      phone,
      address,
      country,
      memo,
      tags,
      histories,
      ...inputValues.lastReceiver,
    });
    setReceiverVisible(true);
  };
  const hideReceiverModal = () => {
    if (isEmpty(inputValues.lastReceiver)) {
      setInputValues({
        realname,
        phone,
        address,
        country,
        memo,
        tags,
        histories,
      });
    } else {
      setInputValues({
        ...inputValues.lastReceiver,
      });
    }

    setReceiverVisible(false);
  };
  const [printVisible, setPrintVisible] = useState(false);
  const showPrintModal = () => {
    setInputValues({
      status,
      printStatus,
      histories,
      ...inputValues.lastReceiver,
    });

    setPrintVisible(true);
  };
  const hidePrintModal = () => {
    if (isEmpty(inputValues.lastReceiver)) {
      setInputValues({
        printStatus,
        histories,
      });
    } else {
      setInputValues({
        ...inputValues.lastReceiver,
      });
    }

    setPrintVisible(false);
  };
  const [sendVisible, setSendVisible] = useState(false);
  const showSendModal = () => {
    setInputValues({
      status,
      sendStatus,
      sendCompany,
      sendId,
      sendDate,
      histories,
      ...inputValues.lastReceiver,
    });

    setSendVisible(true);
  };
  const hideSendModal = () => {
    if (isEmpty(inputValues.lastReceiver)) {
      setInputValues({
        sendStatus,
        sendCompany,
        sendId,
        sendDate,
        histories,
      });
    } else {
      setInputValues({
        ...inputValues.lastReceiver,
      });
    }

    setSendVisible(false);
  };

  const [downloadVisible, setDownloadVisible] = useState(true);
  const showDownload = () => {
    setDownloadVisible(true);
  };
  const hideDownload = () => {
    setDownloadVisible(false);
  };
  const componentRef = useRef(null);
  const handleDownloadPrint = useReactToPrint({
    content: () => componentRef.current,
    onBeforeGetContent: showDownload,
    onAfterPrint: hideDownload,
  });

  const [inputValues, setInputValues] = useReducer(
    (state: any, newState: any) => ({ ...state, ...newState }),
    {
      cancelMessage: '',
      status: '',
      realname: '',
      phone: '',
      address: '',
      country: '',
      memo: '',
      tags: [],
      histories: [],
      lastReceiver: {},
    }
  );

  const handleOnChange = (event: { target: { name: any; value: any } }) => {
    const { name, value } = event.target;
    setInputValues({ [name]: value });
  };

  const handleUserDataChange = async () => {
    await updateOrderDetail({
      variables: {
        data: {
          realname: { set: inputValues.realname },
          phone: { set: inputValues.phone },
          address: { set: inputValues.address },
          country: { set: inputValues.country },
          memo: { set: inputValues.memo },
          tags: {
            connectOrCreate: customTags.map((t) => ({
              where: { tag: t },
              create: { tag: t },
            })),
            disconnect: tags
              .map(({ tag }) => tag)
              .filter((t) => !customTags.includes(t))
              .map((t) => ({ tag: t })),
          },
        },
        where: {
          id,
        },
      },
    });

    const operation = {
      realname: inputValues.realname,
      phone: inputValues.phone,
      address: inputValues.address,
      country: inputValues.country,
      memo: inputValues.memo,
      tags: customTags,
    };

    const orderDetailHistory = await createOrderDetailHistory({
      variables: {
        data: {
          status: OrderStatus.WaitToPrint,
          operation: JSON.stringify(operation),
          operator: {
            connect: {
              id: localStorage.userId,
            },
          },
          lastHistory: {
            connect: {
              id: inputValues.histories[inputValues.histories.length - 1].id,
            },
          },
          detail: {
            connect: {
              id: id,
            },
          },
        },
      },
    });
    const history = orderDetailHistory.data?.createOneOrderDetailHistory;

    const updateHistories = [...inputValues.histories, history];
    const lastReceiver = {
      ...operation,
      histories: updateHistories,
    };

    setInputValues({
      histories: updateHistories,
      lastReceiver,
    });

    setReceiverVisible(false);
  };

  const handleAddTag = (tags: string[]) => {
    setCutsomTags((prev) => [...tags]);
  };
  const handleCancel = async () => {
    await updateOrderDetail({
      variables: {
        data: {
          status: { set: OrderStatus.Cancel },
        },
        where: {
          id,
        },
      },
    });
    const orderDetailHistory = await createOrderDetailHistory({
      variables: {
        data: {
          status: OrderStatus.Cancel,
          operation: JSON.stringify({
            canncelMessage: inputValues.cancelMessage,
          }),
          operator: {
            connect: {
              id: localStorage.userId,
            },
          },
          lastHistory: {
            connect: {
              id: inputValues.histories[inputValues.histories.length - 1].id,
            },
          },
          detail: {
            connect: {
              id: id,
            },
          },
        },
      },
    });
    const history = orderDetailHistory.data?.createOneOrderDetailHistory;

    const updateHistories = [...inputValues.histories, history];
    const lastReceiver = {
      status: 'cancel',
      histories: updateHistories,
    };

    setInputValues({
      status: 'cancel',
      histories: updateHistories,
      lastReceiver,
    });
    setCancelVisible(false);
  };

  const handlePrint = async () => {
    // if (inputValues.status !== 'sent')
    //   return message.error('目前訂單狀態，不能修改印製狀況');
    await updateOrderDetail({
      variables: {
        data: {
          status: { set: inputValues.printStatus },
          printStatus: { set: inputValues.printStatus },
        },
        where: {
          id,
        },
      },
    });
    const orderDetailHistory = await createOrderDetailHistory({
      variables: {
        data: {
          status: inputValues.printStatus,
          operation: JSON.stringify({
            status: inputValues.sendStatus,
            printStatus: inputValues.printStatus,
          }),
          operator: {
            connect: {
              id: localStorage.userId,
            },
          },
          lastHistory: {
            connect: {
              id: inputValues.histories[inputValues.histories.length - 1].id,
            },
          },
          detail: {
            connect: {
              id: id,
            },
          },
        },
      },
    });
    const history = orderDetailHistory.data?.createOneOrderDetailHistory;

    const updateHistories = [...inputValues.histories, history];
    const lastReceiver = {
      status: inputValues.printStatus,
      printStatus: inputValues.printStatus,
      histories: updateHistories,
    };

    setInputValues({
      status: inputValues.printStatus,
      printStatus: inputValues.printStatus,
      histories: updateHistories,
      lastReceiver,
    });
    setPrintVisible(false);
  };

  const handleSend = async () => {
    if (inputValues.status !== 'printed' && inputValues.status !== 'waitToSend')
      return message.error('目前訂單狀態，不能修改配送狀況');
    await updateOrderDetail({
      variables: {
        data: {
          status: { set: inputValues.sendStatus },
          sendStatus: { set: inputValues.sendStatus },
          sendDate: { set: inputValues.sendDate },
          sendId: { set: inputValues.sendId },
          post: {
            update: {
              sendStatus: {
                set: inputValues.sendStatus,
              },
            },
          },
        },
        where: {
          id,
        },
      },
    });
    const orderDetailHistory = await createOrderDetailHistory({
      variables: {
        data: {
          status: inputValues.sendStatus,
          operation: JSON.stringify({
            status: inputValues.sendStatus,
            sendStatus: inputValues.sendStatus,
            sendDate: inputValues.sendDate,
            sendId: inputValues.sendId,
          }),
          operator: {
            connect: {
              id: localStorage.userId,
            },
          },
          lastHistory: {
            connect: {
              id: inputValues.histories[inputValues.histories.length - 1].id,
            },
          },
          detail: {
            connect: {
              id: id,
            },
          },
        },
      },
    });
    const history = orderDetailHistory.data?.createOneOrderDetailHistory;

    const updateHistories = [...inputValues.histories, history];
    const lastReceiver = {
      status: inputValues.sendStatus,
      sendStatus: inputValues.sendStatus,
      sendDate: inputValues.sendDate,
      sendId: inputValues.sendId,
      histories: updateHistories,
    };

    setInputValues({
      status: inputValues.sendStatus,
      sendStatus: inputValues.sendStatus,
      sendDate: inputValues.sendDate,
      sendId: inputValues.sendId,
      histories: updateHistories,
      lastReceiver,
    });
    setSendVisible(false);
  };

  let { id } = useParams<{ id?: string }>();
  const { loading, error, data } = useOrderDetailQuery({
    fetchPolicy: 'no-cache',
    variables: {
      where: { id },
    },
  });
  const [updateOrderDetail] = useUpdateOneOrderDetailMutation({
    refetchQueries: [
      {
        query: OrderDetailsDocument,
        variables: {
          where: {
            createdAt: {
              gt: orderValidStartDateTime,
            },
          },
        },
      },
    ],
  });
  const [createOrderDetailHistory] = useCreateOneOrderDetailHistoryMutation({
    refetchQueries: [
      {
        query: OrderDetailsDocument,
        variables: {
          where: {
            createdAt: {
              gt: orderValidStartDateTime,
            },
          },
        },
      },
    ],
  });

  const [printHorizontal, setPrintHorizontal] = useState(true);
  const handleImageLoad = (event: { target: any }) => {
    if (event.target.clientHeight > event.target.clientWidth)
      setPrintHorizontal(false);
  };

  useEffect(() => {
    setTimeout(() => hideDownload(), 500);
  });

  useEffect(() => {
    setTimeout(showDownload, 100);
    hideDownload();
  }, [printHorizontal]);

  if (loading) return <Spin size="large" />;
  if (error)
    return (
      <Result
        status="warning"
        title="讀取不到訂單資料."
        extra={
          <Button type="primary" key="console">
            Go Console
          </Button>
        }
      />
    );
  let {
    updatedAt,
    printStatus,
    sendStatus,
    sendCompany,
    sendId,
    sendDate,
    order,
    receiver,
    realname,
    phone,
    address,
    country,
    createdAt,
    histories,
    memo,
    tags,
    status,
    post,
    price,
  } = data!.orderDetail!;

  return (
    <>
      <Card bordered={false}>
        <Row>
          <Col span={1}>
            <Button
              type="link"
              onClick={() =>
                history.push(`/order${location.search}${location.hash}`)
              }
            >
              <ArrowLeftOutlined />
            </Button>
          </Col>
          <Col span={2}>
            <Text strong>訂單</Text>
          </Col>
          <Col span={16}>
            <Text strong underline style={{ color: '#2d9bf0' }}>
              {order.readableId}
            </Text>
          </Col>
          <Col span={2}>
            <Button
              danger
              type="text"
              style={{ fontWeight: 'bold' }}
              onClick={showCancelModal}
            >
              取消訂單
            </Button>
          </Col>
        </Row>
      </Card>
      <Card bordered={false} style={{ background: '#e6e6e6', margin: 12 }}>
        <Row>
          <Col span={2}>
            <Text strong style={{ color: '#2c816e' }}>
              訂單狀態
            </Text>
          </Col>
          <Col>
            <Tag
              className="ms-5"
              color={STATUS_TAG_COLOR[inputValues.status || status]}
            >
              {STATUS[inputValues.status || status]}
            </Tag>
          </Col>
          <Col span={8}>
            <Text strong>
              {dayjs(updatedAt).format('(更新時間: YYYY/MM/D HH:mm)')}
            </Text>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col span={2}>
            <Text strong style={{ color: '#2c816e' }}>
              付款狀態
            </Text>
          </Col>
          <Col>
            <Tag className="ms-5" color={'red'}>
              {order.isPaid ? '已付款' : '未付款'}
            </Tag>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col span={2}>
            <Text strong style={{ color: '#2c816e' }}>
              發票開立狀態
            </Text>
          </Col>
          <Col>
            <Tag className="ms-5" color={'red'}>
              {Number(order.price) > 0 && order.isInvoiced === false
                ? '失敗'
                : '成功'}
            </Tag>
          </Col>
        </Row>
      </Card>
      <Row>
        <Col span={12}>
          <Card bordered={false} style={{ background: '#e6e6e6', margin: 12 }}>
            <Row>
              <Col>
                <Text strong style={{ color: '#2c816e' }}>
                  訂單資訊
                </Text>
              </Col>
            </Row>
            <Row>
              <Col span={8}>
                <Text strong>訂單編號</Text>
              </Col>
              <Col span={16}>
                <Text underline style={{ color: '#2d9bf0' }}>
                  {order.readableId}
                </Text>
              </Col>
            </Row>
            <Row>
              <Col span={8}>
                <Text strong>訂單金額</Text>
              </Col>
              <Col span={16}>
                <Text>{price} POS幣</Text>
              </Col>
            </Row>
            <Row>
              <Col span={8}>
                <Text strong>訂單日期</Text>
              </Col>
              <Col span={16}>
                <Text>{dayjs(updatedAt).format('YYYY/MM/DD (ddd) HH:mm')}</Text>
              </Col>
            </Row>
          </Card>
          <Card bordered={false} style={{ background: '#e6e6e6', margin: 12 }}>
            <Row>
              <Col>
                <Text strong style={{ color: '#2c816e' }}>
                  寄件人
                </Text>
              </Col>
            </Row>
            <Row>
              <Col span={8}>
                <Text strong>Username</Text>
              </Col>
              <Col span={16}>
                <Text>{order.sender.name}</Text>
              </Col>
            </Row>
          </Card>
          <Card bordered={false} style={{ background: '#e6e6e6', margin: 12 }}>
            <Row>
              <Col span={6}>
                <Text strong style={{ color: '#2c816e' }}>
                  收件人
                </Text>
              </Col>
              <Col span={4} offset={14}>
                <Button
                  style={{ background: '#fac710' }}
                  onClick={showReceiverModal}
                >
                  <Text strong>編輯</Text>
                </Button>
              </Col>
            </Row>
            <Row>
              <Col span={8}>
                <Text strong>Username</Text>
              </Col>
              <Col span={16}>
                <Text>{receiver?.idStr}</Text>
              </Col>
            </Row>
            <Row>
              <Col span={8}>
                <Text>實際姓名</Text>
              </Col>
              <Col span={16}>
                <Text>{inputValues.realname || realname}</Text>
              </Col>
            </Row>
            <Row>
              <Col span={8}>
                <Text>連絡電話</Text>
              </Col>
              <Col span={16}>
                <Text>{inputValues.phone || phone}</Text>
              </Col>
            </Row>
            <Row>
              <Col span={8}>
                <Text>聯絡地址</Text>
              </Col>
              <Col span={16}>
                <Text>{inputValues.address || address}</Text>
              </Col>
            </Row>
            <Row>
              <Col span={8}>
                <Text>國家/地區</Text>
              </Col>
              <Col span={16}>
                <Text>{COUNTRY[country!]}</Text>
              </Col>
            </Row>
            <Row>
              <Col>
                <Text strong style={{ color: '#2c816e' }}>
                  內部備註
                </Text>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <TextArea
                  disabled
                  rows={4}
                  placeholder="請輸入備註"
                  value={inputValues.memo || memo}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <Text strong style={{ color: '#2c816e' }}>
                  標籤
                </Text>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Input disabled placeholder="請輸入標籤" />
              </Col>
            </Row>
          </Card>
        </Col>
        <Col span={12}>
          <Card bordered={false} style={{ background: '#e6e6e6', margin: 12 }}>
            <Row>
              <Col>
                <Text strong style={{ color: '#2c816e' }}>
                  明信片預覽
                </Text>
              </Col>
            </Row>
            <Row justify="space-around" align="middle">
              <Col span={8}>
                <Image src={post.body.headUri} onLoad={handleImageLoad} />
              </Col>
              <Col span={8}>
                <Image src={post?.rearUri4Practical as string | undefined} />
              </Col>
              <Col span={4}>
                <Button type="text" onClick={handleDownloadPrint}>
                  <Row>
                    <Col>
                      <CloudDownloadOutlined style={{ fontSize: 55 }} />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Text strong style={{ color: '#2c816e' }}>
                        列印明信片
                      </Text>
                    </Col>
                  </Row>
                </Button>
              </Col>
              <Col span={4}>
                <Button type="text" onClick={() => exportPdf(id!, post)}>
                  <Row>
                    <Col>
                      <CloudDownloadOutlined style={{ fontSize: 55 }} />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Text strong style={{ color: '#2c816e' }}>
                        下載PDF
                      </Text>
                    </Col>
                  </Row>
                </Button>
              </Col>
            </Row>
          </Card>
          <Card bordered={false} style={{ background: '#e6e6e6', margin: 12 }}>
            <Row>
              <Col span={6}>
                <Text strong style={{ color: '#2c816e' }}>
                  印製狀況
                </Text>
              </Col>
              <Col span={4} offset={14}>
                <Button
                  style={{ background: '#fac710' }}
                  onClick={showPrintModal}
                  disabled={!order.isPaid}
                >
                  <Text strong>編輯</Text>
                </Button>
              </Col>
            </Row>
            <Row>
              <Col span={8}>
                <Text strong>印製狀態</Text>
              </Col>
              <Col span={16}>
                <Tag
                  color={
                    STATUS_TAG_COLOR[inputValues.printStatus || printStatus]
                  }
                >
                  <Text strong>
                    {STATUS[inputValues.printStatus || printStatus]}
                  </Text>
                </Tag>
              </Col>
            </Row>
            <Row>
              <Col span={8}>
                <Text strong>印製廠商</Text>
              </Col>
              <Col span={16}>
                <Select disabled defaultValue="郵局" style={{ width: '100%' }}>
                  <Option value="post">郵局</Option>
                </Select>
              </Col>
            </Row>
          </Card>
          <Card bordered={false} style={{ background: '#e6e6e6', margin: 12 }}>
            <Row>
              <Col span={6}>
                <Text strong style={{ color: '#2c816e' }}>
                  配送狀況
                </Text>
              </Col>
              <Col span={4} offset={14}>
                <Button
                  style={{ background: '#fac710' }}
                  onClick={showSendModal}
                  disabled={!order.isPaid}
                >
                  <Text strong>編輯</Text>
                </Button>
              </Col>
            </Row>
            <Row>
              <Col span={8}>
                <Text strong>寄件日</Text>
              </Col>
              <Col span={16}>
                <DatePicker
                  disabled
                  value={moment(
                    moment(inputValues.sendDate || sendDate).isSame(
                      new Date(),
                      'day'
                    )
                      ? inputValues.sendDate || sendDate
                      : new Date(),
                    'YYYY/MM/DD'
                  )}
                  format={'YYYY/MM/DD'}
                  style={{ width: '100%' }}
                />
              </Col>
            </Row>
            <Row>
              <Col span={8}>
                <Text strong>配送狀態</Text>
              </Col>
              <Col span={16}>
                <Tag
                  color={STATUS_TAG_COLOR[inputValues.sendStatus || sendStatus]}
                >
                  <Text strong>
                    {STATUS[inputValues.sendStatus || sendStatus]}
                  </Text>
                </Tag>
              </Col>
            </Row>
            <Row>
              <Col span={8}>
                <Text strong>配送/物流廠商</Text>
              </Col>
              <Col span={16}>
                <Select disabled defaultValue="郵局" style={{ width: '100%' }}>
                  <Option value="post">郵局</Option>
                </Select>
              </Col>
            </Row>
            <Row>
              <Col span={8}>
                <Text strong>配送追蹤編號</Text>
              </Col>
              <Col span={16}>
                <Input disabled value={inputValues.sendId || sendId} />
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
      <Card bordered={false} style={{ background: '#e6e6e6', margin: 12 }}>
        <Text strong style={{ color: '#2c816e' }}>
          訂單更新紀錄
        </Text>
        <Table
          columns={columns}
          dataSource={
            inputValues.histories.length > 0
              ? inputValues.histories.map(
                  (history: {
                    id: any;
                    createdAt: any;
                    operation: any;
                    operator: any;
                    lastHistory: any;
                    status: any;
                  }) => {
                    const {
                      id,
                      createdAt,
                      operation,
                      operator,
                      lastHistory,
                      status,
                    } = history;
                    return {
                      key: id,
                      createdAt: dayjs(createdAt).format(
                        'YYYY/MM/DD (ddd) HH:mm'
                      ),
                      operation,
                      idStr: operator.idStr,
                      lastStatus: lastHistory?.status,
                      status: status,
                    };
                  }
                )
              : histories.map((history) => {
                  const {
                    id,
                    createdAt,
                    operation,
                    operator,
                    lastHistory,
                    status,
                  } = history;
                  return {
                    key: id,
                    createdAt: dayjs(createdAt).format(
                      'YYYY/MM/DD (ddd) HH:mm'
                    ),
                    operation,
                    idStr: operator.idStr,
                    lastStatus: lastHistory?.status,
                    status: status,
                  };
                })
          }
        />
      </Card>

      <Modal
        visible={cancelVisible}
        onOk={handleCancel}
        onCancel={hideCancelModal}
        okText="確定"
        cancelText="取消"
      >
        <Text>請輸入取消原因</Text>
        <TextArea
          rows={4}
          onChange={handleOnChange}
          name="cancelMessage"
          value={inputValues.cancelMessage}
        />
      </Modal>

      <Modal
        visible={receiverVisible}
        onOk={handleUserDataChange}
        onCancel={hideReceiverModal}
        okText="確定"
        cancelText="取消"
      >
        <Card bordered={false} style={{ margin: 12 }}>
          <Row>
            <Col span={6}>
              <Text strong style={{ color: '#2c816e' }}>
                收件人
              </Text>
            </Col>
          </Row>
          <Row>
            <Col span={8}>
              <Text strong>Username</Text>
            </Col>
            <Col span={16}>
              <Input disabled defaultValue={receiver?.idStr} />
            </Col>
          </Row>
          <Row>
            <Col span={8}>
              <Text>實際姓名</Text>
            </Col>
            <Col span={16}>
              <Input
                defaultValue={realname!}
                onChange={handleOnChange}
                name="realname"
                value={inputValues.realname}
              />
            </Col>
          </Row>
          <Row>
            <Col span={8}>
              <Text>連絡電話</Text>
            </Col>
            <Col span={16}>
              <Input
                defaultValue={phone!}
                onChange={handleOnChange}
                name="phone"
                value={inputValues.phone}
              />
            </Col>
          </Row>
          <Row>
            <Col span={8}>
              <Text>聯絡地址</Text>
            </Col>
            <Col span={16}>
              <TextArea
                rows={2}
                defaultValue={address!}
                onChange={handleOnChange}
                name="address"
                value={inputValues.address}
              />
            </Col>
          </Row>
          <Row>
            <Col span={8}>
              <Text>國家/地區</Text>
            </Col>
            <Col span={16}>
              <Select
                defaultValue={COUNTRY[country!]}
                style={{ width: '100%' }}
              >
                <Option value="tw">{COUNTRY[country!]}</Option>
              </Select>
            </Col>
          </Row>
          <Row>
            <Col>
              <Text strong style={{ color: '#2c816e' }}>
                內部備註
              </Text>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <TextArea
                rows={4}
                placeholder="請輸入備註"
                onChange={handleOnChange}
                name="memo"
                value={inputValues.memo}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <Text strong style={{ color: '#2c816e' }}>
                標籤
              </Text>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Select
                mode="tags"
                defaultValue={tags.map(({ tag }) => tag)}
                className="w-100"
                placeholder="Tags Mode"
                onChange={handleAddTag}
              >
                {Array.from(new Set(orderTags))?.map((orderTags) => (
                  <Option key={orderTags.tag}>{orderTags.tag}</Option>
                ))}
              </Select>
            </Col>
          </Row>
        </Card>
      </Modal>

      <Modal
        visible={printVisible}
        onOk={handlePrint}
        onCancel={hidePrintModal}
        okText="確定"
        cancelText="取消"
      >
        <Card bordered={false} style={{ margin: 12 }}>
          <Row>
            <Col span={6}>
              <Text strong style={{ color: '#2c816e' }}>
                印製狀況
              </Text>
            </Col>
          </Row>
          <Row>
            <Col span={8}>
              <Text strong>印製狀態</Text>
            </Col>
            <Col span={16}>
              <Select
                defaultValue="waitToPrint"
                style={{ width: '100%' }}
                value={inputValues.printStatus}
                onChange={(value) => setInputValues({ printStatus: value })}
              >
                <Option value="waitToPrint">待印製</Option>
                <Option value="printed">已印製</Option>
              </Select>
            </Col>
          </Row>
          <Row>
            <Col span={8}>
              <Text strong>印製廠商</Text>
            </Col>
            <Col span={16}>
              <Select defaultValue="post" style={{ width: '100%' }}>
                <Option value="post">郵局</Option>
              </Select>
            </Col>
          </Row>
        </Card>
      </Modal>

      <Modal
        visible={sendVisible}
        onOk={handleSend}
        onCancel={hideSendModal}
        okText="確定"
        cancelText="取消"
      >
        <Card bordered={false} style={{ margin: 12 }}>
          <Row>
            <Col span={6}>
              <Text strong style={{ color: '#2c816e' }}>
                配送狀況
              </Text>
            </Col>
          </Row>
          <Row>
            <Col span={8}>
              <Text strong>寄件日</Text>
            </Col>
            <Col span={16}>
              <DatePicker
                defaultValue={moment(
                  moment(inputValues.sendDate).isSame(new Date(), 'day')
                    ? inputValues.sendDate
                    : new Date(),
                  'YYYY/MM/DD'
                )}
                format={'YYYY/MM/DD'}
                style={{ width: '100%' }}
                value={moment(
                  moment(inputValues.sendDate).isSame(new Date(), 'day')
                    ? inputValues.sendDate
                    : new Date(),
                  'YYYY/MM/DD'
                )}
                onChange={(value) =>
                  setInputValues({
                    sendDate: value?.format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
                  })
                }
              />
            </Col>
          </Row>
          <Row>
            <Col span={8}>
              <Text strong>配送狀態</Text>
            </Col>
            <Col span={16}>
              <Select
                defaultValue="waitToSend"
                style={{ width: '100%' }}
                value={inputValues.sendStatus}
                onChange={(value) => setInputValues({ sendStatus: value })}
              >
                <Option value="waitToSend">待配送</Option>
                <Option value="sent">已配送</Option>
              </Select>
            </Col>
          </Row>
          <Row>
            <Col span={8}>
              <Text strong>配送/物流廠商</Text>
            </Col>
            <Col span={16}>
              <Select defaultValue="郵局" style={{ width: '100%' }}>
                <Option value="post">郵局</Option>
              </Select>
            </Col>
          </Row>
          <Row>
            <Col span={8}>
              <Text strong>配送追蹤編號</Text>
            </Col>
            <Col span={16}>
              <Input
                defaultValue={sendId!}
                onChange={handleOnChange}
                name="sendId"
                value={inputValues.sendId}
              />
            </Col>
          </Row>
        </Card>
      </Modal>
      <Modal
        wrapClassName="printModal"
        visible={downloadVisible}
        onOk={handleDownloadPrint}
        onCancel={hideDownload}
        okText="列印"
        cancelText="取消"
        mask={false}
      >
        <div id="containerToBePrinted" ref={componentRef}>
          <DownloadPostCardPDF
            headUri={post.body.headUri}
            rearUri={post.rearUri4Practical as string | undefined}
            printHorizontal={printHorizontal}
          />
        </div>
      </Modal>
    </>
  );
};
