export const nationsOption = [
  { value: 'tw', label: '台灣' },
  { value: 'ng', label: '奈及利亞' },
  { value: 'af', label: '阿富汗' },
  { value: 'ax', label: '奧蘭群島' },
  { value: 'al', label: '阿爾巴尼亞' },
  { value: 'dz', label: '阿爾及利亞' },
  { value: 'as', label: '美屬薩摩亞' },
  { value: 'ad', label: '安道爾' },
  { value: 'ao', label: '安哥拉' },
  { value: 'ai', label: '安圭拉' },
  { value: 'aq', label: '南極' },
  { value: 'ag', label: '安提瓜和巴布達' },
  { value: 'ar', label: '阿根廷' },
  { value: 'am', label: '亞美尼亞' },
  { value: 'aw', label: '阿魯巴' },
  { value: 'au', label: '澳洲' },
  { value: 'at', label: '奧地利' },
  { value: 'az', label: '亞塞拜然' },
  { value: 'bs', label: '巴哈馬' },
  { value: 'bh', label: '巴林' },
  { value: 'bd', label: '孟加拉' },
  { value: 'bb', label: '巴巴多斯' },
  { value: 'by', label: '白俄羅斯' },
  { value: 'be', label: '比利時' },
  { value: 'bz', label: '伯利茲' },
  { value: 'bj', label: '貝南' },
  { value: 'bm', label: '百慕達' },
  { value: 'bt', label: '不丹' },
  { value: 'bo', label: '玻利維亞' },
  { value: 'ba', label: '波士尼亞和黑塞哥維那' },
  { value: 'bw', label: '博茨瓦納' },
  { value: 'bv', label: '布維特島' },
  { value: 'br', label: '巴西' },
  { value: 'io', label: '英屬印度洋領地' },
  { value: 'bn', label: '汶萊' },
  { value: 'bg', label: '保加利亞' },
  { value: 'bf', label: '布吉納法索' },
  { value: 'bi', label: '蒲隆地' },
  { value: 'cv', label: '佛得角' },
  { value: 'kh', label: '柬埔寨' },
  { value: 'cm', label: '喀麥隆' },
  { value: 'ca', label: '加拿大' },
  { value: 'ky', label: '開曼群島' },
  { value: 'cf', label: '中非共和國' },
  { value: 'td', label: '查德' },
  { value: 'cl', label: '智利' },
  { value: 'cn', label: '中國' },
  { value: 'cx', label: '聖誕島' },
  { value: 'cc', label: '科科斯（基林）群島' },
  { value: 'co', label: '哥倫比亞' },
  { value: 'km', label: '科摩羅' },
  { value: 'cg', label: '剛果共和國（布拉柴維爾）' },
  { value: 'cd', label: '剛果民主共和國（金沙薩）' },
  { value: 'ck', label: '庫克群島' },
  { value: 'cr', label: '哥斯大黎加' },
  { value: 'ci', label: '象牙海岸' },
  { value: 'hr', label: '克羅埃西亞' },
  { value: 'cu', label: '古巴' },
  { value: 'cy', label: '塞普勒斯' },
  { value: 'cz', label: '捷克' },
  { value: 'dk', label: '丹麥' },
  { value: 'dj', label: '吉布地' },
  { value: 'dm', label: '多明尼加' },
  { value: 'do', label: '多明尼加共和國' },
  { value: 'ec', label: '厄瓜多' },
  { value: 'eg', label: '埃及' },
  { value: 'sv', label: '薩爾瓦多' },
  { value: 'gq', label: '赤道幾內亞' },
  { value: 'er', label: '厄立特裡亞' },
  { value: 'ee', label: '愛沙尼亞' },
  { value: 'et', label: '伊索比亞' },
  { value: 'fk', label: '福克蘭群島' },
  { value: 'fo', label: '法羅群島' },
  { value: 'fj', label: '斐濟' },
  { value: 'fi', label: '芬蘭' },
  { value: 'fr', label: '法國' },
  { value: 'gf', label: '法屬蓋亞那' },
  { value: 'pf', label: '法屬玻里尼西亞' },
  { value: 'tf', label: '法屬南部屬地' },
  { value: 'ga', label: '加彭' },
  { value: 'gm', label: '岡比亞' },
  { value: 'ge', label: '喬治亞' },
  { value: 'de', label: '德國' },
  { value: 'gh', label: '迦納' },
  { value: 'gi', label: '直布羅陀' },
  { value: 'gr', label: '希臘' },
  { value: 'gl', label: '格陵蘭' },
  { value: 'gd', label: '格林納達' },
  { value: 'gp', label: '瓜地洛普' },
  { value: 'gu', label: '關島' },
  { value: 'gt', label: '瓜地馬拉' },
  { value: 'gg', label: '根息島' },
  { value: 'gn', label: '幾內亞' },
  { value: 'gw', label: '幾內亞比索' },
  { value: 'gy', label: '蓋亞那' },
  { value: 'ht', label: '海地' },
  { value: 'hm', label: '赫德暨麥當勞群島' },
  { value: 'va', label: '梵蒂岡' },
  { value: 'hn', label: '宏都拉斯' },
  { value: 'hk', label: '香港' },
  { value: 'hu', label: '匈牙利' },
  { value: 'is', label: '冰島' },
  { value: 'in', label: '印度' },
  { value: 'id', label: '印尼' },
  { value: 'ir', label: '伊朗' },
  { value: 'iq', label: '伊拉克' },
  { value: 'ie', label: '愛爾蘭' },
  { value: 'im', label: '曼島' },
  { value: 'il', label: '以色列' },
  { value: 'it', label: '義大利' },
  { value: 'jm', label: '牙買加' },
  { value: 'jp', label: '日本' },
  { value: 'je', label: '澤西' },
  { value: 'jo', label: '約旦' },
  { value: 'kz', label: '哈薩克' },
  { value: 'ke', label: '肯亞' },
  { value: 'ki', label: '吉里巴斯' },
  { value: 'kp', label: '北韓' },
  { value: 'kr', label: '南韓' },
  { value: 'kw', label: '科威特' },
  { value: 'kg', label: '吉爾吉斯' },
  { value: 'la', label: '寮國' },
  { value: 'lv', label: '拉脫維亞' },
  { value: 'lb', label: '黎巴嫩' },
  { value: 'ls', label: '賴索托' },
  { value: 'lr', label: '賴比瑞亞' },
  { value: 'ly', label: '利比亞' },
  { value: 'li', label: '列支敦斯登' },
  { value: 'lt', label: '立陶宛' },
  { value: 'lu', label: '盧森堡' },
  { value: 'mo', label: '澳門' },
  { value: 'mk', label: '北馬其頓' },
  { value: 'mg', label: '馬達加斯加' },
  { value: 'mw', label: '馬拉威' },
  { value: 'my', label: '馬來西亞' },
  { value: 'mv', label: '馬爾地夫' },
  { value: 'ml', label: '馬里' },
  { value: 'mt', label: '馬爾他' },
  { value: 'mh', label: '馬紹爾群島' },
  { value: 'mq', label: '馬丁尼克' },
  { value: 'mr', label: '茅利塔尼亞' },
  { value: 'mu', label: '模里西斯' },
  { value: 'yt', label: '馬約特' },
  { value: 'mx', label: '墨西哥' },
  { value: 'fm', label: '密克羅尼西亞' },
  { value: 'md', label: '摩爾多瓦' },
  { value: 'mc', label: '摩納哥' },
  { value: 'mn', label: '蒙古' },
  { value: 'me', label: '蒙特內哥羅' },
  { value: 'ms', label: '蒙哲臘' },
  { value: 'ma', label: '摩洛哥' },
  { value: 'mz', label: '莫三比克' },
  { value: 'mm', label: '緬甸' },
  { value: 'na', label: '納米比亞' },
  { value: 'nr', label: '諾魯' },
  { value: 'np', label: '尼泊爾' },
  { value: 'nl', label: '荷蘭' },
  { value: 'nc', label: '新喀里多尼亞' },
  { value: 'nz', label: '紐西蘭' },
  { value: 'ni', label: '尼加拉瓜' },
  { value: 'ne', label: '尼日爾' },
  { value: 'ng', label: '奈及利亞' },
  { value: 'nu', label: '紐埃' },
  { value: 'nf', label: '諾福克島' },
  { value: 'mp', label: '北馬利安納群島' },
  { value: 'no', label: '挪威' },
  { value: 'om', label: '阿曼' },
  { value: 'pk', label: '巴基斯坦' },
  { value: 'pw', label: '帛琉' },
  { value: 'ps', label: '巴勒斯坦' },
  { value: 'pa', label: '巴拿馬' },
  { value: 'pg', label: '巴布亞新幾內亞' },
  { value: 'py', label: '巴拉圭' },
  { value: 'pe', label: '祕魯' },
  { value: 'ph', label: '菲律賓' },
  { value: 'pn', label: '皮特肯群島' },
  { value: 'pl', label: '波蘭' },
  { value: 'pt', label: '葡萄牙' },
  { value: 'pr', label: '波多黎各' },
  { value: 'qa', label: '卡達' },
  { value: 're', label: '留尼旺' },
  { value: 'ro', label: '羅馬尼亞' },
  { value: 'ru', label: '俄羅斯' },
  { value: 'rw', label: '盧安達' },
  { value: 'bl', label: '聖巴瑟米' },
  { value: 'sh', label: '聖凱倫拿島' },
  { value: 'kn', label: '聖克里斯多福及尼維斯' },
  { value: 'lc', label: '聖露西亞' },
  { value: 'mf', label: '聖馬丁' },
  { value: 'pm', label: '聖皮埃與密克隆群島' },
  { value: 'vc', label: '聖文森及格瑞那丁' },
  { value: 'ws', label: '薩摩亞' },
  { value: 'sm', label: '聖馬利諾' },
  { value: 'st', label: '聖多美普林西比' },
  { value: 'sa', label: '沙烏地阿拉伯' },
  { value: 'sn', label: '塞內加爾' },
  { value: 'rs', label: '塞爾維亞' },
  { value: 'sc', label: '塞席爾' },
  { value: 'sl', label: '獅子山' },
  { value: 'sg', label: '新加坡' },
  { value: 'sk', label: '斯洛伐克' },
  { value: 'si', label: '斯洛維尼亞' },
  { value: 'sb', label: '所羅門群島' },
  { value: 'so', label: '索馬利亞' },
  { value: 'za', label: '南非' },
  { value: 'gs', label: '南喬治亞與南三明治群島 ' },
  { value: 'ss', label: '南蘇丹' },
  { value: 'es', label: '西班牙' },
  { value: 'lk', label: '斯里蘭卡' },
  { value: 'sd', label: '蘇丹' },
  { value: 'sr', label: '蘇利南' },
  { value: 'sj', label: '斯瓦巴及尖棉' },
  { value: 'sz', label: '史瓦帝尼' },
  { value: 'se', label: '瑞典' },
  { value: 'ch', label: '瑞士' },
  { value: 'sy', label: '敘利亞' },
  { value: 'tj', label: '塔吉克' },
  { value: 'tz', label: '坦尚尼亞' },
  { value: 'th', label: '泰國' },
  { value: 'tl', label: '東帝汶' },
  { value: 'tg', label: '多哥' },
  { value: 'tk', label: '托克勞' },
  { value: 'to', label: '東加' },
  { value: 'tt', label: '千里達及托巴哥' },
  { value: 'tn', label: '突尼西亞' },
  { value: 'tr', label: '土耳其' },
  { value: 'tm', label: '土庫曼' },
  { value: 'tc', label: '土克斯及開科斯群島' },
  { value: 'tv', label: '圖瓦盧' },
  { value: 'ug', label: '烏干達' },
  { value: 'ua', label: '烏克蘭' },
  { value: 'ae', label: '阿拉伯聯合大公國' },
  { value: 'gb', label: '英國' },
  { value: 'us', label: '美國' },
  { value: 'uy', label: '烏拉圭' },
  { value: 'uz', label: '烏玆別克' },
  { value: 'vu', label: '瓦努阿圖' },
  { value: 've', label: '委內瑞拉' },
  { value: 'vn', label: '越南' },
  { value: 'vg', label: '英屬維京群島' },
  { value: 'vi', label: '美屬維京群島' },
  { value: 'wf', label: '瓦利斯和富圖那' },
  { value: 'ye', label: '葉門' },
  { value: 'zm', label: '贊比亞' },
  { value: 'zw', label: '辛巴威' },
];
