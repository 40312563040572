import { Button, Input, Modal, Typography } from 'antd';
import dayjs from 'dayjs';
import { useRef, useState, useEffect } from 'react';
import { useReactToPrint } from 'react-to-print';
import {
  BenefitMemosQuery,
  BenefitType,
  ProfitableQuery,
} from '../../../graphql/types';
import { CSVLink } from 'react-csv';
import { cloneDeep, isEmpty } from 'lodash';

const formatDollarNumber = (num: number) => {
  return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
};

const TableRow = ({ rowContent }: any) => {
  const row = rowContent;
  return (
    <tr className="text-align-right">
      {row.map((val: string, rowID: string) => (
        <td style={{ border: '1px solid black', paddingLeft: 5 }} key={rowID}>
          {val}
        </td>
      ))}
    </tr>
  );
};
interface Props {
  isModalOpen: boolean;
  handleOk: () => void;
  handleCancel: () => void;
  initialValue?: ProfitableQuery['profitable'];
  forceUpdate: () => void;
  benefitMemos: BenefitMemosQuery['benefitMemos'][0][];
  monthRange: string[];
  curMemoType: BenefitType;
  unPaidMemos: number;
  curProfitable?: {
    idStr: string;
    profitableId: string;
    userId: string;
    userName: string;
  };
}

const SaleReportModal = (props: Props) => {
  const {
    benefitMemos,
    handleOk,
    handleCancel,
    isModalOpen,
    monthRange,
    curMemoType,
    unPaidMemos,
    curProfitable,
  } = props;

  const componentRef = useRef(null);
  const handleDownloadPrint = useReactToPrint({
    content: () => componentRef.current,
  });
  const [mockExchange, setMockExchange] = useState<number>(0);
  const [csvData, setCsvData] = useState(null);

  var heading = [
    '訂單編號',
    '銷售日期',
    '銷售品名',
    '購買者ID',
    '類型',
    '銷售金額(PO幣)',
    '分潤比例',
    '分潤金額(PO幣)',
    '換算匯率',
    '實際分潤金額(台幣)',
  ];
  console.log('Hello mockExchange', mockExchange);
  const tableBody = benefitMemos.map((memo) => {
    let row = [];
    const extRate = memo?.transfer?.exchangeRateForCoin || 0;
    row.push(memo?.coin?.coin.readableId); // 訂單編號
    row.push(dayjs(memo?.coin?.coin.createdAt).format('YYYY-MM-DD')); // 銷售日期
    row.push(memo?.coin?.storeItem.name); // 銷售品名
    row.push(`${memo?.coin?.coin.user.name} (${memo?.coin?.coin.user.idStr})`); // 購買者ID
    row.push(memo?.coin?.storeItem.type === 'postcard' ? '明信片' : '郵票'); // 類型
    row.push(memo?.coin?.subTotal); // 銷售金額(PO幣)
    row.push(memo?.coin?.ratio); // 分潤比例
    row.push(memo?.coin?.benefit); // 分潤金額(PO幣)
    row.push(
      memo?.transfer
        ? memo?.transfer.exchangeRateForCoin
        : `${mockExchange} (*)`
    ); // 換算匯率
    row.push(
      extRate
        ? (extRate * (memo?.coin?.benefit || 0)).toFixed(2)
        : (mockExchange * (memo?.coin?.benefit || 0)).toFixed(2)
    ); // 實際分潤金額(台幣)

    return row;
  });
  // setCsvData(tableBody as any);
  useEffect(() => {
    if (!isEmpty(tableBody)) return;
    const row = cloneDeep(tableBody);
    console.log('Hello row', row);
    // setCsvData(row as any);
  }, [tableBody]);
  console.log('Hello csvData', csvData);
  console.log('Hello tableBody', tableBody);

  return (
    <Modal
      title={'分潤報表'}
      open={isModalOpen}
      // open={true}
      onOk={handleOk}
      onCancel={() => {
        handleCancel();
      }}
      footer={null}
      destroyOnClose={true}
      width={'88%'}
    >
      <div ref={componentRef} className="m-3">
        <Typography.Title level={4} className="mb-5">
          {`${curProfitable?.userName}(${curProfitable?.idStr}) ${monthRange
            .map((month) => dayjs(month).format('YYYY-MM-DD'))
            .join(' 到 ')} 對帳單`}
        </Typography.Title>

        <Typography.Title level={4} className="mb-5">
          {curMemoType === 'memoToCoin' ? '商店銷售' : '實體銷售'}
        </Typography.Title>

        {/* <Typography.Title level={4} className="mb-5 d-print-none">
          {<Button onClick={handleDownloadPrint}>列印報表</Button>}
        </Typography.Title> */}

        <Input
          onChange={(e) => setMockExchange(Number(e.target.value))}
          placeholder="請輸入假設匯率"
          className="mb-5 d-print-none"
        />

        <CSVLink
          filename={`${curProfitable?.userName}(${
            curProfitable?.idStr
          }) 商店銷售 ${monthRange
            .map((month) => dayjs(month).format('YYYY-MM-DD'))
            .join(' 到 ')} 對帳單`}
          headers={heading}
          data={tableBody}
        >
          <Button> CSV 下載</Button>
        </CSVLink>

        <table
          className="mt-5"
          style={{ width: '100%', border: '1px solid black' }}
        >
          <thead>
            <tr>
              {heading.map((head, headID) => (
                <th
                  style={{
                    border: '1px solid black',
                    textAlign: 'left',
                    paddingLeft: 5,
                  }}
                  key={headID}
                >
                  {head}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {tableBody.map((rowContent, rowID) => (
              <TableRow rowContent={rowContent} key={rowID} />
            ))}
          </tbody>
        </table>
        {tableBody && (
          <>
            <div className="float-end mt-3 me-1 mb-3">
              合計:
              {formatDollarNumber(
                tableBody.reduce((acc, cur) => acc + Number(cur?.[9]), 0)
              )}
            </div>
            <br />
            <div className="float-end mt-3 me-1 mb-3">
              累積未付款:
              {formatDollarNumber(unPaidMemos * mockExchange)}
            </div>
          </>
        )}
      </div>
    </Modal>
  );
};

export default SaleReportModal;
