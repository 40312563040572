import { Spin, Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import dayjs from 'dayjs';
import { isEmpty } from 'lodash';
import { OrderBenefitsQuery } from '../../../graphql/types';

interface Props {
  items: OrderBenefitsQuery['benefitMemos'] | undefined;
  rowSelection: {
    onChange: (
      newSelectedRowKeys: React.Key[],
      selectRows: OrderBenefitsQuery['benefitMemos'][0][]
    ) => void;
  };
}

function SalesOrderTable({ items, rowSelection }: Props) {
  if (isEmpty(items)) {
    return <Spin />;
  }
  const columns: ColumnsType<OrderBenefitsQuery['benefitMemos'][0]> = [
    {
      title: '關聯匯款',
      dataIndex: 'transfer',
      key: 'order.transfer',
      render: (order, data) => {
        return data.transfer ? '已匯款' : '尚未匯款';
      },
    },
    {
      title: '訂單編號',
      dataIndex: 'order',
      key: 'order.readableId',
      render: (order, data) => {
        return order.order.readableId;
      },
    },
    {
      title: '銷售日期',
      dataIndex: 'order',
      key: 'order',
      render: (order, data) => {
        return dayjs(order.order.createdAt).format('YYYY-MM-DD');
      },
    },
    {
      title: '銷售品名',
      dataIndex: 'order',
      key: 'order.storeItem.name',
      render: (order, data) => {
        return order.storeItem.name;
      },
    },
    {
      title: '購買者ID',
      dataIndex: 'order',
      key: 'order',
      render: (order, data) => {
        return `${order.order.sender.name} (${order.order.sender.idStr})`;
      },
    },
    {
      title: '創作者ID',
      dataIndex: 'profitable',
      key: 'profitable.storeItem.name.id',
      render: (profitable, data) => {
        return `${profitable.user.name} (${profitable.id})`;
      },
    },
    {
      title: '類型',
      dataIndex: 'order',
      key: 'order.storeItem.type',
      render: (order, data) => {
        return order.storeItem.type === 'postcard' ? '明信片' : '郵票';
      },
    },
    {
      title: '銷售金額(台幣)',
      dataIndex: 'order',
      key: 'order.subTotal',
      render: (order, data) => {
        return order.subTotal;
      },
    },
    {
      title: '分潤比例',
      dataIndex: 'order',
      key: 'order.ratio',
      render: (order, data) => {
        return order.ratio;
      },
    },
    {
      title: '分潤金額(台幣)',
      dataIndex: 'order',
      key: 'order.benefit',
      render: (order, data) => {
        if (order?.storeItem.type === 'stamp') {
          return 2;
        }
        return order.benefit;
      },
    },
    {
      title: '換算匯率',
      dataIndex: 'transfer',
      key: 'transfer.ratio',
      render: (transfer, data) => {
        return transfer ? transfer.exchangeRateForCoin : '(尚未匯款)';
      },
    },
    {
      title: '實際分潤金額(台幣)',
      dataIndex: 'order',
      key: 'order',
      render: (order, data) => {
        console.log('Hello orderorderorder', order);
        const extRate = data.transfer?.exchangeRateForCoin;
        if (extRate) {
          return (order.benefit * extRate).toFixed(2);
        } else {
          return '尚未匯款';
        }
      },
    },
  ];

  return (
    <Table
      rowKey={(record) => record.id}
      rowSelection={{ ...rowSelection }}
      dataSource={items}
      columns={columns}
    />
  );
}

export default SalesOrderTable;
