import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The javascript `Date` as string. Type represents date and time as the ISO Date string. */
  DateTime: any;
  /** GraphQL Scalar representing the Prisma.Decimal type, based on Decimal.js library. */
  Decimal: any;
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: any;
};

export type Address = {
  __typename?: 'Address';
  address1: Scalars['String'];
  address2?: Maybe<Scalars['String']>;
  city: Scalars['String'];
  country: Scalars['String'];
  createdAt: Scalars['DateTime'];
  district?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  manualAddr?: Maybe<ManualAddr>;
  owner?: Maybe<User>;
  ownerId?: Maybe<Scalars['String']>;
  postcode: Scalars['String'];
  state?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  virtualAddr?: Maybe<VirtualAddrMapping>;
};

export type AddressCountAggregate = {
  __typename?: 'AddressCountAggregate';
  _all: Scalars['Int'];
  address1: Scalars['Int'];
  address2: Scalars['Int'];
  city: Scalars['Int'];
  country: Scalars['Int'];
  createdAt: Scalars['Int'];
  district: Scalars['Int'];
  id: Scalars['Int'];
  ownerId: Scalars['Int'];
  postcode: Scalars['Int'];
  state: Scalars['Int'];
  street: Scalars['Int'];
  updatedAt: Scalars['Int'];
};

export type AddressCountOrderByAggregateInput = {
  address1?: InputMaybe<SortOrder>;
  address2?: InputMaybe<SortOrder>;
  city?: InputMaybe<SortOrder>;
  country?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  district?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  ownerId?: InputMaybe<SortOrder>;
  postcode?: InputMaybe<SortOrder>;
  state?: InputMaybe<SortOrder>;
  street?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type AddressCreateInput = {
  address1: Scalars['String'];
  address2?: InputMaybe<Scalars['String']>;
  city: Scalars['String'];
  country: Scalars['String'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  district?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  manualAddr?: InputMaybe<ManualAddrCreateNestedOneWithoutAddressInput>;
  owner?: InputMaybe<UserCreateNestedOneWithoutAddressInput>;
  postcode: Scalars['String'];
  state?: InputMaybe<Scalars['String']>;
  street?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  virtualAddr?: InputMaybe<VirtualAddrMappingCreateNestedOneWithoutRealAddressInput>;
};

export type AddressCreateManyInput = {
  address1: Scalars['String'];
  address2?: InputMaybe<Scalars['String']>;
  city: Scalars['String'];
  country: Scalars['String'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  district?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  ownerId?: InputMaybe<Scalars['String']>;
  postcode: Scalars['String'];
  state?: InputMaybe<Scalars['String']>;
  street?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type AddressCreateManyOwnerInput = {
  address1: Scalars['String'];
  address2?: InputMaybe<Scalars['String']>;
  city: Scalars['String'];
  country: Scalars['String'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  district?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  postcode: Scalars['String'];
  state?: InputMaybe<Scalars['String']>;
  street?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type AddressCreateManyOwnerInputEnvelope = {
  data: Array<AddressCreateManyOwnerInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type AddressCreateNestedManyWithoutOwnerInput = {
  connect?: InputMaybe<Array<AddressWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<AddressCreateOrConnectWithoutOwnerInput>>;
  create?: InputMaybe<Array<AddressCreateWithoutOwnerInput>>;
  createMany?: InputMaybe<AddressCreateManyOwnerInputEnvelope>;
};

export type AddressCreateNestedOneWithoutManualAddrInput = {
  connect?: InputMaybe<AddressWhereUniqueInput>;
  connectOrCreate?: InputMaybe<AddressCreateOrConnectWithoutManualAddrInput>;
  create?: InputMaybe<AddressCreateWithoutManualAddrInput>;
};

export type AddressCreateNestedOneWithoutVirtualAddrInput = {
  connect?: InputMaybe<AddressWhereUniqueInput>;
  connectOrCreate?: InputMaybe<AddressCreateOrConnectWithoutVirtualAddrInput>;
  create?: InputMaybe<AddressCreateWithoutVirtualAddrInput>;
};

export type AddressCreateOrConnectWithoutManualAddrInput = {
  create: AddressCreateWithoutManualAddrInput;
  where: AddressWhereUniqueInput;
};

export type AddressCreateOrConnectWithoutOwnerInput = {
  create: AddressCreateWithoutOwnerInput;
  where: AddressWhereUniqueInput;
};

export type AddressCreateOrConnectWithoutVirtualAddrInput = {
  create: AddressCreateWithoutVirtualAddrInput;
  where: AddressWhereUniqueInput;
};

export type AddressCreateWithoutManualAddrInput = {
  address1: Scalars['String'];
  address2?: InputMaybe<Scalars['String']>;
  city: Scalars['String'];
  country: Scalars['String'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  district?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  owner?: InputMaybe<UserCreateNestedOneWithoutAddressInput>;
  postcode: Scalars['String'];
  state?: InputMaybe<Scalars['String']>;
  street?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  virtualAddr?: InputMaybe<VirtualAddrMappingCreateNestedOneWithoutRealAddressInput>;
};

export type AddressCreateWithoutOwnerInput = {
  address1: Scalars['String'];
  address2?: InputMaybe<Scalars['String']>;
  city: Scalars['String'];
  country: Scalars['String'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  district?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  manualAddr?: InputMaybe<ManualAddrCreateNestedOneWithoutAddressInput>;
  postcode: Scalars['String'];
  state?: InputMaybe<Scalars['String']>;
  street?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  virtualAddr?: InputMaybe<VirtualAddrMappingCreateNestedOneWithoutRealAddressInput>;
};

export type AddressCreateWithoutVirtualAddrInput = {
  address1: Scalars['String'];
  address2?: InputMaybe<Scalars['String']>;
  city: Scalars['String'];
  country: Scalars['String'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  district?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  manualAddr?: InputMaybe<ManualAddrCreateNestedOneWithoutAddressInput>;
  owner?: InputMaybe<UserCreateNestedOneWithoutAddressInput>;
  postcode: Scalars['String'];
  state?: InputMaybe<Scalars['String']>;
  street?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type AddressGroupBy = {
  __typename?: 'AddressGroupBy';
  _count?: Maybe<AddressCountAggregate>;
  _max?: Maybe<AddressMaxAggregate>;
  _min?: Maybe<AddressMinAggregate>;
  address1: Scalars['String'];
  address2?: Maybe<Scalars['String']>;
  city: Scalars['String'];
  country: Scalars['String'];
  createdAt: Scalars['DateTime'];
  district?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  ownerId?: Maybe<Scalars['String']>;
  postcode: Scalars['String'];
  state?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};

export type AddressListRelationFilter = {
  every?: InputMaybe<AddressWhereInput>;
  none?: InputMaybe<AddressWhereInput>;
  some?: InputMaybe<AddressWhereInput>;
};

export type AddressMaxAggregate = {
  __typename?: 'AddressMaxAggregate';
  address1?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  district?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  ownerId?: Maybe<Scalars['String']>;
  postcode?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AddressMaxOrderByAggregateInput = {
  address1?: InputMaybe<SortOrder>;
  address2?: InputMaybe<SortOrder>;
  city?: InputMaybe<SortOrder>;
  country?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  district?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  ownerId?: InputMaybe<SortOrder>;
  postcode?: InputMaybe<SortOrder>;
  state?: InputMaybe<SortOrder>;
  street?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type AddressMinAggregate = {
  __typename?: 'AddressMinAggregate';
  address1?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  district?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  ownerId?: Maybe<Scalars['String']>;
  postcode?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AddressMinOrderByAggregateInput = {
  address1?: InputMaybe<SortOrder>;
  address2?: InputMaybe<SortOrder>;
  city?: InputMaybe<SortOrder>;
  country?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  district?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  ownerId?: InputMaybe<SortOrder>;
  postcode?: InputMaybe<SortOrder>;
  state?: InputMaybe<SortOrder>;
  street?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type AddressOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type AddressOrderByWithAggregationInput = {
  _count?: InputMaybe<AddressCountOrderByAggregateInput>;
  _max?: InputMaybe<AddressMaxOrderByAggregateInput>;
  _min?: InputMaybe<AddressMinOrderByAggregateInput>;
  address1?: InputMaybe<SortOrder>;
  address2?: InputMaybe<SortOrder>;
  city?: InputMaybe<SortOrder>;
  country?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  district?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  ownerId?: InputMaybe<SortOrder>;
  postcode?: InputMaybe<SortOrder>;
  state?: InputMaybe<SortOrder>;
  street?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type AddressOrderByWithRelationInput = {
  address1?: InputMaybe<SortOrder>;
  address2?: InputMaybe<SortOrder>;
  city?: InputMaybe<SortOrder>;
  country?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  district?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  manualAddr?: InputMaybe<ManualAddrOrderByWithRelationInput>;
  owner?: InputMaybe<UserOrderByWithRelationInput>;
  ownerId?: InputMaybe<SortOrder>;
  postcode?: InputMaybe<SortOrder>;
  state?: InputMaybe<SortOrder>;
  street?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  virtualAddr?: InputMaybe<VirtualAddrMappingOrderByWithRelationInput>;
};

export type AddressRelationFilter = {
  is?: InputMaybe<AddressWhereInput>;
  isNot?: InputMaybe<AddressWhereInput>;
};

export enum AddressScalarFieldEnum {
  Address1 = 'address1',
  Address2 = 'address2',
  City = 'city',
  Country = 'country',
  CreatedAt = 'createdAt',
  District = 'district',
  Id = 'id',
  OwnerId = 'ownerId',
  Postcode = 'postcode',
  State = 'state',
  Street = 'street',
  UpdatedAt = 'updatedAt'
}

export type AddressScalarWhereInput = {
  AND?: InputMaybe<Array<AddressScalarWhereInput>>;
  NOT?: InputMaybe<Array<AddressScalarWhereInput>>;
  OR?: InputMaybe<Array<AddressScalarWhereInput>>;
  address1?: InputMaybe<StringFilter>;
  address2?: InputMaybe<StringNullableFilter>;
  city?: InputMaybe<StringFilter>;
  country?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  district?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<StringFilter>;
  ownerId?: InputMaybe<StringNullableFilter>;
  postcode?: InputMaybe<StringFilter>;
  state?: InputMaybe<StringNullableFilter>;
  street?: InputMaybe<StringNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type AddressScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<AddressScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<AddressScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<AddressScalarWhereWithAggregatesInput>>;
  address1?: InputMaybe<StringWithAggregatesFilter>;
  address2?: InputMaybe<StringNullableWithAggregatesFilter>;
  city?: InputMaybe<StringWithAggregatesFilter>;
  country?: InputMaybe<StringWithAggregatesFilter>;
  createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  district?: InputMaybe<StringNullableWithAggregatesFilter>;
  id?: InputMaybe<StringWithAggregatesFilter>;
  ownerId?: InputMaybe<StringNullableWithAggregatesFilter>;
  postcode?: InputMaybe<StringWithAggregatesFilter>;
  state?: InputMaybe<StringNullableWithAggregatesFilter>;
  street?: InputMaybe<StringNullableWithAggregatesFilter>;
  updatedAt?: InputMaybe<DateTimeWithAggregatesFilter>;
};

export type AddressUpdateInput = {
  address1?: InputMaybe<StringFieldUpdateOperationsInput>;
  address2?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  city?: InputMaybe<StringFieldUpdateOperationsInput>;
  country?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  district?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  manualAddr?: InputMaybe<ManualAddrUpdateOneWithoutAddressNestedInput>;
  owner?: InputMaybe<UserUpdateOneWithoutAddressNestedInput>;
  postcode?: InputMaybe<StringFieldUpdateOperationsInput>;
  state?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  street?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  virtualAddr?: InputMaybe<VirtualAddrMappingUpdateOneWithoutRealAddressNestedInput>;
};

export type AddressUpdateManyMutationInput = {
  address1?: InputMaybe<StringFieldUpdateOperationsInput>;
  address2?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  city?: InputMaybe<StringFieldUpdateOperationsInput>;
  country?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  district?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  postcode?: InputMaybe<StringFieldUpdateOperationsInput>;
  state?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  street?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type AddressUpdateManyWithWhereWithoutOwnerInput = {
  data: AddressUpdateManyMutationInput;
  where: AddressScalarWhereInput;
};

export type AddressUpdateManyWithoutOwnerNestedInput = {
  connect?: InputMaybe<Array<AddressWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<AddressCreateOrConnectWithoutOwnerInput>>;
  create?: InputMaybe<Array<AddressCreateWithoutOwnerInput>>;
  createMany?: InputMaybe<AddressCreateManyOwnerInputEnvelope>;
  delete?: InputMaybe<Array<AddressWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<AddressScalarWhereInput>>;
  disconnect?: InputMaybe<Array<AddressWhereUniqueInput>>;
  set?: InputMaybe<Array<AddressWhereUniqueInput>>;
  update?: InputMaybe<Array<AddressUpdateWithWhereUniqueWithoutOwnerInput>>;
  updateMany?: InputMaybe<Array<AddressUpdateManyWithWhereWithoutOwnerInput>>;
  upsert?: InputMaybe<Array<AddressUpsertWithWhereUniqueWithoutOwnerInput>>;
};

export type AddressUpdateOneRequiredWithoutManualAddrNestedInput = {
  connect?: InputMaybe<AddressWhereUniqueInput>;
  connectOrCreate?: InputMaybe<AddressCreateOrConnectWithoutManualAddrInput>;
  create?: InputMaybe<AddressCreateWithoutManualAddrInput>;
  update?: InputMaybe<AddressUpdateWithoutManualAddrInput>;
  upsert?: InputMaybe<AddressUpsertWithoutManualAddrInput>;
};

export type AddressUpdateOneWithoutVirtualAddrNestedInput = {
  connect?: InputMaybe<AddressWhereUniqueInput>;
  connectOrCreate?: InputMaybe<AddressCreateOrConnectWithoutVirtualAddrInput>;
  create?: InputMaybe<AddressCreateWithoutVirtualAddrInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<AddressUpdateWithoutVirtualAddrInput>;
  upsert?: InputMaybe<AddressUpsertWithoutVirtualAddrInput>;
};

export type AddressUpdateWithWhereUniqueWithoutOwnerInput = {
  data: AddressUpdateWithoutOwnerInput;
  where: AddressWhereUniqueInput;
};

export type AddressUpdateWithoutManualAddrInput = {
  address1?: InputMaybe<StringFieldUpdateOperationsInput>;
  address2?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  city?: InputMaybe<StringFieldUpdateOperationsInput>;
  country?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  district?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  owner?: InputMaybe<UserUpdateOneWithoutAddressNestedInput>;
  postcode?: InputMaybe<StringFieldUpdateOperationsInput>;
  state?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  street?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  virtualAddr?: InputMaybe<VirtualAddrMappingUpdateOneWithoutRealAddressNestedInput>;
};

export type AddressUpdateWithoutOwnerInput = {
  address1?: InputMaybe<StringFieldUpdateOperationsInput>;
  address2?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  city?: InputMaybe<StringFieldUpdateOperationsInput>;
  country?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  district?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  manualAddr?: InputMaybe<ManualAddrUpdateOneWithoutAddressNestedInput>;
  postcode?: InputMaybe<StringFieldUpdateOperationsInput>;
  state?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  street?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  virtualAddr?: InputMaybe<VirtualAddrMappingUpdateOneWithoutRealAddressNestedInput>;
};

export type AddressUpdateWithoutVirtualAddrInput = {
  address1?: InputMaybe<StringFieldUpdateOperationsInput>;
  address2?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  city?: InputMaybe<StringFieldUpdateOperationsInput>;
  country?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  district?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  manualAddr?: InputMaybe<ManualAddrUpdateOneWithoutAddressNestedInput>;
  owner?: InputMaybe<UserUpdateOneWithoutAddressNestedInput>;
  postcode?: InputMaybe<StringFieldUpdateOperationsInput>;
  state?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  street?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type AddressUpsertWithWhereUniqueWithoutOwnerInput = {
  create: AddressCreateWithoutOwnerInput;
  update: AddressUpdateWithoutOwnerInput;
  where: AddressWhereUniqueInput;
};

export type AddressUpsertWithoutManualAddrInput = {
  create: AddressCreateWithoutManualAddrInput;
  update: AddressUpdateWithoutManualAddrInput;
};

export type AddressUpsertWithoutVirtualAddrInput = {
  create: AddressCreateWithoutVirtualAddrInput;
  update: AddressUpdateWithoutVirtualAddrInput;
};

export type AddressWhereInput = {
  AND?: InputMaybe<Array<AddressWhereInput>>;
  NOT?: InputMaybe<Array<AddressWhereInput>>;
  OR?: InputMaybe<Array<AddressWhereInput>>;
  address1?: InputMaybe<StringFilter>;
  address2?: InputMaybe<StringNullableFilter>;
  city?: InputMaybe<StringFilter>;
  country?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  district?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<StringFilter>;
  manualAddr?: InputMaybe<ManualAddrRelationFilter>;
  owner?: InputMaybe<UserRelationFilter>;
  ownerId?: InputMaybe<StringNullableFilter>;
  postcode?: InputMaybe<StringFilter>;
  state?: InputMaybe<StringNullableFilter>;
  street?: InputMaybe<StringNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  virtualAddr?: InputMaybe<VirtualAddrMappingRelationFilter>;
};

export type AddressWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
};

export enum AdsAction {
  Link = 'link',
  Operation = 'operation'
}

export type Advertisement = {
  __typename?: 'Advertisement';
  _count?: Maybe<AdvertisementCount>;
  act: AdsAction;
  createdAt: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  inStoreItem: Array<StoreItem>;
  linkUri?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  opCode?: Maybe<Scalars['String']>;
  picUri: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};


export type AdvertisementInStoreItemArgs = {
  cursor?: InputMaybe<StoreItemWhereUniqueInput>;
  distinct?: InputMaybe<Array<StoreItemScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<StoreItemOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<StoreItemWhereInput>;
};

export type AdvertisementCount = {
  __typename?: 'AdvertisementCount';
  inStoreItem: Scalars['Int'];
};


export type AdvertisementCountInStoreItemArgs = {
  where?: InputMaybe<StoreItemWhereInput>;
};

export type AdvertisementCountAggregate = {
  __typename?: 'AdvertisementCountAggregate';
  _all: Scalars['Int'];
  act: Scalars['Int'];
  createdAt: Scalars['Int'];
  description: Scalars['Int'];
  id: Scalars['Int'];
  linkUri: Scalars['Int'];
  name: Scalars['Int'];
  opCode: Scalars['Int'];
  picUri: Scalars['Int'];
  updatedAt: Scalars['Int'];
};

export type AdvertisementCountOrderByAggregateInput = {
  act?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  linkUri?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  opCode?: InputMaybe<SortOrder>;
  picUri?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type AdvertisementCreateInput = {
  act: AdsAction;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  inStoreItem?: InputMaybe<StoreItemCreateNestedManyWithoutItemAdsInput>;
  linkUri?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  opCode?: InputMaybe<Scalars['String']>;
  picUri: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type AdvertisementCreateManyInput = {
  act: AdsAction;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  linkUri?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  opCode?: InputMaybe<Scalars['String']>;
  picUri: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type AdvertisementCreateNestedOneWithoutInStoreItemInput = {
  connect?: InputMaybe<AdvertisementWhereUniqueInput>;
  connectOrCreate?: InputMaybe<AdvertisementCreateOrConnectWithoutInStoreItemInput>;
  create?: InputMaybe<AdvertisementCreateWithoutInStoreItemInput>;
};

export type AdvertisementCreateOrConnectWithoutInStoreItemInput = {
  create: AdvertisementCreateWithoutInStoreItemInput;
  where: AdvertisementWhereUniqueInput;
};

export type AdvertisementCreateWithoutInStoreItemInput = {
  act: AdsAction;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  linkUri?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  opCode?: InputMaybe<Scalars['String']>;
  picUri: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type AdvertisementGroupBy = {
  __typename?: 'AdvertisementGroupBy';
  _count?: Maybe<AdvertisementCountAggregate>;
  _max?: Maybe<AdvertisementMaxAggregate>;
  _min?: Maybe<AdvertisementMinAggregate>;
  act: AdsAction;
  createdAt: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  linkUri?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  opCode?: Maybe<Scalars['String']>;
  picUri: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type AdvertisementMaxAggregate = {
  __typename?: 'AdvertisementMaxAggregate';
  act?: Maybe<AdsAction>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  linkUri?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  opCode?: Maybe<Scalars['String']>;
  picUri?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AdvertisementMaxOrderByAggregateInput = {
  act?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  linkUri?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  opCode?: InputMaybe<SortOrder>;
  picUri?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type AdvertisementMinAggregate = {
  __typename?: 'AdvertisementMinAggregate';
  act?: Maybe<AdsAction>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  linkUri?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  opCode?: Maybe<Scalars['String']>;
  picUri?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AdvertisementMinOrderByAggregateInput = {
  act?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  linkUri?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  opCode?: InputMaybe<SortOrder>;
  picUri?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type AdvertisementOrderByWithAggregationInput = {
  _count?: InputMaybe<AdvertisementCountOrderByAggregateInput>;
  _max?: InputMaybe<AdvertisementMaxOrderByAggregateInput>;
  _min?: InputMaybe<AdvertisementMinOrderByAggregateInput>;
  act?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  linkUri?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  opCode?: InputMaybe<SortOrder>;
  picUri?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type AdvertisementOrderByWithRelationInput = {
  act?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  inStoreItem?: InputMaybe<StoreItemOrderByRelationAggregateInput>;
  linkUri?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  opCode?: InputMaybe<SortOrder>;
  picUri?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type AdvertisementRelationFilter = {
  is?: InputMaybe<AdvertisementWhereInput>;
  isNot?: InputMaybe<AdvertisementWhereInput>;
};

export enum AdvertisementScalarFieldEnum {
  Act = 'act',
  CreatedAt = 'createdAt',
  Description = 'description',
  Id = 'id',
  LinkUri = 'linkUri',
  Name = 'name',
  OpCode = 'opCode',
  PicUri = 'picUri',
  UpdatedAt = 'updatedAt'
}

export type AdvertisementScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<AdvertisementScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<AdvertisementScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<AdvertisementScalarWhereWithAggregatesInput>>;
  act?: InputMaybe<EnumAdsActionWithAggregatesFilter>;
  createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  description?: InputMaybe<StringNullableWithAggregatesFilter>;
  id?: InputMaybe<StringWithAggregatesFilter>;
  linkUri?: InputMaybe<StringNullableWithAggregatesFilter>;
  name?: InputMaybe<StringWithAggregatesFilter>;
  opCode?: InputMaybe<StringNullableWithAggregatesFilter>;
  picUri?: InputMaybe<StringWithAggregatesFilter>;
  updatedAt?: InputMaybe<DateTimeWithAggregatesFilter>;
};

export type AdvertisementUpdateInput = {
  act?: InputMaybe<EnumAdsActionFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  inStoreItem?: InputMaybe<StoreItemUpdateManyWithoutItemAdsNestedInput>;
  linkUri?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  opCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  picUri?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type AdvertisementUpdateManyMutationInput = {
  act?: InputMaybe<EnumAdsActionFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  linkUri?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  opCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  picUri?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type AdvertisementUpdateOneWithoutInStoreItemNestedInput = {
  connect?: InputMaybe<AdvertisementWhereUniqueInput>;
  connectOrCreate?: InputMaybe<AdvertisementCreateOrConnectWithoutInStoreItemInput>;
  create?: InputMaybe<AdvertisementCreateWithoutInStoreItemInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<AdvertisementUpdateWithoutInStoreItemInput>;
  upsert?: InputMaybe<AdvertisementUpsertWithoutInStoreItemInput>;
};

export type AdvertisementUpdateWithoutInStoreItemInput = {
  act?: InputMaybe<EnumAdsActionFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  linkUri?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  opCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  picUri?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type AdvertisementUpsertWithoutInStoreItemInput = {
  create: AdvertisementCreateWithoutInStoreItemInput;
  update: AdvertisementUpdateWithoutInStoreItemInput;
};

export type AdvertisementWhereInput = {
  AND?: InputMaybe<Array<AdvertisementWhereInput>>;
  NOT?: InputMaybe<Array<AdvertisementWhereInput>>;
  OR?: InputMaybe<Array<AdvertisementWhereInput>>;
  act?: InputMaybe<EnumAdsActionFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  description?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<StringFilter>;
  inStoreItem?: InputMaybe<StoreItemListRelationFilter>;
  linkUri?: InputMaybe<StringNullableFilter>;
  name?: InputMaybe<StringFilter>;
  opCode?: InputMaybe<StringNullableFilter>;
  picUri?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type AdvertisementWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
};

export type AffectedRowsOutput = {
  __typename?: 'AffectedRowsOutput';
  count: Scalars['Int'];
};

export type AggregateAddress = {
  __typename?: 'AggregateAddress';
  _count?: Maybe<AddressCountAggregate>;
  _max?: Maybe<AddressMaxAggregate>;
  _min?: Maybe<AddressMinAggregate>;
};

export type AggregateAdvertisement = {
  __typename?: 'AggregateAdvertisement';
  _count?: Maybe<AdvertisementCountAggregate>;
  _max?: Maybe<AdvertisementMaxAggregate>;
  _min?: Maybe<AdvertisementMinAggregate>;
};

export type AggregateAttachment = {
  __typename?: 'AggregateAttachment';
  _count?: Maybe<AttachmentCountAggregate>;
  _max?: Maybe<AttachmentMaxAggregate>;
  _min?: Maybe<AttachmentMinAggregate>;
};

export type AggregateBenefitMemo = {
  __typename?: 'AggregateBenefitMemo';
  _avg?: Maybe<BenefitMemoAvgAggregate>;
  _count?: Maybe<BenefitMemoCountAggregate>;
  _max?: Maybe<BenefitMemoMaxAggregate>;
  _min?: Maybe<BenefitMemoMinAggregate>;
  _sum?: Maybe<BenefitMemoSumAggregate>;
};

export type AggregateBenefitMemoToCoin = {
  __typename?: 'AggregateBenefitMemoToCoin';
  _avg?: Maybe<BenefitMemoToCoinAvgAggregate>;
  _count?: Maybe<BenefitMemoToCoinCountAggregate>;
  _max?: Maybe<BenefitMemoToCoinMaxAggregate>;
  _min?: Maybe<BenefitMemoToCoinMinAggregate>;
  _sum?: Maybe<BenefitMemoToCoinSumAggregate>;
};

export type AggregateBenefitMemoToOrder = {
  __typename?: 'AggregateBenefitMemoToOrder';
  _avg?: Maybe<BenefitMemoToOrderAvgAggregate>;
  _count?: Maybe<BenefitMemoToOrderCountAggregate>;
  _max?: Maybe<BenefitMemoToOrderMaxAggregate>;
  _min?: Maybe<BenefitMemoToOrderMinAggregate>;
  _sum?: Maybe<BenefitMemoToOrderSumAggregate>;
};

export type AggregateBenefitTransfer = {
  __typename?: 'AggregateBenefitTransfer';
  _avg?: Maybe<BenefitTransferAvgAggregate>;
  _count?: Maybe<BenefitTransferCountAggregate>;
  _max?: Maybe<BenefitTransferMaxAggregate>;
  _min?: Maybe<BenefitTransferMinAggregate>;
  _sum?: Maybe<BenefitTransferSumAggregate>;
};

export type AggregateCampaignNanhui = {
  __typename?: 'AggregateCampaignNanhui';
  _count?: Maybe<CampaignNanhuiCountAggregate>;
  _max?: Maybe<CampaignNanhuiMaxAggregate>;
  _min?: Maybe<CampaignNanhuiMinAggregate>;
};

export type AggregateCampainZeczec = {
  __typename?: 'AggregateCampainZeczec';
  _count?: Maybe<CampainZeczecCountAggregate>;
  _max?: Maybe<CampainZeczecMaxAggregate>;
  _min?: Maybe<CampainZeczecMinAggregate>;
};

export type AggregateCard = {
  __typename?: 'AggregateCard';
  _avg?: Maybe<CardAvgAggregate>;
  _count?: Maybe<CardCountAggregate>;
  _max?: Maybe<CardMaxAggregate>;
  _min?: Maybe<CardMinAggregate>;
  _sum?: Maybe<CardSumAggregate>;
};

export type AggregateCartItem = {
  __typename?: 'AggregateCartItem';
  _avg?: Maybe<CartItemAvgAggregate>;
  _count?: Maybe<CartItemCountAggregate>;
  _max?: Maybe<CartItemMaxAggregate>;
  _min?: Maybe<CartItemMinAggregate>;
  _sum?: Maybe<CartItemSumAggregate>;
};

export type AggregateCoin = {
  __typename?: 'AggregateCoin';
  _avg?: Maybe<CoinAvgAggregate>;
  _count?: Maybe<CoinCountAggregate>;
  _max?: Maybe<CoinMaxAggregate>;
  _min?: Maybe<CoinMinAggregate>;
  _sum?: Maybe<CoinSumAggregate>;
};

export type AggregateCoinPack = {
  __typename?: 'AggregateCoinPack';
  _avg?: Maybe<CoinPackAvgAggregate>;
  _count?: Maybe<CoinPackCountAggregate>;
  _max?: Maybe<CoinPackMaxAggregate>;
  _min?: Maybe<CoinPackMinAggregate>;
  _sum?: Maybe<CoinPackSumAggregate>;
};

export type AggregateCollection = {
  __typename?: 'AggregateCollection';
  _count?: Maybe<CollectionCountAggregate>;
  _max?: Maybe<CollectionMaxAggregate>;
  _min?: Maybe<CollectionMinAggregate>;
};

export type AggregateCouponActivity = {
  __typename?: 'AggregateCouponActivity';
  _count?: Maybe<CouponActivityCountAggregate>;
  _max?: Maybe<CouponActivityMaxAggregate>;
  _min?: Maybe<CouponActivityMinAggregate>;
};

export type AggregateCouponEntity = {
  __typename?: 'AggregateCouponEntity';
  _avg?: Maybe<CouponEntityAvgAggregate>;
  _count?: Maybe<CouponEntityCountAggregate>;
  _max?: Maybe<CouponEntityMaxAggregate>;
  _min?: Maybe<CouponEntityMinAggregate>;
  _sum?: Maybe<CouponEntitySumAggregate>;
};

export type AggregateCouponGroup = {
  __typename?: 'AggregateCouponGroup';
  _count?: Maybe<CouponGroupCountAggregate>;
  _max?: Maybe<CouponGroupMaxAggregate>;
  _min?: Maybe<CouponGroupMinAggregate>;
};

export type AggregateCouponInfo = {
  __typename?: 'AggregateCouponInfo';
  _avg?: Maybe<CouponInfoAvgAggregate>;
  _count?: Maybe<CouponInfoCountAggregate>;
  _max?: Maybe<CouponInfoMaxAggregate>;
  _min?: Maybe<CouponInfoMinAggregate>;
  _sum?: Maybe<CouponInfoSumAggregate>;
};

export type AggregateCouponUsage = {
  __typename?: 'AggregateCouponUsage';
  _count?: Maybe<CouponUsageCountAggregate>;
  _max?: Maybe<CouponUsageMaxAggregate>;
  _min?: Maybe<CouponUsageMinAggregate>;
};

export type AggregateDeviceToken = {
  __typename?: 'AggregateDeviceToken';
  _count?: Maybe<DeviceTokenCountAggregate>;
  _max?: Maybe<DeviceTokenMaxAggregate>;
  _min?: Maybe<DeviceTokenMinAggregate>;
};

export type AggregateEcloudlife = {
  __typename?: 'AggregateEcloudlife';
  _count?: Maybe<EcloudlifeCountAggregate>;
  _max?: Maybe<EcloudlifeMaxAggregate>;
  _min?: Maybe<EcloudlifeMinAggregate>;
};

export type AggregateEcloudlifeTrack = {
  __typename?: 'AggregateEcloudlifeTrack';
  _avg?: Maybe<EcloudlifeTrackAvgAggregate>;
  _count?: Maybe<EcloudlifeTrackCountAggregate>;
  _max?: Maybe<EcloudlifeTrackMaxAggregate>;
  _min?: Maybe<EcloudlifeTrackMinAggregate>;
  _sum?: Maybe<EcloudlifeTrackSumAggregate>;
};

export type AggregateEmailTo = {
  __typename?: 'AggregateEmailTo';
  _count?: Maybe<EmailToCountAggregate>;
  _max?: Maybe<EmailToMaxAggregate>;
  _min?: Maybe<EmailToMinAggregate>;
};

export type AggregateGroup = {
  __typename?: 'AggregateGroup';
  _count?: Maybe<GroupCountAggregate>;
  _max?: Maybe<GroupMaxAggregate>;
  _min?: Maybe<GroupMinAggregate>;
};

export type AggregateInValidReason = {
  __typename?: 'AggregateInValidReason';
  _count?: Maybe<InValidReasonCountAggregate>;
  _max?: Maybe<InValidReasonMaxAggregate>;
  _min?: Maybe<InValidReasonMinAggregate>;
};

export type AggregateInvoice = {
  __typename?: 'AggregateInvoice';
  _count?: Maybe<InvoiceCountAggregate>;
  _max?: Maybe<InvoiceMaxAggregate>;
  _min?: Maybe<InvoiceMinAggregate>;
};

export type AggregateManualAddr = {
  __typename?: 'AggregateManualAddr';
  _count?: Maybe<ManualAddrCountAggregate>;
  _max?: Maybe<ManualAddrMaxAggregate>;
  _min?: Maybe<ManualAddrMinAggregate>;
};

export type AggregateNotification = {
  __typename?: 'AggregateNotification';
  _count?: Maybe<NotificationCountAggregate>;
  _max?: Maybe<NotificationMaxAggregate>;
  _min?: Maybe<NotificationMinAggregate>;
};

export type AggregateOrder = {
  __typename?: 'AggregateOrder';
  _avg?: Maybe<OrderAvgAggregate>;
  _count?: Maybe<OrderCountAggregate>;
  _max?: Maybe<OrderMaxAggregate>;
  _min?: Maybe<OrderMinAggregate>;
  _sum?: Maybe<OrderSumAggregate>;
};

export type AggregateOrderDetail = {
  __typename?: 'AggregateOrderDetail';
  _avg?: Maybe<OrderDetailAvgAggregate>;
  _count?: Maybe<OrderDetailCountAggregate>;
  _max?: Maybe<OrderDetailMaxAggregate>;
  _min?: Maybe<OrderDetailMinAggregate>;
  _sum?: Maybe<OrderDetailSumAggregate>;
};

export type AggregateOrderDetailHistory = {
  __typename?: 'AggregateOrderDetailHistory';
  _count?: Maybe<OrderDetailHistoryCountAggregate>;
  _max?: Maybe<OrderDetailHistoryMaxAggregate>;
  _min?: Maybe<OrderDetailHistoryMinAggregate>;
};

export type AggregateOrderTag = {
  __typename?: 'AggregateOrderTag';
  _count?: Maybe<OrderTagCountAggregate>;
  _max?: Maybe<OrderTagMaxAggregate>;
  _min?: Maybe<OrderTagMinAggregate>;
};

export type AggregatePayment = {
  __typename?: 'AggregatePayment';
  _count?: Maybe<PaymentCountAggregate>;
  _max?: Maybe<PaymentMaxAggregate>;
  _min?: Maybe<PaymentMinAggregate>;
};

export type AggregatePost = {
  __typename?: 'AggregatePost';
  _count?: Maybe<PostCountAggregate>;
  _max?: Maybe<PostMaxAggregate>;
  _min?: Maybe<PostMinAggregate>;
};

export type AggregatePostHistory = {
  __typename?: 'AggregatePostHistory';
  _count?: Maybe<PostHistoryCountAggregate>;
  _max?: Maybe<PostHistoryMaxAggregate>;
  _min?: Maybe<PostHistoryMinAggregate>;
};

export type AggregatePostPackage = {
  __typename?: 'AggregatePostPackage';
  _avg?: Maybe<PostPackageAvgAggregate>;
  _count?: Maybe<PostPackageCountAggregate>;
  _max?: Maybe<PostPackageMaxAggregate>;
  _min?: Maybe<PostPackageMinAggregate>;
  _sum?: Maybe<PostPackageSumAggregate>;
};

export type AggregatePostTag = {
  __typename?: 'AggregatePostTag';
  _avg?: Maybe<PostTagAvgAggregate>;
  _count?: Maybe<PostTagCountAggregate>;
  _max?: Maybe<PostTagMaxAggregate>;
  _min?: Maybe<PostTagMinAggregate>;
  _sum?: Maybe<PostTagSumAggregate>;
};

export type AggregateProduct = {
  __typename?: 'AggregateProduct';
  _avg?: Maybe<ProductAvgAggregate>;
  _count?: Maybe<ProductCountAggregate>;
  _max?: Maybe<ProductMaxAggregate>;
  _min?: Maybe<ProductMinAggregate>;
  _sum?: Maybe<ProductSumAggregate>;
};

export type AggregateProfitable = {
  __typename?: 'AggregateProfitable';
  _avg?: Maybe<ProfitableAvgAggregate>;
  _count?: Maybe<ProfitableCountAggregate>;
  _max?: Maybe<ProfitableMaxAggregate>;
  _min?: Maybe<ProfitableMinAggregate>;
  _sum?: Maybe<ProfitableSumAggregate>;
};

export type AggregateProfitableBadget = {
  __typename?: 'AggregateProfitableBadget';
  _count?: Maybe<ProfitableBadgetCountAggregate>;
  _max?: Maybe<ProfitableBadgetMaxAggregate>;
  _min?: Maybe<ProfitableBadgetMinAggregate>;
};

export type AggregateQrcode = {
  __typename?: 'AggregateQrcode';
  _count?: Maybe<QrcodeCountAggregate>;
  _max?: Maybe<QrcodeMaxAggregate>;
  _min?: Maybe<QrcodeMinAggregate>;
};

export type AggregateRemoteString = {
  __typename?: 'AggregateRemoteString';
  _count?: Maybe<RemoteStringCountAggregate>;
  _max?: Maybe<RemoteStringMaxAggregate>;
  _min?: Maybe<RemoteStringMinAggregate>;
};

export type AggregateReport = {
  __typename?: 'AggregateReport';
  _count?: Maybe<ReportCountAggregate>;
  _max?: Maybe<ReportMaxAggregate>;
  _min?: Maybe<ReportMinAggregate>;
};

export type AggregateReportUser = {
  __typename?: 'AggregateReportUser';
  _count?: Maybe<ReportUserCountAggregate>;
  _max?: Maybe<ReportUserMaxAggregate>;
  _min?: Maybe<ReportUserMinAggregate>;
};

export type AggregateScenicSpot = {
  __typename?: 'AggregateScenicSpot';
  _avg?: Maybe<ScenicSpotAvgAggregate>;
  _count?: Maybe<ScenicSpotCountAggregate>;
  _max?: Maybe<ScenicSpotMaxAggregate>;
  _min?: Maybe<ScenicSpotMinAggregate>;
  _sum?: Maybe<ScenicSpotSumAggregate>;
};

export type AggregateSettingNotification = {
  __typename?: 'AggregateSettingNotification';
  _count?: Maybe<SettingNotificationCountAggregate>;
  _max?: Maybe<SettingNotificationMaxAggregate>;
  _min?: Maybe<SettingNotificationMinAggregate>;
};

export type AggregateSettingNotificationSuppress = {
  __typename?: 'AggregateSettingNotificationSuppress';
  _count?: Maybe<SettingNotificationSuppressCountAggregate>;
  _max?: Maybe<SettingNotificationSuppressMaxAggregate>;
  _min?: Maybe<SettingNotificationSuppressMinAggregate>;
};

export type AggregateSettingPrivacy = {
  __typename?: 'AggregateSettingPrivacy';
  _count?: Maybe<SettingPrivacyCountAggregate>;
  _max?: Maybe<SettingPrivacyMaxAggregate>;
  _min?: Maybe<SettingPrivacyMinAggregate>;
};

export type AggregateSignature = {
  __typename?: 'AggregateSignature';
  _count?: Maybe<SignatureCountAggregate>;
  _max?: Maybe<SignatureMaxAggregate>;
  _min?: Maybe<SignatureMinAggregate>;
};

export type AggregateStamp = {
  __typename?: 'AggregateStamp';
  _avg?: Maybe<StampAvgAggregate>;
  _count?: Maybe<StampCountAggregate>;
  _max?: Maybe<StampMaxAggregate>;
  _min?: Maybe<StampMinAggregate>;
  _sum?: Maybe<StampSumAggregate>;
};

export type AggregateStoreItem = {
  __typename?: 'AggregateStoreItem';
  _avg?: Maybe<StoreItemAvgAggregate>;
  _count?: Maybe<StoreItemCountAggregate>;
  _max?: Maybe<StoreItemMaxAggregate>;
  _min?: Maybe<StoreItemMinAggregate>;
  _sum?: Maybe<StoreItemSumAggregate>;
};

export type AggregateStoreItemTag = {
  __typename?: 'AggregateStoreItemTag';
  _count?: Maybe<StoreItemTagCountAggregate>;
  _max?: Maybe<StoreItemTagMaxAggregate>;
  _min?: Maybe<StoreItemTagMinAggregate>;
};

export type AggregateStoreItemToCartItem = {
  __typename?: 'AggregateStoreItemToCartItem';
  _count?: Maybe<StoreItemToCartItemCountAggregate>;
  _max?: Maybe<StoreItemToCartItemMaxAggregate>;
  _min?: Maybe<StoreItemToCartItemMinAggregate>;
};

export type AggregateStoreRatio = {
  __typename?: 'AggregateStoreRatio';
  _avg?: Maybe<StoreRatioAvgAggregate>;
  _count?: Maybe<StoreRatioCountAggregate>;
  _max?: Maybe<StoreRatioMaxAggregate>;
  _min?: Maybe<StoreRatioMinAggregate>;
  _sum?: Maybe<StoreRatioSumAggregate>;
};

export type AggregateStoreRatioHistoryItem = {
  __typename?: 'AggregateStoreRatioHistoryItem';
  _count?: Maybe<StoreRatioHistoryItemCountAggregate>;
  _max?: Maybe<StoreRatioHistoryItemMaxAggregate>;
  _min?: Maybe<StoreRatioHistoryItemMinAggregate>;
};

export type AggregateTappay = {
  __typename?: 'AggregateTappay';
  _avg?: Maybe<TappayAvgAggregate>;
  _count?: Maybe<TappayCountAggregate>;
  _max?: Maybe<TappayMaxAggregate>;
  _min?: Maybe<TappayMinAggregate>;
  _sum?: Maybe<TappaySumAggregate>;
};

export type AggregateUser = {
  __typename?: 'AggregateUser';
  _avg?: Maybe<UserAvgAggregate>;
  _count?: Maybe<UserCountAggregate>;
  _max?: Maybe<UserMaxAggregate>;
  _min?: Maybe<UserMinAggregate>;
  _sum?: Maybe<UserSumAggregate>;
};

export type AggregateUserFriend = {
  __typename?: 'AggregateUserFriend';
  _count?: Maybe<UserFriendCountAggregate>;
  _max?: Maybe<UserFriendMaxAggregate>;
  _min?: Maybe<UserFriendMinAggregate>;
};

export type AggregateVirtualAddrMapping = {
  __typename?: 'AggregateVirtualAddrMapping';
  _count?: Maybe<VirtualAddrMappingCountAggregate>;
  _max?: Maybe<VirtualAddrMappingMaxAggregate>;
  _min?: Maybe<VirtualAddrMappingMinAggregate>;
};

export type AggregateVirtualLand = {
  __typename?: 'AggregateVirtualLand';
  _avg?: Maybe<VirtualLandAvgAggregate>;
  _count?: Maybe<VirtualLandCountAggregate>;
  _max?: Maybe<VirtualLandMaxAggregate>;
  _min?: Maybe<VirtualLandMinAggregate>;
  _sum?: Maybe<VirtualLandSumAggregate>;
};

export type Attachment = {
  __typename?: 'Attachment';
  bucketName: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  destFileName: Scalars['String'];
  id: Scalars['String'];
  owner?: Maybe<User>;
  ownerId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AttachmentCountAggregate = {
  __typename?: 'AttachmentCountAggregate';
  _all: Scalars['Int'];
  bucketName: Scalars['Int'];
  createdAt: Scalars['Int'];
  destFileName: Scalars['Int'];
  id: Scalars['Int'];
  ownerId: Scalars['Int'];
  updatedAt: Scalars['Int'];
};

export type AttachmentCountOrderByAggregateInput = {
  bucketName?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  destFileName?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  ownerId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type AttachmentCreateInput = {
  bucketName: Scalars['String'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  destFileName: Scalars['String'];
  id?: InputMaybe<Scalars['String']>;
  owner?: InputMaybe<UserCreateNestedOneWithoutAttachmentsInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type AttachmentCreateManyInput = {
  bucketName: Scalars['String'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  destFileName: Scalars['String'];
  id?: InputMaybe<Scalars['String']>;
  ownerId?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type AttachmentCreateManyOwnerInput = {
  bucketName: Scalars['String'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  destFileName: Scalars['String'];
  id?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type AttachmentCreateManyOwnerInputEnvelope = {
  data: Array<AttachmentCreateManyOwnerInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type AttachmentCreateNestedManyWithoutOwnerInput = {
  connect?: InputMaybe<Array<AttachmentWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<AttachmentCreateOrConnectWithoutOwnerInput>>;
  create?: InputMaybe<Array<AttachmentCreateWithoutOwnerInput>>;
  createMany?: InputMaybe<AttachmentCreateManyOwnerInputEnvelope>;
};

export type AttachmentCreateOrConnectWithoutOwnerInput = {
  create: AttachmentCreateWithoutOwnerInput;
  where: AttachmentWhereUniqueInput;
};

export type AttachmentCreateWithoutOwnerInput = {
  bucketName: Scalars['String'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  destFileName: Scalars['String'];
  id?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type AttachmentGroupBy = {
  __typename?: 'AttachmentGroupBy';
  _count?: Maybe<AttachmentCountAggregate>;
  _max?: Maybe<AttachmentMaxAggregate>;
  _min?: Maybe<AttachmentMinAggregate>;
  bucketName: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  destFileName: Scalars['String'];
  id: Scalars['String'];
  ownerId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AttachmentListRelationFilter = {
  every?: InputMaybe<AttachmentWhereInput>;
  none?: InputMaybe<AttachmentWhereInput>;
  some?: InputMaybe<AttachmentWhereInput>;
};

export type AttachmentMaxAggregate = {
  __typename?: 'AttachmentMaxAggregate';
  bucketName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  destFileName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  ownerId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AttachmentMaxOrderByAggregateInput = {
  bucketName?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  destFileName?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  ownerId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type AttachmentMinAggregate = {
  __typename?: 'AttachmentMinAggregate';
  bucketName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  destFileName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  ownerId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AttachmentMinOrderByAggregateInput = {
  bucketName?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  destFileName?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  ownerId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type AttachmentOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type AttachmentOrderByWithAggregationInput = {
  _count?: InputMaybe<AttachmentCountOrderByAggregateInput>;
  _max?: InputMaybe<AttachmentMaxOrderByAggregateInput>;
  _min?: InputMaybe<AttachmentMinOrderByAggregateInput>;
  bucketName?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  destFileName?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  ownerId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type AttachmentOrderByWithRelationInput = {
  bucketName?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  destFileName?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  owner?: InputMaybe<UserOrderByWithRelationInput>;
  ownerId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export enum AttachmentScalarFieldEnum {
  BucketName = 'bucketName',
  CreatedAt = 'createdAt',
  DestFileName = 'destFileName',
  Id = 'id',
  OwnerId = 'ownerId',
  UpdatedAt = 'updatedAt'
}

export type AttachmentScalarWhereInput = {
  AND?: InputMaybe<Array<AttachmentScalarWhereInput>>;
  NOT?: InputMaybe<Array<AttachmentScalarWhereInput>>;
  OR?: InputMaybe<Array<AttachmentScalarWhereInput>>;
  bucketName?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeNullableFilter>;
  destFileName?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  ownerId?: InputMaybe<StringNullableFilter>;
  updatedAt?: InputMaybe<DateTimeNullableFilter>;
};

export type AttachmentScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<AttachmentScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<AttachmentScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<AttachmentScalarWhereWithAggregatesInput>>;
  bucketName?: InputMaybe<StringWithAggregatesFilter>;
  createdAt?: InputMaybe<DateTimeNullableWithAggregatesFilter>;
  destFileName?: InputMaybe<StringWithAggregatesFilter>;
  id?: InputMaybe<StringWithAggregatesFilter>;
  ownerId?: InputMaybe<StringNullableWithAggregatesFilter>;
  updatedAt?: InputMaybe<DateTimeNullableWithAggregatesFilter>;
};

export type AttachmentUpdateInput = {
  bucketName?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  destFileName?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  owner?: InputMaybe<UserUpdateOneWithoutAttachmentsNestedInput>;
  updatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
};

export type AttachmentUpdateManyMutationInput = {
  bucketName?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  destFileName?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
};

export type AttachmentUpdateManyWithWhereWithoutOwnerInput = {
  data: AttachmentUpdateManyMutationInput;
  where: AttachmentScalarWhereInput;
};

export type AttachmentUpdateManyWithoutOwnerNestedInput = {
  connect?: InputMaybe<Array<AttachmentWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<AttachmentCreateOrConnectWithoutOwnerInput>>;
  create?: InputMaybe<Array<AttachmentCreateWithoutOwnerInput>>;
  createMany?: InputMaybe<AttachmentCreateManyOwnerInputEnvelope>;
  delete?: InputMaybe<Array<AttachmentWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<AttachmentScalarWhereInput>>;
  disconnect?: InputMaybe<Array<AttachmentWhereUniqueInput>>;
  set?: InputMaybe<Array<AttachmentWhereUniqueInput>>;
  update?: InputMaybe<Array<AttachmentUpdateWithWhereUniqueWithoutOwnerInput>>;
  updateMany?: InputMaybe<Array<AttachmentUpdateManyWithWhereWithoutOwnerInput>>;
  upsert?: InputMaybe<Array<AttachmentUpsertWithWhereUniqueWithoutOwnerInput>>;
};

export type AttachmentUpdateWithWhereUniqueWithoutOwnerInput = {
  data: AttachmentUpdateWithoutOwnerInput;
  where: AttachmentWhereUniqueInput;
};

export type AttachmentUpdateWithoutOwnerInput = {
  bucketName?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  destFileName?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
};

export type AttachmentUpsertWithWhereUniqueWithoutOwnerInput = {
  create: AttachmentCreateWithoutOwnerInput;
  update: AttachmentUpdateWithoutOwnerInput;
  where: AttachmentWhereUniqueInput;
};

export type AttachmentWhereInput = {
  AND?: InputMaybe<Array<AttachmentWhereInput>>;
  NOT?: InputMaybe<Array<AttachmentWhereInput>>;
  OR?: InputMaybe<Array<AttachmentWhereInput>>;
  bucketName?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeNullableFilter>;
  destFileName?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  owner?: InputMaybe<UserRelationFilter>;
  ownerId?: InputMaybe<StringNullableFilter>;
  updatedAt?: InputMaybe<DateTimeNullableFilter>;
};

export type AttachmentWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
};

export type BenefitMemo = {
  __typename?: 'BenefitMemo';
  coin?: Maybe<BenefitMemoToCoin>;
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  order?: Maybe<BenefitMemoToOrder>;
  profitable: Profitable;
  profitableId: Scalars['String'];
  receipt?: Maybe<Scalars['JSON']>;
  transfer?: Maybe<BenefitTransfer>;
  transferAmount?: Maybe<Scalars['Float']>;
  transferId?: Maybe<Scalars['String']>;
  type: BenefitType;
  updatedAt: Scalars['DateTime'];
};

export type BenefitMemoAvgAggregate = {
  __typename?: 'BenefitMemoAvgAggregate';
  transferAmount?: Maybe<Scalars['Float']>;
};

export type BenefitMemoAvgOrderByAggregateInput = {
  transferAmount?: InputMaybe<SortOrder>;
};

export type BenefitMemoCountAggregate = {
  __typename?: 'BenefitMemoCountAggregate';
  _all: Scalars['Int'];
  createdAt: Scalars['Int'];
  id: Scalars['Int'];
  profitableId: Scalars['Int'];
  receipt: Scalars['Int'];
  transferAmount: Scalars['Int'];
  transferId: Scalars['Int'];
  type: Scalars['Int'];
  updatedAt: Scalars['Int'];
};

export type BenefitMemoCountOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  profitableId?: InputMaybe<SortOrder>;
  receipt?: InputMaybe<SortOrder>;
  transferAmount?: InputMaybe<SortOrder>;
  transferId?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type BenefitMemoCreateInput = {
  coin?: InputMaybe<BenefitMemoToCoinCreateNestedOneWithoutBenefitMemoInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<BenefitMemoToOrderCreateNestedOneWithoutBenefitMemoInput>;
  profitable: ProfitableCreateNestedOneWithoutBenefitMemosInput;
  receipt?: InputMaybe<Scalars['JSON']>;
  transfer?: InputMaybe<BenefitTransferCreateNestedOneWithoutBenifitMemoInput>;
  transferAmount?: InputMaybe<Scalars['Float']>;
  type: BenefitType;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type BenefitMemoCreateManyInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  profitableId: Scalars['String'];
  receipt?: InputMaybe<Scalars['JSON']>;
  transferAmount?: InputMaybe<Scalars['Float']>;
  transferId?: InputMaybe<Scalars['String']>;
  type: BenefitType;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type BenefitMemoCreateManyProfitableInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  receipt?: InputMaybe<Scalars['JSON']>;
  transferAmount?: InputMaybe<Scalars['Float']>;
  transferId?: InputMaybe<Scalars['String']>;
  type: BenefitType;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type BenefitMemoCreateManyProfitableInputEnvelope = {
  data: Array<BenefitMemoCreateManyProfitableInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type BenefitMemoCreateManyTransferInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  profitableId: Scalars['String'];
  receipt?: InputMaybe<Scalars['JSON']>;
  transferAmount?: InputMaybe<Scalars['Float']>;
  type: BenefitType;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type BenefitMemoCreateManyTransferInputEnvelope = {
  data: Array<BenefitMemoCreateManyTransferInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type BenefitMemoCreateNestedManyWithoutProfitableInput = {
  connect?: InputMaybe<Array<BenefitMemoWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<BenefitMemoCreateOrConnectWithoutProfitableInput>>;
  create?: InputMaybe<Array<BenefitMemoCreateWithoutProfitableInput>>;
  createMany?: InputMaybe<BenefitMemoCreateManyProfitableInputEnvelope>;
};

export type BenefitMemoCreateNestedManyWithoutTransferInput = {
  connect?: InputMaybe<Array<BenefitMemoWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<BenefitMemoCreateOrConnectWithoutTransferInput>>;
  create?: InputMaybe<Array<BenefitMemoCreateWithoutTransferInput>>;
  createMany?: InputMaybe<BenefitMemoCreateManyTransferInputEnvelope>;
};

export type BenefitMemoCreateNestedOneWithoutCoinInput = {
  connect?: InputMaybe<BenefitMemoWhereUniqueInput>;
  connectOrCreate?: InputMaybe<BenefitMemoCreateOrConnectWithoutCoinInput>;
  create?: InputMaybe<BenefitMemoCreateWithoutCoinInput>;
};

export type BenefitMemoCreateNestedOneWithoutOrderInput = {
  connect?: InputMaybe<BenefitMemoWhereUniqueInput>;
  connectOrCreate?: InputMaybe<BenefitMemoCreateOrConnectWithoutOrderInput>;
  create?: InputMaybe<BenefitMemoCreateWithoutOrderInput>;
};

export type BenefitMemoCreateOrConnectWithoutCoinInput = {
  create: BenefitMemoCreateWithoutCoinInput;
  where: BenefitMemoWhereUniqueInput;
};

export type BenefitMemoCreateOrConnectWithoutOrderInput = {
  create: BenefitMemoCreateWithoutOrderInput;
  where: BenefitMemoWhereUniqueInput;
};

export type BenefitMemoCreateOrConnectWithoutProfitableInput = {
  create: BenefitMemoCreateWithoutProfitableInput;
  where: BenefitMemoWhereUniqueInput;
};

export type BenefitMemoCreateOrConnectWithoutTransferInput = {
  create: BenefitMemoCreateWithoutTransferInput;
  where: BenefitMemoWhereUniqueInput;
};

export type BenefitMemoCreateWithoutCoinInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<BenefitMemoToOrderCreateNestedOneWithoutBenefitMemoInput>;
  profitable: ProfitableCreateNestedOneWithoutBenefitMemosInput;
  receipt?: InputMaybe<Scalars['JSON']>;
  transfer?: InputMaybe<BenefitTransferCreateNestedOneWithoutBenifitMemoInput>;
  transferAmount?: InputMaybe<Scalars['Float']>;
  type: BenefitType;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type BenefitMemoCreateWithoutOrderInput = {
  coin?: InputMaybe<BenefitMemoToCoinCreateNestedOneWithoutBenefitMemoInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  profitable: ProfitableCreateNestedOneWithoutBenefitMemosInput;
  receipt?: InputMaybe<Scalars['JSON']>;
  transfer?: InputMaybe<BenefitTransferCreateNestedOneWithoutBenifitMemoInput>;
  transferAmount?: InputMaybe<Scalars['Float']>;
  type: BenefitType;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type BenefitMemoCreateWithoutProfitableInput = {
  coin?: InputMaybe<BenefitMemoToCoinCreateNestedOneWithoutBenefitMemoInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<BenefitMemoToOrderCreateNestedOneWithoutBenefitMemoInput>;
  receipt?: InputMaybe<Scalars['JSON']>;
  transfer?: InputMaybe<BenefitTransferCreateNestedOneWithoutBenifitMemoInput>;
  transferAmount?: InputMaybe<Scalars['Float']>;
  type: BenefitType;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type BenefitMemoCreateWithoutTransferInput = {
  coin?: InputMaybe<BenefitMemoToCoinCreateNestedOneWithoutBenefitMemoInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<BenefitMemoToOrderCreateNestedOneWithoutBenefitMemoInput>;
  profitable: ProfitableCreateNestedOneWithoutBenefitMemosInput;
  receipt?: InputMaybe<Scalars['JSON']>;
  transferAmount?: InputMaybe<Scalars['Float']>;
  type: BenefitType;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type BenefitMemoGroupBy = {
  __typename?: 'BenefitMemoGroupBy';
  _avg?: Maybe<BenefitMemoAvgAggregate>;
  _count?: Maybe<BenefitMemoCountAggregate>;
  _max?: Maybe<BenefitMemoMaxAggregate>;
  _min?: Maybe<BenefitMemoMinAggregate>;
  _sum?: Maybe<BenefitMemoSumAggregate>;
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  profitableId: Scalars['String'];
  receipt?: Maybe<Scalars['JSON']>;
  transferAmount?: Maybe<Scalars['Float']>;
  transferId?: Maybe<Scalars['String']>;
  type: BenefitType;
  updatedAt: Scalars['DateTime'];
};

export type BenefitMemoListRelationFilter = {
  every?: InputMaybe<BenefitMemoWhereInput>;
  none?: InputMaybe<BenefitMemoWhereInput>;
  some?: InputMaybe<BenefitMemoWhereInput>;
};

export type BenefitMemoMaxAggregate = {
  __typename?: 'BenefitMemoMaxAggregate';
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  profitableId?: Maybe<Scalars['String']>;
  transferAmount?: Maybe<Scalars['Float']>;
  transferId?: Maybe<Scalars['String']>;
  type?: Maybe<BenefitType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BenefitMemoMaxOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  profitableId?: InputMaybe<SortOrder>;
  transferAmount?: InputMaybe<SortOrder>;
  transferId?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type BenefitMemoMinAggregate = {
  __typename?: 'BenefitMemoMinAggregate';
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  profitableId?: Maybe<Scalars['String']>;
  transferAmount?: Maybe<Scalars['Float']>;
  transferId?: Maybe<Scalars['String']>;
  type?: Maybe<BenefitType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BenefitMemoMinOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  profitableId?: InputMaybe<SortOrder>;
  transferAmount?: InputMaybe<SortOrder>;
  transferId?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type BenefitMemoOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type BenefitMemoOrderByWithAggregationInput = {
  _avg?: InputMaybe<BenefitMemoAvgOrderByAggregateInput>;
  _count?: InputMaybe<BenefitMemoCountOrderByAggregateInput>;
  _max?: InputMaybe<BenefitMemoMaxOrderByAggregateInput>;
  _min?: InputMaybe<BenefitMemoMinOrderByAggregateInput>;
  _sum?: InputMaybe<BenefitMemoSumOrderByAggregateInput>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  profitableId?: InputMaybe<SortOrder>;
  receipt?: InputMaybe<SortOrder>;
  transferAmount?: InputMaybe<SortOrder>;
  transferId?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type BenefitMemoOrderByWithRelationInput = {
  coin?: InputMaybe<BenefitMemoToCoinOrderByWithRelationInput>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  order?: InputMaybe<BenefitMemoToOrderOrderByWithRelationInput>;
  profitable?: InputMaybe<ProfitableOrderByWithRelationInput>;
  profitableId?: InputMaybe<SortOrder>;
  receipt?: InputMaybe<SortOrder>;
  transfer?: InputMaybe<BenefitTransferOrderByWithRelationInput>;
  transferAmount?: InputMaybe<SortOrder>;
  transferId?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type BenefitMemoRelationFilter = {
  is?: InputMaybe<BenefitMemoWhereInput>;
  isNot?: InputMaybe<BenefitMemoWhereInput>;
};

export enum BenefitMemoScalarFieldEnum {
  CreatedAt = 'createdAt',
  Id = 'id',
  ProfitableId = 'profitableId',
  Receipt = 'receipt',
  TransferAmount = 'transferAmount',
  TransferId = 'transferId',
  Type = 'type',
  UpdatedAt = 'updatedAt'
}

export type BenefitMemoScalarWhereInput = {
  AND?: InputMaybe<Array<BenefitMemoScalarWhereInput>>;
  NOT?: InputMaybe<Array<BenefitMemoScalarWhereInput>>;
  OR?: InputMaybe<Array<BenefitMemoScalarWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  profitableId?: InputMaybe<StringFilter>;
  receipt?: InputMaybe<JsonNullableFilter>;
  transferAmount?: InputMaybe<FloatNullableFilter>;
  transferId?: InputMaybe<StringNullableFilter>;
  type?: InputMaybe<EnumBenefitTypeFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type BenefitMemoScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<BenefitMemoScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<BenefitMemoScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<BenefitMemoScalarWhereWithAggregatesInput>>;
  createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  id?: InputMaybe<StringWithAggregatesFilter>;
  profitableId?: InputMaybe<StringWithAggregatesFilter>;
  receipt?: InputMaybe<JsonNullableWithAggregatesFilter>;
  transferAmount?: InputMaybe<FloatNullableWithAggregatesFilter>;
  transferId?: InputMaybe<StringNullableWithAggregatesFilter>;
  type?: InputMaybe<EnumBenefitTypeWithAggregatesFilter>;
  updatedAt?: InputMaybe<DateTimeWithAggregatesFilter>;
};

export type BenefitMemoSumAggregate = {
  __typename?: 'BenefitMemoSumAggregate';
  transferAmount?: Maybe<Scalars['Float']>;
};

export type BenefitMemoSumOrderByAggregateInput = {
  transferAmount?: InputMaybe<SortOrder>;
};

export type BenefitMemoToCoin = {
  __typename?: 'BenefitMemoToCoin';
  _count?: Maybe<BenefitMemoToCoinCount>;
  benefit: Scalars['Float'];
  benefitMemo: BenefitMemo;
  benefitMemoId: Scalars['String'];
  benefitRatio: StoreRatio;
  benefitRatioId: Scalars['String'];
  coin: Coin;
  coinId: Scalars['String'];
  couponEntities: Array<CouponEntity>;
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  originalPrice: Scalars['Float'];
  ratio: Scalars['Float'];
  storeItem: StoreItem;
  storeItemId: Scalars['String'];
  subTotal: Scalars['Float'];
};


export type BenefitMemoToCoinCouponEntitiesArgs = {
  cursor?: InputMaybe<CouponEntityWhereUniqueInput>;
  distinct?: InputMaybe<Array<CouponEntityScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<CouponEntityOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CouponEntityWhereInput>;
};

export type BenefitMemoToCoinAvgAggregate = {
  __typename?: 'BenefitMemoToCoinAvgAggregate';
  benefit?: Maybe<Scalars['Float']>;
  originalPrice?: Maybe<Scalars['Float']>;
  ratio?: Maybe<Scalars['Float']>;
  subTotal?: Maybe<Scalars['Float']>;
};

export type BenefitMemoToCoinAvgOrderByAggregateInput = {
  benefit?: InputMaybe<SortOrder>;
  originalPrice?: InputMaybe<SortOrder>;
  ratio?: InputMaybe<SortOrder>;
  subTotal?: InputMaybe<SortOrder>;
};

export type BenefitMemoToCoinBenefitMemoIdCoinIdCompoundUniqueInput = {
  benefitMemoId: Scalars['String'];
  coinId: Scalars['String'];
};

export type BenefitMemoToCoinCount = {
  __typename?: 'BenefitMemoToCoinCount';
  couponEntities: Scalars['Int'];
};


export type BenefitMemoToCoinCountCouponEntitiesArgs = {
  where?: InputMaybe<CouponEntityWhereInput>;
};

export type BenefitMemoToCoinCountAggregate = {
  __typename?: 'BenefitMemoToCoinCountAggregate';
  _all: Scalars['Int'];
  benefit: Scalars['Int'];
  benefitMemoId: Scalars['Int'];
  benefitRatioId: Scalars['Int'];
  coinId: Scalars['Int'];
  createdAt: Scalars['Int'];
  id: Scalars['Int'];
  originalPrice: Scalars['Int'];
  ratio: Scalars['Int'];
  storeItemId: Scalars['Int'];
  subTotal: Scalars['Int'];
};

export type BenefitMemoToCoinCountOrderByAggregateInput = {
  benefit?: InputMaybe<SortOrder>;
  benefitMemoId?: InputMaybe<SortOrder>;
  benefitRatioId?: InputMaybe<SortOrder>;
  coinId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  originalPrice?: InputMaybe<SortOrder>;
  ratio?: InputMaybe<SortOrder>;
  storeItemId?: InputMaybe<SortOrder>;
  subTotal?: InputMaybe<SortOrder>;
};

export type BenefitMemoToCoinCreateInput = {
  benefit?: InputMaybe<Scalars['Float']>;
  benefitMemo: BenefitMemoCreateNestedOneWithoutCoinInput;
  benefitRatio: StoreRatioCreateNestedOneWithoutInBenefitMemoToCoinInput;
  coin: CoinCreateNestedOneWithoutBenefitMemosInput;
  couponEntities?: InputMaybe<CouponEntityCreateNestedManyWithoutBenefitMemoToCoinInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  originalPrice: Scalars['Float'];
  ratio?: InputMaybe<Scalars['Float']>;
  storeItem: StoreItemCreateNestedOneWithoutInBenefitMemoToCoinsInput;
  subTotal?: InputMaybe<Scalars['Float']>;
};

export type BenefitMemoToCoinCreateManyBenefitRatioInput = {
  benefit?: InputMaybe<Scalars['Float']>;
  benefitMemoId: Scalars['String'];
  coinId: Scalars['String'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  originalPrice: Scalars['Float'];
  ratio?: InputMaybe<Scalars['Float']>;
  storeItemId: Scalars['String'];
  subTotal?: InputMaybe<Scalars['Float']>;
};

export type BenefitMemoToCoinCreateManyBenefitRatioInputEnvelope = {
  data: Array<BenefitMemoToCoinCreateManyBenefitRatioInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type BenefitMemoToCoinCreateManyCoinInput = {
  benefit?: InputMaybe<Scalars['Float']>;
  benefitMemoId: Scalars['String'];
  benefitRatioId: Scalars['String'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  originalPrice: Scalars['Float'];
  ratio?: InputMaybe<Scalars['Float']>;
  storeItemId: Scalars['String'];
  subTotal?: InputMaybe<Scalars['Float']>;
};

export type BenefitMemoToCoinCreateManyCoinInputEnvelope = {
  data: Array<BenefitMemoToCoinCreateManyCoinInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type BenefitMemoToCoinCreateManyInput = {
  benefit?: InputMaybe<Scalars['Float']>;
  benefitMemoId: Scalars['String'];
  benefitRatioId: Scalars['String'];
  coinId: Scalars['String'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  originalPrice: Scalars['Float'];
  ratio?: InputMaybe<Scalars['Float']>;
  storeItemId: Scalars['String'];
  subTotal?: InputMaybe<Scalars['Float']>;
};

export type BenefitMemoToCoinCreateManyStoreItemInput = {
  benefit?: InputMaybe<Scalars['Float']>;
  benefitMemoId: Scalars['String'];
  benefitRatioId: Scalars['String'];
  coinId: Scalars['String'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  originalPrice: Scalars['Float'];
  ratio?: InputMaybe<Scalars['Float']>;
  subTotal?: InputMaybe<Scalars['Float']>;
};

export type BenefitMemoToCoinCreateManyStoreItemInputEnvelope = {
  data: Array<BenefitMemoToCoinCreateManyStoreItemInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type BenefitMemoToCoinCreateNestedManyWithoutBenefitRatioInput = {
  connect?: InputMaybe<Array<BenefitMemoToCoinWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<BenefitMemoToCoinCreateOrConnectWithoutBenefitRatioInput>>;
  create?: InputMaybe<Array<BenefitMemoToCoinCreateWithoutBenefitRatioInput>>;
  createMany?: InputMaybe<BenefitMemoToCoinCreateManyBenefitRatioInputEnvelope>;
};

export type BenefitMemoToCoinCreateNestedManyWithoutCoinInput = {
  connect?: InputMaybe<Array<BenefitMemoToCoinWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<BenefitMemoToCoinCreateOrConnectWithoutCoinInput>>;
  create?: InputMaybe<Array<BenefitMemoToCoinCreateWithoutCoinInput>>;
  createMany?: InputMaybe<BenefitMemoToCoinCreateManyCoinInputEnvelope>;
};

export type BenefitMemoToCoinCreateNestedManyWithoutCouponEntitiesInput = {
  connect?: InputMaybe<Array<BenefitMemoToCoinWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<BenefitMemoToCoinCreateOrConnectWithoutCouponEntitiesInput>>;
  create?: InputMaybe<Array<BenefitMemoToCoinCreateWithoutCouponEntitiesInput>>;
};

export type BenefitMemoToCoinCreateNestedManyWithoutStoreItemInput = {
  connect?: InputMaybe<Array<BenefitMemoToCoinWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<BenefitMemoToCoinCreateOrConnectWithoutStoreItemInput>>;
  create?: InputMaybe<Array<BenefitMemoToCoinCreateWithoutStoreItemInput>>;
  createMany?: InputMaybe<BenefitMemoToCoinCreateManyStoreItemInputEnvelope>;
};

export type BenefitMemoToCoinCreateNestedOneWithoutBenefitMemoInput = {
  connect?: InputMaybe<BenefitMemoToCoinWhereUniqueInput>;
  connectOrCreate?: InputMaybe<BenefitMemoToCoinCreateOrConnectWithoutBenefitMemoInput>;
  create?: InputMaybe<BenefitMemoToCoinCreateWithoutBenefitMemoInput>;
};

export type BenefitMemoToCoinCreateOrConnectWithoutBenefitMemoInput = {
  create: BenefitMemoToCoinCreateWithoutBenefitMemoInput;
  where: BenefitMemoToCoinWhereUniqueInput;
};

export type BenefitMemoToCoinCreateOrConnectWithoutBenefitRatioInput = {
  create: BenefitMemoToCoinCreateWithoutBenefitRatioInput;
  where: BenefitMemoToCoinWhereUniqueInput;
};

export type BenefitMemoToCoinCreateOrConnectWithoutCoinInput = {
  create: BenefitMemoToCoinCreateWithoutCoinInput;
  where: BenefitMemoToCoinWhereUniqueInput;
};

export type BenefitMemoToCoinCreateOrConnectWithoutCouponEntitiesInput = {
  create: BenefitMemoToCoinCreateWithoutCouponEntitiesInput;
  where: BenefitMemoToCoinWhereUniqueInput;
};

export type BenefitMemoToCoinCreateOrConnectWithoutStoreItemInput = {
  create: BenefitMemoToCoinCreateWithoutStoreItemInput;
  where: BenefitMemoToCoinWhereUniqueInput;
};

export type BenefitMemoToCoinCreateWithoutBenefitMemoInput = {
  benefit?: InputMaybe<Scalars['Float']>;
  benefitRatio: StoreRatioCreateNestedOneWithoutInBenefitMemoToCoinInput;
  coin: CoinCreateNestedOneWithoutBenefitMemosInput;
  couponEntities?: InputMaybe<CouponEntityCreateNestedManyWithoutBenefitMemoToCoinInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  originalPrice: Scalars['Float'];
  ratio?: InputMaybe<Scalars['Float']>;
  storeItem: StoreItemCreateNestedOneWithoutInBenefitMemoToCoinsInput;
  subTotal?: InputMaybe<Scalars['Float']>;
};

export type BenefitMemoToCoinCreateWithoutBenefitRatioInput = {
  benefit?: InputMaybe<Scalars['Float']>;
  benefitMemo: BenefitMemoCreateNestedOneWithoutCoinInput;
  coin: CoinCreateNestedOneWithoutBenefitMemosInput;
  couponEntities?: InputMaybe<CouponEntityCreateNestedManyWithoutBenefitMemoToCoinInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  originalPrice: Scalars['Float'];
  ratio?: InputMaybe<Scalars['Float']>;
  storeItem: StoreItemCreateNestedOneWithoutInBenefitMemoToCoinsInput;
  subTotal?: InputMaybe<Scalars['Float']>;
};

export type BenefitMemoToCoinCreateWithoutCoinInput = {
  benefit?: InputMaybe<Scalars['Float']>;
  benefitMemo: BenefitMemoCreateNestedOneWithoutCoinInput;
  benefitRatio: StoreRatioCreateNestedOneWithoutInBenefitMemoToCoinInput;
  couponEntities?: InputMaybe<CouponEntityCreateNestedManyWithoutBenefitMemoToCoinInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  originalPrice: Scalars['Float'];
  ratio?: InputMaybe<Scalars['Float']>;
  storeItem: StoreItemCreateNestedOneWithoutInBenefitMemoToCoinsInput;
  subTotal?: InputMaybe<Scalars['Float']>;
};

export type BenefitMemoToCoinCreateWithoutCouponEntitiesInput = {
  benefit?: InputMaybe<Scalars['Float']>;
  benefitMemo: BenefitMemoCreateNestedOneWithoutCoinInput;
  benefitRatio: StoreRatioCreateNestedOneWithoutInBenefitMemoToCoinInput;
  coin: CoinCreateNestedOneWithoutBenefitMemosInput;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  originalPrice: Scalars['Float'];
  ratio?: InputMaybe<Scalars['Float']>;
  storeItem: StoreItemCreateNestedOneWithoutInBenefitMemoToCoinsInput;
  subTotal?: InputMaybe<Scalars['Float']>;
};

export type BenefitMemoToCoinCreateWithoutStoreItemInput = {
  benefit?: InputMaybe<Scalars['Float']>;
  benefitMemo: BenefitMemoCreateNestedOneWithoutCoinInput;
  benefitRatio: StoreRatioCreateNestedOneWithoutInBenefitMemoToCoinInput;
  coin: CoinCreateNestedOneWithoutBenefitMemosInput;
  couponEntities?: InputMaybe<CouponEntityCreateNestedManyWithoutBenefitMemoToCoinInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  originalPrice: Scalars['Float'];
  ratio?: InputMaybe<Scalars['Float']>;
  subTotal?: InputMaybe<Scalars['Float']>;
};

export type BenefitMemoToCoinGroupBy = {
  __typename?: 'BenefitMemoToCoinGroupBy';
  _avg?: Maybe<BenefitMemoToCoinAvgAggregate>;
  _count?: Maybe<BenefitMemoToCoinCountAggregate>;
  _max?: Maybe<BenefitMemoToCoinMaxAggregate>;
  _min?: Maybe<BenefitMemoToCoinMinAggregate>;
  _sum?: Maybe<BenefitMemoToCoinSumAggregate>;
  benefit: Scalars['Float'];
  benefitMemoId: Scalars['String'];
  benefitRatioId: Scalars['String'];
  coinId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  originalPrice: Scalars['Float'];
  ratio: Scalars['Float'];
  storeItemId: Scalars['String'];
  subTotal: Scalars['Float'];
};

export type BenefitMemoToCoinListRelationFilter = {
  every?: InputMaybe<BenefitMemoToCoinWhereInput>;
  none?: InputMaybe<BenefitMemoToCoinWhereInput>;
  some?: InputMaybe<BenefitMemoToCoinWhereInput>;
};

export type BenefitMemoToCoinMaxAggregate = {
  __typename?: 'BenefitMemoToCoinMaxAggregate';
  benefit?: Maybe<Scalars['Float']>;
  benefitMemoId?: Maybe<Scalars['String']>;
  benefitRatioId?: Maybe<Scalars['String']>;
  coinId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  originalPrice?: Maybe<Scalars['Float']>;
  ratio?: Maybe<Scalars['Float']>;
  storeItemId?: Maybe<Scalars['String']>;
  subTotal?: Maybe<Scalars['Float']>;
};

export type BenefitMemoToCoinMaxOrderByAggregateInput = {
  benefit?: InputMaybe<SortOrder>;
  benefitMemoId?: InputMaybe<SortOrder>;
  benefitRatioId?: InputMaybe<SortOrder>;
  coinId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  originalPrice?: InputMaybe<SortOrder>;
  ratio?: InputMaybe<SortOrder>;
  storeItemId?: InputMaybe<SortOrder>;
  subTotal?: InputMaybe<SortOrder>;
};

export type BenefitMemoToCoinMinAggregate = {
  __typename?: 'BenefitMemoToCoinMinAggregate';
  benefit?: Maybe<Scalars['Float']>;
  benefitMemoId?: Maybe<Scalars['String']>;
  benefitRatioId?: Maybe<Scalars['String']>;
  coinId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  originalPrice?: Maybe<Scalars['Float']>;
  ratio?: Maybe<Scalars['Float']>;
  storeItemId?: Maybe<Scalars['String']>;
  subTotal?: Maybe<Scalars['Float']>;
};

export type BenefitMemoToCoinMinOrderByAggregateInput = {
  benefit?: InputMaybe<SortOrder>;
  benefitMemoId?: InputMaybe<SortOrder>;
  benefitRatioId?: InputMaybe<SortOrder>;
  coinId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  originalPrice?: InputMaybe<SortOrder>;
  ratio?: InputMaybe<SortOrder>;
  storeItemId?: InputMaybe<SortOrder>;
  subTotal?: InputMaybe<SortOrder>;
};

export type BenefitMemoToCoinOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type BenefitMemoToCoinOrderByWithAggregationInput = {
  _avg?: InputMaybe<BenefitMemoToCoinAvgOrderByAggregateInput>;
  _count?: InputMaybe<BenefitMemoToCoinCountOrderByAggregateInput>;
  _max?: InputMaybe<BenefitMemoToCoinMaxOrderByAggregateInput>;
  _min?: InputMaybe<BenefitMemoToCoinMinOrderByAggregateInput>;
  _sum?: InputMaybe<BenefitMemoToCoinSumOrderByAggregateInput>;
  benefit?: InputMaybe<SortOrder>;
  benefitMemoId?: InputMaybe<SortOrder>;
  benefitRatioId?: InputMaybe<SortOrder>;
  coinId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  originalPrice?: InputMaybe<SortOrder>;
  ratio?: InputMaybe<SortOrder>;
  storeItemId?: InputMaybe<SortOrder>;
  subTotal?: InputMaybe<SortOrder>;
};

export type BenefitMemoToCoinOrderByWithRelationInput = {
  benefit?: InputMaybe<SortOrder>;
  benefitMemo?: InputMaybe<BenefitMemoOrderByWithRelationInput>;
  benefitMemoId?: InputMaybe<SortOrder>;
  benefitRatio?: InputMaybe<StoreRatioOrderByWithRelationInput>;
  benefitRatioId?: InputMaybe<SortOrder>;
  coin?: InputMaybe<CoinOrderByWithRelationInput>;
  coinId?: InputMaybe<SortOrder>;
  couponEntities?: InputMaybe<CouponEntityOrderByRelationAggregateInput>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  originalPrice?: InputMaybe<SortOrder>;
  ratio?: InputMaybe<SortOrder>;
  storeItem?: InputMaybe<StoreItemOrderByWithRelationInput>;
  storeItemId?: InputMaybe<SortOrder>;
  subTotal?: InputMaybe<SortOrder>;
};

export type BenefitMemoToCoinRelationFilter = {
  is?: InputMaybe<BenefitMemoToCoinWhereInput>;
  isNot?: InputMaybe<BenefitMemoToCoinWhereInput>;
};

export enum BenefitMemoToCoinScalarFieldEnum {
  Benefit = 'benefit',
  BenefitMemoId = 'benefitMemoId',
  BenefitRatioId = 'benefitRatioId',
  CoinId = 'coinId',
  CreatedAt = 'createdAt',
  Id = 'id',
  OriginalPrice = 'originalPrice',
  Ratio = 'ratio',
  StoreItemId = 'storeItemId',
  SubTotal = 'subTotal'
}

export type BenefitMemoToCoinScalarWhereInput = {
  AND?: InputMaybe<Array<BenefitMemoToCoinScalarWhereInput>>;
  NOT?: InputMaybe<Array<BenefitMemoToCoinScalarWhereInput>>;
  OR?: InputMaybe<Array<BenefitMemoToCoinScalarWhereInput>>;
  benefit?: InputMaybe<FloatFilter>;
  benefitMemoId?: InputMaybe<StringFilter>;
  benefitRatioId?: InputMaybe<StringFilter>;
  coinId?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  originalPrice?: InputMaybe<FloatFilter>;
  ratio?: InputMaybe<FloatFilter>;
  storeItemId?: InputMaybe<StringFilter>;
  subTotal?: InputMaybe<FloatFilter>;
};

export type BenefitMemoToCoinScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<BenefitMemoToCoinScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<BenefitMemoToCoinScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<BenefitMemoToCoinScalarWhereWithAggregatesInput>>;
  benefit?: InputMaybe<FloatWithAggregatesFilter>;
  benefitMemoId?: InputMaybe<StringWithAggregatesFilter>;
  benefitRatioId?: InputMaybe<StringWithAggregatesFilter>;
  coinId?: InputMaybe<StringWithAggregatesFilter>;
  createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  id?: InputMaybe<StringWithAggregatesFilter>;
  originalPrice?: InputMaybe<FloatWithAggregatesFilter>;
  ratio?: InputMaybe<FloatWithAggregatesFilter>;
  storeItemId?: InputMaybe<StringWithAggregatesFilter>;
  subTotal?: InputMaybe<FloatWithAggregatesFilter>;
};

export type BenefitMemoToCoinSumAggregate = {
  __typename?: 'BenefitMemoToCoinSumAggregate';
  benefit?: Maybe<Scalars['Float']>;
  originalPrice?: Maybe<Scalars['Float']>;
  ratio?: Maybe<Scalars['Float']>;
  subTotal?: Maybe<Scalars['Float']>;
};

export type BenefitMemoToCoinSumOrderByAggregateInput = {
  benefit?: InputMaybe<SortOrder>;
  originalPrice?: InputMaybe<SortOrder>;
  ratio?: InputMaybe<SortOrder>;
  subTotal?: InputMaybe<SortOrder>;
};

export type BenefitMemoToCoinUpdateInput = {
  benefit?: InputMaybe<FloatFieldUpdateOperationsInput>;
  benefitMemo?: InputMaybe<BenefitMemoUpdateOneRequiredWithoutCoinNestedInput>;
  benefitRatio?: InputMaybe<StoreRatioUpdateOneRequiredWithoutInBenefitMemoToCoinNestedInput>;
  coin?: InputMaybe<CoinUpdateOneRequiredWithoutBenefitMemosNestedInput>;
  couponEntities?: InputMaybe<CouponEntityUpdateManyWithoutBenefitMemoToCoinNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  originalPrice?: InputMaybe<FloatFieldUpdateOperationsInput>;
  ratio?: InputMaybe<FloatFieldUpdateOperationsInput>;
  storeItem?: InputMaybe<StoreItemUpdateOneRequiredWithoutInBenefitMemoToCoinsNestedInput>;
  subTotal?: InputMaybe<FloatFieldUpdateOperationsInput>;
};

export type BenefitMemoToCoinUpdateManyMutationInput = {
  benefit?: InputMaybe<FloatFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  originalPrice?: InputMaybe<FloatFieldUpdateOperationsInput>;
  ratio?: InputMaybe<FloatFieldUpdateOperationsInput>;
  subTotal?: InputMaybe<FloatFieldUpdateOperationsInput>;
};

export type BenefitMemoToCoinUpdateManyWithWhereWithoutBenefitRatioInput = {
  data: BenefitMemoToCoinUpdateManyMutationInput;
  where: BenefitMemoToCoinScalarWhereInput;
};

export type BenefitMemoToCoinUpdateManyWithWhereWithoutCoinInput = {
  data: BenefitMemoToCoinUpdateManyMutationInput;
  where: BenefitMemoToCoinScalarWhereInput;
};

export type BenefitMemoToCoinUpdateManyWithWhereWithoutCouponEntitiesInput = {
  data: BenefitMemoToCoinUpdateManyMutationInput;
  where: BenefitMemoToCoinScalarWhereInput;
};

export type BenefitMemoToCoinUpdateManyWithWhereWithoutStoreItemInput = {
  data: BenefitMemoToCoinUpdateManyMutationInput;
  where: BenefitMemoToCoinScalarWhereInput;
};

export type BenefitMemoToCoinUpdateManyWithoutBenefitRatioNestedInput = {
  connect?: InputMaybe<Array<BenefitMemoToCoinWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<BenefitMemoToCoinCreateOrConnectWithoutBenefitRatioInput>>;
  create?: InputMaybe<Array<BenefitMemoToCoinCreateWithoutBenefitRatioInput>>;
  createMany?: InputMaybe<BenefitMemoToCoinCreateManyBenefitRatioInputEnvelope>;
  delete?: InputMaybe<Array<BenefitMemoToCoinWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<BenefitMemoToCoinScalarWhereInput>>;
  disconnect?: InputMaybe<Array<BenefitMemoToCoinWhereUniqueInput>>;
  set?: InputMaybe<Array<BenefitMemoToCoinWhereUniqueInput>>;
  update?: InputMaybe<Array<BenefitMemoToCoinUpdateWithWhereUniqueWithoutBenefitRatioInput>>;
  updateMany?: InputMaybe<Array<BenefitMemoToCoinUpdateManyWithWhereWithoutBenefitRatioInput>>;
  upsert?: InputMaybe<Array<BenefitMemoToCoinUpsertWithWhereUniqueWithoutBenefitRatioInput>>;
};

export type BenefitMemoToCoinUpdateManyWithoutCoinNestedInput = {
  connect?: InputMaybe<Array<BenefitMemoToCoinWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<BenefitMemoToCoinCreateOrConnectWithoutCoinInput>>;
  create?: InputMaybe<Array<BenefitMemoToCoinCreateWithoutCoinInput>>;
  createMany?: InputMaybe<BenefitMemoToCoinCreateManyCoinInputEnvelope>;
  delete?: InputMaybe<Array<BenefitMemoToCoinWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<BenefitMemoToCoinScalarWhereInput>>;
  disconnect?: InputMaybe<Array<BenefitMemoToCoinWhereUniqueInput>>;
  set?: InputMaybe<Array<BenefitMemoToCoinWhereUniqueInput>>;
  update?: InputMaybe<Array<BenefitMemoToCoinUpdateWithWhereUniqueWithoutCoinInput>>;
  updateMany?: InputMaybe<Array<BenefitMemoToCoinUpdateManyWithWhereWithoutCoinInput>>;
  upsert?: InputMaybe<Array<BenefitMemoToCoinUpsertWithWhereUniqueWithoutCoinInput>>;
};

export type BenefitMemoToCoinUpdateManyWithoutCouponEntitiesNestedInput = {
  connect?: InputMaybe<Array<BenefitMemoToCoinWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<BenefitMemoToCoinCreateOrConnectWithoutCouponEntitiesInput>>;
  create?: InputMaybe<Array<BenefitMemoToCoinCreateWithoutCouponEntitiesInput>>;
  delete?: InputMaybe<Array<BenefitMemoToCoinWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<BenefitMemoToCoinScalarWhereInput>>;
  disconnect?: InputMaybe<Array<BenefitMemoToCoinWhereUniqueInput>>;
  set?: InputMaybe<Array<BenefitMemoToCoinWhereUniqueInput>>;
  update?: InputMaybe<Array<BenefitMemoToCoinUpdateWithWhereUniqueWithoutCouponEntitiesInput>>;
  updateMany?: InputMaybe<Array<BenefitMemoToCoinUpdateManyWithWhereWithoutCouponEntitiesInput>>;
  upsert?: InputMaybe<Array<BenefitMemoToCoinUpsertWithWhereUniqueWithoutCouponEntitiesInput>>;
};

export type BenefitMemoToCoinUpdateManyWithoutStoreItemNestedInput = {
  connect?: InputMaybe<Array<BenefitMemoToCoinWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<BenefitMemoToCoinCreateOrConnectWithoutStoreItemInput>>;
  create?: InputMaybe<Array<BenefitMemoToCoinCreateWithoutStoreItemInput>>;
  createMany?: InputMaybe<BenefitMemoToCoinCreateManyStoreItemInputEnvelope>;
  delete?: InputMaybe<Array<BenefitMemoToCoinWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<BenefitMemoToCoinScalarWhereInput>>;
  disconnect?: InputMaybe<Array<BenefitMemoToCoinWhereUniqueInput>>;
  set?: InputMaybe<Array<BenefitMemoToCoinWhereUniqueInput>>;
  update?: InputMaybe<Array<BenefitMemoToCoinUpdateWithWhereUniqueWithoutStoreItemInput>>;
  updateMany?: InputMaybe<Array<BenefitMemoToCoinUpdateManyWithWhereWithoutStoreItemInput>>;
  upsert?: InputMaybe<Array<BenefitMemoToCoinUpsertWithWhereUniqueWithoutStoreItemInput>>;
};

export type BenefitMemoToCoinUpdateOneWithoutBenefitMemoNestedInput = {
  connect?: InputMaybe<BenefitMemoToCoinWhereUniqueInput>;
  connectOrCreate?: InputMaybe<BenefitMemoToCoinCreateOrConnectWithoutBenefitMemoInput>;
  create?: InputMaybe<BenefitMemoToCoinCreateWithoutBenefitMemoInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<BenefitMemoToCoinUpdateWithoutBenefitMemoInput>;
  upsert?: InputMaybe<BenefitMemoToCoinUpsertWithoutBenefitMemoInput>;
};

export type BenefitMemoToCoinUpdateWithWhereUniqueWithoutBenefitRatioInput = {
  data: BenefitMemoToCoinUpdateWithoutBenefitRatioInput;
  where: BenefitMemoToCoinWhereUniqueInput;
};

export type BenefitMemoToCoinUpdateWithWhereUniqueWithoutCoinInput = {
  data: BenefitMemoToCoinUpdateWithoutCoinInput;
  where: BenefitMemoToCoinWhereUniqueInput;
};

export type BenefitMemoToCoinUpdateWithWhereUniqueWithoutCouponEntitiesInput = {
  data: BenefitMemoToCoinUpdateWithoutCouponEntitiesInput;
  where: BenefitMemoToCoinWhereUniqueInput;
};

export type BenefitMemoToCoinUpdateWithWhereUniqueWithoutStoreItemInput = {
  data: BenefitMemoToCoinUpdateWithoutStoreItemInput;
  where: BenefitMemoToCoinWhereUniqueInput;
};

export type BenefitMemoToCoinUpdateWithoutBenefitMemoInput = {
  benefit?: InputMaybe<FloatFieldUpdateOperationsInput>;
  benefitRatio?: InputMaybe<StoreRatioUpdateOneRequiredWithoutInBenefitMemoToCoinNestedInput>;
  coin?: InputMaybe<CoinUpdateOneRequiredWithoutBenefitMemosNestedInput>;
  couponEntities?: InputMaybe<CouponEntityUpdateManyWithoutBenefitMemoToCoinNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  originalPrice?: InputMaybe<FloatFieldUpdateOperationsInput>;
  ratio?: InputMaybe<FloatFieldUpdateOperationsInput>;
  storeItem?: InputMaybe<StoreItemUpdateOneRequiredWithoutInBenefitMemoToCoinsNestedInput>;
  subTotal?: InputMaybe<FloatFieldUpdateOperationsInput>;
};

export type BenefitMemoToCoinUpdateWithoutBenefitRatioInput = {
  benefit?: InputMaybe<FloatFieldUpdateOperationsInput>;
  benefitMemo?: InputMaybe<BenefitMemoUpdateOneRequiredWithoutCoinNestedInput>;
  coin?: InputMaybe<CoinUpdateOneRequiredWithoutBenefitMemosNestedInput>;
  couponEntities?: InputMaybe<CouponEntityUpdateManyWithoutBenefitMemoToCoinNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  originalPrice?: InputMaybe<FloatFieldUpdateOperationsInput>;
  ratio?: InputMaybe<FloatFieldUpdateOperationsInput>;
  storeItem?: InputMaybe<StoreItemUpdateOneRequiredWithoutInBenefitMemoToCoinsNestedInput>;
  subTotal?: InputMaybe<FloatFieldUpdateOperationsInput>;
};

export type BenefitMemoToCoinUpdateWithoutCoinInput = {
  benefit?: InputMaybe<FloatFieldUpdateOperationsInput>;
  benefitMemo?: InputMaybe<BenefitMemoUpdateOneRequiredWithoutCoinNestedInput>;
  benefitRatio?: InputMaybe<StoreRatioUpdateOneRequiredWithoutInBenefitMemoToCoinNestedInput>;
  couponEntities?: InputMaybe<CouponEntityUpdateManyWithoutBenefitMemoToCoinNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  originalPrice?: InputMaybe<FloatFieldUpdateOperationsInput>;
  ratio?: InputMaybe<FloatFieldUpdateOperationsInput>;
  storeItem?: InputMaybe<StoreItemUpdateOneRequiredWithoutInBenefitMemoToCoinsNestedInput>;
  subTotal?: InputMaybe<FloatFieldUpdateOperationsInput>;
};

export type BenefitMemoToCoinUpdateWithoutCouponEntitiesInput = {
  benefit?: InputMaybe<FloatFieldUpdateOperationsInput>;
  benefitMemo?: InputMaybe<BenefitMemoUpdateOneRequiredWithoutCoinNestedInput>;
  benefitRatio?: InputMaybe<StoreRatioUpdateOneRequiredWithoutInBenefitMemoToCoinNestedInput>;
  coin?: InputMaybe<CoinUpdateOneRequiredWithoutBenefitMemosNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  originalPrice?: InputMaybe<FloatFieldUpdateOperationsInput>;
  ratio?: InputMaybe<FloatFieldUpdateOperationsInput>;
  storeItem?: InputMaybe<StoreItemUpdateOneRequiredWithoutInBenefitMemoToCoinsNestedInput>;
  subTotal?: InputMaybe<FloatFieldUpdateOperationsInput>;
};

export type BenefitMemoToCoinUpdateWithoutStoreItemInput = {
  benefit?: InputMaybe<FloatFieldUpdateOperationsInput>;
  benefitMemo?: InputMaybe<BenefitMemoUpdateOneRequiredWithoutCoinNestedInput>;
  benefitRatio?: InputMaybe<StoreRatioUpdateOneRequiredWithoutInBenefitMemoToCoinNestedInput>;
  coin?: InputMaybe<CoinUpdateOneRequiredWithoutBenefitMemosNestedInput>;
  couponEntities?: InputMaybe<CouponEntityUpdateManyWithoutBenefitMemoToCoinNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  originalPrice?: InputMaybe<FloatFieldUpdateOperationsInput>;
  ratio?: InputMaybe<FloatFieldUpdateOperationsInput>;
  subTotal?: InputMaybe<FloatFieldUpdateOperationsInput>;
};

export type BenefitMemoToCoinUpsertWithWhereUniqueWithoutBenefitRatioInput = {
  create: BenefitMemoToCoinCreateWithoutBenefitRatioInput;
  update: BenefitMemoToCoinUpdateWithoutBenefitRatioInput;
  where: BenefitMemoToCoinWhereUniqueInput;
};

export type BenefitMemoToCoinUpsertWithWhereUniqueWithoutCoinInput = {
  create: BenefitMemoToCoinCreateWithoutCoinInput;
  update: BenefitMemoToCoinUpdateWithoutCoinInput;
  where: BenefitMemoToCoinWhereUniqueInput;
};

export type BenefitMemoToCoinUpsertWithWhereUniqueWithoutCouponEntitiesInput = {
  create: BenefitMemoToCoinCreateWithoutCouponEntitiesInput;
  update: BenefitMemoToCoinUpdateWithoutCouponEntitiesInput;
  where: BenefitMemoToCoinWhereUniqueInput;
};

export type BenefitMemoToCoinUpsertWithWhereUniqueWithoutStoreItemInput = {
  create: BenefitMemoToCoinCreateWithoutStoreItemInput;
  update: BenefitMemoToCoinUpdateWithoutStoreItemInput;
  where: BenefitMemoToCoinWhereUniqueInput;
};

export type BenefitMemoToCoinUpsertWithoutBenefitMemoInput = {
  create: BenefitMemoToCoinCreateWithoutBenefitMemoInput;
  update: BenefitMemoToCoinUpdateWithoutBenefitMemoInput;
};

export type BenefitMemoToCoinWhereInput = {
  AND?: InputMaybe<Array<BenefitMemoToCoinWhereInput>>;
  NOT?: InputMaybe<Array<BenefitMemoToCoinWhereInput>>;
  OR?: InputMaybe<Array<BenefitMemoToCoinWhereInput>>;
  benefit?: InputMaybe<FloatFilter>;
  benefitMemo?: InputMaybe<BenefitMemoRelationFilter>;
  benefitMemoId?: InputMaybe<StringFilter>;
  benefitRatio?: InputMaybe<StoreRatioRelationFilter>;
  benefitRatioId?: InputMaybe<StringFilter>;
  coin?: InputMaybe<CoinRelationFilter>;
  coinId?: InputMaybe<StringFilter>;
  couponEntities?: InputMaybe<CouponEntityListRelationFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  originalPrice?: InputMaybe<FloatFilter>;
  ratio?: InputMaybe<FloatFilter>;
  storeItem?: InputMaybe<StoreItemRelationFilter>;
  storeItemId?: InputMaybe<StringFilter>;
  subTotal?: InputMaybe<FloatFilter>;
};

export type BenefitMemoToCoinWhereUniqueInput = {
  benefitMemoId?: InputMaybe<Scalars['String']>;
  benefitMemoId_coinId?: InputMaybe<BenefitMemoToCoinBenefitMemoIdCoinIdCompoundUniqueInput>;
  id?: InputMaybe<Scalars['String']>;
};

export type BenefitMemoToOrder = {
  __typename?: 'BenefitMemoToOrder';
  _count?: Maybe<BenefitMemoToOrderCount>;
  benefit: Scalars['Float'];
  benefitMemo: BenefitMemo;
  benefitMemoId: Scalars['String'];
  benefitRatio: StoreRatio;
  benefitRatioId: Scalars['String'];
  couponEntities: Array<CouponEntity>;
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  order: Order;
  orderId: Scalars['String'];
  originalPrice: Scalars['Float'];
  post: Post;
  postId: Scalars['String'];
  ratio: Scalars['Float'];
  storeItem: StoreItem;
  storeItemId: Scalars['String'];
  subTotal: Scalars['Float'];
};


export type BenefitMemoToOrderCouponEntitiesArgs = {
  cursor?: InputMaybe<CouponEntityWhereUniqueInput>;
  distinct?: InputMaybe<Array<CouponEntityScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<CouponEntityOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CouponEntityWhereInput>;
};

export type BenefitMemoToOrderAvgAggregate = {
  __typename?: 'BenefitMemoToOrderAvgAggregate';
  benefit?: Maybe<Scalars['Float']>;
  originalPrice?: Maybe<Scalars['Float']>;
  ratio?: Maybe<Scalars['Float']>;
  subTotal?: Maybe<Scalars['Float']>;
};

export type BenefitMemoToOrderAvgOrderByAggregateInput = {
  benefit?: InputMaybe<SortOrder>;
  originalPrice?: InputMaybe<SortOrder>;
  ratio?: InputMaybe<SortOrder>;
  subTotal?: InputMaybe<SortOrder>;
};

export type BenefitMemoToOrderBenefitMemoIdPostIdCompoundUniqueInput = {
  benefitMemoId: Scalars['String'];
  postId: Scalars['String'];
};

export type BenefitMemoToOrderCount = {
  __typename?: 'BenefitMemoToOrderCount';
  couponEntities: Scalars['Int'];
};


export type BenefitMemoToOrderCountCouponEntitiesArgs = {
  where?: InputMaybe<CouponEntityWhereInput>;
};

export type BenefitMemoToOrderCountAggregate = {
  __typename?: 'BenefitMemoToOrderCountAggregate';
  _all: Scalars['Int'];
  benefit: Scalars['Int'];
  benefitMemoId: Scalars['Int'];
  benefitRatioId: Scalars['Int'];
  createdAt: Scalars['Int'];
  id: Scalars['Int'];
  orderId: Scalars['Int'];
  originalPrice: Scalars['Int'];
  postId: Scalars['Int'];
  ratio: Scalars['Int'];
  storeItemId: Scalars['Int'];
  subTotal: Scalars['Int'];
};

export type BenefitMemoToOrderCountOrderByAggregateInput = {
  benefit?: InputMaybe<SortOrder>;
  benefitMemoId?: InputMaybe<SortOrder>;
  benefitRatioId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  orderId?: InputMaybe<SortOrder>;
  originalPrice?: InputMaybe<SortOrder>;
  postId?: InputMaybe<SortOrder>;
  ratio?: InputMaybe<SortOrder>;
  storeItemId?: InputMaybe<SortOrder>;
  subTotal?: InputMaybe<SortOrder>;
};

export type BenefitMemoToOrderCreateInput = {
  benefit?: InputMaybe<Scalars['Float']>;
  benefitMemo: BenefitMemoCreateNestedOneWithoutOrderInput;
  benefitRatio: StoreRatioCreateNestedOneWithoutInBenefitMemoToOrderInput;
  couponEntities?: InputMaybe<CouponEntityCreateNestedManyWithoutBenefitMemoToOrderInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  order: OrderCreateNestedOneWithoutBenefitMemosInput;
  originalPrice: Scalars['Float'];
  post: PostCreateNestedOneWithoutBenefitMemoToOrderInput;
  ratio?: InputMaybe<Scalars['Float']>;
  storeItem: StoreItemCreateNestedOneWithoutInBenefitMemoToOrdersInput;
  subTotal?: InputMaybe<Scalars['Float']>;
};

export type BenefitMemoToOrderCreateManyBenefitRatioInput = {
  benefit?: InputMaybe<Scalars['Float']>;
  benefitMemoId: Scalars['String'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  orderId: Scalars['String'];
  originalPrice: Scalars['Float'];
  postId: Scalars['String'];
  ratio?: InputMaybe<Scalars['Float']>;
  storeItemId: Scalars['String'];
  subTotal?: InputMaybe<Scalars['Float']>;
};

export type BenefitMemoToOrderCreateManyBenefitRatioInputEnvelope = {
  data: Array<BenefitMemoToOrderCreateManyBenefitRatioInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type BenefitMemoToOrderCreateManyInput = {
  benefit?: InputMaybe<Scalars['Float']>;
  benefitMemoId: Scalars['String'];
  benefitRatioId: Scalars['String'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  orderId: Scalars['String'];
  originalPrice: Scalars['Float'];
  postId: Scalars['String'];
  ratio?: InputMaybe<Scalars['Float']>;
  storeItemId: Scalars['String'];
  subTotal?: InputMaybe<Scalars['Float']>;
};

export type BenefitMemoToOrderCreateManyOrderInput = {
  benefit?: InputMaybe<Scalars['Float']>;
  benefitMemoId: Scalars['String'];
  benefitRatioId: Scalars['String'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  originalPrice: Scalars['Float'];
  postId: Scalars['String'];
  ratio?: InputMaybe<Scalars['Float']>;
  storeItemId: Scalars['String'];
  subTotal?: InputMaybe<Scalars['Float']>;
};

export type BenefitMemoToOrderCreateManyOrderInputEnvelope = {
  data: Array<BenefitMemoToOrderCreateManyOrderInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type BenefitMemoToOrderCreateManyPostInput = {
  benefit?: InputMaybe<Scalars['Float']>;
  benefitMemoId: Scalars['String'];
  benefitRatioId: Scalars['String'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  orderId: Scalars['String'];
  originalPrice: Scalars['Float'];
  ratio?: InputMaybe<Scalars['Float']>;
  storeItemId: Scalars['String'];
  subTotal?: InputMaybe<Scalars['Float']>;
};

export type BenefitMemoToOrderCreateManyPostInputEnvelope = {
  data: Array<BenefitMemoToOrderCreateManyPostInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type BenefitMemoToOrderCreateManyStoreItemInput = {
  benefit?: InputMaybe<Scalars['Float']>;
  benefitMemoId: Scalars['String'];
  benefitRatioId: Scalars['String'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  orderId: Scalars['String'];
  originalPrice: Scalars['Float'];
  postId: Scalars['String'];
  ratio?: InputMaybe<Scalars['Float']>;
  subTotal?: InputMaybe<Scalars['Float']>;
};

export type BenefitMemoToOrderCreateManyStoreItemInputEnvelope = {
  data: Array<BenefitMemoToOrderCreateManyStoreItemInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type BenefitMemoToOrderCreateNestedManyWithoutBenefitRatioInput = {
  connect?: InputMaybe<Array<BenefitMemoToOrderWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<BenefitMemoToOrderCreateOrConnectWithoutBenefitRatioInput>>;
  create?: InputMaybe<Array<BenefitMemoToOrderCreateWithoutBenefitRatioInput>>;
  createMany?: InputMaybe<BenefitMemoToOrderCreateManyBenefitRatioInputEnvelope>;
};

export type BenefitMemoToOrderCreateNestedManyWithoutCouponEntitiesInput = {
  connect?: InputMaybe<Array<BenefitMemoToOrderWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<BenefitMemoToOrderCreateOrConnectWithoutCouponEntitiesInput>>;
  create?: InputMaybe<Array<BenefitMemoToOrderCreateWithoutCouponEntitiesInput>>;
};

export type BenefitMemoToOrderCreateNestedManyWithoutOrderInput = {
  connect?: InputMaybe<Array<BenefitMemoToOrderWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<BenefitMemoToOrderCreateOrConnectWithoutOrderInput>>;
  create?: InputMaybe<Array<BenefitMemoToOrderCreateWithoutOrderInput>>;
  createMany?: InputMaybe<BenefitMemoToOrderCreateManyOrderInputEnvelope>;
};

export type BenefitMemoToOrderCreateNestedManyWithoutPostInput = {
  connect?: InputMaybe<Array<BenefitMemoToOrderWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<BenefitMemoToOrderCreateOrConnectWithoutPostInput>>;
  create?: InputMaybe<Array<BenefitMemoToOrderCreateWithoutPostInput>>;
  createMany?: InputMaybe<BenefitMemoToOrderCreateManyPostInputEnvelope>;
};

export type BenefitMemoToOrderCreateNestedManyWithoutStoreItemInput = {
  connect?: InputMaybe<Array<BenefitMemoToOrderWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<BenefitMemoToOrderCreateOrConnectWithoutStoreItemInput>>;
  create?: InputMaybe<Array<BenefitMemoToOrderCreateWithoutStoreItemInput>>;
  createMany?: InputMaybe<BenefitMemoToOrderCreateManyStoreItemInputEnvelope>;
};

export type BenefitMemoToOrderCreateNestedOneWithoutBenefitMemoInput = {
  connect?: InputMaybe<BenefitMemoToOrderWhereUniqueInput>;
  connectOrCreate?: InputMaybe<BenefitMemoToOrderCreateOrConnectWithoutBenefitMemoInput>;
  create?: InputMaybe<BenefitMemoToOrderCreateWithoutBenefitMemoInput>;
};

export type BenefitMemoToOrderCreateOrConnectWithoutBenefitMemoInput = {
  create: BenefitMemoToOrderCreateWithoutBenefitMemoInput;
  where: BenefitMemoToOrderWhereUniqueInput;
};

export type BenefitMemoToOrderCreateOrConnectWithoutBenefitRatioInput = {
  create: BenefitMemoToOrderCreateWithoutBenefitRatioInput;
  where: BenefitMemoToOrderWhereUniqueInput;
};

export type BenefitMemoToOrderCreateOrConnectWithoutCouponEntitiesInput = {
  create: BenefitMemoToOrderCreateWithoutCouponEntitiesInput;
  where: BenefitMemoToOrderWhereUniqueInput;
};

export type BenefitMemoToOrderCreateOrConnectWithoutOrderInput = {
  create: BenefitMemoToOrderCreateWithoutOrderInput;
  where: BenefitMemoToOrderWhereUniqueInput;
};

export type BenefitMemoToOrderCreateOrConnectWithoutPostInput = {
  create: BenefitMemoToOrderCreateWithoutPostInput;
  where: BenefitMemoToOrderWhereUniqueInput;
};

export type BenefitMemoToOrderCreateOrConnectWithoutStoreItemInput = {
  create: BenefitMemoToOrderCreateWithoutStoreItemInput;
  where: BenefitMemoToOrderWhereUniqueInput;
};

export type BenefitMemoToOrderCreateWithoutBenefitMemoInput = {
  benefit?: InputMaybe<Scalars['Float']>;
  benefitRatio: StoreRatioCreateNestedOneWithoutInBenefitMemoToOrderInput;
  couponEntities?: InputMaybe<CouponEntityCreateNestedManyWithoutBenefitMemoToOrderInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  order: OrderCreateNestedOneWithoutBenefitMemosInput;
  originalPrice: Scalars['Float'];
  post: PostCreateNestedOneWithoutBenefitMemoToOrderInput;
  ratio?: InputMaybe<Scalars['Float']>;
  storeItem: StoreItemCreateNestedOneWithoutInBenefitMemoToOrdersInput;
  subTotal?: InputMaybe<Scalars['Float']>;
};

export type BenefitMemoToOrderCreateWithoutBenefitRatioInput = {
  benefit?: InputMaybe<Scalars['Float']>;
  benefitMemo: BenefitMemoCreateNestedOneWithoutOrderInput;
  couponEntities?: InputMaybe<CouponEntityCreateNestedManyWithoutBenefitMemoToOrderInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  order: OrderCreateNestedOneWithoutBenefitMemosInput;
  originalPrice: Scalars['Float'];
  post: PostCreateNestedOneWithoutBenefitMemoToOrderInput;
  ratio?: InputMaybe<Scalars['Float']>;
  storeItem: StoreItemCreateNestedOneWithoutInBenefitMemoToOrdersInput;
  subTotal?: InputMaybe<Scalars['Float']>;
};

export type BenefitMemoToOrderCreateWithoutCouponEntitiesInput = {
  benefit?: InputMaybe<Scalars['Float']>;
  benefitMemo: BenefitMemoCreateNestedOneWithoutOrderInput;
  benefitRatio: StoreRatioCreateNestedOneWithoutInBenefitMemoToOrderInput;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  order: OrderCreateNestedOneWithoutBenefitMemosInput;
  originalPrice: Scalars['Float'];
  post: PostCreateNestedOneWithoutBenefitMemoToOrderInput;
  ratio?: InputMaybe<Scalars['Float']>;
  storeItem: StoreItemCreateNestedOneWithoutInBenefitMemoToOrdersInput;
  subTotal?: InputMaybe<Scalars['Float']>;
};

export type BenefitMemoToOrderCreateWithoutOrderInput = {
  benefit?: InputMaybe<Scalars['Float']>;
  benefitMemo: BenefitMemoCreateNestedOneWithoutOrderInput;
  benefitRatio: StoreRatioCreateNestedOneWithoutInBenefitMemoToOrderInput;
  couponEntities?: InputMaybe<CouponEntityCreateNestedManyWithoutBenefitMemoToOrderInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  originalPrice: Scalars['Float'];
  post: PostCreateNestedOneWithoutBenefitMemoToOrderInput;
  ratio?: InputMaybe<Scalars['Float']>;
  storeItem: StoreItemCreateNestedOneWithoutInBenefitMemoToOrdersInput;
  subTotal?: InputMaybe<Scalars['Float']>;
};

export type BenefitMemoToOrderCreateWithoutPostInput = {
  benefit?: InputMaybe<Scalars['Float']>;
  benefitMemo: BenefitMemoCreateNestedOneWithoutOrderInput;
  benefitRatio: StoreRatioCreateNestedOneWithoutInBenefitMemoToOrderInput;
  couponEntities?: InputMaybe<CouponEntityCreateNestedManyWithoutBenefitMemoToOrderInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  order: OrderCreateNestedOneWithoutBenefitMemosInput;
  originalPrice: Scalars['Float'];
  ratio?: InputMaybe<Scalars['Float']>;
  storeItem: StoreItemCreateNestedOneWithoutInBenefitMemoToOrdersInput;
  subTotal?: InputMaybe<Scalars['Float']>;
};

export type BenefitMemoToOrderCreateWithoutStoreItemInput = {
  benefit?: InputMaybe<Scalars['Float']>;
  benefitMemo: BenefitMemoCreateNestedOneWithoutOrderInput;
  benefitRatio: StoreRatioCreateNestedOneWithoutInBenefitMemoToOrderInput;
  couponEntities?: InputMaybe<CouponEntityCreateNestedManyWithoutBenefitMemoToOrderInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  order: OrderCreateNestedOneWithoutBenefitMemosInput;
  originalPrice: Scalars['Float'];
  post: PostCreateNestedOneWithoutBenefitMemoToOrderInput;
  ratio?: InputMaybe<Scalars['Float']>;
  subTotal?: InputMaybe<Scalars['Float']>;
};

export type BenefitMemoToOrderGroupBy = {
  __typename?: 'BenefitMemoToOrderGroupBy';
  _avg?: Maybe<BenefitMemoToOrderAvgAggregate>;
  _count?: Maybe<BenefitMemoToOrderCountAggregate>;
  _max?: Maybe<BenefitMemoToOrderMaxAggregate>;
  _min?: Maybe<BenefitMemoToOrderMinAggregate>;
  _sum?: Maybe<BenefitMemoToOrderSumAggregate>;
  benefit: Scalars['Float'];
  benefitMemoId: Scalars['String'];
  benefitRatioId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  orderId: Scalars['String'];
  originalPrice: Scalars['Float'];
  postId: Scalars['String'];
  ratio: Scalars['Float'];
  storeItemId: Scalars['String'];
  subTotal: Scalars['Float'];
};

export type BenefitMemoToOrderListRelationFilter = {
  every?: InputMaybe<BenefitMemoToOrderWhereInput>;
  none?: InputMaybe<BenefitMemoToOrderWhereInput>;
  some?: InputMaybe<BenefitMemoToOrderWhereInput>;
};

export type BenefitMemoToOrderMaxAggregate = {
  __typename?: 'BenefitMemoToOrderMaxAggregate';
  benefit?: Maybe<Scalars['Float']>;
  benefitMemoId?: Maybe<Scalars['String']>;
  benefitRatioId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  orderId?: Maybe<Scalars['String']>;
  originalPrice?: Maybe<Scalars['Float']>;
  postId?: Maybe<Scalars['String']>;
  ratio?: Maybe<Scalars['Float']>;
  storeItemId?: Maybe<Scalars['String']>;
  subTotal?: Maybe<Scalars['Float']>;
};

export type BenefitMemoToOrderMaxOrderByAggregateInput = {
  benefit?: InputMaybe<SortOrder>;
  benefitMemoId?: InputMaybe<SortOrder>;
  benefitRatioId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  orderId?: InputMaybe<SortOrder>;
  originalPrice?: InputMaybe<SortOrder>;
  postId?: InputMaybe<SortOrder>;
  ratio?: InputMaybe<SortOrder>;
  storeItemId?: InputMaybe<SortOrder>;
  subTotal?: InputMaybe<SortOrder>;
};

export type BenefitMemoToOrderMinAggregate = {
  __typename?: 'BenefitMemoToOrderMinAggregate';
  benefit?: Maybe<Scalars['Float']>;
  benefitMemoId?: Maybe<Scalars['String']>;
  benefitRatioId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  orderId?: Maybe<Scalars['String']>;
  originalPrice?: Maybe<Scalars['Float']>;
  postId?: Maybe<Scalars['String']>;
  ratio?: Maybe<Scalars['Float']>;
  storeItemId?: Maybe<Scalars['String']>;
  subTotal?: Maybe<Scalars['Float']>;
};

export type BenefitMemoToOrderMinOrderByAggregateInput = {
  benefit?: InputMaybe<SortOrder>;
  benefitMemoId?: InputMaybe<SortOrder>;
  benefitRatioId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  orderId?: InputMaybe<SortOrder>;
  originalPrice?: InputMaybe<SortOrder>;
  postId?: InputMaybe<SortOrder>;
  ratio?: InputMaybe<SortOrder>;
  storeItemId?: InputMaybe<SortOrder>;
  subTotal?: InputMaybe<SortOrder>;
};

export type BenefitMemoToOrderOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type BenefitMemoToOrderOrderByWithAggregationInput = {
  _avg?: InputMaybe<BenefitMemoToOrderAvgOrderByAggregateInput>;
  _count?: InputMaybe<BenefitMemoToOrderCountOrderByAggregateInput>;
  _max?: InputMaybe<BenefitMemoToOrderMaxOrderByAggregateInput>;
  _min?: InputMaybe<BenefitMemoToOrderMinOrderByAggregateInput>;
  _sum?: InputMaybe<BenefitMemoToOrderSumOrderByAggregateInput>;
  benefit?: InputMaybe<SortOrder>;
  benefitMemoId?: InputMaybe<SortOrder>;
  benefitRatioId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  orderId?: InputMaybe<SortOrder>;
  originalPrice?: InputMaybe<SortOrder>;
  postId?: InputMaybe<SortOrder>;
  ratio?: InputMaybe<SortOrder>;
  storeItemId?: InputMaybe<SortOrder>;
  subTotal?: InputMaybe<SortOrder>;
};

export type BenefitMemoToOrderOrderByWithRelationInput = {
  benefit?: InputMaybe<SortOrder>;
  benefitMemo?: InputMaybe<BenefitMemoOrderByWithRelationInput>;
  benefitMemoId?: InputMaybe<SortOrder>;
  benefitRatio?: InputMaybe<StoreRatioOrderByWithRelationInput>;
  benefitRatioId?: InputMaybe<SortOrder>;
  couponEntities?: InputMaybe<CouponEntityOrderByRelationAggregateInput>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  order?: InputMaybe<OrderOrderByWithRelationInput>;
  orderId?: InputMaybe<SortOrder>;
  originalPrice?: InputMaybe<SortOrder>;
  post?: InputMaybe<PostOrderByWithRelationInput>;
  postId?: InputMaybe<SortOrder>;
  ratio?: InputMaybe<SortOrder>;
  storeItem?: InputMaybe<StoreItemOrderByWithRelationInput>;
  storeItemId?: InputMaybe<SortOrder>;
  subTotal?: InputMaybe<SortOrder>;
};

export type BenefitMemoToOrderRelationFilter = {
  is?: InputMaybe<BenefitMemoToOrderWhereInput>;
  isNot?: InputMaybe<BenefitMemoToOrderWhereInput>;
};

export enum BenefitMemoToOrderScalarFieldEnum {
  Benefit = 'benefit',
  BenefitMemoId = 'benefitMemoId',
  BenefitRatioId = 'benefitRatioId',
  CreatedAt = 'createdAt',
  Id = 'id',
  OrderId = 'orderId',
  OriginalPrice = 'originalPrice',
  PostId = 'postId',
  Ratio = 'ratio',
  StoreItemId = 'storeItemId',
  SubTotal = 'subTotal'
}

export type BenefitMemoToOrderScalarWhereInput = {
  AND?: InputMaybe<Array<BenefitMemoToOrderScalarWhereInput>>;
  NOT?: InputMaybe<Array<BenefitMemoToOrderScalarWhereInput>>;
  OR?: InputMaybe<Array<BenefitMemoToOrderScalarWhereInput>>;
  benefit?: InputMaybe<FloatFilter>;
  benefitMemoId?: InputMaybe<StringFilter>;
  benefitRatioId?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  orderId?: InputMaybe<StringFilter>;
  originalPrice?: InputMaybe<FloatFilter>;
  postId?: InputMaybe<StringFilter>;
  ratio?: InputMaybe<FloatFilter>;
  storeItemId?: InputMaybe<StringFilter>;
  subTotal?: InputMaybe<FloatFilter>;
};

export type BenefitMemoToOrderScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<BenefitMemoToOrderScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<BenefitMemoToOrderScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<BenefitMemoToOrderScalarWhereWithAggregatesInput>>;
  benefit?: InputMaybe<FloatWithAggregatesFilter>;
  benefitMemoId?: InputMaybe<StringWithAggregatesFilter>;
  benefitRatioId?: InputMaybe<StringWithAggregatesFilter>;
  createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  id?: InputMaybe<StringWithAggregatesFilter>;
  orderId?: InputMaybe<StringWithAggregatesFilter>;
  originalPrice?: InputMaybe<FloatWithAggregatesFilter>;
  postId?: InputMaybe<StringWithAggregatesFilter>;
  ratio?: InputMaybe<FloatWithAggregatesFilter>;
  storeItemId?: InputMaybe<StringWithAggregatesFilter>;
  subTotal?: InputMaybe<FloatWithAggregatesFilter>;
};

export type BenefitMemoToOrderSumAggregate = {
  __typename?: 'BenefitMemoToOrderSumAggregate';
  benefit?: Maybe<Scalars['Float']>;
  originalPrice?: Maybe<Scalars['Float']>;
  ratio?: Maybe<Scalars['Float']>;
  subTotal?: Maybe<Scalars['Float']>;
};

export type BenefitMemoToOrderSumOrderByAggregateInput = {
  benefit?: InputMaybe<SortOrder>;
  originalPrice?: InputMaybe<SortOrder>;
  ratio?: InputMaybe<SortOrder>;
  subTotal?: InputMaybe<SortOrder>;
};

export type BenefitMemoToOrderUpdateInput = {
  benefit?: InputMaybe<FloatFieldUpdateOperationsInput>;
  benefitMemo?: InputMaybe<BenefitMemoUpdateOneRequiredWithoutOrderNestedInput>;
  benefitRatio?: InputMaybe<StoreRatioUpdateOneRequiredWithoutInBenefitMemoToOrderNestedInput>;
  couponEntities?: InputMaybe<CouponEntityUpdateManyWithoutBenefitMemoToOrderNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  order?: InputMaybe<OrderUpdateOneRequiredWithoutBenefitMemosNestedInput>;
  originalPrice?: InputMaybe<FloatFieldUpdateOperationsInput>;
  post?: InputMaybe<PostUpdateOneRequiredWithoutBenefitMemoToOrderNestedInput>;
  ratio?: InputMaybe<FloatFieldUpdateOperationsInput>;
  storeItem?: InputMaybe<StoreItemUpdateOneRequiredWithoutInBenefitMemoToOrdersNestedInput>;
  subTotal?: InputMaybe<FloatFieldUpdateOperationsInput>;
};

export type BenefitMemoToOrderUpdateManyMutationInput = {
  benefit?: InputMaybe<FloatFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  originalPrice?: InputMaybe<FloatFieldUpdateOperationsInput>;
  ratio?: InputMaybe<FloatFieldUpdateOperationsInput>;
  subTotal?: InputMaybe<FloatFieldUpdateOperationsInput>;
};

export type BenefitMemoToOrderUpdateManyWithWhereWithoutBenefitRatioInput = {
  data: BenefitMemoToOrderUpdateManyMutationInput;
  where: BenefitMemoToOrderScalarWhereInput;
};

export type BenefitMemoToOrderUpdateManyWithWhereWithoutCouponEntitiesInput = {
  data: BenefitMemoToOrderUpdateManyMutationInput;
  where: BenefitMemoToOrderScalarWhereInput;
};

export type BenefitMemoToOrderUpdateManyWithWhereWithoutOrderInput = {
  data: BenefitMemoToOrderUpdateManyMutationInput;
  where: BenefitMemoToOrderScalarWhereInput;
};

export type BenefitMemoToOrderUpdateManyWithWhereWithoutPostInput = {
  data: BenefitMemoToOrderUpdateManyMutationInput;
  where: BenefitMemoToOrderScalarWhereInput;
};

export type BenefitMemoToOrderUpdateManyWithWhereWithoutStoreItemInput = {
  data: BenefitMemoToOrderUpdateManyMutationInput;
  where: BenefitMemoToOrderScalarWhereInput;
};

export type BenefitMemoToOrderUpdateManyWithoutBenefitRatioNestedInput = {
  connect?: InputMaybe<Array<BenefitMemoToOrderWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<BenefitMemoToOrderCreateOrConnectWithoutBenefitRatioInput>>;
  create?: InputMaybe<Array<BenefitMemoToOrderCreateWithoutBenefitRatioInput>>;
  createMany?: InputMaybe<BenefitMemoToOrderCreateManyBenefitRatioInputEnvelope>;
  delete?: InputMaybe<Array<BenefitMemoToOrderWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<BenefitMemoToOrderScalarWhereInput>>;
  disconnect?: InputMaybe<Array<BenefitMemoToOrderWhereUniqueInput>>;
  set?: InputMaybe<Array<BenefitMemoToOrderWhereUniqueInput>>;
  update?: InputMaybe<Array<BenefitMemoToOrderUpdateWithWhereUniqueWithoutBenefitRatioInput>>;
  updateMany?: InputMaybe<Array<BenefitMemoToOrderUpdateManyWithWhereWithoutBenefitRatioInput>>;
  upsert?: InputMaybe<Array<BenefitMemoToOrderUpsertWithWhereUniqueWithoutBenefitRatioInput>>;
};

export type BenefitMemoToOrderUpdateManyWithoutCouponEntitiesNestedInput = {
  connect?: InputMaybe<Array<BenefitMemoToOrderWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<BenefitMemoToOrderCreateOrConnectWithoutCouponEntitiesInput>>;
  create?: InputMaybe<Array<BenefitMemoToOrderCreateWithoutCouponEntitiesInput>>;
  delete?: InputMaybe<Array<BenefitMemoToOrderWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<BenefitMemoToOrderScalarWhereInput>>;
  disconnect?: InputMaybe<Array<BenefitMemoToOrderWhereUniqueInput>>;
  set?: InputMaybe<Array<BenefitMemoToOrderWhereUniqueInput>>;
  update?: InputMaybe<Array<BenefitMemoToOrderUpdateWithWhereUniqueWithoutCouponEntitiesInput>>;
  updateMany?: InputMaybe<Array<BenefitMemoToOrderUpdateManyWithWhereWithoutCouponEntitiesInput>>;
  upsert?: InputMaybe<Array<BenefitMemoToOrderUpsertWithWhereUniqueWithoutCouponEntitiesInput>>;
};

export type BenefitMemoToOrderUpdateManyWithoutOrderNestedInput = {
  connect?: InputMaybe<Array<BenefitMemoToOrderWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<BenefitMemoToOrderCreateOrConnectWithoutOrderInput>>;
  create?: InputMaybe<Array<BenefitMemoToOrderCreateWithoutOrderInput>>;
  createMany?: InputMaybe<BenefitMemoToOrderCreateManyOrderInputEnvelope>;
  delete?: InputMaybe<Array<BenefitMemoToOrderWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<BenefitMemoToOrderScalarWhereInput>>;
  disconnect?: InputMaybe<Array<BenefitMemoToOrderWhereUniqueInput>>;
  set?: InputMaybe<Array<BenefitMemoToOrderWhereUniqueInput>>;
  update?: InputMaybe<Array<BenefitMemoToOrderUpdateWithWhereUniqueWithoutOrderInput>>;
  updateMany?: InputMaybe<Array<BenefitMemoToOrderUpdateManyWithWhereWithoutOrderInput>>;
  upsert?: InputMaybe<Array<BenefitMemoToOrderUpsertWithWhereUniqueWithoutOrderInput>>;
};

export type BenefitMemoToOrderUpdateManyWithoutPostNestedInput = {
  connect?: InputMaybe<Array<BenefitMemoToOrderWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<BenefitMemoToOrderCreateOrConnectWithoutPostInput>>;
  create?: InputMaybe<Array<BenefitMemoToOrderCreateWithoutPostInput>>;
  createMany?: InputMaybe<BenefitMemoToOrderCreateManyPostInputEnvelope>;
  delete?: InputMaybe<Array<BenefitMemoToOrderWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<BenefitMemoToOrderScalarWhereInput>>;
  disconnect?: InputMaybe<Array<BenefitMemoToOrderWhereUniqueInput>>;
  set?: InputMaybe<Array<BenefitMemoToOrderWhereUniqueInput>>;
  update?: InputMaybe<Array<BenefitMemoToOrderUpdateWithWhereUniqueWithoutPostInput>>;
  updateMany?: InputMaybe<Array<BenefitMemoToOrderUpdateManyWithWhereWithoutPostInput>>;
  upsert?: InputMaybe<Array<BenefitMemoToOrderUpsertWithWhereUniqueWithoutPostInput>>;
};

export type BenefitMemoToOrderUpdateManyWithoutStoreItemNestedInput = {
  connect?: InputMaybe<Array<BenefitMemoToOrderWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<BenefitMemoToOrderCreateOrConnectWithoutStoreItemInput>>;
  create?: InputMaybe<Array<BenefitMemoToOrderCreateWithoutStoreItemInput>>;
  createMany?: InputMaybe<BenefitMemoToOrderCreateManyStoreItemInputEnvelope>;
  delete?: InputMaybe<Array<BenefitMemoToOrderWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<BenefitMemoToOrderScalarWhereInput>>;
  disconnect?: InputMaybe<Array<BenefitMemoToOrderWhereUniqueInput>>;
  set?: InputMaybe<Array<BenefitMemoToOrderWhereUniqueInput>>;
  update?: InputMaybe<Array<BenefitMemoToOrderUpdateWithWhereUniqueWithoutStoreItemInput>>;
  updateMany?: InputMaybe<Array<BenefitMemoToOrderUpdateManyWithWhereWithoutStoreItemInput>>;
  upsert?: InputMaybe<Array<BenefitMemoToOrderUpsertWithWhereUniqueWithoutStoreItemInput>>;
};

export type BenefitMemoToOrderUpdateOneWithoutBenefitMemoNestedInput = {
  connect?: InputMaybe<BenefitMemoToOrderWhereUniqueInput>;
  connectOrCreate?: InputMaybe<BenefitMemoToOrderCreateOrConnectWithoutBenefitMemoInput>;
  create?: InputMaybe<BenefitMemoToOrderCreateWithoutBenefitMemoInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<BenefitMemoToOrderUpdateWithoutBenefitMemoInput>;
  upsert?: InputMaybe<BenefitMemoToOrderUpsertWithoutBenefitMemoInput>;
};

export type BenefitMemoToOrderUpdateWithWhereUniqueWithoutBenefitRatioInput = {
  data: BenefitMemoToOrderUpdateWithoutBenefitRatioInput;
  where: BenefitMemoToOrderWhereUniqueInput;
};

export type BenefitMemoToOrderUpdateWithWhereUniqueWithoutCouponEntitiesInput = {
  data: BenefitMemoToOrderUpdateWithoutCouponEntitiesInput;
  where: BenefitMemoToOrderWhereUniqueInput;
};

export type BenefitMemoToOrderUpdateWithWhereUniqueWithoutOrderInput = {
  data: BenefitMemoToOrderUpdateWithoutOrderInput;
  where: BenefitMemoToOrderWhereUniqueInput;
};

export type BenefitMemoToOrderUpdateWithWhereUniqueWithoutPostInput = {
  data: BenefitMemoToOrderUpdateWithoutPostInput;
  where: BenefitMemoToOrderWhereUniqueInput;
};

export type BenefitMemoToOrderUpdateWithWhereUniqueWithoutStoreItemInput = {
  data: BenefitMemoToOrderUpdateWithoutStoreItemInput;
  where: BenefitMemoToOrderWhereUniqueInput;
};

export type BenefitMemoToOrderUpdateWithoutBenefitMemoInput = {
  benefit?: InputMaybe<FloatFieldUpdateOperationsInput>;
  benefitRatio?: InputMaybe<StoreRatioUpdateOneRequiredWithoutInBenefitMemoToOrderNestedInput>;
  couponEntities?: InputMaybe<CouponEntityUpdateManyWithoutBenefitMemoToOrderNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  order?: InputMaybe<OrderUpdateOneRequiredWithoutBenefitMemosNestedInput>;
  originalPrice?: InputMaybe<FloatFieldUpdateOperationsInput>;
  post?: InputMaybe<PostUpdateOneRequiredWithoutBenefitMemoToOrderNestedInput>;
  ratio?: InputMaybe<FloatFieldUpdateOperationsInput>;
  storeItem?: InputMaybe<StoreItemUpdateOneRequiredWithoutInBenefitMemoToOrdersNestedInput>;
  subTotal?: InputMaybe<FloatFieldUpdateOperationsInput>;
};

export type BenefitMemoToOrderUpdateWithoutBenefitRatioInput = {
  benefit?: InputMaybe<FloatFieldUpdateOperationsInput>;
  benefitMemo?: InputMaybe<BenefitMemoUpdateOneRequiredWithoutOrderNestedInput>;
  couponEntities?: InputMaybe<CouponEntityUpdateManyWithoutBenefitMemoToOrderNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  order?: InputMaybe<OrderUpdateOneRequiredWithoutBenefitMemosNestedInput>;
  originalPrice?: InputMaybe<FloatFieldUpdateOperationsInput>;
  post?: InputMaybe<PostUpdateOneRequiredWithoutBenefitMemoToOrderNestedInput>;
  ratio?: InputMaybe<FloatFieldUpdateOperationsInput>;
  storeItem?: InputMaybe<StoreItemUpdateOneRequiredWithoutInBenefitMemoToOrdersNestedInput>;
  subTotal?: InputMaybe<FloatFieldUpdateOperationsInput>;
};

export type BenefitMemoToOrderUpdateWithoutCouponEntitiesInput = {
  benefit?: InputMaybe<FloatFieldUpdateOperationsInput>;
  benefitMemo?: InputMaybe<BenefitMemoUpdateOneRequiredWithoutOrderNestedInput>;
  benefitRatio?: InputMaybe<StoreRatioUpdateOneRequiredWithoutInBenefitMemoToOrderNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  order?: InputMaybe<OrderUpdateOneRequiredWithoutBenefitMemosNestedInput>;
  originalPrice?: InputMaybe<FloatFieldUpdateOperationsInput>;
  post?: InputMaybe<PostUpdateOneRequiredWithoutBenefitMemoToOrderNestedInput>;
  ratio?: InputMaybe<FloatFieldUpdateOperationsInput>;
  storeItem?: InputMaybe<StoreItemUpdateOneRequiredWithoutInBenefitMemoToOrdersNestedInput>;
  subTotal?: InputMaybe<FloatFieldUpdateOperationsInput>;
};

export type BenefitMemoToOrderUpdateWithoutOrderInput = {
  benefit?: InputMaybe<FloatFieldUpdateOperationsInput>;
  benefitMemo?: InputMaybe<BenefitMemoUpdateOneRequiredWithoutOrderNestedInput>;
  benefitRatio?: InputMaybe<StoreRatioUpdateOneRequiredWithoutInBenefitMemoToOrderNestedInput>;
  couponEntities?: InputMaybe<CouponEntityUpdateManyWithoutBenefitMemoToOrderNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  originalPrice?: InputMaybe<FloatFieldUpdateOperationsInput>;
  post?: InputMaybe<PostUpdateOneRequiredWithoutBenefitMemoToOrderNestedInput>;
  ratio?: InputMaybe<FloatFieldUpdateOperationsInput>;
  storeItem?: InputMaybe<StoreItemUpdateOneRequiredWithoutInBenefitMemoToOrdersNestedInput>;
  subTotal?: InputMaybe<FloatFieldUpdateOperationsInput>;
};

export type BenefitMemoToOrderUpdateWithoutPostInput = {
  benefit?: InputMaybe<FloatFieldUpdateOperationsInput>;
  benefitMemo?: InputMaybe<BenefitMemoUpdateOneRequiredWithoutOrderNestedInput>;
  benefitRatio?: InputMaybe<StoreRatioUpdateOneRequiredWithoutInBenefitMemoToOrderNestedInput>;
  couponEntities?: InputMaybe<CouponEntityUpdateManyWithoutBenefitMemoToOrderNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  order?: InputMaybe<OrderUpdateOneRequiredWithoutBenefitMemosNestedInput>;
  originalPrice?: InputMaybe<FloatFieldUpdateOperationsInput>;
  ratio?: InputMaybe<FloatFieldUpdateOperationsInput>;
  storeItem?: InputMaybe<StoreItemUpdateOneRequiredWithoutInBenefitMemoToOrdersNestedInput>;
  subTotal?: InputMaybe<FloatFieldUpdateOperationsInput>;
};

export type BenefitMemoToOrderUpdateWithoutStoreItemInput = {
  benefit?: InputMaybe<FloatFieldUpdateOperationsInput>;
  benefitMemo?: InputMaybe<BenefitMemoUpdateOneRequiredWithoutOrderNestedInput>;
  benefitRatio?: InputMaybe<StoreRatioUpdateOneRequiredWithoutInBenefitMemoToOrderNestedInput>;
  couponEntities?: InputMaybe<CouponEntityUpdateManyWithoutBenefitMemoToOrderNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  order?: InputMaybe<OrderUpdateOneRequiredWithoutBenefitMemosNestedInput>;
  originalPrice?: InputMaybe<FloatFieldUpdateOperationsInput>;
  post?: InputMaybe<PostUpdateOneRequiredWithoutBenefitMemoToOrderNestedInput>;
  ratio?: InputMaybe<FloatFieldUpdateOperationsInput>;
  subTotal?: InputMaybe<FloatFieldUpdateOperationsInput>;
};

export type BenefitMemoToOrderUpsertWithWhereUniqueWithoutBenefitRatioInput = {
  create: BenefitMemoToOrderCreateWithoutBenefitRatioInput;
  update: BenefitMemoToOrderUpdateWithoutBenefitRatioInput;
  where: BenefitMemoToOrderWhereUniqueInput;
};

export type BenefitMemoToOrderUpsertWithWhereUniqueWithoutCouponEntitiesInput = {
  create: BenefitMemoToOrderCreateWithoutCouponEntitiesInput;
  update: BenefitMemoToOrderUpdateWithoutCouponEntitiesInput;
  where: BenefitMemoToOrderWhereUniqueInput;
};

export type BenefitMemoToOrderUpsertWithWhereUniqueWithoutOrderInput = {
  create: BenefitMemoToOrderCreateWithoutOrderInput;
  update: BenefitMemoToOrderUpdateWithoutOrderInput;
  where: BenefitMemoToOrderWhereUniqueInput;
};

export type BenefitMemoToOrderUpsertWithWhereUniqueWithoutPostInput = {
  create: BenefitMemoToOrderCreateWithoutPostInput;
  update: BenefitMemoToOrderUpdateWithoutPostInput;
  where: BenefitMemoToOrderWhereUniqueInput;
};

export type BenefitMemoToOrderUpsertWithWhereUniqueWithoutStoreItemInput = {
  create: BenefitMemoToOrderCreateWithoutStoreItemInput;
  update: BenefitMemoToOrderUpdateWithoutStoreItemInput;
  where: BenefitMemoToOrderWhereUniqueInput;
};

export type BenefitMemoToOrderUpsertWithoutBenefitMemoInput = {
  create: BenefitMemoToOrderCreateWithoutBenefitMemoInput;
  update: BenefitMemoToOrderUpdateWithoutBenefitMemoInput;
};

export type BenefitMemoToOrderWhereInput = {
  AND?: InputMaybe<Array<BenefitMemoToOrderWhereInput>>;
  NOT?: InputMaybe<Array<BenefitMemoToOrderWhereInput>>;
  OR?: InputMaybe<Array<BenefitMemoToOrderWhereInput>>;
  benefit?: InputMaybe<FloatFilter>;
  benefitMemo?: InputMaybe<BenefitMemoRelationFilter>;
  benefitMemoId?: InputMaybe<StringFilter>;
  benefitRatio?: InputMaybe<StoreRatioRelationFilter>;
  benefitRatioId?: InputMaybe<StringFilter>;
  couponEntities?: InputMaybe<CouponEntityListRelationFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  order?: InputMaybe<OrderRelationFilter>;
  orderId?: InputMaybe<StringFilter>;
  originalPrice?: InputMaybe<FloatFilter>;
  post?: InputMaybe<PostRelationFilter>;
  postId?: InputMaybe<StringFilter>;
  ratio?: InputMaybe<FloatFilter>;
  storeItem?: InputMaybe<StoreItemRelationFilter>;
  storeItemId?: InputMaybe<StringFilter>;
  subTotal?: InputMaybe<FloatFilter>;
};

export type BenefitMemoToOrderWhereUniqueInput = {
  benefitMemoId?: InputMaybe<Scalars['String']>;
  benefitMemoId_postId?: InputMaybe<BenefitMemoToOrderBenefitMemoIdPostIdCompoundUniqueInput>;
  id?: InputMaybe<Scalars['String']>;
};

export type BenefitMemoUpdateInput = {
  coin?: InputMaybe<BenefitMemoToCoinUpdateOneWithoutBenefitMemoNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  order?: InputMaybe<BenefitMemoToOrderUpdateOneWithoutBenefitMemoNestedInput>;
  profitable?: InputMaybe<ProfitableUpdateOneRequiredWithoutBenefitMemosNestedInput>;
  receipt?: InputMaybe<Scalars['JSON']>;
  transfer?: InputMaybe<BenefitTransferUpdateOneWithoutBenifitMemoNestedInput>;
  transferAmount?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  type?: InputMaybe<EnumBenefitTypeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type BenefitMemoUpdateManyMutationInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  receipt?: InputMaybe<Scalars['JSON']>;
  transferAmount?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  type?: InputMaybe<EnumBenefitTypeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type BenefitMemoUpdateManyWithWhereWithoutProfitableInput = {
  data: BenefitMemoUpdateManyMutationInput;
  where: BenefitMemoScalarWhereInput;
};

export type BenefitMemoUpdateManyWithWhereWithoutTransferInput = {
  data: BenefitMemoUpdateManyMutationInput;
  where: BenefitMemoScalarWhereInput;
};

export type BenefitMemoUpdateManyWithoutProfitableNestedInput = {
  connect?: InputMaybe<Array<BenefitMemoWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<BenefitMemoCreateOrConnectWithoutProfitableInput>>;
  create?: InputMaybe<Array<BenefitMemoCreateWithoutProfitableInput>>;
  createMany?: InputMaybe<BenefitMemoCreateManyProfitableInputEnvelope>;
  delete?: InputMaybe<Array<BenefitMemoWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<BenefitMemoScalarWhereInput>>;
  disconnect?: InputMaybe<Array<BenefitMemoWhereUniqueInput>>;
  set?: InputMaybe<Array<BenefitMemoWhereUniqueInput>>;
  update?: InputMaybe<Array<BenefitMemoUpdateWithWhereUniqueWithoutProfitableInput>>;
  updateMany?: InputMaybe<Array<BenefitMemoUpdateManyWithWhereWithoutProfitableInput>>;
  upsert?: InputMaybe<Array<BenefitMemoUpsertWithWhereUniqueWithoutProfitableInput>>;
};

export type BenefitMemoUpdateManyWithoutTransferNestedInput = {
  connect?: InputMaybe<Array<BenefitMemoWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<BenefitMemoCreateOrConnectWithoutTransferInput>>;
  create?: InputMaybe<Array<BenefitMemoCreateWithoutTransferInput>>;
  createMany?: InputMaybe<BenefitMemoCreateManyTransferInputEnvelope>;
  delete?: InputMaybe<Array<BenefitMemoWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<BenefitMemoScalarWhereInput>>;
  disconnect?: InputMaybe<Array<BenefitMemoWhereUniqueInput>>;
  set?: InputMaybe<Array<BenefitMemoWhereUniqueInput>>;
  update?: InputMaybe<Array<BenefitMemoUpdateWithWhereUniqueWithoutTransferInput>>;
  updateMany?: InputMaybe<Array<BenefitMemoUpdateManyWithWhereWithoutTransferInput>>;
  upsert?: InputMaybe<Array<BenefitMemoUpsertWithWhereUniqueWithoutTransferInput>>;
};

export type BenefitMemoUpdateOneRequiredWithoutCoinNestedInput = {
  connect?: InputMaybe<BenefitMemoWhereUniqueInput>;
  connectOrCreate?: InputMaybe<BenefitMemoCreateOrConnectWithoutCoinInput>;
  create?: InputMaybe<BenefitMemoCreateWithoutCoinInput>;
  update?: InputMaybe<BenefitMemoUpdateWithoutCoinInput>;
  upsert?: InputMaybe<BenefitMemoUpsertWithoutCoinInput>;
};

export type BenefitMemoUpdateOneRequiredWithoutOrderNestedInput = {
  connect?: InputMaybe<BenefitMemoWhereUniqueInput>;
  connectOrCreate?: InputMaybe<BenefitMemoCreateOrConnectWithoutOrderInput>;
  create?: InputMaybe<BenefitMemoCreateWithoutOrderInput>;
  update?: InputMaybe<BenefitMemoUpdateWithoutOrderInput>;
  upsert?: InputMaybe<BenefitMemoUpsertWithoutOrderInput>;
};

export type BenefitMemoUpdateWithWhereUniqueWithoutProfitableInput = {
  data: BenefitMemoUpdateWithoutProfitableInput;
  where: BenefitMemoWhereUniqueInput;
};

export type BenefitMemoUpdateWithWhereUniqueWithoutTransferInput = {
  data: BenefitMemoUpdateWithoutTransferInput;
  where: BenefitMemoWhereUniqueInput;
};

export type BenefitMemoUpdateWithoutCoinInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  order?: InputMaybe<BenefitMemoToOrderUpdateOneWithoutBenefitMemoNestedInput>;
  profitable?: InputMaybe<ProfitableUpdateOneRequiredWithoutBenefitMemosNestedInput>;
  receipt?: InputMaybe<Scalars['JSON']>;
  transfer?: InputMaybe<BenefitTransferUpdateOneWithoutBenifitMemoNestedInput>;
  transferAmount?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  type?: InputMaybe<EnumBenefitTypeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type BenefitMemoUpdateWithoutOrderInput = {
  coin?: InputMaybe<BenefitMemoToCoinUpdateOneWithoutBenefitMemoNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  profitable?: InputMaybe<ProfitableUpdateOneRequiredWithoutBenefitMemosNestedInput>;
  receipt?: InputMaybe<Scalars['JSON']>;
  transfer?: InputMaybe<BenefitTransferUpdateOneWithoutBenifitMemoNestedInput>;
  transferAmount?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  type?: InputMaybe<EnumBenefitTypeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type BenefitMemoUpdateWithoutProfitableInput = {
  coin?: InputMaybe<BenefitMemoToCoinUpdateOneWithoutBenefitMemoNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  order?: InputMaybe<BenefitMemoToOrderUpdateOneWithoutBenefitMemoNestedInput>;
  receipt?: InputMaybe<Scalars['JSON']>;
  transfer?: InputMaybe<BenefitTransferUpdateOneWithoutBenifitMemoNestedInput>;
  transferAmount?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  type?: InputMaybe<EnumBenefitTypeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type BenefitMemoUpdateWithoutTransferInput = {
  coin?: InputMaybe<BenefitMemoToCoinUpdateOneWithoutBenefitMemoNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  order?: InputMaybe<BenefitMemoToOrderUpdateOneWithoutBenefitMemoNestedInput>;
  profitable?: InputMaybe<ProfitableUpdateOneRequiredWithoutBenefitMemosNestedInput>;
  receipt?: InputMaybe<Scalars['JSON']>;
  transferAmount?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  type?: InputMaybe<EnumBenefitTypeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type BenefitMemoUpsertWithWhereUniqueWithoutProfitableInput = {
  create: BenefitMemoCreateWithoutProfitableInput;
  update: BenefitMemoUpdateWithoutProfitableInput;
  where: BenefitMemoWhereUniqueInput;
};

export type BenefitMemoUpsertWithWhereUniqueWithoutTransferInput = {
  create: BenefitMemoCreateWithoutTransferInput;
  update: BenefitMemoUpdateWithoutTransferInput;
  where: BenefitMemoWhereUniqueInput;
};

export type BenefitMemoUpsertWithoutCoinInput = {
  create: BenefitMemoCreateWithoutCoinInput;
  update: BenefitMemoUpdateWithoutCoinInput;
};

export type BenefitMemoUpsertWithoutOrderInput = {
  create: BenefitMemoCreateWithoutOrderInput;
  update: BenefitMemoUpdateWithoutOrderInput;
};

export type BenefitMemoWhereInput = {
  AND?: InputMaybe<Array<BenefitMemoWhereInput>>;
  NOT?: InputMaybe<Array<BenefitMemoWhereInput>>;
  OR?: InputMaybe<Array<BenefitMemoWhereInput>>;
  coin?: InputMaybe<BenefitMemoToCoinRelationFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  order?: InputMaybe<BenefitMemoToOrderRelationFilter>;
  profitable?: InputMaybe<ProfitableRelationFilter>;
  profitableId?: InputMaybe<StringFilter>;
  receipt?: InputMaybe<JsonNullableFilter>;
  transfer?: InputMaybe<BenefitTransferRelationFilter>;
  transferAmount?: InputMaybe<FloatNullableFilter>;
  transferId?: InputMaybe<StringNullableFilter>;
  type?: InputMaybe<EnumBenefitTypeFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type BenefitMemoWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
};

export type BenefitTransfer = {
  __typename?: 'BenefitTransfer';
  _count?: Maybe<BenefitTransferCount>;
  benifitMemo: Array<BenefitMemo>;
  createdAt: Scalars['DateTime'];
  exchangeRateForCoin?: Maybe<Scalars['Float']>;
  id: Scalars['String'];
  profitable: Profitable;
  profitableId: Scalars['String'];
  totalAmount?: Maybe<Scalars['Float']>;
  transferDate?: Maybe<Scalars['String']>;
  transferMemo?: Maybe<Scalars['JSON']>;
};


export type BenefitTransferBenifitMemoArgs = {
  cursor?: InputMaybe<BenefitMemoWhereUniqueInput>;
  distinct?: InputMaybe<Array<BenefitMemoScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<BenefitMemoOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<BenefitMemoWhereInput>;
};

export type BenefitTransferAvgAggregate = {
  __typename?: 'BenefitTransferAvgAggregate';
  exchangeRateForCoin?: Maybe<Scalars['Float']>;
  totalAmount?: Maybe<Scalars['Float']>;
};

export type BenefitTransferAvgOrderByAggregateInput = {
  exchangeRateForCoin?: InputMaybe<SortOrder>;
  totalAmount?: InputMaybe<SortOrder>;
};

export type BenefitTransferCount = {
  __typename?: 'BenefitTransferCount';
  benifitMemo: Scalars['Int'];
};


export type BenefitTransferCountBenifitMemoArgs = {
  where?: InputMaybe<BenefitMemoWhereInput>;
};

export type BenefitTransferCountAggregate = {
  __typename?: 'BenefitTransferCountAggregate';
  _all: Scalars['Int'];
  createdAt: Scalars['Int'];
  exchangeRateForCoin: Scalars['Int'];
  id: Scalars['Int'];
  profitableId: Scalars['Int'];
  totalAmount: Scalars['Int'];
  transferDate: Scalars['Int'];
  transferMemo: Scalars['Int'];
};

export type BenefitTransferCountOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  exchangeRateForCoin?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  profitableId?: InputMaybe<SortOrder>;
  totalAmount?: InputMaybe<SortOrder>;
  transferDate?: InputMaybe<SortOrder>;
  transferMemo?: InputMaybe<SortOrder>;
};

export type BenefitTransferCreateInput = {
  benifitMemo?: InputMaybe<BenefitMemoCreateNestedManyWithoutTransferInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  exchangeRateForCoin?: InputMaybe<Scalars['Float']>;
  id?: InputMaybe<Scalars['String']>;
  profitable: ProfitableCreateNestedOneWithoutBenefitTransfersInput;
  totalAmount?: InputMaybe<Scalars['Float']>;
  transferDate?: InputMaybe<Scalars['String']>;
  transferMemo?: InputMaybe<Scalars['JSON']>;
};

export type BenefitTransferCreateManyInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  exchangeRateForCoin?: InputMaybe<Scalars['Float']>;
  id?: InputMaybe<Scalars['String']>;
  profitableId: Scalars['String'];
  totalAmount?: InputMaybe<Scalars['Float']>;
  transferDate?: InputMaybe<Scalars['String']>;
  transferMemo?: InputMaybe<Scalars['JSON']>;
};

export type BenefitTransferCreateManyProfitableInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  exchangeRateForCoin?: InputMaybe<Scalars['Float']>;
  id?: InputMaybe<Scalars['String']>;
  totalAmount?: InputMaybe<Scalars['Float']>;
  transferDate?: InputMaybe<Scalars['String']>;
  transferMemo?: InputMaybe<Scalars['JSON']>;
};

export type BenefitTransferCreateManyProfitableInputEnvelope = {
  data: Array<BenefitTransferCreateManyProfitableInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type BenefitTransferCreateNestedManyWithoutProfitableInput = {
  connect?: InputMaybe<Array<BenefitTransferWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<BenefitTransferCreateOrConnectWithoutProfitableInput>>;
  create?: InputMaybe<Array<BenefitTransferCreateWithoutProfitableInput>>;
  createMany?: InputMaybe<BenefitTransferCreateManyProfitableInputEnvelope>;
};

export type BenefitTransferCreateNestedOneWithoutBenifitMemoInput = {
  connect?: InputMaybe<BenefitTransferWhereUniqueInput>;
  connectOrCreate?: InputMaybe<BenefitTransferCreateOrConnectWithoutBenifitMemoInput>;
  create?: InputMaybe<BenefitTransferCreateWithoutBenifitMemoInput>;
};

export type BenefitTransferCreateOrConnectWithoutBenifitMemoInput = {
  create: BenefitTransferCreateWithoutBenifitMemoInput;
  where: BenefitTransferWhereUniqueInput;
};

export type BenefitTransferCreateOrConnectWithoutProfitableInput = {
  create: BenefitTransferCreateWithoutProfitableInput;
  where: BenefitTransferWhereUniqueInput;
};

export type BenefitTransferCreateWithoutBenifitMemoInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  exchangeRateForCoin?: InputMaybe<Scalars['Float']>;
  id?: InputMaybe<Scalars['String']>;
  profitable: ProfitableCreateNestedOneWithoutBenefitTransfersInput;
  totalAmount?: InputMaybe<Scalars['Float']>;
  transferDate?: InputMaybe<Scalars['String']>;
  transferMemo?: InputMaybe<Scalars['JSON']>;
};

export type BenefitTransferCreateWithoutProfitableInput = {
  benifitMemo?: InputMaybe<BenefitMemoCreateNestedManyWithoutTransferInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  exchangeRateForCoin?: InputMaybe<Scalars['Float']>;
  id?: InputMaybe<Scalars['String']>;
  totalAmount?: InputMaybe<Scalars['Float']>;
  transferDate?: InputMaybe<Scalars['String']>;
  transferMemo?: InputMaybe<Scalars['JSON']>;
};

export type BenefitTransferGroupBy = {
  __typename?: 'BenefitTransferGroupBy';
  _avg?: Maybe<BenefitTransferAvgAggregate>;
  _count?: Maybe<BenefitTransferCountAggregate>;
  _max?: Maybe<BenefitTransferMaxAggregate>;
  _min?: Maybe<BenefitTransferMinAggregate>;
  _sum?: Maybe<BenefitTransferSumAggregate>;
  createdAt: Scalars['DateTime'];
  exchangeRateForCoin?: Maybe<Scalars['Float']>;
  id: Scalars['String'];
  profitableId: Scalars['String'];
  totalAmount?: Maybe<Scalars['Float']>;
  transferDate?: Maybe<Scalars['String']>;
  transferMemo?: Maybe<Scalars['JSON']>;
};

export type BenefitTransferListRelationFilter = {
  every?: InputMaybe<BenefitTransferWhereInput>;
  none?: InputMaybe<BenefitTransferWhereInput>;
  some?: InputMaybe<BenefitTransferWhereInput>;
};

export type BenefitTransferMaxAggregate = {
  __typename?: 'BenefitTransferMaxAggregate';
  createdAt?: Maybe<Scalars['DateTime']>;
  exchangeRateForCoin?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['String']>;
  profitableId?: Maybe<Scalars['String']>;
  totalAmount?: Maybe<Scalars['Float']>;
  transferDate?: Maybe<Scalars['String']>;
};

export type BenefitTransferMaxOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  exchangeRateForCoin?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  profitableId?: InputMaybe<SortOrder>;
  totalAmount?: InputMaybe<SortOrder>;
  transferDate?: InputMaybe<SortOrder>;
};

export type BenefitTransferMinAggregate = {
  __typename?: 'BenefitTransferMinAggregate';
  createdAt?: Maybe<Scalars['DateTime']>;
  exchangeRateForCoin?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['String']>;
  profitableId?: Maybe<Scalars['String']>;
  totalAmount?: Maybe<Scalars['Float']>;
  transferDate?: Maybe<Scalars['String']>;
};

export type BenefitTransferMinOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  exchangeRateForCoin?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  profitableId?: InputMaybe<SortOrder>;
  totalAmount?: InputMaybe<SortOrder>;
  transferDate?: InputMaybe<SortOrder>;
};

export type BenefitTransferOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type BenefitTransferOrderByWithAggregationInput = {
  _avg?: InputMaybe<BenefitTransferAvgOrderByAggregateInput>;
  _count?: InputMaybe<BenefitTransferCountOrderByAggregateInput>;
  _max?: InputMaybe<BenefitTransferMaxOrderByAggregateInput>;
  _min?: InputMaybe<BenefitTransferMinOrderByAggregateInput>;
  _sum?: InputMaybe<BenefitTransferSumOrderByAggregateInput>;
  createdAt?: InputMaybe<SortOrder>;
  exchangeRateForCoin?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  profitableId?: InputMaybe<SortOrder>;
  totalAmount?: InputMaybe<SortOrder>;
  transferDate?: InputMaybe<SortOrder>;
  transferMemo?: InputMaybe<SortOrder>;
};

export type BenefitTransferOrderByWithRelationInput = {
  benifitMemo?: InputMaybe<BenefitMemoOrderByRelationAggregateInput>;
  createdAt?: InputMaybe<SortOrder>;
  exchangeRateForCoin?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  profitable?: InputMaybe<ProfitableOrderByWithRelationInput>;
  profitableId?: InputMaybe<SortOrder>;
  totalAmount?: InputMaybe<SortOrder>;
  transferDate?: InputMaybe<SortOrder>;
  transferMemo?: InputMaybe<SortOrder>;
};

export type BenefitTransferRelationFilter = {
  is?: InputMaybe<BenefitTransferWhereInput>;
  isNot?: InputMaybe<BenefitTransferWhereInput>;
};

export enum BenefitTransferScalarFieldEnum {
  CreatedAt = 'createdAt',
  ExchangeRateForCoin = 'exchangeRateForCoin',
  Id = 'id',
  ProfitableId = 'profitableId',
  TotalAmount = 'totalAmount',
  TransferDate = 'transferDate',
  TransferMemo = 'transferMemo'
}

export type BenefitTransferScalarWhereInput = {
  AND?: InputMaybe<Array<BenefitTransferScalarWhereInput>>;
  NOT?: InputMaybe<Array<BenefitTransferScalarWhereInput>>;
  OR?: InputMaybe<Array<BenefitTransferScalarWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  exchangeRateForCoin?: InputMaybe<FloatNullableFilter>;
  id?: InputMaybe<StringFilter>;
  profitableId?: InputMaybe<StringFilter>;
  totalAmount?: InputMaybe<FloatNullableFilter>;
  transferDate?: InputMaybe<StringNullableFilter>;
  transferMemo?: InputMaybe<JsonNullableFilter>;
};

export type BenefitTransferScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<BenefitTransferScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<BenefitTransferScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<BenefitTransferScalarWhereWithAggregatesInput>>;
  createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  exchangeRateForCoin?: InputMaybe<FloatNullableWithAggregatesFilter>;
  id?: InputMaybe<StringWithAggregatesFilter>;
  profitableId?: InputMaybe<StringWithAggregatesFilter>;
  totalAmount?: InputMaybe<FloatNullableWithAggregatesFilter>;
  transferDate?: InputMaybe<StringNullableWithAggregatesFilter>;
  transferMemo?: InputMaybe<JsonNullableWithAggregatesFilter>;
};

export type BenefitTransferSumAggregate = {
  __typename?: 'BenefitTransferSumAggregate';
  exchangeRateForCoin?: Maybe<Scalars['Float']>;
  totalAmount?: Maybe<Scalars['Float']>;
};

export type BenefitTransferSumOrderByAggregateInput = {
  exchangeRateForCoin?: InputMaybe<SortOrder>;
  totalAmount?: InputMaybe<SortOrder>;
};

export type BenefitTransferUpdateInput = {
  benifitMemo?: InputMaybe<BenefitMemoUpdateManyWithoutTransferNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  exchangeRateForCoin?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  profitable?: InputMaybe<ProfitableUpdateOneRequiredWithoutBenefitTransfersNestedInput>;
  totalAmount?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  transferDate?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  transferMemo?: InputMaybe<Scalars['JSON']>;
};

export type BenefitTransferUpdateManyMutationInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  exchangeRateForCoin?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  totalAmount?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  transferDate?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  transferMemo?: InputMaybe<Scalars['JSON']>;
};

export type BenefitTransferUpdateManyWithWhereWithoutProfitableInput = {
  data: BenefitTransferUpdateManyMutationInput;
  where: BenefitTransferScalarWhereInput;
};

export type BenefitTransferUpdateManyWithoutProfitableNestedInput = {
  connect?: InputMaybe<Array<BenefitTransferWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<BenefitTransferCreateOrConnectWithoutProfitableInput>>;
  create?: InputMaybe<Array<BenefitTransferCreateWithoutProfitableInput>>;
  createMany?: InputMaybe<BenefitTransferCreateManyProfitableInputEnvelope>;
  delete?: InputMaybe<Array<BenefitTransferWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<BenefitTransferScalarWhereInput>>;
  disconnect?: InputMaybe<Array<BenefitTransferWhereUniqueInput>>;
  set?: InputMaybe<Array<BenefitTransferWhereUniqueInput>>;
  update?: InputMaybe<Array<BenefitTransferUpdateWithWhereUniqueWithoutProfitableInput>>;
  updateMany?: InputMaybe<Array<BenefitTransferUpdateManyWithWhereWithoutProfitableInput>>;
  upsert?: InputMaybe<Array<BenefitTransferUpsertWithWhereUniqueWithoutProfitableInput>>;
};

export type BenefitTransferUpdateOneWithoutBenifitMemoNestedInput = {
  connect?: InputMaybe<BenefitTransferWhereUniqueInput>;
  connectOrCreate?: InputMaybe<BenefitTransferCreateOrConnectWithoutBenifitMemoInput>;
  create?: InputMaybe<BenefitTransferCreateWithoutBenifitMemoInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<BenefitTransferUpdateWithoutBenifitMemoInput>;
  upsert?: InputMaybe<BenefitTransferUpsertWithoutBenifitMemoInput>;
};

export type BenefitTransferUpdateWithWhereUniqueWithoutProfitableInput = {
  data: BenefitTransferUpdateWithoutProfitableInput;
  where: BenefitTransferWhereUniqueInput;
};

export type BenefitTransferUpdateWithoutBenifitMemoInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  exchangeRateForCoin?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  profitable?: InputMaybe<ProfitableUpdateOneRequiredWithoutBenefitTransfersNestedInput>;
  totalAmount?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  transferDate?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  transferMemo?: InputMaybe<Scalars['JSON']>;
};

export type BenefitTransferUpdateWithoutProfitableInput = {
  benifitMemo?: InputMaybe<BenefitMemoUpdateManyWithoutTransferNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  exchangeRateForCoin?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  totalAmount?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  transferDate?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  transferMemo?: InputMaybe<Scalars['JSON']>;
};

export type BenefitTransferUpsertWithWhereUniqueWithoutProfitableInput = {
  create: BenefitTransferCreateWithoutProfitableInput;
  update: BenefitTransferUpdateWithoutProfitableInput;
  where: BenefitTransferWhereUniqueInput;
};

export type BenefitTransferUpsertWithoutBenifitMemoInput = {
  create: BenefitTransferCreateWithoutBenifitMemoInput;
  update: BenefitTransferUpdateWithoutBenifitMemoInput;
};

export type BenefitTransferWhereInput = {
  AND?: InputMaybe<Array<BenefitTransferWhereInput>>;
  NOT?: InputMaybe<Array<BenefitTransferWhereInput>>;
  OR?: InputMaybe<Array<BenefitTransferWhereInput>>;
  benifitMemo?: InputMaybe<BenefitMemoListRelationFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  exchangeRateForCoin?: InputMaybe<FloatNullableFilter>;
  id?: InputMaybe<StringFilter>;
  profitable?: InputMaybe<ProfitableRelationFilter>;
  profitableId?: InputMaybe<StringFilter>;
  totalAmount?: InputMaybe<FloatNullableFilter>;
  transferDate?: InputMaybe<StringNullableFilter>;
  transferMemo?: InputMaybe<JsonNullableFilter>;
};

export type BenefitTransferWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
};

export enum BenefitType {
  MemoToCoin = 'memoToCoin',
  MemoToOrder = 'memoToOrder'
}

export type BoolFieldUpdateOperationsInput = {
  set?: InputMaybe<Scalars['Boolean']>;
};

export type BoolFilter = {
  equals?: InputMaybe<Scalars['Boolean']>;
  not?: InputMaybe<NestedBoolFilter>;
};

export type BoolNullableFilter = {
  equals?: InputMaybe<Scalars['Boolean']>;
  not?: InputMaybe<NestedBoolNullableFilter>;
};

export type BoolNullableWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntNullableFilter>;
  _max?: InputMaybe<NestedBoolNullableFilter>;
  _min?: InputMaybe<NestedBoolNullableFilter>;
  equals?: InputMaybe<Scalars['Boolean']>;
  not?: InputMaybe<NestedBoolNullableWithAggregatesFilter>;
};

export type BoolWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedBoolFilter>;
  _min?: InputMaybe<NestedBoolFilter>;
  equals?: InputMaybe<Scalars['Boolean']>;
  not?: InputMaybe<NestedBoolWithAggregatesFilter>;
};

export type CampaignNanhui = {
  __typename?: 'CampaignNanhui';
  address: Scalars['String'];
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  name: Scalars['String'];
  phone: Scalars['String'];
  postCode: Scalars['String'];
  submitter?: Maybe<User>;
  submitterId?: Maybe<Scalars['String']>;
  year: Scalars['String'];
};

export type CampaignNanhuiCountAggregate = {
  __typename?: 'CampaignNanhuiCountAggregate';
  _all: Scalars['Int'];
  address: Scalars['Int'];
  createdAt: Scalars['Int'];
  id: Scalars['Int'];
  name: Scalars['Int'];
  phone: Scalars['Int'];
  postCode: Scalars['Int'];
  submitterId: Scalars['Int'];
  year: Scalars['Int'];
};

export type CampaignNanhuiCountOrderByAggregateInput = {
  address?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  phone?: InputMaybe<SortOrder>;
  postCode?: InputMaybe<SortOrder>;
  submitterId?: InputMaybe<SortOrder>;
  year?: InputMaybe<SortOrder>;
};

export type CampaignNanhuiCreateInput = {
  address: Scalars['String'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  phone: Scalars['String'];
  postCode: Scalars['String'];
  submitter?: InputMaybe<UserCreateNestedOneWithoutCampaignNanhuiInput>;
  year: Scalars['String'];
};

export type CampaignNanhuiCreateManyInput = {
  address: Scalars['String'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  phone: Scalars['String'];
  postCode: Scalars['String'];
  submitterId?: InputMaybe<Scalars['String']>;
  year: Scalars['String'];
};

export type CampaignNanhuiCreateManySubmitterInput = {
  address: Scalars['String'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  phone: Scalars['String'];
  postCode: Scalars['String'];
  year: Scalars['String'];
};

export type CampaignNanhuiCreateManySubmitterInputEnvelope = {
  data: Array<CampaignNanhuiCreateManySubmitterInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type CampaignNanhuiCreateNestedManyWithoutSubmitterInput = {
  connect?: InputMaybe<Array<CampaignNanhuiWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CampaignNanhuiCreateOrConnectWithoutSubmitterInput>>;
  create?: InputMaybe<Array<CampaignNanhuiCreateWithoutSubmitterInput>>;
  createMany?: InputMaybe<CampaignNanhuiCreateManySubmitterInputEnvelope>;
};

export type CampaignNanhuiCreateOrConnectWithoutSubmitterInput = {
  create: CampaignNanhuiCreateWithoutSubmitterInput;
  where: CampaignNanhuiWhereUniqueInput;
};

export type CampaignNanhuiCreateWithoutSubmitterInput = {
  address: Scalars['String'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  phone: Scalars['String'];
  postCode: Scalars['String'];
  year: Scalars['String'];
};

export type CampaignNanhuiGroupBy = {
  __typename?: 'CampaignNanhuiGroupBy';
  _count?: Maybe<CampaignNanhuiCountAggregate>;
  _max?: Maybe<CampaignNanhuiMaxAggregate>;
  _min?: Maybe<CampaignNanhuiMinAggregate>;
  address: Scalars['String'];
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  name: Scalars['String'];
  phone: Scalars['String'];
  postCode: Scalars['String'];
  submitterId?: Maybe<Scalars['String']>;
  year: Scalars['String'];
};

export type CampaignNanhuiListRelationFilter = {
  every?: InputMaybe<CampaignNanhuiWhereInput>;
  none?: InputMaybe<CampaignNanhuiWhereInput>;
  some?: InputMaybe<CampaignNanhuiWhereInput>;
};

export type CampaignNanhuiMaxAggregate = {
  __typename?: 'CampaignNanhuiMaxAggregate';
  address?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  postCode?: Maybe<Scalars['String']>;
  submitterId?: Maybe<Scalars['String']>;
  year?: Maybe<Scalars['String']>;
};

export type CampaignNanhuiMaxOrderByAggregateInput = {
  address?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  phone?: InputMaybe<SortOrder>;
  postCode?: InputMaybe<SortOrder>;
  submitterId?: InputMaybe<SortOrder>;
  year?: InputMaybe<SortOrder>;
};

export type CampaignNanhuiMinAggregate = {
  __typename?: 'CampaignNanhuiMinAggregate';
  address?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  postCode?: Maybe<Scalars['String']>;
  submitterId?: Maybe<Scalars['String']>;
  year?: Maybe<Scalars['String']>;
};

export type CampaignNanhuiMinOrderByAggregateInput = {
  address?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  phone?: InputMaybe<SortOrder>;
  postCode?: InputMaybe<SortOrder>;
  submitterId?: InputMaybe<SortOrder>;
  year?: InputMaybe<SortOrder>;
};

export type CampaignNanhuiOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type CampaignNanhuiOrderByWithAggregationInput = {
  _count?: InputMaybe<CampaignNanhuiCountOrderByAggregateInput>;
  _max?: InputMaybe<CampaignNanhuiMaxOrderByAggregateInput>;
  _min?: InputMaybe<CampaignNanhuiMinOrderByAggregateInput>;
  address?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  phone?: InputMaybe<SortOrder>;
  postCode?: InputMaybe<SortOrder>;
  submitterId?: InputMaybe<SortOrder>;
  year?: InputMaybe<SortOrder>;
};

export type CampaignNanhuiOrderByWithRelationInput = {
  address?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  phone?: InputMaybe<SortOrder>;
  postCode?: InputMaybe<SortOrder>;
  submitter?: InputMaybe<UserOrderByWithRelationInput>;
  submitterId?: InputMaybe<SortOrder>;
  year?: InputMaybe<SortOrder>;
};

export enum CampaignNanhuiScalarFieldEnum {
  Address = 'address',
  CreatedAt = 'createdAt',
  Id = 'id',
  Name = 'name',
  Phone = 'phone',
  PostCode = 'postCode',
  SubmitterId = 'submitterId',
  Year = 'year'
}

export type CampaignNanhuiScalarWhereInput = {
  AND?: InputMaybe<Array<CampaignNanhuiScalarWhereInput>>;
  NOT?: InputMaybe<Array<CampaignNanhuiScalarWhereInput>>;
  OR?: InputMaybe<Array<CampaignNanhuiScalarWhereInput>>;
  address?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringFilter>;
  phone?: InputMaybe<StringFilter>;
  postCode?: InputMaybe<StringFilter>;
  submitterId?: InputMaybe<StringNullableFilter>;
  year?: InputMaybe<StringFilter>;
};

export type CampaignNanhuiScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<CampaignNanhuiScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<CampaignNanhuiScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<CampaignNanhuiScalarWhereWithAggregatesInput>>;
  address?: InputMaybe<StringWithAggregatesFilter>;
  createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  id?: InputMaybe<StringWithAggregatesFilter>;
  name?: InputMaybe<StringWithAggregatesFilter>;
  phone?: InputMaybe<StringWithAggregatesFilter>;
  postCode?: InputMaybe<StringWithAggregatesFilter>;
  submitterId?: InputMaybe<StringNullableWithAggregatesFilter>;
  year?: InputMaybe<StringWithAggregatesFilter>;
};

export type CampaignNanhuiUpdateInput = {
  address?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  phone?: InputMaybe<StringFieldUpdateOperationsInput>;
  postCode?: InputMaybe<StringFieldUpdateOperationsInput>;
  submitter?: InputMaybe<UserUpdateOneWithoutCampaignNanhuiNestedInput>;
  year?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type CampaignNanhuiUpdateManyMutationInput = {
  address?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  phone?: InputMaybe<StringFieldUpdateOperationsInput>;
  postCode?: InputMaybe<StringFieldUpdateOperationsInput>;
  year?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type CampaignNanhuiUpdateManyWithWhereWithoutSubmitterInput = {
  data: CampaignNanhuiUpdateManyMutationInput;
  where: CampaignNanhuiScalarWhereInput;
};

export type CampaignNanhuiUpdateManyWithoutSubmitterNestedInput = {
  connect?: InputMaybe<Array<CampaignNanhuiWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CampaignNanhuiCreateOrConnectWithoutSubmitterInput>>;
  create?: InputMaybe<Array<CampaignNanhuiCreateWithoutSubmitterInput>>;
  createMany?: InputMaybe<CampaignNanhuiCreateManySubmitterInputEnvelope>;
  delete?: InputMaybe<Array<CampaignNanhuiWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<CampaignNanhuiScalarWhereInput>>;
  disconnect?: InputMaybe<Array<CampaignNanhuiWhereUniqueInput>>;
  set?: InputMaybe<Array<CampaignNanhuiWhereUniqueInput>>;
  update?: InputMaybe<Array<CampaignNanhuiUpdateWithWhereUniqueWithoutSubmitterInput>>;
  updateMany?: InputMaybe<Array<CampaignNanhuiUpdateManyWithWhereWithoutSubmitterInput>>;
  upsert?: InputMaybe<Array<CampaignNanhuiUpsertWithWhereUniqueWithoutSubmitterInput>>;
};

export type CampaignNanhuiUpdateWithWhereUniqueWithoutSubmitterInput = {
  data: CampaignNanhuiUpdateWithoutSubmitterInput;
  where: CampaignNanhuiWhereUniqueInput;
};

export type CampaignNanhuiUpdateWithoutSubmitterInput = {
  address?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  phone?: InputMaybe<StringFieldUpdateOperationsInput>;
  postCode?: InputMaybe<StringFieldUpdateOperationsInput>;
  year?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type CampaignNanhuiUpsertWithWhereUniqueWithoutSubmitterInput = {
  create: CampaignNanhuiCreateWithoutSubmitterInput;
  update: CampaignNanhuiUpdateWithoutSubmitterInput;
  where: CampaignNanhuiWhereUniqueInput;
};

export type CampaignNanhuiWhereInput = {
  AND?: InputMaybe<Array<CampaignNanhuiWhereInput>>;
  NOT?: InputMaybe<Array<CampaignNanhuiWhereInput>>;
  OR?: InputMaybe<Array<CampaignNanhuiWhereInput>>;
  address?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringFilter>;
  phone?: InputMaybe<StringFilter>;
  postCode?: InputMaybe<StringFilter>;
  submitter?: InputMaybe<UserRelationFilter>;
  submitterId?: InputMaybe<StringNullableFilter>;
  year?: InputMaybe<StringFilter>;
};

export type CampaignNanhuiWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
};

export type CampainZeczec = {
  __typename?: 'CampainZeczec';
  id: Scalars['String'];
  idStrs: Array<Scalars['String']>;
  registerUser?: Maybe<User>;
  registerUserId?: Maybe<Scalars['String']>;
  sponsorEmail: Scalars['String'];
  virtualAddr: VirtualAddrMapping;
  virtualLandId: Scalars['String'];
  virtualNo: Scalars['String'];
};

export type CampainZeczecCountAggregate = {
  __typename?: 'CampainZeczecCountAggregate';
  _all: Scalars['Int'];
  id: Scalars['Int'];
  idStrs: Scalars['Int'];
  registerUserId: Scalars['Int'];
  sponsorEmail: Scalars['Int'];
  virtualLandId: Scalars['Int'];
  virtualNo: Scalars['Int'];
};

export type CampainZeczecCountOrderByAggregateInput = {
  id?: InputMaybe<SortOrder>;
  idStrs?: InputMaybe<SortOrder>;
  registerUserId?: InputMaybe<SortOrder>;
  sponsorEmail?: InputMaybe<SortOrder>;
  virtualLandId?: InputMaybe<SortOrder>;
  virtualNo?: InputMaybe<SortOrder>;
};

export type CampainZeczecCreateInput = {
  id?: InputMaybe<Scalars['String']>;
  idStrs?: InputMaybe<CampainZeczecCreateidStrsInput>;
  registerUser?: InputMaybe<UserCreateNestedOneWithoutCampainZeczecInput>;
  sponsorEmail: Scalars['String'];
  virtualAddr: VirtualAddrMappingCreateNestedOneWithoutBelongZeczecInput;
};

export type CampainZeczecCreateManyInput = {
  id?: InputMaybe<Scalars['String']>;
  idStrs?: InputMaybe<CampainZeczecCreateidStrsInput>;
  registerUserId?: InputMaybe<Scalars['String']>;
  sponsorEmail: Scalars['String'];
  virtualLandId: Scalars['String'];
  virtualNo: Scalars['String'];
};

export type CampainZeczecCreateNestedOneWithoutRegisterUserInput = {
  connect?: InputMaybe<CampainZeczecWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CampainZeczecCreateOrConnectWithoutRegisterUserInput>;
  create?: InputMaybe<CampainZeczecCreateWithoutRegisterUserInput>;
};

export type CampainZeczecCreateNestedOneWithoutVirtualAddrInput = {
  connect?: InputMaybe<CampainZeczecWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CampainZeczecCreateOrConnectWithoutVirtualAddrInput>;
  create?: InputMaybe<CampainZeczecCreateWithoutVirtualAddrInput>;
};

export type CampainZeczecCreateOrConnectWithoutRegisterUserInput = {
  create: CampainZeczecCreateWithoutRegisterUserInput;
  where: CampainZeczecWhereUniqueInput;
};

export type CampainZeczecCreateOrConnectWithoutVirtualAddrInput = {
  create: CampainZeczecCreateWithoutVirtualAddrInput;
  where: CampainZeczecWhereUniqueInput;
};

export type CampainZeczecCreateWithoutRegisterUserInput = {
  id?: InputMaybe<Scalars['String']>;
  idStrs?: InputMaybe<CampainZeczecCreateidStrsInput>;
  sponsorEmail: Scalars['String'];
  virtualAddr: VirtualAddrMappingCreateNestedOneWithoutBelongZeczecInput;
};

export type CampainZeczecCreateWithoutVirtualAddrInput = {
  id?: InputMaybe<Scalars['String']>;
  idStrs?: InputMaybe<CampainZeczecCreateidStrsInput>;
  registerUser?: InputMaybe<UserCreateNestedOneWithoutCampainZeczecInput>;
  sponsorEmail: Scalars['String'];
};

export type CampainZeczecCreateidStrsInput = {
  set: Array<Scalars['String']>;
};

export type CampainZeczecGroupBy = {
  __typename?: 'CampainZeczecGroupBy';
  _count?: Maybe<CampainZeczecCountAggregate>;
  _max?: Maybe<CampainZeczecMaxAggregate>;
  _min?: Maybe<CampainZeczecMinAggregate>;
  id: Scalars['String'];
  idStrs?: Maybe<Array<Scalars['String']>>;
  registerUserId?: Maybe<Scalars['String']>;
  sponsorEmail: Scalars['String'];
  virtualLandId: Scalars['String'];
  virtualNo: Scalars['String'];
};

export type CampainZeczecMaxAggregate = {
  __typename?: 'CampainZeczecMaxAggregate';
  id?: Maybe<Scalars['String']>;
  registerUserId?: Maybe<Scalars['String']>;
  sponsorEmail?: Maybe<Scalars['String']>;
  virtualLandId?: Maybe<Scalars['String']>;
  virtualNo?: Maybe<Scalars['String']>;
};

export type CampainZeczecMaxOrderByAggregateInput = {
  id?: InputMaybe<SortOrder>;
  registerUserId?: InputMaybe<SortOrder>;
  sponsorEmail?: InputMaybe<SortOrder>;
  virtualLandId?: InputMaybe<SortOrder>;
  virtualNo?: InputMaybe<SortOrder>;
};

export type CampainZeczecMinAggregate = {
  __typename?: 'CampainZeczecMinAggregate';
  id?: Maybe<Scalars['String']>;
  registerUserId?: Maybe<Scalars['String']>;
  sponsorEmail?: Maybe<Scalars['String']>;
  virtualLandId?: Maybe<Scalars['String']>;
  virtualNo?: Maybe<Scalars['String']>;
};

export type CampainZeczecMinOrderByAggregateInput = {
  id?: InputMaybe<SortOrder>;
  registerUserId?: InputMaybe<SortOrder>;
  sponsorEmail?: InputMaybe<SortOrder>;
  virtualLandId?: InputMaybe<SortOrder>;
  virtualNo?: InputMaybe<SortOrder>;
};

export type CampainZeczecOrderByWithAggregationInput = {
  _count?: InputMaybe<CampainZeczecCountOrderByAggregateInput>;
  _max?: InputMaybe<CampainZeczecMaxOrderByAggregateInput>;
  _min?: InputMaybe<CampainZeczecMinOrderByAggregateInput>;
  id?: InputMaybe<SortOrder>;
  idStrs?: InputMaybe<SortOrder>;
  registerUserId?: InputMaybe<SortOrder>;
  sponsorEmail?: InputMaybe<SortOrder>;
  virtualLandId?: InputMaybe<SortOrder>;
  virtualNo?: InputMaybe<SortOrder>;
};

export type CampainZeczecOrderByWithRelationInput = {
  id?: InputMaybe<SortOrder>;
  idStrs?: InputMaybe<SortOrder>;
  registerUser?: InputMaybe<UserOrderByWithRelationInput>;
  registerUserId?: InputMaybe<SortOrder>;
  sponsorEmail?: InputMaybe<SortOrder>;
  virtualAddr?: InputMaybe<VirtualAddrMappingOrderByWithRelationInput>;
  virtualLandId?: InputMaybe<SortOrder>;
  virtualNo?: InputMaybe<SortOrder>;
};

export type CampainZeczecRelationFilter = {
  is?: InputMaybe<CampainZeczecWhereInput>;
  isNot?: InputMaybe<CampainZeczecWhereInput>;
};

export enum CampainZeczecScalarFieldEnum {
  Id = 'id',
  IdStrs = 'idStrs',
  RegisterUserId = 'registerUserId',
  SponsorEmail = 'sponsorEmail',
  VirtualLandId = 'virtualLandId',
  VirtualNo = 'virtualNo'
}

export type CampainZeczecScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<CampainZeczecScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<CampainZeczecScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<CampainZeczecScalarWhereWithAggregatesInput>>;
  id?: InputMaybe<StringWithAggregatesFilter>;
  idStrs?: InputMaybe<StringNullableListFilter>;
  registerUserId?: InputMaybe<StringNullableWithAggregatesFilter>;
  sponsorEmail?: InputMaybe<StringWithAggregatesFilter>;
  virtualLandId?: InputMaybe<StringWithAggregatesFilter>;
  virtualNo?: InputMaybe<StringWithAggregatesFilter>;
};

export type CampainZeczecUpdateInput = {
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  idStrs?: InputMaybe<CampainZeczecUpdateidStrsInput>;
  registerUser?: InputMaybe<UserUpdateOneWithoutCampainZeczecNestedInput>;
  sponsorEmail?: InputMaybe<StringFieldUpdateOperationsInput>;
  virtualAddr?: InputMaybe<VirtualAddrMappingUpdateOneRequiredWithoutBelongZeczecNestedInput>;
};

export type CampainZeczecUpdateManyMutationInput = {
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  idStrs?: InputMaybe<CampainZeczecUpdateidStrsInput>;
  sponsorEmail?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type CampainZeczecUpdateOneWithoutRegisterUserNestedInput = {
  connect?: InputMaybe<CampainZeczecWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CampainZeczecCreateOrConnectWithoutRegisterUserInput>;
  create?: InputMaybe<CampainZeczecCreateWithoutRegisterUserInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<CampainZeczecUpdateWithoutRegisterUserInput>;
  upsert?: InputMaybe<CampainZeczecUpsertWithoutRegisterUserInput>;
};

export type CampainZeczecUpdateOneWithoutVirtualAddrNestedInput = {
  connect?: InputMaybe<CampainZeczecWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CampainZeczecCreateOrConnectWithoutVirtualAddrInput>;
  create?: InputMaybe<CampainZeczecCreateWithoutVirtualAddrInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<CampainZeczecUpdateWithoutVirtualAddrInput>;
  upsert?: InputMaybe<CampainZeczecUpsertWithoutVirtualAddrInput>;
};

export type CampainZeczecUpdateWithoutRegisterUserInput = {
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  idStrs?: InputMaybe<CampainZeczecUpdateidStrsInput>;
  sponsorEmail?: InputMaybe<StringFieldUpdateOperationsInput>;
  virtualAddr?: InputMaybe<VirtualAddrMappingUpdateOneRequiredWithoutBelongZeczecNestedInput>;
};

export type CampainZeczecUpdateWithoutVirtualAddrInput = {
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  idStrs?: InputMaybe<CampainZeczecUpdateidStrsInput>;
  registerUser?: InputMaybe<UserUpdateOneWithoutCampainZeczecNestedInput>;
  sponsorEmail?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type CampainZeczecUpdateidStrsInput = {
  push?: InputMaybe<Array<Scalars['String']>>;
  set?: InputMaybe<Array<Scalars['String']>>;
};

export type CampainZeczecUpsertWithoutRegisterUserInput = {
  create: CampainZeczecCreateWithoutRegisterUserInput;
  update: CampainZeczecUpdateWithoutRegisterUserInput;
};

export type CampainZeczecUpsertWithoutVirtualAddrInput = {
  create: CampainZeczecCreateWithoutVirtualAddrInput;
  update: CampainZeczecUpdateWithoutVirtualAddrInput;
};

export type CampainZeczecVirtualLandIdVirtualNoCompoundUniqueInput = {
  virtualLandId: Scalars['String'];
  virtualNo: Scalars['String'];
};

export type CampainZeczecWhereInput = {
  AND?: InputMaybe<Array<CampainZeczecWhereInput>>;
  NOT?: InputMaybe<Array<CampainZeczecWhereInput>>;
  OR?: InputMaybe<Array<CampainZeczecWhereInput>>;
  id?: InputMaybe<StringFilter>;
  idStrs?: InputMaybe<StringNullableListFilter>;
  registerUser?: InputMaybe<UserRelationFilter>;
  registerUserId?: InputMaybe<StringNullableFilter>;
  sponsorEmail?: InputMaybe<StringFilter>;
  virtualAddr?: InputMaybe<VirtualAddrMappingRelationFilter>;
  virtualLandId?: InputMaybe<StringFilter>;
  virtualNo?: InputMaybe<StringFilter>;
};

export type CampainZeczecWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
  registerUserId?: InputMaybe<Scalars['String']>;
  sponsorEmail?: InputMaybe<Scalars['String']>;
  virtualLandId_virtualNo?: InputMaybe<CampainZeczecVirtualLandIdVirtualNoCompoundUniqueInput>;
};

export type Card = {
  __typename?: 'Card';
  _count?: Maybe<CardCount>;
  collections: Array<Collection>;
  couponActivity?: Maybe<CouponActivity>;
  createdAt: Scalars['DateTime'];
  creator: User;
  creatorId: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  free: Scalars['Boolean'];
  headUri: Scalars['String'];
  id: Scalars['String'];
  inStoreItem: Array<StoreItem>;
  inValidReasonHistories: Array<InValidReason>;
  name: Scalars['String'];
  orientation: CardOrientation;
  postsUsed: Array<Post>;
  price: Scalars['Float'];
  qrcode?: Maybe<Scalars['String']>;
  scenicSpot?: Maybe<ScenicSpot>;
  scenicSpotId?: Maybe<Scalars['String']>;
  sn: Scalars['String'];
  tailUri: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  valid: Scalars['Boolean'];
};


export type CardCollectionsArgs = {
  cursor?: InputMaybe<CollectionWhereUniqueInput>;
  distinct?: InputMaybe<Array<CollectionScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<CollectionOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CollectionWhereInput>;
};


export type CardInStoreItemArgs = {
  cursor?: InputMaybe<StoreItemWhereUniqueInput>;
  distinct?: InputMaybe<Array<StoreItemScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<StoreItemOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<StoreItemWhereInput>;
};


export type CardInValidReasonHistoriesArgs = {
  cursor?: InputMaybe<InValidReasonWhereUniqueInput>;
  distinct?: InputMaybe<Array<InValidReasonScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<InValidReasonOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<InValidReasonWhereInput>;
};


export type CardPostsUsedArgs = {
  cursor?: InputMaybe<PostWhereUniqueInput>;
  distinct?: InputMaybe<Array<PostScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<PostOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PostWhereInput>;
};

export type CardAvgAggregate = {
  __typename?: 'CardAvgAggregate';
  price?: Maybe<Scalars['Float']>;
};

export type CardAvgOrderByAggregateInput = {
  price?: InputMaybe<SortOrder>;
};

export type CardCount = {
  __typename?: 'CardCount';
  collections: Scalars['Int'];
  inStoreItem: Scalars['Int'];
  inValidReasonHistories: Scalars['Int'];
  postsUsed: Scalars['Int'];
};


export type CardCountCollectionsArgs = {
  where?: InputMaybe<CollectionWhereInput>;
};


export type CardCountInStoreItemArgs = {
  where?: InputMaybe<StoreItemWhereInput>;
};


export type CardCountInValidReasonHistoriesArgs = {
  where?: InputMaybe<InValidReasonWhereInput>;
};


export type CardCountPostsUsedArgs = {
  where?: InputMaybe<PostWhereInput>;
};

export type CardCountAggregate = {
  __typename?: 'CardCountAggregate';
  _all: Scalars['Int'];
  createdAt: Scalars['Int'];
  creatorId: Scalars['Int'];
  description: Scalars['Int'];
  free: Scalars['Int'];
  headUri: Scalars['Int'];
  id: Scalars['Int'];
  name: Scalars['Int'];
  orientation: Scalars['Int'];
  price: Scalars['Int'];
  qrcode: Scalars['Int'];
  scenicSpotId: Scalars['Int'];
  sn: Scalars['Int'];
  tailUri: Scalars['Int'];
  updatedAt: Scalars['Int'];
  valid: Scalars['Int'];
};

export type CardCountOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  creatorId?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  free?: InputMaybe<SortOrder>;
  headUri?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  orientation?: InputMaybe<SortOrder>;
  price?: InputMaybe<SortOrder>;
  qrcode?: InputMaybe<SortOrder>;
  scenicSpotId?: InputMaybe<SortOrder>;
  sn?: InputMaybe<SortOrder>;
  tailUri?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  valid?: InputMaybe<SortOrder>;
};

export type CardCreateInput = {
  collections?: InputMaybe<CollectionCreateNestedManyWithoutPostcardInput>;
  couponActivity?: InputMaybe<CouponActivityCreateNestedOneWithoutCardInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  creator: UserCreateNestedOneWithoutCardInput;
  description?: InputMaybe<Scalars['String']>;
  free?: InputMaybe<Scalars['Boolean']>;
  headUri: Scalars['String'];
  id?: InputMaybe<Scalars['String']>;
  inStoreItem?: InputMaybe<StoreItemCreateNestedManyWithoutItemCardInput>;
  inValidReasonHistories?: InputMaybe<InValidReasonCreateNestedManyWithoutCardInput>;
  name?: InputMaybe<Scalars['String']>;
  orientation?: InputMaybe<CardOrientation>;
  postsUsed?: InputMaybe<PostCreateNestedManyWithoutBodyInput>;
  price?: InputMaybe<Scalars['Float']>;
  qrcode?: InputMaybe<Scalars['String']>;
  scenicSpot?: InputMaybe<ScenicSpotCreateNestedOneWithoutCardsInput>;
  sn?: InputMaybe<Scalars['String']>;
  tailUri: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  valid?: InputMaybe<Scalars['Boolean']>;
};

export type CardCreateManyCreatorInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  free?: InputMaybe<Scalars['Boolean']>;
  headUri: Scalars['String'];
  id?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  orientation?: InputMaybe<CardOrientation>;
  price?: InputMaybe<Scalars['Float']>;
  qrcode?: InputMaybe<Scalars['String']>;
  scenicSpotId?: InputMaybe<Scalars['String']>;
  sn?: InputMaybe<Scalars['String']>;
  tailUri: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  valid?: InputMaybe<Scalars['Boolean']>;
};

export type CardCreateManyCreatorInputEnvelope = {
  data: Array<CardCreateManyCreatorInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type CardCreateManyInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  creatorId: Scalars['String'];
  description?: InputMaybe<Scalars['String']>;
  free?: InputMaybe<Scalars['Boolean']>;
  headUri: Scalars['String'];
  id?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  orientation?: InputMaybe<CardOrientation>;
  price?: InputMaybe<Scalars['Float']>;
  qrcode?: InputMaybe<Scalars['String']>;
  scenicSpotId?: InputMaybe<Scalars['String']>;
  sn?: InputMaybe<Scalars['String']>;
  tailUri: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  valid?: InputMaybe<Scalars['Boolean']>;
};

export type CardCreateManyScenicSpotInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  creatorId: Scalars['String'];
  description?: InputMaybe<Scalars['String']>;
  free?: InputMaybe<Scalars['Boolean']>;
  headUri: Scalars['String'];
  id?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  orientation?: InputMaybe<CardOrientation>;
  price?: InputMaybe<Scalars['Float']>;
  qrcode?: InputMaybe<Scalars['String']>;
  sn?: InputMaybe<Scalars['String']>;
  tailUri: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  valid?: InputMaybe<Scalars['Boolean']>;
};

export type CardCreateManyScenicSpotInputEnvelope = {
  data: Array<CardCreateManyScenicSpotInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type CardCreateNestedManyWithoutCreatorInput = {
  connect?: InputMaybe<Array<CardWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CardCreateOrConnectWithoutCreatorInput>>;
  create?: InputMaybe<Array<CardCreateWithoutCreatorInput>>;
  createMany?: InputMaybe<CardCreateManyCreatorInputEnvelope>;
};

export type CardCreateNestedManyWithoutScenicSpotInput = {
  connect?: InputMaybe<Array<CardWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CardCreateOrConnectWithoutScenicSpotInput>>;
  create?: InputMaybe<Array<CardCreateWithoutScenicSpotInput>>;
  createMany?: InputMaybe<CardCreateManyScenicSpotInputEnvelope>;
};

export type CardCreateNestedOneWithoutCollectionsInput = {
  connect?: InputMaybe<CardWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CardCreateOrConnectWithoutCollectionsInput>;
  create?: InputMaybe<CardCreateWithoutCollectionsInput>;
};

export type CardCreateNestedOneWithoutCouponActivityInput = {
  connect?: InputMaybe<CardWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CardCreateOrConnectWithoutCouponActivityInput>;
  create?: InputMaybe<CardCreateWithoutCouponActivityInput>;
};

export type CardCreateNestedOneWithoutInStoreItemInput = {
  connect?: InputMaybe<CardWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CardCreateOrConnectWithoutInStoreItemInput>;
  create?: InputMaybe<CardCreateWithoutInStoreItemInput>;
};

export type CardCreateNestedOneWithoutInValidReasonHistoriesInput = {
  connect?: InputMaybe<CardWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CardCreateOrConnectWithoutInValidReasonHistoriesInput>;
  create?: InputMaybe<CardCreateWithoutInValidReasonHistoriesInput>;
};

export type CardCreateNestedOneWithoutPostsUsedInput = {
  connect?: InputMaybe<CardWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CardCreateOrConnectWithoutPostsUsedInput>;
  create?: InputMaybe<CardCreateWithoutPostsUsedInput>;
};

export type CardCreateOrConnectWithoutCollectionsInput = {
  create: CardCreateWithoutCollectionsInput;
  where: CardWhereUniqueInput;
};

export type CardCreateOrConnectWithoutCouponActivityInput = {
  create: CardCreateWithoutCouponActivityInput;
  where: CardWhereUniqueInput;
};

export type CardCreateOrConnectWithoutCreatorInput = {
  create: CardCreateWithoutCreatorInput;
  where: CardWhereUniqueInput;
};

export type CardCreateOrConnectWithoutInStoreItemInput = {
  create: CardCreateWithoutInStoreItemInput;
  where: CardWhereUniqueInput;
};

export type CardCreateOrConnectWithoutInValidReasonHistoriesInput = {
  create: CardCreateWithoutInValidReasonHistoriesInput;
  where: CardWhereUniqueInput;
};

export type CardCreateOrConnectWithoutPostsUsedInput = {
  create: CardCreateWithoutPostsUsedInput;
  where: CardWhereUniqueInput;
};

export type CardCreateOrConnectWithoutScenicSpotInput = {
  create: CardCreateWithoutScenicSpotInput;
  where: CardWhereUniqueInput;
};

export type CardCreateWithoutCollectionsInput = {
  couponActivity?: InputMaybe<CouponActivityCreateNestedOneWithoutCardInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  creator: UserCreateNestedOneWithoutCardInput;
  description?: InputMaybe<Scalars['String']>;
  free?: InputMaybe<Scalars['Boolean']>;
  headUri: Scalars['String'];
  id?: InputMaybe<Scalars['String']>;
  inStoreItem?: InputMaybe<StoreItemCreateNestedManyWithoutItemCardInput>;
  inValidReasonHistories?: InputMaybe<InValidReasonCreateNestedManyWithoutCardInput>;
  name?: InputMaybe<Scalars['String']>;
  orientation?: InputMaybe<CardOrientation>;
  postsUsed?: InputMaybe<PostCreateNestedManyWithoutBodyInput>;
  price?: InputMaybe<Scalars['Float']>;
  qrcode?: InputMaybe<Scalars['String']>;
  scenicSpot?: InputMaybe<ScenicSpotCreateNestedOneWithoutCardsInput>;
  sn?: InputMaybe<Scalars['String']>;
  tailUri: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  valid?: InputMaybe<Scalars['Boolean']>;
};

export type CardCreateWithoutCouponActivityInput = {
  collections?: InputMaybe<CollectionCreateNestedManyWithoutPostcardInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  creator: UserCreateNestedOneWithoutCardInput;
  description?: InputMaybe<Scalars['String']>;
  free?: InputMaybe<Scalars['Boolean']>;
  headUri: Scalars['String'];
  id?: InputMaybe<Scalars['String']>;
  inStoreItem?: InputMaybe<StoreItemCreateNestedManyWithoutItemCardInput>;
  inValidReasonHistories?: InputMaybe<InValidReasonCreateNestedManyWithoutCardInput>;
  name?: InputMaybe<Scalars['String']>;
  orientation?: InputMaybe<CardOrientation>;
  postsUsed?: InputMaybe<PostCreateNestedManyWithoutBodyInput>;
  price?: InputMaybe<Scalars['Float']>;
  qrcode?: InputMaybe<Scalars['String']>;
  scenicSpot?: InputMaybe<ScenicSpotCreateNestedOneWithoutCardsInput>;
  sn?: InputMaybe<Scalars['String']>;
  tailUri: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  valid?: InputMaybe<Scalars['Boolean']>;
};

export type CardCreateWithoutCreatorInput = {
  collections?: InputMaybe<CollectionCreateNestedManyWithoutPostcardInput>;
  couponActivity?: InputMaybe<CouponActivityCreateNestedOneWithoutCardInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  free?: InputMaybe<Scalars['Boolean']>;
  headUri: Scalars['String'];
  id?: InputMaybe<Scalars['String']>;
  inStoreItem?: InputMaybe<StoreItemCreateNestedManyWithoutItemCardInput>;
  inValidReasonHistories?: InputMaybe<InValidReasonCreateNestedManyWithoutCardInput>;
  name?: InputMaybe<Scalars['String']>;
  orientation?: InputMaybe<CardOrientation>;
  postsUsed?: InputMaybe<PostCreateNestedManyWithoutBodyInput>;
  price?: InputMaybe<Scalars['Float']>;
  qrcode?: InputMaybe<Scalars['String']>;
  scenicSpot?: InputMaybe<ScenicSpotCreateNestedOneWithoutCardsInput>;
  sn?: InputMaybe<Scalars['String']>;
  tailUri: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  valid?: InputMaybe<Scalars['Boolean']>;
};

export type CardCreateWithoutInStoreItemInput = {
  collections?: InputMaybe<CollectionCreateNestedManyWithoutPostcardInput>;
  couponActivity?: InputMaybe<CouponActivityCreateNestedOneWithoutCardInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  creator: UserCreateNestedOneWithoutCardInput;
  description?: InputMaybe<Scalars['String']>;
  free?: InputMaybe<Scalars['Boolean']>;
  headUri: Scalars['String'];
  id?: InputMaybe<Scalars['String']>;
  inValidReasonHistories?: InputMaybe<InValidReasonCreateNestedManyWithoutCardInput>;
  name?: InputMaybe<Scalars['String']>;
  orientation?: InputMaybe<CardOrientation>;
  postsUsed?: InputMaybe<PostCreateNestedManyWithoutBodyInput>;
  price?: InputMaybe<Scalars['Float']>;
  qrcode?: InputMaybe<Scalars['String']>;
  scenicSpot?: InputMaybe<ScenicSpotCreateNestedOneWithoutCardsInput>;
  sn?: InputMaybe<Scalars['String']>;
  tailUri: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  valid?: InputMaybe<Scalars['Boolean']>;
};

export type CardCreateWithoutInValidReasonHistoriesInput = {
  collections?: InputMaybe<CollectionCreateNestedManyWithoutPostcardInput>;
  couponActivity?: InputMaybe<CouponActivityCreateNestedOneWithoutCardInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  creator: UserCreateNestedOneWithoutCardInput;
  description?: InputMaybe<Scalars['String']>;
  free?: InputMaybe<Scalars['Boolean']>;
  headUri: Scalars['String'];
  id?: InputMaybe<Scalars['String']>;
  inStoreItem?: InputMaybe<StoreItemCreateNestedManyWithoutItemCardInput>;
  name?: InputMaybe<Scalars['String']>;
  orientation?: InputMaybe<CardOrientation>;
  postsUsed?: InputMaybe<PostCreateNestedManyWithoutBodyInput>;
  price?: InputMaybe<Scalars['Float']>;
  qrcode?: InputMaybe<Scalars['String']>;
  scenicSpot?: InputMaybe<ScenicSpotCreateNestedOneWithoutCardsInput>;
  sn?: InputMaybe<Scalars['String']>;
  tailUri: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  valid?: InputMaybe<Scalars['Boolean']>;
};

export type CardCreateWithoutPostsUsedInput = {
  collections?: InputMaybe<CollectionCreateNestedManyWithoutPostcardInput>;
  couponActivity?: InputMaybe<CouponActivityCreateNestedOneWithoutCardInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  creator: UserCreateNestedOneWithoutCardInput;
  description?: InputMaybe<Scalars['String']>;
  free?: InputMaybe<Scalars['Boolean']>;
  headUri: Scalars['String'];
  id?: InputMaybe<Scalars['String']>;
  inStoreItem?: InputMaybe<StoreItemCreateNestedManyWithoutItemCardInput>;
  inValidReasonHistories?: InputMaybe<InValidReasonCreateNestedManyWithoutCardInput>;
  name?: InputMaybe<Scalars['String']>;
  orientation?: InputMaybe<CardOrientation>;
  price?: InputMaybe<Scalars['Float']>;
  qrcode?: InputMaybe<Scalars['String']>;
  scenicSpot?: InputMaybe<ScenicSpotCreateNestedOneWithoutCardsInput>;
  sn?: InputMaybe<Scalars['String']>;
  tailUri: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  valid?: InputMaybe<Scalars['Boolean']>;
};

export type CardCreateWithoutScenicSpotInput = {
  collections?: InputMaybe<CollectionCreateNestedManyWithoutPostcardInput>;
  couponActivity?: InputMaybe<CouponActivityCreateNestedOneWithoutCardInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  creator: UserCreateNestedOneWithoutCardInput;
  description?: InputMaybe<Scalars['String']>;
  free?: InputMaybe<Scalars['Boolean']>;
  headUri: Scalars['String'];
  id?: InputMaybe<Scalars['String']>;
  inStoreItem?: InputMaybe<StoreItemCreateNestedManyWithoutItemCardInput>;
  inValidReasonHistories?: InputMaybe<InValidReasonCreateNestedManyWithoutCardInput>;
  name?: InputMaybe<Scalars['String']>;
  orientation?: InputMaybe<CardOrientation>;
  postsUsed?: InputMaybe<PostCreateNestedManyWithoutBodyInput>;
  price?: InputMaybe<Scalars['Float']>;
  qrcode?: InputMaybe<Scalars['String']>;
  sn?: InputMaybe<Scalars['String']>;
  tailUri: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  valid?: InputMaybe<Scalars['Boolean']>;
};

export type CardGroupBy = {
  __typename?: 'CardGroupBy';
  _avg?: Maybe<CardAvgAggregate>;
  _count?: Maybe<CardCountAggregate>;
  _max?: Maybe<CardMaxAggregate>;
  _min?: Maybe<CardMinAggregate>;
  _sum?: Maybe<CardSumAggregate>;
  createdAt: Scalars['DateTime'];
  creatorId: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  free: Scalars['Boolean'];
  headUri: Scalars['String'];
  id: Scalars['String'];
  name: Scalars['String'];
  orientation: CardOrientation;
  price: Scalars['Float'];
  qrcode?: Maybe<Scalars['String']>;
  scenicSpotId?: Maybe<Scalars['String']>;
  sn: Scalars['String'];
  tailUri: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  valid: Scalars['Boolean'];
};

export type CardListRelationFilter = {
  every?: InputMaybe<CardWhereInput>;
  none?: InputMaybe<CardWhereInput>;
  some?: InputMaybe<CardWhereInput>;
};

export type CardMaxAggregate = {
  __typename?: 'CardMaxAggregate';
  createdAt?: Maybe<Scalars['DateTime']>;
  creatorId?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  free?: Maybe<Scalars['Boolean']>;
  headUri?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  orientation?: Maybe<CardOrientation>;
  price?: Maybe<Scalars['Float']>;
  qrcode?: Maybe<Scalars['String']>;
  scenicSpotId?: Maybe<Scalars['String']>;
  sn?: Maybe<Scalars['String']>;
  tailUri?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  valid?: Maybe<Scalars['Boolean']>;
};

export type CardMaxOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  creatorId?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  free?: InputMaybe<SortOrder>;
  headUri?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  orientation?: InputMaybe<SortOrder>;
  price?: InputMaybe<SortOrder>;
  qrcode?: InputMaybe<SortOrder>;
  scenicSpotId?: InputMaybe<SortOrder>;
  sn?: InputMaybe<SortOrder>;
  tailUri?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  valid?: InputMaybe<SortOrder>;
};

export type CardMinAggregate = {
  __typename?: 'CardMinAggregate';
  createdAt?: Maybe<Scalars['DateTime']>;
  creatorId?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  free?: Maybe<Scalars['Boolean']>;
  headUri?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  orientation?: Maybe<CardOrientation>;
  price?: Maybe<Scalars['Float']>;
  qrcode?: Maybe<Scalars['String']>;
  scenicSpotId?: Maybe<Scalars['String']>;
  sn?: Maybe<Scalars['String']>;
  tailUri?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  valid?: Maybe<Scalars['Boolean']>;
};

export type CardMinOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  creatorId?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  free?: InputMaybe<SortOrder>;
  headUri?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  orientation?: InputMaybe<SortOrder>;
  price?: InputMaybe<SortOrder>;
  qrcode?: InputMaybe<SortOrder>;
  scenicSpotId?: InputMaybe<SortOrder>;
  sn?: InputMaybe<SortOrder>;
  tailUri?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  valid?: InputMaybe<SortOrder>;
};

export type CardOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type CardOrderByWithAggregationInput = {
  _avg?: InputMaybe<CardAvgOrderByAggregateInput>;
  _count?: InputMaybe<CardCountOrderByAggregateInput>;
  _max?: InputMaybe<CardMaxOrderByAggregateInput>;
  _min?: InputMaybe<CardMinOrderByAggregateInput>;
  _sum?: InputMaybe<CardSumOrderByAggregateInput>;
  createdAt?: InputMaybe<SortOrder>;
  creatorId?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  free?: InputMaybe<SortOrder>;
  headUri?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  orientation?: InputMaybe<SortOrder>;
  price?: InputMaybe<SortOrder>;
  qrcode?: InputMaybe<SortOrder>;
  scenicSpotId?: InputMaybe<SortOrder>;
  sn?: InputMaybe<SortOrder>;
  tailUri?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  valid?: InputMaybe<SortOrder>;
};

export type CardOrderByWithRelationInput = {
  collections?: InputMaybe<CollectionOrderByRelationAggregateInput>;
  couponActivity?: InputMaybe<CouponActivityOrderByWithRelationInput>;
  createdAt?: InputMaybe<SortOrder>;
  creator?: InputMaybe<UserOrderByWithRelationInput>;
  creatorId?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  free?: InputMaybe<SortOrder>;
  headUri?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  inStoreItem?: InputMaybe<StoreItemOrderByRelationAggregateInput>;
  inValidReasonHistories?: InputMaybe<InValidReasonOrderByRelationAggregateInput>;
  name?: InputMaybe<SortOrder>;
  orientation?: InputMaybe<SortOrder>;
  postsUsed?: InputMaybe<PostOrderByRelationAggregateInput>;
  price?: InputMaybe<SortOrder>;
  qrcode?: InputMaybe<SortOrder>;
  scenicSpot?: InputMaybe<ScenicSpotOrderByWithRelationInput>;
  scenicSpotId?: InputMaybe<SortOrder>;
  sn?: InputMaybe<SortOrder>;
  tailUri?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  valid?: InputMaybe<SortOrder>;
};

export enum CardOrientation {
  Horizontal = 'Horizontal',
  Vertical = 'Vertical'
}

export type CardRelationFilter = {
  is?: InputMaybe<CardWhereInput>;
  isNot?: InputMaybe<CardWhereInput>;
};

export enum CardScalarFieldEnum {
  CreatedAt = 'createdAt',
  CreatorId = 'creatorId',
  Description = 'description',
  Free = 'free',
  HeadUri = 'headUri',
  Id = 'id',
  Name = 'name',
  Orientation = 'orientation',
  Price = 'price',
  Qrcode = 'qrcode',
  ScenicSpotId = 'scenicSpotId',
  Sn = 'sn',
  TailUri = 'tailUri',
  UpdatedAt = 'updatedAt',
  Valid = 'valid'
}

export type CardScalarWhereInput = {
  AND?: InputMaybe<Array<CardScalarWhereInput>>;
  NOT?: InputMaybe<Array<CardScalarWhereInput>>;
  OR?: InputMaybe<Array<CardScalarWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  creatorId?: InputMaybe<StringFilter>;
  description?: InputMaybe<StringNullableFilter>;
  free?: InputMaybe<BoolFilter>;
  headUri?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringFilter>;
  orientation?: InputMaybe<EnumCardOrientationFilter>;
  price?: InputMaybe<FloatFilter>;
  qrcode?: InputMaybe<StringNullableFilter>;
  scenicSpotId?: InputMaybe<StringNullableFilter>;
  sn?: InputMaybe<StringFilter>;
  tailUri?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  valid?: InputMaybe<BoolFilter>;
};

export type CardScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<CardScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<CardScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<CardScalarWhereWithAggregatesInput>>;
  createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  creatorId?: InputMaybe<StringWithAggregatesFilter>;
  description?: InputMaybe<StringNullableWithAggregatesFilter>;
  free?: InputMaybe<BoolWithAggregatesFilter>;
  headUri?: InputMaybe<StringWithAggregatesFilter>;
  id?: InputMaybe<StringWithAggregatesFilter>;
  name?: InputMaybe<StringWithAggregatesFilter>;
  orientation?: InputMaybe<EnumCardOrientationWithAggregatesFilter>;
  price?: InputMaybe<FloatWithAggregatesFilter>;
  qrcode?: InputMaybe<StringNullableWithAggregatesFilter>;
  scenicSpotId?: InputMaybe<StringNullableWithAggregatesFilter>;
  sn?: InputMaybe<StringWithAggregatesFilter>;
  tailUri?: InputMaybe<StringWithAggregatesFilter>;
  updatedAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  valid?: InputMaybe<BoolWithAggregatesFilter>;
};

export type CardSumAggregate = {
  __typename?: 'CardSumAggregate';
  price?: Maybe<Scalars['Float']>;
};

export type CardSumOrderByAggregateInput = {
  price?: InputMaybe<SortOrder>;
};

export type CardUpdateInput = {
  collections?: InputMaybe<CollectionUpdateManyWithoutPostcardNestedInput>;
  couponActivity?: InputMaybe<CouponActivityUpdateOneWithoutCardNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  creator?: InputMaybe<UserUpdateOneRequiredWithoutCardNestedInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  free?: InputMaybe<BoolFieldUpdateOperationsInput>;
  headUri?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  inStoreItem?: InputMaybe<StoreItemUpdateManyWithoutItemCardNestedInput>;
  inValidReasonHistories?: InputMaybe<InValidReasonUpdateManyWithoutCardNestedInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  orientation?: InputMaybe<EnumCardOrientationFieldUpdateOperationsInput>;
  postsUsed?: InputMaybe<PostUpdateManyWithoutBodyNestedInput>;
  price?: InputMaybe<FloatFieldUpdateOperationsInput>;
  qrcode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  scenicSpot?: InputMaybe<ScenicSpotUpdateOneWithoutCardsNestedInput>;
  sn?: InputMaybe<StringFieldUpdateOperationsInput>;
  tailUri?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  valid?: InputMaybe<BoolFieldUpdateOperationsInput>;
};

export type CardUpdateManyMutationInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  free?: InputMaybe<BoolFieldUpdateOperationsInput>;
  headUri?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  orientation?: InputMaybe<EnumCardOrientationFieldUpdateOperationsInput>;
  price?: InputMaybe<FloatFieldUpdateOperationsInput>;
  qrcode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sn?: InputMaybe<StringFieldUpdateOperationsInput>;
  tailUri?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  valid?: InputMaybe<BoolFieldUpdateOperationsInput>;
};

export type CardUpdateManyWithWhereWithoutCreatorInput = {
  data: CardUpdateManyMutationInput;
  where: CardScalarWhereInput;
};

export type CardUpdateManyWithWhereWithoutScenicSpotInput = {
  data: CardUpdateManyMutationInput;
  where: CardScalarWhereInput;
};

export type CardUpdateManyWithoutCreatorNestedInput = {
  connect?: InputMaybe<Array<CardWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CardCreateOrConnectWithoutCreatorInput>>;
  create?: InputMaybe<Array<CardCreateWithoutCreatorInput>>;
  createMany?: InputMaybe<CardCreateManyCreatorInputEnvelope>;
  delete?: InputMaybe<Array<CardWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<CardScalarWhereInput>>;
  disconnect?: InputMaybe<Array<CardWhereUniqueInput>>;
  set?: InputMaybe<Array<CardWhereUniqueInput>>;
  update?: InputMaybe<Array<CardUpdateWithWhereUniqueWithoutCreatorInput>>;
  updateMany?: InputMaybe<Array<CardUpdateManyWithWhereWithoutCreatorInput>>;
  upsert?: InputMaybe<Array<CardUpsertWithWhereUniqueWithoutCreatorInput>>;
};

export type CardUpdateManyWithoutScenicSpotNestedInput = {
  connect?: InputMaybe<Array<CardWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CardCreateOrConnectWithoutScenicSpotInput>>;
  create?: InputMaybe<Array<CardCreateWithoutScenicSpotInput>>;
  createMany?: InputMaybe<CardCreateManyScenicSpotInputEnvelope>;
  delete?: InputMaybe<Array<CardWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<CardScalarWhereInput>>;
  disconnect?: InputMaybe<Array<CardWhereUniqueInput>>;
  set?: InputMaybe<Array<CardWhereUniqueInput>>;
  update?: InputMaybe<Array<CardUpdateWithWhereUniqueWithoutScenicSpotInput>>;
  updateMany?: InputMaybe<Array<CardUpdateManyWithWhereWithoutScenicSpotInput>>;
  upsert?: InputMaybe<Array<CardUpsertWithWhereUniqueWithoutScenicSpotInput>>;
};

export type CardUpdateOneRequiredWithoutPostsUsedNestedInput = {
  connect?: InputMaybe<CardWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CardCreateOrConnectWithoutPostsUsedInput>;
  create?: InputMaybe<CardCreateWithoutPostsUsedInput>;
  update?: InputMaybe<CardUpdateWithoutPostsUsedInput>;
  upsert?: InputMaybe<CardUpsertWithoutPostsUsedInput>;
};

export type CardUpdateOneWithoutCollectionsNestedInput = {
  connect?: InputMaybe<CardWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CardCreateOrConnectWithoutCollectionsInput>;
  create?: InputMaybe<CardCreateWithoutCollectionsInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<CardUpdateWithoutCollectionsInput>;
  upsert?: InputMaybe<CardUpsertWithoutCollectionsInput>;
};

export type CardUpdateOneWithoutCouponActivityNestedInput = {
  connect?: InputMaybe<CardWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CardCreateOrConnectWithoutCouponActivityInput>;
  create?: InputMaybe<CardCreateWithoutCouponActivityInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<CardUpdateWithoutCouponActivityInput>;
  upsert?: InputMaybe<CardUpsertWithoutCouponActivityInput>;
};

export type CardUpdateOneWithoutInStoreItemNestedInput = {
  connect?: InputMaybe<CardWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CardCreateOrConnectWithoutInStoreItemInput>;
  create?: InputMaybe<CardCreateWithoutInStoreItemInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<CardUpdateWithoutInStoreItemInput>;
  upsert?: InputMaybe<CardUpsertWithoutInStoreItemInput>;
};

export type CardUpdateOneWithoutInValidReasonHistoriesNestedInput = {
  connect?: InputMaybe<CardWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CardCreateOrConnectWithoutInValidReasonHistoriesInput>;
  create?: InputMaybe<CardCreateWithoutInValidReasonHistoriesInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<CardUpdateWithoutInValidReasonHistoriesInput>;
  upsert?: InputMaybe<CardUpsertWithoutInValidReasonHistoriesInput>;
};

export type CardUpdateWithWhereUniqueWithoutCreatorInput = {
  data: CardUpdateWithoutCreatorInput;
  where: CardWhereUniqueInput;
};

export type CardUpdateWithWhereUniqueWithoutScenicSpotInput = {
  data: CardUpdateWithoutScenicSpotInput;
  where: CardWhereUniqueInput;
};

export type CardUpdateWithoutCollectionsInput = {
  couponActivity?: InputMaybe<CouponActivityUpdateOneWithoutCardNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  creator?: InputMaybe<UserUpdateOneRequiredWithoutCardNestedInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  free?: InputMaybe<BoolFieldUpdateOperationsInput>;
  headUri?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  inStoreItem?: InputMaybe<StoreItemUpdateManyWithoutItemCardNestedInput>;
  inValidReasonHistories?: InputMaybe<InValidReasonUpdateManyWithoutCardNestedInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  orientation?: InputMaybe<EnumCardOrientationFieldUpdateOperationsInput>;
  postsUsed?: InputMaybe<PostUpdateManyWithoutBodyNestedInput>;
  price?: InputMaybe<FloatFieldUpdateOperationsInput>;
  qrcode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  scenicSpot?: InputMaybe<ScenicSpotUpdateOneWithoutCardsNestedInput>;
  sn?: InputMaybe<StringFieldUpdateOperationsInput>;
  tailUri?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  valid?: InputMaybe<BoolFieldUpdateOperationsInput>;
};

export type CardUpdateWithoutCouponActivityInput = {
  collections?: InputMaybe<CollectionUpdateManyWithoutPostcardNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  creator?: InputMaybe<UserUpdateOneRequiredWithoutCardNestedInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  free?: InputMaybe<BoolFieldUpdateOperationsInput>;
  headUri?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  inStoreItem?: InputMaybe<StoreItemUpdateManyWithoutItemCardNestedInput>;
  inValidReasonHistories?: InputMaybe<InValidReasonUpdateManyWithoutCardNestedInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  orientation?: InputMaybe<EnumCardOrientationFieldUpdateOperationsInput>;
  postsUsed?: InputMaybe<PostUpdateManyWithoutBodyNestedInput>;
  price?: InputMaybe<FloatFieldUpdateOperationsInput>;
  qrcode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  scenicSpot?: InputMaybe<ScenicSpotUpdateOneWithoutCardsNestedInput>;
  sn?: InputMaybe<StringFieldUpdateOperationsInput>;
  tailUri?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  valid?: InputMaybe<BoolFieldUpdateOperationsInput>;
};

export type CardUpdateWithoutCreatorInput = {
  collections?: InputMaybe<CollectionUpdateManyWithoutPostcardNestedInput>;
  couponActivity?: InputMaybe<CouponActivityUpdateOneWithoutCardNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  free?: InputMaybe<BoolFieldUpdateOperationsInput>;
  headUri?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  inStoreItem?: InputMaybe<StoreItemUpdateManyWithoutItemCardNestedInput>;
  inValidReasonHistories?: InputMaybe<InValidReasonUpdateManyWithoutCardNestedInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  orientation?: InputMaybe<EnumCardOrientationFieldUpdateOperationsInput>;
  postsUsed?: InputMaybe<PostUpdateManyWithoutBodyNestedInput>;
  price?: InputMaybe<FloatFieldUpdateOperationsInput>;
  qrcode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  scenicSpot?: InputMaybe<ScenicSpotUpdateOneWithoutCardsNestedInput>;
  sn?: InputMaybe<StringFieldUpdateOperationsInput>;
  tailUri?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  valid?: InputMaybe<BoolFieldUpdateOperationsInput>;
};

export type CardUpdateWithoutInStoreItemInput = {
  collections?: InputMaybe<CollectionUpdateManyWithoutPostcardNestedInput>;
  couponActivity?: InputMaybe<CouponActivityUpdateOneWithoutCardNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  creator?: InputMaybe<UserUpdateOneRequiredWithoutCardNestedInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  free?: InputMaybe<BoolFieldUpdateOperationsInput>;
  headUri?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  inValidReasonHistories?: InputMaybe<InValidReasonUpdateManyWithoutCardNestedInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  orientation?: InputMaybe<EnumCardOrientationFieldUpdateOperationsInput>;
  postsUsed?: InputMaybe<PostUpdateManyWithoutBodyNestedInput>;
  price?: InputMaybe<FloatFieldUpdateOperationsInput>;
  qrcode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  scenicSpot?: InputMaybe<ScenicSpotUpdateOneWithoutCardsNestedInput>;
  sn?: InputMaybe<StringFieldUpdateOperationsInput>;
  tailUri?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  valid?: InputMaybe<BoolFieldUpdateOperationsInput>;
};

export type CardUpdateWithoutInValidReasonHistoriesInput = {
  collections?: InputMaybe<CollectionUpdateManyWithoutPostcardNestedInput>;
  couponActivity?: InputMaybe<CouponActivityUpdateOneWithoutCardNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  creator?: InputMaybe<UserUpdateOneRequiredWithoutCardNestedInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  free?: InputMaybe<BoolFieldUpdateOperationsInput>;
  headUri?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  inStoreItem?: InputMaybe<StoreItemUpdateManyWithoutItemCardNestedInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  orientation?: InputMaybe<EnumCardOrientationFieldUpdateOperationsInput>;
  postsUsed?: InputMaybe<PostUpdateManyWithoutBodyNestedInput>;
  price?: InputMaybe<FloatFieldUpdateOperationsInput>;
  qrcode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  scenicSpot?: InputMaybe<ScenicSpotUpdateOneWithoutCardsNestedInput>;
  sn?: InputMaybe<StringFieldUpdateOperationsInput>;
  tailUri?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  valid?: InputMaybe<BoolFieldUpdateOperationsInput>;
};

export type CardUpdateWithoutPostsUsedInput = {
  collections?: InputMaybe<CollectionUpdateManyWithoutPostcardNestedInput>;
  couponActivity?: InputMaybe<CouponActivityUpdateOneWithoutCardNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  creator?: InputMaybe<UserUpdateOneRequiredWithoutCardNestedInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  free?: InputMaybe<BoolFieldUpdateOperationsInput>;
  headUri?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  inStoreItem?: InputMaybe<StoreItemUpdateManyWithoutItemCardNestedInput>;
  inValidReasonHistories?: InputMaybe<InValidReasonUpdateManyWithoutCardNestedInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  orientation?: InputMaybe<EnumCardOrientationFieldUpdateOperationsInput>;
  price?: InputMaybe<FloatFieldUpdateOperationsInput>;
  qrcode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  scenicSpot?: InputMaybe<ScenicSpotUpdateOneWithoutCardsNestedInput>;
  sn?: InputMaybe<StringFieldUpdateOperationsInput>;
  tailUri?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  valid?: InputMaybe<BoolFieldUpdateOperationsInput>;
};

export type CardUpdateWithoutScenicSpotInput = {
  collections?: InputMaybe<CollectionUpdateManyWithoutPostcardNestedInput>;
  couponActivity?: InputMaybe<CouponActivityUpdateOneWithoutCardNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  creator?: InputMaybe<UserUpdateOneRequiredWithoutCardNestedInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  free?: InputMaybe<BoolFieldUpdateOperationsInput>;
  headUri?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  inStoreItem?: InputMaybe<StoreItemUpdateManyWithoutItemCardNestedInput>;
  inValidReasonHistories?: InputMaybe<InValidReasonUpdateManyWithoutCardNestedInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  orientation?: InputMaybe<EnumCardOrientationFieldUpdateOperationsInput>;
  postsUsed?: InputMaybe<PostUpdateManyWithoutBodyNestedInput>;
  price?: InputMaybe<FloatFieldUpdateOperationsInput>;
  qrcode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sn?: InputMaybe<StringFieldUpdateOperationsInput>;
  tailUri?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  valid?: InputMaybe<BoolFieldUpdateOperationsInput>;
};

export type CardUpsertWithWhereUniqueWithoutCreatorInput = {
  create: CardCreateWithoutCreatorInput;
  update: CardUpdateWithoutCreatorInput;
  where: CardWhereUniqueInput;
};

export type CardUpsertWithWhereUniqueWithoutScenicSpotInput = {
  create: CardCreateWithoutScenicSpotInput;
  update: CardUpdateWithoutScenicSpotInput;
  where: CardWhereUniqueInput;
};

export type CardUpsertWithoutCollectionsInput = {
  create: CardCreateWithoutCollectionsInput;
  update: CardUpdateWithoutCollectionsInput;
};

export type CardUpsertWithoutCouponActivityInput = {
  create: CardCreateWithoutCouponActivityInput;
  update: CardUpdateWithoutCouponActivityInput;
};

export type CardUpsertWithoutInStoreItemInput = {
  create: CardCreateWithoutInStoreItemInput;
  update: CardUpdateWithoutInStoreItemInput;
};

export type CardUpsertWithoutInValidReasonHistoriesInput = {
  create: CardCreateWithoutInValidReasonHistoriesInput;
  update: CardUpdateWithoutInValidReasonHistoriesInput;
};

export type CardUpsertWithoutPostsUsedInput = {
  create: CardCreateWithoutPostsUsedInput;
  update: CardUpdateWithoutPostsUsedInput;
};

export type CardWhereInput = {
  AND?: InputMaybe<Array<CardWhereInput>>;
  NOT?: InputMaybe<Array<CardWhereInput>>;
  OR?: InputMaybe<Array<CardWhereInput>>;
  collections?: InputMaybe<CollectionListRelationFilter>;
  couponActivity?: InputMaybe<CouponActivityRelationFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  creator?: InputMaybe<UserRelationFilter>;
  creatorId?: InputMaybe<StringFilter>;
  description?: InputMaybe<StringNullableFilter>;
  free?: InputMaybe<BoolFilter>;
  headUri?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  inStoreItem?: InputMaybe<StoreItemListRelationFilter>;
  inValidReasonHistories?: InputMaybe<InValidReasonListRelationFilter>;
  name?: InputMaybe<StringFilter>;
  orientation?: InputMaybe<EnumCardOrientationFilter>;
  postsUsed?: InputMaybe<PostListRelationFilter>;
  price?: InputMaybe<FloatFilter>;
  qrcode?: InputMaybe<StringNullableFilter>;
  scenicSpot?: InputMaybe<ScenicSpotRelationFilter>;
  scenicSpotId?: InputMaybe<StringNullableFilter>;
  sn?: InputMaybe<StringFilter>;
  tailUri?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  valid?: InputMaybe<BoolFilter>;
};

export type CardWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
  qrcode?: InputMaybe<Scalars['String']>;
};

export type CartItem = {
  __typename?: 'CartItem';
  _count?: Maybe<CartItemCount>;
  checkout?: Maybe<Coin>;
  couponEntities: Array<CouponEntity>;
  createdAt: Scalars['DateTime'];
  customer: User;
  customerId: Scalars['String'];
  id: Scalars['String'];
  isPaid: Scalars['Boolean'];
  originalTotalAmount?: Maybe<Scalars['Decimal']>;
  storeToCartItems: Array<StoreItemToCartItem>;
  totalAmount: Scalars['Decimal'];
  updatedAt: Scalars['DateTime'];
};


export type CartItemCouponEntitiesArgs = {
  cursor?: InputMaybe<CouponEntityWhereUniqueInput>;
  distinct?: InputMaybe<Array<CouponEntityScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<CouponEntityOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CouponEntityWhereInput>;
};


export type CartItemStoreToCartItemsArgs = {
  cursor?: InputMaybe<StoreItemToCartItemWhereUniqueInput>;
  distinct?: InputMaybe<Array<StoreItemToCartItemScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<StoreItemToCartItemOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<StoreItemToCartItemWhereInput>;
};

export type CartItemAvgAggregate = {
  __typename?: 'CartItemAvgAggregate';
  originalTotalAmount?: Maybe<Scalars['Decimal']>;
  totalAmount?: Maybe<Scalars['Decimal']>;
};

export type CartItemAvgOrderByAggregateInput = {
  originalTotalAmount?: InputMaybe<SortOrder>;
  totalAmount?: InputMaybe<SortOrder>;
};

export type CartItemCount = {
  __typename?: 'CartItemCount';
  couponEntities: Scalars['Int'];
  storeToCartItems: Scalars['Int'];
};


export type CartItemCountCouponEntitiesArgs = {
  where?: InputMaybe<CouponEntityWhereInput>;
};


export type CartItemCountStoreToCartItemsArgs = {
  where?: InputMaybe<StoreItemToCartItemWhereInput>;
};

export type CartItemCountAggregate = {
  __typename?: 'CartItemCountAggregate';
  _all: Scalars['Int'];
  createdAt: Scalars['Int'];
  customerId: Scalars['Int'];
  id: Scalars['Int'];
  isPaid: Scalars['Int'];
  originalTotalAmount: Scalars['Int'];
  totalAmount: Scalars['Int'];
  updatedAt: Scalars['Int'];
};

export type CartItemCountOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  customerId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  isPaid?: InputMaybe<SortOrder>;
  originalTotalAmount?: InputMaybe<SortOrder>;
  totalAmount?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type CartItemCreateInput = {
  checkout?: InputMaybe<CoinCreateNestedOneWithoutCartInput>;
  couponEntities?: InputMaybe<CouponEntityCreateNestedManyWithoutInCartItemInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  customer: UserCreateNestedOneWithoutStorePurchaseHistoryInput;
  id?: InputMaybe<Scalars['String']>;
  isPaid?: InputMaybe<Scalars['Boolean']>;
  originalTotalAmount?: InputMaybe<Scalars['Decimal']>;
  storeToCartItems?: InputMaybe<StoreItemToCartItemCreateNestedManyWithoutCartItemInput>;
  totalAmount: Scalars['Decimal'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type CartItemCreateManyCustomerInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  isPaid?: InputMaybe<Scalars['Boolean']>;
  originalTotalAmount?: InputMaybe<Scalars['Decimal']>;
  totalAmount: Scalars['Decimal'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type CartItemCreateManyCustomerInputEnvelope = {
  data: Array<CartItemCreateManyCustomerInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type CartItemCreateManyInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  customerId: Scalars['String'];
  id?: InputMaybe<Scalars['String']>;
  isPaid?: InputMaybe<Scalars['Boolean']>;
  originalTotalAmount?: InputMaybe<Scalars['Decimal']>;
  totalAmount: Scalars['Decimal'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type CartItemCreateNestedManyWithoutCustomerInput = {
  connect?: InputMaybe<Array<CartItemWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CartItemCreateOrConnectWithoutCustomerInput>>;
  create?: InputMaybe<Array<CartItemCreateWithoutCustomerInput>>;
  createMany?: InputMaybe<CartItemCreateManyCustomerInputEnvelope>;
};

export type CartItemCreateNestedOneWithoutCheckoutInput = {
  connect?: InputMaybe<CartItemWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CartItemCreateOrConnectWithoutCheckoutInput>;
  create?: InputMaybe<CartItemCreateWithoutCheckoutInput>;
};

export type CartItemCreateNestedOneWithoutCouponEntitiesInput = {
  connect?: InputMaybe<CartItemWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CartItemCreateOrConnectWithoutCouponEntitiesInput>;
  create?: InputMaybe<CartItemCreateWithoutCouponEntitiesInput>;
};

export type CartItemCreateNestedOneWithoutStoreToCartItemsInput = {
  connect?: InputMaybe<CartItemWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CartItemCreateOrConnectWithoutStoreToCartItemsInput>;
  create?: InputMaybe<CartItemCreateWithoutStoreToCartItemsInput>;
};

export type CartItemCreateOrConnectWithoutCheckoutInput = {
  create: CartItemCreateWithoutCheckoutInput;
  where: CartItemWhereUniqueInput;
};

export type CartItemCreateOrConnectWithoutCouponEntitiesInput = {
  create: CartItemCreateWithoutCouponEntitiesInput;
  where: CartItemWhereUniqueInput;
};

export type CartItemCreateOrConnectWithoutCustomerInput = {
  create: CartItemCreateWithoutCustomerInput;
  where: CartItemWhereUniqueInput;
};

export type CartItemCreateOrConnectWithoutStoreToCartItemsInput = {
  create: CartItemCreateWithoutStoreToCartItemsInput;
  where: CartItemWhereUniqueInput;
};

export type CartItemCreateWithoutCheckoutInput = {
  couponEntities?: InputMaybe<CouponEntityCreateNestedManyWithoutInCartItemInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  customer: UserCreateNestedOneWithoutStorePurchaseHistoryInput;
  id?: InputMaybe<Scalars['String']>;
  isPaid?: InputMaybe<Scalars['Boolean']>;
  originalTotalAmount?: InputMaybe<Scalars['Decimal']>;
  storeToCartItems?: InputMaybe<StoreItemToCartItemCreateNestedManyWithoutCartItemInput>;
  totalAmount: Scalars['Decimal'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type CartItemCreateWithoutCouponEntitiesInput = {
  checkout?: InputMaybe<CoinCreateNestedOneWithoutCartInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  customer: UserCreateNestedOneWithoutStorePurchaseHistoryInput;
  id?: InputMaybe<Scalars['String']>;
  isPaid?: InputMaybe<Scalars['Boolean']>;
  originalTotalAmount?: InputMaybe<Scalars['Decimal']>;
  storeToCartItems?: InputMaybe<StoreItemToCartItemCreateNestedManyWithoutCartItemInput>;
  totalAmount: Scalars['Decimal'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type CartItemCreateWithoutCustomerInput = {
  checkout?: InputMaybe<CoinCreateNestedOneWithoutCartInput>;
  couponEntities?: InputMaybe<CouponEntityCreateNestedManyWithoutInCartItemInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  isPaid?: InputMaybe<Scalars['Boolean']>;
  originalTotalAmount?: InputMaybe<Scalars['Decimal']>;
  storeToCartItems?: InputMaybe<StoreItemToCartItemCreateNestedManyWithoutCartItemInput>;
  totalAmount: Scalars['Decimal'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type CartItemCreateWithoutStoreToCartItemsInput = {
  checkout?: InputMaybe<CoinCreateNestedOneWithoutCartInput>;
  couponEntities?: InputMaybe<CouponEntityCreateNestedManyWithoutInCartItemInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  customer: UserCreateNestedOneWithoutStorePurchaseHistoryInput;
  id?: InputMaybe<Scalars['String']>;
  isPaid?: InputMaybe<Scalars['Boolean']>;
  originalTotalAmount?: InputMaybe<Scalars['Decimal']>;
  totalAmount: Scalars['Decimal'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type CartItemGroupBy = {
  __typename?: 'CartItemGroupBy';
  _avg?: Maybe<CartItemAvgAggregate>;
  _count?: Maybe<CartItemCountAggregate>;
  _max?: Maybe<CartItemMaxAggregate>;
  _min?: Maybe<CartItemMinAggregate>;
  _sum?: Maybe<CartItemSumAggregate>;
  createdAt: Scalars['DateTime'];
  customerId: Scalars['String'];
  id: Scalars['String'];
  isPaid: Scalars['Boolean'];
  originalTotalAmount?: Maybe<Scalars['Decimal']>;
  totalAmount: Scalars['Decimal'];
  updatedAt: Scalars['DateTime'];
};

export type CartItemListRelationFilter = {
  every?: InputMaybe<CartItemWhereInput>;
  none?: InputMaybe<CartItemWhereInput>;
  some?: InputMaybe<CartItemWhereInput>;
};

export type CartItemMaxAggregate = {
  __typename?: 'CartItemMaxAggregate';
  createdAt?: Maybe<Scalars['DateTime']>;
  customerId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isPaid?: Maybe<Scalars['Boolean']>;
  originalTotalAmount?: Maybe<Scalars['Decimal']>;
  totalAmount?: Maybe<Scalars['Decimal']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CartItemMaxOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  customerId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  isPaid?: InputMaybe<SortOrder>;
  originalTotalAmount?: InputMaybe<SortOrder>;
  totalAmount?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type CartItemMinAggregate = {
  __typename?: 'CartItemMinAggregate';
  createdAt?: Maybe<Scalars['DateTime']>;
  customerId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isPaid?: Maybe<Scalars['Boolean']>;
  originalTotalAmount?: Maybe<Scalars['Decimal']>;
  totalAmount?: Maybe<Scalars['Decimal']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CartItemMinOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  customerId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  isPaid?: InputMaybe<SortOrder>;
  originalTotalAmount?: InputMaybe<SortOrder>;
  totalAmount?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type CartItemOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type CartItemOrderByWithAggregationInput = {
  _avg?: InputMaybe<CartItemAvgOrderByAggregateInput>;
  _count?: InputMaybe<CartItemCountOrderByAggregateInput>;
  _max?: InputMaybe<CartItemMaxOrderByAggregateInput>;
  _min?: InputMaybe<CartItemMinOrderByAggregateInput>;
  _sum?: InputMaybe<CartItemSumOrderByAggregateInput>;
  createdAt?: InputMaybe<SortOrder>;
  customerId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  isPaid?: InputMaybe<SortOrder>;
  originalTotalAmount?: InputMaybe<SortOrder>;
  totalAmount?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type CartItemOrderByWithRelationInput = {
  checkout?: InputMaybe<CoinOrderByWithRelationInput>;
  couponEntities?: InputMaybe<CouponEntityOrderByRelationAggregateInput>;
  createdAt?: InputMaybe<SortOrder>;
  customer?: InputMaybe<UserOrderByWithRelationInput>;
  customerId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  isPaid?: InputMaybe<SortOrder>;
  originalTotalAmount?: InputMaybe<SortOrder>;
  storeToCartItems?: InputMaybe<StoreItemToCartItemOrderByRelationAggregateInput>;
  totalAmount?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type CartItemRelationFilter = {
  is?: InputMaybe<CartItemWhereInput>;
  isNot?: InputMaybe<CartItemWhereInput>;
};

export enum CartItemScalarFieldEnum {
  CreatedAt = 'createdAt',
  CustomerId = 'customerId',
  Id = 'id',
  IsPaid = 'isPaid',
  OriginalTotalAmount = 'originalTotalAmount',
  TotalAmount = 'totalAmount',
  UpdatedAt = 'updatedAt'
}

export type CartItemScalarWhereInput = {
  AND?: InputMaybe<Array<CartItemScalarWhereInput>>;
  NOT?: InputMaybe<Array<CartItemScalarWhereInput>>;
  OR?: InputMaybe<Array<CartItemScalarWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  customerId?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  isPaid?: InputMaybe<BoolFilter>;
  originalTotalAmount?: InputMaybe<DecimalNullableFilter>;
  totalAmount?: InputMaybe<DecimalFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type CartItemScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<CartItemScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<CartItemScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<CartItemScalarWhereWithAggregatesInput>>;
  createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  customerId?: InputMaybe<StringWithAggregatesFilter>;
  id?: InputMaybe<StringWithAggregatesFilter>;
  isPaid?: InputMaybe<BoolWithAggregatesFilter>;
  originalTotalAmount?: InputMaybe<DecimalNullableWithAggregatesFilter>;
  totalAmount?: InputMaybe<DecimalWithAggregatesFilter>;
  updatedAt?: InputMaybe<DateTimeWithAggregatesFilter>;
};

export type CartItemSumAggregate = {
  __typename?: 'CartItemSumAggregate';
  originalTotalAmount?: Maybe<Scalars['Decimal']>;
  totalAmount?: Maybe<Scalars['Decimal']>;
};

export type CartItemSumOrderByAggregateInput = {
  originalTotalAmount?: InputMaybe<SortOrder>;
  totalAmount?: InputMaybe<SortOrder>;
};

export type CartItemUpdateInput = {
  checkout?: InputMaybe<CoinUpdateOneWithoutCartNestedInput>;
  couponEntities?: InputMaybe<CouponEntityUpdateManyWithoutInCartItemNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  customer?: InputMaybe<UserUpdateOneRequiredWithoutStorePurchaseHistoryNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isPaid?: InputMaybe<BoolFieldUpdateOperationsInput>;
  originalTotalAmount?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
  storeToCartItems?: InputMaybe<StoreItemToCartItemUpdateManyWithoutCartItemNestedInput>;
  totalAmount?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type CartItemUpdateManyMutationInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isPaid?: InputMaybe<BoolFieldUpdateOperationsInput>;
  originalTotalAmount?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
  totalAmount?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type CartItemUpdateManyWithWhereWithoutCustomerInput = {
  data: CartItemUpdateManyMutationInput;
  where: CartItemScalarWhereInput;
};

export type CartItemUpdateManyWithoutCustomerNestedInput = {
  connect?: InputMaybe<Array<CartItemWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CartItemCreateOrConnectWithoutCustomerInput>>;
  create?: InputMaybe<Array<CartItemCreateWithoutCustomerInput>>;
  createMany?: InputMaybe<CartItemCreateManyCustomerInputEnvelope>;
  delete?: InputMaybe<Array<CartItemWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<CartItemScalarWhereInput>>;
  disconnect?: InputMaybe<Array<CartItemWhereUniqueInput>>;
  set?: InputMaybe<Array<CartItemWhereUniqueInput>>;
  update?: InputMaybe<Array<CartItemUpdateWithWhereUniqueWithoutCustomerInput>>;
  updateMany?: InputMaybe<Array<CartItemUpdateManyWithWhereWithoutCustomerInput>>;
  upsert?: InputMaybe<Array<CartItemUpsertWithWhereUniqueWithoutCustomerInput>>;
};

export type CartItemUpdateOneRequiredWithoutStoreToCartItemsNestedInput = {
  connect?: InputMaybe<CartItemWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CartItemCreateOrConnectWithoutStoreToCartItemsInput>;
  create?: InputMaybe<CartItemCreateWithoutStoreToCartItemsInput>;
  update?: InputMaybe<CartItemUpdateWithoutStoreToCartItemsInput>;
  upsert?: InputMaybe<CartItemUpsertWithoutStoreToCartItemsInput>;
};

export type CartItemUpdateOneWithoutCheckoutNestedInput = {
  connect?: InputMaybe<CartItemWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CartItemCreateOrConnectWithoutCheckoutInput>;
  create?: InputMaybe<CartItemCreateWithoutCheckoutInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<CartItemUpdateWithoutCheckoutInput>;
  upsert?: InputMaybe<CartItemUpsertWithoutCheckoutInput>;
};

export type CartItemUpdateOneWithoutCouponEntitiesNestedInput = {
  connect?: InputMaybe<CartItemWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CartItemCreateOrConnectWithoutCouponEntitiesInput>;
  create?: InputMaybe<CartItemCreateWithoutCouponEntitiesInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<CartItemUpdateWithoutCouponEntitiesInput>;
  upsert?: InputMaybe<CartItemUpsertWithoutCouponEntitiesInput>;
};

export type CartItemUpdateWithWhereUniqueWithoutCustomerInput = {
  data: CartItemUpdateWithoutCustomerInput;
  where: CartItemWhereUniqueInput;
};

export type CartItemUpdateWithoutCheckoutInput = {
  couponEntities?: InputMaybe<CouponEntityUpdateManyWithoutInCartItemNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  customer?: InputMaybe<UserUpdateOneRequiredWithoutStorePurchaseHistoryNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isPaid?: InputMaybe<BoolFieldUpdateOperationsInput>;
  originalTotalAmount?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
  storeToCartItems?: InputMaybe<StoreItemToCartItemUpdateManyWithoutCartItemNestedInput>;
  totalAmount?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type CartItemUpdateWithoutCouponEntitiesInput = {
  checkout?: InputMaybe<CoinUpdateOneWithoutCartNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  customer?: InputMaybe<UserUpdateOneRequiredWithoutStorePurchaseHistoryNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isPaid?: InputMaybe<BoolFieldUpdateOperationsInput>;
  originalTotalAmount?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
  storeToCartItems?: InputMaybe<StoreItemToCartItemUpdateManyWithoutCartItemNestedInput>;
  totalAmount?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type CartItemUpdateWithoutCustomerInput = {
  checkout?: InputMaybe<CoinUpdateOneWithoutCartNestedInput>;
  couponEntities?: InputMaybe<CouponEntityUpdateManyWithoutInCartItemNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isPaid?: InputMaybe<BoolFieldUpdateOperationsInput>;
  originalTotalAmount?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
  storeToCartItems?: InputMaybe<StoreItemToCartItemUpdateManyWithoutCartItemNestedInput>;
  totalAmount?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type CartItemUpdateWithoutStoreToCartItemsInput = {
  checkout?: InputMaybe<CoinUpdateOneWithoutCartNestedInput>;
  couponEntities?: InputMaybe<CouponEntityUpdateManyWithoutInCartItemNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  customer?: InputMaybe<UserUpdateOneRequiredWithoutStorePurchaseHistoryNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isPaid?: InputMaybe<BoolFieldUpdateOperationsInput>;
  originalTotalAmount?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
  totalAmount?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type CartItemUpsertWithWhereUniqueWithoutCustomerInput = {
  create: CartItemCreateWithoutCustomerInput;
  update: CartItemUpdateWithoutCustomerInput;
  where: CartItemWhereUniqueInput;
};

export type CartItemUpsertWithoutCheckoutInput = {
  create: CartItemCreateWithoutCheckoutInput;
  update: CartItemUpdateWithoutCheckoutInput;
};

export type CartItemUpsertWithoutCouponEntitiesInput = {
  create: CartItemCreateWithoutCouponEntitiesInput;
  update: CartItemUpdateWithoutCouponEntitiesInput;
};

export type CartItemUpsertWithoutStoreToCartItemsInput = {
  create: CartItemCreateWithoutStoreToCartItemsInput;
  update: CartItemUpdateWithoutStoreToCartItemsInput;
};

export type CartItemWhereInput = {
  AND?: InputMaybe<Array<CartItemWhereInput>>;
  NOT?: InputMaybe<Array<CartItemWhereInput>>;
  OR?: InputMaybe<Array<CartItemWhereInput>>;
  checkout?: InputMaybe<CoinRelationFilter>;
  couponEntities?: InputMaybe<CouponEntityListRelationFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  customer?: InputMaybe<UserRelationFilter>;
  customerId?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  isPaid?: InputMaybe<BoolFilter>;
  originalTotalAmount?: InputMaybe<DecimalNullableFilter>;
  storeToCartItems?: InputMaybe<StoreItemToCartItemListRelationFilter>;
  totalAmount?: InputMaybe<DecimalFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type CartItemWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
};

export type Coin = {
  __typename?: 'Coin';
  _count?: Maybe<CoinCount>;
  amount: Scalars['Decimal'];
  benefitMemos: Array<BenefitMemoToCoin>;
  cart?: Maybe<CartItem>;
  cartId?: Maybe<Scalars['String']>;
  couponEntities: Array<CouponEntity>;
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  order?: Maybe<Order>;
  orderId?: Maybe<Scalars['String']>;
  originalAmount?: Maybe<Scalars['Decimal']>;
  paymentMethod?: Maybe<PaymentMethod>;
  readableId?: Maybe<Scalars['String']>;
  receipt?: Maybe<Scalars['JSON']>;
  type: CoinType;
  updatedAt: Scalars['DateTime'];
  user: User;
  userId: Scalars['String'];
};


export type CoinBenefitMemosArgs = {
  cursor?: InputMaybe<BenefitMemoToCoinWhereUniqueInput>;
  distinct?: InputMaybe<Array<BenefitMemoToCoinScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<BenefitMemoToCoinOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<BenefitMemoToCoinWhereInput>;
};


export type CoinCouponEntitiesArgs = {
  cursor?: InputMaybe<CouponEntityWhereUniqueInput>;
  distinct?: InputMaybe<Array<CouponEntityScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<CouponEntityOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CouponEntityWhereInput>;
};

export type CoinAvgAggregate = {
  __typename?: 'CoinAvgAggregate';
  amount?: Maybe<Scalars['Decimal']>;
  originalAmount?: Maybe<Scalars['Decimal']>;
};

export type CoinAvgOrderByAggregateInput = {
  amount?: InputMaybe<SortOrder>;
  originalAmount?: InputMaybe<SortOrder>;
};

export type CoinCount = {
  __typename?: 'CoinCount';
  benefitMemos: Scalars['Int'];
  couponEntities: Scalars['Int'];
};


export type CoinCountBenefitMemosArgs = {
  where?: InputMaybe<BenefitMemoToCoinWhereInput>;
};


export type CoinCountCouponEntitiesArgs = {
  where?: InputMaybe<CouponEntityWhereInput>;
};

export type CoinCountAggregate = {
  __typename?: 'CoinCountAggregate';
  _all: Scalars['Int'];
  amount: Scalars['Int'];
  cartId: Scalars['Int'];
  createdAt: Scalars['Int'];
  id: Scalars['Int'];
  orderId: Scalars['Int'];
  originalAmount: Scalars['Int'];
  paymentMethod: Scalars['Int'];
  readableId: Scalars['Int'];
  receipt: Scalars['Int'];
  type: Scalars['Int'];
  updatedAt: Scalars['Int'];
  userId: Scalars['Int'];
};

export type CoinCountOrderByAggregateInput = {
  amount?: InputMaybe<SortOrder>;
  cartId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  orderId?: InputMaybe<SortOrder>;
  originalAmount?: InputMaybe<SortOrder>;
  paymentMethod?: InputMaybe<SortOrder>;
  readableId?: InputMaybe<SortOrder>;
  receipt?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
};

export type CoinCreateInput = {
  amount: Scalars['Decimal'];
  benefitMemos?: InputMaybe<BenefitMemoToCoinCreateNestedManyWithoutCoinInput>;
  cart?: InputMaybe<CartItemCreateNestedOneWithoutCheckoutInput>;
  couponEntities?: InputMaybe<CouponEntityCreateNestedManyWithoutConsumeCoinInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<OrderCreateNestedOneWithoutCoinInput>;
  originalAmount?: InputMaybe<Scalars['Decimal']>;
  paymentMethod?: InputMaybe<PaymentMethod>;
  readableId?: InputMaybe<Scalars['String']>;
  receipt?: InputMaybe<Scalars['JSON']>;
  type: CoinType;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  user: UserCreateNestedOneWithoutCoinsInput;
};

export type CoinCreateManyInput = {
  amount: Scalars['Decimal'];
  cartId?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  orderId?: InputMaybe<Scalars['String']>;
  originalAmount?: InputMaybe<Scalars['Decimal']>;
  paymentMethod?: InputMaybe<PaymentMethod>;
  readableId?: InputMaybe<Scalars['String']>;
  receipt?: InputMaybe<Scalars['JSON']>;
  type: CoinType;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userId: Scalars['String'];
};

export type CoinCreateManyUserInput = {
  amount: Scalars['Decimal'];
  cartId?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  orderId?: InputMaybe<Scalars['String']>;
  originalAmount?: InputMaybe<Scalars['Decimal']>;
  paymentMethod?: InputMaybe<PaymentMethod>;
  readableId?: InputMaybe<Scalars['String']>;
  receipt?: InputMaybe<Scalars['JSON']>;
  type: CoinType;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type CoinCreateManyUserInputEnvelope = {
  data: Array<CoinCreateManyUserInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type CoinCreateNestedManyWithoutUserInput = {
  connect?: InputMaybe<Array<CoinWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CoinCreateOrConnectWithoutUserInput>>;
  create?: InputMaybe<Array<CoinCreateWithoutUserInput>>;
  createMany?: InputMaybe<CoinCreateManyUserInputEnvelope>;
};

export type CoinCreateNestedOneWithoutBenefitMemosInput = {
  connect?: InputMaybe<CoinWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CoinCreateOrConnectWithoutBenefitMemosInput>;
  create?: InputMaybe<CoinCreateWithoutBenefitMemosInput>;
};

export type CoinCreateNestedOneWithoutCartInput = {
  connect?: InputMaybe<CoinWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CoinCreateOrConnectWithoutCartInput>;
  create?: InputMaybe<CoinCreateWithoutCartInput>;
};

export type CoinCreateNestedOneWithoutCouponEntitiesInput = {
  connect?: InputMaybe<CoinWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CoinCreateOrConnectWithoutCouponEntitiesInput>;
  create?: InputMaybe<CoinCreateWithoutCouponEntitiesInput>;
};

export type CoinCreateNestedOneWithoutOrderInput = {
  connect?: InputMaybe<CoinWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CoinCreateOrConnectWithoutOrderInput>;
  create?: InputMaybe<CoinCreateWithoutOrderInput>;
};

export type CoinCreateOrConnectWithoutBenefitMemosInput = {
  create: CoinCreateWithoutBenefitMemosInput;
  where: CoinWhereUniqueInput;
};

export type CoinCreateOrConnectWithoutCartInput = {
  create: CoinCreateWithoutCartInput;
  where: CoinWhereUniqueInput;
};

export type CoinCreateOrConnectWithoutCouponEntitiesInput = {
  create: CoinCreateWithoutCouponEntitiesInput;
  where: CoinWhereUniqueInput;
};

export type CoinCreateOrConnectWithoutOrderInput = {
  create: CoinCreateWithoutOrderInput;
  where: CoinWhereUniqueInput;
};

export type CoinCreateOrConnectWithoutUserInput = {
  create: CoinCreateWithoutUserInput;
  where: CoinWhereUniqueInput;
};

export type CoinCreateWithoutBenefitMemosInput = {
  amount: Scalars['Decimal'];
  cart?: InputMaybe<CartItemCreateNestedOneWithoutCheckoutInput>;
  couponEntities?: InputMaybe<CouponEntityCreateNestedManyWithoutConsumeCoinInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<OrderCreateNestedOneWithoutCoinInput>;
  originalAmount?: InputMaybe<Scalars['Decimal']>;
  paymentMethod?: InputMaybe<PaymentMethod>;
  readableId?: InputMaybe<Scalars['String']>;
  receipt?: InputMaybe<Scalars['JSON']>;
  type: CoinType;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  user: UserCreateNestedOneWithoutCoinsInput;
};

export type CoinCreateWithoutCartInput = {
  amount: Scalars['Decimal'];
  benefitMemos?: InputMaybe<BenefitMemoToCoinCreateNestedManyWithoutCoinInput>;
  couponEntities?: InputMaybe<CouponEntityCreateNestedManyWithoutConsumeCoinInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<OrderCreateNestedOneWithoutCoinInput>;
  originalAmount?: InputMaybe<Scalars['Decimal']>;
  paymentMethod?: InputMaybe<PaymentMethod>;
  readableId?: InputMaybe<Scalars['String']>;
  receipt?: InputMaybe<Scalars['JSON']>;
  type: CoinType;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  user: UserCreateNestedOneWithoutCoinsInput;
};

export type CoinCreateWithoutCouponEntitiesInput = {
  amount: Scalars['Decimal'];
  benefitMemos?: InputMaybe<BenefitMemoToCoinCreateNestedManyWithoutCoinInput>;
  cart?: InputMaybe<CartItemCreateNestedOneWithoutCheckoutInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<OrderCreateNestedOneWithoutCoinInput>;
  originalAmount?: InputMaybe<Scalars['Decimal']>;
  paymentMethod?: InputMaybe<PaymentMethod>;
  readableId?: InputMaybe<Scalars['String']>;
  receipt?: InputMaybe<Scalars['JSON']>;
  type: CoinType;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  user: UserCreateNestedOneWithoutCoinsInput;
};

export type CoinCreateWithoutOrderInput = {
  amount: Scalars['Decimal'];
  benefitMemos?: InputMaybe<BenefitMemoToCoinCreateNestedManyWithoutCoinInput>;
  cart?: InputMaybe<CartItemCreateNestedOneWithoutCheckoutInput>;
  couponEntities?: InputMaybe<CouponEntityCreateNestedManyWithoutConsumeCoinInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  originalAmount?: InputMaybe<Scalars['Decimal']>;
  paymentMethod?: InputMaybe<PaymentMethod>;
  readableId?: InputMaybe<Scalars['String']>;
  receipt?: InputMaybe<Scalars['JSON']>;
  type: CoinType;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  user: UserCreateNestedOneWithoutCoinsInput;
};

export type CoinCreateWithoutUserInput = {
  amount: Scalars['Decimal'];
  benefitMemos?: InputMaybe<BenefitMemoToCoinCreateNestedManyWithoutCoinInput>;
  cart?: InputMaybe<CartItemCreateNestedOneWithoutCheckoutInput>;
  couponEntities?: InputMaybe<CouponEntityCreateNestedManyWithoutConsumeCoinInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<OrderCreateNestedOneWithoutCoinInput>;
  originalAmount?: InputMaybe<Scalars['Decimal']>;
  paymentMethod?: InputMaybe<PaymentMethod>;
  readableId?: InputMaybe<Scalars['String']>;
  receipt?: InputMaybe<Scalars['JSON']>;
  type: CoinType;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type CoinGroupBy = {
  __typename?: 'CoinGroupBy';
  _avg?: Maybe<CoinAvgAggregate>;
  _count?: Maybe<CoinCountAggregate>;
  _max?: Maybe<CoinMaxAggregate>;
  _min?: Maybe<CoinMinAggregate>;
  _sum?: Maybe<CoinSumAggregate>;
  amount: Scalars['Decimal'];
  cartId?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  orderId?: Maybe<Scalars['String']>;
  originalAmount?: Maybe<Scalars['Decimal']>;
  paymentMethod?: Maybe<PaymentMethod>;
  readableId?: Maybe<Scalars['String']>;
  receipt?: Maybe<Scalars['JSON']>;
  type: CoinType;
  updatedAt: Scalars['DateTime'];
  userId: Scalars['String'];
};

export type CoinListRelationFilter = {
  every?: InputMaybe<CoinWhereInput>;
  none?: InputMaybe<CoinWhereInput>;
  some?: InputMaybe<CoinWhereInput>;
};

export type CoinMaxAggregate = {
  __typename?: 'CoinMaxAggregate';
  amount?: Maybe<Scalars['Decimal']>;
  cartId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  orderId?: Maybe<Scalars['String']>;
  originalAmount?: Maybe<Scalars['Decimal']>;
  paymentMethod?: Maybe<PaymentMethod>;
  readableId?: Maybe<Scalars['String']>;
  type?: Maybe<CoinType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
};

export type CoinMaxOrderByAggregateInput = {
  amount?: InputMaybe<SortOrder>;
  cartId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  orderId?: InputMaybe<SortOrder>;
  originalAmount?: InputMaybe<SortOrder>;
  paymentMethod?: InputMaybe<SortOrder>;
  readableId?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
};

export type CoinMinAggregate = {
  __typename?: 'CoinMinAggregate';
  amount?: Maybe<Scalars['Decimal']>;
  cartId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  orderId?: Maybe<Scalars['String']>;
  originalAmount?: Maybe<Scalars['Decimal']>;
  paymentMethod?: Maybe<PaymentMethod>;
  readableId?: Maybe<Scalars['String']>;
  type?: Maybe<CoinType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
};

export type CoinMinOrderByAggregateInput = {
  amount?: InputMaybe<SortOrder>;
  cartId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  orderId?: InputMaybe<SortOrder>;
  originalAmount?: InputMaybe<SortOrder>;
  paymentMethod?: InputMaybe<SortOrder>;
  readableId?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
};

export type CoinOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type CoinOrderByWithAggregationInput = {
  _avg?: InputMaybe<CoinAvgOrderByAggregateInput>;
  _count?: InputMaybe<CoinCountOrderByAggregateInput>;
  _max?: InputMaybe<CoinMaxOrderByAggregateInput>;
  _min?: InputMaybe<CoinMinOrderByAggregateInput>;
  _sum?: InputMaybe<CoinSumOrderByAggregateInput>;
  amount?: InputMaybe<SortOrder>;
  cartId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  orderId?: InputMaybe<SortOrder>;
  originalAmount?: InputMaybe<SortOrder>;
  paymentMethod?: InputMaybe<SortOrder>;
  readableId?: InputMaybe<SortOrder>;
  receipt?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
};

export type CoinOrderByWithRelationInput = {
  amount?: InputMaybe<SortOrder>;
  benefitMemos?: InputMaybe<BenefitMemoToCoinOrderByRelationAggregateInput>;
  cart?: InputMaybe<CartItemOrderByWithRelationInput>;
  cartId?: InputMaybe<SortOrder>;
  couponEntities?: InputMaybe<CouponEntityOrderByRelationAggregateInput>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  order?: InputMaybe<OrderOrderByWithRelationInput>;
  orderId?: InputMaybe<SortOrder>;
  originalAmount?: InputMaybe<SortOrder>;
  paymentMethod?: InputMaybe<SortOrder>;
  readableId?: InputMaybe<SortOrder>;
  receipt?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  user?: InputMaybe<UserOrderByWithRelationInput>;
  userId?: InputMaybe<SortOrder>;
};

export type CoinPack = {
  __typename?: 'CoinPack';
  amount: Scalars['Decimal'];
  id: Scalars['String'];
  isHot: Scalars['Boolean'];
};

export type CoinPackAvgAggregate = {
  __typename?: 'CoinPackAvgAggregate';
  amount?: Maybe<Scalars['Decimal']>;
};

export type CoinPackAvgOrderByAggregateInput = {
  amount?: InputMaybe<SortOrder>;
};

export type CoinPackCountAggregate = {
  __typename?: 'CoinPackCountAggregate';
  _all: Scalars['Int'];
  amount: Scalars['Int'];
  id: Scalars['Int'];
  isHot: Scalars['Int'];
};

export type CoinPackCountOrderByAggregateInput = {
  amount?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  isHot?: InputMaybe<SortOrder>;
};

export type CoinPackCreateInput = {
  amount: Scalars['Decimal'];
  id: Scalars['String'];
  isHot?: InputMaybe<Scalars['Boolean']>;
};

export type CoinPackCreateManyInput = {
  amount: Scalars['Decimal'];
  id: Scalars['String'];
  isHot?: InputMaybe<Scalars['Boolean']>;
};

export type CoinPackGroupBy = {
  __typename?: 'CoinPackGroupBy';
  _avg?: Maybe<CoinPackAvgAggregate>;
  _count?: Maybe<CoinPackCountAggregate>;
  _max?: Maybe<CoinPackMaxAggregate>;
  _min?: Maybe<CoinPackMinAggregate>;
  _sum?: Maybe<CoinPackSumAggregate>;
  amount: Scalars['Decimal'];
  id: Scalars['String'];
  isHot: Scalars['Boolean'];
};

export type CoinPackMaxAggregate = {
  __typename?: 'CoinPackMaxAggregate';
  amount?: Maybe<Scalars['Decimal']>;
  id?: Maybe<Scalars['String']>;
  isHot?: Maybe<Scalars['Boolean']>;
};

export type CoinPackMaxOrderByAggregateInput = {
  amount?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  isHot?: InputMaybe<SortOrder>;
};

export type CoinPackMinAggregate = {
  __typename?: 'CoinPackMinAggregate';
  amount?: Maybe<Scalars['Decimal']>;
  id?: Maybe<Scalars['String']>;
  isHot?: Maybe<Scalars['Boolean']>;
};

export type CoinPackMinOrderByAggregateInput = {
  amount?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  isHot?: InputMaybe<SortOrder>;
};

export type CoinPackOrderByWithAggregationInput = {
  _avg?: InputMaybe<CoinPackAvgOrderByAggregateInput>;
  _count?: InputMaybe<CoinPackCountOrderByAggregateInput>;
  _max?: InputMaybe<CoinPackMaxOrderByAggregateInput>;
  _min?: InputMaybe<CoinPackMinOrderByAggregateInput>;
  _sum?: InputMaybe<CoinPackSumOrderByAggregateInput>;
  amount?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  isHot?: InputMaybe<SortOrder>;
};

export type CoinPackOrderByWithRelationInput = {
  amount?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  isHot?: InputMaybe<SortOrder>;
};

export enum CoinPackScalarFieldEnum {
  Amount = 'amount',
  Id = 'id',
  IsHot = 'isHot'
}

export type CoinPackScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<CoinPackScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<CoinPackScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<CoinPackScalarWhereWithAggregatesInput>>;
  amount?: InputMaybe<DecimalWithAggregatesFilter>;
  id?: InputMaybe<StringWithAggregatesFilter>;
  isHot?: InputMaybe<BoolWithAggregatesFilter>;
};

export type CoinPackSumAggregate = {
  __typename?: 'CoinPackSumAggregate';
  amount?: Maybe<Scalars['Decimal']>;
};

export type CoinPackSumOrderByAggregateInput = {
  amount?: InputMaybe<SortOrder>;
};

export type CoinPackUpdateInput = {
  amount?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isHot?: InputMaybe<BoolFieldUpdateOperationsInput>;
};

export type CoinPackUpdateManyMutationInput = {
  amount?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isHot?: InputMaybe<BoolFieldUpdateOperationsInput>;
};

export type CoinPackWhereInput = {
  AND?: InputMaybe<Array<CoinPackWhereInput>>;
  NOT?: InputMaybe<Array<CoinPackWhereInput>>;
  OR?: InputMaybe<Array<CoinPackWhereInput>>;
  amount?: InputMaybe<DecimalFilter>;
  id?: InputMaybe<StringFilter>;
  isHot?: InputMaybe<BoolFilter>;
};

export type CoinPackWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
};

export type CoinRelationFilter = {
  is?: InputMaybe<CoinWhereInput>;
  isNot?: InputMaybe<CoinWhereInput>;
};

export enum CoinScalarFieldEnum {
  Amount = 'amount',
  CartId = 'cartId',
  CreatedAt = 'createdAt',
  Id = 'id',
  OrderId = 'orderId',
  OriginalAmount = 'originalAmount',
  PaymentMethod = 'paymentMethod',
  ReadableId = 'readableId',
  Receipt = 'receipt',
  Type = 'type',
  UpdatedAt = 'updatedAt',
  UserId = 'userId'
}

export type CoinScalarWhereInput = {
  AND?: InputMaybe<Array<CoinScalarWhereInput>>;
  NOT?: InputMaybe<Array<CoinScalarWhereInput>>;
  OR?: InputMaybe<Array<CoinScalarWhereInput>>;
  amount?: InputMaybe<DecimalFilter>;
  cartId?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  orderId?: InputMaybe<StringNullableFilter>;
  originalAmount?: InputMaybe<DecimalNullableFilter>;
  paymentMethod?: InputMaybe<EnumPaymentMethodNullableFilter>;
  readableId?: InputMaybe<StringNullableFilter>;
  receipt?: InputMaybe<JsonNullableFilter>;
  type?: InputMaybe<EnumCoinTypeFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  userId?: InputMaybe<StringFilter>;
};

export type CoinScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<CoinScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<CoinScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<CoinScalarWhereWithAggregatesInput>>;
  amount?: InputMaybe<DecimalWithAggregatesFilter>;
  cartId?: InputMaybe<StringNullableWithAggregatesFilter>;
  createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  id?: InputMaybe<StringWithAggregatesFilter>;
  orderId?: InputMaybe<StringNullableWithAggregatesFilter>;
  originalAmount?: InputMaybe<DecimalNullableWithAggregatesFilter>;
  paymentMethod?: InputMaybe<EnumPaymentMethodNullableWithAggregatesFilter>;
  readableId?: InputMaybe<StringNullableWithAggregatesFilter>;
  receipt?: InputMaybe<JsonNullableWithAggregatesFilter>;
  type?: InputMaybe<EnumCoinTypeWithAggregatesFilter>;
  updatedAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  userId?: InputMaybe<StringWithAggregatesFilter>;
};

export type CoinSumAggregate = {
  __typename?: 'CoinSumAggregate';
  amount?: Maybe<Scalars['Decimal']>;
  originalAmount?: Maybe<Scalars['Decimal']>;
};

export type CoinSumOrderByAggregateInput = {
  amount?: InputMaybe<SortOrder>;
  originalAmount?: InputMaybe<SortOrder>;
};

export enum CoinType {
  BuyPostcardWithContent = 'BUY_POSTCARD_WITH_CONTENT',
  CartitemCheckout = 'CARTITEM_CHECKOUT',
  Topup = 'TOPUP'
}

export type CoinUpdateInput = {
  amount?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  benefitMemos?: InputMaybe<BenefitMemoToCoinUpdateManyWithoutCoinNestedInput>;
  cart?: InputMaybe<CartItemUpdateOneWithoutCheckoutNestedInput>;
  couponEntities?: InputMaybe<CouponEntityUpdateManyWithoutConsumeCoinNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  order?: InputMaybe<OrderUpdateOneWithoutCoinNestedInput>;
  originalAmount?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
  paymentMethod?: InputMaybe<NullableEnumPaymentMethodFieldUpdateOperationsInput>;
  readableId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  receipt?: InputMaybe<Scalars['JSON']>;
  type?: InputMaybe<EnumCoinTypeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutCoinsNestedInput>;
};

export type CoinUpdateManyMutationInput = {
  amount?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  originalAmount?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
  paymentMethod?: InputMaybe<NullableEnumPaymentMethodFieldUpdateOperationsInput>;
  readableId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  receipt?: InputMaybe<Scalars['JSON']>;
  type?: InputMaybe<EnumCoinTypeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type CoinUpdateManyWithWhereWithoutUserInput = {
  data: CoinUpdateManyMutationInput;
  where: CoinScalarWhereInput;
};

export type CoinUpdateManyWithoutUserNestedInput = {
  connect?: InputMaybe<Array<CoinWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CoinCreateOrConnectWithoutUserInput>>;
  create?: InputMaybe<Array<CoinCreateWithoutUserInput>>;
  createMany?: InputMaybe<CoinCreateManyUserInputEnvelope>;
  delete?: InputMaybe<Array<CoinWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<CoinScalarWhereInput>>;
  disconnect?: InputMaybe<Array<CoinWhereUniqueInput>>;
  set?: InputMaybe<Array<CoinWhereUniqueInput>>;
  update?: InputMaybe<Array<CoinUpdateWithWhereUniqueWithoutUserInput>>;
  updateMany?: InputMaybe<Array<CoinUpdateManyWithWhereWithoutUserInput>>;
  upsert?: InputMaybe<Array<CoinUpsertWithWhereUniqueWithoutUserInput>>;
};

export type CoinUpdateOneRequiredWithoutBenefitMemosNestedInput = {
  connect?: InputMaybe<CoinWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CoinCreateOrConnectWithoutBenefitMemosInput>;
  create?: InputMaybe<CoinCreateWithoutBenefitMemosInput>;
  update?: InputMaybe<CoinUpdateWithoutBenefitMemosInput>;
  upsert?: InputMaybe<CoinUpsertWithoutBenefitMemosInput>;
};

export type CoinUpdateOneWithoutCartNestedInput = {
  connect?: InputMaybe<CoinWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CoinCreateOrConnectWithoutCartInput>;
  create?: InputMaybe<CoinCreateWithoutCartInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<CoinUpdateWithoutCartInput>;
  upsert?: InputMaybe<CoinUpsertWithoutCartInput>;
};

export type CoinUpdateOneWithoutCouponEntitiesNestedInput = {
  connect?: InputMaybe<CoinWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CoinCreateOrConnectWithoutCouponEntitiesInput>;
  create?: InputMaybe<CoinCreateWithoutCouponEntitiesInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<CoinUpdateWithoutCouponEntitiesInput>;
  upsert?: InputMaybe<CoinUpsertWithoutCouponEntitiesInput>;
};

export type CoinUpdateOneWithoutOrderNestedInput = {
  connect?: InputMaybe<CoinWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CoinCreateOrConnectWithoutOrderInput>;
  create?: InputMaybe<CoinCreateWithoutOrderInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<CoinUpdateWithoutOrderInput>;
  upsert?: InputMaybe<CoinUpsertWithoutOrderInput>;
};

export type CoinUpdateWithWhereUniqueWithoutUserInput = {
  data: CoinUpdateWithoutUserInput;
  where: CoinWhereUniqueInput;
};

export type CoinUpdateWithoutBenefitMemosInput = {
  amount?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  cart?: InputMaybe<CartItemUpdateOneWithoutCheckoutNestedInput>;
  couponEntities?: InputMaybe<CouponEntityUpdateManyWithoutConsumeCoinNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  order?: InputMaybe<OrderUpdateOneWithoutCoinNestedInput>;
  originalAmount?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
  paymentMethod?: InputMaybe<NullableEnumPaymentMethodFieldUpdateOperationsInput>;
  readableId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  receipt?: InputMaybe<Scalars['JSON']>;
  type?: InputMaybe<EnumCoinTypeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutCoinsNestedInput>;
};

export type CoinUpdateWithoutCartInput = {
  amount?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  benefitMemos?: InputMaybe<BenefitMemoToCoinUpdateManyWithoutCoinNestedInput>;
  couponEntities?: InputMaybe<CouponEntityUpdateManyWithoutConsumeCoinNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  order?: InputMaybe<OrderUpdateOneWithoutCoinNestedInput>;
  originalAmount?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
  paymentMethod?: InputMaybe<NullableEnumPaymentMethodFieldUpdateOperationsInput>;
  readableId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  receipt?: InputMaybe<Scalars['JSON']>;
  type?: InputMaybe<EnumCoinTypeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutCoinsNestedInput>;
};

export type CoinUpdateWithoutCouponEntitiesInput = {
  amount?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  benefitMemos?: InputMaybe<BenefitMemoToCoinUpdateManyWithoutCoinNestedInput>;
  cart?: InputMaybe<CartItemUpdateOneWithoutCheckoutNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  order?: InputMaybe<OrderUpdateOneWithoutCoinNestedInput>;
  originalAmount?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
  paymentMethod?: InputMaybe<NullableEnumPaymentMethodFieldUpdateOperationsInput>;
  readableId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  receipt?: InputMaybe<Scalars['JSON']>;
  type?: InputMaybe<EnumCoinTypeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutCoinsNestedInput>;
};

export type CoinUpdateWithoutOrderInput = {
  amount?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  benefitMemos?: InputMaybe<BenefitMemoToCoinUpdateManyWithoutCoinNestedInput>;
  cart?: InputMaybe<CartItemUpdateOneWithoutCheckoutNestedInput>;
  couponEntities?: InputMaybe<CouponEntityUpdateManyWithoutConsumeCoinNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  originalAmount?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
  paymentMethod?: InputMaybe<NullableEnumPaymentMethodFieldUpdateOperationsInput>;
  readableId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  receipt?: InputMaybe<Scalars['JSON']>;
  type?: InputMaybe<EnumCoinTypeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutCoinsNestedInput>;
};

export type CoinUpdateWithoutUserInput = {
  amount?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  benefitMemos?: InputMaybe<BenefitMemoToCoinUpdateManyWithoutCoinNestedInput>;
  cart?: InputMaybe<CartItemUpdateOneWithoutCheckoutNestedInput>;
  couponEntities?: InputMaybe<CouponEntityUpdateManyWithoutConsumeCoinNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  order?: InputMaybe<OrderUpdateOneWithoutCoinNestedInput>;
  originalAmount?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
  paymentMethod?: InputMaybe<NullableEnumPaymentMethodFieldUpdateOperationsInput>;
  readableId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  receipt?: InputMaybe<Scalars['JSON']>;
  type?: InputMaybe<EnumCoinTypeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type CoinUpsertWithWhereUniqueWithoutUserInput = {
  create: CoinCreateWithoutUserInput;
  update: CoinUpdateWithoutUserInput;
  where: CoinWhereUniqueInput;
};

export type CoinUpsertWithoutBenefitMemosInput = {
  create: CoinCreateWithoutBenefitMemosInput;
  update: CoinUpdateWithoutBenefitMemosInput;
};

export type CoinUpsertWithoutCartInput = {
  create: CoinCreateWithoutCartInput;
  update: CoinUpdateWithoutCartInput;
};

export type CoinUpsertWithoutCouponEntitiesInput = {
  create: CoinCreateWithoutCouponEntitiesInput;
  update: CoinUpdateWithoutCouponEntitiesInput;
};

export type CoinUpsertWithoutOrderInput = {
  create: CoinCreateWithoutOrderInput;
  update: CoinUpdateWithoutOrderInput;
};

export type CoinWhereInput = {
  AND?: InputMaybe<Array<CoinWhereInput>>;
  NOT?: InputMaybe<Array<CoinWhereInput>>;
  OR?: InputMaybe<Array<CoinWhereInput>>;
  amount?: InputMaybe<DecimalFilter>;
  benefitMemos?: InputMaybe<BenefitMemoToCoinListRelationFilter>;
  cart?: InputMaybe<CartItemRelationFilter>;
  cartId?: InputMaybe<StringNullableFilter>;
  couponEntities?: InputMaybe<CouponEntityListRelationFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  order?: InputMaybe<OrderRelationFilter>;
  orderId?: InputMaybe<StringNullableFilter>;
  originalAmount?: InputMaybe<DecimalNullableFilter>;
  paymentMethod?: InputMaybe<EnumPaymentMethodNullableFilter>;
  readableId?: InputMaybe<StringNullableFilter>;
  receipt?: InputMaybe<JsonNullableFilter>;
  type?: InputMaybe<EnumCoinTypeFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  user?: InputMaybe<UserRelationFilter>;
  userId?: InputMaybe<StringFilter>;
};

export type CoinWhereUniqueInput = {
  cartId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  orderId?: InputMaybe<Scalars['String']>;
  readableId?: InputMaybe<Scalars['String']>;
};

export type Collection = {
  __typename?: 'Collection';
  collectCountry: Scalars['String'];
  collectDate: Scalars['DateTime'];
  collectPlace?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  favorite: Scalars['Boolean'];
  id: Scalars['String'];
  owner: User;
  ownerId: Scalars['String'];
  postcard?: Maybe<Card>;
  postcardId?: Maybe<Scalars['String']>;
  scenicSpot?: Maybe<ScenicSpot>;
  scenicSpotId?: Maybe<Scalars['String']>;
  stamp?: Maybe<Stamp>;
  stampId?: Maybe<Scalars['String']>;
  type: CollectionType;
  updatedAt: Scalars['DateTime'];
};

export type CollectionCountAggregate = {
  __typename?: 'CollectionCountAggregate';
  _all: Scalars['Int'];
  collectCountry: Scalars['Int'];
  collectDate: Scalars['Int'];
  collectPlace: Scalars['Int'];
  createdAt: Scalars['Int'];
  favorite: Scalars['Int'];
  id: Scalars['Int'];
  ownerId: Scalars['Int'];
  postcardId: Scalars['Int'];
  scenicSpotId: Scalars['Int'];
  stampId: Scalars['Int'];
  type: Scalars['Int'];
  updatedAt: Scalars['Int'];
};

export type CollectionCountOrderByAggregateInput = {
  collectCountry?: InputMaybe<SortOrder>;
  collectDate?: InputMaybe<SortOrder>;
  collectPlace?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  favorite?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  ownerId?: InputMaybe<SortOrder>;
  postcardId?: InputMaybe<SortOrder>;
  scenicSpotId?: InputMaybe<SortOrder>;
  stampId?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type CollectionCreateInput = {
  collectCountry?: InputMaybe<Scalars['String']>;
  collectDate?: InputMaybe<Scalars['DateTime']>;
  collectPlace?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  favorite?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['String']>;
  owner: UserCreateNestedOneWithoutCollectionInput;
  postcard?: InputMaybe<CardCreateNestedOneWithoutCollectionsInput>;
  scenicSpot?: InputMaybe<ScenicSpotCreateNestedOneWithoutCollectionsInput>;
  stamp?: InputMaybe<StampCreateNestedOneWithoutCollectionsInput>;
  type: CollectionType;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type CollectionCreateManyInput = {
  collectCountry?: InputMaybe<Scalars['String']>;
  collectDate?: InputMaybe<Scalars['DateTime']>;
  collectPlace?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  favorite?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['String']>;
  ownerId: Scalars['String'];
  postcardId?: InputMaybe<Scalars['String']>;
  scenicSpotId?: InputMaybe<Scalars['String']>;
  stampId?: InputMaybe<Scalars['String']>;
  type: CollectionType;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type CollectionCreateManyOwnerInput = {
  collectCountry?: InputMaybe<Scalars['String']>;
  collectDate?: InputMaybe<Scalars['DateTime']>;
  collectPlace?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  favorite?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['String']>;
  postcardId?: InputMaybe<Scalars['String']>;
  scenicSpotId?: InputMaybe<Scalars['String']>;
  stampId?: InputMaybe<Scalars['String']>;
  type: CollectionType;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type CollectionCreateManyOwnerInputEnvelope = {
  data: Array<CollectionCreateManyOwnerInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type CollectionCreateManyPostcardInput = {
  collectCountry?: InputMaybe<Scalars['String']>;
  collectDate?: InputMaybe<Scalars['DateTime']>;
  collectPlace?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  favorite?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['String']>;
  ownerId: Scalars['String'];
  scenicSpotId?: InputMaybe<Scalars['String']>;
  stampId?: InputMaybe<Scalars['String']>;
  type: CollectionType;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type CollectionCreateManyPostcardInputEnvelope = {
  data: Array<CollectionCreateManyPostcardInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type CollectionCreateManyScenicSpotInput = {
  collectCountry?: InputMaybe<Scalars['String']>;
  collectDate?: InputMaybe<Scalars['DateTime']>;
  collectPlace?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  favorite?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['String']>;
  ownerId: Scalars['String'];
  postcardId?: InputMaybe<Scalars['String']>;
  stampId?: InputMaybe<Scalars['String']>;
  type: CollectionType;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type CollectionCreateManyScenicSpotInputEnvelope = {
  data: Array<CollectionCreateManyScenicSpotInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type CollectionCreateManyStampInput = {
  collectCountry?: InputMaybe<Scalars['String']>;
  collectDate?: InputMaybe<Scalars['DateTime']>;
  collectPlace?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  favorite?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['String']>;
  ownerId: Scalars['String'];
  postcardId?: InputMaybe<Scalars['String']>;
  scenicSpotId?: InputMaybe<Scalars['String']>;
  type: CollectionType;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type CollectionCreateManyStampInputEnvelope = {
  data: Array<CollectionCreateManyStampInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type CollectionCreateNestedManyWithoutOwnerInput = {
  connect?: InputMaybe<Array<CollectionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CollectionCreateOrConnectWithoutOwnerInput>>;
  create?: InputMaybe<Array<CollectionCreateWithoutOwnerInput>>;
  createMany?: InputMaybe<CollectionCreateManyOwnerInputEnvelope>;
};

export type CollectionCreateNestedManyWithoutPostcardInput = {
  connect?: InputMaybe<Array<CollectionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CollectionCreateOrConnectWithoutPostcardInput>>;
  create?: InputMaybe<Array<CollectionCreateWithoutPostcardInput>>;
  createMany?: InputMaybe<CollectionCreateManyPostcardInputEnvelope>;
};

export type CollectionCreateNestedManyWithoutScenicSpotInput = {
  connect?: InputMaybe<Array<CollectionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CollectionCreateOrConnectWithoutScenicSpotInput>>;
  create?: InputMaybe<Array<CollectionCreateWithoutScenicSpotInput>>;
  createMany?: InputMaybe<CollectionCreateManyScenicSpotInputEnvelope>;
};

export type CollectionCreateNestedManyWithoutStampInput = {
  connect?: InputMaybe<Array<CollectionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CollectionCreateOrConnectWithoutStampInput>>;
  create?: InputMaybe<Array<CollectionCreateWithoutStampInput>>;
  createMany?: InputMaybe<CollectionCreateManyStampInputEnvelope>;
};

export type CollectionCreateOrConnectWithoutOwnerInput = {
  create: CollectionCreateWithoutOwnerInput;
  where: CollectionWhereUniqueInput;
};

export type CollectionCreateOrConnectWithoutPostcardInput = {
  create: CollectionCreateWithoutPostcardInput;
  where: CollectionWhereUniqueInput;
};

export type CollectionCreateOrConnectWithoutScenicSpotInput = {
  create: CollectionCreateWithoutScenicSpotInput;
  where: CollectionWhereUniqueInput;
};

export type CollectionCreateOrConnectWithoutStampInput = {
  create: CollectionCreateWithoutStampInput;
  where: CollectionWhereUniqueInput;
};

export type CollectionCreateWithoutOwnerInput = {
  collectCountry?: InputMaybe<Scalars['String']>;
  collectDate?: InputMaybe<Scalars['DateTime']>;
  collectPlace?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  favorite?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['String']>;
  postcard?: InputMaybe<CardCreateNestedOneWithoutCollectionsInput>;
  scenicSpot?: InputMaybe<ScenicSpotCreateNestedOneWithoutCollectionsInput>;
  stamp?: InputMaybe<StampCreateNestedOneWithoutCollectionsInput>;
  type: CollectionType;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type CollectionCreateWithoutPostcardInput = {
  collectCountry?: InputMaybe<Scalars['String']>;
  collectDate?: InputMaybe<Scalars['DateTime']>;
  collectPlace?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  favorite?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['String']>;
  owner: UserCreateNestedOneWithoutCollectionInput;
  scenicSpot?: InputMaybe<ScenicSpotCreateNestedOneWithoutCollectionsInput>;
  stamp?: InputMaybe<StampCreateNestedOneWithoutCollectionsInput>;
  type: CollectionType;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type CollectionCreateWithoutScenicSpotInput = {
  collectCountry?: InputMaybe<Scalars['String']>;
  collectDate?: InputMaybe<Scalars['DateTime']>;
  collectPlace?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  favorite?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['String']>;
  owner: UserCreateNestedOneWithoutCollectionInput;
  postcard?: InputMaybe<CardCreateNestedOneWithoutCollectionsInput>;
  stamp?: InputMaybe<StampCreateNestedOneWithoutCollectionsInput>;
  type: CollectionType;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type CollectionCreateWithoutStampInput = {
  collectCountry?: InputMaybe<Scalars['String']>;
  collectDate?: InputMaybe<Scalars['DateTime']>;
  collectPlace?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  favorite?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['String']>;
  owner: UserCreateNestedOneWithoutCollectionInput;
  postcard?: InputMaybe<CardCreateNestedOneWithoutCollectionsInput>;
  scenicSpot?: InputMaybe<ScenicSpotCreateNestedOneWithoutCollectionsInput>;
  type: CollectionType;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type CollectionGroupBy = {
  __typename?: 'CollectionGroupBy';
  _count?: Maybe<CollectionCountAggregate>;
  _max?: Maybe<CollectionMaxAggregate>;
  _min?: Maybe<CollectionMinAggregate>;
  collectCountry: Scalars['String'];
  collectDate: Scalars['DateTime'];
  collectPlace?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  favorite: Scalars['Boolean'];
  id: Scalars['String'];
  ownerId: Scalars['String'];
  postcardId?: Maybe<Scalars['String']>;
  scenicSpotId?: Maybe<Scalars['String']>;
  stampId?: Maybe<Scalars['String']>;
  type: CollectionType;
  updatedAt: Scalars['DateTime'];
};

export type CollectionListRelationFilter = {
  every?: InputMaybe<CollectionWhereInput>;
  none?: InputMaybe<CollectionWhereInput>;
  some?: InputMaybe<CollectionWhereInput>;
};

export type CollectionMaxAggregate = {
  __typename?: 'CollectionMaxAggregate';
  collectCountry?: Maybe<Scalars['String']>;
  collectDate?: Maybe<Scalars['DateTime']>;
  collectPlace?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  favorite?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  ownerId?: Maybe<Scalars['String']>;
  postcardId?: Maybe<Scalars['String']>;
  scenicSpotId?: Maybe<Scalars['String']>;
  stampId?: Maybe<Scalars['String']>;
  type?: Maybe<CollectionType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CollectionMaxOrderByAggregateInput = {
  collectCountry?: InputMaybe<SortOrder>;
  collectDate?: InputMaybe<SortOrder>;
  collectPlace?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  favorite?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  ownerId?: InputMaybe<SortOrder>;
  postcardId?: InputMaybe<SortOrder>;
  scenicSpotId?: InputMaybe<SortOrder>;
  stampId?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type CollectionMinAggregate = {
  __typename?: 'CollectionMinAggregate';
  collectCountry?: Maybe<Scalars['String']>;
  collectDate?: Maybe<Scalars['DateTime']>;
  collectPlace?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  favorite?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  ownerId?: Maybe<Scalars['String']>;
  postcardId?: Maybe<Scalars['String']>;
  scenicSpotId?: Maybe<Scalars['String']>;
  stampId?: Maybe<Scalars['String']>;
  type?: Maybe<CollectionType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CollectionMinOrderByAggregateInput = {
  collectCountry?: InputMaybe<SortOrder>;
  collectDate?: InputMaybe<SortOrder>;
  collectPlace?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  favorite?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  ownerId?: InputMaybe<SortOrder>;
  postcardId?: InputMaybe<SortOrder>;
  scenicSpotId?: InputMaybe<SortOrder>;
  stampId?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type CollectionOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type CollectionOrderByWithAggregationInput = {
  _count?: InputMaybe<CollectionCountOrderByAggregateInput>;
  _max?: InputMaybe<CollectionMaxOrderByAggregateInput>;
  _min?: InputMaybe<CollectionMinOrderByAggregateInput>;
  collectCountry?: InputMaybe<SortOrder>;
  collectDate?: InputMaybe<SortOrder>;
  collectPlace?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  favorite?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  ownerId?: InputMaybe<SortOrder>;
  postcardId?: InputMaybe<SortOrder>;
  scenicSpotId?: InputMaybe<SortOrder>;
  stampId?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type CollectionOrderByWithRelationInput = {
  collectCountry?: InputMaybe<SortOrder>;
  collectDate?: InputMaybe<SortOrder>;
  collectPlace?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  favorite?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  owner?: InputMaybe<UserOrderByWithRelationInput>;
  ownerId?: InputMaybe<SortOrder>;
  postcard?: InputMaybe<CardOrderByWithRelationInput>;
  postcardId?: InputMaybe<SortOrder>;
  scenicSpot?: InputMaybe<ScenicSpotOrderByWithRelationInput>;
  scenicSpotId?: InputMaybe<SortOrder>;
  stamp?: InputMaybe<StampOrderByWithRelationInput>;
  stampId?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export enum CollectionScalarFieldEnum {
  CollectCountry = 'collectCountry',
  CollectDate = 'collectDate',
  CollectPlace = 'collectPlace',
  CreatedAt = 'createdAt',
  Favorite = 'favorite',
  Id = 'id',
  OwnerId = 'ownerId',
  PostcardId = 'postcardId',
  ScenicSpotId = 'scenicSpotId',
  StampId = 'stampId',
  Type = 'type',
  UpdatedAt = 'updatedAt'
}

export type CollectionScalarWhereInput = {
  AND?: InputMaybe<Array<CollectionScalarWhereInput>>;
  NOT?: InputMaybe<Array<CollectionScalarWhereInput>>;
  OR?: InputMaybe<Array<CollectionScalarWhereInput>>;
  collectCountry?: InputMaybe<StringFilter>;
  collectDate?: InputMaybe<DateTimeFilter>;
  collectPlace?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  favorite?: InputMaybe<BoolFilter>;
  id?: InputMaybe<StringFilter>;
  ownerId?: InputMaybe<StringFilter>;
  postcardId?: InputMaybe<StringNullableFilter>;
  scenicSpotId?: InputMaybe<StringNullableFilter>;
  stampId?: InputMaybe<StringNullableFilter>;
  type?: InputMaybe<EnumCollectionTypeFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type CollectionScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<CollectionScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<CollectionScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<CollectionScalarWhereWithAggregatesInput>>;
  collectCountry?: InputMaybe<StringWithAggregatesFilter>;
  collectDate?: InputMaybe<DateTimeWithAggregatesFilter>;
  collectPlace?: InputMaybe<StringNullableWithAggregatesFilter>;
  createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  favorite?: InputMaybe<BoolWithAggregatesFilter>;
  id?: InputMaybe<StringWithAggregatesFilter>;
  ownerId?: InputMaybe<StringWithAggregatesFilter>;
  postcardId?: InputMaybe<StringNullableWithAggregatesFilter>;
  scenicSpotId?: InputMaybe<StringNullableWithAggregatesFilter>;
  stampId?: InputMaybe<StringNullableWithAggregatesFilter>;
  type?: InputMaybe<EnumCollectionTypeWithAggregatesFilter>;
  updatedAt?: InputMaybe<DateTimeWithAggregatesFilter>;
};

export enum CollectionType {
  Postcard = 'postcard',
  Stamp = 'stamp'
}

export type CollectionUpdateInput = {
  collectCountry?: InputMaybe<StringFieldUpdateOperationsInput>;
  collectDate?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  collectPlace?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  favorite?: InputMaybe<BoolFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  owner?: InputMaybe<UserUpdateOneRequiredWithoutCollectionNestedInput>;
  postcard?: InputMaybe<CardUpdateOneWithoutCollectionsNestedInput>;
  scenicSpot?: InputMaybe<ScenicSpotUpdateOneWithoutCollectionsNestedInput>;
  stamp?: InputMaybe<StampUpdateOneWithoutCollectionsNestedInput>;
  type?: InputMaybe<EnumCollectionTypeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type CollectionUpdateManyMutationInput = {
  collectCountry?: InputMaybe<StringFieldUpdateOperationsInput>;
  collectDate?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  collectPlace?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  favorite?: InputMaybe<BoolFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  type?: InputMaybe<EnumCollectionTypeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type CollectionUpdateManyWithWhereWithoutOwnerInput = {
  data: CollectionUpdateManyMutationInput;
  where: CollectionScalarWhereInput;
};

export type CollectionUpdateManyWithWhereWithoutPostcardInput = {
  data: CollectionUpdateManyMutationInput;
  where: CollectionScalarWhereInput;
};

export type CollectionUpdateManyWithWhereWithoutScenicSpotInput = {
  data: CollectionUpdateManyMutationInput;
  where: CollectionScalarWhereInput;
};

export type CollectionUpdateManyWithWhereWithoutStampInput = {
  data: CollectionUpdateManyMutationInput;
  where: CollectionScalarWhereInput;
};

export type CollectionUpdateManyWithoutOwnerNestedInput = {
  connect?: InputMaybe<Array<CollectionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CollectionCreateOrConnectWithoutOwnerInput>>;
  create?: InputMaybe<Array<CollectionCreateWithoutOwnerInput>>;
  createMany?: InputMaybe<CollectionCreateManyOwnerInputEnvelope>;
  delete?: InputMaybe<Array<CollectionWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<CollectionScalarWhereInput>>;
  disconnect?: InputMaybe<Array<CollectionWhereUniqueInput>>;
  set?: InputMaybe<Array<CollectionWhereUniqueInput>>;
  update?: InputMaybe<Array<CollectionUpdateWithWhereUniqueWithoutOwnerInput>>;
  updateMany?: InputMaybe<Array<CollectionUpdateManyWithWhereWithoutOwnerInput>>;
  upsert?: InputMaybe<Array<CollectionUpsertWithWhereUniqueWithoutOwnerInput>>;
};

export type CollectionUpdateManyWithoutPostcardNestedInput = {
  connect?: InputMaybe<Array<CollectionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CollectionCreateOrConnectWithoutPostcardInput>>;
  create?: InputMaybe<Array<CollectionCreateWithoutPostcardInput>>;
  createMany?: InputMaybe<CollectionCreateManyPostcardInputEnvelope>;
  delete?: InputMaybe<Array<CollectionWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<CollectionScalarWhereInput>>;
  disconnect?: InputMaybe<Array<CollectionWhereUniqueInput>>;
  set?: InputMaybe<Array<CollectionWhereUniqueInput>>;
  update?: InputMaybe<Array<CollectionUpdateWithWhereUniqueWithoutPostcardInput>>;
  updateMany?: InputMaybe<Array<CollectionUpdateManyWithWhereWithoutPostcardInput>>;
  upsert?: InputMaybe<Array<CollectionUpsertWithWhereUniqueWithoutPostcardInput>>;
};

export type CollectionUpdateManyWithoutScenicSpotNestedInput = {
  connect?: InputMaybe<Array<CollectionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CollectionCreateOrConnectWithoutScenicSpotInput>>;
  create?: InputMaybe<Array<CollectionCreateWithoutScenicSpotInput>>;
  createMany?: InputMaybe<CollectionCreateManyScenicSpotInputEnvelope>;
  delete?: InputMaybe<Array<CollectionWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<CollectionScalarWhereInput>>;
  disconnect?: InputMaybe<Array<CollectionWhereUniqueInput>>;
  set?: InputMaybe<Array<CollectionWhereUniqueInput>>;
  update?: InputMaybe<Array<CollectionUpdateWithWhereUniqueWithoutScenicSpotInput>>;
  updateMany?: InputMaybe<Array<CollectionUpdateManyWithWhereWithoutScenicSpotInput>>;
  upsert?: InputMaybe<Array<CollectionUpsertWithWhereUniqueWithoutScenicSpotInput>>;
};

export type CollectionUpdateManyWithoutStampNestedInput = {
  connect?: InputMaybe<Array<CollectionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CollectionCreateOrConnectWithoutStampInput>>;
  create?: InputMaybe<Array<CollectionCreateWithoutStampInput>>;
  createMany?: InputMaybe<CollectionCreateManyStampInputEnvelope>;
  delete?: InputMaybe<Array<CollectionWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<CollectionScalarWhereInput>>;
  disconnect?: InputMaybe<Array<CollectionWhereUniqueInput>>;
  set?: InputMaybe<Array<CollectionWhereUniqueInput>>;
  update?: InputMaybe<Array<CollectionUpdateWithWhereUniqueWithoutStampInput>>;
  updateMany?: InputMaybe<Array<CollectionUpdateManyWithWhereWithoutStampInput>>;
  upsert?: InputMaybe<Array<CollectionUpsertWithWhereUniqueWithoutStampInput>>;
};

export type CollectionUpdateWithWhereUniqueWithoutOwnerInput = {
  data: CollectionUpdateWithoutOwnerInput;
  where: CollectionWhereUniqueInput;
};

export type CollectionUpdateWithWhereUniqueWithoutPostcardInput = {
  data: CollectionUpdateWithoutPostcardInput;
  where: CollectionWhereUniqueInput;
};

export type CollectionUpdateWithWhereUniqueWithoutScenicSpotInput = {
  data: CollectionUpdateWithoutScenicSpotInput;
  where: CollectionWhereUniqueInput;
};

export type CollectionUpdateWithWhereUniqueWithoutStampInput = {
  data: CollectionUpdateWithoutStampInput;
  where: CollectionWhereUniqueInput;
};

export type CollectionUpdateWithoutOwnerInput = {
  collectCountry?: InputMaybe<StringFieldUpdateOperationsInput>;
  collectDate?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  collectPlace?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  favorite?: InputMaybe<BoolFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  postcard?: InputMaybe<CardUpdateOneWithoutCollectionsNestedInput>;
  scenicSpot?: InputMaybe<ScenicSpotUpdateOneWithoutCollectionsNestedInput>;
  stamp?: InputMaybe<StampUpdateOneWithoutCollectionsNestedInput>;
  type?: InputMaybe<EnumCollectionTypeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type CollectionUpdateWithoutPostcardInput = {
  collectCountry?: InputMaybe<StringFieldUpdateOperationsInput>;
  collectDate?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  collectPlace?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  favorite?: InputMaybe<BoolFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  owner?: InputMaybe<UserUpdateOneRequiredWithoutCollectionNestedInput>;
  scenicSpot?: InputMaybe<ScenicSpotUpdateOneWithoutCollectionsNestedInput>;
  stamp?: InputMaybe<StampUpdateOneWithoutCollectionsNestedInput>;
  type?: InputMaybe<EnumCollectionTypeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type CollectionUpdateWithoutScenicSpotInput = {
  collectCountry?: InputMaybe<StringFieldUpdateOperationsInput>;
  collectDate?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  collectPlace?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  favorite?: InputMaybe<BoolFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  owner?: InputMaybe<UserUpdateOneRequiredWithoutCollectionNestedInput>;
  postcard?: InputMaybe<CardUpdateOneWithoutCollectionsNestedInput>;
  stamp?: InputMaybe<StampUpdateOneWithoutCollectionsNestedInput>;
  type?: InputMaybe<EnumCollectionTypeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type CollectionUpdateWithoutStampInput = {
  collectCountry?: InputMaybe<StringFieldUpdateOperationsInput>;
  collectDate?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  collectPlace?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  favorite?: InputMaybe<BoolFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  owner?: InputMaybe<UserUpdateOneRequiredWithoutCollectionNestedInput>;
  postcard?: InputMaybe<CardUpdateOneWithoutCollectionsNestedInput>;
  scenicSpot?: InputMaybe<ScenicSpotUpdateOneWithoutCollectionsNestedInput>;
  type?: InputMaybe<EnumCollectionTypeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type CollectionUpsertWithWhereUniqueWithoutOwnerInput = {
  create: CollectionCreateWithoutOwnerInput;
  update: CollectionUpdateWithoutOwnerInput;
  where: CollectionWhereUniqueInput;
};

export type CollectionUpsertWithWhereUniqueWithoutPostcardInput = {
  create: CollectionCreateWithoutPostcardInput;
  update: CollectionUpdateWithoutPostcardInput;
  where: CollectionWhereUniqueInput;
};

export type CollectionUpsertWithWhereUniqueWithoutScenicSpotInput = {
  create: CollectionCreateWithoutScenicSpotInput;
  update: CollectionUpdateWithoutScenicSpotInput;
  where: CollectionWhereUniqueInput;
};

export type CollectionUpsertWithWhereUniqueWithoutStampInput = {
  create: CollectionCreateWithoutStampInput;
  update: CollectionUpdateWithoutStampInput;
  where: CollectionWhereUniqueInput;
};

export type CollectionWhereInput = {
  AND?: InputMaybe<Array<CollectionWhereInput>>;
  NOT?: InputMaybe<Array<CollectionWhereInput>>;
  OR?: InputMaybe<Array<CollectionWhereInput>>;
  collectCountry?: InputMaybe<StringFilter>;
  collectDate?: InputMaybe<DateTimeFilter>;
  collectPlace?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  favorite?: InputMaybe<BoolFilter>;
  id?: InputMaybe<StringFilter>;
  owner?: InputMaybe<UserRelationFilter>;
  ownerId?: InputMaybe<StringFilter>;
  postcard?: InputMaybe<CardRelationFilter>;
  postcardId?: InputMaybe<StringNullableFilter>;
  scenicSpot?: InputMaybe<ScenicSpotRelationFilter>;
  scenicSpotId?: InputMaybe<StringNullableFilter>;
  stamp?: InputMaybe<StampRelationFilter>;
  stampId?: InputMaybe<StringNullableFilter>;
  type?: InputMaybe<EnumCollectionTypeFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type CollectionWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
};

export enum ConsumeType {
  DirectPay = 'directPay',
  InAppPurchase = 'inAppPurchase'
}

export type CouponActivity = {
  __typename?: 'CouponActivity';
  card?: Maybe<Card>;
  cardId?: Maybe<Scalars['String']>;
  checkoutAction: CouponCheckoutAction;
  checkoutButton: Scalars['Boolean'];
  confirmCode?: Maybe<Scalars['String']>;
  confirmQrcode?: Maybe<Scalars['String']>;
  couponInfo?: Maybe<CouponInfo>;
  descExchange?: Maybe<Scalars['String']>;
  descQuantity?: Maybe<Scalars['String']>;
  description: Scalars['String'];
  end: Scalars['DateTime'];
  id: Scalars['String'];
  picUri: Scalars['String'];
  stamp?: Maybe<Stamp>;
  stampId?: Maybe<Scalars['String']>;
  start: Scalars['DateTime'];
  type: CouponActivityType;
  valid: Scalars['Boolean'];
};

export type CouponActivityCountAggregate = {
  __typename?: 'CouponActivityCountAggregate';
  _all: Scalars['Int'];
  cardId: Scalars['Int'];
  checkoutAction: Scalars['Int'];
  checkoutButton: Scalars['Int'];
  confirmCode: Scalars['Int'];
  confirmQrcode: Scalars['Int'];
  descExchange: Scalars['Int'];
  descQuantity: Scalars['Int'];
  description: Scalars['Int'];
  end: Scalars['Int'];
  id: Scalars['Int'];
  picUri: Scalars['Int'];
  stampId: Scalars['Int'];
  start: Scalars['Int'];
  type: Scalars['Int'];
  valid: Scalars['Int'];
};

export type CouponActivityCountOrderByAggregateInput = {
  cardId?: InputMaybe<SortOrder>;
  checkoutAction?: InputMaybe<SortOrder>;
  checkoutButton?: InputMaybe<SortOrder>;
  confirmCode?: InputMaybe<SortOrder>;
  confirmQrcode?: InputMaybe<SortOrder>;
  descExchange?: InputMaybe<SortOrder>;
  descQuantity?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  end?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  picUri?: InputMaybe<SortOrder>;
  stampId?: InputMaybe<SortOrder>;
  start?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
  valid?: InputMaybe<SortOrder>;
};

export type CouponActivityCreateInput = {
  card?: InputMaybe<CardCreateNestedOneWithoutCouponActivityInput>;
  checkoutAction?: InputMaybe<CouponCheckoutAction>;
  checkoutButton?: InputMaybe<Scalars['Boolean']>;
  confirmCode?: InputMaybe<Scalars['String']>;
  confirmQrcode?: InputMaybe<Scalars['String']>;
  couponInfo?: InputMaybe<CouponInfoCreateNestedOneWithoutActivityInput>;
  descExchange?: InputMaybe<Scalars['String']>;
  descQuantity?: InputMaybe<Scalars['String']>;
  description: Scalars['String'];
  end: Scalars['DateTime'];
  id?: InputMaybe<Scalars['String']>;
  picUri: Scalars['String'];
  stamp?: InputMaybe<StampCreateNestedOneWithoutCouponActivityInput>;
  start: Scalars['DateTime'];
  type?: InputMaybe<CouponActivityType>;
  valid?: InputMaybe<Scalars['Boolean']>;
};

export type CouponActivityCreateManyInput = {
  cardId?: InputMaybe<Scalars['String']>;
  checkoutAction?: InputMaybe<CouponCheckoutAction>;
  checkoutButton?: InputMaybe<Scalars['Boolean']>;
  confirmCode?: InputMaybe<Scalars['String']>;
  confirmQrcode?: InputMaybe<Scalars['String']>;
  descExchange?: InputMaybe<Scalars['String']>;
  descQuantity?: InputMaybe<Scalars['String']>;
  description: Scalars['String'];
  end: Scalars['DateTime'];
  id?: InputMaybe<Scalars['String']>;
  picUri: Scalars['String'];
  stampId?: InputMaybe<Scalars['String']>;
  start: Scalars['DateTime'];
  type?: InputMaybe<CouponActivityType>;
  valid?: InputMaybe<Scalars['Boolean']>;
};

export type CouponActivityCreateNestedOneWithoutCardInput = {
  connect?: InputMaybe<CouponActivityWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CouponActivityCreateOrConnectWithoutCardInput>;
  create?: InputMaybe<CouponActivityCreateWithoutCardInput>;
};

export type CouponActivityCreateNestedOneWithoutCouponInfoInput = {
  connect?: InputMaybe<CouponActivityWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CouponActivityCreateOrConnectWithoutCouponInfoInput>;
  create?: InputMaybe<CouponActivityCreateWithoutCouponInfoInput>;
};

export type CouponActivityCreateNestedOneWithoutStampInput = {
  connect?: InputMaybe<CouponActivityWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CouponActivityCreateOrConnectWithoutStampInput>;
  create?: InputMaybe<CouponActivityCreateWithoutStampInput>;
};

export type CouponActivityCreateOrConnectWithoutCardInput = {
  create: CouponActivityCreateWithoutCardInput;
  where: CouponActivityWhereUniqueInput;
};

export type CouponActivityCreateOrConnectWithoutCouponInfoInput = {
  create: CouponActivityCreateWithoutCouponInfoInput;
  where: CouponActivityWhereUniqueInput;
};

export type CouponActivityCreateOrConnectWithoutStampInput = {
  create: CouponActivityCreateWithoutStampInput;
  where: CouponActivityWhereUniqueInput;
};

export type CouponActivityCreateWithoutCardInput = {
  checkoutAction?: InputMaybe<CouponCheckoutAction>;
  checkoutButton?: InputMaybe<Scalars['Boolean']>;
  confirmCode?: InputMaybe<Scalars['String']>;
  confirmQrcode?: InputMaybe<Scalars['String']>;
  couponInfo?: InputMaybe<CouponInfoCreateNestedOneWithoutActivityInput>;
  descExchange?: InputMaybe<Scalars['String']>;
  descQuantity?: InputMaybe<Scalars['String']>;
  description: Scalars['String'];
  end: Scalars['DateTime'];
  id?: InputMaybe<Scalars['String']>;
  picUri: Scalars['String'];
  stamp?: InputMaybe<StampCreateNestedOneWithoutCouponActivityInput>;
  start: Scalars['DateTime'];
  type?: InputMaybe<CouponActivityType>;
  valid?: InputMaybe<Scalars['Boolean']>;
};

export type CouponActivityCreateWithoutCouponInfoInput = {
  card?: InputMaybe<CardCreateNestedOneWithoutCouponActivityInput>;
  checkoutAction?: InputMaybe<CouponCheckoutAction>;
  checkoutButton?: InputMaybe<Scalars['Boolean']>;
  confirmCode?: InputMaybe<Scalars['String']>;
  confirmQrcode?: InputMaybe<Scalars['String']>;
  descExchange?: InputMaybe<Scalars['String']>;
  descQuantity?: InputMaybe<Scalars['String']>;
  description: Scalars['String'];
  end: Scalars['DateTime'];
  id?: InputMaybe<Scalars['String']>;
  picUri: Scalars['String'];
  stamp?: InputMaybe<StampCreateNestedOneWithoutCouponActivityInput>;
  start: Scalars['DateTime'];
  type?: InputMaybe<CouponActivityType>;
  valid?: InputMaybe<Scalars['Boolean']>;
};

export type CouponActivityCreateWithoutStampInput = {
  card?: InputMaybe<CardCreateNestedOneWithoutCouponActivityInput>;
  checkoutAction?: InputMaybe<CouponCheckoutAction>;
  checkoutButton?: InputMaybe<Scalars['Boolean']>;
  confirmCode?: InputMaybe<Scalars['String']>;
  confirmQrcode?: InputMaybe<Scalars['String']>;
  couponInfo?: InputMaybe<CouponInfoCreateNestedOneWithoutActivityInput>;
  descExchange?: InputMaybe<Scalars['String']>;
  descQuantity?: InputMaybe<Scalars['String']>;
  description: Scalars['String'];
  end: Scalars['DateTime'];
  id?: InputMaybe<Scalars['String']>;
  picUri: Scalars['String'];
  start: Scalars['DateTime'];
  type?: InputMaybe<CouponActivityType>;
  valid?: InputMaybe<Scalars['Boolean']>;
};

export type CouponActivityGroupBy = {
  __typename?: 'CouponActivityGroupBy';
  _count?: Maybe<CouponActivityCountAggregate>;
  _max?: Maybe<CouponActivityMaxAggregate>;
  _min?: Maybe<CouponActivityMinAggregate>;
  cardId?: Maybe<Scalars['String']>;
  checkoutAction: CouponCheckoutAction;
  checkoutButton: Scalars['Boolean'];
  confirmCode?: Maybe<Scalars['String']>;
  confirmQrcode?: Maybe<Scalars['String']>;
  descExchange?: Maybe<Scalars['String']>;
  descQuantity?: Maybe<Scalars['String']>;
  description: Scalars['String'];
  end: Scalars['DateTime'];
  id: Scalars['String'];
  picUri: Scalars['String'];
  stampId?: Maybe<Scalars['String']>;
  start: Scalars['DateTime'];
  type: CouponActivityType;
  valid: Scalars['Boolean'];
};

export type CouponActivityMaxAggregate = {
  __typename?: 'CouponActivityMaxAggregate';
  cardId?: Maybe<Scalars['String']>;
  checkoutAction?: Maybe<CouponCheckoutAction>;
  checkoutButton?: Maybe<Scalars['Boolean']>;
  confirmCode?: Maybe<Scalars['String']>;
  confirmQrcode?: Maybe<Scalars['String']>;
  descExchange?: Maybe<Scalars['String']>;
  descQuantity?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  end?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  picUri?: Maybe<Scalars['String']>;
  stampId?: Maybe<Scalars['String']>;
  start?: Maybe<Scalars['DateTime']>;
  type?: Maybe<CouponActivityType>;
  valid?: Maybe<Scalars['Boolean']>;
};

export type CouponActivityMaxOrderByAggregateInput = {
  cardId?: InputMaybe<SortOrder>;
  checkoutAction?: InputMaybe<SortOrder>;
  checkoutButton?: InputMaybe<SortOrder>;
  confirmCode?: InputMaybe<SortOrder>;
  confirmQrcode?: InputMaybe<SortOrder>;
  descExchange?: InputMaybe<SortOrder>;
  descQuantity?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  end?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  picUri?: InputMaybe<SortOrder>;
  stampId?: InputMaybe<SortOrder>;
  start?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
  valid?: InputMaybe<SortOrder>;
};

export type CouponActivityMinAggregate = {
  __typename?: 'CouponActivityMinAggregate';
  cardId?: Maybe<Scalars['String']>;
  checkoutAction?: Maybe<CouponCheckoutAction>;
  checkoutButton?: Maybe<Scalars['Boolean']>;
  confirmCode?: Maybe<Scalars['String']>;
  confirmQrcode?: Maybe<Scalars['String']>;
  descExchange?: Maybe<Scalars['String']>;
  descQuantity?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  end?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  picUri?: Maybe<Scalars['String']>;
  stampId?: Maybe<Scalars['String']>;
  start?: Maybe<Scalars['DateTime']>;
  type?: Maybe<CouponActivityType>;
  valid?: Maybe<Scalars['Boolean']>;
};

export type CouponActivityMinOrderByAggregateInput = {
  cardId?: InputMaybe<SortOrder>;
  checkoutAction?: InputMaybe<SortOrder>;
  checkoutButton?: InputMaybe<SortOrder>;
  confirmCode?: InputMaybe<SortOrder>;
  confirmQrcode?: InputMaybe<SortOrder>;
  descExchange?: InputMaybe<SortOrder>;
  descQuantity?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  end?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  picUri?: InputMaybe<SortOrder>;
  stampId?: InputMaybe<SortOrder>;
  start?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
  valid?: InputMaybe<SortOrder>;
};

export type CouponActivityOrderByWithAggregationInput = {
  _count?: InputMaybe<CouponActivityCountOrderByAggregateInput>;
  _max?: InputMaybe<CouponActivityMaxOrderByAggregateInput>;
  _min?: InputMaybe<CouponActivityMinOrderByAggregateInput>;
  cardId?: InputMaybe<SortOrder>;
  checkoutAction?: InputMaybe<SortOrder>;
  checkoutButton?: InputMaybe<SortOrder>;
  confirmCode?: InputMaybe<SortOrder>;
  confirmQrcode?: InputMaybe<SortOrder>;
  descExchange?: InputMaybe<SortOrder>;
  descQuantity?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  end?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  picUri?: InputMaybe<SortOrder>;
  stampId?: InputMaybe<SortOrder>;
  start?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
  valid?: InputMaybe<SortOrder>;
};

export type CouponActivityOrderByWithRelationInput = {
  card?: InputMaybe<CardOrderByWithRelationInput>;
  cardId?: InputMaybe<SortOrder>;
  checkoutAction?: InputMaybe<SortOrder>;
  checkoutButton?: InputMaybe<SortOrder>;
  confirmCode?: InputMaybe<SortOrder>;
  confirmQrcode?: InputMaybe<SortOrder>;
  couponInfo?: InputMaybe<CouponInfoOrderByWithRelationInput>;
  descExchange?: InputMaybe<SortOrder>;
  descQuantity?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  end?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  picUri?: InputMaybe<SortOrder>;
  stamp?: InputMaybe<StampOrderByWithRelationInput>;
  stampId?: InputMaybe<SortOrder>;
  start?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
  valid?: InputMaybe<SortOrder>;
};

export type CouponActivityRelationFilter = {
  is?: InputMaybe<CouponActivityWhereInput>;
  isNot?: InputMaybe<CouponActivityWhereInput>;
};

export enum CouponActivityScalarFieldEnum {
  CardId = 'cardId',
  CheckoutAction = 'checkoutAction',
  CheckoutButton = 'checkoutButton',
  ConfirmCode = 'confirmCode',
  ConfirmQrcode = 'confirmQrcode',
  DescExchange = 'descExchange',
  DescQuantity = 'descQuantity',
  Description = 'description',
  End = 'end',
  Id = 'id',
  PicUri = 'picUri',
  StampId = 'stampId',
  Start = 'start',
  Type = 'type',
  Valid = 'valid'
}

export type CouponActivityScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<CouponActivityScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<CouponActivityScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<CouponActivityScalarWhereWithAggregatesInput>>;
  cardId?: InputMaybe<StringNullableWithAggregatesFilter>;
  checkoutAction?: InputMaybe<EnumCouponCheckoutActionWithAggregatesFilter>;
  checkoutButton?: InputMaybe<BoolWithAggregatesFilter>;
  confirmCode?: InputMaybe<StringNullableWithAggregatesFilter>;
  confirmQrcode?: InputMaybe<StringNullableWithAggregatesFilter>;
  descExchange?: InputMaybe<StringNullableWithAggregatesFilter>;
  descQuantity?: InputMaybe<StringNullableWithAggregatesFilter>;
  description?: InputMaybe<StringWithAggregatesFilter>;
  end?: InputMaybe<DateTimeWithAggregatesFilter>;
  id?: InputMaybe<StringWithAggregatesFilter>;
  picUri?: InputMaybe<StringWithAggregatesFilter>;
  stampId?: InputMaybe<StringNullableWithAggregatesFilter>;
  start?: InputMaybe<DateTimeWithAggregatesFilter>;
  type?: InputMaybe<EnumCouponActivityTypeWithAggregatesFilter>;
  valid?: InputMaybe<BoolWithAggregatesFilter>;
};

export enum CouponActivityType {
  CouponPostcard = 'couponPostcard',
  CouponStamp = 'couponStamp'
}

export type CouponActivityUpdateInput = {
  card?: InputMaybe<CardUpdateOneWithoutCouponActivityNestedInput>;
  checkoutAction?: InputMaybe<EnumCouponCheckoutActionFieldUpdateOperationsInput>;
  checkoutButton?: InputMaybe<BoolFieldUpdateOperationsInput>;
  confirmCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  confirmQrcode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  couponInfo?: InputMaybe<CouponInfoUpdateOneWithoutActivityNestedInput>;
  descExchange?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  descQuantity?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  description?: InputMaybe<StringFieldUpdateOperationsInput>;
  end?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  picUri?: InputMaybe<StringFieldUpdateOperationsInput>;
  stamp?: InputMaybe<StampUpdateOneWithoutCouponActivityNestedInput>;
  start?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  type?: InputMaybe<EnumCouponActivityTypeFieldUpdateOperationsInput>;
  valid?: InputMaybe<BoolFieldUpdateOperationsInput>;
};

export type CouponActivityUpdateManyMutationInput = {
  checkoutAction?: InputMaybe<EnumCouponCheckoutActionFieldUpdateOperationsInput>;
  checkoutButton?: InputMaybe<BoolFieldUpdateOperationsInput>;
  confirmCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  confirmQrcode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  descExchange?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  descQuantity?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  description?: InputMaybe<StringFieldUpdateOperationsInput>;
  end?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  picUri?: InputMaybe<StringFieldUpdateOperationsInput>;
  start?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  type?: InputMaybe<EnumCouponActivityTypeFieldUpdateOperationsInput>;
  valid?: InputMaybe<BoolFieldUpdateOperationsInput>;
};

export type CouponActivityUpdateOneWithoutCardNestedInput = {
  connect?: InputMaybe<CouponActivityWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CouponActivityCreateOrConnectWithoutCardInput>;
  create?: InputMaybe<CouponActivityCreateWithoutCardInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<CouponActivityUpdateWithoutCardInput>;
  upsert?: InputMaybe<CouponActivityUpsertWithoutCardInput>;
};

export type CouponActivityUpdateOneWithoutCouponInfoNestedInput = {
  connect?: InputMaybe<CouponActivityWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CouponActivityCreateOrConnectWithoutCouponInfoInput>;
  create?: InputMaybe<CouponActivityCreateWithoutCouponInfoInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<CouponActivityUpdateWithoutCouponInfoInput>;
  upsert?: InputMaybe<CouponActivityUpsertWithoutCouponInfoInput>;
};

export type CouponActivityUpdateOneWithoutStampNestedInput = {
  connect?: InputMaybe<CouponActivityWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CouponActivityCreateOrConnectWithoutStampInput>;
  create?: InputMaybe<CouponActivityCreateWithoutStampInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<CouponActivityUpdateWithoutStampInput>;
  upsert?: InputMaybe<CouponActivityUpsertWithoutStampInput>;
};

export type CouponActivityUpdateWithoutCardInput = {
  checkoutAction?: InputMaybe<EnumCouponCheckoutActionFieldUpdateOperationsInput>;
  checkoutButton?: InputMaybe<BoolFieldUpdateOperationsInput>;
  confirmCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  confirmQrcode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  couponInfo?: InputMaybe<CouponInfoUpdateOneWithoutActivityNestedInput>;
  descExchange?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  descQuantity?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  description?: InputMaybe<StringFieldUpdateOperationsInput>;
  end?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  picUri?: InputMaybe<StringFieldUpdateOperationsInput>;
  stamp?: InputMaybe<StampUpdateOneWithoutCouponActivityNestedInput>;
  start?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  type?: InputMaybe<EnumCouponActivityTypeFieldUpdateOperationsInput>;
  valid?: InputMaybe<BoolFieldUpdateOperationsInput>;
};

export type CouponActivityUpdateWithoutCouponInfoInput = {
  card?: InputMaybe<CardUpdateOneWithoutCouponActivityNestedInput>;
  checkoutAction?: InputMaybe<EnumCouponCheckoutActionFieldUpdateOperationsInput>;
  checkoutButton?: InputMaybe<BoolFieldUpdateOperationsInput>;
  confirmCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  confirmQrcode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  descExchange?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  descQuantity?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  description?: InputMaybe<StringFieldUpdateOperationsInput>;
  end?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  picUri?: InputMaybe<StringFieldUpdateOperationsInput>;
  stamp?: InputMaybe<StampUpdateOneWithoutCouponActivityNestedInput>;
  start?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  type?: InputMaybe<EnumCouponActivityTypeFieldUpdateOperationsInput>;
  valid?: InputMaybe<BoolFieldUpdateOperationsInput>;
};

export type CouponActivityUpdateWithoutStampInput = {
  card?: InputMaybe<CardUpdateOneWithoutCouponActivityNestedInput>;
  checkoutAction?: InputMaybe<EnumCouponCheckoutActionFieldUpdateOperationsInput>;
  checkoutButton?: InputMaybe<BoolFieldUpdateOperationsInput>;
  confirmCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  confirmQrcode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  couponInfo?: InputMaybe<CouponInfoUpdateOneWithoutActivityNestedInput>;
  descExchange?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  descQuantity?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  description?: InputMaybe<StringFieldUpdateOperationsInput>;
  end?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  picUri?: InputMaybe<StringFieldUpdateOperationsInput>;
  start?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  type?: InputMaybe<EnumCouponActivityTypeFieldUpdateOperationsInput>;
  valid?: InputMaybe<BoolFieldUpdateOperationsInput>;
};

export type CouponActivityUpsertWithoutCardInput = {
  create: CouponActivityCreateWithoutCardInput;
  update: CouponActivityUpdateWithoutCardInput;
};

export type CouponActivityUpsertWithoutCouponInfoInput = {
  create: CouponActivityCreateWithoutCouponInfoInput;
  update: CouponActivityUpdateWithoutCouponInfoInput;
};

export type CouponActivityUpsertWithoutStampInput = {
  create: CouponActivityCreateWithoutStampInput;
  update: CouponActivityUpdateWithoutStampInput;
};

export type CouponActivityWhereInput = {
  AND?: InputMaybe<Array<CouponActivityWhereInput>>;
  NOT?: InputMaybe<Array<CouponActivityWhereInput>>;
  OR?: InputMaybe<Array<CouponActivityWhereInput>>;
  card?: InputMaybe<CardRelationFilter>;
  cardId?: InputMaybe<StringNullableFilter>;
  checkoutAction?: InputMaybe<EnumCouponCheckoutActionFilter>;
  checkoutButton?: InputMaybe<BoolFilter>;
  confirmCode?: InputMaybe<StringNullableFilter>;
  confirmQrcode?: InputMaybe<StringNullableFilter>;
  couponInfo?: InputMaybe<CouponInfoRelationFilter>;
  descExchange?: InputMaybe<StringNullableFilter>;
  descQuantity?: InputMaybe<StringNullableFilter>;
  description?: InputMaybe<StringFilter>;
  end?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  picUri?: InputMaybe<StringFilter>;
  stamp?: InputMaybe<StampRelationFilter>;
  stampId?: InputMaybe<StringNullableFilter>;
  start?: InputMaybe<DateTimeFilter>;
  type?: InputMaybe<EnumCouponActivityTypeFilter>;
  valid?: InputMaybe<BoolFilter>;
};

export type CouponActivityWhereUniqueInput = {
  cardId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  stampId?: InputMaybe<Scalars['String']>;
};

export enum CouponCheckoutAction {
  FormNanhui2022 = 'formNanhui2022',
  FreeSending = 'freeSending',
  InputCode = 'inputCode'
}

export type CouponEntity = {
  __typename?: 'CouponEntity';
  _count?: Maybe<CouponEntityCount>;
  benefitMemoToCoin: Array<BenefitMemoToCoin>;
  benefitMemoToOrder: Array<BenefitMemoToOrder>;
  code?: Maybe<Scalars['String']>;
  consumeCoin?: Maybe<Coin>;
  consumeCoinId?: Maybe<Scalars['String']>;
  consumeOrder?: Maybe<Order>;
  consumeOrderId?: Maybe<Scalars['String']>;
  holder?: Maybe<User>;
  holderId?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  inCartItem?: Maybe<CartItem>;
  inCartItemId?: Maybe<Scalars['String']>;
  inPostPackage?: Maybe<PostPackage>;
  inPostPackageId?: Maybe<Scalars['String']>;
  info: CouponInfo;
  infoId: Scalars['String'];
  memo?: Maybe<Scalars['String']>;
  seq: Scalars['Int'];
  usageHistory: Array<CouponUsage>;
  used: Scalars['Boolean'];
  valid: Scalars['Boolean'];
};


export type CouponEntityBenefitMemoToCoinArgs = {
  cursor?: InputMaybe<BenefitMemoToCoinWhereUniqueInput>;
  distinct?: InputMaybe<Array<BenefitMemoToCoinScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<BenefitMemoToCoinOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<BenefitMemoToCoinWhereInput>;
};


export type CouponEntityBenefitMemoToOrderArgs = {
  cursor?: InputMaybe<BenefitMemoToOrderWhereUniqueInput>;
  distinct?: InputMaybe<Array<BenefitMemoToOrderScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<BenefitMemoToOrderOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<BenefitMemoToOrderWhereInput>;
};


export type CouponEntityUsageHistoryArgs = {
  cursor?: InputMaybe<CouponUsageWhereUniqueInput>;
  distinct?: InputMaybe<Array<CouponUsageScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<CouponUsageOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CouponUsageWhereInput>;
};

export type CouponEntityAvgAggregate = {
  __typename?: 'CouponEntityAvgAggregate';
  seq?: Maybe<Scalars['Float']>;
};

export type CouponEntityAvgOrderByAggregateInput = {
  seq?: InputMaybe<SortOrder>;
};

export type CouponEntityCount = {
  __typename?: 'CouponEntityCount';
  benefitMemoToCoin: Scalars['Int'];
  benefitMemoToOrder: Scalars['Int'];
  usageHistory: Scalars['Int'];
};


export type CouponEntityCountBenefitMemoToCoinArgs = {
  where?: InputMaybe<BenefitMemoToCoinWhereInput>;
};


export type CouponEntityCountBenefitMemoToOrderArgs = {
  where?: InputMaybe<BenefitMemoToOrderWhereInput>;
};


export type CouponEntityCountUsageHistoryArgs = {
  where?: InputMaybe<CouponUsageWhereInput>;
};

export type CouponEntityCountAggregate = {
  __typename?: 'CouponEntityCountAggregate';
  _all: Scalars['Int'];
  code: Scalars['Int'];
  consumeCoinId: Scalars['Int'];
  consumeOrderId: Scalars['Int'];
  holderId: Scalars['Int'];
  id: Scalars['Int'];
  inCartItemId: Scalars['Int'];
  inPostPackageId: Scalars['Int'];
  infoId: Scalars['Int'];
  memo: Scalars['Int'];
  seq: Scalars['Int'];
  used: Scalars['Int'];
  valid: Scalars['Int'];
};

export type CouponEntityCountOrderByAggregateInput = {
  code?: InputMaybe<SortOrder>;
  consumeCoinId?: InputMaybe<SortOrder>;
  consumeOrderId?: InputMaybe<SortOrder>;
  holderId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  inCartItemId?: InputMaybe<SortOrder>;
  inPostPackageId?: InputMaybe<SortOrder>;
  infoId?: InputMaybe<SortOrder>;
  memo?: InputMaybe<SortOrder>;
  seq?: InputMaybe<SortOrder>;
  used?: InputMaybe<SortOrder>;
  valid?: InputMaybe<SortOrder>;
};

export type CouponEntityCreateInput = {
  benefitMemoToCoin?: InputMaybe<BenefitMemoToCoinCreateNestedManyWithoutCouponEntitiesInput>;
  benefitMemoToOrder?: InputMaybe<BenefitMemoToOrderCreateNestedManyWithoutCouponEntitiesInput>;
  code?: InputMaybe<Scalars['String']>;
  consumeCoin?: InputMaybe<CoinCreateNestedOneWithoutCouponEntitiesInput>;
  consumeOrder?: InputMaybe<OrderCreateNestedOneWithoutCouponEntitiesInput>;
  holder?: InputMaybe<UserCreateNestedOneWithoutMyCouponsInput>;
  id?: InputMaybe<Scalars['String']>;
  inCartItem?: InputMaybe<CartItemCreateNestedOneWithoutCouponEntitiesInput>;
  inPostPackage?: InputMaybe<PostPackageCreateNestedOneWithoutCouponEntitiesInput>;
  info: CouponInfoCreateNestedOneWithoutCouponEntitiesInput;
  memo?: InputMaybe<Scalars['String']>;
  seq: Scalars['Int'];
  usageHistory?: InputMaybe<CouponUsageCreateNestedManyWithoutCouponInput>;
  used?: InputMaybe<Scalars['Boolean']>;
  valid?: InputMaybe<Scalars['Boolean']>;
};

export type CouponEntityCreateManyConsumeCoinInput = {
  code?: InputMaybe<Scalars['String']>;
  consumeOrderId?: InputMaybe<Scalars['String']>;
  holderId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  inCartItemId?: InputMaybe<Scalars['String']>;
  inPostPackageId?: InputMaybe<Scalars['String']>;
  infoId: Scalars['String'];
  memo?: InputMaybe<Scalars['String']>;
  seq: Scalars['Int'];
  used?: InputMaybe<Scalars['Boolean']>;
  valid?: InputMaybe<Scalars['Boolean']>;
};

export type CouponEntityCreateManyConsumeCoinInputEnvelope = {
  data: Array<CouponEntityCreateManyConsumeCoinInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type CouponEntityCreateManyConsumeOrderInput = {
  code?: InputMaybe<Scalars['String']>;
  consumeCoinId?: InputMaybe<Scalars['String']>;
  holderId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  inCartItemId?: InputMaybe<Scalars['String']>;
  inPostPackageId?: InputMaybe<Scalars['String']>;
  infoId: Scalars['String'];
  memo?: InputMaybe<Scalars['String']>;
  seq: Scalars['Int'];
  used?: InputMaybe<Scalars['Boolean']>;
  valid?: InputMaybe<Scalars['Boolean']>;
};

export type CouponEntityCreateManyConsumeOrderInputEnvelope = {
  data: Array<CouponEntityCreateManyConsumeOrderInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type CouponEntityCreateManyHolderInput = {
  code?: InputMaybe<Scalars['String']>;
  consumeCoinId?: InputMaybe<Scalars['String']>;
  consumeOrderId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  inCartItemId?: InputMaybe<Scalars['String']>;
  inPostPackageId?: InputMaybe<Scalars['String']>;
  infoId: Scalars['String'];
  memo?: InputMaybe<Scalars['String']>;
  seq: Scalars['Int'];
  used?: InputMaybe<Scalars['Boolean']>;
  valid?: InputMaybe<Scalars['Boolean']>;
};

export type CouponEntityCreateManyHolderInputEnvelope = {
  data: Array<CouponEntityCreateManyHolderInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type CouponEntityCreateManyInCartItemInput = {
  code?: InputMaybe<Scalars['String']>;
  consumeCoinId?: InputMaybe<Scalars['String']>;
  consumeOrderId?: InputMaybe<Scalars['String']>;
  holderId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  inPostPackageId?: InputMaybe<Scalars['String']>;
  infoId: Scalars['String'];
  memo?: InputMaybe<Scalars['String']>;
  seq: Scalars['Int'];
  used?: InputMaybe<Scalars['Boolean']>;
  valid?: InputMaybe<Scalars['Boolean']>;
};

export type CouponEntityCreateManyInCartItemInputEnvelope = {
  data: Array<CouponEntityCreateManyInCartItemInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type CouponEntityCreateManyInPostPackageInput = {
  code?: InputMaybe<Scalars['String']>;
  consumeCoinId?: InputMaybe<Scalars['String']>;
  consumeOrderId?: InputMaybe<Scalars['String']>;
  holderId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  inCartItemId?: InputMaybe<Scalars['String']>;
  infoId: Scalars['String'];
  memo?: InputMaybe<Scalars['String']>;
  seq: Scalars['Int'];
  used?: InputMaybe<Scalars['Boolean']>;
  valid?: InputMaybe<Scalars['Boolean']>;
};

export type CouponEntityCreateManyInPostPackageInputEnvelope = {
  data: Array<CouponEntityCreateManyInPostPackageInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type CouponEntityCreateManyInfoInput = {
  code?: InputMaybe<Scalars['String']>;
  consumeCoinId?: InputMaybe<Scalars['String']>;
  consumeOrderId?: InputMaybe<Scalars['String']>;
  holderId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  inCartItemId?: InputMaybe<Scalars['String']>;
  inPostPackageId?: InputMaybe<Scalars['String']>;
  memo?: InputMaybe<Scalars['String']>;
  seq: Scalars['Int'];
  used?: InputMaybe<Scalars['Boolean']>;
  valid?: InputMaybe<Scalars['Boolean']>;
};

export type CouponEntityCreateManyInfoInputEnvelope = {
  data: Array<CouponEntityCreateManyInfoInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type CouponEntityCreateManyInput = {
  code?: InputMaybe<Scalars['String']>;
  consumeCoinId?: InputMaybe<Scalars['String']>;
  consumeOrderId?: InputMaybe<Scalars['String']>;
  holderId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  inCartItemId?: InputMaybe<Scalars['String']>;
  inPostPackageId?: InputMaybe<Scalars['String']>;
  infoId: Scalars['String'];
  memo?: InputMaybe<Scalars['String']>;
  seq: Scalars['Int'];
  used?: InputMaybe<Scalars['Boolean']>;
  valid?: InputMaybe<Scalars['Boolean']>;
};

export type CouponEntityCreateNestedManyWithoutBenefitMemoToCoinInput = {
  connect?: InputMaybe<Array<CouponEntityWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CouponEntityCreateOrConnectWithoutBenefitMemoToCoinInput>>;
  create?: InputMaybe<Array<CouponEntityCreateWithoutBenefitMemoToCoinInput>>;
};

export type CouponEntityCreateNestedManyWithoutBenefitMemoToOrderInput = {
  connect?: InputMaybe<Array<CouponEntityWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CouponEntityCreateOrConnectWithoutBenefitMemoToOrderInput>>;
  create?: InputMaybe<Array<CouponEntityCreateWithoutBenefitMemoToOrderInput>>;
};

export type CouponEntityCreateNestedManyWithoutConsumeCoinInput = {
  connect?: InputMaybe<Array<CouponEntityWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CouponEntityCreateOrConnectWithoutConsumeCoinInput>>;
  create?: InputMaybe<Array<CouponEntityCreateWithoutConsumeCoinInput>>;
  createMany?: InputMaybe<CouponEntityCreateManyConsumeCoinInputEnvelope>;
};

export type CouponEntityCreateNestedManyWithoutConsumeOrderInput = {
  connect?: InputMaybe<Array<CouponEntityWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CouponEntityCreateOrConnectWithoutConsumeOrderInput>>;
  create?: InputMaybe<Array<CouponEntityCreateWithoutConsumeOrderInput>>;
  createMany?: InputMaybe<CouponEntityCreateManyConsumeOrderInputEnvelope>;
};

export type CouponEntityCreateNestedManyWithoutHolderInput = {
  connect?: InputMaybe<Array<CouponEntityWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CouponEntityCreateOrConnectWithoutHolderInput>>;
  create?: InputMaybe<Array<CouponEntityCreateWithoutHolderInput>>;
  createMany?: InputMaybe<CouponEntityCreateManyHolderInputEnvelope>;
};

export type CouponEntityCreateNestedManyWithoutInCartItemInput = {
  connect?: InputMaybe<Array<CouponEntityWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CouponEntityCreateOrConnectWithoutInCartItemInput>>;
  create?: InputMaybe<Array<CouponEntityCreateWithoutInCartItemInput>>;
  createMany?: InputMaybe<CouponEntityCreateManyInCartItemInputEnvelope>;
};

export type CouponEntityCreateNestedManyWithoutInPostPackageInput = {
  connect?: InputMaybe<Array<CouponEntityWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CouponEntityCreateOrConnectWithoutInPostPackageInput>>;
  create?: InputMaybe<Array<CouponEntityCreateWithoutInPostPackageInput>>;
  createMany?: InputMaybe<CouponEntityCreateManyInPostPackageInputEnvelope>;
};

export type CouponEntityCreateNestedManyWithoutInfoInput = {
  connect?: InputMaybe<Array<CouponEntityWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CouponEntityCreateOrConnectWithoutInfoInput>>;
  create?: InputMaybe<Array<CouponEntityCreateWithoutInfoInput>>;
  createMany?: InputMaybe<CouponEntityCreateManyInfoInputEnvelope>;
};

export type CouponEntityCreateNestedOneWithoutUsageHistoryInput = {
  connect?: InputMaybe<CouponEntityWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CouponEntityCreateOrConnectWithoutUsageHistoryInput>;
  create?: InputMaybe<CouponEntityCreateWithoutUsageHistoryInput>;
};

export type CouponEntityCreateOrConnectWithoutBenefitMemoToCoinInput = {
  create: CouponEntityCreateWithoutBenefitMemoToCoinInput;
  where: CouponEntityWhereUniqueInput;
};

export type CouponEntityCreateOrConnectWithoutBenefitMemoToOrderInput = {
  create: CouponEntityCreateWithoutBenefitMemoToOrderInput;
  where: CouponEntityWhereUniqueInput;
};

export type CouponEntityCreateOrConnectWithoutConsumeCoinInput = {
  create: CouponEntityCreateWithoutConsumeCoinInput;
  where: CouponEntityWhereUniqueInput;
};

export type CouponEntityCreateOrConnectWithoutConsumeOrderInput = {
  create: CouponEntityCreateWithoutConsumeOrderInput;
  where: CouponEntityWhereUniqueInput;
};

export type CouponEntityCreateOrConnectWithoutHolderInput = {
  create: CouponEntityCreateWithoutHolderInput;
  where: CouponEntityWhereUniqueInput;
};

export type CouponEntityCreateOrConnectWithoutInCartItemInput = {
  create: CouponEntityCreateWithoutInCartItemInput;
  where: CouponEntityWhereUniqueInput;
};

export type CouponEntityCreateOrConnectWithoutInPostPackageInput = {
  create: CouponEntityCreateWithoutInPostPackageInput;
  where: CouponEntityWhereUniqueInput;
};

export type CouponEntityCreateOrConnectWithoutInfoInput = {
  create: CouponEntityCreateWithoutInfoInput;
  where: CouponEntityWhereUniqueInput;
};

export type CouponEntityCreateOrConnectWithoutUsageHistoryInput = {
  create: CouponEntityCreateWithoutUsageHistoryInput;
  where: CouponEntityWhereUniqueInput;
};

export type CouponEntityCreateWithoutBenefitMemoToCoinInput = {
  benefitMemoToOrder?: InputMaybe<BenefitMemoToOrderCreateNestedManyWithoutCouponEntitiesInput>;
  code?: InputMaybe<Scalars['String']>;
  consumeCoin?: InputMaybe<CoinCreateNestedOneWithoutCouponEntitiesInput>;
  consumeOrder?: InputMaybe<OrderCreateNestedOneWithoutCouponEntitiesInput>;
  holder?: InputMaybe<UserCreateNestedOneWithoutMyCouponsInput>;
  id?: InputMaybe<Scalars['String']>;
  inCartItem?: InputMaybe<CartItemCreateNestedOneWithoutCouponEntitiesInput>;
  inPostPackage?: InputMaybe<PostPackageCreateNestedOneWithoutCouponEntitiesInput>;
  info: CouponInfoCreateNestedOneWithoutCouponEntitiesInput;
  memo?: InputMaybe<Scalars['String']>;
  seq: Scalars['Int'];
  usageHistory?: InputMaybe<CouponUsageCreateNestedManyWithoutCouponInput>;
  used?: InputMaybe<Scalars['Boolean']>;
  valid?: InputMaybe<Scalars['Boolean']>;
};

export type CouponEntityCreateWithoutBenefitMemoToOrderInput = {
  benefitMemoToCoin?: InputMaybe<BenefitMemoToCoinCreateNestedManyWithoutCouponEntitiesInput>;
  code?: InputMaybe<Scalars['String']>;
  consumeCoin?: InputMaybe<CoinCreateNestedOneWithoutCouponEntitiesInput>;
  consumeOrder?: InputMaybe<OrderCreateNestedOneWithoutCouponEntitiesInput>;
  holder?: InputMaybe<UserCreateNestedOneWithoutMyCouponsInput>;
  id?: InputMaybe<Scalars['String']>;
  inCartItem?: InputMaybe<CartItemCreateNestedOneWithoutCouponEntitiesInput>;
  inPostPackage?: InputMaybe<PostPackageCreateNestedOneWithoutCouponEntitiesInput>;
  info: CouponInfoCreateNestedOneWithoutCouponEntitiesInput;
  memo?: InputMaybe<Scalars['String']>;
  seq: Scalars['Int'];
  usageHistory?: InputMaybe<CouponUsageCreateNestedManyWithoutCouponInput>;
  used?: InputMaybe<Scalars['Boolean']>;
  valid?: InputMaybe<Scalars['Boolean']>;
};

export type CouponEntityCreateWithoutConsumeCoinInput = {
  benefitMemoToCoin?: InputMaybe<BenefitMemoToCoinCreateNestedManyWithoutCouponEntitiesInput>;
  benefitMemoToOrder?: InputMaybe<BenefitMemoToOrderCreateNestedManyWithoutCouponEntitiesInput>;
  code?: InputMaybe<Scalars['String']>;
  consumeOrder?: InputMaybe<OrderCreateNestedOneWithoutCouponEntitiesInput>;
  holder?: InputMaybe<UserCreateNestedOneWithoutMyCouponsInput>;
  id?: InputMaybe<Scalars['String']>;
  inCartItem?: InputMaybe<CartItemCreateNestedOneWithoutCouponEntitiesInput>;
  inPostPackage?: InputMaybe<PostPackageCreateNestedOneWithoutCouponEntitiesInput>;
  info: CouponInfoCreateNestedOneWithoutCouponEntitiesInput;
  memo?: InputMaybe<Scalars['String']>;
  seq: Scalars['Int'];
  usageHistory?: InputMaybe<CouponUsageCreateNestedManyWithoutCouponInput>;
  used?: InputMaybe<Scalars['Boolean']>;
  valid?: InputMaybe<Scalars['Boolean']>;
};

export type CouponEntityCreateWithoutConsumeOrderInput = {
  benefitMemoToCoin?: InputMaybe<BenefitMemoToCoinCreateNestedManyWithoutCouponEntitiesInput>;
  benefitMemoToOrder?: InputMaybe<BenefitMemoToOrderCreateNestedManyWithoutCouponEntitiesInput>;
  code?: InputMaybe<Scalars['String']>;
  consumeCoin?: InputMaybe<CoinCreateNestedOneWithoutCouponEntitiesInput>;
  holder?: InputMaybe<UserCreateNestedOneWithoutMyCouponsInput>;
  id?: InputMaybe<Scalars['String']>;
  inCartItem?: InputMaybe<CartItemCreateNestedOneWithoutCouponEntitiesInput>;
  inPostPackage?: InputMaybe<PostPackageCreateNestedOneWithoutCouponEntitiesInput>;
  info: CouponInfoCreateNestedOneWithoutCouponEntitiesInput;
  memo?: InputMaybe<Scalars['String']>;
  seq: Scalars['Int'];
  usageHistory?: InputMaybe<CouponUsageCreateNestedManyWithoutCouponInput>;
  used?: InputMaybe<Scalars['Boolean']>;
  valid?: InputMaybe<Scalars['Boolean']>;
};

export type CouponEntityCreateWithoutHolderInput = {
  benefitMemoToCoin?: InputMaybe<BenefitMemoToCoinCreateNestedManyWithoutCouponEntitiesInput>;
  benefitMemoToOrder?: InputMaybe<BenefitMemoToOrderCreateNestedManyWithoutCouponEntitiesInput>;
  code?: InputMaybe<Scalars['String']>;
  consumeCoin?: InputMaybe<CoinCreateNestedOneWithoutCouponEntitiesInput>;
  consumeOrder?: InputMaybe<OrderCreateNestedOneWithoutCouponEntitiesInput>;
  id?: InputMaybe<Scalars['String']>;
  inCartItem?: InputMaybe<CartItemCreateNestedOneWithoutCouponEntitiesInput>;
  inPostPackage?: InputMaybe<PostPackageCreateNestedOneWithoutCouponEntitiesInput>;
  info: CouponInfoCreateNestedOneWithoutCouponEntitiesInput;
  memo?: InputMaybe<Scalars['String']>;
  seq: Scalars['Int'];
  usageHistory?: InputMaybe<CouponUsageCreateNestedManyWithoutCouponInput>;
  used?: InputMaybe<Scalars['Boolean']>;
  valid?: InputMaybe<Scalars['Boolean']>;
};

export type CouponEntityCreateWithoutInCartItemInput = {
  benefitMemoToCoin?: InputMaybe<BenefitMemoToCoinCreateNestedManyWithoutCouponEntitiesInput>;
  benefitMemoToOrder?: InputMaybe<BenefitMemoToOrderCreateNestedManyWithoutCouponEntitiesInput>;
  code?: InputMaybe<Scalars['String']>;
  consumeCoin?: InputMaybe<CoinCreateNestedOneWithoutCouponEntitiesInput>;
  consumeOrder?: InputMaybe<OrderCreateNestedOneWithoutCouponEntitiesInput>;
  holder?: InputMaybe<UserCreateNestedOneWithoutMyCouponsInput>;
  id?: InputMaybe<Scalars['String']>;
  inPostPackage?: InputMaybe<PostPackageCreateNestedOneWithoutCouponEntitiesInput>;
  info: CouponInfoCreateNestedOneWithoutCouponEntitiesInput;
  memo?: InputMaybe<Scalars['String']>;
  seq: Scalars['Int'];
  usageHistory?: InputMaybe<CouponUsageCreateNestedManyWithoutCouponInput>;
  used?: InputMaybe<Scalars['Boolean']>;
  valid?: InputMaybe<Scalars['Boolean']>;
};

export type CouponEntityCreateWithoutInPostPackageInput = {
  benefitMemoToCoin?: InputMaybe<BenefitMemoToCoinCreateNestedManyWithoutCouponEntitiesInput>;
  benefitMemoToOrder?: InputMaybe<BenefitMemoToOrderCreateNestedManyWithoutCouponEntitiesInput>;
  code?: InputMaybe<Scalars['String']>;
  consumeCoin?: InputMaybe<CoinCreateNestedOneWithoutCouponEntitiesInput>;
  consumeOrder?: InputMaybe<OrderCreateNestedOneWithoutCouponEntitiesInput>;
  holder?: InputMaybe<UserCreateNestedOneWithoutMyCouponsInput>;
  id?: InputMaybe<Scalars['String']>;
  inCartItem?: InputMaybe<CartItemCreateNestedOneWithoutCouponEntitiesInput>;
  info: CouponInfoCreateNestedOneWithoutCouponEntitiesInput;
  memo?: InputMaybe<Scalars['String']>;
  seq: Scalars['Int'];
  usageHistory?: InputMaybe<CouponUsageCreateNestedManyWithoutCouponInput>;
  used?: InputMaybe<Scalars['Boolean']>;
  valid?: InputMaybe<Scalars['Boolean']>;
};

export type CouponEntityCreateWithoutInfoInput = {
  benefitMemoToCoin?: InputMaybe<BenefitMemoToCoinCreateNestedManyWithoutCouponEntitiesInput>;
  benefitMemoToOrder?: InputMaybe<BenefitMemoToOrderCreateNestedManyWithoutCouponEntitiesInput>;
  code?: InputMaybe<Scalars['String']>;
  consumeCoin?: InputMaybe<CoinCreateNestedOneWithoutCouponEntitiesInput>;
  consumeOrder?: InputMaybe<OrderCreateNestedOneWithoutCouponEntitiesInput>;
  holder?: InputMaybe<UserCreateNestedOneWithoutMyCouponsInput>;
  id?: InputMaybe<Scalars['String']>;
  inCartItem?: InputMaybe<CartItemCreateNestedOneWithoutCouponEntitiesInput>;
  inPostPackage?: InputMaybe<PostPackageCreateNestedOneWithoutCouponEntitiesInput>;
  memo?: InputMaybe<Scalars['String']>;
  seq: Scalars['Int'];
  usageHistory?: InputMaybe<CouponUsageCreateNestedManyWithoutCouponInput>;
  used?: InputMaybe<Scalars['Boolean']>;
  valid?: InputMaybe<Scalars['Boolean']>;
};

export type CouponEntityCreateWithoutUsageHistoryInput = {
  benefitMemoToCoin?: InputMaybe<BenefitMemoToCoinCreateNestedManyWithoutCouponEntitiesInput>;
  benefitMemoToOrder?: InputMaybe<BenefitMemoToOrderCreateNestedManyWithoutCouponEntitiesInput>;
  code?: InputMaybe<Scalars['String']>;
  consumeCoin?: InputMaybe<CoinCreateNestedOneWithoutCouponEntitiesInput>;
  consumeOrder?: InputMaybe<OrderCreateNestedOneWithoutCouponEntitiesInput>;
  holder?: InputMaybe<UserCreateNestedOneWithoutMyCouponsInput>;
  id?: InputMaybe<Scalars['String']>;
  inCartItem?: InputMaybe<CartItemCreateNestedOneWithoutCouponEntitiesInput>;
  inPostPackage?: InputMaybe<PostPackageCreateNestedOneWithoutCouponEntitiesInput>;
  info: CouponInfoCreateNestedOneWithoutCouponEntitiesInput;
  memo?: InputMaybe<Scalars['String']>;
  seq: Scalars['Int'];
  used?: InputMaybe<Scalars['Boolean']>;
  valid?: InputMaybe<Scalars['Boolean']>;
};

export type CouponEntityGroupBy = {
  __typename?: 'CouponEntityGroupBy';
  _avg?: Maybe<CouponEntityAvgAggregate>;
  _count?: Maybe<CouponEntityCountAggregate>;
  _max?: Maybe<CouponEntityMaxAggregate>;
  _min?: Maybe<CouponEntityMinAggregate>;
  _sum?: Maybe<CouponEntitySumAggregate>;
  code?: Maybe<Scalars['String']>;
  consumeCoinId?: Maybe<Scalars['String']>;
  consumeOrderId?: Maybe<Scalars['String']>;
  holderId?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  inCartItemId?: Maybe<Scalars['String']>;
  inPostPackageId?: Maybe<Scalars['String']>;
  infoId: Scalars['String'];
  memo?: Maybe<Scalars['String']>;
  seq: Scalars['Int'];
  used: Scalars['Boolean'];
  valid: Scalars['Boolean'];
};

export type CouponEntityListRelationFilter = {
  every?: InputMaybe<CouponEntityWhereInput>;
  none?: InputMaybe<CouponEntityWhereInput>;
  some?: InputMaybe<CouponEntityWhereInput>;
};

export type CouponEntityMaxAggregate = {
  __typename?: 'CouponEntityMaxAggregate';
  code?: Maybe<Scalars['String']>;
  consumeCoinId?: Maybe<Scalars['String']>;
  consumeOrderId?: Maybe<Scalars['String']>;
  holderId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  inCartItemId?: Maybe<Scalars['String']>;
  inPostPackageId?: Maybe<Scalars['String']>;
  infoId?: Maybe<Scalars['String']>;
  memo?: Maybe<Scalars['String']>;
  seq?: Maybe<Scalars['Int']>;
  used?: Maybe<Scalars['Boolean']>;
  valid?: Maybe<Scalars['Boolean']>;
};

export type CouponEntityMaxOrderByAggregateInput = {
  code?: InputMaybe<SortOrder>;
  consumeCoinId?: InputMaybe<SortOrder>;
  consumeOrderId?: InputMaybe<SortOrder>;
  holderId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  inCartItemId?: InputMaybe<SortOrder>;
  inPostPackageId?: InputMaybe<SortOrder>;
  infoId?: InputMaybe<SortOrder>;
  memo?: InputMaybe<SortOrder>;
  seq?: InputMaybe<SortOrder>;
  used?: InputMaybe<SortOrder>;
  valid?: InputMaybe<SortOrder>;
};

export type CouponEntityMinAggregate = {
  __typename?: 'CouponEntityMinAggregate';
  code?: Maybe<Scalars['String']>;
  consumeCoinId?: Maybe<Scalars['String']>;
  consumeOrderId?: Maybe<Scalars['String']>;
  holderId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  inCartItemId?: Maybe<Scalars['String']>;
  inPostPackageId?: Maybe<Scalars['String']>;
  infoId?: Maybe<Scalars['String']>;
  memo?: Maybe<Scalars['String']>;
  seq?: Maybe<Scalars['Int']>;
  used?: Maybe<Scalars['Boolean']>;
  valid?: Maybe<Scalars['Boolean']>;
};

export type CouponEntityMinOrderByAggregateInput = {
  code?: InputMaybe<SortOrder>;
  consumeCoinId?: InputMaybe<SortOrder>;
  consumeOrderId?: InputMaybe<SortOrder>;
  holderId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  inCartItemId?: InputMaybe<SortOrder>;
  inPostPackageId?: InputMaybe<SortOrder>;
  infoId?: InputMaybe<SortOrder>;
  memo?: InputMaybe<SortOrder>;
  seq?: InputMaybe<SortOrder>;
  used?: InputMaybe<SortOrder>;
  valid?: InputMaybe<SortOrder>;
};

export type CouponEntityOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type CouponEntityOrderByWithAggregationInput = {
  _avg?: InputMaybe<CouponEntityAvgOrderByAggregateInput>;
  _count?: InputMaybe<CouponEntityCountOrderByAggregateInput>;
  _max?: InputMaybe<CouponEntityMaxOrderByAggregateInput>;
  _min?: InputMaybe<CouponEntityMinOrderByAggregateInput>;
  _sum?: InputMaybe<CouponEntitySumOrderByAggregateInput>;
  code?: InputMaybe<SortOrder>;
  consumeCoinId?: InputMaybe<SortOrder>;
  consumeOrderId?: InputMaybe<SortOrder>;
  holderId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  inCartItemId?: InputMaybe<SortOrder>;
  inPostPackageId?: InputMaybe<SortOrder>;
  infoId?: InputMaybe<SortOrder>;
  memo?: InputMaybe<SortOrder>;
  seq?: InputMaybe<SortOrder>;
  used?: InputMaybe<SortOrder>;
  valid?: InputMaybe<SortOrder>;
};

export type CouponEntityOrderByWithRelationInput = {
  benefitMemoToCoin?: InputMaybe<BenefitMemoToCoinOrderByRelationAggregateInput>;
  benefitMemoToOrder?: InputMaybe<BenefitMemoToOrderOrderByRelationAggregateInput>;
  code?: InputMaybe<SortOrder>;
  consumeCoin?: InputMaybe<CoinOrderByWithRelationInput>;
  consumeCoinId?: InputMaybe<SortOrder>;
  consumeOrder?: InputMaybe<OrderOrderByWithRelationInput>;
  consumeOrderId?: InputMaybe<SortOrder>;
  holder?: InputMaybe<UserOrderByWithRelationInput>;
  holderId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  inCartItem?: InputMaybe<CartItemOrderByWithRelationInput>;
  inCartItemId?: InputMaybe<SortOrder>;
  inPostPackage?: InputMaybe<PostPackageOrderByWithRelationInput>;
  inPostPackageId?: InputMaybe<SortOrder>;
  info?: InputMaybe<CouponInfoOrderByWithRelationInput>;
  infoId?: InputMaybe<SortOrder>;
  memo?: InputMaybe<SortOrder>;
  seq?: InputMaybe<SortOrder>;
  usageHistory?: InputMaybe<CouponUsageOrderByRelationAggregateInput>;
  used?: InputMaybe<SortOrder>;
  valid?: InputMaybe<SortOrder>;
};

export type CouponEntityRelationFilter = {
  is?: InputMaybe<CouponEntityWhereInput>;
  isNot?: InputMaybe<CouponEntityWhereInput>;
};

export enum CouponEntityScalarFieldEnum {
  Code = 'code',
  ConsumeCoinId = 'consumeCoinId',
  ConsumeOrderId = 'consumeOrderId',
  HolderId = 'holderId',
  Id = 'id',
  InCartItemId = 'inCartItemId',
  InPostPackageId = 'inPostPackageId',
  InfoId = 'infoId',
  Memo = 'memo',
  Seq = 'seq',
  Used = 'used',
  Valid = 'valid'
}

export type CouponEntityScalarWhereInput = {
  AND?: InputMaybe<Array<CouponEntityScalarWhereInput>>;
  NOT?: InputMaybe<Array<CouponEntityScalarWhereInput>>;
  OR?: InputMaybe<Array<CouponEntityScalarWhereInput>>;
  code?: InputMaybe<StringNullableFilter>;
  consumeCoinId?: InputMaybe<StringNullableFilter>;
  consumeOrderId?: InputMaybe<StringNullableFilter>;
  holderId?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<StringFilter>;
  inCartItemId?: InputMaybe<StringNullableFilter>;
  inPostPackageId?: InputMaybe<StringNullableFilter>;
  infoId?: InputMaybe<StringFilter>;
  memo?: InputMaybe<StringNullableFilter>;
  seq?: InputMaybe<IntFilter>;
  used?: InputMaybe<BoolFilter>;
  valid?: InputMaybe<BoolFilter>;
};

export type CouponEntityScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<CouponEntityScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<CouponEntityScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<CouponEntityScalarWhereWithAggregatesInput>>;
  code?: InputMaybe<StringNullableWithAggregatesFilter>;
  consumeCoinId?: InputMaybe<StringNullableWithAggregatesFilter>;
  consumeOrderId?: InputMaybe<StringNullableWithAggregatesFilter>;
  holderId?: InputMaybe<StringNullableWithAggregatesFilter>;
  id?: InputMaybe<StringWithAggregatesFilter>;
  inCartItemId?: InputMaybe<StringNullableWithAggregatesFilter>;
  inPostPackageId?: InputMaybe<StringNullableWithAggregatesFilter>;
  infoId?: InputMaybe<StringWithAggregatesFilter>;
  memo?: InputMaybe<StringNullableWithAggregatesFilter>;
  seq?: InputMaybe<IntWithAggregatesFilter>;
  used?: InputMaybe<BoolWithAggregatesFilter>;
  valid?: InputMaybe<BoolWithAggregatesFilter>;
};

export type CouponEntitySumAggregate = {
  __typename?: 'CouponEntitySumAggregate';
  seq?: Maybe<Scalars['Int']>;
};

export type CouponEntitySumOrderByAggregateInput = {
  seq?: InputMaybe<SortOrder>;
};

export type CouponEntityUpdateInput = {
  benefitMemoToCoin?: InputMaybe<BenefitMemoToCoinUpdateManyWithoutCouponEntitiesNestedInput>;
  benefitMemoToOrder?: InputMaybe<BenefitMemoToOrderUpdateManyWithoutCouponEntitiesNestedInput>;
  code?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  consumeCoin?: InputMaybe<CoinUpdateOneWithoutCouponEntitiesNestedInput>;
  consumeOrder?: InputMaybe<OrderUpdateOneWithoutCouponEntitiesNestedInput>;
  holder?: InputMaybe<UserUpdateOneWithoutMyCouponsNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  inCartItem?: InputMaybe<CartItemUpdateOneWithoutCouponEntitiesNestedInput>;
  inPostPackage?: InputMaybe<PostPackageUpdateOneWithoutCouponEntitiesNestedInput>;
  info?: InputMaybe<CouponInfoUpdateOneRequiredWithoutCouponEntitiesNestedInput>;
  memo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  seq?: InputMaybe<IntFieldUpdateOperationsInput>;
  usageHistory?: InputMaybe<CouponUsageUpdateManyWithoutCouponNestedInput>;
  used?: InputMaybe<BoolFieldUpdateOperationsInput>;
  valid?: InputMaybe<BoolFieldUpdateOperationsInput>;
};

export type CouponEntityUpdateManyMutationInput = {
  code?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  memo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  seq?: InputMaybe<IntFieldUpdateOperationsInput>;
  used?: InputMaybe<BoolFieldUpdateOperationsInput>;
  valid?: InputMaybe<BoolFieldUpdateOperationsInput>;
};

export type CouponEntityUpdateManyWithWhereWithoutBenefitMemoToCoinInput = {
  data: CouponEntityUpdateManyMutationInput;
  where: CouponEntityScalarWhereInput;
};

export type CouponEntityUpdateManyWithWhereWithoutBenefitMemoToOrderInput = {
  data: CouponEntityUpdateManyMutationInput;
  where: CouponEntityScalarWhereInput;
};

export type CouponEntityUpdateManyWithWhereWithoutConsumeCoinInput = {
  data: CouponEntityUpdateManyMutationInput;
  where: CouponEntityScalarWhereInput;
};

export type CouponEntityUpdateManyWithWhereWithoutConsumeOrderInput = {
  data: CouponEntityUpdateManyMutationInput;
  where: CouponEntityScalarWhereInput;
};

export type CouponEntityUpdateManyWithWhereWithoutHolderInput = {
  data: CouponEntityUpdateManyMutationInput;
  where: CouponEntityScalarWhereInput;
};

export type CouponEntityUpdateManyWithWhereWithoutInCartItemInput = {
  data: CouponEntityUpdateManyMutationInput;
  where: CouponEntityScalarWhereInput;
};

export type CouponEntityUpdateManyWithWhereWithoutInPostPackageInput = {
  data: CouponEntityUpdateManyMutationInput;
  where: CouponEntityScalarWhereInput;
};

export type CouponEntityUpdateManyWithWhereWithoutInfoInput = {
  data: CouponEntityUpdateManyMutationInput;
  where: CouponEntityScalarWhereInput;
};

export type CouponEntityUpdateManyWithoutBenefitMemoToCoinNestedInput = {
  connect?: InputMaybe<Array<CouponEntityWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CouponEntityCreateOrConnectWithoutBenefitMemoToCoinInput>>;
  create?: InputMaybe<Array<CouponEntityCreateWithoutBenefitMemoToCoinInput>>;
  delete?: InputMaybe<Array<CouponEntityWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<CouponEntityScalarWhereInput>>;
  disconnect?: InputMaybe<Array<CouponEntityWhereUniqueInput>>;
  set?: InputMaybe<Array<CouponEntityWhereUniqueInput>>;
  update?: InputMaybe<Array<CouponEntityUpdateWithWhereUniqueWithoutBenefitMemoToCoinInput>>;
  updateMany?: InputMaybe<Array<CouponEntityUpdateManyWithWhereWithoutBenefitMemoToCoinInput>>;
  upsert?: InputMaybe<Array<CouponEntityUpsertWithWhereUniqueWithoutBenefitMemoToCoinInput>>;
};

export type CouponEntityUpdateManyWithoutBenefitMemoToOrderNestedInput = {
  connect?: InputMaybe<Array<CouponEntityWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CouponEntityCreateOrConnectWithoutBenefitMemoToOrderInput>>;
  create?: InputMaybe<Array<CouponEntityCreateWithoutBenefitMemoToOrderInput>>;
  delete?: InputMaybe<Array<CouponEntityWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<CouponEntityScalarWhereInput>>;
  disconnect?: InputMaybe<Array<CouponEntityWhereUniqueInput>>;
  set?: InputMaybe<Array<CouponEntityWhereUniqueInput>>;
  update?: InputMaybe<Array<CouponEntityUpdateWithWhereUniqueWithoutBenefitMemoToOrderInput>>;
  updateMany?: InputMaybe<Array<CouponEntityUpdateManyWithWhereWithoutBenefitMemoToOrderInput>>;
  upsert?: InputMaybe<Array<CouponEntityUpsertWithWhereUniqueWithoutBenefitMemoToOrderInput>>;
};

export type CouponEntityUpdateManyWithoutConsumeCoinNestedInput = {
  connect?: InputMaybe<Array<CouponEntityWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CouponEntityCreateOrConnectWithoutConsumeCoinInput>>;
  create?: InputMaybe<Array<CouponEntityCreateWithoutConsumeCoinInput>>;
  createMany?: InputMaybe<CouponEntityCreateManyConsumeCoinInputEnvelope>;
  delete?: InputMaybe<Array<CouponEntityWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<CouponEntityScalarWhereInput>>;
  disconnect?: InputMaybe<Array<CouponEntityWhereUniqueInput>>;
  set?: InputMaybe<Array<CouponEntityWhereUniqueInput>>;
  update?: InputMaybe<Array<CouponEntityUpdateWithWhereUniqueWithoutConsumeCoinInput>>;
  updateMany?: InputMaybe<Array<CouponEntityUpdateManyWithWhereWithoutConsumeCoinInput>>;
  upsert?: InputMaybe<Array<CouponEntityUpsertWithWhereUniqueWithoutConsumeCoinInput>>;
};

export type CouponEntityUpdateManyWithoutConsumeOrderNestedInput = {
  connect?: InputMaybe<Array<CouponEntityWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CouponEntityCreateOrConnectWithoutConsumeOrderInput>>;
  create?: InputMaybe<Array<CouponEntityCreateWithoutConsumeOrderInput>>;
  createMany?: InputMaybe<CouponEntityCreateManyConsumeOrderInputEnvelope>;
  delete?: InputMaybe<Array<CouponEntityWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<CouponEntityScalarWhereInput>>;
  disconnect?: InputMaybe<Array<CouponEntityWhereUniqueInput>>;
  set?: InputMaybe<Array<CouponEntityWhereUniqueInput>>;
  update?: InputMaybe<Array<CouponEntityUpdateWithWhereUniqueWithoutConsumeOrderInput>>;
  updateMany?: InputMaybe<Array<CouponEntityUpdateManyWithWhereWithoutConsumeOrderInput>>;
  upsert?: InputMaybe<Array<CouponEntityUpsertWithWhereUniqueWithoutConsumeOrderInput>>;
};

export type CouponEntityUpdateManyWithoutHolderNestedInput = {
  connect?: InputMaybe<Array<CouponEntityWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CouponEntityCreateOrConnectWithoutHolderInput>>;
  create?: InputMaybe<Array<CouponEntityCreateWithoutHolderInput>>;
  createMany?: InputMaybe<CouponEntityCreateManyHolderInputEnvelope>;
  delete?: InputMaybe<Array<CouponEntityWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<CouponEntityScalarWhereInput>>;
  disconnect?: InputMaybe<Array<CouponEntityWhereUniqueInput>>;
  set?: InputMaybe<Array<CouponEntityWhereUniqueInput>>;
  update?: InputMaybe<Array<CouponEntityUpdateWithWhereUniqueWithoutHolderInput>>;
  updateMany?: InputMaybe<Array<CouponEntityUpdateManyWithWhereWithoutHolderInput>>;
  upsert?: InputMaybe<Array<CouponEntityUpsertWithWhereUniqueWithoutHolderInput>>;
};

export type CouponEntityUpdateManyWithoutInCartItemNestedInput = {
  connect?: InputMaybe<Array<CouponEntityWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CouponEntityCreateOrConnectWithoutInCartItemInput>>;
  create?: InputMaybe<Array<CouponEntityCreateWithoutInCartItemInput>>;
  createMany?: InputMaybe<CouponEntityCreateManyInCartItemInputEnvelope>;
  delete?: InputMaybe<Array<CouponEntityWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<CouponEntityScalarWhereInput>>;
  disconnect?: InputMaybe<Array<CouponEntityWhereUniqueInput>>;
  set?: InputMaybe<Array<CouponEntityWhereUniqueInput>>;
  update?: InputMaybe<Array<CouponEntityUpdateWithWhereUniqueWithoutInCartItemInput>>;
  updateMany?: InputMaybe<Array<CouponEntityUpdateManyWithWhereWithoutInCartItemInput>>;
  upsert?: InputMaybe<Array<CouponEntityUpsertWithWhereUniqueWithoutInCartItemInput>>;
};

export type CouponEntityUpdateManyWithoutInPostPackageNestedInput = {
  connect?: InputMaybe<Array<CouponEntityWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CouponEntityCreateOrConnectWithoutInPostPackageInput>>;
  create?: InputMaybe<Array<CouponEntityCreateWithoutInPostPackageInput>>;
  createMany?: InputMaybe<CouponEntityCreateManyInPostPackageInputEnvelope>;
  delete?: InputMaybe<Array<CouponEntityWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<CouponEntityScalarWhereInput>>;
  disconnect?: InputMaybe<Array<CouponEntityWhereUniqueInput>>;
  set?: InputMaybe<Array<CouponEntityWhereUniqueInput>>;
  update?: InputMaybe<Array<CouponEntityUpdateWithWhereUniqueWithoutInPostPackageInput>>;
  updateMany?: InputMaybe<Array<CouponEntityUpdateManyWithWhereWithoutInPostPackageInput>>;
  upsert?: InputMaybe<Array<CouponEntityUpsertWithWhereUniqueWithoutInPostPackageInput>>;
};

export type CouponEntityUpdateManyWithoutInfoNestedInput = {
  connect?: InputMaybe<Array<CouponEntityWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CouponEntityCreateOrConnectWithoutInfoInput>>;
  create?: InputMaybe<Array<CouponEntityCreateWithoutInfoInput>>;
  createMany?: InputMaybe<CouponEntityCreateManyInfoInputEnvelope>;
  delete?: InputMaybe<Array<CouponEntityWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<CouponEntityScalarWhereInput>>;
  disconnect?: InputMaybe<Array<CouponEntityWhereUniqueInput>>;
  set?: InputMaybe<Array<CouponEntityWhereUniqueInput>>;
  update?: InputMaybe<Array<CouponEntityUpdateWithWhereUniqueWithoutInfoInput>>;
  updateMany?: InputMaybe<Array<CouponEntityUpdateManyWithWhereWithoutInfoInput>>;
  upsert?: InputMaybe<Array<CouponEntityUpsertWithWhereUniqueWithoutInfoInput>>;
};

export type CouponEntityUpdateOneRequiredWithoutUsageHistoryNestedInput = {
  connect?: InputMaybe<CouponEntityWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CouponEntityCreateOrConnectWithoutUsageHistoryInput>;
  create?: InputMaybe<CouponEntityCreateWithoutUsageHistoryInput>;
  update?: InputMaybe<CouponEntityUpdateWithoutUsageHistoryInput>;
  upsert?: InputMaybe<CouponEntityUpsertWithoutUsageHistoryInput>;
};

export type CouponEntityUpdateWithWhereUniqueWithoutBenefitMemoToCoinInput = {
  data: CouponEntityUpdateWithoutBenefitMemoToCoinInput;
  where: CouponEntityWhereUniqueInput;
};

export type CouponEntityUpdateWithWhereUniqueWithoutBenefitMemoToOrderInput = {
  data: CouponEntityUpdateWithoutBenefitMemoToOrderInput;
  where: CouponEntityWhereUniqueInput;
};

export type CouponEntityUpdateWithWhereUniqueWithoutConsumeCoinInput = {
  data: CouponEntityUpdateWithoutConsumeCoinInput;
  where: CouponEntityWhereUniqueInput;
};

export type CouponEntityUpdateWithWhereUniqueWithoutConsumeOrderInput = {
  data: CouponEntityUpdateWithoutConsumeOrderInput;
  where: CouponEntityWhereUniqueInput;
};

export type CouponEntityUpdateWithWhereUniqueWithoutHolderInput = {
  data: CouponEntityUpdateWithoutHolderInput;
  where: CouponEntityWhereUniqueInput;
};

export type CouponEntityUpdateWithWhereUniqueWithoutInCartItemInput = {
  data: CouponEntityUpdateWithoutInCartItemInput;
  where: CouponEntityWhereUniqueInput;
};

export type CouponEntityUpdateWithWhereUniqueWithoutInPostPackageInput = {
  data: CouponEntityUpdateWithoutInPostPackageInput;
  where: CouponEntityWhereUniqueInput;
};

export type CouponEntityUpdateWithWhereUniqueWithoutInfoInput = {
  data: CouponEntityUpdateWithoutInfoInput;
  where: CouponEntityWhereUniqueInput;
};

export type CouponEntityUpdateWithoutBenefitMemoToCoinInput = {
  benefitMemoToOrder?: InputMaybe<BenefitMemoToOrderUpdateManyWithoutCouponEntitiesNestedInput>;
  code?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  consumeCoin?: InputMaybe<CoinUpdateOneWithoutCouponEntitiesNestedInput>;
  consumeOrder?: InputMaybe<OrderUpdateOneWithoutCouponEntitiesNestedInput>;
  holder?: InputMaybe<UserUpdateOneWithoutMyCouponsNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  inCartItem?: InputMaybe<CartItemUpdateOneWithoutCouponEntitiesNestedInput>;
  inPostPackage?: InputMaybe<PostPackageUpdateOneWithoutCouponEntitiesNestedInput>;
  info?: InputMaybe<CouponInfoUpdateOneRequiredWithoutCouponEntitiesNestedInput>;
  memo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  seq?: InputMaybe<IntFieldUpdateOperationsInput>;
  usageHistory?: InputMaybe<CouponUsageUpdateManyWithoutCouponNestedInput>;
  used?: InputMaybe<BoolFieldUpdateOperationsInput>;
  valid?: InputMaybe<BoolFieldUpdateOperationsInput>;
};

export type CouponEntityUpdateWithoutBenefitMemoToOrderInput = {
  benefitMemoToCoin?: InputMaybe<BenefitMemoToCoinUpdateManyWithoutCouponEntitiesNestedInput>;
  code?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  consumeCoin?: InputMaybe<CoinUpdateOneWithoutCouponEntitiesNestedInput>;
  consumeOrder?: InputMaybe<OrderUpdateOneWithoutCouponEntitiesNestedInput>;
  holder?: InputMaybe<UserUpdateOneWithoutMyCouponsNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  inCartItem?: InputMaybe<CartItemUpdateOneWithoutCouponEntitiesNestedInput>;
  inPostPackage?: InputMaybe<PostPackageUpdateOneWithoutCouponEntitiesNestedInput>;
  info?: InputMaybe<CouponInfoUpdateOneRequiredWithoutCouponEntitiesNestedInput>;
  memo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  seq?: InputMaybe<IntFieldUpdateOperationsInput>;
  usageHistory?: InputMaybe<CouponUsageUpdateManyWithoutCouponNestedInput>;
  used?: InputMaybe<BoolFieldUpdateOperationsInput>;
  valid?: InputMaybe<BoolFieldUpdateOperationsInput>;
};

export type CouponEntityUpdateWithoutConsumeCoinInput = {
  benefitMemoToCoin?: InputMaybe<BenefitMemoToCoinUpdateManyWithoutCouponEntitiesNestedInput>;
  benefitMemoToOrder?: InputMaybe<BenefitMemoToOrderUpdateManyWithoutCouponEntitiesNestedInput>;
  code?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  consumeOrder?: InputMaybe<OrderUpdateOneWithoutCouponEntitiesNestedInput>;
  holder?: InputMaybe<UserUpdateOneWithoutMyCouponsNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  inCartItem?: InputMaybe<CartItemUpdateOneWithoutCouponEntitiesNestedInput>;
  inPostPackage?: InputMaybe<PostPackageUpdateOneWithoutCouponEntitiesNestedInput>;
  info?: InputMaybe<CouponInfoUpdateOneRequiredWithoutCouponEntitiesNestedInput>;
  memo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  seq?: InputMaybe<IntFieldUpdateOperationsInput>;
  usageHistory?: InputMaybe<CouponUsageUpdateManyWithoutCouponNestedInput>;
  used?: InputMaybe<BoolFieldUpdateOperationsInput>;
  valid?: InputMaybe<BoolFieldUpdateOperationsInput>;
};

export type CouponEntityUpdateWithoutConsumeOrderInput = {
  benefitMemoToCoin?: InputMaybe<BenefitMemoToCoinUpdateManyWithoutCouponEntitiesNestedInput>;
  benefitMemoToOrder?: InputMaybe<BenefitMemoToOrderUpdateManyWithoutCouponEntitiesNestedInput>;
  code?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  consumeCoin?: InputMaybe<CoinUpdateOneWithoutCouponEntitiesNestedInput>;
  holder?: InputMaybe<UserUpdateOneWithoutMyCouponsNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  inCartItem?: InputMaybe<CartItemUpdateOneWithoutCouponEntitiesNestedInput>;
  inPostPackage?: InputMaybe<PostPackageUpdateOneWithoutCouponEntitiesNestedInput>;
  info?: InputMaybe<CouponInfoUpdateOneRequiredWithoutCouponEntitiesNestedInput>;
  memo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  seq?: InputMaybe<IntFieldUpdateOperationsInput>;
  usageHistory?: InputMaybe<CouponUsageUpdateManyWithoutCouponNestedInput>;
  used?: InputMaybe<BoolFieldUpdateOperationsInput>;
  valid?: InputMaybe<BoolFieldUpdateOperationsInput>;
};

export type CouponEntityUpdateWithoutHolderInput = {
  benefitMemoToCoin?: InputMaybe<BenefitMemoToCoinUpdateManyWithoutCouponEntitiesNestedInput>;
  benefitMemoToOrder?: InputMaybe<BenefitMemoToOrderUpdateManyWithoutCouponEntitiesNestedInput>;
  code?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  consumeCoin?: InputMaybe<CoinUpdateOneWithoutCouponEntitiesNestedInput>;
  consumeOrder?: InputMaybe<OrderUpdateOneWithoutCouponEntitiesNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  inCartItem?: InputMaybe<CartItemUpdateOneWithoutCouponEntitiesNestedInput>;
  inPostPackage?: InputMaybe<PostPackageUpdateOneWithoutCouponEntitiesNestedInput>;
  info?: InputMaybe<CouponInfoUpdateOneRequiredWithoutCouponEntitiesNestedInput>;
  memo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  seq?: InputMaybe<IntFieldUpdateOperationsInput>;
  usageHistory?: InputMaybe<CouponUsageUpdateManyWithoutCouponNestedInput>;
  used?: InputMaybe<BoolFieldUpdateOperationsInput>;
  valid?: InputMaybe<BoolFieldUpdateOperationsInput>;
};

export type CouponEntityUpdateWithoutInCartItemInput = {
  benefitMemoToCoin?: InputMaybe<BenefitMemoToCoinUpdateManyWithoutCouponEntitiesNestedInput>;
  benefitMemoToOrder?: InputMaybe<BenefitMemoToOrderUpdateManyWithoutCouponEntitiesNestedInput>;
  code?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  consumeCoin?: InputMaybe<CoinUpdateOneWithoutCouponEntitiesNestedInput>;
  consumeOrder?: InputMaybe<OrderUpdateOneWithoutCouponEntitiesNestedInput>;
  holder?: InputMaybe<UserUpdateOneWithoutMyCouponsNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  inPostPackage?: InputMaybe<PostPackageUpdateOneWithoutCouponEntitiesNestedInput>;
  info?: InputMaybe<CouponInfoUpdateOneRequiredWithoutCouponEntitiesNestedInput>;
  memo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  seq?: InputMaybe<IntFieldUpdateOperationsInput>;
  usageHistory?: InputMaybe<CouponUsageUpdateManyWithoutCouponNestedInput>;
  used?: InputMaybe<BoolFieldUpdateOperationsInput>;
  valid?: InputMaybe<BoolFieldUpdateOperationsInput>;
};

export type CouponEntityUpdateWithoutInPostPackageInput = {
  benefitMemoToCoin?: InputMaybe<BenefitMemoToCoinUpdateManyWithoutCouponEntitiesNestedInput>;
  benefitMemoToOrder?: InputMaybe<BenefitMemoToOrderUpdateManyWithoutCouponEntitiesNestedInput>;
  code?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  consumeCoin?: InputMaybe<CoinUpdateOneWithoutCouponEntitiesNestedInput>;
  consumeOrder?: InputMaybe<OrderUpdateOneWithoutCouponEntitiesNestedInput>;
  holder?: InputMaybe<UserUpdateOneWithoutMyCouponsNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  inCartItem?: InputMaybe<CartItemUpdateOneWithoutCouponEntitiesNestedInput>;
  info?: InputMaybe<CouponInfoUpdateOneRequiredWithoutCouponEntitiesNestedInput>;
  memo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  seq?: InputMaybe<IntFieldUpdateOperationsInput>;
  usageHistory?: InputMaybe<CouponUsageUpdateManyWithoutCouponNestedInput>;
  used?: InputMaybe<BoolFieldUpdateOperationsInput>;
  valid?: InputMaybe<BoolFieldUpdateOperationsInput>;
};

export type CouponEntityUpdateWithoutInfoInput = {
  benefitMemoToCoin?: InputMaybe<BenefitMemoToCoinUpdateManyWithoutCouponEntitiesNestedInput>;
  benefitMemoToOrder?: InputMaybe<BenefitMemoToOrderUpdateManyWithoutCouponEntitiesNestedInput>;
  code?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  consumeCoin?: InputMaybe<CoinUpdateOneWithoutCouponEntitiesNestedInput>;
  consumeOrder?: InputMaybe<OrderUpdateOneWithoutCouponEntitiesNestedInput>;
  holder?: InputMaybe<UserUpdateOneWithoutMyCouponsNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  inCartItem?: InputMaybe<CartItemUpdateOneWithoutCouponEntitiesNestedInput>;
  inPostPackage?: InputMaybe<PostPackageUpdateOneWithoutCouponEntitiesNestedInput>;
  memo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  seq?: InputMaybe<IntFieldUpdateOperationsInput>;
  usageHistory?: InputMaybe<CouponUsageUpdateManyWithoutCouponNestedInput>;
  used?: InputMaybe<BoolFieldUpdateOperationsInput>;
  valid?: InputMaybe<BoolFieldUpdateOperationsInput>;
};

export type CouponEntityUpdateWithoutUsageHistoryInput = {
  benefitMemoToCoin?: InputMaybe<BenefitMemoToCoinUpdateManyWithoutCouponEntitiesNestedInput>;
  benefitMemoToOrder?: InputMaybe<BenefitMemoToOrderUpdateManyWithoutCouponEntitiesNestedInput>;
  code?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  consumeCoin?: InputMaybe<CoinUpdateOneWithoutCouponEntitiesNestedInput>;
  consumeOrder?: InputMaybe<OrderUpdateOneWithoutCouponEntitiesNestedInput>;
  holder?: InputMaybe<UserUpdateOneWithoutMyCouponsNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  inCartItem?: InputMaybe<CartItemUpdateOneWithoutCouponEntitiesNestedInput>;
  inPostPackage?: InputMaybe<PostPackageUpdateOneWithoutCouponEntitiesNestedInput>;
  info?: InputMaybe<CouponInfoUpdateOneRequiredWithoutCouponEntitiesNestedInput>;
  memo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  seq?: InputMaybe<IntFieldUpdateOperationsInput>;
  used?: InputMaybe<BoolFieldUpdateOperationsInput>;
  valid?: InputMaybe<BoolFieldUpdateOperationsInput>;
};

export type CouponEntityUpsertWithWhereUniqueWithoutBenefitMemoToCoinInput = {
  create: CouponEntityCreateWithoutBenefitMemoToCoinInput;
  update: CouponEntityUpdateWithoutBenefitMemoToCoinInput;
  where: CouponEntityWhereUniqueInput;
};

export type CouponEntityUpsertWithWhereUniqueWithoutBenefitMemoToOrderInput = {
  create: CouponEntityCreateWithoutBenefitMemoToOrderInput;
  update: CouponEntityUpdateWithoutBenefitMemoToOrderInput;
  where: CouponEntityWhereUniqueInput;
};

export type CouponEntityUpsertWithWhereUniqueWithoutConsumeCoinInput = {
  create: CouponEntityCreateWithoutConsumeCoinInput;
  update: CouponEntityUpdateWithoutConsumeCoinInput;
  where: CouponEntityWhereUniqueInput;
};

export type CouponEntityUpsertWithWhereUniqueWithoutConsumeOrderInput = {
  create: CouponEntityCreateWithoutConsumeOrderInput;
  update: CouponEntityUpdateWithoutConsumeOrderInput;
  where: CouponEntityWhereUniqueInput;
};

export type CouponEntityUpsertWithWhereUniqueWithoutHolderInput = {
  create: CouponEntityCreateWithoutHolderInput;
  update: CouponEntityUpdateWithoutHolderInput;
  where: CouponEntityWhereUniqueInput;
};

export type CouponEntityUpsertWithWhereUniqueWithoutInCartItemInput = {
  create: CouponEntityCreateWithoutInCartItemInput;
  update: CouponEntityUpdateWithoutInCartItemInput;
  where: CouponEntityWhereUniqueInput;
};

export type CouponEntityUpsertWithWhereUniqueWithoutInPostPackageInput = {
  create: CouponEntityCreateWithoutInPostPackageInput;
  update: CouponEntityUpdateWithoutInPostPackageInput;
  where: CouponEntityWhereUniqueInput;
};

export type CouponEntityUpsertWithWhereUniqueWithoutInfoInput = {
  create: CouponEntityCreateWithoutInfoInput;
  update: CouponEntityUpdateWithoutInfoInput;
  where: CouponEntityWhereUniqueInput;
};

export type CouponEntityUpsertWithoutUsageHistoryInput = {
  create: CouponEntityCreateWithoutUsageHistoryInput;
  update: CouponEntityUpdateWithoutUsageHistoryInput;
};

export type CouponEntityWhereInput = {
  AND?: InputMaybe<Array<CouponEntityWhereInput>>;
  NOT?: InputMaybe<Array<CouponEntityWhereInput>>;
  OR?: InputMaybe<Array<CouponEntityWhereInput>>;
  benefitMemoToCoin?: InputMaybe<BenefitMemoToCoinListRelationFilter>;
  benefitMemoToOrder?: InputMaybe<BenefitMemoToOrderListRelationFilter>;
  code?: InputMaybe<StringNullableFilter>;
  consumeCoin?: InputMaybe<CoinRelationFilter>;
  consumeCoinId?: InputMaybe<StringNullableFilter>;
  consumeOrder?: InputMaybe<OrderRelationFilter>;
  consumeOrderId?: InputMaybe<StringNullableFilter>;
  holder?: InputMaybe<UserRelationFilter>;
  holderId?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<StringFilter>;
  inCartItem?: InputMaybe<CartItemRelationFilter>;
  inCartItemId?: InputMaybe<StringNullableFilter>;
  inPostPackage?: InputMaybe<PostPackageRelationFilter>;
  inPostPackageId?: InputMaybe<StringNullableFilter>;
  info?: InputMaybe<CouponInfoRelationFilter>;
  infoId?: InputMaybe<StringFilter>;
  memo?: InputMaybe<StringNullableFilter>;
  seq?: InputMaybe<IntFilter>;
  usageHistory?: InputMaybe<CouponUsageListRelationFilter>;
  used?: InputMaybe<BoolFilter>;
  valid?: InputMaybe<BoolFilter>;
};

export type CouponEntityWhereUniqueInput = {
  code?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
};

export type CouponGroup = {
  __typename?: 'CouponGroup';
  createdAt: Scalars['DateTime'];
  groupHead: CouponInfo;
  groupHeadId: Scalars['String'];
  member: CouponInfo;
  memberId: Scalars['String'];
};

export type CouponGroupCountAggregate = {
  __typename?: 'CouponGroupCountAggregate';
  _all: Scalars['Int'];
  createdAt: Scalars['Int'];
  groupHeadId: Scalars['Int'];
  memberId: Scalars['Int'];
};

export type CouponGroupCountOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  groupHeadId?: InputMaybe<SortOrder>;
  memberId?: InputMaybe<SortOrder>;
};

export type CouponGroupCreateInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  groupHead: CouponInfoCreateNestedOneWithoutGroupCouponsInput;
  member: CouponInfoCreateNestedOneWithoutGroupHeadInput;
};

export type CouponGroupCreateManyGroupHeadInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  memberId: Scalars['String'];
};

export type CouponGroupCreateManyGroupHeadInputEnvelope = {
  data: Array<CouponGroupCreateManyGroupHeadInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type CouponGroupCreateManyInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  groupHeadId: Scalars['String'];
  memberId: Scalars['String'];
};

export type CouponGroupCreateNestedManyWithoutGroupHeadInput = {
  connect?: InputMaybe<Array<CouponGroupWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CouponGroupCreateOrConnectWithoutGroupHeadInput>>;
  create?: InputMaybe<Array<CouponGroupCreateWithoutGroupHeadInput>>;
  createMany?: InputMaybe<CouponGroupCreateManyGroupHeadInputEnvelope>;
};

export type CouponGroupCreateNestedOneWithoutMemberInput = {
  connect?: InputMaybe<CouponGroupWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CouponGroupCreateOrConnectWithoutMemberInput>;
  create?: InputMaybe<CouponGroupCreateWithoutMemberInput>;
};

export type CouponGroupCreateOrConnectWithoutGroupHeadInput = {
  create: CouponGroupCreateWithoutGroupHeadInput;
  where: CouponGroupWhereUniqueInput;
};

export type CouponGroupCreateOrConnectWithoutMemberInput = {
  create: CouponGroupCreateWithoutMemberInput;
  where: CouponGroupWhereUniqueInput;
};

export type CouponGroupCreateWithoutGroupHeadInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  member: CouponInfoCreateNestedOneWithoutGroupHeadInput;
};

export type CouponGroupCreateWithoutMemberInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  groupHead: CouponInfoCreateNestedOneWithoutGroupCouponsInput;
};

export type CouponGroupGroupBy = {
  __typename?: 'CouponGroupGroupBy';
  _count?: Maybe<CouponGroupCountAggregate>;
  _max?: Maybe<CouponGroupMaxAggregate>;
  _min?: Maybe<CouponGroupMinAggregate>;
  createdAt: Scalars['DateTime'];
  groupHeadId: Scalars['String'];
  memberId: Scalars['String'];
};

export type CouponGroupGroupHeadIdMemberIdCompoundUniqueInput = {
  groupHeadId: Scalars['String'];
  memberId: Scalars['String'];
};

export type CouponGroupListRelationFilter = {
  every?: InputMaybe<CouponGroupWhereInput>;
  none?: InputMaybe<CouponGroupWhereInput>;
  some?: InputMaybe<CouponGroupWhereInput>;
};

export type CouponGroupMaxAggregate = {
  __typename?: 'CouponGroupMaxAggregate';
  createdAt?: Maybe<Scalars['DateTime']>;
  groupHeadId?: Maybe<Scalars['String']>;
  memberId?: Maybe<Scalars['String']>;
};

export type CouponGroupMaxOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  groupHeadId?: InputMaybe<SortOrder>;
  memberId?: InputMaybe<SortOrder>;
};

export type CouponGroupMinAggregate = {
  __typename?: 'CouponGroupMinAggregate';
  createdAt?: Maybe<Scalars['DateTime']>;
  groupHeadId?: Maybe<Scalars['String']>;
  memberId?: Maybe<Scalars['String']>;
};

export type CouponGroupMinOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  groupHeadId?: InputMaybe<SortOrder>;
  memberId?: InputMaybe<SortOrder>;
};

export type CouponGroupOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type CouponGroupOrderByWithAggregationInput = {
  _count?: InputMaybe<CouponGroupCountOrderByAggregateInput>;
  _max?: InputMaybe<CouponGroupMaxOrderByAggregateInput>;
  _min?: InputMaybe<CouponGroupMinOrderByAggregateInput>;
  createdAt?: InputMaybe<SortOrder>;
  groupHeadId?: InputMaybe<SortOrder>;
  memberId?: InputMaybe<SortOrder>;
};

export type CouponGroupOrderByWithRelationInput = {
  createdAt?: InputMaybe<SortOrder>;
  groupHead?: InputMaybe<CouponInfoOrderByWithRelationInput>;
  groupHeadId?: InputMaybe<SortOrder>;
  member?: InputMaybe<CouponInfoOrderByWithRelationInput>;
  memberId?: InputMaybe<SortOrder>;
};

export type CouponGroupRelationFilter = {
  is?: InputMaybe<CouponGroupWhereInput>;
  isNot?: InputMaybe<CouponGroupWhereInput>;
};

export enum CouponGroupScalarFieldEnum {
  CreatedAt = 'createdAt',
  GroupHeadId = 'groupHeadId',
  MemberId = 'memberId'
}

export type CouponGroupScalarWhereInput = {
  AND?: InputMaybe<Array<CouponGroupScalarWhereInput>>;
  NOT?: InputMaybe<Array<CouponGroupScalarWhereInput>>;
  OR?: InputMaybe<Array<CouponGroupScalarWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  groupHeadId?: InputMaybe<StringFilter>;
  memberId?: InputMaybe<StringFilter>;
};

export type CouponGroupScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<CouponGroupScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<CouponGroupScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<CouponGroupScalarWhereWithAggregatesInput>>;
  createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  groupHeadId?: InputMaybe<StringWithAggregatesFilter>;
  memberId?: InputMaybe<StringWithAggregatesFilter>;
};

export type CouponGroupUpdateInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  groupHead?: InputMaybe<CouponInfoUpdateOneRequiredWithoutGroupCouponsNestedInput>;
  member?: InputMaybe<CouponInfoUpdateOneRequiredWithoutGroupHeadNestedInput>;
};

export type CouponGroupUpdateManyMutationInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type CouponGroupUpdateManyWithWhereWithoutGroupHeadInput = {
  data: CouponGroupUpdateManyMutationInput;
  where: CouponGroupScalarWhereInput;
};

export type CouponGroupUpdateManyWithoutGroupHeadNestedInput = {
  connect?: InputMaybe<Array<CouponGroupWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CouponGroupCreateOrConnectWithoutGroupHeadInput>>;
  create?: InputMaybe<Array<CouponGroupCreateWithoutGroupHeadInput>>;
  createMany?: InputMaybe<CouponGroupCreateManyGroupHeadInputEnvelope>;
  delete?: InputMaybe<Array<CouponGroupWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<CouponGroupScalarWhereInput>>;
  disconnect?: InputMaybe<Array<CouponGroupWhereUniqueInput>>;
  set?: InputMaybe<Array<CouponGroupWhereUniqueInput>>;
  update?: InputMaybe<Array<CouponGroupUpdateWithWhereUniqueWithoutGroupHeadInput>>;
  updateMany?: InputMaybe<Array<CouponGroupUpdateManyWithWhereWithoutGroupHeadInput>>;
  upsert?: InputMaybe<Array<CouponGroupUpsertWithWhereUniqueWithoutGroupHeadInput>>;
};

export type CouponGroupUpdateOneWithoutMemberNestedInput = {
  connect?: InputMaybe<CouponGroupWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CouponGroupCreateOrConnectWithoutMemberInput>;
  create?: InputMaybe<CouponGroupCreateWithoutMemberInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<CouponGroupUpdateWithoutMemberInput>;
  upsert?: InputMaybe<CouponGroupUpsertWithoutMemberInput>;
};

export type CouponGroupUpdateWithWhereUniqueWithoutGroupHeadInput = {
  data: CouponGroupUpdateWithoutGroupHeadInput;
  where: CouponGroupWhereUniqueInput;
};

export type CouponGroupUpdateWithoutGroupHeadInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  member?: InputMaybe<CouponInfoUpdateOneRequiredWithoutGroupHeadNestedInput>;
};

export type CouponGroupUpdateWithoutMemberInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  groupHead?: InputMaybe<CouponInfoUpdateOneRequiredWithoutGroupCouponsNestedInput>;
};

export type CouponGroupUpsertWithWhereUniqueWithoutGroupHeadInput = {
  create: CouponGroupCreateWithoutGroupHeadInput;
  update: CouponGroupUpdateWithoutGroupHeadInput;
  where: CouponGroupWhereUniqueInput;
};

export type CouponGroupUpsertWithoutMemberInput = {
  create: CouponGroupCreateWithoutMemberInput;
  update: CouponGroupUpdateWithoutMemberInput;
};

export type CouponGroupWhereInput = {
  AND?: InputMaybe<Array<CouponGroupWhereInput>>;
  NOT?: InputMaybe<Array<CouponGroupWhereInput>>;
  OR?: InputMaybe<Array<CouponGroupWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  groupHead?: InputMaybe<CouponInfoRelationFilter>;
  groupHeadId?: InputMaybe<StringFilter>;
  member?: InputMaybe<CouponInfoRelationFilter>;
  memberId?: InputMaybe<StringFilter>;
};

export type CouponGroupWhereUniqueInput = {
  groupHeadId_memberId?: InputMaybe<CouponGroupGroupHeadIdMemberIdCompoundUniqueInput>;
  memberId?: InputMaybe<Scalars['String']>;
};

export type CouponInfo = {
  __typename?: 'CouponInfo';
  _count?: Maybe<CouponInfoCount>;
  activity?: Maybe<CouponActivity>;
  activityId?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  couponEntities: Array<CouponEntity>;
  createdAt: Scalars['DateTime'];
  description: Scalars['String'];
  discountSending?: Maybe<Scalars['Float']>;
  entitiesCount: Scalars['Int'];
  fixPriceInTW?: Maybe<Scalars['Decimal']>;
  fixPriceOverSea?: Maybe<Scalars['Decimal']>;
  freeSendingLmtCardId?: Maybe<Scalars['String']>;
  freeSendingLmtTW?: Maybe<Scalars['Boolean']>;
  freeSendingLmtTimes?: Maybe<Scalars['Int']>;
  groupCoupons: Array<CouponGroup>;
  groupHead?: Maybe<CouponGroup>;
  id: Scalars['String'];
  lmtEntitiesCount: Scalars['Int'];
  picUri?: Maybe<Scalars['String']>;
  pocoinsDeduct?: Maybe<Scalars['Decimal']>;
  qrcode?: Maybe<Scalars['String']>;
  type: CouponType;
  updatedAt: Scalars['DateTime'];
  valid: Scalars['Boolean'];
};


export type CouponInfoCouponEntitiesArgs = {
  cursor?: InputMaybe<CouponEntityWhereUniqueInput>;
  distinct?: InputMaybe<Array<CouponEntityScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<CouponEntityOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CouponEntityWhereInput>;
};


export type CouponInfoGroupCouponsArgs = {
  cursor?: InputMaybe<CouponGroupWhereUniqueInput>;
  distinct?: InputMaybe<Array<CouponGroupScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<CouponGroupOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CouponGroupWhereInput>;
};

export type CouponInfoAvgAggregate = {
  __typename?: 'CouponInfoAvgAggregate';
  discountSending?: Maybe<Scalars['Float']>;
  entitiesCount?: Maybe<Scalars['Float']>;
  fixPriceInTW?: Maybe<Scalars['Decimal']>;
  fixPriceOverSea?: Maybe<Scalars['Decimal']>;
  freeSendingLmtTimes?: Maybe<Scalars['Float']>;
  lmtEntitiesCount?: Maybe<Scalars['Float']>;
  pocoinsDeduct?: Maybe<Scalars['Decimal']>;
};

export type CouponInfoAvgOrderByAggregateInput = {
  discountSending?: InputMaybe<SortOrder>;
  entitiesCount?: InputMaybe<SortOrder>;
  fixPriceInTW?: InputMaybe<SortOrder>;
  fixPriceOverSea?: InputMaybe<SortOrder>;
  freeSendingLmtTimes?: InputMaybe<SortOrder>;
  lmtEntitiesCount?: InputMaybe<SortOrder>;
  pocoinsDeduct?: InputMaybe<SortOrder>;
};

export type CouponInfoCount = {
  __typename?: 'CouponInfoCount';
  couponEntities: Scalars['Int'];
  groupCoupons: Scalars['Int'];
};


export type CouponInfoCountCouponEntitiesArgs = {
  where?: InputMaybe<CouponEntityWhereInput>;
};


export type CouponInfoCountGroupCouponsArgs = {
  where?: InputMaybe<CouponGroupWhereInput>;
};

export type CouponInfoCountAggregate = {
  __typename?: 'CouponInfoCountAggregate';
  _all: Scalars['Int'];
  activityId: Scalars['Int'];
  code: Scalars['Int'];
  createdAt: Scalars['Int'];
  description: Scalars['Int'];
  discountSending: Scalars['Int'];
  entitiesCount: Scalars['Int'];
  fixPriceInTW: Scalars['Int'];
  fixPriceOverSea: Scalars['Int'];
  freeSendingLmtCardId: Scalars['Int'];
  freeSendingLmtTW: Scalars['Int'];
  freeSendingLmtTimes: Scalars['Int'];
  id: Scalars['Int'];
  lmtEntitiesCount: Scalars['Int'];
  picUri: Scalars['Int'];
  pocoinsDeduct: Scalars['Int'];
  qrcode: Scalars['Int'];
  type: Scalars['Int'];
  updatedAt: Scalars['Int'];
  valid: Scalars['Int'];
};

export type CouponInfoCountOrderByAggregateInput = {
  activityId?: InputMaybe<SortOrder>;
  code?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  discountSending?: InputMaybe<SortOrder>;
  entitiesCount?: InputMaybe<SortOrder>;
  fixPriceInTW?: InputMaybe<SortOrder>;
  fixPriceOverSea?: InputMaybe<SortOrder>;
  freeSendingLmtCardId?: InputMaybe<SortOrder>;
  freeSendingLmtTW?: InputMaybe<SortOrder>;
  freeSendingLmtTimes?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  lmtEntitiesCount?: InputMaybe<SortOrder>;
  picUri?: InputMaybe<SortOrder>;
  pocoinsDeduct?: InputMaybe<SortOrder>;
  qrcode?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  valid?: InputMaybe<SortOrder>;
};

export type CouponInfoCreateInput = {
  activity?: InputMaybe<CouponActivityCreateNestedOneWithoutCouponInfoInput>;
  code?: InputMaybe<Scalars['String']>;
  couponEntities?: InputMaybe<CouponEntityCreateNestedManyWithoutInfoInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  discountSending?: InputMaybe<Scalars['Float']>;
  entitiesCount?: InputMaybe<Scalars['Int']>;
  fixPriceInTW?: InputMaybe<Scalars['Decimal']>;
  fixPriceOverSea?: InputMaybe<Scalars['Decimal']>;
  freeSendingLmtCardId?: InputMaybe<Scalars['String']>;
  freeSendingLmtTW?: InputMaybe<Scalars['Boolean']>;
  freeSendingLmtTimes?: InputMaybe<Scalars['Int']>;
  groupCoupons?: InputMaybe<CouponGroupCreateNestedManyWithoutGroupHeadInput>;
  groupHead?: InputMaybe<CouponGroupCreateNestedOneWithoutMemberInput>;
  id?: InputMaybe<Scalars['String']>;
  lmtEntitiesCount?: InputMaybe<Scalars['Int']>;
  picUri?: InputMaybe<Scalars['String']>;
  pocoinsDeduct?: InputMaybe<Scalars['Decimal']>;
  qrcode?: InputMaybe<Scalars['String']>;
  type: CouponType;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  valid?: InputMaybe<Scalars['Boolean']>;
};

export type CouponInfoCreateManyInput = {
  activityId?: InputMaybe<Scalars['String']>;
  code?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  discountSending?: InputMaybe<Scalars['Float']>;
  entitiesCount?: InputMaybe<Scalars['Int']>;
  fixPriceInTW?: InputMaybe<Scalars['Decimal']>;
  fixPriceOverSea?: InputMaybe<Scalars['Decimal']>;
  freeSendingLmtCardId?: InputMaybe<Scalars['String']>;
  freeSendingLmtTW?: InputMaybe<Scalars['Boolean']>;
  freeSendingLmtTimes?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['String']>;
  lmtEntitiesCount?: InputMaybe<Scalars['Int']>;
  picUri?: InputMaybe<Scalars['String']>;
  pocoinsDeduct?: InputMaybe<Scalars['Decimal']>;
  qrcode?: InputMaybe<Scalars['String']>;
  type: CouponType;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  valid?: InputMaybe<Scalars['Boolean']>;
};

export type CouponInfoCreateNestedOneWithoutActivityInput = {
  connect?: InputMaybe<CouponInfoWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CouponInfoCreateOrConnectWithoutActivityInput>;
  create?: InputMaybe<CouponInfoCreateWithoutActivityInput>;
};

export type CouponInfoCreateNestedOneWithoutCouponEntitiesInput = {
  connect?: InputMaybe<CouponInfoWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CouponInfoCreateOrConnectWithoutCouponEntitiesInput>;
  create?: InputMaybe<CouponInfoCreateWithoutCouponEntitiesInput>;
};

export type CouponInfoCreateNestedOneWithoutGroupCouponsInput = {
  connect?: InputMaybe<CouponInfoWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CouponInfoCreateOrConnectWithoutGroupCouponsInput>;
  create?: InputMaybe<CouponInfoCreateWithoutGroupCouponsInput>;
};

export type CouponInfoCreateNestedOneWithoutGroupHeadInput = {
  connect?: InputMaybe<CouponInfoWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CouponInfoCreateOrConnectWithoutGroupHeadInput>;
  create?: InputMaybe<CouponInfoCreateWithoutGroupHeadInput>;
};

export type CouponInfoCreateOrConnectWithoutActivityInput = {
  create: CouponInfoCreateWithoutActivityInput;
  where: CouponInfoWhereUniqueInput;
};

export type CouponInfoCreateOrConnectWithoutCouponEntitiesInput = {
  create: CouponInfoCreateWithoutCouponEntitiesInput;
  where: CouponInfoWhereUniqueInput;
};

export type CouponInfoCreateOrConnectWithoutGroupCouponsInput = {
  create: CouponInfoCreateWithoutGroupCouponsInput;
  where: CouponInfoWhereUniqueInput;
};

export type CouponInfoCreateOrConnectWithoutGroupHeadInput = {
  create: CouponInfoCreateWithoutGroupHeadInput;
  where: CouponInfoWhereUniqueInput;
};

export type CouponInfoCreateWithoutActivityInput = {
  code?: InputMaybe<Scalars['String']>;
  couponEntities?: InputMaybe<CouponEntityCreateNestedManyWithoutInfoInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  discountSending?: InputMaybe<Scalars['Float']>;
  entitiesCount?: InputMaybe<Scalars['Int']>;
  fixPriceInTW?: InputMaybe<Scalars['Decimal']>;
  fixPriceOverSea?: InputMaybe<Scalars['Decimal']>;
  freeSendingLmtCardId?: InputMaybe<Scalars['String']>;
  freeSendingLmtTW?: InputMaybe<Scalars['Boolean']>;
  freeSendingLmtTimes?: InputMaybe<Scalars['Int']>;
  groupCoupons?: InputMaybe<CouponGroupCreateNestedManyWithoutGroupHeadInput>;
  groupHead?: InputMaybe<CouponGroupCreateNestedOneWithoutMemberInput>;
  id?: InputMaybe<Scalars['String']>;
  lmtEntitiesCount?: InputMaybe<Scalars['Int']>;
  picUri?: InputMaybe<Scalars['String']>;
  pocoinsDeduct?: InputMaybe<Scalars['Decimal']>;
  qrcode?: InputMaybe<Scalars['String']>;
  type: CouponType;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  valid?: InputMaybe<Scalars['Boolean']>;
};

export type CouponInfoCreateWithoutCouponEntitiesInput = {
  activity?: InputMaybe<CouponActivityCreateNestedOneWithoutCouponInfoInput>;
  code?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  discountSending?: InputMaybe<Scalars['Float']>;
  entitiesCount?: InputMaybe<Scalars['Int']>;
  fixPriceInTW?: InputMaybe<Scalars['Decimal']>;
  fixPriceOverSea?: InputMaybe<Scalars['Decimal']>;
  freeSendingLmtCardId?: InputMaybe<Scalars['String']>;
  freeSendingLmtTW?: InputMaybe<Scalars['Boolean']>;
  freeSendingLmtTimes?: InputMaybe<Scalars['Int']>;
  groupCoupons?: InputMaybe<CouponGroupCreateNestedManyWithoutGroupHeadInput>;
  groupHead?: InputMaybe<CouponGroupCreateNestedOneWithoutMemberInput>;
  id?: InputMaybe<Scalars['String']>;
  lmtEntitiesCount?: InputMaybe<Scalars['Int']>;
  picUri?: InputMaybe<Scalars['String']>;
  pocoinsDeduct?: InputMaybe<Scalars['Decimal']>;
  qrcode?: InputMaybe<Scalars['String']>;
  type: CouponType;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  valid?: InputMaybe<Scalars['Boolean']>;
};

export type CouponInfoCreateWithoutGroupCouponsInput = {
  activity?: InputMaybe<CouponActivityCreateNestedOneWithoutCouponInfoInput>;
  code?: InputMaybe<Scalars['String']>;
  couponEntities?: InputMaybe<CouponEntityCreateNestedManyWithoutInfoInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  discountSending?: InputMaybe<Scalars['Float']>;
  entitiesCount?: InputMaybe<Scalars['Int']>;
  fixPriceInTW?: InputMaybe<Scalars['Decimal']>;
  fixPriceOverSea?: InputMaybe<Scalars['Decimal']>;
  freeSendingLmtCardId?: InputMaybe<Scalars['String']>;
  freeSendingLmtTW?: InputMaybe<Scalars['Boolean']>;
  freeSendingLmtTimes?: InputMaybe<Scalars['Int']>;
  groupHead?: InputMaybe<CouponGroupCreateNestedOneWithoutMemberInput>;
  id?: InputMaybe<Scalars['String']>;
  lmtEntitiesCount?: InputMaybe<Scalars['Int']>;
  picUri?: InputMaybe<Scalars['String']>;
  pocoinsDeduct?: InputMaybe<Scalars['Decimal']>;
  qrcode?: InputMaybe<Scalars['String']>;
  type: CouponType;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  valid?: InputMaybe<Scalars['Boolean']>;
};

export type CouponInfoCreateWithoutGroupHeadInput = {
  activity?: InputMaybe<CouponActivityCreateNestedOneWithoutCouponInfoInput>;
  code?: InputMaybe<Scalars['String']>;
  couponEntities?: InputMaybe<CouponEntityCreateNestedManyWithoutInfoInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  discountSending?: InputMaybe<Scalars['Float']>;
  entitiesCount?: InputMaybe<Scalars['Int']>;
  fixPriceInTW?: InputMaybe<Scalars['Decimal']>;
  fixPriceOverSea?: InputMaybe<Scalars['Decimal']>;
  freeSendingLmtCardId?: InputMaybe<Scalars['String']>;
  freeSendingLmtTW?: InputMaybe<Scalars['Boolean']>;
  freeSendingLmtTimes?: InputMaybe<Scalars['Int']>;
  groupCoupons?: InputMaybe<CouponGroupCreateNestedManyWithoutGroupHeadInput>;
  id?: InputMaybe<Scalars['String']>;
  lmtEntitiesCount?: InputMaybe<Scalars['Int']>;
  picUri?: InputMaybe<Scalars['String']>;
  pocoinsDeduct?: InputMaybe<Scalars['Decimal']>;
  qrcode?: InputMaybe<Scalars['String']>;
  type: CouponType;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  valid?: InputMaybe<Scalars['Boolean']>;
};

export type CouponInfoGroupBy = {
  __typename?: 'CouponInfoGroupBy';
  _avg?: Maybe<CouponInfoAvgAggregate>;
  _count?: Maybe<CouponInfoCountAggregate>;
  _max?: Maybe<CouponInfoMaxAggregate>;
  _min?: Maybe<CouponInfoMinAggregate>;
  _sum?: Maybe<CouponInfoSumAggregate>;
  activityId?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  description: Scalars['String'];
  discountSending?: Maybe<Scalars['Float']>;
  entitiesCount: Scalars['Int'];
  fixPriceInTW?: Maybe<Scalars['Decimal']>;
  fixPriceOverSea?: Maybe<Scalars['Decimal']>;
  freeSendingLmtCardId?: Maybe<Scalars['String']>;
  freeSendingLmtTW?: Maybe<Scalars['Boolean']>;
  freeSendingLmtTimes?: Maybe<Scalars['Int']>;
  id: Scalars['String'];
  lmtEntitiesCount: Scalars['Int'];
  picUri?: Maybe<Scalars['String']>;
  pocoinsDeduct?: Maybe<Scalars['Decimal']>;
  qrcode?: Maybe<Scalars['String']>;
  type: CouponType;
  updatedAt: Scalars['DateTime'];
  valid: Scalars['Boolean'];
};

export type CouponInfoMaxAggregate = {
  __typename?: 'CouponInfoMaxAggregate';
  activityId?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  discountSending?: Maybe<Scalars['Float']>;
  entitiesCount?: Maybe<Scalars['Int']>;
  fixPriceInTW?: Maybe<Scalars['Decimal']>;
  fixPriceOverSea?: Maybe<Scalars['Decimal']>;
  freeSendingLmtCardId?: Maybe<Scalars['String']>;
  freeSendingLmtTW?: Maybe<Scalars['Boolean']>;
  freeSendingLmtTimes?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  lmtEntitiesCount?: Maybe<Scalars['Int']>;
  picUri?: Maybe<Scalars['String']>;
  pocoinsDeduct?: Maybe<Scalars['Decimal']>;
  qrcode?: Maybe<Scalars['String']>;
  type?: Maybe<CouponType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  valid?: Maybe<Scalars['Boolean']>;
};

export type CouponInfoMaxOrderByAggregateInput = {
  activityId?: InputMaybe<SortOrder>;
  code?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  discountSending?: InputMaybe<SortOrder>;
  entitiesCount?: InputMaybe<SortOrder>;
  fixPriceInTW?: InputMaybe<SortOrder>;
  fixPriceOverSea?: InputMaybe<SortOrder>;
  freeSendingLmtCardId?: InputMaybe<SortOrder>;
  freeSendingLmtTW?: InputMaybe<SortOrder>;
  freeSendingLmtTimes?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  lmtEntitiesCount?: InputMaybe<SortOrder>;
  picUri?: InputMaybe<SortOrder>;
  pocoinsDeduct?: InputMaybe<SortOrder>;
  qrcode?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  valid?: InputMaybe<SortOrder>;
};

export type CouponInfoMinAggregate = {
  __typename?: 'CouponInfoMinAggregate';
  activityId?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  discountSending?: Maybe<Scalars['Float']>;
  entitiesCount?: Maybe<Scalars['Int']>;
  fixPriceInTW?: Maybe<Scalars['Decimal']>;
  fixPriceOverSea?: Maybe<Scalars['Decimal']>;
  freeSendingLmtCardId?: Maybe<Scalars['String']>;
  freeSendingLmtTW?: Maybe<Scalars['Boolean']>;
  freeSendingLmtTimes?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  lmtEntitiesCount?: Maybe<Scalars['Int']>;
  picUri?: Maybe<Scalars['String']>;
  pocoinsDeduct?: Maybe<Scalars['Decimal']>;
  qrcode?: Maybe<Scalars['String']>;
  type?: Maybe<CouponType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  valid?: Maybe<Scalars['Boolean']>;
};

export type CouponInfoMinOrderByAggregateInput = {
  activityId?: InputMaybe<SortOrder>;
  code?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  discountSending?: InputMaybe<SortOrder>;
  entitiesCount?: InputMaybe<SortOrder>;
  fixPriceInTW?: InputMaybe<SortOrder>;
  fixPriceOverSea?: InputMaybe<SortOrder>;
  freeSendingLmtCardId?: InputMaybe<SortOrder>;
  freeSendingLmtTW?: InputMaybe<SortOrder>;
  freeSendingLmtTimes?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  lmtEntitiesCount?: InputMaybe<SortOrder>;
  picUri?: InputMaybe<SortOrder>;
  pocoinsDeduct?: InputMaybe<SortOrder>;
  qrcode?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  valid?: InputMaybe<SortOrder>;
};

export type CouponInfoOrderByWithAggregationInput = {
  _avg?: InputMaybe<CouponInfoAvgOrderByAggregateInput>;
  _count?: InputMaybe<CouponInfoCountOrderByAggregateInput>;
  _max?: InputMaybe<CouponInfoMaxOrderByAggregateInput>;
  _min?: InputMaybe<CouponInfoMinOrderByAggregateInput>;
  _sum?: InputMaybe<CouponInfoSumOrderByAggregateInput>;
  activityId?: InputMaybe<SortOrder>;
  code?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  discountSending?: InputMaybe<SortOrder>;
  entitiesCount?: InputMaybe<SortOrder>;
  fixPriceInTW?: InputMaybe<SortOrder>;
  fixPriceOverSea?: InputMaybe<SortOrder>;
  freeSendingLmtCardId?: InputMaybe<SortOrder>;
  freeSendingLmtTW?: InputMaybe<SortOrder>;
  freeSendingLmtTimes?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  lmtEntitiesCount?: InputMaybe<SortOrder>;
  picUri?: InputMaybe<SortOrder>;
  pocoinsDeduct?: InputMaybe<SortOrder>;
  qrcode?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  valid?: InputMaybe<SortOrder>;
};

export type CouponInfoOrderByWithRelationInput = {
  activity?: InputMaybe<CouponActivityOrderByWithRelationInput>;
  activityId?: InputMaybe<SortOrder>;
  code?: InputMaybe<SortOrder>;
  couponEntities?: InputMaybe<CouponEntityOrderByRelationAggregateInput>;
  createdAt?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  discountSending?: InputMaybe<SortOrder>;
  entitiesCount?: InputMaybe<SortOrder>;
  fixPriceInTW?: InputMaybe<SortOrder>;
  fixPriceOverSea?: InputMaybe<SortOrder>;
  freeSendingLmtCardId?: InputMaybe<SortOrder>;
  freeSendingLmtTW?: InputMaybe<SortOrder>;
  freeSendingLmtTimes?: InputMaybe<SortOrder>;
  groupCoupons?: InputMaybe<CouponGroupOrderByRelationAggregateInput>;
  groupHead?: InputMaybe<CouponGroupOrderByWithRelationInput>;
  id?: InputMaybe<SortOrder>;
  lmtEntitiesCount?: InputMaybe<SortOrder>;
  picUri?: InputMaybe<SortOrder>;
  pocoinsDeduct?: InputMaybe<SortOrder>;
  qrcode?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  valid?: InputMaybe<SortOrder>;
};

export type CouponInfoRelationFilter = {
  is?: InputMaybe<CouponInfoWhereInput>;
  isNot?: InputMaybe<CouponInfoWhereInput>;
};

export enum CouponInfoScalarFieldEnum {
  ActivityId = 'activityId',
  Code = 'code',
  CreatedAt = 'createdAt',
  Description = 'description',
  DiscountSending = 'discountSending',
  EntitiesCount = 'entitiesCount',
  FixPriceInTw = 'fixPriceInTW',
  FixPriceOverSea = 'fixPriceOverSea',
  FreeSendingLmtCardId = 'freeSendingLmtCardId',
  FreeSendingLmtTw = 'freeSendingLmtTW',
  FreeSendingLmtTimes = 'freeSendingLmtTimes',
  Id = 'id',
  LmtEntitiesCount = 'lmtEntitiesCount',
  PicUri = 'picUri',
  PocoinsDeduct = 'pocoinsDeduct',
  Qrcode = 'qrcode',
  Type = 'type',
  UpdatedAt = 'updatedAt',
  Valid = 'valid'
}

export type CouponInfoScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<CouponInfoScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<CouponInfoScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<CouponInfoScalarWhereWithAggregatesInput>>;
  activityId?: InputMaybe<StringNullableWithAggregatesFilter>;
  code?: InputMaybe<StringNullableWithAggregatesFilter>;
  createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  description?: InputMaybe<StringWithAggregatesFilter>;
  discountSending?: InputMaybe<FloatNullableWithAggregatesFilter>;
  entitiesCount?: InputMaybe<IntWithAggregatesFilter>;
  fixPriceInTW?: InputMaybe<DecimalNullableWithAggregatesFilter>;
  fixPriceOverSea?: InputMaybe<DecimalNullableWithAggregatesFilter>;
  freeSendingLmtCardId?: InputMaybe<StringNullableWithAggregatesFilter>;
  freeSendingLmtTW?: InputMaybe<BoolNullableWithAggregatesFilter>;
  freeSendingLmtTimes?: InputMaybe<IntNullableWithAggregatesFilter>;
  id?: InputMaybe<StringWithAggregatesFilter>;
  lmtEntitiesCount?: InputMaybe<IntWithAggregatesFilter>;
  picUri?: InputMaybe<StringNullableWithAggregatesFilter>;
  pocoinsDeduct?: InputMaybe<DecimalNullableWithAggregatesFilter>;
  qrcode?: InputMaybe<StringNullableWithAggregatesFilter>;
  type?: InputMaybe<EnumCouponTypeWithAggregatesFilter>;
  updatedAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  valid?: InputMaybe<BoolWithAggregatesFilter>;
};

export type CouponInfoSumAggregate = {
  __typename?: 'CouponInfoSumAggregate';
  discountSending?: Maybe<Scalars['Float']>;
  entitiesCount?: Maybe<Scalars['Int']>;
  fixPriceInTW?: Maybe<Scalars['Decimal']>;
  fixPriceOverSea?: Maybe<Scalars['Decimal']>;
  freeSendingLmtTimes?: Maybe<Scalars['Int']>;
  lmtEntitiesCount?: Maybe<Scalars['Int']>;
  pocoinsDeduct?: Maybe<Scalars['Decimal']>;
};

export type CouponInfoSumOrderByAggregateInput = {
  discountSending?: InputMaybe<SortOrder>;
  entitiesCount?: InputMaybe<SortOrder>;
  fixPriceInTW?: InputMaybe<SortOrder>;
  fixPriceOverSea?: InputMaybe<SortOrder>;
  freeSendingLmtTimes?: InputMaybe<SortOrder>;
  lmtEntitiesCount?: InputMaybe<SortOrder>;
  pocoinsDeduct?: InputMaybe<SortOrder>;
};

export type CouponInfoUpdateInput = {
  activity?: InputMaybe<CouponActivityUpdateOneWithoutCouponInfoNestedInput>;
  code?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  couponEntities?: InputMaybe<CouponEntityUpdateManyWithoutInfoNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<StringFieldUpdateOperationsInput>;
  discountSending?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  entitiesCount?: InputMaybe<IntFieldUpdateOperationsInput>;
  fixPriceInTW?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
  fixPriceOverSea?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
  freeSendingLmtCardId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  freeSendingLmtTW?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  freeSendingLmtTimes?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  groupCoupons?: InputMaybe<CouponGroupUpdateManyWithoutGroupHeadNestedInput>;
  groupHead?: InputMaybe<CouponGroupUpdateOneWithoutMemberNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  lmtEntitiesCount?: InputMaybe<IntFieldUpdateOperationsInput>;
  picUri?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  pocoinsDeduct?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
  qrcode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  type?: InputMaybe<EnumCouponTypeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  valid?: InputMaybe<BoolFieldUpdateOperationsInput>;
};

export type CouponInfoUpdateManyMutationInput = {
  code?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<StringFieldUpdateOperationsInput>;
  discountSending?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  entitiesCount?: InputMaybe<IntFieldUpdateOperationsInput>;
  fixPriceInTW?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
  fixPriceOverSea?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
  freeSendingLmtCardId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  freeSendingLmtTW?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  freeSendingLmtTimes?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  lmtEntitiesCount?: InputMaybe<IntFieldUpdateOperationsInput>;
  picUri?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  pocoinsDeduct?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
  qrcode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  type?: InputMaybe<EnumCouponTypeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  valid?: InputMaybe<BoolFieldUpdateOperationsInput>;
};

export type CouponInfoUpdateOneRequiredWithoutCouponEntitiesNestedInput = {
  connect?: InputMaybe<CouponInfoWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CouponInfoCreateOrConnectWithoutCouponEntitiesInput>;
  create?: InputMaybe<CouponInfoCreateWithoutCouponEntitiesInput>;
  update?: InputMaybe<CouponInfoUpdateWithoutCouponEntitiesInput>;
  upsert?: InputMaybe<CouponInfoUpsertWithoutCouponEntitiesInput>;
};

export type CouponInfoUpdateOneRequiredWithoutGroupCouponsNestedInput = {
  connect?: InputMaybe<CouponInfoWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CouponInfoCreateOrConnectWithoutGroupCouponsInput>;
  create?: InputMaybe<CouponInfoCreateWithoutGroupCouponsInput>;
  update?: InputMaybe<CouponInfoUpdateWithoutGroupCouponsInput>;
  upsert?: InputMaybe<CouponInfoUpsertWithoutGroupCouponsInput>;
};

export type CouponInfoUpdateOneRequiredWithoutGroupHeadNestedInput = {
  connect?: InputMaybe<CouponInfoWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CouponInfoCreateOrConnectWithoutGroupHeadInput>;
  create?: InputMaybe<CouponInfoCreateWithoutGroupHeadInput>;
  update?: InputMaybe<CouponInfoUpdateWithoutGroupHeadInput>;
  upsert?: InputMaybe<CouponInfoUpsertWithoutGroupHeadInput>;
};

export type CouponInfoUpdateOneWithoutActivityNestedInput = {
  connect?: InputMaybe<CouponInfoWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CouponInfoCreateOrConnectWithoutActivityInput>;
  create?: InputMaybe<CouponInfoCreateWithoutActivityInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<CouponInfoUpdateWithoutActivityInput>;
  upsert?: InputMaybe<CouponInfoUpsertWithoutActivityInput>;
};

export type CouponInfoUpdateWithoutActivityInput = {
  code?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  couponEntities?: InputMaybe<CouponEntityUpdateManyWithoutInfoNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<StringFieldUpdateOperationsInput>;
  discountSending?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  entitiesCount?: InputMaybe<IntFieldUpdateOperationsInput>;
  fixPriceInTW?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
  fixPriceOverSea?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
  freeSendingLmtCardId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  freeSendingLmtTW?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  freeSendingLmtTimes?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  groupCoupons?: InputMaybe<CouponGroupUpdateManyWithoutGroupHeadNestedInput>;
  groupHead?: InputMaybe<CouponGroupUpdateOneWithoutMemberNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  lmtEntitiesCount?: InputMaybe<IntFieldUpdateOperationsInput>;
  picUri?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  pocoinsDeduct?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
  qrcode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  type?: InputMaybe<EnumCouponTypeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  valid?: InputMaybe<BoolFieldUpdateOperationsInput>;
};

export type CouponInfoUpdateWithoutCouponEntitiesInput = {
  activity?: InputMaybe<CouponActivityUpdateOneWithoutCouponInfoNestedInput>;
  code?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<StringFieldUpdateOperationsInput>;
  discountSending?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  entitiesCount?: InputMaybe<IntFieldUpdateOperationsInput>;
  fixPriceInTW?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
  fixPriceOverSea?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
  freeSendingLmtCardId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  freeSendingLmtTW?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  freeSendingLmtTimes?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  groupCoupons?: InputMaybe<CouponGroupUpdateManyWithoutGroupHeadNestedInput>;
  groupHead?: InputMaybe<CouponGroupUpdateOneWithoutMemberNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  lmtEntitiesCount?: InputMaybe<IntFieldUpdateOperationsInput>;
  picUri?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  pocoinsDeduct?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
  qrcode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  type?: InputMaybe<EnumCouponTypeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  valid?: InputMaybe<BoolFieldUpdateOperationsInput>;
};

export type CouponInfoUpdateWithoutGroupCouponsInput = {
  activity?: InputMaybe<CouponActivityUpdateOneWithoutCouponInfoNestedInput>;
  code?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  couponEntities?: InputMaybe<CouponEntityUpdateManyWithoutInfoNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<StringFieldUpdateOperationsInput>;
  discountSending?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  entitiesCount?: InputMaybe<IntFieldUpdateOperationsInput>;
  fixPriceInTW?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
  fixPriceOverSea?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
  freeSendingLmtCardId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  freeSendingLmtTW?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  freeSendingLmtTimes?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  groupHead?: InputMaybe<CouponGroupUpdateOneWithoutMemberNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  lmtEntitiesCount?: InputMaybe<IntFieldUpdateOperationsInput>;
  picUri?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  pocoinsDeduct?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
  qrcode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  type?: InputMaybe<EnumCouponTypeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  valid?: InputMaybe<BoolFieldUpdateOperationsInput>;
};

export type CouponInfoUpdateWithoutGroupHeadInput = {
  activity?: InputMaybe<CouponActivityUpdateOneWithoutCouponInfoNestedInput>;
  code?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  couponEntities?: InputMaybe<CouponEntityUpdateManyWithoutInfoNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<StringFieldUpdateOperationsInput>;
  discountSending?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  entitiesCount?: InputMaybe<IntFieldUpdateOperationsInput>;
  fixPriceInTW?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
  fixPriceOverSea?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
  freeSendingLmtCardId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  freeSendingLmtTW?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  freeSendingLmtTimes?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  groupCoupons?: InputMaybe<CouponGroupUpdateManyWithoutGroupHeadNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  lmtEntitiesCount?: InputMaybe<IntFieldUpdateOperationsInput>;
  picUri?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  pocoinsDeduct?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
  qrcode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  type?: InputMaybe<EnumCouponTypeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  valid?: InputMaybe<BoolFieldUpdateOperationsInput>;
};

export type CouponInfoUpsertWithoutActivityInput = {
  create: CouponInfoCreateWithoutActivityInput;
  update: CouponInfoUpdateWithoutActivityInput;
};

export type CouponInfoUpsertWithoutCouponEntitiesInput = {
  create: CouponInfoCreateWithoutCouponEntitiesInput;
  update: CouponInfoUpdateWithoutCouponEntitiesInput;
};

export type CouponInfoUpsertWithoutGroupCouponsInput = {
  create: CouponInfoCreateWithoutGroupCouponsInput;
  update: CouponInfoUpdateWithoutGroupCouponsInput;
};

export type CouponInfoUpsertWithoutGroupHeadInput = {
  create: CouponInfoCreateWithoutGroupHeadInput;
  update: CouponInfoUpdateWithoutGroupHeadInput;
};

export type CouponInfoWhereInput = {
  AND?: InputMaybe<Array<CouponInfoWhereInput>>;
  NOT?: InputMaybe<Array<CouponInfoWhereInput>>;
  OR?: InputMaybe<Array<CouponInfoWhereInput>>;
  activity?: InputMaybe<CouponActivityRelationFilter>;
  activityId?: InputMaybe<StringNullableFilter>;
  code?: InputMaybe<StringNullableFilter>;
  couponEntities?: InputMaybe<CouponEntityListRelationFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  description?: InputMaybe<StringFilter>;
  discountSending?: InputMaybe<FloatNullableFilter>;
  entitiesCount?: InputMaybe<IntFilter>;
  fixPriceInTW?: InputMaybe<DecimalNullableFilter>;
  fixPriceOverSea?: InputMaybe<DecimalNullableFilter>;
  freeSendingLmtCardId?: InputMaybe<StringNullableFilter>;
  freeSendingLmtTW?: InputMaybe<BoolNullableFilter>;
  freeSendingLmtTimes?: InputMaybe<IntNullableFilter>;
  groupCoupons?: InputMaybe<CouponGroupListRelationFilter>;
  groupHead?: InputMaybe<CouponGroupRelationFilter>;
  id?: InputMaybe<StringFilter>;
  lmtEntitiesCount?: InputMaybe<IntFilter>;
  picUri?: InputMaybe<StringNullableFilter>;
  pocoinsDeduct?: InputMaybe<DecimalNullableFilter>;
  qrcode?: InputMaybe<StringNullableFilter>;
  type?: InputMaybe<EnumCouponTypeFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  valid?: InputMaybe<BoolFilter>;
};

export type CouponInfoWhereUniqueInput = {
  activityId?: InputMaybe<Scalars['String']>;
  code?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  qrcode?: InputMaybe<Scalars['String']>;
};

export enum CouponType {
  CouponActivoty = 'COUPON_ACTIVOTY',
  DiscountSending = 'DISCOUNT_SENDING',
  DiscountSendingCouponActivity = 'DISCOUNT_SENDING__COUPON_ACTIVITY',
  FixpriceSending = 'FIXPRICE_SENDING',
  FixpriceSendingCouponActivity = 'FIXPRICE_SENDING__COUPON_ACTIVITY',
  FreeSending = 'FREE_SENDING',
  FreeSendingCouponActivoty = 'FREE_SENDING__COUPON_ACTIVOTY',
  PocoinsDeduct = 'POCOINS_DEDUCT'
}

export type CouponUsage = {
  __typename?: 'CouponUsage';
  action: CouponUsageAction;
  coupon: CouponEntity;
  couponId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  user: User;
  userId: Scalars['String'];
};

export enum CouponUsageAction {
  Consume = 'CONSUME',
  Transfer = 'TRANSFER'
}

export type CouponUsageCountAggregate = {
  __typename?: 'CouponUsageCountAggregate';
  _all: Scalars['Int'];
  action: Scalars['Int'];
  couponId: Scalars['Int'];
  createdAt: Scalars['Int'];
  id: Scalars['Int'];
  updatedAt: Scalars['Int'];
  userId: Scalars['Int'];
};

export type CouponUsageCountOrderByAggregateInput = {
  action?: InputMaybe<SortOrder>;
  couponId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
};

export type CouponUsageCreateInput = {
  action: CouponUsageAction;
  coupon: CouponEntityCreateNestedOneWithoutUsageHistoryInput;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  user: UserCreateNestedOneWithoutCouponUsagesInput;
};

export type CouponUsageCreateManyCouponInput = {
  action: CouponUsageAction;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userId: Scalars['String'];
};

export type CouponUsageCreateManyCouponInputEnvelope = {
  data: Array<CouponUsageCreateManyCouponInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type CouponUsageCreateManyInput = {
  action: CouponUsageAction;
  couponId: Scalars['String'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userId: Scalars['String'];
};

export type CouponUsageCreateManyUserInput = {
  action: CouponUsageAction;
  couponId: Scalars['String'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type CouponUsageCreateManyUserInputEnvelope = {
  data: Array<CouponUsageCreateManyUserInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type CouponUsageCreateNestedManyWithoutCouponInput = {
  connect?: InputMaybe<Array<CouponUsageWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CouponUsageCreateOrConnectWithoutCouponInput>>;
  create?: InputMaybe<Array<CouponUsageCreateWithoutCouponInput>>;
  createMany?: InputMaybe<CouponUsageCreateManyCouponInputEnvelope>;
};

export type CouponUsageCreateNestedManyWithoutUserInput = {
  connect?: InputMaybe<Array<CouponUsageWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CouponUsageCreateOrConnectWithoutUserInput>>;
  create?: InputMaybe<Array<CouponUsageCreateWithoutUserInput>>;
  createMany?: InputMaybe<CouponUsageCreateManyUserInputEnvelope>;
};

export type CouponUsageCreateOrConnectWithoutCouponInput = {
  create: CouponUsageCreateWithoutCouponInput;
  where: CouponUsageWhereUniqueInput;
};

export type CouponUsageCreateOrConnectWithoutUserInput = {
  create: CouponUsageCreateWithoutUserInput;
  where: CouponUsageWhereUniqueInput;
};

export type CouponUsageCreateWithoutCouponInput = {
  action: CouponUsageAction;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  user: UserCreateNestedOneWithoutCouponUsagesInput;
};

export type CouponUsageCreateWithoutUserInput = {
  action: CouponUsageAction;
  coupon: CouponEntityCreateNestedOneWithoutUsageHistoryInput;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type CouponUsageGroupBy = {
  __typename?: 'CouponUsageGroupBy';
  _count?: Maybe<CouponUsageCountAggregate>;
  _max?: Maybe<CouponUsageMaxAggregate>;
  _min?: Maybe<CouponUsageMinAggregate>;
  action: CouponUsageAction;
  couponId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  userId: Scalars['String'];
};

export type CouponUsageListRelationFilter = {
  every?: InputMaybe<CouponUsageWhereInput>;
  none?: InputMaybe<CouponUsageWhereInput>;
  some?: InputMaybe<CouponUsageWhereInput>;
};

export type CouponUsageMaxAggregate = {
  __typename?: 'CouponUsageMaxAggregate';
  action?: Maybe<CouponUsageAction>;
  couponId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
};

export type CouponUsageMaxOrderByAggregateInput = {
  action?: InputMaybe<SortOrder>;
  couponId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
};

export type CouponUsageMinAggregate = {
  __typename?: 'CouponUsageMinAggregate';
  action?: Maybe<CouponUsageAction>;
  couponId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
};

export type CouponUsageMinOrderByAggregateInput = {
  action?: InputMaybe<SortOrder>;
  couponId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
};

export type CouponUsageOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type CouponUsageOrderByWithAggregationInput = {
  _count?: InputMaybe<CouponUsageCountOrderByAggregateInput>;
  _max?: InputMaybe<CouponUsageMaxOrderByAggregateInput>;
  _min?: InputMaybe<CouponUsageMinOrderByAggregateInput>;
  action?: InputMaybe<SortOrder>;
  couponId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
};

export type CouponUsageOrderByWithRelationInput = {
  action?: InputMaybe<SortOrder>;
  coupon?: InputMaybe<CouponEntityOrderByWithRelationInput>;
  couponId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  user?: InputMaybe<UserOrderByWithRelationInput>;
  userId?: InputMaybe<SortOrder>;
};

export enum CouponUsageScalarFieldEnum {
  Action = 'action',
  CouponId = 'couponId',
  CreatedAt = 'createdAt',
  Id = 'id',
  UpdatedAt = 'updatedAt',
  UserId = 'userId'
}

export type CouponUsageScalarWhereInput = {
  AND?: InputMaybe<Array<CouponUsageScalarWhereInput>>;
  NOT?: InputMaybe<Array<CouponUsageScalarWhereInput>>;
  OR?: InputMaybe<Array<CouponUsageScalarWhereInput>>;
  action?: InputMaybe<EnumCouponUsageActionFilter>;
  couponId?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  userId?: InputMaybe<StringFilter>;
};

export type CouponUsageScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<CouponUsageScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<CouponUsageScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<CouponUsageScalarWhereWithAggregatesInput>>;
  action?: InputMaybe<EnumCouponUsageActionWithAggregatesFilter>;
  couponId?: InputMaybe<StringWithAggregatesFilter>;
  createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  id?: InputMaybe<StringWithAggregatesFilter>;
  updatedAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  userId?: InputMaybe<StringWithAggregatesFilter>;
};

export type CouponUsageUpdateInput = {
  action?: InputMaybe<EnumCouponUsageActionFieldUpdateOperationsInput>;
  coupon?: InputMaybe<CouponEntityUpdateOneRequiredWithoutUsageHistoryNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutCouponUsagesNestedInput>;
};

export type CouponUsageUpdateManyMutationInput = {
  action?: InputMaybe<EnumCouponUsageActionFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type CouponUsageUpdateManyWithWhereWithoutCouponInput = {
  data: CouponUsageUpdateManyMutationInput;
  where: CouponUsageScalarWhereInput;
};

export type CouponUsageUpdateManyWithWhereWithoutUserInput = {
  data: CouponUsageUpdateManyMutationInput;
  where: CouponUsageScalarWhereInput;
};

export type CouponUsageUpdateManyWithoutCouponNestedInput = {
  connect?: InputMaybe<Array<CouponUsageWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CouponUsageCreateOrConnectWithoutCouponInput>>;
  create?: InputMaybe<Array<CouponUsageCreateWithoutCouponInput>>;
  createMany?: InputMaybe<CouponUsageCreateManyCouponInputEnvelope>;
  delete?: InputMaybe<Array<CouponUsageWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<CouponUsageScalarWhereInput>>;
  disconnect?: InputMaybe<Array<CouponUsageWhereUniqueInput>>;
  set?: InputMaybe<Array<CouponUsageWhereUniqueInput>>;
  update?: InputMaybe<Array<CouponUsageUpdateWithWhereUniqueWithoutCouponInput>>;
  updateMany?: InputMaybe<Array<CouponUsageUpdateManyWithWhereWithoutCouponInput>>;
  upsert?: InputMaybe<Array<CouponUsageUpsertWithWhereUniqueWithoutCouponInput>>;
};

export type CouponUsageUpdateManyWithoutUserNestedInput = {
  connect?: InputMaybe<Array<CouponUsageWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CouponUsageCreateOrConnectWithoutUserInput>>;
  create?: InputMaybe<Array<CouponUsageCreateWithoutUserInput>>;
  createMany?: InputMaybe<CouponUsageCreateManyUserInputEnvelope>;
  delete?: InputMaybe<Array<CouponUsageWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<CouponUsageScalarWhereInput>>;
  disconnect?: InputMaybe<Array<CouponUsageWhereUniqueInput>>;
  set?: InputMaybe<Array<CouponUsageWhereUniqueInput>>;
  update?: InputMaybe<Array<CouponUsageUpdateWithWhereUniqueWithoutUserInput>>;
  updateMany?: InputMaybe<Array<CouponUsageUpdateManyWithWhereWithoutUserInput>>;
  upsert?: InputMaybe<Array<CouponUsageUpsertWithWhereUniqueWithoutUserInput>>;
};

export type CouponUsageUpdateWithWhereUniqueWithoutCouponInput = {
  data: CouponUsageUpdateWithoutCouponInput;
  where: CouponUsageWhereUniqueInput;
};

export type CouponUsageUpdateWithWhereUniqueWithoutUserInput = {
  data: CouponUsageUpdateWithoutUserInput;
  where: CouponUsageWhereUniqueInput;
};

export type CouponUsageUpdateWithoutCouponInput = {
  action?: InputMaybe<EnumCouponUsageActionFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutCouponUsagesNestedInput>;
};

export type CouponUsageUpdateWithoutUserInput = {
  action?: InputMaybe<EnumCouponUsageActionFieldUpdateOperationsInput>;
  coupon?: InputMaybe<CouponEntityUpdateOneRequiredWithoutUsageHistoryNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type CouponUsageUpsertWithWhereUniqueWithoutCouponInput = {
  create: CouponUsageCreateWithoutCouponInput;
  update: CouponUsageUpdateWithoutCouponInput;
  where: CouponUsageWhereUniqueInput;
};

export type CouponUsageUpsertWithWhereUniqueWithoutUserInput = {
  create: CouponUsageCreateWithoutUserInput;
  update: CouponUsageUpdateWithoutUserInput;
  where: CouponUsageWhereUniqueInput;
};

export type CouponUsageWhereInput = {
  AND?: InputMaybe<Array<CouponUsageWhereInput>>;
  NOT?: InputMaybe<Array<CouponUsageWhereInput>>;
  OR?: InputMaybe<Array<CouponUsageWhereInput>>;
  action?: InputMaybe<EnumCouponUsageActionFilter>;
  coupon?: InputMaybe<CouponEntityRelationFilter>;
  couponId?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  user?: InputMaybe<UserRelationFilter>;
  userId?: InputMaybe<StringFilter>;
};

export type CouponUsageWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
};

export type DateTimeFieldUpdateOperationsInput = {
  set?: InputMaybe<Scalars['DateTime']>;
};

export type DateTimeFilter = {
  equals?: InputMaybe<Scalars['DateTime']>;
  gt?: InputMaybe<Scalars['DateTime']>;
  gte?: InputMaybe<Scalars['DateTime']>;
  in?: InputMaybe<Array<Scalars['DateTime']>>;
  lt?: InputMaybe<Scalars['DateTime']>;
  lte?: InputMaybe<Scalars['DateTime']>;
  not?: InputMaybe<NestedDateTimeFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type DateTimeNullableFilter = {
  equals?: InputMaybe<Scalars['DateTime']>;
  gt?: InputMaybe<Scalars['DateTime']>;
  gte?: InputMaybe<Scalars['DateTime']>;
  in?: InputMaybe<Array<Scalars['DateTime']>>;
  lt?: InputMaybe<Scalars['DateTime']>;
  lte?: InputMaybe<Scalars['DateTime']>;
  not?: InputMaybe<NestedDateTimeNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type DateTimeNullableWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntNullableFilter>;
  _max?: InputMaybe<NestedDateTimeNullableFilter>;
  _min?: InputMaybe<NestedDateTimeNullableFilter>;
  equals?: InputMaybe<Scalars['DateTime']>;
  gt?: InputMaybe<Scalars['DateTime']>;
  gte?: InputMaybe<Scalars['DateTime']>;
  in?: InputMaybe<Array<Scalars['DateTime']>>;
  lt?: InputMaybe<Scalars['DateTime']>;
  lte?: InputMaybe<Scalars['DateTime']>;
  not?: InputMaybe<NestedDateTimeNullableWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type DateTimeWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedDateTimeFilter>;
  _min?: InputMaybe<NestedDateTimeFilter>;
  equals?: InputMaybe<Scalars['DateTime']>;
  gt?: InputMaybe<Scalars['DateTime']>;
  gte?: InputMaybe<Scalars['DateTime']>;
  in?: InputMaybe<Array<Scalars['DateTime']>>;
  lt?: InputMaybe<Scalars['DateTime']>;
  lte?: InputMaybe<Scalars['DateTime']>;
  not?: InputMaybe<NestedDateTimeWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type DecimalFieldUpdateOperationsInput = {
  decrement?: InputMaybe<Scalars['Decimal']>;
  divide?: InputMaybe<Scalars['Decimal']>;
  increment?: InputMaybe<Scalars['Decimal']>;
  multiply?: InputMaybe<Scalars['Decimal']>;
  set?: InputMaybe<Scalars['Decimal']>;
};

export type DecimalFilter = {
  equals?: InputMaybe<Scalars['Decimal']>;
  gt?: InputMaybe<Scalars['Decimal']>;
  gte?: InputMaybe<Scalars['Decimal']>;
  in?: InputMaybe<Array<Scalars['Decimal']>>;
  lt?: InputMaybe<Scalars['Decimal']>;
  lte?: InputMaybe<Scalars['Decimal']>;
  not?: InputMaybe<NestedDecimalFilter>;
  notIn?: InputMaybe<Array<Scalars['Decimal']>>;
};

export type DecimalNullableFilter = {
  equals?: InputMaybe<Scalars['Decimal']>;
  gt?: InputMaybe<Scalars['Decimal']>;
  gte?: InputMaybe<Scalars['Decimal']>;
  in?: InputMaybe<Array<Scalars['Decimal']>>;
  lt?: InputMaybe<Scalars['Decimal']>;
  lte?: InputMaybe<Scalars['Decimal']>;
  not?: InputMaybe<NestedDecimalNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['Decimal']>>;
};

export type DecimalNullableWithAggregatesFilter = {
  _avg?: InputMaybe<NestedDecimalNullableFilter>;
  _count?: InputMaybe<NestedIntNullableFilter>;
  _max?: InputMaybe<NestedDecimalNullableFilter>;
  _min?: InputMaybe<NestedDecimalNullableFilter>;
  _sum?: InputMaybe<NestedDecimalNullableFilter>;
  equals?: InputMaybe<Scalars['Decimal']>;
  gt?: InputMaybe<Scalars['Decimal']>;
  gte?: InputMaybe<Scalars['Decimal']>;
  in?: InputMaybe<Array<Scalars['Decimal']>>;
  lt?: InputMaybe<Scalars['Decimal']>;
  lte?: InputMaybe<Scalars['Decimal']>;
  not?: InputMaybe<NestedDecimalNullableWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['Decimal']>>;
};

export type DecimalWithAggregatesFilter = {
  _avg?: InputMaybe<NestedDecimalFilter>;
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedDecimalFilter>;
  _min?: InputMaybe<NestedDecimalFilter>;
  _sum?: InputMaybe<NestedDecimalFilter>;
  equals?: InputMaybe<Scalars['Decimal']>;
  gt?: InputMaybe<Scalars['Decimal']>;
  gte?: InputMaybe<Scalars['Decimal']>;
  in?: InputMaybe<Array<Scalars['Decimal']>>;
  lt?: InputMaybe<Scalars['Decimal']>;
  lte?: InputMaybe<Scalars['Decimal']>;
  not?: InputMaybe<NestedDecimalWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['Decimal']>>;
};

export type DeviceToken = {
  __typename?: 'DeviceToken';
  createdAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['String'];
  token: Scalars['String'];
  type: DeviceTokenType;
  updatedAt?: Maybe<Scalars['DateTime']>;
  user: User;
  userId: Scalars['String'];
};

export type DeviceTokenCountAggregate = {
  __typename?: 'DeviceTokenCountAggregate';
  _all: Scalars['Int'];
  createdAt: Scalars['Int'];
  id: Scalars['Int'];
  token: Scalars['Int'];
  type: Scalars['Int'];
  updatedAt: Scalars['Int'];
  userId: Scalars['Int'];
};

export type DeviceTokenCountOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  token?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
};

export type DeviceTokenCreateInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  token: Scalars['String'];
  type: DeviceTokenType;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  user: UserCreateNestedOneWithoutDeviceTokenInput;
};

export type DeviceTokenCreateManyInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  token: Scalars['String'];
  type: DeviceTokenType;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userId: Scalars['String'];
};

export type DeviceTokenCreateManyUserInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  token: Scalars['String'];
  type: DeviceTokenType;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type DeviceTokenCreateManyUserInputEnvelope = {
  data: Array<DeviceTokenCreateManyUserInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type DeviceTokenCreateNestedManyWithoutUserInput = {
  connect?: InputMaybe<Array<DeviceTokenWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<DeviceTokenCreateOrConnectWithoutUserInput>>;
  create?: InputMaybe<Array<DeviceTokenCreateWithoutUserInput>>;
  createMany?: InputMaybe<DeviceTokenCreateManyUserInputEnvelope>;
};

export type DeviceTokenCreateOrConnectWithoutUserInput = {
  create: DeviceTokenCreateWithoutUserInput;
  where: DeviceTokenWhereUniqueInput;
};

export type DeviceTokenCreateWithoutUserInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  token: Scalars['String'];
  type: DeviceTokenType;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type DeviceTokenGroupBy = {
  __typename?: 'DeviceTokenGroupBy';
  _count?: Maybe<DeviceTokenCountAggregate>;
  _max?: Maybe<DeviceTokenMaxAggregate>;
  _min?: Maybe<DeviceTokenMinAggregate>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['String'];
  token: Scalars['String'];
  type: DeviceTokenType;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId: Scalars['String'];
};

export type DeviceTokenListRelationFilter = {
  every?: InputMaybe<DeviceTokenWhereInput>;
  none?: InputMaybe<DeviceTokenWhereInput>;
  some?: InputMaybe<DeviceTokenWhereInput>;
};

export type DeviceTokenMaxAggregate = {
  __typename?: 'DeviceTokenMaxAggregate';
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  type?: Maybe<DeviceTokenType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
};

export type DeviceTokenMaxOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  token?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
};

export type DeviceTokenMinAggregate = {
  __typename?: 'DeviceTokenMinAggregate';
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  type?: Maybe<DeviceTokenType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
};

export type DeviceTokenMinOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  token?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
};

export type DeviceTokenOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type DeviceTokenOrderByWithAggregationInput = {
  _count?: InputMaybe<DeviceTokenCountOrderByAggregateInput>;
  _max?: InputMaybe<DeviceTokenMaxOrderByAggregateInput>;
  _min?: InputMaybe<DeviceTokenMinOrderByAggregateInput>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  token?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
};

export type DeviceTokenOrderByWithRelationInput = {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  token?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  user?: InputMaybe<UserOrderByWithRelationInput>;
  userId?: InputMaybe<SortOrder>;
};

export enum DeviceTokenScalarFieldEnum {
  CreatedAt = 'createdAt',
  Id = 'id',
  Token = 'token',
  Type = 'type',
  UpdatedAt = 'updatedAt',
  UserId = 'userId'
}

export type DeviceTokenScalarWhereInput = {
  AND?: InputMaybe<Array<DeviceTokenScalarWhereInput>>;
  NOT?: InputMaybe<Array<DeviceTokenScalarWhereInput>>;
  OR?: InputMaybe<Array<DeviceTokenScalarWhereInput>>;
  createdAt?: InputMaybe<DateTimeNullableFilter>;
  id?: InputMaybe<StringFilter>;
  token?: InputMaybe<StringFilter>;
  type?: InputMaybe<EnumDeviceTokenTypeFilter>;
  updatedAt?: InputMaybe<DateTimeNullableFilter>;
  userId?: InputMaybe<StringFilter>;
};

export type DeviceTokenScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<DeviceTokenScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<DeviceTokenScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<DeviceTokenScalarWhereWithAggregatesInput>>;
  createdAt?: InputMaybe<DateTimeNullableWithAggregatesFilter>;
  id?: InputMaybe<StringWithAggregatesFilter>;
  token?: InputMaybe<StringWithAggregatesFilter>;
  type?: InputMaybe<EnumDeviceTokenTypeWithAggregatesFilter>;
  updatedAt?: InputMaybe<DateTimeNullableWithAggregatesFilter>;
  userId?: InputMaybe<StringWithAggregatesFilter>;
};

export enum DeviceTokenType {
  Android = 'ANDROID',
  Ios = 'IOS'
}

export type DeviceTokenUpdateInput = {
  createdAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  token?: InputMaybe<StringFieldUpdateOperationsInput>;
  type?: InputMaybe<EnumDeviceTokenTypeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutDeviceTokenNestedInput>;
};

export type DeviceTokenUpdateManyMutationInput = {
  createdAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  token?: InputMaybe<StringFieldUpdateOperationsInput>;
  type?: InputMaybe<EnumDeviceTokenTypeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
};

export type DeviceTokenUpdateManyWithWhereWithoutUserInput = {
  data: DeviceTokenUpdateManyMutationInput;
  where: DeviceTokenScalarWhereInput;
};

export type DeviceTokenUpdateManyWithoutUserNestedInput = {
  connect?: InputMaybe<Array<DeviceTokenWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<DeviceTokenCreateOrConnectWithoutUserInput>>;
  create?: InputMaybe<Array<DeviceTokenCreateWithoutUserInput>>;
  createMany?: InputMaybe<DeviceTokenCreateManyUserInputEnvelope>;
  delete?: InputMaybe<Array<DeviceTokenWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<DeviceTokenScalarWhereInput>>;
  disconnect?: InputMaybe<Array<DeviceTokenWhereUniqueInput>>;
  set?: InputMaybe<Array<DeviceTokenWhereUniqueInput>>;
  update?: InputMaybe<Array<DeviceTokenUpdateWithWhereUniqueWithoutUserInput>>;
  updateMany?: InputMaybe<Array<DeviceTokenUpdateManyWithWhereWithoutUserInput>>;
  upsert?: InputMaybe<Array<DeviceTokenUpsertWithWhereUniqueWithoutUserInput>>;
};

export type DeviceTokenUpdateWithWhereUniqueWithoutUserInput = {
  data: DeviceTokenUpdateWithoutUserInput;
  where: DeviceTokenWhereUniqueInput;
};

export type DeviceTokenUpdateWithoutUserInput = {
  createdAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  token?: InputMaybe<StringFieldUpdateOperationsInput>;
  type?: InputMaybe<EnumDeviceTokenTypeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
};

export type DeviceTokenUpsertWithWhereUniqueWithoutUserInput = {
  create: DeviceTokenCreateWithoutUserInput;
  update: DeviceTokenUpdateWithoutUserInput;
  where: DeviceTokenWhereUniqueInput;
};

export type DeviceTokenWhereInput = {
  AND?: InputMaybe<Array<DeviceTokenWhereInput>>;
  NOT?: InputMaybe<Array<DeviceTokenWhereInput>>;
  OR?: InputMaybe<Array<DeviceTokenWhereInput>>;
  createdAt?: InputMaybe<DateTimeNullableFilter>;
  id?: InputMaybe<StringFilter>;
  token?: InputMaybe<StringFilter>;
  type?: InputMaybe<EnumDeviceTokenTypeFilter>;
  updatedAt?: InputMaybe<DateTimeNullableFilter>;
  user?: InputMaybe<UserRelationFilter>;
  userId?: InputMaybe<StringFilter>;
};

export type DeviceTokenWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
  token?: InputMaybe<Scalars['String']>;
};

export type Ecloudlife = {
  __typename?: 'Ecloudlife';
  _count?: Maybe<EcloudlifeCount>;
  buyerIdentifier?: Maybe<Scalars['String']>;
  buyerTitle?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  donateCode?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  invoice: Array<Invoice>;
  invoiceType: InvoiceType;
  nationalIdCarrier?: Maybe<Scalars['String']>;
  phoneCarrier?: Maybe<Scalars['String']>;
  response_process_id?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};


export type EcloudlifeInvoiceArgs = {
  cursor?: InputMaybe<InvoiceWhereUniqueInput>;
  distinct?: InputMaybe<Array<InvoiceScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<InvoiceOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<InvoiceWhereInput>;
};

export type EcloudlifeCount = {
  __typename?: 'EcloudlifeCount';
  invoice: Scalars['Int'];
};


export type EcloudlifeCountInvoiceArgs = {
  where?: InputMaybe<InvoiceWhereInput>;
};

export type EcloudlifeCountAggregate = {
  __typename?: 'EcloudlifeCountAggregate';
  _all: Scalars['Int'];
  buyerIdentifier: Scalars['Int'];
  buyerTitle: Scalars['Int'];
  createdAt: Scalars['Int'];
  donateCode: Scalars['Int'];
  email: Scalars['Int'];
  id: Scalars['Int'];
  invoiceType: Scalars['Int'];
  nationalIdCarrier: Scalars['Int'];
  phoneCarrier: Scalars['Int'];
  response_process_id: Scalars['Int'];
  updatedAt: Scalars['Int'];
};

export type EcloudlifeCountOrderByAggregateInput = {
  buyerIdentifier?: InputMaybe<SortOrder>;
  buyerTitle?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  donateCode?: InputMaybe<SortOrder>;
  email?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  invoiceType?: InputMaybe<SortOrder>;
  nationalIdCarrier?: InputMaybe<SortOrder>;
  phoneCarrier?: InputMaybe<SortOrder>;
  response_process_id?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type EcloudlifeCreateInput = {
  buyerIdentifier?: InputMaybe<Scalars['String']>;
  buyerTitle?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  donateCode?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  invoice?: InputMaybe<InvoiceCreateNestedManyWithoutEcloudlifeInput>;
  invoiceType: InvoiceType;
  nationalIdCarrier?: InputMaybe<Scalars['String']>;
  phoneCarrier?: InputMaybe<Scalars['String']>;
  response_process_id?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type EcloudlifeCreateManyInput = {
  buyerIdentifier?: InputMaybe<Scalars['String']>;
  buyerTitle?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  donateCode?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  invoiceType: InvoiceType;
  nationalIdCarrier?: InputMaybe<Scalars['String']>;
  phoneCarrier?: InputMaybe<Scalars['String']>;
  response_process_id?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type EcloudlifeCreateNestedOneWithoutInvoiceInput = {
  connect?: InputMaybe<EcloudlifeWhereUniqueInput>;
  connectOrCreate?: InputMaybe<EcloudlifeCreateOrConnectWithoutInvoiceInput>;
  create?: InputMaybe<EcloudlifeCreateWithoutInvoiceInput>;
};

export type EcloudlifeCreateOrConnectWithoutInvoiceInput = {
  create: EcloudlifeCreateWithoutInvoiceInput;
  where: EcloudlifeWhereUniqueInput;
};

export type EcloudlifeCreateWithoutInvoiceInput = {
  buyerIdentifier?: InputMaybe<Scalars['String']>;
  buyerTitle?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  donateCode?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  invoiceType: InvoiceType;
  nationalIdCarrier?: InputMaybe<Scalars['String']>;
  phoneCarrier?: InputMaybe<Scalars['String']>;
  response_process_id?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type EcloudlifeGroupBy = {
  __typename?: 'EcloudlifeGroupBy';
  _count?: Maybe<EcloudlifeCountAggregate>;
  _max?: Maybe<EcloudlifeMaxAggregate>;
  _min?: Maybe<EcloudlifeMinAggregate>;
  buyerIdentifier?: Maybe<Scalars['String']>;
  buyerTitle?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  donateCode?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  invoiceType: InvoiceType;
  nationalIdCarrier?: Maybe<Scalars['String']>;
  phoneCarrier?: Maybe<Scalars['String']>;
  response_process_id?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};

export type EcloudlifeMaxAggregate = {
  __typename?: 'EcloudlifeMaxAggregate';
  buyerIdentifier?: Maybe<Scalars['String']>;
  buyerTitle?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  donateCode?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  invoiceType?: Maybe<InvoiceType>;
  nationalIdCarrier?: Maybe<Scalars['String']>;
  phoneCarrier?: Maybe<Scalars['String']>;
  response_process_id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type EcloudlifeMaxOrderByAggregateInput = {
  buyerIdentifier?: InputMaybe<SortOrder>;
  buyerTitle?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  donateCode?: InputMaybe<SortOrder>;
  email?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  invoiceType?: InputMaybe<SortOrder>;
  nationalIdCarrier?: InputMaybe<SortOrder>;
  phoneCarrier?: InputMaybe<SortOrder>;
  response_process_id?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type EcloudlifeMinAggregate = {
  __typename?: 'EcloudlifeMinAggregate';
  buyerIdentifier?: Maybe<Scalars['String']>;
  buyerTitle?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  donateCode?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  invoiceType?: Maybe<InvoiceType>;
  nationalIdCarrier?: Maybe<Scalars['String']>;
  phoneCarrier?: Maybe<Scalars['String']>;
  response_process_id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type EcloudlifeMinOrderByAggregateInput = {
  buyerIdentifier?: InputMaybe<SortOrder>;
  buyerTitle?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  donateCode?: InputMaybe<SortOrder>;
  email?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  invoiceType?: InputMaybe<SortOrder>;
  nationalIdCarrier?: InputMaybe<SortOrder>;
  phoneCarrier?: InputMaybe<SortOrder>;
  response_process_id?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type EcloudlifeOrderByWithAggregationInput = {
  _count?: InputMaybe<EcloudlifeCountOrderByAggregateInput>;
  _max?: InputMaybe<EcloudlifeMaxOrderByAggregateInput>;
  _min?: InputMaybe<EcloudlifeMinOrderByAggregateInput>;
  buyerIdentifier?: InputMaybe<SortOrder>;
  buyerTitle?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  donateCode?: InputMaybe<SortOrder>;
  email?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  invoiceType?: InputMaybe<SortOrder>;
  nationalIdCarrier?: InputMaybe<SortOrder>;
  phoneCarrier?: InputMaybe<SortOrder>;
  response_process_id?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type EcloudlifeOrderByWithRelationInput = {
  buyerIdentifier?: InputMaybe<SortOrder>;
  buyerTitle?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  donateCode?: InputMaybe<SortOrder>;
  email?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  invoice?: InputMaybe<InvoiceOrderByRelationAggregateInput>;
  invoiceType?: InputMaybe<SortOrder>;
  nationalIdCarrier?: InputMaybe<SortOrder>;
  phoneCarrier?: InputMaybe<SortOrder>;
  response_process_id?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type EcloudlifeRelationFilter = {
  is?: InputMaybe<EcloudlifeWhereInput>;
  isNot?: InputMaybe<EcloudlifeWhereInput>;
};

export enum EcloudlifeScalarFieldEnum {
  BuyerIdentifier = 'buyerIdentifier',
  BuyerTitle = 'buyerTitle',
  CreatedAt = 'createdAt',
  DonateCode = 'donateCode',
  Email = 'email',
  Id = 'id',
  InvoiceType = 'invoiceType',
  NationalIdCarrier = 'nationalIdCarrier',
  PhoneCarrier = 'phoneCarrier',
  ResponseProcessId = 'response_process_id',
  UpdatedAt = 'updatedAt'
}

export type EcloudlifeScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<EcloudlifeScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<EcloudlifeScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<EcloudlifeScalarWhereWithAggregatesInput>>;
  buyerIdentifier?: InputMaybe<StringNullableWithAggregatesFilter>;
  buyerTitle?: InputMaybe<StringNullableWithAggregatesFilter>;
  createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  donateCode?: InputMaybe<StringNullableWithAggregatesFilter>;
  email?: InputMaybe<StringNullableWithAggregatesFilter>;
  id?: InputMaybe<StringWithAggregatesFilter>;
  invoiceType?: InputMaybe<EnumInvoiceTypeWithAggregatesFilter>;
  nationalIdCarrier?: InputMaybe<StringNullableWithAggregatesFilter>;
  phoneCarrier?: InputMaybe<StringNullableWithAggregatesFilter>;
  response_process_id?: InputMaybe<StringNullableWithAggregatesFilter>;
  updatedAt?: InputMaybe<DateTimeWithAggregatesFilter>;
};

export type EcloudlifeTrack = {
  __typename?: 'EcloudlifeTrack';
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  length: Scalars['Int'];
  peroid: Scalars['Int'];
  remain: Scalars['Int'];
  track: Scalars['String'];
  tracksEnd: Scalars['String'];
  tracksStart: Scalars['String'];
  tracksType: Scalars['String'];
  tracksTypeDesc: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  year: Scalars['String'];
};

export type EcloudlifeTrackAvgAggregate = {
  __typename?: 'EcloudlifeTrackAvgAggregate';
  length?: Maybe<Scalars['Float']>;
  peroid?: Maybe<Scalars['Float']>;
  remain?: Maybe<Scalars['Float']>;
};

export type EcloudlifeTrackAvgOrderByAggregateInput = {
  length?: InputMaybe<SortOrder>;
  peroid?: InputMaybe<SortOrder>;
  remain?: InputMaybe<SortOrder>;
};

export type EcloudlifeTrackCountAggregate = {
  __typename?: 'EcloudlifeTrackCountAggregate';
  _all: Scalars['Int'];
  createdAt: Scalars['Int'];
  id: Scalars['Int'];
  length: Scalars['Int'];
  peroid: Scalars['Int'];
  remain: Scalars['Int'];
  track: Scalars['Int'];
  tracksEnd: Scalars['Int'];
  tracksStart: Scalars['Int'];
  tracksType: Scalars['Int'];
  tracksTypeDesc: Scalars['Int'];
  updatedAt: Scalars['Int'];
  year: Scalars['Int'];
};

export type EcloudlifeTrackCountOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  length?: InputMaybe<SortOrder>;
  peroid?: InputMaybe<SortOrder>;
  remain?: InputMaybe<SortOrder>;
  track?: InputMaybe<SortOrder>;
  tracksEnd?: InputMaybe<SortOrder>;
  tracksStart?: InputMaybe<SortOrder>;
  tracksType?: InputMaybe<SortOrder>;
  tracksTypeDesc?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  year?: InputMaybe<SortOrder>;
};

export type EcloudlifeTrackCreateInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id: Scalars['String'];
  length: Scalars['Int'];
  peroid: Scalars['Int'];
  remain: Scalars['Int'];
  track: Scalars['String'];
  tracksEnd: Scalars['String'];
  tracksStart: Scalars['String'];
  tracksType: Scalars['String'];
  tracksTypeDesc: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  year: Scalars['String'];
};

export type EcloudlifeTrackCreateManyInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id: Scalars['String'];
  length: Scalars['Int'];
  peroid: Scalars['Int'];
  remain: Scalars['Int'];
  track: Scalars['String'];
  tracksEnd: Scalars['String'];
  tracksStart: Scalars['String'];
  tracksType: Scalars['String'];
  tracksTypeDesc: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  year: Scalars['String'];
};

export type EcloudlifeTrackGroupBy = {
  __typename?: 'EcloudlifeTrackGroupBy';
  _avg?: Maybe<EcloudlifeTrackAvgAggregate>;
  _count?: Maybe<EcloudlifeTrackCountAggregate>;
  _max?: Maybe<EcloudlifeTrackMaxAggregate>;
  _min?: Maybe<EcloudlifeTrackMinAggregate>;
  _sum?: Maybe<EcloudlifeTrackSumAggregate>;
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  length: Scalars['Int'];
  peroid: Scalars['Int'];
  remain: Scalars['Int'];
  track: Scalars['String'];
  tracksEnd: Scalars['String'];
  tracksStart: Scalars['String'];
  tracksType: Scalars['String'];
  tracksTypeDesc: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  year: Scalars['String'];
};

export type EcloudlifeTrackMaxAggregate = {
  __typename?: 'EcloudlifeTrackMaxAggregate';
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  length?: Maybe<Scalars['Int']>;
  peroid?: Maybe<Scalars['Int']>;
  remain?: Maybe<Scalars['Int']>;
  track?: Maybe<Scalars['String']>;
  tracksEnd?: Maybe<Scalars['String']>;
  tracksStart?: Maybe<Scalars['String']>;
  tracksType?: Maybe<Scalars['String']>;
  tracksTypeDesc?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  year?: Maybe<Scalars['String']>;
};

export type EcloudlifeTrackMaxOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  length?: InputMaybe<SortOrder>;
  peroid?: InputMaybe<SortOrder>;
  remain?: InputMaybe<SortOrder>;
  track?: InputMaybe<SortOrder>;
  tracksEnd?: InputMaybe<SortOrder>;
  tracksStart?: InputMaybe<SortOrder>;
  tracksType?: InputMaybe<SortOrder>;
  tracksTypeDesc?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  year?: InputMaybe<SortOrder>;
};

export type EcloudlifeTrackMinAggregate = {
  __typename?: 'EcloudlifeTrackMinAggregate';
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  length?: Maybe<Scalars['Int']>;
  peroid?: Maybe<Scalars['Int']>;
  remain?: Maybe<Scalars['Int']>;
  track?: Maybe<Scalars['String']>;
  tracksEnd?: Maybe<Scalars['String']>;
  tracksStart?: Maybe<Scalars['String']>;
  tracksType?: Maybe<Scalars['String']>;
  tracksTypeDesc?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  year?: Maybe<Scalars['String']>;
};

export type EcloudlifeTrackMinOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  length?: InputMaybe<SortOrder>;
  peroid?: InputMaybe<SortOrder>;
  remain?: InputMaybe<SortOrder>;
  track?: InputMaybe<SortOrder>;
  tracksEnd?: InputMaybe<SortOrder>;
  tracksStart?: InputMaybe<SortOrder>;
  tracksType?: InputMaybe<SortOrder>;
  tracksTypeDesc?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  year?: InputMaybe<SortOrder>;
};

export type EcloudlifeTrackOrderByWithAggregationInput = {
  _avg?: InputMaybe<EcloudlifeTrackAvgOrderByAggregateInput>;
  _count?: InputMaybe<EcloudlifeTrackCountOrderByAggregateInput>;
  _max?: InputMaybe<EcloudlifeTrackMaxOrderByAggregateInput>;
  _min?: InputMaybe<EcloudlifeTrackMinOrderByAggregateInput>;
  _sum?: InputMaybe<EcloudlifeTrackSumOrderByAggregateInput>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  length?: InputMaybe<SortOrder>;
  peroid?: InputMaybe<SortOrder>;
  remain?: InputMaybe<SortOrder>;
  track?: InputMaybe<SortOrder>;
  tracksEnd?: InputMaybe<SortOrder>;
  tracksStart?: InputMaybe<SortOrder>;
  tracksType?: InputMaybe<SortOrder>;
  tracksTypeDesc?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  year?: InputMaybe<SortOrder>;
};

export type EcloudlifeTrackOrderByWithRelationInput = {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  length?: InputMaybe<SortOrder>;
  peroid?: InputMaybe<SortOrder>;
  remain?: InputMaybe<SortOrder>;
  track?: InputMaybe<SortOrder>;
  tracksEnd?: InputMaybe<SortOrder>;
  tracksStart?: InputMaybe<SortOrder>;
  tracksType?: InputMaybe<SortOrder>;
  tracksTypeDesc?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  year?: InputMaybe<SortOrder>;
};

export enum EcloudlifeTrackScalarFieldEnum {
  CreatedAt = 'createdAt',
  Id = 'id',
  Length = 'length',
  Peroid = 'peroid',
  Remain = 'remain',
  Track = 'track',
  TracksEnd = 'tracksEnd',
  TracksStart = 'tracksStart',
  TracksType = 'tracksType',
  TracksTypeDesc = 'tracksTypeDesc',
  UpdatedAt = 'updatedAt',
  Year = 'year'
}

export type EcloudlifeTrackScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<EcloudlifeTrackScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<EcloudlifeTrackScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<EcloudlifeTrackScalarWhereWithAggregatesInput>>;
  createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  id?: InputMaybe<StringWithAggregatesFilter>;
  length?: InputMaybe<IntWithAggregatesFilter>;
  peroid?: InputMaybe<IntWithAggregatesFilter>;
  remain?: InputMaybe<IntWithAggregatesFilter>;
  track?: InputMaybe<StringWithAggregatesFilter>;
  tracksEnd?: InputMaybe<StringWithAggregatesFilter>;
  tracksStart?: InputMaybe<StringWithAggregatesFilter>;
  tracksType?: InputMaybe<StringWithAggregatesFilter>;
  tracksTypeDesc?: InputMaybe<StringWithAggregatesFilter>;
  updatedAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  year?: InputMaybe<StringWithAggregatesFilter>;
};

export type EcloudlifeTrackSumAggregate = {
  __typename?: 'EcloudlifeTrackSumAggregate';
  length?: Maybe<Scalars['Int']>;
  peroid?: Maybe<Scalars['Int']>;
  remain?: Maybe<Scalars['Int']>;
};

export type EcloudlifeTrackSumOrderByAggregateInput = {
  length?: InputMaybe<SortOrder>;
  peroid?: InputMaybe<SortOrder>;
  remain?: InputMaybe<SortOrder>;
};

export type EcloudlifeTrackUpdateInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  length?: InputMaybe<IntFieldUpdateOperationsInput>;
  peroid?: InputMaybe<IntFieldUpdateOperationsInput>;
  remain?: InputMaybe<IntFieldUpdateOperationsInput>;
  track?: InputMaybe<StringFieldUpdateOperationsInput>;
  tracksEnd?: InputMaybe<StringFieldUpdateOperationsInput>;
  tracksStart?: InputMaybe<StringFieldUpdateOperationsInput>;
  tracksType?: InputMaybe<StringFieldUpdateOperationsInput>;
  tracksTypeDesc?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  year?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type EcloudlifeTrackUpdateManyMutationInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  length?: InputMaybe<IntFieldUpdateOperationsInput>;
  peroid?: InputMaybe<IntFieldUpdateOperationsInput>;
  remain?: InputMaybe<IntFieldUpdateOperationsInput>;
  track?: InputMaybe<StringFieldUpdateOperationsInput>;
  tracksEnd?: InputMaybe<StringFieldUpdateOperationsInput>;
  tracksStart?: InputMaybe<StringFieldUpdateOperationsInput>;
  tracksType?: InputMaybe<StringFieldUpdateOperationsInput>;
  tracksTypeDesc?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  year?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type EcloudlifeTrackWhereInput = {
  AND?: InputMaybe<Array<EcloudlifeTrackWhereInput>>;
  NOT?: InputMaybe<Array<EcloudlifeTrackWhereInput>>;
  OR?: InputMaybe<Array<EcloudlifeTrackWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  length?: InputMaybe<IntFilter>;
  peroid?: InputMaybe<IntFilter>;
  remain?: InputMaybe<IntFilter>;
  track?: InputMaybe<StringFilter>;
  tracksEnd?: InputMaybe<StringFilter>;
  tracksStart?: InputMaybe<StringFilter>;
  tracksType?: InputMaybe<StringFilter>;
  tracksTypeDesc?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  year?: InputMaybe<StringFilter>;
};

export type EcloudlifeTrackWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
};

export type EcloudlifeUpdateInput = {
  buyerIdentifier?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  buyerTitle?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  donateCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  invoice?: InputMaybe<InvoiceUpdateManyWithoutEcloudlifeNestedInput>;
  invoiceType?: InputMaybe<EnumInvoiceTypeFieldUpdateOperationsInput>;
  nationalIdCarrier?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  phoneCarrier?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  response_process_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type EcloudlifeUpdateManyMutationInput = {
  buyerIdentifier?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  buyerTitle?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  donateCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  invoiceType?: InputMaybe<EnumInvoiceTypeFieldUpdateOperationsInput>;
  nationalIdCarrier?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  phoneCarrier?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  response_process_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type EcloudlifeUpdateOneWithoutInvoiceNestedInput = {
  connect?: InputMaybe<EcloudlifeWhereUniqueInput>;
  connectOrCreate?: InputMaybe<EcloudlifeCreateOrConnectWithoutInvoiceInput>;
  create?: InputMaybe<EcloudlifeCreateWithoutInvoiceInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<EcloudlifeUpdateWithoutInvoiceInput>;
  upsert?: InputMaybe<EcloudlifeUpsertWithoutInvoiceInput>;
};

export type EcloudlifeUpdateWithoutInvoiceInput = {
  buyerIdentifier?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  buyerTitle?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  donateCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  invoiceType?: InputMaybe<EnumInvoiceTypeFieldUpdateOperationsInput>;
  nationalIdCarrier?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  phoneCarrier?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  response_process_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type EcloudlifeUpsertWithoutInvoiceInput = {
  create: EcloudlifeCreateWithoutInvoiceInput;
  update: EcloudlifeUpdateWithoutInvoiceInput;
};

export type EcloudlifeWhereInput = {
  AND?: InputMaybe<Array<EcloudlifeWhereInput>>;
  NOT?: InputMaybe<Array<EcloudlifeWhereInput>>;
  OR?: InputMaybe<Array<EcloudlifeWhereInput>>;
  buyerIdentifier?: InputMaybe<StringNullableFilter>;
  buyerTitle?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  donateCode?: InputMaybe<StringNullableFilter>;
  email?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<StringFilter>;
  invoice?: InputMaybe<InvoiceListRelationFilter>;
  invoiceType?: InputMaybe<EnumInvoiceTypeFilter>;
  nationalIdCarrier?: InputMaybe<StringNullableFilter>;
  phoneCarrier?: InputMaybe<StringNullableFilter>;
  response_process_id?: InputMaybe<StringNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type EcloudlifeWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
};

export type EmailTo = {
  __typename?: 'EmailTo';
  _count?: Maybe<EmailToCount>;
  address: Scalars['String'];
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  name: Scalars['String'];
  owner: User;
  ownerId: Scalars['String'];
  postPackages: Array<PostPackage>;
  updatedAt: Scalars['DateTime'];
};


export type EmailToPostPackagesArgs = {
  cursor?: InputMaybe<PostPackageWhereUniqueInput>;
  distinct?: InputMaybe<Array<PostPackageScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<PostPackageOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PostPackageWhereInput>;
};

export type EmailToCount = {
  __typename?: 'EmailToCount';
  postPackages: Scalars['Int'];
};


export type EmailToCountPostPackagesArgs = {
  where?: InputMaybe<PostPackageWhereInput>;
};

export type EmailToCountAggregate = {
  __typename?: 'EmailToCountAggregate';
  _all: Scalars['Int'];
  address: Scalars['Int'];
  createdAt: Scalars['Int'];
  id: Scalars['Int'];
  name: Scalars['Int'];
  ownerId: Scalars['Int'];
  updatedAt: Scalars['Int'];
};

export type EmailToCountOrderByAggregateInput = {
  address?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  ownerId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type EmailToCreateInput = {
  address: Scalars['String'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  owner: UserCreateNestedOneWithoutEmailTosInput;
  postPackages?: InputMaybe<PostPackageCreateNestedManyWithoutEmailTosInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type EmailToCreateManyInput = {
  address: Scalars['String'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  ownerId: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type EmailToCreateManyOwnerInput = {
  address: Scalars['String'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type EmailToCreateManyOwnerInputEnvelope = {
  data: Array<EmailToCreateManyOwnerInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type EmailToCreateNestedManyWithoutOwnerInput = {
  connect?: InputMaybe<Array<EmailToWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<EmailToCreateOrConnectWithoutOwnerInput>>;
  create?: InputMaybe<Array<EmailToCreateWithoutOwnerInput>>;
  createMany?: InputMaybe<EmailToCreateManyOwnerInputEnvelope>;
};

export type EmailToCreateNestedManyWithoutPostPackagesInput = {
  connect?: InputMaybe<Array<EmailToWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<EmailToCreateOrConnectWithoutPostPackagesInput>>;
  create?: InputMaybe<Array<EmailToCreateWithoutPostPackagesInput>>;
};

export type EmailToCreateOrConnectWithoutOwnerInput = {
  create: EmailToCreateWithoutOwnerInput;
  where: EmailToWhereUniqueInput;
};

export type EmailToCreateOrConnectWithoutPostPackagesInput = {
  create: EmailToCreateWithoutPostPackagesInput;
  where: EmailToWhereUniqueInput;
};

export type EmailToCreateWithoutOwnerInput = {
  address: Scalars['String'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  postPackages?: InputMaybe<PostPackageCreateNestedManyWithoutEmailTosInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type EmailToCreateWithoutPostPackagesInput = {
  address: Scalars['String'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  owner: UserCreateNestedOneWithoutEmailTosInput;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type EmailToGroupBy = {
  __typename?: 'EmailToGroupBy';
  _count?: Maybe<EmailToCountAggregate>;
  _max?: Maybe<EmailToMaxAggregate>;
  _min?: Maybe<EmailToMinAggregate>;
  address: Scalars['String'];
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  name: Scalars['String'];
  ownerId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type EmailToListRelationFilter = {
  every?: InputMaybe<EmailToWhereInput>;
  none?: InputMaybe<EmailToWhereInput>;
  some?: InputMaybe<EmailToWhereInput>;
};

export type EmailToMaxAggregate = {
  __typename?: 'EmailToMaxAggregate';
  address?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  ownerId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type EmailToMaxOrderByAggregateInput = {
  address?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  ownerId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type EmailToMinAggregate = {
  __typename?: 'EmailToMinAggregate';
  address?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  ownerId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type EmailToMinOrderByAggregateInput = {
  address?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  ownerId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type EmailToOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type EmailToOrderByWithAggregationInput = {
  _count?: InputMaybe<EmailToCountOrderByAggregateInput>;
  _max?: InputMaybe<EmailToMaxOrderByAggregateInput>;
  _min?: InputMaybe<EmailToMinOrderByAggregateInput>;
  address?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  ownerId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type EmailToOrderByWithRelationInput = {
  address?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  owner?: InputMaybe<UserOrderByWithRelationInput>;
  ownerId?: InputMaybe<SortOrder>;
  postPackages?: InputMaybe<PostPackageOrderByRelationAggregateInput>;
  updatedAt?: InputMaybe<SortOrder>;
};

export enum EmailToScalarFieldEnum {
  Address = 'address',
  CreatedAt = 'createdAt',
  Id = 'id',
  Name = 'name',
  OwnerId = 'ownerId',
  UpdatedAt = 'updatedAt'
}

export type EmailToScalarWhereInput = {
  AND?: InputMaybe<Array<EmailToScalarWhereInput>>;
  NOT?: InputMaybe<Array<EmailToScalarWhereInput>>;
  OR?: InputMaybe<Array<EmailToScalarWhereInput>>;
  address?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringFilter>;
  ownerId?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type EmailToScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<EmailToScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<EmailToScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<EmailToScalarWhereWithAggregatesInput>>;
  address?: InputMaybe<StringWithAggregatesFilter>;
  createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  id?: InputMaybe<StringWithAggregatesFilter>;
  name?: InputMaybe<StringWithAggregatesFilter>;
  ownerId?: InputMaybe<StringWithAggregatesFilter>;
  updatedAt?: InputMaybe<DateTimeWithAggregatesFilter>;
};

export type EmailToUpdateInput = {
  address?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  owner?: InputMaybe<UserUpdateOneRequiredWithoutEmailTosNestedInput>;
  postPackages?: InputMaybe<PostPackageUpdateManyWithoutEmailTosNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type EmailToUpdateManyMutationInput = {
  address?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type EmailToUpdateManyWithWhereWithoutOwnerInput = {
  data: EmailToUpdateManyMutationInput;
  where: EmailToScalarWhereInput;
};

export type EmailToUpdateManyWithWhereWithoutPostPackagesInput = {
  data: EmailToUpdateManyMutationInput;
  where: EmailToScalarWhereInput;
};

export type EmailToUpdateManyWithoutOwnerNestedInput = {
  connect?: InputMaybe<Array<EmailToWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<EmailToCreateOrConnectWithoutOwnerInput>>;
  create?: InputMaybe<Array<EmailToCreateWithoutOwnerInput>>;
  createMany?: InputMaybe<EmailToCreateManyOwnerInputEnvelope>;
  delete?: InputMaybe<Array<EmailToWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<EmailToScalarWhereInput>>;
  disconnect?: InputMaybe<Array<EmailToWhereUniqueInput>>;
  set?: InputMaybe<Array<EmailToWhereUniqueInput>>;
  update?: InputMaybe<Array<EmailToUpdateWithWhereUniqueWithoutOwnerInput>>;
  updateMany?: InputMaybe<Array<EmailToUpdateManyWithWhereWithoutOwnerInput>>;
  upsert?: InputMaybe<Array<EmailToUpsertWithWhereUniqueWithoutOwnerInput>>;
};

export type EmailToUpdateManyWithoutPostPackagesNestedInput = {
  connect?: InputMaybe<Array<EmailToWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<EmailToCreateOrConnectWithoutPostPackagesInput>>;
  create?: InputMaybe<Array<EmailToCreateWithoutPostPackagesInput>>;
  delete?: InputMaybe<Array<EmailToWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<EmailToScalarWhereInput>>;
  disconnect?: InputMaybe<Array<EmailToWhereUniqueInput>>;
  set?: InputMaybe<Array<EmailToWhereUniqueInput>>;
  update?: InputMaybe<Array<EmailToUpdateWithWhereUniqueWithoutPostPackagesInput>>;
  updateMany?: InputMaybe<Array<EmailToUpdateManyWithWhereWithoutPostPackagesInput>>;
  upsert?: InputMaybe<Array<EmailToUpsertWithWhereUniqueWithoutPostPackagesInput>>;
};

export type EmailToUpdateWithWhereUniqueWithoutOwnerInput = {
  data: EmailToUpdateWithoutOwnerInput;
  where: EmailToWhereUniqueInput;
};

export type EmailToUpdateWithWhereUniqueWithoutPostPackagesInput = {
  data: EmailToUpdateWithoutPostPackagesInput;
  where: EmailToWhereUniqueInput;
};

export type EmailToUpdateWithoutOwnerInput = {
  address?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  postPackages?: InputMaybe<PostPackageUpdateManyWithoutEmailTosNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type EmailToUpdateWithoutPostPackagesInput = {
  address?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  owner?: InputMaybe<UserUpdateOneRequiredWithoutEmailTosNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type EmailToUpsertWithWhereUniqueWithoutOwnerInput = {
  create: EmailToCreateWithoutOwnerInput;
  update: EmailToUpdateWithoutOwnerInput;
  where: EmailToWhereUniqueInput;
};

export type EmailToUpsertWithWhereUniqueWithoutPostPackagesInput = {
  create: EmailToCreateWithoutPostPackagesInput;
  update: EmailToUpdateWithoutPostPackagesInput;
  where: EmailToWhereUniqueInput;
};

export type EmailToWhereInput = {
  AND?: InputMaybe<Array<EmailToWhereInput>>;
  NOT?: InputMaybe<Array<EmailToWhereInput>>;
  OR?: InputMaybe<Array<EmailToWhereInput>>;
  address?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringFilter>;
  owner?: InputMaybe<UserRelationFilter>;
  ownerId?: InputMaybe<StringFilter>;
  postPackages?: InputMaybe<PostPackageListRelationFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type EmailToWhereUniqueInput = {
  address?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
};

export type EnumAdsActionFieldUpdateOperationsInput = {
  set?: InputMaybe<AdsAction>;
};

export type EnumAdsActionFilter = {
  equals?: InputMaybe<AdsAction>;
  in?: InputMaybe<Array<AdsAction>>;
  not?: InputMaybe<NestedEnumAdsActionFilter>;
  notIn?: InputMaybe<Array<AdsAction>>;
};

export type EnumAdsActionWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumAdsActionFilter>;
  _min?: InputMaybe<NestedEnumAdsActionFilter>;
  equals?: InputMaybe<AdsAction>;
  in?: InputMaybe<Array<AdsAction>>;
  not?: InputMaybe<NestedEnumAdsActionWithAggregatesFilter>;
  notIn?: InputMaybe<Array<AdsAction>>;
};

export type EnumBenefitTypeFieldUpdateOperationsInput = {
  set?: InputMaybe<BenefitType>;
};

export type EnumBenefitTypeFilter = {
  equals?: InputMaybe<BenefitType>;
  in?: InputMaybe<Array<BenefitType>>;
  not?: InputMaybe<NestedEnumBenefitTypeFilter>;
  notIn?: InputMaybe<Array<BenefitType>>;
};

export type EnumBenefitTypeWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumBenefitTypeFilter>;
  _min?: InputMaybe<NestedEnumBenefitTypeFilter>;
  equals?: InputMaybe<BenefitType>;
  in?: InputMaybe<Array<BenefitType>>;
  not?: InputMaybe<NestedEnumBenefitTypeWithAggregatesFilter>;
  notIn?: InputMaybe<Array<BenefitType>>;
};

export type EnumCardOrientationFieldUpdateOperationsInput = {
  set?: InputMaybe<CardOrientation>;
};

export type EnumCardOrientationFilter = {
  equals?: InputMaybe<CardOrientation>;
  in?: InputMaybe<Array<CardOrientation>>;
  not?: InputMaybe<NestedEnumCardOrientationFilter>;
  notIn?: InputMaybe<Array<CardOrientation>>;
};

export type EnumCardOrientationWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumCardOrientationFilter>;
  _min?: InputMaybe<NestedEnumCardOrientationFilter>;
  equals?: InputMaybe<CardOrientation>;
  in?: InputMaybe<Array<CardOrientation>>;
  not?: InputMaybe<NestedEnumCardOrientationWithAggregatesFilter>;
  notIn?: InputMaybe<Array<CardOrientation>>;
};

export type EnumCoinTypeFieldUpdateOperationsInput = {
  set?: InputMaybe<CoinType>;
};

export type EnumCoinTypeFilter = {
  equals?: InputMaybe<CoinType>;
  in?: InputMaybe<Array<CoinType>>;
  not?: InputMaybe<NestedEnumCoinTypeFilter>;
  notIn?: InputMaybe<Array<CoinType>>;
};

export type EnumCoinTypeWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumCoinTypeFilter>;
  _min?: InputMaybe<NestedEnumCoinTypeFilter>;
  equals?: InputMaybe<CoinType>;
  in?: InputMaybe<Array<CoinType>>;
  not?: InputMaybe<NestedEnumCoinTypeWithAggregatesFilter>;
  notIn?: InputMaybe<Array<CoinType>>;
};

export type EnumCollectionTypeFieldUpdateOperationsInput = {
  set?: InputMaybe<CollectionType>;
};

export type EnumCollectionTypeFilter = {
  equals?: InputMaybe<CollectionType>;
  in?: InputMaybe<Array<CollectionType>>;
  not?: InputMaybe<NestedEnumCollectionTypeFilter>;
  notIn?: InputMaybe<Array<CollectionType>>;
};

export type EnumCollectionTypeWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumCollectionTypeFilter>;
  _min?: InputMaybe<NestedEnumCollectionTypeFilter>;
  equals?: InputMaybe<CollectionType>;
  in?: InputMaybe<Array<CollectionType>>;
  not?: InputMaybe<NestedEnumCollectionTypeWithAggregatesFilter>;
  notIn?: InputMaybe<Array<CollectionType>>;
};

export type EnumConsumeTypeFieldUpdateOperationsInput = {
  set?: InputMaybe<ConsumeType>;
};

export type EnumConsumeTypeFilter = {
  equals?: InputMaybe<ConsumeType>;
  in?: InputMaybe<Array<ConsumeType>>;
  not?: InputMaybe<NestedEnumConsumeTypeFilter>;
  notIn?: InputMaybe<Array<ConsumeType>>;
};

export type EnumConsumeTypeWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumConsumeTypeFilter>;
  _min?: InputMaybe<NestedEnumConsumeTypeFilter>;
  equals?: InputMaybe<ConsumeType>;
  in?: InputMaybe<Array<ConsumeType>>;
  not?: InputMaybe<NestedEnumConsumeTypeWithAggregatesFilter>;
  notIn?: InputMaybe<Array<ConsumeType>>;
};

export type EnumCouponActivityTypeFieldUpdateOperationsInput = {
  set?: InputMaybe<CouponActivityType>;
};

export type EnumCouponActivityTypeFilter = {
  equals?: InputMaybe<CouponActivityType>;
  in?: InputMaybe<Array<CouponActivityType>>;
  not?: InputMaybe<NestedEnumCouponActivityTypeFilter>;
  notIn?: InputMaybe<Array<CouponActivityType>>;
};

export type EnumCouponActivityTypeWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumCouponActivityTypeFilter>;
  _min?: InputMaybe<NestedEnumCouponActivityTypeFilter>;
  equals?: InputMaybe<CouponActivityType>;
  in?: InputMaybe<Array<CouponActivityType>>;
  not?: InputMaybe<NestedEnumCouponActivityTypeWithAggregatesFilter>;
  notIn?: InputMaybe<Array<CouponActivityType>>;
};

export type EnumCouponCheckoutActionFieldUpdateOperationsInput = {
  set?: InputMaybe<CouponCheckoutAction>;
};

export type EnumCouponCheckoutActionFilter = {
  equals?: InputMaybe<CouponCheckoutAction>;
  in?: InputMaybe<Array<CouponCheckoutAction>>;
  not?: InputMaybe<NestedEnumCouponCheckoutActionFilter>;
  notIn?: InputMaybe<Array<CouponCheckoutAction>>;
};

export type EnumCouponCheckoutActionWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumCouponCheckoutActionFilter>;
  _min?: InputMaybe<NestedEnumCouponCheckoutActionFilter>;
  equals?: InputMaybe<CouponCheckoutAction>;
  in?: InputMaybe<Array<CouponCheckoutAction>>;
  not?: InputMaybe<NestedEnumCouponCheckoutActionWithAggregatesFilter>;
  notIn?: InputMaybe<Array<CouponCheckoutAction>>;
};

export type EnumCouponTypeFieldUpdateOperationsInput = {
  set?: InputMaybe<CouponType>;
};

export type EnumCouponTypeFilter = {
  equals?: InputMaybe<CouponType>;
  in?: InputMaybe<Array<CouponType>>;
  not?: InputMaybe<NestedEnumCouponTypeFilter>;
  notIn?: InputMaybe<Array<CouponType>>;
};

export type EnumCouponTypeWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumCouponTypeFilter>;
  _min?: InputMaybe<NestedEnumCouponTypeFilter>;
  equals?: InputMaybe<CouponType>;
  in?: InputMaybe<Array<CouponType>>;
  not?: InputMaybe<NestedEnumCouponTypeWithAggregatesFilter>;
  notIn?: InputMaybe<Array<CouponType>>;
};

export type EnumCouponUsageActionFieldUpdateOperationsInput = {
  set?: InputMaybe<CouponUsageAction>;
};

export type EnumCouponUsageActionFilter = {
  equals?: InputMaybe<CouponUsageAction>;
  in?: InputMaybe<Array<CouponUsageAction>>;
  not?: InputMaybe<NestedEnumCouponUsageActionFilter>;
  notIn?: InputMaybe<Array<CouponUsageAction>>;
};

export type EnumCouponUsageActionWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumCouponUsageActionFilter>;
  _min?: InputMaybe<NestedEnumCouponUsageActionFilter>;
  equals?: InputMaybe<CouponUsageAction>;
  in?: InputMaybe<Array<CouponUsageAction>>;
  not?: InputMaybe<NestedEnumCouponUsageActionWithAggregatesFilter>;
  notIn?: InputMaybe<Array<CouponUsageAction>>;
};

export type EnumDeviceTokenTypeFieldUpdateOperationsInput = {
  set?: InputMaybe<DeviceTokenType>;
};

export type EnumDeviceTokenTypeFilter = {
  equals?: InputMaybe<DeviceTokenType>;
  in?: InputMaybe<Array<DeviceTokenType>>;
  not?: InputMaybe<NestedEnumDeviceTokenTypeFilter>;
  notIn?: InputMaybe<Array<DeviceTokenType>>;
};

export type EnumDeviceTokenTypeWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumDeviceTokenTypeFilter>;
  _min?: InputMaybe<NestedEnumDeviceTokenTypeFilter>;
  equals?: InputMaybe<DeviceTokenType>;
  in?: InputMaybe<Array<DeviceTokenType>>;
  not?: InputMaybe<NestedEnumDeviceTokenTypeWithAggregatesFilter>;
  notIn?: InputMaybe<Array<DeviceTokenType>>;
};

export type EnumFriendStatusFieldUpdateOperationsInput = {
  set?: InputMaybe<FriendStatus>;
};

export type EnumFriendStatusFilter = {
  equals?: InputMaybe<FriendStatus>;
  in?: InputMaybe<Array<FriendStatus>>;
  not?: InputMaybe<NestedEnumFriendStatusFilter>;
  notIn?: InputMaybe<Array<FriendStatus>>;
};

export type EnumFriendStatusWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumFriendStatusFilter>;
  _min?: InputMaybe<NestedEnumFriendStatusFilter>;
  equals?: InputMaybe<FriendStatus>;
  in?: InputMaybe<Array<FriendStatus>>;
  not?: InputMaybe<NestedEnumFriendStatusWithAggregatesFilter>;
  notIn?: InputMaybe<Array<FriendStatus>>;
};

export type EnumInValidTypeFieldUpdateOperationsInput = {
  set?: InputMaybe<InValidType>;
};

export type EnumInValidTypeFilter = {
  equals?: InputMaybe<InValidType>;
  in?: InputMaybe<Array<InValidType>>;
  not?: InputMaybe<NestedEnumInValidTypeFilter>;
  notIn?: InputMaybe<Array<InValidType>>;
};

export type EnumInValidTypeWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumInValidTypeFilter>;
  _min?: InputMaybe<NestedEnumInValidTypeFilter>;
  equals?: InputMaybe<InValidType>;
  in?: InputMaybe<Array<InValidType>>;
  not?: InputMaybe<NestedEnumInValidTypeWithAggregatesFilter>;
  notIn?: InputMaybe<Array<InValidType>>;
};

export type EnumInvoiceServiceProviderFieldUpdateOperationsInput = {
  set?: InputMaybe<InvoiceServiceProvider>;
};

export type EnumInvoiceServiceProviderFilter = {
  equals?: InputMaybe<InvoiceServiceProvider>;
  in?: InputMaybe<Array<InvoiceServiceProvider>>;
  not?: InputMaybe<NestedEnumInvoiceServiceProviderFilter>;
  notIn?: InputMaybe<Array<InvoiceServiceProvider>>;
};

export type EnumInvoiceServiceProviderWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumInvoiceServiceProviderFilter>;
  _min?: InputMaybe<NestedEnumInvoiceServiceProviderFilter>;
  equals?: InputMaybe<InvoiceServiceProvider>;
  in?: InputMaybe<Array<InvoiceServiceProvider>>;
  not?: InputMaybe<NestedEnumInvoiceServiceProviderWithAggregatesFilter>;
  notIn?: InputMaybe<Array<InvoiceServiceProvider>>;
};

export type EnumInvoiceTypeFieldUpdateOperationsInput = {
  set?: InputMaybe<InvoiceType>;
};

export type EnumInvoiceTypeFilter = {
  equals?: InputMaybe<InvoiceType>;
  in?: InputMaybe<Array<InvoiceType>>;
  not?: InputMaybe<NestedEnumInvoiceTypeFilter>;
  notIn?: InputMaybe<Array<InvoiceType>>;
};

export type EnumInvoiceTypeWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumInvoiceTypeFilter>;
  _min?: InputMaybe<NestedEnumInvoiceTypeFilter>;
  equals?: InputMaybe<InvoiceType>;
  in?: InputMaybe<Array<InvoiceType>>;
  not?: InputMaybe<NestedEnumInvoiceTypeWithAggregatesFilter>;
  notIn?: InputMaybe<Array<InvoiceType>>;
};

export type EnumLikeSettingNotificationFieldUpdateOperationsInput = {
  set?: InputMaybe<LikeSettingNotification>;
};

export type EnumLikeSettingNotificationFilter = {
  equals?: InputMaybe<LikeSettingNotification>;
  in?: InputMaybe<Array<LikeSettingNotification>>;
  not?: InputMaybe<NestedEnumLikeSettingNotificationFilter>;
  notIn?: InputMaybe<Array<LikeSettingNotification>>;
};

export type EnumLikeSettingNotificationWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumLikeSettingNotificationFilter>;
  _min?: InputMaybe<NestedEnumLikeSettingNotificationFilter>;
  equals?: InputMaybe<LikeSettingNotification>;
  in?: InputMaybe<Array<LikeSettingNotification>>;
  not?: InputMaybe<NestedEnumLikeSettingNotificationWithAggregatesFilter>;
  notIn?: InputMaybe<Array<LikeSettingNotification>>;
};

export type EnumNotificationStatusFieldUpdateOperationsInput = {
  set?: InputMaybe<NotificationStatus>;
};

export type EnumNotificationStatusFilter = {
  equals?: InputMaybe<NotificationStatus>;
  in?: InputMaybe<Array<NotificationStatus>>;
  not?: InputMaybe<NestedEnumNotificationStatusFilter>;
  notIn?: InputMaybe<Array<NotificationStatus>>;
};

export type EnumNotificationStatusWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumNotificationStatusFilter>;
  _min?: InputMaybe<NestedEnumNotificationStatusFilter>;
  equals?: InputMaybe<NotificationStatus>;
  in?: InputMaybe<Array<NotificationStatus>>;
  not?: InputMaybe<NestedEnumNotificationStatusWithAggregatesFilter>;
  notIn?: InputMaybe<Array<NotificationStatus>>;
};

export type EnumNotificationTypeFieldUpdateOperationsInput = {
  set?: InputMaybe<NotificationType>;
};

export type EnumNotificationTypeFilter = {
  equals?: InputMaybe<NotificationType>;
  in?: InputMaybe<Array<NotificationType>>;
  not?: InputMaybe<NestedEnumNotificationTypeFilter>;
  notIn?: InputMaybe<Array<NotificationType>>;
};

export type EnumNotificationTypeWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumNotificationTypeFilter>;
  _min?: InputMaybe<NestedEnumNotificationTypeFilter>;
  equals?: InputMaybe<NotificationType>;
  in?: InputMaybe<Array<NotificationType>>;
  not?: InputMaybe<NestedEnumNotificationTypeWithAggregatesFilter>;
  notIn?: InputMaybe<Array<NotificationType>>;
};

export type EnumOrderStatusFieldUpdateOperationsInput = {
  set?: InputMaybe<OrderStatus>;
};

export type EnumOrderStatusFilter = {
  equals?: InputMaybe<OrderStatus>;
  in?: InputMaybe<Array<OrderStatus>>;
  not?: InputMaybe<NestedEnumOrderStatusFilter>;
  notIn?: InputMaybe<Array<OrderStatus>>;
};

export type EnumOrderStatusWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumOrderStatusFilter>;
  _min?: InputMaybe<NestedEnumOrderStatusFilter>;
  equals?: InputMaybe<OrderStatus>;
  in?: InputMaybe<Array<OrderStatus>>;
  not?: InputMaybe<NestedEnumOrderStatusWithAggregatesFilter>;
  notIn?: InputMaybe<Array<OrderStatus>>;
};

export type EnumPackageStatusFieldUpdateOperationsInput = {
  set?: InputMaybe<PackageStatus>;
};

export type EnumPackageStatusFilter = {
  equals?: InputMaybe<PackageStatus>;
  in?: InputMaybe<Array<PackageStatus>>;
  not?: InputMaybe<NestedEnumPackageStatusFilter>;
  notIn?: InputMaybe<Array<PackageStatus>>;
};

export type EnumPackageStatusWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumPackageStatusFilter>;
  _min?: InputMaybe<NestedEnumPackageStatusFilter>;
  equals?: InputMaybe<PackageStatus>;
  in?: InputMaybe<Array<PackageStatus>>;
  not?: InputMaybe<NestedEnumPackageStatusWithAggregatesFilter>;
  notIn?: InputMaybe<Array<PackageStatus>>;
};

export type EnumPackageTypeFieldUpdateOperationsInput = {
  set?: InputMaybe<PackageType>;
};

export type EnumPackageTypeFilter = {
  equals?: InputMaybe<PackageType>;
  in?: InputMaybe<Array<PackageType>>;
  not?: InputMaybe<NestedEnumPackageTypeFilter>;
  notIn?: InputMaybe<Array<PackageType>>;
};

export type EnumPackageTypeWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumPackageTypeFilter>;
  _min?: InputMaybe<NestedEnumPackageTypeFilter>;
  equals?: InputMaybe<PackageType>;
  in?: InputMaybe<Array<PackageType>>;
  not?: InputMaybe<NestedEnumPackageTypeWithAggregatesFilter>;
  notIn?: InputMaybe<Array<PackageType>>;
};

export type EnumPaymentMethodNullableFilter = {
  equals?: InputMaybe<PaymentMethod>;
  in?: InputMaybe<Array<PaymentMethod>>;
  not?: InputMaybe<NestedEnumPaymentMethodNullableFilter>;
  notIn?: InputMaybe<Array<PaymentMethod>>;
};

export type EnumPaymentMethodNullableWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntNullableFilter>;
  _max?: InputMaybe<NestedEnumPaymentMethodNullableFilter>;
  _min?: InputMaybe<NestedEnumPaymentMethodNullableFilter>;
  equals?: InputMaybe<PaymentMethod>;
  in?: InputMaybe<Array<PaymentMethod>>;
  not?: InputMaybe<NestedEnumPaymentMethodNullableWithAggregatesFilter>;
  notIn?: InputMaybe<Array<PaymentMethod>>;
};

export type EnumPaymentServiceProviderFieldUpdateOperationsInput = {
  set?: InputMaybe<PaymentServiceProvider>;
};

export type EnumPaymentServiceProviderFilter = {
  equals?: InputMaybe<PaymentServiceProvider>;
  in?: InputMaybe<Array<PaymentServiceProvider>>;
  not?: InputMaybe<NestedEnumPaymentServiceProviderFilter>;
  notIn?: InputMaybe<Array<PaymentServiceProvider>>;
};

export type EnumPaymentServiceProviderWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumPaymentServiceProviderFilter>;
  _min?: InputMaybe<NestedEnumPaymentServiceProviderFilter>;
  equals?: InputMaybe<PaymentServiceProvider>;
  in?: InputMaybe<Array<PaymentServiceProvider>>;
  not?: InputMaybe<NestedEnumPaymentServiceProviderWithAggregatesFilter>;
  notIn?: InputMaybe<Array<PaymentServiceProvider>>;
};

export type EnumPrintStatusFieldUpdateOperationsInput = {
  set?: InputMaybe<PrintStatus>;
};

export type EnumPrintStatusFilter = {
  equals?: InputMaybe<PrintStatus>;
  in?: InputMaybe<Array<PrintStatus>>;
  not?: InputMaybe<NestedEnumPrintStatusFilter>;
  notIn?: InputMaybe<Array<PrintStatus>>;
};

export type EnumPrintStatusWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumPrintStatusFilter>;
  _min?: InputMaybe<NestedEnumPrintStatusFilter>;
  equals?: InputMaybe<PrintStatus>;
  in?: InputMaybe<Array<PrintStatus>>;
  not?: InputMaybe<NestedEnumPrintStatusWithAggregatesFilter>;
  notIn?: InputMaybe<Array<PrintStatus>>;
};

export type EnumPrivacyLevelFieldUpdateOperationsInput = {
  set?: InputMaybe<PrivacyLevel>;
};

export type EnumPrivacyLevelFilter = {
  equals?: InputMaybe<PrivacyLevel>;
  in?: InputMaybe<Array<PrivacyLevel>>;
  not?: InputMaybe<NestedEnumPrivacyLevelFilter>;
  notIn?: InputMaybe<Array<PrivacyLevel>>;
};

export type EnumPrivacyLevelNullableFilter = {
  equals?: InputMaybe<PrivacyLevel>;
  in?: InputMaybe<Array<PrivacyLevel>>;
  not?: InputMaybe<NestedEnumPrivacyLevelNullableFilter>;
  notIn?: InputMaybe<Array<PrivacyLevel>>;
};

export type EnumPrivacyLevelNullableWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntNullableFilter>;
  _max?: InputMaybe<NestedEnumPrivacyLevelNullableFilter>;
  _min?: InputMaybe<NestedEnumPrivacyLevelNullableFilter>;
  equals?: InputMaybe<PrivacyLevel>;
  in?: InputMaybe<Array<PrivacyLevel>>;
  not?: InputMaybe<NestedEnumPrivacyLevelNullableWithAggregatesFilter>;
  notIn?: InputMaybe<Array<PrivacyLevel>>;
};

export type EnumPrivacyLevelWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumPrivacyLevelFilter>;
  _min?: InputMaybe<NestedEnumPrivacyLevelFilter>;
  equals?: InputMaybe<PrivacyLevel>;
  in?: InputMaybe<Array<PrivacyLevel>>;
  not?: InputMaybe<NestedEnumPrivacyLevelWithAggregatesFilter>;
  notIn?: InputMaybe<Array<PrivacyLevel>>;
};

export type EnumProductTypeFieldUpdateOperationsInput = {
  set?: InputMaybe<ProductType>;
};

export type EnumProductTypeFilter = {
  equals?: InputMaybe<ProductType>;
  in?: InputMaybe<Array<ProductType>>;
  not?: InputMaybe<NestedEnumProductTypeFilter>;
  notIn?: InputMaybe<Array<ProductType>>;
};

export type EnumProductTypeWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumProductTypeFilter>;
  _min?: InputMaybe<NestedEnumProductTypeFilter>;
  equals?: InputMaybe<ProductType>;
  in?: InputMaybe<Array<ProductType>>;
  not?: InputMaybe<NestedEnumProductTypeWithAggregatesFilter>;
  notIn?: InputMaybe<Array<ProductType>>;
};

export type EnumProfitableClassFieldUpdateOperationsInput = {
  set?: InputMaybe<ProfitableClass>;
};

export type EnumProfitableClassFilter = {
  equals?: InputMaybe<ProfitableClass>;
  in?: InputMaybe<Array<ProfitableClass>>;
  not?: InputMaybe<NestedEnumProfitableClassFilter>;
  notIn?: InputMaybe<Array<ProfitableClass>>;
};

export type EnumProfitableClassWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumProfitableClassFilter>;
  _min?: InputMaybe<NestedEnumProfitableClassFilter>;
  equals?: InputMaybe<ProfitableClass>;
  in?: InputMaybe<Array<ProfitableClass>>;
  not?: InputMaybe<NestedEnumProfitableClassWithAggregatesFilter>;
  notIn?: InputMaybe<Array<ProfitableClass>>;
};

export type EnumProfitableTypeFieldUpdateOperationsInput = {
  set?: InputMaybe<ProfitableType>;
};

export type EnumProfitableTypeFilter = {
  equals?: InputMaybe<ProfitableType>;
  in?: InputMaybe<Array<ProfitableType>>;
  not?: InputMaybe<NestedEnumProfitableTypeFilter>;
  notIn?: InputMaybe<Array<ProfitableType>>;
};

export type EnumProfitableTypeWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumProfitableTypeFilter>;
  _min?: InputMaybe<NestedEnumProfitableTypeFilter>;
  equals?: InputMaybe<ProfitableType>;
  in?: InputMaybe<Array<ProfitableType>>;
  not?: InputMaybe<NestedEnumProfitableTypeWithAggregatesFilter>;
  notIn?: InputMaybe<Array<ProfitableType>>;
};

export type EnumQrcodeServiceTypeFieldUpdateOperationsInput = {
  set?: InputMaybe<QrcodeServiceType>;
};

export type EnumQrcodeServiceTypeFilter = {
  equals?: InputMaybe<QrcodeServiceType>;
  in?: InputMaybe<Array<QrcodeServiceType>>;
  not?: InputMaybe<NestedEnumQrcodeServiceTypeFilter>;
  notIn?: InputMaybe<Array<QrcodeServiceType>>;
};

export type EnumQrcodeServiceTypeWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumQrcodeServiceTypeFilter>;
  _min?: InputMaybe<NestedEnumQrcodeServiceTypeFilter>;
  equals?: InputMaybe<QrcodeServiceType>;
  in?: InputMaybe<Array<QrcodeServiceType>>;
  not?: InputMaybe<NestedEnumQrcodeServiceTypeWithAggregatesFilter>;
  notIn?: InputMaybe<Array<QrcodeServiceType>>;
};

export type EnumReceiverSettingNotificationFieldUpdateOperationsInput = {
  set?: InputMaybe<ReceiverSettingNotification>;
};

export type EnumReceiverSettingNotificationFilter = {
  equals?: InputMaybe<ReceiverSettingNotification>;
  in?: InputMaybe<Array<ReceiverSettingNotification>>;
  not?: InputMaybe<NestedEnumReceiverSettingNotificationFilter>;
  notIn?: InputMaybe<Array<ReceiverSettingNotification>>;
};

export type EnumReceiverSettingNotificationWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumReceiverSettingNotificationFilter>;
  _min?: InputMaybe<NestedEnumReceiverSettingNotificationFilter>;
  equals?: InputMaybe<ReceiverSettingNotification>;
  in?: InputMaybe<Array<ReceiverSettingNotification>>;
  not?: InputMaybe<NestedEnumReceiverSettingNotificationWithAggregatesFilter>;
  notIn?: InputMaybe<Array<ReceiverSettingNotification>>;
};

export type EnumReceiverSettingPrivacyFieldUpdateOperationsInput = {
  set?: InputMaybe<ReceiverSettingPrivacy>;
};

export type EnumReceiverSettingPrivacyFilter = {
  equals?: InputMaybe<ReceiverSettingPrivacy>;
  in?: InputMaybe<Array<ReceiverSettingPrivacy>>;
  not?: InputMaybe<NestedEnumReceiverSettingPrivacyFilter>;
  notIn?: InputMaybe<Array<ReceiverSettingPrivacy>>;
};

export type EnumReceiverSettingPrivacyWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumReceiverSettingPrivacyFilter>;
  _min?: InputMaybe<NestedEnumReceiverSettingPrivacyFilter>;
  equals?: InputMaybe<ReceiverSettingPrivacy>;
  in?: InputMaybe<Array<ReceiverSettingPrivacy>>;
  not?: InputMaybe<NestedEnumReceiverSettingPrivacyWithAggregatesFilter>;
  notIn?: InputMaybe<Array<ReceiverSettingPrivacy>>;
};

export type EnumReportTypeFieldUpdateOperationsInput = {
  set?: InputMaybe<ReportType>;
};

export type EnumReportTypeFilter = {
  equals?: InputMaybe<ReportType>;
  in?: InputMaybe<Array<ReportType>>;
  not?: InputMaybe<NestedEnumReportTypeFilter>;
  notIn?: InputMaybe<Array<ReportType>>;
};

export type EnumReportTypeWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumReportTypeFilter>;
  _min?: InputMaybe<NestedEnumReportTypeFilter>;
  equals?: InputMaybe<ReportType>;
  in?: InputMaybe<Array<ReportType>>;
  not?: InputMaybe<NestedEnumReportTypeWithAggregatesFilter>;
  notIn?: InputMaybe<Array<ReportType>>;
};

export type EnumReportUserTypeFieldUpdateOperationsInput = {
  set?: InputMaybe<ReportUserType>;
};

export type EnumReportUserTypeFilter = {
  equals?: InputMaybe<ReportUserType>;
  in?: InputMaybe<Array<ReportUserType>>;
  not?: InputMaybe<NestedEnumReportUserTypeFilter>;
  notIn?: InputMaybe<Array<ReportUserType>>;
};

export type EnumReportUserTypeWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumReportUserTypeFilter>;
  _min?: InputMaybe<NestedEnumReportUserTypeFilter>;
  equals?: InputMaybe<ReportUserType>;
  in?: InputMaybe<Array<ReportUserType>>;
  not?: InputMaybe<NestedEnumReportUserTypeWithAggregatesFilter>;
  notIn?: InputMaybe<Array<ReportUserType>>;
};

export type EnumSenderSettingNotificationFieldUpdateOperationsInput = {
  set?: InputMaybe<SenderSettingNotification>;
};

export type EnumSenderSettingNotificationFilter = {
  equals?: InputMaybe<SenderSettingNotification>;
  in?: InputMaybe<Array<SenderSettingNotification>>;
  not?: InputMaybe<NestedEnumSenderSettingNotificationFilter>;
  notIn?: InputMaybe<Array<SenderSettingNotification>>;
};

export type EnumSenderSettingNotificationWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumSenderSettingNotificationFilter>;
  _min?: InputMaybe<NestedEnumSenderSettingNotificationFilter>;
  equals?: InputMaybe<SenderSettingNotification>;
  in?: InputMaybe<Array<SenderSettingNotification>>;
  not?: InputMaybe<NestedEnumSenderSettingNotificationWithAggregatesFilter>;
  notIn?: InputMaybe<Array<SenderSettingNotification>>;
};

export type EnumSendingTypeFieldUpdateOperationsInput = {
  set?: InputMaybe<SendingType>;
};

export type EnumSendingTypeFilter = {
  equals?: InputMaybe<SendingType>;
  in?: InputMaybe<Array<SendingType>>;
  not?: InputMaybe<NestedEnumSendingTypeFilter>;
  notIn?: InputMaybe<Array<SendingType>>;
};

export type EnumSendingTypeWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumSendingTypeFilter>;
  _min?: InputMaybe<NestedEnumSendingTypeFilter>;
  equals?: InputMaybe<SendingType>;
  in?: InputMaybe<Array<SendingType>>;
  not?: InputMaybe<NestedEnumSendingTypeWithAggregatesFilter>;
  notIn?: InputMaybe<Array<SendingType>>;
};

export type EnumStoreItemTagClassFieldUpdateOperationsInput = {
  set?: InputMaybe<StoreItemTagClass>;
};

export type EnumStoreItemTagClassFilter = {
  equals?: InputMaybe<StoreItemTagClass>;
  in?: InputMaybe<Array<StoreItemTagClass>>;
  not?: InputMaybe<NestedEnumStoreItemTagClassFilter>;
  notIn?: InputMaybe<Array<StoreItemTagClass>>;
};

export type EnumStoreItemTagClassWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumStoreItemTagClassFilter>;
  _min?: InputMaybe<NestedEnumStoreItemTagClassFilter>;
  equals?: InputMaybe<StoreItemTagClass>;
  in?: InputMaybe<Array<StoreItemTagClass>>;
  not?: InputMaybe<NestedEnumStoreItemTagClassWithAggregatesFilter>;
  notIn?: InputMaybe<Array<StoreItemTagClass>>;
};

export type EnumStoreItemTypeFieldUpdateOperationsInput = {
  set?: InputMaybe<StoreItemType>;
};

export type EnumStoreItemTypeFilter = {
  equals?: InputMaybe<StoreItemType>;
  in?: InputMaybe<Array<StoreItemType>>;
  not?: InputMaybe<NestedEnumStoreItemTypeFilter>;
  notIn?: InputMaybe<Array<StoreItemType>>;
};

export type EnumStoreItemTypeWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumStoreItemTypeFilter>;
  _min?: InputMaybe<NestedEnumStoreItemTypeFilter>;
  equals?: InputMaybe<StoreItemType>;
  in?: InputMaybe<Array<StoreItemType>>;
  not?: InputMaybe<NestedEnumStoreItemTypeWithAggregatesFilter>;
  notIn?: InputMaybe<Array<StoreItemType>>;
};

export type EnumTappayMethodTypeFieldUpdateOperationsInput = {
  set?: InputMaybe<TappayMethodType>;
};

export type EnumTappayMethodTypeFilter = {
  equals?: InputMaybe<TappayMethodType>;
  in?: InputMaybe<Array<TappayMethodType>>;
  not?: InputMaybe<NestedEnumTappayMethodTypeFilter>;
  notIn?: InputMaybe<Array<TappayMethodType>>;
};

export type EnumTappayMethodTypeWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumTappayMethodTypeFilter>;
  _min?: InputMaybe<NestedEnumTappayMethodTypeFilter>;
  equals?: InputMaybe<TappayMethodType>;
  in?: InputMaybe<Array<TappayMethodType>>;
  not?: InputMaybe<NestedEnumTappayMethodTypeWithAggregatesFilter>;
  notIn?: InputMaybe<Array<TappayMethodType>>;
};

export type EnumUserAuthTypeFieldUpdateOperationsInput = {
  set?: InputMaybe<UserAuthType>;
};

export type EnumUserAuthTypeFilter = {
  equals?: InputMaybe<UserAuthType>;
  in?: InputMaybe<Array<UserAuthType>>;
  not?: InputMaybe<NestedEnumUserAuthTypeFilter>;
  notIn?: InputMaybe<Array<UserAuthType>>;
};

export type EnumUserAuthTypeWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumUserAuthTypeFilter>;
  _min?: InputMaybe<NestedEnumUserAuthTypeFilter>;
  equals?: InputMaybe<UserAuthType>;
  in?: InputMaybe<Array<UserAuthType>>;
  not?: InputMaybe<NestedEnumUserAuthTypeWithAggregatesFilter>;
  notIn?: InputMaybe<Array<UserAuthType>>;
};

export type FloatFieldUpdateOperationsInput = {
  decrement?: InputMaybe<Scalars['Float']>;
  divide?: InputMaybe<Scalars['Float']>;
  increment?: InputMaybe<Scalars['Float']>;
  multiply?: InputMaybe<Scalars['Float']>;
  set?: InputMaybe<Scalars['Float']>;
};

export type FloatFilter = {
  equals?: InputMaybe<Scalars['Float']>;
  gt?: InputMaybe<Scalars['Float']>;
  gte?: InputMaybe<Scalars['Float']>;
  in?: InputMaybe<Array<Scalars['Float']>>;
  lt?: InputMaybe<Scalars['Float']>;
  lte?: InputMaybe<Scalars['Float']>;
  not?: InputMaybe<NestedFloatFilter>;
  notIn?: InputMaybe<Array<Scalars['Float']>>;
};

export type FloatNullableFilter = {
  equals?: InputMaybe<Scalars['Float']>;
  gt?: InputMaybe<Scalars['Float']>;
  gte?: InputMaybe<Scalars['Float']>;
  in?: InputMaybe<Array<Scalars['Float']>>;
  lt?: InputMaybe<Scalars['Float']>;
  lte?: InputMaybe<Scalars['Float']>;
  not?: InputMaybe<NestedFloatNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['Float']>>;
};

export type FloatNullableWithAggregatesFilter = {
  _avg?: InputMaybe<NestedFloatNullableFilter>;
  _count?: InputMaybe<NestedIntNullableFilter>;
  _max?: InputMaybe<NestedFloatNullableFilter>;
  _min?: InputMaybe<NestedFloatNullableFilter>;
  _sum?: InputMaybe<NestedFloatNullableFilter>;
  equals?: InputMaybe<Scalars['Float']>;
  gt?: InputMaybe<Scalars['Float']>;
  gte?: InputMaybe<Scalars['Float']>;
  in?: InputMaybe<Array<Scalars['Float']>>;
  lt?: InputMaybe<Scalars['Float']>;
  lte?: InputMaybe<Scalars['Float']>;
  not?: InputMaybe<NestedFloatNullableWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['Float']>>;
};

export type FloatWithAggregatesFilter = {
  _avg?: InputMaybe<NestedFloatFilter>;
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedFloatFilter>;
  _min?: InputMaybe<NestedFloatFilter>;
  _sum?: InputMaybe<NestedFloatFilter>;
  equals?: InputMaybe<Scalars['Float']>;
  gt?: InputMaybe<Scalars['Float']>;
  gte?: InputMaybe<Scalars['Float']>;
  in?: InputMaybe<Array<Scalars['Float']>>;
  lt?: InputMaybe<Scalars['Float']>;
  lte?: InputMaybe<Scalars['Float']>;
  not?: InputMaybe<NestedFloatWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['Float']>>;
};

export enum FriendStatus {
  BestFriend = 'BEST_FRIEND',
  Block = 'BLOCK',
  Cancel = 'CANCEL',
  Friend = 'FRIEND',
  Stranger = 'STRANGER'
}

export type Group = {
  __typename?: 'Group';
  _count?: Maybe<GroupCount>;
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  members: Array<User>;
  name: Scalars['String'];
  owner: User;
  ownerId: Scalars['String'];
  packagesUsed: Array<PostPackage>;
  updatedAt: Scalars['DateTime'];
};


export type GroupMembersArgs = {
  cursor?: InputMaybe<UserWhereUniqueInput>;
  distinct?: InputMaybe<Array<UserScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<UserOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<UserWhereInput>;
};


export type GroupPackagesUsedArgs = {
  cursor?: InputMaybe<PostPackageWhereUniqueInput>;
  distinct?: InputMaybe<Array<PostPackageScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<PostPackageOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PostPackageWhereInput>;
};

export type GroupCount = {
  __typename?: 'GroupCount';
  members: Scalars['Int'];
  packagesUsed: Scalars['Int'];
};


export type GroupCountMembersArgs = {
  where?: InputMaybe<UserWhereInput>;
};


export type GroupCountPackagesUsedArgs = {
  where?: InputMaybe<PostPackageWhereInput>;
};

export type GroupCountAggregate = {
  __typename?: 'GroupCountAggregate';
  _all: Scalars['Int'];
  createdAt: Scalars['Int'];
  id: Scalars['Int'];
  name: Scalars['Int'];
  ownerId: Scalars['Int'];
  updatedAt: Scalars['Int'];
};

export type GroupCountOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  ownerId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type GroupCreateInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  members?: InputMaybe<UserCreateNestedManyWithoutGroupsJoinInput>;
  name: Scalars['String'];
  owner: UserCreateNestedOneWithoutGroupsOwnInput;
  packagesUsed?: InputMaybe<PostPackageCreateNestedManyWithoutGroupsRecvInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type GroupCreateManyInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  ownerId: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type GroupCreateManyOwnerInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type GroupCreateManyOwnerInputEnvelope = {
  data: Array<GroupCreateManyOwnerInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type GroupCreateNestedManyWithoutMembersInput = {
  connect?: InputMaybe<Array<GroupWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<GroupCreateOrConnectWithoutMembersInput>>;
  create?: InputMaybe<Array<GroupCreateWithoutMembersInput>>;
};

export type GroupCreateNestedManyWithoutOwnerInput = {
  connect?: InputMaybe<Array<GroupWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<GroupCreateOrConnectWithoutOwnerInput>>;
  create?: InputMaybe<Array<GroupCreateWithoutOwnerInput>>;
  createMany?: InputMaybe<GroupCreateManyOwnerInputEnvelope>;
};

export type GroupCreateNestedManyWithoutPackagesUsedInput = {
  connect?: InputMaybe<Array<GroupWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<GroupCreateOrConnectWithoutPackagesUsedInput>>;
  create?: InputMaybe<Array<GroupCreateWithoutPackagesUsedInput>>;
};

export type GroupCreateOrConnectWithoutMembersInput = {
  create: GroupCreateWithoutMembersInput;
  where: GroupWhereUniqueInput;
};

export type GroupCreateOrConnectWithoutOwnerInput = {
  create: GroupCreateWithoutOwnerInput;
  where: GroupWhereUniqueInput;
};

export type GroupCreateOrConnectWithoutPackagesUsedInput = {
  create: GroupCreateWithoutPackagesUsedInput;
  where: GroupWhereUniqueInput;
};

export type GroupCreateWithoutMembersInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  owner: UserCreateNestedOneWithoutGroupsOwnInput;
  packagesUsed?: InputMaybe<PostPackageCreateNestedManyWithoutGroupsRecvInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type GroupCreateWithoutOwnerInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  members?: InputMaybe<UserCreateNestedManyWithoutGroupsJoinInput>;
  name: Scalars['String'];
  packagesUsed?: InputMaybe<PostPackageCreateNestedManyWithoutGroupsRecvInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type GroupCreateWithoutPackagesUsedInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  members?: InputMaybe<UserCreateNestedManyWithoutGroupsJoinInput>;
  name: Scalars['String'];
  owner: UserCreateNestedOneWithoutGroupsOwnInput;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type GroupGroupBy = {
  __typename?: 'GroupGroupBy';
  _count?: Maybe<GroupCountAggregate>;
  _max?: Maybe<GroupMaxAggregate>;
  _min?: Maybe<GroupMinAggregate>;
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  name: Scalars['String'];
  ownerId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type GroupListRelationFilter = {
  every?: InputMaybe<GroupWhereInput>;
  none?: InputMaybe<GroupWhereInput>;
  some?: InputMaybe<GroupWhereInput>;
};

export type GroupMaxAggregate = {
  __typename?: 'GroupMaxAggregate';
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  ownerId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type GroupMaxOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  ownerId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type GroupMinAggregate = {
  __typename?: 'GroupMinAggregate';
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  ownerId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type GroupMinOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  ownerId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type GroupOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type GroupOrderByWithAggregationInput = {
  _count?: InputMaybe<GroupCountOrderByAggregateInput>;
  _max?: InputMaybe<GroupMaxOrderByAggregateInput>;
  _min?: InputMaybe<GroupMinOrderByAggregateInput>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  ownerId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type GroupOrderByWithRelationInput = {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  members?: InputMaybe<UserOrderByRelationAggregateInput>;
  name?: InputMaybe<SortOrder>;
  owner?: InputMaybe<UserOrderByWithRelationInput>;
  ownerId?: InputMaybe<SortOrder>;
  packagesUsed?: InputMaybe<PostPackageOrderByRelationAggregateInput>;
  updatedAt?: InputMaybe<SortOrder>;
};

export enum GroupScalarFieldEnum {
  CreatedAt = 'createdAt',
  Id = 'id',
  Name = 'name',
  OwnerId = 'ownerId',
  UpdatedAt = 'updatedAt'
}

export type GroupScalarWhereInput = {
  AND?: InputMaybe<Array<GroupScalarWhereInput>>;
  NOT?: InputMaybe<Array<GroupScalarWhereInput>>;
  OR?: InputMaybe<Array<GroupScalarWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringFilter>;
  ownerId?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type GroupScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<GroupScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<GroupScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<GroupScalarWhereWithAggregatesInput>>;
  createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  id?: InputMaybe<StringWithAggregatesFilter>;
  name?: InputMaybe<StringWithAggregatesFilter>;
  ownerId?: InputMaybe<StringWithAggregatesFilter>;
  updatedAt?: InputMaybe<DateTimeWithAggregatesFilter>;
};

export type GroupUpdateInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  members?: InputMaybe<UserUpdateManyWithoutGroupsJoinNestedInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  owner?: InputMaybe<UserUpdateOneRequiredWithoutGroupsOwnNestedInput>;
  packagesUsed?: InputMaybe<PostPackageUpdateManyWithoutGroupsRecvNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type GroupUpdateManyMutationInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type GroupUpdateManyWithWhereWithoutMembersInput = {
  data: GroupUpdateManyMutationInput;
  where: GroupScalarWhereInput;
};

export type GroupUpdateManyWithWhereWithoutOwnerInput = {
  data: GroupUpdateManyMutationInput;
  where: GroupScalarWhereInput;
};

export type GroupUpdateManyWithWhereWithoutPackagesUsedInput = {
  data: GroupUpdateManyMutationInput;
  where: GroupScalarWhereInput;
};

export type GroupUpdateManyWithoutMembersNestedInput = {
  connect?: InputMaybe<Array<GroupWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<GroupCreateOrConnectWithoutMembersInput>>;
  create?: InputMaybe<Array<GroupCreateWithoutMembersInput>>;
  delete?: InputMaybe<Array<GroupWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<GroupScalarWhereInput>>;
  disconnect?: InputMaybe<Array<GroupWhereUniqueInput>>;
  set?: InputMaybe<Array<GroupWhereUniqueInput>>;
  update?: InputMaybe<Array<GroupUpdateWithWhereUniqueWithoutMembersInput>>;
  updateMany?: InputMaybe<Array<GroupUpdateManyWithWhereWithoutMembersInput>>;
  upsert?: InputMaybe<Array<GroupUpsertWithWhereUniqueWithoutMembersInput>>;
};

export type GroupUpdateManyWithoutOwnerNestedInput = {
  connect?: InputMaybe<Array<GroupWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<GroupCreateOrConnectWithoutOwnerInput>>;
  create?: InputMaybe<Array<GroupCreateWithoutOwnerInput>>;
  createMany?: InputMaybe<GroupCreateManyOwnerInputEnvelope>;
  delete?: InputMaybe<Array<GroupWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<GroupScalarWhereInput>>;
  disconnect?: InputMaybe<Array<GroupWhereUniqueInput>>;
  set?: InputMaybe<Array<GroupWhereUniqueInput>>;
  update?: InputMaybe<Array<GroupUpdateWithWhereUniqueWithoutOwnerInput>>;
  updateMany?: InputMaybe<Array<GroupUpdateManyWithWhereWithoutOwnerInput>>;
  upsert?: InputMaybe<Array<GroupUpsertWithWhereUniqueWithoutOwnerInput>>;
};

export type GroupUpdateManyWithoutPackagesUsedNestedInput = {
  connect?: InputMaybe<Array<GroupWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<GroupCreateOrConnectWithoutPackagesUsedInput>>;
  create?: InputMaybe<Array<GroupCreateWithoutPackagesUsedInput>>;
  delete?: InputMaybe<Array<GroupWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<GroupScalarWhereInput>>;
  disconnect?: InputMaybe<Array<GroupWhereUniqueInput>>;
  set?: InputMaybe<Array<GroupWhereUniqueInput>>;
  update?: InputMaybe<Array<GroupUpdateWithWhereUniqueWithoutPackagesUsedInput>>;
  updateMany?: InputMaybe<Array<GroupUpdateManyWithWhereWithoutPackagesUsedInput>>;
  upsert?: InputMaybe<Array<GroupUpsertWithWhereUniqueWithoutPackagesUsedInput>>;
};

export type GroupUpdateWithWhereUniqueWithoutMembersInput = {
  data: GroupUpdateWithoutMembersInput;
  where: GroupWhereUniqueInput;
};

export type GroupUpdateWithWhereUniqueWithoutOwnerInput = {
  data: GroupUpdateWithoutOwnerInput;
  where: GroupWhereUniqueInput;
};

export type GroupUpdateWithWhereUniqueWithoutPackagesUsedInput = {
  data: GroupUpdateWithoutPackagesUsedInput;
  where: GroupWhereUniqueInput;
};

export type GroupUpdateWithoutMembersInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  owner?: InputMaybe<UserUpdateOneRequiredWithoutGroupsOwnNestedInput>;
  packagesUsed?: InputMaybe<PostPackageUpdateManyWithoutGroupsRecvNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type GroupUpdateWithoutOwnerInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  members?: InputMaybe<UserUpdateManyWithoutGroupsJoinNestedInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  packagesUsed?: InputMaybe<PostPackageUpdateManyWithoutGroupsRecvNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type GroupUpdateWithoutPackagesUsedInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  members?: InputMaybe<UserUpdateManyWithoutGroupsJoinNestedInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  owner?: InputMaybe<UserUpdateOneRequiredWithoutGroupsOwnNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type GroupUpsertWithWhereUniqueWithoutMembersInput = {
  create: GroupCreateWithoutMembersInput;
  update: GroupUpdateWithoutMembersInput;
  where: GroupWhereUniqueInput;
};

export type GroupUpsertWithWhereUniqueWithoutOwnerInput = {
  create: GroupCreateWithoutOwnerInput;
  update: GroupUpdateWithoutOwnerInput;
  where: GroupWhereUniqueInput;
};

export type GroupUpsertWithWhereUniqueWithoutPackagesUsedInput = {
  create: GroupCreateWithoutPackagesUsedInput;
  update: GroupUpdateWithoutPackagesUsedInput;
  where: GroupWhereUniqueInput;
};

export type GroupWhereInput = {
  AND?: InputMaybe<Array<GroupWhereInput>>;
  NOT?: InputMaybe<Array<GroupWhereInput>>;
  OR?: InputMaybe<Array<GroupWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  members?: InputMaybe<UserListRelationFilter>;
  name?: InputMaybe<StringFilter>;
  owner?: InputMaybe<UserRelationFilter>;
  ownerId?: InputMaybe<StringFilter>;
  packagesUsed?: InputMaybe<PostPackageListRelationFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type GroupWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
};

export type InValidReason = {
  __typename?: 'InValidReason';
  card?: Maybe<Card>;
  cardId?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  originalUserIdStr?: Maybe<Scalars['String']>;
  post?: Maybe<Post>;
  postId?: Maybe<Scalars['String']>;
  profitable?: Maybe<Profitable>;
  profitableId?: Maybe<Scalars['String']>;
  stamp?: Maybe<Stamp>;
  stampId?: Maybe<Scalars['String']>;
  type: InValidType;
  updatedAt: Scalars['DateTime'];
  user?: Maybe<User>;
  userId?: Maybe<Scalars['String']>;
};

export type InValidReasonCountAggregate = {
  __typename?: 'InValidReasonCountAggregate';
  _all: Scalars['Int'];
  cardId: Scalars['Int'];
  createdAt: Scalars['Int'];
  description: Scalars['Int'];
  id: Scalars['Int'];
  originalUserIdStr: Scalars['Int'];
  postId: Scalars['Int'];
  profitableId: Scalars['Int'];
  stampId: Scalars['Int'];
  type: Scalars['Int'];
  updatedAt: Scalars['Int'];
  userId: Scalars['Int'];
};

export type InValidReasonCountOrderByAggregateInput = {
  cardId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  originalUserIdStr?: InputMaybe<SortOrder>;
  postId?: InputMaybe<SortOrder>;
  profitableId?: InputMaybe<SortOrder>;
  stampId?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
};

export type InValidReasonCreateInput = {
  card?: InputMaybe<CardCreateNestedOneWithoutInValidReasonHistoriesInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  originalUserIdStr?: InputMaybe<Scalars['String']>;
  post?: InputMaybe<PostCreateNestedOneWithoutInValidReasonHistoriesInput>;
  profitable?: InputMaybe<ProfitableCreateNestedOneWithoutInValidReasonHistoriesInput>;
  stamp?: InputMaybe<StampCreateNestedOneWithoutInValidReasonHistoriesInput>;
  type: InValidType;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  user?: InputMaybe<UserCreateNestedOneWithoutInValidReasonHistoriesInput>;
};

export type InValidReasonCreateManyCardInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  originalUserIdStr?: InputMaybe<Scalars['String']>;
  postId?: InputMaybe<Scalars['String']>;
  profitableId?: InputMaybe<Scalars['String']>;
  stampId?: InputMaybe<Scalars['String']>;
  type: InValidType;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userId?: InputMaybe<Scalars['String']>;
};

export type InValidReasonCreateManyCardInputEnvelope = {
  data: Array<InValidReasonCreateManyCardInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type InValidReasonCreateManyInput = {
  cardId?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  originalUserIdStr?: InputMaybe<Scalars['String']>;
  postId?: InputMaybe<Scalars['String']>;
  profitableId?: InputMaybe<Scalars['String']>;
  stampId?: InputMaybe<Scalars['String']>;
  type: InValidType;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userId?: InputMaybe<Scalars['String']>;
};

export type InValidReasonCreateManyPostInput = {
  cardId?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  originalUserIdStr?: InputMaybe<Scalars['String']>;
  profitableId?: InputMaybe<Scalars['String']>;
  stampId?: InputMaybe<Scalars['String']>;
  type: InValidType;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userId?: InputMaybe<Scalars['String']>;
};

export type InValidReasonCreateManyPostInputEnvelope = {
  data: Array<InValidReasonCreateManyPostInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type InValidReasonCreateManyProfitableInput = {
  cardId?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  originalUserIdStr?: InputMaybe<Scalars['String']>;
  postId?: InputMaybe<Scalars['String']>;
  stampId?: InputMaybe<Scalars['String']>;
  type: InValidType;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userId?: InputMaybe<Scalars['String']>;
};

export type InValidReasonCreateManyProfitableInputEnvelope = {
  data: Array<InValidReasonCreateManyProfitableInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type InValidReasonCreateManyStampInput = {
  cardId?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  originalUserIdStr?: InputMaybe<Scalars['String']>;
  postId?: InputMaybe<Scalars['String']>;
  profitableId?: InputMaybe<Scalars['String']>;
  type: InValidType;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userId?: InputMaybe<Scalars['String']>;
};

export type InValidReasonCreateManyStampInputEnvelope = {
  data: Array<InValidReasonCreateManyStampInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type InValidReasonCreateManyUserInput = {
  cardId?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  originalUserIdStr?: InputMaybe<Scalars['String']>;
  postId?: InputMaybe<Scalars['String']>;
  profitableId?: InputMaybe<Scalars['String']>;
  stampId?: InputMaybe<Scalars['String']>;
  type: InValidType;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type InValidReasonCreateManyUserInputEnvelope = {
  data: Array<InValidReasonCreateManyUserInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type InValidReasonCreateNestedManyWithoutCardInput = {
  connect?: InputMaybe<Array<InValidReasonWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<InValidReasonCreateOrConnectWithoutCardInput>>;
  create?: InputMaybe<Array<InValidReasonCreateWithoutCardInput>>;
  createMany?: InputMaybe<InValidReasonCreateManyCardInputEnvelope>;
};

export type InValidReasonCreateNestedManyWithoutPostInput = {
  connect?: InputMaybe<Array<InValidReasonWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<InValidReasonCreateOrConnectWithoutPostInput>>;
  create?: InputMaybe<Array<InValidReasonCreateWithoutPostInput>>;
  createMany?: InputMaybe<InValidReasonCreateManyPostInputEnvelope>;
};

export type InValidReasonCreateNestedManyWithoutProfitableInput = {
  connect?: InputMaybe<Array<InValidReasonWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<InValidReasonCreateOrConnectWithoutProfitableInput>>;
  create?: InputMaybe<Array<InValidReasonCreateWithoutProfitableInput>>;
  createMany?: InputMaybe<InValidReasonCreateManyProfitableInputEnvelope>;
};

export type InValidReasonCreateNestedManyWithoutStampInput = {
  connect?: InputMaybe<Array<InValidReasonWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<InValidReasonCreateOrConnectWithoutStampInput>>;
  create?: InputMaybe<Array<InValidReasonCreateWithoutStampInput>>;
  createMany?: InputMaybe<InValidReasonCreateManyStampInputEnvelope>;
};

export type InValidReasonCreateNestedManyWithoutUserInput = {
  connect?: InputMaybe<Array<InValidReasonWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<InValidReasonCreateOrConnectWithoutUserInput>>;
  create?: InputMaybe<Array<InValidReasonCreateWithoutUserInput>>;
  createMany?: InputMaybe<InValidReasonCreateManyUserInputEnvelope>;
};

export type InValidReasonCreateOrConnectWithoutCardInput = {
  create: InValidReasonCreateWithoutCardInput;
  where: InValidReasonWhereUniqueInput;
};

export type InValidReasonCreateOrConnectWithoutPostInput = {
  create: InValidReasonCreateWithoutPostInput;
  where: InValidReasonWhereUniqueInput;
};

export type InValidReasonCreateOrConnectWithoutProfitableInput = {
  create: InValidReasonCreateWithoutProfitableInput;
  where: InValidReasonWhereUniqueInput;
};

export type InValidReasonCreateOrConnectWithoutStampInput = {
  create: InValidReasonCreateWithoutStampInput;
  where: InValidReasonWhereUniqueInput;
};

export type InValidReasonCreateOrConnectWithoutUserInput = {
  create: InValidReasonCreateWithoutUserInput;
  where: InValidReasonWhereUniqueInput;
};

export type InValidReasonCreateWithoutCardInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  originalUserIdStr?: InputMaybe<Scalars['String']>;
  post?: InputMaybe<PostCreateNestedOneWithoutInValidReasonHistoriesInput>;
  profitable?: InputMaybe<ProfitableCreateNestedOneWithoutInValidReasonHistoriesInput>;
  stamp?: InputMaybe<StampCreateNestedOneWithoutInValidReasonHistoriesInput>;
  type: InValidType;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  user?: InputMaybe<UserCreateNestedOneWithoutInValidReasonHistoriesInput>;
};

export type InValidReasonCreateWithoutPostInput = {
  card?: InputMaybe<CardCreateNestedOneWithoutInValidReasonHistoriesInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  originalUserIdStr?: InputMaybe<Scalars['String']>;
  profitable?: InputMaybe<ProfitableCreateNestedOneWithoutInValidReasonHistoriesInput>;
  stamp?: InputMaybe<StampCreateNestedOneWithoutInValidReasonHistoriesInput>;
  type: InValidType;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  user?: InputMaybe<UserCreateNestedOneWithoutInValidReasonHistoriesInput>;
};

export type InValidReasonCreateWithoutProfitableInput = {
  card?: InputMaybe<CardCreateNestedOneWithoutInValidReasonHistoriesInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  originalUserIdStr?: InputMaybe<Scalars['String']>;
  post?: InputMaybe<PostCreateNestedOneWithoutInValidReasonHistoriesInput>;
  stamp?: InputMaybe<StampCreateNestedOneWithoutInValidReasonHistoriesInput>;
  type: InValidType;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  user?: InputMaybe<UserCreateNestedOneWithoutInValidReasonHistoriesInput>;
};

export type InValidReasonCreateWithoutStampInput = {
  card?: InputMaybe<CardCreateNestedOneWithoutInValidReasonHistoriesInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  originalUserIdStr?: InputMaybe<Scalars['String']>;
  post?: InputMaybe<PostCreateNestedOneWithoutInValidReasonHistoriesInput>;
  profitable?: InputMaybe<ProfitableCreateNestedOneWithoutInValidReasonHistoriesInput>;
  type: InValidType;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  user?: InputMaybe<UserCreateNestedOneWithoutInValidReasonHistoriesInput>;
};

export type InValidReasonCreateWithoutUserInput = {
  card?: InputMaybe<CardCreateNestedOneWithoutInValidReasonHistoriesInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  originalUserIdStr?: InputMaybe<Scalars['String']>;
  post?: InputMaybe<PostCreateNestedOneWithoutInValidReasonHistoriesInput>;
  profitable?: InputMaybe<ProfitableCreateNestedOneWithoutInValidReasonHistoriesInput>;
  stamp?: InputMaybe<StampCreateNestedOneWithoutInValidReasonHistoriesInput>;
  type: InValidType;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type InValidReasonGroupBy = {
  __typename?: 'InValidReasonGroupBy';
  _count?: Maybe<InValidReasonCountAggregate>;
  _max?: Maybe<InValidReasonMaxAggregate>;
  _min?: Maybe<InValidReasonMinAggregate>;
  cardId?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  originalUserIdStr?: Maybe<Scalars['String']>;
  postId?: Maybe<Scalars['String']>;
  profitableId?: Maybe<Scalars['String']>;
  stampId?: Maybe<Scalars['String']>;
  type: InValidType;
  updatedAt: Scalars['DateTime'];
  userId?: Maybe<Scalars['String']>;
};

export type InValidReasonListRelationFilter = {
  every?: InputMaybe<InValidReasonWhereInput>;
  none?: InputMaybe<InValidReasonWhereInput>;
  some?: InputMaybe<InValidReasonWhereInput>;
};

export type InValidReasonMaxAggregate = {
  __typename?: 'InValidReasonMaxAggregate';
  cardId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  originalUserIdStr?: Maybe<Scalars['String']>;
  postId?: Maybe<Scalars['String']>;
  profitableId?: Maybe<Scalars['String']>;
  stampId?: Maybe<Scalars['String']>;
  type?: Maybe<InValidType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
};

export type InValidReasonMaxOrderByAggregateInput = {
  cardId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  originalUserIdStr?: InputMaybe<SortOrder>;
  postId?: InputMaybe<SortOrder>;
  profitableId?: InputMaybe<SortOrder>;
  stampId?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
};

export type InValidReasonMinAggregate = {
  __typename?: 'InValidReasonMinAggregate';
  cardId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  originalUserIdStr?: Maybe<Scalars['String']>;
  postId?: Maybe<Scalars['String']>;
  profitableId?: Maybe<Scalars['String']>;
  stampId?: Maybe<Scalars['String']>;
  type?: Maybe<InValidType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
};

export type InValidReasonMinOrderByAggregateInput = {
  cardId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  originalUserIdStr?: InputMaybe<SortOrder>;
  postId?: InputMaybe<SortOrder>;
  profitableId?: InputMaybe<SortOrder>;
  stampId?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
};

export type InValidReasonOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type InValidReasonOrderByWithAggregationInput = {
  _count?: InputMaybe<InValidReasonCountOrderByAggregateInput>;
  _max?: InputMaybe<InValidReasonMaxOrderByAggregateInput>;
  _min?: InputMaybe<InValidReasonMinOrderByAggregateInput>;
  cardId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  originalUserIdStr?: InputMaybe<SortOrder>;
  postId?: InputMaybe<SortOrder>;
  profitableId?: InputMaybe<SortOrder>;
  stampId?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
};

export type InValidReasonOrderByWithRelationInput = {
  card?: InputMaybe<CardOrderByWithRelationInput>;
  cardId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  originalUserIdStr?: InputMaybe<SortOrder>;
  post?: InputMaybe<PostOrderByWithRelationInput>;
  postId?: InputMaybe<SortOrder>;
  profitable?: InputMaybe<ProfitableOrderByWithRelationInput>;
  profitableId?: InputMaybe<SortOrder>;
  stamp?: InputMaybe<StampOrderByWithRelationInput>;
  stampId?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  user?: InputMaybe<UserOrderByWithRelationInput>;
  userId?: InputMaybe<SortOrder>;
};

export enum InValidReasonScalarFieldEnum {
  CardId = 'cardId',
  CreatedAt = 'createdAt',
  Description = 'description',
  Id = 'id',
  OriginalUserIdStr = 'originalUserIdStr',
  PostId = 'postId',
  ProfitableId = 'profitableId',
  StampId = 'stampId',
  Type = 'type',
  UpdatedAt = 'updatedAt',
  UserId = 'userId'
}

export type InValidReasonScalarWhereInput = {
  AND?: InputMaybe<Array<InValidReasonScalarWhereInput>>;
  NOT?: InputMaybe<Array<InValidReasonScalarWhereInput>>;
  OR?: InputMaybe<Array<InValidReasonScalarWhereInput>>;
  cardId?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  description?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<StringFilter>;
  originalUserIdStr?: InputMaybe<StringNullableFilter>;
  postId?: InputMaybe<StringNullableFilter>;
  profitableId?: InputMaybe<StringNullableFilter>;
  stampId?: InputMaybe<StringNullableFilter>;
  type?: InputMaybe<EnumInValidTypeFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  userId?: InputMaybe<StringNullableFilter>;
};

export type InValidReasonScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<InValidReasonScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<InValidReasonScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<InValidReasonScalarWhereWithAggregatesInput>>;
  cardId?: InputMaybe<StringNullableWithAggregatesFilter>;
  createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  description?: InputMaybe<StringNullableWithAggregatesFilter>;
  id?: InputMaybe<StringWithAggregatesFilter>;
  originalUserIdStr?: InputMaybe<StringNullableWithAggregatesFilter>;
  postId?: InputMaybe<StringNullableWithAggregatesFilter>;
  profitableId?: InputMaybe<StringNullableWithAggregatesFilter>;
  stampId?: InputMaybe<StringNullableWithAggregatesFilter>;
  type?: InputMaybe<EnumInValidTypeWithAggregatesFilter>;
  updatedAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  userId?: InputMaybe<StringNullableWithAggregatesFilter>;
};

export type InValidReasonUpdateInput = {
  card?: InputMaybe<CardUpdateOneWithoutInValidReasonHistoriesNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  originalUserIdStr?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  post?: InputMaybe<PostUpdateOneWithoutInValidReasonHistoriesNestedInput>;
  profitable?: InputMaybe<ProfitableUpdateOneWithoutInValidReasonHistoriesNestedInput>;
  stamp?: InputMaybe<StampUpdateOneWithoutInValidReasonHistoriesNestedInput>;
  type?: InputMaybe<EnumInValidTypeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneWithoutInValidReasonHistoriesNestedInput>;
};

export type InValidReasonUpdateManyMutationInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  originalUserIdStr?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  type?: InputMaybe<EnumInValidTypeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type InValidReasonUpdateManyWithWhereWithoutCardInput = {
  data: InValidReasonUpdateManyMutationInput;
  where: InValidReasonScalarWhereInput;
};

export type InValidReasonUpdateManyWithWhereWithoutPostInput = {
  data: InValidReasonUpdateManyMutationInput;
  where: InValidReasonScalarWhereInput;
};

export type InValidReasonUpdateManyWithWhereWithoutProfitableInput = {
  data: InValidReasonUpdateManyMutationInput;
  where: InValidReasonScalarWhereInput;
};

export type InValidReasonUpdateManyWithWhereWithoutStampInput = {
  data: InValidReasonUpdateManyMutationInput;
  where: InValidReasonScalarWhereInput;
};

export type InValidReasonUpdateManyWithWhereWithoutUserInput = {
  data: InValidReasonUpdateManyMutationInput;
  where: InValidReasonScalarWhereInput;
};

export type InValidReasonUpdateManyWithoutCardNestedInput = {
  connect?: InputMaybe<Array<InValidReasonWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<InValidReasonCreateOrConnectWithoutCardInput>>;
  create?: InputMaybe<Array<InValidReasonCreateWithoutCardInput>>;
  createMany?: InputMaybe<InValidReasonCreateManyCardInputEnvelope>;
  delete?: InputMaybe<Array<InValidReasonWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<InValidReasonScalarWhereInput>>;
  disconnect?: InputMaybe<Array<InValidReasonWhereUniqueInput>>;
  set?: InputMaybe<Array<InValidReasonWhereUniqueInput>>;
  update?: InputMaybe<Array<InValidReasonUpdateWithWhereUniqueWithoutCardInput>>;
  updateMany?: InputMaybe<Array<InValidReasonUpdateManyWithWhereWithoutCardInput>>;
  upsert?: InputMaybe<Array<InValidReasonUpsertWithWhereUniqueWithoutCardInput>>;
};

export type InValidReasonUpdateManyWithoutPostNestedInput = {
  connect?: InputMaybe<Array<InValidReasonWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<InValidReasonCreateOrConnectWithoutPostInput>>;
  create?: InputMaybe<Array<InValidReasonCreateWithoutPostInput>>;
  createMany?: InputMaybe<InValidReasonCreateManyPostInputEnvelope>;
  delete?: InputMaybe<Array<InValidReasonWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<InValidReasonScalarWhereInput>>;
  disconnect?: InputMaybe<Array<InValidReasonWhereUniqueInput>>;
  set?: InputMaybe<Array<InValidReasonWhereUniqueInput>>;
  update?: InputMaybe<Array<InValidReasonUpdateWithWhereUniqueWithoutPostInput>>;
  updateMany?: InputMaybe<Array<InValidReasonUpdateManyWithWhereWithoutPostInput>>;
  upsert?: InputMaybe<Array<InValidReasonUpsertWithWhereUniqueWithoutPostInput>>;
};

export type InValidReasonUpdateManyWithoutProfitableNestedInput = {
  connect?: InputMaybe<Array<InValidReasonWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<InValidReasonCreateOrConnectWithoutProfitableInput>>;
  create?: InputMaybe<Array<InValidReasonCreateWithoutProfitableInput>>;
  createMany?: InputMaybe<InValidReasonCreateManyProfitableInputEnvelope>;
  delete?: InputMaybe<Array<InValidReasonWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<InValidReasonScalarWhereInput>>;
  disconnect?: InputMaybe<Array<InValidReasonWhereUniqueInput>>;
  set?: InputMaybe<Array<InValidReasonWhereUniqueInput>>;
  update?: InputMaybe<Array<InValidReasonUpdateWithWhereUniqueWithoutProfitableInput>>;
  updateMany?: InputMaybe<Array<InValidReasonUpdateManyWithWhereWithoutProfitableInput>>;
  upsert?: InputMaybe<Array<InValidReasonUpsertWithWhereUniqueWithoutProfitableInput>>;
};

export type InValidReasonUpdateManyWithoutStampNestedInput = {
  connect?: InputMaybe<Array<InValidReasonWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<InValidReasonCreateOrConnectWithoutStampInput>>;
  create?: InputMaybe<Array<InValidReasonCreateWithoutStampInput>>;
  createMany?: InputMaybe<InValidReasonCreateManyStampInputEnvelope>;
  delete?: InputMaybe<Array<InValidReasonWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<InValidReasonScalarWhereInput>>;
  disconnect?: InputMaybe<Array<InValidReasonWhereUniqueInput>>;
  set?: InputMaybe<Array<InValidReasonWhereUniqueInput>>;
  update?: InputMaybe<Array<InValidReasonUpdateWithWhereUniqueWithoutStampInput>>;
  updateMany?: InputMaybe<Array<InValidReasonUpdateManyWithWhereWithoutStampInput>>;
  upsert?: InputMaybe<Array<InValidReasonUpsertWithWhereUniqueWithoutStampInput>>;
};

export type InValidReasonUpdateManyWithoutUserNestedInput = {
  connect?: InputMaybe<Array<InValidReasonWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<InValidReasonCreateOrConnectWithoutUserInput>>;
  create?: InputMaybe<Array<InValidReasonCreateWithoutUserInput>>;
  createMany?: InputMaybe<InValidReasonCreateManyUserInputEnvelope>;
  delete?: InputMaybe<Array<InValidReasonWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<InValidReasonScalarWhereInput>>;
  disconnect?: InputMaybe<Array<InValidReasonWhereUniqueInput>>;
  set?: InputMaybe<Array<InValidReasonWhereUniqueInput>>;
  update?: InputMaybe<Array<InValidReasonUpdateWithWhereUniqueWithoutUserInput>>;
  updateMany?: InputMaybe<Array<InValidReasonUpdateManyWithWhereWithoutUserInput>>;
  upsert?: InputMaybe<Array<InValidReasonUpsertWithWhereUniqueWithoutUserInput>>;
};

export type InValidReasonUpdateWithWhereUniqueWithoutCardInput = {
  data: InValidReasonUpdateWithoutCardInput;
  where: InValidReasonWhereUniqueInput;
};

export type InValidReasonUpdateWithWhereUniqueWithoutPostInput = {
  data: InValidReasonUpdateWithoutPostInput;
  where: InValidReasonWhereUniqueInput;
};

export type InValidReasonUpdateWithWhereUniqueWithoutProfitableInput = {
  data: InValidReasonUpdateWithoutProfitableInput;
  where: InValidReasonWhereUniqueInput;
};

export type InValidReasonUpdateWithWhereUniqueWithoutStampInput = {
  data: InValidReasonUpdateWithoutStampInput;
  where: InValidReasonWhereUniqueInput;
};

export type InValidReasonUpdateWithWhereUniqueWithoutUserInput = {
  data: InValidReasonUpdateWithoutUserInput;
  where: InValidReasonWhereUniqueInput;
};

export type InValidReasonUpdateWithoutCardInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  originalUserIdStr?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  post?: InputMaybe<PostUpdateOneWithoutInValidReasonHistoriesNestedInput>;
  profitable?: InputMaybe<ProfitableUpdateOneWithoutInValidReasonHistoriesNestedInput>;
  stamp?: InputMaybe<StampUpdateOneWithoutInValidReasonHistoriesNestedInput>;
  type?: InputMaybe<EnumInValidTypeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneWithoutInValidReasonHistoriesNestedInput>;
};

export type InValidReasonUpdateWithoutPostInput = {
  card?: InputMaybe<CardUpdateOneWithoutInValidReasonHistoriesNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  originalUserIdStr?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  profitable?: InputMaybe<ProfitableUpdateOneWithoutInValidReasonHistoriesNestedInput>;
  stamp?: InputMaybe<StampUpdateOneWithoutInValidReasonHistoriesNestedInput>;
  type?: InputMaybe<EnumInValidTypeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneWithoutInValidReasonHistoriesNestedInput>;
};

export type InValidReasonUpdateWithoutProfitableInput = {
  card?: InputMaybe<CardUpdateOneWithoutInValidReasonHistoriesNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  originalUserIdStr?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  post?: InputMaybe<PostUpdateOneWithoutInValidReasonHistoriesNestedInput>;
  stamp?: InputMaybe<StampUpdateOneWithoutInValidReasonHistoriesNestedInput>;
  type?: InputMaybe<EnumInValidTypeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneWithoutInValidReasonHistoriesNestedInput>;
};

export type InValidReasonUpdateWithoutStampInput = {
  card?: InputMaybe<CardUpdateOneWithoutInValidReasonHistoriesNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  originalUserIdStr?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  post?: InputMaybe<PostUpdateOneWithoutInValidReasonHistoriesNestedInput>;
  profitable?: InputMaybe<ProfitableUpdateOneWithoutInValidReasonHistoriesNestedInput>;
  type?: InputMaybe<EnumInValidTypeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneWithoutInValidReasonHistoriesNestedInput>;
};

export type InValidReasonUpdateWithoutUserInput = {
  card?: InputMaybe<CardUpdateOneWithoutInValidReasonHistoriesNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  originalUserIdStr?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  post?: InputMaybe<PostUpdateOneWithoutInValidReasonHistoriesNestedInput>;
  profitable?: InputMaybe<ProfitableUpdateOneWithoutInValidReasonHistoriesNestedInput>;
  stamp?: InputMaybe<StampUpdateOneWithoutInValidReasonHistoriesNestedInput>;
  type?: InputMaybe<EnumInValidTypeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type InValidReasonUpsertWithWhereUniqueWithoutCardInput = {
  create: InValidReasonCreateWithoutCardInput;
  update: InValidReasonUpdateWithoutCardInput;
  where: InValidReasonWhereUniqueInput;
};

export type InValidReasonUpsertWithWhereUniqueWithoutPostInput = {
  create: InValidReasonCreateWithoutPostInput;
  update: InValidReasonUpdateWithoutPostInput;
  where: InValidReasonWhereUniqueInput;
};

export type InValidReasonUpsertWithWhereUniqueWithoutProfitableInput = {
  create: InValidReasonCreateWithoutProfitableInput;
  update: InValidReasonUpdateWithoutProfitableInput;
  where: InValidReasonWhereUniqueInput;
};

export type InValidReasonUpsertWithWhereUniqueWithoutStampInput = {
  create: InValidReasonCreateWithoutStampInput;
  update: InValidReasonUpdateWithoutStampInput;
  where: InValidReasonWhereUniqueInput;
};

export type InValidReasonUpsertWithWhereUniqueWithoutUserInput = {
  create: InValidReasonCreateWithoutUserInput;
  update: InValidReasonUpdateWithoutUserInput;
  where: InValidReasonWhereUniqueInput;
};

export type InValidReasonWhereInput = {
  AND?: InputMaybe<Array<InValidReasonWhereInput>>;
  NOT?: InputMaybe<Array<InValidReasonWhereInput>>;
  OR?: InputMaybe<Array<InValidReasonWhereInput>>;
  card?: InputMaybe<CardRelationFilter>;
  cardId?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  description?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<StringFilter>;
  originalUserIdStr?: InputMaybe<StringNullableFilter>;
  post?: InputMaybe<PostRelationFilter>;
  postId?: InputMaybe<StringNullableFilter>;
  profitable?: InputMaybe<ProfitableRelationFilter>;
  profitableId?: InputMaybe<StringNullableFilter>;
  stamp?: InputMaybe<StampRelationFilter>;
  stampId?: InputMaybe<StringNullableFilter>;
  type?: InputMaybe<EnumInValidTypeFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  user?: InputMaybe<UserRelationFilter>;
  userId?: InputMaybe<StringNullableFilter>;
};

export type InValidReasonWhereUniqueInput = {
  cardId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  postId?: InputMaybe<Scalars['String']>;
  profitableId?: InputMaybe<Scalars['String']>;
  stampId?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['String']>;
};

export enum InValidType {
  Card = 'card',
  Post = 'post',
  Profitable = 'profitable',
  Stamp = 'stamp',
  User = 'user'
}

export type IntFieldUpdateOperationsInput = {
  decrement?: InputMaybe<Scalars['Int']>;
  divide?: InputMaybe<Scalars['Int']>;
  increment?: InputMaybe<Scalars['Int']>;
  multiply?: InputMaybe<Scalars['Int']>;
  set?: InputMaybe<Scalars['Int']>;
};

export type IntFilter = {
  equals?: InputMaybe<Scalars['Int']>;
  gt?: InputMaybe<Scalars['Int']>;
  gte?: InputMaybe<Scalars['Int']>;
  in?: InputMaybe<Array<Scalars['Int']>>;
  lt?: InputMaybe<Scalars['Int']>;
  lte?: InputMaybe<Scalars['Int']>;
  not?: InputMaybe<NestedIntFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']>>;
};

export type IntNullableFilter = {
  equals?: InputMaybe<Scalars['Int']>;
  gt?: InputMaybe<Scalars['Int']>;
  gte?: InputMaybe<Scalars['Int']>;
  in?: InputMaybe<Array<Scalars['Int']>>;
  lt?: InputMaybe<Scalars['Int']>;
  lte?: InputMaybe<Scalars['Int']>;
  not?: InputMaybe<NestedIntNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']>>;
};

export type IntNullableWithAggregatesFilter = {
  _avg?: InputMaybe<NestedFloatNullableFilter>;
  _count?: InputMaybe<NestedIntNullableFilter>;
  _max?: InputMaybe<NestedIntNullableFilter>;
  _min?: InputMaybe<NestedIntNullableFilter>;
  _sum?: InputMaybe<NestedIntNullableFilter>;
  equals?: InputMaybe<Scalars['Int']>;
  gt?: InputMaybe<Scalars['Int']>;
  gte?: InputMaybe<Scalars['Int']>;
  in?: InputMaybe<Array<Scalars['Int']>>;
  lt?: InputMaybe<Scalars['Int']>;
  lte?: InputMaybe<Scalars['Int']>;
  not?: InputMaybe<NestedIntNullableWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']>>;
};

export type IntWithAggregatesFilter = {
  _avg?: InputMaybe<NestedFloatFilter>;
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedIntFilter>;
  _min?: InputMaybe<NestedIntFilter>;
  _sum?: InputMaybe<NestedIntFilter>;
  equals?: InputMaybe<Scalars['Int']>;
  gt?: InputMaybe<Scalars['Int']>;
  gte?: InputMaybe<Scalars['Int']>;
  in?: InputMaybe<Array<Scalars['Int']>>;
  lt?: InputMaybe<Scalars['Int']>;
  lte?: InputMaybe<Scalars['Int']>;
  not?: InputMaybe<NestedIntWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']>>;
};

export type Invoice = {
  __typename?: 'Invoice';
  createdAt: Scalars['DateTime'];
  ecloudlife?: Maybe<Ecloudlife>;
  ecloudlifeId?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  number?: Maybe<Scalars['String']>;
  order: Order;
  orderId: Scalars['String'];
  type: InvoiceServiceProvider;
};

export type InvoiceCountAggregate = {
  __typename?: 'InvoiceCountAggregate';
  _all: Scalars['Int'];
  createdAt: Scalars['Int'];
  ecloudlifeId: Scalars['Int'];
  id: Scalars['Int'];
  number: Scalars['Int'];
  orderId: Scalars['Int'];
  type: Scalars['Int'];
};

export type InvoiceCountOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  ecloudlifeId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  number?: InputMaybe<SortOrder>;
  orderId?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
};

export type InvoiceCreateInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  ecloudlife?: InputMaybe<EcloudlifeCreateNestedOneWithoutInvoiceInput>;
  id?: InputMaybe<Scalars['String']>;
  number?: InputMaybe<Scalars['String']>;
  order: OrderCreateNestedOneWithoutInvoiceInput;
  type?: InputMaybe<InvoiceServiceProvider>;
};

export type InvoiceCreateManyEcloudlifeInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  number?: InputMaybe<Scalars['String']>;
  orderId: Scalars['String'];
  type?: InputMaybe<InvoiceServiceProvider>;
};

export type InvoiceCreateManyEcloudlifeInputEnvelope = {
  data: Array<InvoiceCreateManyEcloudlifeInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type InvoiceCreateManyInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  ecloudlifeId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  number?: InputMaybe<Scalars['String']>;
  orderId: Scalars['String'];
  type?: InputMaybe<InvoiceServiceProvider>;
};

export type InvoiceCreateManyOrderInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  ecloudlifeId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  number?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<InvoiceServiceProvider>;
};

export type InvoiceCreateManyOrderInputEnvelope = {
  data: Array<InvoiceCreateManyOrderInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type InvoiceCreateNestedManyWithoutEcloudlifeInput = {
  connect?: InputMaybe<Array<InvoiceWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<InvoiceCreateOrConnectWithoutEcloudlifeInput>>;
  create?: InputMaybe<Array<InvoiceCreateWithoutEcloudlifeInput>>;
  createMany?: InputMaybe<InvoiceCreateManyEcloudlifeInputEnvelope>;
};

export type InvoiceCreateNestedManyWithoutOrderInput = {
  connect?: InputMaybe<Array<InvoiceWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<InvoiceCreateOrConnectWithoutOrderInput>>;
  create?: InputMaybe<Array<InvoiceCreateWithoutOrderInput>>;
  createMany?: InputMaybe<InvoiceCreateManyOrderInputEnvelope>;
};

export type InvoiceCreateOrConnectWithoutEcloudlifeInput = {
  create: InvoiceCreateWithoutEcloudlifeInput;
  where: InvoiceWhereUniqueInput;
};

export type InvoiceCreateOrConnectWithoutOrderInput = {
  create: InvoiceCreateWithoutOrderInput;
  where: InvoiceWhereUniqueInput;
};

export type InvoiceCreateWithoutEcloudlifeInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  number?: InputMaybe<Scalars['String']>;
  order: OrderCreateNestedOneWithoutInvoiceInput;
  type?: InputMaybe<InvoiceServiceProvider>;
};

export type InvoiceCreateWithoutOrderInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  ecloudlife?: InputMaybe<EcloudlifeCreateNestedOneWithoutInvoiceInput>;
  id?: InputMaybe<Scalars['String']>;
  number?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<InvoiceServiceProvider>;
};

export type InvoiceGroupBy = {
  __typename?: 'InvoiceGroupBy';
  _count?: Maybe<InvoiceCountAggregate>;
  _max?: Maybe<InvoiceMaxAggregate>;
  _min?: Maybe<InvoiceMinAggregate>;
  createdAt: Scalars['DateTime'];
  ecloudlifeId?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  number?: Maybe<Scalars['String']>;
  orderId: Scalars['String'];
  type: InvoiceServiceProvider;
};

export type InvoiceListRelationFilter = {
  every?: InputMaybe<InvoiceWhereInput>;
  none?: InputMaybe<InvoiceWhereInput>;
  some?: InputMaybe<InvoiceWhereInput>;
};

export type InvoiceMaxAggregate = {
  __typename?: 'InvoiceMaxAggregate';
  createdAt?: Maybe<Scalars['DateTime']>;
  ecloudlifeId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  orderId?: Maybe<Scalars['String']>;
  type?: Maybe<InvoiceServiceProvider>;
};

export type InvoiceMaxOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  ecloudlifeId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  number?: InputMaybe<SortOrder>;
  orderId?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
};

export type InvoiceMinAggregate = {
  __typename?: 'InvoiceMinAggregate';
  createdAt?: Maybe<Scalars['DateTime']>;
  ecloudlifeId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  orderId?: Maybe<Scalars['String']>;
  type?: Maybe<InvoiceServiceProvider>;
};

export type InvoiceMinOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  ecloudlifeId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  number?: InputMaybe<SortOrder>;
  orderId?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
};

export type InvoiceOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type InvoiceOrderByWithAggregationInput = {
  _count?: InputMaybe<InvoiceCountOrderByAggregateInput>;
  _max?: InputMaybe<InvoiceMaxOrderByAggregateInput>;
  _min?: InputMaybe<InvoiceMinOrderByAggregateInput>;
  createdAt?: InputMaybe<SortOrder>;
  ecloudlifeId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  number?: InputMaybe<SortOrder>;
  orderId?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
};

export type InvoiceOrderByWithRelationInput = {
  createdAt?: InputMaybe<SortOrder>;
  ecloudlife?: InputMaybe<EcloudlifeOrderByWithRelationInput>;
  ecloudlifeId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  number?: InputMaybe<SortOrder>;
  order?: InputMaybe<OrderOrderByWithRelationInput>;
  orderId?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
};

export enum InvoiceScalarFieldEnum {
  CreatedAt = 'createdAt',
  EcloudlifeId = 'ecloudlifeId',
  Id = 'id',
  Number = 'number',
  OrderId = 'orderId',
  Type = 'type'
}

export type InvoiceScalarWhereInput = {
  AND?: InputMaybe<Array<InvoiceScalarWhereInput>>;
  NOT?: InputMaybe<Array<InvoiceScalarWhereInput>>;
  OR?: InputMaybe<Array<InvoiceScalarWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  ecloudlifeId?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<StringFilter>;
  number?: InputMaybe<StringNullableFilter>;
  orderId?: InputMaybe<StringFilter>;
  type?: InputMaybe<EnumInvoiceServiceProviderFilter>;
};

export type InvoiceScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<InvoiceScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<InvoiceScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<InvoiceScalarWhereWithAggregatesInput>>;
  createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  ecloudlifeId?: InputMaybe<StringNullableWithAggregatesFilter>;
  id?: InputMaybe<StringWithAggregatesFilter>;
  number?: InputMaybe<StringNullableWithAggregatesFilter>;
  orderId?: InputMaybe<StringWithAggregatesFilter>;
  type?: InputMaybe<EnumInvoiceServiceProviderWithAggregatesFilter>;
};

export enum InvoiceServiceProvider {
  ECloudLife = 'eCloudLife'
}

export enum InvoiceType {
  BuyerId = 'buyerId',
  Donate = 'donate',
  MemberCarrier = 'memberCarrier',
  NationalIdCarrier = 'nationalIdCarrier',
  PhoneCarrier = 'phoneCarrier'
}

export type InvoiceUpdateInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  ecloudlife?: InputMaybe<EcloudlifeUpdateOneWithoutInvoiceNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  number?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  order?: InputMaybe<OrderUpdateOneRequiredWithoutInvoiceNestedInput>;
  type?: InputMaybe<EnumInvoiceServiceProviderFieldUpdateOperationsInput>;
};

export type InvoiceUpdateManyMutationInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  number?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  type?: InputMaybe<EnumInvoiceServiceProviderFieldUpdateOperationsInput>;
};

export type InvoiceUpdateManyWithWhereWithoutEcloudlifeInput = {
  data: InvoiceUpdateManyMutationInput;
  where: InvoiceScalarWhereInput;
};

export type InvoiceUpdateManyWithWhereWithoutOrderInput = {
  data: InvoiceUpdateManyMutationInput;
  where: InvoiceScalarWhereInput;
};

export type InvoiceUpdateManyWithoutEcloudlifeNestedInput = {
  connect?: InputMaybe<Array<InvoiceWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<InvoiceCreateOrConnectWithoutEcloudlifeInput>>;
  create?: InputMaybe<Array<InvoiceCreateWithoutEcloudlifeInput>>;
  createMany?: InputMaybe<InvoiceCreateManyEcloudlifeInputEnvelope>;
  delete?: InputMaybe<Array<InvoiceWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<InvoiceScalarWhereInput>>;
  disconnect?: InputMaybe<Array<InvoiceWhereUniqueInput>>;
  set?: InputMaybe<Array<InvoiceWhereUniqueInput>>;
  update?: InputMaybe<Array<InvoiceUpdateWithWhereUniqueWithoutEcloudlifeInput>>;
  updateMany?: InputMaybe<Array<InvoiceUpdateManyWithWhereWithoutEcloudlifeInput>>;
  upsert?: InputMaybe<Array<InvoiceUpsertWithWhereUniqueWithoutEcloudlifeInput>>;
};

export type InvoiceUpdateManyWithoutOrderNestedInput = {
  connect?: InputMaybe<Array<InvoiceWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<InvoiceCreateOrConnectWithoutOrderInput>>;
  create?: InputMaybe<Array<InvoiceCreateWithoutOrderInput>>;
  createMany?: InputMaybe<InvoiceCreateManyOrderInputEnvelope>;
  delete?: InputMaybe<Array<InvoiceWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<InvoiceScalarWhereInput>>;
  disconnect?: InputMaybe<Array<InvoiceWhereUniqueInput>>;
  set?: InputMaybe<Array<InvoiceWhereUniqueInput>>;
  update?: InputMaybe<Array<InvoiceUpdateWithWhereUniqueWithoutOrderInput>>;
  updateMany?: InputMaybe<Array<InvoiceUpdateManyWithWhereWithoutOrderInput>>;
  upsert?: InputMaybe<Array<InvoiceUpsertWithWhereUniqueWithoutOrderInput>>;
};

export type InvoiceUpdateWithWhereUniqueWithoutEcloudlifeInput = {
  data: InvoiceUpdateWithoutEcloudlifeInput;
  where: InvoiceWhereUniqueInput;
};

export type InvoiceUpdateWithWhereUniqueWithoutOrderInput = {
  data: InvoiceUpdateWithoutOrderInput;
  where: InvoiceWhereUniqueInput;
};

export type InvoiceUpdateWithoutEcloudlifeInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  number?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  order?: InputMaybe<OrderUpdateOneRequiredWithoutInvoiceNestedInput>;
  type?: InputMaybe<EnumInvoiceServiceProviderFieldUpdateOperationsInput>;
};

export type InvoiceUpdateWithoutOrderInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  ecloudlife?: InputMaybe<EcloudlifeUpdateOneWithoutInvoiceNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  number?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  type?: InputMaybe<EnumInvoiceServiceProviderFieldUpdateOperationsInput>;
};

export type InvoiceUpsertWithWhereUniqueWithoutEcloudlifeInput = {
  create: InvoiceCreateWithoutEcloudlifeInput;
  update: InvoiceUpdateWithoutEcloudlifeInput;
  where: InvoiceWhereUniqueInput;
};

export type InvoiceUpsertWithWhereUniqueWithoutOrderInput = {
  create: InvoiceCreateWithoutOrderInput;
  update: InvoiceUpdateWithoutOrderInput;
  where: InvoiceWhereUniqueInput;
};

export type InvoiceWhereInput = {
  AND?: InputMaybe<Array<InvoiceWhereInput>>;
  NOT?: InputMaybe<Array<InvoiceWhereInput>>;
  OR?: InputMaybe<Array<InvoiceWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  ecloudlife?: InputMaybe<EcloudlifeRelationFilter>;
  ecloudlifeId?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<StringFilter>;
  number?: InputMaybe<StringNullableFilter>;
  order?: InputMaybe<OrderRelationFilter>;
  orderId?: InputMaybe<StringFilter>;
  type?: InputMaybe<EnumInvoiceServiceProviderFilter>;
};

export type InvoiceWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
};

export type JsonNullableFilter = {
  array_contains?: InputMaybe<Scalars['JSON']>;
  array_ends_with?: InputMaybe<Scalars['JSON']>;
  array_starts_with?: InputMaybe<Scalars['JSON']>;
  equals?: InputMaybe<Scalars['JSON']>;
  gt?: InputMaybe<Scalars['JSON']>;
  gte?: InputMaybe<Scalars['JSON']>;
  lt?: InputMaybe<Scalars['JSON']>;
  lte?: InputMaybe<Scalars['JSON']>;
  not?: InputMaybe<Scalars['JSON']>;
  path?: InputMaybe<Array<Scalars['String']>>;
  string_contains?: InputMaybe<Scalars['String']>;
  string_ends_with?: InputMaybe<Scalars['String']>;
  string_starts_with?: InputMaybe<Scalars['String']>;
};

export type JsonNullableWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntNullableFilter>;
  _max?: InputMaybe<NestedJsonNullableFilter>;
  _min?: InputMaybe<NestedJsonNullableFilter>;
  array_contains?: InputMaybe<Scalars['JSON']>;
  array_ends_with?: InputMaybe<Scalars['JSON']>;
  array_starts_with?: InputMaybe<Scalars['JSON']>;
  equals?: InputMaybe<Scalars['JSON']>;
  gt?: InputMaybe<Scalars['JSON']>;
  gte?: InputMaybe<Scalars['JSON']>;
  lt?: InputMaybe<Scalars['JSON']>;
  lte?: InputMaybe<Scalars['JSON']>;
  not?: InputMaybe<Scalars['JSON']>;
  path?: InputMaybe<Array<Scalars['String']>>;
  string_contains?: InputMaybe<Scalars['String']>;
  string_ends_with?: InputMaybe<Scalars['String']>;
  string_starts_with?: InputMaybe<Scalars['String']>;
};

export enum LikeSettingNotification {
  OnlyFollowing = 'onlyFollowing',
  Private = 'private',
  Public = 'public'
}

export type ManualAddr = {
  __typename?: 'ManualAddr';
  _count?: Maybe<ManualAddrCount>;
  address: Address;
  addressId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  name: Scalars['String'];
  owner: User;
  ownerId: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  postPackages: Array<PostPackage>;
  updatedAt: Scalars['DateTime'];
};


export type ManualAddrPostPackagesArgs = {
  cursor?: InputMaybe<PostPackageWhereUniqueInput>;
  distinct?: InputMaybe<Array<PostPackageScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<PostPackageOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PostPackageWhereInput>;
};

export type ManualAddrCount = {
  __typename?: 'ManualAddrCount';
  postPackages: Scalars['Int'];
};


export type ManualAddrCountPostPackagesArgs = {
  where?: InputMaybe<PostPackageWhereInput>;
};

export type ManualAddrCountAggregate = {
  __typename?: 'ManualAddrCountAggregate';
  _all: Scalars['Int'];
  addressId: Scalars['Int'];
  createdAt: Scalars['Int'];
  id: Scalars['Int'];
  name: Scalars['Int'];
  ownerId: Scalars['Int'];
  phone: Scalars['Int'];
  updatedAt: Scalars['Int'];
};

export type ManualAddrCountOrderByAggregateInput = {
  addressId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  ownerId?: InputMaybe<SortOrder>;
  phone?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type ManualAddrCreateInput = {
  address: AddressCreateNestedOneWithoutManualAddrInput;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  owner: UserCreateNestedOneWithoutManualAddrsInput;
  phone?: InputMaybe<Scalars['String']>;
  postPackages?: InputMaybe<PostPackageCreateNestedManyWithoutManualAddrsInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ManualAddrCreateManyInput = {
  addressId: Scalars['String'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  ownerId: Scalars['String'];
  phone?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ManualAddrCreateManyOwnerInput = {
  addressId: Scalars['String'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  phone?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ManualAddrCreateManyOwnerInputEnvelope = {
  data: Array<ManualAddrCreateManyOwnerInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type ManualAddrCreateNestedManyWithoutOwnerInput = {
  connect?: InputMaybe<Array<ManualAddrWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ManualAddrCreateOrConnectWithoutOwnerInput>>;
  create?: InputMaybe<Array<ManualAddrCreateWithoutOwnerInput>>;
  createMany?: InputMaybe<ManualAddrCreateManyOwnerInputEnvelope>;
};

export type ManualAddrCreateNestedManyWithoutPostPackagesInput = {
  connect?: InputMaybe<Array<ManualAddrWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ManualAddrCreateOrConnectWithoutPostPackagesInput>>;
  create?: InputMaybe<Array<ManualAddrCreateWithoutPostPackagesInput>>;
};

export type ManualAddrCreateNestedOneWithoutAddressInput = {
  connect?: InputMaybe<ManualAddrWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ManualAddrCreateOrConnectWithoutAddressInput>;
  create?: InputMaybe<ManualAddrCreateWithoutAddressInput>;
};

export type ManualAddrCreateOrConnectWithoutAddressInput = {
  create: ManualAddrCreateWithoutAddressInput;
  where: ManualAddrWhereUniqueInput;
};

export type ManualAddrCreateOrConnectWithoutOwnerInput = {
  create: ManualAddrCreateWithoutOwnerInput;
  where: ManualAddrWhereUniqueInput;
};

export type ManualAddrCreateOrConnectWithoutPostPackagesInput = {
  create: ManualAddrCreateWithoutPostPackagesInput;
  where: ManualAddrWhereUniqueInput;
};

export type ManualAddrCreateWithoutAddressInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  owner: UserCreateNestedOneWithoutManualAddrsInput;
  phone?: InputMaybe<Scalars['String']>;
  postPackages?: InputMaybe<PostPackageCreateNestedManyWithoutManualAddrsInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ManualAddrCreateWithoutOwnerInput = {
  address: AddressCreateNestedOneWithoutManualAddrInput;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  phone?: InputMaybe<Scalars['String']>;
  postPackages?: InputMaybe<PostPackageCreateNestedManyWithoutManualAddrsInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ManualAddrCreateWithoutPostPackagesInput = {
  address: AddressCreateNestedOneWithoutManualAddrInput;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  owner: UserCreateNestedOneWithoutManualAddrsInput;
  phone?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ManualAddrGroupBy = {
  __typename?: 'ManualAddrGroupBy';
  _count?: Maybe<ManualAddrCountAggregate>;
  _max?: Maybe<ManualAddrMaxAggregate>;
  _min?: Maybe<ManualAddrMinAggregate>;
  addressId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  name: Scalars['String'];
  ownerId: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};

export type ManualAddrListRelationFilter = {
  every?: InputMaybe<ManualAddrWhereInput>;
  none?: InputMaybe<ManualAddrWhereInput>;
  some?: InputMaybe<ManualAddrWhereInput>;
};

export type ManualAddrMaxAggregate = {
  __typename?: 'ManualAddrMaxAggregate';
  addressId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  ownerId?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ManualAddrMaxOrderByAggregateInput = {
  addressId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  ownerId?: InputMaybe<SortOrder>;
  phone?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type ManualAddrMinAggregate = {
  __typename?: 'ManualAddrMinAggregate';
  addressId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  ownerId?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ManualAddrMinOrderByAggregateInput = {
  addressId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  ownerId?: InputMaybe<SortOrder>;
  phone?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type ManualAddrOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type ManualAddrOrderByWithAggregationInput = {
  _count?: InputMaybe<ManualAddrCountOrderByAggregateInput>;
  _max?: InputMaybe<ManualAddrMaxOrderByAggregateInput>;
  _min?: InputMaybe<ManualAddrMinOrderByAggregateInput>;
  addressId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  ownerId?: InputMaybe<SortOrder>;
  phone?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type ManualAddrOrderByWithRelationInput = {
  address?: InputMaybe<AddressOrderByWithRelationInput>;
  addressId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  owner?: InputMaybe<UserOrderByWithRelationInput>;
  ownerId?: InputMaybe<SortOrder>;
  phone?: InputMaybe<SortOrder>;
  postPackages?: InputMaybe<PostPackageOrderByRelationAggregateInput>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type ManualAddrOwnerIdNameCompoundUniqueInput = {
  name: Scalars['String'];
  ownerId: Scalars['String'];
};

export type ManualAddrRelationFilter = {
  is?: InputMaybe<ManualAddrWhereInput>;
  isNot?: InputMaybe<ManualAddrWhereInput>;
};

export enum ManualAddrScalarFieldEnum {
  AddressId = 'addressId',
  CreatedAt = 'createdAt',
  Id = 'id',
  Name = 'name',
  OwnerId = 'ownerId',
  Phone = 'phone',
  UpdatedAt = 'updatedAt'
}

export type ManualAddrScalarWhereInput = {
  AND?: InputMaybe<Array<ManualAddrScalarWhereInput>>;
  NOT?: InputMaybe<Array<ManualAddrScalarWhereInput>>;
  OR?: InputMaybe<Array<ManualAddrScalarWhereInput>>;
  addressId?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringFilter>;
  ownerId?: InputMaybe<StringFilter>;
  phone?: InputMaybe<StringNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type ManualAddrScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<ManualAddrScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<ManualAddrScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<ManualAddrScalarWhereWithAggregatesInput>>;
  addressId?: InputMaybe<StringWithAggregatesFilter>;
  createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  id?: InputMaybe<StringWithAggregatesFilter>;
  name?: InputMaybe<StringWithAggregatesFilter>;
  ownerId?: InputMaybe<StringWithAggregatesFilter>;
  phone?: InputMaybe<StringNullableWithAggregatesFilter>;
  updatedAt?: InputMaybe<DateTimeWithAggregatesFilter>;
};

export type ManualAddrUpdateInput = {
  address?: InputMaybe<AddressUpdateOneRequiredWithoutManualAddrNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  owner?: InputMaybe<UserUpdateOneRequiredWithoutManualAddrsNestedInput>;
  phone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  postPackages?: InputMaybe<PostPackageUpdateManyWithoutManualAddrsNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type ManualAddrUpdateManyMutationInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  phone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type ManualAddrUpdateManyWithWhereWithoutOwnerInput = {
  data: ManualAddrUpdateManyMutationInput;
  where: ManualAddrScalarWhereInput;
};

export type ManualAddrUpdateManyWithWhereWithoutPostPackagesInput = {
  data: ManualAddrUpdateManyMutationInput;
  where: ManualAddrScalarWhereInput;
};

export type ManualAddrUpdateManyWithoutOwnerNestedInput = {
  connect?: InputMaybe<Array<ManualAddrWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ManualAddrCreateOrConnectWithoutOwnerInput>>;
  create?: InputMaybe<Array<ManualAddrCreateWithoutOwnerInput>>;
  createMany?: InputMaybe<ManualAddrCreateManyOwnerInputEnvelope>;
  delete?: InputMaybe<Array<ManualAddrWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ManualAddrScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ManualAddrWhereUniqueInput>>;
  set?: InputMaybe<Array<ManualAddrWhereUniqueInput>>;
  update?: InputMaybe<Array<ManualAddrUpdateWithWhereUniqueWithoutOwnerInput>>;
  updateMany?: InputMaybe<Array<ManualAddrUpdateManyWithWhereWithoutOwnerInput>>;
  upsert?: InputMaybe<Array<ManualAddrUpsertWithWhereUniqueWithoutOwnerInput>>;
};

export type ManualAddrUpdateManyWithoutPostPackagesNestedInput = {
  connect?: InputMaybe<Array<ManualAddrWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ManualAddrCreateOrConnectWithoutPostPackagesInput>>;
  create?: InputMaybe<Array<ManualAddrCreateWithoutPostPackagesInput>>;
  delete?: InputMaybe<Array<ManualAddrWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ManualAddrScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ManualAddrWhereUniqueInput>>;
  set?: InputMaybe<Array<ManualAddrWhereUniqueInput>>;
  update?: InputMaybe<Array<ManualAddrUpdateWithWhereUniqueWithoutPostPackagesInput>>;
  updateMany?: InputMaybe<Array<ManualAddrUpdateManyWithWhereWithoutPostPackagesInput>>;
  upsert?: InputMaybe<Array<ManualAddrUpsertWithWhereUniqueWithoutPostPackagesInput>>;
};

export type ManualAddrUpdateOneWithoutAddressNestedInput = {
  connect?: InputMaybe<ManualAddrWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ManualAddrCreateOrConnectWithoutAddressInput>;
  create?: InputMaybe<ManualAddrCreateWithoutAddressInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<ManualAddrUpdateWithoutAddressInput>;
  upsert?: InputMaybe<ManualAddrUpsertWithoutAddressInput>;
};

export type ManualAddrUpdateWithWhereUniqueWithoutOwnerInput = {
  data: ManualAddrUpdateWithoutOwnerInput;
  where: ManualAddrWhereUniqueInput;
};

export type ManualAddrUpdateWithWhereUniqueWithoutPostPackagesInput = {
  data: ManualAddrUpdateWithoutPostPackagesInput;
  where: ManualAddrWhereUniqueInput;
};

export type ManualAddrUpdateWithoutAddressInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  owner?: InputMaybe<UserUpdateOneRequiredWithoutManualAddrsNestedInput>;
  phone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  postPackages?: InputMaybe<PostPackageUpdateManyWithoutManualAddrsNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type ManualAddrUpdateWithoutOwnerInput = {
  address?: InputMaybe<AddressUpdateOneRequiredWithoutManualAddrNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  phone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  postPackages?: InputMaybe<PostPackageUpdateManyWithoutManualAddrsNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type ManualAddrUpdateWithoutPostPackagesInput = {
  address?: InputMaybe<AddressUpdateOneRequiredWithoutManualAddrNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  owner?: InputMaybe<UserUpdateOneRequiredWithoutManualAddrsNestedInput>;
  phone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type ManualAddrUpsertWithWhereUniqueWithoutOwnerInput = {
  create: ManualAddrCreateWithoutOwnerInput;
  update: ManualAddrUpdateWithoutOwnerInput;
  where: ManualAddrWhereUniqueInput;
};

export type ManualAddrUpsertWithWhereUniqueWithoutPostPackagesInput = {
  create: ManualAddrCreateWithoutPostPackagesInput;
  update: ManualAddrUpdateWithoutPostPackagesInput;
  where: ManualAddrWhereUniqueInput;
};

export type ManualAddrUpsertWithoutAddressInput = {
  create: ManualAddrCreateWithoutAddressInput;
  update: ManualAddrUpdateWithoutAddressInput;
};

export type ManualAddrWhereInput = {
  AND?: InputMaybe<Array<ManualAddrWhereInput>>;
  NOT?: InputMaybe<Array<ManualAddrWhereInput>>;
  OR?: InputMaybe<Array<ManualAddrWhereInput>>;
  address?: InputMaybe<AddressRelationFilter>;
  addressId?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringFilter>;
  owner?: InputMaybe<UserRelationFilter>;
  ownerId?: InputMaybe<StringFilter>;
  phone?: InputMaybe<StringNullableFilter>;
  postPackages?: InputMaybe<PostPackageListRelationFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type ManualAddrWhereUniqueInput = {
  addressId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  ownerId_name?: InputMaybe<ManualAddrOwnerIdNameCompoundUniqueInput>;
};

export type Mutation = {
  __typename?: 'Mutation';
  createManyAddress: AffectedRowsOutput;
  createManyAdvertisement: AffectedRowsOutput;
  createManyAttachment: AffectedRowsOutput;
  createManyBenefitMemo: AffectedRowsOutput;
  createManyBenefitMemoToCoin: AffectedRowsOutput;
  createManyBenefitMemoToOrder: AffectedRowsOutput;
  createManyBenefitTransfer: AffectedRowsOutput;
  createManyCampaignNanhui: AffectedRowsOutput;
  createManyCampainZeczec: AffectedRowsOutput;
  createManyCard: AffectedRowsOutput;
  createManyCartItem: AffectedRowsOutput;
  createManyCoin: AffectedRowsOutput;
  createManyCoinPack: AffectedRowsOutput;
  createManyCollection: AffectedRowsOutput;
  createManyCouponActivity: AffectedRowsOutput;
  createManyCouponEntity: AffectedRowsOutput;
  createManyCouponGroup: AffectedRowsOutput;
  createManyCouponInfo: AffectedRowsOutput;
  createManyCouponUsage: AffectedRowsOutput;
  createManyDeviceToken: AffectedRowsOutput;
  createManyEcloudlife: AffectedRowsOutput;
  createManyEcloudlifeTrack: AffectedRowsOutput;
  createManyEmailTo: AffectedRowsOutput;
  createManyGroup: AffectedRowsOutput;
  createManyInValidReason: AffectedRowsOutput;
  createManyInvoice: AffectedRowsOutput;
  createManyManualAddr: AffectedRowsOutput;
  createManyNotification: AffectedRowsOutput;
  createManyOrder: AffectedRowsOutput;
  createManyOrderDetail: AffectedRowsOutput;
  createManyOrderDetailHistory: AffectedRowsOutput;
  createManyOrderTag: AffectedRowsOutput;
  createManyPayment: AffectedRowsOutput;
  createManyPost: AffectedRowsOutput;
  createManyPostHistory: AffectedRowsOutput;
  createManyPostPackage: AffectedRowsOutput;
  createManyPostTag: AffectedRowsOutput;
  createManyProduct: AffectedRowsOutput;
  createManyProfitable: AffectedRowsOutput;
  createManyProfitableBadget: AffectedRowsOutput;
  createManyQrcode: AffectedRowsOutput;
  createManyRemoteString: AffectedRowsOutput;
  createManyReport: AffectedRowsOutput;
  createManyReportUser: AffectedRowsOutput;
  createManyScenicSpot: AffectedRowsOutput;
  createManySettingNotification: AffectedRowsOutput;
  createManySettingNotificationSuppress: AffectedRowsOutput;
  createManySettingPrivacy: AffectedRowsOutput;
  createManySignature: AffectedRowsOutput;
  createManyStamp: AffectedRowsOutput;
  createManyStoreItem: AffectedRowsOutput;
  createManyStoreItemTag: AffectedRowsOutput;
  createManyStoreItemToCartItem: AffectedRowsOutput;
  createManyStoreRatio: AffectedRowsOutput;
  createManyStoreRatioHistoryItem: AffectedRowsOutput;
  createManyTappay: AffectedRowsOutput;
  createManyUser: AffectedRowsOutput;
  createManyUserFriend: AffectedRowsOutput;
  createManyVirtualAddrMapping: AffectedRowsOutput;
  createManyVirtualLand: AffectedRowsOutput;
  createOneAddress: Address;
  createOneAdvertisement: Advertisement;
  createOneAttachment: Attachment;
  createOneBenefitMemo: BenefitMemo;
  createOneBenefitMemoToCoin: BenefitMemoToCoin;
  createOneBenefitMemoToOrder: BenefitMemoToOrder;
  createOneBenefitTransfer: BenefitTransfer;
  createOneCampaignNanhui: CampaignNanhui;
  createOneCampainZeczec: CampainZeczec;
  createOneCard: Card;
  createOneCartItem: CartItem;
  createOneCoin: Coin;
  createOneCoinPack: CoinPack;
  createOneCollection: Collection;
  createOneCouponActivity: CouponActivity;
  createOneCouponEntity: CouponEntity;
  createOneCouponGroup: CouponGroup;
  createOneCouponInfo: CouponInfo;
  createOneCouponUsage: CouponUsage;
  createOneDeviceToken: DeviceToken;
  createOneEcloudlife: Ecloudlife;
  createOneEcloudlifeTrack: EcloudlifeTrack;
  createOneEmailTo: EmailTo;
  createOneGroup: Group;
  createOneInValidReason: InValidReason;
  createOneInvoice: Invoice;
  createOneManualAddr: ManualAddr;
  createOneNotification: Notification;
  createOneOrder: Order;
  createOneOrderDetail: OrderDetail;
  createOneOrderDetailHistory: OrderDetailHistory;
  createOneOrderTag: OrderTag;
  createOnePayment: Payment;
  createOnePost: Post;
  createOnePostHistory: PostHistory;
  createOnePostPackage: PostPackage;
  createOnePostTag: PostTag;
  createOneProduct: Product;
  createOneProfitable: Profitable;
  createOneProfitableBadget: ProfitableBadget;
  createOneQrcode: Qrcode;
  createOneRemoteString: RemoteString;
  createOneReport: Report;
  createOneReportUser: ReportUser;
  createOneScenicSpot: ScenicSpot;
  createOneSettingNotification: SettingNotification;
  createOneSettingNotificationSuppress: SettingNotificationSuppress;
  createOneSettingPrivacy: SettingPrivacy;
  createOneSignature: Signature;
  createOneStamp: Stamp;
  createOneStoreItem: StoreItem;
  createOneStoreItemTag: StoreItemTag;
  createOneStoreItemToCartItem: StoreItemToCartItem;
  createOneStoreRatio: StoreRatio;
  createOneStoreRatioHistoryItem: StoreRatioHistoryItem;
  createOneTappay: Tappay;
  createOneUser: User;
  createOneUserFriend: UserFriend;
  createOneVirtualAddrMapping: VirtualAddrMapping;
  createOneVirtualLand: VirtualLand;
  deleteManyAddress: AffectedRowsOutput;
  deleteManyAdvertisement: AffectedRowsOutput;
  deleteManyAttachment: AffectedRowsOutput;
  deleteManyBenefitMemo: AffectedRowsOutput;
  deleteManyBenefitMemoToCoin: AffectedRowsOutput;
  deleteManyBenefitMemoToOrder: AffectedRowsOutput;
  deleteManyBenefitTransfer: AffectedRowsOutput;
  deleteManyCampaignNanhui: AffectedRowsOutput;
  deleteManyCampainZeczec: AffectedRowsOutput;
  deleteManyCard: AffectedRowsOutput;
  deleteManyCartItem: AffectedRowsOutput;
  deleteManyCoin: AffectedRowsOutput;
  deleteManyCoinPack: AffectedRowsOutput;
  deleteManyCollection: AffectedRowsOutput;
  deleteManyCouponActivity: AffectedRowsOutput;
  deleteManyCouponEntity: AffectedRowsOutput;
  deleteManyCouponGroup: AffectedRowsOutput;
  deleteManyCouponInfo: AffectedRowsOutput;
  deleteManyCouponUsage: AffectedRowsOutput;
  deleteManyDeviceToken: AffectedRowsOutput;
  deleteManyEcloudlife: AffectedRowsOutput;
  deleteManyEcloudlifeTrack: AffectedRowsOutput;
  deleteManyEmailTo: AffectedRowsOutput;
  deleteManyGroup: AffectedRowsOutput;
  deleteManyInValidReason: AffectedRowsOutput;
  deleteManyInvoice: AffectedRowsOutput;
  deleteManyManualAddr: AffectedRowsOutput;
  deleteManyNotification: AffectedRowsOutput;
  deleteManyOrder: AffectedRowsOutput;
  deleteManyOrderDetail: AffectedRowsOutput;
  deleteManyOrderDetailHistory: AffectedRowsOutput;
  deleteManyOrderTag: AffectedRowsOutput;
  deleteManyPayment: AffectedRowsOutput;
  deleteManyPost: AffectedRowsOutput;
  deleteManyPostHistory: AffectedRowsOutput;
  deleteManyPostPackage: AffectedRowsOutput;
  deleteManyPostTag: AffectedRowsOutput;
  deleteManyProduct: AffectedRowsOutput;
  deleteManyProfitable: AffectedRowsOutput;
  deleteManyProfitableBadget: AffectedRowsOutput;
  deleteManyQrcode: AffectedRowsOutput;
  deleteManyRemoteString: AffectedRowsOutput;
  deleteManyReport: AffectedRowsOutput;
  deleteManyReportUser: AffectedRowsOutput;
  deleteManyScenicSpot: AffectedRowsOutput;
  deleteManySettingNotification: AffectedRowsOutput;
  deleteManySettingNotificationSuppress: AffectedRowsOutput;
  deleteManySettingPrivacy: AffectedRowsOutput;
  deleteManySignature: AffectedRowsOutput;
  deleteManyStamp: AffectedRowsOutput;
  deleteManyStoreItem: AffectedRowsOutput;
  deleteManyStoreItemTag: AffectedRowsOutput;
  deleteManyStoreItemToCartItem: AffectedRowsOutput;
  deleteManyStoreRatio: AffectedRowsOutput;
  deleteManyStoreRatioHistoryItem: AffectedRowsOutput;
  deleteManyTappay: AffectedRowsOutput;
  deleteManyUser: AffectedRowsOutput;
  deleteManyUserFriend: AffectedRowsOutput;
  deleteManyVirtualAddrMapping: AffectedRowsOutput;
  deleteManyVirtualLand: AffectedRowsOutput;
  deleteOneAddress?: Maybe<Address>;
  deleteOneAdvertisement?: Maybe<Advertisement>;
  deleteOneAttachment?: Maybe<Attachment>;
  deleteOneBenefitMemo?: Maybe<BenefitMemo>;
  deleteOneBenefitMemoToCoin?: Maybe<BenefitMemoToCoin>;
  deleteOneBenefitMemoToOrder?: Maybe<BenefitMemoToOrder>;
  deleteOneBenefitTransfer?: Maybe<BenefitTransfer>;
  deleteOneCampaignNanhui?: Maybe<CampaignNanhui>;
  deleteOneCampainZeczec?: Maybe<CampainZeczec>;
  deleteOneCard?: Maybe<Card>;
  deleteOneCartItem?: Maybe<CartItem>;
  deleteOneCoin?: Maybe<Coin>;
  deleteOneCoinPack?: Maybe<CoinPack>;
  deleteOneCollection?: Maybe<Collection>;
  deleteOneCouponActivity?: Maybe<CouponActivity>;
  deleteOneCouponEntity?: Maybe<CouponEntity>;
  deleteOneCouponGroup?: Maybe<CouponGroup>;
  deleteOneCouponInfo?: Maybe<CouponInfo>;
  deleteOneCouponUsage?: Maybe<CouponUsage>;
  deleteOneDeviceToken?: Maybe<DeviceToken>;
  deleteOneEcloudlife?: Maybe<Ecloudlife>;
  deleteOneEcloudlifeTrack?: Maybe<EcloudlifeTrack>;
  deleteOneEmailTo?: Maybe<EmailTo>;
  deleteOneGroup?: Maybe<Group>;
  deleteOneInValidReason?: Maybe<InValidReason>;
  deleteOneInvoice?: Maybe<Invoice>;
  deleteOneManualAddr?: Maybe<ManualAddr>;
  deleteOneNotification?: Maybe<Notification>;
  deleteOneOrder?: Maybe<Order>;
  deleteOneOrderDetail?: Maybe<OrderDetail>;
  deleteOneOrderDetailHistory?: Maybe<OrderDetailHistory>;
  deleteOneOrderTag?: Maybe<OrderTag>;
  deleteOnePayment?: Maybe<Payment>;
  deleteOnePost?: Maybe<Post>;
  deleteOnePostHistory?: Maybe<PostHistory>;
  deleteOnePostPackage?: Maybe<PostPackage>;
  deleteOnePostTag?: Maybe<PostTag>;
  deleteOneProduct?: Maybe<Product>;
  deleteOneProfitable?: Maybe<Profitable>;
  deleteOneProfitableBadget?: Maybe<ProfitableBadget>;
  deleteOneQrcode?: Maybe<Qrcode>;
  deleteOneRemoteString?: Maybe<RemoteString>;
  deleteOneReport?: Maybe<Report>;
  deleteOneReportUser?: Maybe<ReportUser>;
  deleteOneScenicSpot?: Maybe<ScenicSpot>;
  deleteOneSettingNotification?: Maybe<SettingNotification>;
  deleteOneSettingNotificationSuppress?: Maybe<SettingNotificationSuppress>;
  deleteOneSettingPrivacy?: Maybe<SettingPrivacy>;
  deleteOneSignature?: Maybe<Signature>;
  deleteOneStamp?: Maybe<Stamp>;
  deleteOneStoreItem?: Maybe<StoreItem>;
  deleteOneStoreItemTag?: Maybe<StoreItemTag>;
  deleteOneStoreItemToCartItem?: Maybe<StoreItemToCartItem>;
  deleteOneStoreRatio?: Maybe<StoreRatio>;
  deleteOneStoreRatioHistoryItem?: Maybe<StoreRatioHistoryItem>;
  deleteOneTappay?: Maybe<Tappay>;
  deleteOneUser?: Maybe<User>;
  deleteOneUserFriend?: Maybe<UserFriend>;
  deleteOneVirtualAddrMapping?: Maybe<VirtualAddrMapping>;
  deleteOneVirtualLand?: Maybe<VirtualLand>;
  updateManyAddress: AffectedRowsOutput;
  updateManyAdvertisement: AffectedRowsOutput;
  updateManyAttachment: AffectedRowsOutput;
  updateManyBenefitMemo: AffectedRowsOutput;
  updateManyBenefitMemoToCoin: AffectedRowsOutput;
  updateManyBenefitMemoToOrder: AffectedRowsOutput;
  updateManyBenefitTransfer: AffectedRowsOutput;
  updateManyCampaignNanhui: AffectedRowsOutput;
  updateManyCampainZeczec: AffectedRowsOutput;
  updateManyCard: AffectedRowsOutput;
  updateManyCartItem: AffectedRowsOutput;
  updateManyCoin: AffectedRowsOutput;
  updateManyCoinPack: AffectedRowsOutput;
  updateManyCollection: AffectedRowsOutput;
  updateManyCouponActivity: AffectedRowsOutput;
  updateManyCouponEntity: AffectedRowsOutput;
  updateManyCouponGroup: AffectedRowsOutput;
  updateManyCouponInfo: AffectedRowsOutput;
  updateManyCouponUsage: AffectedRowsOutput;
  updateManyDeviceToken: AffectedRowsOutput;
  updateManyEcloudlife: AffectedRowsOutput;
  updateManyEcloudlifeTrack: AffectedRowsOutput;
  updateManyEmailTo: AffectedRowsOutput;
  updateManyGroup: AffectedRowsOutput;
  updateManyInValidReason: AffectedRowsOutput;
  updateManyInvoice: AffectedRowsOutput;
  updateManyManualAddr: AffectedRowsOutput;
  updateManyNotification: AffectedRowsOutput;
  updateManyOrder: AffectedRowsOutput;
  updateManyOrderDetail: AffectedRowsOutput;
  updateManyOrderDetailHistory: AffectedRowsOutput;
  updateManyOrderTag: AffectedRowsOutput;
  updateManyPayment: AffectedRowsOutput;
  updateManyPost: AffectedRowsOutput;
  updateManyPostHistory: AffectedRowsOutput;
  updateManyPostPackage: AffectedRowsOutput;
  updateManyPostTag: AffectedRowsOutput;
  updateManyProduct: AffectedRowsOutput;
  updateManyProfitable: AffectedRowsOutput;
  updateManyProfitableBadget: AffectedRowsOutput;
  updateManyQrcode: AffectedRowsOutput;
  updateManyRemoteString: AffectedRowsOutput;
  updateManyReport: AffectedRowsOutput;
  updateManyReportUser: AffectedRowsOutput;
  updateManyScenicSpot: AffectedRowsOutput;
  updateManySettingNotification: AffectedRowsOutput;
  updateManySettingNotificationSuppress: AffectedRowsOutput;
  updateManySettingPrivacy: AffectedRowsOutput;
  updateManySignature: AffectedRowsOutput;
  updateManyStamp: AffectedRowsOutput;
  updateManyStoreItem: AffectedRowsOutput;
  updateManyStoreItemTag: AffectedRowsOutput;
  updateManyStoreItemToCartItem: AffectedRowsOutput;
  updateManyStoreRatio: AffectedRowsOutput;
  updateManyStoreRatioHistoryItem: AffectedRowsOutput;
  updateManyTappay: AffectedRowsOutput;
  updateManyUser: AffectedRowsOutput;
  updateManyUserFriend: AffectedRowsOutput;
  updateManyVirtualAddrMapping: AffectedRowsOutput;
  updateManyVirtualLand: AffectedRowsOutput;
  updateOneAddress?: Maybe<Address>;
  updateOneAdvertisement?: Maybe<Advertisement>;
  updateOneAttachment?: Maybe<Attachment>;
  updateOneBenefitMemo?: Maybe<BenefitMemo>;
  updateOneBenefitMemoToCoin?: Maybe<BenefitMemoToCoin>;
  updateOneBenefitMemoToOrder?: Maybe<BenefitMemoToOrder>;
  updateOneBenefitTransfer?: Maybe<BenefitTransfer>;
  updateOneCampaignNanhui?: Maybe<CampaignNanhui>;
  updateOneCampainZeczec?: Maybe<CampainZeczec>;
  updateOneCard?: Maybe<Card>;
  updateOneCartItem?: Maybe<CartItem>;
  updateOneCoin?: Maybe<Coin>;
  updateOneCoinPack?: Maybe<CoinPack>;
  updateOneCollection?: Maybe<Collection>;
  updateOneCouponActivity?: Maybe<CouponActivity>;
  updateOneCouponEntity?: Maybe<CouponEntity>;
  updateOneCouponGroup?: Maybe<CouponGroup>;
  updateOneCouponInfo?: Maybe<CouponInfo>;
  updateOneCouponUsage?: Maybe<CouponUsage>;
  updateOneDeviceToken?: Maybe<DeviceToken>;
  updateOneEcloudlife?: Maybe<Ecloudlife>;
  updateOneEcloudlifeTrack?: Maybe<EcloudlifeTrack>;
  updateOneEmailTo?: Maybe<EmailTo>;
  updateOneGroup?: Maybe<Group>;
  updateOneInValidReason?: Maybe<InValidReason>;
  updateOneInvoice?: Maybe<Invoice>;
  updateOneManualAddr?: Maybe<ManualAddr>;
  updateOneNotification?: Maybe<Notification>;
  updateOneOrder?: Maybe<Order>;
  updateOneOrderDetail?: Maybe<OrderDetail>;
  updateOneOrderDetailHistory?: Maybe<OrderDetailHistory>;
  updateOneOrderTag?: Maybe<OrderTag>;
  updateOnePayment?: Maybe<Payment>;
  updateOnePost?: Maybe<Post>;
  updateOnePostHistory?: Maybe<PostHistory>;
  updateOnePostPackage?: Maybe<PostPackage>;
  updateOnePostTag?: Maybe<PostTag>;
  updateOneProduct?: Maybe<Product>;
  updateOneProfitable?: Maybe<Profitable>;
  updateOneProfitableBadget?: Maybe<ProfitableBadget>;
  updateOneQrcode?: Maybe<Qrcode>;
  updateOneRemoteString?: Maybe<RemoteString>;
  updateOneReport?: Maybe<Report>;
  updateOneReportUser?: Maybe<ReportUser>;
  updateOneScenicSpot?: Maybe<ScenicSpot>;
  updateOneSettingNotification?: Maybe<SettingNotification>;
  updateOneSettingNotificationSuppress?: Maybe<SettingNotificationSuppress>;
  updateOneSettingPrivacy?: Maybe<SettingPrivacy>;
  updateOneSignature?: Maybe<Signature>;
  updateOneStamp?: Maybe<Stamp>;
  updateOneStoreItem?: Maybe<StoreItem>;
  updateOneStoreItemTag?: Maybe<StoreItemTag>;
  updateOneStoreItemToCartItem?: Maybe<StoreItemToCartItem>;
  updateOneStoreRatio?: Maybe<StoreRatio>;
  updateOneStoreRatioHistoryItem?: Maybe<StoreRatioHistoryItem>;
  updateOneTappay?: Maybe<Tappay>;
  updateOneUser?: Maybe<User>;
  updateOneUserFriend?: Maybe<UserFriend>;
  updateOneVirtualAddrMapping?: Maybe<VirtualAddrMapping>;
  updateOneVirtualLand?: Maybe<VirtualLand>;
  upsertOneAddress: Address;
  upsertOneAdvertisement: Advertisement;
  upsertOneAttachment: Attachment;
  upsertOneBenefitMemo: BenefitMemo;
  upsertOneBenefitMemoToCoin: BenefitMemoToCoin;
  upsertOneBenefitMemoToOrder: BenefitMemoToOrder;
  upsertOneBenefitTransfer: BenefitTransfer;
  upsertOneCampaignNanhui: CampaignNanhui;
  upsertOneCampainZeczec: CampainZeczec;
  upsertOneCard: Card;
  upsertOneCartItem: CartItem;
  upsertOneCoin: Coin;
  upsertOneCoinPack: CoinPack;
  upsertOneCollection: Collection;
  upsertOneCouponActivity: CouponActivity;
  upsertOneCouponEntity: CouponEntity;
  upsertOneCouponGroup: CouponGroup;
  upsertOneCouponInfo: CouponInfo;
  upsertOneCouponUsage: CouponUsage;
  upsertOneDeviceToken: DeviceToken;
  upsertOneEcloudlife: Ecloudlife;
  upsertOneEcloudlifeTrack: EcloudlifeTrack;
  upsertOneEmailTo: EmailTo;
  upsertOneGroup: Group;
  upsertOneInValidReason: InValidReason;
  upsertOneInvoice: Invoice;
  upsertOneManualAddr: ManualAddr;
  upsertOneNotification: Notification;
  upsertOneOrder: Order;
  upsertOneOrderDetail: OrderDetail;
  upsertOneOrderDetailHistory: OrderDetailHistory;
  upsertOneOrderTag: OrderTag;
  upsertOnePayment: Payment;
  upsertOnePost: Post;
  upsertOnePostHistory: PostHistory;
  upsertOnePostPackage: PostPackage;
  upsertOnePostTag: PostTag;
  upsertOneProduct: Product;
  upsertOneProfitable: Profitable;
  upsertOneProfitableBadget: ProfitableBadget;
  upsertOneQrcode: Qrcode;
  upsertOneRemoteString: RemoteString;
  upsertOneReport: Report;
  upsertOneReportUser: ReportUser;
  upsertOneScenicSpot: ScenicSpot;
  upsertOneSettingNotification: SettingNotification;
  upsertOneSettingNotificationSuppress: SettingNotificationSuppress;
  upsertOneSettingPrivacy: SettingPrivacy;
  upsertOneSignature: Signature;
  upsertOneStamp: Stamp;
  upsertOneStoreItem: StoreItem;
  upsertOneStoreItemTag: StoreItemTag;
  upsertOneStoreItemToCartItem: StoreItemToCartItem;
  upsertOneStoreRatio: StoreRatio;
  upsertOneStoreRatioHistoryItem: StoreRatioHistoryItem;
  upsertOneTappay: Tappay;
  upsertOneUser: User;
  upsertOneUserFriend: UserFriend;
  upsertOneVirtualAddrMapping: VirtualAddrMapping;
  upsertOneVirtualLand: VirtualLand;
};


export type MutationCreateManyAddressArgs = {
  data: Array<AddressCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};


export type MutationCreateManyAdvertisementArgs = {
  data: Array<AdvertisementCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};


export type MutationCreateManyAttachmentArgs = {
  data: Array<AttachmentCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};


export type MutationCreateManyBenefitMemoArgs = {
  data: Array<BenefitMemoCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};


export type MutationCreateManyBenefitMemoToCoinArgs = {
  data: Array<BenefitMemoToCoinCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};


export type MutationCreateManyBenefitMemoToOrderArgs = {
  data: Array<BenefitMemoToOrderCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};


export type MutationCreateManyBenefitTransferArgs = {
  data: Array<BenefitTransferCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};


export type MutationCreateManyCampaignNanhuiArgs = {
  data: Array<CampaignNanhuiCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};


export type MutationCreateManyCampainZeczecArgs = {
  data: Array<CampainZeczecCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};


export type MutationCreateManyCardArgs = {
  data: Array<CardCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};


export type MutationCreateManyCartItemArgs = {
  data: Array<CartItemCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};


export type MutationCreateManyCoinArgs = {
  data: Array<CoinCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};


export type MutationCreateManyCoinPackArgs = {
  data: Array<CoinPackCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};


export type MutationCreateManyCollectionArgs = {
  data: Array<CollectionCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};


export type MutationCreateManyCouponActivityArgs = {
  data: Array<CouponActivityCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};


export type MutationCreateManyCouponEntityArgs = {
  data: Array<CouponEntityCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};


export type MutationCreateManyCouponGroupArgs = {
  data: Array<CouponGroupCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};


export type MutationCreateManyCouponInfoArgs = {
  data: Array<CouponInfoCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};


export type MutationCreateManyCouponUsageArgs = {
  data: Array<CouponUsageCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};


export type MutationCreateManyDeviceTokenArgs = {
  data: Array<DeviceTokenCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};


export type MutationCreateManyEcloudlifeArgs = {
  data: Array<EcloudlifeCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};


export type MutationCreateManyEcloudlifeTrackArgs = {
  data: Array<EcloudlifeTrackCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};


export type MutationCreateManyEmailToArgs = {
  data: Array<EmailToCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};


export type MutationCreateManyGroupArgs = {
  data: Array<GroupCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};


export type MutationCreateManyInValidReasonArgs = {
  data: Array<InValidReasonCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};


export type MutationCreateManyInvoiceArgs = {
  data: Array<InvoiceCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};


export type MutationCreateManyManualAddrArgs = {
  data: Array<ManualAddrCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};


export type MutationCreateManyNotificationArgs = {
  data: Array<NotificationCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};


export type MutationCreateManyOrderArgs = {
  data: Array<OrderCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};


export type MutationCreateManyOrderDetailArgs = {
  data: Array<OrderDetailCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};


export type MutationCreateManyOrderDetailHistoryArgs = {
  data: Array<OrderDetailHistoryCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};


export type MutationCreateManyOrderTagArgs = {
  data: Array<OrderTagCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};


export type MutationCreateManyPaymentArgs = {
  data: Array<PaymentCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};


export type MutationCreateManyPostArgs = {
  data: Array<PostCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};


export type MutationCreateManyPostHistoryArgs = {
  data: Array<PostHistoryCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};


export type MutationCreateManyPostPackageArgs = {
  data: Array<PostPackageCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};


export type MutationCreateManyPostTagArgs = {
  data: Array<PostTagCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};


export type MutationCreateManyProductArgs = {
  data: Array<ProductCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};


export type MutationCreateManyProfitableArgs = {
  data: Array<ProfitableCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};


export type MutationCreateManyProfitableBadgetArgs = {
  data: Array<ProfitableBadgetCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};


export type MutationCreateManyQrcodeArgs = {
  data: Array<QrcodeCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};


export type MutationCreateManyRemoteStringArgs = {
  data: Array<RemoteStringCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};


export type MutationCreateManyReportArgs = {
  data: Array<ReportCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};


export type MutationCreateManyReportUserArgs = {
  data: Array<ReportUserCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};


export type MutationCreateManyScenicSpotArgs = {
  data: Array<ScenicSpotCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};


export type MutationCreateManySettingNotificationArgs = {
  data: Array<SettingNotificationCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};


export type MutationCreateManySettingNotificationSuppressArgs = {
  data: Array<SettingNotificationSuppressCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};


export type MutationCreateManySettingPrivacyArgs = {
  data: Array<SettingPrivacyCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};


export type MutationCreateManySignatureArgs = {
  data: Array<SignatureCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};


export type MutationCreateManyStampArgs = {
  data: Array<StampCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};


export type MutationCreateManyStoreItemArgs = {
  data: Array<StoreItemCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};


export type MutationCreateManyStoreItemTagArgs = {
  data: Array<StoreItemTagCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};


export type MutationCreateManyStoreItemToCartItemArgs = {
  data: Array<StoreItemToCartItemCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};


export type MutationCreateManyStoreRatioArgs = {
  data: Array<StoreRatioCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};


export type MutationCreateManyStoreRatioHistoryItemArgs = {
  data: Array<StoreRatioHistoryItemCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};


export type MutationCreateManyTappayArgs = {
  data: Array<TappayCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};


export type MutationCreateManyUserArgs = {
  data: Array<UserCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};


export type MutationCreateManyUserFriendArgs = {
  data: Array<UserFriendCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};


export type MutationCreateManyVirtualAddrMappingArgs = {
  data: Array<VirtualAddrMappingCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};


export type MutationCreateManyVirtualLandArgs = {
  data: Array<VirtualLandCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};


export type MutationCreateOneAddressArgs = {
  data: AddressCreateInput;
};


export type MutationCreateOneAdvertisementArgs = {
  data: AdvertisementCreateInput;
};


export type MutationCreateOneAttachmentArgs = {
  data: AttachmentCreateInput;
};


export type MutationCreateOneBenefitMemoArgs = {
  data: BenefitMemoCreateInput;
};


export type MutationCreateOneBenefitMemoToCoinArgs = {
  data: BenefitMemoToCoinCreateInput;
};


export type MutationCreateOneBenefitMemoToOrderArgs = {
  data: BenefitMemoToOrderCreateInput;
};


export type MutationCreateOneBenefitTransferArgs = {
  data: BenefitTransferCreateInput;
};


export type MutationCreateOneCampaignNanhuiArgs = {
  data: CampaignNanhuiCreateInput;
};


export type MutationCreateOneCampainZeczecArgs = {
  data: CampainZeczecCreateInput;
};


export type MutationCreateOneCardArgs = {
  data: CardCreateInput;
};


export type MutationCreateOneCartItemArgs = {
  data: CartItemCreateInput;
};


export type MutationCreateOneCoinArgs = {
  data: CoinCreateInput;
};


export type MutationCreateOneCoinPackArgs = {
  data: CoinPackCreateInput;
};


export type MutationCreateOneCollectionArgs = {
  data: CollectionCreateInput;
};


export type MutationCreateOneCouponActivityArgs = {
  data: CouponActivityCreateInput;
};


export type MutationCreateOneCouponEntityArgs = {
  data: CouponEntityCreateInput;
};


export type MutationCreateOneCouponGroupArgs = {
  data: CouponGroupCreateInput;
};


export type MutationCreateOneCouponInfoArgs = {
  data: CouponInfoCreateInput;
};


export type MutationCreateOneCouponUsageArgs = {
  data: CouponUsageCreateInput;
};


export type MutationCreateOneDeviceTokenArgs = {
  data: DeviceTokenCreateInput;
};


export type MutationCreateOneEcloudlifeArgs = {
  data: EcloudlifeCreateInput;
};


export type MutationCreateOneEcloudlifeTrackArgs = {
  data: EcloudlifeTrackCreateInput;
};


export type MutationCreateOneEmailToArgs = {
  data: EmailToCreateInput;
};


export type MutationCreateOneGroupArgs = {
  data: GroupCreateInput;
};


export type MutationCreateOneInValidReasonArgs = {
  data: InValidReasonCreateInput;
};


export type MutationCreateOneInvoiceArgs = {
  data: InvoiceCreateInput;
};


export type MutationCreateOneManualAddrArgs = {
  data: ManualAddrCreateInput;
};


export type MutationCreateOneNotificationArgs = {
  data: NotificationCreateInput;
};


export type MutationCreateOneOrderArgs = {
  data: OrderCreateInput;
};


export type MutationCreateOneOrderDetailArgs = {
  data: OrderDetailCreateInput;
};


export type MutationCreateOneOrderDetailHistoryArgs = {
  data: OrderDetailHistoryCreateInput;
};


export type MutationCreateOneOrderTagArgs = {
  data: OrderTagCreateInput;
};


export type MutationCreateOnePaymentArgs = {
  data: PaymentCreateInput;
};


export type MutationCreateOnePostArgs = {
  data: PostCreateInput;
};


export type MutationCreateOnePostHistoryArgs = {
  data: PostHistoryCreateInput;
};


export type MutationCreateOnePostPackageArgs = {
  data: PostPackageCreateInput;
};


export type MutationCreateOnePostTagArgs = {
  data: PostTagCreateInput;
};


export type MutationCreateOneProductArgs = {
  data: ProductCreateInput;
};


export type MutationCreateOneProfitableArgs = {
  data: ProfitableCreateInput;
};


export type MutationCreateOneProfitableBadgetArgs = {
  data: ProfitableBadgetCreateInput;
};


export type MutationCreateOneQrcodeArgs = {
  data: QrcodeCreateInput;
};


export type MutationCreateOneRemoteStringArgs = {
  data: RemoteStringCreateInput;
};


export type MutationCreateOneReportArgs = {
  data: ReportCreateInput;
};


export type MutationCreateOneReportUserArgs = {
  data: ReportUserCreateInput;
};


export type MutationCreateOneScenicSpotArgs = {
  data: ScenicSpotCreateInput;
};


export type MutationCreateOneSettingNotificationArgs = {
  data: SettingNotificationCreateInput;
};


export type MutationCreateOneSettingNotificationSuppressArgs = {
  data: SettingNotificationSuppressCreateInput;
};


export type MutationCreateOneSettingPrivacyArgs = {
  data: SettingPrivacyCreateInput;
};


export type MutationCreateOneSignatureArgs = {
  data: SignatureCreateInput;
};


export type MutationCreateOneStampArgs = {
  data: StampCreateInput;
};


export type MutationCreateOneStoreItemArgs = {
  data: StoreItemCreateInput;
};


export type MutationCreateOneStoreItemTagArgs = {
  data: StoreItemTagCreateInput;
};


export type MutationCreateOneStoreItemToCartItemArgs = {
  data: StoreItemToCartItemCreateInput;
};


export type MutationCreateOneStoreRatioArgs = {
  data: StoreRatioCreateInput;
};


export type MutationCreateOneStoreRatioHistoryItemArgs = {
  data: StoreRatioHistoryItemCreateInput;
};


export type MutationCreateOneTappayArgs = {
  data: TappayCreateInput;
};


export type MutationCreateOneUserArgs = {
  data: UserCreateInput;
};


export type MutationCreateOneUserFriendArgs = {
  data: UserFriendCreateInput;
};


export type MutationCreateOneVirtualAddrMappingArgs = {
  data: VirtualAddrMappingCreateInput;
};


export type MutationCreateOneVirtualLandArgs = {
  data: VirtualLandCreateInput;
};


export type MutationDeleteManyAddressArgs = {
  where?: InputMaybe<AddressWhereInput>;
};


export type MutationDeleteManyAdvertisementArgs = {
  where?: InputMaybe<AdvertisementWhereInput>;
};


export type MutationDeleteManyAttachmentArgs = {
  where?: InputMaybe<AttachmentWhereInput>;
};


export type MutationDeleteManyBenefitMemoArgs = {
  where?: InputMaybe<BenefitMemoWhereInput>;
};


export type MutationDeleteManyBenefitMemoToCoinArgs = {
  where?: InputMaybe<BenefitMemoToCoinWhereInput>;
};


export type MutationDeleteManyBenefitMemoToOrderArgs = {
  where?: InputMaybe<BenefitMemoToOrderWhereInput>;
};


export type MutationDeleteManyBenefitTransferArgs = {
  where?: InputMaybe<BenefitTransferWhereInput>;
};


export type MutationDeleteManyCampaignNanhuiArgs = {
  where?: InputMaybe<CampaignNanhuiWhereInput>;
};


export type MutationDeleteManyCampainZeczecArgs = {
  where?: InputMaybe<CampainZeczecWhereInput>;
};


export type MutationDeleteManyCardArgs = {
  where?: InputMaybe<CardWhereInput>;
};


export type MutationDeleteManyCartItemArgs = {
  where?: InputMaybe<CartItemWhereInput>;
};


export type MutationDeleteManyCoinArgs = {
  where?: InputMaybe<CoinWhereInput>;
};


export type MutationDeleteManyCoinPackArgs = {
  where?: InputMaybe<CoinPackWhereInput>;
};


export type MutationDeleteManyCollectionArgs = {
  where?: InputMaybe<CollectionWhereInput>;
};


export type MutationDeleteManyCouponActivityArgs = {
  where?: InputMaybe<CouponActivityWhereInput>;
};


export type MutationDeleteManyCouponEntityArgs = {
  where?: InputMaybe<CouponEntityWhereInput>;
};


export type MutationDeleteManyCouponGroupArgs = {
  where?: InputMaybe<CouponGroupWhereInput>;
};


export type MutationDeleteManyCouponInfoArgs = {
  where?: InputMaybe<CouponInfoWhereInput>;
};


export type MutationDeleteManyCouponUsageArgs = {
  where?: InputMaybe<CouponUsageWhereInput>;
};


export type MutationDeleteManyDeviceTokenArgs = {
  where?: InputMaybe<DeviceTokenWhereInput>;
};


export type MutationDeleteManyEcloudlifeArgs = {
  where?: InputMaybe<EcloudlifeWhereInput>;
};


export type MutationDeleteManyEcloudlifeTrackArgs = {
  where?: InputMaybe<EcloudlifeTrackWhereInput>;
};


export type MutationDeleteManyEmailToArgs = {
  where?: InputMaybe<EmailToWhereInput>;
};


export type MutationDeleteManyGroupArgs = {
  where?: InputMaybe<GroupWhereInput>;
};


export type MutationDeleteManyInValidReasonArgs = {
  where?: InputMaybe<InValidReasonWhereInput>;
};


export type MutationDeleteManyInvoiceArgs = {
  where?: InputMaybe<InvoiceWhereInput>;
};


export type MutationDeleteManyManualAddrArgs = {
  where?: InputMaybe<ManualAddrWhereInput>;
};


export type MutationDeleteManyNotificationArgs = {
  where?: InputMaybe<NotificationWhereInput>;
};


export type MutationDeleteManyOrderArgs = {
  where?: InputMaybe<OrderWhereInput>;
};


export type MutationDeleteManyOrderDetailArgs = {
  where?: InputMaybe<OrderDetailWhereInput>;
};


export type MutationDeleteManyOrderDetailHistoryArgs = {
  where?: InputMaybe<OrderDetailHistoryWhereInput>;
};


export type MutationDeleteManyOrderTagArgs = {
  where?: InputMaybe<OrderTagWhereInput>;
};


export type MutationDeleteManyPaymentArgs = {
  where?: InputMaybe<PaymentWhereInput>;
};


export type MutationDeleteManyPostArgs = {
  where?: InputMaybe<PostWhereInput>;
};


export type MutationDeleteManyPostHistoryArgs = {
  where?: InputMaybe<PostHistoryWhereInput>;
};


export type MutationDeleteManyPostPackageArgs = {
  where?: InputMaybe<PostPackageWhereInput>;
};


export type MutationDeleteManyPostTagArgs = {
  where?: InputMaybe<PostTagWhereInput>;
};


export type MutationDeleteManyProductArgs = {
  where?: InputMaybe<ProductWhereInput>;
};


export type MutationDeleteManyProfitableArgs = {
  where?: InputMaybe<ProfitableWhereInput>;
};


export type MutationDeleteManyProfitableBadgetArgs = {
  where?: InputMaybe<ProfitableBadgetWhereInput>;
};


export type MutationDeleteManyQrcodeArgs = {
  where?: InputMaybe<QrcodeWhereInput>;
};


export type MutationDeleteManyRemoteStringArgs = {
  where?: InputMaybe<RemoteStringWhereInput>;
};


export type MutationDeleteManyReportArgs = {
  where?: InputMaybe<ReportWhereInput>;
};


export type MutationDeleteManyReportUserArgs = {
  where?: InputMaybe<ReportUserWhereInput>;
};


export type MutationDeleteManyScenicSpotArgs = {
  where?: InputMaybe<ScenicSpotWhereInput>;
};


export type MutationDeleteManySettingNotificationArgs = {
  where?: InputMaybe<SettingNotificationWhereInput>;
};


export type MutationDeleteManySettingNotificationSuppressArgs = {
  where?: InputMaybe<SettingNotificationSuppressWhereInput>;
};


export type MutationDeleteManySettingPrivacyArgs = {
  where?: InputMaybe<SettingPrivacyWhereInput>;
};


export type MutationDeleteManySignatureArgs = {
  where?: InputMaybe<SignatureWhereInput>;
};


export type MutationDeleteManyStampArgs = {
  where?: InputMaybe<StampWhereInput>;
};


export type MutationDeleteManyStoreItemArgs = {
  where?: InputMaybe<StoreItemWhereInput>;
};


export type MutationDeleteManyStoreItemTagArgs = {
  where?: InputMaybe<StoreItemTagWhereInput>;
};


export type MutationDeleteManyStoreItemToCartItemArgs = {
  where?: InputMaybe<StoreItemToCartItemWhereInput>;
};


export type MutationDeleteManyStoreRatioArgs = {
  where?: InputMaybe<StoreRatioWhereInput>;
};


export type MutationDeleteManyStoreRatioHistoryItemArgs = {
  where?: InputMaybe<StoreRatioHistoryItemWhereInput>;
};


export type MutationDeleteManyTappayArgs = {
  where?: InputMaybe<TappayWhereInput>;
};


export type MutationDeleteManyUserArgs = {
  where?: InputMaybe<UserWhereInput>;
};


export type MutationDeleteManyUserFriendArgs = {
  where?: InputMaybe<UserFriendWhereInput>;
};


export type MutationDeleteManyVirtualAddrMappingArgs = {
  where?: InputMaybe<VirtualAddrMappingWhereInput>;
};


export type MutationDeleteManyVirtualLandArgs = {
  where?: InputMaybe<VirtualLandWhereInput>;
};


export type MutationDeleteOneAddressArgs = {
  where: AddressWhereUniqueInput;
};


export type MutationDeleteOneAdvertisementArgs = {
  where: AdvertisementWhereUniqueInput;
};


export type MutationDeleteOneAttachmentArgs = {
  where: AttachmentWhereUniqueInput;
};


export type MutationDeleteOneBenefitMemoArgs = {
  where: BenefitMemoWhereUniqueInput;
};


export type MutationDeleteOneBenefitMemoToCoinArgs = {
  where: BenefitMemoToCoinWhereUniqueInput;
};


export type MutationDeleteOneBenefitMemoToOrderArgs = {
  where: BenefitMemoToOrderWhereUniqueInput;
};


export type MutationDeleteOneBenefitTransferArgs = {
  where: BenefitTransferWhereUniqueInput;
};


export type MutationDeleteOneCampaignNanhuiArgs = {
  where: CampaignNanhuiWhereUniqueInput;
};


export type MutationDeleteOneCampainZeczecArgs = {
  where: CampainZeczecWhereUniqueInput;
};


export type MutationDeleteOneCardArgs = {
  where: CardWhereUniqueInput;
};


export type MutationDeleteOneCartItemArgs = {
  where: CartItemWhereUniqueInput;
};


export type MutationDeleteOneCoinArgs = {
  where: CoinWhereUniqueInput;
};


export type MutationDeleteOneCoinPackArgs = {
  where: CoinPackWhereUniqueInput;
};


export type MutationDeleteOneCollectionArgs = {
  where: CollectionWhereUniqueInput;
};


export type MutationDeleteOneCouponActivityArgs = {
  where: CouponActivityWhereUniqueInput;
};


export type MutationDeleteOneCouponEntityArgs = {
  where: CouponEntityWhereUniqueInput;
};


export type MutationDeleteOneCouponGroupArgs = {
  where: CouponGroupWhereUniqueInput;
};


export type MutationDeleteOneCouponInfoArgs = {
  where: CouponInfoWhereUniqueInput;
};


export type MutationDeleteOneCouponUsageArgs = {
  where: CouponUsageWhereUniqueInput;
};


export type MutationDeleteOneDeviceTokenArgs = {
  where: DeviceTokenWhereUniqueInput;
};


export type MutationDeleteOneEcloudlifeArgs = {
  where: EcloudlifeWhereUniqueInput;
};


export type MutationDeleteOneEcloudlifeTrackArgs = {
  where: EcloudlifeTrackWhereUniqueInput;
};


export type MutationDeleteOneEmailToArgs = {
  where: EmailToWhereUniqueInput;
};


export type MutationDeleteOneGroupArgs = {
  where: GroupWhereUniqueInput;
};


export type MutationDeleteOneInValidReasonArgs = {
  where: InValidReasonWhereUniqueInput;
};


export type MutationDeleteOneInvoiceArgs = {
  where: InvoiceWhereUniqueInput;
};


export type MutationDeleteOneManualAddrArgs = {
  where: ManualAddrWhereUniqueInput;
};


export type MutationDeleteOneNotificationArgs = {
  where: NotificationWhereUniqueInput;
};


export type MutationDeleteOneOrderArgs = {
  where: OrderWhereUniqueInput;
};


export type MutationDeleteOneOrderDetailArgs = {
  where: OrderDetailWhereUniqueInput;
};


export type MutationDeleteOneOrderDetailHistoryArgs = {
  where: OrderDetailHistoryWhereUniqueInput;
};


export type MutationDeleteOneOrderTagArgs = {
  where: OrderTagWhereUniqueInput;
};


export type MutationDeleteOnePaymentArgs = {
  where: PaymentWhereUniqueInput;
};


export type MutationDeleteOnePostArgs = {
  where: PostWhereUniqueInput;
};


export type MutationDeleteOnePostHistoryArgs = {
  where: PostHistoryWhereUniqueInput;
};


export type MutationDeleteOnePostPackageArgs = {
  where: PostPackageWhereUniqueInput;
};


export type MutationDeleteOnePostTagArgs = {
  where: PostTagWhereUniqueInput;
};


export type MutationDeleteOneProductArgs = {
  where: ProductWhereUniqueInput;
};


export type MutationDeleteOneProfitableArgs = {
  where: ProfitableWhereUniqueInput;
};


export type MutationDeleteOneProfitableBadgetArgs = {
  where: ProfitableBadgetWhereUniqueInput;
};


export type MutationDeleteOneQrcodeArgs = {
  where: QrcodeWhereUniqueInput;
};


export type MutationDeleteOneRemoteStringArgs = {
  where: RemoteStringWhereUniqueInput;
};


export type MutationDeleteOneReportArgs = {
  where: ReportWhereUniqueInput;
};


export type MutationDeleteOneReportUserArgs = {
  where: ReportUserWhereUniqueInput;
};


export type MutationDeleteOneScenicSpotArgs = {
  where: ScenicSpotWhereUniqueInput;
};


export type MutationDeleteOneSettingNotificationArgs = {
  where: SettingNotificationWhereUniqueInput;
};


export type MutationDeleteOneSettingNotificationSuppressArgs = {
  where: SettingNotificationSuppressWhereUniqueInput;
};


export type MutationDeleteOneSettingPrivacyArgs = {
  where: SettingPrivacyWhereUniqueInput;
};


export type MutationDeleteOneSignatureArgs = {
  where: SignatureWhereUniqueInput;
};


export type MutationDeleteOneStampArgs = {
  where: StampWhereUniqueInput;
};


export type MutationDeleteOneStoreItemArgs = {
  where: StoreItemWhereUniqueInput;
};


export type MutationDeleteOneStoreItemTagArgs = {
  where: StoreItemTagWhereUniqueInput;
};


export type MutationDeleteOneStoreItemToCartItemArgs = {
  where: StoreItemToCartItemWhereUniqueInput;
};


export type MutationDeleteOneStoreRatioArgs = {
  where: StoreRatioWhereUniqueInput;
};


export type MutationDeleteOneStoreRatioHistoryItemArgs = {
  where: StoreRatioHistoryItemWhereUniqueInput;
};


export type MutationDeleteOneTappayArgs = {
  where: TappayWhereUniqueInput;
};


export type MutationDeleteOneUserArgs = {
  where: UserWhereUniqueInput;
};


export type MutationDeleteOneUserFriendArgs = {
  where: UserFriendWhereUniqueInput;
};


export type MutationDeleteOneVirtualAddrMappingArgs = {
  where: VirtualAddrMappingWhereUniqueInput;
};


export type MutationDeleteOneVirtualLandArgs = {
  where: VirtualLandWhereUniqueInput;
};


export type MutationUpdateManyAddressArgs = {
  data: AddressUpdateManyMutationInput;
  where?: InputMaybe<AddressWhereInput>;
};


export type MutationUpdateManyAdvertisementArgs = {
  data: AdvertisementUpdateManyMutationInput;
  where?: InputMaybe<AdvertisementWhereInput>;
};


export type MutationUpdateManyAttachmentArgs = {
  data: AttachmentUpdateManyMutationInput;
  where?: InputMaybe<AttachmentWhereInput>;
};


export type MutationUpdateManyBenefitMemoArgs = {
  data: BenefitMemoUpdateManyMutationInput;
  where?: InputMaybe<BenefitMemoWhereInput>;
};


export type MutationUpdateManyBenefitMemoToCoinArgs = {
  data: BenefitMemoToCoinUpdateManyMutationInput;
  where?: InputMaybe<BenefitMemoToCoinWhereInput>;
};


export type MutationUpdateManyBenefitMemoToOrderArgs = {
  data: BenefitMemoToOrderUpdateManyMutationInput;
  where?: InputMaybe<BenefitMemoToOrderWhereInput>;
};


export type MutationUpdateManyBenefitTransferArgs = {
  data: BenefitTransferUpdateManyMutationInput;
  where?: InputMaybe<BenefitTransferWhereInput>;
};


export type MutationUpdateManyCampaignNanhuiArgs = {
  data: CampaignNanhuiUpdateManyMutationInput;
  where?: InputMaybe<CampaignNanhuiWhereInput>;
};


export type MutationUpdateManyCampainZeczecArgs = {
  data: CampainZeczecUpdateManyMutationInput;
  where?: InputMaybe<CampainZeczecWhereInput>;
};


export type MutationUpdateManyCardArgs = {
  data: CardUpdateManyMutationInput;
  where?: InputMaybe<CardWhereInput>;
};


export type MutationUpdateManyCartItemArgs = {
  data: CartItemUpdateManyMutationInput;
  where?: InputMaybe<CartItemWhereInput>;
};


export type MutationUpdateManyCoinArgs = {
  data: CoinUpdateManyMutationInput;
  where?: InputMaybe<CoinWhereInput>;
};


export type MutationUpdateManyCoinPackArgs = {
  data: CoinPackUpdateManyMutationInput;
  where?: InputMaybe<CoinPackWhereInput>;
};


export type MutationUpdateManyCollectionArgs = {
  data: CollectionUpdateManyMutationInput;
  where?: InputMaybe<CollectionWhereInput>;
};


export type MutationUpdateManyCouponActivityArgs = {
  data: CouponActivityUpdateManyMutationInput;
  where?: InputMaybe<CouponActivityWhereInput>;
};


export type MutationUpdateManyCouponEntityArgs = {
  data: CouponEntityUpdateManyMutationInput;
  where?: InputMaybe<CouponEntityWhereInput>;
};


export type MutationUpdateManyCouponGroupArgs = {
  data: CouponGroupUpdateManyMutationInput;
  where?: InputMaybe<CouponGroupWhereInput>;
};


export type MutationUpdateManyCouponInfoArgs = {
  data: CouponInfoUpdateManyMutationInput;
  where?: InputMaybe<CouponInfoWhereInput>;
};


export type MutationUpdateManyCouponUsageArgs = {
  data: CouponUsageUpdateManyMutationInput;
  where?: InputMaybe<CouponUsageWhereInput>;
};


export type MutationUpdateManyDeviceTokenArgs = {
  data: DeviceTokenUpdateManyMutationInput;
  where?: InputMaybe<DeviceTokenWhereInput>;
};


export type MutationUpdateManyEcloudlifeArgs = {
  data: EcloudlifeUpdateManyMutationInput;
  where?: InputMaybe<EcloudlifeWhereInput>;
};


export type MutationUpdateManyEcloudlifeTrackArgs = {
  data: EcloudlifeTrackUpdateManyMutationInput;
  where?: InputMaybe<EcloudlifeTrackWhereInput>;
};


export type MutationUpdateManyEmailToArgs = {
  data: EmailToUpdateManyMutationInput;
  where?: InputMaybe<EmailToWhereInput>;
};


export type MutationUpdateManyGroupArgs = {
  data: GroupUpdateManyMutationInput;
  where?: InputMaybe<GroupWhereInput>;
};


export type MutationUpdateManyInValidReasonArgs = {
  data: InValidReasonUpdateManyMutationInput;
  where?: InputMaybe<InValidReasonWhereInput>;
};


export type MutationUpdateManyInvoiceArgs = {
  data: InvoiceUpdateManyMutationInput;
  where?: InputMaybe<InvoiceWhereInput>;
};


export type MutationUpdateManyManualAddrArgs = {
  data: ManualAddrUpdateManyMutationInput;
  where?: InputMaybe<ManualAddrWhereInput>;
};


export type MutationUpdateManyNotificationArgs = {
  data: NotificationUpdateManyMutationInput;
  where?: InputMaybe<NotificationWhereInput>;
};


export type MutationUpdateManyOrderArgs = {
  data: OrderUpdateManyMutationInput;
  where?: InputMaybe<OrderWhereInput>;
};


export type MutationUpdateManyOrderDetailArgs = {
  data: OrderDetailUpdateManyMutationInput;
  where?: InputMaybe<OrderDetailWhereInput>;
};


export type MutationUpdateManyOrderDetailHistoryArgs = {
  data: OrderDetailHistoryUpdateManyMutationInput;
  where?: InputMaybe<OrderDetailHistoryWhereInput>;
};


export type MutationUpdateManyOrderTagArgs = {
  data: OrderTagUpdateManyMutationInput;
  where?: InputMaybe<OrderTagWhereInput>;
};


export type MutationUpdateManyPaymentArgs = {
  data: PaymentUpdateManyMutationInput;
  where?: InputMaybe<PaymentWhereInput>;
};


export type MutationUpdateManyPostArgs = {
  data: PostUpdateManyMutationInput;
  where?: InputMaybe<PostWhereInput>;
};


export type MutationUpdateManyPostHistoryArgs = {
  data: PostHistoryUpdateManyMutationInput;
  where?: InputMaybe<PostHistoryWhereInput>;
};


export type MutationUpdateManyPostPackageArgs = {
  data: PostPackageUpdateManyMutationInput;
  where?: InputMaybe<PostPackageWhereInput>;
};


export type MutationUpdateManyPostTagArgs = {
  data: PostTagUpdateManyMutationInput;
  where?: InputMaybe<PostTagWhereInput>;
};


export type MutationUpdateManyProductArgs = {
  data: ProductUpdateManyMutationInput;
  where?: InputMaybe<ProductWhereInput>;
};


export type MutationUpdateManyProfitableArgs = {
  data: ProfitableUpdateManyMutationInput;
  where?: InputMaybe<ProfitableWhereInput>;
};


export type MutationUpdateManyProfitableBadgetArgs = {
  data: ProfitableBadgetUpdateManyMutationInput;
  where?: InputMaybe<ProfitableBadgetWhereInput>;
};


export type MutationUpdateManyQrcodeArgs = {
  data: QrcodeUpdateManyMutationInput;
  where?: InputMaybe<QrcodeWhereInput>;
};


export type MutationUpdateManyRemoteStringArgs = {
  data: RemoteStringUpdateManyMutationInput;
  where?: InputMaybe<RemoteStringWhereInput>;
};


export type MutationUpdateManyReportArgs = {
  data: ReportUpdateManyMutationInput;
  where?: InputMaybe<ReportWhereInput>;
};


export type MutationUpdateManyReportUserArgs = {
  data: ReportUserUpdateManyMutationInput;
  where?: InputMaybe<ReportUserWhereInput>;
};


export type MutationUpdateManyScenicSpotArgs = {
  data: ScenicSpotUpdateManyMutationInput;
  where?: InputMaybe<ScenicSpotWhereInput>;
};


export type MutationUpdateManySettingNotificationArgs = {
  data: SettingNotificationUpdateManyMutationInput;
  where?: InputMaybe<SettingNotificationWhereInput>;
};


export type MutationUpdateManySettingNotificationSuppressArgs = {
  data: SettingNotificationSuppressUpdateManyMutationInput;
  where?: InputMaybe<SettingNotificationSuppressWhereInput>;
};


export type MutationUpdateManySettingPrivacyArgs = {
  data: SettingPrivacyUpdateManyMutationInput;
  where?: InputMaybe<SettingPrivacyWhereInput>;
};


export type MutationUpdateManySignatureArgs = {
  data: SignatureUpdateManyMutationInput;
  where?: InputMaybe<SignatureWhereInput>;
};


export type MutationUpdateManyStampArgs = {
  data: StampUpdateManyMutationInput;
  where?: InputMaybe<StampWhereInput>;
};


export type MutationUpdateManyStoreItemArgs = {
  data: StoreItemUpdateManyMutationInput;
  where?: InputMaybe<StoreItemWhereInput>;
};


export type MutationUpdateManyStoreItemTagArgs = {
  data: StoreItemTagUpdateManyMutationInput;
  where?: InputMaybe<StoreItemTagWhereInput>;
};


export type MutationUpdateManyStoreItemToCartItemArgs = {
  data: StoreItemToCartItemUpdateManyMutationInput;
  where?: InputMaybe<StoreItemToCartItemWhereInput>;
};


export type MutationUpdateManyStoreRatioArgs = {
  data: StoreRatioUpdateManyMutationInput;
  where?: InputMaybe<StoreRatioWhereInput>;
};


export type MutationUpdateManyStoreRatioHistoryItemArgs = {
  data: StoreRatioHistoryItemUpdateManyMutationInput;
  where?: InputMaybe<StoreRatioHistoryItemWhereInput>;
};


export type MutationUpdateManyTappayArgs = {
  data: TappayUpdateManyMutationInput;
  where?: InputMaybe<TappayWhereInput>;
};


export type MutationUpdateManyUserArgs = {
  data: UserUpdateManyMutationInput;
  where?: InputMaybe<UserWhereInput>;
};


export type MutationUpdateManyUserFriendArgs = {
  data: UserFriendUpdateManyMutationInput;
  where?: InputMaybe<UserFriendWhereInput>;
};


export type MutationUpdateManyVirtualAddrMappingArgs = {
  data: VirtualAddrMappingUpdateManyMutationInput;
  where?: InputMaybe<VirtualAddrMappingWhereInput>;
};


export type MutationUpdateManyVirtualLandArgs = {
  data: VirtualLandUpdateManyMutationInput;
  where?: InputMaybe<VirtualLandWhereInput>;
};


export type MutationUpdateOneAddressArgs = {
  data: AddressUpdateInput;
  where: AddressWhereUniqueInput;
};


export type MutationUpdateOneAdvertisementArgs = {
  data: AdvertisementUpdateInput;
  where: AdvertisementWhereUniqueInput;
};


export type MutationUpdateOneAttachmentArgs = {
  data: AttachmentUpdateInput;
  where: AttachmentWhereUniqueInput;
};


export type MutationUpdateOneBenefitMemoArgs = {
  data: BenefitMemoUpdateInput;
  where: BenefitMemoWhereUniqueInput;
};


export type MutationUpdateOneBenefitMemoToCoinArgs = {
  data: BenefitMemoToCoinUpdateInput;
  where: BenefitMemoToCoinWhereUniqueInput;
};


export type MutationUpdateOneBenefitMemoToOrderArgs = {
  data: BenefitMemoToOrderUpdateInput;
  where: BenefitMemoToOrderWhereUniqueInput;
};


export type MutationUpdateOneBenefitTransferArgs = {
  data: BenefitTransferUpdateInput;
  where: BenefitTransferWhereUniqueInput;
};


export type MutationUpdateOneCampaignNanhuiArgs = {
  data: CampaignNanhuiUpdateInput;
  where: CampaignNanhuiWhereUniqueInput;
};


export type MutationUpdateOneCampainZeczecArgs = {
  data: CampainZeczecUpdateInput;
  where: CampainZeczecWhereUniqueInput;
};


export type MutationUpdateOneCardArgs = {
  data: CardUpdateInput;
  where: CardWhereUniqueInput;
};


export type MutationUpdateOneCartItemArgs = {
  data: CartItemUpdateInput;
  where: CartItemWhereUniqueInput;
};


export type MutationUpdateOneCoinArgs = {
  data: CoinUpdateInput;
  where: CoinWhereUniqueInput;
};


export type MutationUpdateOneCoinPackArgs = {
  data: CoinPackUpdateInput;
  where: CoinPackWhereUniqueInput;
};


export type MutationUpdateOneCollectionArgs = {
  data: CollectionUpdateInput;
  where: CollectionWhereUniqueInput;
};


export type MutationUpdateOneCouponActivityArgs = {
  data: CouponActivityUpdateInput;
  where: CouponActivityWhereUniqueInput;
};


export type MutationUpdateOneCouponEntityArgs = {
  data: CouponEntityUpdateInput;
  where: CouponEntityWhereUniqueInput;
};


export type MutationUpdateOneCouponGroupArgs = {
  data: CouponGroupUpdateInput;
  where: CouponGroupWhereUniqueInput;
};


export type MutationUpdateOneCouponInfoArgs = {
  data: CouponInfoUpdateInput;
  where: CouponInfoWhereUniqueInput;
};


export type MutationUpdateOneCouponUsageArgs = {
  data: CouponUsageUpdateInput;
  where: CouponUsageWhereUniqueInput;
};


export type MutationUpdateOneDeviceTokenArgs = {
  data: DeviceTokenUpdateInput;
  where: DeviceTokenWhereUniqueInput;
};


export type MutationUpdateOneEcloudlifeArgs = {
  data: EcloudlifeUpdateInput;
  where: EcloudlifeWhereUniqueInput;
};


export type MutationUpdateOneEcloudlifeTrackArgs = {
  data: EcloudlifeTrackUpdateInput;
  where: EcloudlifeTrackWhereUniqueInput;
};


export type MutationUpdateOneEmailToArgs = {
  data: EmailToUpdateInput;
  where: EmailToWhereUniqueInput;
};


export type MutationUpdateOneGroupArgs = {
  data: GroupUpdateInput;
  where: GroupWhereUniqueInput;
};


export type MutationUpdateOneInValidReasonArgs = {
  data: InValidReasonUpdateInput;
  where: InValidReasonWhereUniqueInput;
};


export type MutationUpdateOneInvoiceArgs = {
  data: InvoiceUpdateInput;
  where: InvoiceWhereUniqueInput;
};


export type MutationUpdateOneManualAddrArgs = {
  data: ManualAddrUpdateInput;
  where: ManualAddrWhereUniqueInput;
};


export type MutationUpdateOneNotificationArgs = {
  data: NotificationUpdateInput;
  where: NotificationWhereUniqueInput;
};


export type MutationUpdateOneOrderArgs = {
  data: OrderUpdateInput;
  where: OrderWhereUniqueInput;
};


export type MutationUpdateOneOrderDetailArgs = {
  data: OrderDetailUpdateInput;
  where: OrderDetailWhereUniqueInput;
};


export type MutationUpdateOneOrderDetailHistoryArgs = {
  data: OrderDetailHistoryUpdateInput;
  where: OrderDetailHistoryWhereUniqueInput;
};


export type MutationUpdateOneOrderTagArgs = {
  data: OrderTagUpdateInput;
  where: OrderTagWhereUniqueInput;
};


export type MutationUpdateOnePaymentArgs = {
  data: PaymentUpdateInput;
  where: PaymentWhereUniqueInput;
};


export type MutationUpdateOnePostArgs = {
  data: PostUpdateInput;
  where: PostWhereUniqueInput;
};


export type MutationUpdateOnePostHistoryArgs = {
  data: PostHistoryUpdateInput;
  where: PostHistoryWhereUniqueInput;
};


export type MutationUpdateOnePostPackageArgs = {
  data: PostPackageUpdateInput;
  where: PostPackageWhereUniqueInput;
};


export type MutationUpdateOnePostTagArgs = {
  data: PostTagUpdateInput;
  where: PostTagWhereUniqueInput;
};


export type MutationUpdateOneProductArgs = {
  data: ProductUpdateInput;
  where: ProductWhereUniqueInput;
};


export type MutationUpdateOneProfitableArgs = {
  data: ProfitableUpdateInput;
  where: ProfitableWhereUniqueInput;
};


export type MutationUpdateOneProfitableBadgetArgs = {
  data: ProfitableBadgetUpdateInput;
  where: ProfitableBadgetWhereUniqueInput;
};


export type MutationUpdateOneQrcodeArgs = {
  data: QrcodeUpdateInput;
  where: QrcodeWhereUniqueInput;
};


export type MutationUpdateOneRemoteStringArgs = {
  data: RemoteStringUpdateInput;
  where: RemoteStringWhereUniqueInput;
};


export type MutationUpdateOneReportArgs = {
  data: ReportUpdateInput;
  where: ReportWhereUniqueInput;
};


export type MutationUpdateOneReportUserArgs = {
  data: ReportUserUpdateInput;
  where: ReportUserWhereUniqueInput;
};


export type MutationUpdateOneScenicSpotArgs = {
  data: ScenicSpotUpdateInput;
  where: ScenicSpotWhereUniqueInput;
};


export type MutationUpdateOneSettingNotificationArgs = {
  data: SettingNotificationUpdateInput;
  where: SettingNotificationWhereUniqueInput;
};


export type MutationUpdateOneSettingNotificationSuppressArgs = {
  data: SettingNotificationSuppressUpdateInput;
  where: SettingNotificationSuppressWhereUniqueInput;
};


export type MutationUpdateOneSettingPrivacyArgs = {
  data: SettingPrivacyUpdateInput;
  where: SettingPrivacyWhereUniqueInput;
};


export type MutationUpdateOneSignatureArgs = {
  data: SignatureUpdateInput;
  where: SignatureWhereUniqueInput;
};


export type MutationUpdateOneStampArgs = {
  data: StampUpdateInput;
  where: StampWhereUniqueInput;
};


export type MutationUpdateOneStoreItemArgs = {
  data: StoreItemUpdateInput;
  where: StoreItemWhereUniqueInput;
};


export type MutationUpdateOneStoreItemTagArgs = {
  data: StoreItemTagUpdateInput;
  where: StoreItemTagWhereUniqueInput;
};


export type MutationUpdateOneStoreItemToCartItemArgs = {
  data: StoreItemToCartItemUpdateInput;
  where: StoreItemToCartItemWhereUniqueInput;
};


export type MutationUpdateOneStoreRatioArgs = {
  data: StoreRatioUpdateInput;
  where: StoreRatioWhereUniqueInput;
};


export type MutationUpdateOneStoreRatioHistoryItemArgs = {
  data: StoreRatioHistoryItemUpdateInput;
  where: StoreRatioHistoryItemWhereUniqueInput;
};


export type MutationUpdateOneTappayArgs = {
  data: TappayUpdateInput;
  where: TappayWhereUniqueInput;
};


export type MutationUpdateOneUserArgs = {
  data: UserUpdateInput;
  where: UserWhereUniqueInput;
};


export type MutationUpdateOneUserFriendArgs = {
  data: UserFriendUpdateInput;
  where: UserFriendWhereUniqueInput;
};


export type MutationUpdateOneVirtualAddrMappingArgs = {
  data: VirtualAddrMappingUpdateInput;
  where: VirtualAddrMappingWhereUniqueInput;
};


export type MutationUpdateOneVirtualLandArgs = {
  data: VirtualLandUpdateInput;
  where: VirtualLandWhereUniqueInput;
};


export type MutationUpsertOneAddressArgs = {
  create: AddressCreateInput;
  update: AddressUpdateInput;
  where: AddressWhereUniqueInput;
};


export type MutationUpsertOneAdvertisementArgs = {
  create: AdvertisementCreateInput;
  update: AdvertisementUpdateInput;
  where: AdvertisementWhereUniqueInput;
};


export type MutationUpsertOneAttachmentArgs = {
  create: AttachmentCreateInput;
  update: AttachmentUpdateInput;
  where: AttachmentWhereUniqueInput;
};


export type MutationUpsertOneBenefitMemoArgs = {
  create: BenefitMemoCreateInput;
  update: BenefitMemoUpdateInput;
  where: BenefitMemoWhereUniqueInput;
};


export type MutationUpsertOneBenefitMemoToCoinArgs = {
  create: BenefitMemoToCoinCreateInput;
  update: BenefitMemoToCoinUpdateInput;
  where: BenefitMemoToCoinWhereUniqueInput;
};


export type MutationUpsertOneBenefitMemoToOrderArgs = {
  create: BenefitMemoToOrderCreateInput;
  update: BenefitMemoToOrderUpdateInput;
  where: BenefitMemoToOrderWhereUniqueInput;
};


export type MutationUpsertOneBenefitTransferArgs = {
  create: BenefitTransferCreateInput;
  update: BenefitTransferUpdateInput;
  where: BenefitTransferWhereUniqueInput;
};


export type MutationUpsertOneCampaignNanhuiArgs = {
  create: CampaignNanhuiCreateInput;
  update: CampaignNanhuiUpdateInput;
  where: CampaignNanhuiWhereUniqueInput;
};


export type MutationUpsertOneCampainZeczecArgs = {
  create: CampainZeczecCreateInput;
  update: CampainZeczecUpdateInput;
  where: CampainZeczecWhereUniqueInput;
};


export type MutationUpsertOneCardArgs = {
  create: CardCreateInput;
  update: CardUpdateInput;
  where: CardWhereUniqueInput;
};


export type MutationUpsertOneCartItemArgs = {
  create: CartItemCreateInput;
  update: CartItemUpdateInput;
  where: CartItemWhereUniqueInput;
};


export type MutationUpsertOneCoinArgs = {
  create: CoinCreateInput;
  update: CoinUpdateInput;
  where: CoinWhereUniqueInput;
};


export type MutationUpsertOneCoinPackArgs = {
  create: CoinPackCreateInput;
  update: CoinPackUpdateInput;
  where: CoinPackWhereUniqueInput;
};


export type MutationUpsertOneCollectionArgs = {
  create: CollectionCreateInput;
  update: CollectionUpdateInput;
  where: CollectionWhereUniqueInput;
};


export type MutationUpsertOneCouponActivityArgs = {
  create: CouponActivityCreateInput;
  update: CouponActivityUpdateInput;
  where: CouponActivityWhereUniqueInput;
};


export type MutationUpsertOneCouponEntityArgs = {
  create: CouponEntityCreateInput;
  update: CouponEntityUpdateInput;
  where: CouponEntityWhereUniqueInput;
};


export type MutationUpsertOneCouponGroupArgs = {
  create: CouponGroupCreateInput;
  update: CouponGroupUpdateInput;
  where: CouponGroupWhereUniqueInput;
};


export type MutationUpsertOneCouponInfoArgs = {
  create: CouponInfoCreateInput;
  update: CouponInfoUpdateInput;
  where: CouponInfoWhereUniqueInput;
};


export type MutationUpsertOneCouponUsageArgs = {
  create: CouponUsageCreateInput;
  update: CouponUsageUpdateInput;
  where: CouponUsageWhereUniqueInput;
};


export type MutationUpsertOneDeviceTokenArgs = {
  create: DeviceTokenCreateInput;
  update: DeviceTokenUpdateInput;
  where: DeviceTokenWhereUniqueInput;
};


export type MutationUpsertOneEcloudlifeArgs = {
  create: EcloudlifeCreateInput;
  update: EcloudlifeUpdateInput;
  where: EcloudlifeWhereUniqueInput;
};


export type MutationUpsertOneEcloudlifeTrackArgs = {
  create: EcloudlifeTrackCreateInput;
  update: EcloudlifeTrackUpdateInput;
  where: EcloudlifeTrackWhereUniqueInput;
};


export type MutationUpsertOneEmailToArgs = {
  create: EmailToCreateInput;
  update: EmailToUpdateInput;
  where: EmailToWhereUniqueInput;
};


export type MutationUpsertOneGroupArgs = {
  create: GroupCreateInput;
  update: GroupUpdateInput;
  where: GroupWhereUniqueInput;
};


export type MutationUpsertOneInValidReasonArgs = {
  create: InValidReasonCreateInput;
  update: InValidReasonUpdateInput;
  where: InValidReasonWhereUniqueInput;
};


export type MutationUpsertOneInvoiceArgs = {
  create: InvoiceCreateInput;
  update: InvoiceUpdateInput;
  where: InvoiceWhereUniqueInput;
};


export type MutationUpsertOneManualAddrArgs = {
  create: ManualAddrCreateInput;
  update: ManualAddrUpdateInput;
  where: ManualAddrWhereUniqueInput;
};


export type MutationUpsertOneNotificationArgs = {
  create: NotificationCreateInput;
  update: NotificationUpdateInput;
  where: NotificationWhereUniqueInput;
};


export type MutationUpsertOneOrderArgs = {
  create: OrderCreateInput;
  update: OrderUpdateInput;
  where: OrderWhereUniqueInput;
};


export type MutationUpsertOneOrderDetailArgs = {
  create: OrderDetailCreateInput;
  update: OrderDetailUpdateInput;
  where: OrderDetailWhereUniqueInput;
};


export type MutationUpsertOneOrderDetailHistoryArgs = {
  create: OrderDetailHistoryCreateInput;
  update: OrderDetailHistoryUpdateInput;
  where: OrderDetailHistoryWhereUniqueInput;
};


export type MutationUpsertOneOrderTagArgs = {
  create: OrderTagCreateInput;
  update: OrderTagUpdateInput;
  where: OrderTagWhereUniqueInput;
};


export type MutationUpsertOnePaymentArgs = {
  create: PaymentCreateInput;
  update: PaymentUpdateInput;
  where: PaymentWhereUniqueInput;
};


export type MutationUpsertOnePostArgs = {
  create: PostCreateInput;
  update: PostUpdateInput;
  where: PostWhereUniqueInput;
};


export type MutationUpsertOnePostHistoryArgs = {
  create: PostHistoryCreateInput;
  update: PostHistoryUpdateInput;
  where: PostHistoryWhereUniqueInput;
};


export type MutationUpsertOnePostPackageArgs = {
  create: PostPackageCreateInput;
  update: PostPackageUpdateInput;
  where: PostPackageWhereUniqueInput;
};


export type MutationUpsertOnePostTagArgs = {
  create: PostTagCreateInput;
  update: PostTagUpdateInput;
  where: PostTagWhereUniqueInput;
};


export type MutationUpsertOneProductArgs = {
  create: ProductCreateInput;
  update: ProductUpdateInput;
  where: ProductWhereUniqueInput;
};


export type MutationUpsertOneProfitableArgs = {
  create: ProfitableCreateInput;
  update: ProfitableUpdateInput;
  where: ProfitableWhereUniqueInput;
};


export type MutationUpsertOneProfitableBadgetArgs = {
  create: ProfitableBadgetCreateInput;
  update: ProfitableBadgetUpdateInput;
  where: ProfitableBadgetWhereUniqueInput;
};


export type MutationUpsertOneQrcodeArgs = {
  create: QrcodeCreateInput;
  update: QrcodeUpdateInput;
  where: QrcodeWhereUniqueInput;
};


export type MutationUpsertOneRemoteStringArgs = {
  create: RemoteStringCreateInput;
  update: RemoteStringUpdateInput;
  where: RemoteStringWhereUniqueInput;
};


export type MutationUpsertOneReportArgs = {
  create: ReportCreateInput;
  update: ReportUpdateInput;
  where: ReportWhereUniqueInput;
};


export type MutationUpsertOneReportUserArgs = {
  create: ReportUserCreateInput;
  update: ReportUserUpdateInput;
  where: ReportUserWhereUniqueInput;
};


export type MutationUpsertOneScenicSpotArgs = {
  create: ScenicSpotCreateInput;
  update: ScenicSpotUpdateInput;
  where: ScenicSpotWhereUniqueInput;
};


export type MutationUpsertOneSettingNotificationArgs = {
  create: SettingNotificationCreateInput;
  update: SettingNotificationUpdateInput;
  where: SettingNotificationWhereUniqueInput;
};


export type MutationUpsertOneSettingNotificationSuppressArgs = {
  create: SettingNotificationSuppressCreateInput;
  update: SettingNotificationSuppressUpdateInput;
  where: SettingNotificationSuppressWhereUniqueInput;
};


export type MutationUpsertOneSettingPrivacyArgs = {
  create: SettingPrivacyCreateInput;
  update: SettingPrivacyUpdateInput;
  where: SettingPrivacyWhereUniqueInput;
};


export type MutationUpsertOneSignatureArgs = {
  create: SignatureCreateInput;
  update: SignatureUpdateInput;
  where: SignatureWhereUniqueInput;
};


export type MutationUpsertOneStampArgs = {
  create: StampCreateInput;
  update: StampUpdateInput;
  where: StampWhereUniqueInput;
};


export type MutationUpsertOneStoreItemArgs = {
  create: StoreItemCreateInput;
  update: StoreItemUpdateInput;
  where: StoreItemWhereUniqueInput;
};


export type MutationUpsertOneStoreItemTagArgs = {
  create: StoreItemTagCreateInput;
  update: StoreItemTagUpdateInput;
  where: StoreItemTagWhereUniqueInput;
};


export type MutationUpsertOneStoreItemToCartItemArgs = {
  create: StoreItemToCartItemCreateInput;
  update: StoreItemToCartItemUpdateInput;
  where: StoreItemToCartItemWhereUniqueInput;
};


export type MutationUpsertOneStoreRatioArgs = {
  create: StoreRatioCreateInput;
  update: StoreRatioUpdateInput;
  where: StoreRatioWhereUniqueInput;
};


export type MutationUpsertOneStoreRatioHistoryItemArgs = {
  create: StoreRatioHistoryItemCreateInput;
  update: StoreRatioHistoryItemUpdateInput;
  where: StoreRatioHistoryItemWhereUniqueInput;
};


export type MutationUpsertOneTappayArgs = {
  create: TappayCreateInput;
  update: TappayUpdateInput;
  where: TappayWhereUniqueInput;
};


export type MutationUpsertOneUserArgs = {
  create: UserCreateInput;
  update: UserUpdateInput;
  where: UserWhereUniqueInput;
};


export type MutationUpsertOneUserFriendArgs = {
  create: UserFriendCreateInput;
  update: UserFriendUpdateInput;
  where: UserFriendWhereUniqueInput;
};


export type MutationUpsertOneVirtualAddrMappingArgs = {
  create: VirtualAddrMappingCreateInput;
  update: VirtualAddrMappingUpdateInput;
  where: VirtualAddrMappingWhereUniqueInput;
};


export type MutationUpsertOneVirtualLandArgs = {
  create: VirtualLandCreateInput;
  update: VirtualLandUpdateInput;
  where: VirtualLandWhereUniqueInput;
};

export type NestedBoolFilter = {
  equals?: InputMaybe<Scalars['Boolean']>;
  not?: InputMaybe<NestedBoolFilter>;
};

export type NestedBoolNullableFilter = {
  equals?: InputMaybe<Scalars['Boolean']>;
  not?: InputMaybe<NestedBoolNullableFilter>;
};

export type NestedBoolNullableWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntNullableFilter>;
  _max?: InputMaybe<NestedBoolNullableFilter>;
  _min?: InputMaybe<NestedBoolNullableFilter>;
  equals?: InputMaybe<Scalars['Boolean']>;
  not?: InputMaybe<NestedBoolNullableWithAggregatesFilter>;
};

export type NestedBoolWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedBoolFilter>;
  _min?: InputMaybe<NestedBoolFilter>;
  equals?: InputMaybe<Scalars['Boolean']>;
  not?: InputMaybe<NestedBoolWithAggregatesFilter>;
};

export type NestedDateTimeFilter = {
  equals?: InputMaybe<Scalars['DateTime']>;
  gt?: InputMaybe<Scalars['DateTime']>;
  gte?: InputMaybe<Scalars['DateTime']>;
  in?: InputMaybe<Array<Scalars['DateTime']>>;
  lt?: InputMaybe<Scalars['DateTime']>;
  lte?: InputMaybe<Scalars['DateTime']>;
  not?: InputMaybe<NestedDateTimeFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type NestedDateTimeNullableFilter = {
  equals?: InputMaybe<Scalars['DateTime']>;
  gt?: InputMaybe<Scalars['DateTime']>;
  gte?: InputMaybe<Scalars['DateTime']>;
  in?: InputMaybe<Array<Scalars['DateTime']>>;
  lt?: InputMaybe<Scalars['DateTime']>;
  lte?: InputMaybe<Scalars['DateTime']>;
  not?: InputMaybe<NestedDateTimeNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type NestedDateTimeNullableWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntNullableFilter>;
  _max?: InputMaybe<NestedDateTimeNullableFilter>;
  _min?: InputMaybe<NestedDateTimeNullableFilter>;
  equals?: InputMaybe<Scalars['DateTime']>;
  gt?: InputMaybe<Scalars['DateTime']>;
  gte?: InputMaybe<Scalars['DateTime']>;
  in?: InputMaybe<Array<Scalars['DateTime']>>;
  lt?: InputMaybe<Scalars['DateTime']>;
  lte?: InputMaybe<Scalars['DateTime']>;
  not?: InputMaybe<NestedDateTimeNullableWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type NestedDateTimeWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedDateTimeFilter>;
  _min?: InputMaybe<NestedDateTimeFilter>;
  equals?: InputMaybe<Scalars['DateTime']>;
  gt?: InputMaybe<Scalars['DateTime']>;
  gte?: InputMaybe<Scalars['DateTime']>;
  in?: InputMaybe<Array<Scalars['DateTime']>>;
  lt?: InputMaybe<Scalars['DateTime']>;
  lte?: InputMaybe<Scalars['DateTime']>;
  not?: InputMaybe<NestedDateTimeWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type NestedDecimalFilter = {
  equals?: InputMaybe<Scalars['Decimal']>;
  gt?: InputMaybe<Scalars['Decimal']>;
  gte?: InputMaybe<Scalars['Decimal']>;
  in?: InputMaybe<Array<Scalars['Decimal']>>;
  lt?: InputMaybe<Scalars['Decimal']>;
  lte?: InputMaybe<Scalars['Decimal']>;
  not?: InputMaybe<NestedDecimalFilter>;
  notIn?: InputMaybe<Array<Scalars['Decimal']>>;
};

export type NestedDecimalNullableFilter = {
  equals?: InputMaybe<Scalars['Decimal']>;
  gt?: InputMaybe<Scalars['Decimal']>;
  gte?: InputMaybe<Scalars['Decimal']>;
  in?: InputMaybe<Array<Scalars['Decimal']>>;
  lt?: InputMaybe<Scalars['Decimal']>;
  lte?: InputMaybe<Scalars['Decimal']>;
  not?: InputMaybe<NestedDecimalNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['Decimal']>>;
};

export type NestedDecimalNullableWithAggregatesFilter = {
  _avg?: InputMaybe<NestedDecimalNullableFilter>;
  _count?: InputMaybe<NestedIntNullableFilter>;
  _max?: InputMaybe<NestedDecimalNullableFilter>;
  _min?: InputMaybe<NestedDecimalNullableFilter>;
  _sum?: InputMaybe<NestedDecimalNullableFilter>;
  equals?: InputMaybe<Scalars['Decimal']>;
  gt?: InputMaybe<Scalars['Decimal']>;
  gte?: InputMaybe<Scalars['Decimal']>;
  in?: InputMaybe<Array<Scalars['Decimal']>>;
  lt?: InputMaybe<Scalars['Decimal']>;
  lte?: InputMaybe<Scalars['Decimal']>;
  not?: InputMaybe<NestedDecimalNullableWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['Decimal']>>;
};

export type NestedDecimalWithAggregatesFilter = {
  _avg?: InputMaybe<NestedDecimalFilter>;
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedDecimalFilter>;
  _min?: InputMaybe<NestedDecimalFilter>;
  _sum?: InputMaybe<NestedDecimalFilter>;
  equals?: InputMaybe<Scalars['Decimal']>;
  gt?: InputMaybe<Scalars['Decimal']>;
  gte?: InputMaybe<Scalars['Decimal']>;
  in?: InputMaybe<Array<Scalars['Decimal']>>;
  lt?: InputMaybe<Scalars['Decimal']>;
  lte?: InputMaybe<Scalars['Decimal']>;
  not?: InputMaybe<NestedDecimalWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['Decimal']>>;
};

export type NestedEnumAdsActionFilter = {
  equals?: InputMaybe<AdsAction>;
  in?: InputMaybe<Array<AdsAction>>;
  not?: InputMaybe<NestedEnumAdsActionFilter>;
  notIn?: InputMaybe<Array<AdsAction>>;
};

export type NestedEnumAdsActionWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumAdsActionFilter>;
  _min?: InputMaybe<NestedEnumAdsActionFilter>;
  equals?: InputMaybe<AdsAction>;
  in?: InputMaybe<Array<AdsAction>>;
  not?: InputMaybe<NestedEnumAdsActionWithAggregatesFilter>;
  notIn?: InputMaybe<Array<AdsAction>>;
};

export type NestedEnumBenefitTypeFilter = {
  equals?: InputMaybe<BenefitType>;
  in?: InputMaybe<Array<BenefitType>>;
  not?: InputMaybe<NestedEnumBenefitTypeFilter>;
  notIn?: InputMaybe<Array<BenefitType>>;
};

export type NestedEnumBenefitTypeWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumBenefitTypeFilter>;
  _min?: InputMaybe<NestedEnumBenefitTypeFilter>;
  equals?: InputMaybe<BenefitType>;
  in?: InputMaybe<Array<BenefitType>>;
  not?: InputMaybe<NestedEnumBenefitTypeWithAggregatesFilter>;
  notIn?: InputMaybe<Array<BenefitType>>;
};

export type NestedEnumCardOrientationFilter = {
  equals?: InputMaybe<CardOrientation>;
  in?: InputMaybe<Array<CardOrientation>>;
  not?: InputMaybe<NestedEnumCardOrientationFilter>;
  notIn?: InputMaybe<Array<CardOrientation>>;
};

export type NestedEnumCardOrientationWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumCardOrientationFilter>;
  _min?: InputMaybe<NestedEnumCardOrientationFilter>;
  equals?: InputMaybe<CardOrientation>;
  in?: InputMaybe<Array<CardOrientation>>;
  not?: InputMaybe<NestedEnumCardOrientationWithAggregatesFilter>;
  notIn?: InputMaybe<Array<CardOrientation>>;
};

export type NestedEnumCoinTypeFilter = {
  equals?: InputMaybe<CoinType>;
  in?: InputMaybe<Array<CoinType>>;
  not?: InputMaybe<NestedEnumCoinTypeFilter>;
  notIn?: InputMaybe<Array<CoinType>>;
};

export type NestedEnumCoinTypeWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumCoinTypeFilter>;
  _min?: InputMaybe<NestedEnumCoinTypeFilter>;
  equals?: InputMaybe<CoinType>;
  in?: InputMaybe<Array<CoinType>>;
  not?: InputMaybe<NestedEnumCoinTypeWithAggregatesFilter>;
  notIn?: InputMaybe<Array<CoinType>>;
};

export type NestedEnumCollectionTypeFilter = {
  equals?: InputMaybe<CollectionType>;
  in?: InputMaybe<Array<CollectionType>>;
  not?: InputMaybe<NestedEnumCollectionTypeFilter>;
  notIn?: InputMaybe<Array<CollectionType>>;
};

export type NestedEnumCollectionTypeWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumCollectionTypeFilter>;
  _min?: InputMaybe<NestedEnumCollectionTypeFilter>;
  equals?: InputMaybe<CollectionType>;
  in?: InputMaybe<Array<CollectionType>>;
  not?: InputMaybe<NestedEnumCollectionTypeWithAggregatesFilter>;
  notIn?: InputMaybe<Array<CollectionType>>;
};

export type NestedEnumConsumeTypeFilter = {
  equals?: InputMaybe<ConsumeType>;
  in?: InputMaybe<Array<ConsumeType>>;
  not?: InputMaybe<NestedEnumConsumeTypeFilter>;
  notIn?: InputMaybe<Array<ConsumeType>>;
};

export type NestedEnumConsumeTypeWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumConsumeTypeFilter>;
  _min?: InputMaybe<NestedEnumConsumeTypeFilter>;
  equals?: InputMaybe<ConsumeType>;
  in?: InputMaybe<Array<ConsumeType>>;
  not?: InputMaybe<NestedEnumConsumeTypeWithAggregatesFilter>;
  notIn?: InputMaybe<Array<ConsumeType>>;
};

export type NestedEnumCouponActivityTypeFilter = {
  equals?: InputMaybe<CouponActivityType>;
  in?: InputMaybe<Array<CouponActivityType>>;
  not?: InputMaybe<NestedEnumCouponActivityTypeFilter>;
  notIn?: InputMaybe<Array<CouponActivityType>>;
};

export type NestedEnumCouponActivityTypeWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumCouponActivityTypeFilter>;
  _min?: InputMaybe<NestedEnumCouponActivityTypeFilter>;
  equals?: InputMaybe<CouponActivityType>;
  in?: InputMaybe<Array<CouponActivityType>>;
  not?: InputMaybe<NestedEnumCouponActivityTypeWithAggregatesFilter>;
  notIn?: InputMaybe<Array<CouponActivityType>>;
};

export type NestedEnumCouponCheckoutActionFilter = {
  equals?: InputMaybe<CouponCheckoutAction>;
  in?: InputMaybe<Array<CouponCheckoutAction>>;
  not?: InputMaybe<NestedEnumCouponCheckoutActionFilter>;
  notIn?: InputMaybe<Array<CouponCheckoutAction>>;
};

export type NestedEnumCouponCheckoutActionWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumCouponCheckoutActionFilter>;
  _min?: InputMaybe<NestedEnumCouponCheckoutActionFilter>;
  equals?: InputMaybe<CouponCheckoutAction>;
  in?: InputMaybe<Array<CouponCheckoutAction>>;
  not?: InputMaybe<NestedEnumCouponCheckoutActionWithAggregatesFilter>;
  notIn?: InputMaybe<Array<CouponCheckoutAction>>;
};

export type NestedEnumCouponTypeFilter = {
  equals?: InputMaybe<CouponType>;
  in?: InputMaybe<Array<CouponType>>;
  not?: InputMaybe<NestedEnumCouponTypeFilter>;
  notIn?: InputMaybe<Array<CouponType>>;
};

export type NestedEnumCouponTypeWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumCouponTypeFilter>;
  _min?: InputMaybe<NestedEnumCouponTypeFilter>;
  equals?: InputMaybe<CouponType>;
  in?: InputMaybe<Array<CouponType>>;
  not?: InputMaybe<NestedEnumCouponTypeWithAggregatesFilter>;
  notIn?: InputMaybe<Array<CouponType>>;
};

export type NestedEnumCouponUsageActionFilter = {
  equals?: InputMaybe<CouponUsageAction>;
  in?: InputMaybe<Array<CouponUsageAction>>;
  not?: InputMaybe<NestedEnumCouponUsageActionFilter>;
  notIn?: InputMaybe<Array<CouponUsageAction>>;
};

export type NestedEnumCouponUsageActionWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumCouponUsageActionFilter>;
  _min?: InputMaybe<NestedEnumCouponUsageActionFilter>;
  equals?: InputMaybe<CouponUsageAction>;
  in?: InputMaybe<Array<CouponUsageAction>>;
  not?: InputMaybe<NestedEnumCouponUsageActionWithAggregatesFilter>;
  notIn?: InputMaybe<Array<CouponUsageAction>>;
};

export type NestedEnumDeviceTokenTypeFilter = {
  equals?: InputMaybe<DeviceTokenType>;
  in?: InputMaybe<Array<DeviceTokenType>>;
  not?: InputMaybe<NestedEnumDeviceTokenTypeFilter>;
  notIn?: InputMaybe<Array<DeviceTokenType>>;
};

export type NestedEnumDeviceTokenTypeWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumDeviceTokenTypeFilter>;
  _min?: InputMaybe<NestedEnumDeviceTokenTypeFilter>;
  equals?: InputMaybe<DeviceTokenType>;
  in?: InputMaybe<Array<DeviceTokenType>>;
  not?: InputMaybe<NestedEnumDeviceTokenTypeWithAggregatesFilter>;
  notIn?: InputMaybe<Array<DeviceTokenType>>;
};

export type NestedEnumFriendStatusFilter = {
  equals?: InputMaybe<FriendStatus>;
  in?: InputMaybe<Array<FriendStatus>>;
  not?: InputMaybe<NestedEnumFriendStatusFilter>;
  notIn?: InputMaybe<Array<FriendStatus>>;
};

export type NestedEnumFriendStatusWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumFriendStatusFilter>;
  _min?: InputMaybe<NestedEnumFriendStatusFilter>;
  equals?: InputMaybe<FriendStatus>;
  in?: InputMaybe<Array<FriendStatus>>;
  not?: InputMaybe<NestedEnumFriendStatusWithAggregatesFilter>;
  notIn?: InputMaybe<Array<FriendStatus>>;
};

export type NestedEnumInValidTypeFilter = {
  equals?: InputMaybe<InValidType>;
  in?: InputMaybe<Array<InValidType>>;
  not?: InputMaybe<NestedEnumInValidTypeFilter>;
  notIn?: InputMaybe<Array<InValidType>>;
};

export type NestedEnumInValidTypeWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumInValidTypeFilter>;
  _min?: InputMaybe<NestedEnumInValidTypeFilter>;
  equals?: InputMaybe<InValidType>;
  in?: InputMaybe<Array<InValidType>>;
  not?: InputMaybe<NestedEnumInValidTypeWithAggregatesFilter>;
  notIn?: InputMaybe<Array<InValidType>>;
};

export type NestedEnumInvoiceServiceProviderFilter = {
  equals?: InputMaybe<InvoiceServiceProvider>;
  in?: InputMaybe<Array<InvoiceServiceProvider>>;
  not?: InputMaybe<NestedEnumInvoiceServiceProviderFilter>;
  notIn?: InputMaybe<Array<InvoiceServiceProvider>>;
};

export type NestedEnumInvoiceServiceProviderWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumInvoiceServiceProviderFilter>;
  _min?: InputMaybe<NestedEnumInvoiceServiceProviderFilter>;
  equals?: InputMaybe<InvoiceServiceProvider>;
  in?: InputMaybe<Array<InvoiceServiceProvider>>;
  not?: InputMaybe<NestedEnumInvoiceServiceProviderWithAggregatesFilter>;
  notIn?: InputMaybe<Array<InvoiceServiceProvider>>;
};

export type NestedEnumInvoiceTypeFilter = {
  equals?: InputMaybe<InvoiceType>;
  in?: InputMaybe<Array<InvoiceType>>;
  not?: InputMaybe<NestedEnumInvoiceTypeFilter>;
  notIn?: InputMaybe<Array<InvoiceType>>;
};

export type NestedEnumInvoiceTypeWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumInvoiceTypeFilter>;
  _min?: InputMaybe<NestedEnumInvoiceTypeFilter>;
  equals?: InputMaybe<InvoiceType>;
  in?: InputMaybe<Array<InvoiceType>>;
  not?: InputMaybe<NestedEnumInvoiceTypeWithAggregatesFilter>;
  notIn?: InputMaybe<Array<InvoiceType>>;
};

export type NestedEnumLikeSettingNotificationFilter = {
  equals?: InputMaybe<LikeSettingNotification>;
  in?: InputMaybe<Array<LikeSettingNotification>>;
  not?: InputMaybe<NestedEnumLikeSettingNotificationFilter>;
  notIn?: InputMaybe<Array<LikeSettingNotification>>;
};

export type NestedEnumLikeSettingNotificationWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumLikeSettingNotificationFilter>;
  _min?: InputMaybe<NestedEnumLikeSettingNotificationFilter>;
  equals?: InputMaybe<LikeSettingNotification>;
  in?: InputMaybe<Array<LikeSettingNotification>>;
  not?: InputMaybe<NestedEnumLikeSettingNotificationWithAggregatesFilter>;
  notIn?: InputMaybe<Array<LikeSettingNotification>>;
};

export type NestedEnumNotificationStatusFilter = {
  equals?: InputMaybe<NotificationStatus>;
  in?: InputMaybe<Array<NotificationStatus>>;
  not?: InputMaybe<NestedEnumNotificationStatusFilter>;
  notIn?: InputMaybe<Array<NotificationStatus>>;
};

export type NestedEnumNotificationStatusWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumNotificationStatusFilter>;
  _min?: InputMaybe<NestedEnumNotificationStatusFilter>;
  equals?: InputMaybe<NotificationStatus>;
  in?: InputMaybe<Array<NotificationStatus>>;
  not?: InputMaybe<NestedEnumNotificationStatusWithAggregatesFilter>;
  notIn?: InputMaybe<Array<NotificationStatus>>;
};

export type NestedEnumNotificationTypeFilter = {
  equals?: InputMaybe<NotificationType>;
  in?: InputMaybe<Array<NotificationType>>;
  not?: InputMaybe<NestedEnumNotificationTypeFilter>;
  notIn?: InputMaybe<Array<NotificationType>>;
};

export type NestedEnumNotificationTypeWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumNotificationTypeFilter>;
  _min?: InputMaybe<NestedEnumNotificationTypeFilter>;
  equals?: InputMaybe<NotificationType>;
  in?: InputMaybe<Array<NotificationType>>;
  not?: InputMaybe<NestedEnumNotificationTypeWithAggregatesFilter>;
  notIn?: InputMaybe<Array<NotificationType>>;
};

export type NestedEnumOrderStatusFilter = {
  equals?: InputMaybe<OrderStatus>;
  in?: InputMaybe<Array<OrderStatus>>;
  not?: InputMaybe<NestedEnumOrderStatusFilter>;
  notIn?: InputMaybe<Array<OrderStatus>>;
};

export type NestedEnumOrderStatusWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumOrderStatusFilter>;
  _min?: InputMaybe<NestedEnumOrderStatusFilter>;
  equals?: InputMaybe<OrderStatus>;
  in?: InputMaybe<Array<OrderStatus>>;
  not?: InputMaybe<NestedEnumOrderStatusWithAggregatesFilter>;
  notIn?: InputMaybe<Array<OrderStatus>>;
};

export type NestedEnumPackageStatusFilter = {
  equals?: InputMaybe<PackageStatus>;
  in?: InputMaybe<Array<PackageStatus>>;
  not?: InputMaybe<NestedEnumPackageStatusFilter>;
  notIn?: InputMaybe<Array<PackageStatus>>;
};

export type NestedEnumPackageStatusWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumPackageStatusFilter>;
  _min?: InputMaybe<NestedEnumPackageStatusFilter>;
  equals?: InputMaybe<PackageStatus>;
  in?: InputMaybe<Array<PackageStatus>>;
  not?: InputMaybe<NestedEnumPackageStatusWithAggregatesFilter>;
  notIn?: InputMaybe<Array<PackageStatus>>;
};

export type NestedEnumPackageTypeFilter = {
  equals?: InputMaybe<PackageType>;
  in?: InputMaybe<Array<PackageType>>;
  not?: InputMaybe<NestedEnumPackageTypeFilter>;
  notIn?: InputMaybe<Array<PackageType>>;
};

export type NestedEnumPackageTypeWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumPackageTypeFilter>;
  _min?: InputMaybe<NestedEnumPackageTypeFilter>;
  equals?: InputMaybe<PackageType>;
  in?: InputMaybe<Array<PackageType>>;
  not?: InputMaybe<NestedEnumPackageTypeWithAggregatesFilter>;
  notIn?: InputMaybe<Array<PackageType>>;
};

export type NestedEnumPaymentMethodNullableFilter = {
  equals?: InputMaybe<PaymentMethod>;
  in?: InputMaybe<Array<PaymentMethod>>;
  not?: InputMaybe<NestedEnumPaymentMethodNullableFilter>;
  notIn?: InputMaybe<Array<PaymentMethod>>;
};

export type NestedEnumPaymentMethodNullableWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntNullableFilter>;
  _max?: InputMaybe<NestedEnumPaymentMethodNullableFilter>;
  _min?: InputMaybe<NestedEnumPaymentMethodNullableFilter>;
  equals?: InputMaybe<PaymentMethod>;
  in?: InputMaybe<Array<PaymentMethod>>;
  not?: InputMaybe<NestedEnumPaymentMethodNullableWithAggregatesFilter>;
  notIn?: InputMaybe<Array<PaymentMethod>>;
};

export type NestedEnumPaymentServiceProviderFilter = {
  equals?: InputMaybe<PaymentServiceProvider>;
  in?: InputMaybe<Array<PaymentServiceProvider>>;
  not?: InputMaybe<NestedEnumPaymentServiceProviderFilter>;
  notIn?: InputMaybe<Array<PaymentServiceProvider>>;
};

export type NestedEnumPaymentServiceProviderWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumPaymentServiceProviderFilter>;
  _min?: InputMaybe<NestedEnumPaymentServiceProviderFilter>;
  equals?: InputMaybe<PaymentServiceProvider>;
  in?: InputMaybe<Array<PaymentServiceProvider>>;
  not?: InputMaybe<NestedEnumPaymentServiceProviderWithAggregatesFilter>;
  notIn?: InputMaybe<Array<PaymentServiceProvider>>;
};

export type NestedEnumPrintStatusFilter = {
  equals?: InputMaybe<PrintStatus>;
  in?: InputMaybe<Array<PrintStatus>>;
  not?: InputMaybe<NestedEnumPrintStatusFilter>;
  notIn?: InputMaybe<Array<PrintStatus>>;
};

export type NestedEnumPrintStatusWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumPrintStatusFilter>;
  _min?: InputMaybe<NestedEnumPrintStatusFilter>;
  equals?: InputMaybe<PrintStatus>;
  in?: InputMaybe<Array<PrintStatus>>;
  not?: InputMaybe<NestedEnumPrintStatusWithAggregatesFilter>;
  notIn?: InputMaybe<Array<PrintStatus>>;
};

export type NestedEnumPrivacyLevelFilter = {
  equals?: InputMaybe<PrivacyLevel>;
  in?: InputMaybe<Array<PrivacyLevel>>;
  not?: InputMaybe<NestedEnumPrivacyLevelFilter>;
  notIn?: InputMaybe<Array<PrivacyLevel>>;
};

export type NestedEnumPrivacyLevelNullableFilter = {
  equals?: InputMaybe<PrivacyLevel>;
  in?: InputMaybe<Array<PrivacyLevel>>;
  not?: InputMaybe<NestedEnumPrivacyLevelNullableFilter>;
  notIn?: InputMaybe<Array<PrivacyLevel>>;
};

export type NestedEnumPrivacyLevelNullableWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntNullableFilter>;
  _max?: InputMaybe<NestedEnumPrivacyLevelNullableFilter>;
  _min?: InputMaybe<NestedEnumPrivacyLevelNullableFilter>;
  equals?: InputMaybe<PrivacyLevel>;
  in?: InputMaybe<Array<PrivacyLevel>>;
  not?: InputMaybe<NestedEnumPrivacyLevelNullableWithAggregatesFilter>;
  notIn?: InputMaybe<Array<PrivacyLevel>>;
};

export type NestedEnumPrivacyLevelWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumPrivacyLevelFilter>;
  _min?: InputMaybe<NestedEnumPrivacyLevelFilter>;
  equals?: InputMaybe<PrivacyLevel>;
  in?: InputMaybe<Array<PrivacyLevel>>;
  not?: InputMaybe<NestedEnumPrivacyLevelWithAggregatesFilter>;
  notIn?: InputMaybe<Array<PrivacyLevel>>;
};

export type NestedEnumProductTypeFilter = {
  equals?: InputMaybe<ProductType>;
  in?: InputMaybe<Array<ProductType>>;
  not?: InputMaybe<NestedEnumProductTypeFilter>;
  notIn?: InputMaybe<Array<ProductType>>;
};

export type NestedEnumProductTypeWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumProductTypeFilter>;
  _min?: InputMaybe<NestedEnumProductTypeFilter>;
  equals?: InputMaybe<ProductType>;
  in?: InputMaybe<Array<ProductType>>;
  not?: InputMaybe<NestedEnumProductTypeWithAggregatesFilter>;
  notIn?: InputMaybe<Array<ProductType>>;
};

export type NestedEnumProfitableClassFilter = {
  equals?: InputMaybe<ProfitableClass>;
  in?: InputMaybe<Array<ProfitableClass>>;
  not?: InputMaybe<NestedEnumProfitableClassFilter>;
  notIn?: InputMaybe<Array<ProfitableClass>>;
};

export type NestedEnumProfitableClassWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumProfitableClassFilter>;
  _min?: InputMaybe<NestedEnumProfitableClassFilter>;
  equals?: InputMaybe<ProfitableClass>;
  in?: InputMaybe<Array<ProfitableClass>>;
  not?: InputMaybe<NestedEnumProfitableClassWithAggregatesFilter>;
  notIn?: InputMaybe<Array<ProfitableClass>>;
};

export type NestedEnumProfitableTypeFilter = {
  equals?: InputMaybe<ProfitableType>;
  in?: InputMaybe<Array<ProfitableType>>;
  not?: InputMaybe<NestedEnumProfitableTypeFilter>;
  notIn?: InputMaybe<Array<ProfitableType>>;
};

export type NestedEnumProfitableTypeWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumProfitableTypeFilter>;
  _min?: InputMaybe<NestedEnumProfitableTypeFilter>;
  equals?: InputMaybe<ProfitableType>;
  in?: InputMaybe<Array<ProfitableType>>;
  not?: InputMaybe<NestedEnumProfitableTypeWithAggregatesFilter>;
  notIn?: InputMaybe<Array<ProfitableType>>;
};

export type NestedEnumQrcodeServiceTypeFilter = {
  equals?: InputMaybe<QrcodeServiceType>;
  in?: InputMaybe<Array<QrcodeServiceType>>;
  not?: InputMaybe<NestedEnumQrcodeServiceTypeFilter>;
  notIn?: InputMaybe<Array<QrcodeServiceType>>;
};

export type NestedEnumQrcodeServiceTypeWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumQrcodeServiceTypeFilter>;
  _min?: InputMaybe<NestedEnumQrcodeServiceTypeFilter>;
  equals?: InputMaybe<QrcodeServiceType>;
  in?: InputMaybe<Array<QrcodeServiceType>>;
  not?: InputMaybe<NestedEnumQrcodeServiceTypeWithAggregatesFilter>;
  notIn?: InputMaybe<Array<QrcodeServiceType>>;
};

export type NestedEnumReceiverSettingNotificationFilter = {
  equals?: InputMaybe<ReceiverSettingNotification>;
  in?: InputMaybe<Array<ReceiverSettingNotification>>;
  not?: InputMaybe<NestedEnumReceiverSettingNotificationFilter>;
  notIn?: InputMaybe<Array<ReceiverSettingNotification>>;
};

export type NestedEnumReceiverSettingNotificationWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumReceiverSettingNotificationFilter>;
  _min?: InputMaybe<NestedEnumReceiverSettingNotificationFilter>;
  equals?: InputMaybe<ReceiverSettingNotification>;
  in?: InputMaybe<Array<ReceiverSettingNotification>>;
  not?: InputMaybe<NestedEnumReceiverSettingNotificationWithAggregatesFilter>;
  notIn?: InputMaybe<Array<ReceiverSettingNotification>>;
};

export type NestedEnumReceiverSettingPrivacyFilter = {
  equals?: InputMaybe<ReceiverSettingPrivacy>;
  in?: InputMaybe<Array<ReceiverSettingPrivacy>>;
  not?: InputMaybe<NestedEnumReceiverSettingPrivacyFilter>;
  notIn?: InputMaybe<Array<ReceiverSettingPrivacy>>;
};

export type NestedEnumReceiverSettingPrivacyWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumReceiverSettingPrivacyFilter>;
  _min?: InputMaybe<NestedEnumReceiverSettingPrivacyFilter>;
  equals?: InputMaybe<ReceiverSettingPrivacy>;
  in?: InputMaybe<Array<ReceiverSettingPrivacy>>;
  not?: InputMaybe<NestedEnumReceiverSettingPrivacyWithAggregatesFilter>;
  notIn?: InputMaybe<Array<ReceiverSettingPrivacy>>;
};

export type NestedEnumReportTypeFilter = {
  equals?: InputMaybe<ReportType>;
  in?: InputMaybe<Array<ReportType>>;
  not?: InputMaybe<NestedEnumReportTypeFilter>;
  notIn?: InputMaybe<Array<ReportType>>;
};

export type NestedEnumReportTypeWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumReportTypeFilter>;
  _min?: InputMaybe<NestedEnumReportTypeFilter>;
  equals?: InputMaybe<ReportType>;
  in?: InputMaybe<Array<ReportType>>;
  not?: InputMaybe<NestedEnumReportTypeWithAggregatesFilter>;
  notIn?: InputMaybe<Array<ReportType>>;
};

export type NestedEnumReportUserTypeFilter = {
  equals?: InputMaybe<ReportUserType>;
  in?: InputMaybe<Array<ReportUserType>>;
  not?: InputMaybe<NestedEnumReportUserTypeFilter>;
  notIn?: InputMaybe<Array<ReportUserType>>;
};

export type NestedEnumReportUserTypeWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumReportUserTypeFilter>;
  _min?: InputMaybe<NestedEnumReportUserTypeFilter>;
  equals?: InputMaybe<ReportUserType>;
  in?: InputMaybe<Array<ReportUserType>>;
  not?: InputMaybe<NestedEnumReportUserTypeWithAggregatesFilter>;
  notIn?: InputMaybe<Array<ReportUserType>>;
};

export type NestedEnumSenderSettingNotificationFilter = {
  equals?: InputMaybe<SenderSettingNotification>;
  in?: InputMaybe<Array<SenderSettingNotification>>;
  not?: InputMaybe<NestedEnumSenderSettingNotificationFilter>;
  notIn?: InputMaybe<Array<SenderSettingNotification>>;
};

export type NestedEnumSenderSettingNotificationWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumSenderSettingNotificationFilter>;
  _min?: InputMaybe<NestedEnumSenderSettingNotificationFilter>;
  equals?: InputMaybe<SenderSettingNotification>;
  in?: InputMaybe<Array<SenderSettingNotification>>;
  not?: InputMaybe<NestedEnumSenderSettingNotificationWithAggregatesFilter>;
  notIn?: InputMaybe<Array<SenderSettingNotification>>;
};

export type NestedEnumSendingTypeFilter = {
  equals?: InputMaybe<SendingType>;
  in?: InputMaybe<Array<SendingType>>;
  not?: InputMaybe<NestedEnumSendingTypeFilter>;
  notIn?: InputMaybe<Array<SendingType>>;
};

export type NestedEnumSendingTypeWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumSendingTypeFilter>;
  _min?: InputMaybe<NestedEnumSendingTypeFilter>;
  equals?: InputMaybe<SendingType>;
  in?: InputMaybe<Array<SendingType>>;
  not?: InputMaybe<NestedEnumSendingTypeWithAggregatesFilter>;
  notIn?: InputMaybe<Array<SendingType>>;
};

export type NestedEnumStoreItemTagClassFilter = {
  equals?: InputMaybe<StoreItemTagClass>;
  in?: InputMaybe<Array<StoreItemTagClass>>;
  not?: InputMaybe<NestedEnumStoreItemTagClassFilter>;
  notIn?: InputMaybe<Array<StoreItemTagClass>>;
};

export type NestedEnumStoreItemTagClassWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumStoreItemTagClassFilter>;
  _min?: InputMaybe<NestedEnumStoreItemTagClassFilter>;
  equals?: InputMaybe<StoreItemTagClass>;
  in?: InputMaybe<Array<StoreItemTagClass>>;
  not?: InputMaybe<NestedEnumStoreItemTagClassWithAggregatesFilter>;
  notIn?: InputMaybe<Array<StoreItemTagClass>>;
};

export type NestedEnumStoreItemTypeFilter = {
  equals?: InputMaybe<StoreItemType>;
  in?: InputMaybe<Array<StoreItemType>>;
  not?: InputMaybe<NestedEnumStoreItemTypeFilter>;
  notIn?: InputMaybe<Array<StoreItemType>>;
};

export type NestedEnumStoreItemTypeWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumStoreItemTypeFilter>;
  _min?: InputMaybe<NestedEnumStoreItemTypeFilter>;
  equals?: InputMaybe<StoreItemType>;
  in?: InputMaybe<Array<StoreItemType>>;
  not?: InputMaybe<NestedEnumStoreItemTypeWithAggregatesFilter>;
  notIn?: InputMaybe<Array<StoreItemType>>;
};

export type NestedEnumTappayMethodTypeFilter = {
  equals?: InputMaybe<TappayMethodType>;
  in?: InputMaybe<Array<TappayMethodType>>;
  not?: InputMaybe<NestedEnumTappayMethodTypeFilter>;
  notIn?: InputMaybe<Array<TappayMethodType>>;
};

export type NestedEnumTappayMethodTypeWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumTappayMethodTypeFilter>;
  _min?: InputMaybe<NestedEnumTappayMethodTypeFilter>;
  equals?: InputMaybe<TappayMethodType>;
  in?: InputMaybe<Array<TappayMethodType>>;
  not?: InputMaybe<NestedEnumTappayMethodTypeWithAggregatesFilter>;
  notIn?: InputMaybe<Array<TappayMethodType>>;
};

export type NestedEnumUserAuthTypeFilter = {
  equals?: InputMaybe<UserAuthType>;
  in?: InputMaybe<Array<UserAuthType>>;
  not?: InputMaybe<NestedEnumUserAuthTypeFilter>;
  notIn?: InputMaybe<Array<UserAuthType>>;
};

export type NestedEnumUserAuthTypeWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumUserAuthTypeFilter>;
  _min?: InputMaybe<NestedEnumUserAuthTypeFilter>;
  equals?: InputMaybe<UserAuthType>;
  in?: InputMaybe<Array<UserAuthType>>;
  not?: InputMaybe<NestedEnumUserAuthTypeWithAggregatesFilter>;
  notIn?: InputMaybe<Array<UserAuthType>>;
};

export type NestedFloatFilter = {
  equals?: InputMaybe<Scalars['Float']>;
  gt?: InputMaybe<Scalars['Float']>;
  gte?: InputMaybe<Scalars['Float']>;
  in?: InputMaybe<Array<Scalars['Float']>>;
  lt?: InputMaybe<Scalars['Float']>;
  lte?: InputMaybe<Scalars['Float']>;
  not?: InputMaybe<NestedFloatFilter>;
  notIn?: InputMaybe<Array<Scalars['Float']>>;
};

export type NestedFloatNullableFilter = {
  equals?: InputMaybe<Scalars['Float']>;
  gt?: InputMaybe<Scalars['Float']>;
  gte?: InputMaybe<Scalars['Float']>;
  in?: InputMaybe<Array<Scalars['Float']>>;
  lt?: InputMaybe<Scalars['Float']>;
  lte?: InputMaybe<Scalars['Float']>;
  not?: InputMaybe<NestedFloatNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['Float']>>;
};

export type NestedFloatNullableWithAggregatesFilter = {
  _avg?: InputMaybe<NestedFloatNullableFilter>;
  _count?: InputMaybe<NestedIntNullableFilter>;
  _max?: InputMaybe<NestedFloatNullableFilter>;
  _min?: InputMaybe<NestedFloatNullableFilter>;
  _sum?: InputMaybe<NestedFloatNullableFilter>;
  equals?: InputMaybe<Scalars['Float']>;
  gt?: InputMaybe<Scalars['Float']>;
  gte?: InputMaybe<Scalars['Float']>;
  in?: InputMaybe<Array<Scalars['Float']>>;
  lt?: InputMaybe<Scalars['Float']>;
  lte?: InputMaybe<Scalars['Float']>;
  not?: InputMaybe<NestedFloatNullableWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['Float']>>;
};

export type NestedFloatWithAggregatesFilter = {
  _avg?: InputMaybe<NestedFloatFilter>;
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedFloatFilter>;
  _min?: InputMaybe<NestedFloatFilter>;
  _sum?: InputMaybe<NestedFloatFilter>;
  equals?: InputMaybe<Scalars['Float']>;
  gt?: InputMaybe<Scalars['Float']>;
  gte?: InputMaybe<Scalars['Float']>;
  in?: InputMaybe<Array<Scalars['Float']>>;
  lt?: InputMaybe<Scalars['Float']>;
  lte?: InputMaybe<Scalars['Float']>;
  not?: InputMaybe<NestedFloatWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['Float']>>;
};

export type NestedIntFilter = {
  equals?: InputMaybe<Scalars['Int']>;
  gt?: InputMaybe<Scalars['Int']>;
  gte?: InputMaybe<Scalars['Int']>;
  in?: InputMaybe<Array<Scalars['Int']>>;
  lt?: InputMaybe<Scalars['Int']>;
  lte?: InputMaybe<Scalars['Int']>;
  not?: InputMaybe<NestedIntFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']>>;
};

export type NestedIntNullableFilter = {
  equals?: InputMaybe<Scalars['Int']>;
  gt?: InputMaybe<Scalars['Int']>;
  gte?: InputMaybe<Scalars['Int']>;
  in?: InputMaybe<Array<Scalars['Int']>>;
  lt?: InputMaybe<Scalars['Int']>;
  lte?: InputMaybe<Scalars['Int']>;
  not?: InputMaybe<NestedIntNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']>>;
};

export type NestedIntNullableWithAggregatesFilter = {
  _avg?: InputMaybe<NestedFloatNullableFilter>;
  _count?: InputMaybe<NestedIntNullableFilter>;
  _max?: InputMaybe<NestedIntNullableFilter>;
  _min?: InputMaybe<NestedIntNullableFilter>;
  _sum?: InputMaybe<NestedIntNullableFilter>;
  equals?: InputMaybe<Scalars['Int']>;
  gt?: InputMaybe<Scalars['Int']>;
  gte?: InputMaybe<Scalars['Int']>;
  in?: InputMaybe<Array<Scalars['Int']>>;
  lt?: InputMaybe<Scalars['Int']>;
  lte?: InputMaybe<Scalars['Int']>;
  not?: InputMaybe<NestedIntNullableWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']>>;
};

export type NestedIntWithAggregatesFilter = {
  _avg?: InputMaybe<NestedFloatFilter>;
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedIntFilter>;
  _min?: InputMaybe<NestedIntFilter>;
  _sum?: InputMaybe<NestedIntFilter>;
  equals?: InputMaybe<Scalars['Int']>;
  gt?: InputMaybe<Scalars['Int']>;
  gte?: InputMaybe<Scalars['Int']>;
  in?: InputMaybe<Array<Scalars['Int']>>;
  lt?: InputMaybe<Scalars['Int']>;
  lte?: InputMaybe<Scalars['Int']>;
  not?: InputMaybe<NestedIntWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']>>;
};

export type NestedJsonNullableFilter = {
  array_contains?: InputMaybe<Scalars['JSON']>;
  array_ends_with?: InputMaybe<Scalars['JSON']>;
  array_starts_with?: InputMaybe<Scalars['JSON']>;
  equals?: InputMaybe<Scalars['JSON']>;
  gt?: InputMaybe<Scalars['JSON']>;
  gte?: InputMaybe<Scalars['JSON']>;
  lt?: InputMaybe<Scalars['JSON']>;
  lte?: InputMaybe<Scalars['JSON']>;
  not?: InputMaybe<Scalars['JSON']>;
  path?: InputMaybe<Array<Scalars['String']>>;
  string_contains?: InputMaybe<Scalars['String']>;
  string_ends_with?: InputMaybe<Scalars['String']>;
  string_starts_with?: InputMaybe<Scalars['String']>;
};

export type NestedStringFilter = {
  contains?: InputMaybe<Scalars['String']>;
  endsWith?: InputMaybe<Scalars['String']>;
  equals?: InputMaybe<Scalars['String']>;
  gt?: InputMaybe<Scalars['String']>;
  gte?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<Scalars['String']>>;
  lt?: InputMaybe<Scalars['String']>;
  lte?: InputMaybe<Scalars['String']>;
  not?: InputMaybe<NestedStringFilter>;
  notIn?: InputMaybe<Array<Scalars['String']>>;
  startsWith?: InputMaybe<Scalars['String']>;
};

export type NestedStringNullableFilter = {
  contains?: InputMaybe<Scalars['String']>;
  endsWith?: InputMaybe<Scalars['String']>;
  equals?: InputMaybe<Scalars['String']>;
  gt?: InputMaybe<Scalars['String']>;
  gte?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<Scalars['String']>>;
  lt?: InputMaybe<Scalars['String']>;
  lte?: InputMaybe<Scalars['String']>;
  not?: InputMaybe<NestedStringNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['String']>>;
  startsWith?: InputMaybe<Scalars['String']>;
};

export type NestedStringNullableWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntNullableFilter>;
  _max?: InputMaybe<NestedStringNullableFilter>;
  _min?: InputMaybe<NestedStringNullableFilter>;
  contains?: InputMaybe<Scalars['String']>;
  endsWith?: InputMaybe<Scalars['String']>;
  equals?: InputMaybe<Scalars['String']>;
  gt?: InputMaybe<Scalars['String']>;
  gte?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<Scalars['String']>>;
  lt?: InputMaybe<Scalars['String']>;
  lte?: InputMaybe<Scalars['String']>;
  not?: InputMaybe<NestedStringNullableWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['String']>>;
  startsWith?: InputMaybe<Scalars['String']>;
};

export type NestedStringWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedStringFilter>;
  _min?: InputMaybe<NestedStringFilter>;
  contains?: InputMaybe<Scalars['String']>;
  endsWith?: InputMaybe<Scalars['String']>;
  equals?: InputMaybe<Scalars['String']>;
  gt?: InputMaybe<Scalars['String']>;
  gte?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<Scalars['String']>>;
  lt?: InputMaybe<Scalars['String']>;
  lte?: InputMaybe<Scalars['String']>;
  not?: InputMaybe<NestedStringWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['String']>>;
  startsWith?: InputMaybe<Scalars['String']>;
};

export type Notification = {
  __typename?: 'Notification';
  body: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  data?: Maybe<Scalars['JSON']>;
  id: Scalars['String'];
  receiver: User;
  receiverId: Scalars['String'];
  sendDate?: Maybe<Scalars['DateTime']>;
  sender: User;
  senderId: Scalars['String'];
  status: NotificationStatus;
  title: Scalars['String'];
  type: NotificationType;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type NotificationCountAggregate = {
  __typename?: 'NotificationCountAggregate';
  _all: Scalars['Int'];
  body: Scalars['Int'];
  createdAt: Scalars['Int'];
  data: Scalars['Int'];
  id: Scalars['Int'];
  receiverId: Scalars['Int'];
  sendDate: Scalars['Int'];
  senderId: Scalars['Int'];
  status: Scalars['Int'];
  title: Scalars['Int'];
  type: Scalars['Int'];
  updatedAt: Scalars['Int'];
};

export type NotificationCountOrderByAggregateInput = {
  body?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  data?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  receiverId?: InputMaybe<SortOrder>;
  sendDate?: InputMaybe<SortOrder>;
  senderId?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  title?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type NotificationCreateInput = {
  body: Scalars['String'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  data?: InputMaybe<Scalars['JSON']>;
  id?: InputMaybe<Scalars['String']>;
  receiver: UserCreateNestedOneWithoutNotificationReceiverInput;
  sendDate?: InputMaybe<Scalars['DateTime']>;
  sender: UserCreateNestedOneWithoutNotificationSenderInput;
  status: NotificationStatus;
  title: Scalars['String'];
  type: NotificationType;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type NotificationCreateManyInput = {
  body: Scalars['String'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  data?: InputMaybe<Scalars['JSON']>;
  id?: InputMaybe<Scalars['String']>;
  receiverId: Scalars['String'];
  sendDate?: InputMaybe<Scalars['DateTime']>;
  senderId: Scalars['String'];
  status: NotificationStatus;
  title: Scalars['String'];
  type: NotificationType;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type NotificationCreateManyReceiverInput = {
  body: Scalars['String'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  data?: InputMaybe<Scalars['JSON']>;
  id?: InputMaybe<Scalars['String']>;
  sendDate?: InputMaybe<Scalars['DateTime']>;
  senderId: Scalars['String'];
  status: NotificationStatus;
  title: Scalars['String'];
  type: NotificationType;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type NotificationCreateManyReceiverInputEnvelope = {
  data: Array<NotificationCreateManyReceiverInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type NotificationCreateManySenderInput = {
  body: Scalars['String'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  data?: InputMaybe<Scalars['JSON']>;
  id?: InputMaybe<Scalars['String']>;
  receiverId: Scalars['String'];
  sendDate?: InputMaybe<Scalars['DateTime']>;
  status: NotificationStatus;
  title: Scalars['String'];
  type: NotificationType;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type NotificationCreateManySenderInputEnvelope = {
  data: Array<NotificationCreateManySenderInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type NotificationCreateNestedManyWithoutReceiverInput = {
  connect?: InputMaybe<Array<NotificationWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<NotificationCreateOrConnectWithoutReceiverInput>>;
  create?: InputMaybe<Array<NotificationCreateWithoutReceiverInput>>;
  createMany?: InputMaybe<NotificationCreateManyReceiverInputEnvelope>;
};

export type NotificationCreateNestedManyWithoutSenderInput = {
  connect?: InputMaybe<Array<NotificationWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<NotificationCreateOrConnectWithoutSenderInput>>;
  create?: InputMaybe<Array<NotificationCreateWithoutSenderInput>>;
  createMany?: InputMaybe<NotificationCreateManySenderInputEnvelope>;
};

export type NotificationCreateOrConnectWithoutReceiverInput = {
  create: NotificationCreateWithoutReceiverInput;
  where: NotificationWhereUniqueInput;
};

export type NotificationCreateOrConnectWithoutSenderInput = {
  create: NotificationCreateWithoutSenderInput;
  where: NotificationWhereUniqueInput;
};

export type NotificationCreateWithoutReceiverInput = {
  body: Scalars['String'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  data?: InputMaybe<Scalars['JSON']>;
  id?: InputMaybe<Scalars['String']>;
  sendDate?: InputMaybe<Scalars['DateTime']>;
  sender: UserCreateNestedOneWithoutNotificationSenderInput;
  status: NotificationStatus;
  title: Scalars['String'];
  type: NotificationType;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type NotificationCreateWithoutSenderInput = {
  body: Scalars['String'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  data?: InputMaybe<Scalars['JSON']>;
  id?: InputMaybe<Scalars['String']>;
  receiver: UserCreateNestedOneWithoutNotificationReceiverInput;
  sendDate?: InputMaybe<Scalars['DateTime']>;
  status: NotificationStatus;
  title: Scalars['String'];
  type: NotificationType;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type NotificationGroupBy = {
  __typename?: 'NotificationGroupBy';
  _count?: Maybe<NotificationCountAggregate>;
  _max?: Maybe<NotificationMaxAggregate>;
  _min?: Maybe<NotificationMinAggregate>;
  body: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  data?: Maybe<Scalars['JSON']>;
  id: Scalars['String'];
  receiverId: Scalars['String'];
  sendDate?: Maybe<Scalars['DateTime']>;
  senderId: Scalars['String'];
  status: NotificationStatus;
  title: Scalars['String'];
  type: NotificationType;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type NotificationListRelationFilter = {
  every?: InputMaybe<NotificationWhereInput>;
  none?: InputMaybe<NotificationWhereInput>;
  some?: InputMaybe<NotificationWhereInput>;
};

export type NotificationMaxAggregate = {
  __typename?: 'NotificationMaxAggregate';
  body?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  receiverId?: Maybe<Scalars['String']>;
  sendDate?: Maybe<Scalars['DateTime']>;
  senderId?: Maybe<Scalars['String']>;
  status?: Maybe<NotificationStatus>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<NotificationType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type NotificationMaxOrderByAggregateInput = {
  body?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  receiverId?: InputMaybe<SortOrder>;
  sendDate?: InputMaybe<SortOrder>;
  senderId?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  title?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type NotificationMinAggregate = {
  __typename?: 'NotificationMinAggregate';
  body?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  receiverId?: Maybe<Scalars['String']>;
  sendDate?: Maybe<Scalars['DateTime']>;
  senderId?: Maybe<Scalars['String']>;
  status?: Maybe<NotificationStatus>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<NotificationType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type NotificationMinOrderByAggregateInput = {
  body?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  receiverId?: InputMaybe<SortOrder>;
  sendDate?: InputMaybe<SortOrder>;
  senderId?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  title?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type NotificationOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type NotificationOrderByWithAggregationInput = {
  _count?: InputMaybe<NotificationCountOrderByAggregateInput>;
  _max?: InputMaybe<NotificationMaxOrderByAggregateInput>;
  _min?: InputMaybe<NotificationMinOrderByAggregateInput>;
  body?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  data?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  receiverId?: InputMaybe<SortOrder>;
  sendDate?: InputMaybe<SortOrder>;
  senderId?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  title?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type NotificationOrderByWithRelationInput = {
  body?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  data?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  receiver?: InputMaybe<UserOrderByWithRelationInput>;
  receiverId?: InputMaybe<SortOrder>;
  sendDate?: InputMaybe<SortOrder>;
  sender?: InputMaybe<UserOrderByWithRelationInput>;
  senderId?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  title?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export enum NotificationScalarFieldEnum {
  Body = 'body',
  CreatedAt = 'createdAt',
  Data = 'data',
  Id = 'id',
  ReceiverId = 'receiverId',
  SendDate = 'sendDate',
  SenderId = 'senderId',
  Status = 'status',
  Title = 'title',
  Type = 'type',
  UpdatedAt = 'updatedAt'
}

export type NotificationScalarWhereInput = {
  AND?: InputMaybe<Array<NotificationScalarWhereInput>>;
  NOT?: InputMaybe<Array<NotificationScalarWhereInput>>;
  OR?: InputMaybe<Array<NotificationScalarWhereInput>>;
  body?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeNullableFilter>;
  data?: InputMaybe<JsonNullableFilter>;
  id?: InputMaybe<StringFilter>;
  receiverId?: InputMaybe<StringFilter>;
  sendDate?: InputMaybe<DateTimeNullableFilter>;
  senderId?: InputMaybe<StringFilter>;
  status?: InputMaybe<EnumNotificationStatusFilter>;
  title?: InputMaybe<StringFilter>;
  type?: InputMaybe<EnumNotificationTypeFilter>;
  updatedAt?: InputMaybe<DateTimeNullableFilter>;
};

export type NotificationScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<NotificationScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<NotificationScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<NotificationScalarWhereWithAggregatesInput>>;
  body?: InputMaybe<StringWithAggregatesFilter>;
  createdAt?: InputMaybe<DateTimeNullableWithAggregatesFilter>;
  data?: InputMaybe<JsonNullableWithAggregatesFilter>;
  id?: InputMaybe<StringWithAggregatesFilter>;
  receiverId?: InputMaybe<StringWithAggregatesFilter>;
  sendDate?: InputMaybe<DateTimeNullableWithAggregatesFilter>;
  senderId?: InputMaybe<StringWithAggregatesFilter>;
  status?: InputMaybe<EnumNotificationStatusWithAggregatesFilter>;
  title?: InputMaybe<StringWithAggregatesFilter>;
  type?: InputMaybe<EnumNotificationTypeWithAggregatesFilter>;
  updatedAt?: InputMaybe<DateTimeNullableWithAggregatesFilter>;
};

export enum NotificationStatus {
  Read = 'read',
  Sent = 'sent',
  WaitToSend = 'waitToSend'
}

export enum NotificationType {
  AddCollection = 'ADD_COLLECTION',
  Follower = 'FOLLOWER',
  Friend = 'FRIEND',
  LikeComment = 'LIKE_COMMENT',
  Message = 'MESSAGE',
  ReceivedSent = 'RECEIVED_SENT'
}

export type NotificationUpdateInput = {
  body?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  data?: InputMaybe<Scalars['JSON']>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  receiver?: InputMaybe<UserUpdateOneRequiredWithoutNotificationReceiverNestedInput>;
  sendDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  sender?: InputMaybe<UserUpdateOneRequiredWithoutNotificationSenderNestedInput>;
  status?: InputMaybe<EnumNotificationStatusFieldUpdateOperationsInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
  type?: InputMaybe<EnumNotificationTypeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
};

export type NotificationUpdateManyMutationInput = {
  body?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  data?: InputMaybe<Scalars['JSON']>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  sendDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumNotificationStatusFieldUpdateOperationsInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
  type?: InputMaybe<EnumNotificationTypeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
};

export type NotificationUpdateManyWithWhereWithoutReceiverInput = {
  data: NotificationUpdateManyMutationInput;
  where: NotificationScalarWhereInput;
};

export type NotificationUpdateManyWithWhereWithoutSenderInput = {
  data: NotificationUpdateManyMutationInput;
  where: NotificationScalarWhereInput;
};

export type NotificationUpdateManyWithoutReceiverNestedInput = {
  connect?: InputMaybe<Array<NotificationWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<NotificationCreateOrConnectWithoutReceiverInput>>;
  create?: InputMaybe<Array<NotificationCreateWithoutReceiverInput>>;
  createMany?: InputMaybe<NotificationCreateManyReceiverInputEnvelope>;
  delete?: InputMaybe<Array<NotificationWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<NotificationScalarWhereInput>>;
  disconnect?: InputMaybe<Array<NotificationWhereUniqueInput>>;
  set?: InputMaybe<Array<NotificationWhereUniqueInput>>;
  update?: InputMaybe<Array<NotificationUpdateWithWhereUniqueWithoutReceiverInput>>;
  updateMany?: InputMaybe<Array<NotificationUpdateManyWithWhereWithoutReceiverInput>>;
  upsert?: InputMaybe<Array<NotificationUpsertWithWhereUniqueWithoutReceiverInput>>;
};

export type NotificationUpdateManyWithoutSenderNestedInput = {
  connect?: InputMaybe<Array<NotificationWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<NotificationCreateOrConnectWithoutSenderInput>>;
  create?: InputMaybe<Array<NotificationCreateWithoutSenderInput>>;
  createMany?: InputMaybe<NotificationCreateManySenderInputEnvelope>;
  delete?: InputMaybe<Array<NotificationWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<NotificationScalarWhereInput>>;
  disconnect?: InputMaybe<Array<NotificationWhereUniqueInput>>;
  set?: InputMaybe<Array<NotificationWhereUniqueInput>>;
  update?: InputMaybe<Array<NotificationUpdateWithWhereUniqueWithoutSenderInput>>;
  updateMany?: InputMaybe<Array<NotificationUpdateManyWithWhereWithoutSenderInput>>;
  upsert?: InputMaybe<Array<NotificationUpsertWithWhereUniqueWithoutSenderInput>>;
};

export type NotificationUpdateWithWhereUniqueWithoutReceiverInput = {
  data: NotificationUpdateWithoutReceiverInput;
  where: NotificationWhereUniqueInput;
};

export type NotificationUpdateWithWhereUniqueWithoutSenderInput = {
  data: NotificationUpdateWithoutSenderInput;
  where: NotificationWhereUniqueInput;
};

export type NotificationUpdateWithoutReceiverInput = {
  body?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  data?: InputMaybe<Scalars['JSON']>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  sendDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  sender?: InputMaybe<UserUpdateOneRequiredWithoutNotificationSenderNestedInput>;
  status?: InputMaybe<EnumNotificationStatusFieldUpdateOperationsInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
  type?: InputMaybe<EnumNotificationTypeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
};

export type NotificationUpdateWithoutSenderInput = {
  body?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  data?: InputMaybe<Scalars['JSON']>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  receiver?: InputMaybe<UserUpdateOneRequiredWithoutNotificationReceiverNestedInput>;
  sendDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumNotificationStatusFieldUpdateOperationsInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
  type?: InputMaybe<EnumNotificationTypeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
};

export type NotificationUpsertWithWhereUniqueWithoutReceiverInput = {
  create: NotificationCreateWithoutReceiverInput;
  update: NotificationUpdateWithoutReceiverInput;
  where: NotificationWhereUniqueInput;
};

export type NotificationUpsertWithWhereUniqueWithoutSenderInput = {
  create: NotificationCreateWithoutSenderInput;
  update: NotificationUpdateWithoutSenderInput;
  where: NotificationWhereUniqueInput;
};

export type NotificationWhereInput = {
  AND?: InputMaybe<Array<NotificationWhereInput>>;
  NOT?: InputMaybe<Array<NotificationWhereInput>>;
  OR?: InputMaybe<Array<NotificationWhereInput>>;
  body?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeNullableFilter>;
  data?: InputMaybe<JsonNullableFilter>;
  id?: InputMaybe<StringFilter>;
  receiver?: InputMaybe<UserRelationFilter>;
  receiverId?: InputMaybe<StringFilter>;
  sendDate?: InputMaybe<DateTimeNullableFilter>;
  sender?: InputMaybe<UserRelationFilter>;
  senderId?: InputMaybe<StringFilter>;
  status?: InputMaybe<EnumNotificationStatusFilter>;
  title?: InputMaybe<StringFilter>;
  type?: InputMaybe<EnumNotificationTypeFilter>;
  updatedAt?: InputMaybe<DateTimeNullableFilter>;
};

export type NotificationWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
};

export type NullableBoolFieldUpdateOperationsInput = {
  set?: InputMaybe<Scalars['Boolean']>;
};

export type NullableDateTimeFieldUpdateOperationsInput = {
  set?: InputMaybe<Scalars['DateTime']>;
};

export type NullableDecimalFieldUpdateOperationsInput = {
  decrement?: InputMaybe<Scalars['Decimal']>;
  divide?: InputMaybe<Scalars['Decimal']>;
  increment?: InputMaybe<Scalars['Decimal']>;
  multiply?: InputMaybe<Scalars['Decimal']>;
  set?: InputMaybe<Scalars['Decimal']>;
};

export type NullableEnumPaymentMethodFieldUpdateOperationsInput = {
  set?: InputMaybe<PaymentMethod>;
};

export type NullableEnumPrivacyLevelFieldUpdateOperationsInput = {
  set?: InputMaybe<PrivacyLevel>;
};

export type NullableFloatFieldUpdateOperationsInput = {
  decrement?: InputMaybe<Scalars['Float']>;
  divide?: InputMaybe<Scalars['Float']>;
  increment?: InputMaybe<Scalars['Float']>;
  multiply?: InputMaybe<Scalars['Float']>;
  set?: InputMaybe<Scalars['Float']>;
};

export type NullableIntFieldUpdateOperationsInput = {
  decrement?: InputMaybe<Scalars['Int']>;
  divide?: InputMaybe<Scalars['Int']>;
  increment?: InputMaybe<Scalars['Int']>;
  multiply?: InputMaybe<Scalars['Int']>;
  set?: InputMaybe<Scalars['Int']>;
};

export type NullableStringFieldUpdateOperationsInput = {
  set?: InputMaybe<Scalars['String']>;
};

export type Order = {
  __typename?: 'Order';
  _count?: Maybe<OrderCount>;
  benefitMemos: Array<BenefitMemoToOrder>;
  coin?: Maybe<Coin>;
  couponEntities: Array<CouponEntity>;
  createdAt: Scalars['DateTime'];
  details: Array<OrderDetail>;
  id: Scalars['String'];
  invoice: Array<Invoice>;
  invoiceParam?: Maybe<Scalars['JSON']>;
  isInvoiced: Scalars['Boolean'];
  isPaid: Scalars['Boolean'];
  payment: Array<Payment>;
  postPackage: PostPackage;
  postPackageId: Scalars['String'];
  price: Scalars['Decimal'];
  readableId?: Maybe<Scalars['String']>;
  refundPayment?: Maybe<Payment>;
  refundPaymentId?: Maybe<Scalars['String']>;
  sender: User;
  senderId: Scalars['String'];
  status: OrderStatus;
  updatedAt: Scalars['DateTime'];
};


export type OrderBenefitMemosArgs = {
  cursor?: InputMaybe<BenefitMemoToOrderWhereUniqueInput>;
  distinct?: InputMaybe<Array<BenefitMemoToOrderScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<BenefitMemoToOrderOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<BenefitMemoToOrderWhereInput>;
};


export type OrderCouponEntitiesArgs = {
  cursor?: InputMaybe<CouponEntityWhereUniqueInput>;
  distinct?: InputMaybe<Array<CouponEntityScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<CouponEntityOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CouponEntityWhereInput>;
};


export type OrderDetailsArgs = {
  cursor?: InputMaybe<OrderDetailWhereUniqueInput>;
  distinct?: InputMaybe<Array<OrderDetailScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<OrderDetailOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<OrderDetailWhereInput>;
};


export type OrderInvoiceArgs = {
  cursor?: InputMaybe<InvoiceWhereUniqueInput>;
  distinct?: InputMaybe<Array<InvoiceScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<InvoiceOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<InvoiceWhereInput>;
};


export type OrderPaymentArgs = {
  cursor?: InputMaybe<PaymentWhereUniqueInput>;
  distinct?: InputMaybe<Array<PaymentScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<PaymentOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PaymentWhereInput>;
};

export type OrderAvgAggregate = {
  __typename?: 'OrderAvgAggregate';
  price?: Maybe<Scalars['Decimal']>;
};

export type OrderAvgOrderByAggregateInput = {
  price?: InputMaybe<SortOrder>;
};

export type OrderCount = {
  __typename?: 'OrderCount';
  benefitMemos: Scalars['Int'];
  couponEntities: Scalars['Int'];
  details: Scalars['Int'];
  invoice: Scalars['Int'];
  payment: Scalars['Int'];
};


export type OrderCountBenefitMemosArgs = {
  where?: InputMaybe<BenefitMemoToOrderWhereInput>;
};


export type OrderCountCouponEntitiesArgs = {
  where?: InputMaybe<CouponEntityWhereInput>;
};


export type OrderCountDetailsArgs = {
  where?: InputMaybe<OrderDetailWhereInput>;
};


export type OrderCountInvoiceArgs = {
  where?: InputMaybe<InvoiceWhereInput>;
};


export type OrderCountPaymentArgs = {
  where?: InputMaybe<PaymentWhereInput>;
};

export type OrderCountAggregate = {
  __typename?: 'OrderCountAggregate';
  _all: Scalars['Int'];
  createdAt: Scalars['Int'];
  id: Scalars['Int'];
  invoiceParam: Scalars['Int'];
  isInvoiced: Scalars['Int'];
  isPaid: Scalars['Int'];
  postPackageId: Scalars['Int'];
  price: Scalars['Int'];
  readableId: Scalars['Int'];
  refundPaymentId: Scalars['Int'];
  senderId: Scalars['Int'];
  status: Scalars['Int'];
  updatedAt: Scalars['Int'];
};

export type OrderCountOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  invoiceParam?: InputMaybe<SortOrder>;
  isInvoiced?: InputMaybe<SortOrder>;
  isPaid?: InputMaybe<SortOrder>;
  postPackageId?: InputMaybe<SortOrder>;
  price?: InputMaybe<SortOrder>;
  readableId?: InputMaybe<SortOrder>;
  refundPaymentId?: InputMaybe<SortOrder>;
  senderId?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type OrderCreateInput = {
  benefitMemos?: InputMaybe<BenefitMemoToOrderCreateNestedManyWithoutOrderInput>;
  coin?: InputMaybe<CoinCreateNestedOneWithoutOrderInput>;
  couponEntities?: InputMaybe<CouponEntityCreateNestedManyWithoutConsumeOrderInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  details?: InputMaybe<OrderDetailCreateNestedManyWithoutOrderInput>;
  id?: InputMaybe<Scalars['String']>;
  invoice?: InputMaybe<InvoiceCreateNestedManyWithoutOrderInput>;
  invoiceParam?: InputMaybe<Scalars['JSON']>;
  isInvoiced?: InputMaybe<Scalars['Boolean']>;
  isPaid?: InputMaybe<Scalars['Boolean']>;
  payment?: InputMaybe<PaymentCreateNestedManyWithoutOrderInput>;
  postPackage: PostPackageCreateNestedOneWithoutOrderInput;
  price: Scalars['Decimal'];
  readableId?: InputMaybe<Scalars['String']>;
  refundPayment?: InputMaybe<PaymentCreateNestedOneWithoutRefundOrdersInput>;
  sender: UserCreateNestedOneWithoutOrderInput;
  status: OrderStatus;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type OrderCreateManyInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  invoiceParam?: InputMaybe<Scalars['JSON']>;
  isInvoiced?: InputMaybe<Scalars['Boolean']>;
  isPaid?: InputMaybe<Scalars['Boolean']>;
  postPackageId: Scalars['String'];
  price: Scalars['Decimal'];
  readableId?: InputMaybe<Scalars['String']>;
  refundPaymentId?: InputMaybe<Scalars['String']>;
  senderId: Scalars['String'];
  status: OrderStatus;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type OrderCreateManyRefundPaymentInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  invoiceParam?: InputMaybe<Scalars['JSON']>;
  isInvoiced?: InputMaybe<Scalars['Boolean']>;
  isPaid?: InputMaybe<Scalars['Boolean']>;
  postPackageId: Scalars['String'];
  price: Scalars['Decimal'];
  readableId?: InputMaybe<Scalars['String']>;
  senderId: Scalars['String'];
  status: OrderStatus;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type OrderCreateManyRefundPaymentInputEnvelope = {
  data: Array<OrderCreateManyRefundPaymentInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type OrderCreateManySenderInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  invoiceParam?: InputMaybe<Scalars['JSON']>;
  isInvoiced?: InputMaybe<Scalars['Boolean']>;
  isPaid?: InputMaybe<Scalars['Boolean']>;
  postPackageId: Scalars['String'];
  price: Scalars['Decimal'];
  readableId?: InputMaybe<Scalars['String']>;
  refundPaymentId?: InputMaybe<Scalars['String']>;
  status: OrderStatus;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type OrderCreateManySenderInputEnvelope = {
  data: Array<OrderCreateManySenderInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type OrderCreateNestedManyWithoutRefundPaymentInput = {
  connect?: InputMaybe<Array<OrderWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<OrderCreateOrConnectWithoutRefundPaymentInput>>;
  create?: InputMaybe<Array<OrderCreateWithoutRefundPaymentInput>>;
  createMany?: InputMaybe<OrderCreateManyRefundPaymentInputEnvelope>;
};

export type OrderCreateNestedManyWithoutSenderInput = {
  connect?: InputMaybe<Array<OrderWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<OrderCreateOrConnectWithoutSenderInput>>;
  create?: InputMaybe<Array<OrderCreateWithoutSenderInput>>;
  createMany?: InputMaybe<OrderCreateManySenderInputEnvelope>;
};

export type OrderCreateNestedOneWithoutBenefitMemosInput = {
  connect?: InputMaybe<OrderWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OrderCreateOrConnectWithoutBenefitMemosInput>;
  create?: InputMaybe<OrderCreateWithoutBenefitMemosInput>;
};

export type OrderCreateNestedOneWithoutCoinInput = {
  connect?: InputMaybe<OrderWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OrderCreateOrConnectWithoutCoinInput>;
  create?: InputMaybe<OrderCreateWithoutCoinInput>;
};

export type OrderCreateNestedOneWithoutCouponEntitiesInput = {
  connect?: InputMaybe<OrderWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OrderCreateOrConnectWithoutCouponEntitiesInput>;
  create?: InputMaybe<OrderCreateWithoutCouponEntitiesInput>;
};

export type OrderCreateNestedOneWithoutDetailsInput = {
  connect?: InputMaybe<OrderWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OrderCreateOrConnectWithoutDetailsInput>;
  create?: InputMaybe<OrderCreateWithoutDetailsInput>;
};

export type OrderCreateNestedOneWithoutInvoiceInput = {
  connect?: InputMaybe<OrderWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OrderCreateOrConnectWithoutInvoiceInput>;
  create?: InputMaybe<OrderCreateWithoutInvoiceInput>;
};

export type OrderCreateNestedOneWithoutPaymentInput = {
  connect?: InputMaybe<OrderWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OrderCreateOrConnectWithoutPaymentInput>;
  create?: InputMaybe<OrderCreateWithoutPaymentInput>;
};

export type OrderCreateNestedOneWithoutPostPackageInput = {
  connect?: InputMaybe<OrderWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OrderCreateOrConnectWithoutPostPackageInput>;
  create?: InputMaybe<OrderCreateWithoutPostPackageInput>;
};

export type OrderCreateOrConnectWithoutBenefitMemosInput = {
  create: OrderCreateWithoutBenefitMemosInput;
  where: OrderWhereUniqueInput;
};

export type OrderCreateOrConnectWithoutCoinInput = {
  create: OrderCreateWithoutCoinInput;
  where: OrderWhereUniqueInput;
};

export type OrderCreateOrConnectWithoutCouponEntitiesInput = {
  create: OrderCreateWithoutCouponEntitiesInput;
  where: OrderWhereUniqueInput;
};

export type OrderCreateOrConnectWithoutDetailsInput = {
  create: OrderCreateWithoutDetailsInput;
  where: OrderWhereUniqueInput;
};

export type OrderCreateOrConnectWithoutInvoiceInput = {
  create: OrderCreateWithoutInvoiceInput;
  where: OrderWhereUniqueInput;
};

export type OrderCreateOrConnectWithoutPaymentInput = {
  create: OrderCreateWithoutPaymentInput;
  where: OrderWhereUniqueInput;
};

export type OrderCreateOrConnectWithoutPostPackageInput = {
  create: OrderCreateWithoutPostPackageInput;
  where: OrderWhereUniqueInput;
};

export type OrderCreateOrConnectWithoutRefundPaymentInput = {
  create: OrderCreateWithoutRefundPaymentInput;
  where: OrderWhereUniqueInput;
};

export type OrderCreateOrConnectWithoutSenderInput = {
  create: OrderCreateWithoutSenderInput;
  where: OrderWhereUniqueInput;
};

export type OrderCreateWithoutBenefitMemosInput = {
  coin?: InputMaybe<CoinCreateNestedOneWithoutOrderInput>;
  couponEntities?: InputMaybe<CouponEntityCreateNestedManyWithoutConsumeOrderInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  details?: InputMaybe<OrderDetailCreateNestedManyWithoutOrderInput>;
  id?: InputMaybe<Scalars['String']>;
  invoice?: InputMaybe<InvoiceCreateNestedManyWithoutOrderInput>;
  invoiceParam?: InputMaybe<Scalars['JSON']>;
  isInvoiced?: InputMaybe<Scalars['Boolean']>;
  isPaid?: InputMaybe<Scalars['Boolean']>;
  payment?: InputMaybe<PaymentCreateNestedManyWithoutOrderInput>;
  postPackage: PostPackageCreateNestedOneWithoutOrderInput;
  price: Scalars['Decimal'];
  readableId?: InputMaybe<Scalars['String']>;
  refundPayment?: InputMaybe<PaymentCreateNestedOneWithoutRefundOrdersInput>;
  sender: UserCreateNestedOneWithoutOrderInput;
  status: OrderStatus;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type OrderCreateWithoutCoinInput = {
  benefitMemos?: InputMaybe<BenefitMemoToOrderCreateNestedManyWithoutOrderInput>;
  couponEntities?: InputMaybe<CouponEntityCreateNestedManyWithoutConsumeOrderInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  details?: InputMaybe<OrderDetailCreateNestedManyWithoutOrderInput>;
  id?: InputMaybe<Scalars['String']>;
  invoice?: InputMaybe<InvoiceCreateNestedManyWithoutOrderInput>;
  invoiceParam?: InputMaybe<Scalars['JSON']>;
  isInvoiced?: InputMaybe<Scalars['Boolean']>;
  isPaid?: InputMaybe<Scalars['Boolean']>;
  payment?: InputMaybe<PaymentCreateNestedManyWithoutOrderInput>;
  postPackage: PostPackageCreateNestedOneWithoutOrderInput;
  price: Scalars['Decimal'];
  readableId?: InputMaybe<Scalars['String']>;
  refundPayment?: InputMaybe<PaymentCreateNestedOneWithoutRefundOrdersInput>;
  sender: UserCreateNestedOneWithoutOrderInput;
  status: OrderStatus;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type OrderCreateWithoutCouponEntitiesInput = {
  benefitMemos?: InputMaybe<BenefitMemoToOrderCreateNestedManyWithoutOrderInput>;
  coin?: InputMaybe<CoinCreateNestedOneWithoutOrderInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  details?: InputMaybe<OrderDetailCreateNestedManyWithoutOrderInput>;
  id?: InputMaybe<Scalars['String']>;
  invoice?: InputMaybe<InvoiceCreateNestedManyWithoutOrderInput>;
  invoiceParam?: InputMaybe<Scalars['JSON']>;
  isInvoiced?: InputMaybe<Scalars['Boolean']>;
  isPaid?: InputMaybe<Scalars['Boolean']>;
  payment?: InputMaybe<PaymentCreateNestedManyWithoutOrderInput>;
  postPackage: PostPackageCreateNestedOneWithoutOrderInput;
  price: Scalars['Decimal'];
  readableId?: InputMaybe<Scalars['String']>;
  refundPayment?: InputMaybe<PaymentCreateNestedOneWithoutRefundOrdersInput>;
  sender: UserCreateNestedOneWithoutOrderInput;
  status: OrderStatus;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type OrderCreateWithoutDetailsInput = {
  benefitMemos?: InputMaybe<BenefitMemoToOrderCreateNestedManyWithoutOrderInput>;
  coin?: InputMaybe<CoinCreateNestedOneWithoutOrderInput>;
  couponEntities?: InputMaybe<CouponEntityCreateNestedManyWithoutConsumeOrderInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  invoice?: InputMaybe<InvoiceCreateNestedManyWithoutOrderInput>;
  invoiceParam?: InputMaybe<Scalars['JSON']>;
  isInvoiced?: InputMaybe<Scalars['Boolean']>;
  isPaid?: InputMaybe<Scalars['Boolean']>;
  payment?: InputMaybe<PaymentCreateNestedManyWithoutOrderInput>;
  postPackage: PostPackageCreateNestedOneWithoutOrderInput;
  price: Scalars['Decimal'];
  readableId?: InputMaybe<Scalars['String']>;
  refundPayment?: InputMaybe<PaymentCreateNestedOneWithoutRefundOrdersInput>;
  sender: UserCreateNestedOneWithoutOrderInput;
  status: OrderStatus;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type OrderCreateWithoutInvoiceInput = {
  benefitMemos?: InputMaybe<BenefitMemoToOrderCreateNestedManyWithoutOrderInput>;
  coin?: InputMaybe<CoinCreateNestedOneWithoutOrderInput>;
  couponEntities?: InputMaybe<CouponEntityCreateNestedManyWithoutConsumeOrderInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  details?: InputMaybe<OrderDetailCreateNestedManyWithoutOrderInput>;
  id?: InputMaybe<Scalars['String']>;
  invoiceParam?: InputMaybe<Scalars['JSON']>;
  isInvoiced?: InputMaybe<Scalars['Boolean']>;
  isPaid?: InputMaybe<Scalars['Boolean']>;
  payment?: InputMaybe<PaymentCreateNestedManyWithoutOrderInput>;
  postPackage: PostPackageCreateNestedOneWithoutOrderInput;
  price: Scalars['Decimal'];
  readableId?: InputMaybe<Scalars['String']>;
  refundPayment?: InputMaybe<PaymentCreateNestedOneWithoutRefundOrdersInput>;
  sender: UserCreateNestedOneWithoutOrderInput;
  status: OrderStatus;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type OrderCreateWithoutPaymentInput = {
  benefitMemos?: InputMaybe<BenefitMemoToOrderCreateNestedManyWithoutOrderInput>;
  coin?: InputMaybe<CoinCreateNestedOneWithoutOrderInput>;
  couponEntities?: InputMaybe<CouponEntityCreateNestedManyWithoutConsumeOrderInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  details?: InputMaybe<OrderDetailCreateNestedManyWithoutOrderInput>;
  id?: InputMaybe<Scalars['String']>;
  invoice?: InputMaybe<InvoiceCreateNestedManyWithoutOrderInput>;
  invoiceParam?: InputMaybe<Scalars['JSON']>;
  isInvoiced?: InputMaybe<Scalars['Boolean']>;
  isPaid?: InputMaybe<Scalars['Boolean']>;
  postPackage: PostPackageCreateNestedOneWithoutOrderInput;
  price: Scalars['Decimal'];
  readableId?: InputMaybe<Scalars['String']>;
  refundPayment?: InputMaybe<PaymentCreateNestedOneWithoutRefundOrdersInput>;
  sender: UserCreateNestedOneWithoutOrderInput;
  status: OrderStatus;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type OrderCreateWithoutPostPackageInput = {
  benefitMemos?: InputMaybe<BenefitMemoToOrderCreateNestedManyWithoutOrderInput>;
  coin?: InputMaybe<CoinCreateNestedOneWithoutOrderInput>;
  couponEntities?: InputMaybe<CouponEntityCreateNestedManyWithoutConsumeOrderInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  details?: InputMaybe<OrderDetailCreateNestedManyWithoutOrderInput>;
  id?: InputMaybe<Scalars['String']>;
  invoice?: InputMaybe<InvoiceCreateNestedManyWithoutOrderInput>;
  invoiceParam?: InputMaybe<Scalars['JSON']>;
  isInvoiced?: InputMaybe<Scalars['Boolean']>;
  isPaid?: InputMaybe<Scalars['Boolean']>;
  payment?: InputMaybe<PaymentCreateNestedManyWithoutOrderInput>;
  price: Scalars['Decimal'];
  readableId?: InputMaybe<Scalars['String']>;
  refundPayment?: InputMaybe<PaymentCreateNestedOneWithoutRefundOrdersInput>;
  sender: UserCreateNestedOneWithoutOrderInput;
  status: OrderStatus;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type OrderCreateWithoutRefundPaymentInput = {
  benefitMemos?: InputMaybe<BenefitMemoToOrderCreateNestedManyWithoutOrderInput>;
  coin?: InputMaybe<CoinCreateNestedOneWithoutOrderInput>;
  couponEntities?: InputMaybe<CouponEntityCreateNestedManyWithoutConsumeOrderInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  details?: InputMaybe<OrderDetailCreateNestedManyWithoutOrderInput>;
  id?: InputMaybe<Scalars['String']>;
  invoice?: InputMaybe<InvoiceCreateNestedManyWithoutOrderInput>;
  invoiceParam?: InputMaybe<Scalars['JSON']>;
  isInvoiced?: InputMaybe<Scalars['Boolean']>;
  isPaid?: InputMaybe<Scalars['Boolean']>;
  payment?: InputMaybe<PaymentCreateNestedManyWithoutOrderInput>;
  postPackage: PostPackageCreateNestedOneWithoutOrderInput;
  price: Scalars['Decimal'];
  readableId?: InputMaybe<Scalars['String']>;
  sender: UserCreateNestedOneWithoutOrderInput;
  status: OrderStatus;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type OrderCreateWithoutSenderInput = {
  benefitMemos?: InputMaybe<BenefitMemoToOrderCreateNestedManyWithoutOrderInput>;
  coin?: InputMaybe<CoinCreateNestedOneWithoutOrderInput>;
  couponEntities?: InputMaybe<CouponEntityCreateNestedManyWithoutConsumeOrderInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  details?: InputMaybe<OrderDetailCreateNestedManyWithoutOrderInput>;
  id?: InputMaybe<Scalars['String']>;
  invoice?: InputMaybe<InvoiceCreateNestedManyWithoutOrderInput>;
  invoiceParam?: InputMaybe<Scalars['JSON']>;
  isInvoiced?: InputMaybe<Scalars['Boolean']>;
  isPaid?: InputMaybe<Scalars['Boolean']>;
  payment?: InputMaybe<PaymentCreateNestedManyWithoutOrderInput>;
  postPackage: PostPackageCreateNestedOneWithoutOrderInput;
  price: Scalars['Decimal'];
  readableId?: InputMaybe<Scalars['String']>;
  refundPayment?: InputMaybe<PaymentCreateNestedOneWithoutRefundOrdersInput>;
  status: OrderStatus;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type OrderDetail = {
  __typename?: 'OrderDetail';
  _count?: Maybe<OrderDetailCount>;
  address?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  histories: Array<OrderDetailHistory>;
  id: Scalars['String'];
  memo?: Maybe<Scalars['String']>;
  order: Order;
  orderId: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  post: Post;
  postId: Scalars['String'];
  price: Scalars['Decimal'];
  printCompany?: Maybe<Scalars['String']>;
  printStatus: PrintStatus;
  product?: Maybe<Product>;
  productId?: Maybe<Scalars['String']>;
  realname?: Maybe<Scalars['String']>;
  receiver?: Maybe<User>;
  receiverId?: Maybe<Scalars['String']>;
  sendCompany?: Maybe<Scalars['String']>;
  sendDate: Scalars['DateTime'];
  sendId?: Maybe<Scalars['String']>;
  sendStatus: PackageStatus;
  status: OrderStatus;
  tags: Array<OrderTag>;
  updatedAt: Scalars['DateTime'];
};


export type OrderDetailHistoriesArgs = {
  cursor?: InputMaybe<OrderDetailHistoryWhereUniqueInput>;
  distinct?: InputMaybe<Array<OrderDetailHistoryScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<OrderDetailHistoryOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<OrderDetailHistoryWhereInput>;
};


export type OrderDetailTagsArgs = {
  cursor?: InputMaybe<OrderTagWhereUniqueInput>;
  distinct?: InputMaybe<Array<OrderTagScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<OrderTagOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<OrderTagWhereInput>;
};

export type OrderDetailAvgAggregate = {
  __typename?: 'OrderDetailAvgAggregate';
  price?: Maybe<Scalars['Decimal']>;
};

export type OrderDetailAvgOrderByAggregateInput = {
  price?: InputMaybe<SortOrder>;
};

export type OrderDetailCount = {
  __typename?: 'OrderDetailCount';
  histories: Scalars['Int'];
  tags: Scalars['Int'];
};


export type OrderDetailCountHistoriesArgs = {
  where?: InputMaybe<OrderDetailHistoryWhereInput>;
};


export type OrderDetailCountTagsArgs = {
  where?: InputMaybe<OrderTagWhereInput>;
};

export type OrderDetailCountAggregate = {
  __typename?: 'OrderDetailCountAggregate';
  _all: Scalars['Int'];
  address: Scalars['Int'];
  country: Scalars['Int'];
  createdAt: Scalars['Int'];
  id: Scalars['Int'];
  memo: Scalars['Int'];
  orderId: Scalars['Int'];
  phone: Scalars['Int'];
  postId: Scalars['Int'];
  price: Scalars['Int'];
  printCompany: Scalars['Int'];
  printStatus: Scalars['Int'];
  productId: Scalars['Int'];
  realname: Scalars['Int'];
  receiverId: Scalars['Int'];
  sendCompany: Scalars['Int'];
  sendDate: Scalars['Int'];
  sendId: Scalars['Int'];
  sendStatus: Scalars['Int'];
  status: Scalars['Int'];
  updatedAt: Scalars['Int'];
};

export type OrderDetailCountOrderByAggregateInput = {
  address?: InputMaybe<SortOrder>;
  country?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  memo?: InputMaybe<SortOrder>;
  orderId?: InputMaybe<SortOrder>;
  phone?: InputMaybe<SortOrder>;
  postId?: InputMaybe<SortOrder>;
  price?: InputMaybe<SortOrder>;
  printCompany?: InputMaybe<SortOrder>;
  printStatus?: InputMaybe<SortOrder>;
  productId?: InputMaybe<SortOrder>;
  realname?: InputMaybe<SortOrder>;
  receiverId?: InputMaybe<SortOrder>;
  sendCompany?: InputMaybe<SortOrder>;
  sendDate?: InputMaybe<SortOrder>;
  sendId?: InputMaybe<SortOrder>;
  sendStatus?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type OrderDetailCreateInput = {
  address?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  histories?: InputMaybe<OrderDetailHistoryCreateNestedManyWithoutDetailInput>;
  id?: InputMaybe<Scalars['String']>;
  memo?: InputMaybe<Scalars['String']>;
  order: OrderCreateNestedOneWithoutDetailsInput;
  phone?: InputMaybe<Scalars['String']>;
  post: PostCreateNestedOneWithoutDetailInput;
  price: Scalars['Decimal'];
  printCompany?: InputMaybe<Scalars['String']>;
  printStatus: PrintStatus;
  product?: InputMaybe<ProductCreateNestedOneWithoutOrderDetailsInput>;
  realname?: InputMaybe<Scalars['String']>;
  receiver?: InputMaybe<UserCreateNestedOneWithoutDetailInput>;
  sendCompany?: InputMaybe<Scalars['String']>;
  sendDate: Scalars['DateTime'];
  sendId?: InputMaybe<Scalars['String']>;
  sendStatus: PackageStatus;
  status: OrderStatus;
  tags?: InputMaybe<OrderTagCreateNestedManyWithoutOrderDetailsInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type OrderDetailCreateManyInput = {
  address?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  memo?: InputMaybe<Scalars['String']>;
  orderId: Scalars['String'];
  phone?: InputMaybe<Scalars['String']>;
  postId: Scalars['String'];
  price: Scalars['Decimal'];
  printCompany?: InputMaybe<Scalars['String']>;
  printStatus: PrintStatus;
  productId?: InputMaybe<Scalars['String']>;
  realname?: InputMaybe<Scalars['String']>;
  receiverId?: InputMaybe<Scalars['String']>;
  sendCompany?: InputMaybe<Scalars['String']>;
  sendDate: Scalars['DateTime'];
  sendId?: InputMaybe<Scalars['String']>;
  sendStatus: PackageStatus;
  status: OrderStatus;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type OrderDetailCreateManyOrderInput = {
  address?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  memo?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  postId: Scalars['String'];
  price: Scalars['Decimal'];
  printCompany?: InputMaybe<Scalars['String']>;
  printStatus: PrintStatus;
  productId?: InputMaybe<Scalars['String']>;
  realname?: InputMaybe<Scalars['String']>;
  receiverId?: InputMaybe<Scalars['String']>;
  sendCompany?: InputMaybe<Scalars['String']>;
  sendDate: Scalars['DateTime'];
  sendId?: InputMaybe<Scalars['String']>;
  sendStatus: PackageStatus;
  status: OrderStatus;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type OrderDetailCreateManyOrderInputEnvelope = {
  data: Array<OrderDetailCreateManyOrderInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type OrderDetailCreateManyProductInput = {
  address?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  memo?: InputMaybe<Scalars['String']>;
  orderId: Scalars['String'];
  phone?: InputMaybe<Scalars['String']>;
  postId: Scalars['String'];
  price: Scalars['Decimal'];
  printCompany?: InputMaybe<Scalars['String']>;
  printStatus: PrintStatus;
  realname?: InputMaybe<Scalars['String']>;
  receiverId?: InputMaybe<Scalars['String']>;
  sendCompany?: InputMaybe<Scalars['String']>;
  sendDate: Scalars['DateTime'];
  sendId?: InputMaybe<Scalars['String']>;
  sendStatus: PackageStatus;
  status: OrderStatus;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type OrderDetailCreateManyProductInputEnvelope = {
  data: Array<OrderDetailCreateManyProductInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type OrderDetailCreateManyReceiverInput = {
  address?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  memo?: InputMaybe<Scalars['String']>;
  orderId: Scalars['String'];
  phone?: InputMaybe<Scalars['String']>;
  postId: Scalars['String'];
  price: Scalars['Decimal'];
  printCompany?: InputMaybe<Scalars['String']>;
  printStatus: PrintStatus;
  productId?: InputMaybe<Scalars['String']>;
  realname?: InputMaybe<Scalars['String']>;
  sendCompany?: InputMaybe<Scalars['String']>;
  sendDate: Scalars['DateTime'];
  sendId?: InputMaybe<Scalars['String']>;
  sendStatus: PackageStatus;
  status: OrderStatus;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type OrderDetailCreateManyReceiverInputEnvelope = {
  data: Array<OrderDetailCreateManyReceiverInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type OrderDetailCreateNestedManyWithoutOrderInput = {
  connect?: InputMaybe<Array<OrderDetailWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<OrderDetailCreateOrConnectWithoutOrderInput>>;
  create?: InputMaybe<Array<OrderDetailCreateWithoutOrderInput>>;
  createMany?: InputMaybe<OrderDetailCreateManyOrderInputEnvelope>;
};

export type OrderDetailCreateNestedManyWithoutProductInput = {
  connect?: InputMaybe<Array<OrderDetailWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<OrderDetailCreateOrConnectWithoutProductInput>>;
  create?: InputMaybe<Array<OrderDetailCreateWithoutProductInput>>;
  createMany?: InputMaybe<OrderDetailCreateManyProductInputEnvelope>;
};

export type OrderDetailCreateNestedManyWithoutReceiverInput = {
  connect?: InputMaybe<Array<OrderDetailWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<OrderDetailCreateOrConnectWithoutReceiverInput>>;
  create?: InputMaybe<Array<OrderDetailCreateWithoutReceiverInput>>;
  createMany?: InputMaybe<OrderDetailCreateManyReceiverInputEnvelope>;
};

export type OrderDetailCreateNestedManyWithoutTagsInput = {
  connect?: InputMaybe<Array<OrderDetailWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<OrderDetailCreateOrConnectWithoutTagsInput>>;
  create?: InputMaybe<Array<OrderDetailCreateWithoutTagsInput>>;
};

export type OrderDetailCreateNestedOneWithoutHistoriesInput = {
  connect?: InputMaybe<OrderDetailWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OrderDetailCreateOrConnectWithoutHistoriesInput>;
  create?: InputMaybe<OrderDetailCreateWithoutHistoriesInput>;
};

export type OrderDetailCreateNestedOneWithoutPostInput = {
  connect?: InputMaybe<OrderDetailWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OrderDetailCreateOrConnectWithoutPostInput>;
  create?: InputMaybe<OrderDetailCreateWithoutPostInput>;
};

export type OrderDetailCreateOrConnectWithoutHistoriesInput = {
  create: OrderDetailCreateWithoutHistoriesInput;
  where: OrderDetailWhereUniqueInput;
};

export type OrderDetailCreateOrConnectWithoutOrderInput = {
  create: OrderDetailCreateWithoutOrderInput;
  where: OrderDetailWhereUniqueInput;
};

export type OrderDetailCreateOrConnectWithoutPostInput = {
  create: OrderDetailCreateWithoutPostInput;
  where: OrderDetailWhereUniqueInput;
};

export type OrderDetailCreateOrConnectWithoutProductInput = {
  create: OrderDetailCreateWithoutProductInput;
  where: OrderDetailWhereUniqueInput;
};

export type OrderDetailCreateOrConnectWithoutReceiverInput = {
  create: OrderDetailCreateWithoutReceiverInput;
  where: OrderDetailWhereUniqueInput;
};

export type OrderDetailCreateOrConnectWithoutTagsInput = {
  create: OrderDetailCreateWithoutTagsInput;
  where: OrderDetailWhereUniqueInput;
};

export type OrderDetailCreateWithoutHistoriesInput = {
  address?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  memo?: InputMaybe<Scalars['String']>;
  order: OrderCreateNestedOneWithoutDetailsInput;
  phone?: InputMaybe<Scalars['String']>;
  post: PostCreateNestedOneWithoutDetailInput;
  price: Scalars['Decimal'];
  printCompany?: InputMaybe<Scalars['String']>;
  printStatus: PrintStatus;
  product?: InputMaybe<ProductCreateNestedOneWithoutOrderDetailsInput>;
  realname?: InputMaybe<Scalars['String']>;
  receiver?: InputMaybe<UserCreateNestedOneWithoutDetailInput>;
  sendCompany?: InputMaybe<Scalars['String']>;
  sendDate: Scalars['DateTime'];
  sendId?: InputMaybe<Scalars['String']>;
  sendStatus: PackageStatus;
  status: OrderStatus;
  tags?: InputMaybe<OrderTagCreateNestedManyWithoutOrderDetailsInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type OrderDetailCreateWithoutOrderInput = {
  address?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  histories?: InputMaybe<OrderDetailHistoryCreateNestedManyWithoutDetailInput>;
  id?: InputMaybe<Scalars['String']>;
  memo?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  post: PostCreateNestedOneWithoutDetailInput;
  price: Scalars['Decimal'];
  printCompany?: InputMaybe<Scalars['String']>;
  printStatus: PrintStatus;
  product?: InputMaybe<ProductCreateNestedOneWithoutOrderDetailsInput>;
  realname?: InputMaybe<Scalars['String']>;
  receiver?: InputMaybe<UserCreateNestedOneWithoutDetailInput>;
  sendCompany?: InputMaybe<Scalars['String']>;
  sendDate: Scalars['DateTime'];
  sendId?: InputMaybe<Scalars['String']>;
  sendStatus: PackageStatus;
  status: OrderStatus;
  tags?: InputMaybe<OrderTagCreateNestedManyWithoutOrderDetailsInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type OrderDetailCreateWithoutPostInput = {
  address?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  histories?: InputMaybe<OrderDetailHistoryCreateNestedManyWithoutDetailInput>;
  id?: InputMaybe<Scalars['String']>;
  memo?: InputMaybe<Scalars['String']>;
  order: OrderCreateNestedOneWithoutDetailsInput;
  phone?: InputMaybe<Scalars['String']>;
  price: Scalars['Decimal'];
  printCompany?: InputMaybe<Scalars['String']>;
  printStatus: PrintStatus;
  product?: InputMaybe<ProductCreateNestedOneWithoutOrderDetailsInput>;
  realname?: InputMaybe<Scalars['String']>;
  receiver?: InputMaybe<UserCreateNestedOneWithoutDetailInput>;
  sendCompany?: InputMaybe<Scalars['String']>;
  sendDate: Scalars['DateTime'];
  sendId?: InputMaybe<Scalars['String']>;
  sendStatus: PackageStatus;
  status: OrderStatus;
  tags?: InputMaybe<OrderTagCreateNestedManyWithoutOrderDetailsInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type OrderDetailCreateWithoutProductInput = {
  address?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  histories?: InputMaybe<OrderDetailHistoryCreateNestedManyWithoutDetailInput>;
  id?: InputMaybe<Scalars['String']>;
  memo?: InputMaybe<Scalars['String']>;
  order: OrderCreateNestedOneWithoutDetailsInput;
  phone?: InputMaybe<Scalars['String']>;
  post: PostCreateNestedOneWithoutDetailInput;
  price: Scalars['Decimal'];
  printCompany?: InputMaybe<Scalars['String']>;
  printStatus: PrintStatus;
  realname?: InputMaybe<Scalars['String']>;
  receiver?: InputMaybe<UserCreateNestedOneWithoutDetailInput>;
  sendCompany?: InputMaybe<Scalars['String']>;
  sendDate: Scalars['DateTime'];
  sendId?: InputMaybe<Scalars['String']>;
  sendStatus: PackageStatus;
  status: OrderStatus;
  tags?: InputMaybe<OrderTagCreateNestedManyWithoutOrderDetailsInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type OrderDetailCreateWithoutReceiverInput = {
  address?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  histories?: InputMaybe<OrderDetailHistoryCreateNestedManyWithoutDetailInput>;
  id?: InputMaybe<Scalars['String']>;
  memo?: InputMaybe<Scalars['String']>;
  order: OrderCreateNestedOneWithoutDetailsInput;
  phone?: InputMaybe<Scalars['String']>;
  post: PostCreateNestedOneWithoutDetailInput;
  price: Scalars['Decimal'];
  printCompany?: InputMaybe<Scalars['String']>;
  printStatus: PrintStatus;
  product?: InputMaybe<ProductCreateNestedOneWithoutOrderDetailsInput>;
  realname?: InputMaybe<Scalars['String']>;
  sendCompany?: InputMaybe<Scalars['String']>;
  sendDate: Scalars['DateTime'];
  sendId?: InputMaybe<Scalars['String']>;
  sendStatus: PackageStatus;
  status: OrderStatus;
  tags?: InputMaybe<OrderTagCreateNestedManyWithoutOrderDetailsInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type OrderDetailCreateWithoutTagsInput = {
  address?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  histories?: InputMaybe<OrderDetailHistoryCreateNestedManyWithoutDetailInput>;
  id?: InputMaybe<Scalars['String']>;
  memo?: InputMaybe<Scalars['String']>;
  order: OrderCreateNestedOneWithoutDetailsInput;
  phone?: InputMaybe<Scalars['String']>;
  post: PostCreateNestedOneWithoutDetailInput;
  price: Scalars['Decimal'];
  printCompany?: InputMaybe<Scalars['String']>;
  printStatus: PrintStatus;
  product?: InputMaybe<ProductCreateNestedOneWithoutOrderDetailsInput>;
  realname?: InputMaybe<Scalars['String']>;
  receiver?: InputMaybe<UserCreateNestedOneWithoutDetailInput>;
  sendCompany?: InputMaybe<Scalars['String']>;
  sendDate: Scalars['DateTime'];
  sendId?: InputMaybe<Scalars['String']>;
  sendStatus: PackageStatus;
  status: OrderStatus;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type OrderDetailGroupBy = {
  __typename?: 'OrderDetailGroupBy';
  _avg?: Maybe<OrderDetailAvgAggregate>;
  _count?: Maybe<OrderDetailCountAggregate>;
  _max?: Maybe<OrderDetailMaxAggregate>;
  _min?: Maybe<OrderDetailMinAggregate>;
  _sum?: Maybe<OrderDetailSumAggregate>;
  address?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  memo?: Maybe<Scalars['String']>;
  orderId: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  postId: Scalars['String'];
  price: Scalars['Decimal'];
  printCompany?: Maybe<Scalars['String']>;
  printStatus: PrintStatus;
  productId?: Maybe<Scalars['String']>;
  realname?: Maybe<Scalars['String']>;
  receiverId?: Maybe<Scalars['String']>;
  sendCompany?: Maybe<Scalars['String']>;
  sendDate: Scalars['DateTime'];
  sendId?: Maybe<Scalars['String']>;
  sendStatus: PackageStatus;
  status: OrderStatus;
  updatedAt: Scalars['DateTime'];
};

export type OrderDetailHistory = {
  __typename?: 'OrderDetailHistory';
  createdAt: Scalars['DateTime'];
  detail: OrderDetail;
  detailId: Scalars['String'];
  id: Scalars['String'];
  lastHistory?: Maybe<OrderDetailHistory>;
  lastHistoryId?: Maybe<Scalars['String']>;
  nextHistory?: Maybe<OrderDetailHistory>;
  operation?: Maybe<Scalars['String']>;
  operator: User;
  operatorId: Scalars['String'];
  status: OrderStatus;
  updatedAt: Scalars['DateTime'];
};

export type OrderDetailHistoryCountAggregate = {
  __typename?: 'OrderDetailHistoryCountAggregate';
  _all: Scalars['Int'];
  createdAt: Scalars['Int'];
  detailId: Scalars['Int'];
  id: Scalars['Int'];
  lastHistoryId: Scalars['Int'];
  operation: Scalars['Int'];
  operatorId: Scalars['Int'];
  status: Scalars['Int'];
  updatedAt: Scalars['Int'];
};

export type OrderDetailHistoryCountOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  detailId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  lastHistoryId?: InputMaybe<SortOrder>;
  operation?: InputMaybe<SortOrder>;
  operatorId?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type OrderDetailHistoryCreateInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  detail: OrderDetailCreateNestedOneWithoutHistoriesInput;
  id?: InputMaybe<Scalars['String']>;
  lastHistory?: InputMaybe<OrderDetailHistoryCreateNestedOneWithoutNextHistoryInput>;
  nextHistory?: InputMaybe<OrderDetailHistoryCreateNestedOneWithoutLastHistoryInput>;
  operation?: InputMaybe<Scalars['String']>;
  operator: UserCreateNestedOneWithoutHistoryInput;
  status: OrderStatus;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type OrderDetailHistoryCreateManyDetailInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  lastHistoryId?: InputMaybe<Scalars['String']>;
  operation?: InputMaybe<Scalars['String']>;
  operatorId: Scalars['String'];
  status: OrderStatus;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type OrderDetailHistoryCreateManyDetailInputEnvelope = {
  data: Array<OrderDetailHistoryCreateManyDetailInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type OrderDetailHistoryCreateManyInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  detailId: Scalars['String'];
  id?: InputMaybe<Scalars['String']>;
  lastHistoryId?: InputMaybe<Scalars['String']>;
  operation?: InputMaybe<Scalars['String']>;
  operatorId: Scalars['String'];
  status: OrderStatus;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type OrderDetailHistoryCreateManyOperatorInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  detailId: Scalars['String'];
  id?: InputMaybe<Scalars['String']>;
  lastHistoryId?: InputMaybe<Scalars['String']>;
  operation?: InputMaybe<Scalars['String']>;
  status: OrderStatus;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type OrderDetailHistoryCreateManyOperatorInputEnvelope = {
  data: Array<OrderDetailHistoryCreateManyOperatorInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type OrderDetailHistoryCreateNestedManyWithoutDetailInput = {
  connect?: InputMaybe<Array<OrderDetailHistoryWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<OrderDetailHistoryCreateOrConnectWithoutDetailInput>>;
  create?: InputMaybe<Array<OrderDetailHistoryCreateWithoutDetailInput>>;
  createMany?: InputMaybe<OrderDetailHistoryCreateManyDetailInputEnvelope>;
};

export type OrderDetailHistoryCreateNestedManyWithoutOperatorInput = {
  connect?: InputMaybe<Array<OrderDetailHistoryWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<OrderDetailHistoryCreateOrConnectWithoutOperatorInput>>;
  create?: InputMaybe<Array<OrderDetailHistoryCreateWithoutOperatorInput>>;
  createMany?: InputMaybe<OrderDetailHistoryCreateManyOperatorInputEnvelope>;
};

export type OrderDetailHistoryCreateNestedOneWithoutLastHistoryInput = {
  connect?: InputMaybe<OrderDetailHistoryWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OrderDetailHistoryCreateOrConnectWithoutLastHistoryInput>;
  create?: InputMaybe<OrderDetailHistoryCreateWithoutLastHistoryInput>;
};

export type OrderDetailHistoryCreateNestedOneWithoutNextHistoryInput = {
  connect?: InputMaybe<OrderDetailHistoryWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OrderDetailHistoryCreateOrConnectWithoutNextHistoryInput>;
  create?: InputMaybe<OrderDetailHistoryCreateWithoutNextHistoryInput>;
};

export type OrderDetailHistoryCreateOrConnectWithoutDetailInput = {
  create: OrderDetailHistoryCreateWithoutDetailInput;
  where: OrderDetailHistoryWhereUniqueInput;
};

export type OrderDetailHistoryCreateOrConnectWithoutLastHistoryInput = {
  create: OrderDetailHistoryCreateWithoutLastHistoryInput;
  where: OrderDetailHistoryWhereUniqueInput;
};

export type OrderDetailHistoryCreateOrConnectWithoutNextHistoryInput = {
  create: OrderDetailHistoryCreateWithoutNextHistoryInput;
  where: OrderDetailHistoryWhereUniqueInput;
};

export type OrderDetailHistoryCreateOrConnectWithoutOperatorInput = {
  create: OrderDetailHistoryCreateWithoutOperatorInput;
  where: OrderDetailHistoryWhereUniqueInput;
};

export type OrderDetailHistoryCreateWithoutDetailInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  lastHistory?: InputMaybe<OrderDetailHistoryCreateNestedOneWithoutNextHistoryInput>;
  nextHistory?: InputMaybe<OrderDetailHistoryCreateNestedOneWithoutLastHistoryInput>;
  operation?: InputMaybe<Scalars['String']>;
  operator: UserCreateNestedOneWithoutHistoryInput;
  status: OrderStatus;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type OrderDetailHistoryCreateWithoutLastHistoryInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  detail: OrderDetailCreateNestedOneWithoutHistoriesInput;
  id?: InputMaybe<Scalars['String']>;
  nextHistory?: InputMaybe<OrderDetailHistoryCreateNestedOneWithoutLastHistoryInput>;
  operation?: InputMaybe<Scalars['String']>;
  operator: UserCreateNestedOneWithoutHistoryInput;
  status: OrderStatus;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type OrderDetailHistoryCreateWithoutNextHistoryInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  detail: OrderDetailCreateNestedOneWithoutHistoriesInput;
  id?: InputMaybe<Scalars['String']>;
  lastHistory?: InputMaybe<OrderDetailHistoryCreateNestedOneWithoutNextHistoryInput>;
  operation?: InputMaybe<Scalars['String']>;
  operator: UserCreateNestedOneWithoutHistoryInput;
  status: OrderStatus;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type OrderDetailHistoryCreateWithoutOperatorInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  detail: OrderDetailCreateNestedOneWithoutHistoriesInput;
  id?: InputMaybe<Scalars['String']>;
  lastHistory?: InputMaybe<OrderDetailHistoryCreateNestedOneWithoutNextHistoryInput>;
  nextHistory?: InputMaybe<OrderDetailHistoryCreateNestedOneWithoutLastHistoryInput>;
  operation?: InputMaybe<Scalars['String']>;
  status: OrderStatus;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type OrderDetailHistoryGroupBy = {
  __typename?: 'OrderDetailHistoryGroupBy';
  _count?: Maybe<OrderDetailHistoryCountAggregate>;
  _max?: Maybe<OrderDetailHistoryMaxAggregate>;
  _min?: Maybe<OrderDetailHistoryMinAggregate>;
  createdAt: Scalars['DateTime'];
  detailId: Scalars['String'];
  id: Scalars['String'];
  lastHistoryId?: Maybe<Scalars['String']>;
  operation?: Maybe<Scalars['String']>;
  operatorId: Scalars['String'];
  status: OrderStatus;
  updatedAt: Scalars['DateTime'];
};

export type OrderDetailHistoryListRelationFilter = {
  every?: InputMaybe<OrderDetailHistoryWhereInput>;
  none?: InputMaybe<OrderDetailHistoryWhereInput>;
  some?: InputMaybe<OrderDetailHistoryWhereInput>;
};

export type OrderDetailHistoryMaxAggregate = {
  __typename?: 'OrderDetailHistoryMaxAggregate';
  createdAt?: Maybe<Scalars['DateTime']>;
  detailId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  lastHistoryId?: Maybe<Scalars['String']>;
  operation?: Maybe<Scalars['String']>;
  operatorId?: Maybe<Scalars['String']>;
  status?: Maybe<OrderStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type OrderDetailHistoryMaxOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  detailId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  lastHistoryId?: InputMaybe<SortOrder>;
  operation?: InputMaybe<SortOrder>;
  operatorId?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type OrderDetailHistoryMinAggregate = {
  __typename?: 'OrderDetailHistoryMinAggregate';
  createdAt?: Maybe<Scalars['DateTime']>;
  detailId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  lastHistoryId?: Maybe<Scalars['String']>;
  operation?: Maybe<Scalars['String']>;
  operatorId?: Maybe<Scalars['String']>;
  status?: Maybe<OrderStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type OrderDetailHistoryMinOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  detailId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  lastHistoryId?: InputMaybe<SortOrder>;
  operation?: InputMaybe<SortOrder>;
  operatorId?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type OrderDetailHistoryOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type OrderDetailHistoryOrderByWithAggregationInput = {
  _count?: InputMaybe<OrderDetailHistoryCountOrderByAggregateInput>;
  _max?: InputMaybe<OrderDetailHistoryMaxOrderByAggregateInput>;
  _min?: InputMaybe<OrderDetailHistoryMinOrderByAggregateInput>;
  createdAt?: InputMaybe<SortOrder>;
  detailId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  lastHistoryId?: InputMaybe<SortOrder>;
  operation?: InputMaybe<SortOrder>;
  operatorId?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type OrderDetailHistoryOrderByWithRelationInput = {
  createdAt?: InputMaybe<SortOrder>;
  detail?: InputMaybe<OrderDetailOrderByWithRelationInput>;
  detailId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  lastHistory?: InputMaybe<OrderDetailHistoryOrderByWithRelationInput>;
  lastHistoryId?: InputMaybe<SortOrder>;
  nextHistory?: InputMaybe<OrderDetailHistoryOrderByWithRelationInput>;
  operation?: InputMaybe<SortOrder>;
  operator?: InputMaybe<UserOrderByWithRelationInput>;
  operatorId?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type OrderDetailHistoryRelationFilter = {
  is?: InputMaybe<OrderDetailHistoryWhereInput>;
  isNot?: InputMaybe<OrderDetailHistoryWhereInput>;
};

export enum OrderDetailHistoryScalarFieldEnum {
  CreatedAt = 'createdAt',
  DetailId = 'detailId',
  Id = 'id',
  LastHistoryId = 'lastHistoryId',
  Operation = 'operation',
  OperatorId = 'operatorId',
  Status = 'status',
  UpdatedAt = 'updatedAt'
}

export type OrderDetailHistoryScalarWhereInput = {
  AND?: InputMaybe<Array<OrderDetailHistoryScalarWhereInput>>;
  NOT?: InputMaybe<Array<OrderDetailHistoryScalarWhereInput>>;
  OR?: InputMaybe<Array<OrderDetailHistoryScalarWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  detailId?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  lastHistoryId?: InputMaybe<StringNullableFilter>;
  operation?: InputMaybe<StringNullableFilter>;
  operatorId?: InputMaybe<StringFilter>;
  status?: InputMaybe<EnumOrderStatusFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type OrderDetailHistoryScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<OrderDetailHistoryScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<OrderDetailHistoryScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<OrderDetailHistoryScalarWhereWithAggregatesInput>>;
  createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  detailId?: InputMaybe<StringWithAggregatesFilter>;
  id?: InputMaybe<StringWithAggregatesFilter>;
  lastHistoryId?: InputMaybe<StringNullableWithAggregatesFilter>;
  operation?: InputMaybe<StringNullableWithAggregatesFilter>;
  operatorId?: InputMaybe<StringWithAggregatesFilter>;
  status?: InputMaybe<EnumOrderStatusWithAggregatesFilter>;
  updatedAt?: InputMaybe<DateTimeWithAggregatesFilter>;
};

export type OrderDetailHistoryUpdateInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  detail?: InputMaybe<OrderDetailUpdateOneRequiredWithoutHistoriesNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  lastHistory?: InputMaybe<OrderDetailHistoryUpdateOneWithoutNextHistoryNestedInput>;
  nextHistory?: InputMaybe<OrderDetailHistoryUpdateOneWithoutLastHistoryNestedInput>;
  operation?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  operator?: InputMaybe<UserUpdateOneRequiredWithoutHistoryNestedInput>;
  status?: InputMaybe<EnumOrderStatusFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type OrderDetailHistoryUpdateManyMutationInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  operation?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumOrderStatusFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type OrderDetailHistoryUpdateManyWithWhereWithoutDetailInput = {
  data: OrderDetailHistoryUpdateManyMutationInput;
  where: OrderDetailHistoryScalarWhereInput;
};

export type OrderDetailHistoryUpdateManyWithWhereWithoutOperatorInput = {
  data: OrderDetailHistoryUpdateManyMutationInput;
  where: OrderDetailHistoryScalarWhereInput;
};

export type OrderDetailHistoryUpdateManyWithoutDetailNestedInput = {
  connect?: InputMaybe<Array<OrderDetailHistoryWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<OrderDetailHistoryCreateOrConnectWithoutDetailInput>>;
  create?: InputMaybe<Array<OrderDetailHistoryCreateWithoutDetailInput>>;
  createMany?: InputMaybe<OrderDetailHistoryCreateManyDetailInputEnvelope>;
  delete?: InputMaybe<Array<OrderDetailHistoryWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<OrderDetailHistoryScalarWhereInput>>;
  disconnect?: InputMaybe<Array<OrderDetailHistoryWhereUniqueInput>>;
  set?: InputMaybe<Array<OrderDetailHistoryWhereUniqueInput>>;
  update?: InputMaybe<Array<OrderDetailHistoryUpdateWithWhereUniqueWithoutDetailInput>>;
  updateMany?: InputMaybe<Array<OrderDetailHistoryUpdateManyWithWhereWithoutDetailInput>>;
  upsert?: InputMaybe<Array<OrderDetailHistoryUpsertWithWhereUniqueWithoutDetailInput>>;
};

export type OrderDetailHistoryUpdateManyWithoutOperatorNestedInput = {
  connect?: InputMaybe<Array<OrderDetailHistoryWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<OrderDetailHistoryCreateOrConnectWithoutOperatorInput>>;
  create?: InputMaybe<Array<OrderDetailHistoryCreateWithoutOperatorInput>>;
  createMany?: InputMaybe<OrderDetailHistoryCreateManyOperatorInputEnvelope>;
  delete?: InputMaybe<Array<OrderDetailHistoryWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<OrderDetailHistoryScalarWhereInput>>;
  disconnect?: InputMaybe<Array<OrderDetailHistoryWhereUniqueInput>>;
  set?: InputMaybe<Array<OrderDetailHistoryWhereUniqueInput>>;
  update?: InputMaybe<Array<OrderDetailHistoryUpdateWithWhereUniqueWithoutOperatorInput>>;
  updateMany?: InputMaybe<Array<OrderDetailHistoryUpdateManyWithWhereWithoutOperatorInput>>;
  upsert?: InputMaybe<Array<OrderDetailHistoryUpsertWithWhereUniqueWithoutOperatorInput>>;
};

export type OrderDetailHistoryUpdateOneWithoutLastHistoryNestedInput = {
  connect?: InputMaybe<OrderDetailHistoryWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OrderDetailHistoryCreateOrConnectWithoutLastHistoryInput>;
  create?: InputMaybe<OrderDetailHistoryCreateWithoutLastHistoryInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<OrderDetailHistoryUpdateWithoutLastHistoryInput>;
  upsert?: InputMaybe<OrderDetailHistoryUpsertWithoutLastHistoryInput>;
};

export type OrderDetailHistoryUpdateOneWithoutNextHistoryNestedInput = {
  connect?: InputMaybe<OrderDetailHistoryWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OrderDetailHistoryCreateOrConnectWithoutNextHistoryInput>;
  create?: InputMaybe<OrderDetailHistoryCreateWithoutNextHistoryInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<OrderDetailHistoryUpdateWithoutNextHistoryInput>;
  upsert?: InputMaybe<OrderDetailHistoryUpsertWithoutNextHistoryInput>;
};

export type OrderDetailHistoryUpdateWithWhereUniqueWithoutDetailInput = {
  data: OrderDetailHistoryUpdateWithoutDetailInput;
  where: OrderDetailHistoryWhereUniqueInput;
};

export type OrderDetailHistoryUpdateWithWhereUniqueWithoutOperatorInput = {
  data: OrderDetailHistoryUpdateWithoutOperatorInput;
  where: OrderDetailHistoryWhereUniqueInput;
};

export type OrderDetailHistoryUpdateWithoutDetailInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  lastHistory?: InputMaybe<OrderDetailHistoryUpdateOneWithoutNextHistoryNestedInput>;
  nextHistory?: InputMaybe<OrderDetailHistoryUpdateOneWithoutLastHistoryNestedInput>;
  operation?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  operator?: InputMaybe<UserUpdateOneRequiredWithoutHistoryNestedInput>;
  status?: InputMaybe<EnumOrderStatusFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type OrderDetailHistoryUpdateWithoutLastHistoryInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  detail?: InputMaybe<OrderDetailUpdateOneRequiredWithoutHistoriesNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  nextHistory?: InputMaybe<OrderDetailHistoryUpdateOneWithoutLastHistoryNestedInput>;
  operation?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  operator?: InputMaybe<UserUpdateOneRequiredWithoutHistoryNestedInput>;
  status?: InputMaybe<EnumOrderStatusFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type OrderDetailHistoryUpdateWithoutNextHistoryInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  detail?: InputMaybe<OrderDetailUpdateOneRequiredWithoutHistoriesNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  lastHistory?: InputMaybe<OrderDetailHistoryUpdateOneWithoutNextHistoryNestedInput>;
  operation?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  operator?: InputMaybe<UserUpdateOneRequiredWithoutHistoryNestedInput>;
  status?: InputMaybe<EnumOrderStatusFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type OrderDetailHistoryUpdateWithoutOperatorInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  detail?: InputMaybe<OrderDetailUpdateOneRequiredWithoutHistoriesNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  lastHistory?: InputMaybe<OrderDetailHistoryUpdateOneWithoutNextHistoryNestedInput>;
  nextHistory?: InputMaybe<OrderDetailHistoryUpdateOneWithoutLastHistoryNestedInput>;
  operation?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumOrderStatusFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type OrderDetailHistoryUpsertWithWhereUniqueWithoutDetailInput = {
  create: OrderDetailHistoryCreateWithoutDetailInput;
  update: OrderDetailHistoryUpdateWithoutDetailInput;
  where: OrderDetailHistoryWhereUniqueInput;
};

export type OrderDetailHistoryUpsertWithWhereUniqueWithoutOperatorInput = {
  create: OrderDetailHistoryCreateWithoutOperatorInput;
  update: OrderDetailHistoryUpdateWithoutOperatorInput;
  where: OrderDetailHistoryWhereUniqueInput;
};

export type OrderDetailHistoryUpsertWithoutLastHistoryInput = {
  create: OrderDetailHistoryCreateWithoutLastHistoryInput;
  update: OrderDetailHistoryUpdateWithoutLastHistoryInput;
};

export type OrderDetailHistoryUpsertWithoutNextHistoryInput = {
  create: OrderDetailHistoryCreateWithoutNextHistoryInput;
  update: OrderDetailHistoryUpdateWithoutNextHistoryInput;
};

export type OrderDetailHistoryWhereInput = {
  AND?: InputMaybe<Array<OrderDetailHistoryWhereInput>>;
  NOT?: InputMaybe<Array<OrderDetailHistoryWhereInput>>;
  OR?: InputMaybe<Array<OrderDetailHistoryWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  detail?: InputMaybe<OrderDetailRelationFilter>;
  detailId?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  lastHistory?: InputMaybe<OrderDetailHistoryRelationFilter>;
  lastHistoryId?: InputMaybe<StringNullableFilter>;
  nextHistory?: InputMaybe<OrderDetailHistoryRelationFilter>;
  operation?: InputMaybe<StringNullableFilter>;
  operator?: InputMaybe<UserRelationFilter>;
  operatorId?: InputMaybe<StringFilter>;
  status?: InputMaybe<EnumOrderStatusFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type OrderDetailHistoryWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
  lastHistoryId?: InputMaybe<Scalars['String']>;
};

export type OrderDetailListRelationFilter = {
  every?: InputMaybe<OrderDetailWhereInput>;
  none?: InputMaybe<OrderDetailWhereInput>;
  some?: InputMaybe<OrderDetailWhereInput>;
};

export type OrderDetailMaxAggregate = {
  __typename?: 'OrderDetailMaxAggregate';
  address?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  memo?: Maybe<Scalars['String']>;
  orderId?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  postId?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Decimal']>;
  printCompany?: Maybe<Scalars['String']>;
  printStatus?: Maybe<PrintStatus>;
  productId?: Maybe<Scalars['String']>;
  realname?: Maybe<Scalars['String']>;
  receiverId?: Maybe<Scalars['String']>;
  sendCompany?: Maybe<Scalars['String']>;
  sendDate?: Maybe<Scalars['DateTime']>;
  sendId?: Maybe<Scalars['String']>;
  sendStatus?: Maybe<PackageStatus>;
  status?: Maybe<OrderStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type OrderDetailMaxOrderByAggregateInput = {
  address?: InputMaybe<SortOrder>;
  country?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  memo?: InputMaybe<SortOrder>;
  orderId?: InputMaybe<SortOrder>;
  phone?: InputMaybe<SortOrder>;
  postId?: InputMaybe<SortOrder>;
  price?: InputMaybe<SortOrder>;
  printCompany?: InputMaybe<SortOrder>;
  printStatus?: InputMaybe<SortOrder>;
  productId?: InputMaybe<SortOrder>;
  realname?: InputMaybe<SortOrder>;
  receiverId?: InputMaybe<SortOrder>;
  sendCompany?: InputMaybe<SortOrder>;
  sendDate?: InputMaybe<SortOrder>;
  sendId?: InputMaybe<SortOrder>;
  sendStatus?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type OrderDetailMinAggregate = {
  __typename?: 'OrderDetailMinAggregate';
  address?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  memo?: Maybe<Scalars['String']>;
  orderId?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  postId?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Decimal']>;
  printCompany?: Maybe<Scalars['String']>;
  printStatus?: Maybe<PrintStatus>;
  productId?: Maybe<Scalars['String']>;
  realname?: Maybe<Scalars['String']>;
  receiverId?: Maybe<Scalars['String']>;
  sendCompany?: Maybe<Scalars['String']>;
  sendDate?: Maybe<Scalars['DateTime']>;
  sendId?: Maybe<Scalars['String']>;
  sendStatus?: Maybe<PackageStatus>;
  status?: Maybe<OrderStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type OrderDetailMinOrderByAggregateInput = {
  address?: InputMaybe<SortOrder>;
  country?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  memo?: InputMaybe<SortOrder>;
  orderId?: InputMaybe<SortOrder>;
  phone?: InputMaybe<SortOrder>;
  postId?: InputMaybe<SortOrder>;
  price?: InputMaybe<SortOrder>;
  printCompany?: InputMaybe<SortOrder>;
  printStatus?: InputMaybe<SortOrder>;
  productId?: InputMaybe<SortOrder>;
  realname?: InputMaybe<SortOrder>;
  receiverId?: InputMaybe<SortOrder>;
  sendCompany?: InputMaybe<SortOrder>;
  sendDate?: InputMaybe<SortOrder>;
  sendId?: InputMaybe<SortOrder>;
  sendStatus?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type OrderDetailOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type OrderDetailOrderByWithAggregationInput = {
  _avg?: InputMaybe<OrderDetailAvgOrderByAggregateInput>;
  _count?: InputMaybe<OrderDetailCountOrderByAggregateInput>;
  _max?: InputMaybe<OrderDetailMaxOrderByAggregateInput>;
  _min?: InputMaybe<OrderDetailMinOrderByAggregateInput>;
  _sum?: InputMaybe<OrderDetailSumOrderByAggregateInput>;
  address?: InputMaybe<SortOrder>;
  country?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  memo?: InputMaybe<SortOrder>;
  orderId?: InputMaybe<SortOrder>;
  phone?: InputMaybe<SortOrder>;
  postId?: InputMaybe<SortOrder>;
  price?: InputMaybe<SortOrder>;
  printCompany?: InputMaybe<SortOrder>;
  printStatus?: InputMaybe<SortOrder>;
  productId?: InputMaybe<SortOrder>;
  realname?: InputMaybe<SortOrder>;
  receiverId?: InputMaybe<SortOrder>;
  sendCompany?: InputMaybe<SortOrder>;
  sendDate?: InputMaybe<SortOrder>;
  sendId?: InputMaybe<SortOrder>;
  sendStatus?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type OrderDetailOrderByWithRelationInput = {
  address?: InputMaybe<SortOrder>;
  country?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  histories?: InputMaybe<OrderDetailHistoryOrderByRelationAggregateInput>;
  id?: InputMaybe<SortOrder>;
  memo?: InputMaybe<SortOrder>;
  order?: InputMaybe<OrderOrderByWithRelationInput>;
  orderId?: InputMaybe<SortOrder>;
  phone?: InputMaybe<SortOrder>;
  post?: InputMaybe<PostOrderByWithRelationInput>;
  postId?: InputMaybe<SortOrder>;
  price?: InputMaybe<SortOrder>;
  printCompany?: InputMaybe<SortOrder>;
  printStatus?: InputMaybe<SortOrder>;
  product?: InputMaybe<ProductOrderByWithRelationInput>;
  productId?: InputMaybe<SortOrder>;
  realname?: InputMaybe<SortOrder>;
  receiver?: InputMaybe<UserOrderByWithRelationInput>;
  receiverId?: InputMaybe<SortOrder>;
  sendCompany?: InputMaybe<SortOrder>;
  sendDate?: InputMaybe<SortOrder>;
  sendId?: InputMaybe<SortOrder>;
  sendStatus?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  tags?: InputMaybe<OrderTagOrderByRelationAggregateInput>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type OrderDetailRelationFilter = {
  is?: InputMaybe<OrderDetailWhereInput>;
  isNot?: InputMaybe<OrderDetailWhereInput>;
};

export enum OrderDetailScalarFieldEnum {
  Address = 'address',
  Country = 'country',
  CreatedAt = 'createdAt',
  Id = 'id',
  Memo = 'memo',
  OrderId = 'orderId',
  Phone = 'phone',
  PostId = 'postId',
  Price = 'price',
  PrintCompany = 'printCompany',
  PrintStatus = 'printStatus',
  ProductId = 'productId',
  Realname = 'realname',
  ReceiverId = 'receiverId',
  SendCompany = 'sendCompany',
  SendDate = 'sendDate',
  SendId = 'sendId',
  SendStatus = 'sendStatus',
  Status = 'status',
  UpdatedAt = 'updatedAt'
}

export type OrderDetailScalarWhereInput = {
  AND?: InputMaybe<Array<OrderDetailScalarWhereInput>>;
  NOT?: InputMaybe<Array<OrderDetailScalarWhereInput>>;
  OR?: InputMaybe<Array<OrderDetailScalarWhereInput>>;
  address?: InputMaybe<StringNullableFilter>;
  country?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  memo?: InputMaybe<StringNullableFilter>;
  orderId?: InputMaybe<StringFilter>;
  phone?: InputMaybe<StringNullableFilter>;
  postId?: InputMaybe<StringFilter>;
  price?: InputMaybe<DecimalFilter>;
  printCompany?: InputMaybe<StringNullableFilter>;
  printStatus?: InputMaybe<EnumPrintStatusFilter>;
  productId?: InputMaybe<StringNullableFilter>;
  realname?: InputMaybe<StringNullableFilter>;
  receiverId?: InputMaybe<StringNullableFilter>;
  sendCompany?: InputMaybe<StringNullableFilter>;
  sendDate?: InputMaybe<DateTimeFilter>;
  sendId?: InputMaybe<StringNullableFilter>;
  sendStatus?: InputMaybe<EnumPackageStatusFilter>;
  status?: InputMaybe<EnumOrderStatusFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type OrderDetailScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<OrderDetailScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<OrderDetailScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<OrderDetailScalarWhereWithAggregatesInput>>;
  address?: InputMaybe<StringNullableWithAggregatesFilter>;
  country?: InputMaybe<StringNullableWithAggregatesFilter>;
  createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  id?: InputMaybe<StringWithAggregatesFilter>;
  memo?: InputMaybe<StringNullableWithAggregatesFilter>;
  orderId?: InputMaybe<StringWithAggregatesFilter>;
  phone?: InputMaybe<StringNullableWithAggregatesFilter>;
  postId?: InputMaybe<StringWithAggregatesFilter>;
  price?: InputMaybe<DecimalWithAggregatesFilter>;
  printCompany?: InputMaybe<StringNullableWithAggregatesFilter>;
  printStatus?: InputMaybe<EnumPrintStatusWithAggregatesFilter>;
  productId?: InputMaybe<StringNullableWithAggregatesFilter>;
  realname?: InputMaybe<StringNullableWithAggregatesFilter>;
  receiverId?: InputMaybe<StringNullableWithAggregatesFilter>;
  sendCompany?: InputMaybe<StringNullableWithAggregatesFilter>;
  sendDate?: InputMaybe<DateTimeWithAggregatesFilter>;
  sendId?: InputMaybe<StringNullableWithAggregatesFilter>;
  sendStatus?: InputMaybe<EnumPackageStatusWithAggregatesFilter>;
  status?: InputMaybe<EnumOrderStatusWithAggregatesFilter>;
  updatedAt?: InputMaybe<DateTimeWithAggregatesFilter>;
};

export type OrderDetailSumAggregate = {
  __typename?: 'OrderDetailSumAggregate';
  price?: Maybe<Scalars['Decimal']>;
};

export type OrderDetailSumOrderByAggregateInput = {
  price?: InputMaybe<SortOrder>;
};

export type OrderDetailUpdateInput = {
  address?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  country?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  histories?: InputMaybe<OrderDetailHistoryUpdateManyWithoutDetailNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  memo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  order?: InputMaybe<OrderUpdateOneRequiredWithoutDetailsNestedInput>;
  phone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  post?: InputMaybe<PostUpdateOneRequiredWithoutDetailNestedInput>;
  price?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  printCompany?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  printStatus?: InputMaybe<EnumPrintStatusFieldUpdateOperationsInput>;
  product?: InputMaybe<ProductUpdateOneWithoutOrderDetailsNestedInput>;
  realname?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  receiver?: InputMaybe<UserUpdateOneWithoutDetailNestedInput>;
  sendCompany?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sendDate?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  sendId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sendStatus?: InputMaybe<EnumPackageStatusFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumOrderStatusFieldUpdateOperationsInput>;
  tags?: InputMaybe<OrderTagUpdateManyWithoutOrderDetailsNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type OrderDetailUpdateManyMutationInput = {
  address?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  country?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  memo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  phone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  price?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  printCompany?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  printStatus?: InputMaybe<EnumPrintStatusFieldUpdateOperationsInput>;
  realname?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sendCompany?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sendDate?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  sendId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sendStatus?: InputMaybe<EnumPackageStatusFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumOrderStatusFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type OrderDetailUpdateManyWithWhereWithoutOrderInput = {
  data: OrderDetailUpdateManyMutationInput;
  where: OrderDetailScalarWhereInput;
};

export type OrderDetailUpdateManyWithWhereWithoutProductInput = {
  data: OrderDetailUpdateManyMutationInput;
  where: OrderDetailScalarWhereInput;
};

export type OrderDetailUpdateManyWithWhereWithoutReceiverInput = {
  data: OrderDetailUpdateManyMutationInput;
  where: OrderDetailScalarWhereInput;
};

export type OrderDetailUpdateManyWithWhereWithoutTagsInput = {
  data: OrderDetailUpdateManyMutationInput;
  where: OrderDetailScalarWhereInput;
};

export type OrderDetailUpdateManyWithoutOrderNestedInput = {
  connect?: InputMaybe<Array<OrderDetailWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<OrderDetailCreateOrConnectWithoutOrderInput>>;
  create?: InputMaybe<Array<OrderDetailCreateWithoutOrderInput>>;
  createMany?: InputMaybe<OrderDetailCreateManyOrderInputEnvelope>;
  delete?: InputMaybe<Array<OrderDetailWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<OrderDetailScalarWhereInput>>;
  disconnect?: InputMaybe<Array<OrderDetailWhereUniqueInput>>;
  set?: InputMaybe<Array<OrderDetailWhereUniqueInput>>;
  update?: InputMaybe<Array<OrderDetailUpdateWithWhereUniqueWithoutOrderInput>>;
  updateMany?: InputMaybe<Array<OrderDetailUpdateManyWithWhereWithoutOrderInput>>;
  upsert?: InputMaybe<Array<OrderDetailUpsertWithWhereUniqueWithoutOrderInput>>;
};

export type OrderDetailUpdateManyWithoutProductNestedInput = {
  connect?: InputMaybe<Array<OrderDetailWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<OrderDetailCreateOrConnectWithoutProductInput>>;
  create?: InputMaybe<Array<OrderDetailCreateWithoutProductInput>>;
  createMany?: InputMaybe<OrderDetailCreateManyProductInputEnvelope>;
  delete?: InputMaybe<Array<OrderDetailWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<OrderDetailScalarWhereInput>>;
  disconnect?: InputMaybe<Array<OrderDetailWhereUniqueInput>>;
  set?: InputMaybe<Array<OrderDetailWhereUniqueInput>>;
  update?: InputMaybe<Array<OrderDetailUpdateWithWhereUniqueWithoutProductInput>>;
  updateMany?: InputMaybe<Array<OrderDetailUpdateManyWithWhereWithoutProductInput>>;
  upsert?: InputMaybe<Array<OrderDetailUpsertWithWhereUniqueWithoutProductInput>>;
};

export type OrderDetailUpdateManyWithoutReceiverNestedInput = {
  connect?: InputMaybe<Array<OrderDetailWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<OrderDetailCreateOrConnectWithoutReceiverInput>>;
  create?: InputMaybe<Array<OrderDetailCreateWithoutReceiverInput>>;
  createMany?: InputMaybe<OrderDetailCreateManyReceiverInputEnvelope>;
  delete?: InputMaybe<Array<OrderDetailWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<OrderDetailScalarWhereInput>>;
  disconnect?: InputMaybe<Array<OrderDetailWhereUniqueInput>>;
  set?: InputMaybe<Array<OrderDetailWhereUniqueInput>>;
  update?: InputMaybe<Array<OrderDetailUpdateWithWhereUniqueWithoutReceiverInput>>;
  updateMany?: InputMaybe<Array<OrderDetailUpdateManyWithWhereWithoutReceiverInput>>;
  upsert?: InputMaybe<Array<OrderDetailUpsertWithWhereUniqueWithoutReceiverInput>>;
};

export type OrderDetailUpdateManyWithoutTagsNestedInput = {
  connect?: InputMaybe<Array<OrderDetailWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<OrderDetailCreateOrConnectWithoutTagsInput>>;
  create?: InputMaybe<Array<OrderDetailCreateWithoutTagsInput>>;
  delete?: InputMaybe<Array<OrderDetailWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<OrderDetailScalarWhereInput>>;
  disconnect?: InputMaybe<Array<OrderDetailWhereUniqueInput>>;
  set?: InputMaybe<Array<OrderDetailWhereUniqueInput>>;
  update?: InputMaybe<Array<OrderDetailUpdateWithWhereUniqueWithoutTagsInput>>;
  updateMany?: InputMaybe<Array<OrderDetailUpdateManyWithWhereWithoutTagsInput>>;
  upsert?: InputMaybe<Array<OrderDetailUpsertWithWhereUniqueWithoutTagsInput>>;
};

export type OrderDetailUpdateOneRequiredWithoutHistoriesNestedInput = {
  connect?: InputMaybe<OrderDetailWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OrderDetailCreateOrConnectWithoutHistoriesInput>;
  create?: InputMaybe<OrderDetailCreateWithoutHistoriesInput>;
  update?: InputMaybe<OrderDetailUpdateWithoutHistoriesInput>;
  upsert?: InputMaybe<OrderDetailUpsertWithoutHistoriesInput>;
};

export type OrderDetailUpdateOneWithoutPostNestedInput = {
  connect?: InputMaybe<OrderDetailWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OrderDetailCreateOrConnectWithoutPostInput>;
  create?: InputMaybe<OrderDetailCreateWithoutPostInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<OrderDetailUpdateWithoutPostInput>;
  upsert?: InputMaybe<OrderDetailUpsertWithoutPostInput>;
};

export type OrderDetailUpdateWithWhereUniqueWithoutOrderInput = {
  data: OrderDetailUpdateWithoutOrderInput;
  where: OrderDetailWhereUniqueInput;
};

export type OrderDetailUpdateWithWhereUniqueWithoutProductInput = {
  data: OrderDetailUpdateWithoutProductInput;
  where: OrderDetailWhereUniqueInput;
};

export type OrderDetailUpdateWithWhereUniqueWithoutReceiverInput = {
  data: OrderDetailUpdateWithoutReceiverInput;
  where: OrderDetailWhereUniqueInput;
};

export type OrderDetailUpdateWithWhereUniqueWithoutTagsInput = {
  data: OrderDetailUpdateWithoutTagsInput;
  where: OrderDetailWhereUniqueInput;
};

export type OrderDetailUpdateWithoutHistoriesInput = {
  address?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  country?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  memo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  order?: InputMaybe<OrderUpdateOneRequiredWithoutDetailsNestedInput>;
  phone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  post?: InputMaybe<PostUpdateOneRequiredWithoutDetailNestedInput>;
  price?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  printCompany?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  printStatus?: InputMaybe<EnumPrintStatusFieldUpdateOperationsInput>;
  product?: InputMaybe<ProductUpdateOneWithoutOrderDetailsNestedInput>;
  realname?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  receiver?: InputMaybe<UserUpdateOneWithoutDetailNestedInput>;
  sendCompany?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sendDate?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  sendId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sendStatus?: InputMaybe<EnumPackageStatusFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumOrderStatusFieldUpdateOperationsInput>;
  tags?: InputMaybe<OrderTagUpdateManyWithoutOrderDetailsNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type OrderDetailUpdateWithoutOrderInput = {
  address?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  country?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  histories?: InputMaybe<OrderDetailHistoryUpdateManyWithoutDetailNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  memo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  phone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  post?: InputMaybe<PostUpdateOneRequiredWithoutDetailNestedInput>;
  price?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  printCompany?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  printStatus?: InputMaybe<EnumPrintStatusFieldUpdateOperationsInput>;
  product?: InputMaybe<ProductUpdateOneWithoutOrderDetailsNestedInput>;
  realname?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  receiver?: InputMaybe<UserUpdateOneWithoutDetailNestedInput>;
  sendCompany?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sendDate?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  sendId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sendStatus?: InputMaybe<EnumPackageStatusFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumOrderStatusFieldUpdateOperationsInput>;
  tags?: InputMaybe<OrderTagUpdateManyWithoutOrderDetailsNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type OrderDetailUpdateWithoutPostInput = {
  address?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  country?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  histories?: InputMaybe<OrderDetailHistoryUpdateManyWithoutDetailNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  memo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  order?: InputMaybe<OrderUpdateOneRequiredWithoutDetailsNestedInput>;
  phone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  price?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  printCompany?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  printStatus?: InputMaybe<EnumPrintStatusFieldUpdateOperationsInput>;
  product?: InputMaybe<ProductUpdateOneWithoutOrderDetailsNestedInput>;
  realname?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  receiver?: InputMaybe<UserUpdateOneWithoutDetailNestedInput>;
  sendCompany?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sendDate?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  sendId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sendStatus?: InputMaybe<EnumPackageStatusFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumOrderStatusFieldUpdateOperationsInput>;
  tags?: InputMaybe<OrderTagUpdateManyWithoutOrderDetailsNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type OrderDetailUpdateWithoutProductInput = {
  address?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  country?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  histories?: InputMaybe<OrderDetailHistoryUpdateManyWithoutDetailNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  memo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  order?: InputMaybe<OrderUpdateOneRequiredWithoutDetailsNestedInput>;
  phone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  post?: InputMaybe<PostUpdateOneRequiredWithoutDetailNestedInput>;
  price?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  printCompany?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  printStatus?: InputMaybe<EnumPrintStatusFieldUpdateOperationsInput>;
  realname?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  receiver?: InputMaybe<UserUpdateOneWithoutDetailNestedInput>;
  sendCompany?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sendDate?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  sendId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sendStatus?: InputMaybe<EnumPackageStatusFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumOrderStatusFieldUpdateOperationsInput>;
  tags?: InputMaybe<OrderTagUpdateManyWithoutOrderDetailsNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type OrderDetailUpdateWithoutReceiverInput = {
  address?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  country?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  histories?: InputMaybe<OrderDetailHistoryUpdateManyWithoutDetailNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  memo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  order?: InputMaybe<OrderUpdateOneRequiredWithoutDetailsNestedInput>;
  phone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  post?: InputMaybe<PostUpdateOneRequiredWithoutDetailNestedInput>;
  price?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  printCompany?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  printStatus?: InputMaybe<EnumPrintStatusFieldUpdateOperationsInput>;
  product?: InputMaybe<ProductUpdateOneWithoutOrderDetailsNestedInput>;
  realname?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sendCompany?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sendDate?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  sendId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sendStatus?: InputMaybe<EnumPackageStatusFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumOrderStatusFieldUpdateOperationsInput>;
  tags?: InputMaybe<OrderTagUpdateManyWithoutOrderDetailsNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type OrderDetailUpdateWithoutTagsInput = {
  address?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  country?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  histories?: InputMaybe<OrderDetailHistoryUpdateManyWithoutDetailNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  memo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  order?: InputMaybe<OrderUpdateOneRequiredWithoutDetailsNestedInput>;
  phone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  post?: InputMaybe<PostUpdateOneRequiredWithoutDetailNestedInput>;
  price?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  printCompany?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  printStatus?: InputMaybe<EnumPrintStatusFieldUpdateOperationsInput>;
  product?: InputMaybe<ProductUpdateOneWithoutOrderDetailsNestedInput>;
  realname?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  receiver?: InputMaybe<UserUpdateOneWithoutDetailNestedInput>;
  sendCompany?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sendDate?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  sendId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sendStatus?: InputMaybe<EnumPackageStatusFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumOrderStatusFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type OrderDetailUpsertWithWhereUniqueWithoutOrderInput = {
  create: OrderDetailCreateWithoutOrderInput;
  update: OrderDetailUpdateWithoutOrderInput;
  where: OrderDetailWhereUniqueInput;
};

export type OrderDetailUpsertWithWhereUniqueWithoutProductInput = {
  create: OrderDetailCreateWithoutProductInput;
  update: OrderDetailUpdateWithoutProductInput;
  where: OrderDetailWhereUniqueInput;
};

export type OrderDetailUpsertWithWhereUniqueWithoutReceiverInput = {
  create: OrderDetailCreateWithoutReceiverInput;
  update: OrderDetailUpdateWithoutReceiverInput;
  where: OrderDetailWhereUniqueInput;
};

export type OrderDetailUpsertWithWhereUniqueWithoutTagsInput = {
  create: OrderDetailCreateWithoutTagsInput;
  update: OrderDetailUpdateWithoutTagsInput;
  where: OrderDetailWhereUniqueInput;
};

export type OrderDetailUpsertWithoutHistoriesInput = {
  create: OrderDetailCreateWithoutHistoriesInput;
  update: OrderDetailUpdateWithoutHistoriesInput;
};

export type OrderDetailUpsertWithoutPostInput = {
  create: OrderDetailCreateWithoutPostInput;
  update: OrderDetailUpdateWithoutPostInput;
};

export type OrderDetailWhereInput = {
  AND?: InputMaybe<Array<OrderDetailWhereInput>>;
  NOT?: InputMaybe<Array<OrderDetailWhereInput>>;
  OR?: InputMaybe<Array<OrderDetailWhereInput>>;
  address?: InputMaybe<StringNullableFilter>;
  country?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  histories?: InputMaybe<OrderDetailHistoryListRelationFilter>;
  id?: InputMaybe<StringFilter>;
  memo?: InputMaybe<StringNullableFilter>;
  order?: InputMaybe<OrderRelationFilter>;
  orderId?: InputMaybe<StringFilter>;
  phone?: InputMaybe<StringNullableFilter>;
  post?: InputMaybe<PostRelationFilter>;
  postId?: InputMaybe<StringFilter>;
  price?: InputMaybe<DecimalFilter>;
  printCompany?: InputMaybe<StringNullableFilter>;
  printStatus?: InputMaybe<EnumPrintStatusFilter>;
  product?: InputMaybe<ProductRelationFilter>;
  productId?: InputMaybe<StringNullableFilter>;
  realname?: InputMaybe<StringNullableFilter>;
  receiver?: InputMaybe<UserRelationFilter>;
  receiverId?: InputMaybe<StringNullableFilter>;
  sendCompany?: InputMaybe<StringNullableFilter>;
  sendDate?: InputMaybe<DateTimeFilter>;
  sendId?: InputMaybe<StringNullableFilter>;
  sendStatus?: InputMaybe<EnumPackageStatusFilter>;
  status?: InputMaybe<EnumOrderStatusFilter>;
  tags?: InputMaybe<OrderTagListRelationFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type OrderDetailWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
  postId?: InputMaybe<Scalars['String']>;
};

export type OrderGroupBy = {
  __typename?: 'OrderGroupBy';
  _avg?: Maybe<OrderAvgAggregate>;
  _count?: Maybe<OrderCountAggregate>;
  _max?: Maybe<OrderMaxAggregate>;
  _min?: Maybe<OrderMinAggregate>;
  _sum?: Maybe<OrderSumAggregate>;
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  invoiceParam?: Maybe<Scalars['JSON']>;
  isInvoiced: Scalars['Boolean'];
  isPaid: Scalars['Boolean'];
  postPackageId: Scalars['String'];
  price: Scalars['Decimal'];
  readableId?: Maybe<Scalars['String']>;
  refundPaymentId?: Maybe<Scalars['String']>;
  senderId: Scalars['String'];
  status: OrderStatus;
  updatedAt: Scalars['DateTime'];
};

export type OrderListRelationFilter = {
  every?: InputMaybe<OrderWhereInput>;
  none?: InputMaybe<OrderWhereInput>;
  some?: InputMaybe<OrderWhereInput>;
};

export type OrderMaxAggregate = {
  __typename?: 'OrderMaxAggregate';
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isInvoiced?: Maybe<Scalars['Boolean']>;
  isPaid?: Maybe<Scalars['Boolean']>;
  postPackageId?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Decimal']>;
  readableId?: Maybe<Scalars['String']>;
  refundPaymentId?: Maybe<Scalars['String']>;
  senderId?: Maybe<Scalars['String']>;
  status?: Maybe<OrderStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type OrderMaxOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  isInvoiced?: InputMaybe<SortOrder>;
  isPaid?: InputMaybe<SortOrder>;
  postPackageId?: InputMaybe<SortOrder>;
  price?: InputMaybe<SortOrder>;
  readableId?: InputMaybe<SortOrder>;
  refundPaymentId?: InputMaybe<SortOrder>;
  senderId?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type OrderMinAggregate = {
  __typename?: 'OrderMinAggregate';
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isInvoiced?: Maybe<Scalars['Boolean']>;
  isPaid?: Maybe<Scalars['Boolean']>;
  postPackageId?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Decimal']>;
  readableId?: Maybe<Scalars['String']>;
  refundPaymentId?: Maybe<Scalars['String']>;
  senderId?: Maybe<Scalars['String']>;
  status?: Maybe<OrderStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type OrderMinOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  isInvoiced?: InputMaybe<SortOrder>;
  isPaid?: InputMaybe<SortOrder>;
  postPackageId?: InputMaybe<SortOrder>;
  price?: InputMaybe<SortOrder>;
  readableId?: InputMaybe<SortOrder>;
  refundPaymentId?: InputMaybe<SortOrder>;
  senderId?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type OrderOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type OrderOrderByWithAggregationInput = {
  _avg?: InputMaybe<OrderAvgOrderByAggregateInput>;
  _count?: InputMaybe<OrderCountOrderByAggregateInput>;
  _max?: InputMaybe<OrderMaxOrderByAggregateInput>;
  _min?: InputMaybe<OrderMinOrderByAggregateInput>;
  _sum?: InputMaybe<OrderSumOrderByAggregateInput>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  invoiceParam?: InputMaybe<SortOrder>;
  isInvoiced?: InputMaybe<SortOrder>;
  isPaid?: InputMaybe<SortOrder>;
  postPackageId?: InputMaybe<SortOrder>;
  price?: InputMaybe<SortOrder>;
  readableId?: InputMaybe<SortOrder>;
  refundPaymentId?: InputMaybe<SortOrder>;
  senderId?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type OrderOrderByWithRelationInput = {
  benefitMemos?: InputMaybe<BenefitMemoToOrderOrderByRelationAggregateInput>;
  coin?: InputMaybe<CoinOrderByWithRelationInput>;
  couponEntities?: InputMaybe<CouponEntityOrderByRelationAggregateInput>;
  createdAt?: InputMaybe<SortOrder>;
  details?: InputMaybe<OrderDetailOrderByRelationAggregateInput>;
  id?: InputMaybe<SortOrder>;
  invoice?: InputMaybe<InvoiceOrderByRelationAggregateInput>;
  invoiceParam?: InputMaybe<SortOrder>;
  isInvoiced?: InputMaybe<SortOrder>;
  isPaid?: InputMaybe<SortOrder>;
  payment?: InputMaybe<PaymentOrderByRelationAggregateInput>;
  postPackage?: InputMaybe<PostPackageOrderByWithRelationInput>;
  postPackageId?: InputMaybe<SortOrder>;
  price?: InputMaybe<SortOrder>;
  readableId?: InputMaybe<SortOrder>;
  refundPayment?: InputMaybe<PaymentOrderByWithRelationInput>;
  refundPaymentId?: InputMaybe<SortOrder>;
  sender?: InputMaybe<UserOrderByWithRelationInput>;
  senderId?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type OrderRelationFilter = {
  is?: InputMaybe<OrderWhereInput>;
  isNot?: InputMaybe<OrderWhereInput>;
};

export enum OrderScalarFieldEnum {
  CreatedAt = 'createdAt',
  Id = 'id',
  InvoiceParam = 'invoiceParam',
  IsInvoiced = 'isInvoiced',
  IsPaid = 'isPaid',
  PostPackageId = 'postPackageId',
  Price = 'price',
  ReadableId = 'readableId',
  RefundPaymentId = 'refundPaymentId',
  SenderId = 'senderId',
  Status = 'status',
  UpdatedAt = 'updatedAt'
}

export type OrderScalarWhereInput = {
  AND?: InputMaybe<Array<OrderScalarWhereInput>>;
  NOT?: InputMaybe<Array<OrderScalarWhereInput>>;
  OR?: InputMaybe<Array<OrderScalarWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  invoiceParam?: InputMaybe<JsonNullableFilter>;
  isInvoiced?: InputMaybe<BoolFilter>;
  isPaid?: InputMaybe<BoolFilter>;
  postPackageId?: InputMaybe<StringFilter>;
  price?: InputMaybe<DecimalFilter>;
  readableId?: InputMaybe<StringNullableFilter>;
  refundPaymentId?: InputMaybe<StringNullableFilter>;
  senderId?: InputMaybe<StringFilter>;
  status?: InputMaybe<EnumOrderStatusFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type OrderScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<OrderScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<OrderScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<OrderScalarWhereWithAggregatesInput>>;
  createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  id?: InputMaybe<StringWithAggregatesFilter>;
  invoiceParam?: InputMaybe<JsonNullableWithAggregatesFilter>;
  isInvoiced?: InputMaybe<BoolWithAggregatesFilter>;
  isPaid?: InputMaybe<BoolWithAggregatesFilter>;
  postPackageId?: InputMaybe<StringWithAggregatesFilter>;
  price?: InputMaybe<DecimalWithAggregatesFilter>;
  readableId?: InputMaybe<StringNullableWithAggregatesFilter>;
  refundPaymentId?: InputMaybe<StringNullableWithAggregatesFilter>;
  senderId?: InputMaybe<StringWithAggregatesFilter>;
  status?: InputMaybe<EnumOrderStatusWithAggregatesFilter>;
  updatedAt?: InputMaybe<DateTimeWithAggregatesFilter>;
};

export enum OrderStatus {
  Arrived = 'arrived',
  Cancel = 'cancel',
  Done = 'done',
  Error = 'error',
  Printed = 'printed',
  Printing = 'printing',
  Received = 'received',
  Resend = 'resend',
  Sent = 'sent',
  WaitToPrint = 'waitToPrint',
  WaitToSend = 'waitToSend'
}

export type OrderSumAggregate = {
  __typename?: 'OrderSumAggregate';
  price?: Maybe<Scalars['Decimal']>;
};

export type OrderSumOrderByAggregateInput = {
  price?: InputMaybe<SortOrder>;
};

export type OrderTag = {
  __typename?: 'OrderTag';
  _count?: Maybe<OrderTagCount>;
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  orderDetails: Array<OrderDetail>;
  tag: Scalars['String'];
};


export type OrderTagOrderDetailsArgs = {
  cursor?: InputMaybe<OrderDetailWhereUniqueInput>;
  distinct?: InputMaybe<Array<OrderDetailScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<OrderDetailOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<OrderDetailWhereInput>;
};

export type OrderTagCount = {
  __typename?: 'OrderTagCount';
  orderDetails: Scalars['Int'];
};


export type OrderTagCountOrderDetailsArgs = {
  where?: InputMaybe<OrderDetailWhereInput>;
};

export type OrderTagCountAggregate = {
  __typename?: 'OrderTagCountAggregate';
  _all: Scalars['Int'];
  createdAt: Scalars['Int'];
  id: Scalars['Int'];
  tag: Scalars['Int'];
};

export type OrderTagCountOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  tag?: InputMaybe<SortOrder>;
};

export type OrderTagCreateInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  orderDetails?: InputMaybe<OrderDetailCreateNestedManyWithoutTagsInput>;
  tag: Scalars['String'];
};

export type OrderTagCreateManyInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  tag: Scalars['String'];
};

export type OrderTagCreateNestedManyWithoutOrderDetailsInput = {
  connect?: InputMaybe<Array<OrderTagWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<OrderTagCreateOrConnectWithoutOrderDetailsInput>>;
  create?: InputMaybe<Array<OrderTagCreateWithoutOrderDetailsInput>>;
};

export type OrderTagCreateOrConnectWithoutOrderDetailsInput = {
  create: OrderTagCreateWithoutOrderDetailsInput;
  where: OrderTagWhereUniqueInput;
};

export type OrderTagCreateWithoutOrderDetailsInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  tag: Scalars['String'];
};

export type OrderTagGroupBy = {
  __typename?: 'OrderTagGroupBy';
  _count?: Maybe<OrderTagCountAggregate>;
  _max?: Maybe<OrderTagMaxAggregate>;
  _min?: Maybe<OrderTagMinAggregate>;
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  tag: Scalars['String'];
};

export type OrderTagListRelationFilter = {
  every?: InputMaybe<OrderTagWhereInput>;
  none?: InputMaybe<OrderTagWhereInput>;
  some?: InputMaybe<OrderTagWhereInput>;
};

export type OrderTagMaxAggregate = {
  __typename?: 'OrderTagMaxAggregate';
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  tag?: Maybe<Scalars['String']>;
};

export type OrderTagMaxOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  tag?: InputMaybe<SortOrder>;
};

export type OrderTagMinAggregate = {
  __typename?: 'OrderTagMinAggregate';
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  tag?: Maybe<Scalars['String']>;
};

export type OrderTagMinOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  tag?: InputMaybe<SortOrder>;
};

export type OrderTagOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type OrderTagOrderByWithAggregationInput = {
  _count?: InputMaybe<OrderTagCountOrderByAggregateInput>;
  _max?: InputMaybe<OrderTagMaxOrderByAggregateInput>;
  _min?: InputMaybe<OrderTagMinOrderByAggregateInput>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  tag?: InputMaybe<SortOrder>;
};

export type OrderTagOrderByWithRelationInput = {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  orderDetails?: InputMaybe<OrderDetailOrderByRelationAggregateInput>;
  tag?: InputMaybe<SortOrder>;
};

export enum OrderTagScalarFieldEnum {
  CreatedAt = 'createdAt',
  Id = 'id',
  Tag = 'tag'
}

export type OrderTagScalarWhereInput = {
  AND?: InputMaybe<Array<OrderTagScalarWhereInput>>;
  NOT?: InputMaybe<Array<OrderTagScalarWhereInput>>;
  OR?: InputMaybe<Array<OrderTagScalarWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  tag?: InputMaybe<StringFilter>;
};

export type OrderTagScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<OrderTagScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<OrderTagScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<OrderTagScalarWhereWithAggregatesInput>>;
  createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  id?: InputMaybe<StringWithAggregatesFilter>;
  tag?: InputMaybe<StringWithAggregatesFilter>;
};

export type OrderTagUpdateInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  orderDetails?: InputMaybe<OrderDetailUpdateManyWithoutTagsNestedInput>;
  tag?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type OrderTagUpdateManyMutationInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  tag?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type OrderTagUpdateManyWithWhereWithoutOrderDetailsInput = {
  data: OrderTagUpdateManyMutationInput;
  where: OrderTagScalarWhereInput;
};

export type OrderTagUpdateManyWithoutOrderDetailsNestedInput = {
  connect?: InputMaybe<Array<OrderTagWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<OrderTagCreateOrConnectWithoutOrderDetailsInput>>;
  create?: InputMaybe<Array<OrderTagCreateWithoutOrderDetailsInput>>;
  delete?: InputMaybe<Array<OrderTagWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<OrderTagScalarWhereInput>>;
  disconnect?: InputMaybe<Array<OrderTagWhereUniqueInput>>;
  set?: InputMaybe<Array<OrderTagWhereUniqueInput>>;
  update?: InputMaybe<Array<OrderTagUpdateWithWhereUniqueWithoutOrderDetailsInput>>;
  updateMany?: InputMaybe<Array<OrderTagUpdateManyWithWhereWithoutOrderDetailsInput>>;
  upsert?: InputMaybe<Array<OrderTagUpsertWithWhereUniqueWithoutOrderDetailsInput>>;
};

export type OrderTagUpdateWithWhereUniqueWithoutOrderDetailsInput = {
  data: OrderTagUpdateWithoutOrderDetailsInput;
  where: OrderTagWhereUniqueInput;
};

export type OrderTagUpdateWithoutOrderDetailsInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  tag?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type OrderTagUpsertWithWhereUniqueWithoutOrderDetailsInput = {
  create: OrderTagCreateWithoutOrderDetailsInput;
  update: OrderTagUpdateWithoutOrderDetailsInput;
  where: OrderTagWhereUniqueInput;
};

export type OrderTagWhereInput = {
  AND?: InputMaybe<Array<OrderTagWhereInput>>;
  NOT?: InputMaybe<Array<OrderTagWhereInput>>;
  OR?: InputMaybe<Array<OrderTagWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  orderDetails?: InputMaybe<OrderDetailListRelationFilter>;
  tag?: InputMaybe<StringFilter>;
};

export type OrderTagWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
  tag?: InputMaybe<Scalars['String']>;
};

export type OrderUpdateInput = {
  benefitMemos?: InputMaybe<BenefitMemoToOrderUpdateManyWithoutOrderNestedInput>;
  coin?: InputMaybe<CoinUpdateOneWithoutOrderNestedInput>;
  couponEntities?: InputMaybe<CouponEntityUpdateManyWithoutConsumeOrderNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  details?: InputMaybe<OrderDetailUpdateManyWithoutOrderNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  invoice?: InputMaybe<InvoiceUpdateManyWithoutOrderNestedInput>;
  invoiceParam?: InputMaybe<Scalars['JSON']>;
  isInvoiced?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isPaid?: InputMaybe<BoolFieldUpdateOperationsInput>;
  payment?: InputMaybe<PaymentUpdateManyWithoutOrderNestedInput>;
  postPackage?: InputMaybe<PostPackageUpdateOneRequiredWithoutOrderNestedInput>;
  price?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  readableId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  refundPayment?: InputMaybe<PaymentUpdateOneWithoutRefundOrdersNestedInput>;
  sender?: InputMaybe<UserUpdateOneRequiredWithoutOrderNestedInput>;
  status?: InputMaybe<EnumOrderStatusFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type OrderUpdateManyMutationInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  invoiceParam?: InputMaybe<Scalars['JSON']>;
  isInvoiced?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isPaid?: InputMaybe<BoolFieldUpdateOperationsInput>;
  price?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  readableId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumOrderStatusFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type OrderUpdateManyWithWhereWithoutRefundPaymentInput = {
  data: OrderUpdateManyMutationInput;
  where: OrderScalarWhereInput;
};

export type OrderUpdateManyWithWhereWithoutSenderInput = {
  data: OrderUpdateManyMutationInput;
  where: OrderScalarWhereInput;
};

export type OrderUpdateManyWithoutRefundPaymentNestedInput = {
  connect?: InputMaybe<Array<OrderWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<OrderCreateOrConnectWithoutRefundPaymentInput>>;
  create?: InputMaybe<Array<OrderCreateWithoutRefundPaymentInput>>;
  createMany?: InputMaybe<OrderCreateManyRefundPaymentInputEnvelope>;
  delete?: InputMaybe<Array<OrderWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<OrderScalarWhereInput>>;
  disconnect?: InputMaybe<Array<OrderWhereUniqueInput>>;
  set?: InputMaybe<Array<OrderWhereUniqueInput>>;
  update?: InputMaybe<Array<OrderUpdateWithWhereUniqueWithoutRefundPaymentInput>>;
  updateMany?: InputMaybe<Array<OrderUpdateManyWithWhereWithoutRefundPaymentInput>>;
  upsert?: InputMaybe<Array<OrderUpsertWithWhereUniqueWithoutRefundPaymentInput>>;
};

export type OrderUpdateManyWithoutSenderNestedInput = {
  connect?: InputMaybe<Array<OrderWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<OrderCreateOrConnectWithoutSenderInput>>;
  create?: InputMaybe<Array<OrderCreateWithoutSenderInput>>;
  createMany?: InputMaybe<OrderCreateManySenderInputEnvelope>;
  delete?: InputMaybe<Array<OrderWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<OrderScalarWhereInput>>;
  disconnect?: InputMaybe<Array<OrderWhereUniqueInput>>;
  set?: InputMaybe<Array<OrderWhereUniqueInput>>;
  update?: InputMaybe<Array<OrderUpdateWithWhereUniqueWithoutSenderInput>>;
  updateMany?: InputMaybe<Array<OrderUpdateManyWithWhereWithoutSenderInput>>;
  upsert?: InputMaybe<Array<OrderUpsertWithWhereUniqueWithoutSenderInput>>;
};

export type OrderUpdateOneRequiredWithoutBenefitMemosNestedInput = {
  connect?: InputMaybe<OrderWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OrderCreateOrConnectWithoutBenefitMemosInput>;
  create?: InputMaybe<OrderCreateWithoutBenefitMemosInput>;
  update?: InputMaybe<OrderUpdateWithoutBenefitMemosInput>;
  upsert?: InputMaybe<OrderUpsertWithoutBenefitMemosInput>;
};

export type OrderUpdateOneRequiredWithoutDetailsNestedInput = {
  connect?: InputMaybe<OrderWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OrderCreateOrConnectWithoutDetailsInput>;
  create?: InputMaybe<OrderCreateWithoutDetailsInput>;
  update?: InputMaybe<OrderUpdateWithoutDetailsInput>;
  upsert?: InputMaybe<OrderUpsertWithoutDetailsInput>;
};

export type OrderUpdateOneRequiredWithoutInvoiceNestedInput = {
  connect?: InputMaybe<OrderWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OrderCreateOrConnectWithoutInvoiceInput>;
  create?: InputMaybe<OrderCreateWithoutInvoiceInput>;
  update?: InputMaybe<OrderUpdateWithoutInvoiceInput>;
  upsert?: InputMaybe<OrderUpsertWithoutInvoiceInput>;
};

export type OrderUpdateOneRequiredWithoutPaymentNestedInput = {
  connect?: InputMaybe<OrderWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OrderCreateOrConnectWithoutPaymentInput>;
  create?: InputMaybe<OrderCreateWithoutPaymentInput>;
  update?: InputMaybe<OrderUpdateWithoutPaymentInput>;
  upsert?: InputMaybe<OrderUpsertWithoutPaymentInput>;
};

export type OrderUpdateOneWithoutCoinNestedInput = {
  connect?: InputMaybe<OrderWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OrderCreateOrConnectWithoutCoinInput>;
  create?: InputMaybe<OrderCreateWithoutCoinInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<OrderUpdateWithoutCoinInput>;
  upsert?: InputMaybe<OrderUpsertWithoutCoinInput>;
};

export type OrderUpdateOneWithoutCouponEntitiesNestedInput = {
  connect?: InputMaybe<OrderWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OrderCreateOrConnectWithoutCouponEntitiesInput>;
  create?: InputMaybe<OrderCreateWithoutCouponEntitiesInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<OrderUpdateWithoutCouponEntitiesInput>;
  upsert?: InputMaybe<OrderUpsertWithoutCouponEntitiesInput>;
};

export type OrderUpdateOneWithoutPostPackageNestedInput = {
  connect?: InputMaybe<OrderWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OrderCreateOrConnectWithoutPostPackageInput>;
  create?: InputMaybe<OrderCreateWithoutPostPackageInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<OrderUpdateWithoutPostPackageInput>;
  upsert?: InputMaybe<OrderUpsertWithoutPostPackageInput>;
};

export type OrderUpdateWithWhereUniqueWithoutRefundPaymentInput = {
  data: OrderUpdateWithoutRefundPaymentInput;
  where: OrderWhereUniqueInput;
};

export type OrderUpdateWithWhereUniqueWithoutSenderInput = {
  data: OrderUpdateWithoutSenderInput;
  where: OrderWhereUniqueInput;
};

export type OrderUpdateWithoutBenefitMemosInput = {
  coin?: InputMaybe<CoinUpdateOneWithoutOrderNestedInput>;
  couponEntities?: InputMaybe<CouponEntityUpdateManyWithoutConsumeOrderNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  details?: InputMaybe<OrderDetailUpdateManyWithoutOrderNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  invoice?: InputMaybe<InvoiceUpdateManyWithoutOrderNestedInput>;
  invoiceParam?: InputMaybe<Scalars['JSON']>;
  isInvoiced?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isPaid?: InputMaybe<BoolFieldUpdateOperationsInput>;
  payment?: InputMaybe<PaymentUpdateManyWithoutOrderNestedInput>;
  postPackage?: InputMaybe<PostPackageUpdateOneRequiredWithoutOrderNestedInput>;
  price?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  readableId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  refundPayment?: InputMaybe<PaymentUpdateOneWithoutRefundOrdersNestedInput>;
  sender?: InputMaybe<UserUpdateOneRequiredWithoutOrderNestedInput>;
  status?: InputMaybe<EnumOrderStatusFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type OrderUpdateWithoutCoinInput = {
  benefitMemos?: InputMaybe<BenefitMemoToOrderUpdateManyWithoutOrderNestedInput>;
  couponEntities?: InputMaybe<CouponEntityUpdateManyWithoutConsumeOrderNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  details?: InputMaybe<OrderDetailUpdateManyWithoutOrderNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  invoice?: InputMaybe<InvoiceUpdateManyWithoutOrderNestedInput>;
  invoiceParam?: InputMaybe<Scalars['JSON']>;
  isInvoiced?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isPaid?: InputMaybe<BoolFieldUpdateOperationsInput>;
  payment?: InputMaybe<PaymentUpdateManyWithoutOrderNestedInput>;
  postPackage?: InputMaybe<PostPackageUpdateOneRequiredWithoutOrderNestedInput>;
  price?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  readableId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  refundPayment?: InputMaybe<PaymentUpdateOneWithoutRefundOrdersNestedInput>;
  sender?: InputMaybe<UserUpdateOneRequiredWithoutOrderNestedInput>;
  status?: InputMaybe<EnumOrderStatusFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type OrderUpdateWithoutCouponEntitiesInput = {
  benefitMemos?: InputMaybe<BenefitMemoToOrderUpdateManyWithoutOrderNestedInput>;
  coin?: InputMaybe<CoinUpdateOneWithoutOrderNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  details?: InputMaybe<OrderDetailUpdateManyWithoutOrderNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  invoice?: InputMaybe<InvoiceUpdateManyWithoutOrderNestedInput>;
  invoiceParam?: InputMaybe<Scalars['JSON']>;
  isInvoiced?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isPaid?: InputMaybe<BoolFieldUpdateOperationsInput>;
  payment?: InputMaybe<PaymentUpdateManyWithoutOrderNestedInput>;
  postPackage?: InputMaybe<PostPackageUpdateOneRequiredWithoutOrderNestedInput>;
  price?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  readableId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  refundPayment?: InputMaybe<PaymentUpdateOneWithoutRefundOrdersNestedInput>;
  sender?: InputMaybe<UserUpdateOneRequiredWithoutOrderNestedInput>;
  status?: InputMaybe<EnumOrderStatusFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type OrderUpdateWithoutDetailsInput = {
  benefitMemos?: InputMaybe<BenefitMemoToOrderUpdateManyWithoutOrderNestedInput>;
  coin?: InputMaybe<CoinUpdateOneWithoutOrderNestedInput>;
  couponEntities?: InputMaybe<CouponEntityUpdateManyWithoutConsumeOrderNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  invoice?: InputMaybe<InvoiceUpdateManyWithoutOrderNestedInput>;
  invoiceParam?: InputMaybe<Scalars['JSON']>;
  isInvoiced?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isPaid?: InputMaybe<BoolFieldUpdateOperationsInput>;
  payment?: InputMaybe<PaymentUpdateManyWithoutOrderNestedInput>;
  postPackage?: InputMaybe<PostPackageUpdateOneRequiredWithoutOrderNestedInput>;
  price?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  readableId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  refundPayment?: InputMaybe<PaymentUpdateOneWithoutRefundOrdersNestedInput>;
  sender?: InputMaybe<UserUpdateOneRequiredWithoutOrderNestedInput>;
  status?: InputMaybe<EnumOrderStatusFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type OrderUpdateWithoutInvoiceInput = {
  benefitMemos?: InputMaybe<BenefitMemoToOrderUpdateManyWithoutOrderNestedInput>;
  coin?: InputMaybe<CoinUpdateOneWithoutOrderNestedInput>;
  couponEntities?: InputMaybe<CouponEntityUpdateManyWithoutConsumeOrderNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  details?: InputMaybe<OrderDetailUpdateManyWithoutOrderNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  invoiceParam?: InputMaybe<Scalars['JSON']>;
  isInvoiced?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isPaid?: InputMaybe<BoolFieldUpdateOperationsInput>;
  payment?: InputMaybe<PaymentUpdateManyWithoutOrderNestedInput>;
  postPackage?: InputMaybe<PostPackageUpdateOneRequiredWithoutOrderNestedInput>;
  price?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  readableId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  refundPayment?: InputMaybe<PaymentUpdateOneWithoutRefundOrdersNestedInput>;
  sender?: InputMaybe<UserUpdateOneRequiredWithoutOrderNestedInput>;
  status?: InputMaybe<EnumOrderStatusFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type OrderUpdateWithoutPaymentInput = {
  benefitMemos?: InputMaybe<BenefitMemoToOrderUpdateManyWithoutOrderNestedInput>;
  coin?: InputMaybe<CoinUpdateOneWithoutOrderNestedInput>;
  couponEntities?: InputMaybe<CouponEntityUpdateManyWithoutConsumeOrderNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  details?: InputMaybe<OrderDetailUpdateManyWithoutOrderNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  invoice?: InputMaybe<InvoiceUpdateManyWithoutOrderNestedInput>;
  invoiceParam?: InputMaybe<Scalars['JSON']>;
  isInvoiced?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isPaid?: InputMaybe<BoolFieldUpdateOperationsInput>;
  postPackage?: InputMaybe<PostPackageUpdateOneRequiredWithoutOrderNestedInput>;
  price?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  readableId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  refundPayment?: InputMaybe<PaymentUpdateOneWithoutRefundOrdersNestedInput>;
  sender?: InputMaybe<UserUpdateOneRequiredWithoutOrderNestedInput>;
  status?: InputMaybe<EnumOrderStatusFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type OrderUpdateWithoutPostPackageInput = {
  benefitMemos?: InputMaybe<BenefitMemoToOrderUpdateManyWithoutOrderNestedInput>;
  coin?: InputMaybe<CoinUpdateOneWithoutOrderNestedInput>;
  couponEntities?: InputMaybe<CouponEntityUpdateManyWithoutConsumeOrderNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  details?: InputMaybe<OrderDetailUpdateManyWithoutOrderNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  invoice?: InputMaybe<InvoiceUpdateManyWithoutOrderNestedInput>;
  invoiceParam?: InputMaybe<Scalars['JSON']>;
  isInvoiced?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isPaid?: InputMaybe<BoolFieldUpdateOperationsInput>;
  payment?: InputMaybe<PaymentUpdateManyWithoutOrderNestedInput>;
  price?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  readableId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  refundPayment?: InputMaybe<PaymentUpdateOneWithoutRefundOrdersNestedInput>;
  sender?: InputMaybe<UserUpdateOneRequiredWithoutOrderNestedInput>;
  status?: InputMaybe<EnumOrderStatusFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type OrderUpdateWithoutRefundPaymentInput = {
  benefitMemos?: InputMaybe<BenefitMemoToOrderUpdateManyWithoutOrderNestedInput>;
  coin?: InputMaybe<CoinUpdateOneWithoutOrderNestedInput>;
  couponEntities?: InputMaybe<CouponEntityUpdateManyWithoutConsumeOrderNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  details?: InputMaybe<OrderDetailUpdateManyWithoutOrderNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  invoice?: InputMaybe<InvoiceUpdateManyWithoutOrderNestedInput>;
  invoiceParam?: InputMaybe<Scalars['JSON']>;
  isInvoiced?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isPaid?: InputMaybe<BoolFieldUpdateOperationsInput>;
  payment?: InputMaybe<PaymentUpdateManyWithoutOrderNestedInput>;
  postPackage?: InputMaybe<PostPackageUpdateOneRequiredWithoutOrderNestedInput>;
  price?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  readableId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sender?: InputMaybe<UserUpdateOneRequiredWithoutOrderNestedInput>;
  status?: InputMaybe<EnumOrderStatusFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type OrderUpdateWithoutSenderInput = {
  benefitMemos?: InputMaybe<BenefitMemoToOrderUpdateManyWithoutOrderNestedInput>;
  coin?: InputMaybe<CoinUpdateOneWithoutOrderNestedInput>;
  couponEntities?: InputMaybe<CouponEntityUpdateManyWithoutConsumeOrderNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  details?: InputMaybe<OrderDetailUpdateManyWithoutOrderNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  invoice?: InputMaybe<InvoiceUpdateManyWithoutOrderNestedInput>;
  invoiceParam?: InputMaybe<Scalars['JSON']>;
  isInvoiced?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isPaid?: InputMaybe<BoolFieldUpdateOperationsInput>;
  payment?: InputMaybe<PaymentUpdateManyWithoutOrderNestedInput>;
  postPackage?: InputMaybe<PostPackageUpdateOneRequiredWithoutOrderNestedInput>;
  price?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  readableId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  refundPayment?: InputMaybe<PaymentUpdateOneWithoutRefundOrdersNestedInput>;
  status?: InputMaybe<EnumOrderStatusFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type OrderUpsertWithWhereUniqueWithoutRefundPaymentInput = {
  create: OrderCreateWithoutRefundPaymentInput;
  update: OrderUpdateWithoutRefundPaymentInput;
  where: OrderWhereUniqueInput;
};

export type OrderUpsertWithWhereUniqueWithoutSenderInput = {
  create: OrderCreateWithoutSenderInput;
  update: OrderUpdateWithoutSenderInput;
  where: OrderWhereUniqueInput;
};

export type OrderUpsertWithoutBenefitMemosInput = {
  create: OrderCreateWithoutBenefitMemosInput;
  update: OrderUpdateWithoutBenefitMemosInput;
};

export type OrderUpsertWithoutCoinInput = {
  create: OrderCreateWithoutCoinInput;
  update: OrderUpdateWithoutCoinInput;
};

export type OrderUpsertWithoutCouponEntitiesInput = {
  create: OrderCreateWithoutCouponEntitiesInput;
  update: OrderUpdateWithoutCouponEntitiesInput;
};

export type OrderUpsertWithoutDetailsInput = {
  create: OrderCreateWithoutDetailsInput;
  update: OrderUpdateWithoutDetailsInput;
};

export type OrderUpsertWithoutInvoiceInput = {
  create: OrderCreateWithoutInvoiceInput;
  update: OrderUpdateWithoutInvoiceInput;
};

export type OrderUpsertWithoutPaymentInput = {
  create: OrderCreateWithoutPaymentInput;
  update: OrderUpdateWithoutPaymentInput;
};

export type OrderUpsertWithoutPostPackageInput = {
  create: OrderCreateWithoutPostPackageInput;
  update: OrderUpdateWithoutPostPackageInput;
};

export type OrderWhereInput = {
  AND?: InputMaybe<Array<OrderWhereInput>>;
  NOT?: InputMaybe<Array<OrderWhereInput>>;
  OR?: InputMaybe<Array<OrderWhereInput>>;
  benefitMemos?: InputMaybe<BenefitMemoToOrderListRelationFilter>;
  coin?: InputMaybe<CoinRelationFilter>;
  couponEntities?: InputMaybe<CouponEntityListRelationFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  details?: InputMaybe<OrderDetailListRelationFilter>;
  id?: InputMaybe<StringFilter>;
  invoice?: InputMaybe<InvoiceListRelationFilter>;
  invoiceParam?: InputMaybe<JsonNullableFilter>;
  isInvoiced?: InputMaybe<BoolFilter>;
  isPaid?: InputMaybe<BoolFilter>;
  payment?: InputMaybe<PaymentListRelationFilter>;
  postPackage?: InputMaybe<PostPackageRelationFilter>;
  postPackageId?: InputMaybe<StringFilter>;
  price?: InputMaybe<DecimalFilter>;
  readableId?: InputMaybe<StringNullableFilter>;
  refundPayment?: InputMaybe<PaymentRelationFilter>;
  refundPaymentId?: InputMaybe<StringNullableFilter>;
  sender?: InputMaybe<UserRelationFilter>;
  senderId?: InputMaybe<StringFilter>;
  status?: InputMaybe<EnumOrderStatusFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type OrderWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
  postPackageId?: InputMaybe<Scalars['String']>;
  readableId?: InputMaybe<Scalars['String']>;
};

export enum PackageStatus {
  Arrived = 'arrived',
  Cancel = 'cancel',
  Error = 'error',
  Received = 'received',
  Sent = 'sent',
  WaitToSend = 'waitToSend'
}

export enum PackageType {
  Email = 'EMAIL',
  Group = 'GROUP',
  ManualAddr = 'MANUAL_ADDR',
  User = 'USER'
}

export type Payment = {
  __typename?: 'Payment';
  _count?: Maybe<PaymentCount>;
  consumerUserId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  order: Order;
  orderId: Scalars['String'];
  refundOrders: Array<Order>;
  tappay?: Maybe<Tappay>;
  tappayId?: Maybe<Scalars['String']>;
  type: PaymentServiceProvider;
};


export type PaymentRefundOrdersArgs = {
  cursor?: InputMaybe<OrderWhereUniqueInput>;
  distinct?: InputMaybe<Array<OrderScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<OrderOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<OrderWhereInput>;
};

export type PaymentCount = {
  __typename?: 'PaymentCount';
  refundOrders: Scalars['Int'];
};


export type PaymentCountRefundOrdersArgs = {
  where?: InputMaybe<OrderWhereInput>;
};

export type PaymentCountAggregate = {
  __typename?: 'PaymentCountAggregate';
  _all: Scalars['Int'];
  consumerUserId: Scalars['Int'];
  createdAt: Scalars['Int'];
  id: Scalars['Int'];
  orderId: Scalars['Int'];
  tappayId: Scalars['Int'];
  type: Scalars['Int'];
};

export type PaymentCountOrderByAggregateInput = {
  consumerUserId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  orderId?: InputMaybe<SortOrder>;
  tappayId?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
};

export type PaymentCreateInput = {
  consumerUserId: Scalars['String'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  order: OrderCreateNestedOneWithoutPaymentInput;
  refundOrders?: InputMaybe<OrderCreateNestedManyWithoutRefundPaymentInput>;
  tappay?: InputMaybe<TappayCreateNestedOneWithoutPaymentInput>;
  type?: InputMaybe<PaymentServiceProvider>;
};

export type PaymentCreateManyInput = {
  consumerUserId: Scalars['String'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  orderId: Scalars['String'];
  tappayId?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<PaymentServiceProvider>;
};

export type PaymentCreateManyOrderInput = {
  consumerUserId: Scalars['String'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  tappayId?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<PaymentServiceProvider>;
};

export type PaymentCreateManyOrderInputEnvelope = {
  data: Array<PaymentCreateManyOrderInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type PaymentCreateManyTappayInput = {
  consumerUserId: Scalars['String'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  orderId: Scalars['String'];
  type?: InputMaybe<PaymentServiceProvider>;
};

export type PaymentCreateManyTappayInputEnvelope = {
  data: Array<PaymentCreateManyTappayInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type PaymentCreateNestedManyWithoutOrderInput = {
  connect?: InputMaybe<Array<PaymentWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<PaymentCreateOrConnectWithoutOrderInput>>;
  create?: InputMaybe<Array<PaymentCreateWithoutOrderInput>>;
  createMany?: InputMaybe<PaymentCreateManyOrderInputEnvelope>;
};

export type PaymentCreateNestedManyWithoutTappayInput = {
  connect?: InputMaybe<Array<PaymentWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<PaymentCreateOrConnectWithoutTappayInput>>;
  create?: InputMaybe<Array<PaymentCreateWithoutTappayInput>>;
  createMany?: InputMaybe<PaymentCreateManyTappayInputEnvelope>;
};

export type PaymentCreateNestedOneWithoutRefundOrdersInput = {
  connect?: InputMaybe<PaymentWhereUniqueInput>;
  connectOrCreate?: InputMaybe<PaymentCreateOrConnectWithoutRefundOrdersInput>;
  create?: InputMaybe<PaymentCreateWithoutRefundOrdersInput>;
};

export type PaymentCreateOrConnectWithoutOrderInput = {
  create: PaymentCreateWithoutOrderInput;
  where: PaymentWhereUniqueInput;
};

export type PaymentCreateOrConnectWithoutRefundOrdersInput = {
  create: PaymentCreateWithoutRefundOrdersInput;
  where: PaymentWhereUniqueInput;
};

export type PaymentCreateOrConnectWithoutTappayInput = {
  create: PaymentCreateWithoutTappayInput;
  where: PaymentWhereUniqueInput;
};

export type PaymentCreateWithoutOrderInput = {
  consumerUserId: Scalars['String'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  refundOrders?: InputMaybe<OrderCreateNestedManyWithoutRefundPaymentInput>;
  tappay?: InputMaybe<TappayCreateNestedOneWithoutPaymentInput>;
  type?: InputMaybe<PaymentServiceProvider>;
};

export type PaymentCreateWithoutRefundOrdersInput = {
  consumerUserId: Scalars['String'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  order: OrderCreateNestedOneWithoutPaymentInput;
  tappay?: InputMaybe<TappayCreateNestedOneWithoutPaymentInput>;
  type?: InputMaybe<PaymentServiceProvider>;
};

export type PaymentCreateWithoutTappayInput = {
  consumerUserId: Scalars['String'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  order: OrderCreateNestedOneWithoutPaymentInput;
  refundOrders?: InputMaybe<OrderCreateNestedManyWithoutRefundPaymentInput>;
  type?: InputMaybe<PaymentServiceProvider>;
};

export type PaymentGroupBy = {
  __typename?: 'PaymentGroupBy';
  _count?: Maybe<PaymentCountAggregate>;
  _max?: Maybe<PaymentMaxAggregate>;
  _min?: Maybe<PaymentMinAggregate>;
  consumerUserId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  orderId: Scalars['String'];
  tappayId?: Maybe<Scalars['String']>;
  type: PaymentServiceProvider;
};

export type PaymentListRelationFilter = {
  every?: InputMaybe<PaymentWhereInput>;
  none?: InputMaybe<PaymentWhereInput>;
  some?: InputMaybe<PaymentWhereInput>;
};

export type PaymentMaxAggregate = {
  __typename?: 'PaymentMaxAggregate';
  consumerUserId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  orderId?: Maybe<Scalars['String']>;
  tappayId?: Maybe<Scalars['String']>;
  type?: Maybe<PaymentServiceProvider>;
};

export type PaymentMaxOrderByAggregateInput = {
  consumerUserId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  orderId?: InputMaybe<SortOrder>;
  tappayId?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
};

export enum PaymentMethod {
  Creditcard = 'CREDITCARD',
  Iab = 'IAB',
  Iap = 'IAP'
}

export type PaymentMinAggregate = {
  __typename?: 'PaymentMinAggregate';
  consumerUserId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  orderId?: Maybe<Scalars['String']>;
  tappayId?: Maybe<Scalars['String']>;
  type?: Maybe<PaymentServiceProvider>;
};

export type PaymentMinOrderByAggregateInput = {
  consumerUserId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  orderId?: InputMaybe<SortOrder>;
  tappayId?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
};

export type PaymentOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type PaymentOrderByWithAggregationInput = {
  _count?: InputMaybe<PaymentCountOrderByAggregateInput>;
  _max?: InputMaybe<PaymentMaxOrderByAggregateInput>;
  _min?: InputMaybe<PaymentMinOrderByAggregateInput>;
  consumerUserId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  orderId?: InputMaybe<SortOrder>;
  tappayId?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
};

export type PaymentOrderByWithRelationInput = {
  consumerUserId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  order?: InputMaybe<OrderOrderByWithRelationInput>;
  orderId?: InputMaybe<SortOrder>;
  refundOrders?: InputMaybe<OrderOrderByRelationAggregateInput>;
  tappay?: InputMaybe<TappayOrderByWithRelationInput>;
  tappayId?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
};

export type PaymentRelationFilter = {
  is?: InputMaybe<PaymentWhereInput>;
  isNot?: InputMaybe<PaymentWhereInput>;
};

export enum PaymentScalarFieldEnum {
  ConsumerUserId = 'consumerUserId',
  CreatedAt = 'createdAt',
  Id = 'id',
  OrderId = 'orderId',
  TappayId = 'tappayId',
  Type = 'type'
}

export type PaymentScalarWhereInput = {
  AND?: InputMaybe<Array<PaymentScalarWhereInput>>;
  NOT?: InputMaybe<Array<PaymentScalarWhereInput>>;
  OR?: InputMaybe<Array<PaymentScalarWhereInput>>;
  consumerUserId?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  orderId?: InputMaybe<StringFilter>;
  tappayId?: InputMaybe<StringNullableFilter>;
  type?: InputMaybe<EnumPaymentServiceProviderFilter>;
};

export type PaymentScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<PaymentScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<PaymentScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<PaymentScalarWhereWithAggregatesInput>>;
  consumerUserId?: InputMaybe<StringWithAggregatesFilter>;
  createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  id?: InputMaybe<StringWithAggregatesFilter>;
  orderId?: InputMaybe<StringWithAggregatesFilter>;
  tappayId?: InputMaybe<StringNullableWithAggregatesFilter>;
  type?: InputMaybe<EnumPaymentServiceProviderWithAggregatesFilter>;
};

export enum PaymentServiceProvider {
  Tappay = 'tappay'
}

export type PaymentUpdateInput = {
  consumerUserId?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  order?: InputMaybe<OrderUpdateOneRequiredWithoutPaymentNestedInput>;
  refundOrders?: InputMaybe<OrderUpdateManyWithoutRefundPaymentNestedInput>;
  tappay?: InputMaybe<TappayUpdateOneWithoutPaymentNestedInput>;
  type?: InputMaybe<EnumPaymentServiceProviderFieldUpdateOperationsInput>;
};

export type PaymentUpdateManyMutationInput = {
  consumerUserId?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  type?: InputMaybe<EnumPaymentServiceProviderFieldUpdateOperationsInput>;
};

export type PaymentUpdateManyWithWhereWithoutOrderInput = {
  data: PaymentUpdateManyMutationInput;
  where: PaymentScalarWhereInput;
};

export type PaymentUpdateManyWithWhereWithoutTappayInput = {
  data: PaymentUpdateManyMutationInput;
  where: PaymentScalarWhereInput;
};

export type PaymentUpdateManyWithoutOrderNestedInput = {
  connect?: InputMaybe<Array<PaymentWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<PaymentCreateOrConnectWithoutOrderInput>>;
  create?: InputMaybe<Array<PaymentCreateWithoutOrderInput>>;
  createMany?: InputMaybe<PaymentCreateManyOrderInputEnvelope>;
  delete?: InputMaybe<Array<PaymentWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<PaymentScalarWhereInput>>;
  disconnect?: InputMaybe<Array<PaymentWhereUniqueInput>>;
  set?: InputMaybe<Array<PaymentWhereUniqueInput>>;
  update?: InputMaybe<Array<PaymentUpdateWithWhereUniqueWithoutOrderInput>>;
  updateMany?: InputMaybe<Array<PaymentUpdateManyWithWhereWithoutOrderInput>>;
  upsert?: InputMaybe<Array<PaymentUpsertWithWhereUniqueWithoutOrderInput>>;
};

export type PaymentUpdateManyWithoutTappayNestedInput = {
  connect?: InputMaybe<Array<PaymentWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<PaymentCreateOrConnectWithoutTappayInput>>;
  create?: InputMaybe<Array<PaymentCreateWithoutTappayInput>>;
  createMany?: InputMaybe<PaymentCreateManyTappayInputEnvelope>;
  delete?: InputMaybe<Array<PaymentWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<PaymentScalarWhereInput>>;
  disconnect?: InputMaybe<Array<PaymentWhereUniqueInput>>;
  set?: InputMaybe<Array<PaymentWhereUniqueInput>>;
  update?: InputMaybe<Array<PaymentUpdateWithWhereUniqueWithoutTappayInput>>;
  updateMany?: InputMaybe<Array<PaymentUpdateManyWithWhereWithoutTappayInput>>;
  upsert?: InputMaybe<Array<PaymentUpsertWithWhereUniqueWithoutTappayInput>>;
};

export type PaymentUpdateOneWithoutRefundOrdersNestedInput = {
  connect?: InputMaybe<PaymentWhereUniqueInput>;
  connectOrCreate?: InputMaybe<PaymentCreateOrConnectWithoutRefundOrdersInput>;
  create?: InputMaybe<PaymentCreateWithoutRefundOrdersInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<PaymentUpdateWithoutRefundOrdersInput>;
  upsert?: InputMaybe<PaymentUpsertWithoutRefundOrdersInput>;
};

export type PaymentUpdateWithWhereUniqueWithoutOrderInput = {
  data: PaymentUpdateWithoutOrderInput;
  where: PaymentWhereUniqueInput;
};

export type PaymentUpdateWithWhereUniqueWithoutTappayInput = {
  data: PaymentUpdateWithoutTappayInput;
  where: PaymentWhereUniqueInput;
};

export type PaymentUpdateWithoutOrderInput = {
  consumerUserId?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  refundOrders?: InputMaybe<OrderUpdateManyWithoutRefundPaymentNestedInput>;
  tappay?: InputMaybe<TappayUpdateOneWithoutPaymentNestedInput>;
  type?: InputMaybe<EnumPaymentServiceProviderFieldUpdateOperationsInput>;
};

export type PaymentUpdateWithoutRefundOrdersInput = {
  consumerUserId?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  order?: InputMaybe<OrderUpdateOneRequiredWithoutPaymentNestedInput>;
  tappay?: InputMaybe<TappayUpdateOneWithoutPaymentNestedInput>;
  type?: InputMaybe<EnumPaymentServiceProviderFieldUpdateOperationsInput>;
};

export type PaymentUpdateWithoutTappayInput = {
  consumerUserId?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  order?: InputMaybe<OrderUpdateOneRequiredWithoutPaymentNestedInput>;
  refundOrders?: InputMaybe<OrderUpdateManyWithoutRefundPaymentNestedInput>;
  type?: InputMaybe<EnumPaymentServiceProviderFieldUpdateOperationsInput>;
};

export type PaymentUpsertWithWhereUniqueWithoutOrderInput = {
  create: PaymentCreateWithoutOrderInput;
  update: PaymentUpdateWithoutOrderInput;
  where: PaymentWhereUniqueInput;
};

export type PaymentUpsertWithWhereUniqueWithoutTappayInput = {
  create: PaymentCreateWithoutTappayInput;
  update: PaymentUpdateWithoutTappayInput;
  where: PaymentWhereUniqueInput;
};

export type PaymentUpsertWithoutRefundOrdersInput = {
  create: PaymentCreateWithoutRefundOrdersInput;
  update: PaymentUpdateWithoutRefundOrdersInput;
};

export type PaymentWhereInput = {
  AND?: InputMaybe<Array<PaymentWhereInput>>;
  NOT?: InputMaybe<Array<PaymentWhereInput>>;
  OR?: InputMaybe<Array<PaymentWhereInput>>;
  consumerUserId?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  order?: InputMaybe<OrderRelationFilter>;
  orderId?: InputMaybe<StringFilter>;
  refundOrders?: InputMaybe<OrderListRelationFilter>;
  tappay?: InputMaybe<TappayRelationFilter>;
  tappayId?: InputMaybe<StringNullableFilter>;
  type?: InputMaybe<EnumPaymentServiceProviderFilter>;
};

export type PaymentWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
};

export type Post = {
  __typename?: 'Post';
  _count?: Maybe<PostCount>;
  audioUri?: Maybe<Scalars['String']>;
  benefitMemoToOrder: Array<BenefitMemoToOrder>;
  body: Card;
  bodyId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  detail?: Maybe<OrderDetail>;
  histories: Array<PostHistory>;
  id: Scalars['String'];
  inValidReasonHistories: Array<InValidReason>;
  isCheckin: Scalars['Boolean'];
  likeUsers: Array<User>;
  loc?: Maybe<Scalars['JSON']>;
  message?: Maybe<Scalars['String']>;
  package?: Maybe<PostPackage>;
  packageId?: Maybe<Scalars['String']>;
  paymentProduct?: Maybe<Product>;
  paymentProductId?: Maybe<Scalars['String']>;
  qrcode?: Maybe<Scalars['String']>;
  rearUri: Scalars['String'];
  rearUri4Practical?: Maybe<Scalars['String']>;
  receiver?: Maybe<User>;
  receiverId?: Maybe<Scalars['String']>;
  recvPrivacy?: Maybe<PrivacyLevel>;
  reports: Array<Report>;
  scenicSpot?: Maybe<ScenicSpot>;
  scenicSpotId?: Maybe<Scalars['String']>;
  sendDate: Scalars['DateTime'];
  sendPrivacy: PrivacyLevel;
  sendStatus: PackageStatus;
  sender: User;
  senderId: Scalars['String'];
  signature?: Maybe<Signature>;
  signatureId?: Maybe<Scalars['String']>;
  stamp: Stamp;
  stampId: Scalars['String'];
  tags: Array<PostTag>;
  updatedAt: Scalars['DateTime'];
  valid: Scalars['Boolean'];
};


export type PostBenefitMemoToOrderArgs = {
  cursor?: InputMaybe<BenefitMemoToOrderWhereUniqueInput>;
  distinct?: InputMaybe<Array<BenefitMemoToOrderScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<BenefitMemoToOrderOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<BenefitMemoToOrderWhereInput>;
};


export type PostHistoriesArgs = {
  cursor?: InputMaybe<PostHistoryWhereUniqueInput>;
  distinct?: InputMaybe<Array<PostHistoryScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<PostHistoryOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PostHistoryWhereInput>;
};


export type PostInValidReasonHistoriesArgs = {
  cursor?: InputMaybe<InValidReasonWhereUniqueInput>;
  distinct?: InputMaybe<Array<InValidReasonScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<InValidReasonOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<InValidReasonWhereInput>;
};


export type PostLikeUsersArgs = {
  cursor?: InputMaybe<UserWhereUniqueInput>;
  distinct?: InputMaybe<Array<UserScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<UserOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<UserWhereInput>;
};


export type PostReportsArgs = {
  cursor?: InputMaybe<ReportWhereUniqueInput>;
  distinct?: InputMaybe<Array<ReportScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ReportOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ReportWhereInput>;
};


export type PostTagsArgs = {
  cursor?: InputMaybe<PostTagWhereUniqueInput>;
  distinct?: InputMaybe<Array<PostTagScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<PostTagOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PostTagWhereInput>;
};

export type PostCount = {
  __typename?: 'PostCount';
  benefitMemoToOrder: Scalars['Int'];
  histories: Scalars['Int'];
  inValidReasonHistories: Scalars['Int'];
  likeUsers: Scalars['Int'];
  reports: Scalars['Int'];
  tags: Scalars['Int'];
};


export type PostCountBenefitMemoToOrderArgs = {
  where?: InputMaybe<BenefitMemoToOrderWhereInput>;
};


export type PostCountHistoriesArgs = {
  where?: InputMaybe<PostHistoryWhereInput>;
};


export type PostCountInValidReasonHistoriesArgs = {
  where?: InputMaybe<InValidReasonWhereInput>;
};


export type PostCountLikeUsersArgs = {
  where?: InputMaybe<UserWhereInput>;
};


export type PostCountReportsArgs = {
  where?: InputMaybe<ReportWhereInput>;
};


export type PostCountTagsArgs = {
  where?: InputMaybe<PostTagWhereInput>;
};

export type PostCountAggregate = {
  __typename?: 'PostCountAggregate';
  _all: Scalars['Int'];
  audioUri: Scalars['Int'];
  bodyId: Scalars['Int'];
  createdAt: Scalars['Int'];
  id: Scalars['Int'];
  isCheckin: Scalars['Int'];
  loc: Scalars['Int'];
  message: Scalars['Int'];
  packageId: Scalars['Int'];
  paymentProductId: Scalars['Int'];
  qrcode: Scalars['Int'];
  rearUri: Scalars['Int'];
  rearUri4Practical: Scalars['Int'];
  receiverId: Scalars['Int'];
  recvPrivacy: Scalars['Int'];
  scenicSpotId: Scalars['Int'];
  sendDate: Scalars['Int'];
  sendPrivacy: Scalars['Int'];
  sendStatus: Scalars['Int'];
  senderId: Scalars['Int'];
  signatureId: Scalars['Int'];
  stampId: Scalars['Int'];
  updatedAt: Scalars['Int'];
  valid: Scalars['Int'];
};

export type PostCountOrderByAggregateInput = {
  audioUri?: InputMaybe<SortOrder>;
  bodyId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  isCheckin?: InputMaybe<SortOrder>;
  loc?: InputMaybe<SortOrder>;
  message?: InputMaybe<SortOrder>;
  packageId?: InputMaybe<SortOrder>;
  paymentProductId?: InputMaybe<SortOrder>;
  qrcode?: InputMaybe<SortOrder>;
  rearUri?: InputMaybe<SortOrder>;
  rearUri4Practical?: InputMaybe<SortOrder>;
  receiverId?: InputMaybe<SortOrder>;
  recvPrivacy?: InputMaybe<SortOrder>;
  scenicSpotId?: InputMaybe<SortOrder>;
  sendDate?: InputMaybe<SortOrder>;
  sendPrivacy?: InputMaybe<SortOrder>;
  sendStatus?: InputMaybe<SortOrder>;
  senderId?: InputMaybe<SortOrder>;
  signatureId?: InputMaybe<SortOrder>;
  stampId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  valid?: InputMaybe<SortOrder>;
};

export type PostCreateInput = {
  audioUri?: InputMaybe<Scalars['String']>;
  benefitMemoToOrder?: InputMaybe<BenefitMemoToOrderCreateNestedManyWithoutPostInput>;
  body: CardCreateNestedOneWithoutPostsUsedInput;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  detail?: InputMaybe<OrderDetailCreateNestedOneWithoutPostInput>;
  histories?: InputMaybe<PostHistoryCreateNestedManyWithoutPostInput>;
  id?: InputMaybe<Scalars['String']>;
  inValidReasonHistories?: InputMaybe<InValidReasonCreateNestedManyWithoutPostInput>;
  isCheckin?: InputMaybe<Scalars['Boolean']>;
  likeUsers?: InputMaybe<UserCreateNestedManyWithoutLikePostsInput>;
  loc?: InputMaybe<Scalars['JSON']>;
  message?: InputMaybe<Scalars['String']>;
  package?: InputMaybe<PostPackageCreateNestedOneWithoutPostsInput>;
  paymentProduct?: InputMaybe<ProductCreateNestedOneWithoutPostsInput>;
  qrcode?: InputMaybe<Scalars['String']>;
  rearUri: Scalars['String'];
  rearUri4Practical?: InputMaybe<Scalars['String']>;
  receiver?: InputMaybe<UserCreateNestedOneWithoutPostsInInput>;
  recvPrivacy?: InputMaybe<PrivacyLevel>;
  reports?: InputMaybe<ReportCreateNestedManyWithoutPostInput>;
  scenicSpot?: InputMaybe<ScenicSpotCreateNestedOneWithoutPostsInput>;
  sendDate: Scalars['DateTime'];
  sendPrivacy: PrivacyLevel;
  sendStatus: PackageStatus;
  sender: UserCreateNestedOneWithoutPostsOutInput;
  signature?: InputMaybe<SignatureCreateNestedOneWithoutPostsUsedInput>;
  stamp: StampCreateNestedOneWithoutPostsUsedInput;
  tags?: InputMaybe<PostTagCreateNestedManyWithoutPostInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  valid?: InputMaybe<Scalars['Boolean']>;
};

export type PostCreateManyBodyInput = {
  audioUri?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  isCheckin?: InputMaybe<Scalars['Boolean']>;
  loc?: InputMaybe<Scalars['JSON']>;
  message?: InputMaybe<Scalars['String']>;
  packageId?: InputMaybe<Scalars['String']>;
  paymentProductId?: InputMaybe<Scalars['String']>;
  qrcode?: InputMaybe<Scalars['String']>;
  rearUri: Scalars['String'];
  rearUri4Practical?: InputMaybe<Scalars['String']>;
  receiverId?: InputMaybe<Scalars['String']>;
  recvPrivacy?: InputMaybe<PrivacyLevel>;
  scenicSpotId?: InputMaybe<Scalars['String']>;
  sendDate: Scalars['DateTime'];
  sendPrivacy: PrivacyLevel;
  sendStatus: PackageStatus;
  senderId: Scalars['String'];
  signatureId?: InputMaybe<Scalars['String']>;
  stampId: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  valid?: InputMaybe<Scalars['Boolean']>;
};

export type PostCreateManyBodyInputEnvelope = {
  data: Array<PostCreateManyBodyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type PostCreateManyInput = {
  audioUri?: InputMaybe<Scalars['String']>;
  bodyId: Scalars['String'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  isCheckin?: InputMaybe<Scalars['Boolean']>;
  loc?: InputMaybe<Scalars['JSON']>;
  message?: InputMaybe<Scalars['String']>;
  packageId?: InputMaybe<Scalars['String']>;
  paymentProductId?: InputMaybe<Scalars['String']>;
  qrcode?: InputMaybe<Scalars['String']>;
  rearUri: Scalars['String'];
  rearUri4Practical?: InputMaybe<Scalars['String']>;
  receiverId?: InputMaybe<Scalars['String']>;
  recvPrivacy?: InputMaybe<PrivacyLevel>;
  scenicSpotId?: InputMaybe<Scalars['String']>;
  sendDate: Scalars['DateTime'];
  sendPrivacy: PrivacyLevel;
  sendStatus: PackageStatus;
  senderId: Scalars['String'];
  signatureId?: InputMaybe<Scalars['String']>;
  stampId: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  valid?: InputMaybe<Scalars['Boolean']>;
};

export type PostCreateManyPackageInput = {
  audioUri?: InputMaybe<Scalars['String']>;
  bodyId: Scalars['String'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  isCheckin?: InputMaybe<Scalars['Boolean']>;
  loc?: InputMaybe<Scalars['JSON']>;
  message?: InputMaybe<Scalars['String']>;
  paymentProductId?: InputMaybe<Scalars['String']>;
  qrcode?: InputMaybe<Scalars['String']>;
  rearUri: Scalars['String'];
  rearUri4Practical?: InputMaybe<Scalars['String']>;
  receiverId?: InputMaybe<Scalars['String']>;
  recvPrivacy?: InputMaybe<PrivacyLevel>;
  scenicSpotId?: InputMaybe<Scalars['String']>;
  sendDate: Scalars['DateTime'];
  sendPrivacy: PrivacyLevel;
  sendStatus: PackageStatus;
  senderId: Scalars['String'];
  signatureId?: InputMaybe<Scalars['String']>;
  stampId: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  valid?: InputMaybe<Scalars['Boolean']>;
};

export type PostCreateManyPackageInputEnvelope = {
  data: Array<PostCreateManyPackageInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type PostCreateManyPaymentProductInput = {
  audioUri?: InputMaybe<Scalars['String']>;
  bodyId: Scalars['String'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  isCheckin?: InputMaybe<Scalars['Boolean']>;
  loc?: InputMaybe<Scalars['JSON']>;
  message?: InputMaybe<Scalars['String']>;
  packageId?: InputMaybe<Scalars['String']>;
  qrcode?: InputMaybe<Scalars['String']>;
  rearUri: Scalars['String'];
  rearUri4Practical?: InputMaybe<Scalars['String']>;
  receiverId?: InputMaybe<Scalars['String']>;
  recvPrivacy?: InputMaybe<PrivacyLevel>;
  scenicSpotId?: InputMaybe<Scalars['String']>;
  sendDate: Scalars['DateTime'];
  sendPrivacy: PrivacyLevel;
  sendStatus: PackageStatus;
  senderId: Scalars['String'];
  signatureId?: InputMaybe<Scalars['String']>;
  stampId: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  valid?: InputMaybe<Scalars['Boolean']>;
};

export type PostCreateManyPaymentProductInputEnvelope = {
  data: Array<PostCreateManyPaymentProductInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type PostCreateManyReceiverInput = {
  audioUri?: InputMaybe<Scalars['String']>;
  bodyId: Scalars['String'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  isCheckin?: InputMaybe<Scalars['Boolean']>;
  loc?: InputMaybe<Scalars['JSON']>;
  message?: InputMaybe<Scalars['String']>;
  packageId?: InputMaybe<Scalars['String']>;
  paymentProductId?: InputMaybe<Scalars['String']>;
  qrcode?: InputMaybe<Scalars['String']>;
  rearUri: Scalars['String'];
  rearUri4Practical?: InputMaybe<Scalars['String']>;
  recvPrivacy?: InputMaybe<PrivacyLevel>;
  scenicSpotId?: InputMaybe<Scalars['String']>;
  sendDate: Scalars['DateTime'];
  sendPrivacy: PrivacyLevel;
  sendStatus: PackageStatus;
  senderId: Scalars['String'];
  signatureId?: InputMaybe<Scalars['String']>;
  stampId: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  valid?: InputMaybe<Scalars['Boolean']>;
};

export type PostCreateManyReceiverInputEnvelope = {
  data: Array<PostCreateManyReceiverInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type PostCreateManyScenicSpotInput = {
  audioUri?: InputMaybe<Scalars['String']>;
  bodyId: Scalars['String'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  isCheckin?: InputMaybe<Scalars['Boolean']>;
  loc?: InputMaybe<Scalars['JSON']>;
  message?: InputMaybe<Scalars['String']>;
  packageId?: InputMaybe<Scalars['String']>;
  paymentProductId?: InputMaybe<Scalars['String']>;
  qrcode?: InputMaybe<Scalars['String']>;
  rearUri: Scalars['String'];
  rearUri4Practical?: InputMaybe<Scalars['String']>;
  receiverId?: InputMaybe<Scalars['String']>;
  recvPrivacy?: InputMaybe<PrivacyLevel>;
  sendDate: Scalars['DateTime'];
  sendPrivacy: PrivacyLevel;
  sendStatus: PackageStatus;
  senderId: Scalars['String'];
  signatureId?: InputMaybe<Scalars['String']>;
  stampId: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  valid?: InputMaybe<Scalars['Boolean']>;
};

export type PostCreateManyScenicSpotInputEnvelope = {
  data: Array<PostCreateManyScenicSpotInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type PostCreateManySenderInput = {
  audioUri?: InputMaybe<Scalars['String']>;
  bodyId: Scalars['String'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  isCheckin?: InputMaybe<Scalars['Boolean']>;
  loc?: InputMaybe<Scalars['JSON']>;
  message?: InputMaybe<Scalars['String']>;
  packageId?: InputMaybe<Scalars['String']>;
  paymentProductId?: InputMaybe<Scalars['String']>;
  qrcode?: InputMaybe<Scalars['String']>;
  rearUri: Scalars['String'];
  rearUri4Practical?: InputMaybe<Scalars['String']>;
  receiverId?: InputMaybe<Scalars['String']>;
  recvPrivacy?: InputMaybe<PrivacyLevel>;
  scenicSpotId?: InputMaybe<Scalars['String']>;
  sendDate: Scalars['DateTime'];
  sendPrivacy: PrivacyLevel;
  sendStatus: PackageStatus;
  signatureId?: InputMaybe<Scalars['String']>;
  stampId: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  valid?: InputMaybe<Scalars['Boolean']>;
};

export type PostCreateManySenderInputEnvelope = {
  data: Array<PostCreateManySenderInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type PostCreateManySignatureInput = {
  audioUri?: InputMaybe<Scalars['String']>;
  bodyId: Scalars['String'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  isCheckin?: InputMaybe<Scalars['Boolean']>;
  loc?: InputMaybe<Scalars['JSON']>;
  message?: InputMaybe<Scalars['String']>;
  packageId?: InputMaybe<Scalars['String']>;
  paymentProductId?: InputMaybe<Scalars['String']>;
  qrcode?: InputMaybe<Scalars['String']>;
  rearUri: Scalars['String'];
  rearUri4Practical?: InputMaybe<Scalars['String']>;
  receiverId?: InputMaybe<Scalars['String']>;
  recvPrivacy?: InputMaybe<PrivacyLevel>;
  scenicSpotId?: InputMaybe<Scalars['String']>;
  sendDate: Scalars['DateTime'];
  sendPrivacy: PrivacyLevel;
  sendStatus: PackageStatus;
  senderId: Scalars['String'];
  stampId: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  valid?: InputMaybe<Scalars['Boolean']>;
};

export type PostCreateManySignatureInputEnvelope = {
  data: Array<PostCreateManySignatureInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type PostCreateManyStampInput = {
  audioUri?: InputMaybe<Scalars['String']>;
  bodyId: Scalars['String'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  isCheckin?: InputMaybe<Scalars['Boolean']>;
  loc?: InputMaybe<Scalars['JSON']>;
  message?: InputMaybe<Scalars['String']>;
  packageId?: InputMaybe<Scalars['String']>;
  paymentProductId?: InputMaybe<Scalars['String']>;
  qrcode?: InputMaybe<Scalars['String']>;
  rearUri: Scalars['String'];
  rearUri4Practical?: InputMaybe<Scalars['String']>;
  receiverId?: InputMaybe<Scalars['String']>;
  recvPrivacy?: InputMaybe<PrivacyLevel>;
  scenicSpotId?: InputMaybe<Scalars['String']>;
  sendDate: Scalars['DateTime'];
  sendPrivacy: PrivacyLevel;
  sendStatus: PackageStatus;
  senderId: Scalars['String'];
  signatureId?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  valid?: InputMaybe<Scalars['Boolean']>;
};

export type PostCreateManyStampInputEnvelope = {
  data: Array<PostCreateManyStampInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type PostCreateNestedManyWithoutBodyInput = {
  connect?: InputMaybe<Array<PostWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<PostCreateOrConnectWithoutBodyInput>>;
  create?: InputMaybe<Array<PostCreateWithoutBodyInput>>;
  createMany?: InputMaybe<PostCreateManyBodyInputEnvelope>;
};

export type PostCreateNestedManyWithoutLikeUsersInput = {
  connect?: InputMaybe<Array<PostWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<PostCreateOrConnectWithoutLikeUsersInput>>;
  create?: InputMaybe<Array<PostCreateWithoutLikeUsersInput>>;
};

export type PostCreateNestedManyWithoutPackageInput = {
  connect?: InputMaybe<Array<PostWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<PostCreateOrConnectWithoutPackageInput>>;
  create?: InputMaybe<Array<PostCreateWithoutPackageInput>>;
  createMany?: InputMaybe<PostCreateManyPackageInputEnvelope>;
};

export type PostCreateNestedManyWithoutPaymentProductInput = {
  connect?: InputMaybe<Array<PostWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<PostCreateOrConnectWithoutPaymentProductInput>>;
  create?: InputMaybe<Array<PostCreateWithoutPaymentProductInput>>;
  createMany?: InputMaybe<PostCreateManyPaymentProductInputEnvelope>;
};

export type PostCreateNestedManyWithoutReceiverInput = {
  connect?: InputMaybe<Array<PostWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<PostCreateOrConnectWithoutReceiverInput>>;
  create?: InputMaybe<Array<PostCreateWithoutReceiverInput>>;
  createMany?: InputMaybe<PostCreateManyReceiverInputEnvelope>;
};

export type PostCreateNestedManyWithoutScenicSpotInput = {
  connect?: InputMaybe<Array<PostWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<PostCreateOrConnectWithoutScenicSpotInput>>;
  create?: InputMaybe<Array<PostCreateWithoutScenicSpotInput>>;
  createMany?: InputMaybe<PostCreateManyScenicSpotInputEnvelope>;
};

export type PostCreateNestedManyWithoutSenderInput = {
  connect?: InputMaybe<Array<PostWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<PostCreateOrConnectWithoutSenderInput>>;
  create?: InputMaybe<Array<PostCreateWithoutSenderInput>>;
  createMany?: InputMaybe<PostCreateManySenderInputEnvelope>;
};

export type PostCreateNestedManyWithoutSignatureInput = {
  connect?: InputMaybe<Array<PostWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<PostCreateOrConnectWithoutSignatureInput>>;
  create?: InputMaybe<Array<PostCreateWithoutSignatureInput>>;
  createMany?: InputMaybe<PostCreateManySignatureInputEnvelope>;
};

export type PostCreateNestedManyWithoutStampInput = {
  connect?: InputMaybe<Array<PostWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<PostCreateOrConnectWithoutStampInput>>;
  create?: InputMaybe<Array<PostCreateWithoutStampInput>>;
  createMany?: InputMaybe<PostCreateManyStampInputEnvelope>;
};

export type PostCreateNestedOneWithoutBenefitMemoToOrderInput = {
  connect?: InputMaybe<PostWhereUniqueInput>;
  connectOrCreate?: InputMaybe<PostCreateOrConnectWithoutBenefitMemoToOrderInput>;
  create?: InputMaybe<PostCreateWithoutBenefitMemoToOrderInput>;
};

export type PostCreateNestedOneWithoutDetailInput = {
  connect?: InputMaybe<PostWhereUniqueInput>;
  connectOrCreate?: InputMaybe<PostCreateOrConnectWithoutDetailInput>;
  create?: InputMaybe<PostCreateWithoutDetailInput>;
};

export type PostCreateNestedOneWithoutHistoriesInput = {
  connect?: InputMaybe<PostWhereUniqueInput>;
  connectOrCreate?: InputMaybe<PostCreateOrConnectWithoutHistoriesInput>;
  create?: InputMaybe<PostCreateWithoutHistoriesInput>;
};

export type PostCreateNestedOneWithoutInValidReasonHistoriesInput = {
  connect?: InputMaybe<PostWhereUniqueInput>;
  connectOrCreate?: InputMaybe<PostCreateOrConnectWithoutInValidReasonHistoriesInput>;
  create?: InputMaybe<PostCreateWithoutInValidReasonHistoriesInput>;
};

export type PostCreateNestedOneWithoutReportsInput = {
  connect?: InputMaybe<PostWhereUniqueInput>;
  connectOrCreate?: InputMaybe<PostCreateOrConnectWithoutReportsInput>;
  create?: InputMaybe<PostCreateWithoutReportsInput>;
};

export type PostCreateNestedOneWithoutTagsInput = {
  connect?: InputMaybe<PostWhereUniqueInput>;
  connectOrCreate?: InputMaybe<PostCreateOrConnectWithoutTagsInput>;
  create?: InputMaybe<PostCreateWithoutTagsInput>;
};

export type PostCreateOrConnectWithoutBenefitMemoToOrderInput = {
  create: PostCreateWithoutBenefitMemoToOrderInput;
  where: PostWhereUniqueInput;
};

export type PostCreateOrConnectWithoutBodyInput = {
  create: PostCreateWithoutBodyInput;
  where: PostWhereUniqueInput;
};

export type PostCreateOrConnectWithoutDetailInput = {
  create: PostCreateWithoutDetailInput;
  where: PostWhereUniqueInput;
};

export type PostCreateOrConnectWithoutHistoriesInput = {
  create: PostCreateWithoutHistoriesInput;
  where: PostWhereUniqueInput;
};

export type PostCreateOrConnectWithoutInValidReasonHistoriesInput = {
  create: PostCreateWithoutInValidReasonHistoriesInput;
  where: PostWhereUniqueInput;
};

export type PostCreateOrConnectWithoutLikeUsersInput = {
  create: PostCreateWithoutLikeUsersInput;
  where: PostWhereUniqueInput;
};

export type PostCreateOrConnectWithoutPackageInput = {
  create: PostCreateWithoutPackageInput;
  where: PostWhereUniqueInput;
};

export type PostCreateOrConnectWithoutPaymentProductInput = {
  create: PostCreateWithoutPaymentProductInput;
  where: PostWhereUniqueInput;
};

export type PostCreateOrConnectWithoutReceiverInput = {
  create: PostCreateWithoutReceiverInput;
  where: PostWhereUniqueInput;
};

export type PostCreateOrConnectWithoutReportsInput = {
  create: PostCreateWithoutReportsInput;
  where: PostWhereUniqueInput;
};

export type PostCreateOrConnectWithoutScenicSpotInput = {
  create: PostCreateWithoutScenicSpotInput;
  where: PostWhereUniqueInput;
};

export type PostCreateOrConnectWithoutSenderInput = {
  create: PostCreateWithoutSenderInput;
  where: PostWhereUniqueInput;
};

export type PostCreateOrConnectWithoutSignatureInput = {
  create: PostCreateWithoutSignatureInput;
  where: PostWhereUniqueInput;
};

export type PostCreateOrConnectWithoutStampInput = {
  create: PostCreateWithoutStampInput;
  where: PostWhereUniqueInput;
};

export type PostCreateOrConnectWithoutTagsInput = {
  create: PostCreateWithoutTagsInput;
  where: PostWhereUniqueInput;
};

export type PostCreateWithoutBenefitMemoToOrderInput = {
  audioUri?: InputMaybe<Scalars['String']>;
  body: CardCreateNestedOneWithoutPostsUsedInput;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  detail?: InputMaybe<OrderDetailCreateNestedOneWithoutPostInput>;
  histories?: InputMaybe<PostHistoryCreateNestedManyWithoutPostInput>;
  id?: InputMaybe<Scalars['String']>;
  inValidReasonHistories?: InputMaybe<InValidReasonCreateNestedManyWithoutPostInput>;
  isCheckin?: InputMaybe<Scalars['Boolean']>;
  likeUsers?: InputMaybe<UserCreateNestedManyWithoutLikePostsInput>;
  loc?: InputMaybe<Scalars['JSON']>;
  message?: InputMaybe<Scalars['String']>;
  package?: InputMaybe<PostPackageCreateNestedOneWithoutPostsInput>;
  paymentProduct?: InputMaybe<ProductCreateNestedOneWithoutPostsInput>;
  qrcode?: InputMaybe<Scalars['String']>;
  rearUri: Scalars['String'];
  rearUri4Practical?: InputMaybe<Scalars['String']>;
  receiver?: InputMaybe<UserCreateNestedOneWithoutPostsInInput>;
  recvPrivacy?: InputMaybe<PrivacyLevel>;
  reports?: InputMaybe<ReportCreateNestedManyWithoutPostInput>;
  scenicSpot?: InputMaybe<ScenicSpotCreateNestedOneWithoutPostsInput>;
  sendDate: Scalars['DateTime'];
  sendPrivacy: PrivacyLevel;
  sendStatus: PackageStatus;
  sender: UserCreateNestedOneWithoutPostsOutInput;
  signature?: InputMaybe<SignatureCreateNestedOneWithoutPostsUsedInput>;
  stamp: StampCreateNestedOneWithoutPostsUsedInput;
  tags?: InputMaybe<PostTagCreateNestedManyWithoutPostInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  valid?: InputMaybe<Scalars['Boolean']>;
};

export type PostCreateWithoutBodyInput = {
  audioUri?: InputMaybe<Scalars['String']>;
  benefitMemoToOrder?: InputMaybe<BenefitMemoToOrderCreateNestedManyWithoutPostInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  detail?: InputMaybe<OrderDetailCreateNestedOneWithoutPostInput>;
  histories?: InputMaybe<PostHistoryCreateNestedManyWithoutPostInput>;
  id?: InputMaybe<Scalars['String']>;
  inValidReasonHistories?: InputMaybe<InValidReasonCreateNestedManyWithoutPostInput>;
  isCheckin?: InputMaybe<Scalars['Boolean']>;
  likeUsers?: InputMaybe<UserCreateNestedManyWithoutLikePostsInput>;
  loc?: InputMaybe<Scalars['JSON']>;
  message?: InputMaybe<Scalars['String']>;
  package?: InputMaybe<PostPackageCreateNestedOneWithoutPostsInput>;
  paymentProduct?: InputMaybe<ProductCreateNestedOneWithoutPostsInput>;
  qrcode?: InputMaybe<Scalars['String']>;
  rearUri: Scalars['String'];
  rearUri4Practical?: InputMaybe<Scalars['String']>;
  receiver?: InputMaybe<UserCreateNestedOneWithoutPostsInInput>;
  recvPrivacy?: InputMaybe<PrivacyLevel>;
  reports?: InputMaybe<ReportCreateNestedManyWithoutPostInput>;
  scenicSpot?: InputMaybe<ScenicSpotCreateNestedOneWithoutPostsInput>;
  sendDate: Scalars['DateTime'];
  sendPrivacy: PrivacyLevel;
  sendStatus: PackageStatus;
  sender: UserCreateNestedOneWithoutPostsOutInput;
  signature?: InputMaybe<SignatureCreateNestedOneWithoutPostsUsedInput>;
  stamp: StampCreateNestedOneWithoutPostsUsedInput;
  tags?: InputMaybe<PostTagCreateNestedManyWithoutPostInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  valid?: InputMaybe<Scalars['Boolean']>;
};

export type PostCreateWithoutDetailInput = {
  audioUri?: InputMaybe<Scalars['String']>;
  benefitMemoToOrder?: InputMaybe<BenefitMemoToOrderCreateNestedManyWithoutPostInput>;
  body: CardCreateNestedOneWithoutPostsUsedInput;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  histories?: InputMaybe<PostHistoryCreateNestedManyWithoutPostInput>;
  id?: InputMaybe<Scalars['String']>;
  inValidReasonHistories?: InputMaybe<InValidReasonCreateNestedManyWithoutPostInput>;
  isCheckin?: InputMaybe<Scalars['Boolean']>;
  likeUsers?: InputMaybe<UserCreateNestedManyWithoutLikePostsInput>;
  loc?: InputMaybe<Scalars['JSON']>;
  message?: InputMaybe<Scalars['String']>;
  package?: InputMaybe<PostPackageCreateNestedOneWithoutPostsInput>;
  paymentProduct?: InputMaybe<ProductCreateNestedOneWithoutPostsInput>;
  qrcode?: InputMaybe<Scalars['String']>;
  rearUri: Scalars['String'];
  rearUri4Practical?: InputMaybe<Scalars['String']>;
  receiver?: InputMaybe<UserCreateNestedOneWithoutPostsInInput>;
  recvPrivacy?: InputMaybe<PrivacyLevel>;
  reports?: InputMaybe<ReportCreateNestedManyWithoutPostInput>;
  scenicSpot?: InputMaybe<ScenicSpotCreateNestedOneWithoutPostsInput>;
  sendDate: Scalars['DateTime'];
  sendPrivacy: PrivacyLevel;
  sendStatus: PackageStatus;
  sender: UserCreateNestedOneWithoutPostsOutInput;
  signature?: InputMaybe<SignatureCreateNestedOneWithoutPostsUsedInput>;
  stamp: StampCreateNestedOneWithoutPostsUsedInput;
  tags?: InputMaybe<PostTagCreateNestedManyWithoutPostInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  valid?: InputMaybe<Scalars['Boolean']>;
};

export type PostCreateWithoutHistoriesInput = {
  audioUri?: InputMaybe<Scalars['String']>;
  benefitMemoToOrder?: InputMaybe<BenefitMemoToOrderCreateNestedManyWithoutPostInput>;
  body: CardCreateNestedOneWithoutPostsUsedInput;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  detail?: InputMaybe<OrderDetailCreateNestedOneWithoutPostInput>;
  id?: InputMaybe<Scalars['String']>;
  inValidReasonHistories?: InputMaybe<InValidReasonCreateNestedManyWithoutPostInput>;
  isCheckin?: InputMaybe<Scalars['Boolean']>;
  likeUsers?: InputMaybe<UserCreateNestedManyWithoutLikePostsInput>;
  loc?: InputMaybe<Scalars['JSON']>;
  message?: InputMaybe<Scalars['String']>;
  package?: InputMaybe<PostPackageCreateNestedOneWithoutPostsInput>;
  paymentProduct?: InputMaybe<ProductCreateNestedOneWithoutPostsInput>;
  qrcode?: InputMaybe<Scalars['String']>;
  rearUri: Scalars['String'];
  rearUri4Practical?: InputMaybe<Scalars['String']>;
  receiver?: InputMaybe<UserCreateNestedOneWithoutPostsInInput>;
  recvPrivacy?: InputMaybe<PrivacyLevel>;
  reports?: InputMaybe<ReportCreateNestedManyWithoutPostInput>;
  scenicSpot?: InputMaybe<ScenicSpotCreateNestedOneWithoutPostsInput>;
  sendDate: Scalars['DateTime'];
  sendPrivacy: PrivacyLevel;
  sendStatus: PackageStatus;
  sender: UserCreateNestedOneWithoutPostsOutInput;
  signature?: InputMaybe<SignatureCreateNestedOneWithoutPostsUsedInput>;
  stamp: StampCreateNestedOneWithoutPostsUsedInput;
  tags?: InputMaybe<PostTagCreateNestedManyWithoutPostInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  valid?: InputMaybe<Scalars['Boolean']>;
};

export type PostCreateWithoutInValidReasonHistoriesInput = {
  audioUri?: InputMaybe<Scalars['String']>;
  benefitMemoToOrder?: InputMaybe<BenefitMemoToOrderCreateNestedManyWithoutPostInput>;
  body: CardCreateNestedOneWithoutPostsUsedInput;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  detail?: InputMaybe<OrderDetailCreateNestedOneWithoutPostInput>;
  histories?: InputMaybe<PostHistoryCreateNestedManyWithoutPostInput>;
  id?: InputMaybe<Scalars['String']>;
  isCheckin?: InputMaybe<Scalars['Boolean']>;
  likeUsers?: InputMaybe<UserCreateNestedManyWithoutLikePostsInput>;
  loc?: InputMaybe<Scalars['JSON']>;
  message?: InputMaybe<Scalars['String']>;
  package?: InputMaybe<PostPackageCreateNestedOneWithoutPostsInput>;
  paymentProduct?: InputMaybe<ProductCreateNestedOneWithoutPostsInput>;
  qrcode?: InputMaybe<Scalars['String']>;
  rearUri: Scalars['String'];
  rearUri4Practical?: InputMaybe<Scalars['String']>;
  receiver?: InputMaybe<UserCreateNestedOneWithoutPostsInInput>;
  recvPrivacy?: InputMaybe<PrivacyLevel>;
  reports?: InputMaybe<ReportCreateNestedManyWithoutPostInput>;
  scenicSpot?: InputMaybe<ScenicSpotCreateNestedOneWithoutPostsInput>;
  sendDate: Scalars['DateTime'];
  sendPrivacy: PrivacyLevel;
  sendStatus: PackageStatus;
  sender: UserCreateNestedOneWithoutPostsOutInput;
  signature?: InputMaybe<SignatureCreateNestedOneWithoutPostsUsedInput>;
  stamp: StampCreateNestedOneWithoutPostsUsedInput;
  tags?: InputMaybe<PostTagCreateNestedManyWithoutPostInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  valid?: InputMaybe<Scalars['Boolean']>;
};

export type PostCreateWithoutLikeUsersInput = {
  audioUri?: InputMaybe<Scalars['String']>;
  benefitMemoToOrder?: InputMaybe<BenefitMemoToOrderCreateNestedManyWithoutPostInput>;
  body: CardCreateNestedOneWithoutPostsUsedInput;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  detail?: InputMaybe<OrderDetailCreateNestedOneWithoutPostInput>;
  histories?: InputMaybe<PostHistoryCreateNestedManyWithoutPostInput>;
  id?: InputMaybe<Scalars['String']>;
  inValidReasonHistories?: InputMaybe<InValidReasonCreateNestedManyWithoutPostInput>;
  isCheckin?: InputMaybe<Scalars['Boolean']>;
  loc?: InputMaybe<Scalars['JSON']>;
  message?: InputMaybe<Scalars['String']>;
  package?: InputMaybe<PostPackageCreateNestedOneWithoutPostsInput>;
  paymentProduct?: InputMaybe<ProductCreateNestedOneWithoutPostsInput>;
  qrcode?: InputMaybe<Scalars['String']>;
  rearUri: Scalars['String'];
  rearUri4Practical?: InputMaybe<Scalars['String']>;
  receiver?: InputMaybe<UserCreateNestedOneWithoutPostsInInput>;
  recvPrivacy?: InputMaybe<PrivacyLevel>;
  reports?: InputMaybe<ReportCreateNestedManyWithoutPostInput>;
  scenicSpot?: InputMaybe<ScenicSpotCreateNestedOneWithoutPostsInput>;
  sendDate: Scalars['DateTime'];
  sendPrivacy: PrivacyLevel;
  sendStatus: PackageStatus;
  sender: UserCreateNestedOneWithoutPostsOutInput;
  signature?: InputMaybe<SignatureCreateNestedOneWithoutPostsUsedInput>;
  stamp: StampCreateNestedOneWithoutPostsUsedInput;
  tags?: InputMaybe<PostTagCreateNestedManyWithoutPostInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  valid?: InputMaybe<Scalars['Boolean']>;
};

export type PostCreateWithoutPackageInput = {
  audioUri?: InputMaybe<Scalars['String']>;
  benefitMemoToOrder?: InputMaybe<BenefitMemoToOrderCreateNestedManyWithoutPostInput>;
  body: CardCreateNestedOneWithoutPostsUsedInput;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  detail?: InputMaybe<OrderDetailCreateNestedOneWithoutPostInput>;
  histories?: InputMaybe<PostHistoryCreateNestedManyWithoutPostInput>;
  id?: InputMaybe<Scalars['String']>;
  inValidReasonHistories?: InputMaybe<InValidReasonCreateNestedManyWithoutPostInput>;
  isCheckin?: InputMaybe<Scalars['Boolean']>;
  likeUsers?: InputMaybe<UserCreateNestedManyWithoutLikePostsInput>;
  loc?: InputMaybe<Scalars['JSON']>;
  message?: InputMaybe<Scalars['String']>;
  paymentProduct?: InputMaybe<ProductCreateNestedOneWithoutPostsInput>;
  qrcode?: InputMaybe<Scalars['String']>;
  rearUri: Scalars['String'];
  rearUri4Practical?: InputMaybe<Scalars['String']>;
  receiver?: InputMaybe<UserCreateNestedOneWithoutPostsInInput>;
  recvPrivacy?: InputMaybe<PrivacyLevel>;
  reports?: InputMaybe<ReportCreateNestedManyWithoutPostInput>;
  scenicSpot?: InputMaybe<ScenicSpotCreateNestedOneWithoutPostsInput>;
  sendDate: Scalars['DateTime'];
  sendPrivacy: PrivacyLevel;
  sendStatus: PackageStatus;
  sender: UserCreateNestedOneWithoutPostsOutInput;
  signature?: InputMaybe<SignatureCreateNestedOneWithoutPostsUsedInput>;
  stamp: StampCreateNestedOneWithoutPostsUsedInput;
  tags?: InputMaybe<PostTagCreateNestedManyWithoutPostInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  valid?: InputMaybe<Scalars['Boolean']>;
};

export type PostCreateWithoutPaymentProductInput = {
  audioUri?: InputMaybe<Scalars['String']>;
  benefitMemoToOrder?: InputMaybe<BenefitMemoToOrderCreateNestedManyWithoutPostInput>;
  body: CardCreateNestedOneWithoutPostsUsedInput;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  detail?: InputMaybe<OrderDetailCreateNestedOneWithoutPostInput>;
  histories?: InputMaybe<PostHistoryCreateNestedManyWithoutPostInput>;
  id?: InputMaybe<Scalars['String']>;
  inValidReasonHistories?: InputMaybe<InValidReasonCreateNestedManyWithoutPostInput>;
  isCheckin?: InputMaybe<Scalars['Boolean']>;
  likeUsers?: InputMaybe<UserCreateNestedManyWithoutLikePostsInput>;
  loc?: InputMaybe<Scalars['JSON']>;
  message?: InputMaybe<Scalars['String']>;
  package?: InputMaybe<PostPackageCreateNestedOneWithoutPostsInput>;
  qrcode?: InputMaybe<Scalars['String']>;
  rearUri: Scalars['String'];
  rearUri4Practical?: InputMaybe<Scalars['String']>;
  receiver?: InputMaybe<UserCreateNestedOneWithoutPostsInInput>;
  recvPrivacy?: InputMaybe<PrivacyLevel>;
  reports?: InputMaybe<ReportCreateNestedManyWithoutPostInput>;
  scenicSpot?: InputMaybe<ScenicSpotCreateNestedOneWithoutPostsInput>;
  sendDate: Scalars['DateTime'];
  sendPrivacy: PrivacyLevel;
  sendStatus: PackageStatus;
  sender: UserCreateNestedOneWithoutPostsOutInput;
  signature?: InputMaybe<SignatureCreateNestedOneWithoutPostsUsedInput>;
  stamp: StampCreateNestedOneWithoutPostsUsedInput;
  tags?: InputMaybe<PostTagCreateNestedManyWithoutPostInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  valid?: InputMaybe<Scalars['Boolean']>;
};

export type PostCreateWithoutReceiverInput = {
  audioUri?: InputMaybe<Scalars['String']>;
  benefitMemoToOrder?: InputMaybe<BenefitMemoToOrderCreateNestedManyWithoutPostInput>;
  body: CardCreateNestedOneWithoutPostsUsedInput;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  detail?: InputMaybe<OrderDetailCreateNestedOneWithoutPostInput>;
  histories?: InputMaybe<PostHistoryCreateNestedManyWithoutPostInput>;
  id?: InputMaybe<Scalars['String']>;
  inValidReasonHistories?: InputMaybe<InValidReasonCreateNestedManyWithoutPostInput>;
  isCheckin?: InputMaybe<Scalars['Boolean']>;
  likeUsers?: InputMaybe<UserCreateNestedManyWithoutLikePostsInput>;
  loc?: InputMaybe<Scalars['JSON']>;
  message?: InputMaybe<Scalars['String']>;
  package?: InputMaybe<PostPackageCreateNestedOneWithoutPostsInput>;
  paymentProduct?: InputMaybe<ProductCreateNestedOneWithoutPostsInput>;
  qrcode?: InputMaybe<Scalars['String']>;
  rearUri: Scalars['String'];
  rearUri4Practical?: InputMaybe<Scalars['String']>;
  recvPrivacy?: InputMaybe<PrivacyLevel>;
  reports?: InputMaybe<ReportCreateNestedManyWithoutPostInput>;
  scenicSpot?: InputMaybe<ScenicSpotCreateNestedOneWithoutPostsInput>;
  sendDate: Scalars['DateTime'];
  sendPrivacy: PrivacyLevel;
  sendStatus: PackageStatus;
  sender: UserCreateNestedOneWithoutPostsOutInput;
  signature?: InputMaybe<SignatureCreateNestedOneWithoutPostsUsedInput>;
  stamp: StampCreateNestedOneWithoutPostsUsedInput;
  tags?: InputMaybe<PostTagCreateNestedManyWithoutPostInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  valid?: InputMaybe<Scalars['Boolean']>;
};

export type PostCreateWithoutReportsInput = {
  audioUri?: InputMaybe<Scalars['String']>;
  benefitMemoToOrder?: InputMaybe<BenefitMemoToOrderCreateNestedManyWithoutPostInput>;
  body: CardCreateNestedOneWithoutPostsUsedInput;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  detail?: InputMaybe<OrderDetailCreateNestedOneWithoutPostInput>;
  histories?: InputMaybe<PostHistoryCreateNestedManyWithoutPostInput>;
  id?: InputMaybe<Scalars['String']>;
  inValidReasonHistories?: InputMaybe<InValidReasonCreateNestedManyWithoutPostInput>;
  isCheckin?: InputMaybe<Scalars['Boolean']>;
  likeUsers?: InputMaybe<UserCreateNestedManyWithoutLikePostsInput>;
  loc?: InputMaybe<Scalars['JSON']>;
  message?: InputMaybe<Scalars['String']>;
  package?: InputMaybe<PostPackageCreateNestedOneWithoutPostsInput>;
  paymentProduct?: InputMaybe<ProductCreateNestedOneWithoutPostsInput>;
  qrcode?: InputMaybe<Scalars['String']>;
  rearUri: Scalars['String'];
  rearUri4Practical?: InputMaybe<Scalars['String']>;
  receiver?: InputMaybe<UserCreateNestedOneWithoutPostsInInput>;
  recvPrivacy?: InputMaybe<PrivacyLevel>;
  scenicSpot?: InputMaybe<ScenicSpotCreateNestedOneWithoutPostsInput>;
  sendDate: Scalars['DateTime'];
  sendPrivacy: PrivacyLevel;
  sendStatus: PackageStatus;
  sender: UserCreateNestedOneWithoutPostsOutInput;
  signature?: InputMaybe<SignatureCreateNestedOneWithoutPostsUsedInput>;
  stamp: StampCreateNestedOneWithoutPostsUsedInput;
  tags?: InputMaybe<PostTagCreateNestedManyWithoutPostInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  valid?: InputMaybe<Scalars['Boolean']>;
};

export type PostCreateWithoutScenicSpotInput = {
  audioUri?: InputMaybe<Scalars['String']>;
  benefitMemoToOrder?: InputMaybe<BenefitMemoToOrderCreateNestedManyWithoutPostInput>;
  body: CardCreateNestedOneWithoutPostsUsedInput;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  detail?: InputMaybe<OrderDetailCreateNestedOneWithoutPostInput>;
  histories?: InputMaybe<PostHistoryCreateNestedManyWithoutPostInput>;
  id?: InputMaybe<Scalars['String']>;
  inValidReasonHistories?: InputMaybe<InValidReasonCreateNestedManyWithoutPostInput>;
  isCheckin?: InputMaybe<Scalars['Boolean']>;
  likeUsers?: InputMaybe<UserCreateNestedManyWithoutLikePostsInput>;
  loc?: InputMaybe<Scalars['JSON']>;
  message?: InputMaybe<Scalars['String']>;
  package?: InputMaybe<PostPackageCreateNestedOneWithoutPostsInput>;
  paymentProduct?: InputMaybe<ProductCreateNestedOneWithoutPostsInput>;
  qrcode?: InputMaybe<Scalars['String']>;
  rearUri: Scalars['String'];
  rearUri4Practical?: InputMaybe<Scalars['String']>;
  receiver?: InputMaybe<UserCreateNestedOneWithoutPostsInInput>;
  recvPrivacy?: InputMaybe<PrivacyLevel>;
  reports?: InputMaybe<ReportCreateNestedManyWithoutPostInput>;
  sendDate: Scalars['DateTime'];
  sendPrivacy: PrivacyLevel;
  sendStatus: PackageStatus;
  sender: UserCreateNestedOneWithoutPostsOutInput;
  signature?: InputMaybe<SignatureCreateNestedOneWithoutPostsUsedInput>;
  stamp: StampCreateNestedOneWithoutPostsUsedInput;
  tags?: InputMaybe<PostTagCreateNestedManyWithoutPostInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  valid?: InputMaybe<Scalars['Boolean']>;
};

export type PostCreateWithoutSenderInput = {
  audioUri?: InputMaybe<Scalars['String']>;
  benefitMemoToOrder?: InputMaybe<BenefitMemoToOrderCreateNestedManyWithoutPostInput>;
  body: CardCreateNestedOneWithoutPostsUsedInput;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  detail?: InputMaybe<OrderDetailCreateNestedOneWithoutPostInput>;
  histories?: InputMaybe<PostHistoryCreateNestedManyWithoutPostInput>;
  id?: InputMaybe<Scalars['String']>;
  inValidReasonHistories?: InputMaybe<InValidReasonCreateNestedManyWithoutPostInput>;
  isCheckin?: InputMaybe<Scalars['Boolean']>;
  likeUsers?: InputMaybe<UserCreateNestedManyWithoutLikePostsInput>;
  loc?: InputMaybe<Scalars['JSON']>;
  message?: InputMaybe<Scalars['String']>;
  package?: InputMaybe<PostPackageCreateNestedOneWithoutPostsInput>;
  paymentProduct?: InputMaybe<ProductCreateNestedOneWithoutPostsInput>;
  qrcode?: InputMaybe<Scalars['String']>;
  rearUri: Scalars['String'];
  rearUri4Practical?: InputMaybe<Scalars['String']>;
  receiver?: InputMaybe<UserCreateNestedOneWithoutPostsInInput>;
  recvPrivacy?: InputMaybe<PrivacyLevel>;
  reports?: InputMaybe<ReportCreateNestedManyWithoutPostInput>;
  scenicSpot?: InputMaybe<ScenicSpotCreateNestedOneWithoutPostsInput>;
  sendDate: Scalars['DateTime'];
  sendPrivacy: PrivacyLevel;
  sendStatus: PackageStatus;
  signature?: InputMaybe<SignatureCreateNestedOneWithoutPostsUsedInput>;
  stamp: StampCreateNestedOneWithoutPostsUsedInput;
  tags?: InputMaybe<PostTagCreateNestedManyWithoutPostInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  valid?: InputMaybe<Scalars['Boolean']>;
};

export type PostCreateWithoutSignatureInput = {
  audioUri?: InputMaybe<Scalars['String']>;
  benefitMemoToOrder?: InputMaybe<BenefitMemoToOrderCreateNestedManyWithoutPostInput>;
  body: CardCreateNestedOneWithoutPostsUsedInput;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  detail?: InputMaybe<OrderDetailCreateNestedOneWithoutPostInput>;
  histories?: InputMaybe<PostHistoryCreateNestedManyWithoutPostInput>;
  id?: InputMaybe<Scalars['String']>;
  inValidReasonHistories?: InputMaybe<InValidReasonCreateNestedManyWithoutPostInput>;
  isCheckin?: InputMaybe<Scalars['Boolean']>;
  likeUsers?: InputMaybe<UserCreateNestedManyWithoutLikePostsInput>;
  loc?: InputMaybe<Scalars['JSON']>;
  message?: InputMaybe<Scalars['String']>;
  package?: InputMaybe<PostPackageCreateNestedOneWithoutPostsInput>;
  paymentProduct?: InputMaybe<ProductCreateNestedOneWithoutPostsInput>;
  qrcode?: InputMaybe<Scalars['String']>;
  rearUri: Scalars['String'];
  rearUri4Practical?: InputMaybe<Scalars['String']>;
  receiver?: InputMaybe<UserCreateNestedOneWithoutPostsInInput>;
  recvPrivacy?: InputMaybe<PrivacyLevel>;
  reports?: InputMaybe<ReportCreateNestedManyWithoutPostInput>;
  scenicSpot?: InputMaybe<ScenicSpotCreateNestedOneWithoutPostsInput>;
  sendDate: Scalars['DateTime'];
  sendPrivacy: PrivacyLevel;
  sendStatus: PackageStatus;
  sender: UserCreateNestedOneWithoutPostsOutInput;
  stamp: StampCreateNestedOneWithoutPostsUsedInput;
  tags?: InputMaybe<PostTagCreateNestedManyWithoutPostInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  valid?: InputMaybe<Scalars['Boolean']>;
};

export type PostCreateWithoutStampInput = {
  audioUri?: InputMaybe<Scalars['String']>;
  benefitMemoToOrder?: InputMaybe<BenefitMemoToOrderCreateNestedManyWithoutPostInput>;
  body: CardCreateNestedOneWithoutPostsUsedInput;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  detail?: InputMaybe<OrderDetailCreateNestedOneWithoutPostInput>;
  histories?: InputMaybe<PostHistoryCreateNestedManyWithoutPostInput>;
  id?: InputMaybe<Scalars['String']>;
  inValidReasonHistories?: InputMaybe<InValidReasonCreateNestedManyWithoutPostInput>;
  isCheckin?: InputMaybe<Scalars['Boolean']>;
  likeUsers?: InputMaybe<UserCreateNestedManyWithoutLikePostsInput>;
  loc?: InputMaybe<Scalars['JSON']>;
  message?: InputMaybe<Scalars['String']>;
  package?: InputMaybe<PostPackageCreateNestedOneWithoutPostsInput>;
  paymentProduct?: InputMaybe<ProductCreateNestedOneWithoutPostsInput>;
  qrcode?: InputMaybe<Scalars['String']>;
  rearUri: Scalars['String'];
  rearUri4Practical?: InputMaybe<Scalars['String']>;
  receiver?: InputMaybe<UserCreateNestedOneWithoutPostsInInput>;
  recvPrivacy?: InputMaybe<PrivacyLevel>;
  reports?: InputMaybe<ReportCreateNestedManyWithoutPostInput>;
  scenicSpot?: InputMaybe<ScenicSpotCreateNestedOneWithoutPostsInput>;
  sendDate: Scalars['DateTime'];
  sendPrivacy: PrivacyLevel;
  sendStatus: PackageStatus;
  sender: UserCreateNestedOneWithoutPostsOutInput;
  signature?: InputMaybe<SignatureCreateNestedOneWithoutPostsUsedInput>;
  tags?: InputMaybe<PostTagCreateNestedManyWithoutPostInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  valid?: InputMaybe<Scalars['Boolean']>;
};

export type PostCreateWithoutTagsInput = {
  audioUri?: InputMaybe<Scalars['String']>;
  benefitMemoToOrder?: InputMaybe<BenefitMemoToOrderCreateNestedManyWithoutPostInput>;
  body: CardCreateNestedOneWithoutPostsUsedInput;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  detail?: InputMaybe<OrderDetailCreateNestedOneWithoutPostInput>;
  histories?: InputMaybe<PostHistoryCreateNestedManyWithoutPostInput>;
  id?: InputMaybe<Scalars['String']>;
  inValidReasonHistories?: InputMaybe<InValidReasonCreateNestedManyWithoutPostInput>;
  isCheckin?: InputMaybe<Scalars['Boolean']>;
  likeUsers?: InputMaybe<UserCreateNestedManyWithoutLikePostsInput>;
  loc?: InputMaybe<Scalars['JSON']>;
  message?: InputMaybe<Scalars['String']>;
  package?: InputMaybe<PostPackageCreateNestedOneWithoutPostsInput>;
  paymentProduct?: InputMaybe<ProductCreateNestedOneWithoutPostsInput>;
  qrcode?: InputMaybe<Scalars['String']>;
  rearUri: Scalars['String'];
  rearUri4Practical?: InputMaybe<Scalars['String']>;
  receiver?: InputMaybe<UserCreateNestedOneWithoutPostsInInput>;
  recvPrivacy?: InputMaybe<PrivacyLevel>;
  reports?: InputMaybe<ReportCreateNestedManyWithoutPostInput>;
  scenicSpot?: InputMaybe<ScenicSpotCreateNestedOneWithoutPostsInput>;
  sendDate: Scalars['DateTime'];
  sendPrivacy: PrivacyLevel;
  sendStatus: PackageStatus;
  sender: UserCreateNestedOneWithoutPostsOutInput;
  signature?: InputMaybe<SignatureCreateNestedOneWithoutPostsUsedInput>;
  stamp: StampCreateNestedOneWithoutPostsUsedInput;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  valid?: InputMaybe<Scalars['Boolean']>;
};

export type PostGroupBy = {
  __typename?: 'PostGroupBy';
  _count?: Maybe<PostCountAggregate>;
  _max?: Maybe<PostMaxAggregate>;
  _min?: Maybe<PostMinAggregate>;
  audioUri?: Maybe<Scalars['String']>;
  bodyId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  isCheckin: Scalars['Boolean'];
  loc?: Maybe<Scalars['JSON']>;
  message?: Maybe<Scalars['String']>;
  packageId?: Maybe<Scalars['String']>;
  paymentProductId?: Maybe<Scalars['String']>;
  qrcode?: Maybe<Scalars['String']>;
  rearUri: Scalars['String'];
  rearUri4Practical?: Maybe<Scalars['String']>;
  receiverId?: Maybe<Scalars['String']>;
  recvPrivacy?: Maybe<PrivacyLevel>;
  scenicSpotId?: Maybe<Scalars['String']>;
  sendDate: Scalars['DateTime'];
  sendPrivacy: PrivacyLevel;
  sendStatus: PackageStatus;
  senderId: Scalars['String'];
  signatureId?: Maybe<Scalars['String']>;
  stampId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  valid: Scalars['Boolean'];
};

export type PostHistory = {
  __typename?: 'PostHistory';
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  post: Post;
  postId: Scalars['String'];
  sendStatus: PackageStatus;
  updatedAt: Scalars['DateTime'];
};

export type PostHistoryCountAggregate = {
  __typename?: 'PostHistoryCountAggregate';
  _all: Scalars['Int'];
  createdAt: Scalars['Int'];
  id: Scalars['Int'];
  postId: Scalars['Int'];
  sendStatus: Scalars['Int'];
  updatedAt: Scalars['Int'];
};

export type PostHistoryCountOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  postId?: InputMaybe<SortOrder>;
  sendStatus?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type PostHistoryCreateInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  post: PostCreateNestedOneWithoutHistoriesInput;
  sendStatus: PackageStatus;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type PostHistoryCreateManyInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  postId: Scalars['String'];
  sendStatus: PackageStatus;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type PostHistoryCreateManyPostInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  sendStatus: PackageStatus;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type PostHistoryCreateManyPostInputEnvelope = {
  data: Array<PostHistoryCreateManyPostInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type PostHistoryCreateNestedManyWithoutPostInput = {
  connect?: InputMaybe<Array<PostHistoryWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<PostHistoryCreateOrConnectWithoutPostInput>>;
  create?: InputMaybe<Array<PostHistoryCreateWithoutPostInput>>;
  createMany?: InputMaybe<PostHistoryCreateManyPostInputEnvelope>;
};

export type PostHistoryCreateOrConnectWithoutPostInput = {
  create: PostHistoryCreateWithoutPostInput;
  where: PostHistoryWhereUniqueInput;
};

export type PostHistoryCreateWithoutPostInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  sendStatus: PackageStatus;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type PostHistoryGroupBy = {
  __typename?: 'PostHistoryGroupBy';
  _count?: Maybe<PostHistoryCountAggregate>;
  _max?: Maybe<PostHistoryMaxAggregate>;
  _min?: Maybe<PostHistoryMinAggregate>;
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  postId: Scalars['String'];
  sendStatus: PackageStatus;
  updatedAt: Scalars['DateTime'];
};

export type PostHistoryListRelationFilter = {
  every?: InputMaybe<PostHistoryWhereInput>;
  none?: InputMaybe<PostHistoryWhereInput>;
  some?: InputMaybe<PostHistoryWhereInput>;
};

export type PostHistoryMaxAggregate = {
  __typename?: 'PostHistoryMaxAggregate';
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  postId?: Maybe<Scalars['String']>;
  sendStatus?: Maybe<PackageStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PostHistoryMaxOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  postId?: InputMaybe<SortOrder>;
  sendStatus?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type PostHistoryMinAggregate = {
  __typename?: 'PostHistoryMinAggregate';
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  postId?: Maybe<Scalars['String']>;
  sendStatus?: Maybe<PackageStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PostHistoryMinOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  postId?: InputMaybe<SortOrder>;
  sendStatus?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type PostHistoryOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type PostHistoryOrderByWithAggregationInput = {
  _count?: InputMaybe<PostHistoryCountOrderByAggregateInput>;
  _max?: InputMaybe<PostHistoryMaxOrderByAggregateInput>;
  _min?: InputMaybe<PostHistoryMinOrderByAggregateInput>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  postId?: InputMaybe<SortOrder>;
  sendStatus?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type PostHistoryOrderByWithRelationInput = {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  post?: InputMaybe<PostOrderByWithRelationInput>;
  postId?: InputMaybe<SortOrder>;
  sendStatus?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export enum PostHistoryScalarFieldEnum {
  CreatedAt = 'createdAt',
  Id = 'id',
  PostId = 'postId',
  SendStatus = 'sendStatus',
  UpdatedAt = 'updatedAt'
}

export type PostHistoryScalarWhereInput = {
  AND?: InputMaybe<Array<PostHistoryScalarWhereInput>>;
  NOT?: InputMaybe<Array<PostHistoryScalarWhereInput>>;
  OR?: InputMaybe<Array<PostHistoryScalarWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  postId?: InputMaybe<StringFilter>;
  sendStatus?: InputMaybe<EnumPackageStatusFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type PostHistoryScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<PostHistoryScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<PostHistoryScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<PostHistoryScalarWhereWithAggregatesInput>>;
  createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  id?: InputMaybe<StringWithAggregatesFilter>;
  postId?: InputMaybe<StringWithAggregatesFilter>;
  sendStatus?: InputMaybe<EnumPackageStatusWithAggregatesFilter>;
  updatedAt?: InputMaybe<DateTimeWithAggregatesFilter>;
};

export type PostHistoryUpdateInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  post?: InputMaybe<PostUpdateOneRequiredWithoutHistoriesNestedInput>;
  sendStatus?: InputMaybe<EnumPackageStatusFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type PostHistoryUpdateManyMutationInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  sendStatus?: InputMaybe<EnumPackageStatusFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type PostHistoryUpdateManyWithWhereWithoutPostInput = {
  data: PostHistoryUpdateManyMutationInput;
  where: PostHistoryScalarWhereInput;
};

export type PostHistoryUpdateManyWithoutPostNestedInput = {
  connect?: InputMaybe<Array<PostHistoryWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<PostHistoryCreateOrConnectWithoutPostInput>>;
  create?: InputMaybe<Array<PostHistoryCreateWithoutPostInput>>;
  createMany?: InputMaybe<PostHistoryCreateManyPostInputEnvelope>;
  delete?: InputMaybe<Array<PostHistoryWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<PostHistoryScalarWhereInput>>;
  disconnect?: InputMaybe<Array<PostHistoryWhereUniqueInput>>;
  set?: InputMaybe<Array<PostHistoryWhereUniqueInput>>;
  update?: InputMaybe<Array<PostHistoryUpdateWithWhereUniqueWithoutPostInput>>;
  updateMany?: InputMaybe<Array<PostHistoryUpdateManyWithWhereWithoutPostInput>>;
  upsert?: InputMaybe<Array<PostHistoryUpsertWithWhereUniqueWithoutPostInput>>;
};

export type PostHistoryUpdateWithWhereUniqueWithoutPostInput = {
  data: PostHistoryUpdateWithoutPostInput;
  where: PostHistoryWhereUniqueInput;
};

export type PostHistoryUpdateWithoutPostInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  sendStatus?: InputMaybe<EnumPackageStatusFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type PostHistoryUpsertWithWhereUniqueWithoutPostInput = {
  create: PostHistoryCreateWithoutPostInput;
  update: PostHistoryUpdateWithoutPostInput;
  where: PostHistoryWhereUniqueInput;
};

export type PostHistoryWhereInput = {
  AND?: InputMaybe<Array<PostHistoryWhereInput>>;
  NOT?: InputMaybe<Array<PostHistoryWhereInput>>;
  OR?: InputMaybe<Array<PostHistoryWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  post?: InputMaybe<PostRelationFilter>;
  postId?: InputMaybe<StringFilter>;
  sendStatus?: InputMaybe<EnumPackageStatusFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type PostHistoryWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
};

export type PostListRelationFilter = {
  every?: InputMaybe<PostWhereInput>;
  none?: InputMaybe<PostWhereInput>;
  some?: InputMaybe<PostWhereInput>;
};

export type PostMaxAggregate = {
  __typename?: 'PostMaxAggregate';
  audioUri?: Maybe<Scalars['String']>;
  bodyId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isCheckin?: Maybe<Scalars['Boolean']>;
  message?: Maybe<Scalars['String']>;
  packageId?: Maybe<Scalars['String']>;
  paymentProductId?: Maybe<Scalars['String']>;
  qrcode?: Maybe<Scalars['String']>;
  rearUri?: Maybe<Scalars['String']>;
  rearUri4Practical?: Maybe<Scalars['String']>;
  receiverId?: Maybe<Scalars['String']>;
  recvPrivacy?: Maybe<PrivacyLevel>;
  scenicSpotId?: Maybe<Scalars['String']>;
  sendDate?: Maybe<Scalars['DateTime']>;
  sendPrivacy?: Maybe<PrivacyLevel>;
  sendStatus?: Maybe<PackageStatus>;
  senderId?: Maybe<Scalars['String']>;
  signatureId?: Maybe<Scalars['String']>;
  stampId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  valid?: Maybe<Scalars['Boolean']>;
};

export type PostMaxOrderByAggregateInput = {
  audioUri?: InputMaybe<SortOrder>;
  bodyId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  isCheckin?: InputMaybe<SortOrder>;
  message?: InputMaybe<SortOrder>;
  packageId?: InputMaybe<SortOrder>;
  paymentProductId?: InputMaybe<SortOrder>;
  qrcode?: InputMaybe<SortOrder>;
  rearUri?: InputMaybe<SortOrder>;
  rearUri4Practical?: InputMaybe<SortOrder>;
  receiverId?: InputMaybe<SortOrder>;
  recvPrivacy?: InputMaybe<SortOrder>;
  scenicSpotId?: InputMaybe<SortOrder>;
  sendDate?: InputMaybe<SortOrder>;
  sendPrivacy?: InputMaybe<SortOrder>;
  sendStatus?: InputMaybe<SortOrder>;
  senderId?: InputMaybe<SortOrder>;
  signatureId?: InputMaybe<SortOrder>;
  stampId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  valid?: InputMaybe<SortOrder>;
};

export type PostMinAggregate = {
  __typename?: 'PostMinAggregate';
  audioUri?: Maybe<Scalars['String']>;
  bodyId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isCheckin?: Maybe<Scalars['Boolean']>;
  message?: Maybe<Scalars['String']>;
  packageId?: Maybe<Scalars['String']>;
  paymentProductId?: Maybe<Scalars['String']>;
  qrcode?: Maybe<Scalars['String']>;
  rearUri?: Maybe<Scalars['String']>;
  rearUri4Practical?: Maybe<Scalars['String']>;
  receiverId?: Maybe<Scalars['String']>;
  recvPrivacy?: Maybe<PrivacyLevel>;
  scenicSpotId?: Maybe<Scalars['String']>;
  sendDate?: Maybe<Scalars['DateTime']>;
  sendPrivacy?: Maybe<PrivacyLevel>;
  sendStatus?: Maybe<PackageStatus>;
  senderId?: Maybe<Scalars['String']>;
  signatureId?: Maybe<Scalars['String']>;
  stampId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  valid?: Maybe<Scalars['Boolean']>;
};

export type PostMinOrderByAggregateInput = {
  audioUri?: InputMaybe<SortOrder>;
  bodyId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  isCheckin?: InputMaybe<SortOrder>;
  message?: InputMaybe<SortOrder>;
  packageId?: InputMaybe<SortOrder>;
  paymentProductId?: InputMaybe<SortOrder>;
  qrcode?: InputMaybe<SortOrder>;
  rearUri?: InputMaybe<SortOrder>;
  rearUri4Practical?: InputMaybe<SortOrder>;
  receiverId?: InputMaybe<SortOrder>;
  recvPrivacy?: InputMaybe<SortOrder>;
  scenicSpotId?: InputMaybe<SortOrder>;
  sendDate?: InputMaybe<SortOrder>;
  sendPrivacy?: InputMaybe<SortOrder>;
  sendStatus?: InputMaybe<SortOrder>;
  senderId?: InputMaybe<SortOrder>;
  signatureId?: InputMaybe<SortOrder>;
  stampId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  valid?: InputMaybe<SortOrder>;
};

export type PostOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type PostOrderByWithAggregationInput = {
  _count?: InputMaybe<PostCountOrderByAggregateInput>;
  _max?: InputMaybe<PostMaxOrderByAggregateInput>;
  _min?: InputMaybe<PostMinOrderByAggregateInput>;
  audioUri?: InputMaybe<SortOrder>;
  bodyId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  isCheckin?: InputMaybe<SortOrder>;
  loc?: InputMaybe<SortOrder>;
  message?: InputMaybe<SortOrder>;
  packageId?: InputMaybe<SortOrder>;
  paymentProductId?: InputMaybe<SortOrder>;
  qrcode?: InputMaybe<SortOrder>;
  rearUri?: InputMaybe<SortOrder>;
  rearUri4Practical?: InputMaybe<SortOrder>;
  receiverId?: InputMaybe<SortOrder>;
  recvPrivacy?: InputMaybe<SortOrder>;
  scenicSpotId?: InputMaybe<SortOrder>;
  sendDate?: InputMaybe<SortOrder>;
  sendPrivacy?: InputMaybe<SortOrder>;
  sendStatus?: InputMaybe<SortOrder>;
  senderId?: InputMaybe<SortOrder>;
  signatureId?: InputMaybe<SortOrder>;
  stampId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  valid?: InputMaybe<SortOrder>;
};

export type PostOrderByWithRelationInput = {
  audioUri?: InputMaybe<SortOrder>;
  benefitMemoToOrder?: InputMaybe<BenefitMemoToOrderOrderByRelationAggregateInput>;
  body?: InputMaybe<CardOrderByWithRelationInput>;
  bodyId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  detail?: InputMaybe<OrderDetailOrderByWithRelationInput>;
  histories?: InputMaybe<PostHistoryOrderByRelationAggregateInput>;
  id?: InputMaybe<SortOrder>;
  inValidReasonHistories?: InputMaybe<InValidReasonOrderByRelationAggregateInput>;
  isCheckin?: InputMaybe<SortOrder>;
  likeUsers?: InputMaybe<UserOrderByRelationAggregateInput>;
  loc?: InputMaybe<SortOrder>;
  message?: InputMaybe<SortOrder>;
  package?: InputMaybe<PostPackageOrderByWithRelationInput>;
  packageId?: InputMaybe<SortOrder>;
  paymentProduct?: InputMaybe<ProductOrderByWithRelationInput>;
  paymentProductId?: InputMaybe<SortOrder>;
  qrcode?: InputMaybe<SortOrder>;
  rearUri?: InputMaybe<SortOrder>;
  rearUri4Practical?: InputMaybe<SortOrder>;
  receiver?: InputMaybe<UserOrderByWithRelationInput>;
  receiverId?: InputMaybe<SortOrder>;
  recvPrivacy?: InputMaybe<SortOrder>;
  reports?: InputMaybe<ReportOrderByRelationAggregateInput>;
  scenicSpot?: InputMaybe<ScenicSpotOrderByWithRelationInput>;
  scenicSpotId?: InputMaybe<SortOrder>;
  sendDate?: InputMaybe<SortOrder>;
  sendPrivacy?: InputMaybe<SortOrder>;
  sendStatus?: InputMaybe<SortOrder>;
  sender?: InputMaybe<UserOrderByWithRelationInput>;
  senderId?: InputMaybe<SortOrder>;
  signature?: InputMaybe<SignatureOrderByWithRelationInput>;
  signatureId?: InputMaybe<SortOrder>;
  stamp?: InputMaybe<StampOrderByWithRelationInput>;
  stampId?: InputMaybe<SortOrder>;
  tags?: InputMaybe<PostTagOrderByRelationAggregateInput>;
  updatedAt?: InputMaybe<SortOrder>;
  valid?: InputMaybe<SortOrder>;
};

export type PostPackage = {
  __typename?: 'PostPackage';
  _count?: Maybe<PostPackageCount>;
  couponEntities: Array<CouponEntity>;
  createdAt: Scalars['DateTime'];
  emailTos: Array<EmailTo>;
  groupsRecv: Array<Group>;
  id: Scalars['String'];
  manualAddrs: Array<ManualAddr>;
  order?: Maybe<Order>;
  packageType: PackageType;
  posts: Array<Post>;
  receivers: Array<User>;
  sendDate: Scalars['DateTime'];
  sendPrivacy: PrivacyLevel;
  sender: User;
  senderId: Scalars['String'];
  sendingType: SendingType;
  totalCount: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
};


export type PostPackageCouponEntitiesArgs = {
  cursor?: InputMaybe<CouponEntityWhereUniqueInput>;
  distinct?: InputMaybe<Array<CouponEntityScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<CouponEntityOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CouponEntityWhereInput>;
};


export type PostPackageEmailTosArgs = {
  cursor?: InputMaybe<EmailToWhereUniqueInput>;
  distinct?: InputMaybe<Array<EmailToScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<EmailToOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<EmailToWhereInput>;
};


export type PostPackageGroupsRecvArgs = {
  cursor?: InputMaybe<GroupWhereUniqueInput>;
  distinct?: InputMaybe<Array<GroupScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<GroupOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<GroupWhereInput>;
};


export type PostPackageManualAddrsArgs = {
  cursor?: InputMaybe<ManualAddrWhereUniqueInput>;
  distinct?: InputMaybe<Array<ManualAddrScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ManualAddrOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ManualAddrWhereInput>;
};


export type PostPackagePostsArgs = {
  cursor?: InputMaybe<PostWhereUniqueInput>;
  distinct?: InputMaybe<Array<PostScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<PostOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PostWhereInput>;
};


export type PostPackageReceiversArgs = {
  cursor?: InputMaybe<UserWhereUniqueInput>;
  distinct?: InputMaybe<Array<UserScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<UserOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<UserWhereInput>;
};

export type PostPackageAvgAggregate = {
  __typename?: 'PostPackageAvgAggregate';
  totalCount?: Maybe<Scalars['Float']>;
};

export type PostPackageAvgOrderByAggregateInput = {
  totalCount?: InputMaybe<SortOrder>;
};

export type PostPackageCount = {
  __typename?: 'PostPackageCount';
  couponEntities: Scalars['Int'];
  emailTos: Scalars['Int'];
  groupsRecv: Scalars['Int'];
  manualAddrs: Scalars['Int'];
  posts: Scalars['Int'];
  receivers: Scalars['Int'];
};


export type PostPackageCountCouponEntitiesArgs = {
  where?: InputMaybe<CouponEntityWhereInput>;
};


export type PostPackageCountEmailTosArgs = {
  where?: InputMaybe<EmailToWhereInput>;
};


export type PostPackageCountGroupsRecvArgs = {
  where?: InputMaybe<GroupWhereInput>;
};


export type PostPackageCountManualAddrsArgs = {
  where?: InputMaybe<ManualAddrWhereInput>;
};


export type PostPackageCountPostsArgs = {
  where?: InputMaybe<PostWhereInput>;
};


export type PostPackageCountReceiversArgs = {
  where?: InputMaybe<UserWhereInput>;
};

export type PostPackageCountAggregate = {
  __typename?: 'PostPackageCountAggregate';
  _all: Scalars['Int'];
  createdAt: Scalars['Int'];
  id: Scalars['Int'];
  packageType: Scalars['Int'];
  sendDate: Scalars['Int'];
  sendPrivacy: Scalars['Int'];
  senderId: Scalars['Int'];
  sendingType: Scalars['Int'];
  totalCount: Scalars['Int'];
  updatedAt: Scalars['Int'];
};

export type PostPackageCountOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  packageType?: InputMaybe<SortOrder>;
  sendDate?: InputMaybe<SortOrder>;
  sendPrivacy?: InputMaybe<SortOrder>;
  senderId?: InputMaybe<SortOrder>;
  sendingType?: InputMaybe<SortOrder>;
  totalCount?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type PostPackageCreateInput = {
  couponEntities?: InputMaybe<CouponEntityCreateNestedManyWithoutInPostPackageInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  emailTos?: InputMaybe<EmailToCreateNestedManyWithoutPostPackagesInput>;
  groupsRecv?: InputMaybe<GroupCreateNestedManyWithoutPackagesUsedInput>;
  id?: InputMaybe<Scalars['String']>;
  manualAddrs?: InputMaybe<ManualAddrCreateNestedManyWithoutPostPackagesInput>;
  order?: InputMaybe<OrderCreateNestedOneWithoutPostPackageInput>;
  packageType?: InputMaybe<PackageType>;
  posts?: InputMaybe<PostCreateNestedManyWithoutPackageInput>;
  receivers?: InputMaybe<UserCreateNestedManyWithoutPackagesAsReceiverInput>;
  sendDate: Scalars['DateTime'];
  sendPrivacy: PrivacyLevel;
  sender: UserCreateNestedOneWithoutPostPackageInput;
  sendingType: SendingType;
  totalCount: Scalars['Int'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type PostPackageCreateManyInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  packageType?: InputMaybe<PackageType>;
  sendDate: Scalars['DateTime'];
  sendPrivacy: PrivacyLevel;
  senderId: Scalars['String'];
  sendingType: SendingType;
  totalCount: Scalars['Int'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type PostPackageCreateManySenderInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  packageType?: InputMaybe<PackageType>;
  sendDate: Scalars['DateTime'];
  sendPrivacy: PrivacyLevel;
  sendingType: SendingType;
  totalCount: Scalars['Int'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type PostPackageCreateManySenderInputEnvelope = {
  data: Array<PostPackageCreateManySenderInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type PostPackageCreateNestedManyWithoutEmailTosInput = {
  connect?: InputMaybe<Array<PostPackageWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<PostPackageCreateOrConnectWithoutEmailTosInput>>;
  create?: InputMaybe<Array<PostPackageCreateWithoutEmailTosInput>>;
};

export type PostPackageCreateNestedManyWithoutGroupsRecvInput = {
  connect?: InputMaybe<Array<PostPackageWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<PostPackageCreateOrConnectWithoutGroupsRecvInput>>;
  create?: InputMaybe<Array<PostPackageCreateWithoutGroupsRecvInput>>;
};

export type PostPackageCreateNestedManyWithoutManualAddrsInput = {
  connect?: InputMaybe<Array<PostPackageWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<PostPackageCreateOrConnectWithoutManualAddrsInput>>;
  create?: InputMaybe<Array<PostPackageCreateWithoutManualAddrsInput>>;
};

export type PostPackageCreateNestedManyWithoutReceiversInput = {
  connect?: InputMaybe<Array<PostPackageWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<PostPackageCreateOrConnectWithoutReceiversInput>>;
  create?: InputMaybe<Array<PostPackageCreateWithoutReceiversInput>>;
};

export type PostPackageCreateNestedManyWithoutSenderInput = {
  connect?: InputMaybe<Array<PostPackageWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<PostPackageCreateOrConnectWithoutSenderInput>>;
  create?: InputMaybe<Array<PostPackageCreateWithoutSenderInput>>;
  createMany?: InputMaybe<PostPackageCreateManySenderInputEnvelope>;
};

export type PostPackageCreateNestedOneWithoutCouponEntitiesInput = {
  connect?: InputMaybe<PostPackageWhereUniqueInput>;
  connectOrCreate?: InputMaybe<PostPackageCreateOrConnectWithoutCouponEntitiesInput>;
  create?: InputMaybe<PostPackageCreateWithoutCouponEntitiesInput>;
};

export type PostPackageCreateNestedOneWithoutOrderInput = {
  connect?: InputMaybe<PostPackageWhereUniqueInput>;
  connectOrCreate?: InputMaybe<PostPackageCreateOrConnectWithoutOrderInput>;
  create?: InputMaybe<PostPackageCreateWithoutOrderInput>;
};

export type PostPackageCreateNestedOneWithoutPostsInput = {
  connect?: InputMaybe<PostPackageWhereUniqueInput>;
  connectOrCreate?: InputMaybe<PostPackageCreateOrConnectWithoutPostsInput>;
  create?: InputMaybe<PostPackageCreateWithoutPostsInput>;
};

export type PostPackageCreateOrConnectWithoutCouponEntitiesInput = {
  create: PostPackageCreateWithoutCouponEntitiesInput;
  where: PostPackageWhereUniqueInput;
};

export type PostPackageCreateOrConnectWithoutEmailTosInput = {
  create: PostPackageCreateWithoutEmailTosInput;
  where: PostPackageWhereUniqueInput;
};

export type PostPackageCreateOrConnectWithoutGroupsRecvInput = {
  create: PostPackageCreateWithoutGroupsRecvInput;
  where: PostPackageWhereUniqueInput;
};

export type PostPackageCreateOrConnectWithoutManualAddrsInput = {
  create: PostPackageCreateWithoutManualAddrsInput;
  where: PostPackageWhereUniqueInput;
};

export type PostPackageCreateOrConnectWithoutOrderInput = {
  create: PostPackageCreateWithoutOrderInput;
  where: PostPackageWhereUniqueInput;
};

export type PostPackageCreateOrConnectWithoutPostsInput = {
  create: PostPackageCreateWithoutPostsInput;
  where: PostPackageWhereUniqueInput;
};

export type PostPackageCreateOrConnectWithoutReceiversInput = {
  create: PostPackageCreateWithoutReceiversInput;
  where: PostPackageWhereUniqueInput;
};

export type PostPackageCreateOrConnectWithoutSenderInput = {
  create: PostPackageCreateWithoutSenderInput;
  where: PostPackageWhereUniqueInput;
};

export type PostPackageCreateWithoutCouponEntitiesInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  emailTos?: InputMaybe<EmailToCreateNestedManyWithoutPostPackagesInput>;
  groupsRecv?: InputMaybe<GroupCreateNestedManyWithoutPackagesUsedInput>;
  id?: InputMaybe<Scalars['String']>;
  manualAddrs?: InputMaybe<ManualAddrCreateNestedManyWithoutPostPackagesInput>;
  order?: InputMaybe<OrderCreateNestedOneWithoutPostPackageInput>;
  packageType?: InputMaybe<PackageType>;
  posts?: InputMaybe<PostCreateNestedManyWithoutPackageInput>;
  receivers?: InputMaybe<UserCreateNestedManyWithoutPackagesAsReceiverInput>;
  sendDate: Scalars['DateTime'];
  sendPrivacy: PrivacyLevel;
  sender: UserCreateNestedOneWithoutPostPackageInput;
  sendingType: SendingType;
  totalCount: Scalars['Int'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type PostPackageCreateWithoutEmailTosInput = {
  couponEntities?: InputMaybe<CouponEntityCreateNestedManyWithoutInPostPackageInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  groupsRecv?: InputMaybe<GroupCreateNestedManyWithoutPackagesUsedInput>;
  id?: InputMaybe<Scalars['String']>;
  manualAddrs?: InputMaybe<ManualAddrCreateNestedManyWithoutPostPackagesInput>;
  order?: InputMaybe<OrderCreateNestedOneWithoutPostPackageInput>;
  packageType?: InputMaybe<PackageType>;
  posts?: InputMaybe<PostCreateNestedManyWithoutPackageInput>;
  receivers?: InputMaybe<UserCreateNestedManyWithoutPackagesAsReceiverInput>;
  sendDate: Scalars['DateTime'];
  sendPrivacy: PrivacyLevel;
  sender: UserCreateNestedOneWithoutPostPackageInput;
  sendingType: SendingType;
  totalCount: Scalars['Int'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type PostPackageCreateWithoutGroupsRecvInput = {
  couponEntities?: InputMaybe<CouponEntityCreateNestedManyWithoutInPostPackageInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  emailTos?: InputMaybe<EmailToCreateNestedManyWithoutPostPackagesInput>;
  id?: InputMaybe<Scalars['String']>;
  manualAddrs?: InputMaybe<ManualAddrCreateNestedManyWithoutPostPackagesInput>;
  order?: InputMaybe<OrderCreateNestedOneWithoutPostPackageInput>;
  packageType?: InputMaybe<PackageType>;
  posts?: InputMaybe<PostCreateNestedManyWithoutPackageInput>;
  receivers?: InputMaybe<UserCreateNestedManyWithoutPackagesAsReceiverInput>;
  sendDate: Scalars['DateTime'];
  sendPrivacy: PrivacyLevel;
  sender: UserCreateNestedOneWithoutPostPackageInput;
  sendingType: SendingType;
  totalCount: Scalars['Int'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type PostPackageCreateWithoutManualAddrsInput = {
  couponEntities?: InputMaybe<CouponEntityCreateNestedManyWithoutInPostPackageInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  emailTos?: InputMaybe<EmailToCreateNestedManyWithoutPostPackagesInput>;
  groupsRecv?: InputMaybe<GroupCreateNestedManyWithoutPackagesUsedInput>;
  id?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<OrderCreateNestedOneWithoutPostPackageInput>;
  packageType?: InputMaybe<PackageType>;
  posts?: InputMaybe<PostCreateNestedManyWithoutPackageInput>;
  receivers?: InputMaybe<UserCreateNestedManyWithoutPackagesAsReceiverInput>;
  sendDate: Scalars['DateTime'];
  sendPrivacy: PrivacyLevel;
  sender: UserCreateNestedOneWithoutPostPackageInput;
  sendingType: SendingType;
  totalCount: Scalars['Int'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type PostPackageCreateWithoutOrderInput = {
  couponEntities?: InputMaybe<CouponEntityCreateNestedManyWithoutInPostPackageInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  emailTos?: InputMaybe<EmailToCreateNestedManyWithoutPostPackagesInput>;
  groupsRecv?: InputMaybe<GroupCreateNestedManyWithoutPackagesUsedInput>;
  id?: InputMaybe<Scalars['String']>;
  manualAddrs?: InputMaybe<ManualAddrCreateNestedManyWithoutPostPackagesInput>;
  packageType?: InputMaybe<PackageType>;
  posts?: InputMaybe<PostCreateNestedManyWithoutPackageInput>;
  receivers?: InputMaybe<UserCreateNestedManyWithoutPackagesAsReceiverInput>;
  sendDate: Scalars['DateTime'];
  sendPrivacy: PrivacyLevel;
  sender: UserCreateNestedOneWithoutPostPackageInput;
  sendingType: SendingType;
  totalCount: Scalars['Int'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type PostPackageCreateWithoutPostsInput = {
  couponEntities?: InputMaybe<CouponEntityCreateNestedManyWithoutInPostPackageInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  emailTos?: InputMaybe<EmailToCreateNestedManyWithoutPostPackagesInput>;
  groupsRecv?: InputMaybe<GroupCreateNestedManyWithoutPackagesUsedInput>;
  id?: InputMaybe<Scalars['String']>;
  manualAddrs?: InputMaybe<ManualAddrCreateNestedManyWithoutPostPackagesInput>;
  order?: InputMaybe<OrderCreateNestedOneWithoutPostPackageInput>;
  packageType?: InputMaybe<PackageType>;
  receivers?: InputMaybe<UserCreateNestedManyWithoutPackagesAsReceiverInput>;
  sendDate: Scalars['DateTime'];
  sendPrivacy: PrivacyLevel;
  sender: UserCreateNestedOneWithoutPostPackageInput;
  sendingType: SendingType;
  totalCount: Scalars['Int'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type PostPackageCreateWithoutReceiversInput = {
  couponEntities?: InputMaybe<CouponEntityCreateNestedManyWithoutInPostPackageInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  emailTos?: InputMaybe<EmailToCreateNestedManyWithoutPostPackagesInput>;
  groupsRecv?: InputMaybe<GroupCreateNestedManyWithoutPackagesUsedInput>;
  id?: InputMaybe<Scalars['String']>;
  manualAddrs?: InputMaybe<ManualAddrCreateNestedManyWithoutPostPackagesInput>;
  order?: InputMaybe<OrderCreateNestedOneWithoutPostPackageInput>;
  packageType?: InputMaybe<PackageType>;
  posts?: InputMaybe<PostCreateNestedManyWithoutPackageInput>;
  sendDate: Scalars['DateTime'];
  sendPrivacy: PrivacyLevel;
  sender: UserCreateNestedOneWithoutPostPackageInput;
  sendingType: SendingType;
  totalCount: Scalars['Int'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type PostPackageCreateWithoutSenderInput = {
  couponEntities?: InputMaybe<CouponEntityCreateNestedManyWithoutInPostPackageInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  emailTos?: InputMaybe<EmailToCreateNestedManyWithoutPostPackagesInput>;
  groupsRecv?: InputMaybe<GroupCreateNestedManyWithoutPackagesUsedInput>;
  id?: InputMaybe<Scalars['String']>;
  manualAddrs?: InputMaybe<ManualAddrCreateNestedManyWithoutPostPackagesInput>;
  order?: InputMaybe<OrderCreateNestedOneWithoutPostPackageInput>;
  packageType?: InputMaybe<PackageType>;
  posts?: InputMaybe<PostCreateNestedManyWithoutPackageInput>;
  receivers?: InputMaybe<UserCreateNestedManyWithoutPackagesAsReceiverInput>;
  sendDate: Scalars['DateTime'];
  sendPrivacy: PrivacyLevel;
  sendingType: SendingType;
  totalCount: Scalars['Int'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type PostPackageGroupBy = {
  __typename?: 'PostPackageGroupBy';
  _avg?: Maybe<PostPackageAvgAggregate>;
  _count?: Maybe<PostPackageCountAggregate>;
  _max?: Maybe<PostPackageMaxAggregate>;
  _min?: Maybe<PostPackageMinAggregate>;
  _sum?: Maybe<PostPackageSumAggregate>;
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  packageType: PackageType;
  sendDate: Scalars['DateTime'];
  sendPrivacy: PrivacyLevel;
  senderId: Scalars['String'];
  sendingType: SendingType;
  totalCount: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
};

export type PostPackageListRelationFilter = {
  every?: InputMaybe<PostPackageWhereInput>;
  none?: InputMaybe<PostPackageWhereInput>;
  some?: InputMaybe<PostPackageWhereInput>;
};

export type PostPackageMaxAggregate = {
  __typename?: 'PostPackageMaxAggregate';
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  packageType?: Maybe<PackageType>;
  sendDate?: Maybe<Scalars['DateTime']>;
  sendPrivacy?: Maybe<PrivacyLevel>;
  senderId?: Maybe<Scalars['String']>;
  sendingType?: Maybe<SendingType>;
  totalCount?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PostPackageMaxOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  packageType?: InputMaybe<SortOrder>;
  sendDate?: InputMaybe<SortOrder>;
  sendPrivacy?: InputMaybe<SortOrder>;
  senderId?: InputMaybe<SortOrder>;
  sendingType?: InputMaybe<SortOrder>;
  totalCount?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type PostPackageMinAggregate = {
  __typename?: 'PostPackageMinAggregate';
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  packageType?: Maybe<PackageType>;
  sendDate?: Maybe<Scalars['DateTime']>;
  sendPrivacy?: Maybe<PrivacyLevel>;
  senderId?: Maybe<Scalars['String']>;
  sendingType?: Maybe<SendingType>;
  totalCount?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PostPackageMinOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  packageType?: InputMaybe<SortOrder>;
  sendDate?: InputMaybe<SortOrder>;
  sendPrivacy?: InputMaybe<SortOrder>;
  senderId?: InputMaybe<SortOrder>;
  sendingType?: InputMaybe<SortOrder>;
  totalCount?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type PostPackageOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type PostPackageOrderByWithAggregationInput = {
  _avg?: InputMaybe<PostPackageAvgOrderByAggregateInput>;
  _count?: InputMaybe<PostPackageCountOrderByAggregateInput>;
  _max?: InputMaybe<PostPackageMaxOrderByAggregateInput>;
  _min?: InputMaybe<PostPackageMinOrderByAggregateInput>;
  _sum?: InputMaybe<PostPackageSumOrderByAggregateInput>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  packageType?: InputMaybe<SortOrder>;
  sendDate?: InputMaybe<SortOrder>;
  sendPrivacy?: InputMaybe<SortOrder>;
  senderId?: InputMaybe<SortOrder>;
  sendingType?: InputMaybe<SortOrder>;
  totalCount?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type PostPackageOrderByWithRelationInput = {
  couponEntities?: InputMaybe<CouponEntityOrderByRelationAggregateInput>;
  createdAt?: InputMaybe<SortOrder>;
  emailTos?: InputMaybe<EmailToOrderByRelationAggregateInput>;
  groupsRecv?: InputMaybe<GroupOrderByRelationAggregateInput>;
  id?: InputMaybe<SortOrder>;
  manualAddrs?: InputMaybe<ManualAddrOrderByRelationAggregateInput>;
  order?: InputMaybe<OrderOrderByWithRelationInput>;
  packageType?: InputMaybe<SortOrder>;
  posts?: InputMaybe<PostOrderByRelationAggregateInput>;
  receivers?: InputMaybe<UserOrderByRelationAggregateInput>;
  sendDate?: InputMaybe<SortOrder>;
  sendPrivacy?: InputMaybe<SortOrder>;
  sender?: InputMaybe<UserOrderByWithRelationInput>;
  senderId?: InputMaybe<SortOrder>;
  sendingType?: InputMaybe<SortOrder>;
  totalCount?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type PostPackageRelationFilter = {
  is?: InputMaybe<PostPackageWhereInput>;
  isNot?: InputMaybe<PostPackageWhereInput>;
};

export enum PostPackageScalarFieldEnum {
  CreatedAt = 'createdAt',
  Id = 'id',
  PackageType = 'packageType',
  SendDate = 'sendDate',
  SendPrivacy = 'sendPrivacy',
  SenderId = 'senderId',
  SendingType = 'sendingType',
  TotalCount = 'totalCount',
  UpdatedAt = 'updatedAt'
}

export type PostPackageScalarWhereInput = {
  AND?: InputMaybe<Array<PostPackageScalarWhereInput>>;
  NOT?: InputMaybe<Array<PostPackageScalarWhereInput>>;
  OR?: InputMaybe<Array<PostPackageScalarWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  packageType?: InputMaybe<EnumPackageTypeFilter>;
  sendDate?: InputMaybe<DateTimeFilter>;
  sendPrivacy?: InputMaybe<EnumPrivacyLevelFilter>;
  senderId?: InputMaybe<StringFilter>;
  sendingType?: InputMaybe<EnumSendingTypeFilter>;
  totalCount?: InputMaybe<IntFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type PostPackageScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<PostPackageScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<PostPackageScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<PostPackageScalarWhereWithAggregatesInput>>;
  createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  id?: InputMaybe<StringWithAggregatesFilter>;
  packageType?: InputMaybe<EnumPackageTypeWithAggregatesFilter>;
  sendDate?: InputMaybe<DateTimeWithAggregatesFilter>;
  sendPrivacy?: InputMaybe<EnumPrivacyLevelWithAggregatesFilter>;
  senderId?: InputMaybe<StringWithAggregatesFilter>;
  sendingType?: InputMaybe<EnumSendingTypeWithAggregatesFilter>;
  totalCount?: InputMaybe<IntWithAggregatesFilter>;
  updatedAt?: InputMaybe<DateTimeWithAggregatesFilter>;
};

export type PostPackageSumAggregate = {
  __typename?: 'PostPackageSumAggregate';
  totalCount?: Maybe<Scalars['Int']>;
};

export type PostPackageSumOrderByAggregateInput = {
  totalCount?: InputMaybe<SortOrder>;
};

export type PostPackageUpdateInput = {
  couponEntities?: InputMaybe<CouponEntityUpdateManyWithoutInPostPackageNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  emailTos?: InputMaybe<EmailToUpdateManyWithoutPostPackagesNestedInput>;
  groupsRecv?: InputMaybe<GroupUpdateManyWithoutPackagesUsedNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  manualAddrs?: InputMaybe<ManualAddrUpdateManyWithoutPostPackagesNestedInput>;
  order?: InputMaybe<OrderUpdateOneWithoutPostPackageNestedInput>;
  packageType?: InputMaybe<EnumPackageTypeFieldUpdateOperationsInput>;
  posts?: InputMaybe<PostUpdateManyWithoutPackageNestedInput>;
  receivers?: InputMaybe<UserUpdateManyWithoutPackagesAsReceiverNestedInput>;
  sendDate?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  sendPrivacy?: InputMaybe<EnumPrivacyLevelFieldUpdateOperationsInput>;
  sender?: InputMaybe<UserUpdateOneRequiredWithoutPostPackageNestedInput>;
  sendingType?: InputMaybe<EnumSendingTypeFieldUpdateOperationsInput>;
  totalCount?: InputMaybe<IntFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type PostPackageUpdateManyMutationInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  packageType?: InputMaybe<EnumPackageTypeFieldUpdateOperationsInput>;
  sendDate?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  sendPrivacy?: InputMaybe<EnumPrivacyLevelFieldUpdateOperationsInput>;
  sendingType?: InputMaybe<EnumSendingTypeFieldUpdateOperationsInput>;
  totalCount?: InputMaybe<IntFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type PostPackageUpdateManyWithWhereWithoutEmailTosInput = {
  data: PostPackageUpdateManyMutationInput;
  where: PostPackageScalarWhereInput;
};

export type PostPackageUpdateManyWithWhereWithoutGroupsRecvInput = {
  data: PostPackageUpdateManyMutationInput;
  where: PostPackageScalarWhereInput;
};

export type PostPackageUpdateManyWithWhereWithoutManualAddrsInput = {
  data: PostPackageUpdateManyMutationInput;
  where: PostPackageScalarWhereInput;
};

export type PostPackageUpdateManyWithWhereWithoutReceiversInput = {
  data: PostPackageUpdateManyMutationInput;
  where: PostPackageScalarWhereInput;
};

export type PostPackageUpdateManyWithWhereWithoutSenderInput = {
  data: PostPackageUpdateManyMutationInput;
  where: PostPackageScalarWhereInput;
};

export type PostPackageUpdateManyWithoutEmailTosNestedInput = {
  connect?: InputMaybe<Array<PostPackageWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<PostPackageCreateOrConnectWithoutEmailTosInput>>;
  create?: InputMaybe<Array<PostPackageCreateWithoutEmailTosInput>>;
  delete?: InputMaybe<Array<PostPackageWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<PostPackageScalarWhereInput>>;
  disconnect?: InputMaybe<Array<PostPackageWhereUniqueInput>>;
  set?: InputMaybe<Array<PostPackageWhereUniqueInput>>;
  update?: InputMaybe<Array<PostPackageUpdateWithWhereUniqueWithoutEmailTosInput>>;
  updateMany?: InputMaybe<Array<PostPackageUpdateManyWithWhereWithoutEmailTosInput>>;
  upsert?: InputMaybe<Array<PostPackageUpsertWithWhereUniqueWithoutEmailTosInput>>;
};

export type PostPackageUpdateManyWithoutGroupsRecvNestedInput = {
  connect?: InputMaybe<Array<PostPackageWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<PostPackageCreateOrConnectWithoutGroupsRecvInput>>;
  create?: InputMaybe<Array<PostPackageCreateWithoutGroupsRecvInput>>;
  delete?: InputMaybe<Array<PostPackageWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<PostPackageScalarWhereInput>>;
  disconnect?: InputMaybe<Array<PostPackageWhereUniqueInput>>;
  set?: InputMaybe<Array<PostPackageWhereUniqueInput>>;
  update?: InputMaybe<Array<PostPackageUpdateWithWhereUniqueWithoutGroupsRecvInput>>;
  updateMany?: InputMaybe<Array<PostPackageUpdateManyWithWhereWithoutGroupsRecvInput>>;
  upsert?: InputMaybe<Array<PostPackageUpsertWithWhereUniqueWithoutGroupsRecvInput>>;
};

export type PostPackageUpdateManyWithoutManualAddrsNestedInput = {
  connect?: InputMaybe<Array<PostPackageWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<PostPackageCreateOrConnectWithoutManualAddrsInput>>;
  create?: InputMaybe<Array<PostPackageCreateWithoutManualAddrsInput>>;
  delete?: InputMaybe<Array<PostPackageWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<PostPackageScalarWhereInput>>;
  disconnect?: InputMaybe<Array<PostPackageWhereUniqueInput>>;
  set?: InputMaybe<Array<PostPackageWhereUniqueInput>>;
  update?: InputMaybe<Array<PostPackageUpdateWithWhereUniqueWithoutManualAddrsInput>>;
  updateMany?: InputMaybe<Array<PostPackageUpdateManyWithWhereWithoutManualAddrsInput>>;
  upsert?: InputMaybe<Array<PostPackageUpsertWithWhereUniqueWithoutManualAddrsInput>>;
};

export type PostPackageUpdateManyWithoutReceiversNestedInput = {
  connect?: InputMaybe<Array<PostPackageWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<PostPackageCreateOrConnectWithoutReceiversInput>>;
  create?: InputMaybe<Array<PostPackageCreateWithoutReceiversInput>>;
  delete?: InputMaybe<Array<PostPackageWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<PostPackageScalarWhereInput>>;
  disconnect?: InputMaybe<Array<PostPackageWhereUniqueInput>>;
  set?: InputMaybe<Array<PostPackageWhereUniqueInput>>;
  update?: InputMaybe<Array<PostPackageUpdateWithWhereUniqueWithoutReceiversInput>>;
  updateMany?: InputMaybe<Array<PostPackageUpdateManyWithWhereWithoutReceiversInput>>;
  upsert?: InputMaybe<Array<PostPackageUpsertWithWhereUniqueWithoutReceiversInput>>;
};

export type PostPackageUpdateManyWithoutSenderNestedInput = {
  connect?: InputMaybe<Array<PostPackageWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<PostPackageCreateOrConnectWithoutSenderInput>>;
  create?: InputMaybe<Array<PostPackageCreateWithoutSenderInput>>;
  createMany?: InputMaybe<PostPackageCreateManySenderInputEnvelope>;
  delete?: InputMaybe<Array<PostPackageWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<PostPackageScalarWhereInput>>;
  disconnect?: InputMaybe<Array<PostPackageWhereUniqueInput>>;
  set?: InputMaybe<Array<PostPackageWhereUniqueInput>>;
  update?: InputMaybe<Array<PostPackageUpdateWithWhereUniqueWithoutSenderInput>>;
  updateMany?: InputMaybe<Array<PostPackageUpdateManyWithWhereWithoutSenderInput>>;
  upsert?: InputMaybe<Array<PostPackageUpsertWithWhereUniqueWithoutSenderInput>>;
};

export type PostPackageUpdateOneRequiredWithoutOrderNestedInput = {
  connect?: InputMaybe<PostPackageWhereUniqueInput>;
  connectOrCreate?: InputMaybe<PostPackageCreateOrConnectWithoutOrderInput>;
  create?: InputMaybe<PostPackageCreateWithoutOrderInput>;
  update?: InputMaybe<PostPackageUpdateWithoutOrderInput>;
  upsert?: InputMaybe<PostPackageUpsertWithoutOrderInput>;
};

export type PostPackageUpdateOneWithoutCouponEntitiesNestedInput = {
  connect?: InputMaybe<PostPackageWhereUniqueInput>;
  connectOrCreate?: InputMaybe<PostPackageCreateOrConnectWithoutCouponEntitiesInput>;
  create?: InputMaybe<PostPackageCreateWithoutCouponEntitiesInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<PostPackageUpdateWithoutCouponEntitiesInput>;
  upsert?: InputMaybe<PostPackageUpsertWithoutCouponEntitiesInput>;
};

export type PostPackageUpdateOneWithoutPostsNestedInput = {
  connect?: InputMaybe<PostPackageWhereUniqueInput>;
  connectOrCreate?: InputMaybe<PostPackageCreateOrConnectWithoutPostsInput>;
  create?: InputMaybe<PostPackageCreateWithoutPostsInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<PostPackageUpdateWithoutPostsInput>;
  upsert?: InputMaybe<PostPackageUpsertWithoutPostsInput>;
};

export type PostPackageUpdateWithWhereUniqueWithoutEmailTosInput = {
  data: PostPackageUpdateWithoutEmailTosInput;
  where: PostPackageWhereUniqueInput;
};

export type PostPackageUpdateWithWhereUniqueWithoutGroupsRecvInput = {
  data: PostPackageUpdateWithoutGroupsRecvInput;
  where: PostPackageWhereUniqueInput;
};

export type PostPackageUpdateWithWhereUniqueWithoutManualAddrsInput = {
  data: PostPackageUpdateWithoutManualAddrsInput;
  where: PostPackageWhereUniqueInput;
};

export type PostPackageUpdateWithWhereUniqueWithoutReceiversInput = {
  data: PostPackageUpdateWithoutReceiversInput;
  where: PostPackageWhereUniqueInput;
};

export type PostPackageUpdateWithWhereUniqueWithoutSenderInput = {
  data: PostPackageUpdateWithoutSenderInput;
  where: PostPackageWhereUniqueInput;
};

export type PostPackageUpdateWithoutCouponEntitiesInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  emailTos?: InputMaybe<EmailToUpdateManyWithoutPostPackagesNestedInput>;
  groupsRecv?: InputMaybe<GroupUpdateManyWithoutPackagesUsedNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  manualAddrs?: InputMaybe<ManualAddrUpdateManyWithoutPostPackagesNestedInput>;
  order?: InputMaybe<OrderUpdateOneWithoutPostPackageNestedInput>;
  packageType?: InputMaybe<EnumPackageTypeFieldUpdateOperationsInput>;
  posts?: InputMaybe<PostUpdateManyWithoutPackageNestedInput>;
  receivers?: InputMaybe<UserUpdateManyWithoutPackagesAsReceiverNestedInput>;
  sendDate?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  sendPrivacy?: InputMaybe<EnumPrivacyLevelFieldUpdateOperationsInput>;
  sender?: InputMaybe<UserUpdateOneRequiredWithoutPostPackageNestedInput>;
  sendingType?: InputMaybe<EnumSendingTypeFieldUpdateOperationsInput>;
  totalCount?: InputMaybe<IntFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type PostPackageUpdateWithoutEmailTosInput = {
  couponEntities?: InputMaybe<CouponEntityUpdateManyWithoutInPostPackageNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  groupsRecv?: InputMaybe<GroupUpdateManyWithoutPackagesUsedNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  manualAddrs?: InputMaybe<ManualAddrUpdateManyWithoutPostPackagesNestedInput>;
  order?: InputMaybe<OrderUpdateOneWithoutPostPackageNestedInput>;
  packageType?: InputMaybe<EnumPackageTypeFieldUpdateOperationsInput>;
  posts?: InputMaybe<PostUpdateManyWithoutPackageNestedInput>;
  receivers?: InputMaybe<UserUpdateManyWithoutPackagesAsReceiverNestedInput>;
  sendDate?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  sendPrivacy?: InputMaybe<EnumPrivacyLevelFieldUpdateOperationsInput>;
  sender?: InputMaybe<UserUpdateOneRequiredWithoutPostPackageNestedInput>;
  sendingType?: InputMaybe<EnumSendingTypeFieldUpdateOperationsInput>;
  totalCount?: InputMaybe<IntFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type PostPackageUpdateWithoutGroupsRecvInput = {
  couponEntities?: InputMaybe<CouponEntityUpdateManyWithoutInPostPackageNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  emailTos?: InputMaybe<EmailToUpdateManyWithoutPostPackagesNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  manualAddrs?: InputMaybe<ManualAddrUpdateManyWithoutPostPackagesNestedInput>;
  order?: InputMaybe<OrderUpdateOneWithoutPostPackageNestedInput>;
  packageType?: InputMaybe<EnumPackageTypeFieldUpdateOperationsInput>;
  posts?: InputMaybe<PostUpdateManyWithoutPackageNestedInput>;
  receivers?: InputMaybe<UserUpdateManyWithoutPackagesAsReceiverNestedInput>;
  sendDate?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  sendPrivacy?: InputMaybe<EnumPrivacyLevelFieldUpdateOperationsInput>;
  sender?: InputMaybe<UserUpdateOneRequiredWithoutPostPackageNestedInput>;
  sendingType?: InputMaybe<EnumSendingTypeFieldUpdateOperationsInput>;
  totalCount?: InputMaybe<IntFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type PostPackageUpdateWithoutManualAddrsInput = {
  couponEntities?: InputMaybe<CouponEntityUpdateManyWithoutInPostPackageNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  emailTos?: InputMaybe<EmailToUpdateManyWithoutPostPackagesNestedInput>;
  groupsRecv?: InputMaybe<GroupUpdateManyWithoutPackagesUsedNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  order?: InputMaybe<OrderUpdateOneWithoutPostPackageNestedInput>;
  packageType?: InputMaybe<EnumPackageTypeFieldUpdateOperationsInput>;
  posts?: InputMaybe<PostUpdateManyWithoutPackageNestedInput>;
  receivers?: InputMaybe<UserUpdateManyWithoutPackagesAsReceiverNestedInput>;
  sendDate?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  sendPrivacy?: InputMaybe<EnumPrivacyLevelFieldUpdateOperationsInput>;
  sender?: InputMaybe<UserUpdateOneRequiredWithoutPostPackageNestedInput>;
  sendingType?: InputMaybe<EnumSendingTypeFieldUpdateOperationsInput>;
  totalCount?: InputMaybe<IntFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type PostPackageUpdateWithoutOrderInput = {
  couponEntities?: InputMaybe<CouponEntityUpdateManyWithoutInPostPackageNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  emailTos?: InputMaybe<EmailToUpdateManyWithoutPostPackagesNestedInput>;
  groupsRecv?: InputMaybe<GroupUpdateManyWithoutPackagesUsedNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  manualAddrs?: InputMaybe<ManualAddrUpdateManyWithoutPostPackagesNestedInput>;
  packageType?: InputMaybe<EnumPackageTypeFieldUpdateOperationsInput>;
  posts?: InputMaybe<PostUpdateManyWithoutPackageNestedInput>;
  receivers?: InputMaybe<UserUpdateManyWithoutPackagesAsReceiverNestedInput>;
  sendDate?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  sendPrivacy?: InputMaybe<EnumPrivacyLevelFieldUpdateOperationsInput>;
  sender?: InputMaybe<UserUpdateOneRequiredWithoutPostPackageNestedInput>;
  sendingType?: InputMaybe<EnumSendingTypeFieldUpdateOperationsInput>;
  totalCount?: InputMaybe<IntFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type PostPackageUpdateWithoutPostsInput = {
  couponEntities?: InputMaybe<CouponEntityUpdateManyWithoutInPostPackageNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  emailTos?: InputMaybe<EmailToUpdateManyWithoutPostPackagesNestedInput>;
  groupsRecv?: InputMaybe<GroupUpdateManyWithoutPackagesUsedNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  manualAddrs?: InputMaybe<ManualAddrUpdateManyWithoutPostPackagesNestedInput>;
  order?: InputMaybe<OrderUpdateOneWithoutPostPackageNestedInput>;
  packageType?: InputMaybe<EnumPackageTypeFieldUpdateOperationsInput>;
  receivers?: InputMaybe<UserUpdateManyWithoutPackagesAsReceiverNestedInput>;
  sendDate?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  sendPrivacy?: InputMaybe<EnumPrivacyLevelFieldUpdateOperationsInput>;
  sender?: InputMaybe<UserUpdateOneRequiredWithoutPostPackageNestedInput>;
  sendingType?: InputMaybe<EnumSendingTypeFieldUpdateOperationsInput>;
  totalCount?: InputMaybe<IntFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type PostPackageUpdateWithoutReceiversInput = {
  couponEntities?: InputMaybe<CouponEntityUpdateManyWithoutInPostPackageNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  emailTos?: InputMaybe<EmailToUpdateManyWithoutPostPackagesNestedInput>;
  groupsRecv?: InputMaybe<GroupUpdateManyWithoutPackagesUsedNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  manualAddrs?: InputMaybe<ManualAddrUpdateManyWithoutPostPackagesNestedInput>;
  order?: InputMaybe<OrderUpdateOneWithoutPostPackageNestedInput>;
  packageType?: InputMaybe<EnumPackageTypeFieldUpdateOperationsInput>;
  posts?: InputMaybe<PostUpdateManyWithoutPackageNestedInput>;
  sendDate?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  sendPrivacy?: InputMaybe<EnumPrivacyLevelFieldUpdateOperationsInput>;
  sender?: InputMaybe<UserUpdateOneRequiredWithoutPostPackageNestedInput>;
  sendingType?: InputMaybe<EnumSendingTypeFieldUpdateOperationsInput>;
  totalCount?: InputMaybe<IntFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type PostPackageUpdateWithoutSenderInput = {
  couponEntities?: InputMaybe<CouponEntityUpdateManyWithoutInPostPackageNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  emailTos?: InputMaybe<EmailToUpdateManyWithoutPostPackagesNestedInput>;
  groupsRecv?: InputMaybe<GroupUpdateManyWithoutPackagesUsedNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  manualAddrs?: InputMaybe<ManualAddrUpdateManyWithoutPostPackagesNestedInput>;
  order?: InputMaybe<OrderUpdateOneWithoutPostPackageNestedInput>;
  packageType?: InputMaybe<EnumPackageTypeFieldUpdateOperationsInput>;
  posts?: InputMaybe<PostUpdateManyWithoutPackageNestedInput>;
  receivers?: InputMaybe<UserUpdateManyWithoutPackagesAsReceiverNestedInput>;
  sendDate?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  sendPrivacy?: InputMaybe<EnumPrivacyLevelFieldUpdateOperationsInput>;
  sendingType?: InputMaybe<EnumSendingTypeFieldUpdateOperationsInput>;
  totalCount?: InputMaybe<IntFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type PostPackageUpsertWithWhereUniqueWithoutEmailTosInput = {
  create: PostPackageCreateWithoutEmailTosInput;
  update: PostPackageUpdateWithoutEmailTosInput;
  where: PostPackageWhereUniqueInput;
};

export type PostPackageUpsertWithWhereUniqueWithoutGroupsRecvInput = {
  create: PostPackageCreateWithoutGroupsRecvInput;
  update: PostPackageUpdateWithoutGroupsRecvInput;
  where: PostPackageWhereUniqueInput;
};

export type PostPackageUpsertWithWhereUniqueWithoutManualAddrsInput = {
  create: PostPackageCreateWithoutManualAddrsInput;
  update: PostPackageUpdateWithoutManualAddrsInput;
  where: PostPackageWhereUniqueInput;
};

export type PostPackageUpsertWithWhereUniqueWithoutReceiversInput = {
  create: PostPackageCreateWithoutReceiversInput;
  update: PostPackageUpdateWithoutReceiversInput;
  where: PostPackageWhereUniqueInput;
};

export type PostPackageUpsertWithWhereUniqueWithoutSenderInput = {
  create: PostPackageCreateWithoutSenderInput;
  update: PostPackageUpdateWithoutSenderInput;
  where: PostPackageWhereUniqueInput;
};

export type PostPackageUpsertWithoutCouponEntitiesInput = {
  create: PostPackageCreateWithoutCouponEntitiesInput;
  update: PostPackageUpdateWithoutCouponEntitiesInput;
};

export type PostPackageUpsertWithoutOrderInput = {
  create: PostPackageCreateWithoutOrderInput;
  update: PostPackageUpdateWithoutOrderInput;
};

export type PostPackageUpsertWithoutPostsInput = {
  create: PostPackageCreateWithoutPostsInput;
  update: PostPackageUpdateWithoutPostsInput;
};

export type PostPackageWhereInput = {
  AND?: InputMaybe<Array<PostPackageWhereInput>>;
  NOT?: InputMaybe<Array<PostPackageWhereInput>>;
  OR?: InputMaybe<Array<PostPackageWhereInput>>;
  couponEntities?: InputMaybe<CouponEntityListRelationFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  emailTos?: InputMaybe<EmailToListRelationFilter>;
  groupsRecv?: InputMaybe<GroupListRelationFilter>;
  id?: InputMaybe<StringFilter>;
  manualAddrs?: InputMaybe<ManualAddrListRelationFilter>;
  order?: InputMaybe<OrderRelationFilter>;
  packageType?: InputMaybe<EnumPackageTypeFilter>;
  posts?: InputMaybe<PostListRelationFilter>;
  receivers?: InputMaybe<UserListRelationFilter>;
  sendDate?: InputMaybe<DateTimeFilter>;
  sendPrivacy?: InputMaybe<EnumPrivacyLevelFilter>;
  sender?: InputMaybe<UserRelationFilter>;
  senderId?: InputMaybe<StringFilter>;
  sendingType?: InputMaybe<EnumSendingTypeFilter>;
  totalCount?: InputMaybe<IntFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type PostPackageWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
};

export type PostRelationFilter = {
  is?: InputMaybe<PostWhereInput>;
  isNot?: InputMaybe<PostWhereInput>;
};

export enum PostScalarFieldEnum {
  AudioUri = 'audioUri',
  BodyId = 'bodyId',
  CreatedAt = 'createdAt',
  Id = 'id',
  IsCheckin = 'isCheckin',
  Loc = 'loc',
  Message = 'message',
  PackageId = 'packageId',
  PaymentProductId = 'paymentProductId',
  Qrcode = 'qrcode',
  RearUri = 'rearUri',
  RearUri4Practical = 'rearUri4Practical',
  ReceiverId = 'receiverId',
  RecvPrivacy = 'recvPrivacy',
  ScenicSpotId = 'scenicSpotId',
  SendDate = 'sendDate',
  SendPrivacy = 'sendPrivacy',
  SendStatus = 'sendStatus',
  SenderId = 'senderId',
  SignatureId = 'signatureId',
  StampId = 'stampId',
  UpdatedAt = 'updatedAt',
  Valid = 'valid'
}

export type PostScalarWhereInput = {
  AND?: InputMaybe<Array<PostScalarWhereInput>>;
  NOT?: InputMaybe<Array<PostScalarWhereInput>>;
  OR?: InputMaybe<Array<PostScalarWhereInput>>;
  audioUri?: InputMaybe<StringNullableFilter>;
  bodyId?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  isCheckin?: InputMaybe<BoolFilter>;
  loc?: InputMaybe<JsonNullableFilter>;
  message?: InputMaybe<StringNullableFilter>;
  packageId?: InputMaybe<StringNullableFilter>;
  paymentProductId?: InputMaybe<StringNullableFilter>;
  qrcode?: InputMaybe<StringNullableFilter>;
  rearUri?: InputMaybe<StringFilter>;
  rearUri4Practical?: InputMaybe<StringNullableFilter>;
  receiverId?: InputMaybe<StringNullableFilter>;
  recvPrivacy?: InputMaybe<EnumPrivacyLevelNullableFilter>;
  scenicSpotId?: InputMaybe<StringNullableFilter>;
  sendDate?: InputMaybe<DateTimeFilter>;
  sendPrivacy?: InputMaybe<EnumPrivacyLevelFilter>;
  sendStatus?: InputMaybe<EnumPackageStatusFilter>;
  senderId?: InputMaybe<StringFilter>;
  signatureId?: InputMaybe<StringNullableFilter>;
  stampId?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  valid?: InputMaybe<BoolFilter>;
};

export type PostScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<PostScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<PostScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<PostScalarWhereWithAggregatesInput>>;
  audioUri?: InputMaybe<StringNullableWithAggregatesFilter>;
  bodyId?: InputMaybe<StringWithAggregatesFilter>;
  createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  id?: InputMaybe<StringWithAggregatesFilter>;
  isCheckin?: InputMaybe<BoolWithAggregatesFilter>;
  loc?: InputMaybe<JsonNullableWithAggregatesFilter>;
  message?: InputMaybe<StringNullableWithAggregatesFilter>;
  packageId?: InputMaybe<StringNullableWithAggregatesFilter>;
  paymentProductId?: InputMaybe<StringNullableWithAggregatesFilter>;
  qrcode?: InputMaybe<StringNullableWithAggregatesFilter>;
  rearUri?: InputMaybe<StringWithAggregatesFilter>;
  rearUri4Practical?: InputMaybe<StringNullableWithAggregatesFilter>;
  receiverId?: InputMaybe<StringNullableWithAggregatesFilter>;
  recvPrivacy?: InputMaybe<EnumPrivacyLevelNullableWithAggregatesFilter>;
  scenicSpotId?: InputMaybe<StringNullableWithAggregatesFilter>;
  sendDate?: InputMaybe<DateTimeWithAggregatesFilter>;
  sendPrivacy?: InputMaybe<EnumPrivacyLevelWithAggregatesFilter>;
  sendStatus?: InputMaybe<EnumPackageStatusWithAggregatesFilter>;
  senderId?: InputMaybe<StringWithAggregatesFilter>;
  signatureId?: InputMaybe<StringNullableWithAggregatesFilter>;
  stampId?: InputMaybe<StringWithAggregatesFilter>;
  updatedAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  valid?: InputMaybe<BoolWithAggregatesFilter>;
};

export type PostTag = {
  __typename?: 'PostTag';
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  post: Post;
  postId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  user: User;
  userId: Scalars['String'];
  x: Scalars['Int'];
  y: Scalars['Int'];
};

export type PostTagAvgAggregate = {
  __typename?: 'PostTagAvgAggregate';
  x?: Maybe<Scalars['Float']>;
  y?: Maybe<Scalars['Float']>;
};

export type PostTagAvgOrderByAggregateInput = {
  x?: InputMaybe<SortOrder>;
  y?: InputMaybe<SortOrder>;
};

export type PostTagCountAggregate = {
  __typename?: 'PostTagCountAggregate';
  _all: Scalars['Int'];
  createdAt: Scalars['Int'];
  id: Scalars['Int'];
  postId: Scalars['Int'];
  updatedAt: Scalars['Int'];
  userId: Scalars['Int'];
  x: Scalars['Int'];
  y: Scalars['Int'];
};

export type PostTagCountOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  postId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
  x?: InputMaybe<SortOrder>;
  y?: InputMaybe<SortOrder>;
};

export type PostTagCreateInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  post: PostCreateNestedOneWithoutTagsInput;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  user: UserCreateNestedOneWithoutTagsUsedInput;
  x: Scalars['Int'];
  y: Scalars['Int'];
};

export type PostTagCreateManyInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  postId: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userId: Scalars['String'];
  x: Scalars['Int'];
  y: Scalars['Int'];
};

export type PostTagCreateManyPostInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userId: Scalars['String'];
  x: Scalars['Int'];
  y: Scalars['Int'];
};

export type PostTagCreateManyPostInputEnvelope = {
  data: Array<PostTagCreateManyPostInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type PostTagCreateManyUserInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  postId: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  x: Scalars['Int'];
  y: Scalars['Int'];
};

export type PostTagCreateManyUserInputEnvelope = {
  data: Array<PostTagCreateManyUserInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type PostTagCreateNestedManyWithoutPostInput = {
  connect?: InputMaybe<Array<PostTagWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<PostTagCreateOrConnectWithoutPostInput>>;
  create?: InputMaybe<Array<PostTagCreateWithoutPostInput>>;
  createMany?: InputMaybe<PostTagCreateManyPostInputEnvelope>;
};

export type PostTagCreateNestedManyWithoutUserInput = {
  connect?: InputMaybe<Array<PostTagWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<PostTagCreateOrConnectWithoutUserInput>>;
  create?: InputMaybe<Array<PostTagCreateWithoutUserInput>>;
  createMany?: InputMaybe<PostTagCreateManyUserInputEnvelope>;
};

export type PostTagCreateOrConnectWithoutPostInput = {
  create: PostTagCreateWithoutPostInput;
  where: PostTagWhereUniqueInput;
};

export type PostTagCreateOrConnectWithoutUserInput = {
  create: PostTagCreateWithoutUserInput;
  where: PostTagWhereUniqueInput;
};

export type PostTagCreateWithoutPostInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  user: UserCreateNestedOneWithoutTagsUsedInput;
  x: Scalars['Int'];
  y: Scalars['Int'];
};

export type PostTagCreateWithoutUserInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  post: PostCreateNestedOneWithoutTagsInput;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  x: Scalars['Int'];
  y: Scalars['Int'];
};

export type PostTagGroupBy = {
  __typename?: 'PostTagGroupBy';
  _avg?: Maybe<PostTagAvgAggregate>;
  _count?: Maybe<PostTagCountAggregate>;
  _max?: Maybe<PostTagMaxAggregate>;
  _min?: Maybe<PostTagMinAggregate>;
  _sum?: Maybe<PostTagSumAggregate>;
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  postId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  userId: Scalars['String'];
  x: Scalars['Int'];
  y: Scalars['Int'];
};

export type PostTagListRelationFilter = {
  every?: InputMaybe<PostTagWhereInput>;
  none?: InputMaybe<PostTagWhereInput>;
  some?: InputMaybe<PostTagWhereInput>;
};

export type PostTagMaxAggregate = {
  __typename?: 'PostTagMaxAggregate';
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  postId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
  x?: Maybe<Scalars['Int']>;
  y?: Maybe<Scalars['Int']>;
};

export type PostTagMaxOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  postId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
  x?: InputMaybe<SortOrder>;
  y?: InputMaybe<SortOrder>;
};

export type PostTagMinAggregate = {
  __typename?: 'PostTagMinAggregate';
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  postId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
  x?: Maybe<Scalars['Int']>;
  y?: Maybe<Scalars['Int']>;
};

export type PostTagMinOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  postId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
  x?: InputMaybe<SortOrder>;
  y?: InputMaybe<SortOrder>;
};

export type PostTagOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type PostTagOrderByWithAggregationInput = {
  _avg?: InputMaybe<PostTagAvgOrderByAggregateInput>;
  _count?: InputMaybe<PostTagCountOrderByAggregateInput>;
  _max?: InputMaybe<PostTagMaxOrderByAggregateInput>;
  _min?: InputMaybe<PostTagMinOrderByAggregateInput>;
  _sum?: InputMaybe<PostTagSumOrderByAggregateInput>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  postId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
  x?: InputMaybe<SortOrder>;
  y?: InputMaybe<SortOrder>;
};

export type PostTagOrderByWithRelationInput = {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  post?: InputMaybe<PostOrderByWithRelationInput>;
  postId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  user?: InputMaybe<UserOrderByWithRelationInput>;
  userId?: InputMaybe<SortOrder>;
  x?: InputMaybe<SortOrder>;
  y?: InputMaybe<SortOrder>;
};

export enum PostTagScalarFieldEnum {
  CreatedAt = 'createdAt',
  Id = 'id',
  PostId = 'postId',
  UpdatedAt = 'updatedAt',
  UserId = 'userId',
  X = 'x',
  Y = 'y'
}

export type PostTagScalarWhereInput = {
  AND?: InputMaybe<Array<PostTagScalarWhereInput>>;
  NOT?: InputMaybe<Array<PostTagScalarWhereInput>>;
  OR?: InputMaybe<Array<PostTagScalarWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  postId?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  userId?: InputMaybe<StringFilter>;
  x?: InputMaybe<IntFilter>;
  y?: InputMaybe<IntFilter>;
};

export type PostTagScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<PostTagScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<PostTagScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<PostTagScalarWhereWithAggregatesInput>>;
  createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  id?: InputMaybe<StringWithAggregatesFilter>;
  postId?: InputMaybe<StringWithAggregatesFilter>;
  updatedAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  userId?: InputMaybe<StringWithAggregatesFilter>;
  x?: InputMaybe<IntWithAggregatesFilter>;
  y?: InputMaybe<IntWithAggregatesFilter>;
};

export type PostTagSumAggregate = {
  __typename?: 'PostTagSumAggregate';
  x?: Maybe<Scalars['Int']>;
  y?: Maybe<Scalars['Int']>;
};

export type PostTagSumOrderByAggregateInput = {
  x?: InputMaybe<SortOrder>;
  y?: InputMaybe<SortOrder>;
};

export type PostTagUpdateInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  post?: InputMaybe<PostUpdateOneRequiredWithoutTagsNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutTagsUsedNestedInput>;
  x?: InputMaybe<IntFieldUpdateOperationsInput>;
  y?: InputMaybe<IntFieldUpdateOperationsInput>;
};

export type PostTagUpdateManyMutationInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  x?: InputMaybe<IntFieldUpdateOperationsInput>;
  y?: InputMaybe<IntFieldUpdateOperationsInput>;
};

export type PostTagUpdateManyWithWhereWithoutPostInput = {
  data: PostTagUpdateManyMutationInput;
  where: PostTagScalarWhereInput;
};

export type PostTagUpdateManyWithWhereWithoutUserInput = {
  data: PostTagUpdateManyMutationInput;
  where: PostTagScalarWhereInput;
};

export type PostTagUpdateManyWithoutPostNestedInput = {
  connect?: InputMaybe<Array<PostTagWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<PostTagCreateOrConnectWithoutPostInput>>;
  create?: InputMaybe<Array<PostTagCreateWithoutPostInput>>;
  createMany?: InputMaybe<PostTagCreateManyPostInputEnvelope>;
  delete?: InputMaybe<Array<PostTagWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<PostTagScalarWhereInput>>;
  disconnect?: InputMaybe<Array<PostTagWhereUniqueInput>>;
  set?: InputMaybe<Array<PostTagWhereUniqueInput>>;
  update?: InputMaybe<Array<PostTagUpdateWithWhereUniqueWithoutPostInput>>;
  updateMany?: InputMaybe<Array<PostTagUpdateManyWithWhereWithoutPostInput>>;
  upsert?: InputMaybe<Array<PostTagUpsertWithWhereUniqueWithoutPostInput>>;
};

export type PostTagUpdateManyWithoutUserNestedInput = {
  connect?: InputMaybe<Array<PostTagWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<PostTagCreateOrConnectWithoutUserInput>>;
  create?: InputMaybe<Array<PostTagCreateWithoutUserInput>>;
  createMany?: InputMaybe<PostTagCreateManyUserInputEnvelope>;
  delete?: InputMaybe<Array<PostTagWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<PostTagScalarWhereInput>>;
  disconnect?: InputMaybe<Array<PostTagWhereUniqueInput>>;
  set?: InputMaybe<Array<PostTagWhereUniqueInput>>;
  update?: InputMaybe<Array<PostTagUpdateWithWhereUniqueWithoutUserInput>>;
  updateMany?: InputMaybe<Array<PostTagUpdateManyWithWhereWithoutUserInput>>;
  upsert?: InputMaybe<Array<PostTagUpsertWithWhereUniqueWithoutUserInput>>;
};

export type PostTagUpdateWithWhereUniqueWithoutPostInput = {
  data: PostTagUpdateWithoutPostInput;
  where: PostTagWhereUniqueInput;
};

export type PostTagUpdateWithWhereUniqueWithoutUserInput = {
  data: PostTagUpdateWithoutUserInput;
  where: PostTagWhereUniqueInput;
};

export type PostTagUpdateWithoutPostInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutTagsUsedNestedInput>;
  x?: InputMaybe<IntFieldUpdateOperationsInput>;
  y?: InputMaybe<IntFieldUpdateOperationsInput>;
};

export type PostTagUpdateWithoutUserInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  post?: InputMaybe<PostUpdateOneRequiredWithoutTagsNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  x?: InputMaybe<IntFieldUpdateOperationsInput>;
  y?: InputMaybe<IntFieldUpdateOperationsInput>;
};

export type PostTagUpsertWithWhereUniqueWithoutPostInput = {
  create: PostTagCreateWithoutPostInput;
  update: PostTagUpdateWithoutPostInput;
  where: PostTagWhereUniqueInput;
};

export type PostTagUpsertWithWhereUniqueWithoutUserInput = {
  create: PostTagCreateWithoutUserInput;
  update: PostTagUpdateWithoutUserInput;
  where: PostTagWhereUniqueInput;
};

export type PostTagWhereInput = {
  AND?: InputMaybe<Array<PostTagWhereInput>>;
  NOT?: InputMaybe<Array<PostTagWhereInput>>;
  OR?: InputMaybe<Array<PostTagWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  post?: InputMaybe<PostRelationFilter>;
  postId?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  user?: InputMaybe<UserRelationFilter>;
  userId?: InputMaybe<StringFilter>;
  x?: InputMaybe<IntFilter>;
  y?: InputMaybe<IntFilter>;
};

export type PostTagWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
};

export type PostUpdateInput = {
  audioUri?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  benefitMemoToOrder?: InputMaybe<BenefitMemoToOrderUpdateManyWithoutPostNestedInput>;
  body?: InputMaybe<CardUpdateOneRequiredWithoutPostsUsedNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  detail?: InputMaybe<OrderDetailUpdateOneWithoutPostNestedInput>;
  histories?: InputMaybe<PostHistoryUpdateManyWithoutPostNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  inValidReasonHistories?: InputMaybe<InValidReasonUpdateManyWithoutPostNestedInput>;
  isCheckin?: InputMaybe<BoolFieldUpdateOperationsInput>;
  likeUsers?: InputMaybe<UserUpdateManyWithoutLikePostsNestedInput>;
  loc?: InputMaybe<Scalars['JSON']>;
  message?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  package?: InputMaybe<PostPackageUpdateOneWithoutPostsNestedInput>;
  paymentProduct?: InputMaybe<ProductUpdateOneWithoutPostsNestedInput>;
  qrcode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  rearUri?: InputMaybe<StringFieldUpdateOperationsInput>;
  rearUri4Practical?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  receiver?: InputMaybe<UserUpdateOneWithoutPostsInNestedInput>;
  recvPrivacy?: InputMaybe<NullableEnumPrivacyLevelFieldUpdateOperationsInput>;
  reports?: InputMaybe<ReportUpdateManyWithoutPostNestedInput>;
  scenicSpot?: InputMaybe<ScenicSpotUpdateOneWithoutPostsNestedInput>;
  sendDate?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  sendPrivacy?: InputMaybe<EnumPrivacyLevelFieldUpdateOperationsInput>;
  sendStatus?: InputMaybe<EnumPackageStatusFieldUpdateOperationsInput>;
  sender?: InputMaybe<UserUpdateOneRequiredWithoutPostsOutNestedInput>;
  signature?: InputMaybe<SignatureUpdateOneWithoutPostsUsedNestedInput>;
  stamp?: InputMaybe<StampUpdateOneRequiredWithoutPostsUsedNestedInput>;
  tags?: InputMaybe<PostTagUpdateManyWithoutPostNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  valid?: InputMaybe<BoolFieldUpdateOperationsInput>;
};

export type PostUpdateManyMutationInput = {
  audioUri?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isCheckin?: InputMaybe<BoolFieldUpdateOperationsInput>;
  loc?: InputMaybe<Scalars['JSON']>;
  message?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  qrcode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  rearUri?: InputMaybe<StringFieldUpdateOperationsInput>;
  rearUri4Practical?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  recvPrivacy?: InputMaybe<NullableEnumPrivacyLevelFieldUpdateOperationsInput>;
  sendDate?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  sendPrivacy?: InputMaybe<EnumPrivacyLevelFieldUpdateOperationsInput>;
  sendStatus?: InputMaybe<EnumPackageStatusFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  valid?: InputMaybe<BoolFieldUpdateOperationsInput>;
};

export type PostUpdateManyWithWhereWithoutBodyInput = {
  data: PostUpdateManyMutationInput;
  where: PostScalarWhereInput;
};

export type PostUpdateManyWithWhereWithoutLikeUsersInput = {
  data: PostUpdateManyMutationInput;
  where: PostScalarWhereInput;
};

export type PostUpdateManyWithWhereWithoutPackageInput = {
  data: PostUpdateManyMutationInput;
  where: PostScalarWhereInput;
};

export type PostUpdateManyWithWhereWithoutPaymentProductInput = {
  data: PostUpdateManyMutationInput;
  where: PostScalarWhereInput;
};

export type PostUpdateManyWithWhereWithoutReceiverInput = {
  data: PostUpdateManyMutationInput;
  where: PostScalarWhereInput;
};

export type PostUpdateManyWithWhereWithoutScenicSpotInput = {
  data: PostUpdateManyMutationInput;
  where: PostScalarWhereInput;
};

export type PostUpdateManyWithWhereWithoutSenderInput = {
  data: PostUpdateManyMutationInput;
  where: PostScalarWhereInput;
};

export type PostUpdateManyWithWhereWithoutSignatureInput = {
  data: PostUpdateManyMutationInput;
  where: PostScalarWhereInput;
};

export type PostUpdateManyWithWhereWithoutStampInput = {
  data: PostUpdateManyMutationInput;
  where: PostScalarWhereInput;
};

export type PostUpdateManyWithoutBodyNestedInput = {
  connect?: InputMaybe<Array<PostWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<PostCreateOrConnectWithoutBodyInput>>;
  create?: InputMaybe<Array<PostCreateWithoutBodyInput>>;
  createMany?: InputMaybe<PostCreateManyBodyInputEnvelope>;
  delete?: InputMaybe<Array<PostWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<PostScalarWhereInput>>;
  disconnect?: InputMaybe<Array<PostWhereUniqueInput>>;
  set?: InputMaybe<Array<PostWhereUniqueInput>>;
  update?: InputMaybe<Array<PostUpdateWithWhereUniqueWithoutBodyInput>>;
  updateMany?: InputMaybe<Array<PostUpdateManyWithWhereWithoutBodyInput>>;
  upsert?: InputMaybe<Array<PostUpsertWithWhereUniqueWithoutBodyInput>>;
};

export type PostUpdateManyWithoutLikeUsersNestedInput = {
  connect?: InputMaybe<Array<PostWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<PostCreateOrConnectWithoutLikeUsersInput>>;
  create?: InputMaybe<Array<PostCreateWithoutLikeUsersInput>>;
  delete?: InputMaybe<Array<PostWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<PostScalarWhereInput>>;
  disconnect?: InputMaybe<Array<PostWhereUniqueInput>>;
  set?: InputMaybe<Array<PostWhereUniqueInput>>;
  update?: InputMaybe<Array<PostUpdateWithWhereUniqueWithoutLikeUsersInput>>;
  updateMany?: InputMaybe<Array<PostUpdateManyWithWhereWithoutLikeUsersInput>>;
  upsert?: InputMaybe<Array<PostUpsertWithWhereUniqueWithoutLikeUsersInput>>;
};

export type PostUpdateManyWithoutPackageNestedInput = {
  connect?: InputMaybe<Array<PostWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<PostCreateOrConnectWithoutPackageInput>>;
  create?: InputMaybe<Array<PostCreateWithoutPackageInput>>;
  createMany?: InputMaybe<PostCreateManyPackageInputEnvelope>;
  delete?: InputMaybe<Array<PostWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<PostScalarWhereInput>>;
  disconnect?: InputMaybe<Array<PostWhereUniqueInput>>;
  set?: InputMaybe<Array<PostWhereUniqueInput>>;
  update?: InputMaybe<Array<PostUpdateWithWhereUniqueWithoutPackageInput>>;
  updateMany?: InputMaybe<Array<PostUpdateManyWithWhereWithoutPackageInput>>;
  upsert?: InputMaybe<Array<PostUpsertWithWhereUniqueWithoutPackageInput>>;
};

export type PostUpdateManyWithoutPaymentProductNestedInput = {
  connect?: InputMaybe<Array<PostWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<PostCreateOrConnectWithoutPaymentProductInput>>;
  create?: InputMaybe<Array<PostCreateWithoutPaymentProductInput>>;
  createMany?: InputMaybe<PostCreateManyPaymentProductInputEnvelope>;
  delete?: InputMaybe<Array<PostWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<PostScalarWhereInput>>;
  disconnect?: InputMaybe<Array<PostWhereUniqueInput>>;
  set?: InputMaybe<Array<PostWhereUniqueInput>>;
  update?: InputMaybe<Array<PostUpdateWithWhereUniqueWithoutPaymentProductInput>>;
  updateMany?: InputMaybe<Array<PostUpdateManyWithWhereWithoutPaymentProductInput>>;
  upsert?: InputMaybe<Array<PostUpsertWithWhereUniqueWithoutPaymentProductInput>>;
};

export type PostUpdateManyWithoutReceiverNestedInput = {
  connect?: InputMaybe<Array<PostWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<PostCreateOrConnectWithoutReceiverInput>>;
  create?: InputMaybe<Array<PostCreateWithoutReceiverInput>>;
  createMany?: InputMaybe<PostCreateManyReceiverInputEnvelope>;
  delete?: InputMaybe<Array<PostWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<PostScalarWhereInput>>;
  disconnect?: InputMaybe<Array<PostWhereUniqueInput>>;
  set?: InputMaybe<Array<PostWhereUniqueInput>>;
  update?: InputMaybe<Array<PostUpdateWithWhereUniqueWithoutReceiverInput>>;
  updateMany?: InputMaybe<Array<PostUpdateManyWithWhereWithoutReceiverInput>>;
  upsert?: InputMaybe<Array<PostUpsertWithWhereUniqueWithoutReceiverInput>>;
};

export type PostUpdateManyWithoutScenicSpotNestedInput = {
  connect?: InputMaybe<Array<PostWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<PostCreateOrConnectWithoutScenicSpotInput>>;
  create?: InputMaybe<Array<PostCreateWithoutScenicSpotInput>>;
  createMany?: InputMaybe<PostCreateManyScenicSpotInputEnvelope>;
  delete?: InputMaybe<Array<PostWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<PostScalarWhereInput>>;
  disconnect?: InputMaybe<Array<PostWhereUniqueInput>>;
  set?: InputMaybe<Array<PostWhereUniqueInput>>;
  update?: InputMaybe<Array<PostUpdateWithWhereUniqueWithoutScenicSpotInput>>;
  updateMany?: InputMaybe<Array<PostUpdateManyWithWhereWithoutScenicSpotInput>>;
  upsert?: InputMaybe<Array<PostUpsertWithWhereUniqueWithoutScenicSpotInput>>;
};

export type PostUpdateManyWithoutSenderNestedInput = {
  connect?: InputMaybe<Array<PostWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<PostCreateOrConnectWithoutSenderInput>>;
  create?: InputMaybe<Array<PostCreateWithoutSenderInput>>;
  createMany?: InputMaybe<PostCreateManySenderInputEnvelope>;
  delete?: InputMaybe<Array<PostWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<PostScalarWhereInput>>;
  disconnect?: InputMaybe<Array<PostWhereUniqueInput>>;
  set?: InputMaybe<Array<PostWhereUniqueInput>>;
  update?: InputMaybe<Array<PostUpdateWithWhereUniqueWithoutSenderInput>>;
  updateMany?: InputMaybe<Array<PostUpdateManyWithWhereWithoutSenderInput>>;
  upsert?: InputMaybe<Array<PostUpsertWithWhereUniqueWithoutSenderInput>>;
};

export type PostUpdateManyWithoutSignatureNestedInput = {
  connect?: InputMaybe<Array<PostWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<PostCreateOrConnectWithoutSignatureInput>>;
  create?: InputMaybe<Array<PostCreateWithoutSignatureInput>>;
  createMany?: InputMaybe<PostCreateManySignatureInputEnvelope>;
  delete?: InputMaybe<Array<PostWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<PostScalarWhereInput>>;
  disconnect?: InputMaybe<Array<PostWhereUniqueInput>>;
  set?: InputMaybe<Array<PostWhereUniqueInput>>;
  update?: InputMaybe<Array<PostUpdateWithWhereUniqueWithoutSignatureInput>>;
  updateMany?: InputMaybe<Array<PostUpdateManyWithWhereWithoutSignatureInput>>;
  upsert?: InputMaybe<Array<PostUpsertWithWhereUniqueWithoutSignatureInput>>;
};

export type PostUpdateManyWithoutStampNestedInput = {
  connect?: InputMaybe<Array<PostWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<PostCreateOrConnectWithoutStampInput>>;
  create?: InputMaybe<Array<PostCreateWithoutStampInput>>;
  createMany?: InputMaybe<PostCreateManyStampInputEnvelope>;
  delete?: InputMaybe<Array<PostWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<PostScalarWhereInput>>;
  disconnect?: InputMaybe<Array<PostWhereUniqueInput>>;
  set?: InputMaybe<Array<PostWhereUniqueInput>>;
  update?: InputMaybe<Array<PostUpdateWithWhereUniqueWithoutStampInput>>;
  updateMany?: InputMaybe<Array<PostUpdateManyWithWhereWithoutStampInput>>;
  upsert?: InputMaybe<Array<PostUpsertWithWhereUniqueWithoutStampInput>>;
};

export type PostUpdateOneRequiredWithoutBenefitMemoToOrderNestedInput = {
  connect?: InputMaybe<PostWhereUniqueInput>;
  connectOrCreate?: InputMaybe<PostCreateOrConnectWithoutBenefitMemoToOrderInput>;
  create?: InputMaybe<PostCreateWithoutBenefitMemoToOrderInput>;
  update?: InputMaybe<PostUpdateWithoutBenefitMemoToOrderInput>;
  upsert?: InputMaybe<PostUpsertWithoutBenefitMemoToOrderInput>;
};

export type PostUpdateOneRequiredWithoutDetailNestedInput = {
  connect?: InputMaybe<PostWhereUniqueInput>;
  connectOrCreate?: InputMaybe<PostCreateOrConnectWithoutDetailInput>;
  create?: InputMaybe<PostCreateWithoutDetailInput>;
  update?: InputMaybe<PostUpdateWithoutDetailInput>;
  upsert?: InputMaybe<PostUpsertWithoutDetailInput>;
};

export type PostUpdateOneRequiredWithoutHistoriesNestedInput = {
  connect?: InputMaybe<PostWhereUniqueInput>;
  connectOrCreate?: InputMaybe<PostCreateOrConnectWithoutHistoriesInput>;
  create?: InputMaybe<PostCreateWithoutHistoriesInput>;
  update?: InputMaybe<PostUpdateWithoutHistoriesInput>;
  upsert?: InputMaybe<PostUpsertWithoutHistoriesInput>;
};

export type PostUpdateOneRequiredWithoutReportsNestedInput = {
  connect?: InputMaybe<PostWhereUniqueInput>;
  connectOrCreate?: InputMaybe<PostCreateOrConnectWithoutReportsInput>;
  create?: InputMaybe<PostCreateWithoutReportsInput>;
  update?: InputMaybe<PostUpdateWithoutReportsInput>;
  upsert?: InputMaybe<PostUpsertWithoutReportsInput>;
};

export type PostUpdateOneRequiredWithoutTagsNestedInput = {
  connect?: InputMaybe<PostWhereUniqueInput>;
  connectOrCreate?: InputMaybe<PostCreateOrConnectWithoutTagsInput>;
  create?: InputMaybe<PostCreateWithoutTagsInput>;
  update?: InputMaybe<PostUpdateWithoutTagsInput>;
  upsert?: InputMaybe<PostUpsertWithoutTagsInput>;
};

export type PostUpdateOneWithoutInValidReasonHistoriesNestedInput = {
  connect?: InputMaybe<PostWhereUniqueInput>;
  connectOrCreate?: InputMaybe<PostCreateOrConnectWithoutInValidReasonHistoriesInput>;
  create?: InputMaybe<PostCreateWithoutInValidReasonHistoriesInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<PostUpdateWithoutInValidReasonHistoriesInput>;
  upsert?: InputMaybe<PostUpsertWithoutInValidReasonHistoriesInput>;
};

export type PostUpdateWithWhereUniqueWithoutBodyInput = {
  data: PostUpdateWithoutBodyInput;
  where: PostWhereUniqueInput;
};

export type PostUpdateWithWhereUniqueWithoutLikeUsersInput = {
  data: PostUpdateWithoutLikeUsersInput;
  where: PostWhereUniqueInput;
};

export type PostUpdateWithWhereUniqueWithoutPackageInput = {
  data: PostUpdateWithoutPackageInput;
  where: PostWhereUniqueInput;
};

export type PostUpdateWithWhereUniqueWithoutPaymentProductInput = {
  data: PostUpdateWithoutPaymentProductInput;
  where: PostWhereUniqueInput;
};

export type PostUpdateWithWhereUniqueWithoutReceiverInput = {
  data: PostUpdateWithoutReceiverInput;
  where: PostWhereUniqueInput;
};

export type PostUpdateWithWhereUniqueWithoutScenicSpotInput = {
  data: PostUpdateWithoutScenicSpotInput;
  where: PostWhereUniqueInput;
};

export type PostUpdateWithWhereUniqueWithoutSenderInput = {
  data: PostUpdateWithoutSenderInput;
  where: PostWhereUniqueInput;
};

export type PostUpdateWithWhereUniqueWithoutSignatureInput = {
  data: PostUpdateWithoutSignatureInput;
  where: PostWhereUniqueInput;
};

export type PostUpdateWithWhereUniqueWithoutStampInput = {
  data: PostUpdateWithoutStampInput;
  where: PostWhereUniqueInput;
};

export type PostUpdateWithoutBenefitMemoToOrderInput = {
  audioUri?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  body?: InputMaybe<CardUpdateOneRequiredWithoutPostsUsedNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  detail?: InputMaybe<OrderDetailUpdateOneWithoutPostNestedInput>;
  histories?: InputMaybe<PostHistoryUpdateManyWithoutPostNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  inValidReasonHistories?: InputMaybe<InValidReasonUpdateManyWithoutPostNestedInput>;
  isCheckin?: InputMaybe<BoolFieldUpdateOperationsInput>;
  likeUsers?: InputMaybe<UserUpdateManyWithoutLikePostsNestedInput>;
  loc?: InputMaybe<Scalars['JSON']>;
  message?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  package?: InputMaybe<PostPackageUpdateOneWithoutPostsNestedInput>;
  paymentProduct?: InputMaybe<ProductUpdateOneWithoutPostsNestedInput>;
  qrcode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  rearUri?: InputMaybe<StringFieldUpdateOperationsInput>;
  rearUri4Practical?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  receiver?: InputMaybe<UserUpdateOneWithoutPostsInNestedInput>;
  recvPrivacy?: InputMaybe<NullableEnumPrivacyLevelFieldUpdateOperationsInput>;
  reports?: InputMaybe<ReportUpdateManyWithoutPostNestedInput>;
  scenicSpot?: InputMaybe<ScenicSpotUpdateOneWithoutPostsNestedInput>;
  sendDate?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  sendPrivacy?: InputMaybe<EnumPrivacyLevelFieldUpdateOperationsInput>;
  sendStatus?: InputMaybe<EnumPackageStatusFieldUpdateOperationsInput>;
  sender?: InputMaybe<UserUpdateOneRequiredWithoutPostsOutNestedInput>;
  signature?: InputMaybe<SignatureUpdateOneWithoutPostsUsedNestedInput>;
  stamp?: InputMaybe<StampUpdateOneRequiredWithoutPostsUsedNestedInput>;
  tags?: InputMaybe<PostTagUpdateManyWithoutPostNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  valid?: InputMaybe<BoolFieldUpdateOperationsInput>;
};

export type PostUpdateWithoutBodyInput = {
  audioUri?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  benefitMemoToOrder?: InputMaybe<BenefitMemoToOrderUpdateManyWithoutPostNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  detail?: InputMaybe<OrderDetailUpdateOneWithoutPostNestedInput>;
  histories?: InputMaybe<PostHistoryUpdateManyWithoutPostNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  inValidReasonHistories?: InputMaybe<InValidReasonUpdateManyWithoutPostNestedInput>;
  isCheckin?: InputMaybe<BoolFieldUpdateOperationsInput>;
  likeUsers?: InputMaybe<UserUpdateManyWithoutLikePostsNestedInput>;
  loc?: InputMaybe<Scalars['JSON']>;
  message?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  package?: InputMaybe<PostPackageUpdateOneWithoutPostsNestedInput>;
  paymentProduct?: InputMaybe<ProductUpdateOneWithoutPostsNestedInput>;
  qrcode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  rearUri?: InputMaybe<StringFieldUpdateOperationsInput>;
  rearUri4Practical?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  receiver?: InputMaybe<UserUpdateOneWithoutPostsInNestedInput>;
  recvPrivacy?: InputMaybe<NullableEnumPrivacyLevelFieldUpdateOperationsInput>;
  reports?: InputMaybe<ReportUpdateManyWithoutPostNestedInput>;
  scenicSpot?: InputMaybe<ScenicSpotUpdateOneWithoutPostsNestedInput>;
  sendDate?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  sendPrivacy?: InputMaybe<EnumPrivacyLevelFieldUpdateOperationsInput>;
  sendStatus?: InputMaybe<EnumPackageStatusFieldUpdateOperationsInput>;
  sender?: InputMaybe<UserUpdateOneRequiredWithoutPostsOutNestedInput>;
  signature?: InputMaybe<SignatureUpdateOneWithoutPostsUsedNestedInput>;
  stamp?: InputMaybe<StampUpdateOneRequiredWithoutPostsUsedNestedInput>;
  tags?: InputMaybe<PostTagUpdateManyWithoutPostNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  valid?: InputMaybe<BoolFieldUpdateOperationsInput>;
};

export type PostUpdateWithoutDetailInput = {
  audioUri?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  benefitMemoToOrder?: InputMaybe<BenefitMemoToOrderUpdateManyWithoutPostNestedInput>;
  body?: InputMaybe<CardUpdateOneRequiredWithoutPostsUsedNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  histories?: InputMaybe<PostHistoryUpdateManyWithoutPostNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  inValidReasonHistories?: InputMaybe<InValidReasonUpdateManyWithoutPostNestedInput>;
  isCheckin?: InputMaybe<BoolFieldUpdateOperationsInput>;
  likeUsers?: InputMaybe<UserUpdateManyWithoutLikePostsNestedInput>;
  loc?: InputMaybe<Scalars['JSON']>;
  message?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  package?: InputMaybe<PostPackageUpdateOneWithoutPostsNestedInput>;
  paymentProduct?: InputMaybe<ProductUpdateOneWithoutPostsNestedInput>;
  qrcode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  rearUri?: InputMaybe<StringFieldUpdateOperationsInput>;
  rearUri4Practical?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  receiver?: InputMaybe<UserUpdateOneWithoutPostsInNestedInput>;
  recvPrivacy?: InputMaybe<NullableEnumPrivacyLevelFieldUpdateOperationsInput>;
  reports?: InputMaybe<ReportUpdateManyWithoutPostNestedInput>;
  scenicSpot?: InputMaybe<ScenicSpotUpdateOneWithoutPostsNestedInput>;
  sendDate?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  sendPrivacy?: InputMaybe<EnumPrivacyLevelFieldUpdateOperationsInput>;
  sendStatus?: InputMaybe<EnumPackageStatusFieldUpdateOperationsInput>;
  sender?: InputMaybe<UserUpdateOneRequiredWithoutPostsOutNestedInput>;
  signature?: InputMaybe<SignatureUpdateOneWithoutPostsUsedNestedInput>;
  stamp?: InputMaybe<StampUpdateOneRequiredWithoutPostsUsedNestedInput>;
  tags?: InputMaybe<PostTagUpdateManyWithoutPostNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  valid?: InputMaybe<BoolFieldUpdateOperationsInput>;
};

export type PostUpdateWithoutHistoriesInput = {
  audioUri?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  benefitMemoToOrder?: InputMaybe<BenefitMemoToOrderUpdateManyWithoutPostNestedInput>;
  body?: InputMaybe<CardUpdateOneRequiredWithoutPostsUsedNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  detail?: InputMaybe<OrderDetailUpdateOneWithoutPostNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  inValidReasonHistories?: InputMaybe<InValidReasonUpdateManyWithoutPostNestedInput>;
  isCheckin?: InputMaybe<BoolFieldUpdateOperationsInput>;
  likeUsers?: InputMaybe<UserUpdateManyWithoutLikePostsNestedInput>;
  loc?: InputMaybe<Scalars['JSON']>;
  message?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  package?: InputMaybe<PostPackageUpdateOneWithoutPostsNestedInput>;
  paymentProduct?: InputMaybe<ProductUpdateOneWithoutPostsNestedInput>;
  qrcode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  rearUri?: InputMaybe<StringFieldUpdateOperationsInput>;
  rearUri4Practical?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  receiver?: InputMaybe<UserUpdateOneWithoutPostsInNestedInput>;
  recvPrivacy?: InputMaybe<NullableEnumPrivacyLevelFieldUpdateOperationsInput>;
  reports?: InputMaybe<ReportUpdateManyWithoutPostNestedInput>;
  scenicSpot?: InputMaybe<ScenicSpotUpdateOneWithoutPostsNestedInput>;
  sendDate?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  sendPrivacy?: InputMaybe<EnumPrivacyLevelFieldUpdateOperationsInput>;
  sendStatus?: InputMaybe<EnumPackageStatusFieldUpdateOperationsInput>;
  sender?: InputMaybe<UserUpdateOneRequiredWithoutPostsOutNestedInput>;
  signature?: InputMaybe<SignatureUpdateOneWithoutPostsUsedNestedInput>;
  stamp?: InputMaybe<StampUpdateOneRequiredWithoutPostsUsedNestedInput>;
  tags?: InputMaybe<PostTagUpdateManyWithoutPostNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  valid?: InputMaybe<BoolFieldUpdateOperationsInput>;
};

export type PostUpdateWithoutInValidReasonHistoriesInput = {
  audioUri?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  benefitMemoToOrder?: InputMaybe<BenefitMemoToOrderUpdateManyWithoutPostNestedInput>;
  body?: InputMaybe<CardUpdateOneRequiredWithoutPostsUsedNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  detail?: InputMaybe<OrderDetailUpdateOneWithoutPostNestedInput>;
  histories?: InputMaybe<PostHistoryUpdateManyWithoutPostNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isCheckin?: InputMaybe<BoolFieldUpdateOperationsInput>;
  likeUsers?: InputMaybe<UserUpdateManyWithoutLikePostsNestedInput>;
  loc?: InputMaybe<Scalars['JSON']>;
  message?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  package?: InputMaybe<PostPackageUpdateOneWithoutPostsNestedInput>;
  paymentProduct?: InputMaybe<ProductUpdateOneWithoutPostsNestedInput>;
  qrcode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  rearUri?: InputMaybe<StringFieldUpdateOperationsInput>;
  rearUri4Practical?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  receiver?: InputMaybe<UserUpdateOneWithoutPostsInNestedInput>;
  recvPrivacy?: InputMaybe<NullableEnumPrivacyLevelFieldUpdateOperationsInput>;
  reports?: InputMaybe<ReportUpdateManyWithoutPostNestedInput>;
  scenicSpot?: InputMaybe<ScenicSpotUpdateOneWithoutPostsNestedInput>;
  sendDate?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  sendPrivacy?: InputMaybe<EnumPrivacyLevelFieldUpdateOperationsInput>;
  sendStatus?: InputMaybe<EnumPackageStatusFieldUpdateOperationsInput>;
  sender?: InputMaybe<UserUpdateOneRequiredWithoutPostsOutNestedInput>;
  signature?: InputMaybe<SignatureUpdateOneWithoutPostsUsedNestedInput>;
  stamp?: InputMaybe<StampUpdateOneRequiredWithoutPostsUsedNestedInput>;
  tags?: InputMaybe<PostTagUpdateManyWithoutPostNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  valid?: InputMaybe<BoolFieldUpdateOperationsInput>;
};

export type PostUpdateWithoutLikeUsersInput = {
  audioUri?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  benefitMemoToOrder?: InputMaybe<BenefitMemoToOrderUpdateManyWithoutPostNestedInput>;
  body?: InputMaybe<CardUpdateOneRequiredWithoutPostsUsedNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  detail?: InputMaybe<OrderDetailUpdateOneWithoutPostNestedInput>;
  histories?: InputMaybe<PostHistoryUpdateManyWithoutPostNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  inValidReasonHistories?: InputMaybe<InValidReasonUpdateManyWithoutPostNestedInput>;
  isCheckin?: InputMaybe<BoolFieldUpdateOperationsInput>;
  loc?: InputMaybe<Scalars['JSON']>;
  message?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  package?: InputMaybe<PostPackageUpdateOneWithoutPostsNestedInput>;
  paymentProduct?: InputMaybe<ProductUpdateOneWithoutPostsNestedInput>;
  qrcode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  rearUri?: InputMaybe<StringFieldUpdateOperationsInput>;
  rearUri4Practical?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  receiver?: InputMaybe<UserUpdateOneWithoutPostsInNestedInput>;
  recvPrivacy?: InputMaybe<NullableEnumPrivacyLevelFieldUpdateOperationsInput>;
  reports?: InputMaybe<ReportUpdateManyWithoutPostNestedInput>;
  scenicSpot?: InputMaybe<ScenicSpotUpdateOneWithoutPostsNestedInput>;
  sendDate?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  sendPrivacy?: InputMaybe<EnumPrivacyLevelFieldUpdateOperationsInput>;
  sendStatus?: InputMaybe<EnumPackageStatusFieldUpdateOperationsInput>;
  sender?: InputMaybe<UserUpdateOneRequiredWithoutPostsOutNestedInput>;
  signature?: InputMaybe<SignatureUpdateOneWithoutPostsUsedNestedInput>;
  stamp?: InputMaybe<StampUpdateOneRequiredWithoutPostsUsedNestedInput>;
  tags?: InputMaybe<PostTagUpdateManyWithoutPostNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  valid?: InputMaybe<BoolFieldUpdateOperationsInput>;
};

export type PostUpdateWithoutPackageInput = {
  audioUri?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  benefitMemoToOrder?: InputMaybe<BenefitMemoToOrderUpdateManyWithoutPostNestedInput>;
  body?: InputMaybe<CardUpdateOneRequiredWithoutPostsUsedNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  detail?: InputMaybe<OrderDetailUpdateOneWithoutPostNestedInput>;
  histories?: InputMaybe<PostHistoryUpdateManyWithoutPostNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  inValidReasonHistories?: InputMaybe<InValidReasonUpdateManyWithoutPostNestedInput>;
  isCheckin?: InputMaybe<BoolFieldUpdateOperationsInput>;
  likeUsers?: InputMaybe<UserUpdateManyWithoutLikePostsNestedInput>;
  loc?: InputMaybe<Scalars['JSON']>;
  message?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  paymentProduct?: InputMaybe<ProductUpdateOneWithoutPostsNestedInput>;
  qrcode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  rearUri?: InputMaybe<StringFieldUpdateOperationsInput>;
  rearUri4Practical?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  receiver?: InputMaybe<UserUpdateOneWithoutPostsInNestedInput>;
  recvPrivacy?: InputMaybe<NullableEnumPrivacyLevelFieldUpdateOperationsInput>;
  reports?: InputMaybe<ReportUpdateManyWithoutPostNestedInput>;
  scenicSpot?: InputMaybe<ScenicSpotUpdateOneWithoutPostsNestedInput>;
  sendDate?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  sendPrivacy?: InputMaybe<EnumPrivacyLevelFieldUpdateOperationsInput>;
  sendStatus?: InputMaybe<EnumPackageStatusFieldUpdateOperationsInput>;
  sender?: InputMaybe<UserUpdateOneRequiredWithoutPostsOutNestedInput>;
  signature?: InputMaybe<SignatureUpdateOneWithoutPostsUsedNestedInput>;
  stamp?: InputMaybe<StampUpdateOneRequiredWithoutPostsUsedNestedInput>;
  tags?: InputMaybe<PostTagUpdateManyWithoutPostNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  valid?: InputMaybe<BoolFieldUpdateOperationsInput>;
};

export type PostUpdateWithoutPaymentProductInput = {
  audioUri?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  benefitMemoToOrder?: InputMaybe<BenefitMemoToOrderUpdateManyWithoutPostNestedInput>;
  body?: InputMaybe<CardUpdateOneRequiredWithoutPostsUsedNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  detail?: InputMaybe<OrderDetailUpdateOneWithoutPostNestedInput>;
  histories?: InputMaybe<PostHistoryUpdateManyWithoutPostNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  inValidReasonHistories?: InputMaybe<InValidReasonUpdateManyWithoutPostNestedInput>;
  isCheckin?: InputMaybe<BoolFieldUpdateOperationsInput>;
  likeUsers?: InputMaybe<UserUpdateManyWithoutLikePostsNestedInput>;
  loc?: InputMaybe<Scalars['JSON']>;
  message?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  package?: InputMaybe<PostPackageUpdateOneWithoutPostsNestedInput>;
  qrcode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  rearUri?: InputMaybe<StringFieldUpdateOperationsInput>;
  rearUri4Practical?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  receiver?: InputMaybe<UserUpdateOneWithoutPostsInNestedInput>;
  recvPrivacy?: InputMaybe<NullableEnumPrivacyLevelFieldUpdateOperationsInput>;
  reports?: InputMaybe<ReportUpdateManyWithoutPostNestedInput>;
  scenicSpot?: InputMaybe<ScenicSpotUpdateOneWithoutPostsNestedInput>;
  sendDate?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  sendPrivacy?: InputMaybe<EnumPrivacyLevelFieldUpdateOperationsInput>;
  sendStatus?: InputMaybe<EnumPackageStatusFieldUpdateOperationsInput>;
  sender?: InputMaybe<UserUpdateOneRequiredWithoutPostsOutNestedInput>;
  signature?: InputMaybe<SignatureUpdateOneWithoutPostsUsedNestedInput>;
  stamp?: InputMaybe<StampUpdateOneRequiredWithoutPostsUsedNestedInput>;
  tags?: InputMaybe<PostTagUpdateManyWithoutPostNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  valid?: InputMaybe<BoolFieldUpdateOperationsInput>;
};

export type PostUpdateWithoutReceiverInput = {
  audioUri?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  benefitMemoToOrder?: InputMaybe<BenefitMemoToOrderUpdateManyWithoutPostNestedInput>;
  body?: InputMaybe<CardUpdateOneRequiredWithoutPostsUsedNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  detail?: InputMaybe<OrderDetailUpdateOneWithoutPostNestedInput>;
  histories?: InputMaybe<PostHistoryUpdateManyWithoutPostNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  inValidReasonHistories?: InputMaybe<InValidReasonUpdateManyWithoutPostNestedInput>;
  isCheckin?: InputMaybe<BoolFieldUpdateOperationsInput>;
  likeUsers?: InputMaybe<UserUpdateManyWithoutLikePostsNestedInput>;
  loc?: InputMaybe<Scalars['JSON']>;
  message?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  package?: InputMaybe<PostPackageUpdateOneWithoutPostsNestedInput>;
  paymentProduct?: InputMaybe<ProductUpdateOneWithoutPostsNestedInput>;
  qrcode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  rearUri?: InputMaybe<StringFieldUpdateOperationsInput>;
  rearUri4Practical?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  recvPrivacy?: InputMaybe<NullableEnumPrivacyLevelFieldUpdateOperationsInput>;
  reports?: InputMaybe<ReportUpdateManyWithoutPostNestedInput>;
  scenicSpot?: InputMaybe<ScenicSpotUpdateOneWithoutPostsNestedInput>;
  sendDate?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  sendPrivacy?: InputMaybe<EnumPrivacyLevelFieldUpdateOperationsInput>;
  sendStatus?: InputMaybe<EnumPackageStatusFieldUpdateOperationsInput>;
  sender?: InputMaybe<UserUpdateOneRequiredWithoutPostsOutNestedInput>;
  signature?: InputMaybe<SignatureUpdateOneWithoutPostsUsedNestedInput>;
  stamp?: InputMaybe<StampUpdateOneRequiredWithoutPostsUsedNestedInput>;
  tags?: InputMaybe<PostTagUpdateManyWithoutPostNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  valid?: InputMaybe<BoolFieldUpdateOperationsInput>;
};

export type PostUpdateWithoutReportsInput = {
  audioUri?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  benefitMemoToOrder?: InputMaybe<BenefitMemoToOrderUpdateManyWithoutPostNestedInput>;
  body?: InputMaybe<CardUpdateOneRequiredWithoutPostsUsedNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  detail?: InputMaybe<OrderDetailUpdateOneWithoutPostNestedInput>;
  histories?: InputMaybe<PostHistoryUpdateManyWithoutPostNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  inValidReasonHistories?: InputMaybe<InValidReasonUpdateManyWithoutPostNestedInput>;
  isCheckin?: InputMaybe<BoolFieldUpdateOperationsInput>;
  likeUsers?: InputMaybe<UserUpdateManyWithoutLikePostsNestedInput>;
  loc?: InputMaybe<Scalars['JSON']>;
  message?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  package?: InputMaybe<PostPackageUpdateOneWithoutPostsNestedInput>;
  paymentProduct?: InputMaybe<ProductUpdateOneWithoutPostsNestedInput>;
  qrcode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  rearUri?: InputMaybe<StringFieldUpdateOperationsInput>;
  rearUri4Practical?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  receiver?: InputMaybe<UserUpdateOneWithoutPostsInNestedInput>;
  recvPrivacy?: InputMaybe<NullableEnumPrivacyLevelFieldUpdateOperationsInput>;
  scenicSpot?: InputMaybe<ScenicSpotUpdateOneWithoutPostsNestedInput>;
  sendDate?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  sendPrivacy?: InputMaybe<EnumPrivacyLevelFieldUpdateOperationsInput>;
  sendStatus?: InputMaybe<EnumPackageStatusFieldUpdateOperationsInput>;
  sender?: InputMaybe<UserUpdateOneRequiredWithoutPostsOutNestedInput>;
  signature?: InputMaybe<SignatureUpdateOneWithoutPostsUsedNestedInput>;
  stamp?: InputMaybe<StampUpdateOneRequiredWithoutPostsUsedNestedInput>;
  tags?: InputMaybe<PostTagUpdateManyWithoutPostNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  valid?: InputMaybe<BoolFieldUpdateOperationsInput>;
};

export type PostUpdateWithoutScenicSpotInput = {
  audioUri?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  benefitMemoToOrder?: InputMaybe<BenefitMemoToOrderUpdateManyWithoutPostNestedInput>;
  body?: InputMaybe<CardUpdateOneRequiredWithoutPostsUsedNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  detail?: InputMaybe<OrderDetailUpdateOneWithoutPostNestedInput>;
  histories?: InputMaybe<PostHistoryUpdateManyWithoutPostNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  inValidReasonHistories?: InputMaybe<InValidReasonUpdateManyWithoutPostNestedInput>;
  isCheckin?: InputMaybe<BoolFieldUpdateOperationsInput>;
  likeUsers?: InputMaybe<UserUpdateManyWithoutLikePostsNestedInput>;
  loc?: InputMaybe<Scalars['JSON']>;
  message?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  package?: InputMaybe<PostPackageUpdateOneWithoutPostsNestedInput>;
  paymentProduct?: InputMaybe<ProductUpdateOneWithoutPostsNestedInput>;
  qrcode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  rearUri?: InputMaybe<StringFieldUpdateOperationsInput>;
  rearUri4Practical?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  receiver?: InputMaybe<UserUpdateOneWithoutPostsInNestedInput>;
  recvPrivacy?: InputMaybe<NullableEnumPrivacyLevelFieldUpdateOperationsInput>;
  reports?: InputMaybe<ReportUpdateManyWithoutPostNestedInput>;
  sendDate?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  sendPrivacy?: InputMaybe<EnumPrivacyLevelFieldUpdateOperationsInput>;
  sendStatus?: InputMaybe<EnumPackageStatusFieldUpdateOperationsInput>;
  sender?: InputMaybe<UserUpdateOneRequiredWithoutPostsOutNestedInput>;
  signature?: InputMaybe<SignatureUpdateOneWithoutPostsUsedNestedInput>;
  stamp?: InputMaybe<StampUpdateOneRequiredWithoutPostsUsedNestedInput>;
  tags?: InputMaybe<PostTagUpdateManyWithoutPostNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  valid?: InputMaybe<BoolFieldUpdateOperationsInput>;
};

export type PostUpdateWithoutSenderInput = {
  audioUri?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  benefitMemoToOrder?: InputMaybe<BenefitMemoToOrderUpdateManyWithoutPostNestedInput>;
  body?: InputMaybe<CardUpdateOneRequiredWithoutPostsUsedNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  detail?: InputMaybe<OrderDetailUpdateOneWithoutPostNestedInput>;
  histories?: InputMaybe<PostHistoryUpdateManyWithoutPostNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  inValidReasonHistories?: InputMaybe<InValidReasonUpdateManyWithoutPostNestedInput>;
  isCheckin?: InputMaybe<BoolFieldUpdateOperationsInput>;
  likeUsers?: InputMaybe<UserUpdateManyWithoutLikePostsNestedInput>;
  loc?: InputMaybe<Scalars['JSON']>;
  message?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  package?: InputMaybe<PostPackageUpdateOneWithoutPostsNestedInput>;
  paymentProduct?: InputMaybe<ProductUpdateOneWithoutPostsNestedInput>;
  qrcode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  rearUri?: InputMaybe<StringFieldUpdateOperationsInput>;
  rearUri4Practical?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  receiver?: InputMaybe<UserUpdateOneWithoutPostsInNestedInput>;
  recvPrivacy?: InputMaybe<NullableEnumPrivacyLevelFieldUpdateOperationsInput>;
  reports?: InputMaybe<ReportUpdateManyWithoutPostNestedInput>;
  scenicSpot?: InputMaybe<ScenicSpotUpdateOneWithoutPostsNestedInput>;
  sendDate?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  sendPrivacy?: InputMaybe<EnumPrivacyLevelFieldUpdateOperationsInput>;
  sendStatus?: InputMaybe<EnumPackageStatusFieldUpdateOperationsInput>;
  signature?: InputMaybe<SignatureUpdateOneWithoutPostsUsedNestedInput>;
  stamp?: InputMaybe<StampUpdateOneRequiredWithoutPostsUsedNestedInput>;
  tags?: InputMaybe<PostTagUpdateManyWithoutPostNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  valid?: InputMaybe<BoolFieldUpdateOperationsInput>;
};

export type PostUpdateWithoutSignatureInput = {
  audioUri?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  benefitMemoToOrder?: InputMaybe<BenefitMemoToOrderUpdateManyWithoutPostNestedInput>;
  body?: InputMaybe<CardUpdateOneRequiredWithoutPostsUsedNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  detail?: InputMaybe<OrderDetailUpdateOneWithoutPostNestedInput>;
  histories?: InputMaybe<PostHistoryUpdateManyWithoutPostNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  inValidReasonHistories?: InputMaybe<InValidReasonUpdateManyWithoutPostNestedInput>;
  isCheckin?: InputMaybe<BoolFieldUpdateOperationsInput>;
  likeUsers?: InputMaybe<UserUpdateManyWithoutLikePostsNestedInput>;
  loc?: InputMaybe<Scalars['JSON']>;
  message?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  package?: InputMaybe<PostPackageUpdateOneWithoutPostsNestedInput>;
  paymentProduct?: InputMaybe<ProductUpdateOneWithoutPostsNestedInput>;
  qrcode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  rearUri?: InputMaybe<StringFieldUpdateOperationsInput>;
  rearUri4Practical?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  receiver?: InputMaybe<UserUpdateOneWithoutPostsInNestedInput>;
  recvPrivacy?: InputMaybe<NullableEnumPrivacyLevelFieldUpdateOperationsInput>;
  reports?: InputMaybe<ReportUpdateManyWithoutPostNestedInput>;
  scenicSpot?: InputMaybe<ScenicSpotUpdateOneWithoutPostsNestedInput>;
  sendDate?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  sendPrivacy?: InputMaybe<EnumPrivacyLevelFieldUpdateOperationsInput>;
  sendStatus?: InputMaybe<EnumPackageStatusFieldUpdateOperationsInput>;
  sender?: InputMaybe<UserUpdateOneRequiredWithoutPostsOutNestedInput>;
  stamp?: InputMaybe<StampUpdateOneRequiredWithoutPostsUsedNestedInput>;
  tags?: InputMaybe<PostTagUpdateManyWithoutPostNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  valid?: InputMaybe<BoolFieldUpdateOperationsInput>;
};

export type PostUpdateWithoutStampInput = {
  audioUri?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  benefitMemoToOrder?: InputMaybe<BenefitMemoToOrderUpdateManyWithoutPostNestedInput>;
  body?: InputMaybe<CardUpdateOneRequiredWithoutPostsUsedNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  detail?: InputMaybe<OrderDetailUpdateOneWithoutPostNestedInput>;
  histories?: InputMaybe<PostHistoryUpdateManyWithoutPostNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  inValidReasonHistories?: InputMaybe<InValidReasonUpdateManyWithoutPostNestedInput>;
  isCheckin?: InputMaybe<BoolFieldUpdateOperationsInput>;
  likeUsers?: InputMaybe<UserUpdateManyWithoutLikePostsNestedInput>;
  loc?: InputMaybe<Scalars['JSON']>;
  message?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  package?: InputMaybe<PostPackageUpdateOneWithoutPostsNestedInput>;
  paymentProduct?: InputMaybe<ProductUpdateOneWithoutPostsNestedInput>;
  qrcode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  rearUri?: InputMaybe<StringFieldUpdateOperationsInput>;
  rearUri4Practical?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  receiver?: InputMaybe<UserUpdateOneWithoutPostsInNestedInput>;
  recvPrivacy?: InputMaybe<NullableEnumPrivacyLevelFieldUpdateOperationsInput>;
  reports?: InputMaybe<ReportUpdateManyWithoutPostNestedInput>;
  scenicSpot?: InputMaybe<ScenicSpotUpdateOneWithoutPostsNestedInput>;
  sendDate?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  sendPrivacy?: InputMaybe<EnumPrivacyLevelFieldUpdateOperationsInput>;
  sendStatus?: InputMaybe<EnumPackageStatusFieldUpdateOperationsInput>;
  sender?: InputMaybe<UserUpdateOneRequiredWithoutPostsOutNestedInput>;
  signature?: InputMaybe<SignatureUpdateOneWithoutPostsUsedNestedInput>;
  tags?: InputMaybe<PostTagUpdateManyWithoutPostNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  valid?: InputMaybe<BoolFieldUpdateOperationsInput>;
};

export type PostUpdateWithoutTagsInput = {
  audioUri?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  benefitMemoToOrder?: InputMaybe<BenefitMemoToOrderUpdateManyWithoutPostNestedInput>;
  body?: InputMaybe<CardUpdateOneRequiredWithoutPostsUsedNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  detail?: InputMaybe<OrderDetailUpdateOneWithoutPostNestedInput>;
  histories?: InputMaybe<PostHistoryUpdateManyWithoutPostNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  inValidReasonHistories?: InputMaybe<InValidReasonUpdateManyWithoutPostNestedInput>;
  isCheckin?: InputMaybe<BoolFieldUpdateOperationsInput>;
  likeUsers?: InputMaybe<UserUpdateManyWithoutLikePostsNestedInput>;
  loc?: InputMaybe<Scalars['JSON']>;
  message?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  package?: InputMaybe<PostPackageUpdateOneWithoutPostsNestedInput>;
  paymentProduct?: InputMaybe<ProductUpdateOneWithoutPostsNestedInput>;
  qrcode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  rearUri?: InputMaybe<StringFieldUpdateOperationsInput>;
  rearUri4Practical?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  receiver?: InputMaybe<UserUpdateOneWithoutPostsInNestedInput>;
  recvPrivacy?: InputMaybe<NullableEnumPrivacyLevelFieldUpdateOperationsInput>;
  reports?: InputMaybe<ReportUpdateManyWithoutPostNestedInput>;
  scenicSpot?: InputMaybe<ScenicSpotUpdateOneWithoutPostsNestedInput>;
  sendDate?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  sendPrivacy?: InputMaybe<EnumPrivacyLevelFieldUpdateOperationsInput>;
  sendStatus?: InputMaybe<EnumPackageStatusFieldUpdateOperationsInput>;
  sender?: InputMaybe<UserUpdateOneRequiredWithoutPostsOutNestedInput>;
  signature?: InputMaybe<SignatureUpdateOneWithoutPostsUsedNestedInput>;
  stamp?: InputMaybe<StampUpdateOneRequiredWithoutPostsUsedNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  valid?: InputMaybe<BoolFieldUpdateOperationsInput>;
};

export type PostUpsertWithWhereUniqueWithoutBodyInput = {
  create: PostCreateWithoutBodyInput;
  update: PostUpdateWithoutBodyInput;
  where: PostWhereUniqueInput;
};

export type PostUpsertWithWhereUniqueWithoutLikeUsersInput = {
  create: PostCreateWithoutLikeUsersInput;
  update: PostUpdateWithoutLikeUsersInput;
  where: PostWhereUniqueInput;
};

export type PostUpsertWithWhereUniqueWithoutPackageInput = {
  create: PostCreateWithoutPackageInput;
  update: PostUpdateWithoutPackageInput;
  where: PostWhereUniqueInput;
};

export type PostUpsertWithWhereUniqueWithoutPaymentProductInput = {
  create: PostCreateWithoutPaymentProductInput;
  update: PostUpdateWithoutPaymentProductInput;
  where: PostWhereUniqueInput;
};

export type PostUpsertWithWhereUniqueWithoutReceiverInput = {
  create: PostCreateWithoutReceiverInput;
  update: PostUpdateWithoutReceiverInput;
  where: PostWhereUniqueInput;
};

export type PostUpsertWithWhereUniqueWithoutScenicSpotInput = {
  create: PostCreateWithoutScenicSpotInput;
  update: PostUpdateWithoutScenicSpotInput;
  where: PostWhereUniqueInput;
};

export type PostUpsertWithWhereUniqueWithoutSenderInput = {
  create: PostCreateWithoutSenderInput;
  update: PostUpdateWithoutSenderInput;
  where: PostWhereUniqueInput;
};

export type PostUpsertWithWhereUniqueWithoutSignatureInput = {
  create: PostCreateWithoutSignatureInput;
  update: PostUpdateWithoutSignatureInput;
  where: PostWhereUniqueInput;
};

export type PostUpsertWithWhereUniqueWithoutStampInput = {
  create: PostCreateWithoutStampInput;
  update: PostUpdateWithoutStampInput;
  where: PostWhereUniqueInput;
};

export type PostUpsertWithoutBenefitMemoToOrderInput = {
  create: PostCreateWithoutBenefitMemoToOrderInput;
  update: PostUpdateWithoutBenefitMemoToOrderInput;
};

export type PostUpsertWithoutDetailInput = {
  create: PostCreateWithoutDetailInput;
  update: PostUpdateWithoutDetailInput;
};

export type PostUpsertWithoutHistoriesInput = {
  create: PostCreateWithoutHistoriesInput;
  update: PostUpdateWithoutHistoriesInput;
};

export type PostUpsertWithoutInValidReasonHistoriesInput = {
  create: PostCreateWithoutInValidReasonHistoriesInput;
  update: PostUpdateWithoutInValidReasonHistoriesInput;
};

export type PostUpsertWithoutReportsInput = {
  create: PostCreateWithoutReportsInput;
  update: PostUpdateWithoutReportsInput;
};

export type PostUpsertWithoutTagsInput = {
  create: PostCreateWithoutTagsInput;
  update: PostUpdateWithoutTagsInput;
};

export type PostWhereInput = {
  AND?: InputMaybe<Array<PostWhereInput>>;
  NOT?: InputMaybe<Array<PostWhereInput>>;
  OR?: InputMaybe<Array<PostWhereInput>>;
  audioUri?: InputMaybe<StringNullableFilter>;
  benefitMemoToOrder?: InputMaybe<BenefitMemoToOrderListRelationFilter>;
  body?: InputMaybe<CardRelationFilter>;
  bodyId?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  detail?: InputMaybe<OrderDetailRelationFilter>;
  histories?: InputMaybe<PostHistoryListRelationFilter>;
  id?: InputMaybe<StringFilter>;
  inValidReasonHistories?: InputMaybe<InValidReasonListRelationFilter>;
  isCheckin?: InputMaybe<BoolFilter>;
  likeUsers?: InputMaybe<UserListRelationFilter>;
  loc?: InputMaybe<JsonNullableFilter>;
  message?: InputMaybe<StringNullableFilter>;
  package?: InputMaybe<PostPackageRelationFilter>;
  packageId?: InputMaybe<StringNullableFilter>;
  paymentProduct?: InputMaybe<ProductRelationFilter>;
  paymentProductId?: InputMaybe<StringNullableFilter>;
  qrcode?: InputMaybe<StringNullableFilter>;
  rearUri?: InputMaybe<StringFilter>;
  rearUri4Practical?: InputMaybe<StringNullableFilter>;
  receiver?: InputMaybe<UserRelationFilter>;
  receiverId?: InputMaybe<StringNullableFilter>;
  recvPrivacy?: InputMaybe<EnumPrivacyLevelNullableFilter>;
  reports?: InputMaybe<ReportListRelationFilter>;
  scenicSpot?: InputMaybe<ScenicSpotRelationFilter>;
  scenicSpotId?: InputMaybe<StringNullableFilter>;
  sendDate?: InputMaybe<DateTimeFilter>;
  sendPrivacy?: InputMaybe<EnumPrivacyLevelFilter>;
  sendStatus?: InputMaybe<EnumPackageStatusFilter>;
  sender?: InputMaybe<UserRelationFilter>;
  senderId?: InputMaybe<StringFilter>;
  signature?: InputMaybe<SignatureRelationFilter>;
  signatureId?: InputMaybe<StringNullableFilter>;
  stamp?: InputMaybe<StampRelationFilter>;
  stampId?: InputMaybe<StringFilter>;
  tags?: InputMaybe<PostTagListRelationFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  valid?: InputMaybe<BoolFilter>;
};

export type PostWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
  qrcode?: InputMaybe<Scalars['String']>;
};

export enum PrintStatus {
  Printed = 'printed',
  WaitToPrint = 'waitToPrint'
}

export enum PrivacyLevel {
  Follower = 'follower',
  Friend = 'friend',
  Public = 'public',
  RecvOnly = 'recvOnly'
}

export type Product = {
  __typename?: 'Product';
  _count?: Maybe<ProductCount>;
  consumeType: ConsumeType;
  description?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  name: Scalars['String'];
  orderDetails: Array<OrderDetail>;
  posts: Array<Post>;
  type: ProductType;
  unitPrice: Scalars['Decimal'];
};


export type ProductOrderDetailsArgs = {
  cursor?: InputMaybe<OrderDetailWhereUniqueInput>;
  distinct?: InputMaybe<Array<OrderDetailScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<OrderDetailOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<OrderDetailWhereInput>;
};


export type ProductPostsArgs = {
  cursor?: InputMaybe<PostWhereUniqueInput>;
  distinct?: InputMaybe<Array<PostScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<PostOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PostWhereInput>;
};

export type ProductAvgAggregate = {
  __typename?: 'ProductAvgAggregate';
  unitPrice?: Maybe<Scalars['Decimal']>;
};

export type ProductAvgOrderByAggregateInput = {
  unitPrice?: InputMaybe<SortOrder>;
};

export type ProductCount = {
  __typename?: 'ProductCount';
  orderDetails: Scalars['Int'];
  posts: Scalars['Int'];
};


export type ProductCountOrderDetailsArgs = {
  where?: InputMaybe<OrderDetailWhereInput>;
};


export type ProductCountPostsArgs = {
  where?: InputMaybe<PostWhereInput>;
};

export type ProductCountAggregate = {
  __typename?: 'ProductCountAggregate';
  _all: Scalars['Int'];
  consumeType: Scalars['Int'];
  description: Scalars['Int'];
  id: Scalars['Int'];
  name: Scalars['Int'];
  type: Scalars['Int'];
  unitPrice: Scalars['Int'];
};

export type ProductCountOrderByAggregateInput = {
  consumeType?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
  unitPrice?: InputMaybe<SortOrder>;
};

export type ProductCreateInput = {
  consumeType: ConsumeType;
  description?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  name: Scalars['String'];
  orderDetails?: InputMaybe<OrderDetailCreateNestedManyWithoutProductInput>;
  posts?: InputMaybe<PostCreateNestedManyWithoutPaymentProductInput>;
  type: ProductType;
  unitPrice: Scalars['Decimal'];
};

export type ProductCreateManyInput = {
  consumeType: ConsumeType;
  description?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  name: Scalars['String'];
  type: ProductType;
  unitPrice: Scalars['Decimal'];
};

export type ProductCreateNestedOneWithoutOrderDetailsInput = {
  connect?: InputMaybe<ProductWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ProductCreateOrConnectWithoutOrderDetailsInput>;
  create?: InputMaybe<ProductCreateWithoutOrderDetailsInput>;
};

export type ProductCreateNestedOneWithoutPostsInput = {
  connect?: InputMaybe<ProductWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ProductCreateOrConnectWithoutPostsInput>;
  create?: InputMaybe<ProductCreateWithoutPostsInput>;
};

export type ProductCreateOrConnectWithoutOrderDetailsInput = {
  create: ProductCreateWithoutOrderDetailsInput;
  where: ProductWhereUniqueInput;
};

export type ProductCreateOrConnectWithoutPostsInput = {
  create: ProductCreateWithoutPostsInput;
  where: ProductWhereUniqueInput;
};

export type ProductCreateWithoutOrderDetailsInput = {
  consumeType: ConsumeType;
  description?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  name: Scalars['String'];
  posts?: InputMaybe<PostCreateNestedManyWithoutPaymentProductInput>;
  type: ProductType;
  unitPrice: Scalars['Decimal'];
};

export type ProductCreateWithoutPostsInput = {
  consumeType: ConsumeType;
  description?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  name: Scalars['String'];
  orderDetails?: InputMaybe<OrderDetailCreateNestedManyWithoutProductInput>;
  type: ProductType;
  unitPrice: Scalars['Decimal'];
};

export type ProductGroupBy = {
  __typename?: 'ProductGroupBy';
  _avg?: Maybe<ProductAvgAggregate>;
  _count?: Maybe<ProductCountAggregate>;
  _max?: Maybe<ProductMaxAggregate>;
  _min?: Maybe<ProductMinAggregate>;
  _sum?: Maybe<ProductSumAggregate>;
  consumeType: ConsumeType;
  description?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  name: Scalars['String'];
  type: ProductType;
  unitPrice: Scalars['Decimal'];
};

export type ProductMaxAggregate = {
  __typename?: 'ProductMaxAggregate';
  consumeType?: Maybe<ConsumeType>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  type?: Maybe<ProductType>;
  unitPrice?: Maybe<Scalars['Decimal']>;
};

export type ProductMaxOrderByAggregateInput = {
  consumeType?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
  unitPrice?: InputMaybe<SortOrder>;
};

export type ProductMinAggregate = {
  __typename?: 'ProductMinAggregate';
  consumeType?: Maybe<ConsumeType>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  type?: Maybe<ProductType>;
  unitPrice?: Maybe<Scalars['Decimal']>;
};

export type ProductMinOrderByAggregateInput = {
  consumeType?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
  unitPrice?: InputMaybe<SortOrder>;
};

export type ProductOrderByWithAggregationInput = {
  _avg?: InputMaybe<ProductAvgOrderByAggregateInput>;
  _count?: InputMaybe<ProductCountOrderByAggregateInput>;
  _max?: InputMaybe<ProductMaxOrderByAggregateInput>;
  _min?: InputMaybe<ProductMinOrderByAggregateInput>;
  _sum?: InputMaybe<ProductSumOrderByAggregateInput>;
  consumeType?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
  unitPrice?: InputMaybe<SortOrder>;
};

export type ProductOrderByWithRelationInput = {
  consumeType?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  orderDetails?: InputMaybe<OrderDetailOrderByRelationAggregateInput>;
  posts?: InputMaybe<PostOrderByRelationAggregateInput>;
  type?: InputMaybe<SortOrder>;
  unitPrice?: InputMaybe<SortOrder>;
};

export type ProductRelationFilter = {
  is?: InputMaybe<ProductWhereInput>;
  isNot?: InputMaybe<ProductWhereInput>;
};

export enum ProductScalarFieldEnum {
  ConsumeType = 'consumeType',
  Description = 'description',
  Id = 'id',
  Name = 'name',
  Type = 'type',
  UnitPrice = 'unitPrice'
}

export type ProductScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<ProductScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<ProductScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<ProductScalarWhereWithAggregatesInput>>;
  consumeType?: InputMaybe<EnumConsumeTypeWithAggregatesFilter>;
  description?: InputMaybe<StringNullableWithAggregatesFilter>;
  id?: InputMaybe<StringWithAggregatesFilter>;
  name?: InputMaybe<StringWithAggregatesFilter>;
  type?: InputMaybe<EnumProductTypeWithAggregatesFilter>;
  unitPrice?: InputMaybe<DecimalWithAggregatesFilter>;
};

export type ProductSumAggregate = {
  __typename?: 'ProductSumAggregate';
  unitPrice?: Maybe<Scalars['Decimal']>;
};

export type ProductSumOrderByAggregateInput = {
  unitPrice?: InputMaybe<SortOrder>;
};

export enum ProductType {
  Postcard = 'postcard',
  PracticalSending = 'practicalSending',
  Stamp = 'stamp'
}

export type ProductUpdateInput = {
  consumeType?: InputMaybe<EnumConsumeTypeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  orderDetails?: InputMaybe<OrderDetailUpdateManyWithoutProductNestedInput>;
  posts?: InputMaybe<PostUpdateManyWithoutPaymentProductNestedInput>;
  type?: InputMaybe<EnumProductTypeFieldUpdateOperationsInput>;
  unitPrice?: InputMaybe<DecimalFieldUpdateOperationsInput>;
};

export type ProductUpdateManyMutationInput = {
  consumeType?: InputMaybe<EnumConsumeTypeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  type?: InputMaybe<EnumProductTypeFieldUpdateOperationsInput>;
  unitPrice?: InputMaybe<DecimalFieldUpdateOperationsInput>;
};

export type ProductUpdateOneWithoutOrderDetailsNestedInput = {
  connect?: InputMaybe<ProductWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ProductCreateOrConnectWithoutOrderDetailsInput>;
  create?: InputMaybe<ProductCreateWithoutOrderDetailsInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<ProductUpdateWithoutOrderDetailsInput>;
  upsert?: InputMaybe<ProductUpsertWithoutOrderDetailsInput>;
};

export type ProductUpdateOneWithoutPostsNestedInput = {
  connect?: InputMaybe<ProductWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ProductCreateOrConnectWithoutPostsInput>;
  create?: InputMaybe<ProductCreateWithoutPostsInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<ProductUpdateWithoutPostsInput>;
  upsert?: InputMaybe<ProductUpsertWithoutPostsInput>;
};

export type ProductUpdateWithoutOrderDetailsInput = {
  consumeType?: InputMaybe<EnumConsumeTypeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  posts?: InputMaybe<PostUpdateManyWithoutPaymentProductNestedInput>;
  type?: InputMaybe<EnumProductTypeFieldUpdateOperationsInput>;
  unitPrice?: InputMaybe<DecimalFieldUpdateOperationsInput>;
};

export type ProductUpdateWithoutPostsInput = {
  consumeType?: InputMaybe<EnumConsumeTypeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  orderDetails?: InputMaybe<OrderDetailUpdateManyWithoutProductNestedInput>;
  type?: InputMaybe<EnumProductTypeFieldUpdateOperationsInput>;
  unitPrice?: InputMaybe<DecimalFieldUpdateOperationsInput>;
};

export type ProductUpsertWithoutOrderDetailsInput = {
  create: ProductCreateWithoutOrderDetailsInput;
  update: ProductUpdateWithoutOrderDetailsInput;
};

export type ProductUpsertWithoutPostsInput = {
  create: ProductCreateWithoutPostsInput;
  update: ProductUpdateWithoutPostsInput;
};

export type ProductWhereInput = {
  AND?: InputMaybe<Array<ProductWhereInput>>;
  NOT?: InputMaybe<Array<ProductWhereInput>>;
  OR?: InputMaybe<Array<ProductWhereInput>>;
  consumeType?: InputMaybe<EnumConsumeTypeFilter>;
  description?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringFilter>;
  orderDetails?: InputMaybe<OrderDetailListRelationFilter>;
  posts?: InputMaybe<PostListRelationFilter>;
  type?: InputMaybe<EnumProductTypeFilter>;
  unitPrice?: InputMaybe<DecimalFilter>;
};

export type ProductWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
};

export type Profitable = {
  __typename?: 'Profitable';
  _count?: Maybe<ProfitableCount>;
  badget?: Maybe<ProfitableBadget>;
  badgetId?: Maybe<Scalars['String']>;
  beneficialInfo?: Maybe<Scalars['JSON']>;
  benefitMemos: Array<BenefitMemo>;
  benefitTransfers: Array<BenefitTransfer>;
  bio?: Maybe<Scalars['String']>;
  class: ProfitableClass;
  createdAt: Scalars['DateTime'];
  followedBy: Array<User>;
  id: Scalars['String'];
  inValidReasonHistories: Array<InValidReason>;
  name?: Maybe<Scalars['String']>;
  saleCount: Scalars['Int'];
  saleRatio?: Maybe<StoreRatio>;
  saleRatioHistory: Array<StoreRatioHistoryItem>;
  saleRatioId?: Maybe<Scalars['String']>;
  sendRatio?: Maybe<StoreRatio>;
  sendRatioHistory: Array<StoreRatioHistoryItem>;
  sendRatioId?: Maybe<Scalars['String']>;
  statusText?: Maybe<Scalars['String']>;
  storeItems: Array<StoreItem>;
  type: ProfitableType;
  updatedAt: Scalars['DateTime'];
  user: User;
  userId: Scalars['String'];
  valid: Scalars['Boolean'];
  /** 有效商品數量 */
  validStoreItemCount: Scalars['Int'];
};


export type ProfitableBenefitMemosArgs = {
  cursor?: InputMaybe<BenefitMemoWhereUniqueInput>;
  distinct?: InputMaybe<Array<BenefitMemoScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<BenefitMemoOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<BenefitMemoWhereInput>;
};


export type ProfitableBenefitTransfersArgs = {
  cursor?: InputMaybe<BenefitTransferWhereUniqueInput>;
  distinct?: InputMaybe<Array<BenefitTransferScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<BenefitTransferOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<BenefitTransferWhereInput>;
};


export type ProfitableFollowedByArgs = {
  cursor?: InputMaybe<UserWhereUniqueInput>;
  distinct?: InputMaybe<Array<UserScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<UserOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<UserWhereInput>;
};


export type ProfitableInValidReasonHistoriesArgs = {
  cursor?: InputMaybe<InValidReasonWhereUniqueInput>;
  distinct?: InputMaybe<Array<InValidReasonScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<InValidReasonOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<InValidReasonWhereInput>;
};


export type ProfitableSaleRatioHistoryArgs = {
  cursor?: InputMaybe<StoreRatioHistoryItemWhereUniqueInput>;
  distinct?: InputMaybe<Array<StoreRatioHistoryItemScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<StoreRatioHistoryItemOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<StoreRatioHistoryItemWhereInput>;
};


export type ProfitableSendRatioHistoryArgs = {
  cursor?: InputMaybe<StoreRatioHistoryItemWhereUniqueInput>;
  distinct?: InputMaybe<Array<StoreRatioHistoryItemScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<StoreRatioHistoryItemOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<StoreRatioHistoryItemWhereInput>;
};


export type ProfitableStoreItemsArgs = {
  cursor?: InputMaybe<StoreItemWhereUniqueInput>;
  distinct?: InputMaybe<Array<StoreItemScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<StoreItemOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<StoreItemWhereInput>;
};

export type ProfitableAvgAggregate = {
  __typename?: 'ProfitableAvgAggregate';
  saleCount?: Maybe<Scalars['Float']>;
};

export type ProfitableAvgOrderByAggregateInput = {
  saleCount?: InputMaybe<SortOrder>;
};

export type ProfitableBadget = {
  __typename?: 'ProfitableBadget';
  _count?: Maybe<ProfitableBadgetCount>;
  createdAt: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  picUri: Scalars['String'];
  profitables: Array<Profitable>;
  updatedAt: Scalars['DateTime'];
};


export type ProfitableBadgetProfitablesArgs = {
  cursor?: InputMaybe<ProfitableWhereUniqueInput>;
  distinct?: InputMaybe<Array<ProfitableScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ProfitableOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ProfitableWhereInput>;
};

export type ProfitableBadgetCount = {
  __typename?: 'ProfitableBadgetCount';
  profitables: Scalars['Int'];
};


export type ProfitableBadgetCountProfitablesArgs = {
  where?: InputMaybe<ProfitableWhereInput>;
};

export type ProfitableBadgetCountAggregate = {
  __typename?: 'ProfitableBadgetCountAggregate';
  _all: Scalars['Int'];
  createdAt: Scalars['Int'];
  description: Scalars['Int'];
  id: Scalars['Int'];
  picUri: Scalars['Int'];
  updatedAt: Scalars['Int'];
};

export type ProfitableBadgetCountOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  picUri?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type ProfitableBadgetCreateInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  picUri: Scalars['String'];
  profitables?: InputMaybe<ProfitableCreateNestedManyWithoutBadgetInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ProfitableBadgetCreateManyInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  picUri: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ProfitableBadgetCreateNestedOneWithoutProfitablesInput = {
  connect?: InputMaybe<ProfitableBadgetWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ProfitableBadgetCreateOrConnectWithoutProfitablesInput>;
  create?: InputMaybe<ProfitableBadgetCreateWithoutProfitablesInput>;
};

export type ProfitableBadgetCreateOrConnectWithoutProfitablesInput = {
  create: ProfitableBadgetCreateWithoutProfitablesInput;
  where: ProfitableBadgetWhereUniqueInput;
};

export type ProfitableBadgetCreateWithoutProfitablesInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  picUri: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ProfitableBadgetGroupBy = {
  __typename?: 'ProfitableBadgetGroupBy';
  _count?: Maybe<ProfitableBadgetCountAggregate>;
  _max?: Maybe<ProfitableBadgetMaxAggregate>;
  _min?: Maybe<ProfitableBadgetMinAggregate>;
  createdAt: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  picUri: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type ProfitableBadgetMaxAggregate = {
  __typename?: 'ProfitableBadgetMaxAggregate';
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  picUri?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ProfitableBadgetMaxOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  picUri?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type ProfitableBadgetMinAggregate = {
  __typename?: 'ProfitableBadgetMinAggregate';
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  picUri?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ProfitableBadgetMinOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  picUri?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type ProfitableBadgetOrderByWithAggregationInput = {
  _count?: InputMaybe<ProfitableBadgetCountOrderByAggregateInput>;
  _max?: InputMaybe<ProfitableBadgetMaxOrderByAggregateInput>;
  _min?: InputMaybe<ProfitableBadgetMinOrderByAggregateInput>;
  createdAt?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  picUri?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type ProfitableBadgetOrderByWithRelationInput = {
  createdAt?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  picUri?: InputMaybe<SortOrder>;
  profitables?: InputMaybe<ProfitableOrderByRelationAggregateInput>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type ProfitableBadgetRelationFilter = {
  is?: InputMaybe<ProfitableBadgetWhereInput>;
  isNot?: InputMaybe<ProfitableBadgetWhereInput>;
};

export enum ProfitableBadgetScalarFieldEnum {
  CreatedAt = 'createdAt',
  Description = 'description',
  Id = 'id',
  PicUri = 'picUri',
  UpdatedAt = 'updatedAt'
}

export type ProfitableBadgetScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<ProfitableBadgetScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<ProfitableBadgetScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<ProfitableBadgetScalarWhereWithAggregatesInput>>;
  createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  description?: InputMaybe<StringNullableWithAggregatesFilter>;
  id?: InputMaybe<StringWithAggregatesFilter>;
  picUri?: InputMaybe<StringWithAggregatesFilter>;
  updatedAt?: InputMaybe<DateTimeWithAggregatesFilter>;
};

export type ProfitableBadgetUpdateInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  picUri?: InputMaybe<StringFieldUpdateOperationsInput>;
  profitables?: InputMaybe<ProfitableUpdateManyWithoutBadgetNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type ProfitableBadgetUpdateManyMutationInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  picUri?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type ProfitableBadgetUpdateOneWithoutProfitablesNestedInput = {
  connect?: InputMaybe<ProfitableBadgetWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ProfitableBadgetCreateOrConnectWithoutProfitablesInput>;
  create?: InputMaybe<ProfitableBadgetCreateWithoutProfitablesInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<ProfitableBadgetUpdateWithoutProfitablesInput>;
  upsert?: InputMaybe<ProfitableBadgetUpsertWithoutProfitablesInput>;
};

export type ProfitableBadgetUpdateWithoutProfitablesInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  picUri?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type ProfitableBadgetUpsertWithoutProfitablesInput = {
  create: ProfitableBadgetCreateWithoutProfitablesInput;
  update: ProfitableBadgetUpdateWithoutProfitablesInput;
};

export type ProfitableBadgetWhereInput = {
  AND?: InputMaybe<Array<ProfitableBadgetWhereInput>>;
  NOT?: InputMaybe<Array<ProfitableBadgetWhereInput>>;
  OR?: InputMaybe<Array<ProfitableBadgetWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  description?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<StringFilter>;
  picUri?: InputMaybe<StringFilter>;
  profitables?: InputMaybe<ProfitableListRelationFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type ProfitableBadgetWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
};

export enum ProfitableClass {
  HighQuality = 'highQuality',
  None = 'none'
}

export type ProfitableCount = {
  __typename?: 'ProfitableCount';
  benefitMemos: Scalars['Int'];
  benefitTransfers: Scalars['Int'];
  followedBy: Scalars['Int'];
  inValidReasonHistories: Scalars['Int'];
  saleRatioHistory: Scalars['Int'];
  sendRatioHistory: Scalars['Int'];
  storeItems: Scalars['Int'];
};


export type ProfitableCountBenefitMemosArgs = {
  where?: InputMaybe<BenefitMemoWhereInput>;
};


export type ProfitableCountBenefitTransfersArgs = {
  where?: InputMaybe<BenefitTransferWhereInput>;
};


export type ProfitableCountFollowedByArgs = {
  where?: InputMaybe<UserWhereInput>;
};


export type ProfitableCountInValidReasonHistoriesArgs = {
  where?: InputMaybe<InValidReasonWhereInput>;
};


export type ProfitableCountSaleRatioHistoryArgs = {
  where?: InputMaybe<StoreRatioHistoryItemWhereInput>;
};


export type ProfitableCountSendRatioHistoryArgs = {
  where?: InputMaybe<StoreRatioHistoryItemWhereInput>;
};


export type ProfitableCountStoreItemsArgs = {
  where?: InputMaybe<StoreItemWhereInput>;
};

export type ProfitableCountAggregate = {
  __typename?: 'ProfitableCountAggregate';
  _all: Scalars['Int'];
  badgetId: Scalars['Int'];
  beneficialInfo: Scalars['Int'];
  bio: Scalars['Int'];
  class: Scalars['Int'];
  createdAt: Scalars['Int'];
  id: Scalars['Int'];
  name: Scalars['Int'];
  saleCount: Scalars['Int'];
  saleRatioId: Scalars['Int'];
  sendRatioId: Scalars['Int'];
  statusText: Scalars['Int'];
  type: Scalars['Int'];
  updatedAt: Scalars['Int'];
  userId: Scalars['Int'];
  valid: Scalars['Int'];
};

export type ProfitableCountOrderByAggregateInput = {
  badgetId?: InputMaybe<SortOrder>;
  beneficialInfo?: InputMaybe<SortOrder>;
  bio?: InputMaybe<SortOrder>;
  class?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  saleCount?: InputMaybe<SortOrder>;
  saleRatioId?: InputMaybe<SortOrder>;
  sendRatioId?: InputMaybe<SortOrder>;
  statusText?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
  valid?: InputMaybe<SortOrder>;
};

export type ProfitableCreateInput = {
  badget?: InputMaybe<ProfitableBadgetCreateNestedOneWithoutProfitablesInput>;
  beneficialInfo?: InputMaybe<Scalars['JSON']>;
  benefitMemos?: InputMaybe<BenefitMemoCreateNestedManyWithoutProfitableInput>;
  benefitTransfers?: InputMaybe<BenefitTransferCreateNestedManyWithoutProfitableInput>;
  bio?: InputMaybe<Scalars['String']>;
  class?: InputMaybe<ProfitableClass>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  followedBy?: InputMaybe<UserCreateNestedManyWithoutFollowProfitablesInput>;
  id?: InputMaybe<Scalars['String']>;
  inValidReasonHistories?: InputMaybe<InValidReasonCreateNestedManyWithoutProfitableInput>;
  name?: InputMaybe<Scalars['String']>;
  saleCount?: InputMaybe<Scalars['Int']>;
  saleRatio?: InputMaybe<StoreRatioCreateNestedOneWithoutInSaleRatioInput>;
  saleRatioHistory?: InputMaybe<StoreRatioHistoryItemCreateNestedManyWithoutInSaleRatioHistoryInput>;
  sendRatio?: InputMaybe<StoreRatioCreateNestedOneWithoutInSendRatioInput>;
  sendRatioHistory?: InputMaybe<StoreRatioHistoryItemCreateNestedManyWithoutInSendRatioHistoryInput>;
  statusText?: InputMaybe<Scalars['String']>;
  storeItems?: InputMaybe<StoreItemCreateNestedManyWithoutProfitableInput>;
  type: ProfitableType;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  user: UserCreateNestedOneWithoutProfitableInput;
  valid?: InputMaybe<Scalars['Boolean']>;
};

export type ProfitableCreateManyBadgetInput = {
  beneficialInfo?: InputMaybe<Scalars['JSON']>;
  bio?: InputMaybe<Scalars['String']>;
  class?: InputMaybe<ProfitableClass>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  saleCount?: InputMaybe<Scalars['Int']>;
  saleRatioId?: InputMaybe<Scalars['String']>;
  sendRatioId?: InputMaybe<Scalars['String']>;
  statusText?: InputMaybe<Scalars['String']>;
  type: ProfitableType;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userId: Scalars['String'];
  valid?: InputMaybe<Scalars['Boolean']>;
};

export type ProfitableCreateManyBadgetInputEnvelope = {
  data: Array<ProfitableCreateManyBadgetInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type ProfitableCreateManyInput = {
  badgetId?: InputMaybe<Scalars['String']>;
  beneficialInfo?: InputMaybe<Scalars['JSON']>;
  bio?: InputMaybe<Scalars['String']>;
  class?: InputMaybe<ProfitableClass>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  saleCount?: InputMaybe<Scalars['Int']>;
  saleRatioId?: InputMaybe<Scalars['String']>;
  sendRatioId?: InputMaybe<Scalars['String']>;
  statusText?: InputMaybe<Scalars['String']>;
  type: ProfitableType;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userId: Scalars['String'];
  valid?: InputMaybe<Scalars['Boolean']>;
};

export type ProfitableCreateManySaleRatioInput = {
  badgetId?: InputMaybe<Scalars['String']>;
  beneficialInfo?: InputMaybe<Scalars['JSON']>;
  bio?: InputMaybe<Scalars['String']>;
  class?: InputMaybe<ProfitableClass>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  saleCount?: InputMaybe<Scalars['Int']>;
  sendRatioId?: InputMaybe<Scalars['String']>;
  statusText?: InputMaybe<Scalars['String']>;
  type: ProfitableType;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userId: Scalars['String'];
  valid?: InputMaybe<Scalars['Boolean']>;
};

export type ProfitableCreateManySaleRatioInputEnvelope = {
  data: Array<ProfitableCreateManySaleRatioInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type ProfitableCreateManySendRatioInput = {
  badgetId?: InputMaybe<Scalars['String']>;
  beneficialInfo?: InputMaybe<Scalars['JSON']>;
  bio?: InputMaybe<Scalars['String']>;
  class?: InputMaybe<ProfitableClass>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  saleCount?: InputMaybe<Scalars['Int']>;
  saleRatioId?: InputMaybe<Scalars['String']>;
  statusText?: InputMaybe<Scalars['String']>;
  type: ProfitableType;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userId: Scalars['String'];
  valid?: InputMaybe<Scalars['Boolean']>;
};

export type ProfitableCreateManySendRatioInputEnvelope = {
  data: Array<ProfitableCreateManySendRatioInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type ProfitableCreateNestedManyWithoutBadgetInput = {
  connect?: InputMaybe<Array<ProfitableWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ProfitableCreateOrConnectWithoutBadgetInput>>;
  create?: InputMaybe<Array<ProfitableCreateWithoutBadgetInput>>;
  createMany?: InputMaybe<ProfitableCreateManyBadgetInputEnvelope>;
};

export type ProfitableCreateNestedManyWithoutFollowedByInput = {
  connect?: InputMaybe<Array<ProfitableWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ProfitableCreateOrConnectWithoutFollowedByInput>>;
  create?: InputMaybe<Array<ProfitableCreateWithoutFollowedByInput>>;
};

export type ProfitableCreateNestedManyWithoutSaleRatioInput = {
  connect?: InputMaybe<Array<ProfitableWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ProfitableCreateOrConnectWithoutSaleRatioInput>>;
  create?: InputMaybe<Array<ProfitableCreateWithoutSaleRatioInput>>;
  createMany?: InputMaybe<ProfitableCreateManySaleRatioInputEnvelope>;
};

export type ProfitableCreateNestedManyWithoutSendRatioInput = {
  connect?: InputMaybe<Array<ProfitableWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ProfitableCreateOrConnectWithoutSendRatioInput>>;
  create?: InputMaybe<Array<ProfitableCreateWithoutSendRatioInput>>;
  createMany?: InputMaybe<ProfitableCreateManySendRatioInputEnvelope>;
};

export type ProfitableCreateNestedOneWithoutBenefitMemosInput = {
  connect?: InputMaybe<ProfitableWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ProfitableCreateOrConnectWithoutBenefitMemosInput>;
  create?: InputMaybe<ProfitableCreateWithoutBenefitMemosInput>;
};

export type ProfitableCreateNestedOneWithoutBenefitTransfersInput = {
  connect?: InputMaybe<ProfitableWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ProfitableCreateOrConnectWithoutBenefitTransfersInput>;
  create?: InputMaybe<ProfitableCreateWithoutBenefitTransfersInput>;
};

export type ProfitableCreateNestedOneWithoutInValidReasonHistoriesInput = {
  connect?: InputMaybe<ProfitableWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ProfitableCreateOrConnectWithoutInValidReasonHistoriesInput>;
  create?: InputMaybe<ProfitableCreateWithoutInValidReasonHistoriesInput>;
};

export type ProfitableCreateNestedOneWithoutSaleRatioHistoryInput = {
  connect?: InputMaybe<ProfitableWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ProfitableCreateOrConnectWithoutSaleRatioHistoryInput>;
  create?: InputMaybe<ProfitableCreateWithoutSaleRatioHistoryInput>;
};

export type ProfitableCreateNestedOneWithoutSendRatioHistoryInput = {
  connect?: InputMaybe<ProfitableWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ProfitableCreateOrConnectWithoutSendRatioHistoryInput>;
  create?: InputMaybe<ProfitableCreateWithoutSendRatioHistoryInput>;
};

export type ProfitableCreateNestedOneWithoutStoreItemsInput = {
  connect?: InputMaybe<ProfitableWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ProfitableCreateOrConnectWithoutStoreItemsInput>;
  create?: InputMaybe<ProfitableCreateWithoutStoreItemsInput>;
};

export type ProfitableCreateNestedOneWithoutUserInput = {
  connect?: InputMaybe<ProfitableWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ProfitableCreateOrConnectWithoutUserInput>;
  create?: InputMaybe<ProfitableCreateWithoutUserInput>;
};

export type ProfitableCreateOrConnectWithoutBadgetInput = {
  create: ProfitableCreateWithoutBadgetInput;
  where: ProfitableWhereUniqueInput;
};

export type ProfitableCreateOrConnectWithoutBenefitMemosInput = {
  create: ProfitableCreateWithoutBenefitMemosInput;
  where: ProfitableWhereUniqueInput;
};

export type ProfitableCreateOrConnectWithoutBenefitTransfersInput = {
  create: ProfitableCreateWithoutBenefitTransfersInput;
  where: ProfitableWhereUniqueInput;
};

export type ProfitableCreateOrConnectWithoutFollowedByInput = {
  create: ProfitableCreateWithoutFollowedByInput;
  where: ProfitableWhereUniqueInput;
};

export type ProfitableCreateOrConnectWithoutInValidReasonHistoriesInput = {
  create: ProfitableCreateWithoutInValidReasonHistoriesInput;
  where: ProfitableWhereUniqueInput;
};

export type ProfitableCreateOrConnectWithoutSaleRatioHistoryInput = {
  create: ProfitableCreateWithoutSaleRatioHistoryInput;
  where: ProfitableWhereUniqueInput;
};

export type ProfitableCreateOrConnectWithoutSaleRatioInput = {
  create: ProfitableCreateWithoutSaleRatioInput;
  where: ProfitableWhereUniqueInput;
};

export type ProfitableCreateOrConnectWithoutSendRatioHistoryInput = {
  create: ProfitableCreateWithoutSendRatioHistoryInput;
  where: ProfitableWhereUniqueInput;
};

export type ProfitableCreateOrConnectWithoutSendRatioInput = {
  create: ProfitableCreateWithoutSendRatioInput;
  where: ProfitableWhereUniqueInput;
};

export type ProfitableCreateOrConnectWithoutStoreItemsInput = {
  create: ProfitableCreateWithoutStoreItemsInput;
  where: ProfitableWhereUniqueInput;
};

export type ProfitableCreateOrConnectWithoutUserInput = {
  create: ProfitableCreateWithoutUserInput;
  where: ProfitableWhereUniqueInput;
};

export type ProfitableCreateWithoutBadgetInput = {
  beneficialInfo?: InputMaybe<Scalars['JSON']>;
  benefitMemos?: InputMaybe<BenefitMemoCreateNestedManyWithoutProfitableInput>;
  benefitTransfers?: InputMaybe<BenefitTransferCreateNestedManyWithoutProfitableInput>;
  bio?: InputMaybe<Scalars['String']>;
  class?: InputMaybe<ProfitableClass>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  followedBy?: InputMaybe<UserCreateNestedManyWithoutFollowProfitablesInput>;
  id?: InputMaybe<Scalars['String']>;
  inValidReasonHistories?: InputMaybe<InValidReasonCreateNestedManyWithoutProfitableInput>;
  name?: InputMaybe<Scalars['String']>;
  saleCount?: InputMaybe<Scalars['Int']>;
  saleRatio?: InputMaybe<StoreRatioCreateNestedOneWithoutInSaleRatioInput>;
  saleRatioHistory?: InputMaybe<StoreRatioHistoryItemCreateNestedManyWithoutInSaleRatioHistoryInput>;
  sendRatio?: InputMaybe<StoreRatioCreateNestedOneWithoutInSendRatioInput>;
  sendRatioHistory?: InputMaybe<StoreRatioHistoryItemCreateNestedManyWithoutInSendRatioHistoryInput>;
  statusText?: InputMaybe<Scalars['String']>;
  storeItems?: InputMaybe<StoreItemCreateNestedManyWithoutProfitableInput>;
  type: ProfitableType;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  user: UserCreateNestedOneWithoutProfitableInput;
  valid?: InputMaybe<Scalars['Boolean']>;
};

export type ProfitableCreateWithoutBenefitMemosInput = {
  badget?: InputMaybe<ProfitableBadgetCreateNestedOneWithoutProfitablesInput>;
  beneficialInfo?: InputMaybe<Scalars['JSON']>;
  benefitTransfers?: InputMaybe<BenefitTransferCreateNestedManyWithoutProfitableInput>;
  bio?: InputMaybe<Scalars['String']>;
  class?: InputMaybe<ProfitableClass>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  followedBy?: InputMaybe<UserCreateNestedManyWithoutFollowProfitablesInput>;
  id?: InputMaybe<Scalars['String']>;
  inValidReasonHistories?: InputMaybe<InValidReasonCreateNestedManyWithoutProfitableInput>;
  name?: InputMaybe<Scalars['String']>;
  saleCount?: InputMaybe<Scalars['Int']>;
  saleRatio?: InputMaybe<StoreRatioCreateNestedOneWithoutInSaleRatioInput>;
  saleRatioHistory?: InputMaybe<StoreRatioHistoryItemCreateNestedManyWithoutInSaleRatioHistoryInput>;
  sendRatio?: InputMaybe<StoreRatioCreateNestedOneWithoutInSendRatioInput>;
  sendRatioHistory?: InputMaybe<StoreRatioHistoryItemCreateNestedManyWithoutInSendRatioHistoryInput>;
  statusText?: InputMaybe<Scalars['String']>;
  storeItems?: InputMaybe<StoreItemCreateNestedManyWithoutProfitableInput>;
  type: ProfitableType;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  user: UserCreateNestedOneWithoutProfitableInput;
  valid?: InputMaybe<Scalars['Boolean']>;
};

export type ProfitableCreateWithoutBenefitTransfersInput = {
  badget?: InputMaybe<ProfitableBadgetCreateNestedOneWithoutProfitablesInput>;
  beneficialInfo?: InputMaybe<Scalars['JSON']>;
  benefitMemos?: InputMaybe<BenefitMemoCreateNestedManyWithoutProfitableInput>;
  bio?: InputMaybe<Scalars['String']>;
  class?: InputMaybe<ProfitableClass>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  followedBy?: InputMaybe<UserCreateNestedManyWithoutFollowProfitablesInput>;
  id?: InputMaybe<Scalars['String']>;
  inValidReasonHistories?: InputMaybe<InValidReasonCreateNestedManyWithoutProfitableInput>;
  name?: InputMaybe<Scalars['String']>;
  saleCount?: InputMaybe<Scalars['Int']>;
  saleRatio?: InputMaybe<StoreRatioCreateNestedOneWithoutInSaleRatioInput>;
  saleRatioHistory?: InputMaybe<StoreRatioHistoryItemCreateNestedManyWithoutInSaleRatioHistoryInput>;
  sendRatio?: InputMaybe<StoreRatioCreateNestedOneWithoutInSendRatioInput>;
  sendRatioHistory?: InputMaybe<StoreRatioHistoryItemCreateNestedManyWithoutInSendRatioHistoryInput>;
  statusText?: InputMaybe<Scalars['String']>;
  storeItems?: InputMaybe<StoreItemCreateNestedManyWithoutProfitableInput>;
  type: ProfitableType;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  user: UserCreateNestedOneWithoutProfitableInput;
  valid?: InputMaybe<Scalars['Boolean']>;
};

export type ProfitableCreateWithoutFollowedByInput = {
  badget?: InputMaybe<ProfitableBadgetCreateNestedOneWithoutProfitablesInput>;
  beneficialInfo?: InputMaybe<Scalars['JSON']>;
  benefitMemos?: InputMaybe<BenefitMemoCreateNestedManyWithoutProfitableInput>;
  benefitTransfers?: InputMaybe<BenefitTransferCreateNestedManyWithoutProfitableInput>;
  bio?: InputMaybe<Scalars['String']>;
  class?: InputMaybe<ProfitableClass>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  inValidReasonHistories?: InputMaybe<InValidReasonCreateNestedManyWithoutProfitableInput>;
  name?: InputMaybe<Scalars['String']>;
  saleCount?: InputMaybe<Scalars['Int']>;
  saleRatio?: InputMaybe<StoreRatioCreateNestedOneWithoutInSaleRatioInput>;
  saleRatioHistory?: InputMaybe<StoreRatioHistoryItemCreateNestedManyWithoutInSaleRatioHistoryInput>;
  sendRatio?: InputMaybe<StoreRatioCreateNestedOneWithoutInSendRatioInput>;
  sendRatioHistory?: InputMaybe<StoreRatioHistoryItemCreateNestedManyWithoutInSendRatioHistoryInput>;
  statusText?: InputMaybe<Scalars['String']>;
  storeItems?: InputMaybe<StoreItemCreateNestedManyWithoutProfitableInput>;
  type: ProfitableType;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  user: UserCreateNestedOneWithoutProfitableInput;
  valid?: InputMaybe<Scalars['Boolean']>;
};

export type ProfitableCreateWithoutInValidReasonHistoriesInput = {
  badget?: InputMaybe<ProfitableBadgetCreateNestedOneWithoutProfitablesInput>;
  beneficialInfo?: InputMaybe<Scalars['JSON']>;
  benefitMemos?: InputMaybe<BenefitMemoCreateNestedManyWithoutProfitableInput>;
  benefitTransfers?: InputMaybe<BenefitTransferCreateNestedManyWithoutProfitableInput>;
  bio?: InputMaybe<Scalars['String']>;
  class?: InputMaybe<ProfitableClass>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  followedBy?: InputMaybe<UserCreateNestedManyWithoutFollowProfitablesInput>;
  id?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  saleCount?: InputMaybe<Scalars['Int']>;
  saleRatio?: InputMaybe<StoreRatioCreateNestedOneWithoutInSaleRatioInput>;
  saleRatioHistory?: InputMaybe<StoreRatioHistoryItemCreateNestedManyWithoutInSaleRatioHistoryInput>;
  sendRatio?: InputMaybe<StoreRatioCreateNestedOneWithoutInSendRatioInput>;
  sendRatioHistory?: InputMaybe<StoreRatioHistoryItemCreateNestedManyWithoutInSendRatioHistoryInput>;
  statusText?: InputMaybe<Scalars['String']>;
  storeItems?: InputMaybe<StoreItemCreateNestedManyWithoutProfitableInput>;
  type: ProfitableType;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  user: UserCreateNestedOneWithoutProfitableInput;
  valid?: InputMaybe<Scalars['Boolean']>;
};

export type ProfitableCreateWithoutSaleRatioHistoryInput = {
  badget?: InputMaybe<ProfitableBadgetCreateNestedOneWithoutProfitablesInput>;
  beneficialInfo?: InputMaybe<Scalars['JSON']>;
  benefitMemos?: InputMaybe<BenefitMemoCreateNestedManyWithoutProfitableInput>;
  benefitTransfers?: InputMaybe<BenefitTransferCreateNestedManyWithoutProfitableInput>;
  bio?: InputMaybe<Scalars['String']>;
  class?: InputMaybe<ProfitableClass>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  followedBy?: InputMaybe<UserCreateNestedManyWithoutFollowProfitablesInput>;
  id?: InputMaybe<Scalars['String']>;
  inValidReasonHistories?: InputMaybe<InValidReasonCreateNestedManyWithoutProfitableInput>;
  name?: InputMaybe<Scalars['String']>;
  saleCount?: InputMaybe<Scalars['Int']>;
  saleRatio?: InputMaybe<StoreRatioCreateNestedOneWithoutInSaleRatioInput>;
  sendRatio?: InputMaybe<StoreRatioCreateNestedOneWithoutInSendRatioInput>;
  sendRatioHistory?: InputMaybe<StoreRatioHistoryItemCreateNestedManyWithoutInSendRatioHistoryInput>;
  statusText?: InputMaybe<Scalars['String']>;
  storeItems?: InputMaybe<StoreItemCreateNestedManyWithoutProfitableInput>;
  type: ProfitableType;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  user: UserCreateNestedOneWithoutProfitableInput;
  valid?: InputMaybe<Scalars['Boolean']>;
};

export type ProfitableCreateWithoutSaleRatioInput = {
  badget?: InputMaybe<ProfitableBadgetCreateNestedOneWithoutProfitablesInput>;
  beneficialInfo?: InputMaybe<Scalars['JSON']>;
  benefitMemos?: InputMaybe<BenefitMemoCreateNestedManyWithoutProfitableInput>;
  benefitTransfers?: InputMaybe<BenefitTransferCreateNestedManyWithoutProfitableInput>;
  bio?: InputMaybe<Scalars['String']>;
  class?: InputMaybe<ProfitableClass>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  followedBy?: InputMaybe<UserCreateNestedManyWithoutFollowProfitablesInput>;
  id?: InputMaybe<Scalars['String']>;
  inValidReasonHistories?: InputMaybe<InValidReasonCreateNestedManyWithoutProfitableInput>;
  name?: InputMaybe<Scalars['String']>;
  saleCount?: InputMaybe<Scalars['Int']>;
  saleRatioHistory?: InputMaybe<StoreRatioHistoryItemCreateNestedManyWithoutInSaleRatioHistoryInput>;
  sendRatio?: InputMaybe<StoreRatioCreateNestedOneWithoutInSendRatioInput>;
  sendRatioHistory?: InputMaybe<StoreRatioHistoryItemCreateNestedManyWithoutInSendRatioHistoryInput>;
  statusText?: InputMaybe<Scalars['String']>;
  storeItems?: InputMaybe<StoreItemCreateNestedManyWithoutProfitableInput>;
  type: ProfitableType;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  user: UserCreateNestedOneWithoutProfitableInput;
  valid?: InputMaybe<Scalars['Boolean']>;
};

export type ProfitableCreateWithoutSendRatioHistoryInput = {
  badget?: InputMaybe<ProfitableBadgetCreateNestedOneWithoutProfitablesInput>;
  beneficialInfo?: InputMaybe<Scalars['JSON']>;
  benefitMemos?: InputMaybe<BenefitMemoCreateNestedManyWithoutProfitableInput>;
  benefitTransfers?: InputMaybe<BenefitTransferCreateNestedManyWithoutProfitableInput>;
  bio?: InputMaybe<Scalars['String']>;
  class?: InputMaybe<ProfitableClass>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  followedBy?: InputMaybe<UserCreateNestedManyWithoutFollowProfitablesInput>;
  id?: InputMaybe<Scalars['String']>;
  inValidReasonHistories?: InputMaybe<InValidReasonCreateNestedManyWithoutProfitableInput>;
  name?: InputMaybe<Scalars['String']>;
  saleCount?: InputMaybe<Scalars['Int']>;
  saleRatio?: InputMaybe<StoreRatioCreateNestedOneWithoutInSaleRatioInput>;
  saleRatioHistory?: InputMaybe<StoreRatioHistoryItemCreateNestedManyWithoutInSaleRatioHistoryInput>;
  sendRatio?: InputMaybe<StoreRatioCreateNestedOneWithoutInSendRatioInput>;
  statusText?: InputMaybe<Scalars['String']>;
  storeItems?: InputMaybe<StoreItemCreateNestedManyWithoutProfitableInput>;
  type: ProfitableType;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  user: UserCreateNestedOneWithoutProfitableInput;
  valid?: InputMaybe<Scalars['Boolean']>;
};

export type ProfitableCreateWithoutSendRatioInput = {
  badget?: InputMaybe<ProfitableBadgetCreateNestedOneWithoutProfitablesInput>;
  beneficialInfo?: InputMaybe<Scalars['JSON']>;
  benefitMemos?: InputMaybe<BenefitMemoCreateNestedManyWithoutProfitableInput>;
  benefitTransfers?: InputMaybe<BenefitTransferCreateNestedManyWithoutProfitableInput>;
  bio?: InputMaybe<Scalars['String']>;
  class?: InputMaybe<ProfitableClass>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  followedBy?: InputMaybe<UserCreateNestedManyWithoutFollowProfitablesInput>;
  id?: InputMaybe<Scalars['String']>;
  inValidReasonHistories?: InputMaybe<InValidReasonCreateNestedManyWithoutProfitableInput>;
  name?: InputMaybe<Scalars['String']>;
  saleCount?: InputMaybe<Scalars['Int']>;
  saleRatio?: InputMaybe<StoreRatioCreateNestedOneWithoutInSaleRatioInput>;
  saleRatioHistory?: InputMaybe<StoreRatioHistoryItemCreateNestedManyWithoutInSaleRatioHistoryInput>;
  sendRatioHistory?: InputMaybe<StoreRatioHistoryItemCreateNestedManyWithoutInSendRatioHistoryInput>;
  statusText?: InputMaybe<Scalars['String']>;
  storeItems?: InputMaybe<StoreItemCreateNestedManyWithoutProfitableInput>;
  type: ProfitableType;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  user: UserCreateNestedOneWithoutProfitableInput;
  valid?: InputMaybe<Scalars['Boolean']>;
};

export type ProfitableCreateWithoutStoreItemsInput = {
  badget?: InputMaybe<ProfitableBadgetCreateNestedOneWithoutProfitablesInput>;
  beneficialInfo?: InputMaybe<Scalars['JSON']>;
  benefitMemos?: InputMaybe<BenefitMemoCreateNestedManyWithoutProfitableInput>;
  benefitTransfers?: InputMaybe<BenefitTransferCreateNestedManyWithoutProfitableInput>;
  bio?: InputMaybe<Scalars['String']>;
  class?: InputMaybe<ProfitableClass>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  followedBy?: InputMaybe<UserCreateNestedManyWithoutFollowProfitablesInput>;
  id?: InputMaybe<Scalars['String']>;
  inValidReasonHistories?: InputMaybe<InValidReasonCreateNestedManyWithoutProfitableInput>;
  name?: InputMaybe<Scalars['String']>;
  saleCount?: InputMaybe<Scalars['Int']>;
  saleRatio?: InputMaybe<StoreRatioCreateNestedOneWithoutInSaleRatioInput>;
  saleRatioHistory?: InputMaybe<StoreRatioHistoryItemCreateNestedManyWithoutInSaleRatioHistoryInput>;
  sendRatio?: InputMaybe<StoreRatioCreateNestedOneWithoutInSendRatioInput>;
  sendRatioHistory?: InputMaybe<StoreRatioHistoryItemCreateNestedManyWithoutInSendRatioHistoryInput>;
  statusText?: InputMaybe<Scalars['String']>;
  type: ProfitableType;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  user: UserCreateNestedOneWithoutProfitableInput;
  valid?: InputMaybe<Scalars['Boolean']>;
};

export type ProfitableCreateWithoutUserInput = {
  badget?: InputMaybe<ProfitableBadgetCreateNestedOneWithoutProfitablesInput>;
  beneficialInfo?: InputMaybe<Scalars['JSON']>;
  benefitMemos?: InputMaybe<BenefitMemoCreateNestedManyWithoutProfitableInput>;
  benefitTransfers?: InputMaybe<BenefitTransferCreateNestedManyWithoutProfitableInput>;
  bio?: InputMaybe<Scalars['String']>;
  class?: InputMaybe<ProfitableClass>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  followedBy?: InputMaybe<UserCreateNestedManyWithoutFollowProfitablesInput>;
  id?: InputMaybe<Scalars['String']>;
  inValidReasonHistories?: InputMaybe<InValidReasonCreateNestedManyWithoutProfitableInput>;
  name?: InputMaybe<Scalars['String']>;
  saleCount?: InputMaybe<Scalars['Int']>;
  saleRatio?: InputMaybe<StoreRatioCreateNestedOneWithoutInSaleRatioInput>;
  saleRatioHistory?: InputMaybe<StoreRatioHistoryItemCreateNestedManyWithoutInSaleRatioHistoryInput>;
  sendRatio?: InputMaybe<StoreRatioCreateNestedOneWithoutInSendRatioInput>;
  sendRatioHistory?: InputMaybe<StoreRatioHistoryItemCreateNestedManyWithoutInSendRatioHistoryInput>;
  statusText?: InputMaybe<Scalars['String']>;
  storeItems?: InputMaybe<StoreItemCreateNestedManyWithoutProfitableInput>;
  type: ProfitableType;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  valid?: InputMaybe<Scalars['Boolean']>;
};

export type ProfitableGroupBy = {
  __typename?: 'ProfitableGroupBy';
  _avg?: Maybe<ProfitableAvgAggregate>;
  _count?: Maybe<ProfitableCountAggregate>;
  _max?: Maybe<ProfitableMaxAggregate>;
  _min?: Maybe<ProfitableMinAggregate>;
  _sum?: Maybe<ProfitableSumAggregate>;
  badgetId?: Maybe<Scalars['String']>;
  beneficialInfo?: Maybe<Scalars['JSON']>;
  bio?: Maybe<Scalars['String']>;
  class: ProfitableClass;
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  saleCount: Scalars['Int'];
  saleRatioId?: Maybe<Scalars['String']>;
  sendRatioId?: Maybe<Scalars['String']>;
  statusText?: Maybe<Scalars['String']>;
  type: ProfitableType;
  updatedAt: Scalars['DateTime'];
  userId: Scalars['String'];
  valid: Scalars['Boolean'];
};

export type ProfitableListRelationFilter = {
  every?: InputMaybe<ProfitableWhereInput>;
  none?: InputMaybe<ProfitableWhereInput>;
  some?: InputMaybe<ProfitableWhereInput>;
};

export type ProfitableMaxAggregate = {
  __typename?: 'ProfitableMaxAggregate';
  badgetId?: Maybe<Scalars['String']>;
  bio?: Maybe<Scalars['String']>;
  class?: Maybe<ProfitableClass>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  saleCount?: Maybe<Scalars['Int']>;
  saleRatioId?: Maybe<Scalars['String']>;
  sendRatioId?: Maybe<Scalars['String']>;
  statusText?: Maybe<Scalars['String']>;
  type?: Maybe<ProfitableType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
  valid?: Maybe<Scalars['Boolean']>;
};

export type ProfitableMaxOrderByAggregateInput = {
  badgetId?: InputMaybe<SortOrder>;
  bio?: InputMaybe<SortOrder>;
  class?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  saleCount?: InputMaybe<SortOrder>;
  saleRatioId?: InputMaybe<SortOrder>;
  sendRatioId?: InputMaybe<SortOrder>;
  statusText?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
  valid?: InputMaybe<SortOrder>;
};

export type ProfitableMinAggregate = {
  __typename?: 'ProfitableMinAggregate';
  badgetId?: Maybe<Scalars['String']>;
  bio?: Maybe<Scalars['String']>;
  class?: Maybe<ProfitableClass>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  saleCount?: Maybe<Scalars['Int']>;
  saleRatioId?: Maybe<Scalars['String']>;
  sendRatioId?: Maybe<Scalars['String']>;
  statusText?: Maybe<Scalars['String']>;
  type?: Maybe<ProfitableType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
  valid?: Maybe<Scalars['Boolean']>;
};

export type ProfitableMinOrderByAggregateInput = {
  badgetId?: InputMaybe<SortOrder>;
  bio?: InputMaybe<SortOrder>;
  class?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  saleCount?: InputMaybe<SortOrder>;
  saleRatioId?: InputMaybe<SortOrder>;
  sendRatioId?: InputMaybe<SortOrder>;
  statusText?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
  valid?: InputMaybe<SortOrder>;
};

export type ProfitableOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type ProfitableOrderByWithAggregationInput = {
  _avg?: InputMaybe<ProfitableAvgOrderByAggregateInput>;
  _count?: InputMaybe<ProfitableCountOrderByAggregateInput>;
  _max?: InputMaybe<ProfitableMaxOrderByAggregateInput>;
  _min?: InputMaybe<ProfitableMinOrderByAggregateInput>;
  _sum?: InputMaybe<ProfitableSumOrderByAggregateInput>;
  badgetId?: InputMaybe<SortOrder>;
  beneficialInfo?: InputMaybe<SortOrder>;
  bio?: InputMaybe<SortOrder>;
  class?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  saleCount?: InputMaybe<SortOrder>;
  saleRatioId?: InputMaybe<SortOrder>;
  sendRatioId?: InputMaybe<SortOrder>;
  statusText?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
  valid?: InputMaybe<SortOrder>;
};

export type ProfitableOrderByWithRelationInput = {
  badget?: InputMaybe<ProfitableBadgetOrderByWithRelationInput>;
  badgetId?: InputMaybe<SortOrder>;
  beneficialInfo?: InputMaybe<SortOrder>;
  benefitMemos?: InputMaybe<BenefitMemoOrderByRelationAggregateInput>;
  benefitTransfers?: InputMaybe<BenefitTransferOrderByRelationAggregateInput>;
  bio?: InputMaybe<SortOrder>;
  class?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  followedBy?: InputMaybe<UserOrderByRelationAggregateInput>;
  id?: InputMaybe<SortOrder>;
  inValidReasonHistories?: InputMaybe<InValidReasonOrderByRelationAggregateInput>;
  name?: InputMaybe<SortOrder>;
  saleCount?: InputMaybe<SortOrder>;
  saleRatio?: InputMaybe<StoreRatioOrderByWithRelationInput>;
  saleRatioHistory?: InputMaybe<StoreRatioHistoryItemOrderByRelationAggregateInput>;
  saleRatioId?: InputMaybe<SortOrder>;
  sendRatio?: InputMaybe<StoreRatioOrderByWithRelationInput>;
  sendRatioHistory?: InputMaybe<StoreRatioHistoryItemOrderByRelationAggregateInput>;
  sendRatioId?: InputMaybe<SortOrder>;
  statusText?: InputMaybe<SortOrder>;
  storeItems?: InputMaybe<StoreItemOrderByRelationAggregateInput>;
  type?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  user?: InputMaybe<UserOrderByWithRelationInput>;
  userId?: InputMaybe<SortOrder>;
  valid?: InputMaybe<SortOrder>;
};

export type ProfitableRelationFilter = {
  is?: InputMaybe<ProfitableWhereInput>;
  isNot?: InputMaybe<ProfitableWhereInput>;
};

export enum ProfitableScalarFieldEnum {
  BadgetId = 'badgetId',
  BeneficialInfo = 'beneficialInfo',
  Bio = 'bio',
  Class = 'class',
  CreatedAt = 'createdAt',
  Id = 'id',
  Name = 'name',
  SaleCount = 'saleCount',
  SaleRatioId = 'saleRatioId',
  SendRatioId = 'sendRatioId',
  StatusText = 'statusText',
  Type = 'type',
  UpdatedAt = 'updatedAt',
  UserId = 'userId',
  Valid = 'valid'
}

export type ProfitableScalarWhereInput = {
  AND?: InputMaybe<Array<ProfitableScalarWhereInput>>;
  NOT?: InputMaybe<Array<ProfitableScalarWhereInput>>;
  OR?: InputMaybe<Array<ProfitableScalarWhereInput>>;
  badgetId?: InputMaybe<StringNullableFilter>;
  beneficialInfo?: InputMaybe<JsonNullableFilter>;
  bio?: InputMaybe<StringNullableFilter>;
  class?: InputMaybe<EnumProfitableClassFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringNullableFilter>;
  saleCount?: InputMaybe<IntFilter>;
  saleRatioId?: InputMaybe<StringNullableFilter>;
  sendRatioId?: InputMaybe<StringNullableFilter>;
  statusText?: InputMaybe<StringNullableFilter>;
  type?: InputMaybe<EnumProfitableTypeFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  userId?: InputMaybe<StringFilter>;
  valid?: InputMaybe<BoolFilter>;
};

export type ProfitableScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<ProfitableScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<ProfitableScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<ProfitableScalarWhereWithAggregatesInput>>;
  badgetId?: InputMaybe<StringNullableWithAggregatesFilter>;
  beneficialInfo?: InputMaybe<JsonNullableWithAggregatesFilter>;
  bio?: InputMaybe<StringNullableWithAggregatesFilter>;
  class?: InputMaybe<EnumProfitableClassWithAggregatesFilter>;
  createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  id?: InputMaybe<StringWithAggregatesFilter>;
  name?: InputMaybe<StringNullableWithAggregatesFilter>;
  saleCount?: InputMaybe<IntWithAggregatesFilter>;
  saleRatioId?: InputMaybe<StringNullableWithAggregatesFilter>;
  sendRatioId?: InputMaybe<StringNullableWithAggregatesFilter>;
  statusText?: InputMaybe<StringNullableWithAggregatesFilter>;
  type?: InputMaybe<EnumProfitableTypeWithAggregatesFilter>;
  updatedAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  userId?: InputMaybe<StringWithAggregatesFilter>;
  valid?: InputMaybe<BoolWithAggregatesFilter>;
};

export type ProfitableSumAggregate = {
  __typename?: 'ProfitableSumAggregate';
  saleCount?: Maybe<Scalars['Int']>;
};

export type ProfitableSumOrderByAggregateInput = {
  saleCount?: InputMaybe<SortOrder>;
};

export enum ProfitableType {
  Company = 'company',
  NormalCreator = 'normalCreator',
  OfficialCorpCreator = 'officialCorpCreator'
}

export type ProfitableUpdateInput = {
  badget?: InputMaybe<ProfitableBadgetUpdateOneWithoutProfitablesNestedInput>;
  beneficialInfo?: InputMaybe<Scalars['JSON']>;
  benefitMemos?: InputMaybe<BenefitMemoUpdateManyWithoutProfitableNestedInput>;
  benefitTransfers?: InputMaybe<BenefitTransferUpdateManyWithoutProfitableNestedInput>;
  bio?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  class?: InputMaybe<EnumProfitableClassFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  followedBy?: InputMaybe<UserUpdateManyWithoutFollowProfitablesNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  inValidReasonHistories?: InputMaybe<InValidReasonUpdateManyWithoutProfitableNestedInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  saleCount?: InputMaybe<IntFieldUpdateOperationsInput>;
  saleRatio?: InputMaybe<StoreRatioUpdateOneWithoutInSaleRatioNestedInput>;
  saleRatioHistory?: InputMaybe<StoreRatioHistoryItemUpdateManyWithoutInSaleRatioHistoryNestedInput>;
  sendRatio?: InputMaybe<StoreRatioUpdateOneWithoutInSendRatioNestedInput>;
  sendRatioHistory?: InputMaybe<StoreRatioHistoryItemUpdateManyWithoutInSendRatioHistoryNestedInput>;
  statusText?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  storeItems?: InputMaybe<StoreItemUpdateManyWithoutProfitableNestedInput>;
  type?: InputMaybe<EnumProfitableTypeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutProfitableNestedInput>;
  valid?: InputMaybe<BoolFieldUpdateOperationsInput>;
};

export type ProfitableUpdateManyMutationInput = {
  beneficialInfo?: InputMaybe<Scalars['JSON']>;
  bio?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  class?: InputMaybe<EnumProfitableClassFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  saleCount?: InputMaybe<IntFieldUpdateOperationsInput>;
  statusText?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  type?: InputMaybe<EnumProfitableTypeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  valid?: InputMaybe<BoolFieldUpdateOperationsInput>;
};

export type ProfitableUpdateManyWithWhereWithoutBadgetInput = {
  data: ProfitableUpdateManyMutationInput;
  where: ProfitableScalarWhereInput;
};

export type ProfitableUpdateManyWithWhereWithoutFollowedByInput = {
  data: ProfitableUpdateManyMutationInput;
  where: ProfitableScalarWhereInput;
};

export type ProfitableUpdateManyWithWhereWithoutSaleRatioInput = {
  data: ProfitableUpdateManyMutationInput;
  where: ProfitableScalarWhereInput;
};

export type ProfitableUpdateManyWithWhereWithoutSendRatioInput = {
  data: ProfitableUpdateManyMutationInput;
  where: ProfitableScalarWhereInput;
};

export type ProfitableUpdateManyWithoutBadgetNestedInput = {
  connect?: InputMaybe<Array<ProfitableWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ProfitableCreateOrConnectWithoutBadgetInput>>;
  create?: InputMaybe<Array<ProfitableCreateWithoutBadgetInput>>;
  createMany?: InputMaybe<ProfitableCreateManyBadgetInputEnvelope>;
  delete?: InputMaybe<Array<ProfitableWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ProfitableScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ProfitableWhereUniqueInput>>;
  set?: InputMaybe<Array<ProfitableWhereUniqueInput>>;
  update?: InputMaybe<Array<ProfitableUpdateWithWhereUniqueWithoutBadgetInput>>;
  updateMany?: InputMaybe<Array<ProfitableUpdateManyWithWhereWithoutBadgetInput>>;
  upsert?: InputMaybe<Array<ProfitableUpsertWithWhereUniqueWithoutBadgetInput>>;
};

export type ProfitableUpdateManyWithoutFollowedByNestedInput = {
  connect?: InputMaybe<Array<ProfitableWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ProfitableCreateOrConnectWithoutFollowedByInput>>;
  create?: InputMaybe<Array<ProfitableCreateWithoutFollowedByInput>>;
  delete?: InputMaybe<Array<ProfitableWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ProfitableScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ProfitableWhereUniqueInput>>;
  set?: InputMaybe<Array<ProfitableWhereUniqueInput>>;
  update?: InputMaybe<Array<ProfitableUpdateWithWhereUniqueWithoutFollowedByInput>>;
  updateMany?: InputMaybe<Array<ProfitableUpdateManyWithWhereWithoutFollowedByInput>>;
  upsert?: InputMaybe<Array<ProfitableUpsertWithWhereUniqueWithoutFollowedByInput>>;
};

export type ProfitableUpdateManyWithoutSaleRatioNestedInput = {
  connect?: InputMaybe<Array<ProfitableWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ProfitableCreateOrConnectWithoutSaleRatioInput>>;
  create?: InputMaybe<Array<ProfitableCreateWithoutSaleRatioInput>>;
  createMany?: InputMaybe<ProfitableCreateManySaleRatioInputEnvelope>;
  delete?: InputMaybe<Array<ProfitableWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ProfitableScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ProfitableWhereUniqueInput>>;
  set?: InputMaybe<Array<ProfitableWhereUniqueInput>>;
  update?: InputMaybe<Array<ProfitableUpdateWithWhereUniqueWithoutSaleRatioInput>>;
  updateMany?: InputMaybe<Array<ProfitableUpdateManyWithWhereWithoutSaleRatioInput>>;
  upsert?: InputMaybe<Array<ProfitableUpsertWithWhereUniqueWithoutSaleRatioInput>>;
};

export type ProfitableUpdateManyWithoutSendRatioNestedInput = {
  connect?: InputMaybe<Array<ProfitableWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ProfitableCreateOrConnectWithoutSendRatioInput>>;
  create?: InputMaybe<Array<ProfitableCreateWithoutSendRatioInput>>;
  createMany?: InputMaybe<ProfitableCreateManySendRatioInputEnvelope>;
  delete?: InputMaybe<Array<ProfitableWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ProfitableScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ProfitableWhereUniqueInput>>;
  set?: InputMaybe<Array<ProfitableWhereUniqueInput>>;
  update?: InputMaybe<Array<ProfitableUpdateWithWhereUniqueWithoutSendRatioInput>>;
  updateMany?: InputMaybe<Array<ProfitableUpdateManyWithWhereWithoutSendRatioInput>>;
  upsert?: InputMaybe<Array<ProfitableUpsertWithWhereUniqueWithoutSendRatioInput>>;
};

export type ProfitableUpdateOneRequiredWithoutBenefitMemosNestedInput = {
  connect?: InputMaybe<ProfitableWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ProfitableCreateOrConnectWithoutBenefitMemosInput>;
  create?: InputMaybe<ProfitableCreateWithoutBenefitMemosInput>;
  update?: InputMaybe<ProfitableUpdateWithoutBenefitMemosInput>;
  upsert?: InputMaybe<ProfitableUpsertWithoutBenefitMemosInput>;
};

export type ProfitableUpdateOneRequiredWithoutBenefitTransfersNestedInput = {
  connect?: InputMaybe<ProfitableWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ProfitableCreateOrConnectWithoutBenefitTransfersInput>;
  create?: InputMaybe<ProfitableCreateWithoutBenefitTransfersInput>;
  update?: InputMaybe<ProfitableUpdateWithoutBenefitTransfersInput>;
  upsert?: InputMaybe<ProfitableUpsertWithoutBenefitTransfersInput>;
};

export type ProfitableUpdateOneRequiredWithoutStoreItemsNestedInput = {
  connect?: InputMaybe<ProfitableWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ProfitableCreateOrConnectWithoutStoreItemsInput>;
  create?: InputMaybe<ProfitableCreateWithoutStoreItemsInput>;
  update?: InputMaybe<ProfitableUpdateWithoutStoreItemsInput>;
  upsert?: InputMaybe<ProfitableUpsertWithoutStoreItemsInput>;
};

export type ProfitableUpdateOneWithoutInValidReasonHistoriesNestedInput = {
  connect?: InputMaybe<ProfitableWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ProfitableCreateOrConnectWithoutInValidReasonHistoriesInput>;
  create?: InputMaybe<ProfitableCreateWithoutInValidReasonHistoriesInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<ProfitableUpdateWithoutInValidReasonHistoriesInput>;
  upsert?: InputMaybe<ProfitableUpsertWithoutInValidReasonHistoriesInput>;
};

export type ProfitableUpdateOneWithoutSaleRatioHistoryNestedInput = {
  connect?: InputMaybe<ProfitableWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ProfitableCreateOrConnectWithoutSaleRatioHistoryInput>;
  create?: InputMaybe<ProfitableCreateWithoutSaleRatioHistoryInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<ProfitableUpdateWithoutSaleRatioHistoryInput>;
  upsert?: InputMaybe<ProfitableUpsertWithoutSaleRatioHistoryInput>;
};

export type ProfitableUpdateOneWithoutSendRatioHistoryNestedInput = {
  connect?: InputMaybe<ProfitableWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ProfitableCreateOrConnectWithoutSendRatioHistoryInput>;
  create?: InputMaybe<ProfitableCreateWithoutSendRatioHistoryInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<ProfitableUpdateWithoutSendRatioHistoryInput>;
  upsert?: InputMaybe<ProfitableUpsertWithoutSendRatioHistoryInput>;
};

export type ProfitableUpdateOneWithoutUserNestedInput = {
  connect?: InputMaybe<ProfitableWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ProfitableCreateOrConnectWithoutUserInput>;
  create?: InputMaybe<ProfitableCreateWithoutUserInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<ProfitableUpdateWithoutUserInput>;
  upsert?: InputMaybe<ProfitableUpsertWithoutUserInput>;
};

export type ProfitableUpdateWithWhereUniqueWithoutBadgetInput = {
  data: ProfitableUpdateWithoutBadgetInput;
  where: ProfitableWhereUniqueInput;
};

export type ProfitableUpdateWithWhereUniqueWithoutFollowedByInput = {
  data: ProfitableUpdateWithoutFollowedByInput;
  where: ProfitableWhereUniqueInput;
};

export type ProfitableUpdateWithWhereUniqueWithoutSaleRatioInput = {
  data: ProfitableUpdateWithoutSaleRatioInput;
  where: ProfitableWhereUniqueInput;
};

export type ProfitableUpdateWithWhereUniqueWithoutSendRatioInput = {
  data: ProfitableUpdateWithoutSendRatioInput;
  where: ProfitableWhereUniqueInput;
};

export type ProfitableUpdateWithoutBadgetInput = {
  beneficialInfo?: InputMaybe<Scalars['JSON']>;
  benefitMemos?: InputMaybe<BenefitMemoUpdateManyWithoutProfitableNestedInput>;
  benefitTransfers?: InputMaybe<BenefitTransferUpdateManyWithoutProfitableNestedInput>;
  bio?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  class?: InputMaybe<EnumProfitableClassFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  followedBy?: InputMaybe<UserUpdateManyWithoutFollowProfitablesNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  inValidReasonHistories?: InputMaybe<InValidReasonUpdateManyWithoutProfitableNestedInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  saleCount?: InputMaybe<IntFieldUpdateOperationsInput>;
  saleRatio?: InputMaybe<StoreRatioUpdateOneWithoutInSaleRatioNestedInput>;
  saleRatioHistory?: InputMaybe<StoreRatioHistoryItemUpdateManyWithoutInSaleRatioHistoryNestedInput>;
  sendRatio?: InputMaybe<StoreRatioUpdateOneWithoutInSendRatioNestedInput>;
  sendRatioHistory?: InputMaybe<StoreRatioHistoryItemUpdateManyWithoutInSendRatioHistoryNestedInput>;
  statusText?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  storeItems?: InputMaybe<StoreItemUpdateManyWithoutProfitableNestedInput>;
  type?: InputMaybe<EnumProfitableTypeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutProfitableNestedInput>;
  valid?: InputMaybe<BoolFieldUpdateOperationsInput>;
};

export type ProfitableUpdateWithoutBenefitMemosInput = {
  badget?: InputMaybe<ProfitableBadgetUpdateOneWithoutProfitablesNestedInput>;
  beneficialInfo?: InputMaybe<Scalars['JSON']>;
  benefitTransfers?: InputMaybe<BenefitTransferUpdateManyWithoutProfitableNestedInput>;
  bio?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  class?: InputMaybe<EnumProfitableClassFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  followedBy?: InputMaybe<UserUpdateManyWithoutFollowProfitablesNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  inValidReasonHistories?: InputMaybe<InValidReasonUpdateManyWithoutProfitableNestedInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  saleCount?: InputMaybe<IntFieldUpdateOperationsInput>;
  saleRatio?: InputMaybe<StoreRatioUpdateOneWithoutInSaleRatioNestedInput>;
  saleRatioHistory?: InputMaybe<StoreRatioHistoryItemUpdateManyWithoutInSaleRatioHistoryNestedInput>;
  sendRatio?: InputMaybe<StoreRatioUpdateOneWithoutInSendRatioNestedInput>;
  sendRatioHistory?: InputMaybe<StoreRatioHistoryItemUpdateManyWithoutInSendRatioHistoryNestedInput>;
  statusText?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  storeItems?: InputMaybe<StoreItemUpdateManyWithoutProfitableNestedInput>;
  type?: InputMaybe<EnumProfitableTypeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutProfitableNestedInput>;
  valid?: InputMaybe<BoolFieldUpdateOperationsInput>;
};

export type ProfitableUpdateWithoutBenefitTransfersInput = {
  badget?: InputMaybe<ProfitableBadgetUpdateOneWithoutProfitablesNestedInput>;
  beneficialInfo?: InputMaybe<Scalars['JSON']>;
  benefitMemos?: InputMaybe<BenefitMemoUpdateManyWithoutProfitableNestedInput>;
  bio?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  class?: InputMaybe<EnumProfitableClassFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  followedBy?: InputMaybe<UserUpdateManyWithoutFollowProfitablesNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  inValidReasonHistories?: InputMaybe<InValidReasonUpdateManyWithoutProfitableNestedInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  saleCount?: InputMaybe<IntFieldUpdateOperationsInput>;
  saleRatio?: InputMaybe<StoreRatioUpdateOneWithoutInSaleRatioNestedInput>;
  saleRatioHistory?: InputMaybe<StoreRatioHistoryItemUpdateManyWithoutInSaleRatioHistoryNestedInput>;
  sendRatio?: InputMaybe<StoreRatioUpdateOneWithoutInSendRatioNestedInput>;
  sendRatioHistory?: InputMaybe<StoreRatioHistoryItemUpdateManyWithoutInSendRatioHistoryNestedInput>;
  statusText?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  storeItems?: InputMaybe<StoreItemUpdateManyWithoutProfitableNestedInput>;
  type?: InputMaybe<EnumProfitableTypeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutProfitableNestedInput>;
  valid?: InputMaybe<BoolFieldUpdateOperationsInput>;
};

export type ProfitableUpdateWithoutFollowedByInput = {
  badget?: InputMaybe<ProfitableBadgetUpdateOneWithoutProfitablesNestedInput>;
  beneficialInfo?: InputMaybe<Scalars['JSON']>;
  benefitMemos?: InputMaybe<BenefitMemoUpdateManyWithoutProfitableNestedInput>;
  benefitTransfers?: InputMaybe<BenefitTransferUpdateManyWithoutProfitableNestedInput>;
  bio?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  class?: InputMaybe<EnumProfitableClassFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  inValidReasonHistories?: InputMaybe<InValidReasonUpdateManyWithoutProfitableNestedInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  saleCount?: InputMaybe<IntFieldUpdateOperationsInput>;
  saleRatio?: InputMaybe<StoreRatioUpdateOneWithoutInSaleRatioNestedInput>;
  saleRatioHistory?: InputMaybe<StoreRatioHistoryItemUpdateManyWithoutInSaleRatioHistoryNestedInput>;
  sendRatio?: InputMaybe<StoreRatioUpdateOneWithoutInSendRatioNestedInput>;
  sendRatioHistory?: InputMaybe<StoreRatioHistoryItemUpdateManyWithoutInSendRatioHistoryNestedInput>;
  statusText?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  storeItems?: InputMaybe<StoreItemUpdateManyWithoutProfitableNestedInput>;
  type?: InputMaybe<EnumProfitableTypeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutProfitableNestedInput>;
  valid?: InputMaybe<BoolFieldUpdateOperationsInput>;
};

export type ProfitableUpdateWithoutInValidReasonHistoriesInput = {
  badget?: InputMaybe<ProfitableBadgetUpdateOneWithoutProfitablesNestedInput>;
  beneficialInfo?: InputMaybe<Scalars['JSON']>;
  benefitMemos?: InputMaybe<BenefitMemoUpdateManyWithoutProfitableNestedInput>;
  benefitTransfers?: InputMaybe<BenefitTransferUpdateManyWithoutProfitableNestedInput>;
  bio?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  class?: InputMaybe<EnumProfitableClassFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  followedBy?: InputMaybe<UserUpdateManyWithoutFollowProfitablesNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  saleCount?: InputMaybe<IntFieldUpdateOperationsInput>;
  saleRatio?: InputMaybe<StoreRatioUpdateOneWithoutInSaleRatioNestedInput>;
  saleRatioHistory?: InputMaybe<StoreRatioHistoryItemUpdateManyWithoutInSaleRatioHistoryNestedInput>;
  sendRatio?: InputMaybe<StoreRatioUpdateOneWithoutInSendRatioNestedInput>;
  sendRatioHistory?: InputMaybe<StoreRatioHistoryItemUpdateManyWithoutInSendRatioHistoryNestedInput>;
  statusText?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  storeItems?: InputMaybe<StoreItemUpdateManyWithoutProfitableNestedInput>;
  type?: InputMaybe<EnumProfitableTypeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutProfitableNestedInput>;
  valid?: InputMaybe<BoolFieldUpdateOperationsInput>;
};

export type ProfitableUpdateWithoutSaleRatioHistoryInput = {
  badget?: InputMaybe<ProfitableBadgetUpdateOneWithoutProfitablesNestedInput>;
  beneficialInfo?: InputMaybe<Scalars['JSON']>;
  benefitMemos?: InputMaybe<BenefitMemoUpdateManyWithoutProfitableNestedInput>;
  benefitTransfers?: InputMaybe<BenefitTransferUpdateManyWithoutProfitableNestedInput>;
  bio?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  class?: InputMaybe<EnumProfitableClassFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  followedBy?: InputMaybe<UserUpdateManyWithoutFollowProfitablesNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  inValidReasonHistories?: InputMaybe<InValidReasonUpdateManyWithoutProfitableNestedInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  saleCount?: InputMaybe<IntFieldUpdateOperationsInput>;
  saleRatio?: InputMaybe<StoreRatioUpdateOneWithoutInSaleRatioNestedInput>;
  sendRatio?: InputMaybe<StoreRatioUpdateOneWithoutInSendRatioNestedInput>;
  sendRatioHistory?: InputMaybe<StoreRatioHistoryItemUpdateManyWithoutInSendRatioHistoryNestedInput>;
  statusText?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  storeItems?: InputMaybe<StoreItemUpdateManyWithoutProfitableNestedInput>;
  type?: InputMaybe<EnumProfitableTypeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutProfitableNestedInput>;
  valid?: InputMaybe<BoolFieldUpdateOperationsInput>;
};

export type ProfitableUpdateWithoutSaleRatioInput = {
  badget?: InputMaybe<ProfitableBadgetUpdateOneWithoutProfitablesNestedInput>;
  beneficialInfo?: InputMaybe<Scalars['JSON']>;
  benefitMemos?: InputMaybe<BenefitMemoUpdateManyWithoutProfitableNestedInput>;
  benefitTransfers?: InputMaybe<BenefitTransferUpdateManyWithoutProfitableNestedInput>;
  bio?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  class?: InputMaybe<EnumProfitableClassFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  followedBy?: InputMaybe<UserUpdateManyWithoutFollowProfitablesNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  inValidReasonHistories?: InputMaybe<InValidReasonUpdateManyWithoutProfitableNestedInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  saleCount?: InputMaybe<IntFieldUpdateOperationsInput>;
  saleRatioHistory?: InputMaybe<StoreRatioHistoryItemUpdateManyWithoutInSaleRatioHistoryNestedInput>;
  sendRatio?: InputMaybe<StoreRatioUpdateOneWithoutInSendRatioNestedInput>;
  sendRatioHistory?: InputMaybe<StoreRatioHistoryItemUpdateManyWithoutInSendRatioHistoryNestedInput>;
  statusText?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  storeItems?: InputMaybe<StoreItemUpdateManyWithoutProfitableNestedInput>;
  type?: InputMaybe<EnumProfitableTypeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutProfitableNestedInput>;
  valid?: InputMaybe<BoolFieldUpdateOperationsInput>;
};

export type ProfitableUpdateWithoutSendRatioHistoryInput = {
  badget?: InputMaybe<ProfitableBadgetUpdateOneWithoutProfitablesNestedInput>;
  beneficialInfo?: InputMaybe<Scalars['JSON']>;
  benefitMemos?: InputMaybe<BenefitMemoUpdateManyWithoutProfitableNestedInput>;
  benefitTransfers?: InputMaybe<BenefitTransferUpdateManyWithoutProfitableNestedInput>;
  bio?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  class?: InputMaybe<EnumProfitableClassFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  followedBy?: InputMaybe<UserUpdateManyWithoutFollowProfitablesNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  inValidReasonHistories?: InputMaybe<InValidReasonUpdateManyWithoutProfitableNestedInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  saleCount?: InputMaybe<IntFieldUpdateOperationsInput>;
  saleRatio?: InputMaybe<StoreRatioUpdateOneWithoutInSaleRatioNestedInput>;
  saleRatioHistory?: InputMaybe<StoreRatioHistoryItemUpdateManyWithoutInSaleRatioHistoryNestedInput>;
  sendRatio?: InputMaybe<StoreRatioUpdateOneWithoutInSendRatioNestedInput>;
  statusText?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  storeItems?: InputMaybe<StoreItemUpdateManyWithoutProfitableNestedInput>;
  type?: InputMaybe<EnumProfitableTypeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutProfitableNestedInput>;
  valid?: InputMaybe<BoolFieldUpdateOperationsInput>;
};

export type ProfitableUpdateWithoutSendRatioInput = {
  badget?: InputMaybe<ProfitableBadgetUpdateOneWithoutProfitablesNestedInput>;
  beneficialInfo?: InputMaybe<Scalars['JSON']>;
  benefitMemos?: InputMaybe<BenefitMemoUpdateManyWithoutProfitableNestedInput>;
  benefitTransfers?: InputMaybe<BenefitTransferUpdateManyWithoutProfitableNestedInput>;
  bio?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  class?: InputMaybe<EnumProfitableClassFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  followedBy?: InputMaybe<UserUpdateManyWithoutFollowProfitablesNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  inValidReasonHistories?: InputMaybe<InValidReasonUpdateManyWithoutProfitableNestedInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  saleCount?: InputMaybe<IntFieldUpdateOperationsInput>;
  saleRatio?: InputMaybe<StoreRatioUpdateOneWithoutInSaleRatioNestedInput>;
  saleRatioHistory?: InputMaybe<StoreRatioHistoryItemUpdateManyWithoutInSaleRatioHistoryNestedInput>;
  sendRatioHistory?: InputMaybe<StoreRatioHistoryItemUpdateManyWithoutInSendRatioHistoryNestedInput>;
  statusText?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  storeItems?: InputMaybe<StoreItemUpdateManyWithoutProfitableNestedInput>;
  type?: InputMaybe<EnumProfitableTypeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutProfitableNestedInput>;
  valid?: InputMaybe<BoolFieldUpdateOperationsInput>;
};

export type ProfitableUpdateWithoutStoreItemsInput = {
  badget?: InputMaybe<ProfitableBadgetUpdateOneWithoutProfitablesNestedInput>;
  beneficialInfo?: InputMaybe<Scalars['JSON']>;
  benefitMemos?: InputMaybe<BenefitMemoUpdateManyWithoutProfitableNestedInput>;
  benefitTransfers?: InputMaybe<BenefitTransferUpdateManyWithoutProfitableNestedInput>;
  bio?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  class?: InputMaybe<EnumProfitableClassFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  followedBy?: InputMaybe<UserUpdateManyWithoutFollowProfitablesNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  inValidReasonHistories?: InputMaybe<InValidReasonUpdateManyWithoutProfitableNestedInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  saleCount?: InputMaybe<IntFieldUpdateOperationsInput>;
  saleRatio?: InputMaybe<StoreRatioUpdateOneWithoutInSaleRatioNestedInput>;
  saleRatioHistory?: InputMaybe<StoreRatioHistoryItemUpdateManyWithoutInSaleRatioHistoryNestedInput>;
  sendRatio?: InputMaybe<StoreRatioUpdateOneWithoutInSendRatioNestedInput>;
  sendRatioHistory?: InputMaybe<StoreRatioHistoryItemUpdateManyWithoutInSendRatioHistoryNestedInput>;
  statusText?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  type?: InputMaybe<EnumProfitableTypeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutProfitableNestedInput>;
  valid?: InputMaybe<BoolFieldUpdateOperationsInput>;
};

export type ProfitableUpdateWithoutUserInput = {
  badget?: InputMaybe<ProfitableBadgetUpdateOneWithoutProfitablesNestedInput>;
  beneficialInfo?: InputMaybe<Scalars['JSON']>;
  benefitMemos?: InputMaybe<BenefitMemoUpdateManyWithoutProfitableNestedInput>;
  benefitTransfers?: InputMaybe<BenefitTransferUpdateManyWithoutProfitableNestedInput>;
  bio?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  class?: InputMaybe<EnumProfitableClassFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  followedBy?: InputMaybe<UserUpdateManyWithoutFollowProfitablesNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  inValidReasonHistories?: InputMaybe<InValidReasonUpdateManyWithoutProfitableNestedInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  saleCount?: InputMaybe<IntFieldUpdateOperationsInput>;
  saleRatio?: InputMaybe<StoreRatioUpdateOneWithoutInSaleRatioNestedInput>;
  saleRatioHistory?: InputMaybe<StoreRatioHistoryItemUpdateManyWithoutInSaleRatioHistoryNestedInput>;
  sendRatio?: InputMaybe<StoreRatioUpdateOneWithoutInSendRatioNestedInput>;
  sendRatioHistory?: InputMaybe<StoreRatioHistoryItemUpdateManyWithoutInSendRatioHistoryNestedInput>;
  statusText?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  storeItems?: InputMaybe<StoreItemUpdateManyWithoutProfitableNestedInput>;
  type?: InputMaybe<EnumProfitableTypeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  valid?: InputMaybe<BoolFieldUpdateOperationsInput>;
};

export type ProfitableUpsertWithWhereUniqueWithoutBadgetInput = {
  create: ProfitableCreateWithoutBadgetInput;
  update: ProfitableUpdateWithoutBadgetInput;
  where: ProfitableWhereUniqueInput;
};

export type ProfitableUpsertWithWhereUniqueWithoutFollowedByInput = {
  create: ProfitableCreateWithoutFollowedByInput;
  update: ProfitableUpdateWithoutFollowedByInput;
  where: ProfitableWhereUniqueInput;
};

export type ProfitableUpsertWithWhereUniqueWithoutSaleRatioInput = {
  create: ProfitableCreateWithoutSaleRatioInput;
  update: ProfitableUpdateWithoutSaleRatioInput;
  where: ProfitableWhereUniqueInput;
};

export type ProfitableUpsertWithWhereUniqueWithoutSendRatioInput = {
  create: ProfitableCreateWithoutSendRatioInput;
  update: ProfitableUpdateWithoutSendRatioInput;
  where: ProfitableWhereUniqueInput;
};

export type ProfitableUpsertWithoutBenefitMemosInput = {
  create: ProfitableCreateWithoutBenefitMemosInput;
  update: ProfitableUpdateWithoutBenefitMemosInput;
};

export type ProfitableUpsertWithoutBenefitTransfersInput = {
  create: ProfitableCreateWithoutBenefitTransfersInput;
  update: ProfitableUpdateWithoutBenefitTransfersInput;
};

export type ProfitableUpsertWithoutInValidReasonHistoriesInput = {
  create: ProfitableCreateWithoutInValidReasonHistoriesInput;
  update: ProfitableUpdateWithoutInValidReasonHistoriesInput;
};

export type ProfitableUpsertWithoutSaleRatioHistoryInput = {
  create: ProfitableCreateWithoutSaleRatioHistoryInput;
  update: ProfitableUpdateWithoutSaleRatioHistoryInput;
};

export type ProfitableUpsertWithoutSendRatioHistoryInput = {
  create: ProfitableCreateWithoutSendRatioHistoryInput;
  update: ProfitableUpdateWithoutSendRatioHistoryInput;
};

export type ProfitableUpsertWithoutStoreItemsInput = {
  create: ProfitableCreateWithoutStoreItemsInput;
  update: ProfitableUpdateWithoutStoreItemsInput;
};

export type ProfitableUpsertWithoutUserInput = {
  create: ProfitableCreateWithoutUserInput;
  update: ProfitableUpdateWithoutUserInput;
};

export type ProfitableWhereInput = {
  AND?: InputMaybe<Array<ProfitableWhereInput>>;
  NOT?: InputMaybe<Array<ProfitableWhereInput>>;
  OR?: InputMaybe<Array<ProfitableWhereInput>>;
  badget?: InputMaybe<ProfitableBadgetRelationFilter>;
  badgetId?: InputMaybe<StringNullableFilter>;
  beneficialInfo?: InputMaybe<JsonNullableFilter>;
  benefitMemos?: InputMaybe<BenefitMemoListRelationFilter>;
  benefitTransfers?: InputMaybe<BenefitTransferListRelationFilter>;
  bio?: InputMaybe<StringNullableFilter>;
  class?: InputMaybe<EnumProfitableClassFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  followedBy?: InputMaybe<UserListRelationFilter>;
  id?: InputMaybe<StringFilter>;
  inValidReasonHistories?: InputMaybe<InValidReasonListRelationFilter>;
  name?: InputMaybe<StringNullableFilter>;
  saleCount?: InputMaybe<IntFilter>;
  saleRatio?: InputMaybe<StoreRatioRelationFilter>;
  saleRatioHistory?: InputMaybe<StoreRatioHistoryItemListRelationFilter>;
  saleRatioId?: InputMaybe<StringNullableFilter>;
  sendRatio?: InputMaybe<StoreRatioRelationFilter>;
  sendRatioHistory?: InputMaybe<StoreRatioHistoryItemListRelationFilter>;
  sendRatioId?: InputMaybe<StringNullableFilter>;
  statusText?: InputMaybe<StringNullableFilter>;
  storeItems?: InputMaybe<StoreItemListRelationFilter>;
  type?: InputMaybe<EnumProfitableTypeFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  user?: InputMaybe<UserRelationFilter>;
  userId?: InputMaybe<StringFilter>;
  valid?: InputMaybe<BoolFilter>;
};

export type ProfitableWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['String']>;
};

export type Qrcode = {
  __typename?: 'Qrcode';
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  serviceType: QrcodeServiceType;
  url: Scalars['String'];
};

export type QrcodeCountAggregate = {
  __typename?: 'QrcodeCountAggregate';
  _all: Scalars['Int'];
  createdAt: Scalars['Int'];
  id: Scalars['Int'];
  serviceType: Scalars['Int'];
  url: Scalars['Int'];
};

export type QrcodeCountOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  serviceType?: InputMaybe<SortOrder>;
  url?: InputMaybe<SortOrder>;
};

export type QrcodeCreateInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id: Scalars['String'];
  serviceType?: InputMaybe<QrcodeServiceType>;
  url: Scalars['String'];
};

export type QrcodeCreateManyInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id: Scalars['String'];
  serviceType?: InputMaybe<QrcodeServiceType>;
  url: Scalars['String'];
};

export type QrcodeGroupBy = {
  __typename?: 'QrcodeGroupBy';
  _count?: Maybe<QrcodeCountAggregate>;
  _max?: Maybe<QrcodeMaxAggregate>;
  _min?: Maybe<QrcodeMinAggregate>;
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  serviceType: QrcodeServiceType;
  url: Scalars['String'];
};

export type QrcodeMaxAggregate = {
  __typename?: 'QrcodeMaxAggregate';
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  serviceType?: Maybe<QrcodeServiceType>;
  url?: Maybe<Scalars['String']>;
};

export type QrcodeMaxOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  serviceType?: InputMaybe<SortOrder>;
  url?: InputMaybe<SortOrder>;
};

export type QrcodeMinAggregate = {
  __typename?: 'QrcodeMinAggregate';
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  serviceType?: Maybe<QrcodeServiceType>;
  url?: Maybe<Scalars['String']>;
};

export type QrcodeMinOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  serviceType?: InputMaybe<SortOrder>;
  url?: InputMaybe<SortOrder>;
};

export type QrcodeOrderByWithAggregationInput = {
  _count?: InputMaybe<QrcodeCountOrderByAggregateInput>;
  _max?: InputMaybe<QrcodeMaxOrderByAggregateInput>;
  _min?: InputMaybe<QrcodeMinOrderByAggregateInput>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  serviceType?: InputMaybe<SortOrder>;
  url?: InputMaybe<SortOrder>;
};

export type QrcodeOrderByWithRelationInput = {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  serviceType?: InputMaybe<SortOrder>;
  url?: InputMaybe<SortOrder>;
};

export enum QrcodeScalarFieldEnum {
  CreatedAt = 'createdAt',
  Id = 'id',
  ServiceType = 'serviceType',
  Url = 'url'
}

export type QrcodeScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<QrcodeScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<QrcodeScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<QrcodeScalarWhereWithAggregatesInput>>;
  createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  id?: InputMaybe<StringWithAggregatesFilter>;
  serviceType?: InputMaybe<EnumQrcodeServiceTypeWithAggregatesFilter>;
  url?: InputMaybe<StringWithAggregatesFilter>;
};

export enum QrcodeServiceType {
  Coupon = 'coupon',
  PocoinTopup = 'pocoinTopup',
  PostcardFree = 'postcardFree',
  PostcardRear = 'postcardRear',
  StampFree = 'stampFree'
}

export type QrcodeUpdateInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  serviceType?: InputMaybe<EnumQrcodeServiceTypeFieldUpdateOperationsInput>;
  url?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type QrcodeUpdateManyMutationInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  serviceType?: InputMaybe<EnumQrcodeServiceTypeFieldUpdateOperationsInput>;
  url?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type QrcodeWhereInput = {
  AND?: InputMaybe<Array<QrcodeWhereInput>>;
  NOT?: InputMaybe<Array<QrcodeWhereInput>>;
  OR?: InputMaybe<Array<QrcodeWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  serviceType?: InputMaybe<EnumQrcodeServiceTypeFilter>;
  url?: InputMaybe<StringFilter>;
};

export type QrcodeWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
  url?: InputMaybe<Scalars['String']>;
};

export type Query = {
  __typename?: 'Query';
  address?: Maybe<Address>;
  addresses: Array<Address>;
  advertisement?: Maybe<Advertisement>;
  advertisements: Array<Advertisement>;
  aggregateAddress: AggregateAddress;
  aggregateAdvertisement: AggregateAdvertisement;
  aggregateAttachment: AggregateAttachment;
  aggregateBenefitMemo: AggregateBenefitMemo;
  aggregateBenefitMemoToCoin: AggregateBenefitMemoToCoin;
  aggregateBenefitMemoToOrder: AggregateBenefitMemoToOrder;
  aggregateBenefitTransfer: AggregateBenefitTransfer;
  aggregateCampaignNanhui: AggregateCampaignNanhui;
  aggregateCampainZeczec: AggregateCampainZeczec;
  aggregateCard: AggregateCard;
  aggregateCartItem: AggregateCartItem;
  aggregateCoin: AggregateCoin;
  aggregateCoinPack: AggregateCoinPack;
  aggregateCollection: AggregateCollection;
  aggregateCouponActivity: AggregateCouponActivity;
  aggregateCouponEntity: AggregateCouponEntity;
  aggregateCouponGroup: AggregateCouponGroup;
  aggregateCouponInfo: AggregateCouponInfo;
  aggregateCouponUsage: AggregateCouponUsage;
  aggregateDeviceToken: AggregateDeviceToken;
  aggregateEcloudlife: AggregateEcloudlife;
  aggregateEcloudlifeTrack: AggregateEcloudlifeTrack;
  aggregateEmailTo: AggregateEmailTo;
  aggregateGroup: AggregateGroup;
  aggregateInValidReason: AggregateInValidReason;
  aggregateInvoice: AggregateInvoice;
  aggregateManualAddr: AggregateManualAddr;
  aggregateNotification: AggregateNotification;
  aggregateOrder: AggregateOrder;
  aggregateOrderDetail: AggregateOrderDetail;
  aggregateOrderDetailHistory: AggregateOrderDetailHistory;
  aggregateOrderTag: AggregateOrderTag;
  aggregatePayment: AggregatePayment;
  aggregatePost: AggregatePost;
  aggregatePostHistory: AggregatePostHistory;
  aggregatePostPackage: AggregatePostPackage;
  aggregatePostTag: AggregatePostTag;
  aggregateProduct: AggregateProduct;
  aggregateProfitable: AggregateProfitable;
  aggregateProfitableBadget: AggregateProfitableBadget;
  aggregateQrcode: AggregateQrcode;
  aggregateRemoteString: AggregateRemoteString;
  aggregateReport: AggregateReport;
  aggregateReportUser: AggregateReportUser;
  aggregateScenicSpot: AggregateScenicSpot;
  aggregateSettingNotification: AggregateSettingNotification;
  aggregateSettingNotificationSuppress: AggregateSettingNotificationSuppress;
  aggregateSettingPrivacy: AggregateSettingPrivacy;
  aggregateSignature: AggregateSignature;
  aggregateStamp: AggregateStamp;
  aggregateStoreItem: AggregateStoreItem;
  aggregateStoreItemTag: AggregateStoreItemTag;
  aggregateStoreItemToCartItem: AggregateStoreItemToCartItem;
  aggregateStoreRatio: AggregateStoreRatio;
  aggregateStoreRatioHistoryItem: AggregateStoreRatioHistoryItem;
  aggregateTappay: AggregateTappay;
  aggregateUser: AggregateUser;
  aggregateUserFriend: AggregateUserFriend;
  aggregateVirtualAddrMapping: AggregateVirtualAddrMapping;
  aggregateVirtualLand: AggregateVirtualLand;
  attachment?: Maybe<Attachment>;
  attachments: Array<Attachment>;
  benefitMemo?: Maybe<BenefitMemo>;
  benefitMemoToCoin?: Maybe<BenefitMemoToCoin>;
  benefitMemoToCoins: Array<BenefitMemoToCoin>;
  benefitMemoToOrder?: Maybe<BenefitMemoToOrder>;
  benefitMemoToOrders: Array<BenefitMemoToOrder>;
  benefitMemos: Array<BenefitMemo>;
  benefitTransfer?: Maybe<BenefitTransfer>;
  benefitTransfers: Array<BenefitTransfer>;
  campaignNanhui?: Maybe<CampaignNanhui>;
  campaignNanhuis: Array<CampaignNanhui>;
  campainZeczec?: Maybe<CampainZeczec>;
  campainZeczecs: Array<CampainZeczec>;
  card?: Maybe<Card>;
  cards: Array<Card>;
  cartItem?: Maybe<CartItem>;
  cartItems: Array<CartItem>;
  coin?: Maybe<Coin>;
  coinPack?: Maybe<CoinPack>;
  coinPacks: Array<CoinPack>;
  coins: Array<Coin>;
  collection?: Maybe<Collection>;
  collections: Array<Collection>;
  couponActivities: Array<CouponActivity>;
  couponActivity?: Maybe<CouponActivity>;
  couponEntities: Array<CouponEntity>;
  couponEntity?: Maybe<CouponEntity>;
  couponGroup?: Maybe<CouponGroup>;
  couponGroups: Array<CouponGroup>;
  couponInfo?: Maybe<CouponInfo>;
  couponInfos: Array<CouponInfo>;
  couponUsage?: Maybe<CouponUsage>;
  couponUsages: Array<CouponUsage>;
  deviceToken?: Maybe<DeviceToken>;
  deviceTokens: Array<DeviceToken>;
  ecloudlife?: Maybe<Ecloudlife>;
  ecloudlifeTrack?: Maybe<EcloudlifeTrack>;
  ecloudlifeTracks: Array<EcloudlifeTrack>;
  ecloudlives: Array<Ecloudlife>;
  emailTo?: Maybe<EmailTo>;
  emailTos: Array<EmailTo>;
  findFirstAddress?: Maybe<Address>;
  findFirstAddressOrThrow?: Maybe<Address>;
  findFirstAdvertisement?: Maybe<Advertisement>;
  findFirstAdvertisementOrThrow?: Maybe<Advertisement>;
  findFirstAttachment?: Maybe<Attachment>;
  findFirstAttachmentOrThrow?: Maybe<Attachment>;
  findFirstBenefitMemo?: Maybe<BenefitMemo>;
  findFirstBenefitMemoOrThrow?: Maybe<BenefitMemo>;
  findFirstBenefitMemoToCoin?: Maybe<BenefitMemoToCoin>;
  findFirstBenefitMemoToCoinOrThrow?: Maybe<BenefitMemoToCoin>;
  findFirstBenefitMemoToOrder?: Maybe<BenefitMemoToOrder>;
  findFirstBenefitMemoToOrderOrThrow?: Maybe<BenefitMemoToOrder>;
  findFirstBenefitTransfer?: Maybe<BenefitTransfer>;
  findFirstBenefitTransferOrThrow?: Maybe<BenefitTransfer>;
  findFirstCampaignNanhui?: Maybe<CampaignNanhui>;
  findFirstCampaignNanhuiOrThrow?: Maybe<CampaignNanhui>;
  findFirstCampainZeczec?: Maybe<CampainZeczec>;
  findFirstCampainZeczecOrThrow?: Maybe<CampainZeczec>;
  findFirstCard?: Maybe<Card>;
  findFirstCardOrThrow?: Maybe<Card>;
  findFirstCartItem?: Maybe<CartItem>;
  findFirstCartItemOrThrow?: Maybe<CartItem>;
  findFirstCoin?: Maybe<Coin>;
  findFirstCoinOrThrow?: Maybe<Coin>;
  findFirstCoinPack?: Maybe<CoinPack>;
  findFirstCoinPackOrThrow?: Maybe<CoinPack>;
  findFirstCollection?: Maybe<Collection>;
  findFirstCollectionOrThrow?: Maybe<Collection>;
  findFirstCouponActivity?: Maybe<CouponActivity>;
  findFirstCouponActivityOrThrow?: Maybe<CouponActivity>;
  findFirstCouponEntity?: Maybe<CouponEntity>;
  findFirstCouponEntityOrThrow?: Maybe<CouponEntity>;
  findFirstCouponGroup?: Maybe<CouponGroup>;
  findFirstCouponGroupOrThrow?: Maybe<CouponGroup>;
  findFirstCouponInfo?: Maybe<CouponInfo>;
  findFirstCouponInfoOrThrow?: Maybe<CouponInfo>;
  findFirstCouponUsage?: Maybe<CouponUsage>;
  findFirstCouponUsageOrThrow?: Maybe<CouponUsage>;
  findFirstDeviceToken?: Maybe<DeviceToken>;
  findFirstDeviceTokenOrThrow?: Maybe<DeviceToken>;
  findFirstEcloudlife?: Maybe<Ecloudlife>;
  findFirstEcloudlifeOrThrow?: Maybe<Ecloudlife>;
  findFirstEcloudlifeTrack?: Maybe<EcloudlifeTrack>;
  findFirstEcloudlifeTrackOrThrow?: Maybe<EcloudlifeTrack>;
  findFirstEmailTo?: Maybe<EmailTo>;
  findFirstEmailToOrThrow?: Maybe<EmailTo>;
  findFirstGroup?: Maybe<Group>;
  findFirstGroupOrThrow?: Maybe<Group>;
  findFirstInValidReason?: Maybe<InValidReason>;
  findFirstInValidReasonOrThrow?: Maybe<InValidReason>;
  findFirstInvoice?: Maybe<Invoice>;
  findFirstInvoiceOrThrow?: Maybe<Invoice>;
  findFirstManualAddr?: Maybe<ManualAddr>;
  findFirstManualAddrOrThrow?: Maybe<ManualAddr>;
  findFirstNotification?: Maybe<Notification>;
  findFirstNotificationOrThrow?: Maybe<Notification>;
  findFirstOrder?: Maybe<Order>;
  findFirstOrderDetail?: Maybe<OrderDetail>;
  findFirstOrderDetailHistory?: Maybe<OrderDetailHistory>;
  findFirstOrderDetailHistoryOrThrow?: Maybe<OrderDetailHistory>;
  findFirstOrderDetailOrThrow?: Maybe<OrderDetail>;
  findFirstOrderOrThrow?: Maybe<Order>;
  findFirstOrderTag?: Maybe<OrderTag>;
  findFirstOrderTagOrThrow?: Maybe<OrderTag>;
  findFirstPayment?: Maybe<Payment>;
  findFirstPaymentOrThrow?: Maybe<Payment>;
  findFirstPost?: Maybe<Post>;
  findFirstPostHistory?: Maybe<PostHistory>;
  findFirstPostHistoryOrThrow?: Maybe<PostHistory>;
  findFirstPostOrThrow?: Maybe<Post>;
  findFirstPostPackage?: Maybe<PostPackage>;
  findFirstPostPackageOrThrow?: Maybe<PostPackage>;
  findFirstPostTag?: Maybe<PostTag>;
  findFirstPostTagOrThrow?: Maybe<PostTag>;
  findFirstProduct?: Maybe<Product>;
  findFirstProductOrThrow?: Maybe<Product>;
  findFirstProfitable?: Maybe<Profitable>;
  findFirstProfitableBadget?: Maybe<ProfitableBadget>;
  findFirstProfitableBadgetOrThrow?: Maybe<ProfitableBadget>;
  findFirstProfitableOrThrow?: Maybe<Profitable>;
  findFirstQrcode?: Maybe<Qrcode>;
  findFirstQrcodeOrThrow?: Maybe<Qrcode>;
  findFirstRemoteString?: Maybe<RemoteString>;
  findFirstRemoteStringOrThrow?: Maybe<RemoteString>;
  findFirstReport?: Maybe<Report>;
  findFirstReportOrThrow?: Maybe<Report>;
  findFirstReportUser?: Maybe<ReportUser>;
  findFirstReportUserOrThrow?: Maybe<ReportUser>;
  findFirstScenicSpot?: Maybe<ScenicSpot>;
  findFirstScenicSpotOrThrow?: Maybe<ScenicSpot>;
  findFirstSettingNotification?: Maybe<SettingNotification>;
  findFirstSettingNotificationOrThrow?: Maybe<SettingNotification>;
  findFirstSettingNotificationSuppress?: Maybe<SettingNotificationSuppress>;
  findFirstSettingNotificationSuppressOrThrow?: Maybe<SettingNotificationSuppress>;
  findFirstSettingPrivacy?: Maybe<SettingPrivacy>;
  findFirstSettingPrivacyOrThrow?: Maybe<SettingPrivacy>;
  findFirstSignature?: Maybe<Signature>;
  findFirstSignatureOrThrow?: Maybe<Signature>;
  findFirstStamp?: Maybe<Stamp>;
  findFirstStampOrThrow?: Maybe<Stamp>;
  findFirstStoreItem?: Maybe<StoreItem>;
  findFirstStoreItemOrThrow?: Maybe<StoreItem>;
  findFirstStoreItemTag?: Maybe<StoreItemTag>;
  findFirstStoreItemTagOrThrow?: Maybe<StoreItemTag>;
  findFirstStoreItemToCartItem?: Maybe<StoreItemToCartItem>;
  findFirstStoreItemToCartItemOrThrow?: Maybe<StoreItemToCartItem>;
  findFirstStoreRatio?: Maybe<StoreRatio>;
  findFirstStoreRatioHistoryItem?: Maybe<StoreRatioHistoryItem>;
  findFirstStoreRatioHistoryItemOrThrow?: Maybe<StoreRatioHistoryItem>;
  findFirstStoreRatioOrThrow?: Maybe<StoreRatio>;
  findFirstTappay?: Maybe<Tappay>;
  findFirstTappayOrThrow?: Maybe<Tappay>;
  findFirstUser?: Maybe<User>;
  findFirstUserFriend?: Maybe<UserFriend>;
  findFirstUserFriendOrThrow?: Maybe<UserFriend>;
  findFirstUserOrThrow?: Maybe<User>;
  findFirstVirtualAddrMapping?: Maybe<VirtualAddrMapping>;
  findFirstVirtualAddrMappingOrThrow?: Maybe<VirtualAddrMapping>;
  findFirstVirtualLand?: Maybe<VirtualLand>;
  findFirstVirtualLandOrThrow?: Maybe<VirtualLand>;
  getAddress?: Maybe<Address>;
  getAdvertisement?: Maybe<Advertisement>;
  getAttachment?: Maybe<Attachment>;
  getBenefitMemo?: Maybe<BenefitMemo>;
  getBenefitMemoToCoin?: Maybe<BenefitMemoToCoin>;
  getBenefitMemoToOrder?: Maybe<BenefitMemoToOrder>;
  getBenefitTransfer?: Maybe<BenefitTransfer>;
  getCampaignNanhui?: Maybe<CampaignNanhui>;
  getCampainZeczec?: Maybe<CampainZeczec>;
  getCard?: Maybe<Card>;
  getCartItem?: Maybe<CartItem>;
  getCoin?: Maybe<Coin>;
  getCoinPack?: Maybe<CoinPack>;
  getCollection?: Maybe<Collection>;
  getCouponActivity?: Maybe<CouponActivity>;
  getCouponEntity?: Maybe<CouponEntity>;
  getCouponGroup?: Maybe<CouponGroup>;
  getCouponInfo?: Maybe<CouponInfo>;
  getCouponUsage?: Maybe<CouponUsage>;
  getDeviceToken?: Maybe<DeviceToken>;
  getEcloudlife?: Maybe<Ecloudlife>;
  getEcloudlifeTrack?: Maybe<EcloudlifeTrack>;
  getEmailTo?: Maybe<EmailTo>;
  getGroup?: Maybe<Group>;
  getInValidReason?: Maybe<InValidReason>;
  getInvoice?: Maybe<Invoice>;
  getManualAddr?: Maybe<ManualAddr>;
  getNotification?: Maybe<Notification>;
  getOrder?: Maybe<Order>;
  getOrderDetail?: Maybe<OrderDetail>;
  getOrderDetailHistory?: Maybe<OrderDetailHistory>;
  getOrderTag?: Maybe<OrderTag>;
  getPayment?: Maybe<Payment>;
  getPost?: Maybe<Post>;
  getPostHistory?: Maybe<PostHistory>;
  getPostPackage?: Maybe<PostPackage>;
  getPostTag?: Maybe<PostTag>;
  getProduct?: Maybe<Product>;
  getProfitable?: Maybe<Profitable>;
  getProfitableBadget?: Maybe<ProfitableBadget>;
  getQrcode?: Maybe<Qrcode>;
  getRemoteString?: Maybe<RemoteString>;
  getReport?: Maybe<Report>;
  getReportUser?: Maybe<ReportUser>;
  getScenicSpot?: Maybe<ScenicSpot>;
  getSettingNotification?: Maybe<SettingNotification>;
  getSettingNotificationSuppress?: Maybe<SettingNotificationSuppress>;
  getSettingPrivacy?: Maybe<SettingPrivacy>;
  getSignature?: Maybe<Signature>;
  getStamp?: Maybe<Stamp>;
  getStoreItem?: Maybe<StoreItem>;
  getStoreItemTag?: Maybe<StoreItemTag>;
  getStoreItemToCartItem?: Maybe<StoreItemToCartItem>;
  getStoreRatio?: Maybe<StoreRatio>;
  getStoreRatioHistoryItem?: Maybe<StoreRatioHistoryItem>;
  getTappay?: Maybe<Tappay>;
  getUser?: Maybe<User>;
  getUserFriend?: Maybe<UserFriend>;
  getVirtualAddrMapping?: Maybe<VirtualAddrMapping>;
  getVirtualLand?: Maybe<VirtualLand>;
  group?: Maybe<Group>;
  groupByAddress: Array<AddressGroupBy>;
  groupByAdvertisement: Array<AdvertisementGroupBy>;
  groupByAttachment: Array<AttachmentGroupBy>;
  groupByBenefitMemo: Array<BenefitMemoGroupBy>;
  groupByBenefitMemoToCoin: Array<BenefitMemoToCoinGroupBy>;
  groupByBenefitMemoToOrder: Array<BenefitMemoToOrderGroupBy>;
  groupByBenefitTransfer: Array<BenefitTransferGroupBy>;
  groupByCampaignNanhui: Array<CampaignNanhuiGroupBy>;
  groupByCampainZeczec: Array<CampainZeczecGroupBy>;
  groupByCard: Array<CardGroupBy>;
  groupByCartItem: Array<CartItemGroupBy>;
  groupByCoin: Array<CoinGroupBy>;
  groupByCoinPack: Array<CoinPackGroupBy>;
  groupByCollection: Array<CollectionGroupBy>;
  groupByCouponActivity: Array<CouponActivityGroupBy>;
  groupByCouponEntity: Array<CouponEntityGroupBy>;
  groupByCouponGroup: Array<CouponGroupGroupBy>;
  groupByCouponInfo: Array<CouponInfoGroupBy>;
  groupByCouponUsage: Array<CouponUsageGroupBy>;
  groupByDeviceToken: Array<DeviceTokenGroupBy>;
  groupByEcloudlife: Array<EcloudlifeGroupBy>;
  groupByEcloudlifeTrack: Array<EcloudlifeTrackGroupBy>;
  groupByEmailTo: Array<EmailToGroupBy>;
  groupByGroup: Array<GroupGroupBy>;
  groupByInValidReason: Array<InValidReasonGroupBy>;
  groupByInvoice: Array<InvoiceGroupBy>;
  groupByManualAddr: Array<ManualAddrGroupBy>;
  groupByNotification: Array<NotificationGroupBy>;
  groupByOrder: Array<OrderGroupBy>;
  groupByOrderDetail: Array<OrderDetailGroupBy>;
  groupByOrderDetailHistory: Array<OrderDetailHistoryGroupBy>;
  groupByOrderTag: Array<OrderTagGroupBy>;
  groupByPayment: Array<PaymentGroupBy>;
  groupByPost: Array<PostGroupBy>;
  groupByPostHistory: Array<PostHistoryGroupBy>;
  groupByPostPackage: Array<PostPackageGroupBy>;
  groupByPostTag: Array<PostTagGroupBy>;
  groupByProduct: Array<ProductGroupBy>;
  groupByProfitable: Array<ProfitableGroupBy>;
  groupByProfitableBadget: Array<ProfitableBadgetGroupBy>;
  groupByQrcode: Array<QrcodeGroupBy>;
  groupByRemoteString: Array<RemoteStringGroupBy>;
  groupByReport: Array<ReportGroupBy>;
  groupByReportUser: Array<ReportUserGroupBy>;
  groupByScenicSpot: Array<ScenicSpotGroupBy>;
  groupBySettingNotification: Array<SettingNotificationGroupBy>;
  groupBySettingNotificationSuppress: Array<SettingNotificationSuppressGroupBy>;
  groupBySettingPrivacy: Array<SettingPrivacyGroupBy>;
  groupBySignature: Array<SignatureGroupBy>;
  groupByStamp: Array<StampGroupBy>;
  groupByStoreItem: Array<StoreItemGroupBy>;
  groupByStoreItemTag: Array<StoreItemTagGroupBy>;
  groupByStoreItemToCartItem: Array<StoreItemToCartItemGroupBy>;
  groupByStoreRatio: Array<StoreRatioGroupBy>;
  groupByStoreRatioHistoryItem: Array<StoreRatioHistoryItemGroupBy>;
  groupByTappay: Array<TappayGroupBy>;
  groupByUser: Array<UserGroupBy>;
  groupByUserFriend: Array<UserFriendGroupBy>;
  groupByVirtualAddrMapping: Array<VirtualAddrMappingGroupBy>;
  groupByVirtualLand: Array<VirtualLandGroupBy>;
  groups: Array<Group>;
  inValidReason?: Maybe<InValidReason>;
  inValidReasons: Array<InValidReason>;
  invoice?: Maybe<Invoice>;
  invoices: Array<Invoice>;
  manualAddr?: Maybe<ManualAddr>;
  manualAddrs: Array<ManualAddr>;
  notification?: Maybe<Notification>;
  notifications: Array<Notification>;
  order?: Maybe<Order>;
  orderDetail?: Maybe<OrderDetail>;
  orderDetailHistories: Array<OrderDetailHistory>;
  orderDetailHistory?: Maybe<OrderDetailHistory>;
  orderDetails: Array<OrderDetail>;
  orderTag?: Maybe<OrderTag>;
  orderTags: Array<OrderTag>;
  orders: Array<Order>;
  payment?: Maybe<Payment>;
  payments: Array<Payment>;
  post?: Maybe<Post>;
  postHistories: Array<PostHistory>;
  postHistory?: Maybe<PostHistory>;
  postPackage?: Maybe<PostPackage>;
  postPackages: Array<PostPackage>;
  postTag?: Maybe<PostTag>;
  postTags: Array<PostTag>;
  posts: Array<Post>;
  product?: Maybe<Product>;
  products: Array<Product>;
  profitable?: Maybe<Profitable>;
  profitableBadget?: Maybe<ProfitableBadget>;
  profitableBadgets: Array<ProfitableBadget>;
  profitables: Array<Profitable>;
  qrcode?: Maybe<Qrcode>;
  qrcodes: Array<Qrcode>;
  remoteString?: Maybe<RemoteString>;
  remoteStrings: Array<RemoteString>;
  report?: Maybe<Report>;
  reportUser?: Maybe<ReportUser>;
  reportUsers: Array<ReportUser>;
  reports: Array<Report>;
  scenicSpot?: Maybe<ScenicSpot>;
  scenicSpots: Array<ScenicSpot>;
  /** 動態列表 */
  searchStore: Array<StoreSearchResultItem>;
  settingNotification?: Maybe<SettingNotification>;
  settingNotificationSuppress?: Maybe<SettingNotificationSuppress>;
  settingNotificationSuppresses: Array<SettingNotificationSuppress>;
  settingNotifications: Array<SettingNotification>;
  settingPrivacies: Array<SettingPrivacy>;
  settingPrivacy?: Maybe<SettingPrivacy>;
  signature?: Maybe<Signature>;
  signatures: Array<Signature>;
  stamp?: Maybe<Stamp>;
  stamps: Array<Stamp>;
  storeItem?: Maybe<StoreItem>;
  storeItemTag?: Maybe<StoreItemTag>;
  storeItemTags: Array<StoreItemTag>;
  storeItemToCartItem?: Maybe<StoreItemToCartItem>;
  storeItemToCartItems: Array<StoreItemToCartItem>;
  storeItems: Array<StoreItem>;
  storeRatio?: Maybe<StoreRatio>;
  storeRatioHistoryItem?: Maybe<StoreRatioHistoryItem>;
  storeRatioHistoryItems: Array<StoreRatioHistoryItem>;
  storeRatios: Array<StoreRatio>;
  tappay?: Maybe<Tappay>;
  tappays: Array<Tappay>;
  user?: Maybe<User>;
  userFriend?: Maybe<UserFriend>;
  userFriends: Array<UserFriend>;
  users: Array<User>;
  virtualAddrMapping?: Maybe<VirtualAddrMapping>;
  virtualAddrMappings: Array<VirtualAddrMapping>;
  virtualLand?: Maybe<VirtualLand>;
  virtualLands: Array<VirtualLand>;
};


export type QueryAddressArgs = {
  where: AddressWhereUniqueInput;
};


export type QueryAddressesArgs = {
  cursor?: InputMaybe<AddressWhereUniqueInput>;
  distinct?: InputMaybe<Array<AddressScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<AddressOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<AddressWhereInput>;
};


export type QueryAdvertisementArgs = {
  where: AdvertisementWhereUniqueInput;
};


export type QueryAdvertisementsArgs = {
  cursor?: InputMaybe<AdvertisementWhereUniqueInput>;
  distinct?: InputMaybe<Array<AdvertisementScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<AdvertisementOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<AdvertisementWhereInput>;
};


export type QueryAggregateAddressArgs = {
  cursor?: InputMaybe<AddressWhereUniqueInput>;
  orderBy?: InputMaybe<Array<AddressOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<AddressWhereInput>;
};


export type QueryAggregateAdvertisementArgs = {
  cursor?: InputMaybe<AdvertisementWhereUniqueInput>;
  orderBy?: InputMaybe<Array<AdvertisementOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<AdvertisementWhereInput>;
};


export type QueryAggregateAttachmentArgs = {
  cursor?: InputMaybe<AttachmentWhereUniqueInput>;
  orderBy?: InputMaybe<Array<AttachmentOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<AttachmentWhereInput>;
};


export type QueryAggregateBenefitMemoArgs = {
  cursor?: InputMaybe<BenefitMemoWhereUniqueInput>;
  orderBy?: InputMaybe<Array<BenefitMemoOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<BenefitMemoWhereInput>;
};


export type QueryAggregateBenefitMemoToCoinArgs = {
  cursor?: InputMaybe<BenefitMemoToCoinWhereUniqueInput>;
  orderBy?: InputMaybe<Array<BenefitMemoToCoinOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<BenefitMemoToCoinWhereInput>;
};


export type QueryAggregateBenefitMemoToOrderArgs = {
  cursor?: InputMaybe<BenefitMemoToOrderWhereUniqueInput>;
  orderBy?: InputMaybe<Array<BenefitMemoToOrderOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<BenefitMemoToOrderWhereInput>;
};


export type QueryAggregateBenefitTransferArgs = {
  cursor?: InputMaybe<BenefitTransferWhereUniqueInput>;
  orderBy?: InputMaybe<Array<BenefitTransferOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<BenefitTransferWhereInput>;
};


export type QueryAggregateCampaignNanhuiArgs = {
  cursor?: InputMaybe<CampaignNanhuiWhereUniqueInput>;
  orderBy?: InputMaybe<Array<CampaignNanhuiOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CampaignNanhuiWhereInput>;
};


export type QueryAggregateCampainZeczecArgs = {
  cursor?: InputMaybe<CampainZeczecWhereUniqueInput>;
  orderBy?: InputMaybe<Array<CampainZeczecOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CampainZeczecWhereInput>;
};


export type QueryAggregateCardArgs = {
  cursor?: InputMaybe<CardWhereUniqueInput>;
  orderBy?: InputMaybe<Array<CardOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CardWhereInput>;
};


export type QueryAggregateCartItemArgs = {
  cursor?: InputMaybe<CartItemWhereUniqueInput>;
  orderBy?: InputMaybe<Array<CartItemOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CartItemWhereInput>;
};


export type QueryAggregateCoinArgs = {
  cursor?: InputMaybe<CoinWhereUniqueInput>;
  orderBy?: InputMaybe<Array<CoinOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CoinWhereInput>;
};


export type QueryAggregateCoinPackArgs = {
  cursor?: InputMaybe<CoinPackWhereUniqueInput>;
  orderBy?: InputMaybe<Array<CoinPackOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CoinPackWhereInput>;
};


export type QueryAggregateCollectionArgs = {
  cursor?: InputMaybe<CollectionWhereUniqueInput>;
  orderBy?: InputMaybe<Array<CollectionOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CollectionWhereInput>;
};


export type QueryAggregateCouponActivityArgs = {
  cursor?: InputMaybe<CouponActivityWhereUniqueInput>;
  orderBy?: InputMaybe<Array<CouponActivityOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CouponActivityWhereInput>;
};


export type QueryAggregateCouponEntityArgs = {
  cursor?: InputMaybe<CouponEntityWhereUniqueInput>;
  orderBy?: InputMaybe<Array<CouponEntityOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CouponEntityWhereInput>;
};


export type QueryAggregateCouponGroupArgs = {
  cursor?: InputMaybe<CouponGroupWhereUniqueInput>;
  orderBy?: InputMaybe<Array<CouponGroupOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CouponGroupWhereInput>;
};


export type QueryAggregateCouponInfoArgs = {
  cursor?: InputMaybe<CouponInfoWhereUniqueInput>;
  orderBy?: InputMaybe<Array<CouponInfoOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CouponInfoWhereInput>;
};


export type QueryAggregateCouponUsageArgs = {
  cursor?: InputMaybe<CouponUsageWhereUniqueInput>;
  orderBy?: InputMaybe<Array<CouponUsageOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CouponUsageWhereInput>;
};


export type QueryAggregateDeviceTokenArgs = {
  cursor?: InputMaybe<DeviceTokenWhereUniqueInput>;
  orderBy?: InputMaybe<Array<DeviceTokenOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<DeviceTokenWhereInput>;
};


export type QueryAggregateEcloudlifeArgs = {
  cursor?: InputMaybe<EcloudlifeWhereUniqueInput>;
  orderBy?: InputMaybe<Array<EcloudlifeOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<EcloudlifeWhereInput>;
};


export type QueryAggregateEcloudlifeTrackArgs = {
  cursor?: InputMaybe<EcloudlifeTrackWhereUniqueInput>;
  orderBy?: InputMaybe<Array<EcloudlifeTrackOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<EcloudlifeTrackWhereInput>;
};


export type QueryAggregateEmailToArgs = {
  cursor?: InputMaybe<EmailToWhereUniqueInput>;
  orderBy?: InputMaybe<Array<EmailToOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<EmailToWhereInput>;
};


export type QueryAggregateGroupArgs = {
  cursor?: InputMaybe<GroupWhereUniqueInput>;
  orderBy?: InputMaybe<Array<GroupOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<GroupWhereInput>;
};


export type QueryAggregateInValidReasonArgs = {
  cursor?: InputMaybe<InValidReasonWhereUniqueInput>;
  orderBy?: InputMaybe<Array<InValidReasonOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<InValidReasonWhereInput>;
};


export type QueryAggregateInvoiceArgs = {
  cursor?: InputMaybe<InvoiceWhereUniqueInput>;
  orderBy?: InputMaybe<Array<InvoiceOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<InvoiceWhereInput>;
};


export type QueryAggregateManualAddrArgs = {
  cursor?: InputMaybe<ManualAddrWhereUniqueInput>;
  orderBy?: InputMaybe<Array<ManualAddrOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ManualAddrWhereInput>;
};


export type QueryAggregateNotificationArgs = {
  cursor?: InputMaybe<NotificationWhereUniqueInput>;
  orderBy?: InputMaybe<Array<NotificationOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<NotificationWhereInput>;
};


export type QueryAggregateOrderArgs = {
  cursor?: InputMaybe<OrderWhereUniqueInput>;
  orderBy?: InputMaybe<Array<OrderOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<OrderWhereInput>;
};


export type QueryAggregateOrderDetailArgs = {
  cursor?: InputMaybe<OrderDetailWhereUniqueInput>;
  orderBy?: InputMaybe<Array<OrderDetailOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<OrderDetailWhereInput>;
};


export type QueryAggregateOrderDetailHistoryArgs = {
  cursor?: InputMaybe<OrderDetailHistoryWhereUniqueInput>;
  orderBy?: InputMaybe<Array<OrderDetailHistoryOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<OrderDetailHistoryWhereInput>;
};


export type QueryAggregateOrderTagArgs = {
  cursor?: InputMaybe<OrderTagWhereUniqueInput>;
  orderBy?: InputMaybe<Array<OrderTagOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<OrderTagWhereInput>;
};


export type QueryAggregatePaymentArgs = {
  cursor?: InputMaybe<PaymentWhereUniqueInput>;
  orderBy?: InputMaybe<Array<PaymentOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PaymentWhereInput>;
};


export type QueryAggregatePostArgs = {
  cursor?: InputMaybe<PostWhereUniqueInput>;
  orderBy?: InputMaybe<Array<PostOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PostWhereInput>;
};


export type QueryAggregatePostHistoryArgs = {
  cursor?: InputMaybe<PostHistoryWhereUniqueInput>;
  orderBy?: InputMaybe<Array<PostHistoryOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PostHistoryWhereInput>;
};


export type QueryAggregatePostPackageArgs = {
  cursor?: InputMaybe<PostPackageWhereUniqueInput>;
  orderBy?: InputMaybe<Array<PostPackageOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PostPackageWhereInput>;
};


export type QueryAggregatePostTagArgs = {
  cursor?: InputMaybe<PostTagWhereUniqueInput>;
  orderBy?: InputMaybe<Array<PostTagOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PostTagWhereInput>;
};


export type QueryAggregateProductArgs = {
  cursor?: InputMaybe<ProductWhereUniqueInput>;
  orderBy?: InputMaybe<Array<ProductOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ProductWhereInput>;
};


export type QueryAggregateProfitableArgs = {
  cursor?: InputMaybe<ProfitableWhereUniqueInput>;
  orderBy?: InputMaybe<Array<ProfitableOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ProfitableWhereInput>;
};


export type QueryAggregateProfitableBadgetArgs = {
  cursor?: InputMaybe<ProfitableBadgetWhereUniqueInput>;
  orderBy?: InputMaybe<Array<ProfitableBadgetOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ProfitableBadgetWhereInput>;
};


export type QueryAggregateQrcodeArgs = {
  cursor?: InputMaybe<QrcodeWhereUniqueInput>;
  orderBy?: InputMaybe<Array<QrcodeOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<QrcodeWhereInput>;
};


export type QueryAggregateRemoteStringArgs = {
  cursor?: InputMaybe<RemoteStringWhereUniqueInput>;
  orderBy?: InputMaybe<Array<RemoteStringOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<RemoteStringWhereInput>;
};


export type QueryAggregateReportArgs = {
  cursor?: InputMaybe<ReportWhereUniqueInput>;
  orderBy?: InputMaybe<Array<ReportOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ReportWhereInput>;
};


export type QueryAggregateReportUserArgs = {
  cursor?: InputMaybe<ReportUserWhereUniqueInput>;
  orderBy?: InputMaybe<Array<ReportUserOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ReportUserWhereInput>;
};


export type QueryAggregateScenicSpotArgs = {
  cursor?: InputMaybe<ScenicSpotWhereUniqueInput>;
  orderBy?: InputMaybe<Array<ScenicSpotOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ScenicSpotWhereInput>;
};


export type QueryAggregateSettingNotificationArgs = {
  cursor?: InputMaybe<SettingNotificationWhereUniqueInput>;
  orderBy?: InputMaybe<Array<SettingNotificationOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<SettingNotificationWhereInput>;
};


export type QueryAggregateSettingNotificationSuppressArgs = {
  cursor?: InputMaybe<SettingNotificationSuppressWhereUniqueInput>;
  orderBy?: InputMaybe<Array<SettingNotificationSuppressOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<SettingNotificationSuppressWhereInput>;
};


export type QueryAggregateSettingPrivacyArgs = {
  cursor?: InputMaybe<SettingPrivacyWhereUniqueInput>;
  orderBy?: InputMaybe<Array<SettingPrivacyOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<SettingPrivacyWhereInput>;
};


export type QueryAggregateSignatureArgs = {
  cursor?: InputMaybe<SignatureWhereUniqueInput>;
  orderBy?: InputMaybe<Array<SignatureOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<SignatureWhereInput>;
};


export type QueryAggregateStampArgs = {
  cursor?: InputMaybe<StampWhereUniqueInput>;
  orderBy?: InputMaybe<Array<StampOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<StampWhereInput>;
};


export type QueryAggregateStoreItemArgs = {
  cursor?: InputMaybe<StoreItemWhereUniqueInput>;
  orderBy?: InputMaybe<Array<StoreItemOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<StoreItemWhereInput>;
};


export type QueryAggregateStoreItemTagArgs = {
  cursor?: InputMaybe<StoreItemTagWhereUniqueInput>;
  orderBy?: InputMaybe<Array<StoreItemTagOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<StoreItemTagWhereInput>;
};


export type QueryAggregateStoreItemToCartItemArgs = {
  cursor?: InputMaybe<StoreItemToCartItemWhereUniqueInput>;
  orderBy?: InputMaybe<Array<StoreItemToCartItemOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<StoreItemToCartItemWhereInput>;
};


export type QueryAggregateStoreRatioArgs = {
  cursor?: InputMaybe<StoreRatioWhereUniqueInput>;
  orderBy?: InputMaybe<Array<StoreRatioOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<StoreRatioWhereInput>;
};


export type QueryAggregateStoreRatioHistoryItemArgs = {
  cursor?: InputMaybe<StoreRatioHistoryItemWhereUniqueInput>;
  orderBy?: InputMaybe<Array<StoreRatioHistoryItemOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<StoreRatioHistoryItemWhereInput>;
};


export type QueryAggregateTappayArgs = {
  cursor?: InputMaybe<TappayWhereUniqueInput>;
  orderBy?: InputMaybe<Array<TappayOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<TappayWhereInput>;
};


export type QueryAggregateUserArgs = {
  cursor?: InputMaybe<UserWhereUniqueInput>;
  orderBy?: InputMaybe<Array<UserOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<UserWhereInput>;
};


export type QueryAggregateUserFriendArgs = {
  cursor?: InputMaybe<UserFriendWhereUniqueInput>;
  orderBy?: InputMaybe<Array<UserFriendOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<UserFriendWhereInput>;
};


export type QueryAggregateVirtualAddrMappingArgs = {
  cursor?: InputMaybe<VirtualAddrMappingWhereUniqueInput>;
  orderBy?: InputMaybe<Array<VirtualAddrMappingOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<VirtualAddrMappingWhereInput>;
};


export type QueryAggregateVirtualLandArgs = {
  cursor?: InputMaybe<VirtualLandWhereUniqueInput>;
  orderBy?: InputMaybe<Array<VirtualLandOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<VirtualLandWhereInput>;
};


export type QueryAttachmentArgs = {
  where: AttachmentWhereUniqueInput;
};


export type QueryAttachmentsArgs = {
  cursor?: InputMaybe<AttachmentWhereUniqueInput>;
  distinct?: InputMaybe<Array<AttachmentScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<AttachmentOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<AttachmentWhereInput>;
};


export type QueryBenefitMemoArgs = {
  where: BenefitMemoWhereUniqueInput;
};


export type QueryBenefitMemoToCoinArgs = {
  where: BenefitMemoToCoinWhereUniqueInput;
};


export type QueryBenefitMemoToCoinsArgs = {
  cursor?: InputMaybe<BenefitMemoToCoinWhereUniqueInput>;
  distinct?: InputMaybe<Array<BenefitMemoToCoinScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<BenefitMemoToCoinOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<BenefitMemoToCoinWhereInput>;
};


export type QueryBenefitMemoToOrderArgs = {
  where: BenefitMemoToOrderWhereUniqueInput;
};


export type QueryBenefitMemoToOrdersArgs = {
  cursor?: InputMaybe<BenefitMemoToOrderWhereUniqueInput>;
  distinct?: InputMaybe<Array<BenefitMemoToOrderScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<BenefitMemoToOrderOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<BenefitMemoToOrderWhereInput>;
};


export type QueryBenefitMemosArgs = {
  cursor?: InputMaybe<BenefitMemoWhereUniqueInput>;
  distinct?: InputMaybe<Array<BenefitMemoScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<BenefitMemoOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<BenefitMemoWhereInput>;
};


export type QueryBenefitTransferArgs = {
  where: BenefitTransferWhereUniqueInput;
};


export type QueryBenefitTransfersArgs = {
  cursor?: InputMaybe<BenefitTransferWhereUniqueInput>;
  distinct?: InputMaybe<Array<BenefitTransferScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<BenefitTransferOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<BenefitTransferWhereInput>;
};


export type QueryCampaignNanhuiArgs = {
  where: CampaignNanhuiWhereUniqueInput;
};


export type QueryCampaignNanhuisArgs = {
  cursor?: InputMaybe<CampaignNanhuiWhereUniqueInput>;
  distinct?: InputMaybe<Array<CampaignNanhuiScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<CampaignNanhuiOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CampaignNanhuiWhereInput>;
};


export type QueryCampainZeczecArgs = {
  where: CampainZeczecWhereUniqueInput;
};


export type QueryCampainZeczecsArgs = {
  cursor?: InputMaybe<CampainZeczecWhereUniqueInput>;
  distinct?: InputMaybe<Array<CampainZeczecScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<CampainZeczecOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CampainZeczecWhereInput>;
};


export type QueryCardArgs = {
  where: CardWhereUniqueInput;
};


export type QueryCardsArgs = {
  cursor?: InputMaybe<CardWhereUniqueInput>;
  distinct?: InputMaybe<Array<CardScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<CardOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CardWhereInput>;
};


export type QueryCartItemArgs = {
  where: CartItemWhereUniqueInput;
};


export type QueryCartItemsArgs = {
  cursor?: InputMaybe<CartItemWhereUniqueInput>;
  distinct?: InputMaybe<Array<CartItemScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<CartItemOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CartItemWhereInput>;
};


export type QueryCoinArgs = {
  where: CoinWhereUniqueInput;
};


export type QueryCoinPackArgs = {
  where: CoinPackWhereUniqueInput;
};


export type QueryCoinPacksArgs = {
  cursor?: InputMaybe<CoinPackWhereUniqueInput>;
  distinct?: InputMaybe<Array<CoinPackScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<CoinPackOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CoinPackWhereInput>;
};


export type QueryCoinsArgs = {
  cursor?: InputMaybe<CoinWhereUniqueInput>;
  distinct?: InputMaybe<Array<CoinScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<CoinOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CoinWhereInput>;
};


export type QueryCollectionArgs = {
  where: CollectionWhereUniqueInput;
};


export type QueryCollectionsArgs = {
  cursor?: InputMaybe<CollectionWhereUniqueInput>;
  distinct?: InputMaybe<Array<CollectionScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<CollectionOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CollectionWhereInput>;
};


export type QueryCouponActivitiesArgs = {
  cursor?: InputMaybe<CouponActivityWhereUniqueInput>;
  distinct?: InputMaybe<Array<CouponActivityScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<CouponActivityOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CouponActivityWhereInput>;
};


export type QueryCouponActivityArgs = {
  where: CouponActivityWhereUniqueInput;
};


export type QueryCouponEntitiesArgs = {
  cursor?: InputMaybe<CouponEntityWhereUniqueInput>;
  distinct?: InputMaybe<Array<CouponEntityScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<CouponEntityOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CouponEntityWhereInput>;
};


export type QueryCouponEntityArgs = {
  where: CouponEntityWhereUniqueInput;
};


export type QueryCouponGroupArgs = {
  where: CouponGroupWhereUniqueInput;
};


export type QueryCouponGroupsArgs = {
  cursor?: InputMaybe<CouponGroupWhereUniqueInput>;
  distinct?: InputMaybe<Array<CouponGroupScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<CouponGroupOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CouponGroupWhereInput>;
};


export type QueryCouponInfoArgs = {
  where: CouponInfoWhereUniqueInput;
};


export type QueryCouponInfosArgs = {
  cursor?: InputMaybe<CouponInfoWhereUniqueInput>;
  distinct?: InputMaybe<Array<CouponInfoScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<CouponInfoOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CouponInfoWhereInput>;
};


export type QueryCouponUsageArgs = {
  where: CouponUsageWhereUniqueInput;
};


export type QueryCouponUsagesArgs = {
  cursor?: InputMaybe<CouponUsageWhereUniqueInput>;
  distinct?: InputMaybe<Array<CouponUsageScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<CouponUsageOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CouponUsageWhereInput>;
};


export type QueryDeviceTokenArgs = {
  where: DeviceTokenWhereUniqueInput;
};


export type QueryDeviceTokensArgs = {
  cursor?: InputMaybe<DeviceTokenWhereUniqueInput>;
  distinct?: InputMaybe<Array<DeviceTokenScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<DeviceTokenOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<DeviceTokenWhereInput>;
};


export type QueryEcloudlifeArgs = {
  where: EcloudlifeWhereUniqueInput;
};


export type QueryEcloudlifeTrackArgs = {
  where: EcloudlifeTrackWhereUniqueInput;
};


export type QueryEcloudlifeTracksArgs = {
  cursor?: InputMaybe<EcloudlifeTrackWhereUniqueInput>;
  distinct?: InputMaybe<Array<EcloudlifeTrackScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<EcloudlifeTrackOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<EcloudlifeTrackWhereInput>;
};


export type QueryEcloudlivesArgs = {
  cursor?: InputMaybe<EcloudlifeWhereUniqueInput>;
  distinct?: InputMaybe<Array<EcloudlifeScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<EcloudlifeOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<EcloudlifeWhereInput>;
};


export type QueryEmailToArgs = {
  where: EmailToWhereUniqueInput;
};


export type QueryEmailTosArgs = {
  cursor?: InputMaybe<EmailToWhereUniqueInput>;
  distinct?: InputMaybe<Array<EmailToScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<EmailToOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<EmailToWhereInput>;
};


export type QueryFindFirstAddressArgs = {
  cursor?: InputMaybe<AddressWhereUniqueInput>;
  distinct?: InputMaybe<Array<AddressScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<AddressOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<AddressWhereInput>;
};


export type QueryFindFirstAddressOrThrowArgs = {
  cursor?: InputMaybe<AddressWhereUniqueInput>;
  distinct?: InputMaybe<Array<AddressScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<AddressOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<AddressWhereInput>;
};


export type QueryFindFirstAdvertisementArgs = {
  cursor?: InputMaybe<AdvertisementWhereUniqueInput>;
  distinct?: InputMaybe<Array<AdvertisementScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<AdvertisementOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<AdvertisementWhereInput>;
};


export type QueryFindFirstAdvertisementOrThrowArgs = {
  cursor?: InputMaybe<AdvertisementWhereUniqueInput>;
  distinct?: InputMaybe<Array<AdvertisementScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<AdvertisementOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<AdvertisementWhereInput>;
};


export type QueryFindFirstAttachmentArgs = {
  cursor?: InputMaybe<AttachmentWhereUniqueInput>;
  distinct?: InputMaybe<Array<AttachmentScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<AttachmentOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<AttachmentWhereInput>;
};


export type QueryFindFirstAttachmentOrThrowArgs = {
  cursor?: InputMaybe<AttachmentWhereUniqueInput>;
  distinct?: InputMaybe<Array<AttachmentScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<AttachmentOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<AttachmentWhereInput>;
};


export type QueryFindFirstBenefitMemoArgs = {
  cursor?: InputMaybe<BenefitMemoWhereUniqueInput>;
  distinct?: InputMaybe<Array<BenefitMemoScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<BenefitMemoOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<BenefitMemoWhereInput>;
};


export type QueryFindFirstBenefitMemoOrThrowArgs = {
  cursor?: InputMaybe<BenefitMemoWhereUniqueInput>;
  distinct?: InputMaybe<Array<BenefitMemoScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<BenefitMemoOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<BenefitMemoWhereInput>;
};


export type QueryFindFirstBenefitMemoToCoinArgs = {
  cursor?: InputMaybe<BenefitMemoToCoinWhereUniqueInput>;
  distinct?: InputMaybe<Array<BenefitMemoToCoinScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<BenefitMemoToCoinOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<BenefitMemoToCoinWhereInput>;
};


export type QueryFindFirstBenefitMemoToCoinOrThrowArgs = {
  cursor?: InputMaybe<BenefitMemoToCoinWhereUniqueInput>;
  distinct?: InputMaybe<Array<BenefitMemoToCoinScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<BenefitMemoToCoinOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<BenefitMemoToCoinWhereInput>;
};


export type QueryFindFirstBenefitMemoToOrderArgs = {
  cursor?: InputMaybe<BenefitMemoToOrderWhereUniqueInput>;
  distinct?: InputMaybe<Array<BenefitMemoToOrderScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<BenefitMemoToOrderOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<BenefitMemoToOrderWhereInput>;
};


export type QueryFindFirstBenefitMemoToOrderOrThrowArgs = {
  cursor?: InputMaybe<BenefitMemoToOrderWhereUniqueInput>;
  distinct?: InputMaybe<Array<BenefitMemoToOrderScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<BenefitMemoToOrderOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<BenefitMemoToOrderWhereInput>;
};


export type QueryFindFirstBenefitTransferArgs = {
  cursor?: InputMaybe<BenefitTransferWhereUniqueInput>;
  distinct?: InputMaybe<Array<BenefitTransferScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<BenefitTransferOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<BenefitTransferWhereInput>;
};


export type QueryFindFirstBenefitTransferOrThrowArgs = {
  cursor?: InputMaybe<BenefitTransferWhereUniqueInput>;
  distinct?: InputMaybe<Array<BenefitTransferScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<BenefitTransferOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<BenefitTransferWhereInput>;
};


export type QueryFindFirstCampaignNanhuiArgs = {
  cursor?: InputMaybe<CampaignNanhuiWhereUniqueInput>;
  distinct?: InputMaybe<Array<CampaignNanhuiScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<CampaignNanhuiOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CampaignNanhuiWhereInput>;
};


export type QueryFindFirstCampaignNanhuiOrThrowArgs = {
  cursor?: InputMaybe<CampaignNanhuiWhereUniqueInput>;
  distinct?: InputMaybe<Array<CampaignNanhuiScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<CampaignNanhuiOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CampaignNanhuiWhereInput>;
};


export type QueryFindFirstCampainZeczecArgs = {
  cursor?: InputMaybe<CampainZeczecWhereUniqueInput>;
  distinct?: InputMaybe<Array<CampainZeczecScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<CampainZeczecOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CampainZeczecWhereInput>;
};


export type QueryFindFirstCampainZeczecOrThrowArgs = {
  cursor?: InputMaybe<CampainZeczecWhereUniqueInput>;
  distinct?: InputMaybe<Array<CampainZeczecScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<CampainZeczecOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CampainZeczecWhereInput>;
};


export type QueryFindFirstCardArgs = {
  cursor?: InputMaybe<CardWhereUniqueInput>;
  distinct?: InputMaybe<Array<CardScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<CardOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CardWhereInput>;
};


export type QueryFindFirstCardOrThrowArgs = {
  cursor?: InputMaybe<CardWhereUniqueInput>;
  distinct?: InputMaybe<Array<CardScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<CardOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CardWhereInput>;
};


export type QueryFindFirstCartItemArgs = {
  cursor?: InputMaybe<CartItemWhereUniqueInput>;
  distinct?: InputMaybe<Array<CartItemScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<CartItemOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CartItemWhereInput>;
};


export type QueryFindFirstCartItemOrThrowArgs = {
  cursor?: InputMaybe<CartItemWhereUniqueInput>;
  distinct?: InputMaybe<Array<CartItemScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<CartItemOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CartItemWhereInput>;
};


export type QueryFindFirstCoinArgs = {
  cursor?: InputMaybe<CoinWhereUniqueInput>;
  distinct?: InputMaybe<Array<CoinScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<CoinOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CoinWhereInput>;
};


export type QueryFindFirstCoinOrThrowArgs = {
  cursor?: InputMaybe<CoinWhereUniqueInput>;
  distinct?: InputMaybe<Array<CoinScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<CoinOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CoinWhereInput>;
};


export type QueryFindFirstCoinPackArgs = {
  cursor?: InputMaybe<CoinPackWhereUniqueInput>;
  distinct?: InputMaybe<Array<CoinPackScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<CoinPackOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CoinPackWhereInput>;
};


export type QueryFindFirstCoinPackOrThrowArgs = {
  cursor?: InputMaybe<CoinPackWhereUniqueInput>;
  distinct?: InputMaybe<Array<CoinPackScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<CoinPackOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CoinPackWhereInput>;
};


export type QueryFindFirstCollectionArgs = {
  cursor?: InputMaybe<CollectionWhereUniqueInput>;
  distinct?: InputMaybe<Array<CollectionScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<CollectionOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CollectionWhereInput>;
};


export type QueryFindFirstCollectionOrThrowArgs = {
  cursor?: InputMaybe<CollectionWhereUniqueInput>;
  distinct?: InputMaybe<Array<CollectionScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<CollectionOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CollectionWhereInput>;
};


export type QueryFindFirstCouponActivityArgs = {
  cursor?: InputMaybe<CouponActivityWhereUniqueInput>;
  distinct?: InputMaybe<Array<CouponActivityScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<CouponActivityOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CouponActivityWhereInput>;
};


export type QueryFindFirstCouponActivityOrThrowArgs = {
  cursor?: InputMaybe<CouponActivityWhereUniqueInput>;
  distinct?: InputMaybe<Array<CouponActivityScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<CouponActivityOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CouponActivityWhereInput>;
};


export type QueryFindFirstCouponEntityArgs = {
  cursor?: InputMaybe<CouponEntityWhereUniqueInput>;
  distinct?: InputMaybe<Array<CouponEntityScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<CouponEntityOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CouponEntityWhereInput>;
};


export type QueryFindFirstCouponEntityOrThrowArgs = {
  cursor?: InputMaybe<CouponEntityWhereUniqueInput>;
  distinct?: InputMaybe<Array<CouponEntityScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<CouponEntityOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CouponEntityWhereInput>;
};


export type QueryFindFirstCouponGroupArgs = {
  cursor?: InputMaybe<CouponGroupWhereUniqueInput>;
  distinct?: InputMaybe<Array<CouponGroupScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<CouponGroupOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CouponGroupWhereInput>;
};


export type QueryFindFirstCouponGroupOrThrowArgs = {
  cursor?: InputMaybe<CouponGroupWhereUniqueInput>;
  distinct?: InputMaybe<Array<CouponGroupScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<CouponGroupOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CouponGroupWhereInput>;
};


export type QueryFindFirstCouponInfoArgs = {
  cursor?: InputMaybe<CouponInfoWhereUniqueInput>;
  distinct?: InputMaybe<Array<CouponInfoScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<CouponInfoOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CouponInfoWhereInput>;
};


export type QueryFindFirstCouponInfoOrThrowArgs = {
  cursor?: InputMaybe<CouponInfoWhereUniqueInput>;
  distinct?: InputMaybe<Array<CouponInfoScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<CouponInfoOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CouponInfoWhereInput>;
};


export type QueryFindFirstCouponUsageArgs = {
  cursor?: InputMaybe<CouponUsageWhereUniqueInput>;
  distinct?: InputMaybe<Array<CouponUsageScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<CouponUsageOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CouponUsageWhereInput>;
};


export type QueryFindFirstCouponUsageOrThrowArgs = {
  cursor?: InputMaybe<CouponUsageWhereUniqueInput>;
  distinct?: InputMaybe<Array<CouponUsageScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<CouponUsageOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CouponUsageWhereInput>;
};


export type QueryFindFirstDeviceTokenArgs = {
  cursor?: InputMaybe<DeviceTokenWhereUniqueInput>;
  distinct?: InputMaybe<Array<DeviceTokenScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<DeviceTokenOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<DeviceTokenWhereInput>;
};


export type QueryFindFirstDeviceTokenOrThrowArgs = {
  cursor?: InputMaybe<DeviceTokenWhereUniqueInput>;
  distinct?: InputMaybe<Array<DeviceTokenScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<DeviceTokenOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<DeviceTokenWhereInput>;
};


export type QueryFindFirstEcloudlifeArgs = {
  cursor?: InputMaybe<EcloudlifeWhereUniqueInput>;
  distinct?: InputMaybe<Array<EcloudlifeScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<EcloudlifeOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<EcloudlifeWhereInput>;
};


export type QueryFindFirstEcloudlifeOrThrowArgs = {
  cursor?: InputMaybe<EcloudlifeWhereUniqueInput>;
  distinct?: InputMaybe<Array<EcloudlifeScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<EcloudlifeOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<EcloudlifeWhereInput>;
};


export type QueryFindFirstEcloudlifeTrackArgs = {
  cursor?: InputMaybe<EcloudlifeTrackWhereUniqueInput>;
  distinct?: InputMaybe<Array<EcloudlifeTrackScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<EcloudlifeTrackOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<EcloudlifeTrackWhereInput>;
};


export type QueryFindFirstEcloudlifeTrackOrThrowArgs = {
  cursor?: InputMaybe<EcloudlifeTrackWhereUniqueInput>;
  distinct?: InputMaybe<Array<EcloudlifeTrackScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<EcloudlifeTrackOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<EcloudlifeTrackWhereInput>;
};


export type QueryFindFirstEmailToArgs = {
  cursor?: InputMaybe<EmailToWhereUniqueInput>;
  distinct?: InputMaybe<Array<EmailToScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<EmailToOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<EmailToWhereInput>;
};


export type QueryFindFirstEmailToOrThrowArgs = {
  cursor?: InputMaybe<EmailToWhereUniqueInput>;
  distinct?: InputMaybe<Array<EmailToScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<EmailToOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<EmailToWhereInput>;
};


export type QueryFindFirstGroupArgs = {
  cursor?: InputMaybe<GroupWhereUniqueInput>;
  distinct?: InputMaybe<Array<GroupScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<GroupOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<GroupWhereInput>;
};


export type QueryFindFirstGroupOrThrowArgs = {
  cursor?: InputMaybe<GroupWhereUniqueInput>;
  distinct?: InputMaybe<Array<GroupScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<GroupOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<GroupWhereInput>;
};


export type QueryFindFirstInValidReasonArgs = {
  cursor?: InputMaybe<InValidReasonWhereUniqueInput>;
  distinct?: InputMaybe<Array<InValidReasonScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<InValidReasonOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<InValidReasonWhereInput>;
};


export type QueryFindFirstInValidReasonOrThrowArgs = {
  cursor?: InputMaybe<InValidReasonWhereUniqueInput>;
  distinct?: InputMaybe<Array<InValidReasonScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<InValidReasonOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<InValidReasonWhereInput>;
};


export type QueryFindFirstInvoiceArgs = {
  cursor?: InputMaybe<InvoiceWhereUniqueInput>;
  distinct?: InputMaybe<Array<InvoiceScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<InvoiceOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<InvoiceWhereInput>;
};


export type QueryFindFirstInvoiceOrThrowArgs = {
  cursor?: InputMaybe<InvoiceWhereUniqueInput>;
  distinct?: InputMaybe<Array<InvoiceScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<InvoiceOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<InvoiceWhereInput>;
};


export type QueryFindFirstManualAddrArgs = {
  cursor?: InputMaybe<ManualAddrWhereUniqueInput>;
  distinct?: InputMaybe<Array<ManualAddrScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ManualAddrOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ManualAddrWhereInput>;
};


export type QueryFindFirstManualAddrOrThrowArgs = {
  cursor?: InputMaybe<ManualAddrWhereUniqueInput>;
  distinct?: InputMaybe<Array<ManualAddrScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ManualAddrOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ManualAddrWhereInput>;
};


export type QueryFindFirstNotificationArgs = {
  cursor?: InputMaybe<NotificationWhereUniqueInput>;
  distinct?: InputMaybe<Array<NotificationScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<NotificationOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<NotificationWhereInput>;
};


export type QueryFindFirstNotificationOrThrowArgs = {
  cursor?: InputMaybe<NotificationWhereUniqueInput>;
  distinct?: InputMaybe<Array<NotificationScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<NotificationOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<NotificationWhereInput>;
};


export type QueryFindFirstOrderArgs = {
  cursor?: InputMaybe<OrderWhereUniqueInput>;
  distinct?: InputMaybe<Array<OrderScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<OrderOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<OrderWhereInput>;
};


export type QueryFindFirstOrderDetailArgs = {
  cursor?: InputMaybe<OrderDetailWhereUniqueInput>;
  distinct?: InputMaybe<Array<OrderDetailScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<OrderDetailOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<OrderDetailWhereInput>;
};


export type QueryFindFirstOrderDetailHistoryArgs = {
  cursor?: InputMaybe<OrderDetailHistoryWhereUniqueInput>;
  distinct?: InputMaybe<Array<OrderDetailHistoryScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<OrderDetailHistoryOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<OrderDetailHistoryWhereInput>;
};


export type QueryFindFirstOrderDetailHistoryOrThrowArgs = {
  cursor?: InputMaybe<OrderDetailHistoryWhereUniqueInput>;
  distinct?: InputMaybe<Array<OrderDetailHistoryScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<OrderDetailHistoryOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<OrderDetailHistoryWhereInput>;
};


export type QueryFindFirstOrderDetailOrThrowArgs = {
  cursor?: InputMaybe<OrderDetailWhereUniqueInput>;
  distinct?: InputMaybe<Array<OrderDetailScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<OrderDetailOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<OrderDetailWhereInput>;
};


export type QueryFindFirstOrderOrThrowArgs = {
  cursor?: InputMaybe<OrderWhereUniqueInput>;
  distinct?: InputMaybe<Array<OrderScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<OrderOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<OrderWhereInput>;
};


export type QueryFindFirstOrderTagArgs = {
  cursor?: InputMaybe<OrderTagWhereUniqueInput>;
  distinct?: InputMaybe<Array<OrderTagScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<OrderTagOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<OrderTagWhereInput>;
};


export type QueryFindFirstOrderTagOrThrowArgs = {
  cursor?: InputMaybe<OrderTagWhereUniqueInput>;
  distinct?: InputMaybe<Array<OrderTagScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<OrderTagOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<OrderTagWhereInput>;
};


export type QueryFindFirstPaymentArgs = {
  cursor?: InputMaybe<PaymentWhereUniqueInput>;
  distinct?: InputMaybe<Array<PaymentScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<PaymentOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PaymentWhereInput>;
};


export type QueryFindFirstPaymentOrThrowArgs = {
  cursor?: InputMaybe<PaymentWhereUniqueInput>;
  distinct?: InputMaybe<Array<PaymentScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<PaymentOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PaymentWhereInput>;
};


export type QueryFindFirstPostArgs = {
  cursor?: InputMaybe<PostWhereUniqueInput>;
  distinct?: InputMaybe<Array<PostScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<PostOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PostWhereInput>;
};


export type QueryFindFirstPostHistoryArgs = {
  cursor?: InputMaybe<PostHistoryWhereUniqueInput>;
  distinct?: InputMaybe<Array<PostHistoryScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<PostHistoryOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PostHistoryWhereInput>;
};


export type QueryFindFirstPostHistoryOrThrowArgs = {
  cursor?: InputMaybe<PostHistoryWhereUniqueInput>;
  distinct?: InputMaybe<Array<PostHistoryScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<PostHistoryOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PostHistoryWhereInput>;
};


export type QueryFindFirstPostOrThrowArgs = {
  cursor?: InputMaybe<PostWhereUniqueInput>;
  distinct?: InputMaybe<Array<PostScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<PostOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PostWhereInput>;
};


export type QueryFindFirstPostPackageArgs = {
  cursor?: InputMaybe<PostPackageWhereUniqueInput>;
  distinct?: InputMaybe<Array<PostPackageScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<PostPackageOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PostPackageWhereInput>;
};


export type QueryFindFirstPostPackageOrThrowArgs = {
  cursor?: InputMaybe<PostPackageWhereUniqueInput>;
  distinct?: InputMaybe<Array<PostPackageScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<PostPackageOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PostPackageWhereInput>;
};


export type QueryFindFirstPostTagArgs = {
  cursor?: InputMaybe<PostTagWhereUniqueInput>;
  distinct?: InputMaybe<Array<PostTagScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<PostTagOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PostTagWhereInput>;
};


export type QueryFindFirstPostTagOrThrowArgs = {
  cursor?: InputMaybe<PostTagWhereUniqueInput>;
  distinct?: InputMaybe<Array<PostTagScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<PostTagOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PostTagWhereInput>;
};


export type QueryFindFirstProductArgs = {
  cursor?: InputMaybe<ProductWhereUniqueInput>;
  distinct?: InputMaybe<Array<ProductScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ProductOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ProductWhereInput>;
};


export type QueryFindFirstProductOrThrowArgs = {
  cursor?: InputMaybe<ProductWhereUniqueInput>;
  distinct?: InputMaybe<Array<ProductScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ProductOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ProductWhereInput>;
};


export type QueryFindFirstProfitableArgs = {
  cursor?: InputMaybe<ProfitableWhereUniqueInput>;
  distinct?: InputMaybe<Array<ProfitableScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ProfitableOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ProfitableWhereInput>;
};


export type QueryFindFirstProfitableBadgetArgs = {
  cursor?: InputMaybe<ProfitableBadgetWhereUniqueInput>;
  distinct?: InputMaybe<Array<ProfitableBadgetScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ProfitableBadgetOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ProfitableBadgetWhereInput>;
};


export type QueryFindFirstProfitableBadgetOrThrowArgs = {
  cursor?: InputMaybe<ProfitableBadgetWhereUniqueInput>;
  distinct?: InputMaybe<Array<ProfitableBadgetScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ProfitableBadgetOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ProfitableBadgetWhereInput>;
};


export type QueryFindFirstProfitableOrThrowArgs = {
  cursor?: InputMaybe<ProfitableWhereUniqueInput>;
  distinct?: InputMaybe<Array<ProfitableScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ProfitableOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ProfitableWhereInput>;
};


export type QueryFindFirstQrcodeArgs = {
  cursor?: InputMaybe<QrcodeWhereUniqueInput>;
  distinct?: InputMaybe<Array<QrcodeScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<QrcodeOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<QrcodeWhereInput>;
};


export type QueryFindFirstQrcodeOrThrowArgs = {
  cursor?: InputMaybe<QrcodeWhereUniqueInput>;
  distinct?: InputMaybe<Array<QrcodeScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<QrcodeOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<QrcodeWhereInput>;
};


export type QueryFindFirstRemoteStringArgs = {
  cursor?: InputMaybe<RemoteStringWhereUniqueInput>;
  distinct?: InputMaybe<Array<RemoteStringScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<RemoteStringOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<RemoteStringWhereInput>;
};


export type QueryFindFirstRemoteStringOrThrowArgs = {
  cursor?: InputMaybe<RemoteStringWhereUniqueInput>;
  distinct?: InputMaybe<Array<RemoteStringScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<RemoteStringOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<RemoteStringWhereInput>;
};


export type QueryFindFirstReportArgs = {
  cursor?: InputMaybe<ReportWhereUniqueInput>;
  distinct?: InputMaybe<Array<ReportScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ReportOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ReportWhereInput>;
};


export type QueryFindFirstReportOrThrowArgs = {
  cursor?: InputMaybe<ReportWhereUniqueInput>;
  distinct?: InputMaybe<Array<ReportScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ReportOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ReportWhereInput>;
};


export type QueryFindFirstReportUserArgs = {
  cursor?: InputMaybe<ReportUserWhereUniqueInput>;
  distinct?: InputMaybe<Array<ReportUserScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ReportUserOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ReportUserWhereInput>;
};


export type QueryFindFirstReportUserOrThrowArgs = {
  cursor?: InputMaybe<ReportUserWhereUniqueInput>;
  distinct?: InputMaybe<Array<ReportUserScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ReportUserOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ReportUserWhereInput>;
};


export type QueryFindFirstScenicSpotArgs = {
  cursor?: InputMaybe<ScenicSpotWhereUniqueInput>;
  distinct?: InputMaybe<Array<ScenicSpotScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ScenicSpotOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ScenicSpotWhereInput>;
};


export type QueryFindFirstScenicSpotOrThrowArgs = {
  cursor?: InputMaybe<ScenicSpotWhereUniqueInput>;
  distinct?: InputMaybe<Array<ScenicSpotScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ScenicSpotOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ScenicSpotWhereInput>;
};


export type QueryFindFirstSettingNotificationArgs = {
  cursor?: InputMaybe<SettingNotificationWhereUniqueInput>;
  distinct?: InputMaybe<Array<SettingNotificationScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<SettingNotificationOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<SettingNotificationWhereInput>;
};


export type QueryFindFirstSettingNotificationOrThrowArgs = {
  cursor?: InputMaybe<SettingNotificationWhereUniqueInput>;
  distinct?: InputMaybe<Array<SettingNotificationScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<SettingNotificationOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<SettingNotificationWhereInput>;
};


export type QueryFindFirstSettingNotificationSuppressArgs = {
  cursor?: InputMaybe<SettingNotificationSuppressWhereUniqueInput>;
  distinct?: InputMaybe<Array<SettingNotificationSuppressScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<SettingNotificationSuppressOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<SettingNotificationSuppressWhereInput>;
};


export type QueryFindFirstSettingNotificationSuppressOrThrowArgs = {
  cursor?: InputMaybe<SettingNotificationSuppressWhereUniqueInput>;
  distinct?: InputMaybe<Array<SettingNotificationSuppressScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<SettingNotificationSuppressOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<SettingNotificationSuppressWhereInput>;
};


export type QueryFindFirstSettingPrivacyArgs = {
  cursor?: InputMaybe<SettingPrivacyWhereUniqueInput>;
  distinct?: InputMaybe<Array<SettingPrivacyScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<SettingPrivacyOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<SettingPrivacyWhereInput>;
};


export type QueryFindFirstSettingPrivacyOrThrowArgs = {
  cursor?: InputMaybe<SettingPrivacyWhereUniqueInput>;
  distinct?: InputMaybe<Array<SettingPrivacyScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<SettingPrivacyOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<SettingPrivacyWhereInput>;
};


export type QueryFindFirstSignatureArgs = {
  cursor?: InputMaybe<SignatureWhereUniqueInput>;
  distinct?: InputMaybe<Array<SignatureScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<SignatureOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<SignatureWhereInput>;
};


export type QueryFindFirstSignatureOrThrowArgs = {
  cursor?: InputMaybe<SignatureWhereUniqueInput>;
  distinct?: InputMaybe<Array<SignatureScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<SignatureOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<SignatureWhereInput>;
};


export type QueryFindFirstStampArgs = {
  cursor?: InputMaybe<StampWhereUniqueInput>;
  distinct?: InputMaybe<Array<StampScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<StampOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<StampWhereInput>;
};


export type QueryFindFirstStampOrThrowArgs = {
  cursor?: InputMaybe<StampWhereUniqueInput>;
  distinct?: InputMaybe<Array<StampScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<StampOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<StampWhereInput>;
};


export type QueryFindFirstStoreItemArgs = {
  cursor?: InputMaybe<StoreItemWhereUniqueInput>;
  distinct?: InputMaybe<Array<StoreItemScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<StoreItemOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<StoreItemWhereInput>;
};


export type QueryFindFirstStoreItemOrThrowArgs = {
  cursor?: InputMaybe<StoreItemWhereUniqueInput>;
  distinct?: InputMaybe<Array<StoreItemScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<StoreItemOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<StoreItemWhereInput>;
};


export type QueryFindFirstStoreItemTagArgs = {
  cursor?: InputMaybe<StoreItemTagWhereUniqueInput>;
  distinct?: InputMaybe<Array<StoreItemTagScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<StoreItemTagOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<StoreItemTagWhereInput>;
};


export type QueryFindFirstStoreItemTagOrThrowArgs = {
  cursor?: InputMaybe<StoreItemTagWhereUniqueInput>;
  distinct?: InputMaybe<Array<StoreItemTagScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<StoreItemTagOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<StoreItemTagWhereInput>;
};


export type QueryFindFirstStoreItemToCartItemArgs = {
  cursor?: InputMaybe<StoreItemToCartItemWhereUniqueInput>;
  distinct?: InputMaybe<Array<StoreItemToCartItemScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<StoreItemToCartItemOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<StoreItemToCartItemWhereInput>;
};


export type QueryFindFirstStoreItemToCartItemOrThrowArgs = {
  cursor?: InputMaybe<StoreItemToCartItemWhereUniqueInput>;
  distinct?: InputMaybe<Array<StoreItemToCartItemScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<StoreItemToCartItemOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<StoreItemToCartItemWhereInput>;
};


export type QueryFindFirstStoreRatioArgs = {
  cursor?: InputMaybe<StoreRatioWhereUniqueInput>;
  distinct?: InputMaybe<Array<StoreRatioScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<StoreRatioOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<StoreRatioWhereInput>;
};


export type QueryFindFirstStoreRatioHistoryItemArgs = {
  cursor?: InputMaybe<StoreRatioHistoryItemWhereUniqueInput>;
  distinct?: InputMaybe<Array<StoreRatioHistoryItemScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<StoreRatioHistoryItemOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<StoreRatioHistoryItemWhereInput>;
};


export type QueryFindFirstStoreRatioHistoryItemOrThrowArgs = {
  cursor?: InputMaybe<StoreRatioHistoryItemWhereUniqueInput>;
  distinct?: InputMaybe<Array<StoreRatioHistoryItemScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<StoreRatioHistoryItemOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<StoreRatioHistoryItemWhereInput>;
};


export type QueryFindFirstStoreRatioOrThrowArgs = {
  cursor?: InputMaybe<StoreRatioWhereUniqueInput>;
  distinct?: InputMaybe<Array<StoreRatioScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<StoreRatioOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<StoreRatioWhereInput>;
};


export type QueryFindFirstTappayArgs = {
  cursor?: InputMaybe<TappayWhereUniqueInput>;
  distinct?: InputMaybe<Array<TappayScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<TappayOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<TappayWhereInput>;
};


export type QueryFindFirstTappayOrThrowArgs = {
  cursor?: InputMaybe<TappayWhereUniqueInput>;
  distinct?: InputMaybe<Array<TappayScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<TappayOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<TappayWhereInput>;
};


export type QueryFindFirstUserArgs = {
  cursor?: InputMaybe<UserWhereUniqueInput>;
  distinct?: InputMaybe<Array<UserScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<UserOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<UserWhereInput>;
};


export type QueryFindFirstUserFriendArgs = {
  cursor?: InputMaybe<UserFriendWhereUniqueInput>;
  distinct?: InputMaybe<Array<UserFriendScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<UserFriendOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<UserFriendWhereInput>;
};


export type QueryFindFirstUserFriendOrThrowArgs = {
  cursor?: InputMaybe<UserFriendWhereUniqueInput>;
  distinct?: InputMaybe<Array<UserFriendScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<UserFriendOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<UserFriendWhereInput>;
};


export type QueryFindFirstUserOrThrowArgs = {
  cursor?: InputMaybe<UserWhereUniqueInput>;
  distinct?: InputMaybe<Array<UserScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<UserOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<UserWhereInput>;
};


export type QueryFindFirstVirtualAddrMappingArgs = {
  cursor?: InputMaybe<VirtualAddrMappingWhereUniqueInput>;
  distinct?: InputMaybe<Array<VirtualAddrMappingScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<VirtualAddrMappingOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<VirtualAddrMappingWhereInput>;
};


export type QueryFindFirstVirtualAddrMappingOrThrowArgs = {
  cursor?: InputMaybe<VirtualAddrMappingWhereUniqueInput>;
  distinct?: InputMaybe<Array<VirtualAddrMappingScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<VirtualAddrMappingOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<VirtualAddrMappingWhereInput>;
};


export type QueryFindFirstVirtualLandArgs = {
  cursor?: InputMaybe<VirtualLandWhereUniqueInput>;
  distinct?: InputMaybe<Array<VirtualLandScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<VirtualLandOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<VirtualLandWhereInput>;
};


export type QueryFindFirstVirtualLandOrThrowArgs = {
  cursor?: InputMaybe<VirtualLandWhereUniqueInput>;
  distinct?: InputMaybe<Array<VirtualLandScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<VirtualLandOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<VirtualLandWhereInput>;
};


export type QueryGetAddressArgs = {
  where: AddressWhereUniqueInput;
};


export type QueryGetAdvertisementArgs = {
  where: AdvertisementWhereUniqueInput;
};


export type QueryGetAttachmentArgs = {
  where: AttachmentWhereUniqueInput;
};


export type QueryGetBenefitMemoArgs = {
  where: BenefitMemoWhereUniqueInput;
};


export type QueryGetBenefitMemoToCoinArgs = {
  where: BenefitMemoToCoinWhereUniqueInput;
};


export type QueryGetBenefitMemoToOrderArgs = {
  where: BenefitMemoToOrderWhereUniqueInput;
};


export type QueryGetBenefitTransferArgs = {
  where: BenefitTransferWhereUniqueInput;
};


export type QueryGetCampaignNanhuiArgs = {
  where: CampaignNanhuiWhereUniqueInput;
};


export type QueryGetCampainZeczecArgs = {
  where: CampainZeczecWhereUniqueInput;
};


export type QueryGetCardArgs = {
  where: CardWhereUniqueInput;
};


export type QueryGetCartItemArgs = {
  where: CartItemWhereUniqueInput;
};


export type QueryGetCoinArgs = {
  where: CoinWhereUniqueInput;
};


export type QueryGetCoinPackArgs = {
  where: CoinPackWhereUniqueInput;
};


export type QueryGetCollectionArgs = {
  where: CollectionWhereUniqueInput;
};


export type QueryGetCouponActivityArgs = {
  where: CouponActivityWhereUniqueInput;
};


export type QueryGetCouponEntityArgs = {
  where: CouponEntityWhereUniqueInput;
};


export type QueryGetCouponGroupArgs = {
  where: CouponGroupWhereUniqueInput;
};


export type QueryGetCouponInfoArgs = {
  where: CouponInfoWhereUniqueInput;
};


export type QueryGetCouponUsageArgs = {
  where: CouponUsageWhereUniqueInput;
};


export type QueryGetDeviceTokenArgs = {
  where: DeviceTokenWhereUniqueInput;
};


export type QueryGetEcloudlifeArgs = {
  where: EcloudlifeWhereUniqueInput;
};


export type QueryGetEcloudlifeTrackArgs = {
  where: EcloudlifeTrackWhereUniqueInput;
};


export type QueryGetEmailToArgs = {
  where: EmailToWhereUniqueInput;
};


export type QueryGetGroupArgs = {
  where: GroupWhereUniqueInput;
};


export type QueryGetInValidReasonArgs = {
  where: InValidReasonWhereUniqueInput;
};


export type QueryGetInvoiceArgs = {
  where: InvoiceWhereUniqueInput;
};


export type QueryGetManualAddrArgs = {
  where: ManualAddrWhereUniqueInput;
};


export type QueryGetNotificationArgs = {
  where: NotificationWhereUniqueInput;
};


export type QueryGetOrderArgs = {
  where: OrderWhereUniqueInput;
};


export type QueryGetOrderDetailArgs = {
  where: OrderDetailWhereUniqueInput;
};


export type QueryGetOrderDetailHistoryArgs = {
  where: OrderDetailHistoryWhereUniqueInput;
};


export type QueryGetOrderTagArgs = {
  where: OrderTagWhereUniqueInput;
};


export type QueryGetPaymentArgs = {
  where: PaymentWhereUniqueInput;
};


export type QueryGetPostArgs = {
  where: PostWhereUniqueInput;
};


export type QueryGetPostHistoryArgs = {
  where: PostHistoryWhereUniqueInput;
};


export type QueryGetPostPackageArgs = {
  where: PostPackageWhereUniqueInput;
};


export type QueryGetPostTagArgs = {
  where: PostTagWhereUniqueInput;
};


export type QueryGetProductArgs = {
  where: ProductWhereUniqueInput;
};


export type QueryGetProfitableArgs = {
  where: ProfitableWhereUniqueInput;
};


export type QueryGetProfitableBadgetArgs = {
  where: ProfitableBadgetWhereUniqueInput;
};


export type QueryGetQrcodeArgs = {
  where: QrcodeWhereUniqueInput;
};


export type QueryGetRemoteStringArgs = {
  where: RemoteStringWhereUniqueInput;
};


export type QueryGetReportArgs = {
  where: ReportWhereUniqueInput;
};


export type QueryGetReportUserArgs = {
  where: ReportUserWhereUniqueInput;
};


export type QueryGetScenicSpotArgs = {
  where: ScenicSpotWhereUniqueInput;
};


export type QueryGetSettingNotificationArgs = {
  where: SettingNotificationWhereUniqueInput;
};


export type QueryGetSettingNotificationSuppressArgs = {
  where: SettingNotificationSuppressWhereUniqueInput;
};


export type QueryGetSettingPrivacyArgs = {
  where: SettingPrivacyWhereUniqueInput;
};


export type QueryGetSignatureArgs = {
  where: SignatureWhereUniqueInput;
};


export type QueryGetStampArgs = {
  where: StampWhereUniqueInput;
};


export type QueryGetStoreItemArgs = {
  where: StoreItemWhereUniqueInput;
};


export type QueryGetStoreItemTagArgs = {
  where: StoreItemTagWhereUniqueInput;
};


export type QueryGetStoreItemToCartItemArgs = {
  where: StoreItemToCartItemWhereUniqueInput;
};


export type QueryGetStoreRatioArgs = {
  where: StoreRatioWhereUniqueInput;
};


export type QueryGetStoreRatioHistoryItemArgs = {
  where: StoreRatioHistoryItemWhereUniqueInput;
};


export type QueryGetTappayArgs = {
  where: TappayWhereUniqueInput;
};


export type QueryGetUserArgs = {
  where: UserWhereUniqueInput;
};


export type QueryGetUserFriendArgs = {
  where: UserFriendWhereUniqueInput;
};


export type QueryGetVirtualAddrMappingArgs = {
  where: VirtualAddrMappingWhereUniqueInput;
};


export type QueryGetVirtualLandArgs = {
  where: VirtualLandWhereUniqueInput;
};


export type QueryGroupArgs = {
  where: GroupWhereUniqueInput;
};


export type QueryGroupByAddressArgs = {
  by: Array<AddressScalarFieldEnum>;
  having?: InputMaybe<AddressScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<AddressOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<AddressWhereInput>;
};


export type QueryGroupByAdvertisementArgs = {
  by: Array<AdvertisementScalarFieldEnum>;
  having?: InputMaybe<AdvertisementScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<AdvertisementOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<AdvertisementWhereInput>;
};


export type QueryGroupByAttachmentArgs = {
  by: Array<AttachmentScalarFieldEnum>;
  having?: InputMaybe<AttachmentScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<AttachmentOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<AttachmentWhereInput>;
};


export type QueryGroupByBenefitMemoArgs = {
  by: Array<BenefitMemoScalarFieldEnum>;
  having?: InputMaybe<BenefitMemoScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<BenefitMemoOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<BenefitMemoWhereInput>;
};


export type QueryGroupByBenefitMemoToCoinArgs = {
  by: Array<BenefitMemoToCoinScalarFieldEnum>;
  having?: InputMaybe<BenefitMemoToCoinScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<BenefitMemoToCoinOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<BenefitMemoToCoinWhereInput>;
};


export type QueryGroupByBenefitMemoToOrderArgs = {
  by: Array<BenefitMemoToOrderScalarFieldEnum>;
  having?: InputMaybe<BenefitMemoToOrderScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<BenefitMemoToOrderOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<BenefitMemoToOrderWhereInput>;
};


export type QueryGroupByBenefitTransferArgs = {
  by: Array<BenefitTransferScalarFieldEnum>;
  having?: InputMaybe<BenefitTransferScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<BenefitTransferOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<BenefitTransferWhereInput>;
};


export type QueryGroupByCampaignNanhuiArgs = {
  by: Array<CampaignNanhuiScalarFieldEnum>;
  having?: InputMaybe<CampaignNanhuiScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<CampaignNanhuiOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CampaignNanhuiWhereInput>;
};


export type QueryGroupByCampainZeczecArgs = {
  by: Array<CampainZeczecScalarFieldEnum>;
  having?: InputMaybe<CampainZeczecScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<CampainZeczecOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CampainZeczecWhereInput>;
};


export type QueryGroupByCardArgs = {
  by: Array<CardScalarFieldEnum>;
  having?: InputMaybe<CardScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<CardOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CardWhereInput>;
};


export type QueryGroupByCartItemArgs = {
  by: Array<CartItemScalarFieldEnum>;
  having?: InputMaybe<CartItemScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<CartItemOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CartItemWhereInput>;
};


export type QueryGroupByCoinArgs = {
  by: Array<CoinScalarFieldEnum>;
  having?: InputMaybe<CoinScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<CoinOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CoinWhereInput>;
};


export type QueryGroupByCoinPackArgs = {
  by: Array<CoinPackScalarFieldEnum>;
  having?: InputMaybe<CoinPackScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<CoinPackOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CoinPackWhereInput>;
};


export type QueryGroupByCollectionArgs = {
  by: Array<CollectionScalarFieldEnum>;
  having?: InputMaybe<CollectionScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<CollectionOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CollectionWhereInput>;
};


export type QueryGroupByCouponActivityArgs = {
  by: Array<CouponActivityScalarFieldEnum>;
  having?: InputMaybe<CouponActivityScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<CouponActivityOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CouponActivityWhereInput>;
};


export type QueryGroupByCouponEntityArgs = {
  by: Array<CouponEntityScalarFieldEnum>;
  having?: InputMaybe<CouponEntityScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<CouponEntityOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CouponEntityWhereInput>;
};


export type QueryGroupByCouponGroupArgs = {
  by: Array<CouponGroupScalarFieldEnum>;
  having?: InputMaybe<CouponGroupScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<CouponGroupOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CouponGroupWhereInput>;
};


export type QueryGroupByCouponInfoArgs = {
  by: Array<CouponInfoScalarFieldEnum>;
  having?: InputMaybe<CouponInfoScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<CouponInfoOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CouponInfoWhereInput>;
};


export type QueryGroupByCouponUsageArgs = {
  by: Array<CouponUsageScalarFieldEnum>;
  having?: InputMaybe<CouponUsageScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<CouponUsageOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CouponUsageWhereInput>;
};


export type QueryGroupByDeviceTokenArgs = {
  by: Array<DeviceTokenScalarFieldEnum>;
  having?: InputMaybe<DeviceTokenScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<DeviceTokenOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<DeviceTokenWhereInput>;
};


export type QueryGroupByEcloudlifeArgs = {
  by: Array<EcloudlifeScalarFieldEnum>;
  having?: InputMaybe<EcloudlifeScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<EcloudlifeOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<EcloudlifeWhereInput>;
};


export type QueryGroupByEcloudlifeTrackArgs = {
  by: Array<EcloudlifeTrackScalarFieldEnum>;
  having?: InputMaybe<EcloudlifeTrackScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<EcloudlifeTrackOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<EcloudlifeTrackWhereInput>;
};


export type QueryGroupByEmailToArgs = {
  by: Array<EmailToScalarFieldEnum>;
  having?: InputMaybe<EmailToScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<EmailToOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<EmailToWhereInput>;
};


export type QueryGroupByGroupArgs = {
  by: Array<GroupScalarFieldEnum>;
  having?: InputMaybe<GroupScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<GroupOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<GroupWhereInput>;
};


export type QueryGroupByInValidReasonArgs = {
  by: Array<InValidReasonScalarFieldEnum>;
  having?: InputMaybe<InValidReasonScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<InValidReasonOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<InValidReasonWhereInput>;
};


export type QueryGroupByInvoiceArgs = {
  by: Array<InvoiceScalarFieldEnum>;
  having?: InputMaybe<InvoiceScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<InvoiceOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<InvoiceWhereInput>;
};


export type QueryGroupByManualAddrArgs = {
  by: Array<ManualAddrScalarFieldEnum>;
  having?: InputMaybe<ManualAddrScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<ManualAddrOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ManualAddrWhereInput>;
};


export type QueryGroupByNotificationArgs = {
  by: Array<NotificationScalarFieldEnum>;
  having?: InputMaybe<NotificationScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<NotificationOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<NotificationWhereInput>;
};


export type QueryGroupByOrderArgs = {
  by: Array<OrderScalarFieldEnum>;
  having?: InputMaybe<OrderScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<OrderOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<OrderWhereInput>;
};


export type QueryGroupByOrderDetailArgs = {
  by: Array<OrderDetailScalarFieldEnum>;
  having?: InputMaybe<OrderDetailScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<OrderDetailOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<OrderDetailWhereInput>;
};


export type QueryGroupByOrderDetailHistoryArgs = {
  by: Array<OrderDetailHistoryScalarFieldEnum>;
  having?: InputMaybe<OrderDetailHistoryScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<OrderDetailHistoryOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<OrderDetailHistoryWhereInput>;
};


export type QueryGroupByOrderTagArgs = {
  by: Array<OrderTagScalarFieldEnum>;
  having?: InputMaybe<OrderTagScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<OrderTagOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<OrderTagWhereInput>;
};


export type QueryGroupByPaymentArgs = {
  by: Array<PaymentScalarFieldEnum>;
  having?: InputMaybe<PaymentScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<PaymentOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PaymentWhereInput>;
};


export type QueryGroupByPostArgs = {
  by: Array<PostScalarFieldEnum>;
  having?: InputMaybe<PostScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<PostOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PostWhereInput>;
};


export type QueryGroupByPostHistoryArgs = {
  by: Array<PostHistoryScalarFieldEnum>;
  having?: InputMaybe<PostHistoryScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<PostHistoryOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PostHistoryWhereInput>;
};


export type QueryGroupByPostPackageArgs = {
  by: Array<PostPackageScalarFieldEnum>;
  having?: InputMaybe<PostPackageScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<PostPackageOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PostPackageWhereInput>;
};


export type QueryGroupByPostTagArgs = {
  by: Array<PostTagScalarFieldEnum>;
  having?: InputMaybe<PostTagScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<PostTagOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PostTagWhereInput>;
};


export type QueryGroupByProductArgs = {
  by: Array<ProductScalarFieldEnum>;
  having?: InputMaybe<ProductScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<ProductOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ProductWhereInput>;
};


export type QueryGroupByProfitableArgs = {
  by: Array<ProfitableScalarFieldEnum>;
  having?: InputMaybe<ProfitableScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<ProfitableOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ProfitableWhereInput>;
};


export type QueryGroupByProfitableBadgetArgs = {
  by: Array<ProfitableBadgetScalarFieldEnum>;
  having?: InputMaybe<ProfitableBadgetScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<ProfitableBadgetOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ProfitableBadgetWhereInput>;
};


export type QueryGroupByQrcodeArgs = {
  by: Array<QrcodeScalarFieldEnum>;
  having?: InputMaybe<QrcodeScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<QrcodeOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<QrcodeWhereInput>;
};


export type QueryGroupByRemoteStringArgs = {
  by: Array<RemoteStringScalarFieldEnum>;
  having?: InputMaybe<RemoteStringScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<RemoteStringOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<RemoteStringWhereInput>;
};


export type QueryGroupByReportArgs = {
  by: Array<ReportScalarFieldEnum>;
  having?: InputMaybe<ReportScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<ReportOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ReportWhereInput>;
};


export type QueryGroupByReportUserArgs = {
  by: Array<ReportUserScalarFieldEnum>;
  having?: InputMaybe<ReportUserScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<ReportUserOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ReportUserWhereInput>;
};


export type QueryGroupByScenicSpotArgs = {
  by: Array<ScenicSpotScalarFieldEnum>;
  having?: InputMaybe<ScenicSpotScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<ScenicSpotOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ScenicSpotWhereInput>;
};


export type QueryGroupBySettingNotificationArgs = {
  by: Array<SettingNotificationScalarFieldEnum>;
  having?: InputMaybe<SettingNotificationScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<SettingNotificationOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<SettingNotificationWhereInput>;
};


export type QueryGroupBySettingNotificationSuppressArgs = {
  by: Array<SettingNotificationSuppressScalarFieldEnum>;
  having?: InputMaybe<SettingNotificationSuppressScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<SettingNotificationSuppressOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<SettingNotificationSuppressWhereInput>;
};


export type QueryGroupBySettingPrivacyArgs = {
  by: Array<SettingPrivacyScalarFieldEnum>;
  having?: InputMaybe<SettingPrivacyScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<SettingPrivacyOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<SettingPrivacyWhereInput>;
};


export type QueryGroupBySignatureArgs = {
  by: Array<SignatureScalarFieldEnum>;
  having?: InputMaybe<SignatureScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<SignatureOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<SignatureWhereInput>;
};


export type QueryGroupByStampArgs = {
  by: Array<StampScalarFieldEnum>;
  having?: InputMaybe<StampScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<StampOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<StampWhereInput>;
};


export type QueryGroupByStoreItemArgs = {
  by: Array<StoreItemScalarFieldEnum>;
  having?: InputMaybe<StoreItemScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<StoreItemOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<StoreItemWhereInput>;
};


export type QueryGroupByStoreItemTagArgs = {
  by: Array<StoreItemTagScalarFieldEnum>;
  having?: InputMaybe<StoreItemTagScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<StoreItemTagOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<StoreItemTagWhereInput>;
};


export type QueryGroupByStoreItemToCartItemArgs = {
  by: Array<StoreItemToCartItemScalarFieldEnum>;
  having?: InputMaybe<StoreItemToCartItemScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<StoreItemToCartItemOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<StoreItemToCartItemWhereInput>;
};


export type QueryGroupByStoreRatioArgs = {
  by: Array<StoreRatioScalarFieldEnum>;
  having?: InputMaybe<StoreRatioScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<StoreRatioOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<StoreRatioWhereInput>;
};


export type QueryGroupByStoreRatioHistoryItemArgs = {
  by: Array<StoreRatioHistoryItemScalarFieldEnum>;
  having?: InputMaybe<StoreRatioHistoryItemScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<StoreRatioHistoryItemOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<StoreRatioHistoryItemWhereInput>;
};


export type QueryGroupByTappayArgs = {
  by: Array<TappayScalarFieldEnum>;
  having?: InputMaybe<TappayScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<TappayOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<TappayWhereInput>;
};


export type QueryGroupByUserArgs = {
  by: Array<UserScalarFieldEnum>;
  having?: InputMaybe<UserScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<UserOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<UserWhereInput>;
};


export type QueryGroupByUserFriendArgs = {
  by: Array<UserFriendScalarFieldEnum>;
  having?: InputMaybe<UserFriendScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<UserFriendOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<UserFriendWhereInput>;
};


export type QueryGroupByVirtualAddrMappingArgs = {
  by: Array<VirtualAddrMappingScalarFieldEnum>;
  having?: InputMaybe<VirtualAddrMappingScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<VirtualAddrMappingOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<VirtualAddrMappingWhereInput>;
};


export type QueryGroupByVirtualLandArgs = {
  by: Array<VirtualLandScalarFieldEnum>;
  having?: InputMaybe<VirtualLandScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<VirtualLandOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<VirtualLandWhereInput>;
};


export type QueryGroupsArgs = {
  cursor?: InputMaybe<GroupWhereUniqueInput>;
  distinct?: InputMaybe<Array<GroupScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<GroupOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<GroupWhereInput>;
};


export type QueryInValidReasonArgs = {
  where: InValidReasonWhereUniqueInput;
};


export type QueryInValidReasonsArgs = {
  cursor?: InputMaybe<InValidReasonWhereUniqueInput>;
  distinct?: InputMaybe<Array<InValidReasonScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<InValidReasonOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<InValidReasonWhereInput>;
};


export type QueryInvoiceArgs = {
  where: InvoiceWhereUniqueInput;
};


export type QueryInvoicesArgs = {
  cursor?: InputMaybe<InvoiceWhereUniqueInput>;
  distinct?: InputMaybe<Array<InvoiceScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<InvoiceOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<InvoiceWhereInput>;
};


export type QueryManualAddrArgs = {
  where: ManualAddrWhereUniqueInput;
};


export type QueryManualAddrsArgs = {
  cursor?: InputMaybe<ManualAddrWhereUniqueInput>;
  distinct?: InputMaybe<Array<ManualAddrScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ManualAddrOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ManualAddrWhereInput>;
};


export type QueryNotificationArgs = {
  where: NotificationWhereUniqueInput;
};


export type QueryNotificationsArgs = {
  cursor?: InputMaybe<NotificationWhereUniqueInput>;
  distinct?: InputMaybe<Array<NotificationScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<NotificationOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<NotificationWhereInput>;
};


export type QueryOrderArgs = {
  where: OrderWhereUniqueInput;
};


export type QueryOrderDetailArgs = {
  where: OrderDetailWhereUniqueInput;
};


export type QueryOrderDetailHistoriesArgs = {
  cursor?: InputMaybe<OrderDetailHistoryWhereUniqueInput>;
  distinct?: InputMaybe<Array<OrderDetailHistoryScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<OrderDetailHistoryOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<OrderDetailHistoryWhereInput>;
};


export type QueryOrderDetailHistoryArgs = {
  where: OrderDetailHistoryWhereUniqueInput;
};


export type QueryOrderDetailsArgs = {
  cursor?: InputMaybe<OrderDetailWhereUniqueInput>;
  distinct?: InputMaybe<Array<OrderDetailScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<OrderDetailOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<OrderDetailWhereInput>;
};


export type QueryOrderTagArgs = {
  where: OrderTagWhereUniqueInput;
};


export type QueryOrderTagsArgs = {
  cursor?: InputMaybe<OrderTagWhereUniqueInput>;
  distinct?: InputMaybe<Array<OrderTagScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<OrderTagOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<OrderTagWhereInput>;
};


export type QueryOrdersArgs = {
  cursor?: InputMaybe<OrderWhereUniqueInput>;
  distinct?: InputMaybe<Array<OrderScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<OrderOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<OrderWhereInput>;
};


export type QueryPaymentArgs = {
  where: PaymentWhereUniqueInput;
};


export type QueryPaymentsArgs = {
  cursor?: InputMaybe<PaymentWhereUniqueInput>;
  distinct?: InputMaybe<Array<PaymentScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<PaymentOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PaymentWhereInput>;
};


export type QueryPostArgs = {
  where: PostWhereUniqueInput;
};


export type QueryPostHistoriesArgs = {
  cursor?: InputMaybe<PostHistoryWhereUniqueInput>;
  distinct?: InputMaybe<Array<PostHistoryScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<PostHistoryOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PostHistoryWhereInput>;
};


export type QueryPostHistoryArgs = {
  where: PostHistoryWhereUniqueInput;
};


export type QueryPostPackageArgs = {
  where: PostPackageWhereUniqueInput;
};


export type QueryPostPackagesArgs = {
  cursor?: InputMaybe<PostPackageWhereUniqueInput>;
  distinct?: InputMaybe<Array<PostPackageScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<PostPackageOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PostPackageWhereInput>;
};


export type QueryPostTagArgs = {
  where: PostTagWhereUniqueInput;
};


export type QueryPostTagsArgs = {
  cursor?: InputMaybe<PostTagWhereUniqueInput>;
  distinct?: InputMaybe<Array<PostTagScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<PostTagOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PostTagWhereInput>;
};


export type QueryPostsArgs = {
  cursor?: InputMaybe<PostWhereUniqueInput>;
  distinct?: InputMaybe<Array<PostScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<PostOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PostWhereInput>;
};


export type QueryProductArgs = {
  where: ProductWhereUniqueInput;
};


export type QueryProductsArgs = {
  cursor?: InputMaybe<ProductWhereUniqueInput>;
  distinct?: InputMaybe<Array<ProductScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ProductOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ProductWhereInput>;
};


export type QueryProfitableArgs = {
  where: ProfitableWhereUniqueInput;
};


export type QueryProfitableBadgetArgs = {
  where: ProfitableBadgetWhereUniqueInput;
};


export type QueryProfitableBadgetsArgs = {
  cursor?: InputMaybe<ProfitableBadgetWhereUniqueInput>;
  distinct?: InputMaybe<Array<ProfitableBadgetScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ProfitableBadgetOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ProfitableBadgetWhereInput>;
};


export type QueryProfitablesArgs = {
  cursor?: InputMaybe<ProfitableWhereUniqueInput>;
  distinct?: InputMaybe<Array<ProfitableScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ProfitableOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ProfitableWhereInput>;
};


export type QueryQrcodeArgs = {
  where: QrcodeWhereUniqueInput;
};


export type QueryQrcodesArgs = {
  cursor?: InputMaybe<QrcodeWhereUniqueInput>;
  distinct?: InputMaybe<Array<QrcodeScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<QrcodeOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<QrcodeWhereInput>;
};


export type QueryRemoteStringArgs = {
  where: RemoteStringWhereUniqueInput;
};


export type QueryRemoteStringsArgs = {
  cursor?: InputMaybe<RemoteStringWhereUniqueInput>;
  distinct?: InputMaybe<Array<RemoteStringScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<RemoteStringOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<RemoteStringWhereInput>;
};


export type QueryReportArgs = {
  where: ReportWhereUniqueInput;
};


export type QueryReportUserArgs = {
  where: ReportUserWhereUniqueInput;
};


export type QueryReportUsersArgs = {
  cursor?: InputMaybe<ReportUserWhereUniqueInput>;
  distinct?: InputMaybe<Array<ReportUserScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ReportUserOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ReportUserWhereInput>;
};


export type QueryReportsArgs = {
  cursor?: InputMaybe<ReportWhereUniqueInput>;
  distinct?: InputMaybe<Array<ReportScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ReportOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ReportWhereInput>;
};


export type QueryScenicSpotArgs = {
  where: ScenicSpotWhereUniqueInput;
};


export type QueryScenicSpotsArgs = {
  cursor?: InputMaybe<ScenicSpotWhereUniqueInput>;
  distinct?: InputMaybe<Array<ScenicSpotScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ScenicSpotOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ScenicSpotWhereInput>;
};


export type QuerySearchStoreArgs = {
  keyword: Scalars['String'];
  maxCount?: Scalars['Int'];
};


export type QuerySettingNotificationArgs = {
  where: SettingNotificationWhereUniqueInput;
};


export type QuerySettingNotificationSuppressArgs = {
  where: SettingNotificationSuppressWhereUniqueInput;
};


export type QuerySettingNotificationSuppressesArgs = {
  cursor?: InputMaybe<SettingNotificationSuppressWhereUniqueInput>;
  distinct?: InputMaybe<Array<SettingNotificationSuppressScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<SettingNotificationSuppressOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<SettingNotificationSuppressWhereInput>;
};


export type QuerySettingNotificationsArgs = {
  cursor?: InputMaybe<SettingNotificationWhereUniqueInput>;
  distinct?: InputMaybe<Array<SettingNotificationScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<SettingNotificationOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<SettingNotificationWhereInput>;
};


export type QuerySettingPrivaciesArgs = {
  cursor?: InputMaybe<SettingPrivacyWhereUniqueInput>;
  distinct?: InputMaybe<Array<SettingPrivacyScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<SettingPrivacyOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<SettingPrivacyWhereInput>;
};


export type QuerySettingPrivacyArgs = {
  where: SettingPrivacyWhereUniqueInput;
};


export type QuerySignatureArgs = {
  where: SignatureWhereUniqueInput;
};


export type QuerySignaturesArgs = {
  cursor?: InputMaybe<SignatureWhereUniqueInput>;
  distinct?: InputMaybe<Array<SignatureScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<SignatureOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<SignatureWhereInput>;
};


export type QueryStampArgs = {
  where: StampWhereUniqueInput;
};


export type QueryStampsArgs = {
  cursor?: InputMaybe<StampWhereUniqueInput>;
  distinct?: InputMaybe<Array<StampScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<StampOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<StampWhereInput>;
};


export type QueryStoreItemArgs = {
  where: StoreItemWhereUniqueInput;
};


export type QueryStoreItemTagArgs = {
  where: StoreItemTagWhereUniqueInput;
};


export type QueryStoreItemTagsArgs = {
  cursor?: InputMaybe<StoreItemTagWhereUniqueInput>;
  distinct?: InputMaybe<Array<StoreItemTagScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<StoreItemTagOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<StoreItemTagWhereInput>;
};


export type QueryStoreItemToCartItemArgs = {
  where: StoreItemToCartItemWhereUniqueInput;
};


export type QueryStoreItemToCartItemsArgs = {
  cursor?: InputMaybe<StoreItemToCartItemWhereUniqueInput>;
  distinct?: InputMaybe<Array<StoreItemToCartItemScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<StoreItemToCartItemOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<StoreItemToCartItemWhereInput>;
};


export type QueryStoreItemsArgs = {
  cursor?: InputMaybe<StoreItemWhereUniqueInput>;
  distinct?: InputMaybe<Array<StoreItemScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<StoreItemOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<StoreItemWhereInput>;
};


export type QueryStoreRatioArgs = {
  where: StoreRatioWhereUniqueInput;
};


export type QueryStoreRatioHistoryItemArgs = {
  where: StoreRatioHistoryItemWhereUniqueInput;
};


export type QueryStoreRatioHistoryItemsArgs = {
  cursor?: InputMaybe<StoreRatioHistoryItemWhereUniqueInput>;
  distinct?: InputMaybe<Array<StoreRatioHistoryItemScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<StoreRatioHistoryItemOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<StoreRatioHistoryItemWhereInput>;
};


export type QueryStoreRatiosArgs = {
  cursor?: InputMaybe<StoreRatioWhereUniqueInput>;
  distinct?: InputMaybe<Array<StoreRatioScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<StoreRatioOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<StoreRatioWhereInput>;
};


export type QueryTappayArgs = {
  where: TappayWhereUniqueInput;
};


export type QueryTappaysArgs = {
  cursor?: InputMaybe<TappayWhereUniqueInput>;
  distinct?: InputMaybe<Array<TappayScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<TappayOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<TappayWhereInput>;
};


export type QueryUserArgs = {
  where: UserWhereUniqueInput;
};


export type QueryUserFriendArgs = {
  where: UserFriendWhereUniqueInput;
};


export type QueryUserFriendsArgs = {
  cursor?: InputMaybe<UserFriendWhereUniqueInput>;
  distinct?: InputMaybe<Array<UserFriendScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<UserFriendOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<UserFriendWhereInput>;
};


export type QueryUsersArgs = {
  cursor?: InputMaybe<UserWhereUniqueInput>;
  distinct?: InputMaybe<Array<UserScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<UserOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<UserWhereInput>;
};


export type QueryVirtualAddrMappingArgs = {
  where: VirtualAddrMappingWhereUniqueInput;
};


export type QueryVirtualAddrMappingsArgs = {
  cursor?: InputMaybe<VirtualAddrMappingWhereUniqueInput>;
  distinct?: InputMaybe<Array<VirtualAddrMappingScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<VirtualAddrMappingOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<VirtualAddrMappingWhereInput>;
};


export type QueryVirtualLandArgs = {
  where: VirtualLandWhereUniqueInput;
};


export type QueryVirtualLandsArgs = {
  cursor?: InputMaybe<VirtualLandWhereUniqueInput>;
  distinct?: InputMaybe<Array<VirtualLandScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<VirtualLandOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<VirtualLandWhereInput>;
};

export enum QueryMode {
  Default = 'default',
  Insensitive = 'insensitive'
}

export enum ReceiverSettingNotification {
  OnlyFollowing = 'onlyFollowing',
  OnlyFriend = 'onlyFriend',
  Private = 'private',
  Public = 'public'
}

export enum ReceiverSettingPrivacy {
  OnlyBestFriend = 'onlyBestFriend',
  OnlyFriend = 'onlyFriend',
  Private = 'private'
}

export type RemoteString = {
  __typename?: 'RemoteString';
  createdAt: Scalars['DateTime'];
  en: Scalars['String'];
  id: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  valid: Scalars['Boolean'];
  zh_Hans_CN: Scalars['String'];
  zh_Hant_TW: Scalars['String'];
};

export type RemoteStringCountAggregate = {
  __typename?: 'RemoteStringCountAggregate';
  _all: Scalars['Int'];
  createdAt: Scalars['Int'];
  en: Scalars['Int'];
  id: Scalars['Int'];
  updatedAt: Scalars['Int'];
  valid: Scalars['Int'];
  zh_Hans_CN: Scalars['Int'];
  zh_Hant_TW: Scalars['Int'];
};

export type RemoteStringCountOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  en?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  valid?: InputMaybe<SortOrder>;
  zh_Hans_CN?: InputMaybe<SortOrder>;
  zh_Hant_TW?: InputMaybe<SortOrder>;
};

export type RemoteStringCreateInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  en: Scalars['String'];
  id?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  valid?: InputMaybe<Scalars['Boolean']>;
  zh_Hans_CN: Scalars['String'];
  zh_Hant_TW: Scalars['String'];
};

export type RemoteStringCreateManyInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  en: Scalars['String'];
  id?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  valid?: InputMaybe<Scalars['Boolean']>;
  zh_Hans_CN: Scalars['String'];
  zh_Hant_TW: Scalars['String'];
};

export type RemoteStringGroupBy = {
  __typename?: 'RemoteStringGroupBy';
  _count?: Maybe<RemoteStringCountAggregate>;
  _max?: Maybe<RemoteStringMaxAggregate>;
  _min?: Maybe<RemoteStringMinAggregate>;
  createdAt: Scalars['DateTime'];
  en: Scalars['String'];
  id: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  valid: Scalars['Boolean'];
  zh_Hans_CN: Scalars['String'];
  zh_Hant_TW: Scalars['String'];
};

export type RemoteStringMaxAggregate = {
  __typename?: 'RemoteStringMaxAggregate';
  createdAt?: Maybe<Scalars['DateTime']>;
  en?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  valid?: Maybe<Scalars['Boolean']>;
  zh_Hans_CN?: Maybe<Scalars['String']>;
  zh_Hant_TW?: Maybe<Scalars['String']>;
};

export type RemoteStringMaxOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  en?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  valid?: InputMaybe<SortOrder>;
  zh_Hans_CN?: InputMaybe<SortOrder>;
  zh_Hant_TW?: InputMaybe<SortOrder>;
};

export type RemoteStringMinAggregate = {
  __typename?: 'RemoteStringMinAggregate';
  createdAt?: Maybe<Scalars['DateTime']>;
  en?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  valid?: Maybe<Scalars['Boolean']>;
  zh_Hans_CN?: Maybe<Scalars['String']>;
  zh_Hant_TW?: Maybe<Scalars['String']>;
};

export type RemoteStringMinOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  en?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  valid?: InputMaybe<SortOrder>;
  zh_Hans_CN?: InputMaybe<SortOrder>;
  zh_Hant_TW?: InputMaybe<SortOrder>;
};

export type RemoteStringOrderByWithAggregationInput = {
  _count?: InputMaybe<RemoteStringCountOrderByAggregateInput>;
  _max?: InputMaybe<RemoteStringMaxOrderByAggregateInput>;
  _min?: InputMaybe<RemoteStringMinOrderByAggregateInput>;
  createdAt?: InputMaybe<SortOrder>;
  en?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  valid?: InputMaybe<SortOrder>;
  zh_Hans_CN?: InputMaybe<SortOrder>;
  zh_Hant_TW?: InputMaybe<SortOrder>;
};

export type RemoteStringOrderByWithRelationInput = {
  createdAt?: InputMaybe<SortOrder>;
  en?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  valid?: InputMaybe<SortOrder>;
  zh_Hans_CN?: InputMaybe<SortOrder>;
  zh_Hant_TW?: InputMaybe<SortOrder>;
};

export enum RemoteStringScalarFieldEnum {
  CreatedAt = 'createdAt',
  En = 'en',
  Id = 'id',
  UpdatedAt = 'updatedAt',
  Valid = 'valid',
  ZhHansCn = 'zh_Hans_CN',
  ZhHantTw = 'zh_Hant_TW'
}

export type RemoteStringScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<RemoteStringScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<RemoteStringScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<RemoteStringScalarWhereWithAggregatesInput>>;
  createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  en?: InputMaybe<StringWithAggregatesFilter>;
  id?: InputMaybe<StringWithAggregatesFilter>;
  updatedAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  valid?: InputMaybe<BoolWithAggregatesFilter>;
  zh_Hans_CN?: InputMaybe<StringWithAggregatesFilter>;
  zh_Hant_TW?: InputMaybe<StringWithAggregatesFilter>;
};

export type RemoteStringUpdateInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  en?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  valid?: InputMaybe<BoolFieldUpdateOperationsInput>;
  zh_Hans_CN?: InputMaybe<StringFieldUpdateOperationsInput>;
  zh_Hant_TW?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type RemoteStringUpdateManyMutationInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  en?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  valid?: InputMaybe<BoolFieldUpdateOperationsInput>;
  zh_Hans_CN?: InputMaybe<StringFieldUpdateOperationsInput>;
  zh_Hant_TW?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type RemoteStringWhereInput = {
  AND?: InputMaybe<Array<RemoteStringWhereInput>>;
  NOT?: InputMaybe<Array<RemoteStringWhereInput>>;
  OR?: InputMaybe<Array<RemoteStringWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  en?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  valid?: InputMaybe<BoolFilter>;
  zh_Hans_CN?: InputMaybe<StringFilter>;
  zh_Hant_TW?: InputMaybe<StringFilter>;
};

export type RemoteStringWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
};

export type Report = {
  __typename?: 'Report';
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  post: Post;
  postId: Scalars['String'];
  reporter?: Maybe<User>;
  reporterId?: Maybe<Scalars['String']>;
  type: ReportType;
  updatedAt: Scalars['DateTime'];
};

export type ReportCountAggregate = {
  __typename?: 'ReportCountAggregate';
  _all: Scalars['Int'];
  createdAt: Scalars['Int'];
  id: Scalars['Int'];
  postId: Scalars['Int'];
  reporterId: Scalars['Int'];
  type: Scalars['Int'];
  updatedAt: Scalars['Int'];
};

export type ReportCountOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  postId?: InputMaybe<SortOrder>;
  reporterId?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type ReportCreateInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  post: PostCreateNestedOneWithoutReportsInput;
  reporter?: InputMaybe<UserCreateNestedOneWithoutReportsInput>;
  type: ReportType;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ReportCreateManyInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  postId: Scalars['String'];
  reporterId?: InputMaybe<Scalars['String']>;
  type: ReportType;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ReportCreateManyPostInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  reporterId?: InputMaybe<Scalars['String']>;
  type: ReportType;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ReportCreateManyPostInputEnvelope = {
  data: Array<ReportCreateManyPostInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type ReportCreateManyReporterInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  postId: Scalars['String'];
  type: ReportType;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ReportCreateManyReporterInputEnvelope = {
  data: Array<ReportCreateManyReporterInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type ReportCreateNestedManyWithoutPostInput = {
  connect?: InputMaybe<Array<ReportWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ReportCreateOrConnectWithoutPostInput>>;
  create?: InputMaybe<Array<ReportCreateWithoutPostInput>>;
  createMany?: InputMaybe<ReportCreateManyPostInputEnvelope>;
};

export type ReportCreateNestedManyWithoutReporterInput = {
  connect?: InputMaybe<Array<ReportWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ReportCreateOrConnectWithoutReporterInput>>;
  create?: InputMaybe<Array<ReportCreateWithoutReporterInput>>;
  createMany?: InputMaybe<ReportCreateManyReporterInputEnvelope>;
};

export type ReportCreateOrConnectWithoutPostInput = {
  create: ReportCreateWithoutPostInput;
  where: ReportWhereUniqueInput;
};

export type ReportCreateOrConnectWithoutReporterInput = {
  create: ReportCreateWithoutReporterInput;
  where: ReportWhereUniqueInput;
};

export type ReportCreateWithoutPostInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  reporter?: InputMaybe<UserCreateNestedOneWithoutReportsInput>;
  type: ReportType;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ReportCreateWithoutReporterInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  post: PostCreateNestedOneWithoutReportsInput;
  type: ReportType;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ReportGroupBy = {
  __typename?: 'ReportGroupBy';
  _count?: Maybe<ReportCountAggregate>;
  _max?: Maybe<ReportMaxAggregate>;
  _min?: Maybe<ReportMinAggregate>;
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  postId: Scalars['String'];
  reporterId?: Maybe<Scalars['String']>;
  type: ReportType;
  updatedAt: Scalars['DateTime'];
};

export type ReportListRelationFilter = {
  every?: InputMaybe<ReportWhereInput>;
  none?: InputMaybe<ReportWhereInput>;
  some?: InputMaybe<ReportWhereInput>;
};

export type ReportMaxAggregate = {
  __typename?: 'ReportMaxAggregate';
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  postId?: Maybe<Scalars['String']>;
  reporterId?: Maybe<Scalars['String']>;
  type?: Maybe<ReportType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ReportMaxOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  postId?: InputMaybe<SortOrder>;
  reporterId?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type ReportMinAggregate = {
  __typename?: 'ReportMinAggregate';
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  postId?: Maybe<Scalars['String']>;
  reporterId?: Maybe<Scalars['String']>;
  type?: Maybe<ReportType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ReportMinOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  postId?: InputMaybe<SortOrder>;
  reporterId?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type ReportOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type ReportOrderByWithAggregationInput = {
  _count?: InputMaybe<ReportCountOrderByAggregateInput>;
  _max?: InputMaybe<ReportMaxOrderByAggregateInput>;
  _min?: InputMaybe<ReportMinOrderByAggregateInput>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  postId?: InputMaybe<SortOrder>;
  reporterId?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type ReportOrderByWithRelationInput = {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  post?: InputMaybe<PostOrderByWithRelationInput>;
  postId?: InputMaybe<SortOrder>;
  reporter?: InputMaybe<UserOrderByWithRelationInput>;
  reporterId?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export enum ReportScalarFieldEnum {
  CreatedAt = 'createdAt',
  Id = 'id',
  PostId = 'postId',
  ReporterId = 'reporterId',
  Type = 'type',
  UpdatedAt = 'updatedAt'
}

export type ReportScalarWhereInput = {
  AND?: InputMaybe<Array<ReportScalarWhereInput>>;
  NOT?: InputMaybe<Array<ReportScalarWhereInput>>;
  OR?: InputMaybe<Array<ReportScalarWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  postId?: InputMaybe<StringFilter>;
  reporterId?: InputMaybe<StringNullableFilter>;
  type?: InputMaybe<EnumReportTypeFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type ReportScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<ReportScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<ReportScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<ReportScalarWhereWithAggregatesInput>>;
  createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  id?: InputMaybe<StringWithAggregatesFilter>;
  postId?: InputMaybe<StringWithAggregatesFilter>;
  reporterId?: InputMaybe<StringNullableWithAggregatesFilter>;
  type?: InputMaybe<EnumReportTypeWithAggregatesFilter>;
  updatedAt?: InputMaybe<DateTimeWithAggregatesFilter>;
};

export enum ReportType {
  Fraud = 'FRAUD',
  Hatred = 'HATRED',
  Naked = 'NAKED',
  Other = 'OTHER',
  Rumor = 'RUMOR',
  Trash = 'TRASH'
}

export type ReportUpdateInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  post?: InputMaybe<PostUpdateOneRequiredWithoutReportsNestedInput>;
  reporter?: InputMaybe<UserUpdateOneWithoutReportsNestedInput>;
  type?: InputMaybe<EnumReportTypeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type ReportUpdateManyMutationInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  type?: InputMaybe<EnumReportTypeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type ReportUpdateManyWithWhereWithoutPostInput = {
  data: ReportUpdateManyMutationInput;
  where: ReportScalarWhereInput;
};

export type ReportUpdateManyWithWhereWithoutReporterInput = {
  data: ReportUpdateManyMutationInput;
  where: ReportScalarWhereInput;
};

export type ReportUpdateManyWithoutPostNestedInput = {
  connect?: InputMaybe<Array<ReportWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ReportCreateOrConnectWithoutPostInput>>;
  create?: InputMaybe<Array<ReportCreateWithoutPostInput>>;
  createMany?: InputMaybe<ReportCreateManyPostInputEnvelope>;
  delete?: InputMaybe<Array<ReportWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ReportScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ReportWhereUniqueInput>>;
  set?: InputMaybe<Array<ReportWhereUniqueInput>>;
  update?: InputMaybe<Array<ReportUpdateWithWhereUniqueWithoutPostInput>>;
  updateMany?: InputMaybe<Array<ReportUpdateManyWithWhereWithoutPostInput>>;
  upsert?: InputMaybe<Array<ReportUpsertWithWhereUniqueWithoutPostInput>>;
};

export type ReportUpdateManyWithoutReporterNestedInput = {
  connect?: InputMaybe<Array<ReportWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ReportCreateOrConnectWithoutReporterInput>>;
  create?: InputMaybe<Array<ReportCreateWithoutReporterInput>>;
  createMany?: InputMaybe<ReportCreateManyReporterInputEnvelope>;
  delete?: InputMaybe<Array<ReportWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ReportScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ReportWhereUniqueInput>>;
  set?: InputMaybe<Array<ReportWhereUniqueInput>>;
  update?: InputMaybe<Array<ReportUpdateWithWhereUniqueWithoutReporterInput>>;
  updateMany?: InputMaybe<Array<ReportUpdateManyWithWhereWithoutReporterInput>>;
  upsert?: InputMaybe<Array<ReportUpsertWithWhereUniqueWithoutReporterInput>>;
};

export type ReportUpdateWithWhereUniqueWithoutPostInput = {
  data: ReportUpdateWithoutPostInput;
  where: ReportWhereUniqueInput;
};

export type ReportUpdateWithWhereUniqueWithoutReporterInput = {
  data: ReportUpdateWithoutReporterInput;
  where: ReportWhereUniqueInput;
};

export type ReportUpdateWithoutPostInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  reporter?: InputMaybe<UserUpdateOneWithoutReportsNestedInput>;
  type?: InputMaybe<EnumReportTypeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type ReportUpdateWithoutReporterInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  post?: InputMaybe<PostUpdateOneRequiredWithoutReportsNestedInput>;
  type?: InputMaybe<EnumReportTypeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type ReportUpsertWithWhereUniqueWithoutPostInput = {
  create: ReportCreateWithoutPostInput;
  update: ReportUpdateWithoutPostInput;
  where: ReportWhereUniqueInput;
};

export type ReportUpsertWithWhereUniqueWithoutReporterInput = {
  create: ReportCreateWithoutReporterInput;
  update: ReportUpdateWithoutReporterInput;
  where: ReportWhereUniqueInput;
};

export type ReportUser = {
  __typename?: 'ReportUser';
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  reporter?: Maybe<User>;
  reporterId?: Maybe<Scalars['String']>;
  type: ReportUserType;
  updatedAt: Scalars['DateTime'];
  user: User;
  userId: Scalars['String'];
};

export type ReportUserCountAggregate = {
  __typename?: 'ReportUserCountAggregate';
  _all: Scalars['Int'];
  createdAt: Scalars['Int'];
  id: Scalars['Int'];
  reporterId: Scalars['Int'];
  type: Scalars['Int'];
  updatedAt: Scalars['Int'];
  userId: Scalars['Int'];
};

export type ReportUserCountOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  reporterId?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
};

export type ReportUserCreateInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  reporter?: InputMaybe<UserCreateNestedOneWithoutReportByMeInput>;
  type: ReportUserType;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  user: UserCreateNestedOneWithoutReportedInput;
};

export type ReportUserCreateManyInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  reporterId?: InputMaybe<Scalars['String']>;
  type: ReportUserType;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userId: Scalars['String'];
};

export type ReportUserCreateManyReporterInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  type: ReportUserType;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userId: Scalars['String'];
};

export type ReportUserCreateManyReporterInputEnvelope = {
  data: Array<ReportUserCreateManyReporterInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type ReportUserCreateManyUserInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  reporterId?: InputMaybe<Scalars['String']>;
  type: ReportUserType;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ReportUserCreateManyUserInputEnvelope = {
  data: Array<ReportUserCreateManyUserInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type ReportUserCreateNestedManyWithoutReporterInput = {
  connect?: InputMaybe<Array<ReportUserWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ReportUserCreateOrConnectWithoutReporterInput>>;
  create?: InputMaybe<Array<ReportUserCreateWithoutReporterInput>>;
  createMany?: InputMaybe<ReportUserCreateManyReporterInputEnvelope>;
};

export type ReportUserCreateNestedManyWithoutUserInput = {
  connect?: InputMaybe<Array<ReportUserWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ReportUserCreateOrConnectWithoutUserInput>>;
  create?: InputMaybe<Array<ReportUserCreateWithoutUserInput>>;
  createMany?: InputMaybe<ReportUserCreateManyUserInputEnvelope>;
};

export type ReportUserCreateOrConnectWithoutReporterInput = {
  create: ReportUserCreateWithoutReporterInput;
  where: ReportUserWhereUniqueInput;
};

export type ReportUserCreateOrConnectWithoutUserInput = {
  create: ReportUserCreateWithoutUserInput;
  where: ReportUserWhereUniqueInput;
};

export type ReportUserCreateWithoutReporterInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  type: ReportUserType;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  user: UserCreateNestedOneWithoutReportedInput;
};

export type ReportUserCreateWithoutUserInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  reporter?: InputMaybe<UserCreateNestedOneWithoutReportByMeInput>;
  type: ReportUserType;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ReportUserGroupBy = {
  __typename?: 'ReportUserGroupBy';
  _count?: Maybe<ReportUserCountAggregate>;
  _max?: Maybe<ReportUserMaxAggregate>;
  _min?: Maybe<ReportUserMinAggregate>;
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  reporterId?: Maybe<Scalars['String']>;
  type: ReportUserType;
  updatedAt: Scalars['DateTime'];
  userId: Scalars['String'];
};

export type ReportUserListRelationFilter = {
  every?: InputMaybe<ReportUserWhereInput>;
  none?: InputMaybe<ReportUserWhereInput>;
  some?: InputMaybe<ReportUserWhereInput>;
};

export type ReportUserMaxAggregate = {
  __typename?: 'ReportUserMaxAggregate';
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  reporterId?: Maybe<Scalars['String']>;
  type?: Maybe<ReportUserType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
};

export type ReportUserMaxOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  reporterId?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
};

export type ReportUserMinAggregate = {
  __typename?: 'ReportUserMinAggregate';
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  reporterId?: Maybe<Scalars['String']>;
  type?: Maybe<ReportUserType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
};

export type ReportUserMinOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  reporterId?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
};

export type ReportUserOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type ReportUserOrderByWithAggregationInput = {
  _count?: InputMaybe<ReportUserCountOrderByAggregateInput>;
  _max?: InputMaybe<ReportUserMaxOrderByAggregateInput>;
  _min?: InputMaybe<ReportUserMinOrderByAggregateInput>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  reporterId?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
};

export type ReportUserOrderByWithRelationInput = {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  reporter?: InputMaybe<UserOrderByWithRelationInput>;
  reporterId?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  user?: InputMaybe<UserOrderByWithRelationInput>;
  userId?: InputMaybe<SortOrder>;
};

export enum ReportUserScalarFieldEnum {
  CreatedAt = 'createdAt',
  Id = 'id',
  ReporterId = 'reporterId',
  Type = 'type',
  UpdatedAt = 'updatedAt',
  UserId = 'userId'
}

export type ReportUserScalarWhereInput = {
  AND?: InputMaybe<Array<ReportUserScalarWhereInput>>;
  NOT?: InputMaybe<Array<ReportUserScalarWhereInput>>;
  OR?: InputMaybe<Array<ReportUserScalarWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  reporterId?: InputMaybe<StringNullableFilter>;
  type?: InputMaybe<EnumReportUserTypeFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  userId?: InputMaybe<StringFilter>;
};

export type ReportUserScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<ReportUserScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<ReportUserScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<ReportUserScalarWhereWithAggregatesInput>>;
  createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  id?: InputMaybe<StringWithAggregatesFilter>;
  reporterId?: InputMaybe<StringNullableWithAggregatesFilter>;
  type?: InputMaybe<EnumReportUserTypeWithAggregatesFilter>;
  updatedAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  userId?: InputMaybe<StringWithAggregatesFilter>;
};

export enum ReportUserType {
  Fakeaccount = 'FAKEACCOUNT',
  Harassment = 'HARASSMENT',
  Illproperty = 'ILLPROPERTY',
  Inapproprite = 'INAPPROPRITE',
  Other = 'OTHER',
  Pretending = 'PRETENDING'
}

export type ReportUserUpdateInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  reporter?: InputMaybe<UserUpdateOneWithoutReportByMeNestedInput>;
  type?: InputMaybe<EnumReportUserTypeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutReportedNestedInput>;
};

export type ReportUserUpdateManyMutationInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  type?: InputMaybe<EnumReportUserTypeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type ReportUserUpdateManyWithWhereWithoutReporterInput = {
  data: ReportUserUpdateManyMutationInput;
  where: ReportUserScalarWhereInput;
};

export type ReportUserUpdateManyWithWhereWithoutUserInput = {
  data: ReportUserUpdateManyMutationInput;
  where: ReportUserScalarWhereInput;
};

export type ReportUserUpdateManyWithoutReporterNestedInput = {
  connect?: InputMaybe<Array<ReportUserWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ReportUserCreateOrConnectWithoutReporterInput>>;
  create?: InputMaybe<Array<ReportUserCreateWithoutReporterInput>>;
  createMany?: InputMaybe<ReportUserCreateManyReporterInputEnvelope>;
  delete?: InputMaybe<Array<ReportUserWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ReportUserScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ReportUserWhereUniqueInput>>;
  set?: InputMaybe<Array<ReportUserWhereUniqueInput>>;
  update?: InputMaybe<Array<ReportUserUpdateWithWhereUniqueWithoutReporterInput>>;
  updateMany?: InputMaybe<Array<ReportUserUpdateManyWithWhereWithoutReporterInput>>;
  upsert?: InputMaybe<Array<ReportUserUpsertWithWhereUniqueWithoutReporterInput>>;
};

export type ReportUserUpdateManyWithoutUserNestedInput = {
  connect?: InputMaybe<Array<ReportUserWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ReportUserCreateOrConnectWithoutUserInput>>;
  create?: InputMaybe<Array<ReportUserCreateWithoutUserInput>>;
  createMany?: InputMaybe<ReportUserCreateManyUserInputEnvelope>;
  delete?: InputMaybe<Array<ReportUserWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ReportUserScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ReportUserWhereUniqueInput>>;
  set?: InputMaybe<Array<ReportUserWhereUniqueInput>>;
  update?: InputMaybe<Array<ReportUserUpdateWithWhereUniqueWithoutUserInput>>;
  updateMany?: InputMaybe<Array<ReportUserUpdateManyWithWhereWithoutUserInput>>;
  upsert?: InputMaybe<Array<ReportUserUpsertWithWhereUniqueWithoutUserInput>>;
};

export type ReportUserUpdateWithWhereUniqueWithoutReporterInput = {
  data: ReportUserUpdateWithoutReporterInput;
  where: ReportUserWhereUniqueInput;
};

export type ReportUserUpdateWithWhereUniqueWithoutUserInput = {
  data: ReportUserUpdateWithoutUserInput;
  where: ReportUserWhereUniqueInput;
};

export type ReportUserUpdateWithoutReporterInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  type?: InputMaybe<EnumReportUserTypeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutReportedNestedInput>;
};

export type ReportUserUpdateWithoutUserInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  reporter?: InputMaybe<UserUpdateOneWithoutReportByMeNestedInput>;
  type?: InputMaybe<EnumReportUserTypeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type ReportUserUpsertWithWhereUniqueWithoutReporterInput = {
  create: ReportUserCreateWithoutReporterInput;
  update: ReportUserUpdateWithoutReporterInput;
  where: ReportUserWhereUniqueInput;
};

export type ReportUserUpsertWithWhereUniqueWithoutUserInput = {
  create: ReportUserCreateWithoutUserInput;
  update: ReportUserUpdateWithoutUserInput;
  where: ReportUserWhereUniqueInput;
};

export type ReportUserWhereInput = {
  AND?: InputMaybe<Array<ReportUserWhereInput>>;
  NOT?: InputMaybe<Array<ReportUserWhereInput>>;
  OR?: InputMaybe<Array<ReportUserWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  reporter?: InputMaybe<UserRelationFilter>;
  reporterId?: InputMaybe<StringNullableFilter>;
  type?: InputMaybe<EnumReportUserTypeFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  user?: InputMaybe<UserRelationFilter>;
  userId?: InputMaybe<StringFilter>;
};

export type ReportUserWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
};

export type ReportWhereInput = {
  AND?: InputMaybe<Array<ReportWhereInput>>;
  NOT?: InputMaybe<Array<ReportWhereInput>>;
  OR?: InputMaybe<Array<ReportWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  post?: InputMaybe<PostRelationFilter>;
  postId?: InputMaybe<StringFilter>;
  reporter?: InputMaybe<UserRelationFilter>;
  reporterId?: InputMaybe<StringNullableFilter>;
  type?: InputMaybe<EnumReportTypeFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type ReportWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
};

export type ScenicSpot = {
  __typename?: 'ScenicSpot';
  _count?: Maybe<ScenicSpotCount>;
  cards: Array<Card>;
  collections: Array<Collection>;
  country: Scalars['String'];
  createdAt: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  latitude: Scalars['Int'];
  longitude: Scalars['Int'];
  name: Scalars['String'];
  posts: Array<Post>;
  stamps: Array<Stamp>;
  updatedAt: Scalars['DateTime'];
  valid: Scalars['Boolean'];
};


export type ScenicSpotCardsArgs = {
  cursor?: InputMaybe<CardWhereUniqueInput>;
  distinct?: InputMaybe<Array<CardScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<CardOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CardWhereInput>;
};


export type ScenicSpotCollectionsArgs = {
  cursor?: InputMaybe<CollectionWhereUniqueInput>;
  distinct?: InputMaybe<Array<CollectionScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<CollectionOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CollectionWhereInput>;
};


export type ScenicSpotPostsArgs = {
  cursor?: InputMaybe<PostWhereUniqueInput>;
  distinct?: InputMaybe<Array<PostScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<PostOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PostWhereInput>;
};


export type ScenicSpotStampsArgs = {
  cursor?: InputMaybe<StampWhereUniqueInput>;
  distinct?: InputMaybe<Array<StampScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<StampOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<StampWhereInput>;
};

export type ScenicSpotAvgAggregate = {
  __typename?: 'ScenicSpotAvgAggregate';
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
};

export type ScenicSpotAvgOrderByAggregateInput = {
  latitude?: InputMaybe<SortOrder>;
  longitude?: InputMaybe<SortOrder>;
};

export type ScenicSpotCount = {
  __typename?: 'ScenicSpotCount';
  cards: Scalars['Int'];
  collections: Scalars['Int'];
  posts: Scalars['Int'];
  stamps: Scalars['Int'];
};


export type ScenicSpotCountCardsArgs = {
  where?: InputMaybe<CardWhereInput>;
};


export type ScenicSpotCountCollectionsArgs = {
  where?: InputMaybe<CollectionWhereInput>;
};


export type ScenicSpotCountPostsArgs = {
  where?: InputMaybe<PostWhereInput>;
};


export type ScenicSpotCountStampsArgs = {
  where?: InputMaybe<StampWhereInput>;
};

export type ScenicSpotCountAggregate = {
  __typename?: 'ScenicSpotCountAggregate';
  _all: Scalars['Int'];
  country: Scalars['Int'];
  createdAt: Scalars['Int'];
  description: Scalars['Int'];
  id: Scalars['Int'];
  latitude: Scalars['Int'];
  longitude: Scalars['Int'];
  name: Scalars['Int'];
  updatedAt: Scalars['Int'];
  valid: Scalars['Int'];
};

export type ScenicSpotCountOrderByAggregateInput = {
  country?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  latitude?: InputMaybe<SortOrder>;
  longitude?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  valid?: InputMaybe<SortOrder>;
};

export type ScenicSpotCreateInput = {
  cards?: InputMaybe<CardCreateNestedManyWithoutScenicSpotInput>;
  collections?: InputMaybe<CollectionCreateNestedManyWithoutScenicSpotInput>;
  country: Scalars['String'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  latitude: Scalars['Int'];
  longitude: Scalars['Int'];
  name: Scalars['String'];
  posts?: InputMaybe<PostCreateNestedManyWithoutScenicSpotInput>;
  stamps?: InputMaybe<StampCreateNestedManyWithoutScenicSpotInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  valid?: InputMaybe<Scalars['Boolean']>;
};

export type ScenicSpotCreateManyInput = {
  country: Scalars['String'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  latitude: Scalars['Int'];
  longitude: Scalars['Int'];
  name: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  valid?: InputMaybe<Scalars['Boolean']>;
};

export type ScenicSpotCreateNestedOneWithoutCardsInput = {
  connect?: InputMaybe<ScenicSpotWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ScenicSpotCreateOrConnectWithoutCardsInput>;
  create?: InputMaybe<ScenicSpotCreateWithoutCardsInput>;
};

export type ScenicSpotCreateNestedOneWithoutCollectionsInput = {
  connect?: InputMaybe<ScenicSpotWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ScenicSpotCreateOrConnectWithoutCollectionsInput>;
  create?: InputMaybe<ScenicSpotCreateWithoutCollectionsInput>;
};

export type ScenicSpotCreateNestedOneWithoutPostsInput = {
  connect?: InputMaybe<ScenicSpotWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ScenicSpotCreateOrConnectWithoutPostsInput>;
  create?: InputMaybe<ScenicSpotCreateWithoutPostsInput>;
};

export type ScenicSpotCreateNestedOneWithoutStampsInput = {
  connect?: InputMaybe<ScenicSpotWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ScenicSpotCreateOrConnectWithoutStampsInput>;
  create?: InputMaybe<ScenicSpotCreateWithoutStampsInput>;
};

export type ScenicSpotCreateOrConnectWithoutCardsInput = {
  create: ScenicSpotCreateWithoutCardsInput;
  where: ScenicSpotWhereUniqueInput;
};

export type ScenicSpotCreateOrConnectWithoutCollectionsInput = {
  create: ScenicSpotCreateWithoutCollectionsInput;
  where: ScenicSpotWhereUniqueInput;
};

export type ScenicSpotCreateOrConnectWithoutPostsInput = {
  create: ScenicSpotCreateWithoutPostsInput;
  where: ScenicSpotWhereUniqueInput;
};

export type ScenicSpotCreateOrConnectWithoutStampsInput = {
  create: ScenicSpotCreateWithoutStampsInput;
  where: ScenicSpotWhereUniqueInput;
};

export type ScenicSpotCreateWithoutCardsInput = {
  collections?: InputMaybe<CollectionCreateNestedManyWithoutScenicSpotInput>;
  country: Scalars['String'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  latitude: Scalars['Int'];
  longitude: Scalars['Int'];
  name: Scalars['String'];
  posts?: InputMaybe<PostCreateNestedManyWithoutScenicSpotInput>;
  stamps?: InputMaybe<StampCreateNestedManyWithoutScenicSpotInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  valid?: InputMaybe<Scalars['Boolean']>;
};

export type ScenicSpotCreateWithoutCollectionsInput = {
  cards?: InputMaybe<CardCreateNestedManyWithoutScenicSpotInput>;
  country: Scalars['String'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  latitude: Scalars['Int'];
  longitude: Scalars['Int'];
  name: Scalars['String'];
  posts?: InputMaybe<PostCreateNestedManyWithoutScenicSpotInput>;
  stamps?: InputMaybe<StampCreateNestedManyWithoutScenicSpotInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  valid?: InputMaybe<Scalars['Boolean']>;
};

export type ScenicSpotCreateWithoutPostsInput = {
  cards?: InputMaybe<CardCreateNestedManyWithoutScenicSpotInput>;
  collections?: InputMaybe<CollectionCreateNestedManyWithoutScenicSpotInput>;
  country: Scalars['String'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  latitude: Scalars['Int'];
  longitude: Scalars['Int'];
  name: Scalars['String'];
  stamps?: InputMaybe<StampCreateNestedManyWithoutScenicSpotInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  valid?: InputMaybe<Scalars['Boolean']>;
};

export type ScenicSpotCreateWithoutStampsInput = {
  cards?: InputMaybe<CardCreateNestedManyWithoutScenicSpotInput>;
  collections?: InputMaybe<CollectionCreateNestedManyWithoutScenicSpotInput>;
  country: Scalars['String'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  latitude: Scalars['Int'];
  longitude: Scalars['Int'];
  name: Scalars['String'];
  posts?: InputMaybe<PostCreateNestedManyWithoutScenicSpotInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  valid?: InputMaybe<Scalars['Boolean']>;
};

export type ScenicSpotGroupBy = {
  __typename?: 'ScenicSpotGroupBy';
  _avg?: Maybe<ScenicSpotAvgAggregate>;
  _count?: Maybe<ScenicSpotCountAggregate>;
  _max?: Maybe<ScenicSpotMaxAggregate>;
  _min?: Maybe<ScenicSpotMinAggregate>;
  _sum?: Maybe<ScenicSpotSumAggregate>;
  country: Scalars['String'];
  createdAt: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  latitude: Scalars['Int'];
  longitude: Scalars['Int'];
  name: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  valid: Scalars['Boolean'];
};

export type ScenicSpotLatitudeLongitudeCompoundUniqueInput = {
  latitude: Scalars['Int'];
  longitude: Scalars['Int'];
};

export type ScenicSpotMaxAggregate = {
  __typename?: 'ScenicSpotMaxAggregate';
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  latitude?: Maybe<Scalars['Int']>;
  longitude?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  valid?: Maybe<Scalars['Boolean']>;
};

export type ScenicSpotMaxOrderByAggregateInput = {
  country?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  latitude?: InputMaybe<SortOrder>;
  longitude?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  valid?: InputMaybe<SortOrder>;
};

export type ScenicSpotMinAggregate = {
  __typename?: 'ScenicSpotMinAggregate';
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  latitude?: Maybe<Scalars['Int']>;
  longitude?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  valid?: Maybe<Scalars['Boolean']>;
};

export type ScenicSpotMinOrderByAggregateInput = {
  country?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  latitude?: InputMaybe<SortOrder>;
  longitude?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  valid?: InputMaybe<SortOrder>;
};

export type ScenicSpotOrderByWithAggregationInput = {
  _avg?: InputMaybe<ScenicSpotAvgOrderByAggregateInput>;
  _count?: InputMaybe<ScenicSpotCountOrderByAggregateInput>;
  _max?: InputMaybe<ScenicSpotMaxOrderByAggregateInput>;
  _min?: InputMaybe<ScenicSpotMinOrderByAggregateInput>;
  _sum?: InputMaybe<ScenicSpotSumOrderByAggregateInput>;
  country?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  latitude?: InputMaybe<SortOrder>;
  longitude?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  valid?: InputMaybe<SortOrder>;
};

export type ScenicSpotOrderByWithRelationInput = {
  cards?: InputMaybe<CardOrderByRelationAggregateInput>;
  collections?: InputMaybe<CollectionOrderByRelationAggregateInput>;
  country?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  latitude?: InputMaybe<SortOrder>;
  longitude?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  posts?: InputMaybe<PostOrderByRelationAggregateInput>;
  stamps?: InputMaybe<StampOrderByRelationAggregateInput>;
  updatedAt?: InputMaybe<SortOrder>;
  valid?: InputMaybe<SortOrder>;
};

export type ScenicSpotRelationFilter = {
  is?: InputMaybe<ScenicSpotWhereInput>;
  isNot?: InputMaybe<ScenicSpotWhereInput>;
};

export enum ScenicSpotScalarFieldEnum {
  Country = 'country',
  CreatedAt = 'createdAt',
  Description = 'description',
  Id = 'id',
  Latitude = 'latitude',
  Longitude = 'longitude',
  Name = 'name',
  UpdatedAt = 'updatedAt',
  Valid = 'valid'
}

export type ScenicSpotScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<ScenicSpotScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<ScenicSpotScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<ScenicSpotScalarWhereWithAggregatesInput>>;
  country?: InputMaybe<StringWithAggregatesFilter>;
  createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  description?: InputMaybe<StringNullableWithAggregatesFilter>;
  id?: InputMaybe<StringWithAggregatesFilter>;
  latitude?: InputMaybe<IntWithAggregatesFilter>;
  longitude?: InputMaybe<IntWithAggregatesFilter>;
  name?: InputMaybe<StringWithAggregatesFilter>;
  updatedAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  valid?: InputMaybe<BoolWithAggregatesFilter>;
};

export type ScenicSpotSumAggregate = {
  __typename?: 'ScenicSpotSumAggregate';
  latitude?: Maybe<Scalars['Int']>;
  longitude?: Maybe<Scalars['Int']>;
};

export type ScenicSpotSumOrderByAggregateInput = {
  latitude?: InputMaybe<SortOrder>;
  longitude?: InputMaybe<SortOrder>;
};

export type ScenicSpotUpdateInput = {
  cards?: InputMaybe<CardUpdateManyWithoutScenicSpotNestedInput>;
  collections?: InputMaybe<CollectionUpdateManyWithoutScenicSpotNestedInput>;
  country?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  latitude?: InputMaybe<IntFieldUpdateOperationsInput>;
  longitude?: InputMaybe<IntFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  posts?: InputMaybe<PostUpdateManyWithoutScenicSpotNestedInput>;
  stamps?: InputMaybe<StampUpdateManyWithoutScenicSpotNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  valid?: InputMaybe<BoolFieldUpdateOperationsInput>;
};

export type ScenicSpotUpdateManyMutationInput = {
  country?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  latitude?: InputMaybe<IntFieldUpdateOperationsInput>;
  longitude?: InputMaybe<IntFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  valid?: InputMaybe<BoolFieldUpdateOperationsInput>;
};

export type ScenicSpotUpdateOneWithoutCardsNestedInput = {
  connect?: InputMaybe<ScenicSpotWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ScenicSpotCreateOrConnectWithoutCardsInput>;
  create?: InputMaybe<ScenicSpotCreateWithoutCardsInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<ScenicSpotUpdateWithoutCardsInput>;
  upsert?: InputMaybe<ScenicSpotUpsertWithoutCardsInput>;
};

export type ScenicSpotUpdateOneWithoutCollectionsNestedInput = {
  connect?: InputMaybe<ScenicSpotWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ScenicSpotCreateOrConnectWithoutCollectionsInput>;
  create?: InputMaybe<ScenicSpotCreateWithoutCollectionsInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<ScenicSpotUpdateWithoutCollectionsInput>;
  upsert?: InputMaybe<ScenicSpotUpsertWithoutCollectionsInput>;
};

export type ScenicSpotUpdateOneWithoutPostsNestedInput = {
  connect?: InputMaybe<ScenicSpotWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ScenicSpotCreateOrConnectWithoutPostsInput>;
  create?: InputMaybe<ScenicSpotCreateWithoutPostsInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<ScenicSpotUpdateWithoutPostsInput>;
  upsert?: InputMaybe<ScenicSpotUpsertWithoutPostsInput>;
};

export type ScenicSpotUpdateOneWithoutStampsNestedInput = {
  connect?: InputMaybe<ScenicSpotWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ScenicSpotCreateOrConnectWithoutStampsInput>;
  create?: InputMaybe<ScenicSpotCreateWithoutStampsInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<ScenicSpotUpdateWithoutStampsInput>;
  upsert?: InputMaybe<ScenicSpotUpsertWithoutStampsInput>;
};

export type ScenicSpotUpdateWithoutCardsInput = {
  collections?: InputMaybe<CollectionUpdateManyWithoutScenicSpotNestedInput>;
  country?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  latitude?: InputMaybe<IntFieldUpdateOperationsInput>;
  longitude?: InputMaybe<IntFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  posts?: InputMaybe<PostUpdateManyWithoutScenicSpotNestedInput>;
  stamps?: InputMaybe<StampUpdateManyWithoutScenicSpotNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  valid?: InputMaybe<BoolFieldUpdateOperationsInput>;
};

export type ScenicSpotUpdateWithoutCollectionsInput = {
  cards?: InputMaybe<CardUpdateManyWithoutScenicSpotNestedInput>;
  country?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  latitude?: InputMaybe<IntFieldUpdateOperationsInput>;
  longitude?: InputMaybe<IntFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  posts?: InputMaybe<PostUpdateManyWithoutScenicSpotNestedInput>;
  stamps?: InputMaybe<StampUpdateManyWithoutScenicSpotNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  valid?: InputMaybe<BoolFieldUpdateOperationsInput>;
};

export type ScenicSpotUpdateWithoutPostsInput = {
  cards?: InputMaybe<CardUpdateManyWithoutScenicSpotNestedInput>;
  collections?: InputMaybe<CollectionUpdateManyWithoutScenicSpotNestedInput>;
  country?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  latitude?: InputMaybe<IntFieldUpdateOperationsInput>;
  longitude?: InputMaybe<IntFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  stamps?: InputMaybe<StampUpdateManyWithoutScenicSpotNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  valid?: InputMaybe<BoolFieldUpdateOperationsInput>;
};

export type ScenicSpotUpdateWithoutStampsInput = {
  cards?: InputMaybe<CardUpdateManyWithoutScenicSpotNestedInput>;
  collections?: InputMaybe<CollectionUpdateManyWithoutScenicSpotNestedInput>;
  country?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  latitude?: InputMaybe<IntFieldUpdateOperationsInput>;
  longitude?: InputMaybe<IntFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  posts?: InputMaybe<PostUpdateManyWithoutScenicSpotNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  valid?: InputMaybe<BoolFieldUpdateOperationsInput>;
};

export type ScenicSpotUpsertWithoutCardsInput = {
  create: ScenicSpotCreateWithoutCardsInput;
  update: ScenicSpotUpdateWithoutCardsInput;
};

export type ScenicSpotUpsertWithoutCollectionsInput = {
  create: ScenicSpotCreateWithoutCollectionsInput;
  update: ScenicSpotUpdateWithoutCollectionsInput;
};

export type ScenicSpotUpsertWithoutPostsInput = {
  create: ScenicSpotCreateWithoutPostsInput;
  update: ScenicSpotUpdateWithoutPostsInput;
};

export type ScenicSpotUpsertWithoutStampsInput = {
  create: ScenicSpotCreateWithoutStampsInput;
  update: ScenicSpotUpdateWithoutStampsInput;
};

export type ScenicSpotWhereInput = {
  AND?: InputMaybe<Array<ScenicSpotWhereInput>>;
  NOT?: InputMaybe<Array<ScenicSpotWhereInput>>;
  OR?: InputMaybe<Array<ScenicSpotWhereInput>>;
  cards?: InputMaybe<CardListRelationFilter>;
  collections?: InputMaybe<CollectionListRelationFilter>;
  country?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  description?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<StringFilter>;
  latitude?: InputMaybe<IntFilter>;
  longitude?: InputMaybe<IntFilter>;
  name?: InputMaybe<StringFilter>;
  posts?: InputMaybe<PostListRelationFilter>;
  stamps?: InputMaybe<StampListRelationFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  valid?: InputMaybe<BoolFilter>;
};

export type ScenicSpotWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
  latitude_longitude?: InputMaybe<ScenicSpotLatitudeLongitudeCompoundUniqueInput>;
  name?: InputMaybe<Scalars['String']>;
};

export enum SenderSettingNotification {
  OnlyFollowing = 'onlyFollowing',
  OnlyFriend = 'onlyFriend',
  Private = 'private',
  Public = 'public'
}

export enum SendingType {
  Online = 'online',
  Practical = 'practical',
  Share = 'share'
}

export type SettingNotification = {
  __typename?: 'SettingNotification';
  beFriend: Scalars['Boolean'];
  createdAt?: Maybe<Scalars['DateTime']>;
  hasFollower: Scalars['Boolean'];
  id: Scalars['String'];
  like: LikeSettingNotification;
  receiverSettingNotification: ReceiverSettingNotification;
  senderSettingNotification: SenderSettingNotification;
  suppress: SettingNotificationSuppress;
  suppressId: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  user: User;
  userId: Scalars['String'];
};

export type SettingNotificationCountAggregate = {
  __typename?: 'SettingNotificationCountAggregate';
  _all: Scalars['Int'];
  beFriend: Scalars['Int'];
  createdAt: Scalars['Int'];
  hasFollower: Scalars['Int'];
  id: Scalars['Int'];
  like: Scalars['Int'];
  receiverSettingNotification: Scalars['Int'];
  senderSettingNotification: Scalars['Int'];
  suppressId: Scalars['Int'];
  updatedAt: Scalars['Int'];
  userId: Scalars['Int'];
};

export type SettingNotificationCountOrderByAggregateInput = {
  beFriend?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  hasFollower?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  like?: InputMaybe<SortOrder>;
  receiverSettingNotification?: InputMaybe<SortOrder>;
  senderSettingNotification?: InputMaybe<SortOrder>;
  suppressId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
};

export type SettingNotificationCreateInput = {
  beFriend?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  hasFollower?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['String']>;
  like?: InputMaybe<LikeSettingNotification>;
  receiverSettingNotification?: InputMaybe<ReceiverSettingNotification>;
  senderSettingNotification?: InputMaybe<SenderSettingNotification>;
  suppress: SettingNotificationSuppressCreateNestedOneWithoutSettingNotificationInput;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  user: UserCreateNestedOneWithoutSettingNotificationInput;
};

export type SettingNotificationCreateManyInput = {
  beFriend?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  hasFollower?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['String']>;
  like?: InputMaybe<LikeSettingNotification>;
  receiverSettingNotification?: InputMaybe<ReceiverSettingNotification>;
  senderSettingNotification?: InputMaybe<SenderSettingNotification>;
  suppressId: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userId: Scalars['String'];
};

export type SettingNotificationCreateNestedOneWithoutSuppressInput = {
  connect?: InputMaybe<SettingNotificationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SettingNotificationCreateOrConnectWithoutSuppressInput>;
  create?: InputMaybe<SettingNotificationCreateWithoutSuppressInput>;
};

export type SettingNotificationCreateNestedOneWithoutUserInput = {
  connect?: InputMaybe<SettingNotificationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SettingNotificationCreateOrConnectWithoutUserInput>;
  create?: InputMaybe<SettingNotificationCreateWithoutUserInput>;
};

export type SettingNotificationCreateOrConnectWithoutSuppressInput = {
  create: SettingNotificationCreateWithoutSuppressInput;
  where: SettingNotificationWhereUniqueInput;
};

export type SettingNotificationCreateOrConnectWithoutUserInput = {
  create: SettingNotificationCreateWithoutUserInput;
  where: SettingNotificationWhereUniqueInput;
};

export type SettingNotificationCreateWithoutSuppressInput = {
  beFriend?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  hasFollower?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['String']>;
  like?: InputMaybe<LikeSettingNotification>;
  receiverSettingNotification?: InputMaybe<ReceiverSettingNotification>;
  senderSettingNotification?: InputMaybe<SenderSettingNotification>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  user: UserCreateNestedOneWithoutSettingNotificationInput;
};

export type SettingNotificationCreateWithoutUserInput = {
  beFriend?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  hasFollower?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['String']>;
  like?: InputMaybe<LikeSettingNotification>;
  receiverSettingNotification?: InputMaybe<ReceiverSettingNotification>;
  senderSettingNotification?: InputMaybe<SenderSettingNotification>;
  suppress: SettingNotificationSuppressCreateNestedOneWithoutSettingNotificationInput;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type SettingNotificationGroupBy = {
  __typename?: 'SettingNotificationGroupBy';
  _count?: Maybe<SettingNotificationCountAggregate>;
  _max?: Maybe<SettingNotificationMaxAggregate>;
  _min?: Maybe<SettingNotificationMinAggregate>;
  beFriend: Scalars['Boolean'];
  createdAt?: Maybe<Scalars['DateTime']>;
  hasFollower: Scalars['Boolean'];
  id: Scalars['String'];
  like: LikeSettingNotification;
  receiverSettingNotification: ReceiverSettingNotification;
  senderSettingNotification: SenderSettingNotification;
  suppressId: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId: Scalars['String'];
};

export type SettingNotificationMaxAggregate = {
  __typename?: 'SettingNotificationMaxAggregate';
  beFriend?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  hasFollower?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  like?: Maybe<LikeSettingNotification>;
  receiverSettingNotification?: Maybe<ReceiverSettingNotification>;
  senderSettingNotification?: Maybe<SenderSettingNotification>;
  suppressId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
};

export type SettingNotificationMaxOrderByAggregateInput = {
  beFriend?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  hasFollower?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  like?: InputMaybe<SortOrder>;
  receiverSettingNotification?: InputMaybe<SortOrder>;
  senderSettingNotification?: InputMaybe<SortOrder>;
  suppressId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
};

export type SettingNotificationMinAggregate = {
  __typename?: 'SettingNotificationMinAggregate';
  beFriend?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  hasFollower?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  like?: Maybe<LikeSettingNotification>;
  receiverSettingNotification?: Maybe<ReceiverSettingNotification>;
  senderSettingNotification?: Maybe<SenderSettingNotification>;
  suppressId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
};

export type SettingNotificationMinOrderByAggregateInput = {
  beFriend?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  hasFollower?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  like?: InputMaybe<SortOrder>;
  receiverSettingNotification?: InputMaybe<SortOrder>;
  senderSettingNotification?: InputMaybe<SortOrder>;
  suppressId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
};

export type SettingNotificationOrderByWithAggregationInput = {
  _count?: InputMaybe<SettingNotificationCountOrderByAggregateInput>;
  _max?: InputMaybe<SettingNotificationMaxOrderByAggregateInput>;
  _min?: InputMaybe<SettingNotificationMinOrderByAggregateInput>;
  beFriend?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  hasFollower?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  like?: InputMaybe<SortOrder>;
  receiverSettingNotification?: InputMaybe<SortOrder>;
  senderSettingNotification?: InputMaybe<SortOrder>;
  suppressId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
};

export type SettingNotificationOrderByWithRelationInput = {
  beFriend?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  hasFollower?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  like?: InputMaybe<SortOrder>;
  receiverSettingNotification?: InputMaybe<SortOrder>;
  senderSettingNotification?: InputMaybe<SortOrder>;
  suppress?: InputMaybe<SettingNotificationSuppressOrderByWithRelationInput>;
  suppressId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  user?: InputMaybe<UserOrderByWithRelationInput>;
  userId?: InputMaybe<SortOrder>;
};

export type SettingNotificationRelationFilter = {
  is?: InputMaybe<SettingNotificationWhereInput>;
  isNot?: InputMaybe<SettingNotificationWhereInput>;
};

export enum SettingNotificationScalarFieldEnum {
  BeFriend = 'beFriend',
  CreatedAt = 'createdAt',
  HasFollower = 'hasFollower',
  Id = 'id',
  Like = 'like',
  ReceiverSettingNotification = 'receiverSettingNotification',
  SenderSettingNotification = 'senderSettingNotification',
  SuppressId = 'suppressId',
  UpdatedAt = 'updatedAt',
  UserId = 'userId'
}

export type SettingNotificationScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<SettingNotificationScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<SettingNotificationScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<SettingNotificationScalarWhereWithAggregatesInput>>;
  beFriend?: InputMaybe<BoolWithAggregatesFilter>;
  createdAt?: InputMaybe<DateTimeNullableWithAggregatesFilter>;
  hasFollower?: InputMaybe<BoolWithAggregatesFilter>;
  id?: InputMaybe<StringWithAggregatesFilter>;
  like?: InputMaybe<EnumLikeSettingNotificationWithAggregatesFilter>;
  receiverSettingNotification?: InputMaybe<EnumReceiverSettingNotificationWithAggregatesFilter>;
  senderSettingNotification?: InputMaybe<EnumSenderSettingNotificationWithAggregatesFilter>;
  suppressId?: InputMaybe<StringWithAggregatesFilter>;
  updatedAt?: InputMaybe<DateTimeNullableWithAggregatesFilter>;
  userId?: InputMaybe<StringWithAggregatesFilter>;
};

export type SettingNotificationSuppress = {
  __typename?: 'SettingNotificationSuppress';
  id: Scalars['String'];
  sendDate?: Maybe<Scalars['DateTime']>;
  settingNotification?: Maybe<SettingNotification>;
  toggle: Scalars['Boolean'];
};

export type SettingNotificationSuppressCountAggregate = {
  __typename?: 'SettingNotificationSuppressCountAggregate';
  _all: Scalars['Int'];
  id: Scalars['Int'];
  sendDate: Scalars['Int'];
  toggle: Scalars['Int'];
};

export type SettingNotificationSuppressCountOrderByAggregateInput = {
  id?: InputMaybe<SortOrder>;
  sendDate?: InputMaybe<SortOrder>;
  toggle?: InputMaybe<SortOrder>;
};

export type SettingNotificationSuppressCreateInput = {
  id?: InputMaybe<Scalars['String']>;
  sendDate?: InputMaybe<Scalars['DateTime']>;
  settingNotification?: InputMaybe<SettingNotificationCreateNestedOneWithoutSuppressInput>;
  toggle?: InputMaybe<Scalars['Boolean']>;
};

export type SettingNotificationSuppressCreateManyInput = {
  id?: InputMaybe<Scalars['String']>;
  sendDate?: InputMaybe<Scalars['DateTime']>;
  toggle?: InputMaybe<Scalars['Boolean']>;
};

export type SettingNotificationSuppressCreateNestedOneWithoutSettingNotificationInput = {
  connect?: InputMaybe<SettingNotificationSuppressWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SettingNotificationSuppressCreateOrConnectWithoutSettingNotificationInput>;
  create?: InputMaybe<SettingNotificationSuppressCreateWithoutSettingNotificationInput>;
};

export type SettingNotificationSuppressCreateOrConnectWithoutSettingNotificationInput = {
  create: SettingNotificationSuppressCreateWithoutSettingNotificationInput;
  where: SettingNotificationSuppressWhereUniqueInput;
};

export type SettingNotificationSuppressCreateWithoutSettingNotificationInput = {
  id?: InputMaybe<Scalars['String']>;
  sendDate?: InputMaybe<Scalars['DateTime']>;
  toggle?: InputMaybe<Scalars['Boolean']>;
};

export type SettingNotificationSuppressGroupBy = {
  __typename?: 'SettingNotificationSuppressGroupBy';
  _count?: Maybe<SettingNotificationSuppressCountAggregate>;
  _max?: Maybe<SettingNotificationSuppressMaxAggregate>;
  _min?: Maybe<SettingNotificationSuppressMinAggregate>;
  id: Scalars['String'];
  sendDate?: Maybe<Scalars['DateTime']>;
  toggle: Scalars['Boolean'];
};

export type SettingNotificationSuppressMaxAggregate = {
  __typename?: 'SettingNotificationSuppressMaxAggregate';
  id?: Maybe<Scalars['String']>;
  sendDate?: Maybe<Scalars['DateTime']>;
  toggle?: Maybe<Scalars['Boolean']>;
};

export type SettingNotificationSuppressMaxOrderByAggregateInput = {
  id?: InputMaybe<SortOrder>;
  sendDate?: InputMaybe<SortOrder>;
  toggle?: InputMaybe<SortOrder>;
};

export type SettingNotificationSuppressMinAggregate = {
  __typename?: 'SettingNotificationSuppressMinAggregate';
  id?: Maybe<Scalars['String']>;
  sendDate?: Maybe<Scalars['DateTime']>;
  toggle?: Maybe<Scalars['Boolean']>;
};

export type SettingNotificationSuppressMinOrderByAggregateInput = {
  id?: InputMaybe<SortOrder>;
  sendDate?: InputMaybe<SortOrder>;
  toggle?: InputMaybe<SortOrder>;
};

export type SettingNotificationSuppressOrderByWithAggregationInput = {
  _count?: InputMaybe<SettingNotificationSuppressCountOrderByAggregateInput>;
  _max?: InputMaybe<SettingNotificationSuppressMaxOrderByAggregateInput>;
  _min?: InputMaybe<SettingNotificationSuppressMinOrderByAggregateInput>;
  id?: InputMaybe<SortOrder>;
  sendDate?: InputMaybe<SortOrder>;
  toggle?: InputMaybe<SortOrder>;
};

export type SettingNotificationSuppressOrderByWithRelationInput = {
  id?: InputMaybe<SortOrder>;
  sendDate?: InputMaybe<SortOrder>;
  settingNotification?: InputMaybe<SettingNotificationOrderByWithRelationInput>;
  toggle?: InputMaybe<SortOrder>;
};

export type SettingNotificationSuppressRelationFilter = {
  is?: InputMaybe<SettingNotificationSuppressWhereInput>;
  isNot?: InputMaybe<SettingNotificationSuppressWhereInput>;
};

export enum SettingNotificationSuppressScalarFieldEnum {
  Id = 'id',
  SendDate = 'sendDate',
  Toggle = 'toggle'
}

export type SettingNotificationSuppressScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<SettingNotificationSuppressScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<SettingNotificationSuppressScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<SettingNotificationSuppressScalarWhereWithAggregatesInput>>;
  id?: InputMaybe<StringWithAggregatesFilter>;
  sendDate?: InputMaybe<DateTimeNullableWithAggregatesFilter>;
  toggle?: InputMaybe<BoolWithAggregatesFilter>;
};

export type SettingNotificationSuppressUpdateInput = {
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  sendDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  settingNotification?: InputMaybe<SettingNotificationUpdateOneWithoutSuppressNestedInput>;
  toggle?: InputMaybe<BoolFieldUpdateOperationsInput>;
};

export type SettingNotificationSuppressUpdateManyMutationInput = {
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  sendDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  toggle?: InputMaybe<BoolFieldUpdateOperationsInput>;
};

export type SettingNotificationSuppressUpdateOneRequiredWithoutSettingNotificationNestedInput = {
  connect?: InputMaybe<SettingNotificationSuppressWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SettingNotificationSuppressCreateOrConnectWithoutSettingNotificationInput>;
  create?: InputMaybe<SettingNotificationSuppressCreateWithoutSettingNotificationInput>;
  update?: InputMaybe<SettingNotificationSuppressUpdateWithoutSettingNotificationInput>;
  upsert?: InputMaybe<SettingNotificationSuppressUpsertWithoutSettingNotificationInput>;
};

export type SettingNotificationSuppressUpdateWithoutSettingNotificationInput = {
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  sendDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  toggle?: InputMaybe<BoolFieldUpdateOperationsInput>;
};

export type SettingNotificationSuppressUpsertWithoutSettingNotificationInput = {
  create: SettingNotificationSuppressCreateWithoutSettingNotificationInput;
  update: SettingNotificationSuppressUpdateWithoutSettingNotificationInput;
};

export type SettingNotificationSuppressWhereInput = {
  AND?: InputMaybe<Array<SettingNotificationSuppressWhereInput>>;
  NOT?: InputMaybe<Array<SettingNotificationSuppressWhereInput>>;
  OR?: InputMaybe<Array<SettingNotificationSuppressWhereInput>>;
  id?: InputMaybe<StringFilter>;
  sendDate?: InputMaybe<DateTimeNullableFilter>;
  settingNotification?: InputMaybe<SettingNotificationRelationFilter>;
  toggle?: InputMaybe<BoolFilter>;
};

export type SettingNotificationSuppressWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
};

export type SettingNotificationUpdateInput = {
  beFriend?: InputMaybe<BoolFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  hasFollower?: InputMaybe<BoolFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  like?: InputMaybe<EnumLikeSettingNotificationFieldUpdateOperationsInput>;
  receiverSettingNotification?: InputMaybe<EnumReceiverSettingNotificationFieldUpdateOperationsInput>;
  senderSettingNotification?: InputMaybe<EnumSenderSettingNotificationFieldUpdateOperationsInput>;
  suppress?: InputMaybe<SettingNotificationSuppressUpdateOneRequiredWithoutSettingNotificationNestedInput>;
  updatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutSettingNotificationNestedInput>;
};

export type SettingNotificationUpdateManyMutationInput = {
  beFriend?: InputMaybe<BoolFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  hasFollower?: InputMaybe<BoolFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  like?: InputMaybe<EnumLikeSettingNotificationFieldUpdateOperationsInput>;
  receiverSettingNotification?: InputMaybe<EnumReceiverSettingNotificationFieldUpdateOperationsInput>;
  senderSettingNotification?: InputMaybe<EnumSenderSettingNotificationFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
};

export type SettingNotificationUpdateOneWithoutSuppressNestedInput = {
  connect?: InputMaybe<SettingNotificationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SettingNotificationCreateOrConnectWithoutSuppressInput>;
  create?: InputMaybe<SettingNotificationCreateWithoutSuppressInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<SettingNotificationUpdateWithoutSuppressInput>;
  upsert?: InputMaybe<SettingNotificationUpsertWithoutSuppressInput>;
};

export type SettingNotificationUpdateOneWithoutUserNestedInput = {
  connect?: InputMaybe<SettingNotificationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SettingNotificationCreateOrConnectWithoutUserInput>;
  create?: InputMaybe<SettingNotificationCreateWithoutUserInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<SettingNotificationUpdateWithoutUserInput>;
  upsert?: InputMaybe<SettingNotificationUpsertWithoutUserInput>;
};

export type SettingNotificationUpdateWithoutSuppressInput = {
  beFriend?: InputMaybe<BoolFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  hasFollower?: InputMaybe<BoolFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  like?: InputMaybe<EnumLikeSettingNotificationFieldUpdateOperationsInput>;
  receiverSettingNotification?: InputMaybe<EnumReceiverSettingNotificationFieldUpdateOperationsInput>;
  senderSettingNotification?: InputMaybe<EnumSenderSettingNotificationFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutSettingNotificationNestedInput>;
};

export type SettingNotificationUpdateWithoutUserInput = {
  beFriend?: InputMaybe<BoolFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  hasFollower?: InputMaybe<BoolFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  like?: InputMaybe<EnumLikeSettingNotificationFieldUpdateOperationsInput>;
  receiverSettingNotification?: InputMaybe<EnumReceiverSettingNotificationFieldUpdateOperationsInput>;
  senderSettingNotification?: InputMaybe<EnumSenderSettingNotificationFieldUpdateOperationsInput>;
  suppress?: InputMaybe<SettingNotificationSuppressUpdateOneRequiredWithoutSettingNotificationNestedInput>;
  updatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
};

export type SettingNotificationUpsertWithoutSuppressInput = {
  create: SettingNotificationCreateWithoutSuppressInput;
  update: SettingNotificationUpdateWithoutSuppressInput;
};

export type SettingNotificationUpsertWithoutUserInput = {
  create: SettingNotificationCreateWithoutUserInput;
  update: SettingNotificationUpdateWithoutUserInput;
};

export type SettingNotificationWhereInput = {
  AND?: InputMaybe<Array<SettingNotificationWhereInput>>;
  NOT?: InputMaybe<Array<SettingNotificationWhereInput>>;
  OR?: InputMaybe<Array<SettingNotificationWhereInput>>;
  beFriend?: InputMaybe<BoolFilter>;
  createdAt?: InputMaybe<DateTimeNullableFilter>;
  hasFollower?: InputMaybe<BoolFilter>;
  id?: InputMaybe<StringFilter>;
  like?: InputMaybe<EnumLikeSettingNotificationFilter>;
  receiverSettingNotification?: InputMaybe<EnumReceiverSettingNotificationFilter>;
  senderSettingNotification?: InputMaybe<EnumSenderSettingNotificationFilter>;
  suppress?: InputMaybe<SettingNotificationSuppressRelationFilter>;
  suppressId?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeNullableFilter>;
  user?: InputMaybe<UserRelationFilter>;
  userId?: InputMaybe<StringFilter>;
};

export type SettingNotificationWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
  suppressId?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['String']>;
};

export type SettingPrivacy = {
  __typename?: 'SettingPrivacy';
  createdAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['String'];
  public: Scalars['Boolean'];
  receiverSettingPrivacy: ReceiverSettingPrivacy;
  updatedAt?: Maybe<Scalars['DateTime']>;
  user: User;
  userId: Scalars['String'];
};

export type SettingPrivacyCountAggregate = {
  __typename?: 'SettingPrivacyCountAggregate';
  _all: Scalars['Int'];
  createdAt: Scalars['Int'];
  id: Scalars['Int'];
  public: Scalars['Int'];
  receiverSettingPrivacy: Scalars['Int'];
  updatedAt: Scalars['Int'];
  userId: Scalars['Int'];
};

export type SettingPrivacyCountOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  public?: InputMaybe<SortOrder>;
  receiverSettingPrivacy?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
};

export type SettingPrivacyCreateInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  public?: InputMaybe<Scalars['Boolean']>;
  receiverSettingPrivacy?: InputMaybe<ReceiverSettingPrivacy>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  user: UserCreateNestedOneWithoutSettingPrivacyInput;
};

export type SettingPrivacyCreateManyInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  public?: InputMaybe<Scalars['Boolean']>;
  receiverSettingPrivacy?: InputMaybe<ReceiverSettingPrivacy>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userId: Scalars['String'];
};

export type SettingPrivacyCreateNestedOneWithoutUserInput = {
  connect?: InputMaybe<SettingPrivacyWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SettingPrivacyCreateOrConnectWithoutUserInput>;
  create?: InputMaybe<SettingPrivacyCreateWithoutUserInput>;
};

export type SettingPrivacyCreateOrConnectWithoutUserInput = {
  create: SettingPrivacyCreateWithoutUserInput;
  where: SettingPrivacyWhereUniqueInput;
};

export type SettingPrivacyCreateWithoutUserInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  public?: InputMaybe<Scalars['Boolean']>;
  receiverSettingPrivacy?: InputMaybe<ReceiverSettingPrivacy>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type SettingPrivacyGroupBy = {
  __typename?: 'SettingPrivacyGroupBy';
  _count?: Maybe<SettingPrivacyCountAggregate>;
  _max?: Maybe<SettingPrivacyMaxAggregate>;
  _min?: Maybe<SettingPrivacyMinAggregate>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['String'];
  public: Scalars['Boolean'];
  receiverSettingPrivacy: ReceiverSettingPrivacy;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId: Scalars['String'];
};

export type SettingPrivacyMaxAggregate = {
  __typename?: 'SettingPrivacyMaxAggregate';
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  public?: Maybe<Scalars['Boolean']>;
  receiverSettingPrivacy?: Maybe<ReceiverSettingPrivacy>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
};

export type SettingPrivacyMaxOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  public?: InputMaybe<SortOrder>;
  receiverSettingPrivacy?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
};

export type SettingPrivacyMinAggregate = {
  __typename?: 'SettingPrivacyMinAggregate';
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  public?: Maybe<Scalars['Boolean']>;
  receiverSettingPrivacy?: Maybe<ReceiverSettingPrivacy>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
};

export type SettingPrivacyMinOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  public?: InputMaybe<SortOrder>;
  receiverSettingPrivacy?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
};

export type SettingPrivacyOrderByWithAggregationInput = {
  _count?: InputMaybe<SettingPrivacyCountOrderByAggregateInput>;
  _max?: InputMaybe<SettingPrivacyMaxOrderByAggregateInput>;
  _min?: InputMaybe<SettingPrivacyMinOrderByAggregateInput>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  public?: InputMaybe<SortOrder>;
  receiverSettingPrivacy?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
};

export type SettingPrivacyOrderByWithRelationInput = {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  public?: InputMaybe<SortOrder>;
  receiverSettingPrivacy?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  user?: InputMaybe<UserOrderByWithRelationInput>;
  userId?: InputMaybe<SortOrder>;
};

export type SettingPrivacyRelationFilter = {
  is?: InputMaybe<SettingPrivacyWhereInput>;
  isNot?: InputMaybe<SettingPrivacyWhereInput>;
};

export enum SettingPrivacyScalarFieldEnum {
  CreatedAt = 'createdAt',
  Id = 'id',
  Public = 'public',
  ReceiverSettingPrivacy = 'receiverSettingPrivacy',
  UpdatedAt = 'updatedAt',
  UserId = 'userId'
}

export type SettingPrivacyScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<SettingPrivacyScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<SettingPrivacyScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<SettingPrivacyScalarWhereWithAggregatesInput>>;
  createdAt?: InputMaybe<DateTimeNullableWithAggregatesFilter>;
  id?: InputMaybe<StringWithAggregatesFilter>;
  public?: InputMaybe<BoolWithAggregatesFilter>;
  receiverSettingPrivacy?: InputMaybe<EnumReceiverSettingPrivacyWithAggregatesFilter>;
  updatedAt?: InputMaybe<DateTimeNullableWithAggregatesFilter>;
  userId?: InputMaybe<StringWithAggregatesFilter>;
};

export type SettingPrivacyUpdateInput = {
  createdAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  public?: InputMaybe<BoolFieldUpdateOperationsInput>;
  receiverSettingPrivacy?: InputMaybe<EnumReceiverSettingPrivacyFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutSettingPrivacyNestedInput>;
};

export type SettingPrivacyUpdateManyMutationInput = {
  createdAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  public?: InputMaybe<BoolFieldUpdateOperationsInput>;
  receiverSettingPrivacy?: InputMaybe<EnumReceiverSettingPrivacyFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
};

export type SettingPrivacyUpdateOneWithoutUserNestedInput = {
  connect?: InputMaybe<SettingPrivacyWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SettingPrivacyCreateOrConnectWithoutUserInput>;
  create?: InputMaybe<SettingPrivacyCreateWithoutUserInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<SettingPrivacyUpdateWithoutUserInput>;
  upsert?: InputMaybe<SettingPrivacyUpsertWithoutUserInput>;
};

export type SettingPrivacyUpdateWithoutUserInput = {
  createdAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  public?: InputMaybe<BoolFieldUpdateOperationsInput>;
  receiverSettingPrivacy?: InputMaybe<EnumReceiverSettingPrivacyFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
};

export type SettingPrivacyUpsertWithoutUserInput = {
  create: SettingPrivacyCreateWithoutUserInput;
  update: SettingPrivacyUpdateWithoutUserInput;
};

export type SettingPrivacyWhereInput = {
  AND?: InputMaybe<Array<SettingPrivacyWhereInput>>;
  NOT?: InputMaybe<Array<SettingPrivacyWhereInput>>;
  OR?: InputMaybe<Array<SettingPrivacyWhereInput>>;
  createdAt?: InputMaybe<DateTimeNullableFilter>;
  id?: InputMaybe<StringFilter>;
  public?: InputMaybe<BoolFilter>;
  receiverSettingPrivacy?: InputMaybe<EnumReceiverSettingPrivacyFilter>;
  updatedAt?: InputMaybe<DateTimeNullableFilter>;
  user?: InputMaybe<UserRelationFilter>;
  userId?: InputMaybe<StringFilter>;
};

export type SettingPrivacyWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['String']>;
};

export type Signature = {
  __typename?: 'Signature';
  _count?: Maybe<SignatureCount>;
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  owner: User;
  ownerId: Scalars['String'];
  picUri: Scalars['String'];
  postsUsed: Array<Post>;
  updatedAt: Scalars['DateTime'];
};


export type SignaturePostsUsedArgs = {
  cursor?: InputMaybe<PostWhereUniqueInput>;
  distinct?: InputMaybe<Array<PostScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<PostOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PostWhereInput>;
};

export type SignatureCount = {
  __typename?: 'SignatureCount';
  postsUsed: Scalars['Int'];
};


export type SignatureCountPostsUsedArgs = {
  where?: InputMaybe<PostWhereInput>;
};

export type SignatureCountAggregate = {
  __typename?: 'SignatureCountAggregate';
  _all: Scalars['Int'];
  createdAt: Scalars['Int'];
  id: Scalars['Int'];
  ownerId: Scalars['Int'];
  picUri: Scalars['Int'];
  updatedAt: Scalars['Int'];
};

export type SignatureCountOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  ownerId?: InputMaybe<SortOrder>;
  picUri?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type SignatureCreateInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  owner: UserCreateNestedOneWithoutSignatureInput;
  picUri: Scalars['String'];
  postsUsed?: InputMaybe<PostCreateNestedManyWithoutSignatureInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type SignatureCreateManyInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  ownerId: Scalars['String'];
  picUri: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type SignatureCreateManyOwnerInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  picUri: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type SignatureCreateManyOwnerInputEnvelope = {
  data: Array<SignatureCreateManyOwnerInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type SignatureCreateNestedManyWithoutOwnerInput = {
  connect?: InputMaybe<Array<SignatureWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<SignatureCreateOrConnectWithoutOwnerInput>>;
  create?: InputMaybe<Array<SignatureCreateWithoutOwnerInput>>;
  createMany?: InputMaybe<SignatureCreateManyOwnerInputEnvelope>;
};

export type SignatureCreateNestedOneWithoutPostsUsedInput = {
  connect?: InputMaybe<SignatureWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SignatureCreateOrConnectWithoutPostsUsedInput>;
  create?: InputMaybe<SignatureCreateWithoutPostsUsedInput>;
};

export type SignatureCreateOrConnectWithoutOwnerInput = {
  create: SignatureCreateWithoutOwnerInput;
  where: SignatureWhereUniqueInput;
};

export type SignatureCreateOrConnectWithoutPostsUsedInput = {
  create: SignatureCreateWithoutPostsUsedInput;
  where: SignatureWhereUniqueInput;
};

export type SignatureCreateWithoutOwnerInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  picUri: Scalars['String'];
  postsUsed?: InputMaybe<PostCreateNestedManyWithoutSignatureInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type SignatureCreateWithoutPostsUsedInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  owner: UserCreateNestedOneWithoutSignatureInput;
  picUri: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type SignatureGroupBy = {
  __typename?: 'SignatureGroupBy';
  _count?: Maybe<SignatureCountAggregate>;
  _max?: Maybe<SignatureMaxAggregate>;
  _min?: Maybe<SignatureMinAggregate>;
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  ownerId: Scalars['String'];
  picUri: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type SignatureListRelationFilter = {
  every?: InputMaybe<SignatureWhereInput>;
  none?: InputMaybe<SignatureWhereInput>;
  some?: InputMaybe<SignatureWhereInput>;
};

export type SignatureMaxAggregate = {
  __typename?: 'SignatureMaxAggregate';
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  ownerId?: Maybe<Scalars['String']>;
  picUri?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type SignatureMaxOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  ownerId?: InputMaybe<SortOrder>;
  picUri?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type SignatureMinAggregate = {
  __typename?: 'SignatureMinAggregate';
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  ownerId?: Maybe<Scalars['String']>;
  picUri?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type SignatureMinOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  ownerId?: InputMaybe<SortOrder>;
  picUri?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type SignatureOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type SignatureOrderByWithAggregationInput = {
  _count?: InputMaybe<SignatureCountOrderByAggregateInput>;
  _max?: InputMaybe<SignatureMaxOrderByAggregateInput>;
  _min?: InputMaybe<SignatureMinOrderByAggregateInput>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  ownerId?: InputMaybe<SortOrder>;
  picUri?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type SignatureOrderByWithRelationInput = {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  owner?: InputMaybe<UserOrderByWithRelationInput>;
  ownerId?: InputMaybe<SortOrder>;
  picUri?: InputMaybe<SortOrder>;
  postsUsed?: InputMaybe<PostOrderByRelationAggregateInput>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type SignatureRelationFilter = {
  is?: InputMaybe<SignatureWhereInput>;
  isNot?: InputMaybe<SignatureWhereInput>;
};

export enum SignatureScalarFieldEnum {
  CreatedAt = 'createdAt',
  Id = 'id',
  OwnerId = 'ownerId',
  PicUri = 'picUri',
  UpdatedAt = 'updatedAt'
}

export type SignatureScalarWhereInput = {
  AND?: InputMaybe<Array<SignatureScalarWhereInput>>;
  NOT?: InputMaybe<Array<SignatureScalarWhereInput>>;
  OR?: InputMaybe<Array<SignatureScalarWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  ownerId?: InputMaybe<StringFilter>;
  picUri?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type SignatureScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<SignatureScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<SignatureScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<SignatureScalarWhereWithAggregatesInput>>;
  createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  id?: InputMaybe<StringWithAggregatesFilter>;
  ownerId?: InputMaybe<StringWithAggregatesFilter>;
  picUri?: InputMaybe<StringWithAggregatesFilter>;
  updatedAt?: InputMaybe<DateTimeWithAggregatesFilter>;
};

export type SignatureUpdateInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  owner?: InputMaybe<UserUpdateOneRequiredWithoutSignatureNestedInput>;
  picUri?: InputMaybe<StringFieldUpdateOperationsInput>;
  postsUsed?: InputMaybe<PostUpdateManyWithoutSignatureNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type SignatureUpdateManyMutationInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  picUri?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type SignatureUpdateManyWithWhereWithoutOwnerInput = {
  data: SignatureUpdateManyMutationInput;
  where: SignatureScalarWhereInput;
};

export type SignatureUpdateManyWithoutOwnerNestedInput = {
  connect?: InputMaybe<Array<SignatureWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<SignatureCreateOrConnectWithoutOwnerInput>>;
  create?: InputMaybe<Array<SignatureCreateWithoutOwnerInput>>;
  createMany?: InputMaybe<SignatureCreateManyOwnerInputEnvelope>;
  delete?: InputMaybe<Array<SignatureWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<SignatureScalarWhereInput>>;
  disconnect?: InputMaybe<Array<SignatureWhereUniqueInput>>;
  set?: InputMaybe<Array<SignatureWhereUniqueInput>>;
  update?: InputMaybe<Array<SignatureUpdateWithWhereUniqueWithoutOwnerInput>>;
  updateMany?: InputMaybe<Array<SignatureUpdateManyWithWhereWithoutOwnerInput>>;
  upsert?: InputMaybe<Array<SignatureUpsertWithWhereUniqueWithoutOwnerInput>>;
};

export type SignatureUpdateOneWithoutPostsUsedNestedInput = {
  connect?: InputMaybe<SignatureWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SignatureCreateOrConnectWithoutPostsUsedInput>;
  create?: InputMaybe<SignatureCreateWithoutPostsUsedInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<SignatureUpdateWithoutPostsUsedInput>;
  upsert?: InputMaybe<SignatureUpsertWithoutPostsUsedInput>;
};

export type SignatureUpdateWithWhereUniqueWithoutOwnerInput = {
  data: SignatureUpdateWithoutOwnerInput;
  where: SignatureWhereUniqueInput;
};

export type SignatureUpdateWithoutOwnerInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  picUri?: InputMaybe<StringFieldUpdateOperationsInput>;
  postsUsed?: InputMaybe<PostUpdateManyWithoutSignatureNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type SignatureUpdateWithoutPostsUsedInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  owner?: InputMaybe<UserUpdateOneRequiredWithoutSignatureNestedInput>;
  picUri?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type SignatureUpsertWithWhereUniqueWithoutOwnerInput = {
  create: SignatureCreateWithoutOwnerInput;
  update: SignatureUpdateWithoutOwnerInput;
  where: SignatureWhereUniqueInput;
};

export type SignatureUpsertWithoutPostsUsedInput = {
  create: SignatureCreateWithoutPostsUsedInput;
  update: SignatureUpdateWithoutPostsUsedInput;
};

export type SignatureWhereInput = {
  AND?: InputMaybe<Array<SignatureWhereInput>>;
  NOT?: InputMaybe<Array<SignatureWhereInput>>;
  OR?: InputMaybe<Array<SignatureWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  owner?: InputMaybe<UserRelationFilter>;
  ownerId?: InputMaybe<StringFilter>;
  picUri?: InputMaybe<StringFilter>;
  postsUsed?: InputMaybe<PostListRelationFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type SignatureWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
};

export enum SortOrder {
  Asc = 'asc',
  Desc = 'desc'
}

export type Stamp = {
  __typename?: 'Stamp';
  _count?: Maybe<StampCount>;
  collections: Array<Collection>;
  couponActivity?: Maybe<CouponActivity>;
  createdAt: Scalars['DateTime'];
  creator: User;
  creatorId: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  free: Scalars['Boolean'];
  id: Scalars['String'];
  inStoreItem: Array<StoreItem>;
  inValidReasonHistories: Array<InValidReason>;
  name: Scalars['String'];
  picUri: Scalars['String'];
  postsUsed: Array<Post>;
  price: Scalars['Float'];
  publishedDate: Scalars['DateTime'];
  qrcode?: Maybe<Scalars['String']>;
  scenicSpot?: Maybe<ScenicSpot>;
  scenicSpotId?: Maybe<Scalars['String']>;
  sn: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  valid: Scalars['Boolean'];
};


export type StampCollectionsArgs = {
  cursor?: InputMaybe<CollectionWhereUniqueInput>;
  distinct?: InputMaybe<Array<CollectionScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<CollectionOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CollectionWhereInput>;
};


export type StampInStoreItemArgs = {
  cursor?: InputMaybe<StoreItemWhereUniqueInput>;
  distinct?: InputMaybe<Array<StoreItemScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<StoreItemOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<StoreItemWhereInput>;
};


export type StampInValidReasonHistoriesArgs = {
  cursor?: InputMaybe<InValidReasonWhereUniqueInput>;
  distinct?: InputMaybe<Array<InValidReasonScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<InValidReasonOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<InValidReasonWhereInput>;
};


export type StampPostsUsedArgs = {
  cursor?: InputMaybe<PostWhereUniqueInput>;
  distinct?: InputMaybe<Array<PostScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<PostOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PostWhereInput>;
};

export type StampAvgAggregate = {
  __typename?: 'StampAvgAggregate';
  price?: Maybe<Scalars['Float']>;
};

export type StampAvgOrderByAggregateInput = {
  price?: InputMaybe<SortOrder>;
};

export type StampCount = {
  __typename?: 'StampCount';
  collections: Scalars['Int'];
  inStoreItem: Scalars['Int'];
  inValidReasonHistories: Scalars['Int'];
  postsUsed: Scalars['Int'];
};


export type StampCountCollectionsArgs = {
  where?: InputMaybe<CollectionWhereInput>;
};


export type StampCountInStoreItemArgs = {
  where?: InputMaybe<StoreItemWhereInput>;
};


export type StampCountInValidReasonHistoriesArgs = {
  where?: InputMaybe<InValidReasonWhereInput>;
};


export type StampCountPostsUsedArgs = {
  where?: InputMaybe<PostWhereInput>;
};

export type StampCountAggregate = {
  __typename?: 'StampCountAggregate';
  _all: Scalars['Int'];
  createdAt: Scalars['Int'];
  creatorId: Scalars['Int'];
  description: Scalars['Int'];
  free: Scalars['Int'];
  id: Scalars['Int'];
  name: Scalars['Int'];
  picUri: Scalars['Int'];
  price: Scalars['Int'];
  publishedDate: Scalars['Int'];
  qrcode: Scalars['Int'];
  scenicSpotId: Scalars['Int'];
  sn: Scalars['Int'];
  updatedAt: Scalars['Int'];
  valid: Scalars['Int'];
};

export type StampCountOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  creatorId?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  free?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  picUri?: InputMaybe<SortOrder>;
  price?: InputMaybe<SortOrder>;
  publishedDate?: InputMaybe<SortOrder>;
  qrcode?: InputMaybe<SortOrder>;
  scenicSpotId?: InputMaybe<SortOrder>;
  sn?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  valid?: InputMaybe<SortOrder>;
};

export type StampCreateInput = {
  collections?: InputMaybe<CollectionCreateNestedManyWithoutStampInput>;
  couponActivity?: InputMaybe<CouponActivityCreateNestedOneWithoutStampInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  creator: UserCreateNestedOneWithoutCreatedStampsInput;
  description?: InputMaybe<Scalars['String']>;
  free?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['String']>;
  inStoreItem?: InputMaybe<StoreItemCreateNestedManyWithoutItemStampInput>;
  inValidReasonHistories?: InputMaybe<InValidReasonCreateNestedManyWithoutStampInput>;
  name?: InputMaybe<Scalars['String']>;
  picUri: Scalars['String'];
  postsUsed?: InputMaybe<PostCreateNestedManyWithoutStampInput>;
  price?: InputMaybe<Scalars['Float']>;
  publishedDate?: InputMaybe<Scalars['DateTime']>;
  qrcode?: InputMaybe<Scalars['String']>;
  scenicSpot?: InputMaybe<ScenicSpotCreateNestedOneWithoutStampsInput>;
  sn?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  valid?: InputMaybe<Scalars['Boolean']>;
};

export type StampCreateManyCreatorInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  free?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  picUri: Scalars['String'];
  price?: InputMaybe<Scalars['Float']>;
  publishedDate?: InputMaybe<Scalars['DateTime']>;
  qrcode?: InputMaybe<Scalars['String']>;
  scenicSpotId?: InputMaybe<Scalars['String']>;
  sn?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  valid?: InputMaybe<Scalars['Boolean']>;
};

export type StampCreateManyCreatorInputEnvelope = {
  data: Array<StampCreateManyCreatorInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type StampCreateManyInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  creatorId: Scalars['String'];
  description?: InputMaybe<Scalars['String']>;
  free?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  picUri: Scalars['String'];
  price?: InputMaybe<Scalars['Float']>;
  publishedDate?: InputMaybe<Scalars['DateTime']>;
  qrcode?: InputMaybe<Scalars['String']>;
  scenicSpotId?: InputMaybe<Scalars['String']>;
  sn?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  valid?: InputMaybe<Scalars['Boolean']>;
};

export type StampCreateManyScenicSpotInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  creatorId: Scalars['String'];
  description?: InputMaybe<Scalars['String']>;
  free?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  picUri: Scalars['String'];
  price?: InputMaybe<Scalars['Float']>;
  publishedDate?: InputMaybe<Scalars['DateTime']>;
  qrcode?: InputMaybe<Scalars['String']>;
  sn?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  valid?: InputMaybe<Scalars['Boolean']>;
};

export type StampCreateManyScenicSpotInputEnvelope = {
  data: Array<StampCreateManyScenicSpotInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type StampCreateNestedManyWithoutCreatorInput = {
  connect?: InputMaybe<Array<StampWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<StampCreateOrConnectWithoutCreatorInput>>;
  create?: InputMaybe<Array<StampCreateWithoutCreatorInput>>;
  createMany?: InputMaybe<StampCreateManyCreatorInputEnvelope>;
};

export type StampCreateNestedManyWithoutScenicSpotInput = {
  connect?: InputMaybe<Array<StampWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<StampCreateOrConnectWithoutScenicSpotInput>>;
  create?: InputMaybe<Array<StampCreateWithoutScenicSpotInput>>;
  createMany?: InputMaybe<StampCreateManyScenicSpotInputEnvelope>;
};

export type StampCreateNestedOneWithoutCollectionsInput = {
  connect?: InputMaybe<StampWhereUniqueInput>;
  connectOrCreate?: InputMaybe<StampCreateOrConnectWithoutCollectionsInput>;
  create?: InputMaybe<StampCreateWithoutCollectionsInput>;
};

export type StampCreateNestedOneWithoutCouponActivityInput = {
  connect?: InputMaybe<StampWhereUniqueInput>;
  connectOrCreate?: InputMaybe<StampCreateOrConnectWithoutCouponActivityInput>;
  create?: InputMaybe<StampCreateWithoutCouponActivityInput>;
};

export type StampCreateNestedOneWithoutInStoreItemInput = {
  connect?: InputMaybe<StampWhereUniqueInput>;
  connectOrCreate?: InputMaybe<StampCreateOrConnectWithoutInStoreItemInput>;
  create?: InputMaybe<StampCreateWithoutInStoreItemInput>;
};

export type StampCreateNestedOneWithoutInValidReasonHistoriesInput = {
  connect?: InputMaybe<StampWhereUniqueInput>;
  connectOrCreate?: InputMaybe<StampCreateOrConnectWithoutInValidReasonHistoriesInput>;
  create?: InputMaybe<StampCreateWithoutInValidReasonHistoriesInput>;
};

export type StampCreateNestedOneWithoutPostsUsedInput = {
  connect?: InputMaybe<StampWhereUniqueInput>;
  connectOrCreate?: InputMaybe<StampCreateOrConnectWithoutPostsUsedInput>;
  create?: InputMaybe<StampCreateWithoutPostsUsedInput>;
};

export type StampCreateOrConnectWithoutCollectionsInput = {
  create: StampCreateWithoutCollectionsInput;
  where: StampWhereUniqueInput;
};

export type StampCreateOrConnectWithoutCouponActivityInput = {
  create: StampCreateWithoutCouponActivityInput;
  where: StampWhereUniqueInput;
};

export type StampCreateOrConnectWithoutCreatorInput = {
  create: StampCreateWithoutCreatorInput;
  where: StampWhereUniqueInput;
};

export type StampCreateOrConnectWithoutInStoreItemInput = {
  create: StampCreateWithoutInStoreItemInput;
  where: StampWhereUniqueInput;
};

export type StampCreateOrConnectWithoutInValidReasonHistoriesInput = {
  create: StampCreateWithoutInValidReasonHistoriesInput;
  where: StampWhereUniqueInput;
};

export type StampCreateOrConnectWithoutPostsUsedInput = {
  create: StampCreateWithoutPostsUsedInput;
  where: StampWhereUniqueInput;
};

export type StampCreateOrConnectWithoutScenicSpotInput = {
  create: StampCreateWithoutScenicSpotInput;
  where: StampWhereUniqueInput;
};

export type StampCreateWithoutCollectionsInput = {
  couponActivity?: InputMaybe<CouponActivityCreateNestedOneWithoutStampInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  creator: UserCreateNestedOneWithoutCreatedStampsInput;
  description?: InputMaybe<Scalars['String']>;
  free?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['String']>;
  inStoreItem?: InputMaybe<StoreItemCreateNestedManyWithoutItemStampInput>;
  inValidReasonHistories?: InputMaybe<InValidReasonCreateNestedManyWithoutStampInput>;
  name?: InputMaybe<Scalars['String']>;
  picUri: Scalars['String'];
  postsUsed?: InputMaybe<PostCreateNestedManyWithoutStampInput>;
  price?: InputMaybe<Scalars['Float']>;
  publishedDate?: InputMaybe<Scalars['DateTime']>;
  qrcode?: InputMaybe<Scalars['String']>;
  scenicSpot?: InputMaybe<ScenicSpotCreateNestedOneWithoutStampsInput>;
  sn?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  valid?: InputMaybe<Scalars['Boolean']>;
};

export type StampCreateWithoutCouponActivityInput = {
  collections?: InputMaybe<CollectionCreateNestedManyWithoutStampInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  creator: UserCreateNestedOneWithoutCreatedStampsInput;
  description?: InputMaybe<Scalars['String']>;
  free?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['String']>;
  inStoreItem?: InputMaybe<StoreItemCreateNestedManyWithoutItemStampInput>;
  inValidReasonHistories?: InputMaybe<InValidReasonCreateNestedManyWithoutStampInput>;
  name?: InputMaybe<Scalars['String']>;
  picUri: Scalars['String'];
  postsUsed?: InputMaybe<PostCreateNestedManyWithoutStampInput>;
  price?: InputMaybe<Scalars['Float']>;
  publishedDate?: InputMaybe<Scalars['DateTime']>;
  qrcode?: InputMaybe<Scalars['String']>;
  scenicSpot?: InputMaybe<ScenicSpotCreateNestedOneWithoutStampsInput>;
  sn?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  valid?: InputMaybe<Scalars['Boolean']>;
};

export type StampCreateWithoutCreatorInput = {
  collections?: InputMaybe<CollectionCreateNestedManyWithoutStampInput>;
  couponActivity?: InputMaybe<CouponActivityCreateNestedOneWithoutStampInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  free?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['String']>;
  inStoreItem?: InputMaybe<StoreItemCreateNestedManyWithoutItemStampInput>;
  inValidReasonHistories?: InputMaybe<InValidReasonCreateNestedManyWithoutStampInput>;
  name?: InputMaybe<Scalars['String']>;
  picUri: Scalars['String'];
  postsUsed?: InputMaybe<PostCreateNestedManyWithoutStampInput>;
  price?: InputMaybe<Scalars['Float']>;
  publishedDate?: InputMaybe<Scalars['DateTime']>;
  qrcode?: InputMaybe<Scalars['String']>;
  scenicSpot?: InputMaybe<ScenicSpotCreateNestedOneWithoutStampsInput>;
  sn?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  valid?: InputMaybe<Scalars['Boolean']>;
};

export type StampCreateWithoutInStoreItemInput = {
  collections?: InputMaybe<CollectionCreateNestedManyWithoutStampInput>;
  couponActivity?: InputMaybe<CouponActivityCreateNestedOneWithoutStampInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  creator: UserCreateNestedOneWithoutCreatedStampsInput;
  description?: InputMaybe<Scalars['String']>;
  free?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['String']>;
  inValidReasonHistories?: InputMaybe<InValidReasonCreateNestedManyWithoutStampInput>;
  name?: InputMaybe<Scalars['String']>;
  picUri: Scalars['String'];
  postsUsed?: InputMaybe<PostCreateNestedManyWithoutStampInput>;
  price?: InputMaybe<Scalars['Float']>;
  publishedDate?: InputMaybe<Scalars['DateTime']>;
  qrcode?: InputMaybe<Scalars['String']>;
  scenicSpot?: InputMaybe<ScenicSpotCreateNestedOneWithoutStampsInput>;
  sn?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  valid?: InputMaybe<Scalars['Boolean']>;
};

export type StampCreateWithoutInValidReasonHistoriesInput = {
  collections?: InputMaybe<CollectionCreateNestedManyWithoutStampInput>;
  couponActivity?: InputMaybe<CouponActivityCreateNestedOneWithoutStampInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  creator: UserCreateNestedOneWithoutCreatedStampsInput;
  description?: InputMaybe<Scalars['String']>;
  free?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['String']>;
  inStoreItem?: InputMaybe<StoreItemCreateNestedManyWithoutItemStampInput>;
  name?: InputMaybe<Scalars['String']>;
  picUri: Scalars['String'];
  postsUsed?: InputMaybe<PostCreateNestedManyWithoutStampInput>;
  price?: InputMaybe<Scalars['Float']>;
  publishedDate?: InputMaybe<Scalars['DateTime']>;
  qrcode?: InputMaybe<Scalars['String']>;
  scenicSpot?: InputMaybe<ScenicSpotCreateNestedOneWithoutStampsInput>;
  sn?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  valid?: InputMaybe<Scalars['Boolean']>;
};

export type StampCreateWithoutPostsUsedInput = {
  collections?: InputMaybe<CollectionCreateNestedManyWithoutStampInput>;
  couponActivity?: InputMaybe<CouponActivityCreateNestedOneWithoutStampInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  creator: UserCreateNestedOneWithoutCreatedStampsInput;
  description?: InputMaybe<Scalars['String']>;
  free?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['String']>;
  inStoreItem?: InputMaybe<StoreItemCreateNestedManyWithoutItemStampInput>;
  inValidReasonHistories?: InputMaybe<InValidReasonCreateNestedManyWithoutStampInput>;
  name?: InputMaybe<Scalars['String']>;
  picUri: Scalars['String'];
  price?: InputMaybe<Scalars['Float']>;
  publishedDate?: InputMaybe<Scalars['DateTime']>;
  qrcode?: InputMaybe<Scalars['String']>;
  scenicSpot?: InputMaybe<ScenicSpotCreateNestedOneWithoutStampsInput>;
  sn?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  valid?: InputMaybe<Scalars['Boolean']>;
};

export type StampCreateWithoutScenicSpotInput = {
  collections?: InputMaybe<CollectionCreateNestedManyWithoutStampInput>;
  couponActivity?: InputMaybe<CouponActivityCreateNestedOneWithoutStampInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  creator: UserCreateNestedOneWithoutCreatedStampsInput;
  description?: InputMaybe<Scalars['String']>;
  free?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['String']>;
  inStoreItem?: InputMaybe<StoreItemCreateNestedManyWithoutItemStampInput>;
  inValidReasonHistories?: InputMaybe<InValidReasonCreateNestedManyWithoutStampInput>;
  name?: InputMaybe<Scalars['String']>;
  picUri: Scalars['String'];
  postsUsed?: InputMaybe<PostCreateNestedManyWithoutStampInput>;
  price?: InputMaybe<Scalars['Float']>;
  publishedDate?: InputMaybe<Scalars['DateTime']>;
  qrcode?: InputMaybe<Scalars['String']>;
  sn?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  valid?: InputMaybe<Scalars['Boolean']>;
};

export type StampGroupBy = {
  __typename?: 'StampGroupBy';
  _avg?: Maybe<StampAvgAggregate>;
  _count?: Maybe<StampCountAggregate>;
  _max?: Maybe<StampMaxAggregate>;
  _min?: Maybe<StampMinAggregate>;
  _sum?: Maybe<StampSumAggregate>;
  createdAt: Scalars['DateTime'];
  creatorId: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  free: Scalars['Boolean'];
  id: Scalars['String'];
  name: Scalars['String'];
  picUri: Scalars['String'];
  price: Scalars['Float'];
  publishedDate: Scalars['DateTime'];
  qrcode?: Maybe<Scalars['String']>;
  scenicSpotId?: Maybe<Scalars['String']>;
  sn: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  valid: Scalars['Boolean'];
};

export type StampListRelationFilter = {
  every?: InputMaybe<StampWhereInput>;
  none?: InputMaybe<StampWhereInput>;
  some?: InputMaybe<StampWhereInput>;
};

export type StampMaxAggregate = {
  __typename?: 'StampMaxAggregate';
  createdAt?: Maybe<Scalars['DateTime']>;
  creatorId?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  free?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  picUri?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Float']>;
  publishedDate?: Maybe<Scalars['DateTime']>;
  qrcode?: Maybe<Scalars['String']>;
  scenicSpotId?: Maybe<Scalars['String']>;
  sn?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  valid?: Maybe<Scalars['Boolean']>;
};

export type StampMaxOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  creatorId?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  free?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  picUri?: InputMaybe<SortOrder>;
  price?: InputMaybe<SortOrder>;
  publishedDate?: InputMaybe<SortOrder>;
  qrcode?: InputMaybe<SortOrder>;
  scenicSpotId?: InputMaybe<SortOrder>;
  sn?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  valid?: InputMaybe<SortOrder>;
};

export type StampMinAggregate = {
  __typename?: 'StampMinAggregate';
  createdAt?: Maybe<Scalars['DateTime']>;
  creatorId?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  free?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  picUri?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Float']>;
  publishedDate?: Maybe<Scalars['DateTime']>;
  qrcode?: Maybe<Scalars['String']>;
  scenicSpotId?: Maybe<Scalars['String']>;
  sn?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  valid?: Maybe<Scalars['Boolean']>;
};

export type StampMinOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  creatorId?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  free?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  picUri?: InputMaybe<SortOrder>;
  price?: InputMaybe<SortOrder>;
  publishedDate?: InputMaybe<SortOrder>;
  qrcode?: InputMaybe<SortOrder>;
  scenicSpotId?: InputMaybe<SortOrder>;
  sn?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  valid?: InputMaybe<SortOrder>;
};

export type StampOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type StampOrderByWithAggregationInput = {
  _avg?: InputMaybe<StampAvgOrderByAggregateInput>;
  _count?: InputMaybe<StampCountOrderByAggregateInput>;
  _max?: InputMaybe<StampMaxOrderByAggregateInput>;
  _min?: InputMaybe<StampMinOrderByAggregateInput>;
  _sum?: InputMaybe<StampSumOrderByAggregateInput>;
  createdAt?: InputMaybe<SortOrder>;
  creatorId?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  free?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  picUri?: InputMaybe<SortOrder>;
  price?: InputMaybe<SortOrder>;
  publishedDate?: InputMaybe<SortOrder>;
  qrcode?: InputMaybe<SortOrder>;
  scenicSpotId?: InputMaybe<SortOrder>;
  sn?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  valid?: InputMaybe<SortOrder>;
};

export type StampOrderByWithRelationInput = {
  collections?: InputMaybe<CollectionOrderByRelationAggregateInput>;
  couponActivity?: InputMaybe<CouponActivityOrderByWithRelationInput>;
  createdAt?: InputMaybe<SortOrder>;
  creator?: InputMaybe<UserOrderByWithRelationInput>;
  creatorId?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  free?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  inStoreItem?: InputMaybe<StoreItemOrderByRelationAggregateInput>;
  inValidReasonHistories?: InputMaybe<InValidReasonOrderByRelationAggregateInput>;
  name?: InputMaybe<SortOrder>;
  picUri?: InputMaybe<SortOrder>;
  postsUsed?: InputMaybe<PostOrderByRelationAggregateInput>;
  price?: InputMaybe<SortOrder>;
  publishedDate?: InputMaybe<SortOrder>;
  qrcode?: InputMaybe<SortOrder>;
  scenicSpot?: InputMaybe<ScenicSpotOrderByWithRelationInput>;
  scenicSpotId?: InputMaybe<SortOrder>;
  sn?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  valid?: InputMaybe<SortOrder>;
};

export type StampRelationFilter = {
  is?: InputMaybe<StampWhereInput>;
  isNot?: InputMaybe<StampWhereInput>;
};

export enum StampScalarFieldEnum {
  CreatedAt = 'createdAt',
  CreatorId = 'creatorId',
  Description = 'description',
  Free = 'free',
  Id = 'id',
  Name = 'name',
  PicUri = 'picUri',
  Price = 'price',
  PublishedDate = 'publishedDate',
  Qrcode = 'qrcode',
  ScenicSpotId = 'scenicSpotId',
  Sn = 'sn',
  UpdatedAt = 'updatedAt',
  Valid = 'valid'
}

export type StampScalarWhereInput = {
  AND?: InputMaybe<Array<StampScalarWhereInput>>;
  NOT?: InputMaybe<Array<StampScalarWhereInput>>;
  OR?: InputMaybe<Array<StampScalarWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  creatorId?: InputMaybe<StringFilter>;
  description?: InputMaybe<StringNullableFilter>;
  free?: InputMaybe<BoolFilter>;
  id?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringFilter>;
  picUri?: InputMaybe<StringFilter>;
  price?: InputMaybe<FloatFilter>;
  publishedDate?: InputMaybe<DateTimeFilter>;
  qrcode?: InputMaybe<StringNullableFilter>;
  scenicSpotId?: InputMaybe<StringNullableFilter>;
  sn?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  valid?: InputMaybe<BoolFilter>;
};

export type StampScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<StampScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<StampScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<StampScalarWhereWithAggregatesInput>>;
  createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  creatorId?: InputMaybe<StringWithAggregatesFilter>;
  description?: InputMaybe<StringNullableWithAggregatesFilter>;
  free?: InputMaybe<BoolWithAggregatesFilter>;
  id?: InputMaybe<StringWithAggregatesFilter>;
  name?: InputMaybe<StringWithAggregatesFilter>;
  picUri?: InputMaybe<StringWithAggregatesFilter>;
  price?: InputMaybe<FloatWithAggregatesFilter>;
  publishedDate?: InputMaybe<DateTimeWithAggregatesFilter>;
  qrcode?: InputMaybe<StringNullableWithAggregatesFilter>;
  scenicSpotId?: InputMaybe<StringNullableWithAggregatesFilter>;
  sn?: InputMaybe<StringWithAggregatesFilter>;
  updatedAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  valid?: InputMaybe<BoolWithAggregatesFilter>;
};

export type StampSumAggregate = {
  __typename?: 'StampSumAggregate';
  price?: Maybe<Scalars['Float']>;
};

export type StampSumOrderByAggregateInput = {
  price?: InputMaybe<SortOrder>;
};

export type StampUpdateInput = {
  collections?: InputMaybe<CollectionUpdateManyWithoutStampNestedInput>;
  couponActivity?: InputMaybe<CouponActivityUpdateOneWithoutStampNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  creator?: InputMaybe<UserUpdateOneRequiredWithoutCreatedStampsNestedInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  free?: InputMaybe<BoolFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  inStoreItem?: InputMaybe<StoreItemUpdateManyWithoutItemStampNestedInput>;
  inValidReasonHistories?: InputMaybe<InValidReasonUpdateManyWithoutStampNestedInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  picUri?: InputMaybe<StringFieldUpdateOperationsInput>;
  postsUsed?: InputMaybe<PostUpdateManyWithoutStampNestedInput>;
  price?: InputMaybe<FloatFieldUpdateOperationsInput>;
  publishedDate?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  qrcode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  scenicSpot?: InputMaybe<ScenicSpotUpdateOneWithoutStampsNestedInput>;
  sn?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  valid?: InputMaybe<BoolFieldUpdateOperationsInput>;
};

export type StampUpdateManyMutationInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  free?: InputMaybe<BoolFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  picUri?: InputMaybe<StringFieldUpdateOperationsInput>;
  price?: InputMaybe<FloatFieldUpdateOperationsInput>;
  publishedDate?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  qrcode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sn?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  valid?: InputMaybe<BoolFieldUpdateOperationsInput>;
};

export type StampUpdateManyWithWhereWithoutCreatorInput = {
  data: StampUpdateManyMutationInput;
  where: StampScalarWhereInput;
};

export type StampUpdateManyWithWhereWithoutScenicSpotInput = {
  data: StampUpdateManyMutationInput;
  where: StampScalarWhereInput;
};

export type StampUpdateManyWithoutCreatorNestedInput = {
  connect?: InputMaybe<Array<StampWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<StampCreateOrConnectWithoutCreatorInput>>;
  create?: InputMaybe<Array<StampCreateWithoutCreatorInput>>;
  createMany?: InputMaybe<StampCreateManyCreatorInputEnvelope>;
  delete?: InputMaybe<Array<StampWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<StampScalarWhereInput>>;
  disconnect?: InputMaybe<Array<StampWhereUniqueInput>>;
  set?: InputMaybe<Array<StampWhereUniqueInput>>;
  update?: InputMaybe<Array<StampUpdateWithWhereUniqueWithoutCreatorInput>>;
  updateMany?: InputMaybe<Array<StampUpdateManyWithWhereWithoutCreatorInput>>;
  upsert?: InputMaybe<Array<StampUpsertWithWhereUniqueWithoutCreatorInput>>;
};

export type StampUpdateManyWithoutScenicSpotNestedInput = {
  connect?: InputMaybe<Array<StampWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<StampCreateOrConnectWithoutScenicSpotInput>>;
  create?: InputMaybe<Array<StampCreateWithoutScenicSpotInput>>;
  createMany?: InputMaybe<StampCreateManyScenicSpotInputEnvelope>;
  delete?: InputMaybe<Array<StampWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<StampScalarWhereInput>>;
  disconnect?: InputMaybe<Array<StampWhereUniqueInput>>;
  set?: InputMaybe<Array<StampWhereUniqueInput>>;
  update?: InputMaybe<Array<StampUpdateWithWhereUniqueWithoutScenicSpotInput>>;
  updateMany?: InputMaybe<Array<StampUpdateManyWithWhereWithoutScenicSpotInput>>;
  upsert?: InputMaybe<Array<StampUpsertWithWhereUniqueWithoutScenicSpotInput>>;
};

export type StampUpdateOneRequiredWithoutPostsUsedNestedInput = {
  connect?: InputMaybe<StampWhereUniqueInput>;
  connectOrCreate?: InputMaybe<StampCreateOrConnectWithoutPostsUsedInput>;
  create?: InputMaybe<StampCreateWithoutPostsUsedInput>;
  update?: InputMaybe<StampUpdateWithoutPostsUsedInput>;
  upsert?: InputMaybe<StampUpsertWithoutPostsUsedInput>;
};

export type StampUpdateOneWithoutCollectionsNestedInput = {
  connect?: InputMaybe<StampWhereUniqueInput>;
  connectOrCreate?: InputMaybe<StampCreateOrConnectWithoutCollectionsInput>;
  create?: InputMaybe<StampCreateWithoutCollectionsInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<StampUpdateWithoutCollectionsInput>;
  upsert?: InputMaybe<StampUpsertWithoutCollectionsInput>;
};

export type StampUpdateOneWithoutCouponActivityNestedInput = {
  connect?: InputMaybe<StampWhereUniqueInput>;
  connectOrCreate?: InputMaybe<StampCreateOrConnectWithoutCouponActivityInput>;
  create?: InputMaybe<StampCreateWithoutCouponActivityInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<StampUpdateWithoutCouponActivityInput>;
  upsert?: InputMaybe<StampUpsertWithoutCouponActivityInput>;
};

export type StampUpdateOneWithoutInStoreItemNestedInput = {
  connect?: InputMaybe<StampWhereUniqueInput>;
  connectOrCreate?: InputMaybe<StampCreateOrConnectWithoutInStoreItemInput>;
  create?: InputMaybe<StampCreateWithoutInStoreItemInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<StampUpdateWithoutInStoreItemInput>;
  upsert?: InputMaybe<StampUpsertWithoutInStoreItemInput>;
};

export type StampUpdateOneWithoutInValidReasonHistoriesNestedInput = {
  connect?: InputMaybe<StampWhereUniqueInput>;
  connectOrCreate?: InputMaybe<StampCreateOrConnectWithoutInValidReasonHistoriesInput>;
  create?: InputMaybe<StampCreateWithoutInValidReasonHistoriesInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<StampUpdateWithoutInValidReasonHistoriesInput>;
  upsert?: InputMaybe<StampUpsertWithoutInValidReasonHistoriesInput>;
};

export type StampUpdateWithWhereUniqueWithoutCreatorInput = {
  data: StampUpdateWithoutCreatorInput;
  where: StampWhereUniqueInput;
};

export type StampUpdateWithWhereUniqueWithoutScenicSpotInput = {
  data: StampUpdateWithoutScenicSpotInput;
  where: StampWhereUniqueInput;
};

export type StampUpdateWithoutCollectionsInput = {
  couponActivity?: InputMaybe<CouponActivityUpdateOneWithoutStampNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  creator?: InputMaybe<UserUpdateOneRequiredWithoutCreatedStampsNestedInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  free?: InputMaybe<BoolFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  inStoreItem?: InputMaybe<StoreItemUpdateManyWithoutItemStampNestedInput>;
  inValidReasonHistories?: InputMaybe<InValidReasonUpdateManyWithoutStampNestedInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  picUri?: InputMaybe<StringFieldUpdateOperationsInput>;
  postsUsed?: InputMaybe<PostUpdateManyWithoutStampNestedInput>;
  price?: InputMaybe<FloatFieldUpdateOperationsInput>;
  publishedDate?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  qrcode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  scenicSpot?: InputMaybe<ScenicSpotUpdateOneWithoutStampsNestedInput>;
  sn?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  valid?: InputMaybe<BoolFieldUpdateOperationsInput>;
};

export type StampUpdateWithoutCouponActivityInput = {
  collections?: InputMaybe<CollectionUpdateManyWithoutStampNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  creator?: InputMaybe<UserUpdateOneRequiredWithoutCreatedStampsNestedInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  free?: InputMaybe<BoolFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  inStoreItem?: InputMaybe<StoreItemUpdateManyWithoutItemStampNestedInput>;
  inValidReasonHistories?: InputMaybe<InValidReasonUpdateManyWithoutStampNestedInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  picUri?: InputMaybe<StringFieldUpdateOperationsInput>;
  postsUsed?: InputMaybe<PostUpdateManyWithoutStampNestedInput>;
  price?: InputMaybe<FloatFieldUpdateOperationsInput>;
  publishedDate?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  qrcode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  scenicSpot?: InputMaybe<ScenicSpotUpdateOneWithoutStampsNestedInput>;
  sn?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  valid?: InputMaybe<BoolFieldUpdateOperationsInput>;
};

export type StampUpdateWithoutCreatorInput = {
  collections?: InputMaybe<CollectionUpdateManyWithoutStampNestedInput>;
  couponActivity?: InputMaybe<CouponActivityUpdateOneWithoutStampNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  free?: InputMaybe<BoolFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  inStoreItem?: InputMaybe<StoreItemUpdateManyWithoutItemStampNestedInput>;
  inValidReasonHistories?: InputMaybe<InValidReasonUpdateManyWithoutStampNestedInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  picUri?: InputMaybe<StringFieldUpdateOperationsInput>;
  postsUsed?: InputMaybe<PostUpdateManyWithoutStampNestedInput>;
  price?: InputMaybe<FloatFieldUpdateOperationsInput>;
  publishedDate?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  qrcode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  scenicSpot?: InputMaybe<ScenicSpotUpdateOneWithoutStampsNestedInput>;
  sn?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  valid?: InputMaybe<BoolFieldUpdateOperationsInput>;
};

export type StampUpdateWithoutInStoreItemInput = {
  collections?: InputMaybe<CollectionUpdateManyWithoutStampNestedInput>;
  couponActivity?: InputMaybe<CouponActivityUpdateOneWithoutStampNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  creator?: InputMaybe<UserUpdateOneRequiredWithoutCreatedStampsNestedInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  free?: InputMaybe<BoolFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  inValidReasonHistories?: InputMaybe<InValidReasonUpdateManyWithoutStampNestedInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  picUri?: InputMaybe<StringFieldUpdateOperationsInput>;
  postsUsed?: InputMaybe<PostUpdateManyWithoutStampNestedInput>;
  price?: InputMaybe<FloatFieldUpdateOperationsInput>;
  publishedDate?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  qrcode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  scenicSpot?: InputMaybe<ScenicSpotUpdateOneWithoutStampsNestedInput>;
  sn?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  valid?: InputMaybe<BoolFieldUpdateOperationsInput>;
};

export type StampUpdateWithoutInValidReasonHistoriesInput = {
  collections?: InputMaybe<CollectionUpdateManyWithoutStampNestedInput>;
  couponActivity?: InputMaybe<CouponActivityUpdateOneWithoutStampNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  creator?: InputMaybe<UserUpdateOneRequiredWithoutCreatedStampsNestedInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  free?: InputMaybe<BoolFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  inStoreItem?: InputMaybe<StoreItemUpdateManyWithoutItemStampNestedInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  picUri?: InputMaybe<StringFieldUpdateOperationsInput>;
  postsUsed?: InputMaybe<PostUpdateManyWithoutStampNestedInput>;
  price?: InputMaybe<FloatFieldUpdateOperationsInput>;
  publishedDate?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  qrcode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  scenicSpot?: InputMaybe<ScenicSpotUpdateOneWithoutStampsNestedInput>;
  sn?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  valid?: InputMaybe<BoolFieldUpdateOperationsInput>;
};

export type StampUpdateWithoutPostsUsedInput = {
  collections?: InputMaybe<CollectionUpdateManyWithoutStampNestedInput>;
  couponActivity?: InputMaybe<CouponActivityUpdateOneWithoutStampNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  creator?: InputMaybe<UserUpdateOneRequiredWithoutCreatedStampsNestedInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  free?: InputMaybe<BoolFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  inStoreItem?: InputMaybe<StoreItemUpdateManyWithoutItemStampNestedInput>;
  inValidReasonHistories?: InputMaybe<InValidReasonUpdateManyWithoutStampNestedInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  picUri?: InputMaybe<StringFieldUpdateOperationsInput>;
  price?: InputMaybe<FloatFieldUpdateOperationsInput>;
  publishedDate?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  qrcode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  scenicSpot?: InputMaybe<ScenicSpotUpdateOneWithoutStampsNestedInput>;
  sn?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  valid?: InputMaybe<BoolFieldUpdateOperationsInput>;
};

export type StampUpdateWithoutScenicSpotInput = {
  collections?: InputMaybe<CollectionUpdateManyWithoutStampNestedInput>;
  couponActivity?: InputMaybe<CouponActivityUpdateOneWithoutStampNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  creator?: InputMaybe<UserUpdateOneRequiredWithoutCreatedStampsNestedInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  free?: InputMaybe<BoolFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  inStoreItem?: InputMaybe<StoreItemUpdateManyWithoutItemStampNestedInput>;
  inValidReasonHistories?: InputMaybe<InValidReasonUpdateManyWithoutStampNestedInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  picUri?: InputMaybe<StringFieldUpdateOperationsInput>;
  postsUsed?: InputMaybe<PostUpdateManyWithoutStampNestedInput>;
  price?: InputMaybe<FloatFieldUpdateOperationsInput>;
  publishedDate?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  qrcode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sn?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  valid?: InputMaybe<BoolFieldUpdateOperationsInput>;
};

export type StampUpsertWithWhereUniqueWithoutCreatorInput = {
  create: StampCreateWithoutCreatorInput;
  update: StampUpdateWithoutCreatorInput;
  where: StampWhereUniqueInput;
};

export type StampUpsertWithWhereUniqueWithoutScenicSpotInput = {
  create: StampCreateWithoutScenicSpotInput;
  update: StampUpdateWithoutScenicSpotInput;
  where: StampWhereUniqueInput;
};

export type StampUpsertWithoutCollectionsInput = {
  create: StampCreateWithoutCollectionsInput;
  update: StampUpdateWithoutCollectionsInput;
};

export type StampUpsertWithoutCouponActivityInput = {
  create: StampCreateWithoutCouponActivityInput;
  update: StampUpdateWithoutCouponActivityInput;
};

export type StampUpsertWithoutInStoreItemInput = {
  create: StampCreateWithoutInStoreItemInput;
  update: StampUpdateWithoutInStoreItemInput;
};

export type StampUpsertWithoutInValidReasonHistoriesInput = {
  create: StampCreateWithoutInValidReasonHistoriesInput;
  update: StampUpdateWithoutInValidReasonHistoriesInput;
};

export type StampUpsertWithoutPostsUsedInput = {
  create: StampCreateWithoutPostsUsedInput;
  update: StampUpdateWithoutPostsUsedInput;
};

export type StampWhereInput = {
  AND?: InputMaybe<Array<StampWhereInput>>;
  NOT?: InputMaybe<Array<StampWhereInput>>;
  OR?: InputMaybe<Array<StampWhereInput>>;
  collections?: InputMaybe<CollectionListRelationFilter>;
  couponActivity?: InputMaybe<CouponActivityRelationFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  creator?: InputMaybe<UserRelationFilter>;
  creatorId?: InputMaybe<StringFilter>;
  description?: InputMaybe<StringNullableFilter>;
  free?: InputMaybe<BoolFilter>;
  id?: InputMaybe<StringFilter>;
  inStoreItem?: InputMaybe<StoreItemListRelationFilter>;
  inValidReasonHistories?: InputMaybe<InValidReasonListRelationFilter>;
  name?: InputMaybe<StringFilter>;
  picUri?: InputMaybe<StringFilter>;
  postsUsed?: InputMaybe<PostListRelationFilter>;
  price?: InputMaybe<FloatFilter>;
  publishedDate?: InputMaybe<DateTimeFilter>;
  qrcode?: InputMaybe<StringNullableFilter>;
  scenicSpot?: InputMaybe<ScenicSpotRelationFilter>;
  scenicSpotId?: InputMaybe<StringNullableFilter>;
  sn?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  valid?: InputMaybe<BoolFilter>;
};

export type StampWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
  qrcode?: InputMaybe<Scalars['String']>;
};

export type StoreItem = {
  __typename?: 'StoreItem';
  _count?: Maybe<StoreItemCount>;
  /** 已經賣過了 */
  bought: Scalars['Boolean'];
  createdAt: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  favoriteByUsers: Array<User>;
  id: Scalars['String'];
  inBenefitMemoToCoins: Array<BenefitMemoToCoin>;
  inBenefitMemoToOrders: Array<BenefitMemoToOrder>;
  inStoreToCartItems: Array<StoreItemToCartItem>;
  itemAds?: Maybe<Advertisement>;
  itemAdsId?: Maybe<Scalars['String']>;
  itemCard?: Maybe<Card>;
  itemCardId?: Maybe<Scalars['String']>;
  itemStamp?: Maybe<Stamp>;
  itemStampId?: Maybe<Scalars['String']>;
  limitedQuantity?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  price: Scalars['Decimal'];
  priceOriginal?: Maybe<Scalars['Decimal']>;
  profitable: Profitable;
  profitableId: Scalars['String'];
  readableId?: Maybe<Scalars['String']>;
  scenicSpot?: Maybe<ScenicSpot>;
  scenicSpotId?: Maybe<Scalars['String']>;
  stockQuantity?: Maybe<Scalars['Int']>;
  tags: Array<StoreItemTag>;
  tagsClassify: Array<StoreItemTag>;
  type: StoreItemType;
  updatedAt: Scalars['DateTime'];
  valid: Scalars['Boolean'];
};


export type StoreItemFavoriteByUsersArgs = {
  cursor?: InputMaybe<UserWhereUniqueInput>;
  distinct?: InputMaybe<Array<UserScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<UserOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<UserWhereInput>;
};


export type StoreItemInBenefitMemoToCoinsArgs = {
  cursor?: InputMaybe<BenefitMemoToCoinWhereUniqueInput>;
  distinct?: InputMaybe<Array<BenefitMemoToCoinScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<BenefitMemoToCoinOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<BenefitMemoToCoinWhereInput>;
};


export type StoreItemInBenefitMemoToOrdersArgs = {
  cursor?: InputMaybe<BenefitMemoToOrderWhereUniqueInput>;
  distinct?: InputMaybe<Array<BenefitMemoToOrderScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<BenefitMemoToOrderOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<BenefitMemoToOrderWhereInput>;
};


export type StoreItemInStoreToCartItemsArgs = {
  cursor?: InputMaybe<StoreItemToCartItemWhereUniqueInput>;
  distinct?: InputMaybe<Array<StoreItemToCartItemScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<StoreItemToCartItemOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<StoreItemToCartItemWhereInput>;
};


export type StoreItemTagsArgs = {
  cursor?: InputMaybe<StoreItemTagWhereUniqueInput>;
  distinct?: InputMaybe<Array<StoreItemTagScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<StoreItemTagOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<StoreItemTagWhereInput>;
};


export type StoreItemTagsClassifyArgs = {
  cursor?: InputMaybe<StoreItemTagWhereUniqueInput>;
  distinct?: InputMaybe<Array<StoreItemTagScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<StoreItemTagOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<StoreItemTagWhereInput>;
};

export type StoreItemAvgAggregate = {
  __typename?: 'StoreItemAvgAggregate';
  limitedQuantity?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Decimal']>;
  priceOriginal?: Maybe<Scalars['Decimal']>;
  stockQuantity?: Maybe<Scalars['Float']>;
};

export type StoreItemAvgOrderByAggregateInput = {
  limitedQuantity?: InputMaybe<SortOrder>;
  price?: InputMaybe<SortOrder>;
  priceOriginal?: InputMaybe<SortOrder>;
  stockQuantity?: InputMaybe<SortOrder>;
};

export type StoreItemCount = {
  __typename?: 'StoreItemCount';
  favoriteByUsers: Scalars['Int'];
  inBenefitMemoToCoins: Scalars['Int'];
  inBenefitMemoToOrders: Scalars['Int'];
  inStoreToCartItems: Scalars['Int'];
  tags: Scalars['Int'];
  tagsClassify: Scalars['Int'];
};


export type StoreItemCountFavoriteByUsersArgs = {
  where?: InputMaybe<UserWhereInput>;
};


export type StoreItemCountInBenefitMemoToCoinsArgs = {
  where?: InputMaybe<BenefitMemoToCoinWhereInput>;
};


export type StoreItemCountInBenefitMemoToOrdersArgs = {
  where?: InputMaybe<BenefitMemoToOrderWhereInput>;
};


export type StoreItemCountInStoreToCartItemsArgs = {
  where?: InputMaybe<StoreItemToCartItemWhereInput>;
};


export type StoreItemCountTagsArgs = {
  where?: InputMaybe<StoreItemTagWhereInput>;
};


export type StoreItemCountTagsClassifyArgs = {
  where?: InputMaybe<StoreItemTagWhereInput>;
};

export type StoreItemCountAggregate = {
  __typename?: 'StoreItemCountAggregate';
  _all: Scalars['Int'];
  createdAt: Scalars['Int'];
  id: Scalars['Int'];
  itemAdsId: Scalars['Int'];
  itemCardId: Scalars['Int'];
  itemStampId: Scalars['Int'];
  limitedQuantity: Scalars['Int'];
  price: Scalars['Int'];
  priceOriginal: Scalars['Int'];
  profitableId: Scalars['Int'];
  readableId: Scalars['Int'];
  stockQuantity: Scalars['Int'];
  type: Scalars['Int'];
  updatedAt: Scalars['Int'];
  valid: Scalars['Int'];
};

export type StoreItemCountOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  itemAdsId?: InputMaybe<SortOrder>;
  itemCardId?: InputMaybe<SortOrder>;
  itemStampId?: InputMaybe<SortOrder>;
  limitedQuantity?: InputMaybe<SortOrder>;
  price?: InputMaybe<SortOrder>;
  priceOriginal?: InputMaybe<SortOrder>;
  profitableId?: InputMaybe<SortOrder>;
  readableId?: InputMaybe<SortOrder>;
  stockQuantity?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  valid?: InputMaybe<SortOrder>;
};

export type StoreItemCreateInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  favoriteByUsers?: InputMaybe<UserCreateNestedManyWithoutFavoriteStoreItemsInput>;
  id?: InputMaybe<Scalars['String']>;
  inBenefitMemoToCoins?: InputMaybe<BenefitMemoToCoinCreateNestedManyWithoutStoreItemInput>;
  inBenefitMemoToOrders?: InputMaybe<BenefitMemoToOrderCreateNestedManyWithoutStoreItemInput>;
  inStoreToCartItems?: InputMaybe<StoreItemToCartItemCreateNestedManyWithoutStoreItemInput>;
  itemAds?: InputMaybe<AdvertisementCreateNestedOneWithoutInStoreItemInput>;
  itemCard?: InputMaybe<CardCreateNestedOneWithoutInStoreItemInput>;
  itemStamp?: InputMaybe<StampCreateNestedOneWithoutInStoreItemInput>;
  limitedQuantity?: InputMaybe<Scalars['Int']>;
  price: Scalars['Decimal'];
  priceOriginal?: InputMaybe<Scalars['Decimal']>;
  profitable: ProfitableCreateNestedOneWithoutStoreItemsInput;
  readableId?: InputMaybe<Scalars['String']>;
  stockQuantity?: InputMaybe<Scalars['Int']>;
  tags?: InputMaybe<StoreItemTagCreateNestedManyWithoutTaggedStoresInput>;
  tagsClassify?: InputMaybe<StoreItemTagCreateNestedManyWithoutTagClassifiedStoresInput>;
  type: StoreItemType;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  valid?: InputMaybe<Scalars['Boolean']>;
};

export type StoreItemCreateManyInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  itemAdsId?: InputMaybe<Scalars['String']>;
  itemCardId?: InputMaybe<Scalars['String']>;
  itemStampId?: InputMaybe<Scalars['String']>;
  limitedQuantity?: InputMaybe<Scalars['Int']>;
  price: Scalars['Decimal'];
  priceOriginal?: InputMaybe<Scalars['Decimal']>;
  profitableId: Scalars['String'];
  readableId?: InputMaybe<Scalars['String']>;
  stockQuantity?: InputMaybe<Scalars['Int']>;
  type: StoreItemType;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  valid?: InputMaybe<Scalars['Boolean']>;
};

export type StoreItemCreateManyItemAdsInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  itemCardId?: InputMaybe<Scalars['String']>;
  itemStampId?: InputMaybe<Scalars['String']>;
  limitedQuantity?: InputMaybe<Scalars['Int']>;
  price: Scalars['Decimal'];
  priceOriginal?: InputMaybe<Scalars['Decimal']>;
  profitableId: Scalars['String'];
  readableId?: InputMaybe<Scalars['String']>;
  stockQuantity?: InputMaybe<Scalars['Int']>;
  type: StoreItemType;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  valid?: InputMaybe<Scalars['Boolean']>;
};

export type StoreItemCreateManyItemAdsInputEnvelope = {
  data: Array<StoreItemCreateManyItemAdsInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type StoreItemCreateManyItemCardInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  itemAdsId?: InputMaybe<Scalars['String']>;
  itemStampId?: InputMaybe<Scalars['String']>;
  limitedQuantity?: InputMaybe<Scalars['Int']>;
  price: Scalars['Decimal'];
  priceOriginal?: InputMaybe<Scalars['Decimal']>;
  profitableId: Scalars['String'];
  readableId?: InputMaybe<Scalars['String']>;
  stockQuantity?: InputMaybe<Scalars['Int']>;
  type: StoreItemType;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  valid?: InputMaybe<Scalars['Boolean']>;
};

export type StoreItemCreateManyItemCardInputEnvelope = {
  data: Array<StoreItemCreateManyItemCardInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type StoreItemCreateManyItemStampInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  itemAdsId?: InputMaybe<Scalars['String']>;
  itemCardId?: InputMaybe<Scalars['String']>;
  limitedQuantity?: InputMaybe<Scalars['Int']>;
  price: Scalars['Decimal'];
  priceOriginal?: InputMaybe<Scalars['Decimal']>;
  profitableId: Scalars['String'];
  readableId?: InputMaybe<Scalars['String']>;
  stockQuantity?: InputMaybe<Scalars['Int']>;
  type: StoreItemType;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  valid?: InputMaybe<Scalars['Boolean']>;
};

export type StoreItemCreateManyItemStampInputEnvelope = {
  data: Array<StoreItemCreateManyItemStampInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type StoreItemCreateManyProfitableInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  itemAdsId?: InputMaybe<Scalars['String']>;
  itemCardId?: InputMaybe<Scalars['String']>;
  itemStampId?: InputMaybe<Scalars['String']>;
  limitedQuantity?: InputMaybe<Scalars['Int']>;
  price: Scalars['Decimal'];
  priceOriginal?: InputMaybe<Scalars['Decimal']>;
  readableId?: InputMaybe<Scalars['String']>;
  stockQuantity?: InputMaybe<Scalars['Int']>;
  type: StoreItemType;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  valid?: InputMaybe<Scalars['Boolean']>;
};

export type StoreItemCreateManyProfitableInputEnvelope = {
  data: Array<StoreItemCreateManyProfitableInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type StoreItemCreateNestedManyWithoutFavoriteByUsersInput = {
  connect?: InputMaybe<Array<StoreItemWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<StoreItemCreateOrConnectWithoutFavoriteByUsersInput>>;
  create?: InputMaybe<Array<StoreItemCreateWithoutFavoriteByUsersInput>>;
};

export type StoreItemCreateNestedManyWithoutItemAdsInput = {
  connect?: InputMaybe<Array<StoreItemWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<StoreItemCreateOrConnectWithoutItemAdsInput>>;
  create?: InputMaybe<Array<StoreItemCreateWithoutItemAdsInput>>;
  createMany?: InputMaybe<StoreItemCreateManyItemAdsInputEnvelope>;
};

export type StoreItemCreateNestedManyWithoutItemCardInput = {
  connect?: InputMaybe<Array<StoreItemWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<StoreItemCreateOrConnectWithoutItemCardInput>>;
  create?: InputMaybe<Array<StoreItemCreateWithoutItemCardInput>>;
  createMany?: InputMaybe<StoreItemCreateManyItemCardInputEnvelope>;
};

export type StoreItemCreateNestedManyWithoutItemStampInput = {
  connect?: InputMaybe<Array<StoreItemWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<StoreItemCreateOrConnectWithoutItemStampInput>>;
  create?: InputMaybe<Array<StoreItemCreateWithoutItemStampInput>>;
  createMany?: InputMaybe<StoreItemCreateManyItemStampInputEnvelope>;
};

export type StoreItemCreateNestedManyWithoutProfitableInput = {
  connect?: InputMaybe<Array<StoreItemWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<StoreItemCreateOrConnectWithoutProfitableInput>>;
  create?: InputMaybe<Array<StoreItemCreateWithoutProfitableInput>>;
  createMany?: InputMaybe<StoreItemCreateManyProfitableInputEnvelope>;
};

export type StoreItemCreateNestedManyWithoutTagsClassifyInput = {
  connect?: InputMaybe<Array<StoreItemWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<StoreItemCreateOrConnectWithoutTagsClassifyInput>>;
  create?: InputMaybe<Array<StoreItemCreateWithoutTagsClassifyInput>>;
};

export type StoreItemCreateNestedManyWithoutTagsInput = {
  connect?: InputMaybe<Array<StoreItemWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<StoreItemCreateOrConnectWithoutTagsInput>>;
  create?: InputMaybe<Array<StoreItemCreateWithoutTagsInput>>;
};

export type StoreItemCreateNestedOneWithoutInBenefitMemoToCoinsInput = {
  connect?: InputMaybe<StoreItemWhereUniqueInput>;
  connectOrCreate?: InputMaybe<StoreItemCreateOrConnectWithoutInBenefitMemoToCoinsInput>;
  create?: InputMaybe<StoreItemCreateWithoutInBenefitMemoToCoinsInput>;
};

export type StoreItemCreateNestedOneWithoutInBenefitMemoToOrdersInput = {
  connect?: InputMaybe<StoreItemWhereUniqueInput>;
  connectOrCreate?: InputMaybe<StoreItemCreateOrConnectWithoutInBenefitMemoToOrdersInput>;
  create?: InputMaybe<StoreItemCreateWithoutInBenefitMemoToOrdersInput>;
};

export type StoreItemCreateNestedOneWithoutInStoreToCartItemsInput = {
  connect?: InputMaybe<StoreItemWhereUniqueInput>;
  connectOrCreate?: InputMaybe<StoreItemCreateOrConnectWithoutInStoreToCartItemsInput>;
  create?: InputMaybe<StoreItemCreateWithoutInStoreToCartItemsInput>;
};

export type StoreItemCreateOrConnectWithoutFavoriteByUsersInput = {
  create: StoreItemCreateWithoutFavoriteByUsersInput;
  where: StoreItemWhereUniqueInput;
};

export type StoreItemCreateOrConnectWithoutInBenefitMemoToCoinsInput = {
  create: StoreItemCreateWithoutInBenefitMemoToCoinsInput;
  where: StoreItemWhereUniqueInput;
};

export type StoreItemCreateOrConnectWithoutInBenefitMemoToOrdersInput = {
  create: StoreItemCreateWithoutInBenefitMemoToOrdersInput;
  where: StoreItemWhereUniqueInput;
};

export type StoreItemCreateOrConnectWithoutInStoreToCartItemsInput = {
  create: StoreItemCreateWithoutInStoreToCartItemsInput;
  where: StoreItemWhereUniqueInput;
};

export type StoreItemCreateOrConnectWithoutItemAdsInput = {
  create: StoreItemCreateWithoutItemAdsInput;
  where: StoreItemWhereUniqueInput;
};

export type StoreItemCreateOrConnectWithoutItemCardInput = {
  create: StoreItemCreateWithoutItemCardInput;
  where: StoreItemWhereUniqueInput;
};

export type StoreItemCreateOrConnectWithoutItemStampInput = {
  create: StoreItemCreateWithoutItemStampInput;
  where: StoreItemWhereUniqueInput;
};

export type StoreItemCreateOrConnectWithoutProfitableInput = {
  create: StoreItemCreateWithoutProfitableInput;
  where: StoreItemWhereUniqueInput;
};

export type StoreItemCreateOrConnectWithoutTagsClassifyInput = {
  create: StoreItemCreateWithoutTagsClassifyInput;
  where: StoreItemWhereUniqueInput;
};

export type StoreItemCreateOrConnectWithoutTagsInput = {
  create: StoreItemCreateWithoutTagsInput;
  where: StoreItemWhereUniqueInput;
};

export type StoreItemCreateWithoutFavoriteByUsersInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  inBenefitMemoToCoins?: InputMaybe<BenefitMemoToCoinCreateNestedManyWithoutStoreItemInput>;
  inBenefitMemoToOrders?: InputMaybe<BenefitMemoToOrderCreateNestedManyWithoutStoreItemInput>;
  inStoreToCartItems?: InputMaybe<StoreItemToCartItemCreateNestedManyWithoutStoreItemInput>;
  itemAds?: InputMaybe<AdvertisementCreateNestedOneWithoutInStoreItemInput>;
  itemCard?: InputMaybe<CardCreateNestedOneWithoutInStoreItemInput>;
  itemStamp?: InputMaybe<StampCreateNestedOneWithoutInStoreItemInput>;
  limitedQuantity?: InputMaybe<Scalars['Int']>;
  price: Scalars['Decimal'];
  priceOriginal?: InputMaybe<Scalars['Decimal']>;
  profitable: ProfitableCreateNestedOneWithoutStoreItemsInput;
  readableId?: InputMaybe<Scalars['String']>;
  stockQuantity?: InputMaybe<Scalars['Int']>;
  tags?: InputMaybe<StoreItemTagCreateNestedManyWithoutTaggedStoresInput>;
  tagsClassify?: InputMaybe<StoreItemTagCreateNestedManyWithoutTagClassifiedStoresInput>;
  type: StoreItemType;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  valid?: InputMaybe<Scalars['Boolean']>;
};

export type StoreItemCreateWithoutInBenefitMemoToCoinsInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  favoriteByUsers?: InputMaybe<UserCreateNestedManyWithoutFavoriteStoreItemsInput>;
  id?: InputMaybe<Scalars['String']>;
  inBenefitMemoToOrders?: InputMaybe<BenefitMemoToOrderCreateNestedManyWithoutStoreItemInput>;
  inStoreToCartItems?: InputMaybe<StoreItemToCartItemCreateNestedManyWithoutStoreItemInput>;
  itemAds?: InputMaybe<AdvertisementCreateNestedOneWithoutInStoreItemInput>;
  itemCard?: InputMaybe<CardCreateNestedOneWithoutInStoreItemInput>;
  itemStamp?: InputMaybe<StampCreateNestedOneWithoutInStoreItemInput>;
  limitedQuantity?: InputMaybe<Scalars['Int']>;
  price: Scalars['Decimal'];
  priceOriginal?: InputMaybe<Scalars['Decimal']>;
  profitable: ProfitableCreateNestedOneWithoutStoreItemsInput;
  readableId?: InputMaybe<Scalars['String']>;
  stockQuantity?: InputMaybe<Scalars['Int']>;
  tags?: InputMaybe<StoreItemTagCreateNestedManyWithoutTaggedStoresInput>;
  tagsClassify?: InputMaybe<StoreItemTagCreateNestedManyWithoutTagClassifiedStoresInput>;
  type: StoreItemType;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  valid?: InputMaybe<Scalars['Boolean']>;
};

export type StoreItemCreateWithoutInBenefitMemoToOrdersInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  favoriteByUsers?: InputMaybe<UserCreateNestedManyWithoutFavoriteStoreItemsInput>;
  id?: InputMaybe<Scalars['String']>;
  inBenefitMemoToCoins?: InputMaybe<BenefitMemoToCoinCreateNestedManyWithoutStoreItemInput>;
  inStoreToCartItems?: InputMaybe<StoreItemToCartItemCreateNestedManyWithoutStoreItemInput>;
  itemAds?: InputMaybe<AdvertisementCreateNestedOneWithoutInStoreItemInput>;
  itemCard?: InputMaybe<CardCreateNestedOneWithoutInStoreItemInput>;
  itemStamp?: InputMaybe<StampCreateNestedOneWithoutInStoreItemInput>;
  limitedQuantity?: InputMaybe<Scalars['Int']>;
  price: Scalars['Decimal'];
  priceOriginal?: InputMaybe<Scalars['Decimal']>;
  profitable: ProfitableCreateNestedOneWithoutStoreItemsInput;
  readableId?: InputMaybe<Scalars['String']>;
  stockQuantity?: InputMaybe<Scalars['Int']>;
  tags?: InputMaybe<StoreItemTagCreateNestedManyWithoutTaggedStoresInput>;
  tagsClassify?: InputMaybe<StoreItemTagCreateNestedManyWithoutTagClassifiedStoresInput>;
  type: StoreItemType;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  valid?: InputMaybe<Scalars['Boolean']>;
};

export type StoreItemCreateWithoutInStoreToCartItemsInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  favoriteByUsers?: InputMaybe<UserCreateNestedManyWithoutFavoriteStoreItemsInput>;
  id?: InputMaybe<Scalars['String']>;
  inBenefitMemoToCoins?: InputMaybe<BenefitMemoToCoinCreateNestedManyWithoutStoreItemInput>;
  inBenefitMemoToOrders?: InputMaybe<BenefitMemoToOrderCreateNestedManyWithoutStoreItemInput>;
  itemAds?: InputMaybe<AdvertisementCreateNestedOneWithoutInStoreItemInput>;
  itemCard?: InputMaybe<CardCreateNestedOneWithoutInStoreItemInput>;
  itemStamp?: InputMaybe<StampCreateNestedOneWithoutInStoreItemInput>;
  limitedQuantity?: InputMaybe<Scalars['Int']>;
  price: Scalars['Decimal'];
  priceOriginal?: InputMaybe<Scalars['Decimal']>;
  profitable: ProfitableCreateNestedOneWithoutStoreItemsInput;
  readableId?: InputMaybe<Scalars['String']>;
  stockQuantity?: InputMaybe<Scalars['Int']>;
  tags?: InputMaybe<StoreItemTagCreateNestedManyWithoutTaggedStoresInput>;
  tagsClassify?: InputMaybe<StoreItemTagCreateNestedManyWithoutTagClassifiedStoresInput>;
  type: StoreItemType;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  valid?: InputMaybe<Scalars['Boolean']>;
};

export type StoreItemCreateWithoutItemAdsInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  favoriteByUsers?: InputMaybe<UserCreateNestedManyWithoutFavoriteStoreItemsInput>;
  id?: InputMaybe<Scalars['String']>;
  inBenefitMemoToCoins?: InputMaybe<BenefitMemoToCoinCreateNestedManyWithoutStoreItemInput>;
  inBenefitMemoToOrders?: InputMaybe<BenefitMemoToOrderCreateNestedManyWithoutStoreItemInput>;
  inStoreToCartItems?: InputMaybe<StoreItemToCartItemCreateNestedManyWithoutStoreItemInput>;
  itemCard?: InputMaybe<CardCreateNestedOneWithoutInStoreItemInput>;
  itemStamp?: InputMaybe<StampCreateNestedOneWithoutInStoreItemInput>;
  limitedQuantity?: InputMaybe<Scalars['Int']>;
  price: Scalars['Decimal'];
  priceOriginal?: InputMaybe<Scalars['Decimal']>;
  profitable: ProfitableCreateNestedOneWithoutStoreItemsInput;
  readableId?: InputMaybe<Scalars['String']>;
  stockQuantity?: InputMaybe<Scalars['Int']>;
  tags?: InputMaybe<StoreItemTagCreateNestedManyWithoutTaggedStoresInput>;
  tagsClassify?: InputMaybe<StoreItemTagCreateNestedManyWithoutTagClassifiedStoresInput>;
  type: StoreItemType;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  valid?: InputMaybe<Scalars['Boolean']>;
};

export type StoreItemCreateWithoutItemCardInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  favoriteByUsers?: InputMaybe<UserCreateNestedManyWithoutFavoriteStoreItemsInput>;
  id?: InputMaybe<Scalars['String']>;
  inBenefitMemoToCoins?: InputMaybe<BenefitMemoToCoinCreateNestedManyWithoutStoreItemInput>;
  inBenefitMemoToOrders?: InputMaybe<BenefitMemoToOrderCreateNestedManyWithoutStoreItemInput>;
  inStoreToCartItems?: InputMaybe<StoreItemToCartItemCreateNestedManyWithoutStoreItemInput>;
  itemAds?: InputMaybe<AdvertisementCreateNestedOneWithoutInStoreItemInput>;
  itemStamp?: InputMaybe<StampCreateNestedOneWithoutInStoreItemInput>;
  limitedQuantity?: InputMaybe<Scalars['Int']>;
  price: Scalars['Decimal'];
  priceOriginal?: InputMaybe<Scalars['Decimal']>;
  profitable: ProfitableCreateNestedOneWithoutStoreItemsInput;
  readableId?: InputMaybe<Scalars['String']>;
  stockQuantity?: InputMaybe<Scalars['Int']>;
  tags?: InputMaybe<StoreItemTagCreateNestedManyWithoutTaggedStoresInput>;
  tagsClassify?: InputMaybe<StoreItemTagCreateNestedManyWithoutTagClassifiedStoresInput>;
  type: StoreItemType;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  valid?: InputMaybe<Scalars['Boolean']>;
};

export type StoreItemCreateWithoutItemStampInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  favoriteByUsers?: InputMaybe<UserCreateNestedManyWithoutFavoriteStoreItemsInput>;
  id?: InputMaybe<Scalars['String']>;
  inBenefitMemoToCoins?: InputMaybe<BenefitMemoToCoinCreateNestedManyWithoutStoreItemInput>;
  inBenefitMemoToOrders?: InputMaybe<BenefitMemoToOrderCreateNestedManyWithoutStoreItemInput>;
  inStoreToCartItems?: InputMaybe<StoreItemToCartItemCreateNestedManyWithoutStoreItemInput>;
  itemAds?: InputMaybe<AdvertisementCreateNestedOneWithoutInStoreItemInput>;
  itemCard?: InputMaybe<CardCreateNestedOneWithoutInStoreItemInput>;
  limitedQuantity?: InputMaybe<Scalars['Int']>;
  price: Scalars['Decimal'];
  priceOriginal?: InputMaybe<Scalars['Decimal']>;
  profitable: ProfitableCreateNestedOneWithoutStoreItemsInput;
  readableId?: InputMaybe<Scalars['String']>;
  stockQuantity?: InputMaybe<Scalars['Int']>;
  tags?: InputMaybe<StoreItemTagCreateNestedManyWithoutTaggedStoresInput>;
  tagsClassify?: InputMaybe<StoreItemTagCreateNestedManyWithoutTagClassifiedStoresInput>;
  type: StoreItemType;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  valid?: InputMaybe<Scalars['Boolean']>;
};

export type StoreItemCreateWithoutProfitableInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  favoriteByUsers?: InputMaybe<UserCreateNestedManyWithoutFavoriteStoreItemsInput>;
  id?: InputMaybe<Scalars['String']>;
  inBenefitMemoToCoins?: InputMaybe<BenefitMemoToCoinCreateNestedManyWithoutStoreItemInput>;
  inBenefitMemoToOrders?: InputMaybe<BenefitMemoToOrderCreateNestedManyWithoutStoreItemInput>;
  inStoreToCartItems?: InputMaybe<StoreItemToCartItemCreateNestedManyWithoutStoreItemInput>;
  itemAds?: InputMaybe<AdvertisementCreateNestedOneWithoutInStoreItemInput>;
  itemCard?: InputMaybe<CardCreateNestedOneWithoutInStoreItemInput>;
  itemStamp?: InputMaybe<StampCreateNestedOneWithoutInStoreItemInput>;
  limitedQuantity?: InputMaybe<Scalars['Int']>;
  price: Scalars['Decimal'];
  priceOriginal?: InputMaybe<Scalars['Decimal']>;
  readableId?: InputMaybe<Scalars['String']>;
  stockQuantity?: InputMaybe<Scalars['Int']>;
  tags?: InputMaybe<StoreItemTagCreateNestedManyWithoutTaggedStoresInput>;
  tagsClassify?: InputMaybe<StoreItemTagCreateNestedManyWithoutTagClassifiedStoresInput>;
  type: StoreItemType;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  valid?: InputMaybe<Scalars['Boolean']>;
};

export type StoreItemCreateWithoutTagsClassifyInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  favoriteByUsers?: InputMaybe<UserCreateNestedManyWithoutFavoriteStoreItemsInput>;
  id?: InputMaybe<Scalars['String']>;
  inBenefitMemoToCoins?: InputMaybe<BenefitMemoToCoinCreateNestedManyWithoutStoreItemInput>;
  inBenefitMemoToOrders?: InputMaybe<BenefitMemoToOrderCreateNestedManyWithoutStoreItemInput>;
  inStoreToCartItems?: InputMaybe<StoreItemToCartItemCreateNestedManyWithoutStoreItemInput>;
  itemAds?: InputMaybe<AdvertisementCreateNestedOneWithoutInStoreItemInput>;
  itemCard?: InputMaybe<CardCreateNestedOneWithoutInStoreItemInput>;
  itemStamp?: InputMaybe<StampCreateNestedOneWithoutInStoreItemInput>;
  limitedQuantity?: InputMaybe<Scalars['Int']>;
  price: Scalars['Decimal'];
  priceOriginal?: InputMaybe<Scalars['Decimal']>;
  profitable: ProfitableCreateNestedOneWithoutStoreItemsInput;
  readableId?: InputMaybe<Scalars['String']>;
  stockQuantity?: InputMaybe<Scalars['Int']>;
  tags?: InputMaybe<StoreItemTagCreateNestedManyWithoutTaggedStoresInput>;
  type: StoreItemType;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  valid?: InputMaybe<Scalars['Boolean']>;
};

export type StoreItemCreateWithoutTagsInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  favoriteByUsers?: InputMaybe<UserCreateNestedManyWithoutFavoriteStoreItemsInput>;
  id?: InputMaybe<Scalars['String']>;
  inBenefitMemoToCoins?: InputMaybe<BenefitMemoToCoinCreateNestedManyWithoutStoreItemInput>;
  inBenefitMemoToOrders?: InputMaybe<BenefitMemoToOrderCreateNestedManyWithoutStoreItemInput>;
  inStoreToCartItems?: InputMaybe<StoreItemToCartItemCreateNestedManyWithoutStoreItemInput>;
  itemAds?: InputMaybe<AdvertisementCreateNestedOneWithoutInStoreItemInput>;
  itemCard?: InputMaybe<CardCreateNestedOneWithoutInStoreItemInput>;
  itemStamp?: InputMaybe<StampCreateNestedOneWithoutInStoreItemInput>;
  limitedQuantity?: InputMaybe<Scalars['Int']>;
  price: Scalars['Decimal'];
  priceOriginal?: InputMaybe<Scalars['Decimal']>;
  profitable: ProfitableCreateNestedOneWithoutStoreItemsInput;
  readableId?: InputMaybe<Scalars['String']>;
  stockQuantity?: InputMaybe<Scalars['Int']>;
  tagsClassify?: InputMaybe<StoreItemTagCreateNestedManyWithoutTagClassifiedStoresInput>;
  type: StoreItemType;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  valid?: InputMaybe<Scalars['Boolean']>;
};

export type StoreItemGroupBy = {
  __typename?: 'StoreItemGroupBy';
  _avg?: Maybe<StoreItemAvgAggregate>;
  _count?: Maybe<StoreItemCountAggregate>;
  _max?: Maybe<StoreItemMaxAggregate>;
  _min?: Maybe<StoreItemMinAggregate>;
  _sum?: Maybe<StoreItemSumAggregate>;
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  itemAdsId?: Maybe<Scalars['String']>;
  itemCardId?: Maybe<Scalars['String']>;
  itemStampId?: Maybe<Scalars['String']>;
  limitedQuantity?: Maybe<Scalars['Int']>;
  price: Scalars['Decimal'];
  priceOriginal?: Maybe<Scalars['Decimal']>;
  profitableId: Scalars['String'];
  readableId?: Maybe<Scalars['String']>;
  stockQuantity?: Maybe<Scalars['Int']>;
  type: StoreItemType;
  updatedAt: Scalars['DateTime'];
  valid: Scalars['Boolean'];
};

export type StoreItemListRelationFilter = {
  every?: InputMaybe<StoreItemWhereInput>;
  none?: InputMaybe<StoreItemWhereInput>;
  some?: InputMaybe<StoreItemWhereInput>;
};

export type StoreItemMaxAggregate = {
  __typename?: 'StoreItemMaxAggregate';
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  itemAdsId?: Maybe<Scalars['String']>;
  itemCardId?: Maybe<Scalars['String']>;
  itemStampId?: Maybe<Scalars['String']>;
  limitedQuantity?: Maybe<Scalars['Int']>;
  price?: Maybe<Scalars['Decimal']>;
  priceOriginal?: Maybe<Scalars['Decimal']>;
  profitableId?: Maybe<Scalars['String']>;
  readableId?: Maybe<Scalars['String']>;
  stockQuantity?: Maybe<Scalars['Int']>;
  type?: Maybe<StoreItemType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  valid?: Maybe<Scalars['Boolean']>;
};

export type StoreItemMaxOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  itemAdsId?: InputMaybe<SortOrder>;
  itemCardId?: InputMaybe<SortOrder>;
  itemStampId?: InputMaybe<SortOrder>;
  limitedQuantity?: InputMaybe<SortOrder>;
  price?: InputMaybe<SortOrder>;
  priceOriginal?: InputMaybe<SortOrder>;
  profitableId?: InputMaybe<SortOrder>;
  readableId?: InputMaybe<SortOrder>;
  stockQuantity?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  valid?: InputMaybe<SortOrder>;
};

export type StoreItemMinAggregate = {
  __typename?: 'StoreItemMinAggregate';
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  itemAdsId?: Maybe<Scalars['String']>;
  itemCardId?: Maybe<Scalars['String']>;
  itemStampId?: Maybe<Scalars['String']>;
  limitedQuantity?: Maybe<Scalars['Int']>;
  price?: Maybe<Scalars['Decimal']>;
  priceOriginal?: Maybe<Scalars['Decimal']>;
  profitableId?: Maybe<Scalars['String']>;
  readableId?: Maybe<Scalars['String']>;
  stockQuantity?: Maybe<Scalars['Int']>;
  type?: Maybe<StoreItemType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  valid?: Maybe<Scalars['Boolean']>;
};

export type StoreItemMinOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  itemAdsId?: InputMaybe<SortOrder>;
  itemCardId?: InputMaybe<SortOrder>;
  itemStampId?: InputMaybe<SortOrder>;
  limitedQuantity?: InputMaybe<SortOrder>;
  price?: InputMaybe<SortOrder>;
  priceOriginal?: InputMaybe<SortOrder>;
  profitableId?: InputMaybe<SortOrder>;
  readableId?: InputMaybe<SortOrder>;
  stockQuantity?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  valid?: InputMaybe<SortOrder>;
};

export type StoreItemOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type StoreItemOrderByWithAggregationInput = {
  _avg?: InputMaybe<StoreItemAvgOrderByAggregateInput>;
  _count?: InputMaybe<StoreItemCountOrderByAggregateInput>;
  _max?: InputMaybe<StoreItemMaxOrderByAggregateInput>;
  _min?: InputMaybe<StoreItemMinOrderByAggregateInput>;
  _sum?: InputMaybe<StoreItemSumOrderByAggregateInput>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  itemAdsId?: InputMaybe<SortOrder>;
  itemCardId?: InputMaybe<SortOrder>;
  itemStampId?: InputMaybe<SortOrder>;
  limitedQuantity?: InputMaybe<SortOrder>;
  price?: InputMaybe<SortOrder>;
  priceOriginal?: InputMaybe<SortOrder>;
  profitableId?: InputMaybe<SortOrder>;
  readableId?: InputMaybe<SortOrder>;
  stockQuantity?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  valid?: InputMaybe<SortOrder>;
};

export type StoreItemOrderByWithRelationInput = {
  createdAt?: InputMaybe<SortOrder>;
  favoriteByUsers?: InputMaybe<UserOrderByRelationAggregateInput>;
  id?: InputMaybe<SortOrder>;
  inBenefitMemoToCoins?: InputMaybe<BenefitMemoToCoinOrderByRelationAggregateInput>;
  inBenefitMemoToOrders?: InputMaybe<BenefitMemoToOrderOrderByRelationAggregateInput>;
  inStoreToCartItems?: InputMaybe<StoreItemToCartItemOrderByRelationAggregateInput>;
  itemAds?: InputMaybe<AdvertisementOrderByWithRelationInput>;
  itemAdsId?: InputMaybe<SortOrder>;
  itemCard?: InputMaybe<CardOrderByWithRelationInput>;
  itemCardId?: InputMaybe<SortOrder>;
  itemStamp?: InputMaybe<StampOrderByWithRelationInput>;
  itemStampId?: InputMaybe<SortOrder>;
  limitedQuantity?: InputMaybe<SortOrder>;
  price?: InputMaybe<SortOrder>;
  priceOriginal?: InputMaybe<SortOrder>;
  profitable?: InputMaybe<ProfitableOrderByWithRelationInput>;
  profitableId?: InputMaybe<SortOrder>;
  readableId?: InputMaybe<SortOrder>;
  stockQuantity?: InputMaybe<SortOrder>;
  tags?: InputMaybe<StoreItemTagOrderByRelationAggregateInput>;
  tagsClassify?: InputMaybe<StoreItemTagOrderByRelationAggregateInput>;
  type?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  valid?: InputMaybe<SortOrder>;
};

export type StoreItemRelationFilter = {
  is?: InputMaybe<StoreItemWhereInput>;
  isNot?: InputMaybe<StoreItemWhereInput>;
};

export enum StoreItemScalarFieldEnum {
  CreatedAt = 'createdAt',
  Id = 'id',
  ItemAdsId = 'itemAdsId',
  ItemCardId = 'itemCardId',
  ItemStampId = 'itemStampId',
  LimitedQuantity = 'limitedQuantity',
  Price = 'price',
  PriceOriginal = 'priceOriginal',
  ProfitableId = 'profitableId',
  ReadableId = 'readableId',
  StockQuantity = 'stockQuantity',
  Type = 'type',
  UpdatedAt = 'updatedAt',
  Valid = 'valid'
}

export type StoreItemScalarWhereInput = {
  AND?: InputMaybe<Array<StoreItemScalarWhereInput>>;
  NOT?: InputMaybe<Array<StoreItemScalarWhereInput>>;
  OR?: InputMaybe<Array<StoreItemScalarWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  itemAdsId?: InputMaybe<StringNullableFilter>;
  itemCardId?: InputMaybe<StringNullableFilter>;
  itemStampId?: InputMaybe<StringNullableFilter>;
  limitedQuantity?: InputMaybe<IntNullableFilter>;
  price?: InputMaybe<DecimalFilter>;
  priceOriginal?: InputMaybe<DecimalNullableFilter>;
  profitableId?: InputMaybe<StringFilter>;
  readableId?: InputMaybe<StringNullableFilter>;
  stockQuantity?: InputMaybe<IntNullableFilter>;
  type?: InputMaybe<EnumStoreItemTypeFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  valid?: InputMaybe<BoolFilter>;
};

export type StoreItemScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<StoreItemScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<StoreItemScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<StoreItemScalarWhereWithAggregatesInput>>;
  createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  id?: InputMaybe<StringWithAggregatesFilter>;
  itemAdsId?: InputMaybe<StringNullableWithAggregatesFilter>;
  itemCardId?: InputMaybe<StringNullableWithAggregatesFilter>;
  itemStampId?: InputMaybe<StringNullableWithAggregatesFilter>;
  limitedQuantity?: InputMaybe<IntNullableWithAggregatesFilter>;
  price?: InputMaybe<DecimalWithAggregatesFilter>;
  priceOriginal?: InputMaybe<DecimalNullableWithAggregatesFilter>;
  profitableId?: InputMaybe<StringWithAggregatesFilter>;
  readableId?: InputMaybe<StringNullableWithAggregatesFilter>;
  stockQuantity?: InputMaybe<IntNullableWithAggregatesFilter>;
  type?: InputMaybe<EnumStoreItemTypeWithAggregatesFilter>;
  updatedAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  valid?: InputMaybe<BoolWithAggregatesFilter>;
};

export type StoreItemSumAggregate = {
  __typename?: 'StoreItemSumAggregate';
  limitedQuantity?: Maybe<Scalars['Int']>;
  price?: Maybe<Scalars['Decimal']>;
  priceOriginal?: Maybe<Scalars['Decimal']>;
  stockQuantity?: Maybe<Scalars['Int']>;
};

export type StoreItemSumOrderByAggregateInput = {
  limitedQuantity?: InputMaybe<SortOrder>;
  price?: InputMaybe<SortOrder>;
  priceOriginal?: InputMaybe<SortOrder>;
  stockQuantity?: InputMaybe<SortOrder>;
};

export type StoreItemTag = {
  __typename?: 'StoreItemTag';
  _count?: Maybe<StoreItemTagCount>;
  bgColor: Scalars['String'];
  class: StoreItemTagClass;
  createdAt: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  listTopPicUri?: Maybe<Scalars['String']>;
  picUri?: Maybe<Scalars['String']>;
  subBgColor?: Maybe<Scalars['String']>;
  subTitle?: Maybe<Scalars['String']>;
  subTxtColor?: Maybe<Scalars['String']>;
  tagClassifiedStores: Array<StoreItem>;
  taggedStores: Array<StoreItem>;
  title: Scalars['String'];
  txtColor: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  validEnd?: Maybe<Scalars['DateTime']>;
  validStart: Scalars['DateTime'];
};


export type StoreItemTagTagClassifiedStoresArgs = {
  cursor?: InputMaybe<StoreItemWhereUniqueInput>;
  distinct?: InputMaybe<Array<StoreItemScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<StoreItemOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<StoreItemWhereInput>;
};


export type StoreItemTagTaggedStoresArgs = {
  cursor?: InputMaybe<StoreItemWhereUniqueInput>;
  distinct?: InputMaybe<Array<StoreItemScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<StoreItemOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<StoreItemWhereInput>;
};

export enum StoreItemTagClass {
  BestSeller = 'bestSeller',
  Discount = 'discount',
  HotTag = 'hotTag',
  LazyPack = 'lazyPack',
  None = 'none'
}

export type StoreItemTagCount = {
  __typename?: 'StoreItemTagCount';
  tagClassifiedStores: Scalars['Int'];
  taggedStores: Scalars['Int'];
};


export type StoreItemTagCountTagClassifiedStoresArgs = {
  where?: InputMaybe<StoreItemWhereInput>;
};


export type StoreItemTagCountTaggedStoresArgs = {
  where?: InputMaybe<StoreItemWhereInput>;
};

export type StoreItemTagCountAggregate = {
  __typename?: 'StoreItemTagCountAggregate';
  _all: Scalars['Int'];
  bgColor: Scalars['Int'];
  class: Scalars['Int'];
  createdAt: Scalars['Int'];
  description: Scalars['Int'];
  id: Scalars['Int'];
  listTopPicUri: Scalars['Int'];
  picUri: Scalars['Int'];
  subBgColor: Scalars['Int'];
  subTitle: Scalars['Int'];
  subTxtColor: Scalars['Int'];
  title: Scalars['Int'];
  txtColor: Scalars['Int'];
  updatedAt: Scalars['Int'];
  validEnd: Scalars['Int'];
  validStart: Scalars['Int'];
};

export type StoreItemTagCountOrderByAggregateInput = {
  bgColor?: InputMaybe<SortOrder>;
  class?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  listTopPicUri?: InputMaybe<SortOrder>;
  picUri?: InputMaybe<SortOrder>;
  subBgColor?: InputMaybe<SortOrder>;
  subTitle?: InputMaybe<SortOrder>;
  subTxtColor?: InputMaybe<SortOrder>;
  title?: InputMaybe<SortOrder>;
  txtColor?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  validEnd?: InputMaybe<SortOrder>;
  validStart?: InputMaybe<SortOrder>;
};

export type StoreItemTagCreateInput = {
  bgColor?: InputMaybe<Scalars['String']>;
  class?: InputMaybe<StoreItemTagClass>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  listTopPicUri?: InputMaybe<Scalars['String']>;
  picUri?: InputMaybe<Scalars['String']>;
  subBgColor?: InputMaybe<Scalars['String']>;
  subTitle?: InputMaybe<Scalars['String']>;
  subTxtColor?: InputMaybe<Scalars['String']>;
  tagClassifiedStores?: InputMaybe<StoreItemCreateNestedManyWithoutTagsClassifyInput>;
  taggedStores?: InputMaybe<StoreItemCreateNestedManyWithoutTagsInput>;
  title: Scalars['String'];
  txtColor?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  validEnd?: InputMaybe<Scalars['DateTime']>;
  validStart?: InputMaybe<Scalars['DateTime']>;
};

export type StoreItemTagCreateManyInput = {
  bgColor?: InputMaybe<Scalars['String']>;
  class?: InputMaybe<StoreItemTagClass>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  listTopPicUri?: InputMaybe<Scalars['String']>;
  picUri?: InputMaybe<Scalars['String']>;
  subBgColor?: InputMaybe<Scalars['String']>;
  subTitle?: InputMaybe<Scalars['String']>;
  subTxtColor?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
  txtColor?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  validEnd?: InputMaybe<Scalars['DateTime']>;
  validStart?: InputMaybe<Scalars['DateTime']>;
};

export type StoreItemTagCreateNestedManyWithoutTagClassifiedStoresInput = {
  connect?: InputMaybe<Array<StoreItemTagWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<StoreItemTagCreateOrConnectWithoutTagClassifiedStoresInput>>;
  create?: InputMaybe<Array<StoreItemTagCreateWithoutTagClassifiedStoresInput>>;
};

export type StoreItemTagCreateNestedManyWithoutTaggedStoresInput = {
  connect?: InputMaybe<Array<StoreItemTagWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<StoreItemTagCreateOrConnectWithoutTaggedStoresInput>>;
  create?: InputMaybe<Array<StoreItemTagCreateWithoutTaggedStoresInput>>;
};

export type StoreItemTagCreateOrConnectWithoutTagClassifiedStoresInput = {
  create: StoreItemTagCreateWithoutTagClassifiedStoresInput;
  where: StoreItemTagWhereUniqueInput;
};

export type StoreItemTagCreateOrConnectWithoutTaggedStoresInput = {
  create: StoreItemTagCreateWithoutTaggedStoresInput;
  where: StoreItemTagWhereUniqueInput;
};

export type StoreItemTagCreateWithoutTagClassifiedStoresInput = {
  bgColor?: InputMaybe<Scalars['String']>;
  class?: InputMaybe<StoreItemTagClass>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  listTopPicUri?: InputMaybe<Scalars['String']>;
  picUri?: InputMaybe<Scalars['String']>;
  subBgColor?: InputMaybe<Scalars['String']>;
  subTitle?: InputMaybe<Scalars['String']>;
  subTxtColor?: InputMaybe<Scalars['String']>;
  taggedStores?: InputMaybe<StoreItemCreateNestedManyWithoutTagsInput>;
  title: Scalars['String'];
  txtColor?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  validEnd?: InputMaybe<Scalars['DateTime']>;
  validStart?: InputMaybe<Scalars['DateTime']>;
};

export type StoreItemTagCreateWithoutTaggedStoresInput = {
  bgColor?: InputMaybe<Scalars['String']>;
  class?: InputMaybe<StoreItemTagClass>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  listTopPicUri?: InputMaybe<Scalars['String']>;
  picUri?: InputMaybe<Scalars['String']>;
  subBgColor?: InputMaybe<Scalars['String']>;
  subTitle?: InputMaybe<Scalars['String']>;
  subTxtColor?: InputMaybe<Scalars['String']>;
  tagClassifiedStores?: InputMaybe<StoreItemCreateNestedManyWithoutTagsClassifyInput>;
  title: Scalars['String'];
  txtColor?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  validEnd?: InputMaybe<Scalars['DateTime']>;
  validStart?: InputMaybe<Scalars['DateTime']>;
};

export type StoreItemTagGroupBy = {
  __typename?: 'StoreItemTagGroupBy';
  _count?: Maybe<StoreItemTagCountAggregate>;
  _max?: Maybe<StoreItemTagMaxAggregate>;
  _min?: Maybe<StoreItemTagMinAggregate>;
  bgColor: Scalars['String'];
  class: StoreItemTagClass;
  createdAt: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  listTopPicUri?: Maybe<Scalars['String']>;
  picUri?: Maybe<Scalars['String']>;
  subBgColor?: Maybe<Scalars['String']>;
  subTitle?: Maybe<Scalars['String']>;
  subTxtColor?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  txtColor: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  validEnd?: Maybe<Scalars['DateTime']>;
  validStart: Scalars['DateTime'];
};

export type StoreItemTagListRelationFilter = {
  every?: InputMaybe<StoreItemTagWhereInput>;
  none?: InputMaybe<StoreItemTagWhereInput>;
  some?: InputMaybe<StoreItemTagWhereInput>;
};

export type StoreItemTagMaxAggregate = {
  __typename?: 'StoreItemTagMaxAggregate';
  bgColor?: Maybe<Scalars['String']>;
  class?: Maybe<StoreItemTagClass>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  listTopPicUri?: Maybe<Scalars['String']>;
  picUri?: Maybe<Scalars['String']>;
  subBgColor?: Maybe<Scalars['String']>;
  subTitle?: Maybe<Scalars['String']>;
  subTxtColor?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  txtColor?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  validEnd?: Maybe<Scalars['DateTime']>;
  validStart?: Maybe<Scalars['DateTime']>;
};

export type StoreItemTagMaxOrderByAggregateInput = {
  bgColor?: InputMaybe<SortOrder>;
  class?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  listTopPicUri?: InputMaybe<SortOrder>;
  picUri?: InputMaybe<SortOrder>;
  subBgColor?: InputMaybe<SortOrder>;
  subTitle?: InputMaybe<SortOrder>;
  subTxtColor?: InputMaybe<SortOrder>;
  title?: InputMaybe<SortOrder>;
  txtColor?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  validEnd?: InputMaybe<SortOrder>;
  validStart?: InputMaybe<SortOrder>;
};

export type StoreItemTagMinAggregate = {
  __typename?: 'StoreItemTagMinAggregate';
  bgColor?: Maybe<Scalars['String']>;
  class?: Maybe<StoreItemTagClass>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  listTopPicUri?: Maybe<Scalars['String']>;
  picUri?: Maybe<Scalars['String']>;
  subBgColor?: Maybe<Scalars['String']>;
  subTitle?: Maybe<Scalars['String']>;
  subTxtColor?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  txtColor?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  validEnd?: Maybe<Scalars['DateTime']>;
  validStart?: Maybe<Scalars['DateTime']>;
};

export type StoreItemTagMinOrderByAggregateInput = {
  bgColor?: InputMaybe<SortOrder>;
  class?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  listTopPicUri?: InputMaybe<SortOrder>;
  picUri?: InputMaybe<SortOrder>;
  subBgColor?: InputMaybe<SortOrder>;
  subTitle?: InputMaybe<SortOrder>;
  subTxtColor?: InputMaybe<SortOrder>;
  title?: InputMaybe<SortOrder>;
  txtColor?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  validEnd?: InputMaybe<SortOrder>;
  validStart?: InputMaybe<SortOrder>;
};

export type StoreItemTagOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type StoreItemTagOrderByWithAggregationInput = {
  _count?: InputMaybe<StoreItemTagCountOrderByAggregateInput>;
  _max?: InputMaybe<StoreItemTagMaxOrderByAggregateInput>;
  _min?: InputMaybe<StoreItemTagMinOrderByAggregateInput>;
  bgColor?: InputMaybe<SortOrder>;
  class?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  listTopPicUri?: InputMaybe<SortOrder>;
  picUri?: InputMaybe<SortOrder>;
  subBgColor?: InputMaybe<SortOrder>;
  subTitle?: InputMaybe<SortOrder>;
  subTxtColor?: InputMaybe<SortOrder>;
  title?: InputMaybe<SortOrder>;
  txtColor?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  validEnd?: InputMaybe<SortOrder>;
  validStart?: InputMaybe<SortOrder>;
};

export type StoreItemTagOrderByWithRelationInput = {
  bgColor?: InputMaybe<SortOrder>;
  class?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  listTopPicUri?: InputMaybe<SortOrder>;
  picUri?: InputMaybe<SortOrder>;
  subBgColor?: InputMaybe<SortOrder>;
  subTitle?: InputMaybe<SortOrder>;
  subTxtColor?: InputMaybe<SortOrder>;
  tagClassifiedStores?: InputMaybe<StoreItemOrderByRelationAggregateInput>;
  taggedStores?: InputMaybe<StoreItemOrderByRelationAggregateInput>;
  title?: InputMaybe<SortOrder>;
  txtColor?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  validEnd?: InputMaybe<SortOrder>;
  validStart?: InputMaybe<SortOrder>;
};

export enum StoreItemTagScalarFieldEnum {
  BgColor = 'bgColor',
  Class = 'class',
  CreatedAt = 'createdAt',
  Description = 'description',
  Id = 'id',
  ListTopPicUri = 'listTopPicUri',
  PicUri = 'picUri',
  SubBgColor = 'subBgColor',
  SubTitle = 'subTitle',
  SubTxtColor = 'subTxtColor',
  Title = 'title',
  TxtColor = 'txtColor',
  UpdatedAt = 'updatedAt',
  ValidEnd = 'validEnd',
  ValidStart = 'validStart'
}

export type StoreItemTagScalarWhereInput = {
  AND?: InputMaybe<Array<StoreItemTagScalarWhereInput>>;
  NOT?: InputMaybe<Array<StoreItemTagScalarWhereInput>>;
  OR?: InputMaybe<Array<StoreItemTagScalarWhereInput>>;
  bgColor?: InputMaybe<StringFilter>;
  class?: InputMaybe<EnumStoreItemTagClassFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  description?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<StringFilter>;
  listTopPicUri?: InputMaybe<StringNullableFilter>;
  picUri?: InputMaybe<StringNullableFilter>;
  subBgColor?: InputMaybe<StringNullableFilter>;
  subTitle?: InputMaybe<StringNullableFilter>;
  subTxtColor?: InputMaybe<StringNullableFilter>;
  title?: InputMaybe<StringFilter>;
  txtColor?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  validEnd?: InputMaybe<DateTimeNullableFilter>;
  validStart?: InputMaybe<DateTimeFilter>;
};

export type StoreItemTagScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<StoreItemTagScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<StoreItemTagScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<StoreItemTagScalarWhereWithAggregatesInput>>;
  bgColor?: InputMaybe<StringWithAggregatesFilter>;
  class?: InputMaybe<EnumStoreItemTagClassWithAggregatesFilter>;
  createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  description?: InputMaybe<StringNullableWithAggregatesFilter>;
  id?: InputMaybe<StringWithAggregatesFilter>;
  listTopPicUri?: InputMaybe<StringNullableWithAggregatesFilter>;
  picUri?: InputMaybe<StringNullableWithAggregatesFilter>;
  subBgColor?: InputMaybe<StringNullableWithAggregatesFilter>;
  subTitle?: InputMaybe<StringNullableWithAggregatesFilter>;
  subTxtColor?: InputMaybe<StringNullableWithAggregatesFilter>;
  title?: InputMaybe<StringWithAggregatesFilter>;
  txtColor?: InputMaybe<StringWithAggregatesFilter>;
  updatedAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  validEnd?: InputMaybe<DateTimeNullableWithAggregatesFilter>;
  validStart?: InputMaybe<DateTimeWithAggregatesFilter>;
};

export type StoreItemTagUpdateInput = {
  bgColor?: InputMaybe<StringFieldUpdateOperationsInput>;
  class?: InputMaybe<EnumStoreItemTagClassFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  listTopPicUri?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  picUri?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  subBgColor?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  subTitle?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  subTxtColor?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  tagClassifiedStores?: InputMaybe<StoreItemUpdateManyWithoutTagsClassifyNestedInput>;
  taggedStores?: InputMaybe<StoreItemUpdateManyWithoutTagsNestedInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
  txtColor?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  validEnd?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  validStart?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type StoreItemTagUpdateManyMutationInput = {
  bgColor?: InputMaybe<StringFieldUpdateOperationsInput>;
  class?: InputMaybe<EnumStoreItemTagClassFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  listTopPicUri?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  picUri?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  subBgColor?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  subTitle?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  subTxtColor?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
  txtColor?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  validEnd?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  validStart?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type StoreItemTagUpdateManyWithWhereWithoutTagClassifiedStoresInput = {
  data: StoreItemTagUpdateManyMutationInput;
  where: StoreItemTagScalarWhereInput;
};

export type StoreItemTagUpdateManyWithWhereWithoutTaggedStoresInput = {
  data: StoreItemTagUpdateManyMutationInput;
  where: StoreItemTagScalarWhereInput;
};

export type StoreItemTagUpdateManyWithoutTagClassifiedStoresNestedInput = {
  connect?: InputMaybe<Array<StoreItemTagWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<StoreItemTagCreateOrConnectWithoutTagClassifiedStoresInput>>;
  create?: InputMaybe<Array<StoreItemTagCreateWithoutTagClassifiedStoresInput>>;
  delete?: InputMaybe<Array<StoreItemTagWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<StoreItemTagScalarWhereInput>>;
  disconnect?: InputMaybe<Array<StoreItemTagWhereUniqueInput>>;
  set?: InputMaybe<Array<StoreItemTagWhereUniqueInput>>;
  update?: InputMaybe<Array<StoreItemTagUpdateWithWhereUniqueWithoutTagClassifiedStoresInput>>;
  updateMany?: InputMaybe<Array<StoreItemTagUpdateManyWithWhereWithoutTagClassifiedStoresInput>>;
  upsert?: InputMaybe<Array<StoreItemTagUpsertWithWhereUniqueWithoutTagClassifiedStoresInput>>;
};

export type StoreItemTagUpdateManyWithoutTaggedStoresNestedInput = {
  connect?: InputMaybe<Array<StoreItemTagWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<StoreItemTagCreateOrConnectWithoutTaggedStoresInput>>;
  create?: InputMaybe<Array<StoreItemTagCreateWithoutTaggedStoresInput>>;
  delete?: InputMaybe<Array<StoreItemTagWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<StoreItemTagScalarWhereInput>>;
  disconnect?: InputMaybe<Array<StoreItemTagWhereUniqueInput>>;
  set?: InputMaybe<Array<StoreItemTagWhereUniqueInput>>;
  update?: InputMaybe<Array<StoreItemTagUpdateWithWhereUniqueWithoutTaggedStoresInput>>;
  updateMany?: InputMaybe<Array<StoreItemTagUpdateManyWithWhereWithoutTaggedStoresInput>>;
  upsert?: InputMaybe<Array<StoreItemTagUpsertWithWhereUniqueWithoutTaggedStoresInput>>;
};

export type StoreItemTagUpdateWithWhereUniqueWithoutTagClassifiedStoresInput = {
  data: StoreItemTagUpdateWithoutTagClassifiedStoresInput;
  where: StoreItemTagWhereUniqueInput;
};

export type StoreItemTagUpdateWithWhereUniqueWithoutTaggedStoresInput = {
  data: StoreItemTagUpdateWithoutTaggedStoresInput;
  where: StoreItemTagWhereUniqueInput;
};

export type StoreItemTagUpdateWithoutTagClassifiedStoresInput = {
  bgColor?: InputMaybe<StringFieldUpdateOperationsInput>;
  class?: InputMaybe<EnumStoreItemTagClassFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  listTopPicUri?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  picUri?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  subBgColor?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  subTitle?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  subTxtColor?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  taggedStores?: InputMaybe<StoreItemUpdateManyWithoutTagsNestedInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
  txtColor?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  validEnd?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  validStart?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type StoreItemTagUpdateWithoutTaggedStoresInput = {
  bgColor?: InputMaybe<StringFieldUpdateOperationsInput>;
  class?: InputMaybe<EnumStoreItemTagClassFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  listTopPicUri?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  picUri?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  subBgColor?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  subTitle?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  subTxtColor?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  tagClassifiedStores?: InputMaybe<StoreItemUpdateManyWithoutTagsClassifyNestedInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
  txtColor?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  validEnd?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  validStart?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type StoreItemTagUpsertWithWhereUniqueWithoutTagClassifiedStoresInput = {
  create: StoreItemTagCreateWithoutTagClassifiedStoresInput;
  update: StoreItemTagUpdateWithoutTagClassifiedStoresInput;
  where: StoreItemTagWhereUniqueInput;
};

export type StoreItemTagUpsertWithWhereUniqueWithoutTaggedStoresInput = {
  create: StoreItemTagCreateWithoutTaggedStoresInput;
  update: StoreItemTagUpdateWithoutTaggedStoresInput;
  where: StoreItemTagWhereUniqueInput;
};

export type StoreItemTagWhereInput = {
  AND?: InputMaybe<Array<StoreItemTagWhereInput>>;
  NOT?: InputMaybe<Array<StoreItemTagWhereInput>>;
  OR?: InputMaybe<Array<StoreItemTagWhereInput>>;
  bgColor?: InputMaybe<StringFilter>;
  class?: InputMaybe<EnumStoreItemTagClassFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  description?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<StringFilter>;
  listTopPicUri?: InputMaybe<StringNullableFilter>;
  picUri?: InputMaybe<StringNullableFilter>;
  subBgColor?: InputMaybe<StringNullableFilter>;
  subTitle?: InputMaybe<StringNullableFilter>;
  subTxtColor?: InputMaybe<StringNullableFilter>;
  tagClassifiedStores?: InputMaybe<StoreItemListRelationFilter>;
  taggedStores?: InputMaybe<StoreItemListRelationFilter>;
  title?: InputMaybe<StringFilter>;
  txtColor?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  validEnd?: InputMaybe<DateTimeNullableFilter>;
  validStart?: InputMaybe<DateTimeFilter>;
};

export type StoreItemTagWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
};

export type StoreItemToCartItem = {
  __typename?: 'StoreItemToCartItem';
  cartItem: CartItem;
  cartItemId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  storeItem: StoreItem;
  storeItemId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type StoreItemToCartItemCountAggregate = {
  __typename?: 'StoreItemToCartItemCountAggregate';
  _all: Scalars['Int'];
  cartItemId: Scalars['Int'];
  createdAt: Scalars['Int'];
  storeItemId: Scalars['Int'];
  updatedAt: Scalars['Int'];
};

export type StoreItemToCartItemCountOrderByAggregateInput = {
  cartItemId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  storeItemId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type StoreItemToCartItemCreateInput = {
  cartItem: CartItemCreateNestedOneWithoutStoreToCartItemsInput;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  storeItem: StoreItemCreateNestedOneWithoutInStoreToCartItemsInput;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type StoreItemToCartItemCreateManyCartItemInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  storeItemId: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type StoreItemToCartItemCreateManyCartItemInputEnvelope = {
  data: Array<StoreItemToCartItemCreateManyCartItemInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type StoreItemToCartItemCreateManyInput = {
  cartItemId: Scalars['String'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  storeItemId: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type StoreItemToCartItemCreateManyStoreItemInput = {
  cartItemId: Scalars['String'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type StoreItemToCartItemCreateManyStoreItemInputEnvelope = {
  data: Array<StoreItemToCartItemCreateManyStoreItemInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type StoreItemToCartItemCreateNestedManyWithoutCartItemInput = {
  connect?: InputMaybe<Array<StoreItemToCartItemWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<StoreItemToCartItemCreateOrConnectWithoutCartItemInput>>;
  create?: InputMaybe<Array<StoreItemToCartItemCreateWithoutCartItemInput>>;
  createMany?: InputMaybe<StoreItemToCartItemCreateManyCartItemInputEnvelope>;
};

export type StoreItemToCartItemCreateNestedManyWithoutStoreItemInput = {
  connect?: InputMaybe<Array<StoreItemToCartItemWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<StoreItemToCartItemCreateOrConnectWithoutStoreItemInput>>;
  create?: InputMaybe<Array<StoreItemToCartItemCreateWithoutStoreItemInput>>;
  createMany?: InputMaybe<StoreItemToCartItemCreateManyStoreItemInputEnvelope>;
};

export type StoreItemToCartItemCreateOrConnectWithoutCartItemInput = {
  create: StoreItemToCartItemCreateWithoutCartItemInput;
  where: StoreItemToCartItemWhereUniqueInput;
};

export type StoreItemToCartItemCreateOrConnectWithoutStoreItemInput = {
  create: StoreItemToCartItemCreateWithoutStoreItemInput;
  where: StoreItemToCartItemWhereUniqueInput;
};

export type StoreItemToCartItemCreateWithoutCartItemInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  storeItem: StoreItemCreateNestedOneWithoutInStoreToCartItemsInput;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type StoreItemToCartItemCreateWithoutStoreItemInput = {
  cartItem: CartItemCreateNestedOneWithoutStoreToCartItemsInput;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type StoreItemToCartItemGroupBy = {
  __typename?: 'StoreItemToCartItemGroupBy';
  _count?: Maybe<StoreItemToCartItemCountAggregate>;
  _max?: Maybe<StoreItemToCartItemMaxAggregate>;
  _min?: Maybe<StoreItemToCartItemMinAggregate>;
  cartItemId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  storeItemId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type StoreItemToCartItemListRelationFilter = {
  every?: InputMaybe<StoreItemToCartItemWhereInput>;
  none?: InputMaybe<StoreItemToCartItemWhereInput>;
  some?: InputMaybe<StoreItemToCartItemWhereInput>;
};

export type StoreItemToCartItemMaxAggregate = {
  __typename?: 'StoreItemToCartItemMaxAggregate';
  cartItemId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  storeItemId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StoreItemToCartItemMaxOrderByAggregateInput = {
  cartItemId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  storeItemId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type StoreItemToCartItemMinAggregate = {
  __typename?: 'StoreItemToCartItemMinAggregate';
  cartItemId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  storeItemId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StoreItemToCartItemMinOrderByAggregateInput = {
  cartItemId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  storeItemId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type StoreItemToCartItemOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type StoreItemToCartItemOrderByWithAggregationInput = {
  _count?: InputMaybe<StoreItemToCartItemCountOrderByAggregateInput>;
  _max?: InputMaybe<StoreItemToCartItemMaxOrderByAggregateInput>;
  _min?: InputMaybe<StoreItemToCartItemMinOrderByAggregateInput>;
  cartItemId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  storeItemId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type StoreItemToCartItemOrderByWithRelationInput = {
  cartItem?: InputMaybe<CartItemOrderByWithRelationInput>;
  cartItemId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  storeItem?: InputMaybe<StoreItemOrderByWithRelationInput>;
  storeItemId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export enum StoreItemToCartItemScalarFieldEnum {
  CartItemId = 'cartItemId',
  CreatedAt = 'createdAt',
  StoreItemId = 'storeItemId',
  UpdatedAt = 'updatedAt'
}

export type StoreItemToCartItemScalarWhereInput = {
  AND?: InputMaybe<Array<StoreItemToCartItemScalarWhereInput>>;
  NOT?: InputMaybe<Array<StoreItemToCartItemScalarWhereInput>>;
  OR?: InputMaybe<Array<StoreItemToCartItemScalarWhereInput>>;
  cartItemId?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  storeItemId?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type StoreItemToCartItemScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<StoreItemToCartItemScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<StoreItemToCartItemScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<StoreItemToCartItemScalarWhereWithAggregatesInput>>;
  cartItemId?: InputMaybe<StringWithAggregatesFilter>;
  createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  storeItemId?: InputMaybe<StringWithAggregatesFilter>;
  updatedAt?: InputMaybe<DateTimeWithAggregatesFilter>;
};

export type StoreItemToCartItemStoreItemIdCartItemIdCompoundUniqueInput = {
  cartItemId: Scalars['String'];
  storeItemId: Scalars['String'];
};

export type StoreItemToCartItemUpdateInput = {
  cartItem?: InputMaybe<CartItemUpdateOneRequiredWithoutStoreToCartItemsNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  storeItem?: InputMaybe<StoreItemUpdateOneRequiredWithoutInStoreToCartItemsNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type StoreItemToCartItemUpdateManyMutationInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type StoreItemToCartItemUpdateManyWithWhereWithoutCartItemInput = {
  data: StoreItemToCartItemUpdateManyMutationInput;
  where: StoreItemToCartItemScalarWhereInput;
};

export type StoreItemToCartItemUpdateManyWithWhereWithoutStoreItemInput = {
  data: StoreItemToCartItemUpdateManyMutationInput;
  where: StoreItemToCartItemScalarWhereInput;
};

export type StoreItemToCartItemUpdateManyWithoutCartItemNestedInput = {
  connect?: InputMaybe<Array<StoreItemToCartItemWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<StoreItemToCartItemCreateOrConnectWithoutCartItemInput>>;
  create?: InputMaybe<Array<StoreItemToCartItemCreateWithoutCartItemInput>>;
  createMany?: InputMaybe<StoreItemToCartItemCreateManyCartItemInputEnvelope>;
  delete?: InputMaybe<Array<StoreItemToCartItemWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<StoreItemToCartItemScalarWhereInput>>;
  disconnect?: InputMaybe<Array<StoreItemToCartItemWhereUniqueInput>>;
  set?: InputMaybe<Array<StoreItemToCartItemWhereUniqueInput>>;
  update?: InputMaybe<Array<StoreItemToCartItemUpdateWithWhereUniqueWithoutCartItemInput>>;
  updateMany?: InputMaybe<Array<StoreItemToCartItemUpdateManyWithWhereWithoutCartItemInput>>;
  upsert?: InputMaybe<Array<StoreItemToCartItemUpsertWithWhereUniqueWithoutCartItemInput>>;
};

export type StoreItemToCartItemUpdateManyWithoutStoreItemNestedInput = {
  connect?: InputMaybe<Array<StoreItemToCartItemWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<StoreItemToCartItemCreateOrConnectWithoutStoreItemInput>>;
  create?: InputMaybe<Array<StoreItemToCartItemCreateWithoutStoreItemInput>>;
  createMany?: InputMaybe<StoreItemToCartItemCreateManyStoreItemInputEnvelope>;
  delete?: InputMaybe<Array<StoreItemToCartItemWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<StoreItemToCartItemScalarWhereInput>>;
  disconnect?: InputMaybe<Array<StoreItemToCartItemWhereUniqueInput>>;
  set?: InputMaybe<Array<StoreItemToCartItemWhereUniqueInput>>;
  update?: InputMaybe<Array<StoreItemToCartItemUpdateWithWhereUniqueWithoutStoreItemInput>>;
  updateMany?: InputMaybe<Array<StoreItemToCartItemUpdateManyWithWhereWithoutStoreItemInput>>;
  upsert?: InputMaybe<Array<StoreItemToCartItemUpsertWithWhereUniqueWithoutStoreItemInput>>;
};

export type StoreItemToCartItemUpdateWithWhereUniqueWithoutCartItemInput = {
  data: StoreItemToCartItemUpdateWithoutCartItemInput;
  where: StoreItemToCartItemWhereUniqueInput;
};

export type StoreItemToCartItemUpdateWithWhereUniqueWithoutStoreItemInput = {
  data: StoreItemToCartItemUpdateWithoutStoreItemInput;
  where: StoreItemToCartItemWhereUniqueInput;
};

export type StoreItemToCartItemUpdateWithoutCartItemInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  storeItem?: InputMaybe<StoreItemUpdateOneRequiredWithoutInStoreToCartItemsNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type StoreItemToCartItemUpdateWithoutStoreItemInput = {
  cartItem?: InputMaybe<CartItemUpdateOneRequiredWithoutStoreToCartItemsNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type StoreItemToCartItemUpsertWithWhereUniqueWithoutCartItemInput = {
  create: StoreItemToCartItemCreateWithoutCartItemInput;
  update: StoreItemToCartItemUpdateWithoutCartItemInput;
  where: StoreItemToCartItemWhereUniqueInput;
};

export type StoreItemToCartItemUpsertWithWhereUniqueWithoutStoreItemInput = {
  create: StoreItemToCartItemCreateWithoutStoreItemInput;
  update: StoreItemToCartItemUpdateWithoutStoreItemInput;
  where: StoreItemToCartItemWhereUniqueInput;
};

export type StoreItemToCartItemWhereInput = {
  AND?: InputMaybe<Array<StoreItemToCartItemWhereInput>>;
  NOT?: InputMaybe<Array<StoreItemToCartItemWhereInput>>;
  OR?: InputMaybe<Array<StoreItemToCartItemWhereInput>>;
  cartItem?: InputMaybe<CartItemRelationFilter>;
  cartItemId?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  storeItem?: InputMaybe<StoreItemRelationFilter>;
  storeItemId?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type StoreItemToCartItemWhereUniqueInput = {
  storeItemId_cartItemId?: InputMaybe<StoreItemToCartItemStoreItemIdCartItemIdCompoundUniqueInput>;
};

export enum StoreItemType {
  Ad = 'ad',
  Postcard = 'postcard',
  Stamp = 'stamp'
}

export type StoreItemUpdateInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  favoriteByUsers?: InputMaybe<UserUpdateManyWithoutFavoriteStoreItemsNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  inBenefitMemoToCoins?: InputMaybe<BenefitMemoToCoinUpdateManyWithoutStoreItemNestedInput>;
  inBenefitMemoToOrders?: InputMaybe<BenefitMemoToOrderUpdateManyWithoutStoreItemNestedInput>;
  inStoreToCartItems?: InputMaybe<StoreItemToCartItemUpdateManyWithoutStoreItemNestedInput>;
  itemAds?: InputMaybe<AdvertisementUpdateOneWithoutInStoreItemNestedInput>;
  itemCard?: InputMaybe<CardUpdateOneWithoutInStoreItemNestedInput>;
  itemStamp?: InputMaybe<StampUpdateOneWithoutInStoreItemNestedInput>;
  limitedQuantity?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  price?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  priceOriginal?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
  profitable?: InputMaybe<ProfitableUpdateOneRequiredWithoutStoreItemsNestedInput>;
  readableId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  stockQuantity?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  tags?: InputMaybe<StoreItemTagUpdateManyWithoutTaggedStoresNestedInput>;
  tagsClassify?: InputMaybe<StoreItemTagUpdateManyWithoutTagClassifiedStoresNestedInput>;
  type?: InputMaybe<EnumStoreItemTypeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  valid?: InputMaybe<BoolFieldUpdateOperationsInput>;
};

export type StoreItemUpdateManyMutationInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  limitedQuantity?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  price?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  priceOriginal?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
  readableId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  stockQuantity?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  type?: InputMaybe<EnumStoreItemTypeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  valid?: InputMaybe<BoolFieldUpdateOperationsInput>;
};

export type StoreItemUpdateManyWithWhereWithoutFavoriteByUsersInput = {
  data: StoreItemUpdateManyMutationInput;
  where: StoreItemScalarWhereInput;
};

export type StoreItemUpdateManyWithWhereWithoutItemAdsInput = {
  data: StoreItemUpdateManyMutationInput;
  where: StoreItemScalarWhereInput;
};

export type StoreItemUpdateManyWithWhereWithoutItemCardInput = {
  data: StoreItemUpdateManyMutationInput;
  where: StoreItemScalarWhereInput;
};

export type StoreItemUpdateManyWithWhereWithoutItemStampInput = {
  data: StoreItemUpdateManyMutationInput;
  where: StoreItemScalarWhereInput;
};

export type StoreItemUpdateManyWithWhereWithoutProfitableInput = {
  data: StoreItemUpdateManyMutationInput;
  where: StoreItemScalarWhereInput;
};

export type StoreItemUpdateManyWithWhereWithoutTagsClassifyInput = {
  data: StoreItemUpdateManyMutationInput;
  where: StoreItemScalarWhereInput;
};

export type StoreItemUpdateManyWithWhereWithoutTagsInput = {
  data: StoreItemUpdateManyMutationInput;
  where: StoreItemScalarWhereInput;
};

export type StoreItemUpdateManyWithoutFavoriteByUsersNestedInput = {
  connect?: InputMaybe<Array<StoreItemWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<StoreItemCreateOrConnectWithoutFavoriteByUsersInput>>;
  create?: InputMaybe<Array<StoreItemCreateWithoutFavoriteByUsersInput>>;
  delete?: InputMaybe<Array<StoreItemWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<StoreItemScalarWhereInput>>;
  disconnect?: InputMaybe<Array<StoreItemWhereUniqueInput>>;
  set?: InputMaybe<Array<StoreItemWhereUniqueInput>>;
  update?: InputMaybe<Array<StoreItemUpdateWithWhereUniqueWithoutFavoriteByUsersInput>>;
  updateMany?: InputMaybe<Array<StoreItemUpdateManyWithWhereWithoutFavoriteByUsersInput>>;
  upsert?: InputMaybe<Array<StoreItemUpsertWithWhereUniqueWithoutFavoriteByUsersInput>>;
};

export type StoreItemUpdateManyWithoutItemAdsNestedInput = {
  connect?: InputMaybe<Array<StoreItemWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<StoreItemCreateOrConnectWithoutItemAdsInput>>;
  create?: InputMaybe<Array<StoreItemCreateWithoutItemAdsInput>>;
  createMany?: InputMaybe<StoreItemCreateManyItemAdsInputEnvelope>;
  delete?: InputMaybe<Array<StoreItemWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<StoreItemScalarWhereInput>>;
  disconnect?: InputMaybe<Array<StoreItemWhereUniqueInput>>;
  set?: InputMaybe<Array<StoreItemWhereUniqueInput>>;
  update?: InputMaybe<Array<StoreItemUpdateWithWhereUniqueWithoutItemAdsInput>>;
  updateMany?: InputMaybe<Array<StoreItemUpdateManyWithWhereWithoutItemAdsInput>>;
  upsert?: InputMaybe<Array<StoreItemUpsertWithWhereUniqueWithoutItemAdsInput>>;
};

export type StoreItemUpdateManyWithoutItemCardNestedInput = {
  connect?: InputMaybe<Array<StoreItemWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<StoreItemCreateOrConnectWithoutItemCardInput>>;
  create?: InputMaybe<Array<StoreItemCreateWithoutItemCardInput>>;
  createMany?: InputMaybe<StoreItemCreateManyItemCardInputEnvelope>;
  delete?: InputMaybe<Array<StoreItemWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<StoreItemScalarWhereInput>>;
  disconnect?: InputMaybe<Array<StoreItemWhereUniqueInput>>;
  set?: InputMaybe<Array<StoreItemWhereUniqueInput>>;
  update?: InputMaybe<Array<StoreItemUpdateWithWhereUniqueWithoutItemCardInput>>;
  updateMany?: InputMaybe<Array<StoreItemUpdateManyWithWhereWithoutItemCardInput>>;
  upsert?: InputMaybe<Array<StoreItemUpsertWithWhereUniqueWithoutItemCardInput>>;
};

export type StoreItemUpdateManyWithoutItemStampNestedInput = {
  connect?: InputMaybe<Array<StoreItemWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<StoreItemCreateOrConnectWithoutItemStampInput>>;
  create?: InputMaybe<Array<StoreItemCreateWithoutItemStampInput>>;
  createMany?: InputMaybe<StoreItemCreateManyItemStampInputEnvelope>;
  delete?: InputMaybe<Array<StoreItemWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<StoreItemScalarWhereInput>>;
  disconnect?: InputMaybe<Array<StoreItemWhereUniqueInput>>;
  set?: InputMaybe<Array<StoreItemWhereUniqueInput>>;
  update?: InputMaybe<Array<StoreItemUpdateWithWhereUniqueWithoutItemStampInput>>;
  updateMany?: InputMaybe<Array<StoreItemUpdateManyWithWhereWithoutItemStampInput>>;
  upsert?: InputMaybe<Array<StoreItemUpsertWithWhereUniqueWithoutItemStampInput>>;
};

export type StoreItemUpdateManyWithoutProfitableNestedInput = {
  connect?: InputMaybe<Array<StoreItemWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<StoreItemCreateOrConnectWithoutProfitableInput>>;
  create?: InputMaybe<Array<StoreItemCreateWithoutProfitableInput>>;
  createMany?: InputMaybe<StoreItemCreateManyProfitableInputEnvelope>;
  delete?: InputMaybe<Array<StoreItemWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<StoreItemScalarWhereInput>>;
  disconnect?: InputMaybe<Array<StoreItemWhereUniqueInput>>;
  set?: InputMaybe<Array<StoreItemWhereUniqueInput>>;
  update?: InputMaybe<Array<StoreItemUpdateWithWhereUniqueWithoutProfitableInput>>;
  updateMany?: InputMaybe<Array<StoreItemUpdateManyWithWhereWithoutProfitableInput>>;
  upsert?: InputMaybe<Array<StoreItemUpsertWithWhereUniqueWithoutProfitableInput>>;
};

export type StoreItemUpdateManyWithoutTagsClassifyNestedInput = {
  connect?: InputMaybe<Array<StoreItemWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<StoreItemCreateOrConnectWithoutTagsClassifyInput>>;
  create?: InputMaybe<Array<StoreItemCreateWithoutTagsClassifyInput>>;
  delete?: InputMaybe<Array<StoreItemWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<StoreItemScalarWhereInput>>;
  disconnect?: InputMaybe<Array<StoreItemWhereUniqueInput>>;
  set?: InputMaybe<Array<StoreItemWhereUniqueInput>>;
  update?: InputMaybe<Array<StoreItemUpdateWithWhereUniqueWithoutTagsClassifyInput>>;
  updateMany?: InputMaybe<Array<StoreItemUpdateManyWithWhereWithoutTagsClassifyInput>>;
  upsert?: InputMaybe<Array<StoreItemUpsertWithWhereUniqueWithoutTagsClassifyInput>>;
};

export type StoreItemUpdateManyWithoutTagsNestedInput = {
  connect?: InputMaybe<Array<StoreItemWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<StoreItemCreateOrConnectWithoutTagsInput>>;
  create?: InputMaybe<Array<StoreItemCreateWithoutTagsInput>>;
  delete?: InputMaybe<Array<StoreItemWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<StoreItemScalarWhereInput>>;
  disconnect?: InputMaybe<Array<StoreItemWhereUniqueInput>>;
  set?: InputMaybe<Array<StoreItemWhereUniqueInput>>;
  update?: InputMaybe<Array<StoreItemUpdateWithWhereUniqueWithoutTagsInput>>;
  updateMany?: InputMaybe<Array<StoreItemUpdateManyWithWhereWithoutTagsInput>>;
  upsert?: InputMaybe<Array<StoreItemUpsertWithWhereUniqueWithoutTagsInput>>;
};

export type StoreItemUpdateOneRequiredWithoutInBenefitMemoToCoinsNestedInput = {
  connect?: InputMaybe<StoreItemWhereUniqueInput>;
  connectOrCreate?: InputMaybe<StoreItemCreateOrConnectWithoutInBenefitMemoToCoinsInput>;
  create?: InputMaybe<StoreItemCreateWithoutInBenefitMemoToCoinsInput>;
  update?: InputMaybe<StoreItemUpdateWithoutInBenefitMemoToCoinsInput>;
  upsert?: InputMaybe<StoreItemUpsertWithoutInBenefitMemoToCoinsInput>;
};

export type StoreItemUpdateOneRequiredWithoutInBenefitMemoToOrdersNestedInput = {
  connect?: InputMaybe<StoreItemWhereUniqueInput>;
  connectOrCreate?: InputMaybe<StoreItemCreateOrConnectWithoutInBenefitMemoToOrdersInput>;
  create?: InputMaybe<StoreItemCreateWithoutInBenefitMemoToOrdersInput>;
  update?: InputMaybe<StoreItemUpdateWithoutInBenefitMemoToOrdersInput>;
  upsert?: InputMaybe<StoreItemUpsertWithoutInBenefitMemoToOrdersInput>;
};

export type StoreItemUpdateOneRequiredWithoutInStoreToCartItemsNestedInput = {
  connect?: InputMaybe<StoreItemWhereUniqueInput>;
  connectOrCreate?: InputMaybe<StoreItemCreateOrConnectWithoutInStoreToCartItemsInput>;
  create?: InputMaybe<StoreItemCreateWithoutInStoreToCartItemsInput>;
  update?: InputMaybe<StoreItemUpdateWithoutInStoreToCartItemsInput>;
  upsert?: InputMaybe<StoreItemUpsertWithoutInStoreToCartItemsInput>;
};

export type StoreItemUpdateWithWhereUniqueWithoutFavoriteByUsersInput = {
  data: StoreItemUpdateWithoutFavoriteByUsersInput;
  where: StoreItemWhereUniqueInput;
};

export type StoreItemUpdateWithWhereUniqueWithoutItemAdsInput = {
  data: StoreItemUpdateWithoutItemAdsInput;
  where: StoreItemWhereUniqueInput;
};

export type StoreItemUpdateWithWhereUniqueWithoutItemCardInput = {
  data: StoreItemUpdateWithoutItemCardInput;
  where: StoreItemWhereUniqueInput;
};

export type StoreItemUpdateWithWhereUniqueWithoutItemStampInput = {
  data: StoreItemUpdateWithoutItemStampInput;
  where: StoreItemWhereUniqueInput;
};

export type StoreItemUpdateWithWhereUniqueWithoutProfitableInput = {
  data: StoreItemUpdateWithoutProfitableInput;
  where: StoreItemWhereUniqueInput;
};

export type StoreItemUpdateWithWhereUniqueWithoutTagsClassifyInput = {
  data: StoreItemUpdateWithoutTagsClassifyInput;
  where: StoreItemWhereUniqueInput;
};

export type StoreItemUpdateWithWhereUniqueWithoutTagsInput = {
  data: StoreItemUpdateWithoutTagsInput;
  where: StoreItemWhereUniqueInput;
};

export type StoreItemUpdateWithoutFavoriteByUsersInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  inBenefitMemoToCoins?: InputMaybe<BenefitMemoToCoinUpdateManyWithoutStoreItemNestedInput>;
  inBenefitMemoToOrders?: InputMaybe<BenefitMemoToOrderUpdateManyWithoutStoreItemNestedInput>;
  inStoreToCartItems?: InputMaybe<StoreItemToCartItemUpdateManyWithoutStoreItemNestedInput>;
  itemAds?: InputMaybe<AdvertisementUpdateOneWithoutInStoreItemNestedInput>;
  itemCard?: InputMaybe<CardUpdateOneWithoutInStoreItemNestedInput>;
  itemStamp?: InputMaybe<StampUpdateOneWithoutInStoreItemNestedInput>;
  limitedQuantity?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  price?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  priceOriginal?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
  profitable?: InputMaybe<ProfitableUpdateOneRequiredWithoutStoreItemsNestedInput>;
  readableId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  stockQuantity?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  tags?: InputMaybe<StoreItemTagUpdateManyWithoutTaggedStoresNestedInput>;
  tagsClassify?: InputMaybe<StoreItemTagUpdateManyWithoutTagClassifiedStoresNestedInput>;
  type?: InputMaybe<EnumStoreItemTypeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  valid?: InputMaybe<BoolFieldUpdateOperationsInput>;
};

export type StoreItemUpdateWithoutInBenefitMemoToCoinsInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  favoriteByUsers?: InputMaybe<UserUpdateManyWithoutFavoriteStoreItemsNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  inBenefitMemoToOrders?: InputMaybe<BenefitMemoToOrderUpdateManyWithoutStoreItemNestedInput>;
  inStoreToCartItems?: InputMaybe<StoreItemToCartItemUpdateManyWithoutStoreItemNestedInput>;
  itemAds?: InputMaybe<AdvertisementUpdateOneWithoutInStoreItemNestedInput>;
  itemCard?: InputMaybe<CardUpdateOneWithoutInStoreItemNestedInput>;
  itemStamp?: InputMaybe<StampUpdateOneWithoutInStoreItemNestedInput>;
  limitedQuantity?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  price?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  priceOriginal?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
  profitable?: InputMaybe<ProfitableUpdateOneRequiredWithoutStoreItemsNestedInput>;
  readableId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  stockQuantity?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  tags?: InputMaybe<StoreItemTagUpdateManyWithoutTaggedStoresNestedInput>;
  tagsClassify?: InputMaybe<StoreItemTagUpdateManyWithoutTagClassifiedStoresNestedInput>;
  type?: InputMaybe<EnumStoreItemTypeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  valid?: InputMaybe<BoolFieldUpdateOperationsInput>;
};

export type StoreItemUpdateWithoutInBenefitMemoToOrdersInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  favoriteByUsers?: InputMaybe<UserUpdateManyWithoutFavoriteStoreItemsNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  inBenefitMemoToCoins?: InputMaybe<BenefitMemoToCoinUpdateManyWithoutStoreItemNestedInput>;
  inStoreToCartItems?: InputMaybe<StoreItemToCartItemUpdateManyWithoutStoreItemNestedInput>;
  itemAds?: InputMaybe<AdvertisementUpdateOneWithoutInStoreItemNestedInput>;
  itemCard?: InputMaybe<CardUpdateOneWithoutInStoreItemNestedInput>;
  itemStamp?: InputMaybe<StampUpdateOneWithoutInStoreItemNestedInput>;
  limitedQuantity?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  price?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  priceOriginal?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
  profitable?: InputMaybe<ProfitableUpdateOneRequiredWithoutStoreItemsNestedInput>;
  readableId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  stockQuantity?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  tags?: InputMaybe<StoreItemTagUpdateManyWithoutTaggedStoresNestedInput>;
  tagsClassify?: InputMaybe<StoreItemTagUpdateManyWithoutTagClassifiedStoresNestedInput>;
  type?: InputMaybe<EnumStoreItemTypeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  valid?: InputMaybe<BoolFieldUpdateOperationsInput>;
};

export type StoreItemUpdateWithoutInStoreToCartItemsInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  favoriteByUsers?: InputMaybe<UserUpdateManyWithoutFavoriteStoreItemsNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  inBenefitMemoToCoins?: InputMaybe<BenefitMemoToCoinUpdateManyWithoutStoreItemNestedInput>;
  inBenefitMemoToOrders?: InputMaybe<BenefitMemoToOrderUpdateManyWithoutStoreItemNestedInput>;
  itemAds?: InputMaybe<AdvertisementUpdateOneWithoutInStoreItemNestedInput>;
  itemCard?: InputMaybe<CardUpdateOneWithoutInStoreItemNestedInput>;
  itemStamp?: InputMaybe<StampUpdateOneWithoutInStoreItemNestedInput>;
  limitedQuantity?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  price?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  priceOriginal?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
  profitable?: InputMaybe<ProfitableUpdateOneRequiredWithoutStoreItemsNestedInput>;
  readableId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  stockQuantity?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  tags?: InputMaybe<StoreItemTagUpdateManyWithoutTaggedStoresNestedInput>;
  tagsClassify?: InputMaybe<StoreItemTagUpdateManyWithoutTagClassifiedStoresNestedInput>;
  type?: InputMaybe<EnumStoreItemTypeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  valid?: InputMaybe<BoolFieldUpdateOperationsInput>;
};

export type StoreItemUpdateWithoutItemAdsInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  favoriteByUsers?: InputMaybe<UserUpdateManyWithoutFavoriteStoreItemsNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  inBenefitMemoToCoins?: InputMaybe<BenefitMemoToCoinUpdateManyWithoutStoreItemNestedInput>;
  inBenefitMemoToOrders?: InputMaybe<BenefitMemoToOrderUpdateManyWithoutStoreItemNestedInput>;
  inStoreToCartItems?: InputMaybe<StoreItemToCartItemUpdateManyWithoutStoreItemNestedInput>;
  itemCard?: InputMaybe<CardUpdateOneWithoutInStoreItemNestedInput>;
  itemStamp?: InputMaybe<StampUpdateOneWithoutInStoreItemNestedInput>;
  limitedQuantity?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  price?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  priceOriginal?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
  profitable?: InputMaybe<ProfitableUpdateOneRequiredWithoutStoreItemsNestedInput>;
  readableId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  stockQuantity?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  tags?: InputMaybe<StoreItemTagUpdateManyWithoutTaggedStoresNestedInput>;
  tagsClassify?: InputMaybe<StoreItemTagUpdateManyWithoutTagClassifiedStoresNestedInput>;
  type?: InputMaybe<EnumStoreItemTypeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  valid?: InputMaybe<BoolFieldUpdateOperationsInput>;
};

export type StoreItemUpdateWithoutItemCardInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  favoriteByUsers?: InputMaybe<UserUpdateManyWithoutFavoriteStoreItemsNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  inBenefitMemoToCoins?: InputMaybe<BenefitMemoToCoinUpdateManyWithoutStoreItemNestedInput>;
  inBenefitMemoToOrders?: InputMaybe<BenefitMemoToOrderUpdateManyWithoutStoreItemNestedInput>;
  inStoreToCartItems?: InputMaybe<StoreItemToCartItemUpdateManyWithoutStoreItemNestedInput>;
  itemAds?: InputMaybe<AdvertisementUpdateOneWithoutInStoreItemNestedInput>;
  itemStamp?: InputMaybe<StampUpdateOneWithoutInStoreItemNestedInput>;
  limitedQuantity?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  price?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  priceOriginal?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
  profitable?: InputMaybe<ProfitableUpdateOneRequiredWithoutStoreItemsNestedInput>;
  readableId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  stockQuantity?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  tags?: InputMaybe<StoreItemTagUpdateManyWithoutTaggedStoresNestedInput>;
  tagsClassify?: InputMaybe<StoreItemTagUpdateManyWithoutTagClassifiedStoresNestedInput>;
  type?: InputMaybe<EnumStoreItemTypeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  valid?: InputMaybe<BoolFieldUpdateOperationsInput>;
};

export type StoreItemUpdateWithoutItemStampInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  favoriteByUsers?: InputMaybe<UserUpdateManyWithoutFavoriteStoreItemsNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  inBenefitMemoToCoins?: InputMaybe<BenefitMemoToCoinUpdateManyWithoutStoreItemNestedInput>;
  inBenefitMemoToOrders?: InputMaybe<BenefitMemoToOrderUpdateManyWithoutStoreItemNestedInput>;
  inStoreToCartItems?: InputMaybe<StoreItemToCartItemUpdateManyWithoutStoreItemNestedInput>;
  itemAds?: InputMaybe<AdvertisementUpdateOneWithoutInStoreItemNestedInput>;
  itemCard?: InputMaybe<CardUpdateOneWithoutInStoreItemNestedInput>;
  limitedQuantity?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  price?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  priceOriginal?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
  profitable?: InputMaybe<ProfitableUpdateOneRequiredWithoutStoreItemsNestedInput>;
  readableId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  stockQuantity?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  tags?: InputMaybe<StoreItemTagUpdateManyWithoutTaggedStoresNestedInput>;
  tagsClassify?: InputMaybe<StoreItemTagUpdateManyWithoutTagClassifiedStoresNestedInput>;
  type?: InputMaybe<EnumStoreItemTypeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  valid?: InputMaybe<BoolFieldUpdateOperationsInput>;
};

export type StoreItemUpdateWithoutProfitableInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  favoriteByUsers?: InputMaybe<UserUpdateManyWithoutFavoriteStoreItemsNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  inBenefitMemoToCoins?: InputMaybe<BenefitMemoToCoinUpdateManyWithoutStoreItemNestedInput>;
  inBenefitMemoToOrders?: InputMaybe<BenefitMemoToOrderUpdateManyWithoutStoreItemNestedInput>;
  inStoreToCartItems?: InputMaybe<StoreItemToCartItemUpdateManyWithoutStoreItemNestedInput>;
  itemAds?: InputMaybe<AdvertisementUpdateOneWithoutInStoreItemNestedInput>;
  itemCard?: InputMaybe<CardUpdateOneWithoutInStoreItemNestedInput>;
  itemStamp?: InputMaybe<StampUpdateOneWithoutInStoreItemNestedInput>;
  limitedQuantity?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  price?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  priceOriginal?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
  readableId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  stockQuantity?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  tags?: InputMaybe<StoreItemTagUpdateManyWithoutTaggedStoresNestedInput>;
  tagsClassify?: InputMaybe<StoreItemTagUpdateManyWithoutTagClassifiedStoresNestedInput>;
  type?: InputMaybe<EnumStoreItemTypeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  valid?: InputMaybe<BoolFieldUpdateOperationsInput>;
};

export type StoreItemUpdateWithoutTagsClassifyInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  favoriteByUsers?: InputMaybe<UserUpdateManyWithoutFavoriteStoreItemsNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  inBenefitMemoToCoins?: InputMaybe<BenefitMemoToCoinUpdateManyWithoutStoreItemNestedInput>;
  inBenefitMemoToOrders?: InputMaybe<BenefitMemoToOrderUpdateManyWithoutStoreItemNestedInput>;
  inStoreToCartItems?: InputMaybe<StoreItemToCartItemUpdateManyWithoutStoreItemNestedInput>;
  itemAds?: InputMaybe<AdvertisementUpdateOneWithoutInStoreItemNestedInput>;
  itemCard?: InputMaybe<CardUpdateOneWithoutInStoreItemNestedInput>;
  itemStamp?: InputMaybe<StampUpdateOneWithoutInStoreItemNestedInput>;
  limitedQuantity?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  price?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  priceOriginal?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
  profitable?: InputMaybe<ProfitableUpdateOneRequiredWithoutStoreItemsNestedInput>;
  readableId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  stockQuantity?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  tags?: InputMaybe<StoreItemTagUpdateManyWithoutTaggedStoresNestedInput>;
  type?: InputMaybe<EnumStoreItemTypeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  valid?: InputMaybe<BoolFieldUpdateOperationsInput>;
};

export type StoreItemUpdateWithoutTagsInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  favoriteByUsers?: InputMaybe<UserUpdateManyWithoutFavoriteStoreItemsNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  inBenefitMemoToCoins?: InputMaybe<BenefitMemoToCoinUpdateManyWithoutStoreItemNestedInput>;
  inBenefitMemoToOrders?: InputMaybe<BenefitMemoToOrderUpdateManyWithoutStoreItemNestedInput>;
  inStoreToCartItems?: InputMaybe<StoreItemToCartItemUpdateManyWithoutStoreItemNestedInput>;
  itemAds?: InputMaybe<AdvertisementUpdateOneWithoutInStoreItemNestedInput>;
  itemCard?: InputMaybe<CardUpdateOneWithoutInStoreItemNestedInput>;
  itemStamp?: InputMaybe<StampUpdateOneWithoutInStoreItemNestedInput>;
  limitedQuantity?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  price?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  priceOriginal?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
  profitable?: InputMaybe<ProfitableUpdateOneRequiredWithoutStoreItemsNestedInput>;
  readableId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  stockQuantity?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  tagsClassify?: InputMaybe<StoreItemTagUpdateManyWithoutTagClassifiedStoresNestedInput>;
  type?: InputMaybe<EnumStoreItemTypeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  valid?: InputMaybe<BoolFieldUpdateOperationsInput>;
};

export type StoreItemUpsertWithWhereUniqueWithoutFavoriteByUsersInput = {
  create: StoreItemCreateWithoutFavoriteByUsersInput;
  update: StoreItemUpdateWithoutFavoriteByUsersInput;
  where: StoreItemWhereUniqueInput;
};

export type StoreItemUpsertWithWhereUniqueWithoutItemAdsInput = {
  create: StoreItemCreateWithoutItemAdsInput;
  update: StoreItemUpdateWithoutItemAdsInput;
  where: StoreItemWhereUniqueInput;
};

export type StoreItemUpsertWithWhereUniqueWithoutItemCardInput = {
  create: StoreItemCreateWithoutItemCardInput;
  update: StoreItemUpdateWithoutItemCardInput;
  where: StoreItemWhereUniqueInput;
};

export type StoreItemUpsertWithWhereUniqueWithoutItemStampInput = {
  create: StoreItemCreateWithoutItemStampInput;
  update: StoreItemUpdateWithoutItemStampInput;
  where: StoreItemWhereUniqueInput;
};

export type StoreItemUpsertWithWhereUniqueWithoutProfitableInput = {
  create: StoreItemCreateWithoutProfitableInput;
  update: StoreItemUpdateWithoutProfitableInput;
  where: StoreItemWhereUniqueInput;
};

export type StoreItemUpsertWithWhereUniqueWithoutTagsClassifyInput = {
  create: StoreItemCreateWithoutTagsClassifyInput;
  update: StoreItemUpdateWithoutTagsClassifyInput;
  where: StoreItemWhereUniqueInput;
};

export type StoreItemUpsertWithWhereUniqueWithoutTagsInput = {
  create: StoreItemCreateWithoutTagsInput;
  update: StoreItemUpdateWithoutTagsInput;
  where: StoreItemWhereUniqueInput;
};

export type StoreItemUpsertWithoutInBenefitMemoToCoinsInput = {
  create: StoreItemCreateWithoutInBenefitMemoToCoinsInput;
  update: StoreItemUpdateWithoutInBenefitMemoToCoinsInput;
};

export type StoreItemUpsertWithoutInBenefitMemoToOrdersInput = {
  create: StoreItemCreateWithoutInBenefitMemoToOrdersInput;
  update: StoreItemUpdateWithoutInBenefitMemoToOrdersInput;
};

export type StoreItemUpsertWithoutInStoreToCartItemsInput = {
  create: StoreItemCreateWithoutInStoreToCartItemsInput;
  update: StoreItemUpdateWithoutInStoreToCartItemsInput;
};

export type StoreItemWhereInput = {
  AND?: InputMaybe<Array<StoreItemWhereInput>>;
  NOT?: InputMaybe<Array<StoreItemWhereInput>>;
  OR?: InputMaybe<Array<StoreItemWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  favoriteByUsers?: InputMaybe<UserListRelationFilter>;
  id?: InputMaybe<StringFilter>;
  inBenefitMemoToCoins?: InputMaybe<BenefitMemoToCoinListRelationFilter>;
  inBenefitMemoToOrders?: InputMaybe<BenefitMemoToOrderListRelationFilter>;
  inStoreToCartItems?: InputMaybe<StoreItemToCartItemListRelationFilter>;
  itemAds?: InputMaybe<AdvertisementRelationFilter>;
  itemAdsId?: InputMaybe<StringNullableFilter>;
  itemCard?: InputMaybe<CardRelationFilter>;
  itemCardId?: InputMaybe<StringNullableFilter>;
  itemStamp?: InputMaybe<StampRelationFilter>;
  itemStampId?: InputMaybe<StringNullableFilter>;
  limitedQuantity?: InputMaybe<IntNullableFilter>;
  price?: InputMaybe<DecimalFilter>;
  priceOriginal?: InputMaybe<DecimalNullableFilter>;
  profitable?: InputMaybe<ProfitableRelationFilter>;
  profitableId?: InputMaybe<StringFilter>;
  readableId?: InputMaybe<StringNullableFilter>;
  stockQuantity?: InputMaybe<IntNullableFilter>;
  tags?: InputMaybe<StoreItemTagListRelationFilter>;
  tagsClassify?: InputMaybe<StoreItemTagListRelationFilter>;
  type?: InputMaybe<EnumStoreItemTypeFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  valid?: InputMaybe<BoolFilter>;
};

export type StoreItemWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
  readableId?: InputMaybe<Scalars['String']>;
};

export type StoreRatio = {
  __typename?: 'StoreRatio';
  _count?: Maybe<StoreRatioCount>;
  createdAt: Scalars['DateTime'];
  description: Scalars['String'];
  end?: Maybe<Scalars['DateTime']>;
  id: Scalars['String'];
  inBenefitMemoToCoin: Array<BenefitMemoToCoin>;
  inBenefitMemoToOrder: Array<BenefitMemoToOrder>;
  inSaleRatio: Array<Profitable>;
  inSendRatio: Array<Profitable>;
  inStoreRatioHistoryItem: Array<StoreRatioHistoryItem>;
  start?: Maybe<Scalars['DateTime']>;
  value: Scalars['Float'];
};


export type StoreRatioInBenefitMemoToCoinArgs = {
  cursor?: InputMaybe<BenefitMemoToCoinWhereUniqueInput>;
  distinct?: InputMaybe<Array<BenefitMemoToCoinScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<BenefitMemoToCoinOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<BenefitMemoToCoinWhereInput>;
};


export type StoreRatioInBenefitMemoToOrderArgs = {
  cursor?: InputMaybe<BenefitMemoToOrderWhereUniqueInput>;
  distinct?: InputMaybe<Array<BenefitMemoToOrderScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<BenefitMemoToOrderOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<BenefitMemoToOrderWhereInput>;
};


export type StoreRatioInSaleRatioArgs = {
  cursor?: InputMaybe<ProfitableWhereUniqueInput>;
  distinct?: InputMaybe<Array<ProfitableScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ProfitableOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ProfitableWhereInput>;
};


export type StoreRatioInSendRatioArgs = {
  cursor?: InputMaybe<ProfitableWhereUniqueInput>;
  distinct?: InputMaybe<Array<ProfitableScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ProfitableOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ProfitableWhereInput>;
};


export type StoreRatioInStoreRatioHistoryItemArgs = {
  cursor?: InputMaybe<StoreRatioHistoryItemWhereUniqueInput>;
  distinct?: InputMaybe<Array<StoreRatioHistoryItemScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<StoreRatioHistoryItemOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<StoreRatioHistoryItemWhereInput>;
};

export type StoreRatioAvgAggregate = {
  __typename?: 'StoreRatioAvgAggregate';
  value?: Maybe<Scalars['Float']>;
};

export type StoreRatioAvgOrderByAggregateInput = {
  value?: InputMaybe<SortOrder>;
};

export type StoreRatioCount = {
  __typename?: 'StoreRatioCount';
  inBenefitMemoToCoin: Scalars['Int'];
  inBenefitMemoToOrder: Scalars['Int'];
  inSaleRatio: Scalars['Int'];
  inSendRatio: Scalars['Int'];
  inStoreRatioHistoryItem: Scalars['Int'];
};


export type StoreRatioCountInBenefitMemoToCoinArgs = {
  where?: InputMaybe<BenefitMemoToCoinWhereInput>;
};


export type StoreRatioCountInBenefitMemoToOrderArgs = {
  where?: InputMaybe<BenefitMemoToOrderWhereInput>;
};


export type StoreRatioCountInSaleRatioArgs = {
  where?: InputMaybe<ProfitableWhereInput>;
};


export type StoreRatioCountInSendRatioArgs = {
  where?: InputMaybe<ProfitableWhereInput>;
};


export type StoreRatioCountInStoreRatioHistoryItemArgs = {
  where?: InputMaybe<StoreRatioHistoryItemWhereInput>;
};

export type StoreRatioCountAggregate = {
  __typename?: 'StoreRatioCountAggregate';
  _all: Scalars['Int'];
  createdAt: Scalars['Int'];
  description: Scalars['Int'];
  end: Scalars['Int'];
  id: Scalars['Int'];
  start: Scalars['Int'];
  value: Scalars['Int'];
};

export type StoreRatioCountOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  end?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  start?: InputMaybe<SortOrder>;
  value?: InputMaybe<SortOrder>;
};

export type StoreRatioCreateInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  description: Scalars['String'];
  end?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  inBenefitMemoToCoin?: InputMaybe<BenefitMemoToCoinCreateNestedManyWithoutBenefitRatioInput>;
  inBenefitMemoToOrder?: InputMaybe<BenefitMemoToOrderCreateNestedManyWithoutBenefitRatioInput>;
  inSaleRatio?: InputMaybe<ProfitableCreateNestedManyWithoutSaleRatioInput>;
  inSendRatio?: InputMaybe<ProfitableCreateNestedManyWithoutSendRatioInput>;
  inStoreRatioHistoryItem?: InputMaybe<StoreRatioHistoryItemCreateNestedManyWithoutRatioInput>;
  start?: InputMaybe<Scalars['DateTime']>;
  value: Scalars['Float'];
};

export type StoreRatioCreateManyInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  description: Scalars['String'];
  end?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  start?: InputMaybe<Scalars['DateTime']>;
  value: Scalars['Float'];
};

export type StoreRatioCreateNestedOneWithoutInBenefitMemoToCoinInput = {
  connect?: InputMaybe<StoreRatioWhereUniqueInput>;
  connectOrCreate?: InputMaybe<StoreRatioCreateOrConnectWithoutInBenefitMemoToCoinInput>;
  create?: InputMaybe<StoreRatioCreateWithoutInBenefitMemoToCoinInput>;
};

export type StoreRatioCreateNestedOneWithoutInBenefitMemoToOrderInput = {
  connect?: InputMaybe<StoreRatioWhereUniqueInput>;
  connectOrCreate?: InputMaybe<StoreRatioCreateOrConnectWithoutInBenefitMemoToOrderInput>;
  create?: InputMaybe<StoreRatioCreateWithoutInBenefitMemoToOrderInput>;
};

export type StoreRatioCreateNestedOneWithoutInSaleRatioInput = {
  connect?: InputMaybe<StoreRatioWhereUniqueInput>;
  connectOrCreate?: InputMaybe<StoreRatioCreateOrConnectWithoutInSaleRatioInput>;
  create?: InputMaybe<StoreRatioCreateWithoutInSaleRatioInput>;
};

export type StoreRatioCreateNestedOneWithoutInSendRatioInput = {
  connect?: InputMaybe<StoreRatioWhereUniqueInput>;
  connectOrCreate?: InputMaybe<StoreRatioCreateOrConnectWithoutInSendRatioInput>;
  create?: InputMaybe<StoreRatioCreateWithoutInSendRatioInput>;
};

export type StoreRatioCreateNestedOneWithoutInStoreRatioHistoryItemInput = {
  connect?: InputMaybe<StoreRatioWhereUniqueInput>;
  connectOrCreate?: InputMaybe<StoreRatioCreateOrConnectWithoutInStoreRatioHistoryItemInput>;
  create?: InputMaybe<StoreRatioCreateWithoutInStoreRatioHistoryItemInput>;
};

export type StoreRatioCreateOrConnectWithoutInBenefitMemoToCoinInput = {
  create: StoreRatioCreateWithoutInBenefitMemoToCoinInput;
  where: StoreRatioWhereUniqueInput;
};

export type StoreRatioCreateOrConnectWithoutInBenefitMemoToOrderInput = {
  create: StoreRatioCreateWithoutInBenefitMemoToOrderInput;
  where: StoreRatioWhereUniqueInput;
};

export type StoreRatioCreateOrConnectWithoutInSaleRatioInput = {
  create: StoreRatioCreateWithoutInSaleRatioInput;
  where: StoreRatioWhereUniqueInput;
};

export type StoreRatioCreateOrConnectWithoutInSendRatioInput = {
  create: StoreRatioCreateWithoutInSendRatioInput;
  where: StoreRatioWhereUniqueInput;
};

export type StoreRatioCreateOrConnectWithoutInStoreRatioHistoryItemInput = {
  create: StoreRatioCreateWithoutInStoreRatioHistoryItemInput;
  where: StoreRatioWhereUniqueInput;
};

export type StoreRatioCreateWithoutInBenefitMemoToCoinInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  description: Scalars['String'];
  end?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  inBenefitMemoToOrder?: InputMaybe<BenefitMemoToOrderCreateNestedManyWithoutBenefitRatioInput>;
  inSaleRatio?: InputMaybe<ProfitableCreateNestedManyWithoutSaleRatioInput>;
  inSendRatio?: InputMaybe<ProfitableCreateNestedManyWithoutSendRatioInput>;
  inStoreRatioHistoryItem?: InputMaybe<StoreRatioHistoryItemCreateNestedManyWithoutRatioInput>;
  start?: InputMaybe<Scalars['DateTime']>;
  value: Scalars['Float'];
};

export type StoreRatioCreateWithoutInBenefitMemoToOrderInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  description: Scalars['String'];
  end?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  inBenefitMemoToCoin?: InputMaybe<BenefitMemoToCoinCreateNestedManyWithoutBenefitRatioInput>;
  inSaleRatio?: InputMaybe<ProfitableCreateNestedManyWithoutSaleRatioInput>;
  inSendRatio?: InputMaybe<ProfitableCreateNestedManyWithoutSendRatioInput>;
  inStoreRatioHistoryItem?: InputMaybe<StoreRatioHistoryItemCreateNestedManyWithoutRatioInput>;
  start?: InputMaybe<Scalars['DateTime']>;
  value: Scalars['Float'];
};

export type StoreRatioCreateWithoutInSaleRatioInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  description: Scalars['String'];
  end?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  inBenefitMemoToCoin?: InputMaybe<BenefitMemoToCoinCreateNestedManyWithoutBenefitRatioInput>;
  inBenefitMemoToOrder?: InputMaybe<BenefitMemoToOrderCreateNestedManyWithoutBenefitRatioInput>;
  inSendRatio?: InputMaybe<ProfitableCreateNestedManyWithoutSendRatioInput>;
  inStoreRatioHistoryItem?: InputMaybe<StoreRatioHistoryItemCreateNestedManyWithoutRatioInput>;
  start?: InputMaybe<Scalars['DateTime']>;
  value: Scalars['Float'];
};

export type StoreRatioCreateWithoutInSendRatioInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  description: Scalars['String'];
  end?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  inBenefitMemoToCoin?: InputMaybe<BenefitMemoToCoinCreateNestedManyWithoutBenefitRatioInput>;
  inBenefitMemoToOrder?: InputMaybe<BenefitMemoToOrderCreateNestedManyWithoutBenefitRatioInput>;
  inSaleRatio?: InputMaybe<ProfitableCreateNestedManyWithoutSaleRatioInput>;
  inStoreRatioHistoryItem?: InputMaybe<StoreRatioHistoryItemCreateNestedManyWithoutRatioInput>;
  start?: InputMaybe<Scalars['DateTime']>;
  value: Scalars['Float'];
};

export type StoreRatioCreateWithoutInStoreRatioHistoryItemInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  description: Scalars['String'];
  end?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  inBenefitMemoToCoin?: InputMaybe<BenefitMemoToCoinCreateNestedManyWithoutBenefitRatioInput>;
  inBenefitMemoToOrder?: InputMaybe<BenefitMemoToOrderCreateNestedManyWithoutBenefitRatioInput>;
  inSaleRatio?: InputMaybe<ProfitableCreateNestedManyWithoutSaleRatioInput>;
  inSendRatio?: InputMaybe<ProfitableCreateNestedManyWithoutSendRatioInput>;
  start?: InputMaybe<Scalars['DateTime']>;
  value: Scalars['Float'];
};

export type StoreRatioGroupBy = {
  __typename?: 'StoreRatioGroupBy';
  _avg?: Maybe<StoreRatioAvgAggregate>;
  _count?: Maybe<StoreRatioCountAggregate>;
  _max?: Maybe<StoreRatioMaxAggregate>;
  _min?: Maybe<StoreRatioMinAggregate>;
  _sum?: Maybe<StoreRatioSumAggregate>;
  createdAt: Scalars['DateTime'];
  description: Scalars['String'];
  end?: Maybe<Scalars['DateTime']>;
  id: Scalars['String'];
  start?: Maybe<Scalars['DateTime']>;
  value: Scalars['Float'];
};

export type StoreRatioHistoryItem = {
  __typename?: 'StoreRatioHistoryItem';
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  inSaleProfitableId?: Maybe<Scalars['String']>;
  inSaleRatioHistory?: Maybe<Profitable>;
  inSendProfitableId?: Maybe<Scalars['String']>;
  inSendRatioHistory?: Maybe<Profitable>;
  ratio: StoreRatio;
  ratioId: Scalars['String'];
};

export type StoreRatioHistoryItemCountAggregate = {
  __typename?: 'StoreRatioHistoryItemCountAggregate';
  _all: Scalars['Int'];
  createdAt: Scalars['Int'];
  id: Scalars['Int'];
  inSaleProfitableId: Scalars['Int'];
  inSendProfitableId: Scalars['Int'];
  ratioId: Scalars['Int'];
};

export type StoreRatioHistoryItemCountOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  inSaleProfitableId?: InputMaybe<SortOrder>;
  inSendProfitableId?: InputMaybe<SortOrder>;
  ratioId?: InputMaybe<SortOrder>;
};

export type StoreRatioHistoryItemCreateInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  inSaleRatioHistory?: InputMaybe<ProfitableCreateNestedOneWithoutSaleRatioHistoryInput>;
  inSendRatioHistory?: InputMaybe<ProfitableCreateNestedOneWithoutSendRatioHistoryInput>;
  ratio: StoreRatioCreateNestedOneWithoutInStoreRatioHistoryItemInput;
};

export type StoreRatioHistoryItemCreateManyInSaleRatioHistoryInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  inSendProfitableId?: InputMaybe<Scalars['String']>;
  ratioId: Scalars['String'];
};

export type StoreRatioHistoryItemCreateManyInSaleRatioHistoryInputEnvelope = {
  data: Array<StoreRatioHistoryItemCreateManyInSaleRatioHistoryInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type StoreRatioHistoryItemCreateManyInSendRatioHistoryInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  inSaleProfitableId?: InputMaybe<Scalars['String']>;
  ratioId: Scalars['String'];
};

export type StoreRatioHistoryItemCreateManyInSendRatioHistoryInputEnvelope = {
  data: Array<StoreRatioHistoryItemCreateManyInSendRatioHistoryInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type StoreRatioHistoryItemCreateManyInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  inSaleProfitableId?: InputMaybe<Scalars['String']>;
  inSendProfitableId?: InputMaybe<Scalars['String']>;
  ratioId: Scalars['String'];
};

export type StoreRatioHistoryItemCreateManyRatioInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  inSaleProfitableId?: InputMaybe<Scalars['String']>;
  inSendProfitableId?: InputMaybe<Scalars['String']>;
};

export type StoreRatioHistoryItemCreateManyRatioInputEnvelope = {
  data: Array<StoreRatioHistoryItemCreateManyRatioInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type StoreRatioHistoryItemCreateNestedManyWithoutInSaleRatioHistoryInput = {
  connect?: InputMaybe<Array<StoreRatioHistoryItemWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<StoreRatioHistoryItemCreateOrConnectWithoutInSaleRatioHistoryInput>>;
  create?: InputMaybe<Array<StoreRatioHistoryItemCreateWithoutInSaleRatioHistoryInput>>;
  createMany?: InputMaybe<StoreRatioHistoryItemCreateManyInSaleRatioHistoryInputEnvelope>;
};

export type StoreRatioHistoryItemCreateNestedManyWithoutInSendRatioHistoryInput = {
  connect?: InputMaybe<Array<StoreRatioHistoryItemWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<StoreRatioHistoryItemCreateOrConnectWithoutInSendRatioHistoryInput>>;
  create?: InputMaybe<Array<StoreRatioHistoryItemCreateWithoutInSendRatioHistoryInput>>;
  createMany?: InputMaybe<StoreRatioHistoryItemCreateManyInSendRatioHistoryInputEnvelope>;
};

export type StoreRatioHistoryItemCreateNestedManyWithoutRatioInput = {
  connect?: InputMaybe<Array<StoreRatioHistoryItemWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<StoreRatioHistoryItemCreateOrConnectWithoutRatioInput>>;
  create?: InputMaybe<Array<StoreRatioHistoryItemCreateWithoutRatioInput>>;
  createMany?: InputMaybe<StoreRatioHistoryItemCreateManyRatioInputEnvelope>;
};

export type StoreRatioHistoryItemCreateOrConnectWithoutInSaleRatioHistoryInput = {
  create: StoreRatioHistoryItemCreateWithoutInSaleRatioHistoryInput;
  where: StoreRatioHistoryItemWhereUniqueInput;
};

export type StoreRatioHistoryItemCreateOrConnectWithoutInSendRatioHistoryInput = {
  create: StoreRatioHistoryItemCreateWithoutInSendRatioHistoryInput;
  where: StoreRatioHistoryItemWhereUniqueInput;
};

export type StoreRatioHistoryItemCreateOrConnectWithoutRatioInput = {
  create: StoreRatioHistoryItemCreateWithoutRatioInput;
  where: StoreRatioHistoryItemWhereUniqueInput;
};

export type StoreRatioHistoryItemCreateWithoutInSaleRatioHistoryInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  inSendRatioHistory?: InputMaybe<ProfitableCreateNestedOneWithoutSendRatioHistoryInput>;
  ratio: StoreRatioCreateNestedOneWithoutInStoreRatioHistoryItemInput;
};

export type StoreRatioHistoryItemCreateWithoutInSendRatioHistoryInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  inSaleRatioHistory?: InputMaybe<ProfitableCreateNestedOneWithoutSaleRatioHistoryInput>;
  ratio: StoreRatioCreateNestedOneWithoutInStoreRatioHistoryItemInput;
};

export type StoreRatioHistoryItemCreateWithoutRatioInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  inSaleRatioHistory?: InputMaybe<ProfitableCreateNestedOneWithoutSaleRatioHistoryInput>;
  inSendRatioHistory?: InputMaybe<ProfitableCreateNestedOneWithoutSendRatioHistoryInput>;
};

export type StoreRatioHistoryItemGroupBy = {
  __typename?: 'StoreRatioHistoryItemGroupBy';
  _count?: Maybe<StoreRatioHistoryItemCountAggregate>;
  _max?: Maybe<StoreRatioHistoryItemMaxAggregate>;
  _min?: Maybe<StoreRatioHistoryItemMinAggregate>;
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  inSaleProfitableId?: Maybe<Scalars['String']>;
  inSendProfitableId?: Maybe<Scalars['String']>;
  ratioId: Scalars['String'];
};

export type StoreRatioHistoryItemListRelationFilter = {
  every?: InputMaybe<StoreRatioHistoryItemWhereInput>;
  none?: InputMaybe<StoreRatioHistoryItemWhereInput>;
  some?: InputMaybe<StoreRatioHistoryItemWhereInput>;
};

export type StoreRatioHistoryItemMaxAggregate = {
  __typename?: 'StoreRatioHistoryItemMaxAggregate';
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  inSaleProfitableId?: Maybe<Scalars['String']>;
  inSendProfitableId?: Maybe<Scalars['String']>;
  ratioId?: Maybe<Scalars['String']>;
};

export type StoreRatioHistoryItemMaxOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  inSaleProfitableId?: InputMaybe<SortOrder>;
  inSendProfitableId?: InputMaybe<SortOrder>;
  ratioId?: InputMaybe<SortOrder>;
};

export type StoreRatioHistoryItemMinAggregate = {
  __typename?: 'StoreRatioHistoryItemMinAggregate';
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  inSaleProfitableId?: Maybe<Scalars['String']>;
  inSendProfitableId?: Maybe<Scalars['String']>;
  ratioId?: Maybe<Scalars['String']>;
};

export type StoreRatioHistoryItemMinOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  inSaleProfitableId?: InputMaybe<SortOrder>;
  inSendProfitableId?: InputMaybe<SortOrder>;
  ratioId?: InputMaybe<SortOrder>;
};

export type StoreRatioHistoryItemOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type StoreRatioHistoryItemOrderByWithAggregationInput = {
  _count?: InputMaybe<StoreRatioHistoryItemCountOrderByAggregateInput>;
  _max?: InputMaybe<StoreRatioHistoryItemMaxOrderByAggregateInput>;
  _min?: InputMaybe<StoreRatioHistoryItemMinOrderByAggregateInput>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  inSaleProfitableId?: InputMaybe<SortOrder>;
  inSendProfitableId?: InputMaybe<SortOrder>;
  ratioId?: InputMaybe<SortOrder>;
};

export type StoreRatioHistoryItemOrderByWithRelationInput = {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  inSaleProfitableId?: InputMaybe<SortOrder>;
  inSaleRatioHistory?: InputMaybe<ProfitableOrderByWithRelationInput>;
  inSendProfitableId?: InputMaybe<SortOrder>;
  inSendRatioHistory?: InputMaybe<ProfitableOrderByWithRelationInput>;
  ratio?: InputMaybe<StoreRatioOrderByWithRelationInput>;
  ratioId?: InputMaybe<SortOrder>;
};

export enum StoreRatioHistoryItemScalarFieldEnum {
  CreatedAt = 'createdAt',
  Id = 'id',
  InSaleProfitableId = 'inSaleProfitableId',
  InSendProfitableId = 'inSendProfitableId',
  RatioId = 'ratioId'
}

export type StoreRatioHistoryItemScalarWhereInput = {
  AND?: InputMaybe<Array<StoreRatioHistoryItemScalarWhereInput>>;
  NOT?: InputMaybe<Array<StoreRatioHistoryItemScalarWhereInput>>;
  OR?: InputMaybe<Array<StoreRatioHistoryItemScalarWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  inSaleProfitableId?: InputMaybe<StringNullableFilter>;
  inSendProfitableId?: InputMaybe<StringNullableFilter>;
  ratioId?: InputMaybe<StringFilter>;
};

export type StoreRatioHistoryItemScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<StoreRatioHistoryItemScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<StoreRatioHistoryItemScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<StoreRatioHistoryItemScalarWhereWithAggregatesInput>>;
  createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  id?: InputMaybe<StringWithAggregatesFilter>;
  inSaleProfitableId?: InputMaybe<StringNullableWithAggregatesFilter>;
  inSendProfitableId?: InputMaybe<StringNullableWithAggregatesFilter>;
  ratioId?: InputMaybe<StringWithAggregatesFilter>;
};

export type StoreRatioHistoryItemUpdateInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  inSaleRatioHistory?: InputMaybe<ProfitableUpdateOneWithoutSaleRatioHistoryNestedInput>;
  inSendRatioHistory?: InputMaybe<ProfitableUpdateOneWithoutSendRatioHistoryNestedInput>;
  ratio?: InputMaybe<StoreRatioUpdateOneRequiredWithoutInStoreRatioHistoryItemNestedInput>;
};

export type StoreRatioHistoryItemUpdateManyMutationInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type StoreRatioHistoryItemUpdateManyWithWhereWithoutInSaleRatioHistoryInput = {
  data: StoreRatioHistoryItemUpdateManyMutationInput;
  where: StoreRatioHistoryItemScalarWhereInput;
};

export type StoreRatioHistoryItemUpdateManyWithWhereWithoutInSendRatioHistoryInput = {
  data: StoreRatioHistoryItemUpdateManyMutationInput;
  where: StoreRatioHistoryItemScalarWhereInput;
};

export type StoreRatioHistoryItemUpdateManyWithWhereWithoutRatioInput = {
  data: StoreRatioHistoryItemUpdateManyMutationInput;
  where: StoreRatioHistoryItemScalarWhereInput;
};

export type StoreRatioHistoryItemUpdateManyWithoutInSaleRatioHistoryNestedInput = {
  connect?: InputMaybe<Array<StoreRatioHistoryItemWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<StoreRatioHistoryItemCreateOrConnectWithoutInSaleRatioHistoryInput>>;
  create?: InputMaybe<Array<StoreRatioHistoryItemCreateWithoutInSaleRatioHistoryInput>>;
  createMany?: InputMaybe<StoreRatioHistoryItemCreateManyInSaleRatioHistoryInputEnvelope>;
  delete?: InputMaybe<Array<StoreRatioHistoryItemWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<StoreRatioHistoryItemScalarWhereInput>>;
  disconnect?: InputMaybe<Array<StoreRatioHistoryItemWhereUniqueInput>>;
  set?: InputMaybe<Array<StoreRatioHistoryItemWhereUniqueInput>>;
  update?: InputMaybe<Array<StoreRatioHistoryItemUpdateWithWhereUniqueWithoutInSaleRatioHistoryInput>>;
  updateMany?: InputMaybe<Array<StoreRatioHistoryItemUpdateManyWithWhereWithoutInSaleRatioHistoryInput>>;
  upsert?: InputMaybe<Array<StoreRatioHistoryItemUpsertWithWhereUniqueWithoutInSaleRatioHistoryInput>>;
};

export type StoreRatioHistoryItemUpdateManyWithoutInSendRatioHistoryNestedInput = {
  connect?: InputMaybe<Array<StoreRatioHistoryItemWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<StoreRatioHistoryItemCreateOrConnectWithoutInSendRatioHistoryInput>>;
  create?: InputMaybe<Array<StoreRatioHistoryItemCreateWithoutInSendRatioHistoryInput>>;
  createMany?: InputMaybe<StoreRatioHistoryItemCreateManyInSendRatioHistoryInputEnvelope>;
  delete?: InputMaybe<Array<StoreRatioHistoryItemWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<StoreRatioHistoryItemScalarWhereInput>>;
  disconnect?: InputMaybe<Array<StoreRatioHistoryItemWhereUniqueInput>>;
  set?: InputMaybe<Array<StoreRatioHistoryItemWhereUniqueInput>>;
  update?: InputMaybe<Array<StoreRatioHistoryItemUpdateWithWhereUniqueWithoutInSendRatioHistoryInput>>;
  updateMany?: InputMaybe<Array<StoreRatioHistoryItemUpdateManyWithWhereWithoutInSendRatioHistoryInput>>;
  upsert?: InputMaybe<Array<StoreRatioHistoryItemUpsertWithWhereUniqueWithoutInSendRatioHistoryInput>>;
};

export type StoreRatioHistoryItemUpdateManyWithoutRatioNestedInput = {
  connect?: InputMaybe<Array<StoreRatioHistoryItemWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<StoreRatioHistoryItemCreateOrConnectWithoutRatioInput>>;
  create?: InputMaybe<Array<StoreRatioHistoryItemCreateWithoutRatioInput>>;
  createMany?: InputMaybe<StoreRatioHistoryItemCreateManyRatioInputEnvelope>;
  delete?: InputMaybe<Array<StoreRatioHistoryItemWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<StoreRatioHistoryItemScalarWhereInput>>;
  disconnect?: InputMaybe<Array<StoreRatioHistoryItemWhereUniqueInput>>;
  set?: InputMaybe<Array<StoreRatioHistoryItemWhereUniqueInput>>;
  update?: InputMaybe<Array<StoreRatioHistoryItemUpdateWithWhereUniqueWithoutRatioInput>>;
  updateMany?: InputMaybe<Array<StoreRatioHistoryItemUpdateManyWithWhereWithoutRatioInput>>;
  upsert?: InputMaybe<Array<StoreRatioHistoryItemUpsertWithWhereUniqueWithoutRatioInput>>;
};

export type StoreRatioHistoryItemUpdateWithWhereUniqueWithoutInSaleRatioHistoryInput = {
  data: StoreRatioHistoryItemUpdateWithoutInSaleRatioHistoryInput;
  where: StoreRatioHistoryItemWhereUniqueInput;
};

export type StoreRatioHistoryItemUpdateWithWhereUniqueWithoutInSendRatioHistoryInput = {
  data: StoreRatioHistoryItemUpdateWithoutInSendRatioHistoryInput;
  where: StoreRatioHistoryItemWhereUniqueInput;
};

export type StoreRatioHistoryItemUpdateWithWhereUniqueWithoutRatioInput = {
  data: StoreRatioHistoryItemUpdateWithoutRatioInput;
  where: StoreRatioHistoryItemWhereUniqueInput;
};

export type StoreRatioHistoryItemUpdateWithoutInSaleRatioHistoryInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  inSendRatioHistory?: InputMaybe<ProfitableUpdateOneWithoutSendRatioHistoryNestedInput>;
  ratio?: InputMaybe<StoreRatioUpdateOneRequiredWithoutInStoreRatioHistoryItemNestedInput>;
};

export type StoreRatioHistoryItemUpdateWithoutInSendRatioHistoryInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  inSaleRatioHistory?: InputMaybe<ProfitableUpdateOneWithoutSaleRatioHistoryNestedInput>;
  ratio?: InputMaybe<StoreRatioUpdateOneRequiredWithoutInStoreRatioHistoryItemNestedInput>;
};

export type StoreRatioHistoryItemUpdateWithoutRatioInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  inSaleRatioHistory?: InputMaybe<ProfitableUpdateOneWithoutSaleRatioHistoryNestedInput>;
  inSendRatioHistory?: InputMaybe<ProfitableUpdateOneWithoutSendRatioHistoryNestedInput>;
};

export type StoreRatioHistoryItemUpsertWithWhereUniqueWithoutInSaleRatioHistoryInput = {
  create: StoreRatioHistoryItemCreateWithoutInSaleRatioHistoryInput;
  update: StoreRatioHistoryItemUpdateWithoutInSaleRatioHistoryInput;
  where: StoreRatioHistoryItemWhereUniqueInput;
};

export type StoreRatioHistoryItemUpsertWithWhereUniqueWithoutInSendRatioHistoryInput = {
  create: StoreRatioHistoryItemCreateWithoutInSendRatioHistoryInput;
  update: StoreRatioHistoryItemUpdateWithoutInSendRatioHistoryInput;
  where: StoreRatioHistoryItemWhereUniqueInput;
};

export type StoreRatioHistoryItemUpsertWithWhereUniqueWithoutRatioInput = {
  create: StoreRatioHistoryItemCreateWithoutRatioInput;
  update: StoreRatioHistoryItemUpdateWithoutRatioInput;
  where: StoreRatioHistoryItemWhereUniqueInput;
};

export type StoreRatioHistoryItemWhereInput = {
  AND?: InputMaybe<Array<StoreRatioHistoryItemWhereInput>>;
  NOT?: InputMaybe<Array<StoreRatioHistoryItemWhereInput>>;
  OR?: InputMaybe<Array<StoreRatioHistoryItemWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  inSaleProfitableId?: InputMaybe<StringNullableFilter>;
  inSaleRatioHistory?: InputMaybe<ProfitableRelationFilter>;
  inSendProfitableId?: InputMaybe<StringNullableFilter>;
  inSendRatioHistory?: InputMaybe<ProfitableRelationFilter>;
  ratio?: InputMaybe<StoreRatioRelationFilter>;
  ratioId?: InputMaybe<StringFilter>;
};

export type StoreRatioHistoryItemWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
};

export type StoreRatioMaxAggregate = {
  __typename?: 'StoreRatioMaxAggregate';
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  end?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  start?: Maybe<Scalars['DateTime']>;
  value?: Maybe<Scalars['Float']>;
};

export type StoreRatioMaxOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  end?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  start?: InputMaybe<SortOrder>;
  value?: InputMaybe<SortOrder>;
};

export type StoreRatioMinAggregate = {
  __typename?: 'StoreRatioMinAggregate';
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  end?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  start?: Maybe<Scalars['DateTime']>;
  value?: Maybe<Scalars['Float']>;
};

export type StoreRatioMinOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  end?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  start?: InputMaybe<SortOrder>;
  value?: InputMaybe<SortOrder>;
};

export type StoreRatioOrderByWithAggregationInput = {
  _avg?: InputMaybe<StoreRatioAvgOrderByAggregateInput>;
  _count?: InputMaybe<StoreRatioCountOrderByAggregateInput>;
  _max?: InputMaybe<StoreRatioMaxOrderByAggregateInput>;
  _min?: InputMaybe<StoreRatioMinOrderByAggregateInput>;
  _sum?: InputMaybe<StoreRatioSumOrderByAggregateInput>;
  createdAt?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  end?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  start?: InputMaybe<SortOrder>;
  value?: InputMaybe<SortOrder>;
};

export type StoreRatioOrderByWithRelationInput = {
  createdAt?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  end?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  inBenefitMemoToCoin?: InputMaybe<BenefitMemoToCoinOrderByRelationAggregateInput>;
  inBenefitMemoToOrder?: InputMaybe<BenefitMemoToOrderOrderByRelationAggregateInput>;
  inSaleRatio?: InputMaybe<ProfitableOrderByRelationAggregateInput>;
  inSendRatio?: InputMaybe<ProfitableOrderByRelationAggregateInput>;
  inStoreRatioHistoryItem?: InputMaybe<StoreRatioHistoryItemOrderByRelationAggregateInput>;
  start?: InputMaybe<SortOrder>;
  value?: InputMaybe<SortOrder>;
};

export type StoreRatioRelationFilter = {
  is?: InputMaybe<StoreRatioWhereInput>;
  isNot?: InputMaybe<StoreRatioWhereInput>;
};

export enum StoreRatioScalarFieldEnum {
  CreatedAt = 'createdAt',
  Description = 'description',
  End = 'end',
  Id = 'id',
  Start = 'start',
  Value = 'value'
}

export type StoreRatioScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<StoreRatioScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<StoreRatioScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<StoreRatioScalarWhereWithAggregatesInput>>;
  createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  description?: InputMaybe<StringWithAggregatesFilter>;
  end?: InputMaybe<DateTimeNullableWithAggregatesFilter>;
  id?: InputMaybe<StringWithAggregatesFilter>;
  start?: InputMaybe<DateTimeNullableWithAggregatesFilter>;
  value?: InputMaybe<FloatWithAggregatesFilter>;
};

export type StoreRatioSumAggregate = {
  __typename?: 'StoreRatioSumAggregate';
  value?: Maybe<Scalars['Float']>;
};

export type StoreRatioSumOrderByAggregateInput = {
  value?: InputMaybe<SortOrder>;
};

export type StoreRatioUpdateInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<StringFieldUpdateOperationsInput>;
  end?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  inBenefitMemoToCoin?: InputMaybe<BenefitMemoToCoinUpdateManyWithoutBenefitRatioNestedInput>;
  inBenefitMemoToOrder?: InputMaybe<BenefitMemoToOrderUpdateManyWithoutBenefitRatioNestedInput>;
  inSaleRatio?: InputMaybe<ProfitableUpdateManyWithoutSaleRatioNestedInput>;
  inSendRatio?: InputMaybe<ProfitableUpdateManyWithoutSendRatioNestedInput>;
  inStoreRatioHistoryItem?: InputMaybe<StoreRatioHistoryItemUpdateManyWithoutRatioNestedInput>;
  start?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  value?: InputMaybe<FloatFieldUpdateOperationsInput>;
};

export type StoreRatioUpdateManyMutationInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<StringFieldUpdateOperationsInput>;
  end?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  start?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  value?: InputMaybe<FloatFieldUpdateOperationsInput>;
};

export type StoreRatioUpdateOneRequiredWithoutInBenefitMemoToCoinNestedInput = {
  connect?: InputMaybe<StoreRatioWhereUniqueInput>;
  connectOrCreate?: InputMaybe<StoreRatioCreateOrConnectWithoutInBenefitMemoToCoinInput>;
  create?: InputMaybe<StoreRatioCreateWithoutInBenefitMemoToCoinInput>;
  update?: InputMaybe<StoreRatioUpdateWithoutInBenefitMemoToCoinInput>;
  upsert?: InputMaybe<StoreRatioUpsertWithoutInBenefitMemoToCoinInput>;
};

export type StoreRatioUpdateOneRequiredWithoutInBenefitMemoToOrderNestedInput = {
  connect?: InputMaybe<StoreRatioWhereUniqueInput>;
  connectOrCreate?: InputMaybe<StoreRatioCreateOrConnectWithoutInBenefitMemoToOrderInput>;
  create?: InputMaybe<StoreRatioCreateWithoutInBenefitMemoToOrderInput>;
  update?: InputMaybe<StoreRatioUpdateWithoutInBenefitMemoToOrderInput>;
  upsert?: InputMaybe<StoreRatioUpsertWithoutInBenefitMemoToOrderInput>;
};

export type StoreRatioUpdateOneRequiredWithoutInStoreRatioHistoryItemNestedInput = {
  connect?: InputMaybe<StoreRatioWhereUniqueInput>;
  connectOrCreate?: InputMaybe<StoreRatioCreateOrConnectWithoutInStoreRatioHistoryItemInput>;
  create?: InputMaybe<StoreRatioCreateWithoutInStoreRatioHistoryItemInput>;
  update?: InputMaybe<StoreRatioUpdateWithoutInStoreRatioHistoryItemInput>;
  upsert?: InputMaybe<StoreRatioUpsertWithoutInStoreRatioHistoryItemInput>;
};

export type StoreRatioUpdateOneWithoutInSaleRatioNestedInput = {
  connect?: InputMaybe<StoreRatioWhereUniqueInput>;
  connectOrCreate?: InputMaybe<StoreRatioCreateOrConnectWithoutInSaleRatioInput>;
  create?: InputMaybe<StoreRatioCreateWithoutInSaleRatioInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<StoreRatioUpdateWithoutInSaleRatioInput>;
  upsert?: InputMaybe<StoreRatioUpsertWithoutInSaleRatioInput>;
};

export type StoreRatioUpdateOneWithoutInSendRatioNestedInput = {
  connect?: InputMaybe<StoreRatioWhereUniqueInput>;
  connectOrCreate?: InputMaybe<StoreRatioCreateOrConnectWithoutInSendRatioInput>;
  create?: InputMaybe<StoreRatioCreateWithoutInSendRatioInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<StoreRatioUpdateWithoutInSendRatioInput>;
  upsert?: InputMaybe<StoreRatioUpsertWithoutInSendRatioInput>;
};

export type StoreRatioUpdateWithoutInBenefitMemoToCoinInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<StringFieldUpdateOperationsInput>;
  end?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  inBenefitMemoToOrder?: InputMaybe<BenefitMemoToOrderUpdateManyWithoutBenefitRatioNestedInput>;
  inSaleRatio?: InputMaybe<ProfitableUpdateManyWithoutSaleRatioNestedInput>;
  inSendRatio?: InputMaybe<ProfitableUpdateManyWithoutSendRatioNestedInput>;
  inStoreRatioHistoryItem?: InputMaybe<StoreRatioHistoryItemUpdateManyWithoutRatioNestedInput>;
  start?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  value?: InputMaybe<FloatFieldUpdateOperationsInput>;
};

export type StoreRatioUpdateWithoutInBenefitMemoToOrderInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<StringFieldUpdateOperationsInput>;
  end?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  inBenefitMemoToCoin?: InputMaybe<BenefitMemoToCoinUpdateManyWithoutBenefitRatioNestedInput>;
  inSaleRatio?: InputMaybe<ProfitableUpdateManyWithoutSaleRatioNestedInput>;
  inSendRatio?: InputMaybe<ProfitableUpdateManyWithoutSendRatioNestedInput>;
  inStoreRatioHistoryItem?: InputMaybe<StoreRatioHistoryItemUpdateManyWithoutRatioNestedInput>;
  start?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  value?: InputMaybe<FloatFieldUpdateOperationsInput>;
};

export type StoreRatioUpdateWithoutInSaleRatioInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<StringFieldUpdateOperationsInput>;
  end?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  inBenefitMemoToCoin?: InputMaybe<BenefitMemoToCoinUpdateManyWithoutBenefitRatioNestedInput>;
  inBenefitMemoToOrder?: InputMaybe<BenefitMemoToOrderUpdateManyWithoutBenefitRatioNestedInput>;
  inSendRatio?: InputMaybe<ProfitableUpdateManyWithoutSendRatioNestedInput>;
  inStoreRatioHistoryItem?: InputMaybe<StoreRatioHistoryItemUpdateManyWithoutRatioNestedInput>;
  start?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  value?: InputMaybe<FloatFieldUpdateOperationsInput>;
};

export type StoreRatioUpdateWithoutInSendRatioInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<StringFieldUpdateOperationsInput>;
  end?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  inBenefitMemoToCoin?: InputMaybe<BenefitMemoToCoinUpdateManyWithoutBenefitRatioNestedInput>;
  inBenefitMemoToOrder?: InputMaybe<BenefitMemoToOrderUpdateManyWithoutBenefitRatioNestedInput>;
  inSaleRatio?: InputMaybe<ProfitableUpdateManyWithoutSaleRatioNestedInput>;
  inStoreRatioHistoryItem?: InputMaybe<StoreRatioHistoryItemUpdateManyWithoutRatioNestedInput>;
  start?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  value?: InputMaybe<FloatFieldUpdateOperationsInput>;
};

export type StoreRatioUpdateWithoutInStoreRatioHistoryItemInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<StringFieldUpdateOperationsInput>;
  end?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  inBenefitMemoToCoin?: InputMaybe<BenefitMemoToCoinUpdateManyWithoutBenefitRatioNestedInput>;
  inBenefitMemoToOrder?: InputMaybe<BenefitMemoToOrderUpdateManyWithoutBenefitRatioNestedInput>;
  inSaleRatio?: InputMaybe<ProfitableUpdateManyWithoutSaleRatioNestedInput>;
  inSendRatio?: InputMaybe<ProfitableUpdateManyWithoutSendRatioNestedInput>;
  start?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  value?: InputMaybe<FloatFieldUpdateOperationsInput>;
};

export type StoreRatioUpsertWithoutInBenefitMemoToCoinInput = {
  create: StoreRatioCreateWithoutInBenefitMemoToCoinInput;
  update: StoreRatioUpdateWithoutInBenefitMemoToCoinInput;
};

export type StoreRatioUpsertWithoutInBenefitMemoToOrderInput = {
  create: StoreRatioCreateWithoutInBenefitMemoToOrderInput;
  update: StoreRatioUpdateWithoutInBenefitMemoToOrderInput;
};

export type StoreRatioUpsertWithoutInSaleRatioInput = {
  create: StoreRatioCreateWithoutInSaleRatioInput;
  update: StoreRatioUpdateWithoutInSaleRatioInput;
};

export type StoreRatioUpsertWithoutInSendRatioInput = {
  create: StoreRatioCreateWithoutInSendRatioInput;
  update: StoreRatioUpdateWithoutInSendRatioInput;
};

export type StoreRatioUpsertWithoutInStoreRatioHistoryItemInput = {
  create: StoreRatioCreateWithoutInStoreRatioHistoryItemInput;
  update: StoreRatioUpdateWithoutInStoreRatioHistoryItemInput;
};

export type StoreRatioWhereInput = {
  AND?: InputMaybe<Array<StoreRatioWhereInput>>;
  NOT?: InputMaybe<Array<StoreRatioWhereInput>>;
  OR?: InputMaybe<Array<StoreRatioWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  description?: InputMaybe<StringFilter>;
  end?: InputMaybe<DateTimeNullableFilter>;
  id?: InputMaybe<StringFilter>;
  inBenefitMemoToCoin?: InputMaybe<BenefitMemoToCoinListRelationFilter>;
  inBenefitMemoToOrder?: InputMaybe<BenefitMemoToOrderListRelationFilter>;
  inSaleRatio?: InputMaybe<ProfitableListRelationFilter>;
  inSendRatio?: InputMaybe<ProfitableListRelationFilter>;
  inStoreRatioHistoryItem?: InputMaybe<StoreRatioHistoryItemListRelationFilter>;
  start?: InputMaybe<DateTimeNullableFilter>;
  value?: InputMaybe<FloatFilter>;
};

export type StoreRatioWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
};

export type StoreSearchResultItem = {
  __typename?: 'StoreSearchResultItem';
  country?: Maybe<Scalars['String']>;
  iconUri?: Maybe<Scalars['String']>;
  id2: Scalars['String'];
  isCertified?: Maybe<Scalars['Boolean']>;
  isFollowed?: Maybe<Scalars['Boolean']>;
  postcardCount?: Maybe<Scalars['Int']>;
  price?: Maybe<Scalars['Decimal']>;
  priceOriginal?: Maybe<Scalars['Decimal']>;
  stampCount?: Maybe<Scalars['Int']>;
  storeItemId?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  type: StoreSearchResultItemType;
};

export enum StoreSearchResultItemType {
  Postcard = 'Postcard',
  Profitable = 'Profitable',
  ScenicSpot = 'ScenicSpot',
  Stamp = 'Stamp'
}

export type StringFieldUpdateOperationsInput = {
  set?: InputMaybe<Scalars['String']>;
};

export type StringFilter = {
  contains?: InputMaybe<Scalars['String']>;
  endsWith?: InputMaybe<Scalars['String']>;
  equals?: InputMaybe<Scalars['String']>;
  gt?: InputMaybe<Scalars['String']>;
  gte?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<Scalars['String']>>;
  lt?: InputMaybe<Scalars['String']>;
  lte?: InputMaybe<Scalars['String']>;
  mode?: InputMaybe<QueryMode>;
  not?: InputMaybe<NestedStringFilter>;
  notIn?: InputMaybe<Array<Scalars['String']>>;
  startsWith?: InputMaybe<Scalars['String']>;
};

export type StringNullableFilter = {
  contains?: InputMaybe<Scalars['String']>;
  endsWith?: InputMaybe<Scalars['String']>;
  equals?: InputMaybe<Scalars['String']>;
  gt?: InputMaybe<Scalars['String']>;
  gte?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<Scalars['String']>>;
  lt?: InputMaybe<Scalars['String']>;
  lte?: InputMaybe<Scalars['String']>;
  mode?: InputMaybe<QueryMode>;
  not?: InputMaybe<NestedStringNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['String']>>;
  startsWith?: InputMaybe<Scalars['String']>;
};

export type StringNullableListFilter = {
  equals?: InputMaybe<Array<Scalars['String']>>;
  has?: InputMaybe<Scalars['String']>;
  hasEvery?: InputMaybe<Array<Scalars['String']>>;
  hasSome?: InputMaybe<Array<Scalars['String']>>;
  isEmpty?: InputMaybe<Scalars['Boolean']>;
};

export type StringNullableWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntNullableFilter>;
  _max?: InputMaybe<NestedStringNullableFilter>;
  _min?: InputMaybe<NestedStringNullableFilter>;
  contains?: InputMaybe<Scalars['String']>;
  endsWith?: InputMaybe<Scalars['String']>;
  equals?: InputMaybe<Scalars['String']>;
  gt?: InputMaybe<Scalars['String']>;
  gte?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<Scalars['String']>>;
  lt?: InputMaybe<Scalars['String']>;
  lte?: InputMaybe<Scalars['String']>;
  mode?: InputMaybe<QueryMode>;
  not?: InputMaybe<NestedStringNullableWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['String']>>;
  startsWith?: InputMaybe<Scalars['String']>;
};

export type StringWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedStringFilter>;
  _min?: InputMaybe<NestedStringFilter>;
  contains?: InputMaybe<Scalars['String']>;
  endsWith?: InputMaybe<Scalars['String']>;
  equals?: InputMaybe<Scalars['String']>;
  gt?: InputMaybe<Scalars['String']>;
  gte?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<Scalars['String']>>;
  lt?: InputMaybe<Scalars['String']>;
  lte?: InputMaybe<Scalars['String']>;
  mode?: InputMaybe<QueryMode>;
  not?: InputMaybe<NestedStringWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['String']>>;
  startsWith?: InputMaybe<Scalars['String']>;
};

export type Tappay = {
  __typename?: 'Tappay';
  _count?: Maybe<TappayCount>;
  amount: Scalars['Decimal'];
  backend_notify_log?: Maybe<Scalars['String']>;
  bank_transaction_id: Scalars['String'];
  cardholder?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  currency: Scalars['String'];
  details: Scalars['String'];
  id: Scalars['String'];
  methodType: TappayMethodType;
  payment: Array<Payment>;
  prime?: Maybe<Scalars['String']>;
  rec_trade_id?: Maybe<Scalars['String']>;
  refundAt?: Maybe<Scalars['DateTime']>;
  refundResponse?: Maybe<Scalars['String']>;
  response_log?: Maybe<Scalars['String']>;
  response_msg?: Maybe<Scalars['String']>;
  response_status?: Maybe<Scalars['Int']>;
  three_domain_secure: Scalars['Boolean'];
  transactionDoneAt?: Maybe<Scalars['DateTime']>;
  updatedAt: Scalars['DateTime'];
};


export type TappayPaymentArgs = {
  cursor?: InputMaybe<PaymentWhereUniqueInput>;
  distinct?: InputMaybe<Array<PaymentScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<PaymentOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PaymentWhereInput>;
};

export type TappayAvgAggregate = {
  __typename?: 'TappayAvgAggregate';
  amount?: Maybe<Scalars['Decimal']>;
  response_status?: Maybe<Scalars['Float']>;
};

export type TappayAvgOrderByAggregateInput = {
  amount?: InputMaybe<SortOrder>;
  response_status?: InputMaybe<SortOrder>;
};

export type TappayCount = {
  __typename?: 'TappayCount';
  payment: Scalars['Int'];
};


export type TappayCountPaymentArgs = {
  where?: InputMaybe<PaymentWhereInput>;
};

export type TappayCountAggregate = {
  __typename?: 'TappayCountAggregate';
  _all: Scalars['Int'];
  amount: Scalars['Int'];
  backend_notify_log: Scalars['Int'];
  bank_transaction_id: Scalars['Int'];
  cardholder: Scalars['Int'];
  createdAt: Scalars['Int'];
  currency: Scalars['Int'];
  details: Scalars['Int'];
  id: Scalars['Int'];
  methodType: Scalars['Int'];
  prime: Scalars['Int'];
  rec_trade_id: Scalars['Int'];
  refundAt: Scalars['Int'];
  refundResponse: Scalars['Int'];
  response_log: Scalars['Int'];
  response_msg: Scalars['Int'];
  response_status: Scalars['Int'];
  three_domain_secure: Scalars['Int'];
  transactionDoneAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
};

export type TappayCountOrderByAggregateInput = {
  amount?: InputMaybe<SortOrder>;
  backend_notify_log?: InputMaybe<SortOrder>;
  bank_transaction_id?: InputMaybe<SortOrder>;
  cardholder?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  currency?: InputMaybe<SortOrder>;
  details?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  methodType?: InputMaybe<SortOrder>;
  prime?: InputMaybe<SortOrder>;
  rec_trade_id?: InputMaybe<SortOrder>;
  refundAt?: InputMaybe<SortOrder>;
  refundResponse?: InputMaybe<SortOrder>;
  response_log?: InputMaybe<SortOrder>;
  response_msg?: InputMaybe<SortOrder>;
  response_status?: InputMaybe<SortOrder>;
  three_domain_secure?: InputMaybe<SortOrder>;
  transactionDoneAt?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type TappayCreateInput = {
  amount: Scalars['Decimal'];
  backend_notify_log?: InputMaybe<Scalars['String']>;
  bank_transaction_id?: InputMaybe<Scalars['String']>;
  cardholder?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  currency: Scalars['String'];
  details: Scalars['String'];
  id?: InputMaybe<Scalars['String']>;
  methodType?: InputMaybe<TappayMethodType>;
  payment?: InputMaybe<PaymentCreateNestedManyWithoutTappayInput>;
  prime?: InputMaybe<Scalars['String']>;
  rec_trade_id?: InputMaybe<Scalars['String']>;
  refundAt?: InputMaybe<Scalars['DateTime']>;
  refundResponse?: InputMaybe<Scalars['String']>;
  response_log?: InputMaybe<Scalars['String']>;
  response_msg?: InputMaybe<Scalars['String']>;
  response_status?: InputMaybe<Scalars['Int']>;
  three_domain_secure?: InputMaybe<Scalars['Boolean']>;
  transactionDoneAt?: InputMaybe<Scalars['DateTime']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type TappayCreateManyInput = {
  amount: Scalars['Decimal'];
  backend_notify_log?: InputMaybe<Scalars['String']>;
  bank_transaction_id?: InputMaybe<Scalars['String']>;
  cardholder?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  currency: Scalars['String'];
  details: Scalars['String'];
  id?: InputMaybe<Scalars['String']>;
  methodType?: InputMaybe<TappayMethodType>;
  prime?: InputMaybe<Scalars['String']>;
  rec_trade_id?: InputMaybe<Scalars['String']>;
  refundAt?: InputMaybe<Scalars['DateTime']>;
  refundResponse?: InputMaybe<Scalars['String']>;
  response_log?: InputMaybe<Scalars['String']>;
  response_msg?: InputMaybe<Scalars['String']>;
  response_status?: InputMaybe<Scalars['Int']>;
  three_domain_secure?: InputMaybe<Scalars['Boolean']>;
  transactionDoneAt?: InputMaybe<Scalars['DateTime']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type TappayCreateNestedOneWithoutPaymentInput = {
  connect?: InputMaybe<TappayWhereUniqueInput>;
  connectOrCreate?: InputMaybe<TappayCreateOrConnectWithoutPaymentInput>;
  create?: InputMaybe<TappayCreateWithoutPaymentInput>;
};

export type TappayCreateOrConnectWithoutPaymentInput = {
  create: TappayCreateWithoutPaymentInput;
  where: TappayWhereUniqueInput;
};

export type TappayCreateWithoutPaymentInput = {
  amount: Scalars['Decimal'];
  backend_notify_log?: InputMaybe<Scalars['String']>;
  bank_transaction_id?: InputMaybe<Scalars['String']>;
  cardholder?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  currency: Scalars['String'];
  details: Scalars['String'];
  id?: InputMaybe<Scalars['String']>;
  methodType?: InputMaybe<TappayMethodType>;
  prime?: InputMaybe<Scalars['String']>;
  rec_trade_id?: InputMaybe<Scalars['String']>;
  refundAt?: InputMaybe<Scalars['DateTime']>;
  refundResponse?: InputMaybe<Scalars['String']>;
  response_log?: InputMaybe<Scalars['String']>;
  response_msg?: InputMaybe<Scalars['String']>;
  response_status?: InputMaybe<Scalars['Int']>;
  three_domain_secure?: InputMaybe<Scalars['Boolean']>;
  transactionDoneAt?: InputMaybe<Scalars['DateTime']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type TappayGroupBy = {
  __typename?: 'TappayGroupBy';
  _avg?: Maybe<TappayAvgAggregate>;
  _count?: Maybe<TappayCountAggregate>;
  _max?: Maybe<TappayMaxAggregate>;
  _min?: Maybe<TappayMinAggregate>;
  _sum?: Maybe<TappaySumAggregate>;
  amount: Scalars['Decimal'];
  backend_notify_log?: Maybe<Scalars['String']>;
  bank_transaction_id: Scalars['String'];
  cardholder?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  currency: Scalars['String'];
  details: Scalars['String'];
  id: Scalars['String'];
  methodType: TappayMethodType;
  prime?: Maybe<Scalars['String']>;
  rec_trade_id?: Maybe<Scalars['String']>;
  refundAt?: Maybe<Scalars['DateTime']>;
  refundResponse?: Maybe<Scalars['String']>;
  response_log?: Maybe<Scalars['String']>;
  response_msg?: Maybe<Scalars['String']>;
  response_status?: Maybe<Scalars['Int']>;
  three_domain_secure: Scalars['Boolean'];
  transactionDoneAt?: Maybe<Scalars['DateTime']>;
  updatedAt: Scalars['DateTime'];
};

export type TappayMaxAggregate = {
  __typename?: 'TappayMaxAggregate';
  amount?: Maybe<Scalars['Decimal']>;
  backend_notify_log?: Maybe<Scalars['String']>;
  bank_transaction_id?: Maybe<Scalars['String']>;
  cardholder?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currency?: Maybe<Scalars['String']>;
  details?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  methodType?: Maybe<TappayMethodType>;
  prime?: Maybe<Scalars['String']>;
  rec_trade_id?: Maybe<Scalars['String']>;
  refundAt?: Maybe<Scalars['DateTime']>;
  refundResponse?: Maybe<Scalars['String']>;
  response_log?: Maybe<Scalars['String']>;
  response_msg?: Maybe<Scalars['String']>;
  response_status?: Maybe<Scalars['Int']>;
  three_domain_secure?: Maybe<Scalars['Boolean']>;
  transactionDoneAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type TappayMaxOrderByAggregateInput = {
  amount?: InputMaybe<SortOrder>;
  backend_notify_log?: InputMaybe<SortOrder>;
  bank_transaction_id?: InputMaybe<SortOrder>;
  cardholder?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  currency?: InputMaybe<SortOrder>;
  details?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  methodType?: InputMaybe<SortOrder>;
  prime?: InputMaybe<SortOrder>;
  rec_trade_id?: InputMaybe<SortOrder>;
  refundAt?: InputMaybe<SortOrder>;
  refundResponse?: InputMaybe<SortOrder>;
  response_log?: InputMaybe<SortOrder>;
  response_msg?: InputMaybe<SortOrder>;
  response_status?: InputMaybe<SortOrder>;
  three_domain_secure?: InputMaybe<SortOrder>;
  transactionDoneAt?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export enum TappayMethodType {
  ApplePay = 'ApplePay',
  DirectPay = 'DirectPay',
  GooglePay = 'GooglePay',
  JkoPay = 'JKOPay',
  LinePay = 'LinePay'
}

export type TappayMinAggregate = {
  __typename?: 'TappayMinAggregate';
  amount?: Maybe<Scalars['Decimal']>;
  backend_notify_log?: Maybe<Scalars['String']>;
  bank_transaction_id?: Maybe<Scalars['String']>;
  cardholder?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currency?: Maybe<Scalars['String']>;
  details?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  methodType?: Maybe<TappayMethodType>;
  prime?: Maybe<Scalars['String']>;
  rec_trade_id?: Maybe<Scalars['String']>;
  refundAt?: Maybe<Scalars['DateTime']>;
  refundResponse?: Maybe<Scalars['String']>;
  response_log?: Maybe<Scalars['String']>;
  response_msg?: Maybe<Scalars['String']>;
  response_status?: Maybe<Scalars['Int']>;
  three_domain_secure?: Maybe<Scalars['Boolean']>;
  transactionDoneAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type TappayMinOrderByAggregateInput = {
  amount?: InputMaybe<SortOrder>;
  backend_notify_log?: InputMaybe<SortOrder>;
  bank_transaction_id?: InputMaybe<SortOrder>;
  cardholder?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  currency?: InputMaybe<SortOrder>;
  details?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  methodType?: InputMaybe<SortOrder>;
  prime?: InputMaybe<SortOrder>;
  rec_trade_id?: InputMaybe<SortOrder>;
  refundAt?: InputMaybe<SortOrder>;
  refundResponse?: InputMaybe<SortOrder>;
  response_log?: InputMaybe<SortOrder>;
  response_msg?: InputMaybe<SortOrder>;
  response_status?: InputMaybe<SortOrder>;
  three_domain_secure?: InputMaybe<SortOrder>;
  transactionDoneAt?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type TappayOrderByWithAggregationInput = {
  _avg?: InputMaybe<TappayAvgOrderByAggregateInput>;
  _count?: InputMaybe<TappayCountOrderByAggregateInput>;
  _max?: InputMaybe<TappayMaxOrderByAggregateInput>;
  _min?: InputMaybe<TappayMinOrderByAggregateInput>;
  _sum?: InputMaybe<TappaySumOrderByAggregateInput>;
  amount?: InputMaybe<SortOrder>;
  backend_notify_log?: InputMaybe<SortOrder>;
  bank_transaction_id?: InputMaybe<SortOrder>;
  cardholder?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  currency?: InputMaybe<SortOrder>;
  details?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  methodType?: InputMaybe<SortOrder>;
  prime?: InputMaybe<SortOrder>;
  rec_trade_id?: InputMaybe<SortOrder>;
  refundAt?: InputMaybe<SortOrder>;
  refundResponse?: InputMaybe<SortOrder>;
  response_log?: InputMaybe<SortOrder>;
  response_msg?: InputMaybe<SortOrder>;
  response_status?: InputMaybe<SortOrder>;
  three_domain_secure?: InputMaybe<SortOrder>;
  transactionDoneAt?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type TappayOrderByWithRelationInput = {
  amount?: InputMaybe<SortOrder>;
  backend_notify_log?: InputMaybe<SortOrder>;
  bank_transaction_id?: InputMaybe<SortOrder>;
  cardholder?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  currency?: InputMaybe<SortOrder>;
  details?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  methodType?: InputMaybe<SortOrder>;
  payment?: InputMaybe<PaymentOrderByRelationAggregateInput>;
  prime?: InputMaybe<SortOrder>;
  rec_trade_id?: InputMaybe<SortOrder>;
  refundAt?: InputMaybe<SortOrder>;
  refundResponse?: InputMaybe<SortOrder>;
  response_log?: InputMaybe<SortOrder>;
  response_msg?: InputMaybe<SortOrder>;
  response_status?: InputMaybe<SortOrder>;
  three_domain_secure?: InputMaybe<SortOrder>;
  transactionDoneAt?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type TappayRelationFilter = {
  is?: InputMaybe<TappayWhereInput>;
  isNot?: InputMaybe<TappayWhereInput>;
};

export enum TappayScalarFieldEnum {
  Amount = 'amount',
  BackendNotifyLog = 'backend_notify_log',
  BankTransactionId = 'bank_transaction_id',
  Cardholder = 'cardholder',
  CreatedAt = 'createdAt',
  Currency = 'currency',
  Details = 'details',
  Id = 'id',
  MethodType = 'methodType',
  Prime = 'prime',
  RecTradeId = 'rec_trade_id',
  RefundAt = 'refundAt',
  RefundResponse = 'refundResponse',
  ResponseLog = 'response_log',
  ResponseMsg = 'response_msg',
  ResponseStatus = 'response_status',
  ThreeDomainSecure = 'three_domain_secure',
  TransactionDoneAt = 'transactionDoneAt',
  UpdatedAt = 'updatedAt'
}

export type TappayScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<TappayScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<TappayScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<TappayScalarWhereWithAggregatesInput>>;
  amount?: InputMaybe<DecimalWithAggregatesFilter>;
  backend_notify_log?: InputMaybe<StringNullableWithAggregatesFilter>;
  bank_transaction_id?: InputMaybe<StringWithAggregatesFilter>;
  cardholder?: InputMaybe<StringNullableWithAggregatesFilter>;
  createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  currency?: InputMaybe<StringWithAggregatesFilter>;
  details?: InputMaybe<StringWithAggregatesFilter>;
  id?: InputMaybe<StringWithAggregatesFilter>;
  methodType?: InputMaybe<EnumTappayMethodTypeWithAggregatesFilter>;
  prime?: InputMaybe<StringNullableWithAggregatesFilter>;
  rec_trade_id?: InputMaybe<StringNullableWithAggregatesFilter>;
  refundAt?: InputMaybe<DateTimeNullableWithAggregatesFilter>;
  refundResponse?: InputMaybe<StringNullableWithAggregatesFilter>;
  response_log?: InputMaybe<StringNullableWithAggregatesFilter>;
  response_msg?: InputMaybe<StringNullableWithAggregatesFilter>;
  response_status?: InputMaybe<IntNullableWithAggregatesFilter>;
  three_domain_secure?: InputMaybe<BoolWithAggregatesFilter>;
  transactionDoneAt?: InputMaybe<DateTimeNullableWithAggregatesFilter>;
  updatedAt?: InputMaybe<DateTimeWithAggregatesFilter>;
};

export type TappaySumAggregate = {
  __typename?: 'TappaySumAggregate';
  amount?: Maybe<Scalars['Decimal']>;
  response_status?: Maybe<Scalars['Int']>;
};

export type TappaySumOrderByAggregateInput = {
  amount?: InputMaybe<SortOrder>;
  response_status?: InputMaybe<SortOrder>;
};

export type TappayUpdateInput = {
  amount?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  backend_notify_log?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  bank_transaction_id?: InputMaybe<StringFieldUpdateOperationsInput>;
  cardholder?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  currency?: InputMaybe<StringFieldUpdateOperationsInput>;
  details?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  methodType?: InputMaybe<EnumTappayMethodTypeFieldUpdateOperationsInput>;
  payment?: InputMaybe<PaymentUpdateManyWithoutTappayNestedInput>;
  prime?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  rec_trade_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  refundAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  refundResponse?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  response_log?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  response_msg?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  response_status?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  three_domain_secure?: InputMaybe<BoolFieldUpdateOperationsInput>;
  transactionDoneAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type TappayUpdateManyMutationInput = {
  amount?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  backend_notify_log?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  bank_transaction_id?: InputMaybe<StringFieldUpdateOperationsInput>;
  cardholder?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  currency?: InputMaybe<StringFieldUpdateOperationsInput>;
  details?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  methodType?: InputMaybe<EnumTappayMethodTypeFieldUpdateOperationsInput>;
  prime?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  rec_trade_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  refundAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  refundResponse?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  response_log?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  response_msg?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  response_status?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  three_domain_secure?: InputMaybe<BoolFieldUpdateOperationsInput>;
  transactionDoneAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type TappayUpdateOneWithoutPaymentNestedInput = {
  connect?: InputMaybe<TappayWhereUniqueInput>;
  connectOrCreate?: InputMaybe<TappayCreateOrConnectWithoutPaymentInput>;
  create?: InputMaybe<TappayCreateWithoutPaymentInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<TappayUpdateWithoutPaymentInput>;
  upsert?: InputMaybe<TappayUpsertWithoutPaymentInput>;
};

export type TappayUpdateWithoutPaymentInput = {
  amount?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  backend_notify_log?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  bank_transaction_id?: InputMaybe<StringFieldUpdateOperationsInput>;
  cardholder?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  currency?: InputMaybe<StringFieldUpdateOperationsInput>;
  details?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  methodType?: InputMaybe<EnumTappayMethodTypeFieldUpdateOperationsInput>;
  prime?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  rec_trade_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  refundAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  refundResponse?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  response_log?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  response_msg?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  response_status?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  three_domain_secure?: InputMaybe<BoolFieldUpdateOperationsInput>;
  transactionDoneAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type TappayUpsertWithoutPaymentInput = {
  create: TappayCreateWithoutPaymentInput;
  update: TappayUpdateWithoutPaymentInput;
};

export type TappayWhereInput = {
  AND?: InputMaybe<Array<TappayWhereInput>>;
  NOT?: InputMaybe<Array<TappayWhereInput>>;
  OR?: InputMaybe<Array<TappayWhereInput>>;
  amount?: InputMaybe<DecimalFilter>;
  backend_notify_log?: InputMaybe<StringNullableFilter>;
  bank_transaction_id?: InputMaybe<StringFilter>;
  cardholder?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  currency?: InputMaybe<StringFilter>;
  details?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  methodType?: InputMaybe<EnumTappayMethodTypeFilter>;
  payment?: InputMaybe<PaymentListRelationFilter>;
  prime?: InputMaybe<StringNullableFilter>;
  rec_trade_id?: InputMaybe<StringNullableFilter>;
  refundAt?: InputMaybe<DateTimeNullableFilter>;
  refundResponse?: InputMaybe<StringNullableFilter>;
  response_log?: InputMaybe<StringNullableFilter>;
  response_msg?: InputMaybe<StringNullableFilter>;
  response_status?: InputMaybe<IntNullableFilter>;
  three_domain_secure?: InputMaybe<BoolFilter>;
  transactionDoneAt?: InputMaybe<DateTimeNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type TappayWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
};

export type User = {
  __typename?: 'User';
  _count?: Maybe<UserCount>;
  accessToken?: Maybe<Scalars['String']>;
  address: Array<Address>;
  attachments: Array<Attachment>;
  authType: UserAuthType;
  bio?: Maybe<Scalars['String']>;
  campaignNanhui: Array<CampaignNanhui>;
  campainZeczec?: Maybe<CampainZeczec>;
  card: Array<Card>;
  coins: Array<Coin>;
  collection: Array<Collection>;
  country?: Maybe<Scalars['String']>;
  couponUsages: Array<CouponUsage>;
  createdAt: Scalars['DateTime'];
  createdStamps: Array<Stamp>;
  detail: Array<OrderDetail>;
  deviceToken: Array<DeviceToken>;
  email?: Maybe<Scalars['String']>;
  emailTos: Array<EmailTo>;
  favoriteStoreItems: Array<StoreItem>;
  fetchFriends: Array<UserFriend>;
  followProfitables: Array<Profitable>;
  followedBy: Array<User>;
  following: Array<User>;
  gender?: Maybe<Scalars['String']>;
  groupsJoin: Array<Group>;
  groupsOwn: Array<Group>;
  headUri?: Maybe<Scalars['String']>;
  history: Array<OrderDetailHistory>;
  id: Scalars['String'];
  idStr: Scalars['String'];
  inFriends: Array<UserFriend>;
  inValidReasonHistories: Array<InValidReason>;
  lastLogin?: Maybe<Scalars['DateTime']>;
  lastTokenUpdated?: Maybe<Scalars['DateTime']>;
  likePosts: Array<Post>;
  manualAddrs: Array<ManualAddr>;
  myCoupons: Array<CouponEntity>;
  name: Scalars['String'];
  notificationReceiver: Array<Notification>;
  notificationSender: Array<Notification>;
  order: Array<Order>;
  packagesAsReceiver: Array<PostPackage>;
  password: Scalars['String'];
  pcoins: Scalars['Decimal'];
  phone?: Maybe<Scalars['String']>;
  postPackage: Array<PostPackage>;
  postsIn: Array<Post>;
  postsOut: Array<Post>;
  profitable?: Maybe<Profitable>;
  receive_email?: Maybe<Scalars['String']>;
  receive_phone?: Maybe<Scalars['String']>;
  reportByMe: Array<ReportUser>;
  reported: Array<ReportUser>;
  reports: Array<Report>;
  settingNotification?: Maybe<SettingNotification>;
  settingPrivacy?: Maybe<SettingPrivacy>;
  signature: Array<Signature>;
  storePurchaseHistory: Array<CartItem>;
  tagsUsed: Array<PostTag>;
  thirdpartyId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  /** 虛擬地址區域名 */
  vAddrLandName: Scalars['String'];
  vAddrMapping?: Maybe<VirtualAddrMapping>;
  /** 虛擬地址門牌 */
  vAddrNumber: Scalars['String'];
  valid: Scalars['Boolean'];
  website?: Maybe<Scalars['String']>;
};


export type UserAddressArgs = {
  cursor?: InputMaybe<AddressWhereUniqueInput>;
  distinct?: InputMaybe<Array<AddressScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<AddressOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<AddressWhereInput>;
};


export type UserAttachmentsArgs = {
  cursor?: InputMaybe<AttachmentWhereUniqueInput>;
  distinct?: InputMaybe<Array<AttachmentScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<AttachmentOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<AttachmentWhereInput>;
};


export type UserCampaignNanhuiArgs = {
  cursor?: InputMaybe<CampaignNanhuiWhereUniqueInput>;
  distinct?: InputMaybe<Array<CampaignNanhuiScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<CampaignNanhuiOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CampaignNanhuiWhereInput>;
};


export type UserCardArgs = {
  cursor?: InputMaybe<CardWhereUniqueInput>;
  distinct?: InputMaybe<Array<CardScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<CardOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CardWhereInput>;
};


export type UserCoinsArgs = {
  cursor?: InputMaybe<CoinWhereUniqueInput>;
  distinct?: InputMaybe<Array<CoinScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<CoinOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CoinWhereInput>;
};


export type UserCollectionArgs = {
  cursor?: InputMaybe<CollectionWhereUniqueInput>;
  distinct?: InputMaybe<Array<CollectionScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<CollectionOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CollectionWhereInput>;
};


export type UserCouponUsagesArgs = {
  cursor?: InputMaybe<CouponUsageWhereUniqueInput>;
  distinct?: InputMaybe<Array<CouponUsageScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<CouponUsageOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CouponUsageWhereInput>;
};


export type UserCreatedStampsArgs = {
  cursor?: InputMaybe<StampWhereUniqueInput>;
  distinct?: InputMaybe<Array<StampScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<StampOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<StampWhereInput>;
};


export type UserDetailArgs = {
  cursor?: InputMaybe<OrderDetailWhereUniqueInput>;
  distinct?: InputMaybe<Array<OrderDetailScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<OrderDetailOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<OrderDetailWhereInput>;
};


export type UserDeviceTokenArgs = {
  cursor?: InputMaybe<DeviceTokenWhereUniqueInput>;
  distinct?: InputMaybe<Array<DeviceTokenScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<DeviceTokenOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<DeviceTokenWhereInput>;
};


export type UserEmailTosArgs = {
  cursor?: InputMaybe<EmailToWhereUniqueInput>;
  distinct?: InputMaybe<Array<EmailToScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<EmailToOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<EmailToWhereInput>;
};


export type UserFavoriteStoreItemsArgs = {
  cursor?: InputMaybe<StoreItemWhereUniqueInput>;
  distinct?: InputMaybe<Array<StoreItemScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<StoreItemOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<StoreItemWhereInput>;
};


export type UserFetchFriendsArgs = {
  cursor?: InputMaybe<UserFriendWhereUniqueInput>;
  distinct?: InputMaybe<Array<UserFriendScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<UserFriendOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<UserFriendWhereInput>;
};


export type UserFollowProfitablesArgs = {
  cursor?: InputMaybe<ProfitableWhereUniqueInput>;
  distinct?: InputMaybe<Array<ProfitableScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ProfitableOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ProfitableWhereInput>;
};


export type UserFollowedByArgs = {
  cursor?: InputMaybe<UserWhereUniqueInput>;
  distinct?: InputMaybe<Array<UserScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<UserOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<UserWhereInput>;
};


export type UserFollowingArgs = {
  cursor?: InputMaybe<UserWhereUniqueInput>;
  distinct?: InputMaybe<Array<UserScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<UserOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<UserWhereInput>;
};


export type UserGroupsJoinArgs = {
  cursor?: InputMaybe<GroupWhereUniqueInput>;
  distinct?: InputMaybe<Array<GroupScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<GroupOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<GroupWhereInput>;
};


export type UserGroupsOwnArgs = {
  cursor?: InputMaybe<GroupWhereUniqueInput>;
  distinct?: InputMaybe<Array<GroupScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<GroupOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<GroupWhereInput>;
};


export type UserHistoryArgs = {
  cursor?: InputMaybe<OrderDetailHistoryWhereUniqueInput>;
  distinct?: InputMaybe<Array<OrderDetailHistoryScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<OrderDetailHistoryOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<OrderDetailHistoryWhereInput>;
};


export type UserInFriendsArgs = {
  cursor?: InputMaybe<UserFriendWhereUniqueInput>;
  distinct?: InputMaybe<Array<UserFriendScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<UserFriendOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<UserFriendWhereInput>;
};


export type UserInValidReasonHistoriesArgs = {
  cursor?: InputMaybe<InValidReasonWhereUniqueInput>;
  distinct?: InputMaybe<Array<InValidReasonScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<InValidReasonOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<InValidReasonWhereInput>;
};


export type UserLikePostsArgs = {
  cursor?: InputMaybe<PostWhereUniqueInput>;
  distinct?: InputMaybe<Array<PostScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<PostOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PostWhereInput>;
};


export type UserManualAddrsArgs = {
  cursor?: InputMaybe<ManualAddrWhereUniqueInput>;
  distinct?: InputMaybe<Array<ManualAddrScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ManualAddrOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ManualAddrWhereInput>;
};


export type UserMyCouponsArgs = {
  cursor?: InputMaybe<CouponEntityWhereUniqueInput>;
  distinct?: InputMaybe<Array<CouponEntityScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<CouponEntityOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CouponEntityWhereInput>;
};


export type UserNotificationReceiverArgs = {
  cursor?: InputMaybe<NotificationWhereUniqueInput>;
  distinct?: InputMaybe<Array<NotificationScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<NotificationOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<NotificationWhereInput>;
};


export type UserNotificationSenderArgs = {
  cursor?: InputMaybe<NotificationWhereUniqueInput>;
  distinct?: InputMaybe<Array<NotificationScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<NotificationOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<NotificationWhereInput>;
};


export type UserOrderArgs = {
  cursor?: InputMaybe<OrderWhereUniqueInput>;
  distinct?: InputMaybe<Array<OrderScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<OrderOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<OrderWhereInput>;
};


export type UserPackagesAsReceiverArgs = {
  cursor?: InputMaybe<PostPackageWhereUniqueInput>;
  distinct?: InputMaybe<Array<PostPackageScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<PostPackageOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PostPackageWhereInput>;
};


export type UserPostPackageArgs = {
  cursor?: InputMaybe<PostPackageWhereUniqueInput>;
  distinct?: InputMaybe<Array<PostPackageScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<PostPackageOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PostPackageWhereInput>;
};


export type UserPostsInArgs = {
  cursor?: InputMaybe<PostWhereUniqueInput>;
  distinct?: InputMaybe<Array<PostScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<PostOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PostWhereInput>;
};


export type UserPostsOutArgs = {
  cursor?: InputMaybe<PostWhereUniqueInput>;
  distinct?: InputMaybe<Array<PostScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<PostOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PostWhereInput>;
};


export type UserReportByMeArgs = {
  cursor?: InputMaybe<ReportUserWhereUniqueInput>;
  distinct?: InputMaybe<Array<ReportUserScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ReportUserOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ReportUserWhereInput>;
};


export type UserReportedArgs = {
  cursor?: InputMaybe<ReportUserWhereUniqueInput>;
  distinct?: InputMaybe<Array<ReportUserScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ReportUserOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ReportUserWhereInput>;
};


export type UserReportsArgs = {
  cursor?: InputMaybe<ReportWhereUniqueInput>;
  distinct?: InputMaybe<Array<ReportScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ReportOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ReportWhereInput>;
};


export type UserSignatureArgs = {
  cursor?: InputMaybe<SignatureWhereUniqueInput>;
  distinct?: InputMaybe<Array<SignatureScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<SignatureOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<SignatureWhereInput>;
};


export type UserStorePurchaseHistoryArgs = {
  cursor?: InputMaybe<CartItemWhereUniqueInput>;
  distinct?: InputMaybe<Array<CartItemScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<CartItemOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CartItemWhereInput>;
};


export type UserTagsUsedArgs = {
  cursor?: InputMaybe<PostTagWhereUniqueInput>;
  distinct?: InputMaybe<Array<PostTagScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<PostTagOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PostTagWhereInput>;
};

export enum UserAuthType {
  Apple = 'Apple',
  Email = 'Email',
  Facebook = 'Facebook',
  Google = 'Google',
  Phone = 'Phone'
}

export type UserAvgAggregate = {
  __typename?: 'UserAvgAggregate';
  pcoins?: Maybe<Scalars['Decimal']>;
};

export type UserAvgOrderByAggregateInput = {
  pcoins?: InputMaybe<SortOrder>;
};

export type UserCount = {
  __typename?: 'UserCount';
  address: Scalars['Int'];
  attachments: Scalars['Int'];
  campaignNanhui: Scalars['Int'];
  card: Scalars['Int'];
  coins: Scalars['Int'];
  collection: Scalars['Int'];
  couponUsages: Scalars['Int'];
  createdStamps: Scalars['Int'];
  detail: Scalars['Int'];
  deviceToken: Scalars['Int'];
  emailTos: Scalars['Int'];
  favoriteStoreItems: Scalars['Int'];
  followProfitables: Scalars['Int'];
  followedBy: Scalars['Int'];
  following: Scalars['Int'];
  friends: Scalars['Int'];
  groupsJoin: Scalars['Int'];
  groupsOwn: Scalars['Int'];
  history: Scalars['Int'];
  inFriends: Scalars['Int'];
  inValidReasonHistories: Scalars['Int'];
  likePosts: Scalars['Int'];
  manualAddrs: Scalars['Int'];
  myCoupons: Scalars['Int'];
  notificationReceiver: Scalars['Int'];
  notificationSender: Scalars['Int'];
  order: Scalars['Int'];
  packagesAsReceiver: Scalars['Int'];
  postPackage: Scalars['Int'];
  postsIn: Scalars['Int'];
  postsOut: Scalars['Int'];
  reportByMe: Scalars['Int'];
  reported: Scalars['Int'];
  reports: Scalars['Int'];
  signature: Scalars['Int'];
  storePurchaseHistory: Scalars['Int'];
  tagsUsed: Scalars['Int'];
};


export type UserCountAddressArgs = {
  where?: InputMaybe<AddressWhereInput>;
};


export type UserCountAttachmentsArgs = {
  where?: InputMaybe<AttachmentWhereInput>;
};


export type UserCountCampaignNanhuiArgs = {
  where?: InputMaybe<CampaignNanhuiWhereInput>;
};


export type UserCountCardArgs = {
  where?: InputMaybe<CardWhereInput>;
};


export type UserCountCoinsArgs = {
  where?: InputMaybe<CoinWhereInput>;
};


export type UserCountCollectionArgs = {
  where?: InputMaybe<CollectionWhereInput>;
};


export type UserCountCouponUsagesArgs = {
  where?: InputMaybe<CouponUsageWhereInput>;
};


export type UserCountCreatedStampsArgs = {
  where?: InputMaybe<StampWhereInput>;
};


export type UserCountDetailArgs = {
  where?: InputMaybe<OrderDetailWhereInput>;
};


export type UserCountDeviceTokenArgs = {
  where?: InputMaybe<DeviceTokenWhereInput>;
};


export type UserCountEmailTosArgs = {
  where?: InputMaybe<EmailToWhereInput>;
};


export type UserCountFavoriteStoreItemsArgs = {
  where?: InputMaybe<StoreItemWhereInput>;
};


export type UserCountFollowProfitablesArgs = {
  where?: InputMaybe<ProfitableWhereInput>;
};


export type UserCountFollowedByArgs = {
  where?: InputMaybe<UserWhereInput>;
};


export type UserCountFollowingArgs = {
  where?: InputMaybe<UserWhereInput>;
};


export type UserCountFriendsArgs = {
  where?: InputMaybe<UserFriendWhereInput>;
};


export type UserCountGroupsJoinArgs = {
  where?: InputMaybe<GroupWhereInput>;
};


export type UserCountGroupsOwnArgs = {
  where?: InputMaybe<GroupWhereInput>;
};


export type UserCountHistoryArgs = {
  where?: InputMaybe<OrderDetailHistoryWhereInput>;
};


export type UserCountInFriendsArgs = {
  where?: InputMaybe<UserFriendWhereInput>;
};


export type UserCountInValidReasonHistoriesArgs = {
  where?: InputMaybe<InValidReasonWhereInput>;
};


export type UserCountLikePostsArgs = {
  where?: InputMaybe<PostWhereInput>;
};


export type UserCountManualAddrsArgs = {
  where?: InputMaybe<ManualAddrWhereInput>;
};


export type UserCountMyCouponsArgs = {
  where?: InputMaybe<CouponEntityWhereInput>;
};


export type UserCountNotificationReceiverArgs = {
  where?: InputMaybe<NotificationWhereInput>;
};


export type UserCountNotificationSenderArgs = {
  where?: InputMaybe<NotificationWhereInput>;
};


export type UserCountOrderArgs = {
  where?: InputMaybe<OrderWhereInput>;
};


export type UserCountPackagesAsReceiverArgs = {
  where?: InputMaybe<PostPackageWhereInput>;
};


export type UserCountPostPackageArgs = {
  where?: InputMaybe<PostPackageWhereInput>;
};


export type UserCountPostsInArgs = {
  where?: InputMaybe<PostWhereInput>;
};


export type UserCountPostsOutArgs = {
  where?: InputMaybe<PostWhereInput>;
};


export type UserCountReportByMeArgs = {
  where?: InputMaybe<ReportUserWhereInput>;
};


export type UserCountReportedArgs = {
  where?: InputMaybe<ReportUserWhereInput>;
};


export type UserCountReportsArgs = {
  where?: InputMaybe<ReportWhereInput>;
};


export type UserCountSignatureArgs = {
  where?: InputMaybe<SignatureWhereInput>;
};


export type UserCountStorePurchaseHistoryArgs = {
  where?: InputMaybe<CartItemWhereInput>;
};


export type UserCountTagsUsedArgs = {
  where?: InputMaybe<PostTagWhereInput>;
};

export type UserCountAggregate = {
  __typename?: 'UserCountAggregate';
  _all: Scalars['Int'];
  accessToken: Scalars['Int'];
  authType: Scalars['Int'];
  bio: Scalars['Int'];
  country: Scalars['Int'];
  createdAt: Scalars['Int'];
  email: Scalars['Int'];
  gender: Scalars['Int'];
  headUri: Scalars['Int'];
  id: Scalars['Int'];
  idStr: Scalars['Int'];
  lastLogin: Scalars['Int'];
  lastTokenUpdated: Scalars['Int'];
  name: Scalars['Int'];
  password: Scalars['Int'];
  pcoins: Scalars['Int'];
  phone: Scalars['Int'];
  receive_email: Scalars['Int'];
  receive_phone: Scalars['Int'];
  thirdpartyId: Scalars['Int'];
  updatedAt: Scalars['Int'];
  valid: Scalars['Int'];
  website: Scalars['Int'];
};

export type UserCountOrderByAggregateInput = {
  accessToken?: InputMaybe<SortOrder>;
  authType?: InputMaybe<SortOrder>;
  bio?: InputMaybe<SortOrder>;
  country?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  email?: InputMaybe<SortOrder>;
  gender?: InputMaybe<SortOrder>;
  headUri?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  idStr?: InputMaybe<SortOrder>;
  lastLogin?: InputMaybe<SortOrder>;
  lastTokenUpdated?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  password?: InputMaybe<SortOrder>;
  pcoins?: InputMaybe<SortOrder>;
  phone?: InputMaybe<SortOrder>;
  receive_email?: InputMaybe<SortOrder>;
  receive_phone?: InputMaybe<SortOrder>;
  thirdpartyId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  valid?: InputMaybe<SortOrder>;
  website?: InputMaybe<SortOrder>;
};

export type UserCreateInput = {
  accessToken?: InputMaybe<Scalars['String']>;
  address?: InputMaybe<AddressCreateNestedManyWithoutOwnerInput>;
  attachments?: InputMaybe<AttachmentCreateNestedManyWithoutOwnerInput>;
  authType: UserAuthType;
  bio?: InputMaybe<Scalars['String']>;
  campaignNanhui?: InputMaybe<CampaignNanhuiCreateNestedManyWithoutSubmitterInput>;
  campainZeczec?: InputMaybe<CampainZeczecCreateNestedOneWithoutRegisterUserInput>;
  card?: InputMaybe<CardCreateNestedManyWithoutCreatorInput>;
  coins?: InputMaybe<CoinCreateNestedManyWithoutUserInput>;
  collection?: InputMaybe<CollectionCreateNestedManyWithoutOwnerInput>;
  country?: InputMaybe<Scalars['String']>;
  couponUsages?: InputMaybe<CouponUsageCreateNestedManyWithoutUserInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdStamps?: InputMaybe<StampCreateNestedManyWithoutCreatorInput>;
  detail?: InputMaybe<OrderDetailCreateNestedManyWithoutReceiverInput>;
  deviceToken?: InputMaybe<DeviceTokenCreateNestedManyWithoutUserInput>;
  email?: InputMaybe<Scalars['String']>;
  emailTos?: InputMaybe<EmailToCreateNestedManyWithoutOwnerInput>;
  favoriteStoreItems?: InputMaybe<StoreItemCreateNestedManyWithoutFavoriteByUsersInput>;
  fetchFriends?: InputMaybe<UserFriendCreateNestedManyWithoutOwnerInput>;
  followProfitables?: InputMaybe<ProfitableCreateNestedManyWithoutFollowedByInput>;
  followedBy?: InputMaybe<UserCreateNestedManyWithoutFollowingInput>;
  following?: InputMaybe<UserCreateNestedManyWithoutFollowedByInput>;
  gender?: InputMaybe<Scalars['String']>;
  groupsJoin?: InputMaybe<GroupCreateNestedManyWithoutMembersInput>;
  groupsOwn?: InputMaybe<GroupCreateNestedManyWithoutOwnerInput>;
  headUri?: InputMaybe<Scalars['String']>;
  history?: InputMaybe<OrderDetailHistoryCreateNestedManyWithoutOperatorInput>;
  id?: InputMaybe<Scalars['String']>;
  idStr: Scalars['String'];
  inFriends?: InputMaybe<UserFriendCreateNestedManyWithoutUsersInput>;
  inValidReasonHistories?: InputMaybe<InValidReasonCreateNestedManyWithoutUserInput>;
  lastLogin?: InputMaybe<Scalars['DateTime']>;
  lastTokenUpdated?: InputMaybe<Scalars['DateTime']>;
  likePosts?: InputMaybe<PostCreateNestedManyWithoutLikeUsersInput>;
  manualAddrs?: InputMaybe<ManualAddrCreateNestedManyWithoutOwnerInput>;
  myCoupons?: InputMaybe<CouponEntityCreateNestedManyWithoutHolderInput>;
  name: Scalars['String'];
  notificationReceiver?: InputMaybe<NotificationCreateNestedManyWithoutReceiverInput>;
  notificationSender?: InputMaybe<NotificationCreateNestedManyWithoutSenderInput>;
  order?: InputMaybe<OrderCreateNestedManyWithoutSenderInput>;
  packagesAsReceiver?: InputMaybe<PostPackageCreateNestedManyWithoutReceiversInput>;
  password?: InputMaybe<Scalars['String']>;
  pcoins?: InputMaybe<Scalars['Decimal']>;
  phone?: InputMaybe<Scalars['String']>;
  postPackage?: InputMaybe<PostPackageCreateNestedManyWithoutSenderInput>;
  postsIn?: InputMaybe<PostCreateNestedManyWithoutReceiverInput>;
  postsOut?: InputMaybe<PostCreateNestedManyWithoutSenderInput>;
  profitable?: InputMaybe<ProfitableCreateNestedOneWithoutUserInput>;
  receive_email?: InputMaybe<Scalars['String']>;
  receive_phone?: InputMaybe<Scalars['String']>;
  reportByMe?: InputMaybe<ReportUserCreateNestedManyWithoutReporterInput>;
  reported?: InputMaybe<ReportUserCreateNestedManyWithoutUserInput>;
  reports?: InputMaybe<ReportCreateNestedManyWithoutReporterInput>;
  settingNotification?: InputMaybe<SettingNotificationCreateNestedOneWithoutUserInput>;
  settingPrivacy?: InputMaybe<SettingPrivacyCreateNestedOneWithoutUserInput>;
  signature?: InputMaybe<SignatureCreateNestedManyWithoutOwnerInput>;
  storePurchaseHistory?: InputMaybe<CartItemCreateNestedManyWithoutCustomerInput>;
  tagsUsed?: InputMaybe<PostTagCreateNestedManyWithoutUserInput>;
  thirdpartyId: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  vAddrMapping?: InputMaybe<VirtualAddrMappingCreateNestedOneWithoutOwnerInput>;
  valid?: InputMaybe<Scalars['Boolean']>;
  website?: InputMaybe<Scalars['String']>;
};

export type UserCreateManyInput = {
  accessToken?: InputMaybe<Scalars['String']>;
  authType: UserAuthType;
  bio?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  email?: InputMaybe<Scalars['String']>;
  gender?: InputMaybe<Scalars['String']>;
  headUri?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  idStr: Scalars['String'];
  lastLogin?: InputMaybe<Scalars['DateTime']>;
  lastTokenUpdated?: InputMaybe<Scalars['DateTime']>;
  name: Scalars['String'];
  password?: InputMaybe<Scalars['String']>;
  pcoins?: InputMaybe<Scalars['Decimal']>;
  phone?: InputMaybe<Scalars['String']>;
  receive_email?: InputMaybe<Scalars['String']>;
  receive_phone?: InputMaybe<Scalars['String']>;
  thirdpartyId: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  valid?: InputMaybe<Scalars['Boolean']>;
  website?: InputMaybe<Scalars['String']>;
};

export type UserCreateNestedManyWithoutFavoriteStoreItemsInput = {
  connect?: InputMaybe<Array<UserWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UserCreateOrConnectWithoutFavoriteStoreItemsInput>>;
  create?: InputMaybe<Array<UserCreateWithoutFavoriteStoreItemsInput>>;
};

export type UserCreateNestedManyWithoutFollowProfitablesInput = {
  connect?: InputMaybe<Array<UserWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UserCreateOrConnectWithoutFollowProfitablesInput>>;
  create?: InputMaybe<Array<UserCreateWithoutFollowProfitablesInput>>;
};

export type UserCreateNestedManyWithoutFollowedByInput = {
  connect?: InputMaybe<Array<UserWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UserCreateOrConnectWithoutFollowedByInput>>;
  create?: InputMaybe<Array<UserCreateWithoutFollowedByInput>>;
};

export type UserCreateNestedManyWithoutFollowingInput = {
  connect?: InputMaybe<Array<UserWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UserCreateOrConnectWithoutFollowingInput>>;
  create?: InputMaybe<Array<UserCreateWithoutFollowingInput>>;
};

export type UserCreateNestedManyWithoutGroupsJoinInput = {
  connect?: InputMaybe<Array<UserWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UserCreateOrConnectWithoutGroupsJoinInput>>;
  create?: InputMaybe<Array<UserCreateWithoutGroupsJoinInput>>;
};

export type UserCreateNestedManyWithoutLikePostsInput = {
  connect?: InputMaybe<Array<UserWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UserCreateOrConnectWithoutLikePostsInput>>;
  create?: InputMaybe<Array<UserCreateWithoutLikePostsInput>>;
};

export type UserCreateNestedManyWithoutPackagesAsReceiverInput = {
  connect?: InputMaybe<Array<UserWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UserCreateOrConnectWithoutPackagesAsReceiverInput>>;
  create?: InputMaybe<Array<UserCreateWithoutPackagesAsReceiverInput>>;
};

export type UserCreateNestedOneWithoutAddressInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutAddressInput>;
  create?: InputMaybe<UserCreateWithoutAddressInput>;
};

export type UserCreateNestedOneWithoutAttachmentsInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutAttachmentsInput>;
  create?: InputMaybe<UserCreateWithoutAttachmentsInput>;
};

export type UserCreateNestedOneWithoutCampaignNanhuiInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutCampaignNanhuiInput>;
  create?: InputMaybe<UserCreateWithoutCampaignNanhuiInput>;
};

export type UserCreateNestedOneWithoutCampainZeczecInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutCampainZeczecInput>;
  create?: InputMaybe<UserCreateWithoutCampainZeczecInput>;
};

export type UserCreateNestedOneWithoutCardInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutCardInput>;
  create?: InputMaybe<UserCreateWithoutCardInput>;
};

export type UserCreateNestedOneWithoutCoinsInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutCoinsInput>;
  create?: InputMaybe<UserCreateWithoutCoinsInput>;
};

export type UserCreateNestedOneWithoutCollectionInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutCollectionInput>;
  create?: InputMaybe<UserCreateWithoutCollectionInput>;
};

export type UserCreateNestedOneWithoutCouponUsagesInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutCouponUsagesInput>;
  create?: InputMaybe<UserCreateWithoutCouponUsagesInput>;
};

export type UserCreateNestedOneWithoutCreatedStampsInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutCreatedStampsInput>;
  create?: InputMaybe<UserCreateWithoutCreatedStampsInput>;
};

export type UserCreateNestedOneWithoutDetailInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutDetailInput>;
  create?: InputMaybe<UserCreateWithoutDetailInput>;
};

export type UserCreateNestedOneWithoutDeviceTokenInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutDeviceTokenInput>;
  create?: InputMaybe<UserCreateWithoutDeviceTokenInput>;
};

export type UserCreateNestedOneWithoutEmailTosInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutEmailTosInput>;
  create?: InputMaybe<UserCreateWithoutEmailTosInput>;
};

export type UserCreateNestedOneWithoutFriendsInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutFriendsInput>;
  create?: InputMaybe<UserCreateWithoutFriendsInput>;
};

export type UserCreateNestedOneWithoutGroupsOwnInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutGroupsOwnInput>;
  create?: InputMaybe<UserCreateWithoutGroupsOwnInput>;
};

export type UserCreateNestedOneWithoutHistoryInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutHistoryInput>;
  create?: InputMaybe<UserCreateWithoutHistoryInput>;
};

export type UserCreateNestedOneWithoutInFriendsInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutInFriendsInput>;
  create?: InputMaybe<UserCreateWithoutInFriendsInput>;
};

export type UserCreateNestedOneWithoutInValidReasonHistoriesInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutInValidReasonHistoriesInput>;
  create?: InputMaybe<UserCreateWithoutInValidReasonHistoriesInput>;
};

export type UserCreateNestedOneWithoutManualAddrsInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutManualAddrsInput>;
  create?: InputMaybe<UserCreateWithoutManualAddrsInput>;
};

export type UserCreateNestedOneWithoutMyCouponsInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutMyCouponsInput>;
  create?: InputMaybe<UserCreateWithoutMyCouponsInput>;
};

export type UserCreateNestedOneWithoutNotificationReceiverInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutNotificationReceiverInput>;
  create?: InputMaybe<UserCreateWithoutNotificationReceiverInput>;
};

export type UserCreateNestedOneWithoutNotificationSenderInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutNotificationSenderInput>;
  create?: InputMaybe<UserCreateWithoutNotificationSenderInput>;
};

export type UserCreateNestedOneWithoutOrderInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutOrderInput>;
  create?: InputMaybe<UserCreateWithoutOrderInput>;
};

export type UserCreateNestedOneWithoutPostPackageInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutPostPackageInput>;
  create?: InputMaybe<UserCreateWithoutPostPackageInput>;
};

export type UserCreateNestedOneWithoutPostsInInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutPostsInInput>;
  create?: InputMaybe<UserCreateWithoutPostsInInput>;
};

export type UserCreateNestedOneWithoutPostsOutInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutPostsOutInput>;
  create?: InputMaybe<UserCreateWithoutPostsOutInput>;
};

export type UserCreateNestedOneWithoutProfitableInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutProfitableInput>;
  create?: InputMaybe<UserCreateWithoutProfitableInput>;
};

export type UserCreateNestedOneWithoutReportByMeInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutReportByMeInput>;
  create?: InputMaybe<UserCreateWithoutReportByMeInput>;
};

export type UserCreateNestedOneWithoutReportedInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutReportedInput>;
  create?: InputMaybe<UserCreateWithoutReportedInput>;
};

export type UserCreateNestedOneWithoutReportsInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutReportsInput>;
  create?: InputMaybe<UserCreateWithoutReportsInput>;
};

export type UserCreateNestedOneWithoutSettingNotificationInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutSettingNotificationInput>;
  create?: InputMaybe<UserCreateWithoutSettingNotificationInput>;
};

export type UserCreateNestedOneWithoutSettingPrivacyInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutSettingPrivacyInput>;
  create?: InputMaybe<UserCreateWithoutSettingPrivacyInput>;
};

export type UserCreateNestedOneWithoutSignatureInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutSignatureInput>;
  create?: InputMaybe<UserCreateWithoutSignatureInput>;
};

export type UserCreateNestedOneWithoutStorePurchaseHistoryInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutStorePurchaseHistoryInput>;
  create?: InputMaybe<UserCreateWithoutStorePurchaseHistoryInput>;
};

export type UserCreateNestedOneWithoutTagsUsedInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutTagsUsedInput>;
  create?: InputMaybe<UserCreateWithoutTagsUsedInput>;
};

export type UserCreateNestedOneWithoutVAddrMappingInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutVAddrMappingInput>;
  create?: InputMaybe<UserCreateWithoutVAddrMappingInput>;
};

export type UserCreateOrConnectWithoutAddressInput = {
  create: UserCreateWithoutAddressInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutAttachmentsInput = {
  create: UserCreateWithoutAttachmentsInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutCampaignNanhuiInput = {
  create: UserCreateWithoutCampaignNanhuiInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutCampainZeczecInput = {
  create: UserCreateWithoutCampainZeczecInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutCardInput = {
  create: UserCreateWithoutCardInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutCoinsInput = {
  create: UserCreateWithoutCoinsInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutCollectionInput = {
  create: UserCreateWithoutCollectionInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutCouponUsagesInput = {
  create: UserCreateWithoutCouponUsagesInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutCreatedStampsInput = {
  create: UserCreateWithoutCreatedStampsInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutDetailInput = {
  create: UserCreateWithoutDetailInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutDeviceTokenInput = {
  create: UserCreateWithoutDeviceTokenInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutEmailTosInput = {
  create: UserCreateWithoutEmailTosInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutFavoriteStoreItemsInput = {
  create: UserCreateWithoutFavoriteStoreItemsInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutFollowProfitablesInput = {
  create: UserCreateWithoutFollowProfitablesInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutFollowedByInput = {
  create: UserCreateWithoutFollowedByInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutFollowingInput = {
  create: UserCreateWithoutFollowingInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutFriendsInput = {
  create: UserCreateWithoutFriendsInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutGroupsJoinInput = {
  create: UserCreateWithoutGroupsJoinInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutGroupsOwnInput = {
  create: UserCreateWithoutGroupsOwnInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutHistoryInput = {
  create: UserCreateWithoutHistoryInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutInFriendsInput = {
  create: UserCreateWithoutInFriendsInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutInValidReasonHistoriesInput = {
  create: UserCreateWithoutInValidReasonHistoriesInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutLikePostsInput = {
  create: UserCreateWithoutLikePostsInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutManualAddrsInput = {
  create: UserCreateWithoutManualAddrsInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutMyCouponsInput = {
  create: UserCreateWithoutMyCouponsInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutNotificationReceiverInput = {
  create: UserCreateWithoutNotificationReceiverInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutNotificationSenderInput = {
  create: UserCreateWithoutNotificationSenderInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutOrderInput = {
  create: UserCreateWithoutOrderInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutPackagesAsReceiverInput = {
  create: UserCreateWithoutPackagesAsReceiverInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutPostPackageInput = {
  create: UserCreateWithoutPostPackageInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutPostsInInput = {
  create: UserCreateWithoutPostsInInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutPostsOutInput = {
  create: UserCreateWithoutPostsOutInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutProfitableInput = {
  create: UserCreateWithoutProfitableInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutReportByMeInput = {
  create: UserCreateWithoutReportByMeInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutReportedInput = {
  create: UserCreateWithoutReportedInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutReportsInput = {
  create: UserCreateWithoutReportsInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutSettingNotificationInput = {
  create: UserCreateWithoutSettingNotificationInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutSettingPrivacyInput = {
  create: UserCreateWithoutSettingPrivacyInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutSignatureInput = {
  create: UserCreateWithoutSignatureInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutStorePurchaseHistoryInput = {
  create: UserCreateWithoutStorePurchaseHistoryInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutTagsUsedInput = {
  create: UserCreateWithoutTagsUsedInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutVAddrMappingInput = {
  create: UserCreateWithoutVAddrMappingInput;
  where: UserWhereUniqueInput;
};

export type UserCreateWithoutAddressInput = {
  accessToken?: InputMaybe<Scalars['String']>;
  attachments?: InputMaybe<AttachmentCreateNestedManyWithoutOwnerInput>;
  authType: UserAuthType;
  bio?: InputMaybe<Scalars['String']>;
  campaignNanhui?: InputMaybe<CampaignNanhuiCreateNestedManyWithoutSubmitterInput>;
  campainZeczec?: InputMaybe<CampainZeczecCreateNestedOneWithoutRegisterUserInput>;
  card?: InputMaybe<CardCreateNestedManyWithoutCreatorInput>;
  coins?: InputMaybe<CoinCreateNestedManyWithoutUserInput>;
  collection?: InputMaybe<CollectionCreateNestedManyWithoutOwnerInput>;
  country?: InputMaybe<Scalars['String']>;
  couponUsages?: InputMaybe<CouponUsageCreateNestedManyWithoutUserInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdStamps?: InputMaybe<StampCreateNestedManyWithoutCreatorInput>;
  detail?: InputMaybe<OrderDetailCreateNestedManyWithoutReceiverInput>;
  deviceToken?: InputMaybe<DeviceTokenCreateNestedManyWithoutUserInput>;
  email?: InputMaybe<Scalars['String']>;
  emailTos?: InputMaybe<EmailToCreateNestedManyWithoutOwnerInput>;
  favoriteStoreItems?: InputMaybe<StoreItemCreateNestedManyWithoutFavoriteByUsersInput>;
  fetchFriends?: InputMaybe<UserFriendCreateNestedManyWithoutOwnerInput>;
  followProfitables?: InputMaybe<ProfitableCreateNestedManyWithoutFollowedByInput>;
  followedBy?: InputMaybe<UserCreateNestedManyWithoutFollowingInput>;
  following?: InputMaybe<UserCreateNestedManyWithoutFollowedByInput>;
  gender?: InputMaybe<Scalars['String']>;
  groupsJoin?: InputMaybe<GroupCreateNestedManyWithoutMembersInput>;
  groupsOwn?: InputMaybe<GroupCreateNestedManyWithoutOwnerInput>;
  headUri?: InputMaybe<Scalars['String']>;
  history?: InputMaybe<OrderDetailHistoryCreateNestedManyWithoutOperatorInput>;
  id?: InputMaybe<Scalars['String']>;
  idStr: Scalars['String'];
  inFriends?: InputMaybe<UserFriendCreateNestedManyWithoutUsersInput>;
  inValidReasonHistories?: InputMaybe<InValidReasonCreateNestedManyWithoutUserInput>;
  lastLogin?: InputMaybe<Scalars['DateTime']>;
  lastTokenUpdated?: InputMaybe<Scalars['DateTime']>;
  likePosts?: InputMaybe<PostCreateNestedManyWithoutLikeUsersInput>;
  manualAddrs?: InputMaybe<ManualAddrCreateNestedManyWithoutOwnerInput>;
  myCoupons?: InputMaybe<CouponEntityCreateNestedManyWithoutHolderInput>;
  name: Scalars['String'];
  notificationReceiver?: InputMaybe<NotificationCreateNestedManyWithoutReceiverInput>;
  notificationSender?: InputMaybe<NotificationCreateNestedManyWithoutSenderInput>;
  order?: InputMaybe<OrderCreateNestedManyWithoutSenderInput>;
  packagesAsReceiver?: InputMaybe<PostPackageCreateNestedManyWithoutReceiversInput>;
  password?: InputMaybe<Scalars['String']>;
  pcoins?: InputMaybe<Scalars['Decimal']>;
  phone?: InputMaybe<Scalars['String']>;
  postPackage?: InputMaybe<PostPackageCreateNestedManyWithoutSenderInput>;
  postsIn?: InputMaybe<PostCreateNestedManyWithoutReceiverInput>;
  postsOut?: InputMaybe<PostCreateNestedManyWithoutSenderInput>;
  profitable?: InputMaybe<ProfitableCreateNestedOneWithoutUserInput>;
  receive_email?: InputMaybe<Scalars['String']>;
  receive_phone?: InputMaybe<Scalars['String']>;
  reportByMe?: InputMaybe<ReportUserCreateNestedManyWithoutReporterInput>;
  reported?: InputMaybe<ReportUserCreateNestedManyWithoutUserInput>;
  reports?: InputMaybe<ReportCreateNestedManyWithoutReporterInput>;
  settingNotification?: InputMaybe<SettingNotificationCreateNestedOneWithoutUserInput>;
  settingPrivacy?: InputMaybe<SettingPrivacyCreateNestedOneWithoutUserInput>;
  signature?: InputMaybe<SignatureCreateNestedManyWithoutOwnerInput>;
  storePurchaseHistory?: InputMaybe<CartItemCreateNestedManyWithoutCustomerInput>;
  tagsUsed?: InputMaybe<PostTagCreateNestedManyWithoutUserInput>;
  thirdpartyId: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  vAddrMapping?: InputMaybe<VirtualAddrMappingCreateNestedOneWithoutOwnerInput>;
  valid?: InputMaybe<Scalars['Boolean']>;
  website?: InputMaybe<Scalars['String']>;
};

export type UserCreateWithoutAttachmentsInput = {
  accessToken?: InputMaybe<Scalars['String']>;
  address?: InputMaybe<AddressCreateNestedManyWithoutOwnerInput>;
  authType: UserAuthType;
  bio?: InputMaybe<Scalars['String']>;
  campaignNanhui?: InputMaybe<CampaignNanhuiCreateNestedManyWithoutSubmitterInput>;
  campainZeczec?: InputMaybe<CampainZeczecCreateNestedOneWithoutRegisterUserInput>;
  card?: InputMaybe<CardCreateNestedManyWithoutCreatorInput>;
  coins?: InputMaybe<CoinCreateNestedManyWithoutUserInput>;
  collection?: InputMaybe<CollectionCreateNestedManyWithoutOwnerInput>;
  country?: InputMaybe<Scalars['String']>;
  couponUsages?: InputMaybe<CouponUsageCreateNestedManyWithoutUserInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdStamps?: InputMaybe<StampCreateNestedManyWithoutCreatorInput>;
  detail?: InputMaybe<OrderDetailCreateNestedManyWithoutReceiverInput>;
  deviceToken?: InputMaybe<DeviceTokenCreateNestedManyWithoutUserInput>;
  email?: InputMaybe<Scalars['String']>;
  emailTos?: InputMaybe<EmailToCreateNestedManyWithoutOwnerInput>;
  favoriteStoreItems?: InputMaybe<StoreItemCreateNestedManyWithoutFavoriteByUsersInput>;
  fetchFriends?: InputMaybe<UserFriendCreateNestedManyWithoutOwnerInput>;
  followProfitables?: InputMaybe<ProfitableCreateNestedManyWithoutFollowedByInput>;
  followedBy?: InputMaybe<UserCreateNestedManyWithoutFollowingInput>;
  following?: InputMaybe<UserCreateNestedManyWithoutFollowedByInput>;
  gender?: InputMaybe<Scalars['String']>;
  groupsJoin?: InputMaybe<GroupCreateNestedManyWithoutMembersInput>;
  groupsOwn?: InputMaybe<GroupCreateNestedManyWithoutOwnerInput>;
  headUri?: InputMaybe<Scalars['String']>;
  history?: InputMaybe<OrderDetailHistoryCreateNestedManyWithoutOperatorInput>;
  id?: InputMaybe<Scalars['String']>;
  idStr: Scalars['String'];
  inFriends?: InputMaybe<UserFriendCreateNestedManyWithoutUsersInput>;
  inValidReasonHistories?: InputMaybe<InValidReasonCreateNestedManyWithoutUserInput>;
  lastLogin?: InputMaybe<Scalars['DateTime']>;
  lastTokenUpdated?: InputMaybe<Scalars['DateTime']>;
  likePosts?: InputMaybe<PostCreateNestedManyWithoutLikeUsersInput>;
  manualAddrs?: InputMaybe<ManualAddrCreateNestedManyWithoutOwnerInput>;
  myCoupons?: InputMaybe<CouponEntityCreateNestedManyWithoutHolderInput>;
  name: Scalars['String'];
  notificationReceiver?: InputMaybe<NotificationCreateNestedManyWithoutReceiverInput>;
  notificationSender?: InputMaybe<NotificationCreateNestedManyWithoutSenderInput>;
  order?: InputMaybe<OrderCreateNestedManyWithoutSenderInput>;
  packagesAsReceiver?: InputMaybe<PostPackageCreateNestedManyWithoutReceiversInput>;
  password?: InputMaybe<Scalars['String']>;
  pcoins?: InputMaybe<Scalars['Decimal']>;
  phone?: InputMaybe<Scalars['String']>;
  postPackage?: InputMaybe<PostPackageCreateNestedManyWithoutSenderInput>;
  postsIn?: InputMaybe<PostCreateNestedManyWithoutReceiverInput>;
  postsOut?: InputMaybe<PostCreateNestedManyWithoutSenderInput>;
  profitable?: InputMaybe<ProfitableCreateNestedOneWithoutUserInput>;
  receive_email?: InputMaybe<Scalars['String']>;
  receive_phone?: InputMaybe<Scalars['String']>;
  reportByMe?: InputMaybe<ReportUserCreateNestedManyWithoutReporterInput>;
  reported?: InputMaybe<ReportUserCreateNestedManyWithoutUserInput>;
  reports?: InputMaybe<ReportCreateNestedManyWithoutReporterInput>;
  settingNotification?: InputMaybe<SettingNotificationCreateNestedOneWithoutUserInput>;
  settingPrivacy?: InputMaybe<SettingPrivacyCreateNestedOneWithoutUserInput>;
  signature?: InputMaybe<SignatureCreateNestedManyWithoutOwnerInput>;
  storePurchaseHistory?: InputMaybe<CartItemCreateNestedManyWithoutCustomerInput>;
  tagsUsed?: InputMaybe<PostTagCreateNestedManyWithoutUserInput>;
  thirdpartyId: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  vAddrMapping?: InputMaybe<VirtualAddrMappingCreateNestedOneWithoutOwnerInput>;
  valid?: InputMaybe<Scalars['Boolean']>;
  website?: InputMaybe<Scalars['String']>;
};

export type UserCreateWithoutCampaignNanhuiInput = {
  accessToken?: InputMaybe<Scalars['String']>;
  address?: InputMaybe<AddressCreateNestedManyWithoutOwnerInput>;
  attachments?: InputMaybe<AttachmentCreateNestedManyWithoutOwnerInput>;
  authType: UserAuthType;
  bio?: InputMaybe<Scalars['String']>;
  campainZeczec?: InputMaybe<CampainZeczecCreateNestedOneWithoutRegisterUserInput>;
  card?: InputMaybe<CardCreateNestedManyWithoutCreatorInput>;
  coins?: InputMaybe<CoinCreateNestedManyWithoutUserInput>;
  collection?: InputMaybe<CollectionCreateNestedManyWithoutOwnerInput>;
  country?: InputMaybe<Scalars['String']>;
  couponUsages?: InputMaybe<CouponUsageCreateNestedManyWithoutUserInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdStamps?: InputMaybe<StampCreateNestedManyWithoutCreatorInput>;
  detail?: InputMaybe<OrderDetailCreateNestedManyWithoutReceiverInput>;
  deviceToken?: InputMaybe<DeviceTokenCreateNestedManyWithoutUserInput>;
  email?: InputMaybe<Scalars['String']>;
  emailTos?: InputMaybe<EmailToCreateNestedManyWithoutOwnerInput>;
  favoriteStoreItems?: InputMaybe<StoreItemCreateNestedManyWithoutFavoriteByUsersInput>;
  fetchFriends?: InputMaybe<UserFriendCreateNestedManyWithoutOwnerInput>;
  followProfitables?: InputMaybe<ProfitableCreateNestedManyWithoutFollowedByInput>;
  followedBy?: InputMaybe<UserCreateNestedManyWithoutFollowingInput>;
  following?: InputMaybe<UserCreateNestedManyWithoutFollowedByInput>;
  gender?: InputMaybe<Scalars['String']>;
  groupsJoin?: InputMaybe<GroupCreateNestedManyWithoutMembersInput>;
  groupsOwn?: InputMaybe<GroupCreateNestedManyWithoutOwnerInput>;
  headUri?: InputMaybe<Scalars['String']>;
  history?: InputMaybe<OrderDetailHistoryCreateNestedManyWithoutOperatorInput>;
  id?: InputMaybe<Scalars['String']>;
  idStr: Scalars['String'];
  inFriends?: InputMaybe<UserFriendCreateNestedManyWithoutUsersInput>;
  inValidReasonHistories?: InputMaybe<InValidReasonCreateNestedManyWithoutUserInput>;
  lastLogin?: InputMaybe<Scalars['DateTime']>;
  lastTokenUpdated?: InputMaybe<Scalars['DateTime']>;
  likePosts?: InputMaybe<PostCreateNestedManyWithoutLikeUsersInput>;
  manualAddrs?: InputMaybe<ManualAddrCreateNestedManyWithoutOwnerInput>;
  myCoupons?: InputMaybe<CouponEntityCreateNestedManyWithoutHolderInput>;
  name: Scalars['String'];
  notificationReceiver?: InputMaybe<NotificationCreateNestedManyWithoutReceiverInput>;
  notificationSender?: InputMaybe<NotificationCreateNestedManyWithoutSenderInput>;
  order?: InputMaybe<OrderCreateNestedManyWithoutSenderInput>;
  packagesAsReceiver?: InputMaybe<PostPackageCreateNestedManyWithoutReceiversInput>;
  password?: InputMaybe<Scalars['String']>;
  pcoins?: InputMaybe<Scalars['Decimal']>;
  phone?: InputMaybe<Scalars['String']>;
  postPackage?: InputMaybe<PostPackageCreateNestedManyWithoutSenderInput>;
  postsIn?: InputMaybe<PostCreateNestedManyWithoutReceiverInput>;
  postsOut?: InputMaybe<PostCreateNestedManyWithoutSenderInput>;
  profitable?: InputMaybe<ProfitableCreateNestedOneWithoutUserInput>;
  receive_email?: InputMaybe<Scalars['String']>;
  receive_phone?: InputMaybe<Scalars['String']>;
  reportByMe?: InputMaybe<ReportUserCreateNestedManyWithoutReporterInput>;
  reported?: InputMaybe<ReportUserCreateNestedManyWithoutUserInput>;
  reports?: InputMaybe<ReportCreateNestedManyWithoutReporterInput>;
  settingNotification?: InputMaybe<SettingNotificationCreateNestedOneWithoutUserInput>;
  settingPrivacy?: InputMaybe<SettingPrivacyCreateNestedOneWithoutUserInput>;
  signature?: InputMaybe<SignatureCreateNestedManyWithoutOwnerInput>;
  storePurchaseHistory?: InputMaybe<CartItemCreateNestedManyWithoutCustomerInput>;
  tagsUsed?: InputMaybe<PostTagCreateNestedManyWithoutUserInput>;
  thirdpartyId: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  vAddrMapping?: InputMaybe<VirtualAddrMappingCreateNestedOneWithoutOwnerInput>;
  valid?: InputMaybe<Scalars['Boolean']>;
  website?: InputMaybe<Scalars['String']>;
};

export type UserCreateWithoutCampainZeczecInput = {
  accessToken?: InputMaybe<Scalars['String']>;
  address?: InputMaybe<AddressCreateNestedManyWithoutOwnerInput>;
  attachments?: InputMaybe<AttachmentCreateNestedManyWithoutOwnerInput>;
  authType: UserAuthType;
  bio?: InputMaybe<Scalars['String']>;
  campaignNanhui?: InputMaybe<CampaignNanhuiCreateNestedManyWithoutSubmitterInput>;
  card?: InputMaybe<CardCreateNestedManyWithoutCreatorInput>;
  coins?: InputMaybe<CoinCreateNestedManyWithoutUserInput>;
  collection?: InputMaybe<CollectionCreateNestedManyWithoutOwnerInput>;
  country?: InputMaybe<Scalars['String']>;
  couponUsages?: InputMaybe<CouponUsageCreateNestedManyWithoutUserInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdStamps?: InputMaybe<StampCreateNestedManyWithoutCreatorInput>;
  detail?: InputMaybe<OrderDetailCreateNestedManyWithoutReceiverInput>;
  deviceToken?: InputMaybe<DeviceTokenCreateNestedManyWithoutUserInput>;
  email?: InputMaybe<Scalars['String']>;
  emailTos?: InputMaybe<EmailToCreateNestedManyWithoutOwnerInput>;
  favoriteStoreItems?: InputMaybe<StoreItemCreateNestedManyWithoutFavoriteByUsersInput>;
  fetchFriends?: InputMaybe<UserFriendCreateNestedManyWithoutOwnerInput>;
  followProfitables?: InputMaybe<ProfitableCreateNestedManyWithoutFollowedByInput>;
  followedBy?: InputMaybe<UserCreateNestedManyWithoutFollowingInput>;
  following?: InputMaybe<UserCreateNestedManyWithoutFollowedByInput>;
  gender?: InputMaybe<Scalars['String']>;
  groupsJoin?: InputMaybe<GroupCreateNestedManyWithoutMembersInput>;
  groupsOwn?: InputMaybe<GroupCreateNestedManyWithoutOwnerInput>;
  headUri?: InputMaybe<Scalars['String']>;
  history?: InputMaybe<OrderDetailHistoryCreateNestedManyWithoutOperatorInput>;
  id?: InputMaybe<Scalars['String']>;
  idStr: Scalars['String'];
  inFriends?: InputMaybe<UserFriendCreateNestedManyWithoutUsersInput>;
  inValidReasonHistories?: InputMaybe<InValidReasonCreateNestedManyWithoutUserInput>;
  lastLogin?: InputMaybe<Scalars['DateTime']>;
  lastTokenUpdated?: InputMaybe<Scalars['DateTime']>;
  likePosts?: InputMaybe<PostCreateNestedManyWithoutLikeUsersInput>;
  manualAddrs?: InputMaybe<ManualAddrCreateNestedManyWithoutOwnerInput>;
  myCoupons?: InputMaybe<CouponEntityCreateNestedManyWithoutHolderInput>;
  name: Scalars['String'];
  notificationReceiver?: InputMaybe<NotificationCreateNestedManyWithoutReceiverInput>;
  notificationSender?: InputMaybe<NotificationCreateNestedManyWithoutSenderInput>;
  order?: InputMaybe<OrderCreateNestedManyWithoutSenderInput>;
  packagesAsReceiver?: InputMaybe<PostPackageCreateNestedManyWithoutReceiversInput>;
  password?: InputMaybe<Scalars['String']>;
  pcoins?: InputMaybe<Scalars['Decimal']>;
  phone?: InputMaybe<Scalars['String']>;
  postPackage?: InputMaybe<PostPackageCreateNestedManyWithoutSenderInput>;
  postsIn?: InputMaybe<PostCreateNestedManyWithoutReceiverInput>;
  postsOut?: InputMaybe<PostCreateNestedManyWithoutSenderInput>;
  profitable?: InputMaybe<ProfitableCreateNestedOneWithoutUserInput>;
  receive_email?: InputMaybe<Scalars['String']>;
  receive_phone?: InputMaybe<Scalars['String']>;
  reportByMe?: InputMaybe<ReportUserCreateNestedManyWithoutReporterInput>;
  reported?: InputMaybe<ReportUserCreateNestedManyWithoutUserInput>;
  reports?: InputMaybe<ReportCreateNestedManyWithoutReporterInput>;
  settingNotification?: InputMaybe<SettingNotificationCreateNestedOneWithoutUserInput>;
  settingPrivacy?: InputMaybe<SettingPrivacyCreateNestedOneWithoutUserInput>;
  signature?: InputMaybe<SignatureCreateNestedManyWithoutOwnerInput>;
  storePurchaseHistory?: InputMaybe<CartItemCreateNestedManyWithoutCustomerInput>;
  tagsUsed?: InputMaybe<PostTagCreateNestedManyWithoutUserInput>;
  thirdpartyId: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  vAddrMapping?: InputMaybe<VirtualAddrMappingCreateNestedOneWithoutOwnerInput>;
  valid?: InputMaybe<Scalars['Boolean']>;
  website?: InputMaybe<Scalars['String']>;
};

export type UserCreateWithoutCardInput = {
  accessToken?: InputMaybe<Scalars['String']>;
  address?: InputMaybe<AddressCreateNestedManyWithoutOwnerInput>;
  attachments?: InputMaybe<AttachmentCreateNestedManyWithoutOwnerInput>;
  authType: UserAuthType;
  bio?: InputMaybe<Scalars['String']>;
  campaignNanhui?: InputMaybe<CampaignNanhuiCreateNestedManyWithoutSubmitterInput>;
  campainZeczec?: InputMaybe<CampainZeczecCreateNestedOneWithoutRegisterUserInput>;
  coins?: InputMaybe<CoinCreateNestedManyWithoutUserInput>;
  collection?: InputMaybe<CollectionCreateNestedManyWithoutOwnerInput>;
  country?: InputMaybe<Scalars['String']>;
  couponUsages?: InputMaybe<CouponUsageCreateNestedManyWithoutUserInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdStamps?: InputMaybe<StampCreateNestedManyWithoutCreatorInput>;
  detail?: InputMaybe<OrderDetailCreateNestedManyWithoutReceiverInput>;
  deviceToken?: InputMaybe<DeviceTokenCreateNestedManyWithoutUserInput>;
  email?: InputMaybe<Scalars['String']>;
  emailTos?: InputMaybe<EmailToCreateNestedManyWithoutOwnerInput>;
  favoriteStoreItems?: InputMaybe<StoreItemCreateNestedManyWithoutFavoriteByUsersInput>;
  fetchFriends?: InputMaybe<UserFriendCreateNestedManyWithoutOwnerInput>;
  followProfitables?: InputMaybe<ProfitableCreateNestedManyWithoutFollowedByInput>;
  followedBy?: InputMaybe<UserCreateNestedManyWithoutFollowingInput>;
  following?: InputMaybe<UserCreateNestedManyWithoutFollowedByInput>;
  gender?: InputMaybe<Scalars['String']>;
  groupsJoin?: InputMaybe<GroupCreateNestedManyWithoutMembersInput>;
  groupsOwn?: InputMaybe<GroupCreateNestedManyWithoutOwnerInput>;
  headUri?: InputMaybe<Scalars['String']>;
  history?: InputMaybe<OrderDetailHistoryCreateNestedManyWithoutOperatorInput>;
  id?: InputMaybe<Scalars['String']>;
  idStr: Scalars['String'];
  inFriends?: InputMaybe<UserFriendCreateNestedManyWithoutUsersInput>;
  inValidReasonHistories?: InputMaybe<InValidReasonCreateNestedManyWithoutUserInput>;
  lastLogin?: InputMaybe<Scalars['DateTime']>;
  lastTokenUpdated?: InputMaybe<Scalars['DateTime']>;
  likePosts?: InputMaybe<PostCreateNestedManyWithoutLikeUsersInput>;
  manualAddrs?: InputMaybe<ManualAddrCreateNestedManyWithoutOwnerInput>;
  myCoupons?: InputMaybe<CouponEntityCreateNestedManyWithoutHolderInput>;
  name: Scalars['String'];
  notificationReceiver?: InputMaybe<NotificationCreateNestedManyWithoutReceiverInput>;
  notificationSender?: InputMaybe<NotificationCreateNestedManyWithoutSenderInput>;
  order?: InputMaybe<OrderCreateNestedManyWithoutSenderInput>;
  packagesAsReceiver?: InputMaybe<PostPackageCreateNestedManyWithoutReceiversInput>;
  password?: InputMaybe<Scalars['String']>;
  pcoins?: InputMaybe<Scalars['Decimal']>;
  phone?: InputMaybe<Scalars['String']>;
  postPackage?: InputMaybe<PostPackageCreateNestedManyWithoutSenderInput>;
  postsIn?: InputMaybe<PostCreateNestedManyWithoutReceiverInput>;
  postsOut?: InputMaybe<PostCreateNestedManyWithoutSenderInput>;
  profitable?: InputMaybe<ProfitableCreateNestedOneWithoutUserInput>;
  receive_email?: InputMaybe<Scalars['String']>;
  receive_phone?: InputMaybe<Scalars['String']>;
  reportByMe?: InputMaybe<ReportUserCreateNestedManyWithoutReporterInput>;
  reported?: InputMaybe<ReportUserCreateNestedManyWithoutUserInput>;
  reports?: InputMaybe<ReportCreateNestedManyWithoutReporterInput>;
  settingNotification?: InputMaybe<SettingNotificationCreateNestedOneWithoutUserInput>;
  settingPrivacy?: InputMaybe<SettingPrivacyCreateNestedOneWithoutUserInput>;
  signature?: InputMaybe<SignatureCreateNestedManyWithoutOwnerInput>;
  storePurchaseHistory?: InputMaybe<CartItemCreateNestedManyWithoutCustomerInput>;
  tagsUsed?: InputMaybe<PostTagCreateNestedManyWithoutUserInput>;
  thirdpartyId: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  vAddrMapping?: InputMaybe<VirtualAddrMappingCreateNestedOneWithoutOwnerInput>;
  valid?: InputMaybe<Scalars['Boolean']>;
  website?: InputMaybe<Scalars['String']>;
};

export type UserCreateWithoutCoinsInput = {
  accessToken?: InputMaybe<Scalars['String']>;
  address?: InputMaybe<AddressCreateNestedManyWithoutOwnerInput>;
  attachments?: InputMaybe<AttachmentCreateNestedManyWithoutOwnerInput>;
  authType: UserAuthType;
  bio?: InputMaybe<Scalars['String']>;
  campaignNanhui?: InputMaybe<CampaignNanhuiCreateNestedManyWithoutSubmitterInput>;
  campainZeczec?: InputMaybe<CampainZeczecCreateNestedOneWithoutRegisterUserInput>;
  card?: InputMaybe<CardCreateNestedManyWithoutCreatorInput>;
  collection?: InputMaybe<CollectionCreateNestedManyWithoutOwnerInput>;
  country?: InputMaybe<Scalars['String']>;
  couponUsages?: InputMaybe<CouponUsageCreateNestedManyWithoutUserInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdStamps?: InputMaybe<StampCreateNestedManyWithoutCreatorInput>;
  detail?: InputMaybe<OrderDetailCreateNestedManyWithoutReceiverInput>;
  deviceToken?: InputMaybe<DeviceTokenCreateNestedManyWithoutUserInput>;
  email?: InputMaybe<Scalars['String']>;
  emailTos?: InputMaybe<EmailToCreateNestedManyWithoutOwnerInput>;
  favoriteStoreItems?: InputMaybe<StoreItemCreateNestedManyWithoutFavoriteByUsersInput>;
  fetchFriends?: InputMaybe<UserFriendCreateNestedManyWithoutOwnerInput>;
  followProfitables?: InputMaybe<ProfitableCreateNestedManyWithoutFollowedByInput>;
  followedBy?: InputMaybe<UserCreateNestedManyWithoutFollowingInput>;
  following?: InputMaybe<UserCreateNestedManyWithoutFollowedByInput>;
  gender?: InputMaybe<Scalars['String']>;
  groupsJoin?: InputMaybe<GroupCreateNestedManyWithoutMembersInput>;
  groupsOwn?: InputMaybe<GroupCreateNestedManyWithoutOwnerInput>;
  headUri?: InputMaybe<Scalars['String']>;
  history?: InputMaybe<OrderDetailHistoryCreateNestedManyWithoutOperatorInput>;
  id?: InputMaybe<Scalars['String']>;
  idStr: Scalars['String'];
  inFriends?: InputMaybe<UserFriendCreateNestedManyWithoutUsersInput>;
  inValidReasonHistories?: InputMaybe<InValidReasonCreateNestedManyWithoutUserInput>;
  lastLogin?: InputMaybe<Scalars['DateTime']>;
  lastTokenUpdated?: InputMaybe<Scalars['DateTime']>;
  likePosts?: InputMaybe<PostCreateNestedManyWithoutLikeUsersInput>;
  manualAddrs?: InputMaybe<ManualAddrCreateNestedManyWithoutOwnerInput>;
  myCoupons?: InputMaybe<CouponEntityCreateNestedManyWithoutHolderInput>;
  name: Scalars['String'];
  notificationReceiver?: InputMaybe<NotificationCreateNestedManyWithoutReceiverInput>;
  notificationSender?: InputMaybe<NotificationCreateNestedManyWithoutSenderInput>;
  order?: InputMaybe<OrderCreateNestedManyWithoutSenderInput>;
  packagesAsReceiver?: InputMaybe<PostPackageCreateNestedManyWithoutReceiversInput>;
  password?: InputMaybe<Scalars['String']>;
  pcoins?: InputMaybe<Scalars['Decimal']>;
  phone?: InputMaybe<Scalars['String']>;
  postPackage?: InputMaybe<PostPackageCreateNestedManyWithoutSenderInput>;
  postsIn?: InputMaybe<PostCreateNestedManyWithoutReceiverInput>;
  postsOut?: InputMaybe<PostCreateNestedManyWithoutSenderInput>;
  profitable?: InputMaybe<ProfitableCreateNestedOneWithoutUserInput>;
  receive_email?: InputMaybe<Scalars['String']>;
  receive_phone?: InputMaybe<Scalars['String']>;
  reportByMe?: InputMaybe<ReportUserCreateNestedManyWithoutReporterInput>;
  reported?: InputMaybe<ReportUserCreateNestedManyWithoutUserInput>;
  reports?: InputMaybe<ReportCreateNestedManyWithoutReporterInput>;
  settingNotification?: InputMaybe<SettingNotificationCreateNestedOneWithoutUserInput>;
  settingPrivacy?: InputMaybe<SettingPrivacyCreateNestedOneWithoutUserInput>;
  signature?: InputMaybe<SignatureCreateNestedManyWithoutOwnerInput>;
  storePurchaseHistory?: InputMaybe<CartItemCreateNestedManyWithoutCustomerInput>;
  tagsUsed?: InputMaybe<PostTagCreateNestedManyWithoutUserInput>;
  thirdpartyId: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  vAddrMapping?: InputMaybe<VirtualAddrMappingCreateNestedOneWithoutOwnerInput>;
  valid?: InputMaybe<Scalars['Boolean']>;
  website?: InputMaybe<Scalars['String']>;
};

export type UserCreateWithoutCollectionInput = {
  accessToken?: InputMaybe<Scalars['String']>;
  address?: InputMaybe<AddressCreateNestedManyWithoutOwnerInput>;
  attachments?: InputMaybe<AttachmentCreateNestedManyWithoutOwnerInput>;
  authType: UserAuthType;
  bio?: InputMaybe<Scalars['String']>;
  campaignNanhui?: InputMaybe<CampaignNanhuiCreateNestedManyWithoutSubmitterInput>;
  campainZeczec?: InputMaybe<CampainZeczecCreateNestedOneWithoutRegisterUserInput>;
  card?: InputMaybe<CardCreateNestedManyWithoutCreatorInput>;
  coins?: InputMaybe<CoinCreateNestedManyWithoutUserInput>;
  country?: InputMaybe<Scalars['String']>;
  couponUsages?: InputMaybe<CouponUsageCreateNestedManyWithoutUserInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdStamps?: InputMaybe<StampCreateNestedManyWithoutCreatorInput>;
  detail?: InputMaybe<OrderDetailCreateNestedManyWithoutReceiverInput>;
  deviceToken?: InputMaybe<DeviceTokenCreateNestedManyWithoutUserInput>;
  email?: InputMaybe<Scalars['String']>;
  emailTos?: InputMaybe<EmailToCreateNestedManyWithoutOwnerInput>;
  favoriteStoreItems?: InputMaybe<StoreItemCreateNestedManyWithoutFavoriteByUsersInput>;
  fetchFriends?: InputMaybe<UserFriendCreateNestedManyWithoutOwnerInput>;
  followProfitables?: InputMaybe<ProfitableCreateNestedManyWithoutFollowedByInput>;
  followedBy?: InputMaybe<UserCreateNestedManyWithoutFollowingInput>;
  following?: InputMaybe<UserCreateNestedManyWithoutFollowedByInput>;
  gender?: InputMaybe<Scalars['String']>;
  groupsJoin?: InputMaybe<GroupCreateNestedManyWithoutMembersInput>;
  groupsOwn?: InputMaybe<GroupCreateNestedManyWithoutOwnerInput>;
  headUri?: InputMaybe<Scalars['String']>;
  history?: InputMaybe<OrderDetailHistoryCreateNestedManyWithoutOperatorInput>;
  id?: InputMaybe<Scalars['String']>;
  idStr: Scalars['String'];
  inFriends?: InputMaybe<UserFriendCreateNestedManyWithoutUsersInput>;
  inValidReasonHistories?: InputMaybe<InValidReasonCreateNestedManyWithoutUserInput>;
  lastLogin?: InputMaybe<Scalars['DateTime']>;
  lastTokenUpdated?: InputMaybe<Scalars['DateTime']>;
  likePosts?: InputMaybe<PostCreateNestedManyWithoutLikeUsersInput>;
  manualAddrs?: InputMaybe<ManualAddrCreateNestedManyWithoutOwnerInput>;
  myCoupons?: InputMaybe<CouponEntityCreateNestedManyWithoutHolderInput>;
  name: Scalars['String'];
  notificationReceiver?: InputMaybe<NotificationCreateNestedManyWithoutReceiverInput>;
  notificationSender?: InputMaybe<NotificationCreateNestedManyWithoutSenderInput>;
  order?: InputMaybe<OrderCreateNestedManyWithoutSenderInput>;
  packagesAsReceiver?: InputMaybe<PostPackageCreateNestedManyWithoutReceiversInput>;
  password?: InputMaybe<Scalars['String']>;
  pcoins?: InputMaybe<Scalars['Decimal']>;
  phone?: InputMaybe<Scalars['String']>;
  postPackage?: InputMaybe<PostPackageCreateNestedManyWithoutSenderInput>;
  postsIn?: InputMaybe<PostCreateNestedManyWithoutReceiverInput>;
  postsOut?: InputMaybe<PostCreateNestedManyWithoutSenderInput>;
  profitable?: InputMaybe<ProfitableCreateNestedOneWithoutUserInput>;
  receive_email?: InputMaybe<Scalars['String']>;
  receive_phone?: InputMaybe<Scalars['String']>;
  reportByMe?: InputMaybe<ReportUserCreateNestedManyWithoutReporterInput>;
  reported?: InputMaybe<ReportUserCreateNestedManyWithoutUserInput>;
  reports?: InputMaybe<ReportCreateNestedManyWithoutReporterInput>;
  settingNotification?: InputMaybe<SettingNotificationCreateNestedOneWithoutUserInput>;
  settingPrivacy?: InputMaybe<SettingPrivacyCreateNestedOneWithoutUserInput>;
  signature?: InputMaybe<SignatureCreateNestedManyWithoutOwnerInput>;
  storePurchaseHistory?: InputMaybe<CartItemCreateNestedManyWithoutCustomerInput>;
  tagsUsed?: InputMaybe<PostTagCreateNestedManyWithoutUserInput>;
  thirdpartyId: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  vAddrMapping?: InputMaybe<VirtualAddrMappingCreateNestedOneWithoutOwnerInput>;
  valid?: InputMaybe<Scalars['Boolean']>;
  website?: InputMaybe<Scalars['String']>;
};

export type UserCreateWithoutCouponUsagesInput = {
  accessToken?: InputMaybe<Scalars['String']>;
  address?: InputMaybe<AddressCreateNestedManyWithoutOwnerInput>;
  attachments?: InputMaybe<AttachmentCreateNestedManyWithoutOwnerInput>;
  authType: UserAuthType;
  bio?: InputMaybe<Scalars['String']>;
  campaignNanhui?: InputMaybe<CampaignNanhuiCreateNestedManyWithoutSubmitterInput>;
  campainZeczec?: InputMaybe<CampainZeczecCreateNestedOneWithoutRegisterUserInput>;
  card?: InputMaybe<CardCreateNestedManyWithoutCreatorInput>;
  coins?: InputMaybe<CoinCreateNestedManyWithoutUserInput>;
  collection?: InputMaybe<CollectionCreateNestedManyWithoutOwnerInput>;
  country?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdStamps?: InputMaybe<StampCreateNestedManyWithoutCreatorInput>;
  detail?: InputMaybe<OrderDetailCreateNestedManyWithoutReceiverInput>;
  deviceToken?: InputMaybe<DeviceTokenCreateNestedManyWithoutUserInput>;
  email?: InputMaybe<Scalars['String']>;
  emailTos?: InputMaybe<EmailToCreateNestedManyWithoutOwnerInput>;
  favoriteStoreItems?: InputMaybe<StoreItemCreateNestedManyWithoutFavoriteByUsersInput>;
  fetchFriends?: InputMaybe<UserFriendCreateNestedManyWithoutOwnerInput>;
  followProfitables?: InputMaybe<ProfitableCreateNestedManyWithoutFollowedByInput>;
  followedBy?: InputMaybe<UserCreateNestedManyWithoutFollowingInput>;
  following?: InputMaybe<UserCreateNestedManyWithoutFollowedByInput>;
  gender?: InputMaybe<Scalars['String']>;
  groupsJoin?: InputMaybe<GroupCreateNestedManyWithoutMembersInput>;
  groupsOwn?: InputMaybe<GroupCreateNestedManyWithoutOwnerInput>;
  headUri?: InputMaybe<Scalars['String']>;
  history?: InputMaybe<OrderDetailHistoryCreateNestedManyWithoutOperatorInput>;
  id?: InputMaybe<Scalars['String']>;
  idStr: Scalars['String'];
  inFriends?: InputMaybe<UserFriendCreateNestedManyWithoutUsersInput>;
  inValidReasonHistories?: InputMaybe<InValidReasonCreateNestedManyWithoutUserInput>;
  lastLogin?: InputMaybe<Scalars['DateTime']>;
  lastTokenUpdated?: InputMaybe<Scalars['DateTime']>;
  likePosts?: InputMaybe<PostCreateNestedManyWithoutLikeUsersInput>;
  manualAddrs?: InputMaybe<ManualAddrCreateNestedManyWithoutOwnerInput>;
  myCoupons?: InputMaybe<CouponEntityCreateNestedManyWithoutHolderInput>;
  name: Scalars['String'];
  notificationReceiver?: InputMaybe<NotificationCreateNestedManyWithoutReceiverInput>;
  notificationSender?: InputMaybe<NotificationCreateNestedManyWithoutSenderInput>;
  order?: InputMaybe<OrderCreateNestedManyWithoutSenderInput>;
  packagesAsReceiver?: InputMaybe<PostPackageCreateNestedManyWithoutReceiversInput>;
  password?: InputMaybe<Scalars['String']>;
  pcoins?: InputMaybe<Scalars['Decimal']>;
  phone?: InputMaybe<Scalars['String']>;
  postPackage?: InputMaybe<PostPackageCreateNestedManyWithoutSenderInput>;
  postsIn?: InputMaybe<PostCreateNestedManyWithoutReceiverInput>;
  postsOut?: InputMaybe<PostCreateNestedManyWithoutSenderInput>;
  profitable?: InputMaybe<ProfitableCreateNestedOneWithoutUserInput>;
  receive_email?: InputMaybe<Scalars['String']>;
  receive_phone?: InputMaybe<Scalars['String']>;
  reportByMe?: InputMaybe<ReportUserCreateNestedManyWithoutReporterInput>;
  reported?: InputMaybe<ReportUserCreateNestedManyWithoutUserInput>;
  reports?: InputMaybe<ReportCreateNestedManyWithoutReporterInput>;
  settingNotification?: InputMaybe<SettingNotificationCreateNestedOneWithoutUserInput>;
  settingPrivacy?: InputMaybe<SettingPrivacyCreateNestedOneWithoutUserInput>;
  signature?: InputMaybe<SignatureCreateNestedManyWithoutOwnerInput>;
  storePurchaseHistory?: InputMaybe<CartItemCreateNestedManyWithoutCustomerInput>;
  tagsUsed?: InputMaybe<PostTagCreateNestedManyWithoutUserInput>;
  thirdpartyId: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  vAddrMapping?: InputMaybe<VirtualAddrMappingCreateNestedOneWithoutOwnerInput>;
  valid?: InputMaybe<Scalars['Boolean']>;
  website?: InputMaybe<Scalars['String']>;
};

export type UserCreateWithoutCreatedStampsInput = {
  accessToken?: InputMaybe<Scalars['String']>;
  address?: InputMaybe<AddressCreateNestedManyWithoutOwnerInput>;
  attachments?: InputMaybe<AttachmentCreateNestedManyWithoutOwnerInput>;
  authType: UserAuthType;
  bio?: InputMaybe<Scalars['String']>;
  campaignNanhui?: InputMaybe<CampaignNanhuiCreateNestedManyWithoutSubmitterInput>;
  campainZeczec?: InputMaybe<CampainZeczecCreateNestedOneWithoutRegisterUserInput>;
  card?: InputMaybe<CardCreateNestedManyWithoutCreatorInput>;
  coins?: InputMaybe<CoinCreateNestedManyWithoutUserInput>;
  collection?: InputMaybe<CollectionCreateNestedManyWithoutOwnerInput>;
  country?: InputMaybe<Scalars['String']>;
  couponUsages?: InputMaybe<CouponUsageCreateNestedManyWithoutUserInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  detail?: InputMaybe<OrderDetailCreateNestedManyWithoutReceiverInput>;
  deviceToken?: InputMaybe<DeviceTokenCreateNestedManyWithoutUserInput>;
  email?: InputMaybe<Scalars['String']>;
  emailTos?: InputMaybe<EmailToCreateNestedManyWithoutOwnerInput>;
  favoriteStoreItems?: InputMaybe<StoreItemCreateNestedManyWithoutFavoriteByUsersInput>;
  fetchFriends?: InputMaybe<UserFriendCreateNestedManyWithoutOwnerInput>;
  followProfitables?: InputMaybe<ProfitableCreateNestedManyWithoutFollowedByInput>;
  followedBy?: InputMaybe<UserCreateNestedManyWithoutFollowingInput>;
  following?: InputMaybe<UserCreateNestedManyWithoutFollowedByInput>;
  gender?: InputMaybe<Scalars['String']>;
  groupsJoin?: InputMaybe<GroupCreateNestedManyWithoutMembersInput>;
  groupsOwn?: InputMaybe<GroupCreateNestedManyWithoutOwnerInput>;
  headUri?: InputMaybe<Scalars['String']>;
  history?: InputMaybe<OrderDetailHistoryCreateNestedManyWithoutOperatorInput>;
  id?: InputMaybe<Scalars['String']>;
  idStr: Scalars['String'];
  inFriends?: InputMaybe<UserFriendCreateNestedManyWithoutUsersInput>;
  inValidReasonHistories?: InputMaybe<InValidReasonCreateNestedManyWithoutUserInput>;
  lastLogin?: InputMaybe<Scalars['DateTime']>;
  lastTokenUpdated?: InputMaybe<Scalars['DateTime']>;
  likePosts?: InputMaybe<PostCreateNestedManyWithoutLikeUsersInput>;
  manualAddrs?: InputMaybe<ManualAddrCreateNestedManyWithoutOwnerInput>;
  myCoupons?: InputMaybe<CouponEntityCreateNestedManyWithoutHolderInput>;
  name: Scalars['String'];
  notificationReceiver?: InputMaybe<NotificationCreateNestedManyWithoutReceiverInput>;
  notificationSender?: InputMaybe<NotificationCreateNestedManyWithoutSenderInput>;
  order?: InputMaybe<OrderCreateNestedManyWithoutSenderInput>;
  packagesAsReceiver?: InputMaybe<PostPackageCreateNestedManyWithoutReceiversInput>;
  password?: InputMaybe<Scalars['String']>;
  pcoins?: InputMaybe<Scalars['Decimal']>;
  phone?: InputMaybe<Scalars['String']>;
  postPackage?: InputMaybe<PostPackageCreateNestedManyWithoutSenderInput>;
  postsIn?: InputMaybe<PostCreateNestedManyWithoutReceiverInput>;
  postsOut?: InputMaybe<PostCreateNestedManyWithoutSenderInput>;
  profitable?: InputMaybe<ProfitableCreateNestedOneWithoutUserInput>;
  receive_email?: InputMaybe<Scalars['String']>;
  receive_phone?: InputMaybe<Scalars['String']>;
  reportByMe?: InputMaybe<ReportUserCreateNestedManyWithoutReporterInput>;
  reported?: InputMaybe<ReportUserCreateNestedManyWithoutUserInput>;
  reports?: InputMaybe<ReportCreateNestedManyWithoutReporterInput>;
  settingNotification?: InputMaybe<SettingNotificationCreateNestedOneWithoutUserInput>;
  settingPrivacy?: InputMaybe<SettingPrivacyCreateNestedOneWithoutUserInput>;
  signature?: InputMaybe<SignatureCreateNestedManyWithoutOwnerInput>;
  storePurchaseHistory?: InputMaybe<CartItemCreateNestedManyWithoutCustomerInput>;
  tagsUsed?: InputMaybe<PostTagCreateNestedManyWithoutUserInput>;
  thirdpartyId: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  vAddrMapping?: InputMaybe<VirtualAddrMappingCreateNestedOneWithoutOwnerInput>;
  valid?: InputMaybe<Scalars['Boolean']>;
  website?: InputMaybe<Scalars['String']>;
};

export type UserCreateWithoutDetailInput = {
  accessToken?: InputMaybe<Scalars['String']>;
  address?: InputMaybe<AddressCreateNestedManyWithoutOwnerInput>;
  attachments?: InputMaybe<AttachmentCreateNestedManyWithoutOwnerInput>;
  authType: UserAuthType;
  bio?: InputMaybe<Scalars['String']>;
  campaignNanhui?: InputMaybe<CampaignNanhuiCreateNestedManyWithoutSubmitterInput>;
  campainZeczec?: InputMaybe<CampainZeczecCreateNestedOneWithoutRegisterUserInput>;
  card?: InputMaybe<CardCreateNestedManyWithoutCreatorInput>;
  coins?: InputMaybe<CoinCreateNestedManyWithoutUserInput>;
  collection?: InputMaybe<CollectionCreateNestedManyWithoutOwnerInput>;
  country?: InputMaybe<Scalars['String']>;
  couponUsages?: InputMaybe<CouponUsageCreateNestedManyWithoutUserInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdStamps?: InputMaybe<StampCreateNestedManyWithoutCreatorInput>;
  deviceToken?: InputMaybe<DeviceTokenCreateNestedManyWithoutUserInput>;
  email?: InputMaybe<Scalars['String']>;
  emailTos?: InputMaybe<EmailToCreateNestedManyWithoutOwnerInput>;
  favoriteStoreItems?: InputMaybe<StoreItemCreateNestedManyWithoutFavoriteByUsersInput>;
  fetchFriends?: InputMaybe<UserFriendCreateNestedManyWithoutOwnerInput>;
  followProfitables?: InputMaybe<ProfitableCreateNestedManyWithoutFollowedByInput>;
  followedBy?: InputMaybe<UserCreateNestedManyWithoutFollowingInput>;
  following?: InputMaybe<UserCreateNestedManyWithoutFollowedByInput>;
  gender?: InputMaybe<Scalars['String']>;
  groupsJoin?: InputMaybe<GroupCreateNestedManyWithoutMembersInput>;
  groupsOwn?: InputMaybe<GroupCreateNestedManyWithoutOwnerInput>;
  headUri?: InputMaybe<Scalars['String']>;
  history?: InputMaybe<OrderDetailHistoryCreateNestedManyWithoutOperatorInput>;
  id?: InputMaybe<Scalars['String']>;
  idStr: Scalars['String'];
  inFriends?: InputMaybe<UserFriendCreateNestedManyWithoutUsersInput>;
  inValidReasonHistories?: InputMaybe<InValidReasonCreateNestedManyWithoutUserInput>;
  lastLogin?: InputMaybe<Scalars['DateTime']>;
  lastTokenUpdated?: InputMaybe<Scalars['DateTime']>;
  likePosts?: InputMaybe<PostCreateNestedManyWithoutLikeUsersInput>;
  manualAddrs?: InputMaybe<ManualAddrCreateNestedManyWithoutOwnerInput>;
  myCoupons?: InputMaybe<CouponEntityCreateNestedManyWithoutHolderInput>;
  name: Scalars['String'];
  notificationReceiver?: InputMaybe<NotificationCreateNestedManyWithoutReceiverInput>;
  notificationSender?: InputMaybe<NotificationCreateNestedManyWithoutSenderInput>;
  order?: InputMaybe<OrderCreateNestedManyWithoutSenderInput>;
  packagesAsReceiver?: InputMaybe<PostPackageCreateNestedManyWithoutReceiversInput>;
  password?: InputMaybe<Scalars['String']>;
  pcoins?: InputMaybe<Scalars['Decimal']>;
  phone?: InputMaybe<Scalars['String']>;
  postPackage?: InputMaybe<PostPackageCreateNestedManyWithoutSenderInput>;
  postsIn?: InputMaybe<PostCreateNestedManyWithoutReceiverInput>;
  postsOut?: InputMaybe<PostCreateNestedManyWithoutSenderInput>;
  profitable?: InputMaybe<ProfitableCreateNestedOneWithoutUserInput>;
  receive_email?: InputMaybe<Scalars['String']>;
  receive_phone?: InputMaybe<Scalars['String']>;
  reportByMe?: InputMaybe<ReportUserCreateNestedManyWithoutReporterInput>;
  reported?: InputMaybe<ReportUserCreateNestedManyWithoutUserInput>;
  reports?: InputMaybe<ReportCreateNestedManyWithoutReporterInput>;
  settingNotification?: InputMaybe<SettingNotificationCreateNestedOneWithoutUserInput>;
  settingPrivacy?: InputMaybe<SettingPrivacyCreateNestedOneWithoutUserInput>;
  signature?: InputMaybe<SignatureCreateNestedManyWithoutOwnerInput>;
  storePurchaseHistory?: InputMaybe<CartItemCreateNestedManyWithoutCustomerInput>;
  tagsUsed?: InputMaybe<PostTagCreateNestedManyWithoutUserInput>;
  thirdpartyId: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  vAddrMapping?: InputMaybe<VirtualAddrMappingCreateNestedOneWithoutOwnerInput>;
  valid?: InputMaybe<Scalars['Boolean']>;
  website?: InputMaybe<Scalars['String']>;
};

export type UserCreateWithoutDeviceTokenInput = {
  accessToken?: InputMaybe<Scalars['String']>;
  address?: InputMaybe<AddressCreateNestedManyWithoutOwnerInput>;
  attachments?: InputMaybe<AttachmentCreateNestedManyWithoutOwnerInput>;
  authType: UserAuthType;
  bio?: InputMaybe<Scalars['String']>;
  campaignNanhui?: InputMaybe<CampaignNanhuiCreateNestedManyWithoutSubmitterInput>;
  campainZeczec?: InputMaybe<CampainZeczecCreateNestedOneWithoutRegisterUserInput>;
  card?: InputMaybe<CardCreateNestedManyWithoutCreatorInput>;
  coins?: InputMaybe<CoinCreateNestedManyWithoutUserInput>;
  collection?: InputMaybe<CollectionCreateNestedManyWithoutOwnerInput>;
  country?: InputMaybe<Scalars['String']>;
  couponUsages?: InputMaybe<CouponUsageCreateNestedManyWithoutUserInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdStamps?: InputMaybe<StampCreateNestedManyWithoutCreatorInput>;
  detail?: InputMaybe<OrderDetailCreateNestedManyWithoutReceiverInput>;
  email?: InputMaybe<Scalars['String']>;
  emailTos?: InputMaybe<EmailToCreateNestedManyWithoutOwnerInput>;
  favoriteStoreItems?: InputMaybe<StoreItemCreateNestedManyWithoutFavoriteByUsersInput>;
  fetchFriends?: InputMaybe<UserFriendCreateNestedManyWithoutOwnerInput>;
  followProfitables?: InputMaybe<ProfitableCreateNestedManyWithoutFollowedByInput>;
  followedBy?: InputMaybe<UserCreateNestedManyWithoutFollowingInput>;
  following?: InputMaybe<UserCreateNestedManyWithoutFollowedByInput>;
  gender?: InputMaybe<Scalars['String']>;
  groupsJoin?: InputMaybe<GroupCreateNestedManyWithoutMembersInput>;
  groupsOwn?: InputMaybe<GroupCreateNestedManyWithoutOwnerInput>;
  headUri?: InputMaybe<Scalars['String']>;
  history?: InputMaybe<OrderDetailHistoryCreateNestedManyWithoutOperatorInput>;
  id?: InputMaybe<Scalars['String']>;
  idStr: Scalars['String'];
  inFriends?: InputMaybe<UserFriendCreateNestedManyWithoutUsersInput>;
  inValidReasonHistories?: InputMaybe<InValidReasonCreateNestedManyWithoutUserInput>;
  lastLogin?: InputMaybe<Scalars['DateTime']>;
  lastTokenUpdated?: InputMaybe<Scalars['DateTime']>;
  likePosts?: InputMaybe<PostCreateNestedManyWithoutLikeUsersInput>;
  manualAddrs?: InputMaybe<ManualAddrCreateNestedManyWithoutOwnerInput>;
  myCoupons?: InputMaybe<CouponEntityCreateNestedManyWithoutHolderInput>;
  name: Scalars['String'];
  notificationReceiver?: InputMaybe<NotificationCreateNestedManyWithoutReceiverInput>;
  notificationSender?: InputMaybe<NotificationCreateNestedManyWithoutSenderInput>;
  order?: InputMaybe<OrderCreateNestedManyWithoutSenderInput>;
  packagesAsReceiver?: InputMaybe<PostPackageCreateNestedManyWithoutReceiversInput>;
  password?: InputMaybe<Scalars['String']>;
  pcoins?: InputMaybe<Scalars['Decimal']>;
  phone?: InputMaybe<Scalars['String']>;
  postPackage?: InputMaybe<PostPackageCreateNestedManyWithoutSenderInput>;
  postsIn?: InputMaybe<PostCreateNestedManyWithoutReceiverInput>;
  postsOut?: InputMaybe<PostCreateNestedManyWithoutSenderInput>;
  profitable?: InputMaybe<ProfitableCreateNestedOneWithoutUserInput>;
  receive_email?: InputMaybe<Scalars['String']>;
  receive_phone?: InputMaybe<Scalars['String']>;
  reportByMe?: InputMaybe<ReportUserCreateNestedManyWithoutReporterInput>;
  reported?: InputMaybe<ReportUserCreateNestedManyWithoutUserInput>;
  reports?: InputMaybe<ReportCreateNestedManyWithoutReporterInput>;
  settingNotification?: InputMaybe<SettingNotificationCreateNestedOneWithoutUserInput>;
  settingPrivacy?: InputMaybe<SettingPrivacyCreateNestedOneWithoutUserInput>;
  signature?: InputMaybe<SignatureCreateNestedManyWithoutOwnerInput>;
  storePurchaseHistory?: InputMaybe<CartItemCreateNestedManyWithoutCustomerInput>;
  tagsUsed?: InputMaybe<PostTagCreateNestedManyWithoutUserInput>;
  thirdpartyId: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  vAddrMapping?: InputMaybe<VirtualAddrMappingCreateNestedOneWithoutOwnerInput>;
  valid?: InputMaybe<Scalars['Boolean']>;
  website?: InputMaybe<Scalars['String']>;
};

export type UserCreateWithoutEmailTosInput = {
  accessToken?: InputMaybe<Scalars['String']>;
  address?: InputMaybe<AddressCreateNestedManyWithoutOwnerInput>;
  attachments?: InputMaybe<AttachmentCreateNestedManyWithoutOwnerInput>;
  authType: UserAuthType;
  bio?: InputMaybe<Scalars['String']>;
  campaignNanhui?: InputMaybe<CampaignNanhuiCreateNestedManyWithoutSubmitterInput>;
  campainZeczec?: InputMaybe<CampainZeczecCreateNestedOneWithoutRegisterUserInput>;
  card?: InputMaybe<CardCreateNestedManyWithoutCreatorInput>;
  coins?: InputMaybe<CoinCreateNestedManyWithoutUserInput>;
  collection?: InputMaybe<CollectionCreateNestedManyWithoutOwnerInput>;
  country?: InputMaybe<Scalars['String']>;
  couponUsages?: InputMaybe<CouponUsageCreateNestedManyWithoutUserInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdStamps?: InputMaybe<StampCreateNestedManyWithoutCreatorInput>;
  detail?: InputMaybe<OrderDetailCreateNestedManyWithoutReceiverInput>;
  deviceToken?: InputMaybe<DeviceTokenCreateNestedManyWithoutUserInput>;
  email?: InputMaybe<Scalars['String']>;
  favoriteStoreItems?: InputMaybe<StoreItemCreateNestedManyWithoutFavoriteByUsersInput>;
  fetchFriends?: InputMaybe<UserFriendCreateNestedManyWithoutOwnerInput>;
  followProfitables?: InputMaybe<ProfitableCreateNestedManyWithoutFollowedByInput>;
  followedBy?: InputMaybe<UserCreateNestedManyWithoutFollowingInput>;
  following?: InputMaybe<UserCreateNestedManyWithoutFollowedByInput>;
  gender?: InputMaybe<Scalars['String']>;
  groupsJoin?: InputMaybe<GroupCreateNestedManyWithoutMembersInput>;
  groupsOwn?: InputMaybe<GroupCreateNestedManyWithoutOwnerInput>;
  headUri?: InputMaybe<Scalars['String']>;
  history?: InputMaybe<OrderDetailHistoryCreateNestedManyWithoutOperatorInput>;
  id?: InputMaybe<Scalars['String']>;
  idStr: Scalars['String'];
  inFriends?: InputMaybe<UserFriendCreateNestedManyWithoutUsersInput>;
  inValidReasonHistories?: InputMaybe<InValidReasonCreateNestedManyWithoutUserInput>;
  lastLogin?: InputMaybe<Scalars['DateTime']>;
  lastTokenUpdated?: InputMaybe<Scalars['DateTime']>;
  likePosts?: InputMaybe<PostCreateNestedManyWithoutLikeUsersInput>;
  manualAddrs?: InputMaybe<ManualAddrCreateNestedManyWithoutOwnerInput>;
  myCoupons?: InputMaybe<CouponEntityCreateNestedManyWithoutHolderInput>;
  name: Scalars['String'];
  notificationReceiver?: InputMaybe<NotificationCreateNestedManyWithoutReceiverInput>;
  notificationSender?: InputMaybe<NotificationCreateNestedManyWithoutSenderInput>;
  order?: InputMaybe<OrderCreateNestedManyWithoutSenderInput>;
  packagesAsReceiver?: InputMaybe<PostPackageCreateNestedManyWithoutReceiversInput>;
  password?: InputMaybe<Scalars['String']>;
  pcoins?: InputMaybe<Scalars['Decimal']>;
  phone?: InputMaybe<Scalars['String']>;
  postPackage?: InputMaybe<PostPackageCreateNestedManyWithoutSenderInput>;
  postsIn?: InputMaybe<PostCreateNestedManyWithoutReceiverInput>;
  postsOut?: InputMaybe<PostCreateNestedManyWithoutSenderInput>;
  profitable?: InputMaybe<ProfitableCreateNestedOneWithoutUserInput>;
  receive_email?: InputMaybe<Scalars['String']>;
  receive_phone?: InputMaybe<Scalars['String']>;
  reportByMe?: InputMaybe<ReportUserCreateNestedManyWithoutReporterInput>;
  reported?: InputMaybe<ReportUserCreateNestedManyWithoutUserInput>;
  reports?: InputMaybe<ReportCreateNestedManyWithoutReporterInput>;
  settingNotification?: InputMaybe<SettingNotificationCreateNestedOneWithoutUserInput>;
  settingPrivacy?: InputMaybe<SettingPrivacyCreateNestedOneWithoutUserInput>;
  signature?: InputMaybe<SignatureCreateNestedManyWithoutOwnerInput>;
  storePurchaseHistory?: InputMaybe<CartItemCreateNestedManyWithoutCustomerInput>;
  tagsUsed?: InputMaybe<PostTagCreateNestedManyWithoutUserInput>;
  thirdpartyId: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  vAddrMapping?: InputMaybe<VirtualAddrMappingCreateNestedOneWithoutOwnerInput>;
  valid?: InputMaybe<Scalars['Boolean']>;
  website?: InputMaybe<Scalars['String']>;
};

export type UserCreateWithoutFavoriteStoreItemsInput = {
  accessToken?: InputMaybe<Scalars['String']>;
  address?: InputMaybe<AddressCreateNestedManyWithoutOwnerInput>;
  attachments?: InputMaybe<AttachmentCreateNestedManyWithoutOwnerInput>;
  authType: UserAuthType;
  bio?: InputMaybe<Scalars['String']>;
  campaignNanhui?: InputMaybe<CampaignNanhuiCreateNestedManyWithoutSubmitterInput>;
  campainZeczec?: InputMaybe<CampainZeczecCreateNestedOneWithoutRegisterUserInput>;
  card?: InputMaybe<CardCreateNestedManyWithoutCreatorInput>;
  coins?: InputMaybe<CoinCreateNestedManyWithoutUserInput>;
  collection?: InputMaybe<CollectionCreateNestedManyWithoutOwnerInput>;
  country?: InputMaybe<Scalars['String']>;
  couponUsages?: InputMaybe<CouponUsageCreateNestedManyWithoutUserInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdStamps?: InputMaybe<StampCreateNestedManyWithoutCreatorInput>;
  detail?: InputMaybe<OrderDetailCreateNestedManyWithoutReceiverInput>;
  deviceToken?: InputMaybe<DeviceTokenCreateNestedManyWithoutUserInput>;
  email?: InputMaybe<Scalars['String']>;
  emailTos?: InputMaybe<EmailToCreateNestedManyWithoutOwnerInput>;
  fetchFriends?: InputMaybe<UserFriendCreateNestedManyWithoutOwnerInput>;
  followProfitables?: InputMaybe<ProfitableCreateNestedManyWithoutFollowedByInput>;
  followedBy?: InputMaybe<UserCreateNestedManyWithoutFollowingInput>;
  following?: InputMaybe<UserCreateNestedManyWithoutFollowedByInput>;
  gender?: InputMaybe<Scalars['String']>;
  groupsJoin?: InputMaybe<GroupCreateNestedManyWithoutMembersInput>;
  groupsOwn?: InputMaybe<GroupCreateNestedManyWithoutOwnerInput>;
  headUri?: InputMaybe<Scalars['String']>;
  history?: InputMaybe<OrderDetailHistoryCreateNestedManyWithoutOperatorInput>;
  id?: InputMaybe<Scalars['String']>;
  idStr: Scalars['String'];
  inFriends?: InputMaybe<UserFriendCreateNestedManyWithoutUsersInput>;
  inValidReasonHistories?: InputMaybe<InValidReasonCreateNestedManyWithoutUserInput>;
  lastLogin?: InputMaybe<Scalars['DateTime']>;
  lastTokenUpdated?: InputMaybe<Scalars['DateTime']>;
  likePosts?: InputMaybe<PostCreateNestedManyWithoutLikeUsersInput>;
  manualAddrs?: InputMaybe<ManualAddrCreateNestedManyWithoutOwnerInput>;
  myCoupons?: InputMaybe<CouponEntityCreateNestedManyWithoutHolderInput>;
  name: Scalars['String'];
  notificationReceiver?: InputMaybe<NotificationCreateNestedManyWithoutReceiverInput>;
  notificationSender?: InputMaybe<NotificationCreateNestedManyWithoutSenderInput>;
  order?: InputMaybe<OrderCreateNestedManyWithoutSenderInput>;
  packagesAsReceiver?: InputMaybe<PostPackageCreateNestedManyWithoutReceiversInput>;
  password?: InputMaybe<Scalars['String']>;
  pcoins?: InputMaybe<Scalars['Decimal']>;
  phone?: InputMaybe<Scalars['String']>;
  postPackage?: InputMaybe<PostPackageCreateNestedManyWithoutSenderInput>;
  postsIn?: InputMaybe<PostCreateNestedManyWithoutReceiverInput>;
  postsOut?: InputMaybe<PostCreateNestedManyWithoutSenderInput>;
  profitable?: InputMaybe<ProfitableCreateNestedOneWithoutUserInput>;
  receive_email?: InputMaybe<Scalars['String']>;
  receive_phone?: InputMaybe<Scalars['String']>;
  reportByMe?: InputMaybe<ReportUserCreateNestedManyWithoutReporterInput>;
  reported?: InputMaybe<ReportUserCreateNestedManyWithoutUserInput>;
  reports?: InputMaybe<ReportCreateNestedManyWithoutReporterInput>;
  settingNotification?: InputMaybe<SettingNotificationCreateNestedOneWithoutUserInput>;
  settingPrivacy?: InputMaybe<SettingPrivacyCreateNestedOneWithoutUserInput>;
  signature?: InputMaybe<SignatureCreateNestedManyWithoutOwnerInput>;
  storePurchaseHistory?: InputMaybe<CartItemCreateNestedManyWithoutCustomerInput>;
  tagsUsed?: InputMaybe<PostTagCreateNestedManyWithoutUserInput>;
  thirdpartyId: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  vAddrMapping?: InputMaybe<VirtualAddrMappingCreateNestedOneWithoutOwnerInput>;
  valid?: InputMaybe<Scalars['Boolean']>;
  website?: InputMaybe<Scalars['String']>;
};

export type UserCreateWithoutFollowProfitablesInput = {
  accessToken?: InputMaybe<Scalars['String']>;
  address?: InputMaybe<AddressCreateNestedManyWithoutOwnerInput>;
  attachments?: InputMaybe<AttachmentCreateNestedManyWithoutOwnerInput>;
  authType: UserAuthType;
  bio?: InputMaybe<Scalars['String']>;
  campaignNanhui?: InputMaybe<CampaignNanhuiCreateNestedManyWithoutSubmitterInput>;
  campainZeczec?: InputMaybe<CampainZeczecCreateNestedOneWithoutRegisterUserInput>;
  card?: InputMaybe<CardCreateNestedManyWithoutCreatorInput>;
  coins?: InputMaybe<CoinCreateNestedManyWithoutUserInput>;
  collection?: InputMaybe<CollectionCreateNestedManyWithoutOwnerInput>;
  country?: InputMaybe<Scalars['String']>;
  couponUsages?: InputMaybe<CouponUsageCreateNestedManyWithoutUserInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdStamps?: InputMaybe<StampCreateNestedManyWithoutCreatorInput>;
  detail?: InputMaybe<OrderDetailCreateNestedManyWithoutReceiverInput>;
  deviceToken?: InputMaybe<DeviceTokenCreateNestedManyWithoutUserInput>;
  email?: InputMaybe<Scalars['String']>;
  emailTos?: InputMaybe<EmailToCreateNestedManyWithoutOwnerInput>;
  favoriteStoreItems?: InputMaybe<StoreItemCreateNestedManyWithoutFavoriteByUsersInput>;
  fetchFriends?: InputMaybe<UserFriendCreateNestedManyWithoutOwnerInput>;
  followedBy?: InputMaybe<UserCreateNestedManyWithoutFollowingInput>;
  following?: InputMaybe<UserCreateNestedManyWithoutFollowedByInput>;
  gender?: InputMaybe<Scalars['String']>;
  groupsJoin?: InputMaybe<GroupCreateNestedManyWithoutMembersInput>;
  groupsOwn?: InputMaybe<GroupCreateNestedManyWithoutOwnerInput>;
  headUri?: InputMaybe<Scalars['String']>;
  history?: InputMaybe<OrderDetailHistoryCreateNestedManyWithoutOperatorInput>;
  id?: InputMaybe<Scalars['String']>;
  idStr: Scalars['String'];
  inFriends?: InputMaybe<UserFriendCreateNestedManyWithoutUsersInput>;
  inValidReasonHistories?: InputMaybe<InValidReasonCreateNestedManyWithoutUserInput>;
  lastLogin?: InputMaybe<Scalars['DateTime']>;
  lastTokenUpdated?: InputMaybe<Scalars['DateTime']>;
  likePosts?: InputMaybe<PostCreateNestedManyWithoutLikeUsersInput>;
  manualAddrs?: InputMaybe<ManualAddrCreateNestedManyWithoutOwnerInput>;
  myCoupons?: InputMaybe<CouponEntityCreateNestedManyWithoutHolderInput>;
  name: Scalars['String'];
  notificationReceiver?: InputMaybe<NotificationCreateNestedManyWithoutReceiverInput>;
  notificationSender?: InputMaybe<NotificationCreateNestedManyWithoutSenderInput>;
  order?: InputMaybe<OrderCreateNestedManyWithoutSenderInput>;
  packagesAsReceiver?: InputMaybe<PostPackageCreateNestedManyWithoutReceiversInput>;
  password?: InputMaybe<Scalars['String']>;
  pcoins?: InputMaybe<Scalars['Decimal']>;
  phone?: InputMaybe<Scalars['String']>;
  postPackage?: InputMaybe<PostPackageCreateNestedManyWithoutSenderInput>;
  postsIn?: InputMaybe<PostCreateNestedManyWithoutReceiverInput>;
  postsOut?: InputMaybe<PostCreateNestedManyWithoutSenderInput>;
  profitable?: InputMaybe<ProfitableCreateNestedOneWithoutUserInput>;
  receive_email?: InputMaybe<Scalars['String']>;
  receive_phone?: InputMaybe<Scalars['String']>;
  reportByMe?: InputMaybe<ReportUserCreateNestedManyWithoutReporterInput>;
  reported?: InputMaybe<ReportUserCreateNestedManyWithoutUserInput>;
  reports?: InputMaybe<ReportCreateNestedManyWithoutReporterInput>;
  settingNotification?: InputMaybe<SettingNotificationCreateNestedOneWithoutUserInput>;
  settingPrivacy?: InputMaybe<SettingPrivacyCreateNestedOneWithoutUserInput>;
  signature?: InputMaybe<SignatureCreateNestedManyWithoutOwnerInput>;
  storePurchaseHistory?: InputMaybe<CartItemCreateNestedManyWithoutCustomerInput>;
  tagsUsed?: InputMaybe<PostTagCreateNestedManyWithoutUserInput>;
  thirdpartyId: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  vAddrMapping?: InputMaybe<VirtualAddrMappingCreateNestedOneWithoutOwnerInput>;
  valid?: InputMaybe<Scalars['Boolean']>;
  website?: InputMaybe<Scalars['String']>;
};

export type UserCreateWithoutFollowedByInput = {
  accessToken?: InputMaybe<Scalars['String']>;
  address?: InputMaybe<AddressCreateNestedManyWithoutOwnerInput>;
  attachments?: InputMaybe<AttachmentCreateNestedManyWithoutOwnerInput>;
  authType: UserAuthType;
  bio?: InputMaybe<Scalars['String']>;
  campaignNanhui?: InputMaybe<CampaignNanhuiCreateNestedManyWithoutSubmitterInput>;
  campainZeczec?: InputMaybe<CampainZeczecCreateNestedOneWithoutRegisterUserInput>;
  card?: InputMaybe<CardCreateNestedManyWithoutCreatorInput>;
  coins?: InputMaybe<CoinCreateNestedManyWithoutUserInput>;
  collection?: InputMaybe<CollectionCreateNestedManyWithoutOwnerInput>;
  country?: InputMaybe<Scalars['String']>;
  couponUsages?: InputMaybe<CouponUsageCreateNestedManyWithoutUserInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdStamps?: InputMaybe<StampCreateNestedManyWithoutCreatorInput>;
  detail?: InputMaybe<OrderDetailCreateNestedManyWithoutReceiverInput>;
  deviceToken?: InputMaybe<DeviceTokenCreateNestedManyWithoutUserInput>;
  email?: InputMaybe<Scalars['String']>;
  emailTos?: InputMaybe<EmailToCreateNestedManyWithoutOwnerInput>;
  favoriteStoreItems?: InputMaybe<StoreItemCreateNestedManyWithoutFavoriteByUsersInput>;
  fetchFriends?: InputMaybe<UserFriendCreateNestedManyWithoutOwnerInput>;
  followProfitables?: InputMaybe<ProfitableCreateNestedManyWithoutFollowedByInput>;
  following?: InputMaybe<UserCreateNestedManyWithoutFollowedByInput>;
  gender?: InputMaybe<Scalars['String']>;
  groupsJoin?: InputMaybe<GroupCreateNestedManyWithoutMembersInput>;
  groupsOwn?: InputMaybe<GroupCreateNestedManyWithoutOwnerInput>;
  headUri?: InputMaybe<Scalars['String']>;
  history?: InputMaybe<OrderDetailHistoryCreateNestedManyWithoutOperatorInput>;
  id?: InputMaybe<Scalars['String']>;
  idStr: Scalars['String'];
  inFriends?: InputMaybe<UserFriendCreateNestedManyWithoutUsersInput>;
  inValidReasonHistories?: InputMaybe<InValidReasonCreateNestedManyWithoutUserInput>;
  lastLogin?: InputMaybe<Scalars['DateTime']>;
  lastTokenUpdated?: InputMaybe<Scalars['DateTime']>;
  likePosts?: InputMaybe<PostCreateNestedManyWithoutLikeUsersInput>;
  manualAddrs?: InputMaybe<ManualAddrCreateNestedManyWithoutOwnerInput>;
  myCoupons?: InputMaybe<CouponEntityCreateNestedManyWithoutHolderInput>;
  name: Scalars['String'];
  notificationReceiver?: InputMaybe<NotificationCreateNestedManyWithoutReceiverInput>;
  notificationSender?: InputMaybe<NotificationCreateNestedManyWithoutSenderInput>;
  order?: InputMaybe<OrderCreateNestedManyWithoutSenderInput>;
  packagesAsReceiver?: InputMaybe<PostPackageCreateNestedManyWithoutReceiversInput>;
  password?: InputMaybe<Scalars['String']>;
  pcoins?: InputMaybe<Scalars['Decimal']>;
  phone?: InputMaybe<Scalars['String']>;
  postPackage?: InputMaybe<PostPackageCreateNestedManyWithoutSenderInput>;
  postsIn?: InputMaybe<PostCreateNestedManyWithoutReceiverInput>;
  postsOut?: InputMaybe<PostCreateNestedManyWithoutSenderInput>;
  profitable?: InputMaybe<ProfitableCreateNestedOneWithoutUserInput>;
  receive_email?: InputMaybe<Scalars['String']>;
  receive_phone?: InputMaybe<Scalars['String']>;
  reportByMe?: InputMaybe<ReportUserCreateNestedManyWithoutReporterInput>;
  reported?: InputMaybe<ReportUserCreateNestedManyWithoutUserInput>;
  reports?: InputMaybe<ReportCreateNestedManyWithoutReporterInput>;
  settingNotification?: InputMaybe<SettingNotificationCreateNestedOneWithoutUserInput>;
  settingPrivacy?: InputMaybe<SettingPrivacyCreateNestedOneWithoutUserInput>;
  signature?: InputMaybe<SignatureCreateNestedManyWithoutOwnerInput>;
  storePurchaseHistory?: InputMaybe<CartItemCreateNestedManyWithoutCustomerInput>;
  tagsUsed?: InputMaybe<PostTagCreateNestedManyWithoutUserInput>;
  thirdpartyId: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  vAddrMapping?: InputMaybe<VirtualAddrMappingCreateNestedOneWithoutOwnerInput>;
  valid?: InputMaybe<Scalars['Boolean']>;
  website?: InputMaybe<Scalars['String']>;
};

export type UserCreateWithoutFollowingInput = {
  accessToken?: InputMaybe<Scalars['String']>;
  address?: InputMaybe<AddressCreateNestedManyWithoutOwnerInput>;
  attachments?: InputMaybe<AttachmentCreateNestedManyWithoutOwnerInput>;
  authType: UserAuthType;
  bio?: InputMaybe<Scalars['String']>;
  campaignNanhui?: InputMaybe<CampaignNanhuiCreateNestedManyWithoutSubmitterInput>;
  campainZeczec?: InputMaybe<CampainZeczecCreateNestedOneWithoutRegisterUserInput>;
  card?: InputMaybe<CardCreateNestedManyWithoutCreatorInput>;
  coins?: InputMaybe<CoinCreateNestedManyWithoutUserInput>;
  collection?: InputMaybe<CollectionCreateNestedManyWithoutOwnerInput>;
  country?: InputMaybe<Scalars['String']>;
  couponUsages?: InputMaybe<CouponUsageCreateNestedManyWithoutUserInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdStamps?: InputMaybe<StampCreateNestedManyWithoutCreatorInput>;
  detail?: InputMaybe<OrderDetailCreateNestedManyWithoutReceiverInput>;
  deviceToken?: InputMaybe<DeviceTokenCreateNestedManyWithoutUserInput>;
  email?: InputMaybe<Scalars['String']>;
  emailTos?: InputMaybe<EmailToCreateNestedManyWithoutOwnerInput>;
  favoriteStoreItems?: InputMaybe<StoreItemCreateNestedManyWithoutFavoriteByUsersInput>;
  fetchFriends?: InputMaybe<UserFriendCreateNestedManyWithoutOwnerInput>;
  followProfitables?: InputMaybe<ProfitableCreateNestedManyWithoutFollowedByInput>;
  followedBy?: InputMaybe<UserCreateNestedManyWithoutFollowingInput>;
  gender?: InputMaybe<Scalars['String']>;
  groupsJoin?: InputMaybe<GroupCreateNestedManyWithoutMembersInput>;
  groupsOwn?: InputMaybe<GroupCreateNestedManyWithoutOwnerInput>;
  headUri?: InputMaybe<Scalars['String']>;
  history?: InputMaybe<OrderDetailHistoryCreateNestedManyWithoutOperatorInput>;
  id?: InputMaybe<Scalars['String']>;
  idStr: Scalars['String'];
  inFriends?: InputMaybe<UserFriendCreateNestedManyWithoutUsersInput>;
  inValidReasonHistories?: InputMaybe<InValidReasonCreateNestedManyWithoutUserInput>;
  lastLogin?: InputMaybe<Scalars['DateTime']>;
  lastTokenUpdated?: InputMaybe<Scalars['DateTime']>;
  likePosts?: InputMaybe<PostCreateNestedManyWithoutLikeUsersInput>;
  manualAddrs?: InputMaybe<ManualAddrCreateNestedManyWithoutOwnerInput>;
  myCoupons?: InputMaybe<CouponEntityCreateNestedManyWithoutHolderInput>;
  name: Scalars['String'];
  notificationReceiver?: InputMaybe<NotificationCreateNestedManyWithoutReceiverInput>;
  notificationSender?: InputMaybe<NotificationCreateNestedManyWithoutSenderInput>;
  order?: InputMaybe<OrderCreateNestedManyWithoutSenderInput>;
  packagesAsReceiver?: InputMaybe<PostPackageCreateNestedManyWithoutReceiversInput>;
  password?: InputMaybe<Scalars['String']>;
  pcoins?: InputMaybe<Scalars['Decimal']>;
  phone?: InputMaybe<Scalars['String']>;
  postPackage?: InputMaybe<PostPackageCreateNestedManyWithoutSenderInput>;
  postsIn?: InputMaybe<PostCreateNestedManyWithoutReceiverInput>;
  postsOut?: InputMaybe<PostCreateNestedManyWithoutSenderInput>;
  profitable?: InputMaybe<ProfitableCreateNestedOneWithoutUserInput>;
  receive_email?: InputMaybe<Scalars['String']>;
  receive_phone?: InputMaybe<Scalars['String']>;
  reportByMe?: InputMaybe<ReportUserCreateNestedManyWithoutReporterInput>;
  reported?: InputMaybe<ReportUserCreateNestedManyWithoutUserInput>;
  reports?: InputMaybe<ReportCreateNestedManyWithoutReporterInput>;
  settingNotification?: InputMaybe<SettingNotificationCreateNestedOneWithoutUserInput>;
  settingPrivacy?: InputMaybe<SettingPrivacyCreateNestedOneWithoutUserInput>;
  signature?: InputMaybe<SignatureCreateNestedManyWithoutOwnerInput>;
  storePurchaseHistory?: InputMaybe<CartItemCreateNestedManyWithoutCustomerInput>;
  tagsUsed?: InputMaybe<PostTagCreateNestedManyWithoutUserInput>;
  thirdpartyId: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  vAddrMapping?: InputMaybe<VirtualAddrMappingCreateNestedOneWithoutOwnerInput>;
  valid?: InputMaybe<Scalars['Boolean']>;
  website?: InputMaybe<Scalars['String']>;
};

export type UserCreateWithoutFriendsInput = {
  accessToken?: InputMaybe<Scalars['String']>;
  address?: InputMaybe<AddressCreateNestedManyWithoutOwnerInput>;
  attachments?: InputMaybe<AttachmentCreateNestedManyWithoutOwnerInput>;
  authType: UserAuthType;
  bio?: InputMaybe<Scalars['String']>;
  campaignNanhui?: InputMaybe<CampaignNanhuiCreateNestedManyWithoutSubmitterInput>;
  campainZeczec?: InputMaybe<CampainZeczecCreateNestedOneWithoutRegisterUserInput>;
  card?: InputMaybe<CardCreateNestedManyWithoutCreatorInput>;
  coins?: InputMaybe<CoinCreateNestedManyWithoutUserInput>;
  collection?: InputMaybe<CollectionCreateNestedManyWithoutOwnerInput>;
  country?: InputMaybe<Scalars['String']>;
  couponUsages?: InputMaybe<CouponUsageCreateNestedManyWithoutUserInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdStamps?: InputMaybe<StampCreateNestedManyWithoutCreatorInput>;
  detail?: InputMaybe<OrderDetailCreateNestedManyWithoutReceiverInput>;
  deviceToken?: InputMaybe<DeviceTokenCreateNestedManyWithoutUserInput>;
  email?: InputMaybe<Scalars['String']>;
  emailTos?: InputMaybe<EmailToCreateNestedManyWithoutOwnerInput>;
  favoriteStoreItems?: InputMaybe<StoreItemCreateNestedManyWithoutFavoriteByUsersInput>;
  followProfitables?: InputMaybe<ProfitableCreateNestedManyWithoutFollowedByInput>;
  followedBy?: InputMaybe<UserCreateNestedManyWithoutFollowingInput>;
  following?: InputMaybe<UserCreateNestedManyWithoutFollowedByInput>;
  gender?: InputMaybe<Scalars['String']>;
  groupsJoin?: InputMaybe<GroupCreateNestedManyWithoutMembersInput>;
  groupsOwn?: InputMaybe<GroupCreateNestedManyWithoutOwnerInput>;
  headUri?: InputMaybe<Scalars['String']>;
  history?: InputMaybe<OrderDetailHistoryCreateNestedManyWithoutOperatorInput>;
  id?: InputMaybe<Scalars['String']>;
  idStr: Scalars['String'];
  inFriends?: InputMaybe<UserFriendCreateNestedManyWithoutUsersInput>;
  inValidReasonHistories?: InputMaybe<InValidReasonCreateNestedManyWithoutUserInput>;
  lastLogin?: InputMaybe<Scalars['DateTime']>;
  lastTokenUpdated?: InputMaybe<Scalars['DateTime']>;
  likePosts?: InputMaybe<PostCreateNestedManyWithoutLikeUsersInput>;
  manualAddrs?: InputMaybe<ManualAddrCreateNestedManyWithoutOwnerInput>;
  myCoupons?: InputMaybe<CouponEntityCreateNestedManyWithoutHolderInput>;
  name: Scalars['String'];
  notificationReceiver?: InputMaybe<NotificationCreateNestedManyWithoutReceiverInput>;
  notificationSender?: InputMaybe<NotificationCreateNestedManyWithoutSenderInput>;
  order?: InputMaybe<OrderCreateNestedManyWithoutSenderInput>;
  packagesAsReceiver?: InputMaybe<PostPackageCreateNestedManyWithoutReceiversInput>;
  password?: InputMaybe<Scalars['String']>;
  pcoins?: InputMaybe<Scalars['Decimal']>;
  phone?: InputMaybe<Scalars['String']>;
  postPackage?: InputMaybe<PostPackageCreateNestedManyWithoutSenderInput>;
  postsIn?: InputMaybe<PostCreateNestedManyWithoutReceiverInput>;
  postsOut?: InputMaybe<PostCreateNestedManyWithoutSenderInput>;
  profitable?: InputMaybe<ProfitableCreateNestedOneWithoutUserInput>;
  receive_email?: InputMaybe<Scalars['String']>;
  receive_phone?: InputMaybe<Scalars['String']>;
  reportByMe?: InputMaybe<ReportUserCreateNestedManyWithoutReporterInput>;
  reported?: InputMaybe<ReportUserCreateNestedManyWithoutUserInput>;
  reports?: InputMaybe<ReportCreateNestedManyWithoutReporterInput>;
  settingNotification?: InputMaybe<SettingNotificationCreateNestedOneWithoutUserInput>;
  settingPrivacy?: InputMaybe<SettingPrivacyCreateNestedOneWithoutUserInput>;
  signature?: InputMaybe<SignatureCreateNestedManyWithoutOwnerInput>;
  storePurchaseHistory?: InputMaybe<CartItemCreateNestedManyWithoutCustomerInput>;
  tagsUsed?: InputMaybe<PostTagCreateNestedManyWithoutUserInput>;
  thirdpartyId: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  vAddrMapping?: InputMaybe<VirtualAddrMappingCreateNestedOneWithoutOwnerInput>;
  valid?: InputMaybe<Scalars['Boolean']>;
  website?: InputMaybe<Scalars['String']>;
};

export type UserCreateWithoutGroupsJoinInput = {
  accessToken?: InputMaybe<Scalars['String']>;
  address?: InputMaybe<AddressCreateNestedManyWithoutOwnerInput>;
  attachments?: InputMaybe<AttachmentCreateNestedManyWithoutOwnerInput>;
  authType: UserAuthType;
  bio?: InputMaybe<Scalars['String']>;
  campaignNanhui?: InputMaybe<CampaignNanhuiCreateNestedManyWithoutSubmitterInput>;
  campainZeczec?: InputMaybe<CampainZeczecCreateNestedOneWithoutRegisterUserInput>;
  card?: InputMaybe<CardCreateNestedManyWithoutCreatorInput>;
  coins?: InputMaybe<CoinCreateNestedManyWithoutUserInput>;
  collection?: InputMaybe<CollectionCreateNestedManyWithoutOwnerInput>;
  country?: InputMaybe<Scalars['String']>;
  couponUsages?: InputMaybe<CouponUsageCreateNestedManyWithoutUserInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdStamps?: InputMaybe<StampCreateNestedManyWithoutCreatorInput>;
  detail?: InputMaybe<OrderDetailCreateNestedManyWithoutReceiverInput>;
  deviceToken?: InputMaybe<DeviceTokenCreateNestedManyWithoutUserInput>;
  email?: InputMaybe<Scalars['String']>;
  emailTos?: InputMaybe<EmailToCreateNestedManyWithoutOwnerInput>;
  favoriteStoreItems?: InputMaybe<StoreItemCreateNestedManyWithoutFavoriteByUsersInput>;
  fetchFriends?: InputMaybe<UserFriendCreateNestedManyWithoutOwnerInput>;
  followProfitables?: InputMaybe<ProfitableCreateNestedManyWithoutFollowedByInput>;
  followedBy?: InputMaybe<UserCreateNestedManyWithoutFollowingInput>;
  following?: InputMaybe<UserCreateNestedManyWithoutFollowedByInput>;
  gender?: InputMaybe<Scalars['String']>;
  groupsOwn?: InputMaybe<GroupCreateNestedManyWithoutOwnerInput>;
  headUri?: InputMaybe<Scalars['String']>;
  history?: InputMaybe<OrderDetailHistoryCreateNestedManyWithoutOperatorInput>;
  id?: InputMaybe<Scalars['String']>;
  idStr: Scalars['String'];
  inFriends?: InputMaybe<UserFriendCreateNestedManyWithoutUsersInput>;
  inValidReasonHistories?: InputMaybe<InValidReasonCreateNestedManyWithoutUserInput>;
  lastLogin?: InputMaybe<Scalars['DateTime']>;
  lastTokenUpdated?: InputMaybe<Scalars['DateTime']>;
  likePosts?: InputMaybe<PostCreateNestedManyWithoutLikeUsersInput>;
  manualAddrs?: InputMaybe<ManualAddrCreateNestedManyWithoutOwnerInput>;
  myCoupons?: InputMaybe<CouponEntityCreateNestedManyWithoutHolderInput>;
  name: Scalars['String'];
  notificationReceiver?: InputMaybe<NotificationCreateNestedManyWithoutReceiverInput>;
  notificationSender?: InputMaybe<NotificationCreateNestedManyWithoutSenderInput>;
  order?: InputMaybe<OrderCreateNestedManyWithoutSenderInput>;
  packagesAsReceiver?: InputMaybe<PostPackageCreateNestedManyWithoutReceiversInput>;
  password?: InputMaybe<Scalars['String']>;
  pcoins?: InputMaybe<Scalars['Decimal']>;
  phone?: InputMaybe<Scalars['String']>;
  postPackage?: InputMaybe<PostPackageCreateNestedManyWithoutSenderInput>;
  postsIn?: InputMaybe<PostCreateNestedManyWithoutReceiverInput>;
  postsOut?: InputMaybe<PostCreateNestedManyWithoutSenderInput>;
  profitable?: InputMaybe<ProfitableCreateNestedOneWithoutUserInput>;
  receive_email?: InputMaybe<Scalars['String']>;
  receive_phone?: InputMaybe<Scalars['String']>;
  reportByMe?: InputMaybe<ReportUserCreateNestedManyWithoutReporterInput>;
  reported?: InputMaybe<ReportUserCreateNestedManyWithoutUserInput>;
  reports?: InputMaybe<ReportCreateNestedManyWithoutReporterInput>;
  settingNotification?: InputMaybe<SettingNotificationCreateNestedOneWithoutUserInput>;
  settingPrivacy?: InputMaybe<SettingPrivacyCreateNestedOneWithoutUserInput>;
  signature?: InputMaybe<SignatureCreateNestedManyWithoutOwnerInput>;
  storePurchaseHistory?: InputMaybe<CartItemCreateNestedManyWithoutCustomerInput>;
  tagsUsed?: InputMaybe<PostTagCreateNestedManyWithoutUserInput>;
  thirdpartyId: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  vAddrMapping?: InputMaybe<VirtualAddrMappingCreateNestedOneWithoutOwnerInput>;
  valid?: InputMaybe<Scalars['Boolean']>;
  website?: InputMaybe<Scalars['String']>;
};

export type UserCreateWithoutGroupsOwnInput = {
  accessToken?: InputMaybe<Scalars['String']>;
  address?: InputMaybe<AddressCreateNestedManyWithoutOwnerInput>;
  attachments?: InputMaybe<AttachmentCreateNestedManyWithoutOwnerInput>;
  authType: UserAuthType;
  bio?: InputMaybe<Scalars['String']>;
  campaignNanhui?: InputMaybe<CampaignNanhuiCreateNestedManyWithoutSubmitterInput>;
  campainZeczec?: InputMaybe<CampainZeczecCreateNestedOneWithoutRegisterUserInput>;
  card?: InputMaybe<CardCreateNestedManyWithoutCreatorInput>;
  coins?: InputMaybe<CoinCreateNestedManyWithoutUserInput>;
  collection?: InputMaybe<CollectionCreateNestedManyWithoutOwnerInput>;
  country?: InputMaybe<Scalars['String']>;
  couponUsages?: InputMaybe<CouponUsageCreateNestedManyWithoutUserInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdStamps?: InputMaybe<StampCreateNestedManyWithoutCreatorInput>;
  detail?: InputMaybe<OrderDetailCreateNestedManyWithoutReceiverInput>;
  deviceToken?: InputMaybe<DeviceTokenCreateNestedManyWithoutUserInput>;
  email?: InputMaybe<Scalars['String']>;
  emailTos?: InputMaybe<EmailToCreateNestedManyWithoutOwnerInput>;
  favoriteStoreItems?: InputMaybe<StoreItemCreateNestedManyWithoutFavoriteByUsersInput>;
  fetchFriends?: InputMaybe<UserFriendCreateNestedManyWithoutOwnerInput>;
  followProfitables?: InputMaybe<ProfitableCreateNestedManyWithoutFollowedByInput>;
  followedBy?: InputMaybe<UserCreateNestedManyWithoutFollowingInput>;
  following?: InputMaybe<UserCreateNestedManyWithoutFollowedByInput>;
  gender?: InputMaybe<Scalars['String']>;
  groupsJoin?: InputMaybe<GroupCreateNestedManyWithoutMembersInput>;
  headUri?: InputMaybe<Scalars['String']>;
  history?: InputMaybe<OrderDetailHistoryCreateNestedManyWithoutOperatorInput>;
  id?: InputMaybe<Scalars['String']>;
  idStr: Scalars['String'];
  inFriends?: InputMaybe<UserFriendCreateNestedManyWithoutUsersInput>;
  inValidReasonHistories?: InputMaybe<InValidReasonCreateNestedManyWithoutUserInput>;
  lastLogin?: InputMaybe<Scalars['DateTime']>;
  lastTokenUpdated?: InputMaybe<Scalars['DateTime']>;
  likePosts?: InputMaybe<PostCreateNestedManyWithoutLikeUsersInput>;
  manualAddrs?: InputMaybe<ManualAddrCreateNestedManyWithoutOwnerInput>;
  myCoupons?: InputMaybe<CouponEntityCreateNestedManyWithoutHolderInput>;
  name: Scalars['String'];
  notificationReceiver?: InputMaybe<NotificationCreateNestedManyWithoutReceiverInput>;
  notificationSender?: InputMaybe<NotificationCreateNestedManyWithoutSenderInput>;
  order?: InputMaybe<OrderCreateNestedManyWithoutSenderInput>;
  packagesAsReceiver?: InputMaybe<PostPackageCreateNestedManyWithoutReceiversInput>;
  password?: InputMaybe<Scalars['String']>;
  pcoins?: InputMaybe<Scalars['Decimal']>;
  phone?: InputMaybe<Scalars['String']>;
  postPackage?: InputMaybe<PostPackageCreateNestedManyWithoutSenderInput>;
  postsIn?: InputMaybe<PostCreateNestedManyWithoutReceiverInput>;
  postsOut?: InputMaybe<PostCreateNestedManyWithoutSenderInput>;
  profitable?: InputMaybe<ProfitableCreateNestedOneWithoutUserInput>;
  receive_email?: InputMaybe<Scalars['String']>;
  receive_phone?: InputMaybe<Scalars['String']>;
  reportByMe?: InputMaybe<ReportUserCreateNestedManyWithoutReporterInput>;
  reported?: InputMaybe<ReportUserCreateNestedManyWithoutUserInput>;
  reports?: InputMaybe<ReportCreateNestedManyWithoutReporterInput>;
  settingNotification?: InputMaybe<SettingNotificationCreateNestedOneWithoutUserInput>;
  settingPrivacy?: InputMaybe<SettingPrivacyCreateNestedOneWithoutUserInput>;
  signature?: InputMaybe<SignatureCreateNestedManyWithoutOwnerInput>;
  storePurchaseHistory?: InputMaybe<CartItemCreateNestedManyWithoutCustomerInput>;
  tagsUsed?: InputMaybe<PostTagCreateNestedManyWithoutUserInput>;
  thirdpartyId: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  vAddrMapping?: InputMaybe<VirtualAddrMappingCreateNestedOneWithoutOwnerInput>;
  valid?: InputMaybe<Scalars['Boolean']>;
  website?: InputMaybe<Scalars['String']>;
};

export type UserCreateWithoutHistoryInput = {
  accessToken?: InputMaybe<Scalars['String']>;
  address?: InputMaybe<AddressCreateNestedManyWithoutOwnerInput>;
  attachments?: InputMaybe<AttachmentCreateNestedManyWithoutOwnerInput>;
  authType: UserAuthType;
  bio?: InputMaybe<Scalars['String']>;
  campaignNanhui?: InputMaybe<CampaignNanhuiCreateNestedManyWithoutSubmitterInput>;
  campainZeczec?: InputMaybe<CampainZeczecCreateNestedOneWithoutRegisterUserInput>;
  card?: InputMaybe<CardCreateNestedManyWithoutCreatorInput>;
  coins?: InputMaybe<CoinCreateNestedManyWithoutUserInput>;
  collection?: InputMaybe<CollectionCreateNestedManyWithoutOwnerInput>;
  country?: InputMaybe<Scalars['String']>;
  couponUsages?: InputMaybe<CouponUsageCreateNestedManyWithoutUserInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdStamps?: InputMaybe<StampCreateNestedManyWithoutCreatorInput>;
  detail?: InputMaybe<OrderDetailCreateNestedManyWithoutReceiverInput>;
  deviceToken?: InputMaybe<DeviceTokenCreateNestedManyWithoutUserInput>;
  email?: InputMaybe<Scalars['String']>;
  emailTos?: InputMaybe<EmailToCreateNestedManyWithoutOwnerInput>;
  favoriteStoreItems?: InputMaybe<StoreItemCreateNestedManyWithoutFavoriteByUsersInput>;
  fetchFriends?: InputMaybe<UserFriendCreateNestedManyWithoutOwnerInput>;
  followProfitables?: InputMaybe<ProfitableCreateNestedManyWithoutFollowedByInput>;
  followedBy?: InputMaybe<UserCreateNestedManyWithoutFollowingInput>;
  following?: InputMaybe<UserCreateNestedManyWithoutFollowedByInput>;
  gender?: InputMaybe<Scalars['String']>;
  groupsJoin?: InputMaybe<GroupCreateNestedManyWithoutMembersInput>;
  groupsOwn?: InputMaybe<GroupCreateNestedManyWithoutOwnerInput>;
  headUri?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  idStr: Scalars['String'];
  inFriends?: InputMaybe<UserFriendCreateNestedManyWithoutUsersInput>;
  inValidReasonHistories?: InputMaybe<InValidReasonCreateNestedManyWithoutUserInput>;
  lastLogin?: InputMaybe<Scalars['DateTime']>;
  lastTokenUpdated?: InputMaybe<Scalars['DateTime']>;
  likePosts?: InputMaybe<PostCreateNestedManyWithoutLikeUsersInput>;
  manualAddrs?: InputMaybe<ManualAddrCreateNestedManyWithoutOwnerInput>;
  myCoupons?: InputMaybe<CouponEntityCreateNestedManyWithoutHolderInput>;
  name: Scalars['String'];
  notificationReceiver?: InputMaybe<NotificationCreateNestedManyWithoutReceiverInput>;
  notificationSender?: InputMaybe<NotificationCreateNestedManyWithoutSenderInput>;
  order?: InputMaybe<OrderCreateNestedManyWithoutSenderInput>;
  packagesAsReceiver?: InputMaybe<PostPackageCreateNestedManyWithoutReceiversInput>;
  password?: InputMaybe<Scalars['String']>;
  pcoins?: InputMaybe<Scalars['Decimal']>;
  phone?: InputMaybe<Scalars['String']>;
  postPackage?: InputMaybe<PostPackageCreateNestedManyWithoutSenderInput>;
  postsIn?: InputMaybe<PostCreateNestedManyWithoutReceiverInput>;
  postsOut?: InputMaybe<PostCreateNestedManyWithoutSenderInput>;
  profitable?: InputMaybe<ProfitableCreateNestedOneWithoutUserInput>;
  receive_email?: InputMaybe<Scalars['String']>;
  receive_phone?: InputMaybe<Scalars['String']>;
  reportByMe?: InputMaybe<ReportUserCreateNestedManyWithoutReporterInput>;
  reported?: InputMaybe<ReportUserCreateNestedManyWithoutUserInput>;
  reports?: InputMaybe<ReportCreateNestedManyWithoutReporterInput>;
  settingNotification?: InputMaybe<SettingNotificationCreateNestedOneWithoutUserInput>;
  settingPrivacy?: InputMaybe<SettingPrivacyCreateNestedOneWithoutUserInput>;
  signature?: InputMaybe<SignatureCreateNestedManyWithoutOwnerInput>;
  storePurchaseHistory?: InputMaybe<CartItemCreateNestedManyWithoutCustomerInput>;
  tagsUsed?: InputMaybe<PostTagCreateNestedManyWithoutUserInput>;
  thirdpartyId: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  vAddrMapping?: InputMaybe<VirtualAddrMappingCreateNestedOneWithoutOwnerInput>;
  valid?: InputMaybe<Scalars['Boolean']>;
  website?: InputMaybe<Scalars['String']>;
};

export type UserCreateWithoutInFriendsInput = {
  accessToken?: InputMaybe<Scalars['String']>;
  address?: InputMaybe<AddressCreateNestedManyWithoutOwnerInput>;
  attachments?: InputMaybe<AttachmentCreateNestedManyWithoutOwnerInput>;
  authType: UserAuthType;
  bio?: InputMaybe<Scalars['String']>;
  campaignNanhui?: InputMaybe<CampaignNanhuiCreateNestedManyWithoutSubmitterInput>;
  campainZeczec?: InputMaybe<CampainZeczecCreateNestedOneWithoutRegisterUserInput>;
  card?: InputMaybe<CardCreateNestedManyWithoutCreatorInput>;
  coins?: InputMaybe<CoinCreateNestedManyWithoutUserInput>;
  collection?: InputMaybe<CollectionCreateNestedManyWithoutOwnerInput>;
  country?: InputMaybe<Scalars['String']>;
  couponUsages?: InputMaybe<CouponUsageCreateNestedManyWithoutUserInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdStamps?: InputMaybe<StampCreateNestedManyWithoutCreatorInput>;
  detail?: InputMaybe<OrderDetailCreateNestedManyWithoutReceiverInput>;
  deviceToken?: InputMaybe<DeviceTokenCreateNestedManyWithoutUserInput>;
  email?: InputMaybe<Scalars['String']>;
  emailTos?: InputMaybe<EmailToCreateNestedManyWithoutOwnerInput>;
  favoriteStoreItems?: InputMaybe<StoreItemCreateNestedManyWithoutFavoriteByUsersInput>;
  fetchFriends?: InputMaybe<UserFriendCreateNestedManyWithoutOwnerInput>;
  followProfitables?: InputMaybe<ProfitableCreateNestedManyWithoutFollowedByInput>;
  followedBy?: InputMaybe<UserCreateNestedManyWithoutFollowingInput>;
  following?: InputMaybe<UserCreateNestedManyWithoutFollowedByInput>;
  gender?: InputMaybe<Scalars['String']>;
  groupsJoin?: InputMaybe<GroupCreateNestedManyWithoutMembersInput>;
  groupsOwn?: InputMaybe<GroupCreateNestedManyWithoutOwnerInput>;
  headUri?: InputMaybe<Scalars['String']>;
  history?: InputMaybe<OrderDetailHistoryCreateNestedManyWithoutOperatorInput>;
  id?: InputMaybe<Scalars['String']>;
  idStr: Scalars['String'];
  inValidReasonHistories?: InputMaybe<InValidReasonCreateNestedManyWithoutUserInput>;
  lastLogin?: InputMaybe<Scalars['DateTime']>;
  lastTokenUpdated?: InputMaybe<Scalars['DateTime']>;
  likePosts?: InputMaybe<PostCreateNestedManyWithoutLikeUsersInput>;
  manualAddrs?: InputMaybe<ManualAddrCreateNestedManyWithoutOwnerInput>;
  myCoupons?: InputMaybe<CouponEntityCreateNestedManyWithoutHolderInput>;
  name: Scalars['String'];
  notificationReceiver?: InputMaybe<NotificationCreateNestedManyWithoutReceiverInput>;
  notificationSender?: InputMaybe<NotificationCreateNestedManyWithoutSenderInput>;
  order?: InputMaybe<OrderCreateNestedManyWithoutSenderInput>;
  packagesAsReceiver?: InputMaybe<PostPackageCreateNestedManyWithoutReceiversInput>;
  password?: InputMaybe<Scalars['String']>;
  pcoins?: InputMaybe<Scalars['Decimal']>;
  phone?: InputMaybe<Scalars['String']>;
  postPackage?: InputMaybe<PostPackageCreateNestedManyWithoutSenderInput>;
  postsIn?: InputMaybe<PostCreateNestedManyWithoutReceiverInput>;
  postsOut?: InputMaybe<PostCreateNestedManyWithoutSenderInput>;
  profitable?: InputMaybe<ProfitableCreateNestedOneWithoutUserInput>;
  receive_email?: InputMaybe<Scalars['String']>;
  receive_phone?: InputMaybe<Scalars['String']>;
  reportByMe?: InputMaybe<ReportUserCreateNestedManyWithoutReporterInput>;
  reported?: InputMaybe<ReportUserCreateNestedManyWithoutUserInput>;
  reports?: InputMaybe<ReportCreateNestedManyWithoutReporterInput>;
  settingNotification?: InputMaybe<SettingNotificationCreateNestedOneWithoutUserInput>;
  settingPrivacy?: InputMaybe<SettingPrivacyCreateNestedOneWithoutUserInput>;
  signature?: InputMaybe<SignatureCreateNestedManyWithoutOwnerInput>;
  storePurchaseHistory?: InputMaybe<CartItemCreateNestedManyWithoutCustomerInput>;
  tagsUsed?: InputMaybe<PostTagCreateNestedManyWithoutUserInput>;
  thirdpartyId: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  vAddrMapping?: InputMaybe<VirtualAddrMappingCreateNestedOneWithoutOwnerInput>;
  valid?: InputMaybe<Scalars['Boolean']>;
  website?: InputMaybe<Scalars['String']>;
};

export type UserCreateWithoutInValidReasonHistoriesInput = {
  accessToken?: InputMaybe<Scalars['String']>;
  address?: InputMaybe<AddressCreateNestedManyWithoutOwnerInput>;
  attachments?: InputMaybe<AttachmentCreateNestedManyWithoutOwnerInput>;
  authType: UserAuthType;
  bio?: InputMaybe<Scalars['String']>;
  campaignNanhui?: InputMaybe<CampaignNanhuiCreateNestedManyWithoutSubmitterInput>;
  campainZeczec?: InputMaybe<CampainZeczecCreateNestedOneWithoutRegisterUserInput>;
  card?: InputMaybe<CardCreateNestedManyWithoutCreatorInput>;
  coins?: InputMaybe<CoinCreateNestedManyWithoutUserInput>;
  collection?: InputMaybe<CollectionCreateNestedManyWithoutOwnerInput>;
  country?: InputMaybe<Scalars['String']>;
  couponUsages?: InputMaybe<CouponUsageCreateNestedManyWithoutUserInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdStamps?: InputMaybe<StampCreateNestedManyWithoutCreatorInput>;
  detail?: InputMaybe<OrderDetailCreateNestedManyWithoutReceiverInput>;
  deviceToken?: InputMaybe<DeviceTokenCreateNestedManyWithoutUserInput>;
  email?: InputMaybe<Scalars['String']>;
  emailTos?: InputMaybe<EmailToCreateNestedManyWithoutOwnerInput>;
  favoriteStoreItems?: InputMaybe<StoreItemCreateNestedManyWithoutFavoriteByUsersInput>;
  fetchFriends?: InputMaybe<UserFriendCreateNestedManyWithoutOwnerInput>;
  followProfitables?: InputMaybe<ProfitableCreateNestedManyWithoutFollowedByInput>;
  followedBy?: InputMaybe<UserCreateNestedManyWithoutFollowingInput>;
  following?: InputMaybe<UserCreateNestedManyWithoutFollowedByInput>;
  gender?: InputMaybe<Scalars['String']>;
  groupsJoin?: InputMaybe<GroupCreateNestedManyWithoutMembersInput>;
  groupsOwn?: InputMaybe<GroupCreateNestedManyWithoutOwnerInput>;
  headUri?: InputMaybe<Scalars['String']>;
  history?: InputMaybe<OrderDetailHistoryCreateNestedManyWithoutOperatorInput>;
  id?: InputMaybe<Scalars['String']>;
  idStr: Scalars['String'];
  inFriends?: InputMaybe<UserFriendCreateNestedManyWithoutUsersInput>;
  lastLogin?: InputMaybe<Scalars['DateTime']>;
  lastTokenUpdated?: InputMaybe<Scalars['DateTime']>;
  likePosts?: InputMaybe<PostCreateNestedManyWithoutLikeUsersInput>;
  manualAddrs?: InputMaybe<ManualAddrCreateNestedManyWithoutOwnerInput>;
  myCoupons?: InputMaybe<CouponEntityCreateNestedManyWithoutHolderInput>;
  name: Scalars['String'];
  notificationReceiver?: InputMaybe<NotificationCreateNestedManyWithoutReceiverInput>;
  notificationSender?: InputMaybe<NotificationCreateNestedManyWithoutSenderInput>;
  order?: InputMaybe<OrderCreateNestedManyWithoutSenderInput>;
  packagesAsReceiver?: InputMaybe<PostPackageCreateNestedManyWithoutReceiversInput>;
  password?: InputMaybe<Scalars['String']>;
  pcoins?: InputMaybe<Scalars['Decimal']>;
  phone?: InputMaybe<Scalars['String']>;
  postPackage?: InputMaybe<PostPackageCreateNestedManyWithoutSenderInput>;
  postsIn?: InputMaybe<PostCreateNestedManyWithoutReceiverInput>;
  postsOut?: InputMaybe<PostCreateNestedManyWithoutSenderInput>;
  profitable?: InputMaybe<ProfitableCreateNestedOneWithoutUserInput>;
  receive_email?: InputMaybe<Scalars['String']>;
  receive_phone?: InputMaybe<Scalars['String']>;
  reportByMe?: InputMaybe<ReportUserCreateNestedManyWithoutReporterInput>;
  reported?: InputMaybe<ReportUserCreateNestedManyWithoutUserInput>;
  reports?: InputMaybe<ReportCreateNestedManyWithoutReporterInput>;
  settingNotification?: InputMaybe<SettingNotificationCreateNestedOneWithoutUserInput>;
  settingPrivacy?: InputMaybe<SettingPrivacyCreateNestedOneWithoutUserInput>;
  signature?: InputMaybe<SignatureCreateNestedManyWithoutOwnerInput>;
  storePurchaseHistory?: InputMaybe<CartItemCreateNestedManyWithoutCustomerInput>;
  tagsUsed?: InputMaybe<PostTagCreateNestedManyWithoutUserInput>;
  thirdpartyId: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  vAddrMapping?: InputMaybe<VirtualAddrMappingCreateNestedOneWithoutOwnerInput>;
  valid?: InputMaybe<Scalars['Boolean']>;
  website?: InputMaybe<Scalars['String']>;
};

export type UserCreateWithoutLikePostsInput = {
  accessToken?: InputMaybe<Scalars['String']>;
  address?: InputMaybe<AddressCreateNestedManyWithoutOwnerInput>;
  attachments?: InputMaybe<AttachmentCreateNestedManyWithoutOwnerInput>;
  authType: UserAuthType;
  bio?: InputMaybe<Scalars['String']>;
  campaignNanhui?: InputMaybe<CampaignNanhuiCreateNestedManyWithoutSubmitterInput>;
  campainZeczec?: InputMaybe<CampainZeczecCreateNestedOneWithoutRegisterUserInput>;
  card?: InputMaybe<CardCreateNestedManyWithoutCreatorInput>;
  coins?: InputMaybe<CoinCreateNestedManyWithoutUserInput>;
  collection?: InputMaybe<CollectionCreateNestedManyWithoutOwnerInput>;
  country?: InputMaybe<Scalars['String']>;
  couponUsages?: InputMaybe<CouponUsageCreateNestedManyWithoutUserInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdStamps?: InputMaybe<StampCreateNestedManyWithoutCreatorInput>;
  detail?: InputMaybe<OrderDetailCreateNestedManyWithoutReceiverInput>;
  deviceToken?: InputMaybe<DeviceTokenCreateNestedManyWithoutUserInput>;
  email?: InputMaybe<Scalars['String']>;
  emailTos?: InputMaybe<EmailToCreateNestedManyWithoutOwnerInput>;
  favoriteStoreItems?: InputMaybe<StoreItemCreateNestedManyWithoutFavoriteByUsersInput>;
  fetchFriends?: InputMaybe<UserFriendCreateNestedManyWithoutOwnerInput>;
  followProfitables?: InputMaybe<ProfitableCreateNestedManyWithoutFollowedByInput>;
  followedBy?: InputMaybe<UserCreateNestedManyWithoutFollowingInput>;
  following?: InputMaybe<UserCreateNestedManyWithoutFollowedByInput>;
  gender?: InputMaybe<Scalars['String']>;
  groupsJoin?: InputMaybe<GroupCreateNestedManyWithoutMembersInput>;
  groupsOwn?: InputMaybe<GroupCreateNestedManyWithoutOwnerInput>;
  headUri?: InputMaybe<Scalars['String']>;
  history?: InputMaybe<OrderDetailHistoryCreateNestedManyWithoutOperatorInput>;
  id?: InputMaybe<Scalars['String']>;
  idStr: Scalars['String'];
  inFriends?: InputMaybe<UserFriendCreateNestedManyWithoutUsersInput>;
  inValidReasonHistories?: InputMaybe<InValidReasonCreateNestedManyWithoutUserInput>;
  lastLogin?: InputMaybe<Scalars['DateTime']>;
  lastTokenUpdated?: InputMaybe<Scalars['DateTime']>;
  manualAddrs?: InputMaybe<ManualAddrCreateNestedManyWithoutOwnerInput>;
  myCoupons?: InputMaybe<CouponEntityCreateNestedManyWithoutHolderInput>;
  name: Scalars['String'];
  notificationReceiver?: InputMaybe<NotificationCreateNestedManyWithoutReceiverInput>;
  notificationSender?: InputMaybe<NotificationCreateNestedManyWithoutSenderInput>;
  order?: InputMaybe<OrderCreateNestedManyWithoutSenderInput>;
  packagesAsReceiver?: InputMaybe<PostPackageCreateNestedManyWithoutReceiversInput>;
  password?: InputMaybe<Scalars['String']>;
  pcoins?: InputMaybe<Scalars['Decimal']>;
  phone?: InputMaybe<Scalars['String']>;
  postPackage?: InputMaybe<PostPackageCreateNestedManyWithoutSenderInput>;
  postsIn?: InputMaybe<PostCreateNestedManyWithoutReceiverInput>;
  postsOut?: InputMaybe<PostCreateNestedManyWithoutSenderInput>;
  profitable?: InputMaybe<ProfitableCreateNestedOneWithoutUserInput>;
  receive_email?: InputMaybe<Scalars['String']>;
  receive_phone?: InputMaybe<Scalars['String']>;
  reportByMe?: InputMaybe<ReportUserCreateNestedManyWithoutReporterInput>;
  reported?: InputMaybe<ReportUserCreateNestedManyWithoutUserInput>;
  reports?: InputMaybe<ReportCreateNestedManyWithoutReporterInput>;
  settingNotification?: InputMaybe<SettingNotificationCreateNestedOneWithoutUserInput>;
  settingPrivacy?: InputMaybe<SettingPrivacyCreateNestedOneWithoutUserInput>;
  signature?: InputMaybe<SignatureCreateNestedManyWithoutOwnerInput>;
  storePurchaseHistory?: InputMaybe<CartItemCreateNestedManyWithoutCustomerInput>;
  tagsUsed?: InputMaybe<PostTagCreateNestedManyWithoutUserInput>;
  thirdpartyId: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  vAddrMapping?: InputMaybe<VirtualAddrMappingCreateNestedOneWithoutOwnerInput>;
  valid?: InputMaybe<Scalars['Boolean']>;
  website?: InputMaybe<Scalars['String']>;
};

export type UserCreateWithoutManualAddrsInput = {
  accessToken?: InputMaybe<Scalars['String']>;
  address?: InputMaybe<AddressCreateNestedManyWithoutOwnerInput>;
  attachments?: InputMaybe<AttachmentCreateNestedManyWithoutOwnerInput>;
  authType: UserAuthType;
  bio?: InputMaybe<Scalars['String']>;
  campaignNanhui?: InputMaybe<CampaignNanhuiCreateNestedManyWithoutSubmitterInput>;
  campainZeczec?: InputMaybe<CampainZeczecCreateNestedOneWithoutRegisterUserInput>;
  card?: InputMaybe<CardCreateNestedManyWithoutCreatorInput>;
  coins?: InputMaybe<CoinCreateNestedManyWithoutUserInput>;
  collection?: InputMaybe<CollectionCreateNestedManyWithoutOwnerInput>;
  country?: InputMaybe<Scalars['String']>;
  couponUsages?: InputMaybe<CouponUsageCreateNestedManyWithoutUserInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdStamps?: InputMaybe<StampCreateNestedManyWithoutCreatorInput>;
  detail?: InputMaybe<OrderDetailCreateNestedManyWithoutReceiverInput>;
  deviceToken?: InputMaybe<DeviceTokenCreateNestedManyWithoutUserInput>;
  email?: InputMaybe<Scalars['String']>;
  emailTos?: InputMaybe<EmailToCreateNestedManyWithoutOwnerInput>;
  favoriteStoreItems?: InputMaybe<StoreItemCreateNestedManyWithoutFavoriteByUsersInput>;
  fetchFriends?: InputMaybe<UserFriendCreateNestedManyWithoutOwnerInput>;
  followProfitables?: InputMaybe<ProfitableCreateNestedManyWithoutFollowedByInput>;
  followedBy?: InputMaybe<UserCreateNestedManyWithoutFollowingInput>;
  following?: InputMaybe<UserCreateNestedManyWithoutFollowedByInput>;
  gender?: InputMaybe<Scalars['String']>;
  groupsJoin?: InputMaybe<GroupCreateNestedManyWithoutMembersInput>;
  groupsOwn?: InputMaybe<GroupCreateNestedManyWithoutOwnerInput>;
  headUri?: InputMaybe<Scalars['String']>;
  history?: InputMaybe<OrderDetailHistoryCreateNestedManyWithoutOperatorInput>;
  id?: InputMaybe<Scalars['String']>;
  idStr: Scalars['String'];
  inFriends?: InputMaybe<UserFriendCreateNestedManyWithoutUsersInput>;
  inValidReasonHistories?: InputMaybe<InValidReasonCreateNestedManyWithoutUserInput>;
  lastLogin?: InputMaybe<Scalars['DateTime']>;
  lastTokenUpdated?: InputMaybe<Scalars['DateTime']>;
  likePosts?: InputMaybe<PostCreateNestedManyWithoutLikeUsersInput>;
  myCoupons?: InputMaybe<CouponEntityCreateNestedManyWithoutHolderInput>;
  name: Scalars['String'];
  notificationReceiver?: InputMaybe<NotificationCreateNestedManyWithoutReceiverInput>;
  notificationSender?: InputMaybe<NotificationCreateNestedManyWithoutSenderInput>;
  order?: InputMaybe<OrderCreateNestedManyWithoutSenderInput>;
  packagesAsReceiver?: InputMaybe<PostPackageCreateNestedManyWithoutReceiversInput>;
  password?: InputMaybe<Scalars['String']>;
  pcoins?: InputMaybe<Scalars['Decimal']>;
  phone?: InputMaybe<Scalars['String']>;
  postPackage?: InputMaybe<PostPackageCreateNestedManyWithoutSenderInput>;
  postsIn?: InputMaybe<PostCreateNestedManyWithoutReceiverInput>;
  postsOut?: InputMaybe<PostCreateNestedManyWithoutSenderInput>;
  profitable?: InputMaybe<ProfitableCreateNestedOneWithoutUserInput>;
  receive_email?: InputMaybe<Scalars['String']>;
  receive_phone?: InputMaybe<Scalars['String']>;
  reportByMe?: InputMaybe<ReportUserCreateNestedManyWithoutReporterInput>;
  reported?: InputMaybe<ReportUserCreateNestedManyWithoutUserInput>;
  reports?: InputMaybe<ReportCreateNestedManyWithoutReporterInput>;
  settingNotification?: InputMaybe<SettingNotificationCreateNestedOneWithoutUserInput>;
  settingPrivacy?: InputMaybe<SettingPrivacyCreateNestedOneWithoutUserInput>;
  signature?: InputMaybe<SignatureCreateNestedManyWithoutOwnerInput>;
  storePurchaseHistory?: InputMaybe<CartItemCreateNestedManyWithoutCustomerInput>;
  tagsUsed?: InputMaybe<PostTagCreateNestedManyWithoutUserInput>;
  thirdpartyId: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  vAddrMapping?: InputMaybe<VirtualAddrMappingCreateNestedOneWithoutOwnerInput>;
  valid?: InputMaybe<Scalars['Boolean']>;
  website?: InputMaybe<Scalars['String']>;
};

export type UserCreateWithoutMyCouponsInput = {
  accessToken?: InputMaybe<Scalars['String']>;
  address?: InputMaybe<AddressCreateNestedManyWithoutOwnerInput>;
  attachments?: InputMaybe<AttachmentCreateNestedManyWithoutOwnerInput>;
  authType: UserAuthType;
  bio?: InputMaybe<Scalars['String']>;
  campaignNanhui?: InputMaybe<CampaignNanhuiCreateNestedManyWithoutSubmitterInput>;
  campainZeczec?: InputMaybe<CampainZeczecCreateNestedOneWithoutRegisterUserInput>;
  card?: InputMaybe<CardCreateNestedManyWithoutCreatorInput>;
  coins?: InputMaybe<CoinCreateNestedManyWithoutUserInput>;
  collection?: InputMaybe<CollectionCreateNestedManyWithoutOwnerInput>;
  country?: InputMaybe<Scalars['String']>;
  couponUsages?: InputMaybe<CouponUsageCreateNestedManyWithoutUserInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdStamps?: InputMaybe<StampCreateNestedManyWithoutCreatorInput>;
  detail?: InputMaybe<OrderDetailCreateNestedManyWithoutReceiverInput>;
  deviceToken?: InputMaybe<DeviceTokenCreateNestedManyWithoutUserInput>;
  email?: InputMaybe<Scalars['String']>;
  emailTos?: InputMaybe<EmailToCreateNestedManyWithoutOwnerInput>;
  favoriteStoreItems?: InputMaybe<StoreItemCreateNestedManyWithoutFavoriteByUsersInput>;
  fetchFriends?: InputMaybe<UserFriendCreateNestedManyWithoutOwnerInput>;
  followProfitables?: InputMaybe<ProfitableCreateNestedManyWithoutFollowedByInput>;
  followedBy?: InputMaybe<UserCreateNestedManyWithoutFollowingInput>;
  following?: InputMaybe<UserCreateNestedManyWithoutFollowedByInput>;
  gender?: InputMaybe<Scalars['String']>;
  groupsJoin?: InputMaybe<GroupCreateNestedManyWithoutMembersInput>;
  groupsOwn?: InputMaybe<GroupCreateNestedManyWithoutOwnerInput>;
  headUri?: InputMaybe<Scalars['String']>;
  history?: InputMaybe<OrderDetailHistoryCreateNestedManyWithoutOperatorInput>;
  id?: InputMaybe<Scalars['String']>;
  idStr: Scalars['String'];
  inFriends?: InputMaybe<UserFriendCreateNestedManyWithoutUsersInput>;
  inValidReasonHistories?: InputMaybe<InValidReasonCreateNestedManyWithoutUserInput>;
  lastLogin?: InputMaybe<Scalars['DateTime']>;
  lastTokenUpdated?: InputMaybe<Scalars['DateTime']>;
  likePosts?: InputMaybe<PostCreateNestedManyWithoutLikeUsersInput>;
  manualAddrs?: InputMaybe<ManualAddrCreateNestedManyWithoutOwnerInput>;
  name: Scalars['String'];
  notificationReceiver?: InputMaybe<NotificationCreateNestedManyWithoutReceiverInput>;
  notificationSender?: InputMaybe<NotificationCreateNestedManyWithoutSenderInput>;
  order?: InputMaybe<OrderCreateNestedManyWithoutSenderInput>;
  packagesAsReceiver?: InputMaybe<PostPackageCreateNestedManyWithoutReceiversInput>;
  password?: InputMaybe<Scalars['String']>;
  pcoins?: InputMaybe<Scalars['Decimal']>;
  phone?: InputMaybe<Scalars['String']>;
  postPackage?: InputMaybe<PostPackageCreateNestedManyWithoutSenderInput>;
  postsIn?: InputMaybe<PostCreateNestedManyWithoutReceiverInput>;
  postsOut?: InputMaybe<PostCreateNestedManyWithoutSenderInput>;
  profitable?: InputMaybe<ProfitableCreateNestedOneWithoutUserInput>;
  receive_email?: InputMaybe<Scalars['String']>;
  receive_phone?: InputMaybe<Scalars['String']>;
  reportByMe?: InputMaybe<ReportUserCreateNestedManyWithoutReporterInput>;
  reported?: InputMaybe<ReportUserCreateNestedManyWithoutUserInput>;
  reports?: InputMaybe<ReportCreateNestedManyWithoutReporterInput>;
  settingNotification?: InputMaybe<SettingNotificationCreateNestedOneWithoutUserInput>;
  settingPrivacy?: InputMaybe<SettingPrivacyCreateNestedOneWithoutUserInput>;
  signature?: InputMaybe<SignatureCreateNestedManyWithoutOwnerInput>;
  storePurchaseHistory?: InputMaybe<CartItemCreateNestedManyWithoutCustomerInput>;
  tagsUsed?: InputMaybe<PostTagCreateNestedManyWithoutUserInput>;
  thirdpartyId: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  vAddrMapping?: InputMaybe<VirtualAddrMappingCreateNestedOneWithoutOwnerInput>;
  valid?: InputMaybe<Scalars['Boolean']>;
  website?: InputMaybe<Scalars['String']>;
};

export type UserCreateWithoutNotificationReceiverInput = {
  accessToken?: InputMaybe<Scalars['String']>;
  address?: InputMaybe<AddressCreateNestedManyWithoutOwnerInput>;
  attachments?: InputMaybe<AttachmentCreateNestedManyWithoutOwnerInput>;
  authType: UserAuthType;
  bio?: InputMaybe<Scalars['String']>;
  campaignNanhui?: InputMaybe<CampaignNanhuiCreateNestedManyWithoutSubmitterInput>;
  campainZeczec?: InputMaybe<CampainZeczecCreateNestedOneWithoutRegisterUserInput>;
  card?: InputMaybe<CardCreateNestedManyWithoutCreatorInput>;
  coins?: InputMaybe<CoinCreateNestedManyWithoutUserInput>;
  collection?: InputMaybe<CollectionCreateNestedManyWithoutOwnerInput>;
  country?: InputMaybe<Scalars['String']>;
  couponUsages?: InputMaybe<CouponUsageCreateNestedManyWithoutUserInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdStamps?: InputMaybe<StampCreateNestedManyWithoutCreatorInput>;
  detail?: InputMaybe<OrderDetailCreateNestedManyWithoutReceiverInput>;
  deviceToken?: InputMaybe<DeviceTokenCreateNestedManyWithoutUserInput>;
  email?: InputMaybe<Scalars['String']>;
  emailTos?: InputMaybe<EmailToCreateNestedManyWithoutOwnerInput>;
  favoriteStoreItems?: InputMaybe<StoreItemCreateNestedManyWithoutFavoriteByUsersInput>;
  fetchFriends?: InputMaybe<UserFriendCreateNestedManyWithoutOwnerInput>;
  followProfitables?: InputMaybe<ProfitableCreateNestedManyWithoutFollowedByInput>;
  followedBy?: InputMaybe<UserCreateNestedManyWithoutFollowingInput>;
  following?: InputMaybe<UserCreateNestedManyWithoutFollowedByInput>;
  gender?: InputMaybe<Scalars['String']>;
  groupsJoin?: InputMaybe<GroupCreateNestedManyWithoutMembersInput>;
  groupsOwn?: InputMaybe<GroupCreateNestedManyWithoutOwnerInput>;
  headUri?: InputMaybe<Scalars['String']>;
  history?: InputMaybe<OrderDetailHistoryCreateNestedManyWithoutOperatorInput>;
  id?: InputMaybe<Scalars['String']>;
  idStr: Scalars['String'];
  inFriends?: InputMaybe<UserFriendCreateNestedManyWithoutUsersInput>;
  inValidReasonHistories?: InputMaybe<InValidReasonCreateNestedManyWithoutUserInput>;
  lastLogin?: InputMaybe<Scalars['DateTime']>;
  lastTokenUpdated?: InputMaybe<Scalars['DateTime']>;
  likePosts?: InputMaybe<PostCreateNestedManyWithoutLikeUsersInput>;
  manualAddrs?: InputMaybe<ManualAddrCreateNestedManyWithoutOwnerInput>;
  myCoupons?: InputMaybe<CouponEntityCreateNestedManyWithoutHolderInput>;
  name: Scalars['String'];
  notificationSender?: InputMaybe<NotificationCreateNestedManyWithoutSenderInput>;
  order?: InputMaybe<OrderCreateNestedManyWithoutSenderInput>;
  packagesAsReceiver?: InputMaybe<PostPackageCreateNestedManyWithoutReceiversInput>;
  password?: InputMaybe<Scalars['String']>;
  pcoins?: InputMaybe<Scalars['Decimal']>;
  phone?: InputMaybe<Scalars['String']>;
  postPackage?: InputMaybe<PostPackageCreateNestedManyWithoutSenderInput>;
  postsIn?: InputMaybe<PostCreateNestedManyWithoutReceiverInput>;
  postsOut?: InputMaybe<PostCreateNestedManyWithoutSenderInput>;
  profitable?: InputMaybe<ProfitableCreateNestedOneWithoutUserInput>;
  receive_email?: InputMaybe<Scalars['String']>;
  receive_phone?: InputMaybe<Scalars['String']>;
  reportByMe?: InputMaybe<ReportUserCreateNestedManyWithoutReporterInput>;
  reported?: InputMaybe<ReportUserCreateNestedManyWithoutUserInput>;
  reports?: InputMaybe<ReportCreateNestedManyWithoutReporterInput>;
  settingNotification?: InputMaybe<SettingNotificationCreateNestedOneWithoutUserInput>;
  settingPrivacy?: InputMaybe<SettingPrivacyCreateNestedOneWithoutUserInput>;
  signature?: InputMaybe<SignatureCreateNestedManyWithoutOwnerInput>;
  storePurchaseHistory?: InputMaybe<CartItemCreateNestedManyWithoutCustomerInput>;
  tagsUsed?: InputMaybe<PostTagCreateNestedManyWithoutUserInput>;
  thirdpartyId: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  vAddrMapping?: InputMaybe<VirtualAddrMappingCreateNestedOneWithoutOwnerInput>;
  valid?: InputMaybe<Scalars['Boolean']>;
  website?: InputMaybe<Scalars['String']>;
};

export type UserCreateWithoutNotificationSenderInput = {
  accessToken?: InputMaybe<Scalars['String']>;
  address?: InputMaybe<AddressCreateNestedManyWithoutOwnerInput>;
  attachments?: InputMaybe<AttachmentCreateNestedManyWithoutOwnerInput>;
  authType: UserAuthType;
  bio?: InputMaybe<Scalars['String']>;
  campaignNanhui?: InputMaybe<CampaignNanhuiCreateNestedManyWithoutSubmitterInput>;
  campainZeczec?: InputMaybe<CampainZeczecCreateNestedOneWithoutRegisterUserInput>;
  card?: InputMaybe<CardCreateNestedManyWithoutCreatorInput>;
  coins?: InputMaybe<CoinCreateNestedManyWithoutUserInput>;
  collection?: InputMaybe<CollectionCreateNestedManyWithoutOwnerInput>;
  country?: InputMaybe<Scalars['String']>;
  couponUsages?: InputMaybe<CouponUsageCreateNestedManyWithoutUserInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdStamps?: InputMaybe<StampCreateNestedManyWithoutCreatorInput>;
  detail?: InputMaybe<OrderDetailCreateNestedManyWithoutReceiverInput>;
  deviceToken?: InputMaybe<DeviceTokenCreateNestedManyWithoutUserInput>;
  email?: InputMaybe<Scalars['String']>;
  emailTos?: InputMaybe<EmailToCreateNestedManyWithoutOwnerInput>;
  favoriteStoreItems?: InputMaybe<StoreItemCreateNestedManyWithoutFavoriteByUsersInput>;
  fetchFriends?: InputMaybe<UserFriendCreateNestedManyWithoutOwnerInput>;
  followProfitables?: InputMaybe<ProfitableCreateNestedManyWithoutFollowedByInput>;
  followedBy?: InputMaybe<UserCreateNestedManyWithoutFollowingInput>;
  following?: InputMaybe<UserCreateNestedManyWithoutFollowedByInput>;
  gender?: InputMaybe<Scalars['String']>;
  groupsJoin?: InputMaybe<GroupCreateNestedManyWithoutMembersInput>;
  groupsOwn?: InputMaybe<GroupCreateNestedManyWithoutOwnerInput>;
  headUri?: InputMaybe<Scalars['String']>;
  history?: InputMaybe<OrderDetailHistoryCreateNestedManyWithoutOperatorInput>;
  id?: InputMaybe<Scalars['String']>;
  idStr: Scalars['String'];
  inFriends?: InputMaybe<UserFriendCreateNestedManyWithoutUsersInput>;
  inValidReasonHistories?: InputMaybe<InValidReasonCreateNestedManyWithoutUserInput>;
  lastLogin?: InputMaybe<Scalars['DateTime']>;
  lastTokenUpdated?: InputMaybe<Scalars['DateTime']>;
  likePosts?: InputMaybe<PostCreateNestedManyWithoutLikeUsersInput>;
  manualAddrs?: InputMaybe<ManualAddrCreateNestedManyWithoutOwnerInput>;
  myCoupons?: InputMaybe<CouponEntityCreateNestedManyWithoutHolderInput>;
  name: Scalars['String'];
  notificationReceiver?: InputMaybe<NotificationCreateNestedManyWithoutReceiverInput>;
  order?: InputMaybe<OrderCreateNestedManyWithoutSenderInput>;
  packagesAsReceiver?: InputMaybe<PostPackageCreateNestedManyWithoutReceiversInput>;
  password?: InputMaybe<Scalars['String']>;
  pcoins?: InputMaybe<Scalars['Decimal']>;
  phone?: InputMaybe<Scalars['String']>;
  postPackage?: InputMaybe<PostPackageCreateNestedManyWithoutSenderInput>;
  postsIn?: InputMaybe<PostCreateNestedManyWithoutReceiverInput>;
  postsOut?: InputMaybe<PostCreateNestedManyWithoutSenderInput>;
  profitable?: InputMaybe<ProfitableCreateNestedOneWithoutUserInput>;
  receive_email?: InputMaybe<Scalars['String']>;
  receive_phone?: InputMaybe<Scalars['String']>;
  reportByMe?: InputMaybe<ReportUserCreateNestedManyWithoutReporterInput>;
  reported?: InputMaybe<ReportUserCreateNestedManyWithoutUserInput>;
  reports?: InputMaybe<ReportCreateNestedManyWithoutReporterInput>;
  settingNotification?: InputMaybe<SettingNotificationCreateNestedOneWithoutUserInput>;
  settingPrivacy?: InputMaybe<SettingPrivacyCreateNestedOneWithoutUserInput>;
  signature?: InputMaybe<SignatureCreateNestedManyWithoutOwnerInput>;
  storePurchaseHistory?: InputMaybe<CartItemCreateNestedManyWithoutCustomerInput>;
  tagsUsed?: InputMaybe<PostTagCreateNestedManyWithoutUserInput>;
  thirdpartyId: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  vAddrMapping?: InputMaybe<VirtualAddrMappingCreateNestedOneWithoutOwnerInput>;
  valid?: InputMaybe<Scalars['Boolean']>;
  website?: InputMaybe<Scalars['String']>;
};

export type UserCreateWithoutOrderInput = {
  accessToken?: InputMaybe<Scalars['String']>;
  address?: InputMaybe<AddressCreateNestedManyWithoutOwnerInput>;
  attachments?: InputMaybe<AttachmentCreateNestedManyWithoutOwnerInput>;
  authType: UserAuthType;
  bio?: InputMaybe<Scalars['String']>;
  campaignNanhui?: InputMaybe<CampaignNanhuiCreateNestedManyWithoutSubmitterInput>;
  campainZeczec?: InputMaybe<CampainZeczecCreateNestedOneWithoutRegisterUserInput>;
  card?: InputMaybe<CardCreateNestedManyWithoutCreatorInput>;
  coins?: InputMaybe<CoinCreateNestedManyWithoutUserInput>;
  collection?: InputMaybe<CollectionCreateNestedManyWithoutOwnerInput>;
  country?: InputMaybe<Scalars['String']>;
  couponUsages?: InputMaybe<CouponUsageCreateNestedManyWithoutUserInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdStamps?: InputMaybe<StampCreateNestedManyWithoutCreatorInput>;
  detail?: InputMaybe<OrderDetailCreateNestedManyWithoutReceiverInput>;
  deviceToken?: InputMaybe<DeviceTokenCreateNestedManyWithoutUserInput>;
  email?: InputMaybe<Scalars['String']>;
  emailTos?: InputMaybe<EmailToCreateNestedManyWithoutOwnerInput>;
  favoriteStoreItems?: InputMaybe<StoreItemCreateNestedManyWithoutFavoriteByUsersInput>;
  fetchFriends?: InputMaybe<UserFriendCreateNestedManyWithoutOwnerInput>;
  followProfitables?: InputMaybe<ProfitableCreateNestedManyWithoutFollowedByInput>;
  followedBy?: InputMaybe<UserCreateNestedManyWithoutFollowingInput>;
  following?: InputMaybe<UserCreateNestedManyWithoutFollowedByInput>;
  gender?: InputMaybe<Scalars['String']>;
  groupsJoin?: InputMaybe<GroupCreateNestedManyWithoutMembersInput>;
  groupsOwn?: InputMaybe<GroupCreateNestedManyWithoutOwnerInput>;
  headUri?: InputMaybe<Scalars['String']>;
  history?: InputMaybe<OrderDetailHistoryCreateNestedManyWithoutOperatorInput>;
  id?: InputMaybe<Scalars['String']>;
  idStr: Scalars['String'];
  inFriends?: InputMaybe<UserFriendCreateNestedManyWithoutUsersInput>;
  inValidReasonHistories?: InputMaybe<InValidReasonCreateNestedManyWithoutUserInput>;
  lastLogin?: InputMaybe<Scalars['DateTime']>;
  lastTokenUpdated?: InputMaybe<Scalars['DateTime']>;
  likePosts?: InputMaybe<PostCreateNestedManyWithoutLikeUsersInput>;
  manualAddrs?: InputMaybe<ManualAddrCreateNestedManyWithoutOwnerInput>;
  myCoupons?: InputMaybe<CouponEntityCreateNestedManyWithoutHolderInput>;
  name: Scalars['String'];
  notificationReceiver?: InputMaybe<NotificationCreateNestedManyWithoutReceiverInput>;
  notificationSender?: InputMaybe<NotificationCreateNestedManyWithoutSenderInput>;
  packagesAsReceiver?: InputMaybe<PostPackageCreateNestedManyWithoutReceiversInput>;
  password?: InputMaybe<Scalars['String']>;
  pcoins?: InputMaybe<Scalars['Decimal']>;
  phone?: InputMaybe<Scalars['String']>;
  postPackage?: InputMaybe<PostPackageCreateNestedManyWithoutSenderInput>;
  postsIn?: InputMaybe<PostCreateNestedManyWithoutReceiverInput>;
  postsOut?: InputMaybe<PostCreateNestedManyWithoutSenderInput>;
  profitable?: InputMaybe<ProfitableCreateNestedOneWithoutUserInput>;
  receive_email?: InputMaybe<Scalars['String']>;
  receive_phone?: InputMaybe<Scalars['String']>;
  reportByMe?: InputMaybe<ReportUserCreateNestedManyWithoutReporterInput>;
  reported?: InputMaybe<ReportUserCreateNestedManyWithoutUserInput>;
  reports?: InputMaybe<ReportCreateNestedManyWithoutReporterInput>;
  settingNotification?: InputMaybe<SettingNotificationCreateNestedOneWithoutUserInput>;
  settingPrivacy?: InputMaybe<SettingPrivacyCreateNestedOneWithoutUserInput>;
  signature?: InputMaybe<SignatureCreateNestedManyWithoutOwnerInput>;
  storePurchaseHistory?: InputMaybe<CartItemCreateNestedManyWithoutCustomerInput>;
  tagsUsed?: InputMaybe<PostTagCreateNestedManyWithoutUserInput>;
  thirdpartyId: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  vAddrMapping?: InputMaybe<VirtualAddrMappingCreateNestedOneWithoutOwnerInput>;
  valid?: InputMaybe<Scalars['Boolean']>;
  website?: InputMaybe<Scalars['String']>;
};

export type UserCreateWithoutPackagesAsReceiverInput = {
  accessToken?: InputMaybe<Scalars['String']>;
  address?: InputMaybe<AddressCreateNestedManyWithoutOwnerInput>;
  attachments?: InputMaybe<AttachmentCreateNestedManyWithoutOwnerInput>;
  authType: UserAuthType;
  bio?: InputMaybe<Scalars['String']>;
  campaignNanhui?: InputMaybe<CampaignNanhuiCreateNestedManyWithoutSubmitterInput>;
  campainZeczec?: InputMaybe<CampainZeczecCreateNestedOneWithoutRegisterUserInput>;
  card?: InputMaybe<CardCreateNestedManyWithoutCreatorInput>;
  coins?: InputMaybe<CoinCreateNestedManyWithoutUserInput>;
  collection?: InputMaybe<CollectionCreateNestedManyWithoutOwnerInput>;
  country?: InputMaybe<Scalars['String']>;
  couponUsages?: InputMaybe<CouponUsageCreateNestedManyWithoutUserInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdStamps?: InputMaybe<StampCreateNestedManyWithoutCreatorInput>;
  detail?: InputMaybe<OrderDetailCreateNestedManyWithoutReceiverInput>;
  deviceToken?: InputMaybe<DeviceTokenCreateNestedManyWithoutUserInput>;
  email?: InputMaybe<Scalars['String']>;
  emailTos?: InputMaybe<EmailToCreateNestedManyWithoutOwnerInput>;
  favoriteStoreItems?: InputMaybe<StoreItemCreateNestedManyWithoutFavoriteByUsersInput>;
  fetchFriends?: InputMaybe<UserFriendCreateNestedManyWithoutOwnerInput>;
  followProfitables?: InputMaybe<ProfitableCreateNestedManyWithoutFollowedByInput>;
  followedBy?: InputMaybe<UserCreateNestedManyWithoutFollowingInput>;
  following?: InputMaybe<UserCreateNestedManyWithoutFollowedByInput>;
  gender?: InputMaybe<Scalars['String']>;
  groupsJoin?: InputMaybe<GroupCreateNestedManyWithoutMembersInput>;
  groupsOwn?: InputMaybe<GroupCreateNestedManyWithoutOwnerInput>;
  headUri?: InputMaybe<Scalars['String']>;
  history?: InputMaybe<OrderDetailHistoryCreateNestedManyWithoutOperatorInput>;
  id?: InputMaybe<Scalars['String']>;
  idStr: Scalars['String'];
  inFriends?: InputMaybe<UserFriendCreateNestedManyWithoutUsersInput>;
  inValidReasonHistories?: InputMaybe<InValidReasonCreateNestedManyWithoutUserInput>;
  lastLogin?: InputMaybe<Scalars['DateTime']>;
  lastTokenUpdated?: InputMaybe<Scalars['DateTime']>;
  likePosts?: InputMaybe<PostCreateNestedManyWithoutLikeUsersInput>;
  manualAddrs?: InputMaybe<ManualAddrCreateNestedManyWithoutOwnerInput>;
  myCoupons?: InputMaybe<CouponEntityCreateNestedManyWithoutHolderInput>;
  name: Scalars['String'];
  notificationReceiver?: InputMaybe<NotificationCreateNestedManyWithoutReceiverInput>;
  notificationSender?: InputMaybe<NotificationCreateNestedManyWithoutSenderInput>;
  order?: InputMaybe<OrderCreateNestedManyWithoutSenderInput>;
  password?: InputMaybe<Scalars['String']>;
  pcoins?: InputMaybe<Scalars['Decimal']>;
  phone?: InputMaybe<Scalars['String']>;
  postPackage?: InputMaybe<PostPackageCreateNestedManyWithoutSenderInput>;
  postsIn?: InputMaybe<PostCreateNestedManyWithoutReceiverInput>;
  postsOut?: InputMaybe<PostCreateNestedManyWithoutSenderInput>;
  profitable?: InputMaybe<ProfitableCreateNestedOneWithoutUserInput>;
  receive_email?: InputMaybe<Scalars['String']>;
  receive_phone?: InputMaybe<Scalars['String']>;
  reportByMe?: InputMaybe<ReportUserCreateNestedManyWithoutReporterInput>;
  reported?: InputMaybe<ReportUserCreateNestedManyWithoutUserInput>;
  reports?: InputMaybe<ReportCreateNestedManyWithoutReporterInput>;
  settingNotification?: InputMaybe<SettingNotificationCreateNestedOneWithoutUserInput>;
  settingPrivacy?: InputMaybe<SettingPrivacyCreateNestedOneWithoutUserInput>;
  signature?: InputMaybe<SignatureCreateNestedManyWithoutOwnerInput>;
  storePurchaseHistory?: InputMaybe<CartItemCreateNestedManyWithoutCustomerInput>;
  tagsUsed?: InputMaybe<PostTagCreateNestedManyWithoutUserInput>;
  thirdpartyId: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  vAddrMapping?: InputMaybe<VirtualAddrMappingCreateNestedOneWithoutOwnerInput>;
  valid?: InputMaybe<Scalars['Boolean']>;
  website?: InputMaybe<Scalars['String']>;
};

export type UserCreateWithoutPostPackageInput = {
  accessToken?: InputMaybe<Scalars['String']>;
  address?: InputMaybe<AddressCreateNestedManyWithoutOwnerInput>;
  attachments?: InputMaybe<AttachmentCreateNestedManyWithoutOwnerInput>;
  authType: UserAuthType;
  bio?: InputMaybe<Scalars['String']>;
  campaignNanhui?: InputMaybe<CampaignNanhuiCreateNestedManyWithoutSubmitterInput>;
  campainZeczec?: InputMaybe<CampainZeczecCreateNestedOneWithoutRegisterUserInput>;
  card?: InputMaybe<CardCreateNestedManyWithoutCreatorInput>;
  coins?: InputMaybe<CoinCreateNestedManyWithoutUserInput>;
  collection?: InputMaybe<CollectionCreateNestedManyWithoutOwnerInput>;
  country?: InputMaybe<Scalars['String']>;
  couponUsages?: InputMaybe<CouponUsageCreateNestedManyWithoutUserInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdStamps?: InputMaybe<StampCreateNestedManyWithoutCreatorInput>;
  detail?: InputMaybe<OrderDetailCreateNestedManyWithoutReceiverInput>;
  deviceToken?: InputMaybe<DeviceTokenCreateNestedManyWithoutUserInput>;
  email?: InputMaybe<Scalars['String']>;
  emailTos?: InputMaybe<EmailToCreateNestedManyWithoutOwnerInput>;
  favoriteStoreItems?: InputMaybe<StoreItemCreateNestedManyWithoutFavoriteByUsersInput>;
  fetchFriends?: InputMaybe<UserFriendCreateNestedManyWithoutOwnerInput>;
  followProfitables?: InputMaybe<ProfitableCreateNestedManyWithoutFollowedByInput>;
  followedBy?: InputMaybe<UserCreateNestedManyWithoutFollowingInput>;
  following?: InputMaybe<UserCreateNestedManyWithoutFollowedByInput>;
  gender?: InputMaybe<Scalars['String']>;
  groupsJoin?: InputMaybe<GroupCreateNestedManyWithoutMembersInput>;
  groupsOwn?: InputMaybe<GroupCreateNestedManyWithoutOwnerInput>;
  headUri?: InputMaybe<Scalars['String']>;
  history?: InputMaybe<OrderDetailHistoryCreateNestedManyWithoutOperatorInput>;
  id?: InputMaybe<Scalars['String']>;
  idStr: Scalars['String'];
  inFriends?: InputMaybe<UserFriendCreateNestedManyWithoutUsersInput>;
  inValidReasonHistories?: InputMaybe<InValidReasonCreateNestedManyWithoutUserInput>;
  lastLogin?: InputMaybe<Scalars['DateTime']>;
  lastTokenUpdated?: InputMaybe<Scalars['DateTime']>;
  likePosts?: InputMaybe<PostCreateNestedManyWithoutLikeUsersInput>;
  manualAddrs?: InputMaybe<ManualAddrCreateNestedManyWithoutOwnerInput>;
  myCoupons?: InputMaybe<CouponEntityCreateNestedManyWithoutHolderInput>;
  name: Scalars['String'];
  notificationReceiver?: InputMaybe<NotificationCreateNestedManyWithoutReceiverInput>;
  notificationSender?: InputMaybe<NotificationCreateNestedManyWithoutSenderInput>;
  order?: InputMaybe<OrderCreateNestedManyWithoutSenderInput>;
  packagesAsReceiver?: InputMaybe<PostPackageCreateNestedManyWithoutReceiversInput>;
  password?: InputMaybe<Scalars['String']>;
  pcoins?: InputMaybe<Scalars['Decimal']>;
  phone?: InputMaybe<Scalars['String']>;
  postsIn?: InputMaybe<PostCreateNestedManyWithoutReceiverInput>;
  postsOut?: InputMaybe<PostCreateNestedManyWithoutSenderInput>;
  profitable?: InputMaybe<ProfitableCreateNestedOneWithoutUserInput>;
  receive_email?: InputMaybe<Scalars['String']>;
  receive_phone?: InputMaybe<Scalars['String']>;
  reportByMe?: InputMaybe<ReportUserCreateNestedManyWithoutReporterInput>;
  reported?: InputMaybe<ReportUserCreateNestedManyWithoutUserInput>;
  reports?: InputMaybe<ReportCreateNestedManyWithoutReporterInput>;
  settingNotification?: InputMaybe<SettingNotificationCreateNestedOneWithoutUserInput>;
  settingPrivacy?: InputMaybe<SettingPrivacyCreateNestedOneWithoutUserInput>;
  signature?: InputMaybe<SignatureCreateNestedManyWithoutOwnerInput>;
  storePurchaseHistory?: InputMaybe<CartItemCreateNestedManyWithoutCustomerInput>;
  tagsUsed?: InputMaybe<PostTagCreateNestedManyWithoutUserInput>;
  thirdpartyId: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  vAddrMapping?: InputMaybe<VirtualAddrMappingCreateNestedOneWithoutOwnerInput>;
  valid?: InputMaybe<Scalars['Boolean']>;
  website?: InputMaybe<Scalars['String']>;
};

export type UserCreateWithoutPostsInInput = {
  accessToken?: InputMaybe<Scalars['String']>;
  address?: InputMaybe<AddressCreateNestedManyWithoutOwnerInput>;
  attachments?: InputMaybe<AttachmentCreateNestedManyWithoutOwnerInput>;
  authType: UserAuthType;
  bio?: InputMaybe<Scalars['String']>;
  campaignNanhui?: InputMaybe<CampaignNanhuiCreateNestedManyWithoutSubmitterInput>;
  campainZeczec?: InputMaybe<CampainZeczecCreateNestedOneWithoutRegisterUserInput>;
  card?: InputMaybe<CardCreateNestedManyWithoutCreatorInput>;
  coins?: InputMaybe<CoinCreateNestedManyWithoutUserInput>;
  collection?: InputMaybe<CollectionCreateNestedManyWithoutOwnerInput>;
  country?: InputMaybe<Scalars['String']>;
  couponUsages?: InputMaybe<CouponUsageCreateNestedManyWithoutUserInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdStamps?: InputMaybe<StampCreateNestedManyWithoutCreatorInput>;
  detail?: InputMaybe<OrderDetailCreateNestedManyWithoutReceiverInput>;
  deviceToken?: InputMaybe<DeviceTokenCreateNestedManyWithoutUserInput>;
  email?: InputMaybe<Scalars['String']>;
  emailTos?: InputMaybe<EmailToCreateNestedManyWithoutOwnerInput>;
  favoriteStoreItems?: InputMaybe<StoreItemCreateNestedManyWithoutFavoriteByUsersInput>;
  fetchFriends?: InputMaybe<UserFriendCreateNestedManyWithoutOwnerInput>;
  followProfitables?: InputMaybe<ProfitableCreateNestedManyWithoutFollowedByInput>;
  followedBy?: InputMaybe<UserCreateNestedManyWithoutFollowingInput>;
  following?: InputMaybe<UserCreateNestedManyWithoutFollowedByInput>;
  gender?: InputMaybe<Scalars['String']>;
  groupsJoin?: InputMaybe<GroupCreateNestedManyWithoutMembersInput>;
  groupsOwn?: InputMaybe<GroupCreateNestedManyWithoutOwnerInput>;
  headUri?: InputMaybe<Scalars['String']>;
  history?: InputMaybe<OrderDetailHistoryCreateNestedManyWithoutOperatorInput>;
  id?: InputMaybe<Scalars['String']>;
  idStr: Scalars['String'];
  inFriends?: InputMaybe<UserFriendCreateNestedManyWithoutUsersInput>;
  inValidReasonHistories?: InputMaybe<InValidReasonCreateNestedManyWithoutUserInput>;
  lastLogin?: InputMaybe<Scalars['DateTime']>;
  lastTokenUpdated?: InputMaybe<Scalars['DateTime']>;
  likePosts?: InputMaybe<PostCreateNestedManyWithoutLikeUsersInput>;
  manualAddrs?: InputMaybe<ManualAddrCreateNestedManyWithoutOwnerInput>;
  myCoupons?: InputMaybe<CouponEntityCreateNestedManyWithoutHolderInput>;
  name: Scalars['String'];
  notificationReceiver?: InputMaybe<NotificationCreateNestedManyWithoutReceiverInput>;
  notificationSender?: InputMaybe<NotificationCreateNestedManyWithoutSenderInput>;
  order?: InputMaybe<OrderCreateNestedManyWithoutSenderInput>;
  packagesAsReceiver?: InputMaybe<PostPackageCreateNestedManyWithoutReceiversInput>;
  password?: InputMaybe<Scalars['String']>;
  pcoins?: InputMaybe<Scalars['Decimal']>;
  phone?: InputMaybe<Scalars['String']>;
  postPackage?: InputMaybe<PostPackageCreateNestedManyWithoutSenderInput>;
  postsOut?: InputMaybe<PostCreateNestedManyWithoutSenderInput>;
  profitable?: InputMaybe<ProfitableCreateNestedOneWithoutUserInput>;
  receive_email?: InputMaybe<Scalars['String']>;
  receive_phone?: InputMaybe<Scalars['String']>;
  reportByMe?: InputMaybe<ReportUserCreateNestedManyWithoutReporterInput>;
  reported?: InputMaybe<ReportUserCreateNestedManyWithoutUserInput>;
  reports?: InputMaybe<ReportCreateNestedManyWithoutReporterInput>;
  settingNotification?: InputMaybe<SettingNotificationCreateNestedOneWithoutUserInput>;
  settingPrivacy?: InputMaybe<SettingPrivacyCreateNestedOneWithoutUserInput>;
  signature?: InputMaybe<SignatureCreateNestedManyWithoutOwnerInput>;
  storePurchaseHistory?: InputMaybe<CartItemCreateNestedManyWithoutCustomerInput>;
  tagsUsed?: InputMaybe<PostTagCreateNestedManyWithoutUserInput>;
  thirdpartyId: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  vAddrMapping?: InputMaybe<VirtualAddrMappingCreateNestedOneWithoutOwnerInput>;
  valid?: InputMaybe<Scalars['Boolean']>;
  website?: InputMaybe<Scalars['String']>;
};

export type UserCreateWithoutPostsOutInput = {
  accessToken?: InputMaybe<Scalars['String']>;
  address?: InputMaybe<AddressCreateNestedManyWithoutOwnerInput>;
  attachments?: InputMaybe<AttachmentCreateNestedManyWithoutOwnerInput>;
  authType: UserAuthType;
  bio?: InputMaybe<Scalars['String']>;
  campaignNanhui?: InputMaybe<CampaignNanhuiCreateNestedManyWithoutSubmitterInput>;
  campainZeczec?: InputMaybe<CampainZeczecCreateNestedOneWithoutRegisterUserInput>;
  card?: InputMaybe<CardCreateNestedManyWithoutCreatorInput>;
  coins?: InputMaybe<CoinCreateNestedManyWithoutUserInput>;
  collection?: InputMaybe<CollectionCreateNestedManyWithoutOwnerInput>;
  country?: InputMaybe<Scalars['String']>;
  couponUsages?: InputMaybe<CouponUsageCreateNestedManyWithoutUserInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdStamps?: InputMaybe<StampCreateNestedManyWithoutCreatorInput>;
  detail?: InputMaybe<OrderDetailCreateNestedManyWithoutReceiverInput>;
  deviceToken?: InputMaybe<DeviceTokenCreateNestedManyWithoutUserInput>;
  email?: InputMaybe<Scalars['String']>;
  emailTos?: InputMaybe<EmailToCreateNestedManyWithoutOwnerInput>;
  favoriteStoreItems?: InputMaybe<StoreItemCreateNestedManyWithoutFavoriteByUsersInput>;
  fetchFriends?: InputMaybe<UserFriendCreateNestedManyWithoutOwnerInput>;
  followProfitables?: InputMaybe<ProfitableCreateNestedManyWithoutFollowedByInput>;
  followedBy?: InputMaybe<UserCreateNestedManyWithoutFollowingInput>;
  following?: InputMaybe<UserCreateNestedManyWithoutFollowedByInput>;
  gender?: InputMaybe<Scalars['String']>;
  groupsJoin?: InputMaybe<GroupCreateNestedManyWithoutMembersInput>;
  groupsOwn?: InputMaybe<GroupCreateNestedManyWithoutOwnerInput>;
  headUri?: InputMaybe<Scalars['String']>;
  history?: InputMaybe<OrderDetailHistoryCreateNestedManyWithoutOperatorInput>;
  id?: InputMaybe<Scalars['String']>;
  idStr: Scalars['String'];
  inFriends?: InputMaybe<UserFriendCreateNestedManyWithoutUsersInput>;
  inValidReasonHistories?: InputMaybe<InValidReasonCreateNestedManyWithoutUserInput>;
  lastLogin?: InputMaybe<Scalars['DateTime']>;
  lastTokenUpdated?: InputMaybe<Scalars['DateTime']>;
  likePosts?: InputMaybe<PostCreateNestedManyWithoutLikeUsersInput>;
  manualAddrs?: InputMaybe<ManualAddrCreateNestedManyWithoutOwnerInput>;
  myCoupons?: InputMaybe<CouponEntityCreateNestedManyWithoutHolderInput>;
  name: Scalars['String'];
  notificationReceiver?: InputMaybe<NotificationCreateNestedManyWithoutReceiverInput>;
  notificationSender?: InputMaybe<NotificationCreateNestedManyWithoutSenderInput>;
  order?: InputMaybe<OrderCreateNestedManyWithoutSenderInput>;
  packagesAsReceiver?: InputMaybe<PostPackageCreateNestedManyWithoutReceiversInput>;
  password?: InputMaybe<Scalars['String']>;
  pcoins?: InputMaybe<Scalars['Decimal']>;
  phone?: InputMaybe<Scalars['String']>;
  postPackage?: InputMaybe<PostPackageCreateNestedManyWithoutSenderInput>;
  postsIn?: InputMaybe<PostCreateNestedManyWithoutReceiverInput>;
  profitable?: InputMaybe<ProfitableCreateNestedOneWithoutUserInput>;
  receive_email?: InputMaybe<Scalars['String']>;
  receive_phone?: InputMaybe<Scalars['String']>;
  reportByMe?: InputMaybe<ReportUserCreateNestedManyWithoutReporterInput>;
  reported?: InputMaybe<ReportUserCreateNestedManyWithoutUserInput>;
  reports?: InputMaybe<ReportCreateNestedManyWithoutReporterInput>;
  settingNotification?: InputMaybe<SettingNotificationCreateNestedOneWithoutUserInput>;
  settingPrivacy?: InputMaybe<SettingPrivacyCreateNestedOneWithoutUserInput>;
  signature?: InputMaybe<SignatureCreateNestedManyWithoutOwnerInput>;
  storePurchaseHistory?: InputMaybe<CartItemCreateNestedManyWithoutCustomerInput>;
  tagsUsed?: InputMaybe<PostTagCreateNestedManyWithoutUserInput>;
  thirdpartyId: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  vAddrMapping?: InputMaybe<VirtualAddrMappingCreateNestedOneWithoutOwnerInput>;
  valid?: InputMaybe<Scalars['Boolean']>;
  website?: InputMaybe<Scalars['String']>;
};

export type UserCreateWithoutProfitableInput = {
  accessToken?: InputMaybe<Scalars['String']>;
  address?: InputMaybe<AddressCreateNestedManyWithoutOwnerInput>;
  attachments?: InputMaybe<AttachmentCreateNestedManyWithoutOwnerInput>;
  authType: UserAuthType;
  bio?: InputMaybe<Scalars['String']>;
  campaignNanhui?: InputMaybe<CampaignNanhuiCreateNestedManyWithoutSubmitterInput>;
  campainZeczec?: InputMaybe<CampainZeczecCreateNestedOneWithoutRegisterUserInput>;
  card?: InputMaybe<CardCreateNestedManyWithoutCreatorInput>;
  coins?: InputMaybe<CoinCreateNestedManyWithoutUserInput>;
  collection?: InputMaybe<CollectionCreateNestedManyWithoutOwnerInput>;
  country?: InputMaybe<Scalars['String']>;
  couponUsages?: InputMaybe<CouponUsageCreateNestedManyWithoutUserInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdStamps?: InputMaybe<StampCreateNestedManyWithoutCreatorInput>;
  detail?: InputMaybe<OrderDetailCreateNestedManyWithoutReceiverInput>;
  deviceToken?: InputMaybe<DeviceTokenCreateNestedManyWithoutUserInput>;
  email?: InputMaybe<Scalars['String']>;
  emailTos?: InputMaybe<EmailToCreateNestedManyWithoutOwnerInput>;
  favoriteStoreItems?: InputMaybe<StoreItemCreateNestedManyWithoutFavoriteByUsersInput>;
  fetchFriends?: InputMaybe<UserFriendCreateNestedManyWithoutOwnerInput>;
  followProfitables?: InputMaybe<ProfitableCreateNestedManyWithoutFollowedByInput>;
  followedBy?: InputMaybe<UserCreateNestedManyWithoutFollowingInput>;
  following?: InputMaybe<UserCreateNestedManyWithoutFollowedByInput>;
  gender?: InputMaybe<Scalars['String']>;
  groupsJoin?: InputMaybe<GroupCreateNestedManyWithoutMembersInput>;
  groupsOwn?: InputMaybe<GroupCreateNestedManyWithoutOwnerInput>;
  headUri?: InputMaybe<Scalars['String']>;
  history?: InputMaybe<OrderDetailHistoryCreateNestedManyWithoutOperatorInput>;
  id?: InputMaybe<Scalars['String']>;
  idStr: Scalars['String'];
  inFriends?: InputMaybe<UserFriendCreateNestedManyWithoutUsersInput>;
  inValidReasonHistories?: InputMaybe<InValidReasonCreateNestedManyWithoutUserInput>;
  lastLogin?: InputMaybe<Scalars['DateTime']>;
  lastTokenUpdated?: InputMaybe<Scalars['DateTime']>;
  likePosts?: InputMaybe<PostCreateNestedManyWithoutLikeUsersInput>;
  manualAddrs?: InputMaybe<ManualAddrCreateNestedManyWithoutOwnerInput>;
  myCoupons?: InputMaybe<CouponEntityCreateNestedManyWithoutHolderInput>;
  name: Scalars['String'];
  notificationReceiver?: InputMaybe<NotificationCreateNestedManyWithoutReceiverInput>;
  notificationSender?: InputMaybe<NotificationCreateNestedManyWithoutSenderInput>;
  order?: InputMaybe<OrderCreateNestedManyWithoutSenderInput>;
  packagesAsReceiver?: InputMaybe<PostPackageCreateNestedManyWithoutReceiversInput>;
  password?: InputMaybe<Scalars['String']>;
  pcoins?: InputMaybe<Scalars['Decimal']>;
  phone?: InputMaybe<Scalars['String']>;
  postPackage?: InputMaybe<PostPackageCreateNestedManyWithoutSenderInput>;
  postsIn?: InputMaybe<PostCreateNestedManyWithoutReceiverInput>;
  postsOut?: InputMaybe<PostCreateNestedManyWithoutSenderInput>;
  receive_email?: InputMaybe<Scalars['String']>;
  receive_phone?: InputMaybe<Scalars['String']>;
  reportByMe?: InputMaybe<ReportUserCreateNestedManyWithoutReporterInput>;
  reported?: InputMaybe<ReportUserCreateNestedManyWithoutUserInput>;
  reports?: InputMaybe<ReportCreateNestedManyWithoutReporterInput>;
  settingNotification?: InputMaybe<SettingNotificationCreateNestedOneWithoutUserInput>;
  settingPrivacy?: InputMaybe<SettingPrivacyCreateNestedOneWithoutUserInput>;
  signature?: InputMaybe<SignatureCreateNestedManyWithoutOwnerInput>;
  storePurchaseHistory?: InputMaybe<CartItemCreateNestedManyWithoutCustomerInput>;
  tagsUsed?: InputMaybe<PostTagCreateNestedManyWithoutUserInput>;
  thirdpartyId: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  vAddrMapping?: InputMaybe<VirtualAddrMappingCreateNestedOneWithoutOwnerInput>;
  valid?: InputMaybe<Scalars['Boolean']>;
  website?: InputMaybe<Scalars['String']>;
};

export type UserCreateWithoutReportByMeInput = {
  accessToken?: InputMaybe<Scalars['String']>;
  address?: InputMaybe<AddressCreateNestedManyWithoutOwnerInput>;
  attachments?: InputMaybe<AttachmentCreateNestedManyWithoutOwnerInput>;
  authType: UserAuthType;
  bio?: InputMaybe<Scalars['String']>;
  campaignNanhui?: InputMaybe<CampaignNanhuiCreateNestedManyWithoutSubmitterInput>;
  campainZeczec?: InputMaybe<CampainZeczecCreateNestedOneWithoutRegisterUserInput>;
  card?: InputMaybe<CardCreateNestedManyWithoutCreatorInput>;
  coins?: InputMaybe<CoinCreateNestedManyWithoutUserInput>;
  collection?: InputMaybe<CollectionCreateNestedManyWithoutOwnerInput>;
  country?: InputMaybe<Scalars['String']>;
  couponUsages?: InputMaybe<CouponUsageCreateNestedManyWithoutUserInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdStamps?: InputMaybe<StampCreateNestedManyWithoutCreatorInput>;
  detail?: InputMaybe<OrderDetailCreateNestedManyWithoutReceiverInput>;
  deviceToken?: InputMaybe<DeviceTokenCreateNestedManyWithoutUserInput>;
  email?: InputMaybe<Scalars['String']>;
  emailTos?: InputMaybe<EmailToCreateNestedManyWithoutOwnerInput>;
  favoriteStoreItems?: InputMaybe<StoreItemCreateNestedManyWithoutFavoriteByUsersInput>;
  fetchFriends?: InputMaybe<UserFriendCreateNestedManyWithoutOwnerInput>;
  followProfitables?: InputMaybe<ProfitableCreateNestedManyWithoutFollowedByInput>;
  followedBy?: InputMaybe<UserCreateNestedManyWithoutFollowingInput>;
  following?: InputMaybe<UserCreateNestedManyWithoutFollowedByInput>;
  gender?: InputMaybe<Scalars['String']>;
  groupsJoin?: InputMaybe<GroupCreateNestedManyWithoutMembersInput>;
  groupsOwn?: InputMaybe<GroupCreateNestedManyWithoutOwnerInput>;
  headUri?: InputMaybe<Scalars['String']>;
  history?: InputMaybe<OrderDetailHistoryCreateNestedManyWithoutOperatorInput>;
  id?: InputMaybe<Scalars['String']>;
  idStr: Scalars['String'];
  inFriends?: InputMaybe<UserFriendCreateNestedManyWithoutUsersInput>;
  inValidReasonHistories?: InputMaybe<InValidReasonCreateNestedManyWithoutUserInput>;
  lastLogin?: InputMaybe<Scalars['DateTime']>;
  lastTokenUpdated?: InputMaybe<Scalars['DateTime']>;
  likePosts?: InputMaybe<PostCreateNestedManyWithoutLikeUsersInput>;
  manualAddrs?: InputMaybe<ManualAddrCreateNestedManyWithoutOwnerInput>;
  myCoupons?: InputMaybe<CouponEntityCreateNestedManyWithoutHolderInput>;
  name: Scalars['String'];
  notificationReceiver?: InputMaybe<NotificationCreateNestedManyWithoutReceiverInput>;
  notificationSender?: InputMaybe<NotificationCreateNestedManyWithoutSenderInput>;
  order?: InputMaybe<OrderCreateNestedManyWithoutSenderInput>;
  packagesAsReceiver?: InputMaybe<PostPackageCreateNestedManyWithoutReceiversInput>;
  password?: InputMaybe<Scalars['String']>;
  pcoins?: InputMaybe<Scalars['Decimal']>;
  phone?: InputMaybe<Scalars['String']>;
  postPackage?: InputMaybe<PostPackageCreateNestedManyWithoutSenderInput>;
  postsIn?: InputMaybe<PostCreateNestedManyWithoutReceiverInput>;
  postsOut?: InputMaybe<PostCreateNestedManyWithoutSenderInput>;
  profitable?: InputMaybe<ProfitableCreateNestedOneWithoutUserInput>;
  receive_email?: InputMaybe<Scalars['String']>;
  receive_phone?: InputMaybe<Scalars['String']>;
  reported?: InputMaybe<ReportUserCreateNestedManyWithoutUserInput>;
  reports?: InputMaybe<ReportCreateNestedManyWithoutReporterInput>;
  settingNotification?: InputMaybe<SettingNotificationCreateNestedOneWithoutUserInput>;
  settingPrivacy?: InputMaybe<SettingPrivacyCreateNestedOneWithoutUserInput>;
  signature?: InputMaybe<SignatureCreateNestedManyWithoutOwnerInput>;
  storePurchaseHistory?: InputMaybe<CartItemCreateNestedManyWithoutCustomerInput>;
  tagsUsed?: InputMaybe<PostTagCreateNestedManyWithoutUserInput>;
  thirdpartyId: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  vAddrMapping?: InputMaybe<VirtualAddrMappingCreateNestedOneWithoutOwnerInput>;
  valid?: InputMaybe<Scalars['Boolean']>;
  website?: InputMaybe<Scalars['String']>;
};

export type UserCreateWithoutReportedInput = {
  accessToken?: InputMaybe<Scalars['String']>;
  address?: InputMaybe<AddressCreateNestedManyWithoutOwnerInput>;
  attachments?: InputMaybe<AttachmentCreateNestedManyWithoutOwnerInput>;
  authType: UserAuthType;
  bio?: InputMaybe<Scalars['String']>;
  campaignNanhui?: InputMaybe<CampaignNanhuiCreateNestedManyWithoutSubmitterInput>;
  campainZeczec?: InputMaybe<CampainZeczecCreateNestedOneWithoutRegisterUserInput>;
  card?: InputMaybe<CardCreateNestedManyWithoutCreatorInput>;
  coins?: InputMaybe<CoinCreateNestedManyWithoutUserInput>;
  collection?: InputMaybe<CollectionCreateNestedManyWithoutOwnerInput>;
  country?: InputMaybe<Scalars['String']>;
  couponUsages?: InputMaybe<CouponUsageCreateNestedManyWithoutUserInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdStamps?: InputMaybe<StampCreateNestedManyWithoutCreatorInput>;
  detail?: InputMaybe<OrderDetailCreateNestedManyWithoutReceiverInput>;
  deviceToken?: InputMaybe<DeviceTokenCreateNestedManyWithoutUserInput>;
  email?: InputMaybe<Scalars['String']>;
  emailTos?: InputMaybe<EmailToCreateNestedManyWithoutOwnerInput>;
  favoriteStoreItems?: InputMaybe<StoreItemCreateNestedManyWithoutFavoriteByUsersInput>;
  fetchFriends?: InputMaybe<UserFriendCreateNestedManyWithoutOwnerInput>;
  followProfitables?: InputMaybe<ProfitableCreateNestedManyWithoutFollowedByInput>;
  followedBy?: InputMaybe<UserCreateNestedManyWithoutFollowingInput>;
  following?: InputMaybe<UserCreateNestedManyWithoutFollowedByInput>;
  gender?: InputMaybe<Scalars['String']>;
  groupsJoin?: InputMaybe<GroupCreateNestedManyWithoutMembersInput>;
  groupsOwn?: InputMaybe<GroupCreateNestedManyWithoutOwnerInput>;
  headUri?: InputMaybe<Scalars['String']>;
  history?: InputMaybe<OrderDetailHistoryCreateNestedManyWithoutOperatorInput>;
  id?: InputMaybe<Scalars['String']>;
  idStr: Scalars['String'];
  inFriends?: InputMaybe<UserFriendCreateNestedManyWithoutUsersInput>;
  inValidReasonHistories?: InputMaybe<InValidReasonCreateNestedManyWithoutUserInput>;
  lastLogin?: InputMaybe<Scalars['DateTime']>;
  lastTokenUpdated?: InputMaybe<Scalars['DateTime']>;
  likePosts?: InputMaybe<PostCreateNestedManyWithoutLikeUsersInput>;
  manualAddrs?: InputMaybe<ManualAddrCreateNestedManyWithoutOwnerInput>;
  myCoupons?: InputMaybe<CouponEntityCreateNestedManyWithoutHolderInput>;
  name: Scalars['String'];
  notificationReceiver?: InputMaybe<NotificationCreateNestedManyWithoutReceiverInput>;
  notificationSender?: InputMaybe<NotificationCreateNestedManyWithoutSenderInput>;
  order?: InputMaybe<OrderCreateNestedManyWithoutSenderInput>;
  packagesAsReceiver?: InputMaybe<PostPackageCreateNestedManyWithoutReceiversInput>;
  password?: InputMaybe<Scalars['String']>;
  pcoins?: InputMaybe<Scalars['Decimal']>;
  phone?: InputMaybe<Scalars['String']>;
  postPackage?: InputMaybe<PostPackageCreateNestedManyWithoutSenderInput>;
  postsIn?: InputMaybe<PostCreateNestedManyWithoutReceiverInput>;
  postsOut?: InputMaybe<PostCreateNestedManyWithoutSenderInput>;
  profitable?: InputMaybe<ProfitableCreateNestedOneWithoutUserInput>;
  receive_email?: InputMaybe<Scalars['String']>;
  receive_phone?: InputMaybe<Scalars['String']>;
  reportByMe?: InputMaybe<ReportUserCreateNestedManyWithoutReporterInput>;
  reports?: InputMaybe<ReportCreateNestedManyWithoutReporterInput>;
  settingNotification?: InputMaybe<SettingNotificationCreateNestedOneWithoutUserInput>;
  settingPrivacy?: InputMaybe<SettingPrivacyCreateNestedOneWithoutUserInput>;
  signature?: InputMaybe<SignatureCreateNestedManyWithoutOwnerInput>;
  storePurchaseHistory?: InputMaybe<CartItemCreateNestedManyWithoutCustomerInput>;
  tagsUsed?: InputMaybe<PostTagCreateNestedManyWithoutUserInput>;
  thirdpartyId: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  vAddrMapping?: InputMaybe<VirtualAddrMappingCreateNestedOneWithoutOwnerInput>;
  valid?: InputMaybe<Scalars['Boolean']>;
  website?: InputMaybe<Scalars['String']>;
};

export type UserCreateWithoutReportsInput = {
  accessToken?: InputMaybe<Scalars['String']>;
  address?: InputMaybe<AddressCreateNestedManyWithoutOwnerInput>;
  attachments?: InputMaybe<AttachmentCreateNestedManyWithoutOwnerInput>;
  authType: UserAuthType;
  bio?: InputMaybe<Scalars['String']>;
  campaignNanhui?: InputMaybe<CampaignNanhuiCreateNestedManyWithoutSubmitterInput>;
  campainZeczec?: InputMaybe<CampainZeczecCreateNestedOneWithoutRegisterUserInput>;
  card?: InputMaybe<CardCreateNestedManyWithoutCreatorInput>;
  coins?: InputMaybe<CoinCreateNestedManyWithoutUserInput>;
  collection?: InputMaybe<CollectionCreateNestedManyWithoutOwnerInput>;
  country?: InputMaybe<Scalars['String']>;
  couponUsages?: InputMaybe<CouponUsageCreateNestedManyWithoutUserInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdStamps?: InputMaybe<StampCreateNestedManyWithoutCreatorInput>;
  detail?: InputMaybe<OrderDetailCreateNestedManyWithoutReceiverInput>;
  deviceToken?: InputMaybe<DeviceTokenCreateNestedManyWithoutUserInput>;
  email?: InputMaybe<Scalars['String']>;
  emailTos?: InputMaybe<EmailToCreateNestedManyWithoutOwnerInput>;
  favoriteStoreItems?: InputMaybe<StoreItemCreateNestedManyWithoutFavoriteByUsersInput>;
  fetchFriends?: InputMaybe<UserFriendCreateNestedManyWithoutOwnerInput>;
  followProfitables?: InputMaybe<ProfitableCreateNestedManyWithoutFollowedByInput>;
  followedBy?: InputMaybe<UserCreateNestedManyWithoutFollowingInput>;
  following?: InputMaybe<UserCreateNestedManyWithoutFollowedByInput>;
  gender?: InputMaybe<Scalars['String']>;
  groupsJoin?: InputMaybe<GroupCreateNestedManyWithoutMembersInput>;
  groupsOwn?: InputMaybe<GroupCreateNestedManyWithoutOwnerInput>;
  headUri?: InputMaybe<Scalars['String']>;
  history?: InputMaybe<OrderDetailHistoryCreateNestedManyWithoutOperatorInput>;
  id?: InputMaybe<Scalars['String']>;
  idStr: Scalars['String'];
  inFriends?: InputMaybe<UserFriendCreateNestedManyWithoutUsersInput>;
  inValidReasonHistories?: InputMaybe<InValidReasonCreateNestedManyWithoutUserInput>;
  lastLogin?: InputMaybe<Scalars['DateTime']>;
  lastTokenUpdated?: InputMaybe<Scalars['DateTime']>;
  likePosts?: InputMaybe<PostCreateNestedManyWithoutLikeUsersInput>;
  manualAddrs?: InputMaybe<ManualAddrCreateNestedManyWithoutOwnerInput>;
  myCoupons?: InputMaybe<CouponEntityCreateNestedManyWithoutHolderInput>;
  name: Scalars['String'];
  notificationReceiver?: InputMaybe<NotificationCreateNestedManyWithoutReceiverInput>;
  notificationSender?: InputMaybe<NotificationCreateNestedManyWithoutSenderInput>;
  order?: InputMaybe<OrderCreateNestedManyWithoutSenderInput>;
  packagesAsReceiver?: InputMaybe<PostPackageCreateNestedManyWithoutReceiversInput>;
  password?: InputMaybe<Scalars['String']>;
  pcoins?: InputMaybe<Scalars['Decimal']>;
  phone?: InputMaybe<Scalars['String']>;
  postPackage?: InputMaybe<PostPackageCreateNestedManyWithoutSenderInput>;
  postsIn?: InputMaybe<PostCreateNestedManyWithoutReceiverInput>;
  postsOut?: InputMaybe<PostCreateNestedManyWithoutSenderInput>;
  profitable?: InputMaybe<ProfitableCreateNestedOneWithoutUserInput>;
  receive_email?: InputMaybe<Scalars['String']>;
  receive_phone?: InputMaybe<Scalars['String']>;
  reportByMe?: InputMaybe<ReportUserCreateNestedManyWithoutReporterInput>;
  reported?: InputMaybe<ReportUserCreateNestedManyWithoutUserInput>;
  settingNotification?: InputMaybe<SettingNotificationCreateNestedOneWithoutUserInput>;
  settingPrivacy?: InputMaybe<SettingPrivacyCreateNestedOneWithoutUserInput>;
  signature?: InputMaybe<SignatureCreateNestedManyWithoutOwnerInput>;
  storePurchaseHistory?: InputMaybe<CartItemCreateNestedManyWithoutCustomerInput>;
  tagsUsed?: InputMaybe<PostTagCreateNestedManyWithoutUserInput>;
  thirdpartyId: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  vAddrMapping?: InputMaybe<VirtualAddrMappingCreateNestedOneWithoutOwnerInput>;
  valid?: InputMaybe<Scalars['Boolean']>;
  website?: InputMaybe<Scalars['String']>;
};

export type UserCreateWithoutSettingNotificationInput = {
  accessToken?: InputMaybe<Scalars['String']>;
  address?: InputMaybe<AddressCreateNestedManyWithoutOwnerInput>;
  attachments?: InputMaybe<AttachmentCreateNestedManyWithoutOwnerInput>;
  authType: UserAuthType;
  bio?: InputMaybe<Scalars['String']>;
  campaignNanhui?: InputMaybe<CampaignNanhuiCreateNestedManyWithoutSubmitterInput>;
  campainZeczec?: InputMaybe<CampainZeczecCreateNestedOneWithoutRegisterUserInput>;
  card?: InputMaybe<CardCreateNestedManyWithoutCreatorInput>;
  coins?: InputMaybe<CoinCreateNestedManyWithoutUserInput>;
  collection?: InputMaybe<CollectionCreateNestedManyWithoutOwnerInput>;
  country?: InputMaybe<Scalars['String']>;
  couponUsages?: InputMaybe<CouponUsageCreateNestedManyWithoutUserInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdStamps?: InputMaybe<StampCreateNestedManyWithoutCreatorInput>;
  detail?: InputMaybe<OrderDetailCreateNestedManyWithoutReceiverInput>;
  deviceToken?: InputMaybe<DeviceTokenCreateNestedManyWithoutUserInput>;
  email?: InputMaybe<Scalars['String']>;
  emailTos?: InputMaybe<EmailToCreateNestedManyWithoutOwnerInput>;
  favoriteStoreItems?: InputMaybe<StoreItemCreateNestedManyWithoutFavoriteByUsersInput>;
  fetchFriends?: InputMaybe<UserFriendCreateNestedManyWithoutOwnerInput>;
  followProfitables?: InputMaybe<ProfitableCreateNestedManyWithoutFollowedByInput>;
  followedBy?: InputMaybe<UserCreateNestedManyWithoutFollowingInput>;
  following?: InputMaybe<UserCreateNestedManyWithoutFollowedByInput>;
  gender?: InputMaybe<Scalars['String']>;
  groupsJoin?: InputMaybe<GroupCreateNestedManyWithoutMembersInput>;
  groupsOwn?: InputMaybe<GroupCreateNestedManyWithoutOwnerInput>;
  headUri?: InputMaybe<Scalars['String']>;
  history?: InputMaybe<OrderDetailHistoryCreateNestedManyWithoutOperatorInput>;
  id?: InputMaybe<Scalars['String']>;
  idStr: Scalars['String'];
  inFriends?: InputMaybe<UserFriendCreateNestedManyWithoutUsersInput>;
  inValidReasonHistories?: InputMaybe<InValidReasonCreateNestedManyWithoutUserInput>;
  lastLogin?: InputMaybe<Scalars['DateTime']>;
  lastTokenUpdated?: InputMaybe<Scalars['DateTime']>;
  likePosts?: InputMaybe<PostCreateNestedManyWithoutLikeUsersInput>;
  manualAddrs?: InputMaybe<ManualAddrCreateNestedManyWithoutOwnerInput>;
  myCoupons?: InputMaybe<CouponEntityCreateNestedManyWithoutHolderInput>;
  name: Scalars['String'];
  notificationReceiver?: InputMaybe<NotificationCreateNestedManyWithoutReceiverInput>;
  notificationSender?: InputMaybe<NotificationCreateNestedManyWithoutSenderInput>;
  order?: InputMaybe<OrderCreateNestedManyWithoutSenderInput>;
  packagesAsReceiver?: InputMaybe<PostPackageCreateNestedManyWithoutReceiversInput>;
  password?: InputMaybe<Scalars['String']>;
  pcoins?: InputMaybe<Scalars['Decimal']>;
  phone?: InputMaybe<Scalars['String']>;
  postPackage?: InputMaybe<PostPackageCreateNestedManyWithoutSenderInput>;
  postsIn?: InputMaybe<PostCreateNestedManyWithoutReceiverInput>;
  postsOut?: InputMaybe<PostCreateNestedManyWithoutSenderInput>;
  profitable?: InputMaybe<ProfitableCreateNestedOneWithoutUserInput>;
  receive_email?: InputMaybe<Scalars['String']>;
  receive_phone?: InputMaybe<Scalars['String']>;
  reportByMe?: InputMaybe<ReportUserCreateNestedManyWithoutReporterInput>;
  reported?: InputMaybe<ReportUserCreateNestedManyWithoutUserInput>;
  reports?: InputMaybe<ReportCreateNestedManyWithoutReporterInput>;
  settingPrivacy?: InputMaybe<SettingPrivacyCreateNestedOneWithoutUserInput>;
  signature?: InputMaybe<SignatureCreateNestedManyWithoutOwnerInput>;
  storePurchaseHistory?: InputMaybe<CartItemCreateNestedManyWithoutCustomerInput>;
  tagsUsed?: InputMaybe<PostTagCreateNestedManyWithoutUserInput>;
  thirdpartyId: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  vAddrMapping?: InputMaybe<VirtualAddrMappingCreateNestedOneWithoutOwnerInput>;
  valid?: InputMaybe<Scalars['Boolean']>;
  website?: InputMaybe<Scalars['String']>;
};

export type UserCreateWithoutSettingPrivacyInput = {
  accessToken?: InputMaybe<Scalars['String']>;
  address?: InputMaybe<AddressCreateNestedManyWithoutOwnerInput>;
  attachments?: InputMaybe<AttachmentCreateNestedManyWithoutOwnerInput>;
  authType: UserAuthType;
  bio?: InputMaybe<Scalars['String']>;
  campaignNanhui?: InputMaybe<CampaignNanhuiCreateNestedManyWithoutSubmitterInput>;
  campainZeczec?: InputMaybe<CampainZeczecCreateNestedOneWithoutRegisterUserInput>;
  card?: InputMaybe<CardCreateNestedManyWithoutCreatorInput>;
  coins?: InputMaybe<CoinCreateNestedManyWithoutUserInput>;
  collection?: InputMaybe<CollectionCreateNestedManyWithoutOwnerInput>;
  country?: InputMaybe<Scalars['String']>;
  couponUsages?: InputMaybe<CouponUsageCreateNestedManyWithoutUserInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdStamps?: InputMaybe<StampCreateNestedManyWithoutCreatorInput>;
  detail?: InputMaybe<OrderDetailCreateNestedManyWithoutReceiverInput>;
  deviceToken?: InputMaybe<DeviceTokenCreateNestedManyWithoutUserInput>;
  email?: InputMaybe<Scalars['String']>;
  emailTos?: InputMaybe<EmailToCreateNestedManyWithoutOwnerInput>;
  favoriteStoreItems?: InputMaybe<StoreItemCreateNestedManyWithoutFavoriteByUsersInput>;
  fetchFriends?: InputMaybe<UserFriendCreateNestedManyWithoutOwnerInput>;
  followProfitables?: InputMaybe<ProfitableCreateNestedManyWithoutFollowedByInput>;
  followedBy?: InputMaybe<UserCreateNestedManyWithoutFollowingInput>;
  following?: InputMaybe<UserCreateNestedManyWithoutFollowedByInput>;
  gender?: InputMaybe<Scalars['String']>;
  groupsJoin?: InputMaybe<GroupCreateNestedManyWithoutMembersInput>;
  groupsOwn?: InputMaybe<GroupCreateNestedManyWithoutOwnerInput>;
  headUri?: InputMaybe<Scalars['String']>;
  history?: InputMaybe<OrderDetailHistoryCreateNestedManyWithoutOperatorInput>;
  id?: InputMaybe<Scalars['String']>;
  idStr: Scalars['String'];
  inFriends?: InputMaybe<UserFriendCreateNestedManyWithoutUsersInput>;
  inValidReasonHistories?: InputMaybe<InValidReasonCreateNestedManyWithoutUserInput>;
  lastLogin?: InputMaybe<Scalars['DateTime']>;
  lastTokenUpdated?: InputMaybe<Scalars['DateTime']>;
  likePosts?: InputMaybe<PostCreateNestedManyWithoutLikeUsersInput>;
  manualAddrs?: InputMaybe<ManualAddrCreateNestedManyWithoutOwnerInput>;
  myCoupons?: InputMaybe<CouponEntityCreateNestedManyWithoutHolderInput>;
  name: Scalars['String'];
  notificationReceiver?: InputMaybe<NotificationCreateNestedManyWithoutReceiverInput>;
  notificationSender?: InputMaybe<NotificationCreateNestedManyWithoutSenderInput>;
  order?: InputMaybe<OrderCreateNestedManyWithoutSenderInput>;
  packagesAsReceiver?: InputMaybe<PostPackageCreateNestedManyWithoutReceiversInput>;
  password?: InputMaybe<Scalars['String']>;
  pcoins?: InputMaybe<Scalars['Decimal']>;
  phone?: InputMaybe<Scalars['String']>;
  postPackage?: InputMaybe<PostPackageCreateNestedManyWithoutSenderInput>;
  postsIn?: InputMaybe<PostCreateNestedManyWithoutReceiverInput>;
  postsOut?: InputMaybe<PostCreateNestedManyWithoutSenderInput>;
  profitable?: InputMaybe<ProfitableCreateNestedOneWithoutUserInput>;
  receive_email?: InputMaybe<Scalars['String']>;
  receive_phone?: InputMaybe<Scalars['String']>;
  reportByMe?: InputMaybe<ReportUserCreateNestedManyWithoutReporterInput>;
  reported?: InputMaybe<ReportUserCreateNestedManyWithoutUserInput>;
  reports?: InputMaybe<ReportCreateNestedManyWithoutReporterInput>;
  settingNotification?: InputMaybe<SettingNotificationCreateNestedOneWithoutUserInput>;
  signature?: InputMaybe<SignatureCreateNestedManyWithoutOwnerInput>;
  storePurchaseHistory?: InputMaybe<CartItemCreateNestedManyWithoutCustomerInput>;
  tagsUsed?: InputMaybe<PostTagCreateNestedManyWithoutUserInput>;
  thirdpartyId: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  vAddrMapping?: InputMaybe<VirtualAddrMappingCreateNestedOneWithoutOwnerInput>;
  valid?: InputMaybe<Scalars['Boolean']>;
  website?: InputMaybe<Scalars['String']>;
};

export type UserCreateWithoutSignatureInput = {
  accessToken?: InputMaybe<Scalars['String']>;
  address?: InputMaybe<AddressCreateNestedManyWithoutOwnerInput>;
  attachments?: InputMaybe<AttachmentCreateNestedManyWithoutOwnerInput>;
  authType: UserAuthType;
  bio?: InputMaybe<Scalars['String']>;
  campaignNanhui?: InputMaybe<CampaignNanhuiCreateNestedManyWithoutSubmitterInput>;
  campainZeczec?: InputMaybe<CampainZeczecCreateNestedOneWithoutRegisterUserInput>;
  card?: InputMaybe<CardCreateNestedManyWithoutCreatorInput>;
  coins?: InputMaybe<CoinCreateNestedManyWithoutUserInput>;
  collection?: InputMaybe<CollectionCreateNestedManyWithoutOwnerInput>;
  country?: InputMaybe<Scalars['String']>;
  couponUsages?: InputMaybe<CouponUsageCreateNestedManyWithoutUserInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdStamps?: InputMaybe<StampCreateNestedManyWithoutCreatorInput>;
  detail?: InputMaybe<OrderDetailCreateNestedManyWithoutReceiverInput>;
  deviceToken?: InputMaybe<DeviceTokenCreateNestedManyWithoutUserInput>;
  email?: InputMaybe<Scalars['String']>;
  emailTos?: InputMaybe<EmailToCreateNestedManyWithoutOwnerInput>;
  favoriteStoreItems?: InputMaybe<StoreItemCreateNestedManyWithoutFavoriteByUsersInput>;
  fetchFriends?: InputMaybe<UserFriendCreateNestedManyWithoutOwnerInput>;
  followProfitables?: InputMaybe<ProfitableCreateNestedManyWithoutFollowedByInput>;
  followedBy?: InputMaybe<UserCreateNestedManyWithoutFollowingInput>;
  following?: InputMaybe<UserCreateNestedManyWithoutFollowedByInput>;
  gender?: InputMaybe<Scalars['String']>;
  groupsJoin?: InputMaybe<GroupCreateNestedManyWithoutMembersInput>;
  groupsOwn?: InputMaybe<GroupCreateNestedManyWithoutOwnerInput>;
  headUri?: InputMaybe<Scalars['String']>;
  history?: InputMaybe<OrderDetailHistoryCreateNestedManyWithoutOperatorInput>;
  id?: InputMaybe<Scalars['String']>;
  idStr: Scalars['String'];
  inFriends?: InputMaybe<UserFriendCreateNestedManyWithoutUsersInput>;
  inValidReasonHistories?: InputMaybe<InValidReasonCreateNestedManyWithoutUserInput>;
  lastLogin?: InputMaybe<Scalars['DateTime']>;
  lastTokenUpdated?: InputMaybe<Scalars['DateTime']>;
  likePosts?: InputMaybe<PostCreateNestedManyWithoutLikeUsersInput>;
  manualAddrs?: InputMaybe<ManualAddrCreateNestedManyWithoutOwnerInput>;
  myCoupons?: InputMaybe<CouponEntityCreateNestedManyWithoutHolderInput>;
  name: Scalars['String'];
  notificationReceiver?: InputMaybe<NotificationCreateNestedManyWithoutReceiverInput>;
  notificationSender?: InputMaybe<NotificationCreateNestedManyWithoutSenderInput>;
  order?: InputMaybe<OrderCreateNestedManyWithoutSenderInput>;
  packagesAsReceiver?: InputMaybe<PostPackageCreateNestedManyWithoutReceiversInput>;
  password?: InputMaybe<Scalars['String']>;
  pcoins?: InputMaybe<Scalars['Decimal']>;
  phone?: InputMaybe<Scalars['String']>;
  postPackage?: InputMaybe<PostPackageCreateNestedManyWithoutSenderInput>;
  postsIn?: InputMaybe<PostCreateNestedManyWithoutReceiverInput>;
  postsOut?: InputMaybe<PostCreateNestedManyWithoutSenderInput>;
  profitable?: InputMaybe<ProfitableCreateNestedOneWithoutUserInput>;
  receive_email?: InputMaybe<Scalars['String']>;
  receive_phone?: InputMaybe<Scalars['String']>;
  reportByMe?: InputMaybe<ReportUserCreateNestedManyWithoutReporterInput>;
  reported?: InputMaybe<ReportUserCreateNestedManyWithoutUserInput>;
  reports?: InputMaybe<ReportCreateNestedManyWithoutReporterInput>;
  settingNotification?: InputMaybe<SettingNotificationCreateNestedOneWithoutUserInput>;
  settingPrivacy?: InputMaybe<SettingPrivacyCreateNestedOneWithoutUserInput>;
  storePurchaseHistory?: InputMaybe<CartItemCreateNestedManyWithoutCustomerInput>;
  tagsUsed?: InputMaybe<PostTagCreateNestedManyWithoutUserInput>;
  thirdpartyId: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  vAddrMapping?: InputMaybe<VirtualAddrMappingCreateNestedOneWithoutOwnerInput>;
  valid?: InputMaybe<Scalars['Boolean']>;
  website?: InputMaybe<Scalars['String']>;
};

export type UserCreateWithoutStorePurchaseHistoryInput = {
  accessToken?: InputMaybe<Scalars['String']>;
  address?: InputMaybe<AddressCreateNestedManyWithoutOwnerInput>;
  attachments?: InputMaybe<AttachmentCreateNestedManyWithoutOwnerInput>;
  authType: UserAuthType;
  bio?: InputMaybe<Scalars['String']>;
  campaignNanhui?: InputMaybe<CampaignNanhuiCreateNestedManyWithoutSubmitterInput>;
  campainZeczec?: InputMaybe<CampainZeczecCreateNestedOneWithoutRegisterUserInput>;
  card?: InputMaybe<CardCreateNestedManyWithoutCreatorInput>;
  coins?: InputMaybe<CoinCreateNestedManyWithoutUserInput>;
  collection?: InputMaybe<CollectionCreateNestedManyWithoutOwnerInput>;
  country?: InputMaybe<Scalars['String']>;
  couponUsages?: InputMaybe<CouponUsageCreateNestedManyWithoutUserInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdStamps?: InputMaybe<StampCreateNestedManyWithoutCreatorInput>;
  detail?: InputMaybe<OrderDetailCreateNestedManyWithoutReceiverInput>;
  deviceToken?: InputMaybe<DeviceTokenCreateNestedManyWithoutUserInput>;
  email?: InputMaybe<Scalars['String']>;
  emailTos?: InputMaybe<EmailToCreateNestedManyWithoutOwnerInput>;
  favoriteStoreItems?: InputMaybe<StoreItemCreateNestedManyWithoutFavoriteByUsersInput>;
  fetchFriends?: InputMaybe<UserFriendCreateNestedManyWithoutOwnerInput>;
  followProfitables?: InputMaybe<ProfitableCreateNestedManyWithoutFollowedByInput>;
  followedBy?: InputMaybe<UserCreateNestedManyWithoutFollowingInput>;
  following?: InputMaybe<UserCreateNestedManyWithoutFollowedByInput>;
  gender?: InputMaybe<Scalars['String']>;
  groupsJoin?: InputMaybe<GroupCreateNestedManyWithoutMembersInput>;
  groupsOwn?: InputMaybe<GroupCreateNestedManyWithoutOwnerInput>;
  headUri?: InputMaybe<Scalars['String']>;
  history?: InputMaybe<OrderDetailHistoryCreateNestedManyWithoutOperatorInput>;
  id?: InputMaybe<Scalars['String']>;
  idStr: Scalars['String'];
  inFriends?: InputMaybe<UserFriendCreateNestedManyWithoutUsersInput>;
  inValidReasonHistories?: InputMaybe<InValidReasonCreateNestedManyWithoutUserInput>;
  lastLogin?: InputMaybe<Scalars['DateTime']>;
  lastTokenUpdated?: InputMaybe<Scalars['DateTime']>;
  likePosts?: InputMaybe<PostCreateNestedManyWithoutLikeUsersInput>;
  manualAddrs?: InputMaybe<ManualAddrCreateNestedManyWithoutOwnerInput>;
  myCoupons?: InputMaybe<CouponEntityCreateNestedManyWithoutHolderInput>;
  name: Scalars['String'];
  notificationReceiver?: InputMaybe<NotificationCreateNestedManyWithoutReceiverInput>;
  notificationSender?: InputMaybe<NotificationCreateNestedManyWithoutSenderInput>;
  order?: InputMaybe<OrderCreateNestedManyWithoutSenderInput>;
  packagesAsReceiver?: InputMaybe<PostPackageCreateNestedManyWithoutReceiversInput>;
  password?: InputMaybe<Scalars['String']>;
  pcoins?: InputMaybe<Scalars['Decimal']>;
  phone?: InputMaybe<Scalars['String']>;
  postPackage?: InputMaybe<PostPackageCreateNestedManyWithoutSenderInput>;
  postsIn?: InputMaybe<PostCreateNestedManyWithoutReceiverInput>;
  postsOut?: InputMaybe<PostCreateNestedManyWithoutSenderInput>;
  profitable?: InputMaybe<ProfitableCreateNestedOneWithoutUserInput>;
  receive_email?: InputMaybe<Scalars['String']>;
  receive_phone?: InputMaybe<Scalars['String']>;
  reportByMe?: InputMaybe<ReportUserCreateNestedManyWithoutReporterInput>;
  reported?: InputMaybe<ReportUserCreateNestedManyWithoutUserInput>;
  reports?: InputMaybe<ReportCreateNestedManyWithoutReporterInput>;
  settingNotification?: InputMaybe<SettingNotificationCreateNestedOneWithoutUserInput>;
  settingPrivacy?: InputMaybe<SettingPrivacyCreateNestedOneWithoutUserInput>;
  signature?: InputMaybe<SignatureCreateNestedManyWithoutOwnerInput>;
  tagsUsed?: InputMaybe<PostTagCreateNestedManyWithoutUserInput>;
  thirdpartyId: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  vAddrMapping?: InputMaybe<VirtualAddrMappingCreateNestedOneWithoutOwnerInput>;
  valid?: InputMaybe<Scalars['Boolean']>;
  website?: InputMaybe<Scalars['String']>;
};

export type UserCreateWithoutTagsUsedInput = {
  accessToken?: InputMaybe<Scalars['String']>;
  address?: InputMaybe<AddressCreateNestedManyWithoutOwnerInput>;
  attachments?: InputMaybe<AttachmentCreateNestedManyWithoutOwnerInput>;
  authType: UserAuthType;
  bio?: InputMaybe<Scalars['String']>;
  campaignNanhui?: InputMaybe<CampaignNanhuiCreateNestedManyWithoutSubmitterInput>;
  campainZeczec?: InputMaybe<CampainZeczecCreateNestedOneWithoutRegisterUserInput>;
  card?: InputMaybe<CardCreateNestedManyWithoutCreatorInput>;
  coins?: InputMaybe<CoinCreateNestedManyWithoutUserInput>;
  collection?: InputMaybe<CollectionCreateNestedManyWithoutOwnerInput>;
  country?: InputMaybe<Scalars['String']>;
  couponUsages?: InputMaybe<CouponUsageCreateNestedManyWithoutUserInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdStamps?: InputMaybe<StampCreateNestedManyWithoutCreatorInput>;
  detail?: InputMaybe<OrderDetailCreateNestedManyWithoutReceiverInput>;
  deviceToken?: InputMaybe<DeviceTokenCreateNestedManyWithoutUserInput>;
  email?: InputMaybe<Scalars['String']>;
  emailTos?: InputMaybe<EmailToCreateNestedManyWithoutOwnerInput>;
  favoriteStoreItems?: InputMaybe<StoreItemCreateNestedManyWithoutFavoriteByUsersInput>;
  fetchFriends?: InputMaybe<UserFriendCreateNestedManyWithoutOwnerInput>;
  followProfitables?: InputMaybe<ProfitableCreateNestedManyWithoutFollowedByInput>;
  followedBy?: InputMaybe<UserCreateNestedManyWithoutFollowingInput>;
  following?: InputMaybe<UserCreateNestedManyWithoutFollowedByInput>;
  gender?: InputMaybe<Scalars['String']>;
  groupsJoin?: InputMaybe<GroupCreateNestedManyWithoutMembersInput>;
  groupsOwn?: InputMaybe<GroupCreateNestedManyWithoutOwnerInput>;
  headUri?: InputMaybe<Scalars['String']>;
  history?: InputMaybe<OrderDetailHistoryCreateNestedManyWithoutOperatorInput>;
  id?: InputMaybe<Scalars['String']>;
  idStr: Scalars['String'];
  inFriends?: InputMaybe<UserFriendCreateNestedManyWithoutUsersInput>;
  inValidReasonHistories?: InputMaybe<InValidReasonCreateNestedManyWithoutUserInput>;
  lastLogin?: InputMaybe<Scalars['DateTime']>;
  lastTokenUpdated?: InputMaybe<Scalars['DateTime']>;
  likePosts?: InputMaybe<PostCreateNestedManyWithoutLikeUsersInput>;
  manualAddrs?: InputMaybe<ManualAddrCreateNestedManyWithoutOwnerInput>;
  myCoupons?: InputMaybe<CouponEntityCreateNestedManyWithoutHolderInput>;
  name: Scalars['String'];
  notificationReceiver?: InputMaybe<NotificationCreateNestedManyWithoutReceiverInput>;
  notificationSender?: InputMaybe<NotificationCreateNestedManyWithoutSenderInput>;
  order?: InputMaybe<OrderCreateNestedManyWithoutSenderInput>;
  packagesAsReceiver?: InputMaybe<PostPackageCreateNestedManyWithoutReceiversInput>;
  password?: InputMaybe<Scalars['String']>;
  pcoins?: InputMaybe<Scalars['Decimal']>;
  phone?: InputMaybe<Scalars['String']>;
  postPackage?: InputMaybe<PostPackageCreateNestedManyWithoutSenderInput>;
  postsIn?: InputMaybe<PostCreateNestedManyWithoutReceiverInput>;
  postsOut?: InputMaybe<PostCreateNestedManyWithoutSenderInput>;
  profitable?: InputMaybe<ProfitableCreateNestedOneWithoutUserInput>;
  receive_email?: InputMaybe<Scalars['String']>;
  receive_phone?: InputMaybe<Scalars['String']>;
  reportByMe?: InputMaybe<ReportUserCreateNestedManyWithoutReporterInput>;
  reported?: InputMaybe<ReportUserCreateNestedManyWithoutUserInput>;
  reports?: InputMaybe<ReportCreateNestedManyWithoutReporterInput>;
  settingNotification?: InputMaybe<SettingNotificationCreateNestedOneWithoutUserInput>;
  settingPrivacy?: InputMaybe<SettingPrivacyCreateNestedOneWithoutUserInput>;
  signature?: InputMaybe<SignatureCreateNestedManyWithoutOwnerInput>;
  storePurchaseHistory?: InputMaybe<CartItemCreateNestedManyWithoutCustomerInput>;
  thirdpartyId: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  vAddrMapping?: InputMaybe<VirtualAddrMappingCreateNestedOneWithoutOwnerInput>;
  valid?: InputMaybe<Scalars['Boolean']>;
  website?: InputMaybe<Scalars['String']>;
};

export type UserCreateWithoutVAddrMappingInput = {
  accessToken?: InputMaybe<Scalars['String']>;
  address?: InputMaybe<AddressCreateNestedManyWithoutOwnerInput>;
  attachments?: InputMaybe<AttachmentCreateNestedManyWithoutOwnerInput>;
  authType: UserAuthType;
  bio?: InputMaybe<Scalars['String']>;
  campaignNanhui?: InputMaybe<CampaignNanhuiCreateNestedManyWithoutSubmitterInput>;
  campainZeczec?: InputMaybe<CampainZeczecCreateNestedOneWithoutRegisterUserInput>;
  card?: InputMaybe<CardCreateNestedManyWithoutCreatorInput>;
  coins?: InputMaybe<CoinCreateNestedManyWithoutUserInput>;
  collection?: InputMaybe<CollectionCreateNestedManyWithoutOwnerInput>;
  country?: InputMaybe<Scalars['String']>;
  couponUsages?: InputMaybe<CouponUsageCreateNestedManyWithoutUserInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdStamps?: InputMaybe<StampCreateNestedManyWithoutCreatorInput>;
  detail?: InputMaybe<OrderDetailCreateNestedManyWithoutReceiverInput>;
  deviceToken?: InputMaybe<DeviceTokenCreateNestedManyWithoutUserInput>;
  email?: InputMaybe<Scalars['String']>;
  emailTos?: InputMaybe<EmailToCreateNestedManyWithoutOwnerInput>;
  favoriteStoreItems?: InputMaybe<StoreItemCreateNestedManyWithoutFavoriteByUsersInput>;
  fetchFriends?: InputMaybe<UserFriendCreateNestedManyWithoutOwnerInput>;
  followProfitables?: InputMaybe<ProfitableCreateNestedManyWithoutFollowedByInput>;
  followedBy?: InputMaybe<UserCreateNestedManyWithoutFollowingInput>;
  following?: InputMaybe<UserCreateNestedManyWithoutFollowedByInput>;
  gender?: InputMaybe<Scalars['String']>;
  groupsJoin?: InputMaybe<GroupCreateNestedManyWithoutMembersInput>;
  groupsOwn?: InputMaybe<GroupCreateNestedManyWithoutOwnerInput>;
  headUri?: InputMaybe<Scalars['String']>;
  history?: InputMaybe<OrderDetailHistoryCreateNestedManyWithoutOperatorInput>;
  id?: InputMaybe<Scalars['String']>;
  idStr: Scalars['String'];
  inFriends?: InputMaybe<UserFriendCreateNestedManyWithoutUsersInput>;
  inValidReasonHistories?: InputMaybe<InValidReasonCreateNestedManyWithoutUserInput>;
  lastLogin?: InputMaybe<Scalars['DateTime']>;
  lastTokenUpdated?: InputMaybe<Scalars['DateTime']>;
  likePosts?: InputMaybe<PostCreateNestedManyWithoutLikeUsersInput>;
  manualAddrs?: InputMaybe<ManualAddrCreateNestedManyWithoutOwnerInput>;
  myCoupons?: InputMaybe<CouponEntityCreateNestedManyWithoutHolderInput>;
  name: Scalars['String'];
  notificationReceiver?: InputMaybe<NotificationCreateNestedManyWithoutReceiverInput>;
  notificationSender?: InputMaybe<NotificationCreateNestedManyWithoutSenderInput>;
  order?: InputMaybe<OrderCreateNestedManyWithoutSenderInput>;
  packagesAsReceiver?: InputMaybe<PostPackageCreateNestedManyWithoutReceiversInput>;
  password?: InputMaybe<Scalars['String']>;
  pcoins?: InputMaybe<Scalars['Decimal']>;
  phone?: InputMaybe<Scalars['String']>;
  postPackage?: InputMaybe<PostPackageCreateNestedManyWithoutSenderInput>;
  postsIn?: InputMaybe<PostCreateNestedManyWithoutReceiverInput>;
  postsOut?: InputMaybe<PostCreateNestedManyWithoutSenderInput>;
  profitable?: InputMaybe<ProfitableCreateNestedOneWithoutUserInput>;
  receive_email?: InputMaybe<Scalars['String']>;
  receive_phone?: InputMaybe<Scalars['String']>;
  reportByMe?: InputMaybe<ReportUserCreateNestedManyWithoutReporterInput>;
  reported?: InputMaybe<ReportUserCreateNestedManyWithoutUserInput>;
  reports?: InputMaybe<ReportCreateNestedManyWithoutReporterInput>;
  settingNotification?: InputMaybe<SettingNotificationCreateNestedOneWithoutUserInput>;
  settingPrivacy?: InputMaybe<SettingPrivacyCreateNestedOneWithoutUserInput>;
  signature?: InputMaybe<SignatureCreateNestedManyWithoutOwnerInput>;
  storePurchaseHistory?: InputMaybe<CartItemCreateNestedManyWithoutCustomerInput>;
  tagsUsed?: InputMaybe<PostTagCreateNestedManyWithoutUserInput>;
  thirdpartyId: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  valid?: InputMaybe<Scalars['Boolean']>;
  website?: InputMaybe<Scalars['String']>;
};

export type UserFriend = {
  __typename?: 'UserFriend';
  createdAt: Scalars['DateTime'];
  friendId: Scalars['String'];
  owner: User;
  ownerId: Scalars['String'];
  status: FriendStatus;
  updatedAt: Scalars['DateTime'];
  users: User;
};

export type UserFriendCountAggregate = {
  __typename?: 'UserFriendCountAggregate';
  _all: Scalars['Int'];
  createdAt: Scalars['Int'];
  friendId: Scalars['Int'];
  ownerId: Scalars['Int'];
  status: Scalars['Int'];
  updatedAt: Scalars['Int'];
};

export type UserFriendCountOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  friendId?: InputMaybe<SortOrder>;
  ownerId?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type UserFriendCreateInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  owner: UserCreateNestedOneWithoutFriendsInput;
  status: FriendStatus;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  users: UserCreateNestedOneWithoutInFriendsInput;
};

export type UserFriendCreateManyInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  friendId: Scalars['String'];
  ownerId: Scalars['String'];
  status: FriendStatus;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type UserFriendCreateManyOwnerInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  friendId: Scalars['String'];
  status: FriendStatus;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type UserFriendCreateManyOwnerInputEnvelope = {
  data: Array<UserFriendCreateManyOwnerInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type UserFriendCreateManyUsersInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  ownerId: Scalars['String'];
  status: FriendStatus;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type UserFriendCreateManyUsersInputEnvelope = {
  data: Array<UserFriendCreateManyUsersInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type UserFriendCreateNestedManyWithoutOwnerInput = {
  connect?: InputMaybe<Array<UserFriendWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UserFriendCreateOrConnectWithoutOwnerInput>>;
  create?: InputMaybe<Array<UserFriendCreateWithoutOwnerInput>>;
  createMany?: InputMaybe<UserFriendCreateManyOwnerInputEnvelope>;
};

export type UserFriendCreateNestedManyWithoutUsersInput = {
  connect?: InputMaybe<Array<UserFriendWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UserFriendCreateOrConnectWithoutUsersInput>>;
  create?: InputMaybe<Array<UserFriendCreateWithoutUsersInput>>;
  createMany?: InputMaybe<UserFriendCreateManyUsersInputEnvelope>;
};

export type UserFriendCreateOrConnectWithoutOwnerInput = {
  create: UserFriendCreateWithoutOwnerInput;
  where: UserFriendWhereUniqueInput;
};

export type UserFriendCreateOrConnectWithoutUsersInput = {
  create: UserFriendCreateWithoutUsersInput;
  where: UserFriendWhereUniqueInput;
};

export type UserFriendCreateWithoutOwnerInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  status: FriendStatus;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  users: UserCreateNestedOneWithoutInFriendsInput;
};

export type UserFriendCreateWithoutUsersInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  owner: UserCreateNestedOneWithoutFriendsInput;
  status: FriendStatus;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type UserFriendGroupBy = {
  __typename?: 'UserFriendGroupBy';
  _count?: Maybe<UserFriendCountAggregate>;
  _max?: Maybe<UserFriendMaxAggregate>;
  _min?: Maybe<UserFriendMinAggregate>;
  createdAt: Scalars['DateTime'];
  friendId: Scalars['String'];
  ownerId: Scalars['String'];
  status: FriendStatus;
  updatedAt: Scalars['DateTime'];
};

export type UserFriendListRelationFilter = {
  every?: InputMaybe<UserFriendWhereInput>;
  none?: InputMaybe<UserFriendWhereInput>;
  some?: InputMaybe<UserFriendWhereInput>;
};

export type UserFriendMaxAggregate = {
  __typename?: 'UserFriendMaxAggregate';
  createdAt?: Maybe<Scalars['DateTime']>;
  friendId?: Maybe<Scalars['String']>;
  ownerId?: Maybe<Scalars['String']>;
  status?: Maybe<FriendStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type UserFriendMaxOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  friendId?: InputMaybe<SortOrder>;
  ownerId?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type UserFriendMinAggregate = {
  __typename?: 'UserFriendMinAggregate';
  createdAt?: Maybe<Scalars['DateTime']>;
  friendId?: Maybe<Scalars['String']>;
  ownerId?: Maybe<Scalars['String']>;
  status?: Maybe<FriendStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type UserFriendMinOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  friendId?: InputMaybe<SortOrder>;
  ownerId?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type UserFriendOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type UserFriendOrderByWithAggregationInput = {
  _count?: InputMaybe<UserFriendCountOrderByAggregateInput>;
  _max?: InputMaybe<UserFriendMaxOrderByAggregateInput>;
  _min?: InputMaybe<UserFriendMinOrderByAggregateInput>;
  createdAt?: InputMaybe<SortOrder>;
  friendId?: InputMaybe<SortOrder>;
  ownerId?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type UserFriendOrderByWithRelationInput = {
  createdAt?: InputMaybe<SortOrder>;
  friendId?: InputMaybe<SortOrder>;
  owner?: InputMaybe<UserOrderByWithRelationInput>;
  ownerId?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  users?: InputMaybe<UserOrderByWithRelationInput>;
};

export type UserFriendOwnerIdFriendIdCompoundUniqueInput = {
  friendId: Scalars['String'];
  ownerId: Scalars['String'];
};

export enum UserFriendScalarFieldEnum {
  CreatedAt = 'createdAt',
  FriendId = 'friendId',
  OwnerId = 'ownerId',
  Status = 'status',
  UpdatedAt = 'updatedAt'
}

export type UserFriendScalarWhereInput = {
  AND?: InputMaybe<Array<UserFriendScalarWhereInput>>;
  NOT?: InputMaybe<Array<UserFriendScalarWhereInput>>;
  OR?: InputMaybe<Array<UserFriendScalarWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  friendId?: InputMaybe<StringFilter>;
  ownerId?: InputMaybe<StringFilter>;
  status?: InputMaybe<EnumFriendStatusFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type UserFriendScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<UserFriendScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<UserFriendScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<UserFriendScalarWhereWithAggregatesInput>>;
  createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  friendId?: InputMaybe<StringWithAggregatesFilter>;
  ownerId?: InputMaybe<StringWithAggregatesFilter>;
  status?: InputMaybe<EnumFriendStatusWithAggregatesFilter>;
  updatedAt?: InputMaybe<DateTimeWithAggregatesFilter>;
};

export type UserFriendUpdateInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  owner?: InputMaybe<UserUpdateOneRequiredWithoutFriendsNestedInput>;
  status?: InputMaybe<EnumFriendStatusFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  users?: InputMaybe<UserUpdateOneRequiredWithoutInFriendsNestedInput>;
};

export type UserFriendUpdateManyMutationInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumFriendStatusFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type UserFriendUpdateManyWithWhereWithoutOwnerInput = {
  data: UserFriendUpdateManyMutationInput;
  where: UserFriendScalarWhereInput;
};

export type UserFriendUpdateManyWithWhereWithoutUsersInput = {
  data: UserFriendUpdateManyMutationInput;
  where: UserFriendScalarWhereInput;
};

export type UserFriendUpdateManyWithoutOwnerNestedInput = {
  connect?: InputMaybe<Array<UserFriendWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UserFriendCreateOrConnectWithoutOwnerInput>>;
  create?: InputMaybe<Array<UserFriendCreateWithoutOwnerInput>>;
  createMany?: InputMaybe<UserFriendCreateManyOwnerInputEnvelope>;
  delete?: InputMaybe<Array<UserFriendWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<UserFriendScalarWhereInput>>;
  disconnect?: InputMaybe<Array<UserFriendWhereUniqueInput>>;
  set?: InputMaybe<Array<UserFriendWhereUniqueInput>>;
  update?: InputMaybe<Array<UserFriendUpdateWithWhereUniqueWithoutOwnerInput>>;
  updateMany?: InputMaybe<Array<UserFriendUpdateManyWithWhereWithoutOwnerInput>>;
  upsert?: InputMaybe<Array<UserFriendUpsertWithWhereUniqueWithoutOwnerInput>>;
};

export type UserFriendUpdateManyWithoutUsersNestedInput = {
  connect?: InputMaybe<Array<UserFriendWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UserFriendCreateOrConnectWithoutUsersInput>>;
  create?: InputMaybe<Array<UserFriendCreateWithoutUsersInput>>;
  createMany?: InputMaybe<UserFriendCreateManyUsersInputEnvelope>;
  delete?: InputMaybe<Array<UserFriendWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<UserFriendScalarWhereInput>>;
  disconnect?: InputMaybe<Array<UserFriendWhereUniqueInput>>;
  set?: InputMaybe<Array<UserFriendWhereUniqueInput>>;
  update?: InputMaybe<Array<UserFriendUpdateWithWhereUniqueWithoutUsersInput>>;
  updateMany?: InputMaybe<Array<UserFriendUpdateManyWithWhereWithoutUsersInput>>;
  upsert?: InputMaybe<Array<UserFriendUpsertWithWhereUniqueWithoutUsersInput>>;
};

export type UserFriendUpdateWithWhereUniqueWithoutOwnerInput = {
  data: UserFriendUpdateWithoutOwnerInput;
  where: UserFriendWhereUniqueInput;
};

export type UserFriendUpdateWithWhereUniqueWithoutUsersInput = {
  data: UserFriendUpdateWithoutUsersInput;
  where: UserFriendWhereUniqueInput;
};

export type UserFriendUpdateWithoutOwnerInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumFriendStatusFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  users?: InputMaybe<UserUpdateOneRequiredWithoutInFriendsNestedInput>;
};

export type UserFriendUpdateWithoutUsersInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  owner?: InputMaybe<UserUpdateOneRequiredWithoutFriendsNestedInput>;
  status?: InputMaybe<EnumFriendStatusFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type UserFriendUpsertWithWhereUniqueWithoutOwnerInput = {
  create: UserFriendCreateWithoutOwnerInput;
  update: UserFriendUpdateWithoutOwnerInput;
  where: UserFriendWhereUniqueInput;
};

export type UserFriendUpsertWithWhereUniqueWithoutUsersInput = {
  create: UserFriendCreateWithoutUsersInput;
  update: UserFriendUpdateWithoutUsersInput;
  where: UserFriendWhereUniqueInput;
};

export type UserFriendWhereInput = {
  AND?: InputMaybe<Array<UserFriendWhereInput>>;
  NOT?: InputMaybe<Array<UserFriendWhereInput>>;
  OR?: InputMaybe<Array<UserFriendWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  friendId?: InputMaybe<StringFilter>;
  owner?: InputMaybe<UserRelationFilter>;
  ownerId?: InputMaybe<StringFilter>;
  status?: InputMaybe<EnumFriendStatusFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  users?: InputMaybe<UserRelationFilter>;
};

export type UserFriendWhereUniqueInput = {
  ownerId_friendId?: InputMaybe<UserFriendOwnerIdFriendIdCompoundUniqueInput>;
};

export type UserGroupBy = {
  __typename?: 'UserGroupBy';
  _avg?: Maybe<UserAvgAggregate>;
  _count?: Maybe<UserCountAggregate>;
  _max?: Maybe<UserMaxAggregate>;
  _min?: Maybe<UserMinAggregate>;
  _sum?: Maybe<UserSumAggregate>;
  accessToken?: Maybe<Scalars['String']>;
  authType: UserAuthType;
  bio?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  email?: Maybe<Scalars['String']>;
  gender?: Maybe<Scalars['String']>;
  headUri?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  idStr: Scalars['String'];
  lastLogin?: Maybe<Scalars['DateTime']>;
  lastTokenUpdated?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  password: Scalars['String'];
  pcoins: Scalars['Decimal'];
  phone?: Maybe<Scalars['String']>;
  receive_email?: Maybe<Scalars['String']>;
  receive_phone?: Maybe<Scalars['String']>;
  thirdpartyId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  valid: Scalars['Boolean'];
  website?: Maybe<Scalars['String']>;
};

export type UserListRelationFilter = {
  every?: InputMaybe<UserWhereInput>;
  none?: InputMaybe<UserWhereInput>;
  some?: InputMaybe<UserWhereInput>;
};

export type UserMaxAggregate = {
  __typename?: 'UserMaxAggregate';
  accessToken?: Maybe<Scalars['String']>;
  authType?: Maybe<UserAuthType>;
  bio?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  gender?: Maybe<Scalars['String']>;
  headUri?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  idStr?: Maybe<Scalars['String']>;
  lastLogin?: Maybe<Scalars['DateTime']>;
  lastTokenUpdated?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  pcoins?: Maybe<Scalars['Decimal']>;
  phone?: Maybe<Scalars['String']>;
  receive_email?: Maybe<Scalars['String']>;
  receive_phone?: Maybe<Scalars['String']>;
  thirdpartyId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  valid?: Maybe<Scalars['Boolean']>;
  website?: Maybe<Scalars['String']>;
};

export type UserMaxOrderByAggregateInput = {
  accessToken?: InputMaybe<SortOrder>;
  authType?: InputMaybe<SortOrder>;
  bio?: InputMaybe<SortOrder>;
  country?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  email?: InputMaybe<SortOrder>;
  gender?: InputMaybe<SortOrder>;
  headUri?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  idStr?: InputMaybe<SortOrder>;
  lastLogin?: InputMaybe<SortOrder>;
  lastTokenUpdated?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  password?: InputMaybe<SortOrder>;
  pcoins?: InputMaybe<SortOrder>;
  phone?: InputMaybe<SortOrder>;
  receive_email?: InputMaybe<SortOrder>;
  receive_phone?: InputMaybe<SortOrder>;
  thirdpartyId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  valid?: InputMaybe<SortOrder>;
  website?: InputMaybe<SortOrder>;
};

export type UserMinAggregate = {
  __typename?: 'UserMinAggregate';
  accessToken?: Maybe<Scalars['String']>;
  authType?: Maybe<UserAuthType>;
  bio?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  gender?: Maybe<Scalars['String']>;
  headUri?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  idStr?: Maybe<Scalars['String']>;
  lastLogin?: Maybe<Scalars['DateTime']>;
  lastTokenUpdated?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  pcoins?: Maybe<Scalars['Decimal']>;
  phone?: Maybe<Scalars['String']>;
  receive_email?: Maybe<Scalars['String']>;
  receive_phone?: Maybe<Scalars['String']>;
  thirdpartyId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  valid?: Maybe<Scalars['Boolean']>;
  website?: Maybe<Scalars['String']>;
};

export type UserMinOrderByAggregateInput = {
  accessToken?: InputMaybe<SortOrder>;
  authType?: InputMaybe<SortOrder>;
  bio?: InputMaybe<SortOrder>;
  country?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  email?: InputMaybe<SortOrder>;
  gender?: InputMaybe<SortOrder>;
  headUri?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  idStr?: InputMaybe<SortOrder>;
  lastLogin?: InputMaybe<SortOrder>;
  lastTokenUpdated?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  password?: InputMaybe<SortOrder>;
  pcoins?: InputMaybe<SortOrder>;
  phone?: InputMaybe<SortOrder>;
  receive_email?: InputMaybe<SortOrder>;
  receive_phone?: InputMaybe<SortOrder>;
  thirdpartyId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  valid?: InputMaybe<SortOrder>;
  website?: InputMaybe<SortOrder>;
};

export type UserOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type UserOrderByWithAggregationInput = {
  _avg?: InputMaybe<UserAvgOrderByAggregateInput>;
  _count?: InputMaybe<UserCountOrderByAggregateInput>;
  _max?: InputMaybe<UserMaxOrderByAggregateInput>;
  _min?: InputMaybe<UserMinOrderByAggregateInput>;
  _sum?: InputMaybe<UserSumOrderByAggregateInput>;
  accessToken?: InputMaybe<SortOrder>;
  authType?: InputMaybe<SortOrder>;
  bio?: InputMaybe<SortOrder>;
  country?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  email?: InputMaybe<SortOrder>;
  gender?: InputMaybe<SortOrder>;
  headUri?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  idStr?: InputMaybe<SortOrder>;
  lastLogin?: InputMaybe<SortOrder>;
  lastTokenUpdated?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  password?: InputMaybe<SortOrder>;
  pcoins?: InputMaybe<SortOrder>;
  phone?: InputMaybe<SortOrder>;
  receive_email?: InputMaybe<SortOrder>;
  receive_phone?: InputMaybe<SortOrder>;
  thirdpartyId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  valid?: InputMaybe<SortOrder>;
  website?: InputMaybe<SortOrder>;
};

export type UserOrderByWithRelationInput = {
  accessToken?: InputMaybe<SortOrder>;
  address?: InputMaybe<AddressOrderByRelationAggregateInput>;
  attachments?: InputMaybe<AttachmentOrderByRelationAggregateInput>;
  authType?: InputMaybe<SortOrder>;
  bio?: InputMaybe<SortOrder>;
  campaignNanhui?: InputMaybe<CampaignNanhuiOrderByRelationAggregateInput>;
  campainZeczec?: InputMaybe<CampainZeczecOrderByWithRelationInput>;
  card?: InputMaybe<CardOrderByRelationAggregateInput>;
  coins?: InputMaybe<CoinOrderByRelationAggregateInput>;
  collection?: InputMaybe<CollectionOrderByRelationAggregateInput>;
  country?: InputMaybe<SortOrder>;
  couponUsages?: InputMaybe<CouponUsageOrderByRelationAggregateInput>;
  createdAt?: InputMaybe<SortOrder>;
  createdStamps?: InputMaybe<StampOrderByRelationAggregateInput>;
  detail?: InputMaybe<OrderDetailOrderByRelationAggregateInput>;
  deviceToken?: InputMaybe<DeviceTokenOrderByRelationAggregateInput>;
  email?: InputMaybe<SortOrder>;
  emailTos?: InputMaybe<EmailToOrderByRelationAggregateInput>;
  favoriteStoreItems?: InputMaybe<StoreItemOrderByRelationAggregateInput>;
  fetchFriends?: InputMaybe<UserFriendOrderByRelationAggregateInput>;
  followProfitables?: InputMaybe<ProfitableOrderByRelationAggregateInput>;
  followedBy?: InputMaybe<UserOrderByRelationAggregateInput>;
  following?: InputMaybe<UserOrderByRelationAggregateInput>;
  gender?: InputMaybe<SortOrder>;
  groupsJoin?: InputMaybe<GroupOrderByRelationAggregateInput>;
  groupsOwn?: InputMaybe<GroupOrderByRelationAggregateInput>;
  headUri?: InputMaybe<SortOrder>;
  history?: InputMaybe<OrderDetailHistoryOrderByRelationAggregateInput>;
  id?: InputMaybe<SortOrder>;
  idStr?: InputMaybe<SortOrder>;
  inFriends?: InputMaybe<UserFriendOrderByRelationAggregateInput>;
  inValidReasonHistories?: InputMaybe<InValidReasonOrderByRelationAggregateInput>;
  lastLogin?: InputMaybe<SortOrder>;
  lastTokenUpdated?: InputMaybe<SortOrder>;
  likePosts?: InputMaybe<PostOrderByRelationAggregateInput>;
  manualAddrs?: InputMaybe<ManualAddrOrderByRelationAggregateInput>;
  myCoupons?: InputMaybe<CouponEntityOrderByRelationAggregateInput>;
  name?: InputMaybe<SortOrder>;
  notificationReceiver?: InputMaybe<NotificationOrderByRelationAggregateInput>;
  notificationSender?: InputMaybe<NotificationOrderByRelationAggregateInput>;
  order?: InputMaybe<OrderOrderByRelationAggregateInput>;
  packagesAsReceiver?: InputMaybe<PostPackageOrderByRelationAggregateInput>;
  password?: InputMaybe<SortOrder>;
  pcoins?: InputMaybe<SortOrder>;
  phone?: InputMaybe<SortOrder>;
  postPackage?: InputMaybe<PostPackageOrderByRelationAggregateInput>;
  postsIn?: InputMaybe<PostOrderByRelationAggregateInput>;
  postsOut?: InputMaybe<PostOrderByRelationAggregateInput>;
  profitable?: InputMaybe<ProfitableOrderByWithRelationInput>;
  receive_email?: InputMaybe<SortOrder>;
  receive_phone?: InputMaybe<SortOrder>;
  reportByMe?: InputMaybe<ReportUserOrderByRelationAggregateInput>;
  reported?: InputMaybe<ReportUserOrderByRelationAggregateInput>;
  reports?: InputMaybe<ReportOrderByRelationAggregateInput>;
  settingNotification?: InputMaybe<SettingNotificationOrderByWithRelationInput>;
  settingPrivacy?: InputMaybe<SettingPrivacyOrderByWithRelationInput>;
  signature?: InputMaybe<SignatureOrderByRelationAggregateInput>;
  storePurchaseHistory?: InputMaybe<CartItemOrderByRelationAggregateInput>;
  tagsUsed?: InputMaybe<PostTagOrderByRelationAggregateInput>;
  thirdpartyId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  vAddrMapping?: InputMaybe<VirtualAddrMappingOrderByWithRelationInput>;
  valid?: InputMaybe<SortOrder>;
  website?: InputMaybe<SortOrder>;
};

export type UserRelationFilter = {
  is?: InputMaybe<UserWhereInput>;
  isNot?: InputMaybe<UserWhereInput>;
};

export enum UserScalarFieldEnum {
  AccessToken = 'accessToken',
  AuthType = 'authType',
  Bio = 'bio',
  Country = 'country',
  CreatedAt = 'createdAt',
  Email = 'email',
  Gender = 'gender',
  HeadUri = 'headUri',
  Id = 'id',
  IdStr = 'idStr',
  LastLogin = 'lastLogin',
  LastTokenUpdated = 'lastTokenUpdated',
  Name = 'name',
  Password = 'password',
  Pcoins = 'pcoins',
  Phone = 'phone',
  ReceiveEmail = 'receive_email',
  ReceivePhone = 'receive_phone',
  ThirdpartyId = 'thirdpartyId',
  UpdatedAt = 'updatedAt',
  Valid = 'valid',
  Website = 'website'
}

export type UserScalarWhereInput = {
  AND?: InputMaybe<Array<UserScalarWhereInput>>;
  NOT?: InputMaybe<Array<UserScalarWhereInput>>;
  OR?: InputMaybe<Array<UserScalarWhereInput>>;
  accessToken?: InputMaybe<StringNullableFilter>;
  authType?: InputMaybe<EnumUserAuthTypeFilter>;
  bio?: InputMaybe<StringNullableFilter>;
  country?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  email?: InputMaybe<StringNullableFilter>;
  gender?: InputMaybe<StringNullableFilter>;
  headUri?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<StringFilter>;
  idStr?: InputMaybe<StringFilter>;
  lastLogin?: InputMaybe<DateTimeNullableFilter>;
  lastTokenUpdated?: InputMaybe<DateTimeNullableFilter>;
  name?: InputMaybe<StringFilter>;
  password?: InputMaybe<StringFilter>;
  pcoins?: InputMaybe<DecimalFilter>;
  phone?: InputMaybe<StringNullableFilter>;
  receive_email?: InputMaybe<StringNullableFilter>;
  receive_phone?: InputMaybe<StringNullableFilter>;
  thirdpartyId?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  valid?: InputMaybe<BoolFilter>;
  website?: InputMaybe<StringNullableFilter>;
};

export type UserScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<UserScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<UserScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<UserScalarWhereWithAggregatesInput>>;
  accessToken?: InputMaybe<StringNullableWithAggregatesFilter>;
  authType?: InputMaybe<EnumUserAuthTypeWithAggregatesFilter>;
  bio?: InputMaybe<StringNullableWithAggregatesFilter>;
  country?: InputMaybe<StringNullableWithAggregatesFilter>;
  createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  email?: InputMaybe<StringNullableWithAggregatesFilter>;
  gender?: InputMaybe<StringNullableWithAggregatesFilter>;
  headUri?: InputMaybe<StringNullableWithAggregatesFilter>;
  id?: InputMaybe<StringWithAggregatesFilter>;
  idStr?: InputMaybe<StringWithAggregatesFilter>;
  lastLogin?: InputMaybe<DateTimeNullableWithAggregatesFilter>;
  lastTokenUpdated?: InputMaybe<DateTimeNullableWithAggregatesFilter>;
  name?: InputMaybe<StringWithAggregatesFilter>;
  password?: InputMaybe<StringWithAggregatesFilter>;
  pcoins?: InputMaybe<DecimalWithAggregatesFilter>;
  phone?: InputMaybe<StringNullableWithAggregatesFilter>;
  receive_email?: InputMaybe<StringNullableWithAggregatesFilter>;
  receive_phone?: InputMaybe<StringNullableWithAggregatesFilter>;
  thirdpartyId?: InputMaybe<StringWithAggregatesFilter>;
  updatedAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  valid?: InputMaybe<BoolWithAggregatesFilter>;
  website?: InputMaybe<StringNullableWithAggregatesFilter>;
};

export type UserSumAggregate = {
  __typename?: 'UserSumAggregate';
  pcoins?: Maybe<Scalars['Decimal']>;
};

export type UserSumOrderByAggregateInput = {
  pcoins?: InputMaybe<SortOrder>;
};

export type UserUpdateInput = {
  accessToken?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  address?: InputMaybe<AddressUpdateManyWithoutOwnerNestedInput>;
  attachments?: InputMaybe<AttachmentUpdateManyWithoutOwnerNestedInput>;
  authType?: InputMaybe<EnumUserAuthTypeFieldUpdateOperationsInput>;
  bio?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  campaignNanhui?: InputMaybe<CampaignNanhuiUpdateManyWithoutSubmitterNestedInput>;
  campainZeczec?: InputMaybe<CampainZeczecUpdateOneWithoutRegisterUserNestedInput>;
  card?: InputMaybe<CardUpdateManyWithoutCreatorNestedInput>;
  coins?: InputMaybe<CoinUpdateManyWithoutUserNestedInput>;
  collection?: InputMaybe<CollectionUpdateManyWithoutOwnerNestedInput>;
  country?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  couponUsages?: InputMaybe<CouponUsageUpdateManyWithoutUserNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  createdStamps?: InputMaybe<StampUpdateManyWithoutCreatorNestedInput>;
  detail?: InputMaybe<OrderDetailUpdateManyWithoutReceiverNestedInput>;
  deviceToken?: InputMaybe<DeviceTokenUpdateManyWithoutUserNestedInput>;
  email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  emailTos?: InputMaybe<EmailToUpdateManyWithoutOwnerNestedInput>;
  favoriteStoreItems?: InputMaybe<StoreItemUpdateManyWithoutFavoriteByUsersNestedInput>;
  fetchFriends?: InputMaybe<UserFriendUpdateManyWithoutOwnerNestedInput>;
  followProfitables?: InputMaybe<ProfitableUpdateManyWithoutFollowedByNestedInput>;
  followedBy?: InputMaybe<UserUpdateManyWithoutFollowingNestedInput>;
  following?: InputMaybe<UserUpdateManyWithoutFollowedByNestedInput>;
  gender?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  groupsJoin?: InputMaybe<GroupUpdateManyWithoutMembersNestedInput>;
  groupsOwn?: InputMaybe<GroupUpdateManyWithoutOwnerNestedInput>;
  headUri?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  history?: InputMaybe<OrderDetailHistoryUpdateManyWithoutOperatorNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  idStr?: InputMaybe<StringFieldUpdateOperationsInput>;
  inFriends?: InputMaybe<UserFriendUpdateManyWithoutUsersNestedInput>;
  inValidReasonHistories?: InputMaybe<InValidReasonUpdateManyWithoutUserNestedInput>;
  lastLogin?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  lastTokenUpdated?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  likePosts?: InputMaybe<PostUpdateManyWithoutLikeUsersNestedInput>;
  manualAddrs?: InputMaybe<ManualAddrUpdateManyWithoutOwnerNestedInput>;
  myCoupons?: InputMaybe<CouponEntityUpdateManyWithoutHolderNestedInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  notificationReceiver?: InputMaybe<NotificationUpdateManyWithoutReceiverNestedInput>;
  notificationSender?: InputMaybe<NotificationUpdateManyWithoutSenderNestedInput>;
  order?: InputMaybe<OrderUpdateManyWithoutSenderNestedInput>;
  packagesAsReceiver?: InputMaybe<PostPackageUpdateManyWithoutReceiversNestedInput>;
  password?: InputMaybe<StringFieldUpdateOperationsInput>;
  pcoins?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  phone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  postPackage?: InputMaybe<PostPackageUpdateManyWithoutSenderNestedInput>;
  postsIn?: InputMaybe<PostUpdateManyWithoutReceiverNestedInput>;
  postsOut?: InputMaybe<PostUpdateManyWithoutSenderNestedInput>;
  profitable?: InputMaybe<ProfitableUpdateOneWithoutUserNestedInput>;
  receive_email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  receive_phone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  reportByMe?: InputMaybe<ReportUserUpdateManyWithoutReporterNestedInput>;
  reported?: InputMaybe<ReportUserUpdateManyWithoutUserNestedInput>;
  reports?: InputMaybe<ReportUpdateManyWithoutReporterNestedInput>;
  settingNotification?: InputMaybe<SettingNotificationUpdateOneWithoutUserNestedInput>;
  settingPrivacy?: InputMaybe<SettingPrivacyUpdateOneWithoutUserNestedInput>;
  signature?: InputMaybe<SignatureUpdateManyWithoutOwnerNestedInput>;
  storePurchaseHistory?: InputMaybe<CartItemUpdateManyWithoutCustomerNestedInput>;
  tagsUsed?: InputMaybe<PostTagUpdateManyWithoutUserNestedInput>;
  thirdpartyId?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  vAddrMapping?: InputMaybe<VirtualAddrMappingUpdateOneWithoutOwnerNestedInput>;
  valid?: InputMaybe<BoolFieldUpdateOperationsInput>;
  website?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type UserUpdateManyMutationInput = {
  accessToken?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  authType?: InputMaybe<EnumUserAuthTypeFieldUpdateOperationsInput>;
  bio?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  country?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  gender?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  headUri?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  idStr?: InputMaybe<StringFieldUpdateOperationsInput>;
  lastLogin?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  lastTokenUpdated?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  password?: InputMaybe<StringFieldUpdateOperationsInput>;
  pcoins?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  phone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  receive_email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  receive_phone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  thirdpartyId?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  valid?: InputMaybe<BoolFieldUpdateOperationsInput>;
  website?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type UserUpdateManyWithWhereWithoutFavoriteStoreItemsInput = {
  data: UserUpdateManyMutationInput;
  where: UserScalarWhereInput;
};

export type UserUpdateManyWithWhereWithoutFollowProfitablesInput = {
  data: UserUpdateManyMutationInput;
  where: UserScalarWhereInput;
};

export type UserUpdateManyWithWhereWithoutFollowedByInput = {
  data: UserUpdateManyMutationInput;
  where: UserScalarWhereInput;
};

export type UserUpdateManyWithWhereWithoutFollowingInput = {
  data: UserUpdateManyMutationInput;
  where: UserScalarWhereInput;
};

export type UserUpdateManyWithWhereWithoutGroupsJoinInput = {
  data: UserUpdateManyMutationInput;
  where: UserScalarWhereInput;
};

export type UserUpdateManyWithWhereWithoutLikePostsInput = {
  data: UserUpdateManyMutationInput;
  where: UserScalarWhereInput;
};

export type UserUpdateManyWithWhereWithoutPackagesAsReceiverInput = {
  data: UserUpdateManyMutationInput;
  where: UserScalarWhereInput;
};

export type UserUpdateManyWithoutFavoriteStoreItemsNestedInput = {
  connect?: InputMaybe<Array<UserWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UserCreateOrConnectWithoutFavoriteStoreItemsInput>>;
  create?: InputMaybe<Array<UserCreateWithoutFavoriteStoreItemsInput>>;
  delete?: InputMaybe<Array<UserWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<UserScalarWhereInput>>;
  disconnect?: InputMaybe<Array<UserWhereUniqueInput>>;
  set?: InputMaybe<Array<UserWhereUniqueInput>>;
  update?: InputMaybe<Array<UserUpdateWithWhereUniqueWithoutFavoriteStoreItemsInput>>;
  updateMany?: InputMaybe<Array<UserUpdateManyWithWhereWithoutFavoriteStoreItemsInput>>;
  upsert?: InputMaybe<Array<UserUpsertWithWhereUniqueWithoutFavoriteStoreItemsInput>>;
};

export type UserUpdateManyWithoutFollowProfitablesNestedInput = {
  connect?: InputMaybe<Array<UserWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UserCreateOrConnectWithoutFollowProfitablesInput>>;
  create?: InputMaybe<Array<UserCreateWithoutFollowProfitablesInput>>;
  delete?: InputMaybe<Array<UserWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<UserScalarWhereInput>>;
  disconnect?: InputMaybe<Array<UserWhereUniqueInput>>;
  set?: InputMaybe<Array<UserWhereUniqueInput>>;
  update?: InputMaybe<Array<UserUpdateWithWhereUniqueWithoutFollowProfitablesInput>>;
  updateMany?: InputMaybe<Array<UserUpdateManyWithWhereWithoutFollowProfitablesInput>>;
  upsert?: InputMaybe<Array<UserUpsertWithWhereUniqueWithoutFollowProfitablesInput>>;
};

export type UserUpdateManyWithoutFollowedByNestedInput = {
  connect?: InputMaybe<Array<UserWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UserCreateOrConnectWithoutFollowedByInput>>;
  create?: InputMaybe<Array<UserCreateWithoutFollowedByInput>>;
  delete?: InputMaybe<Array<UserWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<UserScalarWhereInput>>;
  disconnect?: InputMaybe<Array<UserWhereUniqueInput>>;
  set?: InputMaybe<Array<UserWhereUniqueInput>>;
  update?: InputMaybe<Array<UserUpdateWithWhereUniqueWithoutFollowedByInput>>;
  updateMany?: InputMaybe<Array<UserUpdateManyWithWhereWithoutFollowedByInput>>;
  upsert?: InputMaybe<Array<UserUpsertWithWhereUniqueWithoutFollowedByInput>>;
};

export type UserUpdateManyWithoutFollowingNestedInput = {
  connect?: InputMaybe<Array<UserWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UserCreateOrConnectWithoutFollowingInput>>;
  create?: InputMaybe<Array<UserCreateWithoutFollowingInput>>;
  delete?: InputMaybe<Array<UserWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<UserScalarWhereInput>>;
  disconnect?: InputMaybe<Array<UserWhereUniqueInput>>;
  set?: InputMaybe<Array<UserWhereUniqueInput>>;
  update?: InputMaybe<Array<UserUpdateWithWhereUniqueWithoutFollowingInput>>;
  updateMany?: InputMaybe<Array<UserUpdateManyWithWhereWithoutFollowingInput>>;
  upsert?: InputMaybe<Array<UserUpsertWithWhereUniqueWithoutFollowingInput>>;
};

export type UserUpdateManyWithoutGroupsJoinNestedInput = {
  connect?: InputMaybe<Array<UserWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UserCreateOrConnectWithoutGroupsJoinInput>>;
  create?: InputMaybe<Array<UserCreateWithoutGroupsJoinInput>>;
  delete?: InputMaybe<Array<UserWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<UserScalarWhereInput>>;
  disconnect?: InputMaybe<Array<UserWhereUniqueInput>>;
  set?: InputMaybe<Array<UserWhereUniqueInput>>;
  update?: InputMaybe<Array<UserUpdateWithWhereUniqueWithoutGroupsJoinInput>>;
  updateMany?: InputMaybe<Array<UserUpdateManyWithWhereWithoutGroupsJoinInput>>;
  upsert?: InputMaybe<Array<UserUpsertWithWhereUniqueWithoutGroupsJoinInput>>;
};

export type UserUpdateManyWithoutLikePostsNestedInput = {
  connect?: InputMaybe<Array<UserWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UserCreateOrConnectWithoutLikePostsInput>>;
  create?: InputMaybe<Array<UserCreateWithoutLikePostsInput>>;
  delete?: InputMaybe<Array<UserWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<UserScalarWhereInput>>;
  disconnect?: InputMaybe<Array<UserWhereUniqueInput>>;
  set?: InputMaybe<Array<UserWhereUniqueInput>>;
  update?: InputMaybe<Array<UserUpdateWithWhereUniqueWithoutLikePostsInput>>;
  updateMany?: InputMaybe<Array<UserUpdateManyWithWhereWithoutLikePostsInput>>;
  upsert?: InputMaybe<Array<UserUpsertWithWhereUniqueWithoutLikePostsInput>>;
};

export type UserUpdateManyWithoutPackagesAsReceiverNestedInput = {
  connect?: InputMaybe<Array<UserWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UserCreateOrConnectWithoutPackagesAsReceiverInput>>;
  create?: InputMaybe<Array<UserCreateWithoutPackagesAsReceiverInput>>;
  delete?: InputMaybe<Array<UserWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<UserScalarWhereInput>>;
  disconnect?: InputMaybe<Array<UserWhereUniqueInput>>;
  set?: InputMaybe<Array<UserWhereUniqueInput>>;
  update?: InputMaybe<Array<UserUpdateWithWhereUniqueWithoutPackagesAsReceiverInput>>;
  updateMany?: InputMaybe<Array<UserUpdateManyWithWhereWithoutPackagesAsReceiverInput>>;
  upsert?: InputMaybe<Array<UserUpsertWithWhereUniqueWithoutPackagesAsReceiverInput>>;
};

export type UserUpdateOneRequiredWithoutCardNestedInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutCardInput>;
  create?: InputMaybe<UserCreateWithoutCardInput>;
  update?: InputMaybe<UserUpdateWithoutCardInput>;
  upsert?: InputMaybe<UserUpsertWithoutCardInput>;
};

export type UserUpdateOneRequiredWithoutCoinsNestedInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutCoinsInput>;
  create?: InputMaybe<UserCreateWithoutCoinsInput>;
  update?: InputMaybe<UserUpdateWithoutCoinsInput>;
  upsert?: InputMaybe<UserUpsertWithoutCoinsInput>;
};

export type UserUpdateOneRequiredWithoutCollectionNestedInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutCollectionInput>;
  create?: InputMaybe<UserCreateWithoutCollectionInput>;
  update?: InputMaybe<UserUpdateWithoutCollectionInput>;
  upsert?: InputMaybe<UserUpsertWithoutCollectionInput>;
};

export type UserUpdateOneRequiredWithoutCouponUsagesNestedInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutCouponUsagesInput>;
  create?: InputMaybe<UserCreateWithoutCouponUsagesInput>;
  update?: InputMaybe<UserUpdateWithoutCouponUsagesInput>;
  upsert?: InputMaybe<UserUpsertWithoutCouponUsagesInput>;
};

export type UserUpdateOneRequiredWithoutCreatedStampsNestedInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutCreatedStampsInput>;
  create?: InputMaybe<UserCreateWithoutCreatedStampsInput>;
  update?: InputMaybe<UserUpdateWithoutCreatedStampsInput>;
  upsert?: InputMaybe<UserUpsertWithoutCreatedStampsInput>;
};

export type UserUpdateOneRequiredWithoutDeviceTokenNestedInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutDeviceTokenInput>;
  create?: InputMaybe<UserCreateWithoutDeviceTokenInput>;
  update?: InputMaybe<UserUpdateWithoutDeviceTokenInput>;
  upsert?: InputMaybe<UserUpsertWithoutDeviceTokenInput>;
};

export type UserUpdateOneRequiredWithoutEmailTosNestedInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutEmailTosInput>;
  create?: InputMaybe<UserCreateWithoutEmailTosInput>;
  update?: InputMaybe<UserUpdateWithoutEmailTosInput>;
  upsert?: InputMaybe<UserUpsertWithoutEmailTosInput>;
};

export type UserUpdateOneRequiredWithoutFriendsNestedInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutFriendsInput>;
  create?: InputMaybe<UserCreateWithoutFriendsInput>;
  update?: InputMaybe<UserUpdateWithoutFriendsInput>;
  upsert?: InputMaybe<UserUpsertWithoutFriendsInput>;
};

export type UserUpdateOneRequiredWithoutGroupsOwnNestedInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutGroupsOwnInput>;
  create?: InputMaybe<UserCreateWithoutGroupsOwnInput>;
  update?: InputMaybe<UserUpdateWithoutGroupsOwnInput>;
  upsert?: InputMaybe<UserUpsertWithoutGroupsOwnInput>;
};

export type UserUpdateOneRequiredWithoutHistoryNestedInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutHistoryInput>;
  create?: InputMaybe<UserCreateWithoutHistoryInput>;
  update?: InputMaybe<UserUpdateWithoutHistoryInput>;
  upsert?: InputMaybe<UserUpsertWithoutHistoryInput>;
};

export type UserUpdateOneRequiredWithoutInFriendsNestedInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutInFriendsInput>;
  create?: InputMaybe<UserCreateWithoutInFriendsInput>;
  update?: InputMaybe<UserUpdateWithoutInFriendsInput>;
  upsert?: InputMaybe<UserUpsertWithoutInFriendsInput>;
};

export type UserUpdateOneRequiredWithoutManualAddrsNestedInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutManualAddrsInput>;
  create?: InputMaybe<UserCreateWithoutManualAddrsInput>;
  update?: InputMaybe<UserUpdateWithoutManualAddrsInput>;
  upsert?: InputMaybe<UserUpsertWithoutManualAddrsInput>;
};

export type UserUpdateOneRequiredWithoutNotificationReceiverNestedInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutNotificationReceiverInput>;
  create?: InputMaybe<UserCreateWithoutNotificationReceiverInput>;
  update?: InputMaybe<UserUpdateWithoutNotificationReceiverInput>;
  upsert?: InputMaybe<UserUpsertWithoutNotificationReceiverInput>;
};

export type UserUpdateOneRequiredWithoutNotificationSenderNestedInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutNotificationSenderInput>;
  create?: InputMaybe<UserCreateWithoutNotificationSenderInput>;
  update?: InputMaybe<UserUpdateWithoutNotificationSenderInput>;
  upsert?: InputMaybe<UserUpsertWithoutNotificationSenderInput>;
};

export type UserUpdateOneRequiredWithoutOrderNestedInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutOrderInput>;
  create?: InputMaybe<UserCreateWithoutOrderInput>;
  update?: InputMaybe<UserUpdateWithoutOrderInput>;
  upsert?: InputMaybe<UserUpsertWithoutOrderInput>;
};

export type UserUpdateOneRequiredWithoutPostPackageNestedInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutPostPackageInput>;
  create?: InputMaybe<UserCreateWithoutPostPackageInput>;
  update?: InputMaybe<UserUpdateWithoutPostPackageInput>;
  upsert?: InputMaybe<UserUpsertWithoutPostPackageInput>;
};

export type UserUpdateOneRequiredWithoutPostsOutNestedInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutPostsOutInput>;
  create?: InputMaybe<UserCreateWithoutPostsOutInput>;
  update?: InputMaybe<UserUpdateWithoutPostsOutInput>;
  upsert?: InputMaybe<UserUpsertWithoutPostsOutInput>;
};

export type UserUpdateOneRequiredWithoutProfitableNestedInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutProfitableInput>;
  create?: InputMaybe<UserCreateWithoutProfitableInput>;
  update?: InputMaybe<UserUpdateWithoutProfitableInput>;
  upsert?: InputMaybe<UserUpsertWithoutProfitableInput>;
};

export type UserUpdateOneRequiredWithoutReportedNestedInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutReportedInput>;
  create?: InputMaybe<UserCreateWithoutReportedInput>;
  update?: InputMaybe<UserUpdateWithoutReportedInput>;
  upsert?: InputMaybe<UserUpsertWithoutReportedInput>;
};

export type UserUpdateOneRequiredWithoutSettingNotificationNestedInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutSettingNotificationInput>;
  create?: InputMaybe<UserCreateWithoutSettingNotificationInput>;
  update?: InputMaybe<UserUpdateWithoutSettingNotificationInput>;
  upsert?: InputMaybe<UserUpsertWithoutSettingNotificationInput>;
};

export type UserUpdateOneRequiredWithoutSettingPrivacyNestedInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutSettingPrivacyInput>;
  create?: InputMaybe<UserCreateWithoutSettingPrivacyInput>;
  update?: InputMaybe<UserUpdateWithoutSettingPrivacyInput>;
  upsert?: InputMaybe<UserUpsertWithoutSettingPrivacyInput>;
};

export type UserUpdateOneRequiredWithoutSignatureNestedInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutSignatureInput>;
  create?: InputMaybe<UserCreateWithoutSignatureInput>;
  update?: InputMaybe<UserUpdateWithoutSignatureInput>;
  upsert?: InputMaybe<UserUpsertWithoutSignatureInput>;
};

export type UserUpdateOneRequiredWithoutStorePurchaseHistoryNestedInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutStorePurchaseHistoryInput>;
  create?: InputMaybe<UserCreateWithoutStorePurchaseHistoryInput>;
  update?: InputMaybe<UserUpdateWithoutStorePurchaseHistoryInput>;
  upsert?: InputMaybe<UserUpsertWithoutStorePurchaseHistoryInput>;
};

export type UserUpdateOneRequiredWithoutTagsUsedNestedInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutTagsUsedInput>;
  create?: InputMaybe<UserCreateWithoutTagsUsedInput>;
  update?: InputMaybe<UserUpdateWithoutTagsUsedInput>;
  upsert?: InputMaybe<UserUpsertWithoutTagsUsedInput>;
};

export type UserUpdateOneWithoutAddressNestedInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutAddressInput>;
  create?: InputMaybe<UserCreateWithoutAddressInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<UserUpdateWithoutAddressInput>;
  upsert?: InputMaybe<UserUpsertWithoutAddressInput>;
};

export type UserUpdateOneWithoutAttachmentsNestedInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutAttachmentsInput>;
  create?: InputMaybe<UserCreateWithoutAttachmentsInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<UserUpdateWithoutAttachmentsInput>;
  upsert?: InputMaybe<UserUpsertWithoutAttachmentsInput>;
};

export type UserUpdateOneWithoutCampaignNanhuiNestedInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutCampaignNanhuiInput>;
  create?: InputMaybe<UserCreateWithoutCampaignNanhuiInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<UserUpdateWithoutCampaignNanhuiInput>;
  upsert?: InputMaybe<UserUpsertWithoutCampaignNanhuiInput>;
};

export type UserUpdateOneWithoutCampainZeczecNestedInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutCampainZeczecInput>;
  create?: InputMaybe<UserCreateWithoutCampainZeczecInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<UserUpdateWithoutCampainZeczecInput>;
  upsert?: InputMaybe<UserUpsertWithoutCampainZeczecInput>;
};

export type UserUpdateOneWithoutDetailNestedInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutDetailInput>;
  create?: InputMaybe<UserCreateWithoutDetailInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<UserUpdateWithoutDetailInput>;
  upsert?: InputMaybe<UserUpsertWithoutDetailInput>;
};

export type UserUpdateOneWithoutInValidReasonHistoriesNestedInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutInValidReasonHistoriesInput>;
  create?: InputMaybe<UserCreateWithoutInValidReasonHistoriesInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<UserUpdateWithoutInValidReasonHistoriesInput>;
  upsert?: InputMaybe<UserUpsertWithoutInValidReasonHistoriesInput>;
};

export type UserUpdateOneWithoutMyCouponsNestedInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutMyCouponsInput>;
  create?: InputMaybe<UserCreateWithoutMyCouponsInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<UserUpdateWithoutMyCouponsInput>;
  upsert?: InputMaybe<UserUpsertWithoutMyCouponsInput>;
};

export type UserUpdateOneWithoutPostsInNestedInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutPostsInInput>;
  create?: InputMaybe<UserCreateWithoutPostsInInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<UserUpdateWithoutPostsInInput>;
  upsert?: InputMaybe<UserUpsertWithoutPostsInInput>;
};

export type UserUpdateOneWithoutReportByMeNestedInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutReportByMeInput>;
  create?: InputMaybe<UserCreateWithoutReportByMeInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<UserUpdateWithoutReportByMeInput>;
  upsert?: InputMaybe<UserUpsertWithoutReportByMeInput>;
};

export type UserUpdateOneWithoutReportsNestedInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutReportsInput>;
  create?: InputMaybe<UserCreateWithoutReportsInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<UserUpdateWithoutReportsInput>;
  upsert?: InputMaybe<UserUpsertWithoutReportsInput>;
};

export type UserUpdateOneWithoutVAddrMappingNestedInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutVAddrMappingInput>;
  create?: InputMaybe<UserCreateWithoutVAddrMappingInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<UserUpdateWithoutVAddrMappingInput>;
  upsert?: InputMaybe<UserUpsertWithoutVAddrMappingInput>;
};

export type UserUpdateWithWhereUniqueWithoutFavoriteStoreItemsInput = {
  data: UserUpdateWithoutFavoriteStoreItemsInput;
  where: UserWhereUniqueInput;
};

export type UserUpdateWithWhereUniqueWithoutFollowProfitablesInput = {
  data: UserUpdateWithoutFollowProfitablesInput;
  where: UserWhereUniqueInput;
};

export type UserUpdateWithWhereUniqueWithoutFollowedByInput = {
  data: UserUpdateWithoutFollowedByInput;
  where: UserWhereUniqueInput;
};

export type UserUpdateWithWhereUniqueWithoutFollowingInput = {
  data: UserUpdateWithoutFollowingInput;
  where: UserWhereUniqueInput;
};

export type UserUpdateWithWhereUniqueWithoutGroupsJoinInput = {
  data: UserUpdateWithoutGroupsJoinInput;
  where: UserWhereUniqueInput;
};

export type UserUpdateWithWhereUniqueWithoutLikePostsInput = {
  data: UserUpdateWithoutLikePostsInput;
  where: UserWhereUniqueInput;
};

export type UserUpdateWithWhereUniqueWithoutPackagesAsReceiverInput = {
  data: UserUpdateWithoutPackagesAsReceiverInput;
  where: UserWhereUniqueInput;
};

export type UserUpdateWithoutAddressInput = {
  accessToken?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  attachments?: InputMaybe<AttachmentUpdateManyWithoutOwnerNestedInput>;
  authType?: InputMaybe<EnumUserAuthTypeFieldUpdateOperationsInput>;
  bio?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  campaignNanhui?: InputMaybe<CampaignNanhuiUpdateManyWithoutSubmitterNestedInput>;
  campainZeczec?: InputMaybe<CampainZeczecUpdateOneWithoutRegisterUserNestedInput>;
  card?: InputMaybe<CardUpdateManyWithoutCreatorNestedInput>;
  coins?: InputMaybe<CoinUpdateManyWithoutUserNestedInput>;
  collection?: InputMaybe<CollectionUpdateManyWithoutOwnerNestedInput>;
  country?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  couponUsages?: InputMaybe<CouponUsageUpdateManyWithoutUserNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  createdStamps?: InputMaybe<StampUpdateManyWithoutCreatorNestedInput>;
  detail?: InputMaybe<OrderDetailUpdateManyWithoutReceiverNestedInput>;
  deviceToken?: InputMaybe<DeviceTokenUpdateManyWithoutUserNestedInput>;
  email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  emailTos?: InputMaybe<EmailToUpdateManyWithoutOwnerNestedInput>;
  favoriteStoreItems?: InputMaybe<StoreItemUpdateManyWithoutFavoriteByUsersNestedInput>;
  fetchFriends?: InputMaybe<UserFriendUpdateManyWithoutOwnerNestedInput>;
  followProfitables?: InputMaybe<ProfitableUpdateManyWithoutFollowedByNestedInput>;
  followedBy?: InputMaybe<UserUpdateManyWithoutFollowingNestedInput>;
  following?: InputMaybe<UserUpdateManyWithoutFollowedByNestedInput>;
  gender?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  groupsJoin?: InputMaybe<GroupUpdateManyWithoutMembersNestedInput>;
  groupsOwn?: InputMaybe<GroupUpdateManyWithoutOwnerNestedInput>;
  headUri?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  history?: InputMaybe<OrderDetailHistoryUpdateManyWithoutOperatorNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  idStr?: InputMaybe<StringFieldUpdateOperationsInput>;
  inFriends?: InputMaybe<UserFriendUpdateManyWithoutUsersNestedInput>;
  inValidReasonHistories?: InputMaybe<InValidReasonUpdateManyWithoutUserNestedInput>;
  lastLogin?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  lastTokenUpdated?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  likePosts?: InputMaybe<PostUpdateManyWithoutLikeUsersNestedInput>;
  manualAddrs?: InputMaybe<ManualAddrUpdateManyWithoutOwnerNestedInput>;
  myCoupons?: InputMaybe<CouponEntityUpdateManyWithoutHolderNestedInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  notificationReceiver?: InputMaybe<NotificationUpdateManyWithoutReceiverNestedInput>;
  notificationSender?: InputMaybe<NotificationUpdateManyWithoutSenderNestedInput>;
  order?: InputMaybe<OrderUpdateManyWithoutSenderNestedInput>;
  packagesAsReceiver?: InputMaybe<PostPackageUpdateManyWithoutReceiversNestedInput>;
  password?: InputMaybe<StringFieldUpdateOperationsInput>;
  pcoins?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  phone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  postPackage?: InputMaybe<PostPackageUpdateManyWithoutSenderNestedInput>;
  postsIn?: InputMaybe<PostUpdateManyWithoutReceiverNestedInput>;
  postsOut?: InputMaybe<PostUpdateManyWithoutSenderNestedInput>;
  profitable?: InputMaybe<ProfitableUpdateOneWithoutUserNestedInput>;
  receive_email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  receive_phone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  reportByMe?: InputMaybe<ReportUserUpdateManyWithoutReporterNestedInput>;
  reported?: InputMaybe<ReportUserUpdateManyWithoutUserNestedInput>;
  reports?: InputMaybe<ReportUpdateManyWithoutReporterNestedInput>;
  settingNotification?: InputMaybe<SettingNotificationUpdateOneWithoutUserNestedInput>;
  settingPrivacy?: InputMaybe<SettingPrivacyUpdateOneWithoutUserNestedInput>;
  signature?: InputMaybe<SignatureUpdateManyWithoutOwnerNestedInput>;
  storePurchaseHistory?: InputMaybe<CartItemUpdateManyWithoutCustomerNestedInput>;
  tagsUsed?: InputMaybe<PostTagUpdateManyWithoutUserNestedInput>;
  thirdpartyId?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  vAddrMapping?: InputMaybe<VirtualAddrMappingUpdateOneWithoutOwnerNestedInput>;
  valid?: InputMaybe<BoolFieldUpdateOperationsInput>;
  website?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type UserUpdateWithoutAttachmentsInput = {
  accessToken?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  address?: InputMaybe<AddressUpdateManyWithoutOwnerNestedInput>;
  authType?: InputMaybe<EnumUserAuthTypeFieldUpdateOperationsInput>;
  bio?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  campaignNanhui?: InputMaybe<CampaignNanhuiUpdateManyWithoutSubmitterNestedInput>;
  campainZeczec?: InputMaybe<CampainZeczecUpdateOneWithoutRegisterUserNestedInput>;
  card?: InputMaybe<CardUpdateManyWithoutCreatorNestedInput>;
  coins?: InputMaybe<CoinUpdateManyWithoutUserNestedInput>;
  collection?: InputMaybe<CollectionUpdateManyWithoutOwnerNestedInput>;
  country?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  couponUsages?: InputMaybe<CouponUsageUpdateManyWithoutUserNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  createdStamps?: InputMaybe<StampUpdateManyWithoutCreatorNestedInput>;
  detail?: InputMaybe<OrderDetailUpdateManyWithoutReceiverNestedInput>;
  deviceToken?: InputMaybe<DeviceTokenUpdateManyWithoutUserNestedInput>;
  email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  emailTos?: InputMaybe<EmailToUpdateManyWithoutOwnerNestedInput>;
  favoriteStoreItems?: InputMaybe<StoreItemUpdateManyWithoutFavoriteByUsersNestedInput>;
  fetchFriends?: InputMaybe<UserFriendUpdateManyWithoutOwnerNestedInput>;
  followProfitables?: InputMaybe<ProfitableUpdateManyWithoutFollowedByNestedInput>;
  followedBy?: InputMaybe<UserUpdateManyWithoutFollowingNestedInput>;
  following?: InputMaybe<UserUpdateManyWithoutFollowedByNestedInput>;
  gender?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  groupsJoin?: InputMaybe<GroupUpdateManyWithoutMembersNestedInput>;
  groupsOwn?: InputMaybe<GroupUpdateManyWithoutOwnerNestedInput>;
  headUri?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  history?: InputMaybe<OrderDetailHistoryUpdateManyWithoutOperatorNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  idStr?: InputMaybe<StringFieldUpdateOperationsInput>;
  inFriends?: InputMaybe<UserFriendUpdateManyWithoutUsersNestedInput>;
  inValidReasonHistories?: InputMaybe<InValidReasonUpdateManyWithoutUserNestedInput>;
  lastLogin?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  lastTokenUpdated?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  likePosts?: InputMaybe<PostUpdateManyWithoutLikeUsersNestedInput>;
  manualAddrs?: InputMaybe<ManualAddrUpdateManyWithoutOwnerNestedInput>;
  myCoupons?: InputMaybe<CouponEntityUpdateManyWithoutHolderNestedInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  notificationReceiver?: InputMaybe<NotificationUpdateManyWithoutReceiverNestedInput>;
  notificationSender?: InputMaybe<NotificationUpdateManyWithoutSenderNestedInput>;
  order?: InputMaybe<OrderUpdateManyWithoutSenderNestedInput>;
  packagesAsReceiver?: InputMaybe<PostPackageUpdateManyWithoutReceiversNestedInput>;
  password?: InputMaybe<StringFieldUpdateOperationsInput>;
  pcoins?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  phone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  postPackage?: InputMaybe<PostPackageUpdateManyWithoutSenderNestedInput>;
  postsIn?: InputMaybe<PostUpdateManyWithoutReceiverNestedInput>;
  postsOut?: InputMaybe<PostUpdateManyWithoutSenderNestedInput>;
  profitable?: InputMaybe<ProfitableUpdateOneWithoutUserNestedInput>;
  receive_email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  receive_phone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  reportByMe?: InputMaybe<ReportUserUpdateManyWithoutReporterNestedInput>;
  reported?: InputMaybe<ReportUserUpdateManyWithoutUserNestedInput>;
  reports?: InputMaybe<ReportUpdateManyWithoutReporterNestedInput>;
  settingNotification?: InputMaybe<SettingNotificationUpdateOneWithoutUserNestedInput>;
  settingPrivacy?: InputMaybe<SettingPrivacyUpdateOneWithoutUserNestedInput>;
  signature?: InputMaybe<SignatureUpdateManyWithoutOwnerNestedInput>;
  storePurchaseHistory?: InputMaybe<CartItemUpdateManyWithoutCustomerNestedInput>;
  tagsUsed?: InputMaybe<PostTagUpdateManyWithoutUserNestedInput>;
  thirdpartyId?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  vAddrMapping?: InputMaybe<VirtualAddrMappingUpdateOneWithoutOwnerNestedInput>;
  valid?: InputMaybe<BoolFieldUpdateOperationsInput>;
  website?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type UserUpdateWithoutCampaignNanhuiInput = {
  accessToken?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  address?: InputMaybe<AddressUpdateManyWithoutOwnerNestedInput>;
  attachments?: InputMaybe<AttachmentUpdateManyWithoutOwnerNestedInput>;
  authType?: InputMaybe<EnumUserAuthTypeFieldUpdateOperationsInput>;
  bio?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  campainZeczec?: InputMaybe<CampainZeczecUpdateOneWithoutRegisterUserNestedInput>;
  card?: InputMaybe<CardUpdateManyWithoutCreatorNestedInput>;
  coins?: InputMaybe<CoinUpdateManyWithoutUserNestedInput>;
  collection?: InputMaybe<CollectionUpdateManyWithoutOwnerNestedInput>;
  country?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  couponUsages?: InputMaybe<CouponUsageUpdateManyWithoutUserNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  createdStamps?: InputMaybe<StampUpdateManyWithoutCreatorNestedInput>;
  detail?: InputMaybe<OrderDetailUpdateManyWithoutReceiverNestedInput>;
  deviceToken?: InputMaybe<DeviceTokenUpdateManyWithoutUserNestedInput>;
  email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  emailTos?: InputMaybe<EmailToUpdateManyWithoutOwnerNestedInput>;
  favoriteStoreItems?: InputMaybe<StoreItemUpdateManyWithoutFavoriteByUsersNestedInput>;
  fetchFriends?: InputMaybe<UserFriendUpdateManyWithoutOwnerNestedInput>;
  followProfitables?: InputMaybe<ProfitableUpdateManyWithoutFollowedByNestedInput>;
  followedBy?: InputMaybe<UserUpdateManyWithoutFollowingNestedInput>;
  following?: InputMaybe<UserUpdateManyWithoutFollowedByNestedInput>;
  gender?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  groupsJoin?: InputMaybe<GroupUpdateManyWithoutMembersNestedInput>;
  groupsOwn?: InputMaybe<GroupUpdateManyWithoutOwnerNestedInput>;
  headUri?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  history?: InputMaybe<OrderDetailHistoryUpdateManyWithoutOperatorNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  idStr?: InputMaybe<StringFieldUpdateOperationsInput>;
  inFriends?: InputMaybe<UserFriendUpdateManyWithoutUsersNestedInput>;
  inValidReasonHistories?: InputMaybe<InValidReasonUpdateManyWithoutUserNestedInput>;
  lastLogin?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  lastTokenUpdated?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  likePosts?: InputMaybe<PostUpdateManyWithoutLikeUsersNestedInput>;
  manualAddrs?: InputMaybe<ManualAddrUpdateManyWithoutOwnerNestedInput>;
  myCoupons?: InputMaybe<CouponEntityUpdateManyWithoutHolderNestedInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  notificationReceiver?: InputMaybe<NotificationUpdateManyWithoutReceiverNestedInput>;
  notificationSender?: InputMaybe<NotificationUpdateManyWithoutSenderNestedInput>;
  order?: InputMaybe<OrderUpdateManyWithoutSenderNestedInput>;
  packagesAsReceiver?: InputMaybe<PostPackageUpdateManyWithoutReceiversNestedInput>;
  password?: InputMaybe<StringFieldUpdateOperationsInput>;
  pcoins?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  phone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  postPackage?: InputMaybe<PostPackageUpdateManyWithoutSenderNestedInput>;
  postsIn?: InputMaybe<PostUpdateManyWithoutReceiverNestedInput>;
  postsOut?: InputMaybe<PostUpdateManyWithoutSenderNestedInput>;
  profitable?: InputMaybe<ProfitableUpdateOneWithoutUserNestedInput>;
  receive_email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  receive_phone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  reportByMe?: InputMaybe<ReportUserUpdateManyWithoutReporterNestedInput>;
  reported?: InputMaybe<ReportUserUpdateManyWithoutUserNestedInput>;
  reports?: InputMaybe<ReportUpdateManyWithoutReporterNestedInput>;
  settingNotification?: InputMaybe<SettingNotificationUpdateOneWithoutUserNestedInput>;
  settingPrivacy?: InputMaybe<SettingPrivacyUpdateOneWithoutUserNestedInput>;
  signature?: InputMaybe<SignatureUpdateManyWithoutOwnerNestedInput>;
  storePurchaseHistory?: InputMaybe<CartItemUpdateManyWithoutCustomerNestedInput>;
  tagsUsed?: InputMaybe<PostTagUpdateManyWithoutUserNestedInput>;
  thirdpartyId?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  vAddrMapping?: InputMaybe<VirtualAddrMappingUpdateOneWithoutOwnerNestedInput>;
  valid?: InputMaybe<BoolFieldUpdateOperationsInput>;
  website?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type UserUpdateWithoutCampainZeczecInput = {
  accessToken?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  address?: InputMaybe<AddressUpdateManyWithoutOwnerNestedInput>;
  attachments?: InputMaybe<AttachmentUpdateManyWithoutOwnerNestedInput>;
  authType?: InputMaybe<EnumUserAuthTypeFieldUpdateOperationsInput>;
  bio?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  campaignNanhui?: InputMaybe<CampaignNanhuiUpdateManyWithoutSubmitterNestedInput>;
  card?: InputMaybe<CardUpdateManyWithoutCreatorNestedInput>;
  coins?: InputMaybe<CoinUpdateManyWithoutUserNestedInput>;
  collection?: InputMaybe<CollectionUpdateManyWithoutOwnerNestedInput>;
  country?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  couponUsages?: InputMaybe<CouponUsageUpdateManyWithoutUserNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  createdStamps?: InputMaybe<StampUpdateManyWithoutCreatorNestedInput>;
  detail?: InputMaybe<OrderDetailUpdateManyWithoutReceiverNestedInput>;
  deviceToken?: InputMaybe<DeviceTokenUpdateManyWithoutUserNestedInput>;
  email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  emailTos?: InputMaybe<EmailToUpdateManyWithoutOwnerNestedInput>;
  favoriteStoreItems?: InputMaybe<StoreItemUpdateManyWithoutFavoriteByUsersNestedInput>;
  fetchFriends?: InputMaybe<UserFriendUpdateManyWithoutOwnerNestedInput>;
  followProfitables?: InputMaybe<ProfitableUpdateManyWithoutFollowedByNestedInput>;
  followedBy?: InputMaybe<UserUpdateManyWithoutFollowingNestedInput>;
  following?: InputMaybe<UserUpdateManyWithoutFollowedByNestedInput>;
  gender?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  groupsJoin?: InputMaybe<GroupUpdateManyWithoutMembersNestedInput>;
  groupsOwn?: InputMaybe<GroupUpdateManyWithoutOwnerNestedInput>;
  headUri?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  history?: InputMaybe<OrderDetailHistoryUpdateManyWithoutOperatorNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  idStr?: InputMaybe<StringFieldUpdateOperationsInput>;
  inFriends?: InputMaybe<UserFriendUpdateManyWithoutUsersNestedInput>;
  inValidReasonHistories?: InputMaybe<InValidReasonUpdateManyWithoutUserNestedInput>;
  lastLogin?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  lastTokenUpdated?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  likePosts?: InputMaybe<PostUpdateManyWithoutLikeUsersNestedInput>;
  manualAddrs?: InputMaybe<ManualAddrUpdateManyWithoutOwnerNestedInput>;
  myCoupons?: InputMaybe<CouponEntityUpdateManyWithoutHolderNestedInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  notificationReceiver?: InputMaybe<NotificationUpdateManyWithoutReceiverNestedInput>;
  notificationSender?: InputMaybe<NotificationUpdateManyWithoutSenderNestedInput>;
  order?: InputMaybe<OrderUpdateManyWithoutSenderNestedInput>;
  packagesAsReceiver?: InputMaybe<PostPackageUpdateManyWithoutReceiversNestedInput>;
  password?: InputMaybe<StringFieldUpdateOperationsInput>;
  pcoins?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  phone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  postPackage?: InputMaybe<PostPackageUpdateManyWithoutSenderNestedInput>;
  postsIn?: InputMaybe<PostUpdateManyWithoutReceiverNestedInput>;
  postsOut?: InputMaybe<PostUpdateManyWithoutSenderNestedInput>;
  profitable?: InputMaybe<ProfitableUpdateOneWithoutUserNestedInput>;
  receive_email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  receive_phone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  reportByMe?: InputMaybe<ReportUserUpdateManyWithoutReporterNestedInput>;
  reported?: InputMaybe<ReportUserUpdateManyWithoutUserNestedInput>;
  reports?: InputMaybe<ReportUpdateManyWithoutReporterNestedInput>;
  settingNotification?: InputMaybe<SettingNotificationUpdateOneWithoutUserNestedInput>;
  settingPrivacy?: InputMaybe<SettingPrivacyUpdateOneWithoutUserNestedInput>;
  signature?: InputMaybe<SignatureUpdateManyWithoutOwnerNestedInput>;
  storePurchaseHistory?: InputMaybe<CartItemUpdateManyWithoutCustomerNestedInput>;
  tagsUsed?: InputMaybe<PostTagUpdateManyWithoutUserNestedInput>;
  thirdpartyId?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  vAddrMapping?: InputMaybe<VirtualAddrMappingUpdateOneWithoutOwnerNestedInput>;
  valid?: InputMaybe<BoolFieldUpdateOperationsInput>;
  website?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type UserUpdateWithoutCardInput = {
  accessToken?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  address?: InputMaybe<AddressUpdateManyWithoutOwnerNestedInput>;
  attachments?: InputMaybe<AttachmentUpdateManyWithoutOwnerNestedInput>;
  authType?: InputMaybe<EnumUserAuthTypeFieldUpdateOperationsInput>;
  bio?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  campaignNanhui?: InputMaybe<CampaignNanhuiUpdateManyWithoutSubmitterNestedInput>;
  campainZeczec?: InputMaybe<CampainZeczecUpdateOneWithoutRegisterUserNestedInput>;
  coins?: InputMaybe<CoinUpdateManyWithoutUserNestedInput>;
  collection?: InputMaybe<CollectionUpdateManyWithoutOwnerNestedInput>;
  country?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  couponUsages?: InputMaybe<CouponUsageUpdateManyWithoutUserNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  createdStamps?: InputMaybe<StampUpdateManyWithoutCreatorNestedInput>;
  detail?: InputMaybe<OrderDetailUpdateManyWithoutReceiverNestedInput>;
  deviceToken?: InputMaybe<DeviceTokenUpdateManyWithoutUserNestedInput>;
  email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  emailTos?: InputMaybe<EmailToUpdateManyWithoutOwnerNestedInput>;
  favoriteStoreItems?: InputMaybe<StoreItemUpdateManyWithoutFavoriteByUsersNestedInput>;
  fetchFriends?: InputMaybe<UserFriendUpdateManyWithoutOwnerNestedInput>;
  followProfitables?: InputMaybe<ProfitableUpdateManyWithoutFollowedByNestedInput>;
  followedBy?: InputMaybe<UserUpdateManyWithoutFollowingNestedInput>;
  following?: InputMaybe<UserUpdateManyWithoutFollowedByNestedInput>;
  gender?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  groupsJoin?: InputMaybe<GroupUpdateManyWithoutMembersNestedInput>;
  groupsOwn?: InputMaybe<GroupUpdateManyWithoutOwnerNestedInput>;
  headUri?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  history?: InputMaybe<OrderDetailHistoryUpdateManyWithoutOperatorNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  idStr?: InputMaybe<StringFieldUpdateOperationsInput>;
  inFriends?: InputMaybe<UserFriendUpdateManyWithoutUsersNestedInput>;
  inValidReasonHistories?: InputMaybe<InValidReasonUpdateManyWithoutUserNestedInput>;
  lastLogin?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  lastTokenUpdated?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  likePosts?: InputMaybe<PostUpdateManyWithoutLikeUsersNestedInput>;
  manualAddrs?: InputMaybe<ManualAddrUpdateManyWithoutOwnerNestedInput>;
  myCoupons?: InputMaybe<CouponEntityUpdateManyWithoutHolderNestedInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  notificationReceiver?: InputMaybe<NotificationUpdateManyWithoutReceiverNestedInput>;
  notificationSender?: InputMaybe<NotificationUpdateManyWithoutSenderNestedInput>;
  order?: InputMaybe<OrderUpdateManyWithoutSenderNestedInput>;
  packagesAsReceiver?: InputMaybe<PostPackageUpdateManyWithoutReceiversNestedInput>;
  password?: InputMaybe<StringFieldUpdateOperationsInput>;
  pcoins?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  phone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  postPackage?: InputMaybe<PostPackageUpdateManyWithoutSenderNestedInput>;
  postsIn?: InputMaybe<PostUpdateManyWithoutReceiverNestedInput>;
  postsOut?: InputMaybe<PostUpdateManyWithoutSenderNestedInput>;
  profitable?: InputMaybe<ProfitableUpdateOneWithoutUserNestedInput>;
  receive_email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  receive_phone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  reportByMe?: InputMaybe<ReportUserUpdateManyWithoutReporterNestedInput>;
  reported?: InputMaybe<ReportUserUpdateManyWithoutUserNestedInput>;
  reports?: InputMaybe<ReportUpdateManyWithoutReporterNestedInput>;
  settingNotification?: InputMaybe<SettingNotificationUpdateOneWithoutUserNestedInput>;
  settingPrivacy?: InputMaybe<SettingPrivacyUpdateOneWithoutUserNestedInput>;
  signature?: InputMaybe<SignatureUpdateManyWithoutOwnerNestedInput>;
  storePurchaseHistory?: InputMaybe<CartItemUpdateManyWithoutCustomerNestedInput>;
  tagsUsed?: InputMaybe<PostTagUpdateManyWithoutUserNestedInput>;
  thirdpartyId?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  vAddrMapping?: InputMaybe<VirtualAddrMappingUpdateOneWithoutOwnerNestedInput>;
  valid?: InputMaybe<BoolFieldUpdateOperationsInput>;
  website?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type UserUpdateWithoutCoinsInput = {
  accessToken?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  address?: InputMaybe<AddressUpdateManyWithoutOwnerNestedInput>;
  attachments?: InputMaybe<AttachmentUpdateManyWithoutOwnerNestedInput>;
  authType?: InputMaybe<EnumUserAuthTypeFieldUpdateOperationsInput>;
  bio?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  campaignNanhui?: InputMaybe<CampaignNanhuiUpdateManyWithoutSubmitterNestedInput>;
  campainZeczec?: InputMaybe<CampainZeczecUpdateOneWithoutRegisterUserNestedInput>;
  card?: InputMaybe<CardUpdateManyWithoutCreatorNestedInput>;
  collection?: InputMaybe<CollectionUpdateManyWithoutOwnerNestedInput>;
  country?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  couponUsages?: InputMaybe<CouponUsageUpdateManyWithoutUserNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  createdStamps?: InputMaybe<StampUpdateManyWithoutCreatorNestedInput>;
  detail?: InputMaybe<OrderDetailUpdateManyWithoutReceiverNestedInput>;
  deviceToken?: InputMaybe<DeviceTokenUpdateManyWithoutUserNestedInput>;
  email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  emailTos?: InputMaybe<EmailToUpdateManyWithoutOwnerNestedInput>;
  favoriteStoreItems?: InputMaybe<StoreItemUpdateManyWithoutFavoriteByUsersNestedInput>;
  fetchFriends?: InputMaybe<UserFriendUpdateManyWithoutOwnerNestedInput>;
  followProfitables?: InputMaybe<ProfitableUpdateManyWithoutFollowedByNestedInput>;
  followedBy?: InputMaybe<UserUpdateManyWithoutFollowingNestedInput>;
  following?: InputMaybe<UserUpdateManyWithoutFollowedByNestedInput>;
  gender?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  groupsJoin?: InputMaybe<GroupUpdateManyWithoutMembersNestedInput>;
  groupsOwn?: InputMaybe<GroupUpdateManyWithoutOwnerNestedInput>;
  headUri?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  history?: InputMaybe<OrderDetailHistoryUpdateManyWithoutOperatorNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  idStr?: InputMaybe<StringFieldUpdateOperationsInput>;
  inFriends?: InputMaybe<UserFriendUpdateManyWithoutUsersNestedInput>;
  inValidReasonHistories?: InputMaybe<InValidReasonUpdateManyWithoutUserNestedInput>;
  lastLogin?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  lastTokenUpdated?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  likePosts?: InputMaybe<PostUpdateManyWithoutLikeUsersNestedInput>;
  manualAddrs?: InputMaybe<ManualAddrUpdateManyWithoutOwnerNestedInput>;
  myCoupons?: InputMaybe<CouponEntityUpdateManyWithoutHolderNestedInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  notificationReceiver?: InputMaybe<NotificationUpdateManyWithoutReceiverNestedInput>;
  notificationSender?: InputMaybe<NotificationUpdateManyWithoutSenderNestedInput>;
  order?: InputMaybe<OrderUpdateManyWithoutSenderNestedInput>;
  packagesAsReceiver?: InputMaybe<PostPackageUpdateManyWithoutReceiversNestedInput>;
  password?: InputMaybe<StringFieldUpdateOperationsInput>;
  pcoins?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  phone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  postPackage?: InputMaybe<PostPackageUpdateManyWithoutSenderNestedInput>;
  postsIn?: InputMaybe<PostUpdateManyWithoutReceiverNestedInput>;
  postsOut?: InputMaybe<PostUpdateManyWithoutSenderNestedInput>;
  profitable?: InputMaybe<ProfitableUpdateOneWithoutUserNestedInput>;
  receive_email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  receive_phone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  reportByMe?: InputMaybe<ReportUserUpdateManyWithoutReporterNestedInput>;
  reported?: InputMaybe<ReportUserUpdateManyWithoutUserNestedInput>;
  reports?: InputMaybe<ReportUpdateManyWithoutReporterNestedInput>;
  settingNotification?: InputMaybe<SettingNotificationUpdateOneWithoutUserNestedInput>;
  settingPrivacy?: InputMaybe<SettingPrivacyUpdateOneWithoutUserNestedInput>;
  signature?: InputMaybe<SignatureUpdateManyWithoutOwnerNestedInput>;
  storePurchaseHistory?: InputMaybe<CartItemUpdateManyWithoutCustomerNestedInput>;
  tagsUsed?: InputMaybe<PostTagUpdateManyWithoutUserNestedInput>;
  thirdpartyId?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  vAddrMapping?: InputMaybe<VirtualAddrMappingUpdateOneWithoutOwnerNestedInput>;
  valid?: InputMaybe<BoolFieldUpdateOperationsInput>;
  website?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type UserUpdateWithoutCollectionInput = {
  accessToken?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  address?: InputMaybe<AddressUpdateManyWithoutOwnerNestedInput>;
  attachments?: InputMaybe<AttachmentUpdateManyWithoutOwnerNestedInput>;
  authType?: InputMaybe<EnumUserAuthTypeFieldUpdateOperationsInput>;
  bio?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  campaignNanhui?: InputMaybe<CampaignNanhuiUpdateManyWithoutSubmitterNestedInput>;
  campainZeczec?: InputMaybe<CampainZeczecUpdateOneWithoutRegisterUserNestedInput>;
  card?: InputMaybe<CardUpdateManyWithoutCreatorNestedInput>;
  coins?: InputMaybe<CoinUpdateManyWithoutUserNestedInput>;
  country?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  couponUsages?: InputMaybe<CouponUsageUpdateManyWithoutUserNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  createdStamps?: InputMaybe<StampUpdateManyWithoutCreatorNestedInput>;
  detail?: InputMaybe<OrderDetailUpdateManyWithoutReceiverNestedInput>;
  deviceToken?: InputMaybe<DeviceTokenUpdateManyWithoutUserNestedInput>;
  email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  emailTos?: InputMaybe<EmailToUpdateManyWithoutOwnerNestedInput>;
  favoriteStoreItems?: InputMaybe<StoreItemUpdateManyWithoutFavoriteByUsersNestedInput>;
  fetchFriends?: InputMaybe<UserFriendUpdateManyWithoutOwnerNestedInput>;
  followProfitables?: InputMaybe<ProfitableUpdateManyWithoutFollowedByNestedInput>;
  followedBy?: InputMaybe<UserUpdateManyWithoutFollowingNestedInput>;
  following?: InputMaybe<UserUpdateManyWithoutFollowedByNestedInput>;
  gender?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  groupsJoin?: InputMaybe<GroupUpdateManyWithoutMembersNestedInput>;
  groupsOwn?: InputMaybe<GroupUpdateManyWithoutOwnerNestedInput>;
  headUri?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  history?: InputMaybe<OrderDetailHistoryUpdateManyWithoutOperatorNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  idStr?: InputMaybe<StringFieldUpdateOperationsInput>;
  inFriends?: InputMaybe<UserFriendUpdateManyWithoutUsersNestedInput>;
  inValidReasonHistories?: InputMaybe<InValidReasonUpdateManyWithoutUserNestedInput>;
  lastLogin?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  lastTokenUpdated?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  likePosts?: InputMaybe<PostUpdateManyWithoutLikeUsersNestedInput>;
  manualAddrs?: InputMaybe<ManualAddrUpdateManyWithoutOwnerNestedInput>;
  myCoupons?: InputMaybe<CouponEntityUpdateManyWithoutHolderNestedInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  notificationReceiver?: InputMaybe<NotificationUpdateManyWithoutReceiverNestedInput>;
  notificationSender?: InputMaybe<NotificationUpdateManyWithoutSenderNestedInput>;
  order?: InputMaybe<OrderUpdateManyWithoutSenderNestedInput>;
  packagesAsReceiver?: InputMaybe<PostPackageUpdateManyWithoutReceiversNestedInput>;
  password?: InputMaybe<StringFieldUpdateOperationsInput>;
  pcoins?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  phone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  postPackage?: InputMaybe<PostPackageUpdateManyWithoutSenderNestedInput>;
  postsIn?: InputMaybe<PostUpdateManyWithoutReceiverNestedInput>;
  postsOut?: InputMaybe<PostUpdateManyWithoutSenderNestedInput>;
  profitable?: InputMaybe<ProfitableUpdateOneWithoutUserNestedInput>;
  receive_email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  receive_phone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  reportByMe?: InputMaybe<ReportUserUpdateManyWithoutReporterNestedInput>;
  reported?: InputMaybe<ReportUserUpdateManyWithoutUserNestedInput>;
  reports?: InputMaybe<ReportUpdateManyWithoutReporterNestedInput>;
  settingNotification?: InputMaybe<SettingNotificationUpdateOneWithoutUserNestedInput>;
  settingPrivacy?: InputMaybe<SettingPrivacyUpdateOneWithoutUserNestedInput>;
  signature?: InputMaybe<SignatureUpdateManyWithoutOwnerNestedInput>;
  storePurchaseHistory?: InputMaybe<CartItemUpdateManyWithoutCustomerNestedInput>;
  tagsUsed?: InputMaybe<PostTagUpdateManyWithoutUserNestedInput>;
  thirdpartyId?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  vAddrMapping?: InputMaybe<VirtualAddrMappingUpdateOneWithoutOwnerNestedInput>;
  valid?: InputMaybe<BoolFieldUpdateOperationsInput>;
  website?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type UserUpdateWithoutCouponUsagesInput = {
  accessToken?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  address?: InputMaybe<AddressUpdateManyWithoutOwnerNestedInput>;
  attachments?: InputMaybe<AttachmentUpdateManyWithoutOwnerNestedInput>;
  authType?: InputMaybe<EnumUserAuthTypeFieldUpdateOperationsInput>;
  bio?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  campaignNanhui?: InputMaybe<CampaignNanhuiUpdateManyWithoutSubmitterNestedInput>;
  campainZeczec?: InputMaybe<CampainZeczecUpdateOneWithoutRegisterUserNestedInput>;
  card?: InputMaybe<CardUpdateManyWithoutCreatorNestedInput>;
  coins?: InputMaybe<CoinUpdateManyWithoutUserNestedInput>;
  collection?: InputMaybe<CollectionUpdateManyWithoutOwnerNestedInput>;
  country?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  createdStamps?: InputMaybe<StampUpdateManyWithoutCreatorNestedInput>;
  detail?: InputMaybe<OrderDetailUpdateManyWithoutReceiverNestedInput>;
  deviceToken?: InputMaybe<DeviceTokenUpdateManyWithoutUserNestedInput>;
  email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  emailTos?: InputMaybe<EmailToUpdateManyWithoutOwnerNestedInput>;
  favoriteStoreItems?: InputMaybe<StoreItemUpdateManyWithoutFavoriteByUsersNestedInput>;
  fetchFriends?: InputMaybe<UserFriendUpdateManyWithoutOwnerNestedInput>;
  followProfitables?: InputMaybe<ProfitableUpdateManyWithoutFollowedByNestedInput>;
  followedBy?: InputMaybe<UserUpdateManyWithoutFollowingNestedInput>;
  following?: InputMaybe<UserUpdateManyWithoutFollowedByNestedInput>;
  gender?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  groupsJoin?: InputMaybe<GroupUpdateManyWithoutMembersNestedInput>;
  groupsOwn?: InputMaybe<GroupUpdateManyWithoutOwnerNestedInput>;
  headUri?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  history?: InputMaybe<OrderDetailHistoryUpdateManyWithoutOperatorNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  idStr?: InputMaybe<StringFieldUpdateOperationsInput>;
  inFriends?: InputMaybe<UserFriendUpdateManyWithoutUsersNestedInput>;
  inValidReasonHistories?: InputMaybe<InValidReasonUpdateManyWithoutUserNestedInput>;
  lastLogin?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  lastTokenUpdated?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  likePosts?: InputMaybe<PostUpdateManyWithoutLikeUsersNestedInput>;
  manualAddrs?: InputMaybe<ManualAddrUpdateManyWithoutOwnerNestedInput>;
  myCoupons?: InputMaybe<CouponEntityUpdateManyWithoutHolderNestedInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  notificationReceiver?: InputMaybe<NotificationUpdateManyWithoutReceiverNestedInput>;
  notificationSender?: InputMaybe<NotificationUpdateManyWithoutSenderNestedInput>;
  order?: InputMaybe<OrderUpdateManyWithoutSenderNestedInput>;
  packagesAsReceiver?: InputMaybe<PostPackageUpdateManyWithoutReceiversNestedInput>;
  password?: InputMaybe<StringFieldUpdateOperationsInput>;
  pcoins?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  phone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  postPackage?: InputMaybe<PostPackageUpdateManyWithoutSenderNestedInput>;
  postsIn?: InputMaybe<PostUpdateManyWithoutReceiverNestedInput>;
  postsOut?: InputMaybe<PostUpdateManyWithoutSenderNestedInput>;
  profitable?: InputMaybe<ProfitableUpdateOneWithoutUserNestedInput>;
  receive_email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  receive_phone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  reportByMe?: InputMaybe<ReportUserUpdateManyWithoutReporterNestedInput>;
  reported?: InputMaybe<ReportUserUpdateManyWithoutUserNestedInput>;
  reports?: InputMaybe<ReportUpdateManyWithoutReporterNestedInput>;
  settingNotification?: InputMaybe<SettingNotificationUpdateOneWithoutUserNestedInput>;
  settingPrivacy?: InputMaybe<SettingPrivacyUpdateOneWithoutUserNestedInput>;
  signature?: InputMaybe<SignatureUpdateManyWithoutOwnerNestedInput>;
  storePurchaseHistory?: InputMaybe<CartItemUpdateManyWithoutCustomerNestedInput>;
  tagsUsed?: InputMaybe<PostTagUpdateManyWithoutUserNestedInput>;
  thirdpartyId?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  vAddrMapping?: InputMaybe<VirtualAddrMappingUpdateOneWithoutOwnerNestedInput>;
  valid?: InputMaybe<BoolFieldUpdateOperationsInput>;
  website?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type UserUpdateWithoutCreatedStampsInput = {
  accessToken?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  address?: InputMaybe<AddressUpdateManyWithoutOwnerNestedInput>;
  attachments?: InputMaybe<AttachmentUpdateManyWithoutOwnerNestedInput>;
  authType?: InputMaybe<EnumUserAuthTypeFieldUpdateOperationsInput>;
  bio?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  campaignNanhui?: InputMaybe<CampaignNanhuiUpdateManyWithoutSubmitterNestedInput>;
  campainZeczec?: InputMaybe<CampainZeczecUpdateOneWithoutRegisterUserNestedInput>;
  card?: InputMaybe<CardUpdateManyWithoutCreatorNestedInput>;
  coins?: InputMaybe<CoinUpdateManyWithoutUserNestedInput>;
  collection?: InputMaybe<CollectionUpdateManyWithoutOwnerNestedInput>;
  country?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  couponUsages?: InputMaybe<CouponUsageUpdateManyWithoutUserNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  detail?: InputMaybe<OrderDetailUpdateManyWithoutReceiverNestedInput>;
  deviceToken?: InputMaybe<DeviceTokenUpdateManyWithoutUserNestedInput>;
  email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  emailTos?: InputMaybe<EmailToUpdateManyWithoutOwnerNestedInput>;
  favoriteStoreItems?: InputMaybe<StoreItemUpdateManyWithoutFavoriteByUsersNestedInput>;
  followProfitables?: InputMaybe<ProfitableUpdateManyWithoutFollowedByNestedInput>;
  followedBy?: InputMaybe<UserUpdateManyWithoutFollowingNestedInput>;
  following?: InputMaybe<UserUpdateManyWithoutFollowedByNestedInput>;
  friends?: InputMaybe<UserFriendUpdateManyWithoutOwnerNestedInput>;
  gender?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  groupsJoin?: InputMaybe<GroupUpdateManyWithoutMembersNestedInput>;
  groupsOwn?: InputMaybe<GroupUpdateManyWithoutOwnerNestedInput>;
  headUri?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  history?: InputMaybe<OrderDetailHistoryUpdateManyWithoutOperatorNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  idStr?: InputMaybe<StringFieldUpdateOperationsInput>;
  inFriends?: InputMaybe<UserFriendUpdateManyWithoutUsersNestedInput>;
  inValidReasonHistories?: InputMaybe<InValidReasonUpdateManyWithoutUserNestedInput>;
  lastLogin?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  lastTokenUpdated?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  likePosts?: InputMaybe<PostUpdateManyWithoutLikeUsersNestedInput>;
  manualAddrs?: InputMaybe<ManualAddrUpdateManyWithoutOwnerNestedInput>;
  myCoupons?: InputMaybe<CouponEntityUpdateManyWithoutHolderNestedInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  notificationReceiver?: InputMaybe<NotificationUpdateManyWithoutReceiverNestedInput>;
  notificationSender?: InputMaybe<NotificationUpdateManyWithoutSenderNestedInput>;
  order?: InputMaybe<OrderUpdateManyWithoutSenderNestedInput>;
  packagesAsReceiver?: InputMaybe<PostPackageUpdateManyWithoutReceiversNestedInput>;
  password?: InputMaybe<StringFieldUpdateOperationsInput>;
  pcoins?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  phone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  postPackage?: InputMaybe<PostPackageUpdateManyWithoutSenderNestedInput>;
  postsIn?: InputMaybe<PostUpdateManyWithoutReceiverNestedInput>;
  postsOut?: InputMaybe<PostUpdateManyWithoutSenderNestedInput>;
  profitable?: InputMaybe<ProfitableUpdateOneWithoutUserNestedInput>;
  receive_email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  receive_phone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  reportByMe?: InputMaybe<ReportUserUpdateManyWithoutReporterNestedInput>;
  reported?: InputMaybe<ReportUserUpdateManyWithoutUserNestedInput>;
  reports?: InputMaybe<ReportUpdateManyWithoutReporterNestedInput>;
  settingNotification?: InputMaybe<SettingNotificationUpdateOneWithoutUserNestedInput>;
  settingPrivacy?: InputMaybe<SettingPrivacyUpdateOneWithoutUserNestedInput>;
  signature?: InputMaybe<SignatureUpdateManyWithoutOwnerNestedInput>;
  storePurchaseHistory?: InputMaybe<CartItemUpdateManyWithoutCustomerNestedInput>;
  tagsUsed?: InputMaybe<PostTagUpdateManyWithoutUserNestedInput>;
  thirdpartyId?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  vAddrMapping?: InputMaybe<VirtualAddrMappingUpdateOneWithoutOwnerNestedInput>;
  valid?: InputMaybe<BoolFieldUpdateOperationsInput>;
  website?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type UserUpdateWithoutDetailInput = {
  accessToken?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  address?: InputMaybe<AddressUpdateManyWithoutOwnerNestedInput>;
  attachments?: InputMaybe<AttachmentUpdateManyWithoutOwnerNestedInput>;
  authType?: InputMaybe<EnumUserAuthTypeFieldUpdateOperationsInput>;
  bio?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  campaignNanhui?: InputMaybe<CampaignNanhuiUpdateManyWithoutSubmitterNestedInput>;
  campainZeczec?: InputMaybe<CampainZeczecUpdateOneWithoutRegisterUserNestedInput>;
  card?: InputMaybe<CardUpdateManyWithoutCreatorNestedInput>;
  coins?: InputMaybe<CoinUpdateManyWithoutUserNestedInput>;
  collection?: InputMaybe<CollectionUpdateManyWithoutOwnerNestedInput>;
  country?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  couponUsages?: InputMaybe<CouponUsageUpdateManyWithoutUserNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  createdStamps?: InputMaybe<StampUpdateManyWithoutCreatorNestedInput>;
  deviceToken?: InputMaybe<DeviceTokenUpdateManyWithoutUserNestedInput>;
  email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  emailTos?: InputMaybe<EmailToUpdateManyWithoutOwnerNestedInput>;
  favoriteStoreItems?: InputMaybe<StoreItemUpdateManyWithoutFavoriteByUsersNestedInput>;
  fetchFriends?: InputMaybe<UserFriendUpdateManyWithoutOwnerNestedInput>;
  followProfitables?: InputMaybe<ProfitableUpdateManyWithoutFollowedByNestedInput>;
  followedBy?: InputMaybe<UserUpdateManyWithoutFollowingNestedInput>;
  following?: InputMaybe<UserUpdateManyWithoutFollowedByNestedInput>;
  gender?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  groupsJoin?: InputMaybe<GroupUpdateManyWithoutMembersNestedInput>;
  groupsOwn?: InputMaybe<GroupUpdateManyWithoutOwnerNestedInput>;
  headUri?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  history?: InputMaybe<OrderDetailHistoryUpdateManyWithoutOperatorNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  idStr?: InputMaybe<StringFieldUpdateOperationsInput>;
  inFriends?: InputMaybe<UserFriendUpdateManyWithoutUsersNestedInput>;
  inValidReasonHistories?: InputMaybe<InValidReasonUpdateManyWithoutUserNestedInput>;
  lastLogin?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  lastTokenUpdated?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  likePosts?: InputMaybe<PostUpdateManyWithoutLikeUsersNestedInput>;
  manualAddrs?: InputMaybe<ManualAddrUpdateManyWithoutOwnerNestedInput>;
  myCoupons?: InputMaybe<CouponEntityUpdateManyWithoutHolderNestedInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  notificationReceiver?: InputMaybe<NotificationUpdateManyWithoutReceiverNestedInput>;
  notificationSender?: InputMaybe<NotificationUpdateManyWithoutSenderNestedInput>;
  order?: InputMaybe<OrderUpdateManyWithoutSenderNestedInput>;
  packagesAsReceiver?: InputMaybe<PostPackageUpdateManyWithoutReceiversNestedInput>;
  password?: InputMaybe<StringFieldUpdateOperationsInput>;
  pcoins?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  phone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  postPackage?: InputMaybe<PostPackageUpdateManyWithoutSenderNestedInput>;
  postsIn?: InputMaybe<PostUpdateManyWithoutReceiverNestedInput>;
  postsOut?: InputMaybe<PostUpdateManyWithoutSenderNestedInput>;
  profitable?: InputMaybe<ProfitableUpdateOneWithoutUserNestedInput>;
  receive_email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  receive_phone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  reportByMe?: InputMaybe<ReportUserUpdateManyWithoutReporterNestedInput>;
  reported?: InputMaybe<ReportUserUpdateManyWithoutUserNestedInput>;
  reports?: InputMaybe<ReportUpdateManyWithoutReporterNestedInput>;
  settingNotification?: InputMaybe<SettingNotificationUpdateOneWithoutUserNestedInput>;
  settingPrivacy?: InputMaybe<SettingPrivacyUpdateOneWithoutUserNestedInput>;
  signature?: InputMaybe<SignatureUpdateManyWithoutOwnerNestedInput>;
  storePurchaseHistory?: InputMaybe<CartItemUpdateManyWithoutCustomerNestedInput>;
  tagsUsed?: InputMaybe<PostTagUpdateManyWithoutUserNestedInput>;
  thirdpartyId?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  vAddrMapping?: InputMaybe<VirtualAddrMappingUpdateOneWithoutOwnerNestedInput>;
  valid?: InputMaybe<BoolFieldUpdateOperationsInput>;
  website?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type UserUpdateWithoutDeviceTokenInput = {
  accessToken?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  address?: InputMaybe<AddressUpdateManyWithoutOwnerNestedInput>;
  attachments?: InputMaybe<AttachmentUpdateManyWithoutOwnerNestedInput>;
  authType?: InputMaybe<EnumUserAuthTypeFieldUpdateOperationsInput>;
  bio?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  campaignNanhui?: InputMaybe<CampaignNanhuiUpdateManyWithoutSubmitterNestedInput>;
  campainZeczec?: InputMaybe<CampainZeczecUpdateOneWithoutRegisterUserNestedInput>;
  card?: InputMaybe<CardUpdateManyWithoutCreatorNestedInput>;
  coins?: InputMaybe<CoinUpdateManyWithoutUserNestedInput>;
  collection?: InputMaybe<CollectionUpdateManyWithoutOwnerNestedInput>;
  country?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  couponUsages?: InputMaybe<CouponUsageUpdateManyWithoutUserNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  createdStamps?: InputMaybe<StampUpdateManyWithoutCreatorNestedInput>;
  detail?: InputMaybe<OrderDetailUpdateManyWithoutReceiverNestedInput>;
  email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  emailTos?: InputMaybe<EmailToUpdateManyWithoutOwnerNestedInput>;
  favoriteStoreItems?: InputMaybe<StoreItemUpdateManyWithoutFavoriteByUsersNestedInput>;
  fetchFriends?: InputMaybe<UserFriendUpdateManyWithoutOwnerNestedInput>;
  followProfitables?: InputMaybe<ProfitableUpdateManyWithoutFollowedByNestedInput>;
  followedBy?: InputMaybe<UserUpdateManyWithoutFollowingNestedInput>;
  following?: InputMaybe<UserUpdateManyWithoutFollowedByNestedInput>;
  gender?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  groupsJoin?: InputMaybe<GroupUpdateManyWithoutMembersNestedInput>;
  groupsOwn?: InputMaybe<GroupUpdateManyWithoutOwnerNestedInput>;
  headUri?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  history?: InputMaybe<OrderDetailHistoryUpdateManyWithoutOperatorNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  idStr?: InputMaybe<StringFieldUpdateOperationsInput>;
  inFriends?: InputMaybe<UserFriendUpdateManyWithoutUsersNestedInput>;
  inValidReasonHistories?: InputMaybe<InValidReasonUpdateManyWithoutUserNestedInput>;
  lastLogin?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  lastTokenUpdated?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  likePosts?: InputMaybe<PostUpdateManyWithoutLikeUsersNestedInput>;
  manualAddrs?: InputMaybe<ManualAddrUpdateManyWithoutOwnerNestedInput>;
  myCoupons?: InputMaybe<CouponEntityUpdateManyWithoutHolderNestedInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  notificationReceiver?: InputMaybe<NotificationUpdateManyWithoutReceiverNestedInput>;
  notificationSender?: InputMaybe<NotificationUpdateManyWithoutSenderNestedInput>;
  order?: InputMaybe<OrderUpdateManyWithoutSenderNestedInput>;
  packagesAsReceiver?: InputMaybe<PostPackageUpdateManyWithoutReceiversNestedInput>;
  password?: InputMaybe<StringFieldUpdateOperationsInput>;
  pcoins?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  phone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  postPackage?: InputMaybe<PostPackageUpdateManyWithoutSenderNestedInput>;
  postsIn?: InputMaybe<PostUpdateManyWithoutReceiverNestedInput>;
  postsOut?: InputMaybe<PostUpdateManyWithoutSenderNestedInput>;
  profitable?: InputMaybe<ProfitableUpdateOneWithoutUserNestedInput>;
  receive_email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  receive_phone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  reportByMe?: InputMaybe<ReportUserUpdateManyWithoutReporterNestedInput>;
  reported?: InputMaybe<ReportUserUpdateManyWithoutUserNestedInput>;
  reports?: InputMaybe<ReportUpdateManyWithoutReporterNestedInput>;
  settingNotification?: InputMaybe<SettingNotificationUpdateOneWithoutUserNestedInput>;
  settingPrivacy?: InputMaybe<SettingPrivacyUpdateOneWithoutUserNestedInput>;
  signature?: InputMaybe<SignatureUpdateManyWithoutOwnerNestedInput>;
  storePurchaseHistory?: InputMaybe<CartItemUpdateManyWithoutCustomerNestedInput>;
  tagsUsed?: InputMaybe<PostTagUpdateManyWithoutUserNestedInput>;
  thirdpartyId?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  vAddrMapping?: InputMaybe<VirtualAddrMappingUpdateOneWithoutOwnerNestedInput>;
  valid?: InputMaybe<BoolFieldUpdateOperationsInput>;
  website?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type UserUpdateWithoutEmailTosInput = {
  accessToken?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  address?: InputMaybe<AddressUpdateManyWithoutOwnerNestedInput>;
  attachments?: InputMaybe<AttachmentUpdateManyWithoutOwnerNestedInput>;
  authType?: InputMaybe<EnumUserAuthTypeFieldUpdateOperationsInput>;
  bio?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  campaignNanhui?: InputMaybe<CampaignNanhuiUpdateManyWithoutSubmitterNestedInput>;
  campainZeczec?: InputMaybe<CampainZeczecUpdateOneWithoutRegisterUserNestedInput>;
  card?: InputMaybe<CardUpdateManyWithoutCreatorNestedInput>;
  coins?: InputMaybe<CoinUpdateManyWithoutUserNestedInput>;
  collection?: InputMaybe<CollectionUpdateManyWithoutOwnerNestedInput>;
  country?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  couponUsages?: InputMaybe<CouponUsageUpdateManyWithoutUserNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  createdStamps?: InputMaybe<StampUpdateManyWithoutCreatorNestedInput>;
  detail?: InputMaybe<OrderDetailUpdateManyWithoutReceiverNestedInput>;
  deviceToken?: InputMaybe<DeviceTokenUpdateManyWithoutUserNestedInput>;
  email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  favoriteStoreItems?: InputMaybe<StoreItemUpdateManyWithoutFavoriteByUsersNestedInput>;
  fetchFriends?: InputMaybe<UserFriendUpdateManyWithoutOwnerNestedInput>;
  followProfitables?: InputMaybe<ProfitableUpdateManyWithoutFollowedByNestedInput>;
  followedBy?: InputMaybe<UserUpdateManyWithoutFollowingNestedInput>;
  following?: InputMaybe<UserUpdateManyWithoutFollowedByNestedInput>;
  gender?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  groupsJoin?: InputMaybe<GroupUpdateManyWithoutMembersNestedInput>;
  groupsOwn?: InputMaybe<GroupUpdateManyWithoutOwnerNestedInput>;
  headUri?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  history?: InputMaybe<OrderDetailHistoryUpdateManyWithoutOperatorNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  idStr?: InputMaybe<StringFieldUpdateOperationsInput>;
  inFriends?: InputMaybe<UserFriendUpdateManyWithoutUsersNestedInput>;
  inValidReasonHistories?: InputMaybe<InValidReasonUpdateManyWithoutUserNestedInput>;
  lastLogin?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  lastTokenUpdated?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  likePosts?: InputMaybe<PostUpdateManyWithoutLikeUsersNestedInput>;
  manualAddrs?: InputMaybe<ManualAddrUpdateManyWithoutOwnerNestedInput>;
  myCoupons?: InputMaybe<CouponEntityUpdateManyWithoutHolderNestedInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  notificationReceiver?: InputMaybe<NotificationUpdateManyWithoutReceiverNestedInput>;
  notificationSender?: InputMaybe<NotificationUpdateManyWithoutSenderNestedInput>;
  order?: InputMaybe<OrderUpdateManyWithoutSenderNestedInput>;
  packagesAsReceiver?: InputMaybe<PostPackageUpdateManyWithoutReceiversNestedInput>;
  password?: InputMaybe<StringFieldUpdateOperationsInput>;
  pcoins?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  phone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  postPackage?: InputMaybe<PostPackageUpdateManyWithoutSenderNestedInput>;
  postsIn?: InputMaybe<PostUpdateManyWithoutReceiverNestedInput>;
  postsOut?: InputMaybe<PostUpdateManyWithoutSenderNestedInput>;
  profitable?: InputMaybe<ProfitableUpdateOneWithoutUserNestedInput>;
  receive_email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  receive_phone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  reportByMe?: InputMaybe<ReportUserUpdateManyWithoutReporterNestedInput>;
  reported?: InputMaybe<ReportUserUpdateManyWithoutUserNestedInput>;
  reports?: InputMaybe<ReportUpdateManyWithoutReporterNestedInput>;
  settingNotification?: InputMaybe<SettingNotificationUpdateOneWithoutUserNestedInput>;
  settingPrivacy?: InputMaybe<SettingPrivacyUpdateOneWithoutUserNestedInput>;
  signature?: InputMaybe<SignatureUpdateManyWithoutOwnerNestedInput>;
  storePurchaseHistory?: InputMaybe<CartItemUpdateManyWithoutCustomerNestedInput>;
  tagsUsed?: InputMaybe<PostTagUpdateManyWithoutUserNestedInput>;
  thirdpartyId?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  vAddrMapping?: InputMaybe<VirtualAddrMappingUpdateOneWithoutOwnerNestedInput>;
  valid?: InputMaybe<BoolFieldUpdateOperationsInput>;
  website?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type UserUpdateWithoutFavoriteStoreItemsInput = {
  accessToken?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  address?: InputMaybe<AddressUpdateManyWithoutOwnerNestedInput>;
  attachments?: InputMaybe<AttachmentUpdateManyWithoutOwnerNestedInput>;
  authType?: InputMaybe<EnumUserAuthTypeFieldUpdateOperationsInput>;
  bio?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  campaignNanhui?: InputMaybe<CampaignNanhuiUpdateManyWithoutSubmitterNestedInput>;
  campainZeczec?: InputMaybe<CampainZeczecUpdateOneWithoutRegisterUserNestedInput>;
  card?: InputMaybe<CardUpdateManyWithoutCreatorNestedInput>;
  coins?: InputMaybe<CoinUpdateManyWithoutUserNestedInput>;
  collection?: InputMaybe<CollectionUpdateManyWithoutOwnerNestedInput>;
  country?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  couponUsages?: InputMaybe<CouponUsageUpdateManyWithoutUserNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  createdStamps?: InputMaybe<StampUpdateManyWithoutCreatorNestedInput>;
  detail?: InputMaybe<OrderDetailUpdateManyWithoutReceiverNestedInput>;
  deviceToken?: InputMaybe<DeviceTokenUpdateManyWithoutUserNestedInput>;
  email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  emailTos?: InputMaybe<EmailToUpdateManyWithoutOwnerNestedInput>;
  fetchFriends?: InputMaybe<UserFriendUpdateManyWithoutOwnerNestedInput>;
  followProfitables?: InputMaybe<ProfitableUpdateManyWithoutFollowedByNestedInput>;
  followedBy?: InputMaybe<UserUpdateManyWithoutFollowingNestedInput>;
  following?: InputMaybe<UserUpdateManyWithoutFollowedByNestedInput>;
  gender?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  groupsJoin?: InputMaybe<GroupUpdateManyWithoutMembersNestedInput>;
  groupsOwn?: InputMaybe<GroupUpdateManyWithoutOwnerNestedInput>;
  headUri?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  history?: InputMaybe<OrderDetailHistoryUpdateManyWithoutOperatorNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  idStr?: InputMaybe<StringFieldUpdateOperationsInput>;
  inFriends?: InputMaybe<UserFriendUpdateManyWithoutUsersNestedInput>;
  inValidReasonHistories?: InputMaybe<InValidReasonUpdateManyWithoutUserNestedInput>;
  lastLogin?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  lastTokenUpdated?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  likePosts?: InputMaybe<PostUpdateManyWithoutLikeUsersNestedInput>;
  manualAddrs?: InputMaybe<ManualAddrUpdateManyWithoutOwnerNestedInput>;
  myCoupons?: InputMaybe<CouponEntityUpdateManyWithoutHolderNestedInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  notificationReceiver?: InputMaybe<NotificationUpdateManyWithoutReceiverNestedInput>;
  notificationSender?: InputMaybe<NotificationUpdateManyWithoutSenderNestedInput>;
  order?: InputMaybe<OrderUpdateManyWithoutSenderNestedInput>;
  packagesAsReceiver?: InputMaybe<PostPackageUpdateManyWithoutReceiversNestedInput>;
  password?: InputMaybe<StringFieldUpdateOperationsInput>;
  pcoins?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  phone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  postPackage?: InputMaybe<PostPackageUpdateManyWithoutSenderNestedInput>;
  postsIn?: InputMaybe<PostUpdateManyWithoutReceiverNestedInput>;
  postsOut?: InputMaybe<PostUpdateManyWithoutSenderNestedInput>;
  profitable?: InputMaybe<ProfitableUpdateOneWithoutUserNestedInput>;
  receive_email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  receive_phone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  reportByMe?: InputMaybe<ReportUserUpdateManyWithoutReporterNestedInput>;
  reported?: InputMaybe<ReportUserUpdateManyWithoutUserNestedInput>;
  reports?: InputMaybe<ReportUpdateManyWithoutReporterNestedInput>;
  settingNotification?: InputMaybe<SettingNotificationUpdateOneWithoutUserNestedInput>;
  settingPrivacy?: InputMaybe<SettingPrivacyUpdateOneWithoutUserNestedInput>;
  signature?: InputMaybe<SignatureUpdateManyWithoutOwnerNestedInput>;
  storePurchaseHistory?: InputMaybe<CartItemUpdateManyWithoutCustomerNestedInput>;
  tagsUsed?: InputMaybe<PostTagUpdateManyWithoutUserNestedInput>;
  thirdpartyId?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  vAddrMapping?: InputMaybe<VirtualAddrMappingUpdateOneWithoutOwnerNestedInput>;
  valid?: InputMaybe<BoolFieldUpdateOperationsInput>;
  website?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type UserUpdateWithoutFollowProfitablesInput = {
  accessToken?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  address?: InputMaybe<AddressUpdateManyWithoutOwnerNestedInput>;
  attachments?: InputMaybe<AttachmentUpdateManyWithoutOwnerNestedInput>;
  authType?: InputMaybe<EnumUserAuthTypeFieldUpdateOperationsInput>;
  bio?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  campaignNanhui?: InputMaybe<CampaignNanhuiUpdateManyWithoutSubmitterNestedInput>;
  campainZeczec?: InputMaybe<CampainZeczecUpdateOneWithoutRegisterUserNestedInput>;
  card?: InputMaybe<CardUpdateManyWithoutCreatorNestedInput>;
  coins?: InputMaybe<CoinUpdateManyWithoutUserNestedInput>;
  collection?: InputMaybe<CollectionUpdateManyWithoutOwnerNestedInput>;
  country?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  couponUsages?: InputMaybe<CouponUsageUpdateManyWithoutUserNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  createdStamps?: InputMaybe<StampUpdateManyWithoutCreatorNestedInput>;
  detail?: InputMaybe<OrderDetailUpdateManyWithoutReceiverNestedInput>;
  deviceToken?: InputMaybe<DeviceTokenUpdateManyWithoutUserNestedInput>;
  email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  emailTos?: InputMaybe<EmailToUpdateManyWithoutOwnerNestedInput>;
  favoriteStoreItems?: InputMaybe<StoreItemUpdateManyWithoutFavoriteByUsersNestedInput>;
  fetchFriends?: InputMaybe<UserFriendUpdateManyWithoutOwnerNestedInput>;
  followedBy?: InputMaybe<UserUpdateManyWithoutFollowingNestedInput>;
  following?: InputMaybe<UserUpdateManyWithoutFollowedByNestedInput>;
  gender?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  groupsJoin?: InputMaybe<GroupUpdateManyWithoutMembersNestedInput>;
  groupsOwn?: InputMaybe<GroupUpdateManyWithoutOwnerNestedInput>;
  headUri?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  history?: InputMaybe<OrderDetailHistoryUpdateManyWithoutOperatorNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  idStr?: InputMaybe<StringFieldUpdateOperationsInput>;
  inFriends?: InputMaybe<UserFriendUpdateManyWithoutUsersNestedInput>;
  inValidReasonHistories?: InputMaybe<InValidReasonUpdateManyWithoutUserNestedInput>;
  lastLogin?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  lastTokenUpdated?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  likePosts?: InputMaybe<PostUpdateManyWithoutLikeUsersNestedInput>;
  manualAddrs?: InputMaybe<ManualAddrUpdateManyWithoutOwnerNestedInput>;
  myCoupons?: InputMaybe<CouponEntityUpdateManyWithoutHolderNestedInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  notificationReceiver?: InputMaybe<NotificationUpdateManyWithoutReceiverNestedInput>;
  notificationSender?: InputMaybe<NotificationUpdateManyWithoutSenderNestedInput>;
  order?: InputMaybe<OrderUpdateManyWithoutSenderNestedInput>;
  packagesAsReceiver?: InputMaybe<PostPackageUpdateManyWithoutReceiversNestedInput>;
  password?: InputMaybe<StringFieldUpdateOperationsInput>;
  pcoins?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  phone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  postPackage?: InputMaybe<PostPackageUpdateManyWithoutSenderNestedInput>;
  postsIn?: InputMaybe<PostUpdateManyWithoutReceiverNestedInput>;
  postsOut?: InputMaybe<PostUpdateManyWithoutSenderNestedInput>;
  profitable?: InputMaybe<ProfitableUpdateOneWithoutUserNestedInput>;
  receive_email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  receive_phone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  reportByMe?: InputMaybe<ReportUserUpdateManyWithoutReporterNestedInput>;
  reported?: InputMaybe<ReportUserUpdateManyWithoutUserNestedInput>;
  reports?: InputMaybe<ReportUpdateManyWithoutReporterNestedInput>;
  settingNotification?: InputMaybe<SettingNotificationUpdateOneWithoutUserNestedInput>;
  settingPrivacy?: InputMaybe<SettingPrivacyUpdateOneWithoutUserNestedInput>;
  signature?: InputMaybe<SignatureUpdateManyWithoutOwnerNestedInput>;
  storePurchaseHistory?: InputMaybe<CartItemUpdateManyWithoutCustomerNestedInput>;
  tagsUsed?: InputMaybe<PostTagUpdateManyWithoutUserNestedInput>;
  thirdpartyId?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  vAddrMapping?: InputMaybe<VirtualAddrMappingUpdateOneWithoutOwnerNestedInput>;
  valid?: InputMaybe<BoolFieldUpdateOperationsInput>;
  website?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type UserUpdateWithoutFollowedByInput = {
  accessToken?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  address?: InputMaybe<AddressUpdateManyWithoutOwnerNestedInput>;
  attachments?: InputMaybe<AttachmentUpdateManyWithoutOwnerNestedInput>;
  authType?: InputMaybe<EnumUserAuthTypeFieldUpdateOperationsInput>;
  bio?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  campaignNanhui?: InputMaybe<CampaignNanhuiUpdateManyWithoutSubmitterNestedInput>;
  campainZeczec?: InputMaybe<CampainZeczecUpdateOneWithoutRegisterUserNestedInput>;
  card?: InputMaybe<CardUpdateManyWithoutCreatorNestedInput>;
  coins?: InputMaybe<CoinUpdateManyWithoutUserNestedInput>;
  collection?: InputMaybe<CollectionUpdateManyWithoutOwnerNestedInput>;
  country?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  couponUsages?: InputMaybe<CouponUsageUpdateManyWithoutUserNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  createdStamps?: InputMaybe<StampUpdateManyWithoutCreatorNestedInput>;
  detail?: InputMaybe<OrderDetailUpdateManyWithoutReceiverNestedInput>;
  deviceToken?: InputMaybe<DeviceTokenUpdateManyWithoutUserNestedInput>;
  email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  emailTos?: InputMaybe<EmailToUpdateManyWithoutOwnerNestedInput>;
  favoriteStoreItems?: InputMaybe<StoreItemUpdateManyWithoutFavoriteByUsersNestedInput>;
  fetchFriends?: InputMaybe<UserFriendUpdateManyWithoutOwnerNestedInput>;
  followProfitables?: InputMaybe<ProfitableUpdateManyWithoutFollowedByNestedInput>;
  following?: InputMaybe<UserUpdateManyWithoutFollowedByNestedInput>;
  gender?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  groupsJoin?: InputMaybe<GroupUpdateManyWithoutMembersNestedInput>;
  groupsOwn?: InputMaybe<GroupUpdateManyWithoutOwnerNestedInput>;
  headUri?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  history?: InputMaybe<OrderDetailHistoryUpdateManyWithoutOperatorNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  idStr?: InputMaybe<StringFieldUpdateOperationsInput>;
  inFriends?: InputMaybe<UserFriendUpdateManyWithoutUsersNestedInput>;
  inValidReasonHistories?: InputMaybe<InValidReasonUpdateManyWithoutUserNestedInput>;
  lastLogin?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  lastTokenUpdated?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  likePosts?: InputMaybe<PostUpdateManyWithoutLikeUsersNestedInput>;
  manualAddrs?: InputMaybe<ManualAddrUpdateManyWithoutOwnerNestedInput>;
  myCoupons?: InputMaybe<CouponEntityUpdateManyWithoutHolderNestedInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  notificationReceiver?: InputMaybe<NotificationUpdateManyWithoutReceiverNestedInput>;
  notificationSender?: InputMaybe<NotificationUpdateManyWithoutSenderNestedInput>;
  order?: InputMaybe<OrderUpdateManyWithoutSenderNestedInput>;
  packagesAsReceiver?: InputMaybe<PostPackageUpdateManyWithoutReceiversNestedInput>;
  password?: InputMaybe<StringFieldUpdateOperationsInput>;
  pcoins?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  phone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  postPackage?: InputMaybe<PostPackageUpdateManyWithoutSenderNestedInput>;
  postsIn?: InputMaybe<PostUpdateManyWithoutReceiverNestedInput>;
  postsOut?: InputMaybe<PostUpdateManyWithoutSenderNestedInput>;
  profitable?: InputMaybe<ProfitableUpdateOneWithoutUserNestedInput>;
  receive_email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  receive_phone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  reportByMe?: InputMaybe<ReportUserUpdateManyWithoutReporterNestedInput>;
  reported?: InputMaybe<ReportUserUpdateManyWithoutUserNestedInput>;
  reports?: InputMaybe<ReportUpdateManyWithoutReporterNestedInput>;
  settingNotification?: InputMaybe<SettingNotificationUpdateOneWithoutUserNestedInput>;
  settingPrivacy?: InputMaybe<SettingPrivacyUpdateOneWithoutUserNestedInput>;
  signature?: InputMaybe<SignatureUpdateManyWithoutOwnerNestedInput>;
  storePurchaseHistory?: InputMaybe<CartItemUpdateManyWithoutCustomerNestedInput>;
  tagsUsed?: InputMaybe<PostTagUpdateManyWithoutUserNestedInput>;
  thirdpartyId?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  vAddrMapping?: InputMaybe<VirtualAddrMappingUpdateOneWithoutOwnerNestedInput>;
  valid?: InputMaybe<BoolFieldUpdateOperationsInput>;
  website?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type UserUpdateWithoutFollowingInput = {
  accessToken?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  address?: InputMaybe<AddressUpdateManyWithoutOwnerNestedInput>;
  attachments?: InputMaybe<AttachmentUpdateManyWithoutOwnerNestedInput>;
  authType?: InputMaybe<EnumUserAuthTypeFieldUpdateOperationsInput>;
  bio?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  campaignNanhui?: InputMaybe<CampaignNanhuiUpdateManyWithoutSubmitterNestedInput>;
  campainZeczec?: InputMaybe<CampainZeczecUpdateOneWithoutRegisterUserNestedInput>;
  card?: InputMaybe<CardUpdateManyWithoutCreatorNestedInput>;
  coins?: InputMaybe<CoinUpdateManyWithoutUserNestedInput>;
  collection?: InputMaybe<CollectionUpdateManyWithoutOwnerNestedInput>;
  country?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  couponUsages?: InputMaybe<CouponUsageUpdateManyWithoutUserNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  createdStamps?: InputMaybe<StampUpdateManyWithoutCreatorNestedInput>;
  detail?: InputMaybe<OrderDetailUpdateManyWithoutReceiverNestedInput>;
  deviceToken?: InputMaybe<DeviceTokenUpdateManyWithoutUserNestedInput>;
  email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  emailTos?: InputMaybe<EmailToUpdateManyWithoutOwnerNestedInput>;
  favoriteStoreItems?: InputMaybe<StoreItemUpdateManyWithoutFavoriteByUsersNestedInput>;
  fetchFriends?: InputMaybe<UserFriendUpdateManyWithoutOwnerNestedInput>;
  followProfitables?: InputMaybe<ProfitableUpdateManyWithoutFollowedByNestedInput>;
  followedBy?: InputMaybe<UserUpdateManyWithoutFollowingNestedInput>;
  gender?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  groupsJoin?: InputMaybe<GroupUpdateManyWithoutMembersNestedInput>;
  groupsOwn?: InputMaybe<GroupUpdateManyWithoutOwnerNestedInput>;
  headUri?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  history?: InputMaybe<OrderDetailHistoryUpdateManyWithoutOperatorNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  idStr?: InputMaybe<StringFieldUpdateOperationsInput>;
  inFriends?: InputMaybe<UserFriendUpdateManyWithoutUsersNestedInput>;
  inValidReasonHistories?: InputMaybe<InValidReasonUpdateManyWithoutUserNestedInput>;
  lastLogin?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  lastTokenUpdated?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  likePosts?: InputMaybe<PostUpdateManyWithoutLikeUsersNestedInput>;
  manualAddrs?: InputMaybe<ManualAddrUpdateManyWithoutOwnerNestedInput>;
  myCoupons?: InputMaybe<CouponEntityUpdateManyWithoutHolderNestedInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  notificationReceiver?: InputMaybe<NotificationUpdateManyWithoutReceiverNestedInput>;
  notificationSender?: InputMaybe<NotificationUpdateManyWithoutSenderNestedInput>;
  order?: InputMaybe<OrderUpdateManyWithoutSenderNestedInput>;
  packagesAsReceiver?: InputMaybe<PostPackageUpdateManyWithoutReceiversNestedInput>;
  password?: InputMaybe<StringFieldUpdateOperationsInput>;
  pcoins?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  phone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  postPackage?: InputMaybe<PostPackageUpdateManyWithoutSenderNestedInput>;
  postsIn?: InputMaybe<PostUpdateManyWithoutReceiverNestedInput>;
  postsOut?: InputMaybe<PostUpdateManyWithoutSenderNestedInput>;
  profitable?: InputMaybe<ProfitableUpdateOneWithoutUserNestedInput>;
  receive_email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  receive_phone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  reportByMe?: InputMaybe<ReportUserUpdateManyWithoutReporterNestedInput>;
  reported?: InputMaybe<ReportUserUpdateManyWithoutUserNestedInput>;
  reports?: InputMaybe<ReportUpdateManyWithoutReporterNestedInput>;
  settingNotification?: InputMaybe<SettingNotificationUpdateOneWithoutUserNestedInput>;
  settingPrivacy?: InputMaybe<SettingPrivacyUpdateOneWithoutUserNestedInput>;
  signature?: InputMaybe<SignatureUpdateManyWithoutOwnerNestedInput>;
  storePurchaseHistory?: InputMaybe<CartItemUpdateManyWithoutCustomerNestedInput>;
  tagsUsed?: InputMaybe<PostTagUpdateManyWithoutUserNestedInput>;
  thirdpartyId?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  vAddrMapping?: InputMaybe<VirtualAddrMappingUpdateOneWithoutOwnerNestedInput>;
  valid?: InputMaybe<BoolFieldUpdateOperationsInput>;
  website?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type UserUpdateWithoutFriendsInput = {
  accessToken?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  address?: InputMaybe<AddressUpdateManyWithoutOwnerNestedInput>;
  attachments?: InputMaybe<AttachmentUpdateManyWithoutOwnerNestedInput>;
  authType?: InputMaybe<EnumUserAuthTypeFieldUpdateOperationsInput>;
  bio?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  campaignNanhui?: InputMaybe<CampaignNanhuiUpdateManyWithoutSubmitterNestedInput>;
  campainZeczec?: InputMaybe<CampainZeczecUpdateOneWithoutRegisterUserNestedInput>;
  card?: InputMaybe<CardUpdateManyWithoutCreatorNestedInput>;
  coins?: InputMaybe<CoinUpdateManyWithoutUserNestedInput>;
  collection?: InputMaybe<CollectionUpdateManyWithoutOwnerNestedInput>;
  country?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  couponUsages?: InputMaybe<CouponUsageUpdateManyWithoutUserNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  createdStamps?: InputMaybe<StampUpdateManyWithoutCreatorNestedInput>;
  detail?: InputMaybe<OrderDetailUpdateManyWithoutReceiverNestedInput>;
  deviceToken?: InputMaybe<DeviceTokenUpdateManyWithoutUserNestedInput>;
  email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  emailTos?: InputMaybe<EmailToUpdateManyWithoutOwnerNestedInput>;
  favoriteStoreItems?: InputMaybe<StoreItemUpdateManyWithoutFavoriteByUsersNestedInput>;
  followProfitables?: InputMaybe<ProfitableUpdateManyWithoutFollowedByNestedInput>;
  followedBy?: InputMaybe<UserUpdateManyWithoutFollowingNestedInput>;
  following?: InputMaybe<UserUpdateManyWithoutFollowedByNestedInput>;
  gender?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  groupsJoin?: InputMaybe<GroupUpdateManyWithoutMembersNestedInput>;
  groupsOwn?: InputMaybe<GroupUpdateManyWithoutOwnerNestedInput>;
  headUri?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  history?: InputMaybe<OrderDetailHistoryUpdateManyWithoutOperatorNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  idStr?: InputMaybe<StringFieldUpdateOperationsInput>;
  inFriends?: InputMaybe<UserFriendUpdateManyWithoutUsersNestedInput>;
  inValidReasonHistories?: InputMaybe<InValidReasonUpdateManyWithoutUserNestedInput>;
  lastLogin?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  lastTokenUpdated?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  likePosts?: InputMaybe<PostUpdateManyWithoutLikeUsersNestedInput>;
  manualAddrs?: InputMaybe<ManualAddrUpdateManyWithoutOwnerNestedInput>;
  myCoupons?: InputMaybe<CouponEntityUpdateManyWithoutHolderNestedInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  notificationReceiver?: InputMaybe<NotificationUpdateManyWithoutReceiverNestedInput>;
  notificationSender?: InputMaybe<NotificationUpdateManyWithoutSenderNestedInput>;
  order?: InputMaybe<OrderUpdateManyWithoutSenderNestedInput>;
  packagesAsReceiver?: InputMaybe<PostPackageUpdateManyWithoutReceiversNestedInput>;
  password?: InputMaybe<StringFieldUpdateOperationsInput>;
  pcoins?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  phone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  postPackage?: InputMaybe<PostPackageUpdateManyWithoutSenderNestedInput>;
  postsIn?: InputMaybe<PostUpdateManyWithoutReceiverNestedInput>;
  postsOut?: InputMaybe<PostUpdateManyWithoutSenderNestedInput>;
  profitable?: InputMaybe<ProfitableUpdateOneWithoutUserNestedInput>;
  receive_email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  receive_phone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  reportByMe?: InputMaybe<ReportUserUpdateManyWithoutReporterNestedInput>;
  reported?: InputMaybe<ReportUserUpdateManyWithoutUserNestedInput>;
  reports?: InputMaybe<ReportUpdateManyWithoutReporterNestedInput>;
  settingNotification?: InputMaybe<SettingNotificationUpdateOneWithoutUserNestedInput>;
  settingPrivacy?: InputMaybe<SettingPrivacyUpdateOneWithoutUserNestedInput>;
  signature?: InputMaybe<SignatureUpdateManyWithoutOwnerNestedInput>;
  storePurchaseHistory?: InputMaybe<CartItemUpdateManyWithoutCustomerNestedInput>;
  tagsUsed?: InputMaybe<PostTagUpdateManyWithoutUserNestedInput>;
  thirdpartyId?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  vAddrMapping?: InputMaybe<VirtualAddrMappingUpdateOneWithoutOwnerNestedInput>;
  valid?: InputMaybe<BoolFieldUpdateOperationsInput>;
  website?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type UserUpdateWithoutGroupsJoinInput = {
  accessToken?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  address?: InputMaybe<AddressUpdateManyWithoutOwnerNestedInput>;
  attachments?: InputMaybe<AttachmentUpdateManyWithoutOwnerNestedInput>;
  authType?: InputMaybe<EnumUserAuthTypeFieldUpdateOperationsInput>;
  bio?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  campaignNanhui?: InputMaybe<CampaignNanhuiUpdateManyWithoutSubmitterNestedInput>;
  campainZeczec?: InputMaybe<CampainZeczecUpdateOneWithoutRegisterUserNestedInput>;
  card?: InputMaybe<CardUpdateManyWithoutCreatorNestedInput>;
  coins?: InputMaybe<CoinUpdateManyWithoutUserNestedInput>;
  collection?: InputMaybe<CollectionUpdateManyWithoutOwnerNestedInput>;
  country?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  couponUsages?: InputMaybe<CouponUsageUpdateManyWithoutUserNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  createdStamps?: InputMaybe<StampUpdateManyWithoutCreatorNestedInput>;
  detail?: InputMaybe<OrderDetailUpdateManyWithoutReceiverNestedInput>;
  deviceToken?: InputMaybe<DeviceTokenUpdateManyWithoutUserNestedInput>;
  email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  emailTos?: InputMaybe<EmailToUpdateManyWithoutOwnerNestedInput>;
  favoriteStoreItems?: InputMaybe<StoreItemUpdateManyWithoutFavoriteByUsersNestedInput>;
  fetchFriends?: InputMaybe<UserFriendUpdateManyWithoutOwnerNestedInput>;
  followProfitables?: InputMaybe<ProfitableUpdateManyWithoutFollowedByNestedInput>;
  followedBy?: InputMaybe<UserUpdateManyWithoutFollowingNestedInput>;
  following?: InputMaybe<UserUpdateManyWithoutFollowedByNestedInput>;
  gender?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  groupsOwn?: InputMaybe<GroupUpdateManyWithoutOwnerNestedInput>;
  headUri?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  history?: InputMaybe<OrderDetailHistoryUpdateManyWithoutOperatorNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  idStr?: InputMaybe<StringFieldUpdateOperationsInput>;
  inFriends?: InputMaybe<UserFriendUpdateManyWithoutUsersNestedInput>;
  inValidReasonHistories?: InputMaybe<InValidReasonUpdateManyWithoutUserNestedInput>;
  lastLogin?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  lastTokenUpdated?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  likePosts?: InputMaybe<PostUpdateManyWithoutLikeUsersNestedInput>;
  manualAddrs?: InputMaybe<ManualAddrUpdateManyWithoutOwnerNestedInput>;
  myCoupons?: InputMaybe<CouponEntityUpdateManyWithoutHolderNestedInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  notificationReceiver?: InputMaybe<NotificationUpdateManyWithoutReceiverNestedInput>;
  notificationSender?: InputMaybe<NotificationUpdateManyWithoutSenderNestedInput>;
  order?: InputMaybe<OrderUpdateManyWithoutSenderNestedInput>;
  packagesAsReceiver?: InputMaybe<PostPackageUpdateManyWithoutReceiversNestedInput>;
  password?: InputMaybe<StringFieldUpdateOperationsInput>;
  pcoins?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  phone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  postPackage?: InputMaybe<PostPackageUpdateManyWithoutSenderNestedInput>;
  postsIn?: InputMaybe<PostUpdateManyWithoutReceiverNestedInput>;
  postsOut?: InputMaybe<PostUpdateManyWithoutSenderNestedInput>;
  profitable?: InputMaybe<ProfitableUpdateOneWithoutUserNestedInput>;
  receive_email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  receive_phone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  reportByMe?: InputMaybe<ReportUserUpdateManyWithoutReporterNestedInput>;
  reported?: InputMaybe<ReportUserUpdateManyWithoutUserNestedInput>;
  reports?: InputMaybe<ReportUpdateManyWithoutReporterNestedInput>;
  settingNotification?: InputMaybe<SettingNotificationUpdateOneWithoutUserNestedInput>;
  settingPrivacy?: InputMaybe<SettingPrivacyUpdateOneWithoutUserNestedInput>;
  signature?: InputMaybe<SignatureUpdateManyWithoutOwnerNestedInput>;
  storePurchaseHistory?: InputMaybe<CartItemUpdateManyWithoutCustomerNestedInput>;
  tagsUsed?: InputMaybe<PostTagUpdateManyWithoutUserNestedInput>;
  thirdpartyId?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  vAddrMapping?: InputMaybe<VirtualAddrMappingUpdateOneWithoutOwnerNestedInput>;
  valid?: InputMaybe<BoolFieldUpdateOperationsInput>;
  website?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type UserUpdateWithoutGroupsOwnInput = {
  accessToken?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  address?: InputMaybe<AddressUpdateManyWithoutOwnerNestedInput>;
  attachments?: InputMaybe<AttachmentUpdateManyWithoutOwnerNestedInput>;
  authType?: InputMaybe<EnumUserAuthTypeFieldUpdateOperationsInput>;
  bio?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  campaignNanhui?: InputMaybe<CampaignNanhuiUpdateManyWithoutSubmitterNestedInput>;
  campainZeczec?: InputMaybe<CampainZeczecUpdateOneWithoutRegisterUserNestedInput>;
  card?: InputMaybe<CardUpdateManyWithoutCreatorNestedInput>;
  coins?: InputMaybe<CoinUpdateManyWithoutUserNestedInput>;
  collection?: InputMaybe<CollectionUpdateManyWithoutOwnerNestedInput>;
  country?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  couponUsages?: InputMaybe<CouponUsageUpdateManyWithoutUserNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  createdStamps?: InputMaybe<StampUpdateManyWithoutCreatorNestedInput>;
  detail?: InputMaybe<OrderDetailUpdateManyWithoutReceiverNestedInput>;
  deviceToken?: InputMaybe<DeviceTokenUpdateManyWithoutUserNestedInput>;
  email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  emailTos?: InputMaybe<EmailToUpdateManyWithoutOwnerNestedInput>;
  favoriteStoreItems?: InputMaybe<StoreItemUpdateManyWithoutFavoriteByUsersNestedInput>;
  fetchFriends?: InputMaybe<UserFriendUpdateManyWithoutOwnerNestedInput>;
  followProfitables?: InputMaybe<ProfitableUpdateManyWithoutFollowedByNestedInput>;
  followedBy?: InputMaybe<UserUpdateManyWithoutFollowingNestedInput>;
  following?: InputMaybe<UserUpdateManyWithoutFollowedByNestedInput>;
  gender?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  groupsJoin?: InputMaybe<GroupUpdateManyWithoutMembersNestedInput>;
  headUri?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  history?: InputMaybe<OrderDetailHistoryUpdateManyWithoutOperatorNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  idStr?: InputMaybe<StringFieldUpdateOperationsInput>;
  inFriends?: InputMaybe<UserFriendUpdateManyWithoutUsersNestedInput>;
  inValidReasonHistories?: InputMaybe<InValidReasonUpdateManyWithoutUserNestedInput>;
  lastLogin?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  lastTokenUpdated?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  likePosts?: InputMaybe<PostUpdateManyWithoutLikeUsersNestedInput>;
  manualAddrs?: InputMaybe<ManualAddrUpdateManyWithoutOwnerNestedInput>;
  myCoupons?: InputMaybe<CouponEntityUpdateManyWithoutHolderNestedInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  notificationReceiver?: InputMaybe<NotificationUpdateManyWithoutReceiverNestedInput>;
  notificationSender?: InputMaybe<NotificationUpdateManyWithoutSenderNestedInput>;
  order?: InputMaybe<OrderUpdateManyWithoutSenderNestedInput>;
  packagesAsReceiver?: InputMaybe<PostPackageUpdateManyWithoutReceiversNestedInput>;
  password?: InputMaybe<StringFieldUpdateOperationsInput>;
  pcoins?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  phone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  postPackage?: InputMaybe<PostPackageUpdateManyWithoutSenderNestedInput>;
  postsIn?: InputMaybe<PostUpdateManyWithoutReceiverNestedInput>;
  postsOut?: InputMaybe<PostUpdateManyWithoutSenderNestedInput>;
  profitable?: InputMaybe<ProfitableUpdateOneWithoutUserNestedInput>;
  receive_email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  receive_phone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  reportByMe?: InputMaybe<ReportUserUpdateManyWithoutReporterNestedInput>;
  reported?: InputMaybe<ReportUserUpdateManyWithoutUserNestedInput>;
  reports?: InputMaybe<ReportUpdateManyWithoutReporterNestedInput>;
  settingNotification?: InputMaybe<SettingNotificationUpdateOneWithoutUserNestedInput>;
  settingPrivacy?: InputMaybe<SettingPrivacyUpdateOneWithoutUserNestedInput>;
  signature?: InputMaybe<SignatureUpdateManyWithoutOwnerNestedInput>;
  storePurchaseHistory?: InputMaybe<CartItemUpdateManyWithoutCustomerNestedInput>;
  tagsUsed?: InputMaybe<PostTagUpdateManyWithoutUserNestedInput>;
  thirdpartyId?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  vAddrMapping?: InputMaybe<VirtualAddrMappingUpdateOneWithoutOwnerNestedInput>;
  valid?: InputMaybe<BoolFieldUpdateOperationsInput>;
  website?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type UserUpdateWithoutHistoryInput = {
  accessToken?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  address?: InputMaybe<AddressUpdateManyWithoutOwnerNestedInput>;
  attachments?: InputMaybe<AttachmentUpdateManyWithoutOwnerNestedInput>;
  authType?: InputMaybe<EnumUserAuthTypeFieldUpdateOperationsInput>;
  bio?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  campaignNanhui?: InputMaybe<CampaignNanhuiUpdateManyWithoutSubmitterNestedInput>;
  campainZeczec?: InputMaybe<CampainZeczecUpdateOneWithoutRegisterUserNestedInput>;
  card?: InputMaybe<CardUpdateManyWithoutCreatorNestedInput>;
  coins?: InputMaybe<CoinUpdateManyWithoutUserNestedInput>;
  collection?: InputMaybe<CollectionUpdateManyWithoutOwnerNestedInput>;
  country?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  couponUsages?: InputMaybe<CouponUsageUpdateManyWithoutUserNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  createdStamps?: InputMaybe<StampUpdateManyWithoutCreatorNestedInput>;
  detail?: InputMaybe<OrderDetailUpdateManyWithoutReceiverNestedInput>;
  deviceToken?: InputMaybe<DeviceTokenUpdateManyWithoutUserNestedInput>;
  email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  emailTos?: InputMaybe<EmailToUpdateManyWithoutOwnerNestedInput>;
  favoriteStoreItems?: InputMaybe<StoreItemUpdateManyWithoutFavoriteByUsersNestedInput>;
  fetchFriends?: InputMaybe<UserFriendUpdateManyWithoutOwnerNestedInput>;
  followProfitables?: InputMaybe<ProfitableUpdateManyWithoutFollowedByNestedInput>;
  followedBy?: InputMaybe<UserUpdateManyWithoutFollowingNestedInput>;
  following?: InputMaybe<UserUpdateManyWithoutFollowedByNestedInput>;
  gender?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  groupsJoin?: InputMaybe<GroupUpdateManyWithoutMembersNestedInput>;
  groupsOwn?: InputMaybe<GroupUpdateManyWithoutOwnerNestedInput>;
  headUri?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  idStr?: InputMaybe<StringFieldUpdateOperationsInput>;
  inFriends?: InputMaybe<UserFriendUpdateManyWithoutUsersNestedInput>;
  inValidReasonHistories?: InputMaybe<InValidReasonUpdateManyWithoutUserNestedInput>;
  lastLogin?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  lastTokenUpdated?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  likePosts?: InputMaybe<PostUpdateManyWithoutLikeUsersNestedInput>;
  manualAddrs?: InputMaybe<ManualAddrUpdateManyWithoutOwnerNestedInput>;
  myCoupons?: InputMaybe<CouponEntityUpdateManyWithoutHolderNestedInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  notificationReceiver?: InputMaybe<NotificationUpdateManyWithoutReceiverNestedInput>;
  notificationSender?: InputMaybe<NotificationUpdateManyWithoutSenderNestedInput>;
  order?: InputMaybe<OrderUpdateManyWithoutSenderNestedInput>;
  packagesAsReceiver?: InputMaybe<PostPackageUpdateManyWithoutReceiversNestedInput>;
  password?: InputMaybe<StringFieldUpdateOperationsInput>;
  pcoins?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  phone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  postPackage?: InputMaybe<PostPackageUpdateManyWithoutSenderNestedInput>;
  postsIn?: InputMaybe<PostUpdateManyWithoutReceiverNestedInput>;
  postsOut?: InputMaybe<PostUpdateManyWithoutSenderNestedInput>;
  profitable?: InputMaybe<ProfitableUpdateOneWithoutUserNestedInput>;
  receive_email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  receive_phone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  reportByMe?: InputMaybe<ReportUserUpdateManyWithoutReporterNestedInput>;
  reported?: InputMaybe<ReportUserUpdateManyWithoutUserNestedInput>;
  reports?: InputMaybe<ReportUpdateManyWithoutReporterNestedInput>;
  settingNotification?: InputMaybe<SettingNotificationUpdateOneWithoutUserNestedInput>;
  settingPrivacy?: InputMaybe<SettingPrivacyUpdateOneWithoutUserNestedInput>;
  signature?: InputMaybe<SignatureUpdateManyWithoutOwnerNestedInput>;
  storePurchaseHistory?: InputMaybe<CartItemUpdateManyWithoutCustomerNestedInput>;
  tagsUsed?: InputMaybe<PostTagUpdateManyWithoutUserNestedInput>;
  thirdpartyId?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  vAddrMapping?: InputMaybe<VirtualAddrMappingUpdateOneWithoutOwnerNestedInput>;
  valid?: InputMaybe<BoolFieldUpdateOperationsInput>;
  website?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type UserUpdateWithoutInFriendsInput = {
  accessToken?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  address?: InputMaybe<AddressUpdateManyWithoutOwnerNestedInput>;
  attachments?: InputMaybe<AttachmentUpdateManyWithoutOwnerNestedInput>;
  authType?: InputMaybe<EnumUserAuthTypeFieldUpdateOperationsInput>;
  bio?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  campaignNanhui?: InputMaybe<CampaignNanhuiUpdateManyWithoutSubmitterNestedInput>;
  campainZeczec?: InputMaybe<CampainZeczecUpdateOneWithoutRegisterUserNestedInput>;
  card?: InputMaybe<CardUpdateManyWithoutCreatorNestedInput>;
  coins?: InputMaybe<CoinUpdateManyWithoutUserNestedInput>;
  collection?: InputMaybe<CollectionUpdateManyWithoutOwnerNestedInput>;
  country?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  couponUsages?: InputMaybe<CouponUsageUpdateManyWithoutUserNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  createdStamps?: InputMaybe<StampUpdateManyWithoutCreatorNestedInput>;
  detail?: InputMaybe<OrderDetailUpdateManyWithoutReceiverNestedInput>;
  deviceToken?: InputMaybe<DeviceTokenUpdateManyWithoutUserNestedInput>;
  email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  emailTos?: InputMaybe<EmailToUpdateManyWithoutOwnerNestedInput>;
  favoriteStoreItems?: InputMaybe<StoreItemUpdateManyWithoutFavoriteByUsersNestedInput>;
  fetchFriends?: InputMaybe<UserFriendUpdateManyWithoutOwnerNestedInput>;
  followProfitables?: InputMaybe<ProfitableUpdateManyWithoutFollowedByNestedInput>;
  followedBy?: InputMaybe<UserUpdateManyWithoutFollowingNestedInput>;
  following?: InputMaybe<UserUpdateManyWithoutFollowedByNestedInput>;
  gender?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  groupsJoin?: InputMaybe<GroupUpdateManyWithoutMembersNestedInput>;
  groupsOwn?: InputMaybe<GroupUpdateManyWithoutOwnerNestedInput>;
  headUri?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  history?: InputMaybe<OrderDetailHistoryUpdateManyWithoutOperatorNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  idStr?: InputMaybe<StringFieldUpdateOperationsInput>;
  inValidReasonHistories?: InputMaybe<InValidReasonUpdateManyWithoutUserNestedInput>;
  lastLogin?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  lastTokenUpdated?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  likePosts?: InputMaybe<PostUpdateManyWithoutLikeUsersNestedInput>;
  manualAddrs?: InputMaybe<ManualAddrUpdateManyWithoutOwnerNestedInput>;
  myCoupons?: InputMaybe<CouponEntityUpdateManyWithoutHolderNestedInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  notificationReceiver?: InputMaybe<NotificationUpdateManyWithoutReceiverNestedInput>;
  notificationSender?: InputMaybe<NotificationUpdateManyWithoutSenderNestedInput>;
  order?: InputMaybe<OrderUpdateManyWithoutSenderNestedInput>;
  packagesAsReceiver?: InputMaybe<PostPackageUpdateManyWithoutReceiversNestedInput>;
  password?: InputMaybe<StringFieldUpdateOperationsInput>;
  pcoins?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  phone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  postPackage?: InputMaybe<PostPackageUpdateManyWithoutSenderNestedInput>;
  postsIn?: InputMaybe<PostUpdateManyWithoutReceiverNestedInput>;
  postsOut?: InputMaybe<PostUpdateManyWithoutSenderNestedInput>;
  profitable?: InputMaybe<ProfitableUpdateOneWithoutUserNestedInput>;
  receive_email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  receive_phone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  reportByMe?: InputMaybe<ReportUserUpdateManyWithoutReporterNestedInput>;
  reported?: InputMaybe<ReportUserUpdateManyWithoutUserNestedInput>;
  reports?: InputMaybe<ReportUpdateManyWithoutReporterNestedInput>;
  settingNotification?: InputMaybe<SettingNotificationUpdateOneWithoutUserNestedInput>;
  settingPrivacy?: InputMaybe<SettingPrivacyUpdateOneWithoutUserNestedInput>;
  signature?: InputMaybe<SignatureUpdateManyWithoutOwnerNestedInput>;
  storePurchaseHistory?: InputMaybe<CartItemUpdateManyWithoutCustomerNestedInput>;
  tagsUsed?: InputMaybe<PostTagUpdateManyWithoutUserNestedInput>;
  thirdpartyId?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  vAddrMapping?: InputMaybe<VirtualAddrMappingUpdateOneWithoutOwnerNestedInput>;
  valid?: InputMaybe<BoolFieldUpdateOperationsInput>;
  website?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type UserUpdateWithoutInValidReasonHistoriesInput = {
  accessToken?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  address?: InputMaybe<AddressUpdateManyWithoutOwnerNestedInput>;
  attachments?: InputMaybe<AttachmentUpdateManyWithoutOwnerNestedInput>;
  authType?: InputMaybe<EnumUserAuthTypeFieldUpdateOperationsInput>;
  bio?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  campaignNanhui?: InputMaybe<CampaignNanhuiUpdateManyWithoutSubmitterNestedInput>;
  campainZeczec?: InputMaybe<CampainZeczecUpdateOneWithoutRegisterUserNestedInput>;
  card?: InputMaybe<CardUpdateManyWithoutCreatorNestedInput>;
  coins?: InputMaybe<CoinUpdateManyWithoutUserNestedInput>;
  collection?: InputMaybe<CollectionUpdateManyWithoutOwnerNestedInput>;
  country?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  couponUsages?: InputMaybe<CouponUsageUpdateManyWithoutUserNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  createdStamps?: InputMaybe<StampUpdateManyWithoutCreatorNestedInput>;
  detail?: InputMaybe<OrderDetailUpdateManyWithoutReceiverNestedInput>;
  deviceToken?: InputMaybe<DeviceTokenUpdateManyWithoutUserNestedInput>;
  email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  emailTos?: InputMaybe<EmailToUpdateManyWithoutOwnerNestedInput>;
  favoriteStoreItems?: InputMaybe<StoreItemUpdateManyWithoutFavoriteByUsersNestedInput>;
  fetchFriends?: InputMaybe<UserFriendUpdateManyWithoutOwnerNestedInput>;
  followProfitables?: InputMaybe<ProfitableUpdateManyWithoutFollowedByNestedInput>;
  followedBy?: InputMaybe<UserUpdateManyWithoutFollowingNestedInput>;
  following?: InputMaybe<UserUpdateManyWithoutFollowedByNestedInput>;
  gender?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  groupsJoin?: InputMaybe<GroupUpdateManyWithoutMembersNestedInput>;
  groupsOwn?: InputMaybe<GroupUpdateManyWithoutOwnerNestedInput>;
  headUri?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  history?: InputMaybe<OrderDetailHistoryUpdateManyWithoutOperatorNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  idStr?: InputMaybe<StringFieldUpdateOperationsInput>;
  inFriends?: InputMaybe<UserFriendUpdateManyWithoutUsersNestedInput>;
  lastLogin?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  lastTokenUpdated?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  likePosts?: InputMaybe<PostUpdateManyWithoutLikeUsersNestedInput>;
  manualAddrs?: InputMaybe<ManualAddrUpdateManyWithoutOwnerNestedInput>;
  myCoupons?: InputMaybe<CouponEntityUpdateManyWithoutHolderNestedInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  notificationReceiver?: InputMaybe<NotificationUpdateManyWithoutReceiverNestedInput>;
  notificationSender?: InputMaybe<NotificationUpdateManyWithoutSenderNestedInput>;
  order?: InputMaybe<OrderUpdateManyWithoutSenderNestedInput>;
  packagesAsReceiver?: InputMaybe<PostPackageUpdateManyWithoutReceiversNestedInput>;
  password?: InputMaybe<StringFieldUpdateOperationsInput>;
  pcoins?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  phone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  postPackage?: InputMaybe<PostPackageUpdateManyWithoutSenderNestedInput>;
  postsIn?: InputMaybe<PostUpdateManyWithoutReceiverNestedInput>;
  postsOut?: InputMaybe<PostUpdateManyWithoutSenderNestedInput>;
  profitable?: InputMaybe<ProfitableUpdateOneWithoutUserNestedInput>;
  receive_email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  receive_phone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  reportByMe?: InputMaybe<ReportUserUpdateManyWithoutReporterNestedInput>;
  reported?: InputMaybe<ReportUserUpdateManyWithoutUserNestedInput>;
  reports?: InputMaybe<ReportUpdateManyWithoutReporterNestedInput>;
  settingNotification?: InputMaybe<SettingNotificationUpdateOneWithoutUserNestedInput>;
  settingPrivacy?: InputMaybe<SettingPrivacyUpdateOneWithoutUserNestedInput>;
  signature?: InputMaybe<SignatureUpdateManyWithoutOwnerNestedInput>;
  storePurchaseHistory?: InputMaybe<CartItemUpdateManyWithoutCustomerNestedInput>;
  tagsUsed?: InputMaybe<PostTagUpdateManyWithoutUserNestedInput>;
  thirdpartyId?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  vAddrMapping?: InputMaybe<VirtualAddrMappingUpdateOneWithoutOwnerNestedInput>;
  valid?: InputMaybe<BoolFieldUpdateOperationsInput>;
  website?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type UserUpdateWithoutLikePostsInput = {
  accessToken?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  address?: InputMaybe<AddressUpdateManyWithoutOwnerNestedInput>;
  attachments?: InputMaybe<AttachmentUpdateManyWithoutOwnerNestedInput>;
  authType?: InputMaybe<EnumUserAuthTypeFieldUpdateOperationsInput>;
  bio?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  campaignNanhui?: InputMaybe<CampaignNanhuiUpdateManyWithoutSubmitterNestedInput>;
  campainZeczec?: InputMaybe<CampainZeczecUpdateOneWithoutRegisterUserNestedInput>;
  card?: InputMaybe<CardUpdateManyWithoutCreatorNestedInput>;
  coins?: InputMaybe<CoinUpdateManyWithoutUserNestedInput>;
  collection?: InputMaybe<CollectionUpdateManyWithoutOwnerNestedInput>;
  country?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  couponUsages?: InputMaybe<CouponUsageUpdateManyWithoutUserNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  createdStamps?: InputMaybe<StampUpdateManyWithoutCreatorNestedInput>;
  detail?: InputMaybe<OrderDetailUpdateManyWithoutReceiverNestedInput>;
  deviceToken?: InputMaybe<DeviceTokenUpdateManyWithoutUserNestedInput>;
  email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  emailTos?: InputMaybe<EmailToUpdateManyWithoutOwnerNestedInput>;
  favoriteStoreItems?: InputMaybe<StoreItemUpdateManyWithoutFavoriteByUsersNestedInput>;
  fetchFriends?: InputMaybe<UserFriendUpdateManyWithoutOwnerNestedInput>;
  followProfitables?: InputMaybe<ProfitableUpdateManyWithoutFollowedByNestedInput>;
  followedBy?: InputMaybe<UserUpdateManyWithoutFollowingNestedInput>;
  following?: InputMaybe<UserUpdateManyWithoutFollowedByNestedInput>;
  gender?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  groupsJoin?: InputMaybe<GroupUpdateManyWithoutMembersNestedInput>;
  groupsOwn?: InputMaybe<GroupUpdateManyWithoutOwnerNestedInput>;
  headUri?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  history?: InputMaybe<OrderDetailHistoryUpdateManyWithoutOperatorNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  idStr?: InputMaybe<StringFieldUpdateOperationsInput>;
  inFriends?: InputMaybe<UserFriendUpdateManyWithoutUsersNestedInput>;
  inValidReasonHistories?: InputMaybe<InValidReasonUpdateManyWithoutUserNestedInput>;
  lastLogin?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  lastTokenUpdated?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  manualAddrs?: InputMaybe<ManualAddrUpdateManyWithoutOwnerNestedInput>;
  myCoupons?: InputMaybe<CouponEntityUpdateManyWithoutHolderNestedInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  notificationReceiver?: InputMaybe<NotificationUpdateManyWithoutReceiverNestedInput>;
  notificationSender?: InputMaybe<NotificationUpdateManyWithoutSenderNestedInput>;
  order?: InputMaybe<OrderUpdateManyWithoutSenderNestedInput>;
  packagesAsReceiver?: InputMaybe<PostPackageUpdateManyWithoutReceiversNestedInput>;
  password?: InputMaybe<StringFieldUpdateOperationsInput>;
  pcoins?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  phone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  postPackage?: InputMaybe<PostPackageUpdateManyWithoutSenderNestedInput>;
  postsIn?: InputMaybe<PostUpdateManyWithoutReceiverNestedInput>;
  postsOut?: InputMaybe<PostUpdateManyWithoutSenderNestedInput>;
  profitable?: InputMaybe<ProfitableUpdateOneWithoutUserNestedInput>;
  receive_email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  receive_phone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  reportByMe?: InputMaybe<ReportUserUpdateManyWithoutReporterNestedInput>;
  reported?: InputMaybe<ReportUserUpdateManyWithoutUserNestedInput>;
  reports?: InputMaybe<ReportUpdateManyWithoutReporterNestedInput>;
  settingNotification?: InputMaybe<SettingNotificationUpdateOneWithoutUserNestedInput>;
  settingPrivacy?: InputMaybe<SettingPrivacyUpdateOneWithoutUserNestedInput>;
  signature?: InputMaybe<SignatureUpdateManyWithoutOwnerNestedInput>;
  storePurchaseHistory?: InputMaybe<CartItemUpdateManyWithoutCustomerNestedInput>;
  tagsUsed?: InputMaybe<PostTagUpdateManyWithoutUserNestedInput>;
  thirdpartyId?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  vAddrMapping?: InputMaybe<VirtualAddrMappingUpdateOneWithoutOwnerNestedInput>;
  valid?: InputMaybe<BoolFieldUpdateOperationsInput>;
  website?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type UserUpdateWithoutManualAddrsInput = {
  accessToken?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  address?: InputMaybe<AddressUpdateManyWithoutOwnerNestedInput>;
  attachments?: InputMaybe<AttachmentUpdateManyWithoutOwnerNestedInput>;
  authType?: InputMaybe<EnumUserAuthTypeFieldUpdateOperationsInput>;
  bio?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  campaignNanhui?: InputMaybe<CampaignNanhuiUpdateManyWithoutSubmitterNestedInput>;
  campainZeczec?: InputMaybe<CampainZeczecUpdateOneWithoutRegisterUserNestedInput>;
  card?: InputMaybe<CardUpdateManyWithoutCreatorNestedInput>;
  coins?: InputMaybe<CoinUpdateManyWithoutUserNestedInput>;
  collection?: InputMaybe<CollectionUpdateManyWithoutOwnerNestedInput>;
  country?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  couponUsages?: InputMaybe<CouponUsageUpdateManyWithoutUserNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  createdStamps?: InputMaybe<StampUpdateManyWithoutCreatorNestedInput>;
  detail?: InputMaybe<OrderDetailUpdateManyWithoutReceiverNestedInput>;
  deviceToken?: InputMaybe<DeviceTokenUpdateManyWithoutUserNestedInput>;
  email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  emailTos?: InputMaybe<EmailToUpdateManyWithoutOwnerNestedInput>;
  favoriteStoreItems?: InputMaybe<StoreItemUpdateManyWithoutFavoriteByUsersNestedInput>;
  fetchFriends?: InputMaybe<UserFriendUpdateManyWithoutOwnerNestedInput>;
  followProfitables?: InputMaybe<ProfitableUpdateManyWithoutFollowedByNestedInput>;
  followedBy?: InputMaybe<UserUpdateManyWithoutFollowingNestedInput>;
  following?: InputMaybe<UserUpdateManyWithoutFollowedByNestedInput>;
  gender?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  groupsJoin?: InputMaybe<GroupUpdateManyWithoutMembersNestedInput>;
  groupsOwn?: InputMaybe<GroupUpdateManyWithoutOwnerNestedInput>;
  headUri?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  history?: InputMaybe<OrderDetailHistoryUpdateManyWithoutOperatorNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  idStr?: InputMaybe<StringFieldUpdateOperationsInput>;
  inFriends?: InputMaybe<UserFriendUpdateManyWithoutUsersNestedInput>;
  inValidReasonHistories?: InputMaybe<InValidReasonUpdateManyWithoutUserNestedInput>;
  lastLogin?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  lastTokenUpdated?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  likePosts?: InputMaybe<PostUpdateManyWithoutLikeUsersNestedInput>;
  myCoupons?: InputMaybe<CouponEntityUpdateManyWithoutHolderNestedInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  notificationReceiver?: InputMaybe<NotificationUpdateManyWithoutReceiverNestedInput>;
  notificationSender?: InputMaybe<NotificationUpdateManyWithoutSenderNestedInput>;
  order?: InputMaybe<OrderUpdateManyWithoutSenderNestedInput>;
  packagesAsReceiver?: InputMaybe<PostPackageUpdateManyWithoutReceiversNestedInput>;
  password?: InputMaybe<StringFieldUpdateOperationsInput>;
  pcoins?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  phone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  postPackage?: InputMaybe<PostPackageUpdateManyWithoutSenderNestedInput>;
  postsIn?: InputMaybe<PostUpdateManyWithoutReceiverNestedInput>;
  postsOut?: InputMaybe<PostUpdateManyWithoutSenderNestedInput>;
  profitable?: InputMaybe<ProfitableUpdateOneWithoutUserNestedInput>;
  receive_email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  receive_phone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  reportByMe?: InputMaybe<ReportUserUpdateManyWithoutReporterNestedInput>;
  reported?: InputMaybe<ReportUserUpdateManyWithoutUserNestedInput>;
  reports?: InputMaybe<ReportUpdateManyWithoutReporterNestedInput>;
  settingNotification?: InputMaybe<SettingNotificationUpdateOneWithoutUserNestedInput>;
  settingPrivacy?: InputMaybe<SettingPrivacyUpdateOneWithoutUserNestedInput>;
  signature?: InputMaybe<SignatureUpdateManyWithoutOwnerNestedInput>;
  storePurchaseHistory?: InputMaybe<CartItemUpdateManyWithoutCustomerNestedInput>;
  tagsUsed?: InputMaybe<PostTagUpdateManyWithoutUserNestedInput>;
  thirdpartyId?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  vAddrMapping?: InputMaybe<VirtualAddrMappingUpdateOneWithoutOwnerNestedInput>;
  valid?: InputMaybe<BoolFieldUpdateOperationsInput>;
  website?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type UserUpdateWithoutMyCouponsInput = {
  accessToken?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  address?: InputMaybe<AddressUpdateManyWithoutOwnerNestedInput>;
  attachments?: InputMaybe<AttachmentUpdateManyWithoutOwnerNestedInput>;
  authType?: InputMaybe<EnumUserAuthTypeFieldUpdateOperationsInput>;
  bio?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  campaignNanhui?: InputMaybe<CampaignNanhuiUpdateManyWithoutSubmitterNestedInput>;
  campainZeczec?: InputMaybe<CampainZeczecUpdateOneWithoutRegisterUserNestedInput>;
  card?: InputMaybe<CardUpdateManyWithoutCreatorNestedInput>;
  coins?: InputMaybe<CoinUpdateManyWithoutUserNestedInput>;
  collection?: InputMaybe<CollectionUpdateManyWithoutOwnerNestedInput>;
  country?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  couponUsages?: InputMaybe<CouponUsageUpdateManyWithoutUserNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  createdStamps?: InputMaybe<StampUpdateManyWithoutCreatorNestedInput>;
  detail?: InputMaybe<OrderDetailUpdateManyWithoutReceiverNestedInput>;
  deviceToken?: InputMaybe<DeviceTokenUpdateManyWithoutUserNestedInput>;
  email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  emailTos?: InputMaybe<EmailToUpdateManyWithoutOwnerNestedInput>;
  favoriteStoreItems?: InputMaybe<StoreItemUpdateManyWithoutFavoriteByUsersNestedInput>;
  fetchFriends?: InputMaybe<UserFriendUpdateManyWithoutOwnerNestedInput>;
  followProfitables?: InputMaybe<ProfitableUpdateManyWithoutFollowedByNestedInput>;
  followedBy?: InputMaybe<UserUpdateManyWithoutFollowingNestedInput>;
  following?: InputMaybe<UserUpdateManyWithoutFollowedByNestedInput>;
  gender?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  groupsJoin?: InputMaybe<GroupUpdateManyWithoutMembersNestedInput>;
  groupsOwn?: InputMaybe<GroupUpdateManyWithoutOwnerNestedInput>;
  headUri?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  history?: InputMaybe<OrderDetailHistoryUpdateManyWithoutOperatorNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  idStr?: InputMaybe<StringFieldUpdateOperationsInput>;
  inFriends?: InputMaybe<UserFriendUpdateManyWithoutUsersNestedInput>;
  inValidReasonHistories?: InputMaybe<InValidReasonUpdateManyWithoutUserNestedInput>;
  lastLogin?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  lastTokenUpdated?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  likePosts?: InputMaybe<PostUpdateManyWithoutLikeUsersNestedInput>;
  manualAddrs?: InputMaybe<ManualAddrUpdateManyWithoutOwnerNestedInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  notificationReceiver?: InputMaybe<NotificationUpdateManyWithoutReceiverNestedInput>;
  notificationSender?: InputMaybe<NotificationUpdateManyWithoutSenderNestedInput>;
  order?: InputMaybe<OrderUpdateManyWithoutSenderNestedInput>;
  packagesAsReceiver?: InputMaybe<PostPackageUpdateManyWithoutReceiversNestedInput>;
  password?: InputMaybe<StringFieldUpdateOperationsInput>;
  pcoins?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  phone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  postPackage?: InputMaybe<PostPackageUpdateManyWithoutSenderNestedInput>;
  postsIn?: InputMaybe<PostUpdateManyWithoutReceiverNestedInput>;
  postsOut?: InputMaybe<PostUpdateManyWithoutSenderNestedInput>;
  profitable?: InputMaybe<ProfitableUpdateOneWithoutUserNestedInput>;
  receive_email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  receive_phone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  reportByMe?: InputMaybe<ReportUserUpdateManyWithoutReporterNestedInput>;
  reported?: InputMaybe<ReportUserUpdateManyWithoutUserNestedInput>;
  reports?: InputMaybe<ReportUpdateManyWithoutReporterNestedInput>;
  settingNotification?: InputMaybe<SettingNotificationUpdateOneWithoutUserNestedInput>;
  settingPrivacy?: InputMaybe<SettingPrivacyUpdateOneWithoutUserNestedInput>;
  signature?: InputMaybe<SignatureUpdateManyWithoutOwnerNestedInput>;
  storePurchaseHistory?: InputMaybe<CartItemUpdateManyWithoutCustomerNestedInput>;
  tagsUsed?: InputMaybe<PostTagUpdateManyWithoutUserNestedInput>;
  thirdpartyId?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  vAddrMapping?: InputMaybe<VirtualAddrMappingUpdateOneWithoutOwnerNestedInput>;
  valid?: InputMaybe<BoolFieldUpdateOperationsInput>;
  website?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type UserUpdateWithoutNotificationReceiverInput = {
  accessToken?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  address?: InputMaybe<AddressUpdateManyWithoutOwnerNestedInput>;
  attachments?: InputMaybe<AttachmentUpdateManyWithoutOwnerNestedInput>;
  authType?: InputMaybe<EnumUserAuthTypeFieldUpdateOperationsInput>;
  bio?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  campaignNanhui?: InputMaybe<CampaignNanhuiUpdateManyWithoutSubmitterNestedInput>;
  campainZeczec?: InputMaybe<CampainZeczecUpdateOneWithoutRegisterUserNestedInput>;
  card?: InputMaybe<CardUpdateManyWithoutCreatorNestedInput>;
  coins?: InputMaybe<CoinUpdateManyWithoutUserNestedInput>;
  collection?: InputMaybe<CollectionUpdateManyWithoutOwnerNestedInput>;
  country?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  couponUsages?: InputMaybe<CouponUsageUpdateManyWithoutUserNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  createdStamps?: InputMaybe<StampUpdateManyWithoutCreatorNestedInput>;
  detail?: InputMaybe<OrderDetailUpdateManyWithoutReceiverNestedInput>;
  deviceToken?: InputMaybe<DeviceTokenUpdateManyWithoutUserNestedInput>;
  email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  emailTos?: InputMaybe<EmailToUpdateManyWithoutOwnerNestedInput>;
  favoriteStoreItems?: InputMaybe<StoreItemUpdateManyWithoutFavoriteByUsersNestedInput>;
  fetchFriends?: InputMaybe<UserFriendUpdateManyWithoutOwnerNestedInput>;
  followProfitables?: InputMaybe<ProfitableUpdateManyWithoutFollowedByNestedInput>;
  followedBy?: InputMaybe<UserUpdateManyWithoutFollowingNestedInput>;
  following?: InputMaybe<UserUpdateManyWithoutFollowedByNestedInput>;
  gender?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  groupsJoin?: InputMaybe<GroupUpdateManyWithoutMembersNestedInput>;
  groupsOwn?: InputMaybe<GroupUpdateManyWithoutOwnerNestedInput>;
  headUri?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  history?: InputMaybe<OrderDetailHistoryUpdateManyWithoutOperatorNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  idStr?: InputMaybe<StringFieldUpdateOperationsInput>;
  inFriends?: InputMaybe<UserFriendUpdateManyWithoutUsersNestedInput>;
  inValidReasonHistories?: InputMaybe<InValidReasonUpdateManyWithoutUserNestedInput>;
  lastLogin?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  lastTokenUpdated?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  likePosts?: InputMaybe<PostUpdateManyWithoutLikeUsersNestedInput>;
  manualAddrs?: InputMaybe<ManualAddrUpdateManyWithoutOwnerNestedInput>;
  myCoupons?: InputMaybe<CouponEntityUpdateManyWithoutHolderNestedInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  notificationSender?: InputMaybe<NotificationUpdateManyWithoutSenderNestedInput>;
  order?: InputMaybe<OrderUpdateManyWithoutSenderNestedInput>;
  packagesAsReceiver?: InputMaybe<PostPackageUpdateManyWithoutReceiversNestedInput>;
  password?: InputMaybe<StringFieldUpdateOperationsInput>;
  pcoins?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  phone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  postPackage?: InputMaybe<PostPackageUpdateManyWithoutSenderNestedInput>;
  postsIn?: InputMaybe<PostUpdateManyWithoutReceiverNestedInput>;
  postsOut?: InputMaybe<PostUpdateManyWithoutSenderNestedInput>;
  profitable?: InputMaybe<ProfitableUpdateOneWithoutUserNestedInput>;
  receive_email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  receive_phone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  reportByMe?: InputMaybe<ReportUserUpdateManyWithoutReporterNestedInput>;
  reported?: InputMaybe<ReportUserUpdateManyWithoutUserNestedInput>;
  reports?: InputMaybe<ReportUpdateManyWithoutReporterNestedInput>;
  settingNotification?: InputMaybe<SettingNotificationUpdateOneWithoutUserNestedInput>;
  settingPrivacy?: InputMaybe<SettingPrivacyUpdateOneWithoutUserNestedInput>;
  signature?: InputMaybe<SignatureUpdateManyWithoutOwnerNestedInput>;
  storePurchaseHistory?: InputMaybe<CartItemUpdateManyWithoutCustomerNestedInput>;
  tagsUsed?: InputMaybe<PostTagUpdateManyWithoutUserNestedInput>;
  thirdpartyId?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  vAddrMapping?: InputMaybe<VirtualAddrMappingUpdateOneWithoutOwnerNestedInput>;
  valid?: InputMaybe<BoolFieldUpdateOperationsInput>;
  website?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type UserUpdateWithoutNotificationSenderInput = {
  accessToken?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  address?: InputMaybe<AddressUpdateManyWithoutOwnerNestedInput>;
  attachments?: InputMaybe<AttachmentUpdateManyWithoutOwnerNestedInput>;
  authType?: InputMaybe<EnumUserAuthTypeFieldUpdateOperationsInput>;
  bio?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  campaignNanhui?: InputMaybe<CampaignNanhuiUpdateManyWithoutSubmitterNestedInput>;
  campainZeczec?: InputMaybe<CampainZeczecUpdateOneWithoutRegisterUserNestedInput>;
  card?: InputMaybe<CardUpdateManyWithoutCreatorNestedInput>;
  coins?: InputMaybe<CoinUpdateManyWithoutUserNestedInput>;
  collection?: InputMaybe<CollectionUpdateManyWithoutOwnerNestedInput>;
  country?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  couponUsages?: InputMaybe<CouponUsageUpdateManyWithoutUserNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  createdStamps?: InputMaybe<StampUpdateManyWithoutCreatorNestedInput>;
  detail?: InputMaybe<OrderDetailUpdateManyWithoutReceiverNestedInput>;
  deviceToken?: InputMaybe<DeviceTokenUpdateManyWithoutUserNestedInput>;
  email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  emailTos?: InputMaybe<EmailToUpdateManyWithoutOwnerNestedInput>;
  favoriteStoreItems?: InputMaybe<StoreItemUpdateManyWithoutFavoriteByUsersNestedInput>;
  fetchFriends?: InputMaybe<UserFriendUpdateManyWithoutOwnerNestedInput>;
  followProfitables?: InputMaybe<ProfitableUpdateManyWithoutFollowedByNestedInput>;
  followedBy?: InputMaybe<UserUpdateManyWithoutFollowingNestedInput>;
  following?: InputMaybe<UserUpdateManyWithoutFollowedByNestedInput>;
  gender?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  groupsJoin?: InputMaybe<GroupUpdateManyWithoutMembersNestedInput>;
  groupsOwn?: InputMaybe<GroupUpdateManyWithoutOwnerNestedInput>;
  headUri?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  history?: InputMaybe<OrderDetailHistoryUpdateManyWithoutOperatorNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  idStr?: InputMaybe<StringFieldUpdateOperationsInput>;
  inFriends?: InputMaybe<UserFriendUpdateManyWithoutUsersNestedInput>;
  inValidReasonHistories?: InputMaybe<InValidReasonUpdateManyWithoutUserNestedInput>;
  lastLogin?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  lastTokenUpdated?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  likePosts?: InputMaybe<PostUpdateManyWithoutLikeUsersNestedInput>;
  manualAddrs?: InputMaybe<ManualAddrUpdateManyWithoutOwnerNestedInput>;
  myCoupons?: InputMaybe<CouponEntityUpdateManyWithoutHolderNestedInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  notificationReceiver?: InputMaybe<NotificationUpdateManyWithoutReceiverNestedInput>;
  order?: InputMaybe<OrderUpdateManyWithoutSenderNestedInput>;
  packagesAsReceiver?: InputMaybe<PostPackageUpdateManyWithoutReceiversNestedInput>;
  password?: InputMaybe<StringFieldUpdateOperationsInput>;
  pcoins?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  phone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  postPackage?: InputMaybe<PostPackageUpdateManyWithoutSenderNestedInput>;
  postsIn?: InputMaybe<PostUpdateManyWithoutReceiverNestedInput>;
  postsOut?: InputMaybe<PostUpdateManyWithoutSenderNestedInput>;
  profitable?: InputMaybe<ProfitableUpdateOneWithoutUserNestedInput>;
  receive_email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  receive_phone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  reportByMe?: InputMaybe<ReportUserUpdateManyWithoutReporterNestedInput>;
  reported?: InputMaybe<ReportUserUpdateManyWithoutUserNestedInput>;
  reports?: InputMaybe<ReportUpdateManyWithoutReporterNestedInput>;
  settingNotification?: InputMaybe<SettingNotificationUpdateOneWithoutUserNestedInput>;
  settingPrivacy?: InputMaybe<SettingPrivacyUpdateOneWithoutUserNestedInput>;
  signature?: InputMaybe<SignatureUpdateManyWithoutOwnerNestedInput>;
  storePurchaseHistory?: InputMaybe<CartItemUpdateManyWithoutCustomerNestedInput>;
  tagsUsed?: InputMaybe<PostTagUpdateManyWithoutUserNestedInput>;
  thirdpartyId?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  vAddrMapping?: InputMaybe<VirtualAddrMappingUpdateOneWithoutOwnerNestedInput>;
  valid?: InputMaybe<BoolFieldUpdateOperationsInput>;
  website?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type UserUpdateWithoutOrderInput = {
  accessToken?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  address?: InputMaybe<AddressUpdateManyWithoutOwnerNestedInput>;
  attachments?: InputMaybe<AttachmentUpdateManyWithoutOwnerNestedInput>;
  authType?: InputMaybe<EnumUserAuthTypeFieldUpdateOperationsInput>;
  bio?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  campaignNanhui?: InputMaybe<CampaignNanhuiUpdateManyWithoutSubmitterNestedInput>;
  campainZeczec?: InputMaybe<CampainZeczecUpdateOneWithoutRegisterUserNestedInput>;
  card?: InputMaybe<CardUpdateManyWithoutCreatorNestedInput>;
  coins?: InputMaybe<CoinUpdateManyWithoutUserNestedInput>;
  collection?: InputMaybe<CollectionUpdateManyWithoutOwnerNestedInput>;
  country?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  couponUsages?: InputMaybe<CouponUsageUpdateManyWithoutUserNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  createdStamps?: InputMaybe<StampUpdateManyWithoutCreatorNestedInput>;
  detail?: InputMaybe<OrderDetailUpdateManyWithoutReceiverNestedInput>;
  deviceToken?: InputMaybe<DeviceTokenUpdateManyWithoutUserNestedInput>;
  email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  emailTos?: InputMaybe<EmailToUpdateManyWithoutOwnerNestedInput>;
  favoriteStoreItems?: InputMaybe<StoreItemUpdateManyWithoutFavoriteByUsersNestedInput>;
  fetchFriends?: InputMaybe<UserFriendUpdateManyWithoutOwnerNestedInput>;
  followProfitables?: InputMaybe<ProfitableUpdateManyWithoutFollowedByNestedInput>;
  followedBy?: InputMaybe<UserUpdateManyWithoutFollowingNestedInput>;
  following?: InputMaybe<UserUpdateManyWithoutFollowedByNestedInput>;
  gender?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  groupsJoin?: InputMaybe<GroupUpdateManyWithoutMembersNestedInput>;
  groupsOwn?: InputMaybe<GroupUpdateManyWithoutOwnerNestedInput>;
  headUri?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  history?: InputMaybe<OrderDetailHistoryUpdateManyWithoutOperatorNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  idStr?: InputMaybe<StringFieldUpdateOperationsInput>;
  inFriends?: InputMaybe<UserFriendUpdateManyWithoutUsersNestedInput>;
  inValidReasonHistories?: InputMaybe<InValidReasonUpdateManyWithoutUserNestedInput>;
  lastLogin?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  lastTokenUpdated?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  likePosts?: InputMaybe<PostUpdateManyWithoutLikeUsersNestedInput>;
  manualAddrs?: InputMaybe<ManualAddrUpdateManyWithoutOwnerNestedInput>;
  myCoupons?: InputMaybe<CouponEntityUpdateManyWithoutHolderNestedInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  notificationReceiver?: InputMaybe<NotificationUpdateManyWithoutReceiverNestedInput>;
  notificationSender?: InputMaybe<NotificationUpdateManyWithoutSenderNestedInput>;
  packagesAsReceiver?: InputMaybe<PostPackageUpdateManyWithoutReceiversNestedInput>;
  password?: InputMaybe<StringFieldUpdateOperationsInput>;
  pcoins?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  phone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  postPackage?: InputMaybe<PostPackageUpdateManyWithoutSenderNestedInput>;
  postsIn?: InputMaybe<PostUpdateManyWithoutReceiverNestedInput>;
  postsOut?: InputMaybe<PostUpdateManyWithoutSenderNestedInput>;
  profitable?: InputMaybe<ProfitableUpdateOneWithoutUserNestedInput>;
  receive_email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  receive_phone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  reportByMe?: InputMaybe<ReportUserUpdateManyWithoutReporterNestedInput>;
  reported?: InputMaybe<ReportUserUpdateManyWithoutUserNestedInput>;
  reports?: InputMaybe<ReportUpdateManyWithoutReporterNestedInput>;
  settingNotification?: InputMaybe<SettingNotificationUpdateOneWithoutUserNestedInput>;
  settingPrivacy?: InputMaybe<SettingPrivacyUpdateOneWithoutUserNestedInput>;
  signature?: InputMaybe<SignatureUpdateManyWithoutOwnerNestedInput>;
  storePurchaseHistory?: InputMaybe<CartItemUpdateManyWithoutCustomerNestedInput>;
  tagsUsed?: InputMaybe<PostTagUpdateManyWithoutUserNestedInput>;
  thirdpartyId?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  vAddrMapping?: InputMaybe<VirtualAddrMappingUpdateOneWithoutOwnerNestedInput>;
  valid?: InputMaybe<BoolFieldUpdateOperationsInput>;
  website?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type UserUpdateWithoutPackagesAsReceiverInput = {
  accessToken?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  address?: InputMaybe<AddressUpdateManyWithoutOwnerNestedInput>;
  attachments?: InputMaybe<AttachmentUpdateManyWithoutOwnerNestedInput>;
  authType?: InputMaybe<EnumUserAuthTypeFieldUpdateOperationsInput>;
  bio?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  campaignNanhui?: InputMaybe<CampaignNanhuiUpdateManyWithoutSubmitterNestedInput>;
  campainZeczec?: InputMaybe<CampainZeczecUpdateOneWithoutRegisterUserNestedInput>;
  card?: InputMaybe<CardUpdateManyWithoutCreatorNestedInput>;
  coins?: InputMaybe<CoinUpdateManyWithoutUserNestedInput>;
  collection?: InputMaybe<CollectionUpdateManyWithoutOwnerNestedInput>;
  country?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  couponUsages?: InputMaybe<CouponUsageUpdateManyWithoutUserNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  createdStamps?: InputMaybe<StampUpdateManyWithoutCreatorNestedInput>;
  detail?: InputMaybe<OrderDetailUpdateManyWithoutReceiverNestedInput>;
  deviceToken?: InputMaybe<DeviceTokenUpdateManyWithoutUserNestedInput>;
  email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  emailTos?: InputMaybe<EmailToUpdateManyWithoutOwnerNestedInput>;
  favoriteStoreItems?: InputMaybe<StoreItemUpdateManyWithoutFavoriteByUsersNestedInput>;
  fetchFriends?: InputMaybe<UserFriendUpdateManyWithoutOwnerNestedInput>;
  followProfitables?: InputMaybe<ProfitableUpdateManyWithoutFollowedByNestedInput>;
  followedBy?: InputMaybe<UserUpdateManyWithoutFollowingNestedInput>;
  following?: InputMaybe<UserUpdateManyWithoutFollowedByNestedInput>;
  gender?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  groupsJoin?: InputMaybe<GroupUpdateManyWithoutMembersNestedInput>;
  groupsOwn?: InputMaybe<GroupUpdateManyWithoutOwnerNestedInput>;
  headUri?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  history?: InputMaybe<OrderDetailHistoryUpdateManyWithoutOperatorNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  idStr?: InputMaybe<StringFieldUpdateOperationsInput>;
  inFriends?: InputMaybe<UserFriendUpdateManyWithoutUsersNestedInput>;
  inValidReasonHistories?: InputMaybe<InValidReasonUpdateManyWithoutUserNestedInput>;
  lastLogin?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  lastTokenUpdated?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  likePosts?: InputMaybe<PostUpdateManyWithoutLikeUsersNestedInput>;
  manualAddrs?: InputMaybe<ManualAddrUpdateManyWithoutOwnerNestedInput>;
  myCoupons?: InputMaybe<CouponEntityUpdateManyWithoutHolderNestedInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  notificationReceiver?: InputMaybe<NotificationUpdateManyWithoutReceiverNestedInput>;
  notificationSender?: InputMaybe<NotificationUpdateManyWithoutSenderNestedInput>;
  order?: InputMaybe<OrderUpdateManyWithoutSenderNestedInput>;
  password?: InputMaybe<StringFieldUpdateOperationsInput>;
  pcoins?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  phone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  postPackage?: InputMaybe<PostPackageUpdateManyWithoutSenderNestedInput>;
  postsIn?: InputMaybe<PostUpdateManyWithoutReceiverNestedInput>;
  postsOut?: InputMaybe<PostUpdateManyWithoutSenderNestedInput>;
  profitable?: InputMaybe<ProfitableUpdateOneWithoutUserNestedInput>;
  receive_email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  receive_phone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  reportByMe?: InputMaybe<ReportUserUpdateManyWithoutReporterNestedInput>;
  reported?: InputMaybe<ReportUserUpdateManyWithoutUserNestedInput>;
  reports?: InputMaybe<ReportUpdateManyWithoutReporterNestedInput>;
  settingNotification?: InputMaybe<SettingNotificationUpdateOneWithoutUserNestedInput>;
  settingPrivacy?: InputMaybe<SettingPrivacyUpdateOneWithoutUserNestedInput>;
  signature?: InputMaybe<SignatureUpdateManyWithoutOwnerNestedInput>;
  storePurchaseHistory?: InputMaybe<CartItemUpdateManyWithoutCustomerNestedInput>;
  tagsUsed?: InputMaybe<PostTagUpdateManyWithoutUserNestedInput>;
  thirdpartyId?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  vAddrMapping?: InputMaybe<VirtualAddrMappingUpdateOneWithoutOwnerNestedInput>;
  valid?: InputMaybe<BoolFieldUpdateOperationsInput>;
  website?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type UserUpdateWithoutPostPackageInput = {
  accessToken?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  address?: InputMaybe<AddressUpdateManyWithoutOwnerNestedInput>;
  attachments?: InputMaybe<AttachmentUpdateManyWithoutOwnerNestedInput>;
  authType?: InputMaybe<EnumUserAuthTypeFieldUpdateOperationsInput>;
  bio?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  campaignNanhui?: InputMaybe<CampaignNanhuiUpdateManyWithoutSubmitterNestedInput>;
  campainZeczec?: InputMaybe<CampainZeczecUpdateOneWithoutRegisterUserNestedInput>;
  card?: InputMaybe<CardUpdateManyWithoutCreatorNestedInput>;
  coins?: InputMaybe<CoinUpdateManyWithoutUserNestedInput>;
  collection?: InputMaybe<CollectionUpdateManyWithoutOwnerNestedInput>;
  country?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  couponUsages?: InputMaybe<CouponUsageUpdateManyWithoutUserNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  createdStamps?: InputMaybe<StampUpdateManyWithoutCreatorNestedInput>;
  detail?: InputMaybe<OrderDetailUpdateManyWithoutReceiverNestedInput>;
  deviceToken?: InputMaybe<DeviceTokenUpdateManyWithoutUserNestedInput>;
  email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  emailTos?: InputMaybe<EmailToUpdateManyWithoutOwnerNestedInput>;
  favoriteStoreItems?: InputMaybe<StoreItemUpdateManyWithoutFavoriteByUsersNestedInput>;
  fetchFriends?: InputMaybe<UserFriendUpdateManyWithoutOwnerNestedInput>;
  followProfitables?: InputMaybe<ProfitableUpdateManyWithoutFollowedByNestedInput>;
  followedBy?: InputMaybe<UserUpdateManyWithoutFollowingNestedInput>;
  following?: InputMaybe<UserUpdateManyWithoutFollowedByNestedInput>;
  gender?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  groupsJoin?: InputMaybe<GroupUpdateManyWithoutMembersNestedInput>;
  groupsOwn?: InputMaybe<GroupUpdateManyWithoutOwnerNestedInput>;
  headUri?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  history?: InputMaybe<OrderDetailHistoryUpdateManyWithoutOperatorNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  idStr?: InputMaybe<StringFieldUpdateOperationsInput>;
  inFriends?: InputMaybe<UserFriendUpdateManyWithoutUsersNestedInput>;
  inValidReasonHistories?: InputMaybe<InValidReasonUpdateManyWithoutUserNestedInput>;
  lastLogin?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  lastTokenUpdated?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  likePosts?: InputMaybe<PostUpdateManyWithoutLikeUsersNestedInput>;
  manualAddrs?: InputMaybe<ManualAddrUpdateManyWithoutOwnerNestedInput>;
  myCoupons?: InputMaybe<CouponEntityUpdateManyWithoutHolderNestedInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  notificationReceiver?: InputMaybe<NotificationUpdateManyWithoutReceiverNestedInput>;
  notificationSender?: InputMaybe<NotificationUpdateManyWithoutSenderNestedInput>;
  order?: InputMaybe<OrderUpdateManyWithoutSenderNestedInput>;
  packagesAsReceiver?: InputMaybe<PostPackageUpdateManyWithoutReceiversNestedInput>;
  password?: InputMaybe<StringFieldUpdateOperationsInput>;
  pcoins?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  phone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  postsIn?: InputMaybe<PostUpdateManyWithoutReceiverNestedInput>;
  postsOut?: InputMaybe<PostUpdateManyWithoutSenderNestedInput>;
  profitable?: InputMaybe<ProfitableUpdateOneWithoutUserNestedInput>;
  receive_email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  receive_phone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  reportByMe?: InputMaybe<ReportUserUpdateManyWithoutReporterNestedInput>;
  reported?: InputMaybe<ReportUserUpdateManyWithoutUserNestedInput>;
  reports?: InputMaybe<ReportUpdateManyWithoutReporterNestedInput>;
  settingNotification?: InputMaybe<SettingNotificationUpdateOneWithoutUserNestedInput>;
  settingPrivacy?: InputMaybe<SettingPrivacyUpdateOneWithoutUserNestedInput>;
  signature?: InputMaybe<SignatureUpdateManyWithoutOwnerNestedInput>;
  storePurchaseHistory?: InputMaybe<CartItemUpdateManyWithoutCustomerNestedInput>;
  tagsUsed?: InputMaybe<PostTagUpdateManyWithoutUserNestedInput>;
  thirdpartyId?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  vAddrMapping?: InputMaybe<VirtualAddrMappingUpdateOneWithoutOwnerNestedInput>;
  valid?: InputMaybe<BoolFieldUpdateOperationsInput>;
  website?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type UserUpdateWithoutPostsInInput = {
  accessToken?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  address?: InputMaybe<AddressUpdateManyWithoutOwnerNestedInput>;
  attachments?: InputMaybe<AttachmentUpdateManyWithoutOwnerNestedInput>;
  authType?: InputMaybe<EnumUserAuthTypeFieldUpdateOperationsInput>;
  bio?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  campaignNanhui?: InputMaybe<CampaignNanhuiUpdateManyWithoutSubmitterNestedInput>;
  campainZeczec?: InputMaybe<CampainZeczecUpdateOneWithoutRegisterUserNestedInput>;
  card?: InputMaybe<CardUpdateManyWithoutCreatorNestedInput>;
  coins?: InputMaybe<CoinUpdateManyWithoutUserNestedInput>;
  collection?: InputMaybe<CollectionUpdateManyWithoutOwnerNestedInput>;
  country?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  couponUsages?: InputMaybe<CouponUsageUpdateManyWithoutUserNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  createdStamps?: InputMaybe<StampUpdateManyWithoutCreatorNestedInput>;
  detail?: InputMaybe<OrderDetailUpdateManyWithoutReceiverNestedInput>;
  deviceToken?: InputMaybe<DeviceTokenUpdateManyWithoutUserNestedInput>;
  email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  emailTos?: InputMaybe<EmailToUpdateManyWithoutOwnerNestedInput>;
  favoriteStoreItems?: InputMaybe<StoreItemUpdateManyWithoutFavoriteByUsersNestedInput>;
  fetchFriends?: InputMaybe<UserFriendUpdateManyWithoutOwnerNestedInput>;
  followProfitables?: InputMaybe<ProfitableUpdateManyWithoutFollowedByNestedInput>;
  followedBy?: InputMaybe<UserUpdateManyWithoutFollowingNestedInput>;
  following?: InputMaybe<UserUpdateManyWithoutFollowedByNestedInput>;
  gender?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  groupsJoin?: InputMaybe<GroupUpdateManyWithoutMembersNestedInput>;
  groupsOwn?: InputMaybe<GroupUpdateManyWithoutOwnerNestedInput>;
  headUri?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  history?: InputMaybe<OrderDetailHistoryUpdateManyWithoutOperatorNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  idStr?: InputMaybe<StringFieldUpdateOperationsInput>;
  inFriends?: InputMaybe<UserFriendUpdateManyWithoutUsersNestedInput>;
  inValidReasonHistories?: InputMaybe<InValidReasonUpdateManyWithoutUserNestedInput>;
  lastLogin?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  lastTokenUpdated?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  likePosts?: InputMaybe<PostUpdateManyWithoutLikeUsersNestedInput>;
  manualAddrs?: InputMaybe<ManualAddrUpdateManyWithoutOwnerNestedInput>;
  myCoupons?: InputMaybe<CouponEntityUpdateManyWithoutHolderNestedInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  notificationReceiver?: InputMaybe<NotificationUpdateManyWithoutReceiverNestedInput>;
  notificationSender?: InputMaybe<NotificationUpdateManyWithoutSenderNestedInput>;
  order?: InputMaybe<OrderUpdateManyWithoutSenderNestedInput>;
  packagesAsReceiver?: InputMaybe<PostPackageUpdateManyWithoutReceiversNestedInput>;
  password?: InputMaybe<StringFieldUpdateOperationsInput>;
  pcoins?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  phone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  postPackage?: InputMaybe<PostPackageUpdateManyWithoutSenderNestedInput>;
  postsOut?: InputMaybe<PostUpdateManyWithoutSenderNestedInput>;
  profitable?: InputMaybe<ProfitableUpdateOneWithoutUserNestedInput>;
  receive_email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  receive_phone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  reportByMe?: InputMaybe<ReportUserUpdateManyWithoutReporterNestedInput>;
  reported?: InputMaybe<ReportUserUpdateManyWithoutUserNestedInput>;
  reports?: InputMaybe<ReportUpdateManyWithoutReporterNestedInput>;
  settingNotification?: InputMaybe<SettingNotificationUpdateOneWithoutUserNestedInput>;
  settingPrivacy?: InputMaybe<SettingPrivacyUpdateOneWithoutUserNestedInput>;
  signature?: InputMaybe<SignatureUpdateManyWithoutOwnerNestedInput>;
  storePurchaseHistory?: InputMaybe<CartItemUpdateManyWithoutCustomerNestedInput>;
  tagsUsed?: InputMaybe<PostTagUpdateManyWithoutUserNestedInput>;
  thirdpartyId?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  vAddrMapping?: InputMaybe<VirtualAddrMappingUpdateOneWithoutOwnerNestedInput>;
  valid?: InputMaybe<BoolFieldUpdateOperationsInput>;
  website?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type UserUpdateWithoutPostsOutInput = {
  accessToken?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  address?: InputMaybe<AddressUpdateManyWithoutOwnerNestedInput>;
  attachments?: InputMaybe<AttachmentUpdateManyWithoutOwnerNestedInput>;
  authType?: InputMaybe<EnumUserAuthTypeFieldUpdateOperationsInput>;
  bio?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  campaignNanhui?: InputMaybe<CampaignNanhuiUpdateManyWithoutSubmitterNestedInput>;
  campainZeczec?: InputMaybe<CampainZeczecUpdateOneWithoutRegisterUserNestedInput>;
  card?: InputMaybe<CardUpdateManyWithoutCreatorNestedInput>;
  coins?: InputMaybe<CoinUpdateManyWithoutUserNestedInput>;
  collection?: InputMaybe<CollectionUpdateManyWithoutOwnerNestedInput>;
  country?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  couponUsages?: InputMaybe<CouponUsageUpdateManyWithoutUserNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  createdStamps?: InputMaybe<StampUpdateManyWithoutCreatorNestedInput>;
  detail?: InputMaybe<OrderDetailUpdateManyWithoutReceiverNestedInput>;
  deviceToken?: InputMaybe<DeviceTokenUpdateManyWithoutUserNestedInput>;
  email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  emailTos?: InputMaybe<EmailToUpdateManyWithoutOwnerNestedInput>;
  favoriteStoreItems?: InputMaybe<StoreItemUpdateManyWithoutFavoriteByUsersNestedInput>;
  fetchFriends?: InputMaybe<UserFriendUpdateManyWithoutOwnerNestedInput>;
  followProfitables?: InputMaybe<ProfitableUpdateManyWithoutFollowedByNestedInput>;
  followedBy?: InputMaybe<UserUpdateManyWithoutFollowingNestedInput>;
  following?: InputMaybe<UserUpdateManyWithoutFollowedByNestedInput>;
  gender?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  groupsJoin?: InputMaybe<GroupUpdateManyWithoutMembersNestedInput>;
  groupsOwn?: InputMaybe<GroupUpdateManyWithoutOwnerNestedInput>;
  headUri?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  history?: InputMaybe<OrderDetailHistoryUpdateManyWithoutOperatorNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  idStr?: InputMaybe<StringFieldUpdateOperationsInput>;
  inFriends?: InputMaybe<UserFriendUpdateManyWithoutUsersNestedInput>;
  inValidReasonHistories?: InputMaybe<InValidReasonUpdateManyWithoutUserNestedInput>;
  lastLogin?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  lastTokenUpdated?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  likePosts?: InputMaybe<PostUpdateManyWithoutLikeUsersNestedInput>;
  manualAddrs?: InputMaybe<ManualAddrUpdateManyWithoutOwnerNestedInput>;
  myCoupons?: InputMaybe<CouponEntityUpdateManyWithoutHolderNestedInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  notificationReceiver?: InputMaybe<NotificationUpdateManyWithoutReceiverNestedInput>;
  notificationSender?: InputMaybe<NotificationUpdateManyWithoutSenderNestedInput>;
  order?: InputMaybe<OrderUpdateManyWithoutSenderNestedInput>;
  packagesAsReceiver?: InputMaybe<PostPackageUpdateManyWithoutReceiversNestedInput>;
  password?: InputMaybe<StringFieldUpdateOperationsInput>;
  pcoins?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  phone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  postPackage?: InputMaybe<PostPackageUpdateManyWithoutSenderNestedInput>;
  postsIn?: InputMaybe<PostUpdateManyWithoutReceiverNestedInput>;
  profitable?: InputMaybe<ProfitableUpdateOneWithoutUserNestedInput>;
  receive_email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  receive_phone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  reportByMe?: InputMaybe<ReportUserUpdateManyWithoutReporterNestedInput>;
  reported?: InputMaybe<ReportUserUpdateManyWithoutUserNestedInput>;
  reports?: InputMaybe<ReportUpdateManyWithoutReporterNestedInput>;
  settingNotification?: InputMaybe<SettingNotificationUpdateOneWithoutUserNestedInput>;
  settingPrivacy?: InputMaybe<SettingPrivacyUpdateOneWithoutUserNestedInput>;
  signature?: InputMaybe<SignatureUpdateManyWithoutOwnerNestedInput>;
  storePurchaseHistory?: InputMaybe<CartItemUpdateManyWithoutCustomerNestedInput>;
  tagsUsed?: InputMaybe<PostTagUpdateManyWithoutUserNestedInput>;
  thirdpartyId?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  vAddrMapping?: InputMaybe<VirtualAddrMappingUpdateOneWithoutOwnerNestedInput>;
  valid?: InputMaybe<BoolFieldUpdateOperationsInput>;
  website?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type UserUpdateWithoutProfitableInput = {
  accessToken?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  address?: InputMaybe<AddressUpdateManyWithoutOwnerNestedInput>;
  attachments?: InputMaybe<AttachmentUpdateManyWithoutOwnerNestedInput>;
  authType?: InputMaybe<EnumUserAuthTypeFieldUpdateOperationsInput>;
  bio?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  campaignNanhui?: InputMaybe<CampaignNanhuiUpdateManyWithoutSubmitterNestedInput>;
  campainZeczec?: InputMaybe<CampainZeczecUpdateOneWithoutRegisterUserNestedInput>;
  card?: InputMaybe<CardUpdateManyWithoutCreatorNestedInput>;
  coins?: InputMaybe<CoinUpdateManyWithoutUserNestedInput>;
  collection?: InputMaybe<CollectionUpdateManyWithoutOwnerNestedInput>;
  country?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  couponUsages?: InputMaybe<CouponUsageUpdateManyWithoutUserNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  createdStamps?: InputMaybe<StampUpdateManyWithoutCreatorNestedInput>;
  detail?: InputMaybe<OrderDetailUpdateManyWithoutReceiverNestedInput>;
  deviceToken?: InputMaybe<DeviceTokenUpdateManyWithoutUserNestedInput>;
  email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  emailTos?: InputMaybe<EmailToUpdateManyWithoutOwnerNestedInput>;
  favoriteStoreItems?: InputMaybe<StoreItemUpdateManyWithoutFavoriteByUsersNestedInput>;
  fetchFriends?: InputMaybe<UserFriendUpdateManyWithoutOwnerNestedInput>;
  followProfitables?: InputMaybe<ProfitableUpdateManyWithoutFollowedByNestedInput>;
  followedBy?: InputMaybe<UserUpdateManyWithoutFollowingNestedInput>;
  following?: InputMaybe<UserUpdateManyWithoutFollowedByNestedInput>;
  gender?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  groupsJoin?: InputMaybe<GroupUpdateManyWithoutMembersNestedInput>;
  groupsOwn?: InputMaybe<GroupUpdateManyWithoutOwnerNestedInput>;
  headUri?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  history?: InputMaybe<OrderDetailHistoryUpdateManyWithoutOperatorNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  idStr?: InputMaybe<StringFieldUpdateOperationsInput>;
  inFriends?: InputMaybe<UserFriendUpdateManyWithoutUsersNestedInput>;
  inValidReasonHistories?: InputMaybe<InValidReasonUpdateManyWithoutUserNestedInput>;
  lastLogin?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  lastTokenUpdated?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  likePosts?: InputMaybe<PostUpdateManyWithoutLikeUsersNestedInput>;
  manualAddrs?: InputMaybe<ManualAddrUpdateManyWithoutOwnerNestedInput>;
  myCoupons?: InputMaybe<CouponEntityUpdateManyWithoutHolderNestedInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  notificationReceiver?: InputMaybe<NotificationUpdateManyWithoutReceiverNestedInput>;
  notificationSender?: InputMaybe<NotificationUpdateManyWithoutSenderNestedInput>;
  order?: InputMaybe<OrderUpdateManyWithoutSenderNestedInput>;
  packagesAsReceiver?: InputMaybe<PostPackageUpdateManyWithoutReceiversNestedInput>;
  password?: InputMaybe<StringFieldUpdateOperationsInput>;
  pcoins?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  phone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  postPackage?: InputMaybe<PostPackageUpdateManyWithoutSenderNestedInput>;
  postsIn?: InputMaybe<PostUpdateManyWithoutReceiverNestedInput>;
  postsOut?: InputMaybe<PostUpdateManyWithoutSenderNestedInput>;
  receive_email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  receive_phone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  reportByMe?: InputMaybe<ReportUserUpdateManyWithoutReporterNestedInput>;
  reported?: InputMaybe<ReportUserUpdateManyWithoutUserNestedInput>;
  reports?: InputMaybe<ReportUpdateManyWithoutReporterNestedInput>;
  settingNotification?: InputMaybe<SettingNotificationUpdateOneWithoutUserNestedInput>;
  settingPrivacy?: InputMaybe<SettingPrivacyUpdateOneWithoutUserNestedInput>;
  signature?: InputMaybe<SignatureUpdateManyWithoutOwnerNestedInput>;
  storePurchaseHistory?: InputMaybe<CartItemUpdateManyWithoutCustomerNestedInput>;
  tagsUsed?: InputMaybe<PostTagUpdateManyWithoutUserNestedInput>;
  thirdpartyId?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  vAddrMapping?: InputMaybe<VirtualAddrMappingUpdateOneWithoutOwnerNestedInput>;
  valid?: InputMaybe<BoolFieldUpdateOperationsInput>;
  website?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type UserUpdateWithoutReportByMeInput = {
  accessToken?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  address?: InputMaybe<AddressUpdateManyWithoutOwnerNestedInput>;
  attachments?: InputMaybe<AttachmentUpdateManyWithoutOwnerNestedInput>;
  authType?: InputMaybe<EnumUserAuthTypeFieldUpdateOperationsInput>;
  bio?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  campaignNanhui?: InputMaybe<CampaignNanhuiUpdateManyWithoutSubmitterNestedInput>;
  campainZeczec?: InputMaybe<CampainZeczecUpdateOneWithoutRegisterUserNestedInput>;
  card?: InputMaybe<CardUpdateManyWithoutCreatorNestedInput>;
  coins?: InputMaybe<CoinUpdateManyWithoutUserNestedInput>;
  collection?: InputMaybe<CollectionUpdateManyWithoutOwnerNestedInput>;
  country?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  couponUsages?: InputMaybe<CouponUsageUpdateManyWithoutUserNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  createdStamps?: InputMaybe<StampUpdateManyWithoutCreatorNestedInput>;
  detail?: InputMaybe<OrderDetailUpdateManyWithoutReceiverNestedInput>;
  deviceToken?: InputMaybe<DeviceTokenUpdateManyWithoutUserNestedInput>;
  email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  emailTos?: InputMaybe<EmailToUpdateManyWithoutOwnerNestedInput>;
  favoriteStoreItems?: InputMaybe<StoreItemUpdateManyWithoutFavoriteByUsersNestedInput>;
  fetchFriends?: InputMaybe<UserFriendUpdateManyWithoutOwnerNestedInput>;
  followProfitables?: InputMaybe<ProfitableUpdateManyWithoutFollowedByNestedInput>;
  followedBy?: InputMaybe<UserUpdateManyWithoutFollowingNestedInput>;
  following?: InputMaybe<UserUpdateManyWithoutFollowedByNestedInput>;
  gender?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  groupsJoin?: InputMaybe<GroupUpdateManyWithoutMembersNestedInput>;
  groupsOwn?: InputMaybe<GroupUpdateManyWithoutOwnerNestedInput>;
  headUri?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  history?: InputMaybe<OrderDetailHistoryUpdateManyWithoutOperatorNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  idStr?: InputMaybe<StringFieldUpdateOperationsInput>;
  inFriends?: InputMaybe<UserFriendUpdateManyWithoutUsersNestedInput>;
  inValidReasonHistories?: InputMaybe<InValidReasonUpdateManyWithoutUserNestedInput>;
  lastLogin?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  lastTokenUpdated?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  likePosts?: InputMaybe<PostUpdateManyWithoutLikeUsersNestedInput>;
  manualAddrs?: InputMaybe<ManualAddrUpdateManyWithoutOwnerNestedInput>;
  myCoupons?: InputMaybe<CouponEntityUpdateManyWithoutHolderNestedInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  notificationReceiver?: InputMaybe<NotificationUpdateManyWithoutReceiverNestedInput>;
  notificationSender?: InputMaybe<NotificationUpdateManyWithoutSenderNestedInput>;
  order?: InputMaybe<OrderUpdateManyWithoutSenderNestedInput>;
  packagesAsReceiver?: InputMaybe<PostPackageUpdateManyWithoutReceiversNestedInput>;
  password?: InputMaybe<StringFieldUpdateOperationsInput>;
  pcoins?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  phone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  postPackage?: InputMaybe<PostPackageUpdateManyWithoutSenderNestedInput>;
  postsIn?: InputMaybe<PostUpdateManyWithoutReceiverNestedInput>;
  postsOut?: InputMaybe<PostUpdateManyWithoutSenderNestedInput>;
  profitable?: InputMaybe<ProfitableUpdateOneWithoutUserNestedInput>;
  receive_email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  receive_phone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  reported?: InputMaybe<ReportUserUpdateManyWithoutUserNestedInput>;
  reports?: InputMaybe<ReportUpdateManyWithoutReporterNestedInput>;
  settingNotification?: InputMaybe<SettingNotificationUpdateOneWithoutUserNestedInput>;
  settingPrivacy?: InputMaybe<SettingPrivacyUpdateOneWithoutUserNestedInput>;
  signature?: InputMaybe<SignatureUpdateManyWithoutOwnerNestedInput>;
  storePurchaseHistory?: InputMaybe<CartItemUpdateManyWithoutCustomerNestedInput>;
  tagsUsed?: InputMaybe<PostTagUpdateManyWithoutUserNestedInput>;
  thirdpartyId?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  vAddrMapping?: InputMaybe<VirtualAddrMappingUpdateOneWithoutOwnerNestedInput>;
  valid?: InputMaybe<BoolFieldUpdateOperationsInput>;
  website?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type UserUpdateWithoutReportedInput = {
  accessToken?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  address?: InputMaybe<AddressUpdateManyWithoutOwnerNestedInput>;
  attachments?: InputMaybe<AttachmentUpdateManyWithoutOwnerNestedInput>;
  authType?: InputMaybe<EnumUserAuthTypeFieldUpdateOperationsInput>;
  bio?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  campaignNanhui?: InputMaybe<CampaignNanhuiUpdateManyWithoutSubmitterNestedInput>;
  campainZeczec?: InputMaybe<CampainZeczecUpdateOneWithoutRegisterUserNestedInput>;
  card?: InputMaybe<CardUpdateManyWithoutCreatorNestedInput>;
  coins?: InputMaybe<CoinUpdateManyWithoutUserNestedInput>;
  collection?: InputMaybe<CollectionUpdateManyWithoutOwnerNestedInput>;
  country?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  couponUsages?: InputMaybe<CouponUsageUpdateManyWithoutUserNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  createdStamps?: InputMaybe<StampUpdateManyWithoutCreatorNestedInput>;
  detail?: InputMaybe<OrderDetailUpdateManyWithoutReceiverNestedInput>;
  deviceToken?: InputMaybe<DeviceTokenUpdateManyWithoutUserNestedInput>;
  email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  emailTos?: InputMaybe<EmailToUpdateManyWithoutOwnerNestedInput>;
  favoriteStoreItems?: InputMaybe<StoreItemUpdateManyWithoutFavoriteByUsersNestedInput>;
  fetchFriends?: InputMaybe<UserFriendUpdateManyWithoutOwnerNestedInput>;
  followProfitables?: InputMaybe<ProfitableUpdateManyWithoutFollowedByNestedInput>;
  followedBy?: InputMaybe<UserUpdateManyWithoutFollowingNestedInput>;
  following?: InputMaybe<UserUpdateManyWithoutFollowedByNestedInput>;
  gender?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  groupsJoin?: InputMaybe<GroupUpdateManyWithoutMembersNestedInput>;
  groupsOwn?: InputMaybe<GroupUpdateManyWithoutOwnerNestedInput>;
  headUri?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  history?: InputMaybe<OrderDetailHistoryUpdateManyWithoutOperatorNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  idStr?: InputMaybe<StringFieldUpdateOperationsInput>;
  inFriends?: InputMaybe<UserFriendUpdateManyWithoutUsersNestedInput>;
  inValidReasonHistories?: InputMaybe<InValidReasonUpdateManyWithoutUserNestedInput>;
  lastLogin?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  lastTokenUpdated?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  likePosts?: InputMaybe<PostUpdateManyWithoutLikeUsersNestedInput>;
  manualAddrs?: InputMaybe<ManualAddrUpdateManyWithoutOwnerNestedInput>;
  myCoupons?: InputMaybe<CouponEntityUpdateManyWithoutHolderNestedInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  notificationReceiver?: InputMaybe<NotificationUpdateManyWithoutReceiverNestedInput>;
  notificationSender?: InputMaybe<NotificationUpdateManyWithoutSenderNestedInput>;
  order?: InputMaybe<OrderUpdateManyWithoutSenderNestedInput>;
  packagesAsReceiver?: InputMaybe<PostPackageUpdateManyWithoutReceiversNestedInput>;
  password?: InputMaybe<StringFieldUpdateOperationsInput>;
  pcoins?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  phone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  postPackage?: InputMaybe<PostPackageUpdateManyWithoutSenderNestedInput>;
  postsIn?: InputMaybe<PostUpdateManyWithoutReceiverNestedInput>;
  postsOut?: InputMaybe<PostUpdateManyWithoutSenderNestedInput>;
  profitable?: InputMaybe<ProfitableUpdateOneWithoutUserNestedInput>;
  receive_email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  receive_phone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  reportByMe?: InputMaybe<ReportUserUpdateManyWithoutReporterNestedInput>;
  reports?: InputMaybe<ReportUpdateManyWithoutReporterNestedInput>;
  settingNotification?: InputMaybe<SettingNotificationUpdateOneWithoutUserNestedInput>;
  settingPrivacy?: InputMaybe<SettingPrivacyUpdateOneWithoutUserNestedInput>;
  signature?: InputMaybe<SignatureUpdateManyWithoutOwnerNestedInput>;
  storePurchaseHistory?: InputMaybe<CartItemUpdateManyWithoutCustomerNestedInput>;
  tagsUsed?: InputMaybe<PostTagUpdateManyWithoutUserNestedInput>;
  thirdpartyId?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  vAddrMapping?: InputMaybe<VirtualAddrMappingUpdateOneWithoutOwnerNestedInput>;
  valid?: InputMaybe<BoolFieldUpdateOperationsInput>;
  website?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type UserUpdateWithoutReportsInput = {
  accessToken?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  address?: InputMaybe<AddressUpdateManyWithoutOwnerNestedInput>;
  attachments?: InputMaybe<AttachmentUpdateManyWithoutOwnerNestedInput>;
  authType?: InputMaybe<EnumUserAuthTypeFieldUpdateOperationsInput>;
  bio?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  campaignNanhui?: InputMaybe<CampaignNanhuiUpdateManyWithoutSubmitterNestedInput>;
  campainZeczec?: InputMaybe<CampainZeczecUpdateOneWithoutRegisterUserNestedInput>;
  card?: InputMaybe<CardUpdateManyWithoutCreatorNestedInput>;
  coins?: InputMaybe<CoinUpdateManyWithoutUserNestedInput>;
  collection?: InputMaybe<CollectionUpdateManyWithoutOwnerNestedInput>;
  country?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  couponUsages?: InputMaybe<CouponUsageUpdateManyWithoutUserNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  createdStamps?: InputMaybe<StampUpdateManyWithoutCreatorNestedInput>;
  detail?: InputMaybe<OrderDetailUpdateManyWithoutReceiverNestedInput>;
  deviceToken?: InputMaybe<DeviceTokenUpdateManyWithoutUserNestedInput>;
  email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  emailTos?: InputMaybe<EmailToUpdateManyWithoutOwnerNestedInput>;
  favoriteStoreItems?: InputMaybe<StoreItemUpdateManyWithoutFavoriteByUsersNestedInput>;
  fetchFriends?: InputMaybe<UserFriendUpdateManyWithoutOwnerNestedInput>;
  followProfitables?: InputMaybe<ProfitableUpdateManyWithoutFollowedByNestedInput>;
  followedBy?: InputMaybe<UserUpdateManyWithoutFollowingNestedInput>;
  following?: InputMaybe<UserUpdateManyWithoutFollowedByNestedInput>;
  gender?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  groupsJoin?: InputMaybe<GroupUpdateManyWithoutMembersNestedInput>;
  groupsOwn?: InputMaybe<GroupUpdateManyWithoutOwnerNestedInput>;
  headUri?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  history?: InputMaybe<OrderDetailHistoryUpdateManyWithoutOperatorNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  idStr?: InputMaybe<StringFieldUpdateOperationsInput>;
  inFriends?: InputMaybe<UserFriendUpdateManyWithoutUsersNestedInput>;
  inValidReasonHistories?: InputMaybe<InValidReasonUpdateManyWithoutUserNestedInput>;
  lastLogin?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  lastTokenUpdated?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  likePosts?: InputMaybe<PostUpdateManyWithoutLikeUsersNestedInput>;
  manualAddrs?: InputMaybe<ManualAddrUpdateManyWithoutOwnerNestedInput>;
  myCoupons?: InputMaybe<CouponEntityUpdateManyWithoutHolderNestedInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  notificationReceiver?: InputMaybe<NotificationUpdateManyWithoutReceiverNestedInput>;
  notificationSender?: InputMaybe<NotificationUpdateManyWithoutSenderNestedInput>;
  order?: InputMaybe<OrderUpdateManyWithoutSenderNestedInput>;
  packagesAsReceiver?: InputMaybe<PostPackageUpdateManyWithoutReceiversNestedInput>;
  password?: InputMaybe<StringFieldUpdateOperationsInput>;
  pcoins?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  phone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  postPackage?: InputMaybe<PostPackageUpdateManyWithoutSenderNestedInput>;
  postsIn?: InputMaybe<PostUpdateManyWithoutReceiverNestedInput>;
  postsOut?: InputMaybe<PostUpdateManyWithoutSenderNestedInput>;
  profitable?: InputMaybe<ProfitableUpdateOneWithoutUserNestedInput>;
  receive_email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  receive_phone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  reportByMe?: InputMaybe<ReportUserUpdateManyWithoutReporterNestedInput>;
  reported?: InputMaybe<ReportUserUpdateManyWithoutUserNestedInput>;
  settingNotification?: InputMaybe<SettingNotificationUpdateOneWithoutUserNestedInput>;
  settingPrivacy?: InputMaybe<SettingPrivacyUpdateOneWithoutUserNestedInput>;
  signature?: InputMaybe<SignatureUpdateManyWithoutOwnerNestedInput>;
  storePurchaseHistory?: InputMaybe<CartItemUpdateManyWithoutCustomerNestedInput>;
  tagsUsed?: InputMaybe<PostTagUpdateManyWithoutUserNestedInput>;
  thirdpartyId?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  vAddrMapping?: InputMaybe<VirtualAddrMappingUpdateOneWithoutOwnerNestedInput>;
  valid?: InputMaybe<BoolFieldUpdateOperationsInput>;
  website?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type UserUpdateWithoutSettingNotificationInput = {
  accessToken?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  address?: InputMaybe<AddressUpdateManyWithoutOwnerNestedInput>;
  attachments?: InputMaybe<AttachmentUpdateManyWithoutOwnerNestedInput>;
  authType?: InputMaybe<EnumUserAuthTypeFieldUpdateOperationsInput>;
  bio?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  campaignNanhui?: InputMaybe<CampaignNanhuiUpdateManyWithoutSubmitterNestedInput>;
  campainZeczec?: InputMaybe<CampainZeczecUpdateOneWithoutRegisterUserNestedInput>;
  card?: InputMaybe<CardUpdateManyWithoutCreatorNestedInput>;
  coins?: InputMaybe<CoinUpdateManyWithoutUserNestedInput>;
  collection?: InputMaybe<CollectionUpdateManyWithoutOwnerNestedInput>;
  country?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  couponUsages?: InputMaybe<CouponUsageUpdateManyWithoutUserNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  createdStamps?: InputMaybe<StampUpdateManyWithoutCreatorNestedInput>;
  detail?: InputMaybe<OrderDetailUpdateManyWithoutReceiverNestedInput>;
  deviceToken?: InputMaybe<DeviceTokenUpdateManyWithoutUserNestedInput>;
  email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  emailTos?: InputMaybe<EmailToUpdateManyWithoutOwnerNestedInput>;
  favoriteStoreItems?: InputMaybe<StoreItemUpdateManyWithoutFavoriteByUsersNestedInput>;
  fetchFriends?: InputMaybe<UserFriendUpdateManyWithoutOwnerNestedInput>;
  followProfitables?: InputMaybe<ProfitableUpdateManyWithoutFollowedByNestedInput>;
  followedBy?: InputMaybe<UserUpdateManyWithoutFollowingNestedInput>;
  following?: InputMaybe<UserUpdateManyWithoutFollowedByNestedInput>;
  gender?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  groupsJoin?: InputMaybe<GroupUpdateManyWithoutMembersNestedInput>;
  groupsOwn?: InputMaybe<GroupUpdateManyWithoutOwnerNestedInput>;
  headUri?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  history?: InputMaybe<OrderDetailHistoryUpdateManyWithoutOperatorNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  idStr?: InputMaybe<StringFieldUpdateOperationsInput>;
  inFriends?: InputMaybe<UserFriendUpdateManyWithoutUsersNestedInput>;
  inValidReasonHistories?: InputMaybe<InValidReasonUpdateManyWithoutUserNestedInput>;
  lastLogin?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  lastTokenUpdated?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  likePosts?: InputMaybe<PostUpdateManyWithoutLikeUsersNestedInput>;
  manualAddrs?: InputMaybe<ManualAddrUpdateManyWithoutOwnerNestedInput>;
  myCoupons?: InputMaybe<CouponEntityUpdateManyWithoutHolderNestedInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  notificationReceiver?: InputMaybe<NotificationUpdateManyWithoutReceiverNestedInput>;
  notificationSender?: InputMaybe<NotificationUpdateManyWithoutSenderNestedInput>;
  order?: InputMaybe<OrderUpdateManyWithoutSenderNestedInput>;
  packagesAsReceiver?: InputMaybe<PostPackageUpdateManyWithoutReceiversNestedInput>;
  password?: InputMaybe<StringFieldUpdateOperationsInput>;
  pcoins?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  phone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  postPackage?: InputMaybe<PostPackageUpdateManyWithoutSenderNestedInput>;
  postsIn?: InputMaybe<PostUpdateManyWithoutReceiverNestedInput>;
  postsOut?: InputMaybe<PostUpdateManyWithoutSenderNestedInput>;
  profitable?: InputMaybe<ProfitableUpdateOneWithoutUserNestedInput>;
  receive_email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  receive_phone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  reportByMe?: InputMaybe<ReportUserUpdateManyWithoutReporterNestedInput>;
  reported?: InputMaybe<ReportUserUpdateManyWithoutUserNestedInput>;
  reports?: InputMaybe<ReportUpdateManyWithoutReporterNestedInput>;
  settingPrivacy?: InputMaybe<SettingPrivacyUpdateOneWithoutUserNestedInput>;
  signature?: InputMaybe<SignatureUpdateManyWithoutOwnerNestedInput>;
  storePurchaseHistory?: InputMaybe<CartItemUpdateManyWithoutCustomerNestedInput>;
  tagsUsed?: InputMaybe<PostTagUpdateManyWithoutUserNestedInput>;
  thirdpartyId?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  vAddrMapping?: InputMaybe<VirtualAddrMappingUpdateOneWithoutOwnerNestedInput>;
  valid?: InputMaybe<BoolFieldUpdateOperationsInput>;
  website?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type UserUpdateWithoutSettingPrivacyInput = {
  accessToken?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  address?: InputMaybe<AddressUpdateManyWithoutOwnerNestedInput>;
  attachments?: InputMaybe<AttachmentUpdateManyWithoutOwnerNestedInput>;
  authType?: InputMaybe<EnumUserAuthTypeFieldUpdateOperationsInput>;
  bio?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  campaignNanhui?: InputMaybe<CampaignNanhuiUpdateManyWithoutSubmitterNestedInput>;
  campainZeczec?: InputMaybe<CampainZeczecUpdateOneWithoutRegisterUserNestedInput>;
  card?: InputMaybe<CardUpdateManyWithoutCreatorNestedInput>;
  coins?: InputMaybe<CoinUpdateManyWithoutUserNestedInput>;
  collection?: InputMaybe<CollectionUpdateManyWithoutOwnerNestedInput>;
  country?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  couponUsages?: InputMaybe<CouponUsageUpdateManyWithoutUserNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  createdStamps?: InputMaybe<StampUpdateManyWithoutCreatorNestedInput>;
  detail?: InputMaybe<OrderDetailUpdateManyWithoutReceiverNestedInput>;
  deviceToken?: InputMaybe<DeviceTokenUpdateManyWithoutUserNestedInput>;
  email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  emailTos?: InputMaybe<EmailToUpdateManyWithoutOwnerNestedInput>;
  favoriteStoreItems?: InputMaybe<StoreItemUpdateManyWithoutFavoriteByUsersNestedInput>;
  fetchFriends?: InputMaybe<UserFriendUpdateManyWithoutOwnerNestedInput>;
  followProfitables?: InputMaybe<ProfitableUpdateManyWithoutFollowedByNestedInput>;
  followedBy?: InputMaybe<UserUpdateManyWithoutFollowingNestedInput>;
  following?: InputMaybe<UserUpdateManyWithoutFollowedByNestedInput>;
  gender?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  groupsJoin?: InputMaybe<GroupUpdateManyWithoutMembersNestedInput>;
  groupsOwn?: InputMaybe<GroupUpdateManyWithoutOwnerNestedInput>;
  headUri?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  history?: InputMaybe<OrderDetailHistoryUpdateManyWithoutOperatorNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  idStr?: InputMaybe<StringFieldUpdateOperationsInput>;
  inFriends?: InputMaybe<UserFriendUpdateManyWithoutUsersNestedInput>;
  inValidReasonHistories?: InputMaybe<InValidReasonUpdateManyWithoutUserNestedInput>;
  lastLogin?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  lastTokenUpdated?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  likePosts?: InputMaybe<PostUpdateManyWithoutLikeUsersNestedInput>;
  manualAddrs?: InputMaybe<ManualAddrUpdateManyWithoutOwnerNestedInput>;
  myCoupons?: InputMaybe<CouponEntityUpdateManyWithoutHolderNestedInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  notificationReceiver?: InputMaybe<NotificationUpdateManyWithoutReceiverNestedInput>;
  notificationSender?: InputMaybe<NotificationUpdateManyWithoutSenderNestedInput>;
  order?: InputMaybe<OrderUpdateManyWithoutSenderNestedInput>;
  packagesAsReceiver?: InputMaybe<PostPackageUpdateManyWithoutReceiversNestedInput>;
  password?: InputMaybe<StringFieldUpdateOperationsInput>;
  pcoins?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  phone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  postPackage?: InputMaybe<PostPackageUpdateManyWithoutSenderNestedInput>;
  postsIn?: InputMaybe<PostUpdateManyWithoutReceiverNestedInput>;
  postsOut?: InputMaybe<PostUpdateManyWithoutSenderNestedInput>;
  profitable?: InputMaybe<ProfitableUpdateOneWithoutUserNestedInput>;
  receive_email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  receive_phone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  reportByMe?: InputMaybe<ReportUserUpdateManyWithoutReporterNestedInput>;
  reported?: InputMaybe<ReportUserUpdateManyWithoutUserNestedInput>;
  reports?: InputMaybe<ReportUpdateManyWithoutReporterNestedInput>;
  settingNotification?: InputMaybe<SettingNotificationUpdateOneWithoutUserNestedInput>;
  signature?: InputMaybe<SignatureUpdateManyWithoutOwnerNestedInput>;
  storePurchaseHistory?: InputMaybe<CartItemUpdateManyWithoutCustomerNestedInput>;
  tagsUsed?: InputMaybe<PostTagUpdateManyWithoutUserNestedInput>;
  thirdpartyId?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  vAddrMapping?: InputMaybe<VirtualAddrMappingUpdateOneWithoutOwnerNestedInput>;
  valid?: InputMaybe<BoolFieldUpdateOperationsInput>;
  website?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type UserUpdateWithoutSignatureInput = {
  accessToken?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  address?: InputMaybe<AddressUpdateManyWithoutOwnerNestedInput>;
  attachments?: InputMaybe<AttachmentUpdateManyWithoutOwnerNestedInput>;
  authType?: InputMaybe<EnumUserAuthTypeFieldUpdateOperationsInput>;
  bio?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  campaignNanhui?: InputMaybe<CampaignNanhuiUpdateManyWithoutSubmitterNestedInput>;
  campainZeczec?: InputMaybe<CampainZeczecUpdateOneWithoutRegisterUserNestedInput>;
  card?: InputMaybe<CardUpdateManyWithoutCreatorNestedInput>;
  coins?: InputMaybe<CoinUpdateManyWithoutUserNestedInput>;
  collection?: InputMaybe<CollectionUpdateManyWithoutOwnerNestedInput>;
  country?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  couponUsages?: InputMaybe<CouponUsageUpdateManyWithoutUserNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  createdStamps?: InputMaybe<StampUpdateManyWithoutCreatorNestedInput>;
  detail?: InputMaybe<OrderDetailUpdateManyWithoutReceiverNestedInput>;
  deviceToken?: InputMaybe<DeviceTokenUpdateManyWithoutUserNestedInput>;
  email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  emailTos?: InputMaybe<EmailToUpdateManyWithoutOwnerNestedInput>;
  favoriteStoreItems?: InputMaybe<StoreItemUpdateManyWithoutFavoriteByUsersNestedInput>;
  fetchFriends?: InputMaybe<UserFriendUpdateManyWithoutOwnerNestedInput>;
  followProfitables?: InputMaybe<ProfitableUpdateManyWithoutFollowedByNestedInput>;
  followedBy?: InputMaybe<UserUpdateManyWithoutFollowingNestedInput>;
  following?: InputMaybe<UserUpdateManyWithoutFollowedByNestedInput>;
  gender?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  groupsJoin?: InputMaybe<GroupUpdateManyWithoutMembersNestedInput>;
  groupsOwn?: InputMaybe<GroupUpdateManyWithoutOwnerNestedInput>;
  headUri?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  history?: InputMaybe<OrderDetailHistoryUpdateManyWithoutOperatorNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  idStr?: InputMaybe<StringFieldUpdateOperationsInput>;
  inFriends?: InputMaybe<UserFriendUpdateManyWithoutUsersNestedInput>;
  inValidReasonHistories?: InputMaybe<InValidReasonUpdateManyWithoutUserNestedInput>;
  lastLogin?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  lastTokenUpdated?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  likePosts?: InputMaybe<PostUpdateManyWithoutLikeUsersNestedInput>;
  manualAddrs?: InputMaybe<ManualAddrUpdateManyWithoutOwnerNestedInput>;
  myCoupons?: InputMaybe<CouponEntityUpdateManyWithoutHolderNestedInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  notificationReceiver?: InputMaybe<NotificationUpdateManyWithoutReceiverNestedInput>;
  notificationSender?: InputMaybe<NotificationUpdateManyWithoutSenderNestedInput>;
  order?: InputMaybe<OrderUpdateManyWithoutSenderNestedInput>;
  packagesAsReceiver?: InputMaybe<PostPackageUpdateManyWithoutReceiversNestedInput>;
  password?: InputMaybe<StringFieldUpdateOperationsInput>;
  pcoins?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  phone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  postPackage?: InputMaybe<PostPackageUpdateManyWithoutSenderNestedInput>;
  postsIn?: InputMaybe<PostUpdateManyWithoutReceiverNestedInput>;
  postsOut?: InputMaybe<PostUpdateManyWithoutSenderNestedInput>;
  profitable?: InputMaybe<ProfitableUpdateOneWithoutUserNestedInput>;
  receive_email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  receive_phone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  reportByMe?: InputMaybe<ReportUserUpdateManyWithoutReporterNestedInput>;
  reported?: InputMaybe<ReportUserUpdateManyWithoutUserNestedInput>;
  reports?: InputMaybe<ReportUpdateManyWithoutReporterNestedInput>;
  settingNotification?: InputMaybe<SettingNotificationUpdateOneWithoutUserNestedInput>;
  settingPrivacy?: InputMaybe<SettingPrivacyUpdateOneWithoutUserNestedInput>;
  storePurchaseHistory?: InputMaybe<CartItemUpdateManyWithoutCustomerNestedInput>;
  tagsUsed?: InputMaybe<PostTagUpdateManyWithoutUserNestedInput>;
  thirdpartyId?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  vAddrMapping?: InputMaybe<VirtualAddrMappingUpdateOneWithoutOwnerNestedInput>;
  valid?: InputMaybe<BoolFieldUpdateOperationsInput>;
  website?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type UserUpdateWithoutStorePurchaseHistoryInput = {
  accessToken?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  address?: InputMaybe<AddressUpdateManyWithoutOwnerNestedInput>;
  attachments?: InputMaybe<AttachmentUpdateManyWithoutOwnerNestedInput>;
  authType?: InputMaybe<EnumUserAuthTypeFieldUpdateOperationsInput>;
  bio?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  campaignNanhui?: InputMaybe<CampaignNanhuiUpdateManyWithoutSubmitterNestedInput>;
  campainZeczec?: InputMaybe<CampainZeczecUpdateOneWithoutRegisterUserNestedInput>;
  card?: InputMaybe<CardUpdateManyWithoutCreatorNestedInput>;
  coins?: InputMaybe<CoinUpdateManyWithoutUserNestedInput>;
  collection?: InputMaybe<CollectionUpdateManyWithoutOwnerNestedInput>;
  country?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  couponUsages?: InputMaybe<CouponUsageUpdateManyWithoutUserNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  createdStamps?: InputMaybe<StampUpdateManyWithoutCreatorNestedInput>;
  detail?: InputMaybe<OrderDetailUpdateManyWithoutReceiverNestedInput>;
  deviceToken?: InputMaybe<DeviceTokenUpdateManyWithoutUserNestedInput>;
  email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  emailTos?: InputMaybe<EmailToUpdateManyWithoutOwnerNestedInput>;
  favoriteStoreItems?: InputMaybe<StoreItemUpdateManyWithoutFavoriteByUsersNestedInput>;
  fetchFriends?: InputMaybe<UserFriendUpdateManyWithoutOwnerNestedInput>;
  followProfitables?: InputMaybe<ProfitableUpdateManyWithoutFollowedByNestedInput>;
  followedBy?: InputMaybe<UserUpdateManyWithoutFollowingNestedInput>;
  following?: InputMaybe<UserUpdateManyWithoutFollowedByNestedInput>;
  gender?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  groupsJoin?: InputMaybe<GroupUpdateManyWithoutMembersNestedInput>;
  groupsOwn?: InputMaybe<GroupUpdateManyWithoutOwnerNestedInput>;
  headUri?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  history?: InputMaybe<OrderDetailHistoryUpdateManyWithoutOperatorNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  idStr?: InputMaybe<StringFieldUpdateOperationsInput>;
  inFriends?: InputMaybe<UserFriendUpdateManyWithoutUsersNestedInput>;
  inValidReasonHistories?: InputMaybe<InValidReasonUpdateManyWithoutUserNestedInput>;
  lastLogin?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  lastTokenUpdated?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  likePosts?: InputMaybe<PostUpdateManyWithoutLikeUsersNestedInput>;
  manualAddrs?: InputMaybe<ManualAddrUpdateManyWithoutOwnerNestedInput>;
  myCoupons?: InputMaybe<CouponEntityUpdateManyWithoutHolderNestedInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  notificationReceiver?: InputMaybe<NotificationUpdateManyWithoutReceiverNestedInput>;
  notificationSender?: InputMaybe<NotificationUpdateManyWithoutSenderNestedInput>;
  order?: InputMaybe<OrderUpdateManyWithoutSenderNestedInput>;
  packagesAsReceiver?: InputMaybe<PostPackageUpdateManyWithoutReceiversNestedInput>;
  password?: InputMaybe<StringFieldUpdateOperationsInput>;
  pcoins?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  phone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  postPackage?: InputMaybe<PostPackageUpdateManyWithoutSenderNestedInput>;
  postsIn?: InputMaybe<PostUpdateManyWithoutReceiverNestedInput>;
  postsOut?: InputMaybe<PostUpdateManyWithoutSenderNestedInput>;
  profitable?: InputMaybe<ProfitableUpdateOneWithoutUserNestedInput>;
  receive_email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  receive_phone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  reportByMe?: InputMaybe<ReportUserUpdateManyWithoutReporterNestedInput>;
  reported?: InputMaybe<ReportUserUpdateManyWithoutUserNestedInput>;
  reports?: InputMaybe<ReportUpdateManyWithoutReporterNestedInput>;
  settingNotification?: InputMaybe<SettingNotificationUpdateOneWithoutUserNestedInput>;
  settingPrivacy?: InputMaybe<SettingPrivacyUpdateOneWithoutUserNestedInput>;
  signature?: InputMaybe<SignatureUpdateManyWithoutOwnerNestedInput>;
  tagsUsed?: InputMaybe<PostTagUpdateManyWithoutUserNestedInput>;
  thirdpartyId?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  vAddrMapping?: InputMaybe<VirtualAddrMappingUpdateOneWithoutOwnerNestedInput>;
  valid?: InputMaybe<BoolFieldUpdateOperationsInput>;
  website?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type UserUpdateWithoutTagsUsedInput = {
  accessToken?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  address?: InputMaybe<AddressUpdateManyWithoutOwnerNestedInput>;
  attachments?: InputMaybe<AttachmentUpdateManyWithoutOwnerNestedInput>;
  authType?: InputMaybe<EnumUserAuthTypeFieldUpdateOperationsInput>;
  bio?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  campaignNanhui?: InputMaybe<CampaignNanhuiUpdateManyWithoutSubmitterNestedInput>;
  campainZeczec?: InputMaybe<CampainZeczecUpdateOneWithoutRegisterUserNestedInput>;
  card?: InputMaybe<CardUpdateManyWithoutCreatorNestedInput>;
  coins?: InputMaybe<CoinUpdateManyWithoutUserNestedInput>;
  collection?: InputMaybe<CollectionUpdateManyWithoutOwnerNestedInput>;
  country?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  couponUsages?: InputMaybe<CouponUsageUpdateManyWithoutUserNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  createdStamps?: InputMaybe<StampUpdateManyWithoutCreatorNestedInput>;
  detail?: InputMaybe<OrderDetailUpdateManyWithoutReceiverNestedInput>;
  deviceToken?: InputMaybe<DeviceTokenUpdateManyWithoutUserNestedInput>;
  email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  emailTos?: InputMaybe<EmailToUpdateManyWithoutOwnerNestedInput>;
  favoriteStoreItems?: InputMaybe<StoreItemUpdateManyWithoutFavoriteByUsersNestedInput>;
  fetchFriends?: InputMaybe<UserFriendUpdateManyWithoutOwnerNestedInput>;
  followProfitables?: InputMaybe<ProfitableUpdateManyWithoutFollowedByNestedInput>;
  followedBy?: InputMaybe<UserUpdateManyWithoutFollowingNestedInput>;
  following?: InputMaybe<UserUpdateManyWithoutFollowedByNestedInput>;
  gender?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  groupsJoin?: InputMaybe<GroupUpdateManyWithoutMembersNestedInput>;
  groupsOwn?: InputMaybe<GroupUpdateManyWithoutOwnerNestedInput>;
  headUri?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  history?: InputMaybe<OrderDetailHistoryUpdateManyWithoutOperatorNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  idStr?: InputMaybe<StringFieldUpdateOperationsInput>;
  inFriends?: InputMaybe<UserFriendUpdateManyWithoutUsersNestedInput>;
  inValidReasonHistories?: InputMaybe<InValidReasonUpdateManyWithoutUserNestedInput>;
  lastLogin?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  lastTokenUpdated?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  likePosts?: InputMaybe<PostUpdateManyWithoutLikeUsersNestedInput>;
  manualAddrs?: InputMaybe<ManualAddrUpdateManyWithoutOwnerNestedInput>;
  myCoupons?: InputMaybe<CouponEntityUpdateManyWithoutHolderNestedInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  notificationReceiver?: InputMaybe<NotificationUpdateManyWithoutReceiverNestedInput>;
  notificationSender?: InputMaybe<NotificationUpdateManyWithoutSenderNestedInput>;
  order?: InputMaybe<OrderUpdateManyWithoutSenderNestedInput>;
  packagesAsReceiver?: InputMaybe<PostPackageUpdateManyWithoutReceiversNestedInput>;
  password?: InputMaybe<StringFieldUpdateOperationsInput>;
  pcoins?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  phone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  postPackage?: InputMaybe<PostPackageUpdateManyWithoutSenderNestedInput>;
  postsIn?: InputMaybe<PostUpdateManyWithoutReceiverNestedInput>;
  postsOut?: InputMaybe<PostUpdateManyWithoutSenderNestedInput>;
  profitable?: InputMaybe<ProfitableUpdateOneWithoutUserNestedInput>;
  receive_email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  receive_phone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  reportByMe?: InputMaybe<ReportUserUpdateManyWithoutReporterNestedInput>;
  reported?: InputMaybe<ReportUserUpdateManyWithoutUserNestedInput>;
  reports?: InputMaybe<ReportUpdateManyWithoutReporterNestedInput>;
  settingNotification?: InputMaybe<SettingNotificationUpdateOneWithoutUserNestedInput>;
  settingPrivacy?: InputMaybe<SettingPrivacyUpdateOneWithoutUserNestedInput>;
  signature?: InputMaybe<SignatureUpdateManyWithoutOwnerNestedInput>;
  storePurchaseHistory?: InputMaybe<CartItemUpdateManyWithoutCustomerNestedInput>;
  thirdpartyId?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  vAddrMapping?: InputMaybe<VirtualAddrMappingUpdateOneWithoutOwnerNestedInput>;
  valid?: InputMaybe<BoolFieldUpdateOperationsInput>;
  website?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type UserUpdateWithoutVAddrMappingInput = {
  accessToken?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  address?: InputMaybe<AddressUpdateManyWithoutOwnerNestedInput>;
  attachments?: InputMaybe<AttachmentUpdateManyWithoutOwnerNestedInput>;
  authType?: InputMaybe<EnumUserAuthTypeFieldUpdateOperationsInput>;
  bio?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  campaignNanhui?: InputMaybe<CampaignNanhuiUpdateManyWithoutSubmitterNestedInput>;
  campainZeczec?: InputMaybe<CampainZeczecUpdateOneWithoutRegisterUserNestedInput>;
  card?: InputMaybe<CardUpdateManyWithoutCreatorNestedInput>;
  coins?: InputMaybe<CoinUpdateManyWithoutUserNestedInput>;
  collection?: InputMaybe<CollectionUpdateManyWithoutOwnerNestedInput>;
  country?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  couponUsages?: InputMaybe<CouponUsageUpdateManyWithoutUserNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  createdStamps?: InputMaybe<StampUpdateManyWithoutCreatorNestedInput>;
  detail?: InputMaybe<OrderDetailUpdateManyWithoutReceiverNestedInput>;
  deviceToken?: InputMaybe<DeviceTokenUpdateManyWithoutUserNestedInput>;
  email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  emailTos?: InputMaybe<EmailToUpdateManyWithoutOwnerNestedInput>;
  favoriteStoreItems?: InputMaybe<StoreItemUpdateManyWithoutFavoriteByUsersNestedInput>;
  fetchFriends?: InputMaybe<UserFriendUpdateManyWithoutOwnerNestedInput>;
  followProfitables?: InputMaybe<ProfitableUpdateManyWithoutFollowedByNestedInput>;
  followedBy?: InputMaybe<UserUpdateManyWithoutFollowingNestedInput>;
  following?: InputMaybe<UserUpdateManyWithoutFollowedByNestedInput>;
  gender?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  groupsJoin?: InputMaybe<GroupUpdateManyWithoutMembersNestedInput>;
  groupsOwn?: InputMaybe<GroupUpdateManyWithoutOwnerNestedInput>;
  headUri?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  history?: InputMaybe<OrderDetailHistoryUpdateManyWithoutOperatorNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  idStr?: InputMaybe<StringFieldUpdateOperationsInput>;
  inFriends?: InputMaybe<UserFriendUpdateManyWithoutUsersNestedInput>;
  inValidReasonHistories?: InputMaybe<InValidReasonUpdateManyWithoutUserNestedInput>;
  lastLogin?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  lastTokenUpdated?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  likePosts?: InputMaybe<PostUpdateManyWithoutLikeUsersNestedInput>;
  manualAddrs?: InputMaybe<ManualAddrUpdateManyWithoutOwnerNestedInput>;
  myCoupons?: InputMaybe<CouponEntityUpdateManyWithoutHolderNestedInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  notificationReceiver?: InputMaybe<NotificationUpdateManyWithoutReceiverNestedInput>;
  notificationSender?: InputMaybe<NotificationUpdateManyWithoutSenderNestedInput>;
  order?: InputMaybe<OrderUpdateManyWithoutSenderNestedInput>;
  packagesAsReceiver?: InputMaybe<PostPackageUpdateManyWithoutReceiversNestedInput>;
  password?: InputMaybe<StringFieldUpdateOperationsInput>;
  pcoins?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  phone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  postPackage?: InputMaybe<PostPackageUpdateManyWithoutSenderNestedInput>;
  postsIn?: InputMaybe<PostUpdateManyWithoutReceiverNestedInput>;
  postsOut?: InputMaybe<PostUpdateManyWithoutSenderNestedInput>;
  profitable?: InputMaybe<ProfitableUpdateOneWithoutUserNestedInput>;
  receive_email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  receive_phone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  reportByMe?: InputMaybe<ReportUserUpdateManyWithoutReporterNestedInput>;
  reported?: InputMaybe<ReportUserUpdateManyWithoutUserNestedInput>;
  reports?: InputMaybe<ReportUpdateManyWithoutReporterNestedInput>;
  settingNotification?: InputMaybe<SettingNotificationUpdateOneWithoutUserNestedInput>;
  settingPrivacy?: InputMaybe<SettingPrivacyUpdateOneWithoutUserNestedInput>;
  signature?: InputMaybe<SignatureUpdateManyWithoutOwnerNestedInput>;
  storePurchaseHistory?: InputMaybe<CartItemUpdateManyWithoutCustomerNestedInput>;
  tagsUsed?: InputMaybe<PostTagUpdateManyWithoutUserNestedInput>;
  thirdpartyId?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  valid?: InputMaybe<BoolFieldUpdateOperationsInput>;
  website?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type UserUpsertWithWhereUniqueWithoutFavoriteStoreItemsInput = {
  create: UserCreateWithoutFavoriteStoreItemsInput;
  update: UserUpdateWithoutFavoriteStoreItemsInput;
  where: UserWhereUniqueInput;
};

export type UserUpsertWithWhereUniqueWithoutFollowProfitablesInput = {
  create: UserCreateWithoutFollowProfitablesInput;
  update: UserUpdateWithoutFollowProfitablesInput;
  where: UserWhereUniqueInput;
};

export type UserUpsertWithWhereUniqueWithoutFollowedByInput = {
  create: UserCreateWithoutFollowedByInput;
  update: UserUpdateWithoutFollowedByInput;
  where: UserWhereUniqueInput;
};

export type UserUpsertWithWhereUniqueWithoutFollowingInput = {
  create: UserCreateWithoutFollowingInput;
  update: UserUpdateWithoutFollowingInput;
  where: UserWhereUniqueInput;
};

export type UserUpsertWithWhereUniqueWithoutGroupsJoinInput = {
  create: UserCreateWithoutGroupsJoinInput;
  update: UserUpdateWithoutGroupsJoinInput;
  where: UserWhereUniqueInput;
};

export type UserUpsertWithWhereUniqueWithoutLikePostsInput = {
  create: UserCreateWithoutLikePostsInput;
  update: UserUpdateWithoutLikePostsInput;
  where: UserWhereUniqueInput;
};

export type UserUpsertWithWhereUniqueWithoutPackagesAsReceiverInput = {
  create: UserCreateWithoutPackagesAsReceiverInput;
  update: UserUpdateWithoutPackagesAsReceiverInput;
  where: UserWhereUniqueInput;
};

export type UserUpsertWithoutAddressInput = {
  create: UserCreateWithoutAddressInput;
  update: UserUpdateWithoutAddressInput;
};

export type UserUpsertWithoutAttachmentsInput = {
  create: UserCreateWithoutAttachmentsInput;
  update: UserUpdateWithoutAttachmentsInput;
};

export type UserUpsertWithoutCampaignNanhuiInput = {
  create: UserCreateWithoutCampaignNanhuiInput;
  update: UserUpdateWithoutCampaignNanhuiInput;
};

export type UserUpsertWithoutCampainZeczecInput = {
  create: UserCreateWithoutCampainZeczecInput;
  update: UserUpdateWithoutCampainZeczecInput;
};

export type UserUpsertWithoutCardInput = {
  create: UserCreateWithoutCardInput;
  update: UserUpdateWithoutCardInput;
};

export type UserUpsertWithoutCoinsInput = {
  create: UserCreateWithoutCoinsInput;
  update: UserUpdateWithoutCoinsInput;
};

export type UserUpsertWithoutCollectionInput = {
  create: UserCreateWithoutCollectionInput;
  update: UserUpdateWithoutCollectionInput;
};

export type UserUpsertWithoutCouponUsagesInput = {
  create: UserCreateWithoutCouponUsagesInput;
  update: UserUpdateWithoutCouponUsagesInput;
};

export type UserUpsertWithoutCreatedStampsInput = {
  create: UserCreateWithoutCreatedStampsInput;
  update: UserUpdateWithoutCreatedStampsInput;
};

export type UserUpsertWithoutDetailInput = {
  create: UserCreateWithoutDetailInput;
  update: UserUpdateWithoutDetailInput;
};

export type UserUpsertWithoutDeviceTokenInput = {
  create: UserCreateWithoutDeviceTokenInput;
  update: UserUpdateWithoutDeviceTokenInput;
};

export type UserUpsertWithoutEmailTosInput = {
  create: UserCreateWithoutEmailTosInput;
  update: UserUpdateWithoutEmailTosInput;
};

export type UserUpsertWithoutFriendsInput = {
  create: UserCreateWithoutFriendsInput;
  update: UserUpdateWithoutFriendsInput;
};

export type UserUpsertWithoutGroupsOwnInput = {
  create: UserCreateWithoutGroupsOwnInput;
  update: UserUpdateWithoutGroupsOwnInput;
};

export type UserUpsertWithoutHistoryInput = {
  create: UserCreateWithoutHistoryInput;
  update: UserUpdateWithoutHistoryInput;
};

export type UserUpsertWithoutInFriendsInput = {
  create: UserCreateWithoutInFriendsInput;
  update: UserUpdateWithoutInFriendsInput;
};

export type UserUpsertWithoutInValidReasonHistoriesInput = {
  create: UserCreateWithoutInValidReasonHistoriesInput;
  update: UserUpdateWithoutInValidReasonHistoriesInput;
};

export type UserUpsertWithoutManualAddrsInput = {
  create: UserCreateWithoutManualAddrsInput;
  update: UserUpdateWithoutManualAddrsInput;
};

export type UserUpsertWithoutMyCouponsInput = {
  create: UserCreateWithoutMyCouponsInput;
  update: UserUpdateWithoutMyCouponsInput;
};

export type UserUpsertWithoutNotificationReceiverInput = {
  create: UserCreateWithoutNotificationReceiverInput;
  update: UserUpdateWithoutNotificationReceiverInput;
};

export type UserUpsertWithoutNotificationSenderInput = {
  create: UserCreateWithoutNotificationSenderInput;
  update: UserUpdateWithoutNotificationSenderInput;
};

export type UserUpsertWithoutOrderInput = {
  create: UserCreateWithoutOrderInput;
  update: UserUpdateWithoutOrderInput;
};

export type UserUpsertWithoutPostPackageInput = {
  create: UserCreateWithoutPostPackageInput;
  update: UserUpdateWithoutPostPackageInput;
};

export type UserUpsertWithoutPostsInInput = {
  create: UserCreateWithoutPostsInInput;
  update: UserUpdateWithoutPostsInInput;
};

export type UserUpsertWithoutPostsOutInput = {
  create: UserCreateWithoutPostsOutInput;
  update: UserUpdateWithoutPostsOutInput;
};

export type UserUpsertWithoutProfitableInput = {
  create: UserCreateWithoutProfitableInput;
  update: UserUpdateWithoutProfitableInput;
};

export type UserUpsertWithoutReportByMeInput = {
  create: UserCreateWithoutReportByMeInput;
  update: UserUpdateWithoutReportByMeInput;
};

export type UserUpsertWithoutReportedInput = {
  create: UserCreateWithoutReportedInput;
  update: UserUpdateWithoutReportedInput;
};

export type UserUpsertWithoutReportsInput = {
  create: UserCreateWithoutReportsInput;
  update: UserUpdateWithoutReportsInput;
};

export type UserUpsertWithoutSettingNotificationInput = {
  create: UserCreateWithoutSettingNotificationInput;
  update: UserUpdateWithoutSettingNotificationInput;
};

export type UserUpsertWithoutSettingPrivacyInput = {
  create: UserCreateWithoutSettingPrivacyInput;
  update: UserUpdateWithoutSettingPrivacyInput;
};

export type UserUpsertWithoutSignatureInput = {
  create: UserCreateWithoutSignatureInput;
  update: UserUpdateWithoutSignatureInput;
};

export type UserUpsertWithoutStorePurchaseHistoryInput = {
  create: UserCreateWithoutStorePurchaseHistoryInput;
  update: UserUpdateWithoutStorePurchaseHistoryInput;
};

export type UserUpsertWithoutTagsUsedInput = {
  create: UserCreateWithoutTagsUsedInput;
  update: UserUpdateWithoutTagsUsedInput;
};

export type UserUpsertWithoutVAddrMappingInput = {
  create: UserCreateWithoutVAddrMappingInput;
  update: UserUpdateWithoutVAddrMappingInput;
};

export type UserUser_Email_AuthType_KeyCompoundUniqueInput = {
  authType: UserAuthType;
  email: Scalars['String'];
};

export type UserWhereInput = {
  AND?: InputMaybe<Array<UserWhereInput>>;
  NOT?: InputMaybe<Array<UserWhereInput>>;
  OR?: InputMaybe<Array<UserWhereInput>>;
  accessToken?: InputMaybe<StringNullableFilter>;
  address?: InputMaybe<AddressListRelationFilter>;
  attachments?: InputMaybe<AttachmentListRelationFilter>;
  authType?: InputMaybe<EnumUserAuthTypeFilter>;
  bio?: InputMaybe<StringNullableFilter>;
  campaignNanhui?: InputMaybe<CampaignNanhuiListRelationFilter>;
  campainZeczec?: InputMaybe<CampainZeczecRelationFilter>;
  card?: InputMaybe<CardListRelationFilter>;
  coins?: InputMaybe<CoinListRelationFilter>;
  collection?: InputMaybe<CollectionListRelationFilter>;
  country?: InputMaybe<StringNullableFilter>;
  couponUsages?: InputMaybe<CouponUsageListRelationFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  createdStamps?: InputMaybe<StampListRelationFilter>;
  detail?: InputMaybe<OrderDetailListRelationFilter>;
  deviceToken?: InputMaybe<DeviceTokenListRelationFilter>;
  email?: InputMaybe<StringNullableFilter>;
  emailTos?: InputMaybe<EmailToListRelationFilter>;
  favoriteStoreItems?: InputMaybe<StoreItemListRelationFilter>;
  fetchFriends?: InputMaybe<UserFriendListRelationFilter>;
  followProfitables?: InputMaybe<ProfitableListRelationFilter>;
  followedBy?: InputMaybe<UserListRelationFilter>;
  following?: InputMaybe<UserListRelationFilter>;
  gender?: InputMaybe<StringNullableFilter>;
  groupsJoin?: InputMaybe<GroupListRelationFilter>;
  groupsOwn?: InputMaybe<GroupListRelationFilter>;
  headUri?: InputMaybe<StringNullableFilter>;
  history?: InputMaybe<OrderDetailHistoryListRelationFilter>;
  id?: InputMaybe<StringFilter>;
  idStr?: InputMaybe<StringFilter>;
  inFriends?: InputMaybe<UserFriendListRelationFilter>;
  inValidReasonHistories?: InputMaybe<InValidReasonListRelationFilter>;
  lastLogin?: InputMaybe<DateTimeNullableFilter>;
  lastTokenUpdated?: InputMaybe<DateTimeNullableFilter>;
  likePosts?: InputMaybe<PostListRelationFilter>;
  manualAddrs?: InputMaybe<ManualAddrListRelationFilter>;
  myCoupons?: InputMaybe<CouponEntityListRelationFilter>;
  name?: InputMaybe<StringFilter>;
  notificationReceiver?: InputMaybe<NotificationListRelationFilter>;
  notificationSender?: InputMaybe<NotificationListRelationFilter>;
  order?: InputMaybe<OrderListRelationFilter>;
  packagesAsReceiver?: InputMaybe<PostPackageListRelationFilter>;
  password?: InputMaybe<StringFilter>;
  pcoins?: InputMaybe<DecimalFilter>;
  phone?: InputMaybe<StringNullableFilter>;
  postPackage?: InputMaybe<PostPackageListRelationFilter>;
  postsIn?: InputMaybe<PostListRelationFilter>;
  postsOut?: InputMaybe<PostListRelationFilter>;
  profitable?: InputMaybe<ProfitableRelationFilter>;
  receive_email?: InputMaybe<StringNullableFilter>;
  receive_phone?: InputMaybe<StringNullableFilter>;
  reportByMe?: InputMaybe<ReportUserListRelationFilter>;
  reported?: InputMaybe<ReportUserListRelationFilter>;
  reports?: InputMaybe<ReportListRelationFilter>;
  settingNotification?: InputMaybe<SettingNotificationRelationFilter>;
  settingPrivacy?: InputMaybe<SettingPrivacyRelationFilter>;
  signature?: InputMaybe<SignatureListRelationFilter>;
  storePurchaseHistory?: InputMaybe<CartItemListRelationFilter>;
  tagsUsed?: InputMaybe<PostTagListRelationFilter>;
  thirdpartyId?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  vAddrMapping?: InputMaybe<VirtualAddrMappingRelationFilter>;
  valid?: InputMaybe<BoolFilter>;
  website?: InputMaybe<StringNullableFilter>;
};

export type UserWhereUniqueInput = {
  User_email_authType_key?: InputMaybe<UserUser_Email_AuthType_KeyCompoundUniqueInput>;
  id?: InputMaybe<Scalars['String']>;
  idStr?: InputMaybe<Scalars['String']>;
  thirdpartyId?: InputMaybe<Scalars['String']>;
};

export type VirtualAddrMapping = {
  __typename?: 'VirtualAddrMapping';
  addressId?: Maybe<Scalars['String']>;
  belongZeczec?: Maybe<CampainZeczec>;
  land: VirtualLand;
  landId: Scalars['String'];
  number: Scalars['String'];
  owner?: Maybe<User>;
  ownerId?: Maybe<Scalars['String']>;
  realAddress?: Maybe<Address>;
};

export type VirtualAddrMappingCountAggregate = {
  __typename?: 'VirtualAddrMappingCountAggregate';
  _all: Scalars['Int'];
  addressId: Scalars['Int'];
  landId: Scalars['Int'];
  number: Scalars['Int'];
  ownerId: Scalars['Int'];
};

export type VirtualAddrMappingCountOrderByAggregateInput = {
  addressId?: InputMaybe<SortOrder>;
  landId?: InputMaybe<SortOrder>;
  number?: InputMaybe<SortOrder>;
  ownerId?: InputMaybe<SortOrder>;
};

export type VirtualAddrMappingCreateInput = {
  belongZeczec?: InputMaybe<CampainZeczecCreateNestedOneWithoutVirtualAddrInput>;
  land: VirtualLandCreateNestedOneWithoutMappingsInput;
  number: Scalars['String'];
  owner?: InputMaybe<UserCreateNestedOneWithoutVAddrMappingInput>;
  realAddress?: InputMaybe<AddressCreateNestedOneWithoutVirtualAddrInput>;
};

export type VirtualAddrMappingCreateManyInput = {
  addressId?: InputMaybe<Scalars['String']>;
  landId: Scalars['String'];
  number: Scalars['String'];
  ownerId?: InputMaybe<Scalars['String']>;
};

export type VirtualAddrMappingCreateManyLandInput = {
  addressId?: InputMaybe<Scalars['String']>;
  number: Scalars['String'];
  ownerId?: InputMaybe<Scalars['String']>;
};

export type VirtualAddrMappingCreateManyLandInputEnvelope = {
  data: Array<VirtualAddrMappingCreateManyLandInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type VirtualAddrMappingCreateNestedManyWithoutLandInput = {
  connect?: InputMaybe<Array<VirtualAddrMappingWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<VirtualAddrMappingCreateOrConnectWithoutLandInput>>;
  create?: InputMaybe<Array<VirtualAddrMappingCreateWithoutLandInput>>;
  createMany?: InputMaybe<VirtualAddrMappingCreateManyLandInputEnvelope>;
};

export type VirtualAddrMappingCreateNestedOneWithoutBelongZeczecInput = {
  connect?: InputMaybe<VirtualAddrMappingWhereUniqueInput>;
  connectOrCreate?: InputMaybe<VirtualAddrMappingCreateOrConnectWithoutBelongZeczecInput>;
  create?: InputMaybe<VirtualAddrMappingCreateWithoutBelongZeczecInput>;
};

export type VirtualAddrMappingCreateNestedOneWithoutOwnerInput = {
  connect?: InputMaybe<VirtualAddrMappingWhereUniqueInput>;
  connectOrCreate?: InputMaybe<VirtualAddrMappingCreateOrConnectWithoutOwnerInput>;
  create?: InputMaybe<VirtualAddrMappingCreateWithoutOwnerInput>;
};

export type VirtualAddrMappingCreateNestedOneWithoutRealAddressInput = {
  connect?: InputMaybe<VirtualAddrMappingWhereUniqueInput>;
  connectOrCreate?: InputMaybe<VirtualAddrMappingCreateOrConnectWithoutRealAddressInput>;
  create?: InputMaybe<VirtualAddrMappingCreateWithoutRealAddressInput>;
};

export type VirtualAddrMappingCreateOrConnectWithoutBelongZeczecInput = {
  create: VirtualAddrMappingCreateWithoutBelongZeczecInput;
  where: VirtualAddrMappingWhereUniqueInput;
};

export type VirtualAddrMappingCreateOrConnectWithoutLandInput = {
  create: VirtualAddrMappingCreateWithoutLandInput;
  where: VirtualAddrMappingWhereUniqueInput;
};

export type VirtualAddrMappingCreateOrConnectWithoutOwnerInput = {
  create: VirtualAddrMappingCreateWithoutOwnerInput;
  where: VirtualAddrMappingWhereUniqueInput;
};

export type VirtualAddrMappingCreateOrConnectWithoutRealAddressInput = {
  create: VirtualAddrMappingCreateWithoutRealAddressInput;
  where: VirtualAddrMappingWhereUniqueInput;
};

export type VirtualAddrMappingCreateWithoutBelongZeczecInput = {
  land: VirtualLandCreateNestedOneWithoutMappingsInput;
  number: Scalars['String'];
  owner?: InputMaybe<UserCreateNestedOneWithoutVAddrMappingInput>;
  realAddress?: InputMaybe<AddressCreateNestedOneWithoutVirtualAddrInput>;
};

export type VirtualAddrMappingCreateWithoutLandInput = {
  belongZeczec?: InputMaybe<CampainZeczecCreateNestedOneWithoutVirtualAddrInput>;
  number: Scalars['String'];
  owner?: InputMaybe<UserCreateNestedOneWithoutVAddrMappingInput>;
  realAddress?: InputMaybe<AddressCreateNestedOneWithoutVirtualAddrInput>;
};

export type VirtualAddrMappingCreateWithoutOwnerInput = {
  belongZeczec?: InputMaybe<CampainZeczecCreateNestedOneWithoutVirtualAddrInput>;
  land: VirtualLandCreateNestedOneWithoutMappingsInput;
  number: Scalars['String'];
  realAddress?: InputMaybe<AddressCreateNestedOneWithoutVirtualAddrInput>;
};

export type VirtualAddrMappingCreateWithoutRealAddressInput = {
  belongZeczec?: InputMaybe<CampainZeczecCreateNestedOneWithoutVirtualAddrInput>;
  land: VirtualLandCreateNestedOneWithoutMappingsInput;
  number: Scalars['String'];
  owner?: InputMaybe<UserCreateNestedOneWithoutVAddrMappingInput>;
};

export type VirtualAddrMappingGroupBy = {
  __typename?: 'VirtualAddrMappingGroupBy';
  _count?: Maybe<VirtualAddrMappingCountAggregate>;
  _max?: Maybe<VirtualAddrMappingMaxAggregate>;
  _min?: Maybe<VirtualAddrMappingMinAggregate>;
  addressId?: Maybe<Scalars['String']>;
  landId: Scalars['String'];
  number: Scalars['String'];
  ownerId?: Maybe<Scalars['String']>;
};

export type VirtualAddrMappingLandIdNumberCompoundUniqueInput = {
  landId: Scalars['String'];
  number: Scalars['String'];
};

export type VirtualAddrMappingListRelationFilter = {
  every?: InputMaybe<VirtualAddrMappingWhereInput>;
  none?: InputMaybe<VirtualAddrMappingWhereInput>;
  some?: InputMaybe<VirtualAddrMappingWhereInput>;
};

export type VirtualAddrMappingMaxAggregate = {
  __typename?: 'VirtualAddrMappingMaxAggregate';
  addressId?: Maybe<Scalars['String']>;
  landId?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  ownerId?: Maybe<Scalars['String']>;
};

export type VirtualAddrMappingMaxOrderByAggregateInput = {
  addressId?: InputMaybe<SortOrder>;
  landId?: InputMaybe<SortOrder>;
  number?: InputMaybe<SortOrder>;
  ownerId?: InputMaybe<SortOrder>;
};

export type VirtualAddrMappingMinAggregate = {
  __typename?: 'VirtualAddrMappingMinAggregate';
  addressId?: Maybe<Scalars['String']>;
  landId?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  ownerId?: Maybe<Scalars['String']>;
};

export type VirtualAddrMappingMinOrderByAggregateInput = {
  addressId?: InputMaybe<SortOrder>;
  landId?: InputMaybe<SortOrder>;
  number?: InputMaybe<SortOrder>;
  ownerId?: InputMaybe<SortOrder>;
};

export type VirtualAddrMappingOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type VirtualAddrMappingOrderByWithAggregationInput = {
  _count?: InputMaybe<VirtualAddrMappingCountOrderByAggregateInput>;
  _max?: InputMaybe<VirtualAddrMappingMaxOrderByAggregateInput>;
  _min?: InputMaybe<VirtualAddrMappingMinOrderByAggregateInput>;
  addressId?: InputMaybe<SortOrder>;
  landId?: InputMaybe<SortOrder>;
  number?: InputMaybe<SortOrder>;
  ownerId?: InputMaybe<SortOrder>;
};

export type VirtualAddrMappingOrderByWithRelationInput = {
  addressId?: InputMaybe<SortOrder>;
  belongZeczec?: InputMaybe<CampainZeczecOrderByWithRelationInput>;
  land?: InputMaybe<VirtualLandOrderByWithRelationInput>;
  landId?: InputMaybe<SortOrder>;
  number?: InputMaybe<SortOrder>;
  owner?: InputMaybe<UserOrderByWithRelationInput>;
  ownerId?: InputMaybe<SortOrder>;
  realAddress?: InputMaybe<AddressOrderByWithRelationInput>;
};

export type VirtualAddrMappingRelationFilter = {
  is?: InputMaybe<VirtualAddrMappingWhereInput>;
  isNot?: InputMaybe<VirtualAddrMappingWhereInput>;
};

export enum VirtualAddrMappingScalarFieldEnum {
  AddressId = 'addressId',
  LandId = 'landId',
  Number = 'number',
  OwnerId = 'ownerId'
}

export type VirtualAddrMappingScalarWhereInput = {
  AND?: InputMaybe<Array<VirtualAddrMappingScalarWhereInput>>;
  NOT?: InputMaybe<Array<VirtualAddrMappingScalarWhereInput>>;
  OR?: InputMaybe<Array<VirtualAddrMappingScalarWhereInput>>;
  addressId?: InputMaybe<StringNullableFilter>;
  landId?: InputMaybe<StringFilter>;
  number?: InputMaybe<StringFilter>;
  ownerId?: InputMaybe<StringNullableFilter>;
};

export type VirtualAddrMappingScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<VirtualAddrMappingScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<VirtualAddrMappingScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<VirtualAddrMappingScalarWhereWithAggregatesInput>>;
  addressId?: InputMaybe<StringNullableWithAggregatesFilter>;
  landId?: InputMaybe<StringWithAggregatesFilter>;
  number?: InputMaybe<StringWithAggregatesFilter>;
  ownerId?: InputMaybe<StringNullableWithAggregatesFilter>;
};

export type VirtualAddrMappingUpdateInput = {
  belongZeczec?: InputMaybe<CampainZeczecUpdateOneWithoutVirtualAddrNestedInput>;
  land?: InputMaybe<VirtualLandUpdateOneRequiredWithoutMappingsNestedInput>;
  number?: InputMaybe<StringFieldUpdateOperationsInput>;
  owner?: InputMaybe<UserUpdateOneWithoutVAddrMappingNestedInput>;
  realAddress?: InputMaybe<AddressUpdateOneWithoutVirtualAddrNestedInput>;
};

export type VirtualAddrMappingUpdateManyMutationInput = {
  number?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type VirtualAddrMappingUpdateManyWithWhereWithoutLandInput = {
  data: VirtualAddrMappingUpdateManyMutationInput;
  where: VirtualAddrMappingScalarWhereInput;
};

export type VirtualAddrMappingUpdateManyWithoutLandNestedInput = {
  connect?: InputMaybe<Array<VirtualAddrMappingWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<VirtualAddrMappingCreateOrConnectWithoutLandInput>>;
  create?: InputMaybe<Array<VirtualAddrMappingCreateWithoutLandInput>>;
  createMany?: InputMaybe<VirtualAddrMappingCreateManyLandInputEnvelope>;
  delete?: InputMaybe<Array<VirtualAddrMappingWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<VirtualAddrMappingScalarWhereInput>>;
  disconnect?: InputMaybe<Array<VirtualAddrMappingWhereUniqueInput>>;
  set?: InputMaybe<Array<VirtualAddrMappingWhereUniqueInput>>;
  update?: InputMaybe<Array<VirtualAddrMappingUpdateWithWhereUniqueWithoutLandInput>>;
  updateMany?: InputMaybe<Array<VirtualAddrMappingUpdateManyWithWhereWithoutLandInput>>;
  upsert?: InputMaybe<Array<VirtualAddrMappingUpsertWithWhereUniqueWithoutLandInput>>;
};

export type VirtualAddrMappingUpdateOneRequiredWithoutBelongZeczecNestedInput = {
  connect?: InputMaybe<VirtualAddrMappingWhereUniqueInput>;
  connectOrCreate?: InputMaybe<VirtualAddrMappingCreateOrConnectWithoutBelongZeczecInput>;
  create?: InputMaybe<VirtualAddrMappingCreateWithoutBelongZeczecInput>;
  update?: InputMaybe<VirtualAddrMappingUpdateWithoutBelongZeczecInput>;
  upsert?: InputMaybe<VirtualAddrMappingUpsertWithoutBelongZeczecInput>;
};

export type VirtualAddrMappingUpdateOneWithoutOwnerNestedInput = {
  connect?: InputMaybe<VirtualAddrMappingWhereUniqueInput>;
  connectOrCreate?: InputMaybe<VirtualAddrMappingCreateOrConnectWithoutOwnerInput>;
  create?: InputMaybe<VirtualAddrMappingCreateWithoutOwnerInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<VirtualAddrMappingUpdateWithoutOwnerInput>;
  upsert?: InputMaybe<VirtualAddrMappingUpsertWithoutOwnerInput>;
};

export type VirtualAddrMappingUpdateOneWithoutRealAddressNestedInput = {
  connect?: InputMaybe<VirtualAddrMappingWhereUniqueInput>;
  connectOrCreate?: InputMaybe<VirtualAddrMappingCreateOrConnectWithoutRealAddressInput>;
  create?: InputMaybe<VirtualAddrMappingCreateWithoutRealAddressInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<VirtualAddrMappingUpdateWithoutRealAddressInput>;
  upsert?: InputMaybe<VirtualAddrMappingUpsertWithoutRealAddressInput>;
};

export type VirtualAddrMappingUpdateWithWhereUniqueWithoutLandInput = {
  data: VirtualAddrMappingUpdateWithoutLandInput;
  where: VirtualAddrMappingWhereUniqueInput;
};

export type VirtualAddrMappingUpdateWithoutBelongZeczecInput = {
  land?: InputMaybe<VirtualLandUpdateOneRequiredWithoutMappingsNestedInput>;
  number?: InputMaybe<StringFieldUpdateOperationsInput>;
  owner?: InputMaybe<UserUpdateOneWithoutVAddrMappingNestedInput>;
  realAddress?: InputMaybe<AddressUpdateOneWithoutVirtualAddrNestedInput>;
};

export type VirtualAddrMappingUpdateWithoutLandInput = {
  belongZeczec?: InputMaybe<CampainZeczecUpdateOneWithoutVirtualAddrNestedInput>;
  number?: InputMaybe<StringFieldUpdateOperationsInput>;
  owner?: InputMaybe<UserUpdateOneWithoutVAddrMappingNestedInput>;
  realAddress?: InputMaybe<AddressUpdateOneWithoutVirtualAddrNestedInput>;
};

export type VirtualAddrMappingUpdateWithoutOwnerInput = {
  belongZeczec?: InputMaybe<CampainZeczecUpdateOneWithoutVirtualAddrNestedInput>;
  land?: InputMaybe<VirtualLandUpdateOneRequiredWithoutMappingsNestedInput>;
  number?: InputMaybe<StringFieldUpdateOperationsInput>;
  realAddress?: InputMaybe<AddressUpdateOneWithoutVirtualAddrNestedInput>;
};

export type VirtualAddrMappingUpdateWithoutRealAddressInput = {
  belongZeczec?: InputMaybe<CampainZeczecUpdateOneWithoutVirtualAddrNestedInput>;
  land?: InputMaybe<VirtualLandUpdateOneRequiredWithoutMappingsNestedInput>;
  number?: InputMaybe<StringFieldUpdateOperationsInput>;
  owner?: InputMaybe<UserUpdateOneWithoutVAddrMappingNestedInput>;
};

export type VirtualAddrMappingUpsertWithWhereUniqueWithoutLandInput = {
  create: VirtualAddrMappingCreateWithoutLandInput;
  update: VirtualAddrMappingUpdateWithoutLandInput;
  where: VirtualAddrMappingWhereUniqueInput;
};

export type VirtualAddrMappingUpsertWithoutBelongZeczecInput = {
  create: VirtualAddrMappingCreateWithoutBelongZeczecInput;
  update: VirtualAddrMappingUpdateWithoutBelongZeczecInput;
};

export type VirtualAddrMappingUpsertWithoutOwnerInput = {
  create: VirtualAddrMappingCreateWithoutOwnerInput;
  update: VirtualAddrMappingUpdateWithoutOwnerInput;
};

export type VirtualAddrMappingUpsertWithoutRealAddressInput = {
  create: VirtualAddrMappingCreateWithoutRealAddressInput;
  update: VirtualAddrMappingUpdateWithoutRealAddressInput;
};

export type VirtualAddrMappingWhereInput = {
  AND?: InputMaybe<Array<VirtualAddrMappingWhereInput>>;
  NOT?: InputMaybe<Array<VirtualAddrMappingWhereInput>>;
  OR?: InputMaybe<Array<VirtualAddrMappingWhereInput>>;
  addressId?: InputMaybe<StringNullableFilter>;
  belongZeczec?: InputMaybe<CampainZeczecRelationFilter>;
  land?: InputMaybe<VirtualLandRelationFilter>;
  landId?: InputMaybe<StringFilter>;
  number?: InputMaybe<StringFilter>;
  owner?: InputMaybe<UserRelationFilter>;
  ownerId?: InputMaybe<StringNullableFilter>;
  realAddress?: InputMaybe<AddressRelationFilter>;
};

export type VirtualAddrMappingWhereUniqueInput = {
  addressId?: InputMaybe<Scalars['String']>;
  landId_number?: InputMaybe<VirtualAddrMappingLandIdNumberCompoundUniqueInput>;
  ownerId?: InputMaybe<Scalars['String']>;
};

export type VirtualLand = {
  __typename?: 'VirtualLand';
  _count?: Maybe<VirtualLandCount>;
  description: Scalars['String'];
  id: Scalars['String'];
  lastNumber: Scalars['Int'];
  mappings: Array<VirtualAddrMapping>;
  name: Scalars['String'];
  picUri?: Maybe<Scalars['String']>;
  statusText?: Maybe<Scalars['String']>;
};


export type VirtualLandMappingsArgs = {
  cursor?: InputMaybe<VirtualAddrMappingWhereUniqueInput>;
  distinct?: InputMaybe<Array<VirtualAddrMappingScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<VirtualAddrMappingOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<VirtualAddrMappingWhereInput>;
};

export type VirtualLandAvgAggregate = {
  __typename?: 'VirtualLandAvgAggregate';
  lastNumber?: Maybe<Scalars['Float']>;
};

export type VirtualLandAvgOrderByAggregateInput = {
  lastNumber?: InputMaybe<SortOrder>;
};

export type VirtualLandCount = {
  __typename?: 'VirtualLandCount';
  mappings: Scalars['Int'];
};


export type VirtualLandCountMappingsArgs = {
  where?: InputMaybe<VirtualAddrMappingWhereInput>;
};

export type VirtualLandCountAggregate = {
  __typename?: 'VirtualLandCountAggregate';
  _all: Scalars['Int'];
  description: Scalars['Int'];
  id: Scalars['Int'];
  lastNumber: Scalars['Int'];
  name: Scalars['Int'];
  picUri: Scalars['Int'];
  statusText: Scalars['Int'];
};

export type VirtualLandCountOrderByAggregateInput = {
  description?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  lastNumber?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  picUri?: InputMaybe<SortOrder>;
  statusText?: InputMaybe<SortOrder>;
};

export type VirtualLandCreateInput = {
  description: Scalars['String'];
  id?: InputMaybe<Scalars['String']>;
  lastNumber?: InputMaybe<Scalars['Int']>;
  mappings?: InputMaybe<VirtualAddrMappingCreateNestedManyWithoutLandInput>;
  name: Scalars['String'];
  picUri?: InputMaybe<Scalars['String']>;
  statusText?: InputMaybe<Scalars['String']>;
};

export type VirtualLandCreateManyInput = {
  description: Scalars['String'];
  id?: InputMaybe<Scalars['String']>;
  lastNumber?: InputMaybe<Scalars['Int']>;
  name: Scalars['String'];
  picUri?: InputMaybe<Scalars['String']>;
  statusText?: InputMaybe<Scalars['String']>;
};

export type VirtualLandCreateNestedOneWithoutMappingsInput = {
  connect?: InputMaybe<VirtualLandWhereUniqueInput>;
  connectOrCreate?: InputMaybe<VirtualLandCreateOrConnectWithoutMappingsInput>;
  create?: InputMaybe<VirtualLandCreateWithoutMappingsInput>;
};

export type VirtualLandCreateOrConnectWithoutMappingsInput = {
  create: VirtualLandCreateWithoutMappingsInput;
  where: VirtualLandWhereUniqueInput;
};

export type VirtualLandCreateWithoutMappingsInput = {
  description: Scalars['String'];
  id?: InputMaybe<Scalars['String']>;
  lastNumber?: InputMaybe<Scalars['Int']>;
  name: Scalars['String'];
  picUri?: InputMaybe<Scalars['String']>;
  statusText?: InputMaybe<Scalars['String']>;
};

export type VirtualLandGroupBy = {
  __typename?: 'VirtualLandGroupBy';
  _avg?: Maybe<VirtualLandAvgAggregate>;
  _count?: Maybe<VirtualLandCountAggregate>;
  _max?: Maybe<VirtualLandMaxAggregate>;
  _min?: Maybe<VirtualLandMinAggregate>;
  _sum?: Maybe<VirtualLandSumAggregate>;
  description: Scalars['String'];
  id: Scalars['String'];
  lastNumber: Scalars['Int'];
  name: Scalars['String'];
  picUri?: Maybe<Scalars['String']>;
  statusText?: Maybe<Scalars['String']>;
};

export type VirtualLandMaxAggregate = {
  __typename?: 'VirtualLandMaxAggregate';
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  lastNumber?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  picUri?: Maybe<Scalars['String']>;
  statusText?: Maybe<Scalars['String']>;
};

export type VirtualLandMaxOrderByAggregateInput = {
  description?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  lastNumber?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  picUri?: InputMaybe<SortOrder>;
  statusText?: InputMaybe<SortOrder>;
};

export type VirtualLandMinAggregate = {
  __typename?: 'VirtualLandMinAggregate';
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  lastNumber?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  picUri?: Maybe<Scalars['String']>;
  statusText?: Maybe<Scalars['String']>;
};

export type VirtualLandMinOrderByAggregateInput = {
  description?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  lastNumber?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  picUri?: InputMaybe<SortOrder>;
  statusText?: InputMaybe<SortOrder>;
};

export type VirtualLandOrderByWithAggregationInput = {
  _avg?: InputMaybe<VirtualLandAvgOrderByAggregateInput>;
  _count?: InputMaybe<VirtualLandCountOrderByAggregateInput>;
  _max?: InputMaybe<VirtualLandMaxOrderByAggregateInput>;
  _min?: InputMaybe<VirtualLandMinOrderByAggregateInput>;
  _sum?: InputMaybe<VirtualLandSumOrderByAggregateInput>;
  description?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  lastNumber?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  picUri?: InputMaybe<SortOrder>;
  statusText?: InputMaybe<SortOrder>;
};

export type VirtualLandOrderByWithRelationInput = {
  description?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  lastNumber?: InputMaybe<SortOrder>;
  mappings?: InputMaybe<VirtualAddrMappingOrderByRelationAggregateInput>;
  name?: InputMaybe<SortOrder>;
  picUri?: InputMaybe<SortOrder>;
  statusText?: InputMaybe<SortOrder>;
};

export type VirtualLandRelationFilter = {
  is?: InputMaybe<VirtualLandWhereInput>;
  isNot?: InputMaybe<VirtualLandWhereInput>;
};

export enum VirtualLandScalarFieldEnum {
  Description = 'description',
  Id = 'id',
  LastNumber = 'lastNumber',
  Name = 'name',
  PicUri = 'picUri',
  StatusText = 'statusText'
}

export type VirtualLandScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<VirtualLandScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<VirtualLandScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<VirtualLandScalarWhereWithAggregatesInput>>;
  description?: InputMaybe<StringWithAggregatesFilter>;
  id?: InputMaybe<StringWithAggregatesFilter>;
  lastNumber?: InputMaybe<IntWithAggregatesFilter>;
  name?: InputMaybe<StringWithAggregatesFilter>;
  picUri?: InputMaybe<StringNullableWithAggregatesFilter>;
  statusText?: InputMaybe<StringNullableWithAggregatesFilter>;
};

export type VirtualLandSumAggregate = {
  __typename?: 'VirtualLandSumAggregate';
  lastNumber?: Maybe<Scalars['Int']>;
};

export type VirtualLandSumOrderByAggregateInput = {
  lastNumber?: InputMaybe<SortOrder>;
};

export type VirtualLandUpdateInput = {
  description?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  lastNumber?: InputMaybe<IntFieldUpdateOperationsInput>;
  mappings?: InputMaybe<VirtualAddrMappingUpdateManyWithoutLandNestedInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  picUri?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  statusText?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type VirtualLandUpdateManyMutationInput = {
  description?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  lastNumber?: InputMaybe<IntFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  picUri?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  statusText?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type VirtualLandUpdateOneRequiredWithoutMappingsNestedInput = {
  connect?: InputMaybe<VirtualLandWhereUniqueInput>;
  connectOrCreate?: InputMaybe<VirtualLandCreateOrConnectWithoutMappingsInput>;
  create?: InputMaybe<VirtualLandCreateWithoutMappingsInput>;
  update?: InputMaybe<VirtualLandUpdateWithoutMappingsInput>;
  upsert?: InputMaybe<VirtualLandUpsertWithoutMappingsInput>;
};

export type VirtualLandUpdateWithoutMappingsInput = {
  description?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  lastNumber?: InputMaybe<IntFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  picUri?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  statusText?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type VirtualLandUpsertWithoutMappingsInput = {
  create: VirtualLandCreateWithoutMappingsInput;
  update: VirtualLandUpdateWithoutMappingsInput;
};

export type VirtualLandWhereInput = {
  AND?: InputMaybe<Array<VirtualLandWhereInput>>;
  NOT?: InputMaybe<Array<VirtualLandWhereInput>>;
  OR?: InputMaybe<Array<VirtualLandWhereInput>>;
  description?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  lastNumber?: InputMaybe<IntFilter>;
  mappings?: InputMaybe<VirtualAddrMappingListRelationFilter>;
  name?: InputMaybe<StringFilter>;
  picUri?: InputMaybe<StringNullableFilter>;
  statusText?: InputMaybe<StringNullableFilter>;
};

export type VirtualLandWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

export type BenefitMemosQueryVariables = Exact<{
  where?: InputMaybe<BenefitMemoWhereInput>;
  orderBy?: InputMaybe<Array<BenefitMemoOrderByWithRelationInput> | BenefitMemoOrderByWithRelationInput>;
}>;


export type BenefitMemosQuery = { __typename?: 'Query', benefitMemos: Array<{ __typename?: 'BenefitMemo', type: BenefitType, profitableId: string, transferAmount?: number | null, createdAt: any, id: string, transfer?: { __typename?: 'BenefitTransfer', id: string, exchangeRateForCoin?: number | null, totalAmount?: number | null, transferDate?: string | null, transferMemo?: any | null } | null, coin?: { __typename?: 'BenefitMemoToCoin', benefit: number, originalPrice: number, ratio: number, subTotal: number, coin: { __typename?: 'Coin', id: string, readableId?: string | null, createdAt: any, user: { __typename?: 'User', id: string, idStr: string, name: string } }, storeItem: { __typename?: 'StoreItem', id: string, type: StoreItemType, name: string, price: any, itemCard?: { __typename?: 'Card', id: string, name: string, free: boolean, price: number, headUri: string, tailUri: string, valid: boolean } | null, itemStamp?: { __typename?: 'Stamp', id: string, name: string, free: boolean, price: number, picUri: string, valid: boolean } | null, profitable: { __typename?: 'Profitable', id: string, type: ProfitableType, name?: string | null, user: { __typename?: 'User', id: string, idStr: string, name: string } } } } | null, profitable: { __typename?: 'Profitable', id: string, name?: string | null, user: { __typename?: 'User', id: string, name: string, idStr: string } } }> };

export type OrderBenefitsQueryVariables = Exact<{
  where?: InputMaybe<BenefitMemoWhereInput>;
  orderBy?: InputMaybe<Array<BenefitMemoOrderByWithRelationInput> | BenefitMemoOrderByWithRelationInput>;
}>;


export type OrderBenefitsQuery = { __typename?: 'Query', benefitMemos: Array<{ __typename?: 'BenefitMemo', type: BenefitType, transferAmount?: number | null, profitableId: string, createdAt: any, id: string, transfer?: { __typename?: 'BenefitTransfer', id: string, exchangeRateForCoin?: number | null, totalAmount?: number | null, transferDate?: string | null } | null, order?: { __typename?: 'BenefitMemoToOrder', benefit: number, createdAt: any, originalPrice: number, ratio: number, subTotal: number, benefitRatio: { __typename?: 'StoreRatio', value: number }, order: { __typename?: 'Order', id: string, readableId?: string | null, createdAt: any, postPackage: { __typename?: 'PostPackage', id: string, sendingType: SendingType, sendDate: any, packageType: PackageType, manualAddrs: Array<{ __typename?: 'ManualAddr', address: { __typename?: 'Address', country: string } }> }, sender: { __typename?: 'User', id: string, idStr: string, name: string } }, post: { __typename?: 'Post', loc?: any | null, receiver?: { __typename?: 'User', id: string, idStr: string, name: string, address: Array<{ __typename?: 'Address', country: string }> } | null }, storeItem: { __typename?: 'StoreItem', id: string, type: StoreItemType, name: string, price: any, itemCard?: { __typename?: 'Card', id: string, name: string, free: boolean, price: number, headUri: string, tailUri: string, valid: boolean } | null, itemStamp?: { __typename?: 'Stamp', id: string, name: string, free: boolean, price: number, picUri: string, valid: boolean } | null, profitable: { __typename?: 'Profitable', id: string, type: ProfitableType, name?: string | null, user: { __typename?: 'User', id: string, idStr: string, name: string } } } } | null, profitable: { __typename?: 'Profitable', id: string, name?: string | null, user: { __typename?: 'User', id: string, name: string, idStr: string } } }> };

export type CreateOneBenefitTransferMutationVariables = Exact<{
  data: BenefitTransferCreateInput;
}>;


export type CreateOneBenefitTransferMutation = { __typename?: 'Mutation', createOneBenefitTransfer: { __typename?: 'BenefitTransfer', id: string } };

export type UpdateOneBenefitTransferMutationVariables = Exact<{
  data: BenefitTransferUpdateInput;
  where: BenefitTransferWhereUniqueInput;
}>;


export type UpdateOneBenefitTransferMutation = { __typename?: 'Mutation', updateOneBenefitTransfer?: { __typename?: 'BenefitTransfer', id: string } | null };

export type CampaignNanhuisQueryVariables = Exact<{
  orderBy?: InputMaybe<Array<CampaignNanhuiOrderByWithRelationInput> | CampaignNanhuiOrderByWithRelationInput>;
}>;


export type CampaignNanhuisQuery = { __typename?: 'Query', campaignNanhuis: Array<{ __typename?: 'CampaignNanhui', id: string, name: string, phone: string, postCode: string, address: string, createdAt: any }> };

export type CardsQueryVariables = Exact<{
  postsUsedWhere2?: InputMaybe<PostWhereInput>;
  where?: InputMaybe<CardWhereInput>;
  orderBy?: InputMaybe<Array<CardOrderByWithRelationInput> | CardOrderByWithRelationInput>;
}>;


export type CardsQuery = { __typename?: 'Query', cards: Array<{ __typename?: 'Card', id: string, name: string, description?: string | null, headUri: string, _count?: { __typename?: 'CardCount', collections: number } | null, postsUsed: Array<{ __typename?: 'Post', senderId: string, receiverId?: string | null, sendDate: any, rearUri: string, createdAt: any, package?: { __typename?: 'PostPackage', packageType: PackageType, sendingType: SendingType, order?: { __typename?: 'Order', isPaid: boolean } | null } | null }> }> };

export type CoinsQueryVariables = Exact<{
  where?: InputMaybe<CoinWhereInput>;
}>;


export type CoinsQuery = { __typename?: 'Query', coins: Array<{ __typename?: 'Coin', id: string, createdAt: any, user: { __typename?: 'User', idStr: string }, cart?: { __typename?: 'CartItem', storeToCartItems: Array<{ __typename?: 'StoreItemToCartItem', storeItem: { __typename?: 'StoreItem', name: string, price: any, profitable: { __typename?: 'Profitable', userId: string } } }> } | null }> };

export type CouponInfosQueryVariables = Exact<{
  orderBy?: InputMaybe<Array<CouponInfoOrderByWithRelationInput> | CouponInfoOrderByWithRelationInput>;
  where?: InputMaybe<CouponEntityWhereInput>;
}>;


export type CouponInfosQuery = { __typename?: 'Query', couponInfos: Array<{ __typename?: 'CouponInfo', description: string, _count?: { __typename?: 'CouponInfoCount', couponEntities: number } | null, couponEntities: Array<{ __typename?: 'CouponEntity', seq: number, used: boolean }>, activity?: { __typename?: 'CouponActivity', card?: { __typename?: 'Card', id: string, name: string, description?: string | null, headUri: string } | null } | null }> };

export type OrdersQueryVariables = Exact<{
  where?: InputMaybe<OrderWhereInput>;
}>;


export type OrdersQuery = { __typename?: 'Query', orders: Array<{ __typename?: 'Order', id: string, createdAt: any, status: OrderStatus, readableId?: string | null, price: any, isInvoiced: boolean, invoiceParam?: any | null, isPaid: boolean, payment: Array<{ __typename?: 'Payment', tappay?: { __typename?: 'Tappay', rec_trade_id?: string | null } | null }>, invoice: Array<{ __typename?: 'Invoice', number?: string | null }>, postPackage: { __typename?: 'PostPackage', sendDate: any, totalCount: number }, sender: { __typename?: 'User', id: string, idStr: string, name: string, email?: string | null, createdAt: any }, details: Array<{ __typename?: 'OrderDetail', id: string, status: OrderStatus, price: any, sendDate: any, sendStatus: PackageStatus, createdAt: any, receiver?: { __typename?: 'User', country?: string | null } | null, tags: Array<{ __typename?: 'OrderTag', tag: string }> }> }> };

export type OrderDetailQueryVariables = Exact<{
  where: OrderDetailWhereUniqueInput;
}>;


export type OrderDetailQuery = { __typename?: 'Query', orderDetail?: { __typename?: 'OrderDetail', id: string, status: OrderStatus, printStatus: PrintStatus, sendStatus: PackageStatus, createdAt: any, updatedAt: any, memo?: string | null, printCompany?: string | null, sendCompany?: string | null, sendId?: string | null, sendDate: any, realname?: string | null, phone?: string | null, address?: string | null, country?: string | null, price: any, order: { __typename?: 'Order', isPaid: boolean, readableId?: string | null, isInvoiced: boolean, invoiceParam?: any | null, price: any, sender: { __typename?: 'User', idStr: string, name: string }, postPackage: { __typename?: 'PostPackage', totalCount: number }, payment: Array<{ __typename?: 'Payment', tappay?: { __typename?: 'Tappay', rec_trade_id?: string | null } | null }>, invoice: Array<{ __typename?: 'Invoice', number?: string | null }> }, receiver?: { __typename?: 'User', idStr: string, name: string, phone?: string | null, country?: string | null, address: Array<{ __typename?: 'Address', postcode: string, country: string, state?: string | null, city: string, district?: string | null, street?: string | null, address1: string, address2?: string | null }> } | null, tags: Array<{ __typename?: 'OrderTag', tag: string }>, histories: Array<{ __typename?: 'OrderDetailHistory', id: string, createdAt: any, operation?: string | null, status: OrderStatus, operator: { __typename?: 'User', idStr: string }, lastHistory?: { __typename?: 'OrderDetailHistory', status: OrderStatus } | null }>, post: { __typename?: 'Post', loc?: any | null, rearUri: string, rearUri4Practical?: string | null, qrcode?: string | null, body: { __typename?: 'Card', headUri: string, orientation: CardOrientation } } } | null };

export type UpdateOneOrderDetailMutationVariables = Exact<{
  data: OrderDetailUpdateInput;
  where: OrderDetailWhereUniqueInput;
}>;


export type UpdateOneOrderDetailMutation = { __typename?: 'Mutation', updateOneOrderDetail?: { __typename?: 'OrderDetail', realname?: string | null, address?: string | null, country?: string | null, memo?: string | null, phone?: string | null, tags: Array<{ __typename?: 'OrderTag', tag: string }> } | null };

export type UpdateManyOrderDetailMutationVariables = Exact<{
  data: OrderDetailUpdateManyMutationInput;
  where?: InputMaybe<OrderDetailWhereInput>;
}>;


export type UpdateManyOrderDetailMutation = { __typename?: 'Mutation', updateManyOrderDetail: { __typename?: 'AffectedRowsOutput', count: number } };

export type CreateOneOrderDetailHistoryMutationVariables = Exact<{
  data: OrderDetailHistoryCreateInput;
}>;


export type CreateOneOrderDetailHistoryMutation = { __typename?: 'Mutation', createOneOrderDetailHistory: { __typename?: 'OrderDetailHistory', id: string, createdAt: any, operation?: string | null, status: OrderStatus, operator: { __typename?: 'User', idStr: string }, lastHistory?: { __typename?: 'OrderDetailHistory', status: OrderStatus } | null } };

export type CreateManyOrderDetailHistoryMutationVariables = Exact<{
  data: Array<OrderDetailHistoryCreateManyInput> | OrderDetailHistoryCreateManyInput;
}>;


export type CreateManyOrderDetailHistoryMutation = { __typename?: 'Mutation', createManyOrderDetailHistory: { __typename?: 'AffectedRowsOutput', count: number } };

export type OrderDetailsQueryVariables = Exact<{
  where: OrderDetailWhereInput;
  orderBy?: InputMaybe<Array<OrderDetailOrderByWithRelationInput> | OrderDetailOrderByWithRelationInput>;
}>;


export type OrderDetailsQuery = { __typename?: 'Query', orderDetails: Array<{ __typename?: 'OrderDetail', id: string, status: OrderStatus, printStatus: PrintStatus, sendStatus: PackageStatus, createdAt: any, updatedAt: any, memo?: string | null, printCompany?: string | null, sendCompany?: string | null, sendId?: string | null, sendDate: any, realname?: string | null, phone?: string | null, address?: string | null, country?: string | null, price: any, order: { __typename?: 'Order', isPaid: boolean, price: any, readableId?: string | null, isInvoiced: boolean, invoiceParam?: any | null, payment: Array<{ __typename?: 'Payment', tappay?: { __typename?: 'Tappay', rec_trade_id?: string | null } | null }>, postPackage: { __typename?: 'PostPackage', totalCount: number }, sender: { __typename?: 'User', idStr: string, name: string }, invoice: Array<{ __typename?: 'Invoice', number?: string | null }> }, receiver?: { __typename?: 'User', idStr: string, name: string, phone?: string | null, country?: string | null, address: Array<{ __typename?: 'Address', postcode: string, country: string, state?: string | null, city: string, district?: string | null, street?: string | null, address1: string, address2?: string | null }> } | null, tags: Array<{ __typename?: 'OrderTag', tag: string }>, histories: Array<{ __typename?: 'OrderDetailHistory', id: string, createdAt: any, operation?: string | null, status: OrderStatus, operator: { __typename?: 'User', idStr: string }, lastHistory?: { __typename?: 'OrderDetailHistory', status: OrderStatus } | null }>, post: { __typename?: 'Post', loc?: any | null, rearUri: string, rearUri4Practical?: string | null, qrcode?: string | null, body: { __typename?: 'Card', headUri: string, orientation: CardOrientation } } }> };

export type OrderTagsQueryVariables = Exact<{ [key: string]: never; }>;


export type OrderTagsQuery = { __typename?: 'Query', orderTags: Array<{ __typename?: 'OrderTag', tag: string }> };

export type ProfitablesQueryVariables = Exact<{
  where?: InputMaybe<ProfitableWhereInput>;
}>;


export type ProfitablesQuery = { __typename?: 'Query', profitables: Array<{ __typename?: 'Profitable', id: string, userId: string, type: ProfitableType, name?: string | null, saleCount: number, statusText?: string | null, beneficialInfo?: any | null, bio?: string | null, class: ProfitableClass, followedBy: Array<{ __typename?: 'User', id: string }>, saleRatio?: { __typename?: 'StoreRatio', id: string, value: number, description: string } | null, sendRatio?: { __typename?: 'StoreRatio', id: string, value: number, description: string } | null, user: { __typename?: 'User', name: string, country?: string | null, idStr: string, coins: Array<{ __typename?: 'Coin', id: string, amount: any, createdAt: any, user: { __typename?: 'User', idStr: string } }>, order: Array<{ __typename?: 'Order', readableId?: string | null, createdAt: any, price: any, postPackage: { __typename?: 'PostPackage', posts: Array<{ __typename?: 'Post', loc?: any | null }> } }> }, storeItems: Array<{ __typename?: 'StoreItem', description?: string | null }> }> };

export type ProfitableQueryVariables = Exact<{
  where: ProfitableWhereUniqueInput;
}>;


export type ProfitableQuery = { __typename?: 'Query', profitable?: { __typename?: 'Profitable', id: string, userId: string, type: ProfitableType, name?: string | null, saleCount: number, statusText?: string | null, beneficialInfo?: any | null, bio?: string | null, class: ProfitableClass, followedBy: Array<{ __typename?: 'User', id: string }>, saleRatio?: { __typename?: 'StoreRatio', id: string, value: number, description: string } | null, sendRatio?: { __typename?: 'StoreRatio', id: string, value: number, description: string } | null, user: { __typename?: 'User', name: string, country?: string | null, idStr: string, coins: Array<{ __typename?: 'Coin', id: string, amount: any, createdAt: any, user: { __typename?: 'User', idStr: string } }>, order: Array<{ __typename?: 'Order', readableId?: string | null, createdAt: any, price: any, postPackage: { __typename?: 'PostPackage', posts: Array<{ __typename?: 'Post', loc?: any | null }> } }> }, storeItems: Array<{ __typename?: 'StoreItem', description?: string | null, profitable: { __typename?: 'Profitable', userId: string } }> } | null };

export type NewProfitableMutationVariables = Exact<{
  data: ProfitableCreateInput;
}>;


export type NewProfitableMutation = { __typename?: 'Mutation', createOneProfitable: { __typename?: 'Profitable', id: string, saleRatioId?: string | null } };

export type UpdateOneProfitableMutationVariables = Exact<{
  data: ProfitableUpdateInput;
  where: ProfitableWhereUniqueInput;
}>;


export type UpdateOneProfitableMutation = { __typename?: 'Mutation', updateOneProfitable?: { __typename?: 'Profitable', id: string, saleRatioId?: string | null } | null };

export type RelatedPracticalSendingsToProfitableQueryVariables = Exact<{
  where?: InputMaybe<PostPackageWhereInput>;
}>;


export type RelatedPracticalSendingsToProfitableQuery = { __typename?: 'Query', postPackages: Array<{ __typename?: 'PostPackage', id: string, sendingType: SendingType, sendDate: any, order?: { __typename?: 'Order', readableId?: string | null, price: any, createdAt: any, details: Array<{ __typename?: 'OrderDetail', id: string }> } | null, _count?: { __typename?: 'PostPackageCount', posts: number } | null, posts: Array<{ __typename?: 'Post', body: { __typename?: 'Card', id: string, inStoreItem: Array<{ __typename?: 'StoreItem', readableId?: string | null, price: any, profitableId: string }> } }> }> };

export type NewStoreItemCardMutationVariables = Exact<{
  data: StoreItemCreateInput;
}>;


export type NewStoreItemCardMutation = { __typename?: 'Mutation', createOneStoreItem: { __typename?: 'StoreItem', id: string, type: StoreItemType, valid: boolean, itemCard?: { __typename?: 'Card', id: string, sn: string, free: boolean, price: number, valid: boolean, headUri: string, tailUri: string, orientation: CardOrientation, name: string, description?: string | null, creator: { __typename?: 'User', idStr: string, name: string }, scenicSpot?: { __typename?: 'ScenicSpot', id: string, country: string, latitude: number, longitude: number, name: string, description?: string | null } | null } | null, itemStamp?: { __typename?: 'Stamp', id: string, sn: string, free: boolean, price: number, valid: boolean, picUri: string, name: string, description?: string | null, createdAt: any, creator: { __typename?: 'User', idStr: string, name: string }, scenicSpot?: { __typename?: 'ScenicSpot', id: string, country: string, latitude: number, longitude: number, name: string, description?: string | null } | null } | null, profitable: { __typename?: 'Profitable', id: string, name?: string | null } } };

export type StoreItemsQueryVariables = Exact<{
  where?: InputMaybe<StoreItemWhereInput>;
  orderBy?: InputMaybe<Array<StoreItemOrderByWithRelationInput> | StoreItemOrderByWithRelationInput>;
}>;


export type StoreItemsQuery = { __typename?: 'Query', storeItems: Array<{ __typename?: 'StoreItem', id: string, type: StoreItemType, valid: boolean, itemCard?: { __typename?: 'Card', id: string, sn: string, free: boolean, price: number, valid: boolean, headUri: string, tailUri: string, orientation: CardOrientation, name: string, description?: string | null, createdAt: any, creator: { __typename?: 'User', idStr: string, name: string }, scenicSpot?: { __typename?: 'ScenicSpot', id: string, country: string, latitude: number, longitude: number, name: string, description?: string | null } | null } | null, itemStamp?: { __typename?: 'Stamp', id: string, sn: string, free: boolean, price: number, valid: boolean, picUri: string, name: string, description?: string | null, createdAt: any, creator: { __typename?: 'User', idStr: string, name: string }, scenicSpot?: { __typename?: 'ScenicSpot', id: string, country: string, latitude: number, longitude: number, name: string, description?: string | null } | null } | null, profitable: { __typename?: 'Profitable', id: string, name?: string | null, user: { __typename?: 'User', idStr: string, name: string } } }> };

export type StoreItemQueryVariables = Exact<{
  where: StoreItemWhereUniqueInput;
}>;


export type StoreItemQuery = { __typename?: 'Query', storeItem?: { __typename?: 'StoreItem', id: string, type: StoreItemType, valid: boolean, itemCard?: { __typename?: 'Card', id: string, sn: string, free: boolean, price: number, valid: boolean, headUri: string, tailUri: string, orientation: CardOrientation, name: string, description?: string | null, createdAt: any, creator: { __typename?: 'User', idStr: string, name: string }, scenicSpot?: { __typename?: 'ScenicSpot', id: string, country: string, latitude: number, longitude: number, name: string, description?: string | null } | null } | null, itemStamp?: { __typename?: 'Stamp', id: string, sn: string, free: boolean, price: number, valid: boolean, picUri: string, name: string, description?: string | null, createdAt: any, creator: { __typename?: 'User', idStr: string, name: string }, scenicSpot?: { __typename?: 'ScenicSpot', id: string, country: string, latitude: number, longitude: number, name: string, description?: string | null } | null } | null, profitable: { __typename?: 'Profitable', id: string, name?: string | null, user: { __typename?: 'User', idStr: string, name: string } } } | null };

export type UpdateOneStoreItemMutationVariables = Exact<{
  data: StoreItemUpdateInput;
  where: StoreItemWhereUniqueInput;
}>;


export type UpdateOneStoreItemMutation = { __typename?: 'Mutation', updateOneStoreItem?: { __typename?: 'StoreItem', id: string } | null };

export type StoreRatiosQueryVariables = Exact<{
  orderBy?: InputMaybe<Array<StoreRatioOrderByWithRelationInput> | StoreRatioOrderByWithRelationInput>;
}>;


export type StoreRatiosQuery = { __typename?: 'Query', storeRatios: Array<{ __typename?: 'StoreRatio', id: string, value: number, description: string }> };

export type CreateOneStoreRatioHistoryItemMutationVariables = Exact<{
  data: StoreRatioHistoryItemCreateInput;
}>;


export type CreateOneStoreRatioHistoryItemMutation = { __typename?: 'Mutation', createOneStoreRatioHistoryItem: { __typename?: 'StoreRatioHistoryItem', id: string, createdAt: any } };

export type UsersQueryVariables = Exact<{
  where?: InputMaybe<UserWhereInput>;
}>;


export type UsersQuery = { __typename?: 'Query', users: Array<{ __typename?: 'User', id: string, idStr: string, name: string, phone?: string | null, bio?: string | null, country?: string | null, address: Array<{ __typename?: 'Address', address1: string, address2?: string | null }> }> };


export const BenefitMemosDocument = gql`
    query BenefitMemos($where: BenefitMemoWhereInput, $orderBy: [BenefitMemoOrderByWithRelationInput!]) {
  benefitMemos(where: $where, orderBy: $orderBy) {
    type
    profitableId
    transferAmount
    transfer {
      id
      exchangeRateForCoin
      totalAmount
      transferDate
      transferMemo
    }
    coin {
      coin {
        id
        readableId
        createdAt
        user {
          id
          idStr
          name
        }
      }
      storeItem {
        id
        type
        name
        price
        itemCard {
          id
          name
          free
          price
          headUri
          tailUri
          valid
        }
        itemStamp {
          id
          name
          free
          price
          picUri
          valid
        }
        profitable {
          id
          type
          name
          user {
            id
            idStr
            name
          }
        }
      }
      benefit
      originalPrice
      ratio
      subTotal
    }
    profitable {
      id
      name
      user {
        id
        name
        idStr
      }
    }
    createdAt
    id
  }
}
    `;

/**
 * __useBenefitMemosQuery__
 *
 * To run a query within a React component, call `useBenefitMemosQuery` and pass it any options that fit your needs.
 * When your component renders, `useBenefitMemosQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBenefitMemosQuery({
 *   variables: {
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useBenefitMemosQuery(baseOptions?: Apollo.QueryHookOptions<BenefitMemosQuery, BenefitMemosQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BenefitMemosQuery, BenefitMemosQueryVariables>(BenefitMemosDocument, options);
      }
export function useBenefitMemosLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BenefitMemosQuery, BenefitMemosQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BenefitMemosQuery, BenefitMemosQueryVariables>(BenefitMemosDocument, options);
        }
export type BenefitMemosQueryHookResult = ReturnType<typeof useBenefitMemosQuery>;
export type BenefitMemosLazyQueryHookResult = ReturnType<typeof useBenefitMemosLazyQuery>;
export type BenefitMemosQueryResult = Apollo.QueryResult<BenefitMemosQuery, BenefitMemosQueryVariables>;
export const OrderBenefitsDocument = gql`
    query OrderBenefits($where: BenefitMemoWhereInput, $orderBy: [BenefitMemoOrderByWithRelationInput!]) {
  benefitMemos(where: $where, orderBy: $orderBy) {
    type
    transferAmount
    profitableId
    transfer {
      id
      exchangeRateForCoin
      totalAmount
      transferDate
    }
    order {
      benefit
      createdAt
      benefitRatio {
        value
      }
      order {
        id
        readableId
        createdAt
        postPackage {
          id
          sendingType
          sendDate
          packageType
          manualAddrs {
            address {
              country
            }
          }
        }
        sender {
          id
          idStr
          name
        }
      }
      post {
        loc
        receiver {
          id
          idStr
          name
          address {
            country
          }
        }
      }
      storeItem {
        id
        type
        name
        price
        itemCard {
          id
          name
          free
          price
          headUri
          tailUri
          valid
        }
        itemStamp {
          id
          name
          free
          price
          picUri
          valid
        }
        profitable {
          id
          type
          name
          user {
            id
            idStr
            name
          }
        }
      }
      originalPrice
      ratio
      subTotal
    }
    profitable {
      id
      name
      user {
        id
        name
        idStr
      }
    }
    createdAt
    id
  }
}
    `;

/**
 * __useOrderBenefitsQuery__
 *
 * To run a query within a React component, call `useOrderBenefitsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrderBenefitsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrderBenefitsQuery({
 *   variables: {
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useOrderBenefitsQuery(baseOptions?: Apollo.QueryHookOptions<OrderBenefitsQuery, OrderBenefitsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrderBenefitsQuery, OrderBenefitsQueryVariables>(OrderBenefitsDocument, options);
      }
export function useOrderBenefitsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrderBenefitsQuery, OrderBenefitsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrderBenefitsQuery, OrderBenefitsQueryVariables>(OrderBenefitsDocument, options);
        }
export type OrderBenefitsQueryHookResult = ReturnType<typeof useOrderBenefitsQuery>;
export type OrderBenefitsLazyQueryHookResult = ReturnType<typeof useOrderBenefitsLazyQuery>;
export type OrderBenefitsQueryResult = Apollo.QueryResult<OrderBenefitsQuery, OrderBenefitsQueryVariables>;
export const CreateOneBenefitTransferDocument = gql`
    mutation CreateOneBenefitTransfer($data: BenefitTransferCreateInput!) {
  createOneBenefitTransfer(data: $data) {
    id
  }
}
    `;
export type CreateOneBenefitTransferMutationFn = Apollo.MutationFunction<CreateOneBenefitTransferMutation, CreateOneBenefitTransferMutationVariables>;

/**
 * __useCreateOneBenefitTransferMutation__
 *
 * To run a mutation, you first call `useCreateOneBenefitTransferMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOneBenefitTransferMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOneBenefitTransferMutation, { data, loading, error }] = useCreateOneBenefitTransferMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateOneBenefitTransferMutation(baseOptions?: Apollo.MutationHookOptions<CreateOneBenefitTransferMutation, CreateOneBenefitTransferMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateOneBenefitTransferMutation, CreateOneBenefitTransferMutationVariables>(CreateOneBenefitTransferDocument, options);
      }
export type CreateOneBenefitTransferMutationHookResult = ReturnType<typeof useCreateOneBenefitTransferMutation>;
export type CreateOneBenefitTransferMutationResult = Apollo.MutationResult<CreateOneBenefitTransferMutation>;
export type CreateOneBenefitTransferMutationOptions = Apollo.BaseMutationOptions<CreateOneBenefitTransferMutation, CreateOneBenefitTransferMutationVariables>;
export const UpdateOneBenefitTransferDocument = gql`
    mutation UpdateOneBenefitTransfer($data: BenefitTransferUpdateInput!, $where: BenefitTransferWhereUniqueInput!) {
  updateOneBenefitTransfer(data: $data, where: $where) {
    id
  }
}
    `;
export type UpdateOneBenefitTransferMutationFn = Apollo.MutationFunction<UpdateOneBenefitTransferMutation, UpdateOneBenefitTransferMutationVariables>;

/**
 * __useUpdateOneBenefitTransferMutation__
 *
 * To run a mutation, you first call `useUpdateOneBenefitTransferMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOneBenefitTransferMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOneBenefitTransferMutation, { data, loading, error }] = useUpdateOneBenefitTransferMutation({
 *   variables: {
 *      data: // value for 'data'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useUpdateOneBenefitTransferMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOneBenefitTransferMutation, UpdateOneBenefitTransferMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOneBenefitTransferMutation, UpdateOneBenefitTransferMutationVariables>(UpdateOneBenefitTransferDocument, options);
      }
export type UpdateOneBenefitTransferMutationHookResult = ReturnType<typeof useUpdateOneBenefitTransferMutation>;
export type UpdateOneBenefitTransferMutationResult = Apollo.MutationResult<UpdateOneBenefitTransferMutation>;
export type UpdateOneBenefitTransferMutationOptions = Apollo.BaseMutationOptions<UpdateOneBenefitTransferMutation, UpdateOneBenefitTransferMutationVariables>;
export const CampaignNanhuisDocument = gql`
    query CampaignNanhuis($orderBy: [CampaignNanhuiOrderByWithRelationInput!]) {
  campaignNanhuis(orderBy: $orderBy) {
    id
    name
    phone
    postCode
    address
    createdAt
  }
}
    `;

/**
 * __useCampaignNanhuisQuery__
 *
 * To run a query within a React component, call `useCampaignNanhuisQuery` and pass it any options that fit your needs.
 * When your component renders, `useCampaignNanhuisQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCampaignNanhuisQuery({
 *   variables: {
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useCampaignNanhuisQuery(baseOptions?: Apollo.QueryHookOptions<CampaignNanhuisQuery, CampaignNanhuisQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CampaignNanhuisQuery, CampaignNanhuisQueryVariables>(CampaignNanhuisDocument, options);
      }
export function useCampaignNanhuisLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CampaignNanhuisQuery, CampaignNanhuisQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CampaignNanhuisQuery, CampaignNanhuisQueryVariables>(CampaignNanhuisDocument, options);
        }
export type CampaignNanhuisQueryHookResult = ReturnType<typeof useCampaignNanhuisQuery>;
export type CampaignNanhuisLazyQueryHookResult = ReturnType<typeof useCampaignNanhuisLazyQuery>;
export type CampaignNanhuisQueryResult = Apollo.QueryResult<CampaignNanhuisQuery, CampaignNanhuisQueryVariables>;
export const CardsDocument = gql`
    query Cards($postsUsedWhere2: PostWhereInput, $where: CardWhereInput, $orderBy: [CardOrderByWithRelationInput!]) {
  cards(where: $where, orderBy: $orderBy) {
    id
    name
    description
    headUri
    _count {
      collections
    }
    postsUsed(where: $postsUsedWhere2) {
      senderId
      receiverId
      sendDate
      rearUri
      createdAt
      package {
        packageType
        sendingType
        order {
          isPaid
        }
      }
    }
  }
}
    `;

/**
 * __useCardsQuery__
 *
 * To run a query within a React component, call `useCardsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCardsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCardsQuery({
 *   variables: {
 *      postsUsedWhere2: // value for 'postsUsedWhere2'
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useCardsQuery(baseOptions?: Apollo.QueryHookOptions<CardsQuery, CardsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CardsQuery, CardsQueryVariables>(CardsDocument, options);
      }
export function useCardsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CardsQuery, CardsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CardsQuery, CardsQueryVariables>(CardsDocument, options);
        }
export type CardsQueryHookResult = ReturnType<typeof useCardsQuery>;
export type CardsLazyQueryHookResult = ReturnType<typeof useCardsLazyQuery>;
export type CardsQueryResult = Apollo.QueryResult<CardsQuery, CardsQueryVariables>;
export const CoinsDocument = gql`
    query Coins($where: CoinWhereInput) {
  coins(where: $where) {
    id
    createdAt
    user {
      idStr
    }
    cart {
      storeToCartItems {
        storeItem {
          name
          price
          profitable {
            userId
          }
        }
      }
    }
  }
}
    `;

/**
 * __useCoinsQuery__
 *
 * To run a query within a React component, call `useCoinsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCoinsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCoinsQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useCoinsQuery(baseOptions?: Apollo.QueryHookOptions<CoinsQuery, CoinsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CoinsQuery, CoinsQueryVariables>(CoinsDocument, options);
      }
export function useCoinsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CoinsQuery, CoinsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CoinsQuery, CoinsQueryVariables>(CoinsDocument, options);
        }
export type CoinsQueryHookResult = ReturnType<typeof useCoinsQuery>;
export type CoinsLazyQueryHookResult = ReturnType<typeof useCoinsLazyQuery>;
export type CoinsQueryResult = Apollo.QueryResult<CoinsQuery, CoinsQueryVariables>;
export const CouponInfosDocument = gql`
    query CouponInfos($orderBy: [CouponInfoOrderByWithRelationInput!], $where: CouponEntityWhereInput) {
  couponInfos(orderBy: $orderBy) {
    description
    _count {
      couponEntities
    }
    couponEntities(where: $where) {
      seq
      used
    }
    activity {
      card {
        id
        name
        description
        headUri
      }
    }
  }
}
    `;

/**
 * __useCouponInfosQuery__
 *
 * To run a query within a React component, call `useCouponInfosQuery` and pass it any options that fit your needs.
 * When your component renders, `useCouponInfosQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCouponInfosQuery({
 *   variables: {
 *      orderBy: // value for 'orderBy'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useCouponInfosQuery(baseOptions?: Apollo.QueryHookOptions<CouponInfosQuery, CouponInfosQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CouponInfosQuery, CouponInfosQueryVariables>(CouponInfosDocument, options);
      }
export function useCouponInfosLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CouponInfosQuery, CouponInfosQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CouponInfosQuery, CouponInfosQueryVariables>(CouponInfosDocument, options);
        }
export type CouponInfosQueryHookResult = ReturnType<typeof useCouponInfosQuery>;
export type CouponInfosLazyQueryHookResult = ReturnType<typeof useCouponInfosLazyQuery>;
export type CouponInfosQueryResult = Apollo.QueryResult<CouponInfosQuery, CouponInfosQueryVariables>;
export const OrdersDocument = gql`
    query orders($where: OrderWhereInput) {
  orders(where: $where) {
    id
    createdAt
    status
    readableId
    price
    isInvoiced
    invoiceParam
    isPaid
    payment {
      tappay {
        rec_trade_id
      }
    }
    invoice {
      number
    }
    postPackage {
      sendDate
      totalCount
    }
    sender {
      id
      idStr
      name
      email
      createdAt
    }
    details {
      id
      status
      price
      sendDate
      sendStatus
      createdAt
      receiver {
        country
      }
      tags {
        tag
      }
    }
  }
}
    `;

/**
 * __useOrdersQuery__
 *
 * To run a query within a React component, call `useOrdersQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrdersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrdersQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useOrdersQuery(baseOptions?: Apollo.QueryHookOptions<OrdersQuery, OrdersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrdersQuery, OrdersQueryVariables>(OrdersDocument, options);
      }
export function useOrdersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrdersQuery, OrdersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrdersQuery, OrdersQueryVariables>(OrdersDocument, options);
        }
export type OrdersQueryHookResult = ReturnType<typeof useOrdersQuery>;
export type OrdersLazyQueryHookResult = ReturnType<typeof useOrdersLazyQuery>;
export type OrdersQueryResult = Apollo.QueryResult<OrdersQuery, OrdersQueryVariables>;
export const OrderDetailDocument = gql`
    query orderDetail($where: OrderDetailWhereUniqueInput!) {
  orderDetail(where: $where) {
    id
    status
    printStatus
    sendStatus
    createdAt
    updatedAt
    order {
      isPaid
      readableId
      isInvoiced
      invoiceParam
      price
      sender {
        idStr
        name
      }
      postPackage {
        totalCount
      }
      payment {
        tappay {
          rec_trade_id
        }
      }
      invoice {
        number
      }
    }
    receiver {
      idStr
      name
      phone
      address {
        postcode
        country
        state
        city
        district
        street
        address1
        address2
      }
      country
    }
    memo
    tags {
      tag
    }
    printCompany
    sendCompany
    sendId
    sendDate
    histories {
      id
      createdAt
      operation
      operator {
        idStr
      }
      lastHistory {
        status
      }
      status
    }
    realname
    phone
    address
    country
    price
    post {
      loc
      body {
        headUri
        orientation
      }
      rearUri
      rearUri4Practical
      qrcode
    }
  }
}
    `;

/**
 * __useOrderDetailQuery__
 *
 * To run a query within a React component, call `useOrderDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrderDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrderDetailQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useOrderDetailQuery(baseOptions: Apollo.QueryHookOptions<OrderDetailQuery, OrderDetailQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrderDetailQuery, OrderDetailQueryVariables>(OrderDetailDocument, options);
      }
export function useOrderDetailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrderDetailQuery, OrderDetailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrderDetailQuery, OrderDetailQueryVariables>(OrderDetailDocument, options);
        }
export type OrderDetailQueryHookResult = ReturnType<typeof useOrderDetailQuery>;
export type OrderDetailLazyQueryHookResult = ReturnType<typeof useOrderDetailLazyQuery>;
export type OrderDetailQueryResult = Apollo.QueryResult<OrderDetailQuery, OrderDetailQueryVariables>;
export const UpdateOneOrderDetailDocument = gql`
    mutation updateOneOrderDetail($data: OrderDetailUpdateInput!, $where: OrderDetailWhereUniqueInput!) {
  updateOneOrderDetail(data: $data, where: $where) {
    realname
    address
    country
    memo
    phone
    tags {
      tag
    }
  }
}
    `;
export type UpdateOneOrderDetailMutationFn = Apollo.MutationFunction<UpdateOneOrderDetailMutation, UpdateOneOrderDetailMutationVariables>;

/**
 * __useUpdateOneOrderDetailMutation__
 *
 * To run a mutation, you first call `useUpdateOneOrderDetailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOneOrderDetailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOneOrderDetailMutation, { data, loading, error }] = useUpdateOneOrderDetailMutation({
 *   variables: {
 *      data: // value for 'data'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useUpdateOneOrderDetailMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOneOrderDetailMutation, UpdateOneOrderDetailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOneOrderDetailMutation, UpdateOneOrderDetailMutationVariables>(UpdateOneOrderDetailDocument, options);
      }
export type UpdateOneOrderDetailMutationHookResult = ReturnType<typeof useUpdateOneOrderDetailMutation>;
export type UpdateOneOrderDetailMutationResult = Apollo.MutationResult<UpdateOneOrderDetailMutation>;
export type UpdateOneOrderDetailMutationOptions = Apollo.BaseMutationOptions<UpdateOneOrderDetailMutation, UpdateOneOrderDetailMutationVariables>;
export const UpdateManyOrderDetailDocument = gql`
    mutation UpdateManyOrderDetail($data: OrderDetailUpdateManyMutationInput!, $where: OrderDetailWhereInput) {
  updateManyOrderDetail(data: $data, where: $where) {
    count
  }
}
    `;
export type UpdateManyOrderDetailMutationFn = Apollo.MutationFunction<UpdateManyOrderDetailMutation, UpdateManyOrderDetailMutationVariables>;

/**
 * __useUpdateManyOrderDetailMutation__
 *
 * To run a mutation, you first call `useUpdateManyOrderDetailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateManyOrderDetailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateManyOrderDetailMutation, { data, loading, error }] = useUpdateManyOrderDetailMutation({
 *   variables: {
 *      data: // value for 'data'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useUpdateManyOrderDetailMutation(baseOptions?: Apollo.MutationHookOptions<UpdateManyOrderDetailMutation, UpdateManyOrderDetailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateManyOrderDetailMutation, UpdateManyOrderDetailMutationVariables>(UpdateManyOrderDetailDocument, options);
      }
export type UpdateManyOrderDetailMutationHookResult = ReturnType<typeof useUpdateManyOrderDetailMutation>;
export type UpdateManyOrderDetailMutationResult = Apollo.MutationResult<UpdateManyOrderDetailMutation>;
export type UpdateManyOrderDetailMutationOptions = Apollo.BaseMutationOptions<UpdateManyOrderDetailMutation, UpdateManyOrderDetailMutationVariables>;
export const CreateOneOrderDetailHistoryDocument = gql`
    mutation createOneOrderDetailHistory($data: OrderDetailHistoryCreateInput!) {
  createOneOrderDetailHistory(data: $data) {
    id
    createdAt
    operation
    operator {
      idStr
    }
    lastHistory {
      status
    }
    status
  }
}
    `;
export type CreateOneOrderDetailHistoryMutationFn = Apollo.MutationFunction<CreateOneOrderDetailHistoryMutation, CreateOneOrderDetailHistoryMutationVariables>;

/**
 * __useCreateOneOrderDetailHistoryMutation__
 *
 * To run a mutation, you first call `useCreateOneOrderDetailHistoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOneOrderDetailHistoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOneOrderDetailHistoryMutation, { data, loading, error }] = useCreateOneOrderDetailHistoryMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateOneOrderDetailHistoryMutation(baseOptions?: Apollo.MutationHookOptions<CreateOneOrderDetailHistoryMutation, CreateOneOrderDetailHistoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateOneOrderDetailHistoryMutation, CreateOneOrderDetailHistoryMutationVariables>(CreateOneOrderDetailHistoryDocument, options);
      }
export type CreateOneOrderDetailHistoryMutationHookResult = ReturnType<typeof useCreateOneOrderDetailHistoryMutation>;
export type CreateOneOrderDetailHistoryMutationResult = Apollo.MutationResult<CreateOneOrderDetailHistoryMutation>;
export type CreateOneOrderDetailHistoryMutationOptions = Apollo.BaseMutationOptions<CreateOneOrderDetailHistoryMutation, CreateOneOrderDetailHistoryMutationVariables>;
export const CreateManyOrderDetailHistoryDocument = gql`
    mutation CreateManyOrderDetailHistory($data: [OrderDetailHistoryCreateManyInput!]!) {
  createManyOrderDetailHistory(data: $data) {
    count
  }
}
    `;
export type CreateManyOrderDetailHistoryMutationFn = Apollo.MutationFunction<CreateManyOrderDetailHistoryMutation, CreateManyOrderDetailHistoryMutationVariables>;

/**
 * __useCreateManyOrderDetailHistoryMutation__
 *
 * To run a mutation, you first call `useCreateManyOrderDetailHistoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateManyOrderDetailHistoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createManyOrderDetailHistoryMutation, { data, loading, error }] = useCreateManyOrderDetailHistoryMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateManyOrderDetailHistoryMutation(baseOptions?: Apollo.MutationHookOptions<CreateManyOrderDetailHistoryMutation, CreateManyOrderDetailHistoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateManyOrderDetailHistoryMutation, CreateManyOrderDetailHistoryMutationVariables>(CreateManyOrderDetailHistoryDocument, options);
      }
export type CreateManyOrderDetailHistoryMutationHookResult = ReturnType<typeof useCreateManyOrderDetailHistoryMutation>;
export type CreateManyOrderDetailHistoryMutationResult = Apollo.MutationResult<CreateManyOrderDetailHistoryMutation>;
export type CreateManyOrderDetailHistoryMutationOptions = Apollo.BaseMutationOptions<CreateManyOrderDetailHistoryMutation, CreateManyOrderDetailHistoryMutationVariables>;
export const OrderDetailsDocument = gql`
    query orderDetails($where: OrderDetailWhereInput!, $orderBy: [OrderDetailOrderByWithRelationInput!]) {
  orderDetails(where: $where, orderBy: $orderBy) {
    id
    status
    printStatus
    sendStatus
    createdAt
    updatedAt
    order {
      isPaid
      price
      readableId
      isInvoiced
      invoiceParam
      payment {
        tappay {
          rec_trade_id
        }
      }
      postPackage {
        totalCount
      }
      sender {
        idStr
        name
      }
      invoice {
        number
      }
    }
    receiver {
      idStr
      name
      phone
      address {
        postcode
        country
        state
        city
        district
        street
        address1
        address2
      }
      country
    }
    memo
    tags {
      tag
    }
    printCompany
    sendCompany
    sendId
    sendDate
    histories {
      id
      createdAt
      operation
      operator {
        idStr
      }
      lastHistory {
        status
      }
      status
    }
    realname
    phone
    address
    country
    price
    post {
      loc
      body {
        headUri
        orientation
      }
      rearUri
      rearUri4Practical
      qrcode
    }
  }
}
    `;

/**
 * __useOrderDetailsQuery__
 *
 * To run a query within a React component, call `useOrderDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrderDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrderDetailsQuery({
 *   variables: {
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useOrderDetailsQuery(baseOptions: Apollo.QueryHookOptions<OrderDetailsQuery, OrderDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrderDetailsQuery, OrderDetailsQueryVariables>(OrderDetailsDocument, options);
      }
export function useOrderDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrderDetailsQuery, OrderDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrderDetailsQuery, OrderDetailsQueryVariables>(OrderDetailsDocument, options);
        }
export type OrderDetailsQueryHookResult = ReturnType<typeof useOrderDetailsQuery>;
export type OrderDetailsLazyQueryHookResult = ReturnType<typeof useOrderDetailsLazyQuery>;
export type OrderDetailsQueryResult = Apollo.QueryResult<OrderDetailsQuery, OrderDetailsQueryVariables>;
export const OrderTagsDocument = gql`
    query OrderTags {
  orderTags {
    tag
  }
}
    `;

/**
 * __useOrderTagsQuery__
 *
 * To run a query within a React component, call `useOrderTagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrderTagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrderTagsQuery({
 *   variables: {
 *   },
 * });
 */
export function useOrderTagsQuery(baseOptions?: Apollo.QueryHookOptions<OrderTagsQuery, OrderTagsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrderTagsQuery, OrderTagsQueryVariables>(OrderTagsDocument, options);
      }
export function useOrderTagsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrderTagsQuery, OrderTagsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrderTagsQuery, OrderTagsQueryVariables>(OrderTagsDocument, options);
        }
export type OrderTagsQueryHookResult = ReturnType<typeof useOrderTagsQuery>;
export type OrderTagsLazyQueryHookResult = ReturnType<typeof useOrderTagsLazyQuery>;
export type OrderTagsQueryResult = Apollo.QueryResult<OrderTagsQuery, OrderTagsQueryVariables>;
export const ProfitablesDocument = gql`
    query Profitables($where: ProfitableWhereInput) {
  profitables(where: $where) {
    id
    userId
    type
    name
    saleCount
    statusText
    beneficialInfo
    bio
    class
    followedBy {
      id
    }
    saleRatio {
      id
      value
      description
    }
    sendRatio {
      id
      value
      description
    }
    user {
      name
      country
      idStr
      coins {
        id
        amount
        createdAt
        user {
          idStr
        }
      }
      order {
        readableId
        createdAt
        price
        postPackage {
          posts {
            loc
          }
        }
      }
    }
    storeItems {
      description
    }
  }
}
    `;

/**
 * __useProfitablesQuery__
 *
 * To run a query within a React component, call `useProfitablesQuery` and pass it any options that fit your needs.
 * When your component renders, `useProfitablesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProfitablesQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useProfitablesQuery(baseOptions?: Apollo.QueryHookOptions<ProfitablesQuery, ProfitablesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProfitablesQuery, ProfitablesQueryVariables>(ProfitablesDocument, options);
      }
export function useProfitablesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProfitablesQuery, ProfitablesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProfitablesQuery, ProfitablesQueryVariables>(ProfitablesDocument, options);
        }
export type ProfitablesQueryHookResult = ReturnType<typeof useProfitablesQuery>;
export type ProfitablesLazyQueryHookResult = ReturnType<typeof useProfitablesLazyQuery>;
export type ProfitablesQueryResult = Apollo.QueryResult<ProfitablesQuery, ProfitablesQueryVariables>;
export const ProfitableDocument = gql`
    query Profitable($where: ProfitableWhereUniqueInput!) {
  profitable(where: $where) {
    id
    userId
    type
    name
    saleCount
    statusText
    beneficialInfo
    bio
    class
    followedBy {
      id
    }
    saleRatio {
      id
      value
      description
    }
    sendRatio {
      id
      value
      description
    }
    user {
      name
      country
      idStr
      coins {
        id
        amount
        createdAt
        user {
          idStr
        }
      }
      order {
        readableId
        createdAt
        price
        postPackage {
          posts {
            loc
          }
        }
      }
    }
    storeItems {
      description
      profitable {
        userId
      }
    }
  }
}
    `;

/**
 * __useProfitableQuery__
 *
 * To run a query within a React component, call `useProfitableQuery` and pass it any options that fit your needs.
 * When your component renders, `useProfitableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProfitableQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useProfitableQuery(baseOptions: Apollo.QueryHookOptions<ProfitableQuery, ProfitableQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProfitableQuery, ProfitableQueryVariables>(ProfitableDocument, options);
      }
export function useProfitableLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProfitableQuery, ProfitableQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProfitableQuery, ProfitableQueryVariables>(ProfitableDocument, options);
        }
export type ProfitableQueryHookResult = ReturnType<typeof useProfitableQuery>;
export type ProfitableLazyQueryHookResult = ReturnType<typeof useProfitableLazyQuery>;
export type ProfitableQueryResult = Apollo.QueryResult<ProfitableQuery, ProfitableQueryVariables>;
export const NewProfitableDocument = gql`
    mutation NewProfitable($data: ProfitableCreateInput!) {
  createOneProfitable(data: $data) {
    id
    saleRatioId
  }
}
    `;
export type NewProfitableMutationFn = Apollo.MutationFunction<NewProfitableMutation, NewProfitableMutationVariables>;

/**
 * __useNewProfitableMutation__
 *
 * To run a mutation, you first call `useNewProfitableMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNewProfitableMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [newProfitableMutation, { data, loading, error }] = useNewProfitableMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useNewProfitableMutation(baseOptions?: Apollo.MutationHookOptions<NewProfitableMutation, NewProfitableMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<NewProfitableMutation, NewProfitableMutationVariables>(NewProfitableDocument, options);
      }
export type NewProfitableMutationHookResult = ReturnType<typeof useNewProfitableMutation>;
export type NewProfitableMutationResult = Apollo.MutationResult<NewProfitableMutation>;
export type NewProfitableMutationOptions = Apollo.BaseMutationOptions<NewProfitableMutation, NewProfitableMutationVariables>;
export const UpdateOneProfitableDocument = gql`
    mutation UpdateOneProfitable($data: ProfitableUpdateInput!, $where: ProfitableWhereUniqueInput!) {
  updateOneProfitable(data: $data, where: $where) {
    id
    saleRatioId
  }
}
    `;
export type UpdateOneProfitableMutationFn = Apollo.MutationFunction<UpdateOneProfitableMutation, UpdateOneProfitableMutationVariables>;

/**
 * __useUpdateOneProfitableMutation__
 *
 * To run a mutation, you first call `useUpdateOneProfitableMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOneProfitableMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOneProfitableMutation, { data, loading, error }] = useUpdateOneProfitableMutation({
 *   variables: {
 *      data: // value for 'data'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useUpdateOneProfitableMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOneProfitableMutation, UpdateOneProfitableMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOneProfitableMutation, UpdateOneProfitableMutationVariables>(UpdateOneProfitableDocument, options);
      }
export type UpdateOneProfitableMutationHookResult = ReturnType<typeof useUpdateOneProfitableMutation>;
export type UpdateOneProfitableMutationResult = Apollo.MutationResult<UpdateOneProfitableMutation>;
export type UpdateOneProfitableMutationOptions = Apollo.BaseMutationOptions<UpdateOneProfitableMutation, UpdateOneProfitableMutationVariables>;
export const RelatedPracticalSendingsToProfitableDocument = gql`
    query RelatedPracticalSendingsToProfitable($where: PostPackageWhereInput) {
  postPackages(where: $where) {
    id
    sendingType
    sendDate
    order {
      readableId
      price
      details {
        id
      }
      createdAt
    }
    _count {
      posts
    }
    posts {
      body {
        id
        inStoreItem {
          readableId
          price
          profitableId
        }
      }
    }
  }
}
    `;

/**
 * __useRelatedPracticalSendingsToProfitableQuery__
 *
 * To run a query within a React component, call `useRelatedPracticalSendingsToProfitableQuery` and pass it any options that fit your needs.
 * When your component renders, `useRelatedPracticalSendingsToProfitableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRelatedPracticalSendingsToProfitableQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useRelatedPracticalSendingsToProfitableQuery(baseOptions?: Apollo.QueryHookOptions<RelatedPracticalSendingsToProfitableQuery, RelatedPracticalSendingsToProfitableQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RelatedPracticalSendingsToProfitableQuery, RelatedPracticalSendingsToProfitableQueryVariables>(RelatedPracticalSendingsToProfitableDocument, options);
      }
export function useRelatedPracticalSendingsToProfitableLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RelatedPracticalSendingsToProfitableQuery, RelatedPracticalSendingsToProfitableQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RelatedPracticalSendingsToProfitableQuery, RelatedPracticalSendingsToProfitableQueryVariables>(RelatedPracticalSendingsToProfitableDocument, options);
        }
export type RelatedPracticalSendingsToProfitableQueryHookResult = ReturnType<typeof useRelatedPracticalSendingsToProfitableQuery>;
export type RelatedPracticalSendingsToProfitableLazyQueryHookResult = ReturnType<typeof useRelatedPracticalSendingsToProfitableLazyQuery>;
export type RelatedPracticalSendingsToProfitableQueryResult = Apollo.QueryResult<RelatedPracticalSendingsToProfitableQuery, RelatedPracticalSendingsToProfitableQueryVariables>;
export const NewStoreItemCardDocument = gql`
    mutation NewStoreItemCard($data: StoreItemCreateInput!) {
  createOneStoreItem(data: $data) {
    id
    type
    valid
    itemCard {
      id
      sn
      free
      price
      valid
      headUri
      tailUri
      orientation
      name
      description
      creator {
        idStr
        name
      }
      scenicSpot {
        id
        country
        latitude
        longitude
        name
        description
      }
    }
    itemStamp {
      id
      sn
      free
      price
      valid
      picUri
      name
      description
      creator {
        idStr
        name
      }
      scenicSpot {
        id
        country
        latitude
        longitude
        name
        description
      }
      createdAt
    }
    profitable {
      id
      name
    }
  }
}
    `;
export type NewStoreItemCardMutationFn = Apollo.MutationFunction<NewStoreItemCardMutation, NewStoreItemCardMutationVariables>;

/**
 * __useNewStoreItemCardMutation__
 *
 * To run a mutation, you first call `useNewStoreItemCardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNewStoreItemCardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [newStoreItemCardMutation, { data, loading, error }] = useNewStoreItemCardMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useNewStoreItemCardMutation(baseOptions?: Apollo.MutationHookOptions<NewStoreItemCardMutation, NewStoreItemCardMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<NewStoreItemCardMutation, NewStoreItemCardMutationVariables>(NewStoreItemCardDocument, options);
      }
export type NewStoreItemCardMutationHookResult = ReturnType<typeof useNewStoreItemCardMutation>;
export type NewStoreItemCardMutationResult = Apollo.MutationResult<NewStoreItemCardMutation>;
export type NewStoreItemCardMutationOptions = Apollo.BaseMutationOptions<NewStoreItemCardMutation, NewStoreItemCardMutationVariables>;
export const StoreItemsDocument = gql`
    query StoreItems($where: StoreItemWhereInput, $orderBy: [StoreItemOrderByWithRelationInput!]) {
  storeItems(where: $where, orderBy: $orderBy) {
    id
    type
    valid
    itemCard {
      id
      sn
      free
      price
      valid
      headUri
      tailUri
      orientation
      name
      description
      creator {
        idStr
        name
      }
      scenicSpot {
        id
        country
        latitude
        longitude
        name
        description
      }
      createdAt
    }
    itemStamp {
      id
      sn
      free
      price
      valid
      picUri
      name
      description
      creator {
        idStr
        name
      }
      scenicSpot {
        id
        country
        latitude
        longitude
        name
        description
      }
      createdAt
    }
    profitable {
      id
      name
      user {
        idStr
        name
      }
    }
  }
}
    `;

/**
 * __useStoreItemsQuery__
 *
 * To run a query within a React component, call `useStoreItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useStoreItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStoreItemsQuery({
 *   variables: {
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useStoreItemsQuery(baseOptions?: Apollo.QueryHookOptions<StoreItemsQuery, StoreItemsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<StoreItemsQuery, StoreItemsQueryVariables>(StoreItemsDocument, options);
      }
export function useStoreItemsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StoreItemsQuery, StoreItemsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<StoreItemsQuery, StoreItemsQueryVariables>(StoreItemsDocument, options);
        }
export type StoreItemsQueryHookResult = ReturnType<typeof useStoreItemsQuery>;
export type StoreItemsLazyQueryHookResult = ReturnType<typeof useStoreItemsLazyQuery>;
export type StoreItemsQueryResult = Apollo.QueryResult<StoreItemsQuery, StoreItemsQueryVariables>;
export const StoreItemDocument = gql`
    query StoreItem($where: StoreItemWhereUniqueInput!) {
  storeItem(where: $where) {
    id
    type
    valid
    itemCard {
      id
      sn
      free
      price
      valid
      headUri
      tailUri
      orientation
      name
      description
      creator {
        idStr
        name
      }
      scenicSpot {
        id
        country
        latitude
        longitude
        name
        description
      }
      createdAt
    }
    itemStamp {
      id
      sn
      free
      price
      valid
      picUri
      name
      description
      creator {
        idStr
        name
      }
      scenicSpot {
        id
        country
        latitude
        longitude
        name
        description
      }
      createdAt
    }
    profitable {
      id
      name
      user {
        idStr
        name
      }
    }
  }
}
    `;

/**
 * __useStoreItemQuery__
 *
 * To run a query within a React component, call `useStoreItemQuery` and pass it any options that fit your needs.
 * When your component renders, `useStoreItemQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStoreItemQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useStoreItemQuery(baseOptions: Apollo.QueryHookOptions<StoreItemQuery, StoreItemQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<StoreItemQuery, StoreItemQueryVariables>(StoreItemDocument, options);
      }
export function useStoreItemLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StoreItemQuery, StoreItemQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<StoreItemQuery, StoreItemQueryVariables>(StoreItemDocument, options);
        }
export type StoreItemQueryHookResult = ReturnType<typeof useStoreItemQuery>;
export type StoreItemLazyQueryHookResult = ReturnType<typeof useStoreItemLazyQuery>;
export type StoreItemQueryResult = Apollo.QueryResult<StoreItemQuery, StoreItemQueryVariables>;
export const UpdateOneStoreItemDocument = gql`
    mutation UpdateOneStoreItem($data: StoreItemUpdateInput!, $where: StoreItemWhereUniqueInput!) {
  updateOneStoreItem(data: $data, where: $where) {
    id
  }
}
    `;
export type UpdateOneStoreItemMutationFn = Apollo.MutationFunction<UpdateOneStoreItemMutation, UpdateOneStoreItemMutationVariables>;

/**
 * __useUpdateOneStoreItemMutation__
 *
 * To run a mutation, you first call `useUpdateOneStoreItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOneStoreItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOneStoreItemMutation, { data, loading, error }] = useUpdateOneStoreItemMutation({
 *   variables: {
 *      data: // value for 'data'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useUpdateOneStoreItemMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOneStoreItemMutation, UpdateOneStoreItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOneStoreItemMutation, UpdateOneStoreItemMutationVariables>(UpdateOneStoreItemDocument, options);
      }
export type UpdateOneStoreItemMutationHookResult = ReturnType<typeof useUpdateOneStoreItemMutation>;
export type UpdateOneStoreItemMutationResult = Apollo.MutationResult<UpdateOneStoreItemMutation>;
export type UpdateOneStoreItemMutationOptions = Apollo.BaseMutationOptions<UpdateOneStoreItemMutation, UpdateOneStoreItemMutationVariables>;
export const StoreRatiosDocument = gql`
    query StoreRatios($orderBy: [StoreRatioOrderByWithRelationInput!]) {
  storeRatios(orderBy: $orderBy) {
    id
    value
    description
  }
}
    `;

/**
 * __useStoreRatiosQuery__
 *
 * To run a query within a React component, call `useStoreRatiosQuery` and pass it any options that fit your needs.
 * When your component renders, `useStoreRatiosQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStoreRatiosQuery({
 *   variables: {
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useStoreRatiosQuery(baseOptions?: Apollo.QueryHookOptions<StoreRatiosQuery, StoreRatiosQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<StoreRatiosQuery, StoreRatiosQueryVariables>(StoreRatiosDocument, options);
      }
export function useStoreRatiosLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StoreRatiosQuery, StoreRatiosQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<StoreRatiosQuery, StoreRatiosQueryVariables>(StoreRatiosDocument, options);
        }
export type StoreRatiosQueryHookResult = ReturnType<typeof useStoreRatiosQuery>;
export type StoreRatiosLazyQueryHookResult = ReturnType<typeof useStoreRatiosLazyQuery>;
export type StoreRatiosQueryResult = Apollo.QueryResult<StoreRatiosQuery, StoreRatiosQueryVariables>;
export const CreateOneStoreRatioHistoryItemDocument = gql`
    mutation CreateOneStoreRatioHistoryItem($data: StoreRatioHistoryItemCreateInput!) {
  createOneStoreRatioHistoryItem(data: $data) {
    id
    createdAt
  }
}
    `;
export type CreateOneStoreRatioHistoryItemMutationFn = Apollo.MutationFunction<CreateOneStoreRatioHistoryItemMutation, CreateOneStoreRatioHistoryItemMutationVariables>;

/**
 * __useCreateOneStoreRatioHistoryItemMutation__
 *
 * To run a mutation, you first call `useCreateOneStoreRatioHistoryItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOneStoreRatioHistoryItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOneStoreRatioHistoryItemMutation, { data, loading, error }] = useCreateOneStoreRatioHistoryItemMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateOneStoreRatioHistoryItemMutation(baseOptions?: Apollo.MutationHookOptions<CreateOneStoreRatioHistoryItemMutation, CreateOneStoreRatioHistoryItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateOneStoreRatioHistoryItemMutation, CreateOneStoreRatioHistoryItemMutationVariables>(CreateOneStoreRatioHistoryItemDocument, options);
      }
export type CreateOneStoreRatioHistoryItemMutationHookResult = ReturnType<typeof useCreateOneStoreRatioHistoryItemMutation>;
export type CreateOneStoreRatioHistoryItemMutationResult = Apollo.MutationResult<CreateOneStoreRatioHistoryItemMutation>;
export type CreateOneStoreRatioHistoryItemMutationOptions = Apollo.BaseMutationOptions<CreateOneStoreRatioHistoryItemMutation, CreateOneStoreRatioHistoryItemMutationVariables>;
export const UsersDocument = gql`
    query Users($where: UserWhereInput) {
  users(where: $where) {
    id
    idStr
    name
    phone
    bio
    address {
      address1
      address2
    }
    country
  }
}
    `;

/**
 * __useUsersQuery__
 *
 * To run a query within a React component, call `useUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useUsersQuery(baseOptions?: Apollo.QueryHookOptions<UsersQuery, UsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UsersQuery, UsersQueryVariables>(UsersDocument, options);
      }
export function useUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UsersQuery, UsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UsersQuery, UsersQueryVariables>(UsersDocument, options);
        }
export type UsersQueryHookResult = ReturnType<typeof useUsersQuery>;
export type UsersLazyQueryHookResult = ReturnType<typeof useUsersLazyQuery>;
export type UsersQueryResult = Apollo.QueryResult<UsersQuery, UsersQueryVariables>;