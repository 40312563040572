import {
  Button,
  Card,
  Col,
  DatePicker,
  Divider,
  Row,
  Select,
  Typography,
  notification,
} from 'antd';
import dayjs from 'dayjs';
import { isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import {
  BenefitMemo,
  BenefitMemosQuery,
  BenefitTransfer,
  BenefitType,
  OrderBenefitsQuery,
  SortOrder,
  useOrderBenefitsLazyQuery,
  useOrderBenefitsQuery,
  useUpdateOneBenefitTransferMutation,
} from '../../../graphql/types';
import SalesOrderMutationModal from './SalesOrderMutationModal';
import SalesOrderReportModal from './SalesOrderReportModal';
import SalesOrderTable from './SalesOrderTable';
type NotificationType = 'success' | 'info' | 'warning' | 'error';
const openNotificationWithIcon = (type: NotificationType) => {
  notification[type]({
    message: '關聯匯款記錄完成',
  });
};

const SalesOrderPage = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isReportModalOpen, setIsReportModalOpen] = useState(false);
  const [stateBenefitMemos, setStateBenefitMemos] = useState<
    OrderBenefitsQuery['benefitMemos'][0][]
  >([]);
  const [filteredMemo, setFilteredMemo] = useState<
    OrderBenefitsQuery['benefitMemos'][0][]
  >([]);
  const [curProfitableId, setCurProfitableId] = useState('');
  const [selectedRows, setSelectedRows] = useState<
    OrderBenefitsQuery['benefitMemos'][0][]
  >([]);
  const [profitableList, setProfitableList] = useState<
    Array<{
      idStr: string;
      profitableId: string;
      userId: string;
      userName: string;
    }>
  >([]);
  const [curMemoType, setCurMemoType] = useState<BenefitType>(
    BenefitType.MemoToCoin
  );
  const [monthRange, setMonthRange] = useState<string[]>([]);
  const [unPaidMemos, setUnPaidMemos] = useState(0);
  const [transfer, setTransfer] = useState<
    OrderBenefitsQuery['benefitMemos'][0]['transfer'] | null
  >(null);
  const startOfMonth = dayjs().tz('Asia/Taipei').startOf('month').toISOString();
  const endOfMonth = dayjs().tz('Asia/Taipei').endOf('month').toISOString();
  const [fetchBenefitMemosByCreator] = useOrderBenefitsLazyQuery({
    onCompleted: (data) => {
      const unPaidMemos = data.benefitMemos.reduce((acc, cur) => {
        if (!cur.transfer) {
          acc = acc + (cur?.order?.benefit || 0);
        }
        return acc;
      }, 0);
      setUnPaidMemos(unPaidMemos);
    },
  });
  console.log('Hello transferId', transfer);

  const [fetchBenefitMemosByCreatorForOrder] = useOrderBenefitsLazyQuery({
    onCompleted: (data) => {
      console.log('Hello data', data);
      const _transfer = data.benefitMemos.filter((memo) => memo.transfer)[0]
        ?.transfer;
      setTransfer(_transfer);
    },
  });

  const [updateTransfer] = useUpdateOneBenefitTransferMutation({
    onCompleted() {
      forceUpdate();
      handleOk();
    },
    onError(error) {
      console.log('Hello error', error);
    },
  });

  const handleUpdateTransfer = () => {
    if (transfer?.id) {
      updateTransfer({
        variables: {
          where: {
            id: transfer?.id,
          },
          data: {
            benifitMemo: {
              connect: selectedRows.map((item) => ({
                id: item.id,
              })),
            },
          },
        },
      }).then(() => {
        openNotificationWithIcon('success');
      });
    }
  };

  useEffect(() => {
    if (curProfitableId) {
      setFilteredMemo(
        stateBenefitMemos.filter(
          (item) => item.profitable.id === curProfitableId
        )
      );
      fetchBenefitMemosByCreator({
        variables: {
          where: {
            type: {
              equals: BenefitType.MemoToOrder,
            },
            profitableId: {
              equals: curProfitableId,
            },
          },
          orderBy: [
            {
              order: {
                createdAt: SortOrder.Asc,
              },
            },
          ],
        },
      });

      fetchBenefitMemosByCreatorForOrder({
        variables: {
          where: {
            type: {
              equals: BenefitType.MemoToCoin,
            },
            profitableId: {
              equals: curProfitableId,
            },
          },
          orderBy: [
            {
              order: {
                createdAt: SortOrder.Desc,
              },
            },
          ],
        },
      });
    }
  }, [curProfitableId, stateBenefitMemos]);

  useEffect(() => {
    setMonthRange([startOfMonth, endOfMonth]);
  }, []);

  const forceUpdate = React.useCallback(async () => {
    const newFetchedData = await refetchBenefitMemos();
    // await refetchStoreItems();
    console.log('Hello newFetchedData', newFetchedData);
    if (newFetchedData) {
      setStateBenefitMemos(newFetchedData.data.benefitMemos as BenefitMemo[]);
    }
  }, []);

  const rowSelection = {
    onChange: (
      selectedRowKeys: React.Key[],
      selectedRows: BenefitMemosQuery['benefitMemos'][0][]
    ) => {
      setSelectedRows(selectedRows);
    },
  };

  console.log('Hello filteredMemo', filteredMemo);
  console.log('Hello selectedRowKeys', selectedRows);

  const { refetch: refetchBenefitMemos } = useOrderBenefitsQuery({
    variables: {
      where: {
        type: {
          equals: BenefitType.MemoToOrder,
        },
        order: {
          is: {
            order: {
              is: {
                createdAt: {
                  gte: monthRange[0],
                  lte: monthRange[1],
                },
              },
            },
          },
        },
      },
      orderBy: [
        {
          order: {
            createdAt: SortOrder.Asc,
          },
        },
      ],
    },
    onCompleted(data) {
      setStateBenefitMemos(
        data.benefitMemos as OrderBenefitsQuery['benefitMemos'][0][]
      );
      const ProfitableToStoreItemMap = new Map();
      data.benefitMemos
        .map((item) => item.profitable)
        .forEach((item) => {
          ProfitableToStoreItemMap.set(item.id, {
            profitableId: item.id,
            userName: item.user.name,
            userId: item.user.id,
            idStr: item.user.idStr,
          });
        });
      setProfitableList(
        Array.from(ProfitableToStoreItemMap, ([_, value]) => ({
          ...value,
        }))
      );
    },
  });

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const showReportModal = () => {
    setIsReportModalOpen(true);
  };

  const handleReportOk = () => {
    setIsReportModalOpen(false);
  };

  const handleReportCancel = () => {
    setIsReportModalOpen(false);
  };

  const handleChangeMonth = (months: any) => {
    setMonthRange([
      dayjs(months[0]).startOf('month').toISOString(),
      dayjs(months[1]).endOf('month').toISOString(),
    ]);
  };

  const updateMemoListByProfitableId = (profitableId: string) => {
    setCurProfitableId(profitableId);
  };

  console.log('Hello stateBenefitMemos', stateBenefitMemos);

  return (
    <div
      className="site-layout-background"
      style={{ padding: 24, minHeight: 360 }}
    >
      <Row>
        <Col>
          <Typography.Title level={3}>實體寄送</Typography.Title>
        </Col>
      </Row>
      <Row gutter={15} className="mb-3">
        <Col>
          <Typography.Text>銷售月份：</Typography.Text>
          <DatePicker.RangePicker
            onChange={(values) => {
              handleChangeMonth(values);
            }}
            picker="month"
          />
        </Col>
        <Col>
          <Select
            style={{ width: 250 }}
            onChange={updateMemoListByProfitableId}
            placeholder="請選擇創作者"
          >
            <Select.Option key={'default'} value={null}>
              全部創作者
            </Select.Option>
            {profitableList.map((item) => (
              <Select.Option key={item.profitableId} value={item.profitableId}>
                {`${item.userName} (${item.idStr})`}
              </Select.Option>
            ))}
          </Select>
        </Col>
      </Row>
      <Divider />
      <Row className="my-2" gutter={25}>
        <Col className="text-start">
          <Button disabled={isEmpty(curProfitableId)} onClick={showReportModal}>
            分潤報表
          </Button>
        </Col>
      </Row>
      {transfer && (
        <Row className="mb-3">
          <Col span={24}>
            <Card title="這位創作者有一筆最近的匯款記錄">
              <p>日期：{transfer?.transferDate}</p>
              <p>金額：{transfer?.totalAmount}</p>
              <p>ID：{transfer?.id}</p>
              <Button
                disabled={isEmpty(curProfitableId)}
                onClick={handleUpdateTransfer}
              >
                關聯匯款記錄
              </Button>
            </Card>
          </Col>
        </Row>
      )}
      <SalesOrderMutationModal
        isModalOpen={isModalOpen}
        handleOk={handleOk}
        handleCancel={handleCancel}
        forceUpdate={forceUpdate}
        benefitMemos={selectedRows}
        curProfitableId={curProfitableId}
      />

      <SalesOrderReportModal
        isModalOpen={isReportModalOpen}
        handleOk={handleReportOk}
        handleCancel={handleReportCancel}
        forceUpdate={forceUpdate}
        benefitMemos={filteredMemo}
        curProfitable={profitableList.find(
          ({ profitableId }) => profitableId === curProfitableId
        )}
        monthRange={monthRange}
        curMemoType={curMemoType}
        unPaidMemos={unPaidMemos}
      />
      {!curProfitableId ? (
        <Typography.Title level={4}>
          選完月份就選一個創作者吧～！！！
        </Typography.Title>
      ) : (
        <SalesOrderTable
          rowSelection={rowSelection}
          items={
            curProfitableId && filteredMemo.length > 0
              ? filteredMemo
              : stateBenefitMemos
          }
        />
      )}
    </div>
  );
};

export default SalesOrderPage;
