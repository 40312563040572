import { Spin, Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import dayjs from 'dayjs';
import { isEmpty } from 'lodash';
import { Link } from 'react-router-dom';
import { StoreItemsQuery } from '../../../graphql/types';

interface Props {
  storeItems: StoreItemsQuery['storeItems'] | undefined;
}

function ProductTable({ storeItems }: Props) {
  if (isEmpty(storeItems)) {
    return <Spin />;
  }
  const columns: ColumnsType<StoreItemsQuery['storeItems'][0]> = [
    {
      title: '創作者名稱',
      dataIndex: 'profitable',
      key: 'creator',
      render: (profitable, data) => (
        <Link to={`profitable/${profitable.id}`}>{profitable.user.name}</Link>
      ),
    },
    {
      title: '商品名稱',
      dataIndex: 'itemCard',
      key: 'productName',
      render: (itemCard, data) => {
        return (
          <Link to={`product/${data.id}`}>
            {data.itemCard?.name || data.itemStamp?.name}
          </Link>
        );
      },
    },
    {
      title: '商品編號',
      dataIndex: 'itemCard',
      key: 'productName',
      render: (itemCard, data) => {
        return data.itemCard?.sn || data.itemStamp?.sn;
      },
    },
    {
      title: '商品類型',
      dataIndex: 'itemCard',
      key: 'productType',
      render: (itemCard, data) => {
        return data.type === 'postcard' ? '明信片' : '郵票';
      },
    },
    {
      title: '商品預覽',
      dataIndex: 'itemCard',
      key: 'headUri',
      render: (itemCard, data) => (
        <img
          style={{ maxWidth: 80 }}
          src={data.itemCard?.headUri || data.itemStamp?.picUri}
          alt="商品預覽"
        />
      ),
    },
    {
      title: '建立時間',
      dataIndex: 'itemCard',
      key: 'createdAt',
      render: (itemCard, data) =>
        dayjs(data.itemCard?.createdAt || data.itemStamp?.createdAt).format(
          'YYYY/MM/DD'
        ),
    },
  ];

  return <Table dataSource={storeItems} columns={columns} />;
}

export default ProductTable;
