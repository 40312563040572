import DownloadPostCardPDF from '../components/DownloadPostCardPDF';
import React from 'react';
// @ts-ignore
import html2pdf from 'html2pdf.js';
import { renderToStaticMarkup } from 'react-dom/server';

export const exportPdf = (id: string, post: any) => {
  const componentToPrint = React.createElement(DownloadPostCardPDF, {
    headUri: post.body.headUri,
    rearUri: post.rearUri4Practical as string | undefined,
    isVertical: post.body.orientation === 'Vertical',
  });
  const output = document.createElement('div');
  const staticElement = renderToStaticMarkup(componentToPrint);
  output.innerHTML = `<div>${staticElement}</div>`;

  html2pdf()
    .from(output)
    .set({
      filename: `${id}.pdf`,
      pagebreak: { mode: ['css'], before: '.before', after: '.after' },
      image: { type: 'jpeg', quality: 1 },
      jsPDF: {
        orientation: 'portrait',
        unit: 'pt',
        format: 'a4',
        compressPDF: false,
      },
      html2canvas: {
        dpi: 300,
        scale: 3,
        allowTaint: true,
        useCORS: true,
      },
    })
    .save();
};
