import React from 'react';
import { Table, Spin, Image } from 'antd';
import {
  CardsQuery,
  CouponInfosQuery,
  SortOrder,
  useCardsQuery as getCardsQuery,
  useCouponInfosQuery as getCouponInfos,
  SendingType,
} from '../../graphql/types';
import { CSVLink } from 'react-csv';
import dayjs from 'dayjs';

const NormalActivity = () => {
  const columns = [
    {
      title: '活動名稱',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: '掃碼下載明信片次數',
      dataIndex: 'data',
      render: (data: any) => {
        if (!data) return 0;
        return data.cards?.reduce((acc: number, cur: any) => {
          const conut = cur._count.collections + acc;
          return conut;
        }, 0);
      },
    },
    {
      title: '優惠兌換次數',
      dataIndex: 'description',
      render: (description: any) => {
        const count = (couponInfos as CouponInfosQuery)?.couponInfos.reduce(
          (acc: number, coupon: any) => {
            const amount = coupon.activity?.card?.description.includes(
              description
            )
              ? coupon.couponEntities.length
              : 0;
            return acc + amount;
          },
          0
        );
        return count || 0;
      },
    },
    {
      title: '數位明信片寄出次數',
      dataIndex: 'data',
      render: (data: any) => {
        if (!data) return 0;
        return data.cards?.reduce((acc: number, cur: any) => {
          const count =
            cur.postsUsed.filter((p: any) => p.package.sendingType === 'online')
              .length + acc;
          return count;
        }, 0);
      },
    },
    {
      title: '實體明信片寄出次數',
      dataIndex: 'data',
      render: (data: any) => {
        if (!data) return 0;
        return data.cards?.reduce((acc: number, cur: any) => {
          const count =
            cur.postsUsed.filter(
              (p: any) => p.package.sendingType === 'practical'
            ).length + acc;
          return count;
        }, 0);
      },
    },
  ];

  const queryMultiple = () => {
    const res1 = getCardsQuery({
      variables: {
        where: {
          description: {
            contains: '活動: 萬華老城創業故事',
          },
        },
        postsUsedWhere2: {
          package: {
            is: {
              OR: [
                {
                  sendingType: {
                    equals: SendingType.Online,
                  },
                },
                {
                  sendingType: {
                    equals: SendingType.Practical,
                  },
                  order: {
                    is: {
                      isPaid: {
                        equals: true,
                      },
                    },
                  },
                },
              ],
            },
          },
        },
        orderBy: [
          {
            createdAt: SortOrder.Asc,
          },
        ],
      },
    });
    const res2 = getCardsQuery({
      variables: {
        where: {
          description: {
            contains: '活動: 2022萬華老城咖啡香',
          },
        },
        postsUsedWhere2: {
          package: {
            is: {
              OR: [
                {
                  sendingType: {
                    equals: SendingType.Online,
                  },
                },
                {
                  sendingType: {
                    equals: SendingType.Practical,
                  },
                  order: {
                    is: {
                      isPaid: {
                        equals: true,
                      },
                    },
                  },
                },
              ],
            },
          },
        },
        orderBy: [
          {
            createdAt: SortOrder.Asc,
          },
        ],
      },
    });
    const res3 = getCouponInfos({
      variables: {
        where: {
          used: {
            equals: true,
          },
        },
        orderBy: [
          {
            createdAt: SortOrder.Asc,
          },
        ],
      },
    });

    const res4 = getCardsQuery({
      variables: {
        where: {
          description: {
            contains: '南迴藝術',
          },
        },
        postsUsedWhere2: {
          package: {
            is: {
              OR: [
                {
                  sendingType: {
                    equals: SendingType.Online,
                  },
                },
                {
                  sendingType: {
                    equals: SendingType.Practical,
                  },
                  order: {
                    is: {
                      isPaid: {
                        equals: true,
                      },
                    },
                  },
                },
              ],
            },
          },
        },
        orderBy: [
          {
            createdAt: SortOrder.Asc,
          },
        ],
      },
    });

    return [res1, res2, res3, res4];
  };

  const [
    { loading: loading1, data: data1 },
    { loading: loading2, data: data2 },
    { loading: loading3, data: couponInfos },
    { loading: loading4, data: nanHuiData },
  ] = queryMultiple();

  console.log('Hello data1', data1); // log is here
  console.log('Hello data2', data2); // log is here
  console.log('Hello data3', couponInfos); // log is here
  console.log('Hello data4', nanHuiData); // log is here

  const dataSource = [
    {
      key: 0,
      name: '萬華老城創業故事店家優惠內容',
      collected: 0,
      data: data1 as CardsQuery,
      description: '活動: 萬華老城創業故事',
    },
    {
      key: 1,
      name: '萬華老城咖啡香店家優惠內容',
      collected: 0,
      data: data2 as CardsQuery,
      description: '活動: 2022萬華老城咖啡香',
    },
    {
      key: 2,
      name: '2022臺東南迴藝術季',
      collected: 0,
      data: nanHuiData as CardsQuery,
      description: '南迴藝術',
    },
  ];

  const expandedRowRender = ({ data: { cards }, description }: any) => {
    console.log('Hello cards', cards); // log is here
    const columns = [
      {
        title: '店家名稱',
        dataIndex: 'name',
        render: (name: string, data: any) => {
          return (
            <>
              <span>{name}</span>
              <Image width={80} src={data.headUri} alt={name} />
            </>
          );
        },
      },
      {
        title: '掃碼下載明信片次數',
        dataIndex: '_count',
        render: (_count: any) => _count.collections,
      },
      {
        title: '優惠兌換次數',
        dataIndex: 'name',
        render: (name: any) => {
          const count = (couponInfos as CouponInfosQuery)?.couponInfos.find(
            (coupon) =>
              coupon.activity?.card?.name === name &&
              coupon.activity?.card?.description?.includes(description)
          )?.couponEntities.length;
          return count || 0;
        },
      },
      {
        title: '數位明信片寄出次數',
        dataIndex: 'postsUsed',
        render: (postsUsed: any) =>
          postsUsed.filter((p: any) => p.package.sendingType === 'online')
            .length,
      },
      {
        title: '實體明信片寄出次數',
        dataIndex: 'postsUsed',
        render: (postsUsed: any) =>
          postsUsed.filter((p: any) => p.package.sendingType === 'practical')
            .length,
      },
    ];
    return <Table columns={columns} dataSource={cards} pagination={false} />;
  };

  if (loading1 && loading2 && loading3 && loading4) {
    return <Spin size="large" />;
  }

  const exportedData: any[] = [];
  const data: any[] = [];
  const nestedData: any[] = [];

  dataSource.forEach((ds) => {
    const getDownloads = () => {
      if (!ds.data) return 0;
      return ds.data.cards?.reduce((acc: number, cur: any) => {
        const count = cur._count.collections + acc;
        return count;
      }, 0);
    };
    const getDiscounts = () => {
      const count = (couponInfos as CouponInfosQuery)?.couponInfos.reduce(
        (acc: number, coupon: any) => {
          const amount = coupon.activity?.card?.description.includes(
            ds.description
          )
            ? coupon.couponEntities.length
            : 0;
          return acc + amount;
        },
        0
      );
      return count || 0;
    };
    const getSents = (type: string) => {
      if (!ds.data) return 0;
      return ds.data.cards?.reduce((acc: number, cur: any) => {
        const conut =
          cur.postsUsed.filter((p: any) => p.package.sendingType === type)
            .length + acc;
        return conut;
      }, 0);
    };
    data.push({
      key: ds.name,
      活動名稱: ds.name,
      掃碼下載明信片次數: getDownloads(),
      優惠兌換次數: getDiscounts(),
      數位明信片寄出次數: getSents('online'),
      實體明信片寄出次數: getSents('practical'),
    });
    ds.data?.cards.forEach((card) => {
      const getDownloads = () => {
        const count = (couponInfos as CouponInfosQuery)?.couponInfos.find(
          (coupon) =>
            coupon.activity?.card?.name === card.name &&
            coupon.activity?.card?.description?.includes(ds.description)
        )?.couponEntities.length;
        return count || 0;
      };
      nestedData.push({
        key: ds.name,
        活動名稱: ` --- ${card.name}`,
        掃碼下載明信片次數: card._count?.collections,
        優惠兌換次數: getDownloads(),
        數位明信片寄出次數: card.postsUsed?.filter(
          (p: any) => p.package.sendingType === 'online'
        ).length,
        實體明信片寄出次數: card.postsUsed?.filter(
          (p: any) => p.package.sendingType === 'practical'
        ).length,
      });
    });
  });

  data.forEach((element, i) => {
    exportedData.push(element);
    nestedData
      .filter(({ key }) => key === element.key)
      .forEach((element) => exportedData.push(element));
  });

  return (
    <div>
      <CSVLink
        filename={`一般活動統計資料 - ${dayjs().format('YYYY-MM-DD')}.csv`}
        data={exportedData.map(({ key, ...rest }) => rest)}
        className="btn btn-primary"
      >
        Download csv
      </CSVLink>
      <Table
        dataSource={dataSource}
        columns={columns}
        expandable={{ expandedRowRender }}
      />
    </div>
  );
};

export default NormalActivity;
